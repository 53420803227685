import Lib from '../../../commons/lib';

export default class Service {

  static bankUrl = window.location.origin + '/api/bank/';

  static penzugyUrl = window.location.origin + '/api/penzugykozos/';

  // BANK START

  static getEnum(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bankUrl + 'bankkivonat/enum',
      args: { requestType: 'bankkivonat-enum' },
    }], fnDone);
  }

  static exportBankalapXLS(bankszamlaid, tol, ig, bankteteltipus, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bankUrl + 'bankkivonat/bankalaplista/xls',
      acceptType: 'application/json, application/octet-stream',
      params: {
        listak: true,
        bankszamlaid: bankszamlaid,
        tol: tol,
        ig: ig,
        bankteteltipus: bankteteltipus
      }
    }], fnDone);
  }

  // BANK END

  // PENZUGY START

  static listSzamlak(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'bankszamlapenztar/listbankkivonatbankszamla',
      args: { requestType: 'bankszamlapenztar-listbankkivonatbankszamla' }
    }], fnDone);
  }

  // PENZUGY END
}
