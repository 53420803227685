import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Alert ,
  Button,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from 'reactstrap';
import {
  AvForm,
  AvGroup,
  AvFeedback,
  AvInput
} from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { browserHistory } from 'react-router';
import { DateTimePicker } from 'react-widgets';
import momentLocalizer from 'react-widgets-moment';
import Moment from 'moment';
import MomentTimezone from 'moment-timezone';
import Select from 'react-select';
import CreatableSelect  from 'react-select/lib/Creatable';
import CikkSelect from '../CikkSelect';
import Lib from '../../../../../commons/lib';
import { PaginationTotal } from '../../../../../commons/controls';
import { ProgressButton } from '../../../../../commons/ProgressButton';
import NetworkEmptyState from '../../../../../commons/networkemptystate';
import TreeView from '../../../../../commons/Treeview';
import Loader from '../../../../../commons/loader';
import Services from '../services';
import getTooltip from './LeltarTooltips';
import CustomTooltip from '../../../../../components/Tooltip/CustomTooltip';
import 'moment/locale/hu';

momentLocalizer(Moment);

class LeltarForm extends Component {
  constructor(props) {
    super(props);

    this.textInputRef = React.createRef();

    this.state = {
      leltarId: '',
      modositas: false,
      isAlertOpen: false,
      isAlertEditOpen: false,
      hasAllOldRaktarRoles: true,
      // FORM VALUES
      formTitle: '-',
      formTipus: '',
      formLeltarModositasOptions: [],
      formHelyszin: '',
      formHelyszinOptions: [],
      formHatarido: Moment().format('YYYY-MM-DD'),
      formRaktar: '',
      formRaktarOptions: [],
      formFelelos: '',
      formFelelosNev: null,
      formFelelosOptions: [],
      baseJson: [],
      formTelefon: '',
      formEmail: '',
      formModalPenzintezet: '',
      formModalBankszamlaszam: '',
      formModalIbanszam: '',
      // -
      formFordulonap: Moment().startOf('day'),
      formBlokk: false,
      formMegjegyzes: '',
      formLeltarozasMod: '',
      formAllapotMegjegyzes: '',
      formAllapot: '',
      formCikkKereses: '',
      // MODAL UTILS
      modalBankadatTitle: '-',
      modalBankadatButtonText: '-',
      modalLeltariv: false,
      modalEdit: false,
      // FORM UTILS
      formAllapotOptions: [],
      lek: null,
      submitButtonLoading: false,
      bankButtonLoading: false,
      // STATUS UTILS
      // ['LOAD', 'DATA', 'NETW']
      formCardStatus: 'LOAD',
      leltariIvJson: [],
      expanded: [],
      cikkTree: [],
      modalCikk: false,
      cikkFilteredTree: [],
      cikkek: [],
      editmode: false,
      editobj: {},
      existsSzervezet: true,
      felelosok: [],
      selectedIndex: null,
      tableReseted: true,
    };
    this.cikkSelectRef = React.createRef();
    this.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  componentDidMount() {
    Services.listFelelos((err, res) => {
      if (!err) {
        this.setState({ felelosok: res[0] });
      }
    });
    if (this.props.route.type === 'MOD') {
      let params = new URL(window.location.href).searchParams;
      let id = params.get('id');
      this.setState({
        formTitle: 'Leltár módosítása',
        leltarId: id,
        modositas: true,
        lek: this.props.route && this.props.route.location && this.props.route.location.state && this.props.route.location.state.lek
      }, () => {
        this.getLeltar(id);
      });
    }
    if (this.props.route.type === 'FEL') {
      this.setState({
        formTitle: 'Új leltár',
        // lek: this.props && this.props.location && this.props.location.state && this.props.location.state.lek
      }, () => {
        this.initBejelentesForm();
      });
    }
    this.checkRoles();
    this.isSzervezetExist();
  }

  checkRoles = () => {
    this.hasRoleKarb = Lib.KeyCloak.hasHigherRole(this.props.route.globals.keycloak, 'KESZLETNYILV_LELTAR', 'KARB');
    this.hasRoleMennyRogz = Lib.KeyCloak.hasRoleSpec(this.props.route.globals.keycloak, ['KESZLETNYILV_LELTAR_MENNYROGZ']);
  }

  isSzervezetExist = () => {
    Services.existsSzervezet((err, res) => {
      if(!err){
        this.setState({existsSzervezet: JSON.parse(res[0])});
      }
    });
  }

  toggleKorrekcioModal = () => {
    this.setState(prevState => ({ modalKorrekcio: !prevState.modalKorrekcio }));
  }

  toggleAlert = () => {
    this.setState(prevState => ({ isAlertOpen: !prevState.isAlertOpen }));
  }

  toggleAlertEdit = () => {
    this.setState(prevState => ({ isAlertEditOpen: !prevState.isAlertEditOpen }));
  }

  toggleDeleteModal = () => {
    this.setState(prevState => ({ modalDelete: !prevState.modalDelete }));
  }

  toggleEditModal = () => {
    this.setState(prevState => ({ modalEdit: !prevState.modalEdit, isAlertEditOpen: false }));
  }

  toggleLeltariv = (title) => {
    this.setState(prevState => ({ modalLeltariv: !prevState.modalLeltariv, modalLeltarivTitle: title, isAlertOpen: false }));
    Services.listBizonylatRaktar('LELTARIV', null, (err, res) => {
      if (!err) {
        this.setState({
          formRaktarOptions: res[0]
        });
      }
    });

    if(this.state.existsSzervezet){
      Services.listSzemely((err, res) => {
        if (!err) {
          this.setState({
            formFelelosOptions: res[0]
          });
        }
      });
    }
  }

  toggleCikk = () => {
    if (this.state.editmode) this.setState({ editmode: false });
    this.setState({ modalCikk: !this.state.modalCikk });
  }

  // korrekcio = () => {
  //   this.setState({
  //     korrekcioButtonLoading: true
  //   });
  //   Services.getLeltarKorrekcio(this.state.leltarId, (err) => {
  //     if (!err) {
  //       this.toggleKorrekcioModal();
  //       this.getLeltar(this.state.leltarId);
  //       this.props.route.globals.addSuccess('A korrekció végrehajtásra került!');
  //       this.setState({
  //         korrekcioButtonLoading: false
  //       });
  //     } else {
  //       this.setState({
  //         korrekcioButtonLoading: false
  //       });
  //     }
  //   });
  // }

  edit = async() => {
    if (( this.state.existsSzervezet && (this.state.formFelelos === '' || !this.state.formFelelos)) || ( !this.state.existsSzervezet && (!this.state.formFelelosNev || this.state.formFelelosNev.label === ''))) {
      this.setState({
        isAlertEditOpen: true,
      });
    } else {
      let leltarJSON = JSON.parse(JSON.stringify(this.state.leltariIvJson));
      await leltarJSON.forEach((leltariv) => {
        if (leltariv.id == this.state.currentEditId) {
          leltariv.szemely = this.state.existsSzervezet ? this.state.formFelelos : {nev: this.state.formFelelosNev.label};
        }
      });
      this.setState({
        leltariIvJson: leltarJSON,
        modalEdit: false
      });
    }
  }

  addCikk = (event, cikkObj) => {
    let leltarJSON = JSON.parse(JSON.stringify(this.state.leltariIvJson));
    try {
      leltarJSON.forEach((leltariv) => {
        if (leltariv.id == this.state.currentIv) {
          if (!cikkObj.egysegarnetto)
            throw 'A beszerzési ár megadása kötelező nem bevételezett cikk esetén!';
          leltariv.cikkek.push({
            parentid: leltariv.id,
            id: cikkObj.cikk.id,
            megnevezes: cikkObj.megnevezes,
            cikkSzam: cikkObj.cikkszam,
            leltarMennyiseg: cikkObj.mennyiseg,
            beszerzesiAr: cikkObj.egysegarnetto,
            mennyisegiEgyseg: cikkObj.mennyisegiEgyseg,
            mennyiseg: '',
            keszleten: false
          });
          leltariv.cikkek.sort((cikk1, cikk2) => cikk1.cikkSzam.localeCompare(cikk2.cikkSzam));
        }
      });
      this.setState({
        leltariIvJson: leltarJSON
      }, () => {
        this.toggleCikk();
      });
    } catch (e) {
      this.props.route.globals.addError(e);
    }
  }

  editCikk = (event, cikkObj) => {
    let edited = this.state.cikkek.find(keszletcikk => keszletcikk.cikkid === this.state.editobj.cikkid);
    this.state.cikkek[this.state.cikkek.indexOf(edited)] = cikkObj;
    this.setState({ cikkek: this.state.cikkek, editmode: false });
    this.toggleCikk();
  }

  initBejelentesForm = () => {
    Services.getLeltarInit((err, res) => {
      if (!err) {
        this.setState({
          formLeltarModositasOptions: res[0].mod,
          formCardStatus: 'DATA'
        });
      }
    });
  }

  renderHelyszinOptions = () => {
    return this.state.formHelyszinOptions.map((item) => {
      return (
        <option
          value={item.id}
          key={item.id}
        >
          {item.nev}
        </option>
      );
    });
  }

  renderTipusOptions = () => {
    return this.state.formTipusOptions.map((item) => {
      return (
        <option
          value={item.id}
          key={item.id}
        >
          {item.nev + ' (' + item.hatarido + ' óra)'}
        </option>
      );
    });
  }

  getLeltar = (id) => {
    this.setState({
      formCardStatus: 'LOAD',
      leltariIvJson: []
    }, () => {
      Services.getLeltar(id, (err, res) => {
        if (!err) {
          let leltarivJSON = [];
          res[0].leltarIvEntityList.forEach((leltariv) => {
            let leltarivObj = {};
            leltarivObj.id = leltariv.id;
            leltarivObj.bizonylat = leltariv.bizonylat;
            leltarivObj.raktar = leltariv.raktar;
            leltarivObj.szemely = leltariv.szemely;
            leltarivObj.rogzszemelynev = leltariv.uvd.rogzszemelynev;
            leltarivObj.rogzidopont = leltariv.rogzidopont;
            leltarivObj.cikkek = [];
            leltariv.leltarIvCikkEntityList.forEach((cikk) => {
              leltarivObj.cikkek.push({
                parentid: leltariv.id,
                id: cikk.cikk.id,
                leltarivcikkid: cikk.id,
                megnevezes: cikk.cikk.megnevezes,
                cikkSzam: cikk.cikk.cikkszam,
                leltarMennyiseg: cikk.leltarMennyiseg,
                beszerzesiAr: cikk.beszerzesiAr,
                mennyisegiEgyseg: cikk.cikk.mennyisegiEgyseg,
                mennyiseg: cikk.eredetiMennyiseg,
                keszleten: cikk.keszleten,
                modUserNev: cikk.modUserNev
              });
            });
            leltarivJSON.push(leltarivObj);
          });
          this.setState({
            // A null érték is false lesz a "!!"" konvertálás miatt
            hasAllOldRaktarRoles: !!res[0].hasalloldraktarroles,
            formCardStatus: 'DATA',
            formFordulonap: MomentTimezone.tz(this.emptyOrNull(res[0].forduloDatum), this.tz).format('YYYY-MM-DD HH:mm:ss'),
            formBlokk: res[0].keszlet_blokk,
            formLeltarkezdete: res[0].leltarKezdete && MomentTimezone.tz(this.emptyOrNull(res[0].leltarKezdete), this.tz).format('YYYY-MM-DD HH:mm:ss'),
            formLeltarvege: res[0].leltarVege && MomentTimezone.tz(this.emptyOrNull(res[0].leltarVege), this.tz).format('YYYY-MM-DD HH:mm:ss'),
            formLeltarozasMod: res[0].mod && res[0].mod.name,
            formBizonylatSzam: res[0].bizonylat && res[0].bizonylat.bizonylatszam,
            formMegjegyzes: this.nullToString(res[0].megjegyzes),
            leltariIvJson: leltarivJSON,
          }, () => {
          });
          this.initBejelentesForm();
        }
      });
    });
  }

  addLeltar = (type) => {
    const {
      leltarId,
      modositas,
      formTipus,
      formMegjegyzes,
      formAllapot,
      formAllapotMegjegyzes,
      formFordulonap,
      formBlokk,
      formLeltarkezdete,
      formLeltarvege,
      formLeltarozasMod,
      existsSzervezet
    } = this.state;
    let submitObj = {};

    this.setState({ submitButtonLoading: true });

    // if (modositas) submitObj.id = this.emptyOrNull(leltarId);

    submitObj.megjegyzes = this.emptyOrNull(formMegjegyzes);
    submitObj.forduloDatum = formFordulonap ? MomentTimezone.tz(this.emptyOrNull(formFordulonap), this.tz).format('YYYY-MM-DDTHH:mm:ss.SSSZZ') : null;
    submitObj.keszlet_blokk = formBlokk;
    submitObj.leltarKezdete = formLeltarkezdete ? MomentTimezone.tz(this.emptyOrNull(formLeltarkezdete), this.tz).format('YYYY-MM-DDTHH:mm:ss.SSSZZ') : null;
    submitObj.leltarVege = formLeltarvege ? MomentTimezone.tz(this.emptyOrNull(formLeltarvege), this.tz).format('YYYY-MM-DDTHH:mm:ss.SSSZZ') : null;
    submitObj.mod = this.emptyOrNull(formLeltarozasMod);

    let leltarIvEntityList = [];

    this.state.leltariIvJson.forEach((leltariv) => {
      let leltarIvCikkEntityList = [];

      leltariv.cikkek.forEach((cikk) => {
        leltarIvCikkEntityList.push({
          cikkId: cikk.id,
          id: modositas ? cikk.leltarivcikkid : null,
          beszerzesiAr: cikk.keszleten ? null : cikk.beszerzesiAr,
          leltarMennyiseg: cikk.leltarMennyiseg,
          keszleten: cikk.keszleten
        });
      });
      leltarIvEntityList.push({
        id: modositas && !leltariv.felvitt ? leltariv.id : null,
        raktarId: leltariv.raktar && leltariv.raktar.id,
        szemelyId: existsSzervezet ? leltariv.szemely && leltariv.szemely.id : null,
        szemelyNev: existsSzervezet ? null : leltariv.szemely.nev,
        leltarIvCikkEntityList: leltarIvCikkEntityList
      });
    });

    submitObj.leltarIvEntityList = leltarIvEntityList;

    if (modositas) {
      submitObj.id = leltarId;
      Services.editLeltar(submitObj, this.state.leltarId, (err, res) => {
        if (!err) {
          this.setState({ submitButtonLoading: false });
          this.props.route.globals.addSuccess('Leltár módosítva!');
          this.onBack();
        } else {
          this.setState({ submitButtonLoading: false });
        }
      });
    } else {
      Services.addLeltar(submitObj, (err, res) => {
        if (!err) {
          this.setState({ submitButtonLoading: false });
          this.props.route.globals.addSuccess('Leltár felvéve!');
          this.onBack();
        } else {
          this.setState({ submitButtonLoading: false });
        }
      });
    }
  }

  emptyOrNull = (data) => {
    if (!data || data === '') return null;
    return data;
  }

  nullToString = (data) => {
    if (!data) return '';
    return data;
  }

  emptyToZero = (data) => {
    if (!data || data === '') return 0;
    return data;
  }

  onBack = () => {
    // browserHistory.push({
    //   pathname: this.props.route.globals.public_url.concat('/keszlet/keszletkezeles?page=6'),
    //   state: {
    //     lek: this.state.lek
    //   }
    // });
    browserHistory.push("".concat('/keszlet/keszletkezeles?page=6'));
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  filterTree = (cikkFilter, cikkTreeParam) => {
    let cikkTree = cikkTreeParam;
    if (!cikkTree) cikkTree = JSON.parse(JSON.stringify(this.state.cikkTree));
    let filteredTree = [];
    cikkTree.forEach((cikkcsoport) => {
      let cikkek = [];
      cikkcsoport.cikkek.forEach((cikk) => {
        if (cikk.name.toLowerCase().includes(cikkFilter.toLowerCase())) {
          cikkek.push(cikk);
        }
      });
      if (cikkek.length > 0) {
        cikkcsoport.cikkek = cikkek;
        cikkcsoport.children = cikkek;
        cikkcsoport.isExpanded = true;
        filteredTree.push(cikkcsoport);
      }
    });
    return filteredTree;
  }

  handleCikkFilter = (e, cikkTree) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    if (true) { // value.length > 1
      let result = this.filterTree(value, cikkTree);
      this.setState({
        [name]: value,
        cikkFilteredTree: result
      });
    } else {
      this.setState({
        [name]: value
      });
    }
  }

  handleOraInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value,
      formHatarido: Moment().add(value, 'hours')
    });
  }

  handleTipusInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    let hours = 0;

    this.state.formTipusOptions.forEach((tipus) => {
      if (tipus.id == value) hours = tipus.hatarido;
    });

    this.setState({
      [name]: value,
      formOra: hours,
      formHatarido: Moment().add(hours, 'hours')
    });
  }

  handleDateInputChange = (e, v, name) => {
    const value = v === '' ? null : MomentTimezone.tz(e, this.tz).format('YYYY-MM-DD HH:mm:ss');
    this.setState({
      [name]: value
    });
  }

  getCorrectTextColor = (hex) => {
    /*
    From this W3C document: http://www.webmasterworld.com/r.cgi?f=88&d=9769&url=http://www.w3.org/TR/AERT#color-contrast

    Color brightness is determined by the following formula:
    ((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000

    I know this could be more compact, but I think this is easier to read/explain.

    */

    let threshold = 130; /* about half of 256. Lower threshold equals more dark text on dark background  */

    function cutHex(h) { return (h.charAt(0) === '#') ? h.substring(1, 7) : h; }
    function hexToR(h) { return parseInt((cutHex(h)).substring(0, 2), 16); }
    function hexToG(h) { return parseInt((cutHex(h)).substring(2, 4), 16); }
    function hexToB(h) { return parseInt((cutHex(h)).substring(4, 6), 16); }

    let hRed = hexToR(hex);
    let hGreen = hexToG(hex);
    let hBlue = hexToB(hex);

    let cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
    if (cBrightness > threshold) { return '#000000'; } return '#ffffff';
  }

  allapotFormatter = (cell, row) => {
    return (
      <span style={{
        color: this.getCorrectTextColor('#' + row.szin)
      }}
      >
        {cell}
      </span>
    );
  }

  timezoneToDate = (date) => {
    if (!date || date === '') return null;
    return MomentTimezone.tz(date, this.tz).format('lll:ss');
  }

  renderTable = () => {
    const columns = [
      {
        dataField: 'mod',
        text: 'Módosítás időpontja',
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
        },
        formatter: this.timezoneToDate,
        sort: false
      },
      {
        dataField: 'allapot',
        text: 'Állapot',
        style: (cell, row, rowIndex, colIndex) => {
          return {
            wordBreak: 'break-all',
            whiteSpace: 'pre-line',
            backgroundColor: '#' + row.szin
          };
        },
        formatter: this.allapotFormatter,
        sort: false
      },
      {
        dataField: 'moduser',
        text: 'Módosító',
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
        },
        sort: false
      },
      {
        dataField: 'megjegyzes',
        text: 'Megjegyzés',
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
        },
        sort: false
      },
      {
        dataField: 'id',
        text: '-',
        hidden: true
      }
    ];

    return (
      <div className="animated fadeIn">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={this.state.baseJson}
          columns={columns}
          wrapperClasses="table-responsive"
          classes="table-scroll"
          headerClasses="table-th"
          filter={filterFactory()}
          // pagination={paginationFactory(options)}
          noDataIndication="Nincs megjelenítendő adat!"
        />
      </div>
    );
  }

  renderLeltarModositasOptions = () => {
    return this.state.formLeltarModositasOptions.map((item) => {
      return (
        <option
          value={item.name}
          key={item.name}
        >
          {item.caption}
        </option>
      );
    });
  }

  renderFormCardLogic = () => {
    const {
      modositas,
      formTipus,
      formAzonosito,
      formHelyszin,
      formHatarido,
      formOra,
      formBejelento,
      formTelefon,
      formEmail,
      formBejelentes,
      formMegjegyzes,
      formAllapot,
      formLeltarozasMod,
      formBizonylatSzam,
      formFordulonap,
      formLeltarkezdete,
      formLeltarvege
    } = this.state;

    const titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };

    return (
      <React.Fragment>
        {/* <div className="row">
          <div className="col-md-12">
            <TreeView
              data={this.state.data}
              onUpdateCb={(updatedData)=>{
                this.setState({data: updatedData})
              }}
              isCheckable={(node, depth)=>{
                // hide checkbox for all nodes of depth 1
                // or for node with the id of `1`
                // NOTE: The highest depth is 0, node children are depth+1
                if (depth === 0) {
                  return false;
                } else {
                  return true;
                }
              }}
              onCheckToggleCb={(arrayOfNodes, depth)=>{
                const nodeCount = arrayOfNodes.length;
                const status = (arrayOfNodes[0].isChecked)? 'checked': 'unchecked';
                alert(`${nodeCount} node(s) will be ${status} `);
              }}
            />
          </div>
            </div> */}
        <div className="row">
          <div className="col-md-3">
            <Label for="formFordulonap">
              Fordulónap: *
              <CustomTooltip text={getTooltip('formfordulonap')} id="formfordulonapTT" />
            </Label>
            <DateTimePicker
              containerClassName="organw-date-time"
              format="YYYY-MM-DD HH:mm:ss"
              time
              messages={{ dateButton: 'dátum', timeButton: 'idő' }}
              onChange={(e, v) => this.handleDateInputChange(e, v, 'formFordulonap')}
              value={formFordulonap && new Date(formFordulonap)}
              name="formFordulonap"
              id="formFordulonap"
              disabled={!this.hasRoleKarb || (this.state.leltariIvJson && this.state.leltariIvJson.length !== 0) || !this.state.hasAllOldRaktarRoles}
            />
          </div>
          <div className="col-md-3">
            <Label for="formLeltarkezdete">
              Leltár kezdete:
              <CustomTooltip text={getTooltip('formkezdet')} id="formkezdetTT" />
            </Label>
            <DateTimePicker
              containerClassName="organw-date-time"
              format="YYYY-MM-DD HH:mm:ss"
              time
              messages={{ dateButton: 'dátum', timeButton: 'idő' }}
              onChange={(e, v) => this.handleDateInputChange(e, v, 'formLeltarkezdete')}
              value={formLeltarkezdete && new Date(formLeltarkezdete)}
              name="formLeltarkezdete"
              id="formLeltarkezdete"
              disabled={!this.hasRoleKarb || !this.state.formBlokk || !this.state.hasAllOldRaktarRoles}
            />
          </div>
          <div className="col-md-3">
            <Label for="formLeltarvege">
              Leltár vége:
              <CustomTooltip text={getTooltip('formvege')} id="formvegeTT" />
            </Label>
            <DateTimePicker
              containerClassName="organw-date-time"
              format="YYYY-MM-DD HH:mm:ss"
              time
              messages={{ dateButton: 'dátum', timeButton: 'idő' }}
              onChange={(e, v) => this.handleDateInputChange(e, v, 'formLeltarvege')}
              value={formLeltarvege && new Date(formLeltarvege)}
              name="formLeltarvege"
              id="formLeltarvege"
              disabled={!this.hasRoleKarb || !this.state.formBlokk || !this.state.hasAllOldRaktarRoles}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <AvGroup>
              <Label for="formLeltarozasMod">
                Leltározás módja: *
                <CustomTooltip text={getTooltip('formleltarmod')} id="formleltarmodTT" />
              </Label>
              <AvInput
                type="select"
                name="formLeltarozasMod"
                id="formLeltarozasMod"
                // required
                value={formLeltarozasMod}
                disabled={this.state.modositas || !this.state.hasAllOldRaktarRoles}
                onChange={this.handleInputChange}
              >
                <option value="">Válassz módot...</option>
                {this.renderLeltarModositasOptions()}
              </AvInput>
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
          {modositas && (
            <div className="col-md-3">
              <AvGroup>
                <Label for="formBizonylatSzam">Bizonylatszám:</Label>
                <AvInput
                  id="formBizonylatSzam"
                  name="formBizonylatSzam"
                  value={formBizonylatSzam}
                  disabled
                />
              </AvGroup>
            </div>
          )}
          <div className="col-md-3">
            <Label check style={{ marginTop: '35px' }}>
              <Input
                type="checkbox"
                id="blokk"
                name="blokk"
                checked={this.state.formBlokk}
                style={{ marginTop: '5px' }}
                onChange={(val) => { this.setState({ formBlokk: val.target.checked }); }}
                disabled={!this.hasRoleKarb || !this.state.hasAllOldRaktarRoles}
              />
              {' '}
              <span style={{ paddingLeft: '17px' }}>
                Forgalom blokkolása
                <CustomTooltip text={getTooltip('formblokk')} id="formblokkTT" />
              </span>
            </Label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <AvGroup>
              <Label for="formMegjegyzes">
                Megjegyzés:
              </Label>
              <AvInput
                type="text"
                name="formMegjegyzes"
                id="formMegjegyzes"
                maxLength="350"
                value={formMegjegyzes}
                onChange={this.handleInputChange}
                disabled={!this.hasRoleKarb || !this.state.hasAllOldRaktarRoles}
              />
              <AvFeedback>
                Kötelező mező!
              </AvFeedback>
            </AvGroup>
          </div>
        </div>
        {this.hasRoleKarb && (
          <div className="row">
            <div className="col-md-12">
              <Button
                type="button"
                color="success"
                onClick={() => {
                  this.setState({
                    formRaktar: '',
                    formRaktarOptions: [],
                    formFelelos: '',
                    formFelelosNev: null,
                    formFelelosOptions: [],
                    formCikkKereses: '',
                    cikkTree: [],
                    cikkFilteredTree: []
                  }, () => {
                    this.toggleLeltariv('Új leltárív');
                  });
                }}
              >
                Új leltárív
              </Button>
            </div>
          </div>
        )}
        <CikkSelect
          isOpen={this.state.modalCikk}
          toggle={this.toggleCikk}
          add={this.addCikk}
          cikkek={this.state.cikkek}
          editmode={this.state.editmode}
          editobj={this.state.editobj}
          edit={this.editCikk}
          globals={this.props.route.globals}
          price
          raktarId={this.state.currentRaktarId}
          fromLeltar
          excludeCikkIds={this.state.excludeCikkIds}
          ref={this.cikkSelectRef}
        />
      </React.Fragment>
    );
  }

  renderFormCard = () => {
    const cardStyle = {
      padding: 20
    };

    return (
      <div className="animated fadeIn">
        <div className="card">
          <div className="card-header">
            {this.state.formTitle}
          </div>
          <AvForm onValidSubmit={() => {}}>
            <div style={cardStyle}>
              {{
                LOAD: (<div className="col-md-12"><Loader /></div>),
                NETW: (<NetworkEmptyState
                  hasImage
                  submitClick={() => this.getBejelentes(this.state.leltarId)}
                />),
                DATA: (this.renderFormCardLogic())
              }[this.state.formCardStatus]}
            </div>
          </AvForm>
          <div style={{ paddingBottom: 20, paddingLeft: 20, paddingRight: 20, paddingTop: 0 }} >
            <div className="row">
              <div className="col-md-12">
                {this.state.leltariIvJson.length > 0 && this.renderLeltarIvTable()}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12" style={{ marginTop: 16 }}>
                <ProgressButton
                  className="btn-cursor-pointer"
                  color="primary"
                  loading={this.state.submitButtonLoading}
                  onClick={() => this.addLeltar('NORMAL')}
                >
                  {'Mentés'}
                </ProgressButton>
                {' '}
                {/* {this.state.modositas && (
                  <React.Fragment>
                    {Lib.KeyCloak.hasRoleSpec(this.props.route.globals.keycloak, ['KESZLETNYILV_LELTAR_KORREKCIO']) && (
                      <ProgressButton
                        className="btn-cursor-pointer"
                        color="success"
                        loading={this.state.submitButtonLoading}
                        onClick={() => { this.toggleKorrekcioModal(); }}
                      >
                        {'Korrekció'}
                      </ProgressButton>
                    )}
                    {' '}
                  </React.Fragment>
                )} */}
                <Button type="button" color="secondary" onClick={() => { this.onBack(); }}>Vissza</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleRaktarChange = (value) => {
    if (value && value.length !== 0) {
      this.setState({ formRaktar: value, hasMennyisegCheckbox: false });
      let excludecikkids = [];
      this.state.leltariIvJson.forEach((leltariv) => {
        leltariv.cikkek.forEach((cikk) => {
          excludecikkids.push(cikk.id);
        });
      });
      Services.getLeltarCikk(value.id, excludecikkids, this.state.formFordulonap && new Date(this.state.formFordulonap), (err, res) => {
        if (!err) {
          let cikkTree = res[0];
          cikkTree.forEach((cikkcsoport) => {
            cikkcsoport.isExpanded = true;
            cikkcsoport.name = cikkcsoport.nev;
            cikkcsoport.cikkek.forEach((cikk) => {
              cikk.name = cikk.megnevezes;
            });
            cikkcsoport.children = cikkcsoport.cikkek;
          });
          this.setState({
            cikkTree,
            cikkFilteredTree: cikkTree
          });
        }
      });
    } else {
      this.setState({ formRaktar: '' });
    }
  }

  handleFelelosChange = (value) => {
    if (value && value.length !== 0) {
      this.setState({ formFelelos: value });
    } else {
      this.setState({ formFelelos: '' });
    }
  }

  handleFelelosNevChange = (nev) => {
    if (!nev) {
      this.setState({ formFelelosNev: null });
    } else {
      this.setState({ formFelelosNev: { value: nev, label: nev } });
    }
  }

  toggleCikkCheck = (cikkid) => {
    this.setState((prevState) => {
      let { cikkTree } = prevState;
      cikkTree.forEach((cikkcsoport) => {
        cikkcsoport.cikkek.forEach((cikk) => {
          if (cikk.id == cikkid) {
            cikk.isChecked = !cikk.isChecked;
          }
        });
      });
      return { cikkTree };
    });
  }

  handleCikkekClick = (id) => {
    if (!this.state.expanded.includes(id)) {
      this.setState(() => ({
        expanded: [id]
      }));
    } else {
      this.setState(() => ({
        expanded: []
      }));
    }
  }

  handleEditClick = (leltariv) => {
    if(this.state.existsSzervezet){
      Services.listSzemely((err, res) => {
        if (!err) {
          this.setState({
            currentEditId: leltariv.id,
            formFelelos: leltariv.szemely,
            formFelelosOptions: res[0]
          }, () => {
            this.toggleEditModal();
          });
        }
      });
    }else{
      this.setState({
        currentEditId: leltariv.id,
        formFelelosNev: { value: leltariv.szemely.nev, label: leltariv.szemely.nev},
      }, () => {
        this.toggleEditModal();
      });
    }
  }

  delete = () => {
    if (this.state.deleteObj.type === 'LELTARIV') {
      let array = this.state.leltariIvJson.filter((item) => {
        return item.id !== this.state.deleteObj.id;
      });

      this.setState({
        leltariIvJson: array
      }, () => {
        this.toggleDeleteModal();
      });
    } else if (this.state.deleteObj.type === 'CIKK') {
      let leltariIvJson = JSON.parse(JSON.stringify(this.state.leltariIvJson));
      leltariIvJson.forEach((leltariv) => {
        if (leltariv.id == this.state.deleteObj.parentid) {
          leltariv.cikkek = leltariv.cikkek.filter((item) => {
            return item.id !== this.state.deleteObj.id;
          });
        }
      });

      this.setState({
        leltariIvJson: leltariIvJson
      }, () => {
        this.toggleDeleteModal();
      });
    }
  }

  handleLeltarIvDelete = (id) => {
    this.setState({
      deleteObj:
        {
          type: 'LELTARIV',
          id: id
        }
    }, () => {
      this.toggleDeleteModal();
    });
  }

  handleLeltarCikkDelete = (id, row) => {
    this.setState({
      deleteObj:
        {
          type: 'CIKK',
          id: id,
          parentid: row.parentid
        }
    }, () => {
      this.toggleDeleteModal();
    });
  }
  
  formatCreateLabel = (input) => {
    return <span><i>Hozzáadás: </i> {input}</span>
  };

  createFelelos = (nev) => {
      let array = this.state.felelosok.filter(felelos => felelos != nev);
      array.push(nev);
      this.setState({
        felelosok: array,
        formFelelosNev: { value: nev, label: nev },
      });
  }

  leltarIvFormatter = (cell, row) => {
    return (
      <div>
        {
          <React.Fragment>
            {/* <Button className="icon-eye table-action-icon" id={'action-view-' + cell + 'view'} onClick={() => {}} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'view'} delay={50}>
              {'Megtekintés'}
            </UncontrolledTooltip> */}
            <Button className="fa fa-list table-action-icon" id={'action-view-' + cell + 'cikk'} onClick={() => this.handleCikkekClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'cikk'} delay={50}>
              {'Cikkek'}
            </UncontrolledTooltip>
            {this.hasRoleKarb && (
              <React.Fragment>
                <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => { this.handleEditClick(row); }} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'modositas'} delay={50}>
                  {'Módosítás'}
                </UncontrolledTooltip>
                {/* <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => this.handleEditClick(cell)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'modositas'} delay={50}>
                  {'Módosítás'}
                </UncontrolledTooltip> */}
                <Button className="icon-trash table-action-icon" id={'action-delete-' + cell + 'leltariv'} onClick={() => this.handleLeltarIvDelete(cell)} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-delete-' + cell + 'leltariv'} delay={50}>
                  {'Törlés'}
                </UncontrolledTooltip>
              </React.Fragment>
            )}
            {(this.hasRoleMennyRogz || this.hasRoleKarb) && (
              <React.Fragment>
                <Button
                  className="fa fa-plus table-action-icon"
                  id={'action-add-' + cell + 'cikk'}
                  onClick={() => {
                    this.cikkSelectRef.current.clearValues();
                    this.setState((prevState) => {
                      let leltarIv = prevState.leltariIvJson.find(iv => iv.id == cell);
                      return {
                        currentIv: cell,
                        currentRaktarId: row.raktar.id,
                        excludeCikkIds: leltarIv.cikkek && leltarIv.cikkek.map(cikk => cikk.id)
                      };
                    }, () => {
                      this.toggleCikk();
                    });
                  }}
                />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-add-' + cell + 'cikk'} delay={50}>
                  {'Cikk hozzáadása'}
                </UncontrolledTooltip>
              </React.Fragment>
            )}
          </React.Fragment>
      }
      </div>
    );
  }

  leltarCikkFormatter = (cell, row) => {
    return (
      <div>
        {this.hasRoleKarb && (
          <React.Fragment>
            <Button className="icon-trash table-action-icon" id={'action-delete-' + cell + 'leltarcikk'} style={{ backgroundColor: 'transparent', borderColor: 'transparent' }} onClick={() => { this.handleLeltarCikkDelete(cell,row) }} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-delete-' + cell + 'leltarcikk'} delay={50}>
              {'Törlés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  onInputBlur = (leltariIvJson, leltarivid, cikkid, value) => {
    let leltariv = leltariIvJson.find(x => x.id == leltarivid);
    leltariv.cikkek.find(x => x.id == cikkid).leltarMennyiseg = value;
    this.setState({ selectedIndex: null });
  }

  editFormatter = (cell, row, rowIndex, formatExtraData) => {
    if (rowIndex === formatExtraData.selectedIndex) {
      return (
        <input
          onBlur={() => {this.onInputBlur(formatExtraData.leltariIvJson, formatExtraData.expanded[0], row.id, formatExtraData.inputValue)}}
          ref={this.textInputRef}
          value={formatExtraData.inputValue || ''}
          onChange={(e) => {this.setState({ inputValue: e.target.value || '' })}}
        />
      );
    }
    return cell;
  };

  setSelectedRow = (index) => {
    let cikk = this.state.leltariIvJson.find(x => x.id == this.state.expanded[0]).cikkek[index];
    this.setState(
      {
        selectedIndex: index,
        inputValue: cikk.leltarMennyiseg
      },
      () => {
        this.resetTable();
      }
    );
  };

  resetTable = () => {
    this.setState({ tableReseted: false }, () => {
      this.setState({ tableReseted: true }, () => {
        if (this.textInputRef.current) {
          this.textInputRef.current.focus();
        }
      });
    });
  };

  handleKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      let shift = event.shiftKey;
      let leltariv;
      if (this.state.selectedIndex !== null) {
        leltariv = this.state.leltariIvJson.find(x => x.id == this.state.expanded[0]);
        leltariv.cikkek[this.state.selectedIndex].leltarMennyiseg = this.state.inputValue;
        this.setState({ inputValue: null });
      }
      if (this.state.selectedIndex === null) {
        this.setState({ selectedIndex: 0 }, () => {
          this.tabNavCallback();
          this.resetTable();
        });
      } else if (this.state.selectedIndex + 1 == leltariv.cikkek.length && !event.shiftKey) {
        this.setState({ selectedIndex: 0 }, () => {
          this.tabNavCallback();
          this.resetTable();
        });
      } else if (this.state.selectedIndex == 0 && event.shiftKey) {
        this.setState({ selectedIndex: leltariv.cikkek.length - 1}, () => {
          this.tabNavCallback();
          this.resetTable();
        });
      } else {
        this.setState(
          prevState => ({
            selectedIndex: prevState.selectedIndex + (shift ? -1 : 1),
          }),
          () => {
            this.tabNavCallback();
            this.resetTable();
          }
        );
      }
    }
  };

  tabNavCallback = () => {
    let leltariv = this.state.leltariIvJson.find(x => x.id == this.state.expanded[0]);
    let cikk = leltariv.cikkek[this.state.selectedIndex];
    this.setState({ inputValue: cikk.leltarMennyiseg });
  }

  renderLeltarIvTable = () => {
    const options = {
      sizePerPage: 25,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const optionsCikk = {
      sizePerPage: 50,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '50', value: 50 }
      ],
    };

    const { existsSzervezet } = this.state;

    const columns = [
      {
        dataField: 'bizonylat.bizonylatszam',
        text: 'Bizonylatszám',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'raktar.nev',
        text: 'Raktár',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'szemely.nev',
        text: 'Felelős',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'rogzszemelynev',
        text: 'Rögzítő személy',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true,
      },
      {
        dataField: 'rogzidopont',
        text: 'Rögzítési időpont',
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true,
        formatter: rogzidopont => rogzidopont && MomentTimezone.tz(rogzidopont, this.tz).format('YYYY.MM.DD HH:mm:ss')
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        formatter: this.leltarIvFormatter
      }
    ];

    let childColumns = [
      {
        dataField: 'cikkSzam',
        text: 'Cikkszám',
        style: {
          whiteSpace: 'pre-line'
        },
        editable: false
      },
      {
        dataField: 'megnevezes',
        text: 'Megnevezés',
        style: {
          whiteSpace: 'pre-line'
        },
        editable: false
      },
      {
        dataField: 'mennyisegiEgyseg',
        text: 'Mennyiségi egység',
        style: {
          whiteSpace: 'pre-line'
        },
        align: 'center',
        editable: false
      },
      this.state.formLeltarozasMod !== 'VAK' ? {
        dataField: 'mennyiseg',
        text: 'Mennyiség',
        style: {
          whiteSpace: 'pre-line'
        },
        align: 'right',
        editable: false,
        headerFormatter: (column, colIndex, components) => <>{column.text}{components.sortElement}<CustomTooltip text={getTooltip('cikkmennyiseg')} id="cikkmennyisegTT" />{components.filterElement}</>,
      } : {},
      {
        dataField: 'leltarMennyiseg',
        text: 'Leltározott mennyiség',
        formatter: this.editFormatter,
        formatExtraData: this.state,
        validator: (newValue, row, column) => {
          if (isNaN(newValue)) {
            return {
              valid: false,
              message: 'A mező csak számot tartalmazhat.'
            };
          }
          return true;
        },
        align: 'right',
        events: {
          onClick: (e, c, ci, r, rowIndex) => { this.setSelectedRow(rowIndex); }
        },
        headerFormatter: (column, colIndex, components) => <>{column.text}{components.sortElement}<CustomTooltip text={getTooltip('cikkleltarozott')} id="cikkleltarozottTT" />{components.filterElement}</>,
        // filter: textFilter({
        //   delay: 400, // default is 500ms
        //   placeholder: 'Keresés...',
        // }),
        // style: {
        //   whiteSpace: 'pre-line'
        // }
        // editable: true
      },
      {
        dataField: 'modUserNev',
        text: 'Módosító személy',
        style: {
          whiteSpace: 'pre-line'
        },
        editable: false
      },
    ];
    if (this.hasRoleKarb) {
      childColumns = [
        ...childColumns,
        {
          dataField: 'id',
          text: 'Műveletek',
          formatter: this.leltarCikkFormatter
        }
      ];
    }

    let child = childColumns.filter(item => Object.keys(item).length);

    const expandRow = {
      renderer: row => (row.cikkek ?
        (
          <React.Fragment>
            <h5>Cikkek:</h5>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={row.cikkek}
              columns={child}
              // cellEdit={cellEditFactory({
              //   mode: 'click',
              //   blurToSave: true
              // })
              // }
              filter={filterFactory()}
              pagination={paginationFactory(optionsCikk)}
              noDataIndication="Nincs megjelenítendő adat!"
            />
          </React.Fragment>
        )
        : null
      ),
      expanded: this.state.expanded,
      expandByColumnOnly: true
    };

    return (
      <div className="animated fadeIn" onKeyDown={this.handleKeyDown}>
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={this.state.leltariIvJson}
          columns={columns}
          filter={filterFactory()}
          pagination={paginationFactory(options)}
          expandRow={expandRow}
          noDataIndication="Nincs megjelenítendő adat!"
        />
      </div>
    );
  }

  uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
  }

  submitLeltariv = () => {
    if (!this.emptyOrNull(this.state.formRaktar) || (this.state.existsSzervezet && !this.emptyOrNull(this.state.formFelelos)) || (!this.state.existsSzervezet && (!this.state.formFelelosNev || this.state.formFelelosNev.label === ''))) {
      this.setState({
        isAlertOpen: true
      });
    } else {
      let cikkTree = JSON.parse(JSON.stringify(this.state.cikkTree));
      let cikkek = [];
      let uuid = this.uuidv4();

      cikkTree.forEach((cikkcsoport) => {
        cikkcsoport.cikkek.forEach((cikk) => {
          if (cikk.isChecked) {
            cikkek.push({
              id: cikk.id,
              parentid: uuid,
              megnevezes: cikk.megnevezes,
              cikkSzam: cikk.cikkSzam,
              leltarMennyiseg: '',
              beszerzesiAr: '',
              mennyisegiEgyseg: cikk.mennyisegiEgyseg,
              mennyiseg: cikk.mennyiseg,
              keszleten: true
            });
          }
        });
      });

      let leltarivObj = {
        id: uuid,
        felvitt: true,
        raktar: this.state.formRaktar,
        szemely: this.state.existsSzervezet ? this.state.formFelelos : {nev : this.state.formFelelosNev.label},
        cikkek: cikkek
      };

      this.setState(prevState => ({
        leltariIvJson: [...prevState.leltariIvJson, leltarivObj]
      }), () => {
        this.toggleLeltariv('');
      });
    }
  }

  cikkcsoportFunc = (cikkcsoport, prevState) => {
    let cikkcsoportUnticked = false;
    cikkcsoport.children.forEach((cikk) => {
      if (cikk.mennyiseg > 0) {
        cikk.isChecked = !prevState.hasMennyisegCheckbox;
        if (prevState.hasMennyisegCheckbox) cikkcsoportUnticked = true;
      }
    });
    cikkcsoport.cikkek.forEach((cikk) => {
      if (cikk.mennyiseg > 0) {
        cikk.isChecked = !prevState.hasMennyisegCheckbox;
        if (prevState.hasMennyisegCheckbox) cikkcsoportUnticked = true;
      }
    });
    // Ha valamelyik cikktől el lett véve a pipa, akkor a cikkcsoporton is törli
    if (cikkcsoportUnticked) cikkcsoport.isChecked = false;
  }

  handleCheckAllMennyisegToggle = () => {
    this.setState((prevState) => {
      let cikkTree = JSON.parse(JSON.stringify(prevState.cikkTree));
      let cikkFilteredTree = JSON.parse(JSON.stringify(prevState.cikkFilteredTree));
      cikkTree.forEach(cikkcsoport => this.cikkcsoportFunc(cikkcsoport, prevState));
      cikkFilteredTree.forEach(cikkcsoport => this.cikkcsoportFunc(cikkcsoport, prevState));
      return { cikkTree, cikkFilteredTree, hasMennyisegCheckbox: !prevState.hasMennyisegCheckbox };
    });
  }

  render() {
    const {
      formModalBankszamlaszam,
      formModalPenzintezet,
      formModalIbanszam,
      formRaktar,
      formRaktarOptions,
      formFelelos,
      formFelelosNev,
      formFelelosOptions,
      formCikkKereses,
      isAlertOpen,
      isAlertEditOpen,
      existsSzervezet,
      felelosok,
    } = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            {this.renderFormCard()}
          </div>
        </div>
        <Modal isOpen={this.state.modalLeltariv} toggle={() => this.toggleLeltariv('')} backdrop="static" size="lg">
          <AvForm onValidSubmit={this.submitLeltariv}>
            <ModalHeader>{this.state.modalLeltarivTitle}</ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  <Alert color="danger" isOpen={isAlertOpen} toggle={this.toggleAlert}>
                    Raktár és felelős kitöltése kötlező!
                  </Alert>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Label for="raktar">
                    {'Raktár: *'}
                  </Label>
                  <Select
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        '&:focus': { borderColor: 'red' }, // border style on hover
                        border: '1px solid lightgray', // default border color
                        boxShadow: 'none', // no box-shadow
                        borderRadius: '0'
                      }),
                    }}
                    name="raktar"
                    id="raktar"
                    placeholder="Kezdjen el gépelni a kereséshez..."
                    noOptionsMessage={() => 'Nincs keresési eredmény'}
                    searchPromptText="Kezdjen el gépelni a kereséshez..."
                    loadingMessage={() => 'Keresés...'}
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={v => this.handleRaktarChange(v)}
                    getOptionLabel={option => option.nev}
                    getOptionValue={option => option.id}
                    value={formRaktar}
                    // isDisabled={isDisabled}
                    // isLoading={isLoading}
                    isClearable
                    isSearchable
                    options={formRaktarOptions}
                  />
                </div>
                <div className="col-md-6">
                  <Label for="felelos">
                    {'Felelős: *'}
                  </Label>
                  {existsSzervezet ?
                    <Select
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          '&:focus': { borderColor: 'red' }, // border style on hover
                          border: '1px solid lightgray', // default border color
                          boxShadow: 'none', // no box-shadow
                          borderRadius: '0'
                        }),
                      }}
                      name="felelos"
                      id="felelos"
                      placeholder="Kezdjen el gépelni a kereséshez..."
                      noOptionsMessage={() => 'Nincs keresési eredmény'}
                      searchPromptText="Kezdjen el gépelni a kereséshez..."
                      loadingMessage={() => 'Keresés...'}
                      className="basic-single"
                      classNamePrefix="select"
                      onChange={v => this.handleFelelosChange(v)}
                      getOptionLabel={option => option.nev}
                      getOptionValue={option => option.id}
                      value={formFelelos}
                      // isDisabled={isDisabled}
                      // isLoading={isLoading}
                      isClearable
                      isSearchable
                      options={formFelelosOptions}
                    />
                  : (
                    <CreatableSelect
                      styles={{
                        control: (base, state) => ({
                          ...base,
                          '&:focus': { borderColor: 'red' }, // border style on hover
                          border: '1px solid lightgray', // default border color
                          boxShadow: 'none', // no box-shadow
                          borderRadius: '0'
                        }),
                      }}
                      name="formFelelosNev"
                      id="formFelelosNev"
                      placeholder="Kezdjen el gépelni a kereséshez..."
                      noOptionsMessage={() => 'Nincs keresési eredmény'}
                      searchPromptText="Kezdjen el gépelni a kereséshez..."
                      loadingMessage={() => 'Keresés...'}
                      formatCreateLabel={this.formatCreateLabel}
                      onCreateOption={this.createFelelos}
                      onChange={this.handleFelelosNevChange}
                      getOptionLabel={option => option.label || option}
                      getOptionValue={option => option.value || option}
                      value={formFelelosNev}
                      isClearable
                      isSearchable
                      options={felelosok}
                    />
                    )}
                </div>
              </div>
              <div className="row" style={{ marginTop: 10 }}>
                <div className="col-md-12">
                  <AvGroup>
                    <Label for="formCikkKereses">
                      Cikkek:
                    </Label>
                    <AvInput
                      type="text"
                      name="formCikkKereses"
                      id="formCikkKereses"
                      autoComplete="off"
                      placeholder="Kezdjen el gépelni a kereséshez..."
                      maxLength="350"
                      value={formCikkKereses}
                      onChange={e => this.handleCikkFilter(e)}
                      // disabled={this.props.type === 'SZERK'}
                    />
                    <AvFeedback>
                      Kötelező mező!
                    </AvFeedback>
                  </AvGroup>
                </div>
              </div>
              <div className="row">
                {this.state.cikkFilteredTree && this.state.cikkFilteredTree.length > 0 && (
                  <>
                    <div style={{ paddingLeft: 15, paddingTop: 2 }}>
                      <input
                        type="checkbox"
                        name={"hasMennyisegCheckbox"}
                        onClick={() => {
                          this.handleCheckAllMennyisegToggle();
                        }}
                        checked={this.state.hasMennyisegCheckbox}
                        id={"hasMennyisegCheckbox"}
                      />
                    </div>
                    <label
                        htmlFor={"hasMennyisegCheckboxLabel"}
                        title={"Minden cikk a raktárban, ahol az aktuális készlet > 0"}
                        style={{ paddingLeft: 5 }}
                      >
                        Minden cikk a raktárban, ahol az aktuális készlet &gt; 0
                      </label>
                  </>
                )}
                <div className="col-md-12">
                  <TreeView
                    data={this.state.cikkFilteredTree}
                    onUpdateCb={(updatedData) => {
                      this.setState({ cikkFilteredTree: updatedData });
                    }}
                    isCheckable={(node, depth) => {
                      // hide checkbox for all nodes of depth 1
                      // or for node with the id of `1`
                      // NOTE: The highest depth is 0, node children are depth+1
                      if (depth === 2) {
                        return false;
                      } else {
                        return true;
                      }
                    }}
                    onCheckToggleCb={(node, arrayOfNodes, depth) => {
                      const nodeCount = arrayOfNodes.length;
                      const status = (arrayOfNodes[0].isChecked) ? 'checked' : 'unchecked';
                      // alert(`${nodeCount} node(s) will be ${status} ${JSON.stringify(arrayOfNodes[0])} `);
                      if (Array.isArray(arrayOfNodes[0].children)) {
                        this.setState((prevState) => {
                          const checkState = arrayOfNodes[0].isChecked;
                          let cikkTree = JSON.parse(JSON.stringify(prevState.cikkTree));

                          cikkTree.forEach((cikkcsoport) => {
                            if (cikkcsoport.id == node.id) {
                              cikkcsoport.isChecked = checkState;
                              node.cikkek.forEach((cikkNode) => {
                                cikkcsoport.cikkek.forEach((cikk) => {

                                  if (cikk.id == cikkNode.id) {
                                    cikk.isChecked = checkState;
                                  }
                                });
                                cikkcsoport.children.forEach((cikk) => {

                                  if (cikk.id == cikkNode.id) {
                                    cikk.isChecked = checkState;
                                  }
                                });
                              });
                            }
                          });
                          this.handleCikkFilter({
                            target: {
                              type: 'text',
                              value: prevState.formCikkKereses,
                              name: 'formCikkKereses'
                            }
                          }, cikkTree);
                          return { cikkTree };
                        });
                      } else {
                        this.toggleCikkCheck(arrayOfNodes[0].id);
                      }
                    }}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <ProgressButton color="primary" loading={false}>{'Hozzáadás'}</ProgressButton>
              <Button type="button" color="secondary" onClick={() => this.toggleLeltariv('')}>Mégsem</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
        <Modal isOpen={this.state.modalDelete} toggle={this.toggleDeleteModal} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>Valóban törölni kívánja a kiválasztott tételt?</ModalBody>
          <ModalFooter>
            <ProgressButton
              className="btn-cursor-pointer"
              color="primary"
              onClick={() => this.delete()}
              loading={this.state.deleteButtonLoading}
            >
              {'Igen'}
            </ProgressButton>
            <Button type="button" color="secondary" onClick={this.toggleDeleteModal}>Mégsem</Button>
          </ModalFooter>
        </Modal>
        {/* <Modal isOpen={this.state.modalKorrekcio} toggle={this.toggleKorrekcioModal} backdrop="static">
          <ModalHeader>Korrekció</ModalHeader>
          <ModalBody>Biztosan végrehajtja a készlet korrekciót? A korrekcióval automatikusan létrehozásra kerülnek a többlet, és hiánynak megfelelő készletnövekedési és készletcsökkenési tételek!</ModalBody>
          <ModalFooter>
            <ProgressButton
              className="btn-cursor-pointer"
              color="primary"
              onClick={() => this.korrekcio()}
              loading={this.state.korrekcioButtonLoading}
            >
              {'Igen'}
            </ProgressButton>
            <Button type="button" color="secondary" onClick={this.toggleKorrekcioModal}>Mégsem</Button>
          </ModalFooter>
        </Modal> */}
        <Modal isOpen={this.state.modalEdit} toggle={this.toggleEditModal} backdrop="static">
          <ModalHeader>Leltárív módosítása</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <Alert color="danger" isOpen={isAlertEditOpen} toggle={this.toggleAlertEdit}>
                  Felelős kitöltése kötlező!
                </Alert>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <Label for="felelos-edit">
                  {'Felelős: *'}
                </Label>
                {existsSzervezet ? 
                  <Select
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        '&:focus': { borderColor: 'red' }, // border style on hover
                        border: '1px solid lightgray', // default border color
                        boxShadow: 'none', // no box-shadow
                        borderRadius: '0'
                      }),
                    }}
                    name="felelos-edit"
                    id="felelos-edit"
                    placeholder="Kezdjen el gépelni a kereséshez..."
                    noOptionsMessage={() => 'Nincs keresési eredmény'}
                    searchPromptText="Kezdjen el gépelni a kereséshez..."
                    loadingMessage={() => 'Keresés...'}
                    className="basic-single"
                    classNamePrefix="select"
                    onChange={v => this.handleFelelosChange(v)}
                    getOptionLabel={option => option.nev}
                    getOptionValue={option => option.id}
                    value={formFelelos}
                    // isDisabled={isDisabled}
                    // isLoading={isLoading}
                    isClearable
                    isSearchable
                    options={formFelelosOptions}
                  />
                : (
                  <CreatableSelect
                    styles={{
                      control: (base, state) => ({
                        ...base,
                        '&:focus': { borderColor: 'red' }, // border style on hover
                        border: '1px solid lightgray', // default border color
                        boxShadow: 'none', // no box-shadow
                        borderRadius: '0'
                      }),
                    }}
                    name="formFelelosNev"
                    id="formFelelosNev"
                    placeholder="Kezdjen el gépelni a kereséshez..."
                    noOptionsMessage={() => 'Nincs keresési eredmény'}
                    searchPromptText="Kezdjen el gépelni a kereséshez..."
                    loadingMessage={() => 'Keresés...'}
                    formatCreateLabel={this.formatCreateLabel}
                    onCreateOption={this.createFelelos}
                    onChange={this.handleFelelosNevChange}
                    getOptionLabel={option => option.label || option}
                    getOptionValue={option => option.value || option}
                    value={formFelelosNev}
                    isClearable
                    isSearchable
                    options={felelosok}
                  />
                  )
                }
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <ProgressButton
              className="btn-cursor-pointer"
              color="primary"
              onClick={() => this.edit()}
              loading={this.state.editButtonLoading}
            >
              {'Mentés'}
            </ProgressButton>
            <Button type="button" color="secondary" onClick={this.toggleEditModal}>Mégsem</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

LeltarForm.propTypes = {
  globals: PropTypes.object
};

export default LeltarForm;
