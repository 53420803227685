
import React, { useState, useEffect} from 'react';
import { 
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from 'reactstrap';
import { AvForm, AvInput, } from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter} from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Services from '../services';
import Select from 'react-select/lib/Async';
import { browserHistory } from 'react-router';
import { DateTimePicker } from 'react-widgets';
import Lib from '../../../commons/lib';
import { PaginationTotal , FileCard} from '../../../commons/controls';
import { ProgressButton } from '../../../commons/ProgressButton';
import NumberFormatter from '../../../commons/NumberFormatter';
import EditorEmail from '../../Email/EditorEmail';
import { defaultValue } from '../../../commons/EditorFunctions';
import { serializer } from '@organw/wysiwyg-editor';
import _ from 'lodash';
import moment from 'moment';
import AjanlatCikkForm from './AjanlatCikkForm';
import DropZone from 'react-dropzone';
import fileSaver from 'file-saver';

const ROW_STYLE = { marginBottom: '30px' };
const AjanlatForm = (props) => {
  const {
    route,
  } = props;

  const EDITOR_INIT = defaultValue();
  const [id, setId] = useState(null);
  const [partner, setPartner] = useState(null);
  const [cegadat, setCegadat] = useState(null);
  const [kelte, setKelte] = useState(new Date());
  const [ervenyesseg, setErvenyesseg] = useState(new Date());
  const [printDate, setPrintDate] = useState(null);
  const [sendDate, setSendDate] = useState(null);
  const [penznem, setPenznem] = useState(null);
  const [allapot, setAllapot] = useState({name: 'ROGZITETT'});
  const [bizonylat, setBizonylat] = useState(null);
  const [targy, setTargy] = useState(null);
  const [bevezeto, setBevezeto] = useState(EDITOR_INIT);
  const [zaro, setZaro] = useState(null);
  const [megjegyzes, setMegjegyzes] = useState('');
  const [files, setFiles] = useState([]);
  const [cikkek, setCikkek] = useState([]);
  const [currentCikkId, setCurrentCikkId] = useState(null);
  const [loadingSaveButton, toggleLoadingSaveButton] = useState(false);
  const [formStatus, setFormStatus] = useState('DATA'); // ['LOAD', 'DATA', 'NETW']
  const [showCikk, toggleShowCikk] = useState(false);

  useEffect(() => {
    let params = new URL(window.location.href).searchParams;
    let id = params.get('id');
    if(id){
      setId(id);
    }else{
      listBeallitas();
    }
    setDefaultPenznem();
  }, []);

  useEffect(() => {
    if(id){
      setFormStatus('LOAD');
    }
  }, [id]);

  useEffect(() => {
    if(loadingSaveButton) {
      saveAjanlat();
    }
  }, [loadingSaveButton]);

  useEffect(() => {
    if(!showCikk) {
      setCurrentCikkId(null);
    }
  }, [showCikk]);

  useEffect(() => {
    switch(formStatus){
      case 'LOAD':
        getAjanlat(id);
      break;
    }
  }, [formStatus]);

  const handleBack = () => {
    browserHistory.push("".concat('/rendelesek/rendeles'));
  }

  // TODO kivenni, miután más pénznemet is lehet választani, valamint kerekítési szabályt betenni a pénznem listázásba akkor (most itt a true miatt van töltve)
  const setDefaultPenznem = () => {
    Services.getDefaultCurrency(true, (err, res) => {
      if (!err) {
        setPenznem(res[0]);
      }
    });
  };

  const listBeallitas = async () => {
    try {
      const json = await Services.listBeallitas();
      let date = new Date();
      date.setDate(date.getDate() + json.ervenyesseg);
      setErvenyesseg(date);
    } catch (ex) {}
  }

  const getAjanlatSablon = async (id) => {
    try {
      const res = await  Services.getAjanlatSablon({ id });
      if(res) {
        setTargy(res.targy);
        setBevezeto(serializer.deserialize(res.szoveg));
      }
    } catch (ex) { }
  }

  const loadPartnerOptions = (input, callback) => {
    if(_.isEmpty(input)) callback([]);
    Services.listPartner({type: 'RENDELES_VEVOI_CIM_AKTIV_GET', text: input}, (err, res) => {
      if(!err){
        const options = res[0].filter( k => k.nev.toLowerCase().includes(input.toLowerCase()))
        callback(options);
      }else{
        callback([]);
      }
    });
  }

  const loadCegadatOptions = (input, callback) => {
    if(_.isEmpty(input)) callback([]);
    Services.listCegadat({}, (err, res) => {
      if(!err){
        const options = res[0].filter( k => k.nev.toLowerCase().includes(input.toLowerCase()))
        if (options.length == 1)
          setCegadat(options[0]);
        callback(options);
      }else{
        callback([]);
      }
    });
  }

  const loadPenznemOptions = (input, callback) => {
    if(_.isEmpty(input)) callback([]);
    Services.listAktivPenznem({}, (err, res) => {
      if(!err){
        const options = res[0].filter( k =>  k.defaultcurrency && k.nev.toLowerCase().includes(input.toLowerCase()))
        if (options.length == 1) setPenznem(options[0]);
        callback(options);
      }else{
        callback([]);
      }
    });
  };

  const getAjanlat = async (id) => {
    try {
      const res = await Services.getAjanlat({ id: id });
      const resCikkek = res.cikkek.map((c) => {
        let cikk = Object.assign({}, c);
        cikk.afaSzazalek = c.afaSzazalek;
        cikk.nettoAr = c.nettoEgysegAr;
        cikk.afaAr = c.afaEgysegAr;
        cikk.bruttoAr = c.bruttoEgysegAr;
        cikk.nettoOssz = c.nettoErtek;
        cikk.afaOssz = c.afaErtek;
        cikk.bruttoOssz = c.bruttoErtek;
        cikk.irany = c.irany;

        return { ...cikk, ...{ penznem: res.penznem } };
      });
      setPartner(res.partner);
      setCegadat(res.cegadat ? { id: res.cegadat.id, nev: res.cegadat.teljesnev, cim: res.cegadat.cim } : {});
      setAllapot(res.allapot);
      setBizonylat(res.bizonylat ? res.bizonylat.bizonylatszam : null);
      setKelte(res.kelte);
      setErvenyesseg(res.ervenyesseg);
      setPrintDate(res.nyomtatasIdopont);
      setSendDate(res.kuldesIdopont);
      // Ha ezt a sort vissza akarod rakni, akkor olvasd el setDefaultPenznem()-nél a TODO-t
      // setPenznem(res.penznem);
      setTargy(res.targy);
      setBevezeto(serializer.deserialize(res.bevezeto));
      setZaro(res.zaro);
      setMegjegyzes(res.megjegyzes);
      setFiles(res.objects);
      setCikkek(resCikkek);
      setFormStatus('DATA');
    } catch (ex) {
      setFormStatus('NETW');
    }
  };

  const saveAjanlat = async () => {
    if (!penznem) {
      route.globals.addError('Pénznem választása kötelező!');
      return;
    }
    if (!id) {
      let submitObj = {};
      submitObj.kelte = kelte ? moment(kelte).format("YYYY-MM-DD") : null;
      submitObj.ervenyesseg = ervenyesseg ? moment(ervenyesseg).format("YYYY-MM-DD") : null;
      submitObj.partnerTortenetId = partner && partner.id;
      submitObj.cegadatId = cegadat && cegadat.id;
      submitObj.penznemId = penznem && penznem.id;
      submitObj.targy = targy;
      submitObj.bevezeto = serializer.serialize(bevezeto);
      submitObj.zaro = zaro;
      submitObj.megjegyzes = megjegyzes;
      submitObj.objects = files;

      let submitCikkek = [];
      cikkek.forEach((c) => {
        let cikk = Object.assign({}, c);
        if (c.new) {
          cikk.id = null;
        }

        if (c.irany === 1) { // nettó egységártól
          cikk.nettoEgysegAr = NumberFormatter.removeSpacesFromString(c.nettoAr);
        } else if (c.irany === 2) { // bruttó értéktől
          cikk.bruttoErtek = NumberFormatter.removeSpacesFromString(c.bruttoOssz);
        }

        cikk.mennyiseg = NumberFormatter.removeSpacesFromString(cikk.mennyiseg);
        delete cikk.nettoAr;
        delete cikk.afaAr;
        delete cikk.bruttoAr;
        delete cikk.nettoOssz;
        delete cikk.afaOssz;
        delete cikk.bruttoOssz;
        delete cikk.irany;
        delete cikk.afaSzazalek;

        submitCikkek.push(cikk);
      });

      submitObj.cikkek = submitCikkek;
      try {
        const res = await Services.addAjanlat(submitObj);
        route.globals.addSuccess('Vevői ajánlat felvéve!');
        handleBack();
      } catch (ex) {
        toggleLoadingSaveButton(false);
      }
    } else {
      let submitObj = {};
      submitObj.id = id;
      submitObj.kelte = kelte ? moment(kelte).format("YYYY-MM-DD") : null;
      submitObj.ervenyesseg = ervenyesseg ? moment(ervenyesseg).format("YYYY-MM-DD") : null;
      submitObj.partnerTortenetId = partner && partner.id;
      submitObj.cegadatId = cegadat && cegadat.id;
      submitObj.penznemId = penznem && penznem.id;
      submitObj.targy = targy;
      submitObj.bevezeto = serializer.serialize(bevezeto);
      submitObj.zaro = zaro;
      submitObj.megjegyzes = megjegyzes;
      submitObj.objects = files;

      let submitCikkek = [];
      cikkek.forEach((c) => {
        let cikk = Object.assign({}, c);
        if (c.new) {
          cikk.id = null;
        }

        if (c.irany === 1) { // nettó egységártól
          cikk.nettoEgysegAr = NumberFormatter.removeSpacesFromString(c.nettoAr);
          delete cikk.bruttoErtek;
        } else if (c.irany === 2) { // bruttó értéktől
          cikk.bruttoErtek = NumberFormatter.removeSpacesFromString(c.bruttoOssz);
          delete cikk.nettoEgysegAr;
        }

        cikk.mennyiseg = NumberFormatter.removeSpacesFromString(cikk.mennyiseg);
        delete cikk.nettoAr;
        delete cikk.afaAr;
        delete cikk.afaEgysegAr;
        delete cikk.bruttoAr;
        delete cikk.bruttoEgysegAr;
        delete cikk.nettoOssz;
        delete cikk.nettoErtek;
        delete cikk.afaOssz;
        delete cikk.afaErtek;
        delete cikk.bruttoOssz;
        delete cikk.irany;
        delete cikk.afaSzazalek;

        submitCikkek.push(cikk);
      });

      submitObj.cikkek = submitCikkek;
      try {
        const res = await Services.editAjanlat(submitObj);
        route.globals.addSuccess('Vevői ajánlat módosítva!');
        handleBack();
      } catch (ex) {
        toggleLoadingSaveButton(false);
      }
    }
  };

  const formatBytes = bytes => {
    if (bytes < 1024) return bytes + ' bájt';
    if (bytes < 1048576) return (bytes / 1024).toFixed(3) + ' KB';
    if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + ' MB';
    return (bytes / 1073741824).toFixed(3) + ' GB';
  };

  const downloadObject = file => {
    if (file.preview) {
      window.open(file.preview, '_blank');
    } else {
      getContent(file.id);
    }
  }

  const removeObject = file => {
    setFiles(file.preid ?  files.filter(f => f.preid !== file.preid) : files.filter(f => f.id !== file.id));
  }

  const getContent = async objid => {
    let obj = files.find(f => f.id == objid);

    Services.downloadAjanlatObject({objid: objid}, (err, res) => {
      if(!err){
        fileSaver.saveAs(new Blob([res[0]], { type: obj.mime }), obj.docname);
      }
    });
  }

  const onDropFiles = async (acceptedfiles) => {
    acceptedfiles.forEach((file) => {
      const reader = new FileReader();
      let newFile = {};
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        newFile = {
          content: btoa(fileAsBinaryString),
          uploadDate: new Date(),
          docname: file.name,
          preview: file.preview,
          mime: file.type || 'application/octet-stream',
          new: true,
          preid: Lib.Browser.uuidv4(),
          length: file.size,
        };
        setFiles([...[newFile], ...files]);
      };
      reader.readAsBinaryString(file);
    });
  }

  const tableIconFormatter = (cell, row) => {
    return (
      <React.Fragment>
        <React.Fragment>
          {/* TODO Ideiglenes megoldás, cserélni kell */}
          <Button className="icon-pencil table-action-icon" id={'action-view-' + row.id + '-edit'} onClick={() => { toggleShowCikk(true); setCurrentCikkId(row.id); }} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
          <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + row.id + '-edit'} delay={50}>
            {'Módosítás'}
          </UncontrolledTooltip>
        </React.Fragment>

        <React.Fragment>
          <Button className="icon-trash table-action-icon" id={'action-view-' + row.id + '-delete'} onClick={() => setCikkek(cikkek.filter(c => c.id != row.id))} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
          <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + row.id + '-delete'} delay={50}>
            {'Törlés'}
          </UncontrolledTooltip>
        </React.Fragment>
      </React.Fragment>
    );
  }

  const renderForm = () => {
    const selectStyles = {
      control: base => ({
        ...base,
        '&:focus': { borderColor: 'red' }, // border style on hover
        border: '1px solid lightgray', // default border color
        boxShadow: 'none', // no box-shadow
        borderRadius: '0'
      }),
    };
    return (
      <React.Fragment>
        {id &&
          <React.Fragment>
            <div className="row" style={ROW_STYLE}>
            <div className="col-md-6"></div>
              <div className="col-md-2" style={{paddingRight: '2px'}}>
                <Label for="allapot">Állapot: </Label>
                <AvInput
                  //style={{height: '10px'}}
                  name={"allapot"}
                  id={"allapot"}
                  value={allapot && allapot.caption}
                  disabled
                />
              </div>
              <div className="col-md-2" style={{paddingRight: '2px'}}>
                <Label for="bizonylatszam">Bizonylat: </Label>
                <AvInput
                  //style={{height: '10px'}}
                  name={"bizonylatszam"}
                  id={"bizonylatszam"}
                  value={bizonylat}
                  disabled
                />
              </div>
            </div>
          </React.Fragment>
        }
        <div className="row" style={ROW_STYLE}>
          <div className="col-md-6" style={{paddingRight: '2px'}}>
            <h5>Cég adatok:</h5>
          </div>
          <div className="col-md-6" style={{paddingRight: '2px'}}>
            <h5>Ügyfél adatok:</h5>
          </div>
          <div className="col-md-6" style={{paddingRight: '2px'}}>
          <Label for="cegadat">Cégadat: *</Label>
            <Select
              styles={selectStyles}
              name="cegadat"
              id="cegadat"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              onChange={(v) => {
                setCegadat(v);
                if (v) getAjanlatSablon(v.id);
              }}
              loadOptions={_.debounce(loadCegadatOptions, 1000)}
              getOptionLabel={option => option.nev + ' (' + option.cim + ')'}
              getOptionValue={option => option.id}
              value={cegadat}
              maxMenuHeight={200}
              //isDisabled={isNotRogzitett}
              isClearable
              defaultOptions
              required
            />
          </div>
          <div className="col-md-6" style={{paddingRight: '2px'}}>
            <Label for="partner">Ügyfél neve: *</Label>
            <Select
              styles={selectStyles}
              name="partner"
              id="partner"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              onChange={v => setPartner(v)}
              loadOptions={_.debounce(loadPartnerOptions, 1000)}
              getOptionLabel={option => option.nev + ' (' + option.cim + ')'}
              getOptionValue={option => option.id}
              value={partner}
              maxMenuHeight={200}
              //isDisabled={isNotRogzitett}
              isClearable
              //defaultOptions
              required
            />
          </div>
        </div>
        <div className="row" style={ROW_STYLE}>
          <div className="col-md-12" style={{paddingRight: '2px'}}>
            <h5>Ajánlat adatok:</h5>
          </div>
        </div>
        <div className="row" style={ROW_STYLE}>
          <div className="col-md-4" style={ROW_STYLE}>
            <Label for="targy">Tárgy: </Label>
            <AvInput
              name={"targy"}
              id={"targy"}
              value={targy}
              onChange={(e,v) => setTargy(v)}
            />
          </div>
          <div className="col-md-4">
            <Label for="kelte">Kelte: *</Label>
            <DateTimePicker
              id="kelte"
              name="kelte"
              value={kelte && new Date(kelte) }
              //disabled={isNotRogzitett}
              onChange={(e,v) => setKelte(v ? v : null)}
              containerClassName={ "organw-date-time"}
              time={false}
              format="YYYY-MM-DD"
              messages={{ dateButton: "dátum"}}
              required
            />
          </div>
          <div className="col-md-4">
            <Label for="kelte">Érvényesség: *</Label>
            <DateTimePicker
              id="ervenyesseg"
              name="ervenyesseg"
              value={ervenyesseg && new Date(ervenyesseg) }
              onChange={(e,v) => setErvenyesseg(v ? v : null)}
              containerClassName={ "organw-date-time"}
              time={false}
              format="YYYY-MM-DD"
              messages={{ dateButton: "dátum"}}
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={ROW_STYLE}>
            <Label for="bevezeto">Bevezető szöveg: </Label>
            <EditorEmail
              text={bevezeto}
              setText={setBevezeto}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={ROW_STYLE}>
            <Label for="zaro">Záró szöveg: </Label>
            <AvInput
              style={{height: '100px'}}
              className="munkalap-profil-textarea"
              type="textarea"
              name={"zaro"}
              id={"zaro"}
              value={zaro}
              onChange={(e,v) => setZaro(v)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <Label for="penznem">Pénznem: *</Label>
            <Select
              styles={selectStyles}
              name="penznem"
              id="partner"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              onChange={v => setPenznem(v)}
              // setDefaultPenznem() TODO
              // loadOptions={_.debounce(loadPenznemOptions, 1000)}
              getOptionLabel={option => option.nev}
              getOptionValue={option => option.id}
              value={penznem}
              //isDisabled={isNotRogzitett}
              // Ha ezt a sort törölni akarod, akkor olvasd el setDefaultPenznem()-nél a TODO-t
              isDisabled={true}
              isClearable
              defaultOptions
              required
            />
          </div>
          <div className="col-md-4">
            <Label for="printDate">Nyomtatás időpontja: </Label>
            <DateTimePicker
              id="printDate"
              name="printDate"
              value={printDate && new Date(printDate) }
              disabled
              containerClassName={ "organw-date-time"}
              time={false}
              format="YYYY-MM-DD"
              messages={{ dateButton: "dátum"}}
            />
          </div>
          <div className="col-md-4">
            <Label for="sendDate">Elküldés időpontja: </Label>
            <DateTimePicker
              id="sendDate"
              name="sendDate"
              value={sendDate && new Date(sendDate) }
              disabled
              containerClassName={ "organw-date-time"}
              time={false}
              format="YYYY-MM-DD"
              messages={{ dateButton: "dátum"}}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Label for="megjegyzes">Megjegyzés: </Label>
            <AvInput
              style={{height: '100px'}}
              className="munkalap-profil-textarea"
              type="textarea"
              name={"megjegyzes"}
              id={"megjegyzes"}
              value={megjegyzes}
              //disabled={isNotRogzitett}
              onChange={(e,v) => setMegjegyzes(v)}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Label for="files">Feltöltések: </Label>
            <DropZone id="files" onDrop={onDropFiles} multiple/* accept="image/*, .txt, .doc*, .pdf, .xls*" */ className="custom-dropzone" style={{height: '120px'}}>
              <div className="dropzone-text">
                {'File feltöltése!'}
              </div>
            </DropZone>
          </div>
          {files.map((item, idx) => (
            <div className="col-md-2" style={{ padding: 0 }} key={idx.toString()}>
              <FileCard
                key={idx.toString()}
                title={item.docname}
                length={formatBytes(item.length)}
                onDownloadClick={() => downloadObject(item)}
                onDeleteClick={() => removeObject(item)}
              />
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  };

  const renderCikkekTable = () => {
    const roundNumber = (number) => {
      let multiplier = (10 ** penznem.tizedesjegy);
      let num = number * multiplier;
      if (penznem.kerekitesiszabaly === 'HALF_UP') {
        num = Math.round(num);
      } else if (penznem.kerekitesiszabaly === 'HALF_DOWN') {
        // A toFixed() nem megbízható (pl 1.005 => 1.00, 1.006 => 1.01) ezért a round van használva (.5-nél "pozitív irányba" kerekít, tehát -2.5 => -2, 2.5 => 3)
        num = -Math.round(-num);
      }
      return num / multiplier;
    };

    const options = {
      sizePerPage: 5,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const columns = [
      {
        dataField: 'cikk.cikkszam',
        text: 'Cikkszám',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
        },
        sort: true
      },
      {
        dataField: 'cikkNev',
        text: 'Megnevezés',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
        },
        sort: true,
      },
      {
        dataField: 'mennyiseg',
        text: 'Mennyiség',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: NumberFormatter.styleTextAlignWhiteSpace,
        formatter: (cell, row) => NumberFormatter.formatterSpaceOsszeg(cell) + (row && row.cikk ? ' ' + row.cikk.mennyisegiEgyseg : ''),
        sort: true,
      },
      {
        dataField: 'afaSzazalek',
        text: 'ÁFA%',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: NumberFormatter.styleTextAlignWhiteSpace,
        sort: true,
        formatter: (cell, row) => NumberFormatter.formatterSpaceOsszegPenznem(cell, row, '%'),
      },
      {
        dataField: 'nettoOssz',
        text: 'Nettó érték',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: NumberFormatter.styleTextAlignWhiteSpace,
        formatter: (cell, row) => {
          return NumberFormatter.formatterSpaceOsszegPenznem(cell, row, penznem && penznem.jel);
        },
        sort: true
      },
      {
        dataField: 'bruttoOssz',
        text: 'Bruttó érték',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: NumberFormatter.styleTextAlignWhiteSpace,
        formatter: (cell, row) => NumberFormatter.formatterSpaceOsszegPenznem(cell, row, penznem && penznem.jel),
        sort: true
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        csvExport: false,
        formatter: (idx, row) => tableIconFormatter(idx, row)
      }
    ];

    return (
      <React.Fragment>
        <div className="row" style={{ marginTop: 50 }}>
          <div className="col-md-2" style={{ paddingRight: '2px' }}>
            <Button type="button" className="pull-left table-new-button" color="primary" onClick={() => toggleShowCikk(true)}>
              Cikk hozzáadása
            </Button>
          </div>
        </div>
        <div className="animated fadeIn">
          {cikkek && cikkek.length != 0 && (
            <React.Fragment>
              <BootstrapTable
                {...props.baseProps}
                bootstrap4
                keyField="id"
                data={cikkek}
                // rowClasses="kartya-row-class"
                columns={columns}
                filter={filterFactory()}
                pagination={paginationFactory(options)}
                noDataIndication="Nincs megjelenítendő adat!"
              />
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  };

  const renderModals = () => {
    return (
      <React.Fragment>
        {showCikk && (
          <AjanlatCikkForm
            route={route}
            parentId={id}
            id={currentCikkId}
            // isElkuldott={isElkuldott}
            cikkek={cikkek}
            penznem={penznem}
            setCikkek={setCikkek}
            toggleShowCikk={toggleShowCikk}
          />
        )}
      </React.Fragment>
    );
  };

  const renderButtons = () => {
    return (
      <div className="row" style={{ marginTop: 50 }}>
        <div className="col-md-12">
          <ProgressButton
            style={{ marginRight: 10 }}
            className="btn-cursor-pointer"
            color="success"
            loading={loadingSaveButton}
            type="button"
            onClick={() => toggleLoadingSaveButton(true)}
          >
            Mentés
          </ProgressButton>
          <Button type="button" color="secondary" onClick={() => handleBack()}>
            Mégsem
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div className="card">
      <div className="card-header">
        Új vevői ajánlat
      </div>
      <AvForm onValidSubmit={() => {}}>
        <div className="row" style={{ padding: 20 }}>
          <div className="col-md-12">
            {renderForm()}
            {renderCikkekTable()}
            {renderModals()}
            {renderButtons()}
          </div>
        </div>
      </AvForm>
    </div>
  );
};

export default AjanlatForm;
