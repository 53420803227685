import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { Button, Tooltip } from 'reactstrap';


export default class CustomTooltip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovered: false,
      isToggled: false,
    };
  }

    toggleTetelPopOver=(event) => {
      const { type } = event;
      if (type === 'mouseover') {
        this.setState({ isHovered: true });
      } else if (type === 'mouseout') {
        this.setState({ isHovered: false });
      } else if (type === 'click') {
        this.setState((prevState) => { return { isToggled: !prevState.isToggled }; });
      } else if (type === 'focusout') {
        this.setState({ isToggled: false });
      }
    }

    render() {
      const { id, text } = this.props;
      const { isHovered, isToggled } = this.state;
      return (
        <>
          <Button
            type="button"
            style={{ marginTop: 0, fontSize: '0.9em' }}
            id={id}
            className="fa fa-info-circle table-action-icon transp-icon-button"
          />
          <Tooltip
            placement="auto"
            target={id}
            delay={0}
            toggle={this.toggleTetelPopOver}
            isOpen={isHovered || isToggled}
            innerClassName="tooltip-lg"
            style={{ whiteSpace: 'pre-line', textAlign: 'left' }}
          >
            {text}
          </Tooltip>
        </>
      );
    }
}

CustomTooltip.propTypes = {
  id: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
