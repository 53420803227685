import React from 'react';
import { Input, InputGroup, Label } from 'reactstrap';
import PropTypes from 'prop-types';

const Mezo = (props) => {
  return (
    <div className="col-md-3">
      <InputGroup style={{ marginBottom: 16 }}>
        <Label for={props.mezonev} style={{ width: '100%', overflow: 'hidden', marginBottom: 5 }}>
          <div style={{ paddingTop: 12, float: 'left' }}>{props.mezonev}</div>
          <div align="right"><i className="fa fa-trash-o btn btn-danger" aria-hidden="true" onClick={props.removeMetaAdat} /></div>
        </Label>
        <Input name={props.mezonev} id={props.mezonev} value={props.tartalom} onChange={props.updateMetaAdat} />
      </InputGroup>
    </div>
  );
};

Mezo.propTypes = {
  mezonev: PropTypes.string,
  tartalom: PropTypes.string,
  updateMetaAdat: PropTypes.func,
  removeMetaAdat: PropTypes.func
};

export default Mezo;
