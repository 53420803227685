import Lib from '../../../commons/lib';

export default class Service {
  static configUrl = window.location.origin + '/api/config/';

  // FELHASZNALOK START

  static getUserList(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.configUrl + 'userkarb/getusers',
      args: { requestType: 'userkarb-getusers' },
    }], fnDone);
  }

  static getRoleList(userId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.configUrl + 'userkarb/availableclientroles',
      args: { requestType: 'userkarb-availableclientroles' },
      params: { userId }
    }], fnDone);
  }

  static getGroupList(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.configUrl + 'userkarb/getgroups',
      args: { requestType: 'userkarb-getgroups' },
    }], fnDone);
  }

  static getGroup(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.configUrl + 'userkarb/getgroup',
      args: { requestType: 'userkarb-getgroup' },
      params: { groupId: id }
    }], fnDone);
  }

  static insertGroup(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.configUrl + 'userkarb/creategroup',
      args: { requestType: 'userkarb-creategroup' },
      acceptType: 'application/json; charset=utf-8, text/plain;',
      data
    }], fnDone);
  }

  static editGroup(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.configUrl + 'userkarb/editgroup',
      args: { requestType: 'userkarb-editgroup' },
      data
    }], fnDone);
  }

  static deleteGroup(groupId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.configUrl + 'userkarb/removegroup',
      args: { requestType: 'userkarb-removegroup' },
      params: { groupId }
    }], fnDone);
  }

  static getUser(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.configUrl + 'userkarb/getuser',
      args: { requestType: 'userkarb-getuser' },
      params: { id }
    }], fnDone);
  }

  static insertUser(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.configUrl + 'userkarb/createuser',
      args: { requestType: 'userkarb-createuser' },
      acceptType: 'application/json; charset=utf-8, text/plain;',
      data
    }], fnDone);
  }

  static editUser(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.configUrl + 'userkarb/updateuser',
      args: { requestType: 'userkarb-updateuser' },
      data
    }], fnDone);
  }

  static deleteUser(userId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.configUrl + 'userkarb/removeuser',
      args: { requestType: 'userkarb-removeuser' },
      params: { userId }
    }], fnDone);
  }

  // FELHASZNALOK END
}
