import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { Button, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Label, UncontrolledTooltip, Input  } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { browserHistory } from 'react-router';
import Select from 'react-select';
import fileSaver from 'file-saver';
import DataEmptyState from '../../commons/dataemptystate';
import NetworkEmptyState from '../../commons/networkemptystate';
import QueryEmptyState from '../../commons/queryempty';
import Services from './services';
import { FileCard, PaginationTotal, DatePicker } from '../../commons/controls';
import Lib from '../../commons/lib';
import Loader from '../../commons/loader';
import NumberFormatter from '../../commons/NumberFormatter';

const  hasOwnProperty = Object.prototype.hasOwnProperty;
class KompenzacioLezarasaLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ['QUERY', 'LOAD', 'DATA', 'NETW', 'TABL']
      currentStatusBaseTable: 'QUERY',
      // ['LOAD', 'LOADED', 'NETW']
      szerzodesid: null,
      currentStatusBaseView: 'LOAD',
      udulesijogObj: null,
      kompenzacioTetelObj: null,
      udulesijogJson: [],
      aktivSzallodak: [],
      aktivEpuletek: [],
      aktivApartman: [],
      epuletHasValue: false,
      szallodaHasValue: false,
      szuroEpuletHasValue: false,
      szuroSzallodaHasValue: false,
      szuroAktivSzallodak: [],
      szuroAktivEpuletek: [],
      szuroSzallodaValue: '',
      szuroEpuletValue: '',
      szuroJogtulajdonosValue: '',
      tetelek: null,
      UjTetelModal: false,
      tetelModositas: false,
      modalNezet: false,
      szallodaValue: '',
      epuletValue: '',
      apartmanValue: '',
      apartmanHasValue: false,
    //  szuroJogtulajdonosValue: '',
      udulesijogid: 0,
      ujTetel: false,
      veglegesit: false,
      tetelEv: null,
      tetelHet: null,
      hetek:[],

    };

  //    this.updateTetel = this.updateTetel.bind(this);
  }

  componentDidMount(){
    this.getAktivSzallodak();
    this.getSzuroAktivSzallodak();
    if (this.props.location && this.props.location.state && this.props.location.state.type && this.props.location.state.type === 'UDULJ') {
      this.updateEloszures(this.props.location.state.szallodaid, this.props.location.state.epuletid, this.props.location.state.partner);
      if (this.props.location.state.szallodaid) {
        this.getAktivEpuletById(this.props.location.state.szallodaid);
      }
    } else {
      this.getAktivEpulet();
    }
      this.genWeeks();
  }

  genWeeks = () => {
    let weeks = [];
    for (let i = 1; i < 53; i++) {
      weeks.push(<option key={i.toString()} value={i}>{i}</option>);
    }
    this.setState({ hetek: weeks });
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.tableData !== nextProps.tableData) {
      this.setState({
        currentStatusBaseTable: 'LOAD'
      });
      this.setState({ szerzodesid: nextProps.tableData });
      Services.listUdulesijogBySzerzodesId(nextProps.tableData, (err, res) => {
        if (err) {
          this.setState({
            currentStatusBaseTable: 'NETW'
          });
        } else {
          if (res[0].length === 0) this.props.globals.addInfo('A szerződéshez nem tartozik üdülési jog!');
          let newState = res[0];
          newState.forEach((item) => {
            switch (item.statusz.name) {
              case 'AKTIV':
                item.statusz = 0;
                break;
              case 'LEZART':
                item.statusz = 1;
                break;
              default:
                item.statusz = -1;
                break;
            }
          });
          this.setState({
            udulesijogJson: newState,
            currentStatusBaseTable: newState.length > 0 ? 'TABL' : 'DATA'
          });
        }
      });
    }
  }

  getAktivSzallodak = () => {
    Services.listAktivSzallodak((err, res) => {
      if (!err) {
        //console.log(res[0],"szallodak");
        let aktivSzallodak = res[0].map(e => <option key={e.id} value={e.id}>{e.szallodanev}</option>);
        this.setState({ aktivSzallodak: aktivSzallodak });
      }
    });
  }

  getAktivEpulet = () => {
    Services.listAktivEpuletek((err, res) => {
      if (!err) {
        let aktivEpuletek = res[0].map(e => <option key={e.id} value={e.id}>{e.epuletnev}</option>);
        this.setState({ aktivEpuletek: aktivEpuletek });
      }
    });
  }

  getAktivEpuletById = (id) => {
    Services.listAktivEpuletekById(id, (err, res) => {
      if (!err) {
        let aktivEpuletek = res[0].map(e => <option key={e.id} value={e.id}>{e.epuletnev}</option>);
        this.setState({ aktivEpuletek: aktivEpuletek });

        this.getAktivApartmanById(res[0][0].id);
      }
    });
  }

  getAktivApartmanById = (id) => {
    Services.listApartmanokById(id, (err, res) => {
      if (!err) {
        let aktivApartmanok = res[0].map(e => <option key={e.id} value={e.id}>{e.nev}</option>);
        if (res[0].length > 0) {
          this.setState({aktivApartman: aktivApartmanok});
        }
      }
    });
  }

    ////////szuroSzallodaValue
    getSzuroAktivSzallodak = () => {
      Services.listAktivSzallodak((err, res) => {
        if (!err) {
          console.log(res[0],"szallodak");
          let aktivSzallodak = res[0].map(e => <option key={e.id} value={e.id}>{e.szallodanev}</option>);
          this.setState({ szuroAktivSzallodak: aktivSzallodak });
        }
      });
    }

    getSzuroAktivEpulet = () => {
      Services.listAktivEpuletek((err, res) => {
        if (!err) {
          let aktivEpuletek = res[0].map(e => <option key={e.id} value={e.id}>{e.epuletnev}</option>);
          this.setState({ szuroAktivEpuletek: aktivEpuletek });
        }
      });
    }

    getSzuroAktivEpuletById = (id) => {
      Services.listAktivEpuletekById(id, (err, res) => {
        if (!err) {
          let aktivEpuletek = res[0].map(e => <option key={e.id} value={e.id}>{e.epuletnev}</option>);
          this.setState({ szuroAktivEpuletek: aktivEpuletek });
          this.getAktivApartmanById(res[0][0].id);
        }
      });
    }

    getSzuroAktivApartmanById = (id) => {
      Services.listApartmanokById(id, (err, res) => {
        if (!err) {
          let aktivApartmanok = res[0].map(e => <option key={e.id} value={e.id}>{e.nev}</option>);
          if (res[0].length > 0) {
            this.setState({szuroAktivApartman: aktivApartmanok});
          }
        }
      });
    }

    updateEloszures = (szallodaid, epuletid, partner) => {
      console.log('mitortenikitt', partner);
      this.setState({
        szuroSzallodaValue: szallodaid && szallodaid,
        szuroSzallodaHasValue: szallodaid && szallodaid,
        szuroEpuletValue: epuletid && epuletid,
        szuroJogtulajdonosValue: partner
      }, () => {
        this.updateTable();
      });
    }

  updateTetel= (event, tetelObj) => {
    let submitObj = tetelObj;
    let mentesiobj = { apartmanid: 0, ev: 0, het: 0, id:0, };

    if (!parseInt(submitObj.apartman)) { submitObj.apartman = this.state.kompenzacioTetelObj.apartmanbean.id;}
    mentesiobj.apartmanid=submitObj.apartman;
    mentesiobj.ev=submitObj.ev;
    mentesiobj.het=submitObj.het;
    mentesiobj.id=this.state.kompenzacioTetelObj.id;

    Services.updateKompenzacioTetel(mentesiobj, (err,res) => {
      if (!err) {
        this.props.globals.addSuccess('Sikeres tétel módosítás!');
        this.toggletetelModositas();
        this.lekerdezeTetel(this.state.udulesijogid);
        this.setState({ kompenzacioTetelObj: null, modositTetel: false, veglegesit: false, tetelekmodossitas: false })
        this.dataTabla.state.selectedRowKeys = [];
      } else {
        this.props.globals.addError('Sikertelen tétel módosítás!');
      }
    });
  }

  onRowSelect = (row, isSelected, e) => {
    //this.lekerdezett(row.id);

    this.setState({ udulesijogid: row.id });
    this.lekerdezeTetel(row.id);

    if (row.kompenzaciostatusz.name === 'LEZART') {
      this.setState({ modositTetel: false, veglegesit: false, tetelekmodossitas: false });
    } else {
      this.setState({ tetelekmodossitas: true });
    }
  }

  onRowTetelSelect = (row, isSelected, e) => {
    console.log(row);
    console.log(e.key);

    this.setState({ kompenzacioTetelObj: row, tetelLezarasId:row.id })
    if (this.state.tetelekmodossitas) {
      if (row.kompenzaciostatusz.name !== 'LEZART') {
        this.setState({ modositTetel: true, veglegesit: true });
      }else{
        this.setState({ modositTetel: false, veglegesit: false });
      }
    }

  }

  lezar = () => {
  /*-*/  Services.updateKompenzacioTetelStatusz(this.state.tetelLezarasId, (err, res) => {
      if (!err) {
        this.props.globals.addSuccess('Sikeres tétel módosítás!');
        this.lekerdezeTetel(this.state.udulesijogid);
        this.lezarasudulesijog(this.state.udulesijogid);
      }else{this.props.globals.addError('Sikertelen tétel módosítás!');}
    });
    this.lezarasudulesijog(this.state.udulesijogid);
  }


  lezarasudulesijog = (id) => {
    let tetelek;
    let lezartakszam= 0;
    Services.getKompenzacioTetelek(id, (err, res) => {
      if (!err) {
        tetelek=res[0];
        tetelek.forEach(tetel => {
          if (tetel.kompenzaciostatusz.name == 'LEZART') {
            lezartakszam++;
          }
        });

        if (lezartakszam == tetelek.length ){
          Services.updateUdulesijogkompenzaciostatuszLezar(this.state.udulesijogid, (err, res) => {
            if (!err) {
              this.props.globals.addSuccess('Sikeres üdülési jog státusz módosítás!');
            }
          });
          this.updateTable();
        }
      }
    });
  }

  lekerdezeTetel = (id) => {
    let tetelek;
    Services.getKompenzacioTetelek(id, (err, res) => {
      if (!err) {
        tetelek=res[0];
        tetelek.forEach(tetel => {
          Object.assign(tetel,{ apartmannev: tetel.apartmanbean.apartmannev, epuletnev: tetel.apartmanbean.epuletnev, szallodanev: tetel.apartmanbean.szallodanev});
        });
        this.setState({tetelek: tetelek});
      }
    });
  }

    handleChangeSzamlakibocsato = (value) => {
      if (value && value.length !== 0) {
        this.setState({ szamlakibocsatoValue: value.id });
      } else {
        this.setState({ szamlakibocsatoValue: '' });
      }
    }

    iconFormatter = (cell) => {
      return (
        <div >
          <Button style={{ marginRight: '15px' }} className="icon-eye table-action-icon" id={'action-view-' + cell + 'megtekint'} onClick={() => this.handleViewClick(cell)} />
          <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'megtekint'} delay={0}>
            Megtekintés
          </UncontrolledTooltip>
        </div>
      );
    }

    evFormatter = (cell) => {
      return (
        <div>{cell.caption}</div>
      );
    }


    handleTorlesTetel = (cell) => {

      Services.deleteKompenzacioTetel(cell, (err, res) => {
        if (!err) {
          this.props.globals.addSuccess('Sikeres tétel törlés!');
          this.lekerdezeTetel(this.state.udulesijogid);
        }else{
          this.props.globals.addError('Sikertelen tétel törlés!');
        }
      });
      //deleteKompenzacioTetel
    //  this.toggletetelModositas();
    }

    handleModositoTetel = (cell) => {
      Services.getKompenzacioTetel(cell, (err, res) => {
        if (err) {
          this.setState({
            currentStatusBaseView: 'NETW'
          });
        } else {
          this.getAktivApartmanById(res[0].apartmanbean.id);
          console.log(res[0]);

          this.setState({
            kompenzacioTetelObj: res[0],
            currentStatusBaseView: 'LOADED'
          });
        }
      });
      this.toggletetelModositas();
    }

    toggleNezet = () => {
      this.setState({ modalNezet: !this.state.modalNezet });
    }

    kompenzacioStatuszmodositas = () => {

      Services.updateUdulesijogKompenzaciostatuszVegleges(this.state.udulesijogid, (err, res) => {
        if (!err) {
          this.props.globals.addSuccess('Sikeres kompenzáció státusz módosítás!');
        }
      });
    }

    toggletetelModositas = () => {
      this.setState({ tetelModositas: !this.state.tetelModositas });
    }

    statuszFormatter = (cell) =>{
      if ( cell!=null ){
        switch (cell.name) {
          case 'FELVITT':
            return <Badge color="success">Felvitt</Badge>;
          case 'LEZART':
            return <Badge color="secondary">Lezárt</Badge>;
        }
      }
    }

    nevApartmanFormatter = (cell) => {
      return cell.apartmannev;
    }

    nevEpuletFormatter = (cell) => {
      return cell.epuletnev;
    }

    nevSzalloFormatter = (cell) => {
      return cell.szallodanev;
    }

    tetelModositas = () => {
      return (
        <AvForm onValidSubmit={this.updateTetel}>
          <div className="row" >

            <div className="col-md-12">
              <AvField type="select" name="szalloda" id="szalloda" label="Szálloda: " onChange={this.szallodaChange} errorMessage="Kötelező mező!" required value={this.state.kompenzacioTetelObj && this.state.kompenzacioTetelObj.apartmanbean.szallodanev} >
                <option disabled value={this.state.kompenzacioTetelObj && this.state.kompenzacioTetelObj.apartmanbean.szallodanev}>{this.state.kompenzacioTetelObj && this.state.kompenzacioTetelObj.apartmanbean.szallodanev}</option>
                {this.state.aktivSzallodak}
              </AvField>
            </div>

            <div className="col-md-12">
              <AvField type="select" name="epulet" id="epulet" label="Épulet: " onChange={this.epuletChange} errorMessage="Kötelező mező!" required value={this.state.kompenzacioTetelObj && this.state.kompenzacioTetelObj.apartmanbean.epuletnev}>
                <option disabled value={this.state.kompenzacioTetelObj && this.state.kompenzacioTetelObj.apartmanbean.epuletnev}>{this.state.kompenzacioTetelObj && this.state.kompenzacioTetelObj.apartmanbean.epuletnev}</option>
                {this.state.aktivEpuletek}
              </AvField>
            </div>

            <div className="col-md-12">
              <AvField type="select" name="apartman" id="apartman" label="Apartman: " onChange={this.apartmanChange} errorMessage="Kötelező mező!" required value={this.state.kompenzacioTetelObj && this.state.kompenzacioTetelObj.apartmanbean.apartmannev} >
                <option disabled value={this.state.kompenzacioTetelObj && this.state.kompenzacioTetelObj.apartmanbean.apartmannev}>{this.state.kompenzacioTetelObj && this.state.kompenzacioTetelObj.apartmanbean.apartmannev}</option>
                {this.state.aktivApartman}
              </AvField>
            </div>

            <div className="col-md-12">
              <AvGroup>
                <AvField
                  type="select"
                  name="het"
                  id="het"
                  label="Hét azonosítója: "
                  errorMessage="Kötelező mező!"
                  required
                  value={this.state.kompenzacioTetelObj && this.state.kompenzacioTetelObj.het}
                  onChange={(e) => {
                    this.setState({ tetelHet: e.target.value });
                  }}

                >
                  <option value="" disabled>Válassz hetet...</option>
                  {this.state.hetek}
                </AvField>

                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
            </div>

            <div className="col-md-12">
              <AvGroup>
                <Label for="ev">Év: </Label>
                <AvInput required name="ev" id="ev" value={this.state.kompenzacioTetelObj && this.state.kompenzacioTetelObj.ev} />

                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
            </div>

            <div className="col-md-3" style={{ marginRight: '-35px', left: '300px' }}>
              <Button className="margin-top-30" color="primary" >Mentés</Button>
            </div>
            <div className="col-md-9" style={{ left: '300px' }}>
              <Button type="button" color="primary" onClick={this.toggletetelModositas} className="btn-cursor-pointer margin-top-30" >Mégse</Button>
            </div>
          </div>
        </AvForm>
      );
    }

    handleViewClick = (id) => {
      this.setState({
        currentStatusBaseView: 'LOAD',
        modalNezet: true
      });
      Services.getUdulesijog(id, (err, res) => {
        if (err) {
          this.setState({
            currentStatusBaseView: 'NETW'
          });
        } else {
          this.setState({
            udulesijogObj: res[0],
            currentStatusBaseView: 'LOADED'
          });
        }
      });
    }

    szallodaChange = (e) => {
      this.setState({
        szallodaHasValue: true, aktivEpuletek: [], aktivApartman: [], szallodaValue: e.target.value, epuletValue: ''
      });
      if (this.state.kompenzacioTetelObj) {
        this.state.kompenzacioTetelObj.apartmanbean.apartmannev = '';
        this.state.kompenzacioTetelObj.het = '';
        this.state.kompenzacioTetelObj.ev = '';
      }
      this.getAktivEpuletById(e.target.value);
      if (this.state.kompenzacioTetelObj) this.state.kompenzacioTetelObj.apartmanbean.epuletnev = null;
    }

    epuletChange = (e) => {
      this.setState({ epuletHasValue: true, aktivApartman: [], epuletValue: e.target.value });
      this.getAktivApartmanById(e.target.value);
      if(this.state.kompenzacioTetelObj)  this.state.kompenzacioTetelObj.apartmanbean.apartmannev = null;
    }

    apartmanChange = (e) => {
      this.setState({ apartmanHasValue: true, apartmanValue: e.target.value });
    }

    ///Szuro
      szuroSzallodaChange = (e) => {
        this.setState({
          szuroSzallodaHasValue: true, szuroAktivEpuletek: [], szuroAktivApartman: [], szuroSzallodaValue: e.target.value, szuroEpuletValue: ''
        });
        this.getSzuroAktivEpuletById(e.target.value);
      //  if (this.state.kompenzacioTetelObj) this.state.kompenzacioTetelObj.apartmanbean.epuletnev = null;
      }
        szuroEpuletChange = (e) => {
          this.setState({ szuroEpuletHasValue: true, szuroAktivApartman: [], szuroEpuletValue: e.target.value });
          this.getSzuroAktivApartmanById(e.target.value);
        }


      submitSearch = () => {
        Services.listUdulesijog(this.state.szuroSzallodaValue, this.state.szuroEpuletValue, this.state.szuroJogtulajdonosValue, true, (err, res) => {
          if (!err) {
            this.setState({ udulesijogJson: res[0] });
          }
        });
      }

      handleChangeJogtulajdonos = (value) => {
        if (value && value.length !== 0) {
          this.setState({ szuroJogtulajdonosValue: value.id });
        } else {
          this.setState({ szuroJogtulajdonosValue: '' });
        }
      }

      loadJogtulajdonosChange = (input, callback) => {
        if (input.length !== 0) {
          Services.listUdulesijogTulaj('UDULESIJOG_TULAJ_ELO', input, (err, res) => {
            if (!err) {
              let newArray = [];
              res[0].forEach((item) => {
                item.nev += ' (' + item.cim + ') ';
                if (item.adoszam) {
                  item.nev += '[' + NumberFormatter.addHyphensToString(item.adoszam) + '] ';
                }
                newArray.push(item);
              });
              callback(null, { options: newArray });
            }
          });
        }
      }

      updateTable = () => {
        this.setState({
          currentStatusBaseTable: 'LOAD',
          tetelek: [{}],
        });

        this.setState({ujTetel:false, veglegesit:false});
        Services.listUdulesijog(this.state.szuroSzallodaValue, this.state.szuroEpuletValue, this.state.szuroJogtulajdonosValue, true, (err, res) => {
          if (err) {
            this.setState({
              currentStatusBaseTable: 'NETW'
            });
          } else {
            let newState = res[0];
            newState.forEach((item) => {
              switch (item.statusz.name) {
                case 'AKTIV':
                  item.statusz = 0;
                  break;
                case 'LEZART':
                  item.statusz = 1;
                  break;
                default:
                  item.statusz = -1;
                  break;
              }
            });
            let ujstate=[];
            newState.forEach((item) => {
              if (item.kompenzaciostatusz && item.kompenzaciostatusz.name != "FELVITT") {
                ujstate.push(item);
              }
            });

            this.setState({
              udulesijogJson: ujstate,
              currentStatusBaseTable: ujstate.length > 0 ? 'TABL' : 'DATA'
            });
          }
        });
      }


    renderTulajdonosListItem = (tulajdonos) => {
      return (
        <li key={tulajdonos.id.toString()} style={tulajdonos.fotulajdonos ? { color: 'forestgreen' } : {}}>
          <b>{tulajdonos.tulajdonosbean.nev}</b><br />
          <span><b>Cím: </b>{tulajdonos.tulajdonosbean.cim}</span><br />
          <span><b>Érvényesség kezdete: </b>{tulajdonos.kezdete}</span><br />
          <span><b>Érvényesség vége: </b>{tulajdonos.vege}</span><br />
          <span><b>RCI tag: </b>{tulajdonos.rcitag ? 'Igen' : 'Nem'}</span><br />
          <span><b>RCI státusz: </b>{tulajdonos.rcistatusz && tulajdonos.rcistatusz.caption}</span><br />
          <span><b>RCI Weeks azonosító: </b>{tulajdonos.rciweeksazon}</span><br />
          <span><b>RCI Points azonosító: </b>{tulajdonos.rcipointsazon}</span><br />
          <span><b>RCI típus: </b>{tulajdonos.rcitipus && tulajdonos.rcitipus.caption}</span><br />
          {tulajdonos.elhunyt && (
            <React.Fragment>
              <span><b>Elhunyt: </b>Igen</span><br />
            </React.Fragment>
          )}
          <br />
        </li>
      );
    }

    renderAdatok = () => {
      let listItems = [];
      if (this.state.udulesijogObj) {
        for (let item of this.state.udulesijogObj.udulesijogtulajdonosentities) {
          listItems.push(this.renderTulajdonosListItem(item));
        }
      }
      return (
        <div>
          <h6>Üdülési jog információ:</h6>
          <b>Üdülési jog azonosító: </b>{this.state.udulesijogObj && this.state.udulesijogObj.azonosito}<br />
          <b>Érvényesség kezdete: </b>{this.state.udulesijogObj && this.state.udulesijogObj.kezdete}<br />
          <b>Érvényesség vége: </b>{this.state.udulesijogObj && this.state.udulesijogObj.vege}<br />
          <b>Számlakibocsátó: </b>{this.state.udulesijogObj && this.state.udulesijogObj.szamlakibocsatobean && this.state.udulesijogObj.szamlakibocsatobean.nev}<br />
          <b>Szálloda: </b>{this.state.udulesijogObj && this.state.udulesijogObj.apartmanbean && this.state.udulesijogObj.apartmanbean.szallodanev}<br />
          <b>Épület: </b>{this.state.udulesijogObj && this.state.udulesijogObj.apartmanbean && this.state.udulesijogObj.apartmanbean.epuletnev}<br />
          <b>Apartman: </b>{this.state.udulesijogObj && this.state.udulesijogObj.apartmanbean && this.state.udulesijogObj.apartmanbean.apartmannev}<br />
          <b>Hét sorszáma: </b>{this.state.udulesijogObj && this.state.udulesijogObj.het}<br />
          <b>Páros/páratlan év: </b>{this.state.udulesijogObj && this.state.udulesijogObj.gyakorisag && this.state.udulesijogObj.gyakorisag.caption}<br />
          <b>Osztott díj: </b>{this.state.udulesijogObj && this.state.udulesijogObj.reszlet ? 'Igen' : 'Nem' }<br />
          <b>Szín: </b>{this.state.udulesijogObj && this.state.udulesijogObj.apartmanbean && this.state.udulesijogObj.apartmanbean.hetszine}<br />
          <b>Jellemző: </b>{this.state.udulesijogObj && this.state.udulesijogObj.jellemzo}<br />
          <b>Lejárati státusz: </b>{this.state.udulesijogObj && this.state.udulesijogObj.lejaratistatusz && this.state.udulesijogObj.lejaratistatusz.caption}<br /><br />
          <h6>Pénzügyi adatok:</h6>
          <b>Pénznem: </b>{this.state.udulesijogObj && this.state.udulesijogObj.penznem}<br />
          <b>Fizetési mód: </b>{this.state.udulesijogObj && this.state.udulesijogObj.fizmod}<br />
          <b>Számlázás nyelve: </b>{this.state.udulesijogObj && this.state.udulesijogObj.nyelv && this.state.udulesijogObj.nyelv.caption}<br />
          <b>Státusz: </b>{this.state.udulesijogObj && this.state.udulesijogObj.statusz && this.state.udulesijogObj.statusz.caption}<br />
          <b>Lezárás oka: </b>{this.state.udulesijogObj && this.state.udulesijogObj.lezarasoka && this.state.udulesijogObj.lezarasoka.caption}<br /><br />
          <h6>Jogtulajdonos adatai:</h6>
          <ul>
            {listItems}
          </ul>
        </div>
      );
    }
    renderLoader = () => {
      return (
        <div className="showbox">
          <div className="loader">
            <svg className="circular" viewBox="25 25 50 50">
              <circle className="path" cx="50" cy="50" r="20" fill="none" />
            </svg>
          </div>
        </div>
      );
    }



    renderTable = () => {
      const selectRowProp = {
        mode: 'radio',
        onSelect: this.onRowSelect
      };

      const options = {
        sizePerPage: 5,
        paginationShowsTotal: PaginationTotal,
        sizePerPageList: [
          { text: '5', value: 5 },
          { text: '10', value: 10 },
          { text: '25', value: 25 }
        ],
      };
      return (
        <div className="animated fadeIn" >
          <BootstrapTable data={this.state.udulesijogJson} pagination options={options} selectRow={selectRowProp} >
            <TableHeaderColumn dataField="kompenzaciostatusz" width="160" dataAlign="center" dataFormat={this.statuszFormatter} tdStyle={{ whiteSpace: 'normal' }} /*filter={{ type: 'SelectFilter', options: statuszok, placeholder: 'Összes' }}*/>Kompenzáció Státusz</TableHeaderColumn>
            <TableHeaderColumn dataField="tulajdonosok" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Jogtulajdonos</TableHeaderColumn>
            <TableHeaderColumn dataField="azonosito" >Üdülési jog azonosító</TableHeaderColumn>
            <TableHeaderColumn dataField="id" width="150" isKey dataFormat={this.iconFormatter}>Műveletek</TableHeaderColumn>
          </BootstrapTable>
        </div>
      );
    }


  tetelekabla = () => {
    const selectRowProp = {
      mode: 'radio',
      onSelect: this.onRowTetelSelect,

    };

    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    return (
// /apartmanbean
      <div className="animated fadeIn" >
        <BootstrapTable ref={((c) => { this.dataTabla = c; })} data={this.state.tetelek} pagination options={options} selectRow={selectRowProp} >
          <TableHeaderColumn dataField="szallodanev" tdStyle={{ whiteSpace: 'normal' }}  filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }} > Szálloda </TableHeaderColumn>
          <TableHeaderColumn dataField="epuletnev" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}> Épület </TableHeaderColumn>
          <TableHeaderColumn dataField="apartmannev"  tdStyle={{ whiteSpace: 'normal' }} /* filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}*/ > Apartman </TableHeaderColumn>
          <TableHeaderColumn dataField="ev" tdStyle={{ whiteSpace: 'normal' }} /* filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}*/ > Év </TableHeaderColumn>
          <TableHeaderColumn dataField="het" tdStyle={{ whiteSpace: 'normal' }} /* filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}*/ > Hét </TableHeaderColumn>
          <TableHeaderColumn dataField="kompenzaciostatusz" dataFormat={this.statuszFormatter} tdStyle={{ whiteSpace: 'normal' }}> Státusz </TableHeaderColumn>
          <TableHeaderColumn hidden dataField="id" width="150" isKey >id</TableHeaderColumn>
        </BootstrapTable>

      </div>
    );
  }

  isEmpty(obj) {

    if (obj == null) return true;
    if (obj.length > 0)    return false;
    if (obj.length === 0)  return true;
    if (typeof obj !== "object") return true;
    for (var key in obj) {
      if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
  }

  render() {
    return (
      <div className="animated fadeIn" >

        <Modal isOpen={this.state.tetelModositas} toggle={this.toggletetelModositas} className={this.props.className} backdrop="static">
          <ModalHeader >Tétel Módosítás</ModalHeader>
          <ModalBody>
            {this.tetelModositas()}
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.modalNezet}
          toggle={this.toggleNezet}
          className={this.props.className}
          backdrop="static"
        >
          <ModalHeader>Megtekintés</ModalHeader>
          <ModalBody>
            {{
              LOAD: (<Loader />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.handleViewClick()}
              />),
              LOADED: (this.renderAdatok())
            }[this.state.currentStatusBaseView]}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleNezet}>Vissza</Button>
          </ModalFooter>
        </Modal>

        <AvForm onValidSubmit={this.submitSearch}>
          <div className="row">
            <div className="col-md-2">

              <AvField
                type="select"
                name="szallodaid"
                id="szallodaid"
                label="Szálloda: "
                errorMessage="Kötelező mező!"
                value={this.state.szuroSzallodaValue}
                onChange={this.szuroSzallodaChange}
              >
                <option value="" disabled>Válassz szállodát...</option>
                {this.state.szuroAktivSzallodak}
              </AvField>
            </div>
            <div className="col-md-2">
              <AvField
                type="select"
                name="epuletid"
                id="epuletid"
                label="Épület: "
                errorMessage="Kötelező mező!"
                value={this.state.szuroEpuletValue}
                onChange={this.szuroEpuletChange}
                disabled={!(this.state.szuroSzallodaHasValue)}
              >
                <option value="" >Válassz épületet...</option>
                {this.state.szuroAktivEpuletek}
              </AvField>
            </div>
            <div className="col-md-6">
              <Label for="jogtulajId">Jogtulajdonos:</Label>
              <Select.Async
                name="jogtulajId"
                id="jogtulajId"
                placeholder="Kezdjen el gépelni..."
                noResultsText="Nincs keresési eredmény"
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingPlaceholder="Keresés..."
                onChange={v => this.handleChangeJogtulajdonos(v)}
                clearable
                ignoreAccents={false}
                loadOptions={_.debounce((input, callback) => this.loadJogtulajdonosChange(input, callback), 1000)}
                isLoading={false}
                valueKey="id"
                labelKey="nev"
                value={this.state.szuroJogtulajdonosValue}
                cache={false}
                autoload={false}
              />
            </div>
            <div className="col-md-2">
              <Button className="margin-top-30" color="primary" onClick={this.updateTable} >Keresés</Button>
            </div>
          </div>
        </AvForm>
        {this.state.szerzodesid != null ?
          <Button className="margin-top-30" color="primary" onClick={() => this.ujudulesijog()}>Új üdülési jog</Button>
          :
          null
        }

        <div className="row">
          <div className="col-md-12">
            {/* <Button className="szerzodes-collapse-buttons" color="primary" onClick={this.navToForm} >Új üdülési jog</Button> */}
          </div>
          <div className="col-md-12" style={{ marginTop: '30px' }}>
            {{
              QUERY: (<QueryEmptyState
                hasImage
              />),
              LOAD: (<Loader />),
              DATA: (<DataEmptyState
                hasImage
                subtitle="Próbálj más előszűrést vagy vigyél fel új adatokat!"
              />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.updateTable()}
              />),
              TABL: (this.renderTable())
            }[this.state.currentStatusBaseTable]}
          </div>
        </div>


        { this.state.tetelek &&  !this.isEmpty(this.state.tetelek[0]) && <div>
          <br />
          <hr style={{ height: '10px', boreder: '10px', margin: '0px' }} />

          <div className="row" style={{ padding: 20 }}>
            <div className="col-md-12">
              <Label for="kezdete">Kompenzációs tételek:</Label>
              {this.tetelekabla()}
            </div>
          </div>
          <div className="row">
            <div className="col-xs-2" style={{'paddingLeft': "10px"}}>
              <Button className="margin-top-30" disabled={!this.state.modositTetel} onClick={this.toggletetelModositas} color="primary" >Szerződés módosítás</Button>
            </div>
            <div className="col-md-3">
              <Button className="margin-top-30" color="primary" disabled={!this.state.veglegesit} onClick={this.lezar} >Lezárás</Button>
              { /* onClick=this.toggleUjLeltar */ }
            </div>
          </div>
        </div>}

      </div>
    );
  }
}

export default KompenzacioLezarasaLista;
