import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Button, Modal, ModalHeader, ModalBody, Label, UncontrolledTooltip
} from 'reactstrap';
import {
  AvForm, AvGroup, AvInput, AvFeedback
} from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Select from 'react-select/lib/Async';
import Services from './services';
import DataEmptyState from '../../commons/dataemptystate';
import QueryEmptyState from '../../commons/queryempty';
import { PaginationTotal } from '../../commons/controls';
import Lib from '../../commons/lib';
import NumberFormatter from '../../commons/NumberFormatter';

class SzamlaProfil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      UjProfil: false,
      ModosítasProfil: false,
      dataLoaded: null,
      dataViewLoaded: null,
      dataFailed: null,
      queryLoaded: null,
      queryStarted: null,
      ModositoObj: null,
      szamlakiid: null,
      adatokJson: [],
      szamlakibocsato: null,
    };
  }

  // componentWillMount() {
  //   this.getSzamlazasiProfilListaAll();
  // }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.active !== prevProps.active) {
      if (this.props.active) {
        this.getSzamlazasiProfilListaAll();
      }
    }
  }

  getSzamlazasiProfilListaAll = () => {
    Services.getSzamlazasiProfilListaAll((err, res) => {
      if (!err) {
        this.setState({ adatokJson: res[0] });
      }
    });
  }

  updateTable = (event, searchObj) => {
    this.setState({
      dataLoaded: false,
      dataFailed: false,
      queryLoaded: false,
      queryStarted: true
    });

    if (this.state.szamlakibocsatoValue === '') {
      this.props.globals.addError('Az előszűréshez a számlakibocsátó megadása kötelező!');
      this.setState({
        dataLoaded: null, dataFailed: null, queryLoaded: null,  queryStarted: null
      });
    } else {
      let ujertek = {
        szamlakibocsato: this.state.szamlakibocsatoValue,
        szallitofkviszam: searchObj.Szamlakombinaciofksz,
        vevofkviszam: searchObj.Vevoszlellen,
        afafkviszam: searchObj.Afafksz,
        szamlakibocsatoid: this.state.szamlakiid && this.state.szamlakiid.id && this.state.szamlakiid.id,
      }
      Services.setSzamlazasiProfil('f',ujertek,(err,res) => {
        if (!err) {
          this.toggleUjProfil();
          this.getSzamlazasiProfilListaAll();
          this.props.globals.addSuccess('Sikeres adat felvitel');
        }
      });
    }
  }

  updateMTable = (event, searchObj) => {
    this.setState({
      dataLoaded: false,
      dataFailed: false,
      queryLoaded: false,
      queryStarted: true
    });

    if (this.state.szamlakibocsatoValue === '') {
      this.props.globals.addError('Az előszűréshez a számlakibocsátó megadása kötelező!');
      this.setState({
        dataLoaded: null,
        dataFailed: null,
        queryLoaded: null,
        queryStarted: null
      });
    } else {
      let ujertek = {
        id: this._currentViewId,
        szamlakibocsato: this.state.ModositoObj.szamlakibocsato,
        szallitofkviszam: searchObj.Szamlakombinaciofksz,
        vevofkviszam: searchObj.Vevoszlellen,
        afafkviszam: searchObj.Afafksz,
        szamlakibocsatoid: this.state.ModositoObj.szamlakibocsatoid,
      }

      Services.setSzamlazasiProfil('m',ujertek, (err) => {
        if (!err) {
          this.toggleModosítasProfil();
          this.getSzamlazasiProfilListaAll();
          this.props.globals.addSuccess('Sikeres adat módosítás');
        }
      });
    }
  }

  toggleModosítasProfil = () => {
    this.setState({ ModosítasProfil: !this.state.ModosítasProfil });
  }

  toggleUjProfil = () => {
    this.setState({ UjProfil: !this.state.UjProfil });
  }

  iconFormatter = (cell) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_SZAMLAPROFIL', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modosit'} onClick={() => this.handleModositoClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'modosit'} delay={0}>
              {'Módosítás'}
            </UncontrolledTooltip>
            <Button className="icon-trash table-action-icon" id={'action-view-' + cell + 'torol'} onClick={() => this.handleTroloClick(cell)} />
            <UncontrolledTooltip style={{ float: 'right' }} placement="right" target={'action-view-' + cell + 'torol'} delay={0}>
              {'Törlés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  handleModositoClick = (id) => {
    this._currentViewId = id;

    Services.getSzamlazasiProfilLista(id, (err, res) => {
      if (!err) {
        this.setState({ ModositoObj: res[0] });
      }
    });

    this.toggleModosítasProfil();
  }


  handleTroloClick = (id) => {
    this._currentViewId = id;
    Services.SzamlazasiProfilDelete(id, (err) => {
      if (!err) {
        this.getSzamlazasiProfilListaAll();
        this.props.globals.addSuccess('Sikeres törlés.');
      }
    });
  }

  loadSzamlakibocsatoChange = (input, callback) => {
    if (input.length !== 0) {
      Services.getSzamlaKibocsato('SZAMLAPROFIL_SZAMLAKIBOCSATO_LIST_FORM', input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            item.nev += ' (' + item.cim + ') ';
            if (item.adoszam) {
              item.nev += '[' + NumberFormatter.addHyphensToString(item.adoszam) + '] ';
            }
            newArray.push(item)
          });
          callback(newArray);
        }
      });
    }
  }

  loadSzamlakibocsatoChangemodositas = (input, callback) => {
    if (input.length !== 0) {
      Services.getSzamlaKibocsato('SZAMLAPROFIL_SZAMLAKIBOCSATO_LIST_FORM', input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            item.nev += ' (' + item.cim + ') ';
            if (item.adoszam) {
              item.nev += '[' + NumberFormatter.addHyphensToString(item.adoszam) + '] ';
            }
            item.szamlakibocsatoid = item.id;
            item.szamlakibocsato = item.nev;
            newArray.push(item)
          });
          callback(newArray);
        }
      });
    }
  }

  handleChangeSzamlakibocsato = (value) => {
    if (value && value.length !== 0) {
      this.setState({ szamlakibocsato: value });
      this.setState({ szamlakiid: value });
      this.setState({ szamlakibocsatoValue: value.nev });
    } else {
      this.setState({ szamlakibocsatoValue: '' });
      this.setState({ szamlakibocsato: '' });
      this.setState({ szamlakiid: '' });
      this.setState({ szamlakibocsatoValue: '' });
    }
  }


  handleChangeSzamlakibocsatomodositas = (value) => {
    if (value && value.length !== 0) {
      this.setState({ szamlakibocsato: value });

      let adat = this.state.ModositoObj;
      adat.szamlakibocsato = value.szamlakibocsato;
      adat.szamlakibocsatoid = value.szamlakibocsatoid;
      this.setState({ ModositoObj: adat });

      this.setState({ szamlakiid: value });
      this.setState({ szamlakibocsatoValue: value.nev });
    } else {
      this.setState({ szamlakiid: '' });
      this.setState({ szamlakibocsatoValue: '' });
    }
  }

  maxLengthCheck =(object) => {
    if (object.target.value.length > object.target.maxLength) object.target.value = object.target.value.slice(0, object.target.maxLength);
  }

  UjProfil = () => {
    return (
      <AvForm onValidSubmit={this.updateTable}>
        <div className="row">
          <div className="col-md-12 form-group">
            <Label for="szallitoId">Számlakibocsátó:</Label>
            {/* <Select.Async
              name="szallitoId"
              id="szallitoId"
              required
              placeholder="Kezdjen el gépelni..."
              noResultsText="Nincs keresési eredmény"
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingPlaceholder="Keresés..."
              onChange={v => this.handleChangeSzamlakibocsato(v)}
              clearable
              ignoreAccents={false}
              loadOptions={_.debounce((input, callback) => this.loadSzamlakibocsatoChange(input, callback), 1000)}
              isLoading={false}
              valueKey="id"
              labelKey="nev"
              value={this.state.szamlakiid}
              cache={false}
              autoload={false}
            /> */}
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="szallitoId"
              id="szallitoId"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeSzamlakibocsato(v)}
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadSzamlakibocsatoChange, 1000)}
              isLoading={false}
              getOptionLabel={option => option.nev}
              getOptionValue={option => option.id}
              value={this.state.szamlakiid}
              // disabled={this.state.fizTeljElerheto}
              cache={false}
              autoload={false}
            />
          </div>

          <div className="col-md-12">
            <AvGroup>
              <Label for="Szamlakombinaciofksz">Számlakibocsátó fk.sz.: </Label>
              <AvInput name="Szamlakombinaciofksz" type="number" id="Szamlakombinaciofksz" maxLength="10" onInput={this.maxLengthCheck} />
              <AvFeedback>Kötelező mező és maximum 4 karakter hosszú!</AvFeedback>
            </AvGroup>
          </div>

          <div className="col-md-12">
            <AvGroup>
              <Label for="Vevoszlellen">Vevő szl.ellensz. fk.sz.: </Label>
              <AvInput name="Vevoszlellen" type="number" id="Vevoszlellen" maxLength="10" onInput={this.maxLengthCheck} />
              <AvFeedback>Kötelező mező és maximum 4 karakter hosszú!</AvFeedback>
            </AvGroup>
          </div>

          <div className="col-md-12">
            <AvGroup>
              <Label for="Afafksz">ÁFA fksz.: </Label>
              <AvInput name="Afafksz" type="number" id="Afafksz" maxLength="10" onInput={this.maxLengthCheck} />
              <AvFeedback>Kötelező mező és maximum 4 karakter hosszú!</AvFeedback>
            </AvGroup>
          </div>

          <div className="col-md-3" style={{ marginRight: '-35px', left: '300px' }}>
            <Button className="margin-top-30" color="primary" >Mentés</Button>
          </div>
          <div className="col-md-9" style={{ left: '300px' }}>
            <Button type="button" color="secondary" onClick={this.toggleUjProfil} className="btn-cursor-pointer margin-top-30" >Mégse</Button>
          </div>
        </div>
      </AvForm>
    );
  }

  ModositProfil= () => {
    return (
      <AvForm onValidSubmit={this.updateMTable}>
        <div className="row">
          <div className="col-md-12 form-group">
            <Label for="szallitoId">Számlakibocsátó:</Label>
            {/* <Select.Async
              name="szallitoId"
              id="szallitoId"
              placeholder="Kezdjen el gépelni..."
              noResultsText="Nincs keresési eredmény"
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingPlaceholder="Keresés..."
              onChange={v => this.handleChangeSzamlakibocsatomodositas(v)}
              clearable
              ignoreAccents={false}
              loadOptions={_.debounce((input, callback) => this.loadSzamlakibocsatoChangemodositas(input, callback), 1000)}
              isLoading={false}
              disabled
              valueKey="szamlakibocsatoid"
              labelKey="szamlakibocsato"
              value={this.state.ModositoObj}
              cache={false}
              autoload={false}
            /> */}
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="szallitoId"
              id="szallitoId"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeSzamlakibocsatomodositas(v)}
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadSzamlakibocsatoChangemodositas, 1000)}
              isLoading={false}
              getOptionLabel={option => option.szamlakibocsato}
              getOptionValue={option => option.szamlakibocsatoid}
              value={this.state.ModositoObj}
              isDisabled
              cache={false}
              autoload={false}
            />
          </div>

          <div className="col-md-12">
            <AvGroup>
              <Label for="Szamlakombinaciofksz">Számlakibocsátó fk.sz.: </Label>
              <AvInput name="Szamlakombinaciofksz" value={this.state.ModositoObj.szallitofkviszam == null ? '0' : this.state.ModositoObj.szallitofkviszam} type="number" id="Szamlakombinaciofksz" maxLength="10" onInput={this.maxLengthCheck} />
              <AvFeedback>Kötelező mező és maximum 4 karakter hosszú!</AvFeedback>
            </AvGroup>
          </div>

          <div className="col-md-12">
            <AvGroup>
              <Label for="Vevoszlellen">Vevő szl.ellensz. fk.sz.: </Label>
              <AvInput name="Vevoszlellen" value={this.state.ModositoObj.vevofkviszam == null ? '0' : this.state.ModositoObj.vevofkviszam} type="number" id="Vevoszlellen" maxLength="10" onInput={this.maxLengthCheck} />
              <AvFeedback>Kötelező mező és maximum 4 karakter hosszú!</AvFeedback>
            </AvGroup>
          </div>

          <div className="col-md-12">
            <AvGroup>
              <Label for="Afafksz">ÁFA fksz.: </Label>
              <AvInput name="Afafksz" value={this.state.ModositoObj.afafkviszam == null ? '0' : this.state.ModositoObj.afafkviszam} type="number" id="Afafksz" maxLength="10" onInput={this.maxLengthCheck} />
              <AvFeedback>Kötelező mező és maximum 4 karakter hosszú!</AvFeedback>
            </AvGroup>
          </div>

          <div className="col-md-3" style={{ marginRight: '-35px', left: '300px' }}>
            <Button className="margin-top-30" color="primary" >Mentés</Button>
          </div>
          <div className="col-md-9" style={{ left: '300px' }}>
            <Button type="button" color="secondary" onClick={this.toggleModosítasProfil} className="btn-cursor-pointer margin-top-30" >Mégse</Button>
          </div>
        </div>
      </AvForm>
    );
  }

  renderTable = () => {
    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    return this.state.adatokJson.length ? (
      <BootstrapTable data={this.state.adatokJson} pagination options={options} >
        <TableHeaderColumn dataField="szamlakibocsato" tdStyle={{ whiteSpace: 'pre-line' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Számlakibocsátó</TableHeaderColumn>
        <TableHeaderColumn dataField="szallitofkviszam" tdStyle={{ whiteSpace: 'normal' }}>Számlakibocsátó fk.sz.</TableHeaderColumn>
        <TableHeaderColumn dataField="vevofkviszam" tdStyle={{ whiteSpace: 'normal' }}>Vevő szl. ellensz. fk.sz.</TableHeaderColumn>
        <TableHeaderColumn dataField="afafkviszam" tdStyle={{ whiteSpace: 'normal' }}>ÁFA. fk.sz</TableHeaderColumn>
        <TableHeaderColumn dataField="id" isKey width="100" dataFormat={this.iconFormatter}>Műveletek</TableHeaderColumn>
      </BootstrapTable>
    )
      : <DataEmptyState />;
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div><h5 style={{ marginBottom: 20 }}>Számla profil:</h5></div>
          </div>
          {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_SZAMLAPROFIL', 'KARB') && (
            <div className="col-md-3">
              <Button className="" onClick={this.toggleUjProfil} color="primary">Új profil</Button>
            </div>
          )}
        </div>

        <div className="row">
          <div className="col-md-12 margin-top-30">
            { this.state.adatokJson !== null ? this.renderTable() : <QueryEmptyState /> }
          </div>

        </div>

        {/* Modle ami Új profilt hoz létre */}
        <Modal isOpen={this.state.UjProfil} toggle={this.toggleUjProfil} className={this.props.className} backdrop="static">
          <ModalHeader>Új profil létrehozás</ModalHeader>
          <ModalBody>
            {this.UjProfil()}
          </ModalBody>
        </Modal>
        {/* Új profil létrehozásának a vége */}

        {/* Modle ami Profilt módosít */}
        <Modal isOpen={this.state.ModosítasProfil} toggle={this.toggleModosítasProfil} className={this.props.className} backdrop="static">
          <ModalHeader> Módosítás</ModalHeader>
          <ModalBody>
            {this.state.ModositoObj != null ? this.ModositProfil() : null}
          </ModalBody>
        </Modal>
        {/* Profilt módosítás vége */}

      </div>
    );
  }
}

SzamlaProfil.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool
};

export default SzamlaProfil;
