import React, { useState, useEffect } from 'react';
import SelectAsync from 'react-select/lib/Async';
import Select from 'react-select';
import _ from 'lodash';
import Services from '../services';
import { DateTimePicker } from 'react-widgets';
import moment, { relativeTimeThreshold } from 'moment';

const COL_STYLE = {marginBottom: '15px'};
const ROW_STYLE = {marginLeft: '15px', marginRight: '15px'};
const SzallitoiFilter = props => {

  const {
    setFilter
  } = props;

  const [startDate, setStartDate] = useState(new Date());
  const [partner, setPartner] = useState(null);
  const [business, setBusiness] = useState(null);
  const [status, setStatus] = useState(null);
  const [allapotOptions, setAllapotOptions] = useState([]);

  useEffect(() => {
      setFilter(
        {
          partnerTortenetId: partner && partner.id,
          cegadatId: business && business.id,
          allapot: status && status.name,
          kelte: startDate && moment(startDate).format("YYYY-MM-DD"),
        }
      );
  }, [startDate, partner, business, status]);

  useEffect(() => {
    loadAllapotOptions();
  }, []);

  const styles = {
    control: base => ({
      ...base,
      '&:focus': { borderColor: 'red' }, // border style on hover
      border: '1px solid lightgray', // default border color
      boxShadow: 'none', // no box-shadow
      borderRadius: '0'
    }),
  };

  const loadPartnerOptions = (input, callback) => {
    if(_.isEmpty(input)) callback([]);
    Services.listPartner({type: 'RENDELES_SZALLITO_AKTIV_GET', text: input}, (err, res) => {
      if(!err){
        const options = res[0].filter( k => k.nev.toLowerCase().includes(input.toLowerCase()))
        callback(options);
      }else{
        callback([]);
      }
    });
  }

  const loadCegadatOptions = (input, callback) => {
    if(_.isEmpty(input)) callback([]);
    Services.listCegadat({}, (err, res) => {
      if(!err){
        const options = res[0].filter( k => k.nev.toLowerCase().includes(input.toLowerCase()))
        callback(options);
      }else{
        callback([]);
      }
    });
  }

  const loadAllapotOptions = async () => {
    const options = await Services.listAllapotSzallitoiRendeles({});
    setAllapotOptions(options);
  }

  return(
      <React.Fragment>
        <div style={{ marginLeft: 10, marginTop: 10 }}>Kelte (-ig): </div>
        <div className="col-md-2" style={COL_STYLE}>
          <DateTimePicker
            id="startDate"
            name="startDate"
            value={startDate && new Date(startDate)}
            onChange={(e,v) => setStartDate(v === '' ? null : v)}
            containerClassName="organw-date-time"
            time={false}
            format="YYYY-MM-DD"
            messages={{ dateButton: "dátum" }}
          />
        </div>
        <div className="col-md-2" style={COL_STYLE}>
          <SelectAsync
            styles={styles}
            name="partner"
            id="partner"
            placeholder="Partner..."
            noOptionsMessage={() => 'Nincs keresési eredmény'}
            onChange={v => setPartner(v)}
            loadOptions={_.debounce(loadPartnerOptions, 1000)}
            getOptionLabel={option => option.nev + ' (' + option.cim + ')'}
            getOptionValue={option => option.id}
            value={partner}
            isClearable
          />
        </div>
        <div className="col-md-2" style={COL_STYLE}>
          <SelectAsync
            styles={styles}
            name="business"
            id="business"
            placeholder="Cégadat..."
            noOptionsMessage={() => 'Nincs keresési eredmény'}
            onChange={v => setBusiness(v)}
            loadOptions={_.debounce(loadCegadatOptions, 1000)}
            getOptionLabel={option => option.nev + ' (' + option.cim + ')'}
            getOptionValue={option => option.id}
            value={business}
            defaultOptions
            isClearable
          />
        </div>
        <div className="col-md-2" style={COL_STYLE}>
          <Select
            styles={styles}
            name="status"
            id="status"
            placeholder="Állapot..."
            noOptionsMessage={() => 'Nincs keresési eredmény'}
            onChange={v => setStatus(v)}
            options={allapotOptions}
            getOptionLabel={option => option.caption}
            getOptionValue={option => option.name}
            value={status}
            defaultOptions
            isClearable
          />
        </div>
    </React.Fragment>
  );
}

export default SzallitoiFilter;