import React, { useState, useEffect } from 'react';
import Select from 'react-select/lib/Async';
import _ from 'lodash';
import Services from '../services';
import { DateTimePicker } from 'react-widgets';
import moment, { relativeTimeThreshold } from 'moment';

const COL_STYLE = {marginBottom: '15px'};
const ROW_STYLE = {marginLeft: '15px', marginRight: '15px'};
const AjanlatFilter = props => {

  const {
    setFilter
  } = props;


  const [partner, setPartner] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState();

  useEffect(() => {
      setFilter(
        {
          partnerTortenetId: partner && partner.id,
          kezdete: startDate && moment(startDate).format("YYYY-MM-DD"),
          vege: endDate && moment(endDate).format("YYYY-MM-DD"),
        }
      );
  }, [startDate, endDate, partner]);

  const styles = {
    control: base => ({
      ...base,
      '&:focus': { borderColor: 'red' }, // border style on hover
      border: '1px solid lightgray', // default border color
      boxShadow: 'none', // no box-shadow
      borderRadius: '0'
    }),
  };

  const loadPartnerOptions = (input, callback) => {
    if(_.isEmpty(input)) callback([]);
    Services.listPartner({type: 'RENDELES_VEVOI_CIM_AKTIV_GET', text: input}, (err, res) => {
      if(!err){
        const options = res[0].filter( k => k.nev.toLowerCase().includes(input.toLowerCase()))
        callback(options);
      }else{
        callback([]);
      }
    });
  }

  return(
      <React.Fragment>
        <div style={{ marginLeft: 10, marginTop: 10}}>Ügyfél: </div>
        <div className="col-md-2" style={COL_STYLE}>
          <Select
            styles={styles}
            name="partner"
            id="partner"
            placeholder="Partner..."
            noOptionsMessage={() => 'Nincs keresési eredmény'}
            onChange={v => setPartner(v)}
            loadOptions={_.debounce(loadPartnerOptions, 1000)}
            getOptionLabel={option => option.nev + ' (' + option.cim + ')'}
            getOptionValue={option => option.id}
            value={partner}
            isClearable
          />
        </div>
        <div style={{marginTop: 10}}>Érvényesség kezdete:</div>
        <div className="col-md-2" style={COL_STYLE}>
          <DateTimePicker
            id="startDate"
            name="startDate"
            value={startDate && new Date(startDate)}
            onChange={(e,v) => setStartDate(v === '' ? null : v)}
            containerClassName="organw-date-time"
            time={false}
            format="YYYY-MM-DD"
            messages={{ dateButton: "dátum" }}
          />
        </div>
        <div style={{marginTop: 10}}>Érvényesség vége:</div>
        <div className="col-md-2" style={COL_STYLE}>
          <DateTimePicker
            id="endDate"
            name="endDate"
            value={endDate && new Date(endDate)}
            onChange={(e,v) => setEndDate(v === '' ? null : v)}
            containerClassName="organw-date-time"
            time={false}
            format="YYYY-MM-DD"
            messages={{ dateButton: "dátum" }}
          />
        </div>
    </React.Fragment>
  );
}

export default AjanlatFilter;