import React, {Component} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input} from 'reactstrap';
import {AvForm, AvGroup, AvInput, AvFeedback} from 'availity-reactstrap-validation';
import Select from 'react-select/lib/Async';
import Service from './services.js';
import CreatableSelect  from 'react-select/lib/Creatable';
import _ from 'lodash';
import KeszletInfo from '../../../../commons/KeszletInfo';
import {CustomOption} from '../../../../commons/customOption';
import NumberFormatter from '../../../../commons/NumberFormatter.js';
import SlaveAvInput from '../../../../commons/SlaveAvInput.js';

class CikkSelect extends Component {

  constructor(props) {
    super(props);
    this.state = {

      id: '',
      megnevezes: '',
      cikkszam: '',
      mennyisegiEgyseg: '',
      aktualisAfaErtek: '',
      cikk: undefined,

      hasMennyiseg: false,
      mennyiseg: '',
      nettoEgysegAr: '',
      nettoBeszAr: '',
      bruttoBeszAr: '',
      bruttoEgysegAr: '',
      pluszOsszeg: '',
      selejtezesOka: '',
      options: [],
      selejtezesOkaOptions: [],
      mennyisegDisabled: true,
      beviteliMezo: 'netto',
    };
  }

  componentDidMount() {
    const selejtezes = this.props.mozgasTipus && this.props.mozgasTipus.id === 'SELEJT';
    if (selejtezes) {
      this.loadSelejtezesOkaOptions();
    }
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.editobj, this.props.editobj) || prevProps.editmode != this.props.editmode) {
      let cikkObj;
      const hasCikk = this.props.editobj && this.props.editobj.cikk;
      if (hasCikk) {
        cikkObj = {
          id:  this.props.editobj.cikkid,
          megnevezes:  this.props.editobj.cikk.megnevezes,
          cikkszam:  this.props.editobj.cikk.cikkszam,
          mennyisegiEgyseg:  this.props.editobj.cikk.mennyisegiEgyseg,
          aktualisAfaErtek: new String( this.props.editobj.cikk.aktualisAfaErtek),
        };
      }

      let editobj = this.prepareObject(this.props.editobj);

      const newState = {
        cikk: cikkObj,
        id: hasCikk ? editobj.cikkid : '',
        megnevezes: hasCikk ? editobj.cikk.megnevezes : '',
        cikkszam: hasCikk ? editobj.cikk.cikkszam : '',
        mennyisegiEgyseg: hasCikk ? editobj.cikk.mennyisegiEgyseg : '',
        aktualisAfaErtek: hasCikk ? editobj.cikk.aktualisAfaErtek : '',
        hasMennyiseg: editobj.hasMennyiseg,
        mennyiseg: editobj.mennyiseg,
        nettoEgysegAr: editobj.egysegarnetto,
        nettoBeszAr: editobj.beszerzesnetto,
        bruttoBeszAr: editobj.beszerzesbrutto,
        bruttoEgysegAr: editobj.egysegarbrutto,
        pluszOsszeg: editobj.pluszosszeg,
        selejtezesOka: editobj.selejtezesOka ? {label: editobj.selejtezesOka.nev, value: editobj.selejtezesOka.id} : null,
        mennyisegDisabled: !this.props.editmode,
        hasMennyiseg: this.props.editmode,
      };

      this.setState({...newState});
    }
  }

  clearValues = () => {
    this.setState({
      cikk: undefined,
      cikkszam: '',
      megnevezes: '',
      mennyisegiEgyseg: '',
      mennyisegDisabled: true,
      mennyiseg: '',
      hasMennyiseg: false,
      nettoEgysegAr: '',
      aktualisAfaErtek: '',
      bruttoEgysegAr: '',
      nettoBeszAr: '',
      bruttoBeszAr: ''
    });
  }

  loadSelejtezesOkaOptions = (input, callback) => {
    Service.listSelejtezesOka(null, (err, res) => {
      if (!err) {
        this.setState({
          selejtezesOkaOptions: res[0].map(k => ({value: k.id, label: k.nev}))
        });
      }
    });
  }

  prepareObject = (editobj) => {
    if (editobj) {
      Object.keys(editobj).forEach(field => {
        if (editobj[field] === 0) {
          editobj[field] = '0';
        }
      });
    } else {
      editobj = {};
    }
    return editobj; 
  }

  handleCikkChange = (newValue) => {
    let emptyObj = {
      cikk: undefined,
      cikkszam: '',
      megnevezes: '',
      mennyisegiEgyseg: '',
      aktualisAfaErtek: ''
    }
    if (newValue === null) {
      this.setState({...emptyObj, mennyisegDisabled: true});
    } else {
      if(this.props.keszletinfo) this.props.changeCikkId(newValue.id);

      const newState = {
        cikk: {
          megnevezes: newValue.megnevezes,
          cikkszam: newValue.cikkszam,
          id: newValue.id
        },
        id: newValue.id,
        megnevezes: newValue.megnevezes,
        cikkszam: newValue.cikkszam,
        mennyisegiEgyseg: newValue.mennyisegiEgyseg,
        aktualisAfaErtek: newValue.aktualisAfaErtek,
      };

      this.setState({...newState, mennyisegDisabled: false}, () => {
        if (this.props.price) this.setPrices(this.refs.egysegarnetto.state.value, this.refs.mennyiseg.state.value);
      });

    }
  }

  handleUpdateCikkInput = (text, callback) => {
    let newArray = [];
    if (text.length === 0) {
      callback(newArray);
    } else {
      if (this.props.fromLeltar) {
        Service.queryCikk(text, JSON.parse("false"), false, (err, res) => {
          if (!err) {
            res[0].forEach(res => {
              if (!this.props.excludeCikkIds || !this.props.excludeCikkIds.includes(res.id))
                newArray.push(res);
            })
            callback(newArray);
          }
        });
      } else {
        Service.queryCikk(text, JSON.parse("false"), false, (err, res) => {
          if (!err) {
            res[0].forEach(res => {
              newArray.push(res);    
            })
            callback(newArray);
          }
        });
      }
    }
  }

  handleMennyiseg = (event, value) => {
    value = NumberFormatter.removeSpacesFromString(value);
    if (value && value > 0)
      this.setState({ mennyiseg: value });
    if (this.props.price) {
      let egysegarnetto = this.refs.egysegarnetto.state.value;
      let bruttoBeszAr = this.state.bruttoBeszAr;
      if (value && value > 0) {
        this.setState({hasMennyiseg: true}, () => {
          if (this.state.beviteliMezo === 'netto') {
            this.setPrices(egysegarnetto ? egysegarnetto : 0, value);
          } else if (this.state.beviteliMezo === 'brutto') {
            bruttoBeszAr = bruttoBeszAr ? bruttoBeszAr : 0;
            egysegarnetto = this.bruttoToNettoConvert(bruttoBeszAr, value);
            this.setPrices(egysegarnetto, value, bruttoBeszAr);
          }
        });
      } else {
        this.setState({hasMennyiseg: false});
      }
    } else {
      this.setState({hasMennyiseg: true});
    }
  }

  bruttoToNettoConvert = (value, mennyiseg) => {
    return (Math.round(Number( value / mennyiseg / (1 + this.state.aktualisAfaErtek / 100)) * 100) / 100).toFixed(6);
  }

  handlePluszOsszeg = (event, value) => {
    this.setState({ pluszOsszeg: NumberFormatter.removeSpacesFromString(value)});
  }

  handleNettoEgysegAr = (event, value) => {
    this.setState({ beviteliMezo: 'netto' }, () => {
      this.setPrices(value, this.refs.mennyiseg.state.value);
    });
  }

  handleBruttoBeszAr = (event, value) => {
    this.setState({ beviteliMezo: 'brutto' }, () => {
      value = NumberFormatter.removeSpacesFromString(value);
      if (value != null)  {
        if (value[value.length - 1] == "."){
          value = value.substr(0, value.length - 1);
        }
        let mennyiseg = this.refs.mennyiseg.state.value;
        let nettoEgysegAr = this.bruttoToNettoConvert(value, mennyiseg);
        this.setPrices(nettoEgysegAr, mennyiseg, value);
      }
    });
  }

  handleCreateSelejtezesOka = (newSelejtezesOka) => {
    Service.addSelejtezesOka({kod: newSelejtezesOka, nev: newSelejtezesOka}, (err, res) => {
      if (!err) {
        const selejtezesOkaOptions = this.state.selejtezesOkaOptions;
        this.setState({ 
          selejtezesOkaOptions: [...selejtezesOkaOptions, { value: res[0].id, label: res[0].kod}]
        });
      }
    });  
  }

  deleteSelejtezesOka = (id) => {
    Service.deleteSelejtezesOka(id, (err, res) => {
      if (!err) {
        this.loadSelejtezesOkaOptions();
      }
    });  
  }

  setPrices = (value, mennyiseg, brutto) => {
    if (typeof value == "string") value = NumberFormatter.removeSpacesFromString(value);
    const tizedesJegy = this.props.penznem ? this.props.penznem.tizedesjegy : 6;
    const tizedesSzorzo = tizedesJegy == 0 ? 1 : tizedesJegy;
    let nettoBeszAr =  (Math.round((Number(value) * mennyiseg) * Math.pow(10, tizedesSzorzo)) / Math.pow(10, tizedesSzorzo)).toFixed(tizedesJegy);
    let bruttoEgysegAr = (Math.round(Number(value * (1 + (this.state.aktualisAfaErtek / 100))) * Math.pow(10, 6)) / Math.pow(10, 6)).toFixed(6);
    let bruttoBeszAr;
    if (!brutto) bruttoBeszAr =  (Math.round(Number(value * (1 + (this.state.aktualisAfaErtek / 100))) * mennyiseg * Math.pow(10, tizedesSzorzo)) / Math.pow(10, tizedesSzorzo)).toFixed(tizedesJegy);
    if (bruttoBeszAr === 0) bruttoBeszAr = '';
    if (value === 0) value = '';
    this.setState({
      nettoEgysegAr: value,
      nettoBeszAr:  nettoBeszAr,
      bruttoEgysegAr: bruttoEgysegAr,
      bruttoBeszAr: brutto ? brutto : bruttoBeszAr
    });
  }

  checkCikkIds = (event, cikkObj) => {
    cikkObj.pluszOsszeg = NumberFormatter.removeSpacesFromString(cikkObj.pluszOsszeg);
    cikkObj.cikkid = this.state.id;
    cikkObj.beszerzesbrutto = this.state.bruttoBeszAr;
    cikkObj.beviteliMezo = this.state.beviteliMezo;
    cikkObj.selejtezesOka = this.state.selejtezesOka ? {id: this.state.selejtezesOka.value, nev: this.state.selejtezesOka.label, kod: this.state.selejtezesOka.label} : null;
    cikkObj.selejtezesOkaId = this.state.selejtezesOka ? this.state.selejtezesOka.value : null;
    cikkObj.id = this.props.editobj && this.props.editobj.id;
    if (cikkObj.beszerzesbrutto === ''){
      cikkObj.beszerzesbrutto = 0;
    }
    cikkObj.cikk = {
      id: this.state.id,
      megnevezes: cikkObj.megnevezes,
      cikkszam: cikkObj.cikkszam,
      mennyisegiEgyseg: cikkObj.mennyisegiEgyseg,
      aktualisAfaErtek: NumberFormatter.removeSpacesFromString(cikkObj.aktualisAfaErtek)
    }

    if (this.props.keszletinfo && this.props.raktar && !this.props.raktar.id) {
      this.props.globals.addWarning('Kötelező raktár választása a készletinfomáció során!');
      return;
    }

    if ((this.props.editobj && this.props.editobj.cikkid !== cikkObj.cikkid) && this.hasArrayElement(cikkObj.cikkid)) {
      this.props.globals.addWarning('Ez a cikk már hozzá van rendelve!');
      return;
    }

    if (this.props.editmode) {
      this.props.edit(event, cikkObj);
    } else {
      this.props.add(event, cikkObj);
    }
  }

  hasArrayElement = (id) => {
    let filteredArray = this.props.cikkek.filter(item => item.cikkid === id);
    return filteredArray.length > 0;
  }

  formatCreateLabel = input => (
    <span>
      <i>Hozzáadás: </i>
      {input}
    </span>
  );

  render() {
    const bevetelezes = this.props.mozgasTipus && this.props.mozgasTipus.id === 'BEV';
    const selejtezes = this.props.mozgasTipus && this.props.mozgasTipus.id === 'SELEJT';
    const tizedesJegy = this.props.penznem ? this.props.penznem.tizedesJegy : 6;
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} backdrop="static">
        <AvForm onValidSubmit={this.checkCikkIds}>
          <ModalHeader>Cikk kiválasztása</ModalHeader>
          <ModalBody>
            <Label for="cikknev">{'Név:'}</Label>
            <div className="row">
              <div className={this.props.keszletinfo ? "col-md-8" : "col-md-12" }>
                <Select
                  styles={{
                    control: (base) => ({
                      ...base,
                      '&:focus': { borderColor: 'red' },
                      border: '1px solid lightgray',
                      boxShadow: 'none',
                      borderRadius: '0'
                    }),
                  }}
                  name="cikknev"
                  id="cikknev"
                  placeholder="Kezdjen el gépelni a kereséshez..."
                  noOptionsMessage={() => "Nincs keresési eredmény"}
                  searchPromptText="Kezdjen el gépelni a kereséshez..."
                  loadingMessage={() => "Keresés..."}
                  onChange={this.handleCikkChange}
                  loadOptions={_.debounce(this.handleUpdateCikkInput, 1000)}
                  value={this.state.cikk != {} ? this.state.cikk  : undefined}
                  isClearable
                  isLoading={false}
                  ignoreAccents={false}
                  cache={false}
                  autoload={false}
                  getOptionValue={ option => option.id}
                  getOptionLabel={ option => (option.megnevezes  + " - " + option.cikkszam)} // - option.cikkszam
                />
              </div>
              { this.props.keszletinfo && this.state.id &&
              <div className="col-md-2">
                  <KeszletInfo
                    buttonText="Készletinformáció"
                    handleListItemClick={this.props.handleListItemClick}
                    selectedSzabad={this.props.selectedSzabad}
                    onSave={this.props.onSave}
                    getData={this.props.getData}
                    formKeszletCikkId={this.state.id}
                    formKeszletRaktarId={this.props.raktar}
                    formKeszletCikkszam={this.state.cikkszam}
                    formKeszletCikknev={this.state.megnevezes}
                    raktarak={this.props.formKeszletInfoRaktarak}
                    loadingStatus={this.props.formKeszletLoadingStatus}
                    disabledButton={this.state.mennyisegDisabled}
                    />
              </div>
              }
            </div>
            <AvGroup>
              <Label style={{ paddingTop: 8 }} for="cikkszam">Cikkszám:</Label>
              <AvInput name="cikkszam" id="cikkszam" disabled value={this.state.cikkszam} />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
            <AvGroup>
              <Label for="megnevezes">Megnevezés:</Label>
              <AvInput name="megnevezes" id="megnevezes" disabled value={this.state.megnevezes} />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
            <AvGroup>
              <Label for="mennyisegiEgyseg">Mennyiségi egység:</Label>
              <AvInput name="mennyisegiEgyseg" id="mennyisegiEgyseg" disabled value={this.state.mennyisegiEgyseg} />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
            <SlaveAvInput
              id="mennyiseg"
              label="Mennyiség:"
              disabled={this.state.mennyisegDisabled}
              onChange={this.handleMennyiseg}
              value={this.state.mennyiseg}
              required
              szuroTipus={NumberFormatter.MENNYISEG}
              emptyStringOnZero
            />
            <AvInput style={{display: 'none'}} name="mennyiseg" ref="mennyiseg" value={this.state.mennyiseg} />
            {this.props.price && (
            <React.Fragment>
              <div className="row">
                <div className="col-md-3">
                  Egységár
                </div>
                <div className="col-md-3">
                  <SlaveAvInput
                    id="egysegarnetto"
                    label="Nettó:"
                    required
                    disabled={!this.state.hasMennyiseg}
                    value={this.state.nettoEgysegAr}
                    onChange={this.handleNettoEgysegAr}
                    szuroTipus={NumberFormatter.EGYSEGAR}
                    emptyStringOnZero
                  />
                  <AvInput style={{display: 'none'}} name="egysegarnetto" ref="egysegarnetto" value={this.state.nettoEgysegAr} />
                </div>
                <div className="col-md-2">
                  <SlaveAvInput
                    id="aktualisAfaErtek"
                    label="ÁFA%:"
                    required
                    disabled
                    value={this.state.aktualisAfaErtek}
                    szuroTipus={NumberFormatter.OSSZEG}
                  />
                </div>
                <div className="col-md-3">
                  <SlaveAvInput
                    id="egysegarbrutto"
                    label="Bruttó:"
                    required
                    disabled
                    value={this.state.bruttoEgysegAr}
                    szuroTipus={NumberFormatter.EGYSEGAR}
                  />
                  <AvInput
                    style={{display: 'none'}}
                    name="egysegarbrutto"
                    id="egysegarbrutto"
                    required
                    // pattern={"^\d+[.]?\d{0,6}?$"}
                    disabled
                    value={this.state.bruttoEgysegAr} /* onChange={this.handleBruttoEgysegAr} */
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  Beszerzési ár
                </div>
                <div className="col-md-3">
                  <SlaveAvInput
                    id="beszerzesnetto"
                    label="Nettó:"
                    ref="beszerzesnetto"
                    required
                    disabled
                    value={this.state.nettoBeszAr}
                    szuroTipus={NumberFormatter.OSSZEG}
                  />
                  <AvInput style={{display: 'none'}} name="beszerzesnetto" ref="beszerzesnetto" value={this.state.nettoBeszAr} />
                </div>
                <div className="col-md-2">
                  <SlaveAvInput
                    id="aktualisAfaErtek"
                    label="ÁFA%:"
                    required
                    disabled
                    value={this.state.aktualisAfaErtek}
                    szuroTipus={NumberFormatter.OSSZEG}
                  />
                </div>
                <div className="col-md-3">
                  <SlaveAvInput
                    id="beszerzesbrutto"
                    label="Bruttó:"
                    required
                    disabled={!this.state.hasMennyiseg}
                    value={this.state.bruttoBeszAr}
                    onChange={this.handleBruttoBeszAr}
                    szuroTipus={NumberFormatter.OSSZEG}
                    emptyStringOnZero
                  />
                  <AvInput style={{display: 'none'}} name="beszerzesbrutto" value={this.state.bruttoBeszAr} />
                </div>
              </div>
            </React.Fragment>
            )}
            {this.props.price && bevetelezes && (
              <React.Fragment>
                <div className="row">
                  <div className="col-md-3">
                    Bekerülési értéket növelő összeg:
                  </div>
                  <div className="col-md-3">
                    <SlaveAvInput
                      id="pluszosszeg"
                      label="Nettó:"
                      value={this.state.pluszOsszeg}
                      onChange={this.handlePluszOsszeg}
                      szuroTipus={NumberFormatter.OSSZEG}
                    />
                  <AvInput style={{display: 'none'}} name="pluszosszeg" ref="pluszosszeg" value={this.state.pluszOsszeg} />
                  </div>
                </div>
              </React.Fragment>
            )}
            {selejtezes && (
              <React.Fragment> 
                <div className="row">
                  <div className="col-md-12" style={{paddingRight: '2px'}}>
                    <Label for="selejtezesoka">Selejtezés oka:</Label>
                    <CreatableSelect
                      styles={{
                        control: base => ({
                          ...base,
                          '&:focus': { borderColor: 'red' }, // border style on hover
                          border: '1px solid lightgray', // default border color
                          boxShadow: 'none', // no box-shadow
                          borderRadius: '0'
                        }),
                      }}
                      name="selejtezesoka"
                      id="selejtezesoka"
                      placeholder="Kezdjen el gépelni..."
                      noOptionsMessage={() => 'Nincs keresési eredmény'}
                      formatCreateLabel={this.formatCreateLabel}
                      onChange={(v,t) => {this.setState({selejtezesOka: v})}}
                      onCreateOption={this.handleCreateSelejtezesOka}
                      onDeleteOption={this.deleteSelejtezesOka}
                      options={this.state.selejtezesOkaOptions}
                      getOptionLabel={option => option.label}
                      getOptionValue={option => option.value}
                      components={{Option: CustomOption}}
                      value={this.state.selejtezesOka}
                      isClearable
                      defaultOptions
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" disabled={!this.state.hasMennyiseg}> {this.props.editmode ? 'Mentés' : 'Hozzáadás'}</Button>{' '}
            <Button type="button" color="secondary" onClick={this.props.toggle} >Mégse</Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    )
  }
}

export default CikkSelect;
