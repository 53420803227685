(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("prop-types"), require("emotion"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "prop-types", "emotion"], factory);
	else {
		var a = typeof exports === 'object' ? factory(require("react"), require("prop-types"), require("emotion")) : factory(root["react"], root["prop-types"], root["emotion"]);
		for(var i in a) (typeof exports === 'object' ? exports : root)[i] = a[i];
	}
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__185__) {
return 