import React from 'react';
import PropTypes from 'prop-types';
import RaktarLista from './RaktarLista';
import getTooltip from './RaktarTooltips';
import CustomTooltip from '../../../components/Tooltip/CustomTooltip';

const cardStyle = {
  padding: 20
};

const Raktar = (props) => {
  return (
    <div>
      <div className="animated fadeIn">
        <div className="card">
          <div className="card-header">
            {'Raktár'}
            <CustomTooltip text={getTooltip('raktar')} id="raktarTT" />
          </div>
          <div className="row" style={cardStyle}>
            <div className="col-md-12">
              <RaktarLista globals={props.route.globals} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Raktar.propTypes = {
  route: PropTypes.object,
  globals: PropTypes.object
};

export default Raktar;
