import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  ListGroup,
  ListGroupItem,
  Badge,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from 'reactstrap';
import Select from 'react-select/lib/Async';
import { components } from 'react-select';
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback
} from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DataEmptyState from '../../commons/dataemptystate';
import { PaginationTotal } from '../../commons/controls';
import Services from './services';
import Loader from '../../commons/loader';
import NetworkEmptyState from '../../commons/networkemptystate';
import QueryEmptyState from '../../commons/queryempty';
import Option from './Option';
import Lib from '../../commons/lib';
import NumberFormatter from '../../commons/NumberFormatter';
import SlaveAvInput from '../../commons/SlaveAvInput';

// 0 -> Felvitel
// 1 -> módosítás
let _muvelet = 0;

class BanktetelAnalitikaTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ['QUERY', 'LOAD', 'DATA', 'NETW', 'TABL']
      currentStatusBaseTable: 'QUERY',
      dropdownOpen: false,
      // ['LOAD', 'LOADED', 'NETW']
      // currentStatusBaseView: 'LOAD',
      // dataBaseViewLoaded: true,
      currentBizszamok: [],
      formSzamlaszam: [],
      formTipus: [],
      formTipusValue: null,
      formAllapotValue: null,
      formPartner: '',
      formSzamlakibocsato: '',
      disableButtons: true,
      // PARENT VALUES
      currentId: null,
      currentPenznem: null,
      currentTipus: null,
      // MODAL CONTROLL
      modalBaseView: false,
      modalBaseNew: false,
      modalBaseDelete: false,
      modalBaseDeleteAll: false,
      baseJson: [],
      arfolyamVisible: false,
      /*
       *  FORM VALUES
       */
      osszeg: 0,
      fkviszam: null,
      bankszamlatortenetid: null,
    };
  }

  componentDidMount() {
    this.getEnums();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.tableData !== nextProps.tableData) {
      this.setState({
        parentId: nextProps.tableData.id,
        currentStatusBaseTable: 'LOAD',
        currentPenznem: nextProps.tableData.penznem,
        currentPenznemId: nextProps.tableData.penznemId
      });
      Services.listAnalitika(nextProps.tableData.id, (err, res) => {
        if (!err) {
          this.setState({
            baseJson: res[0],
            disableButtons: true,
            currentStatusBaseTable: 'TABL',
            currentId: nextProps.tableData.id,
            currentPenznem: nextProps.tableData.penznem,
            currentPenznemId: nextProps.tableData.penznemId,
            currentTipus: nextProps.tableData.tipus
          });
        } else {
          this.setState({
            currentStatusBaseTable: 'QUERY'
          });
        }
      });
    }

    if (this.props.tableStatus !== nextProps.tableStatus) {
      if (nextProps.tableStatus && nextProps.tableStatus.tetel) {
        if (nextProps.tableStatus.tetel === 'RELOAD') {
          this.setState({
            currentStatusBaseTable: 'QUERY',
            baseJson: [],
            disableButtons: true,
            currentId: null,
            currentPenznem: null,
            currentTipus: null
          });
          this.props.tableStatusChange({
            fej: 'LOADED',
            tranzakcio: 'LOADED',
            tetel: 'LOADED'
          });
        }
      }
    }
  }

  getEnums = () => {
    Services.getEnums((err, res) => {
      let tipusOptions = [];
      if (res[0][1]) {
        tipusOptions = res[0][4].map(e => (
          <option key={e.name} value={e.name}>
            {e.caption}
          </option>
        ));
      }
      this.setState({
        formTipus: tipusOptions,
      });
    });
  }

  addAnalitika = (event, bankfejObj) => {
    let submitObj = bankfejObj;
    submitObj.tipus = bankfejObj.formTipusValue;
    submitObj.tranzakcioentity = {
      id: this.state.currentId
    };
    if (this.state.formPartner) {
      if (this.state.formPartner.id) {
        submitObj.partnertortid = this.state.formPartner.id;
      } else {
        submitObj.partnertortid = null;
      }
    }
    if (this.state.formBizonylatszam) submitObj.szamlaid = this.state.formBizonylatszam.id;
    if (this.state.formBizonylatszam) submitObj.szamlahely = this.state.formBizonylatszam.hely;

    submitObj.osszeg = this.state.osszeg.replace(/\s/g, '');

    Services.addAnalitika(submitObj, submitObj.arfolyam, (err) => {
      if (!err) {
        this.toggleBaseNew();
        this.updateBaseTable();
        this.props.globals.addSuccess('Bankkivonat tétel felvéve!');
      }
    });

    // Services.addAnalitika(submitObj, (err) => {
    //   if (!err) {
    //     this.toggleBaseNew();
    //     this.updateBaseTable();
    //     this.props.globals.addSuccess('Bankkivonat tétel felvéve!');
    //   }
    // });
  }

  modifyBanktetel = () => {
    let submitObj = {
      id: this._currentEditId,
      tranzakcioentity: {
        id: this.state.currentId
      },
      osszeg: this.state.osszeg.replace(/\s/g, ''),
      szamlaid: this.state.formBizonylatszam.id,
      szamlahely: this.state.formBizonylatszam.hely,
      tipus: this.state.formTipusValue,
      allapot: this.state.formAllapotValue,
      fkviszam: this.state.fkviszam,
      bankszamlatortenetid: this.state.bankszamlatortenetid,
    };

    if (this.state.formPartner) {
      if (this.state.formPartner.id) {
        submitObj.partnertortid = this.state.formPartner.id;
      } else {
        submitObj.partnertortid = null;
      }
    }

    Services.editAnalitika(this._currentEditId, submitObj, (err) => {
      if (!err) {
        this.toggleBaseNew();
        this.updateBaseTable();
        this.props.globals.addSuccess('Bankkivonat tétel módosítva!');
      }
    });
  }

  toggleBaseView = () => {
    this.setState(prevState => ({
      modalBaseView: !prevState.modalBaseView
    }));
  }

  toggleBaseNew = () => {
    this.setState(prevState => ({
      arfolyamVisible: false,
      modalBaseNew: !prevState.modalBaseNew,
      osszeg: 0,
      formPartner: '',
      formSzamlakibocsato: '',
      formBizonylatszam: null
    }));
  }

  toggleBaseDelete = () => {
    this.setState(prevState => ({
      modalBaseDelete: !prevState.modalBaseDelete
    }));
  }

  toggleBaseDeleteAll = () => {
    this.setState(prevState => ({
      modalBaseDeleteAll: !prevState.modalBaseDeleteAll
    }));
  }

  updateBaseTable = () => {
    this.setState({
      currentStatusBaseTable: 'LOAD'
    });
    Services.listAnalitika(this.state.parentId, (err, res) => {
      if (!err) {
        this.setState({ baseJson: res[0], currentStatusBaseTable: 'TABL', disableButtons: true, });
      } else {
        this.setState({
          currentStatusBaseTable: 'QUERY'
        });
      }
    });
  }

  handleBaseViewClick = () => {
    this.toggleBaseView();
  }

  handleBaseNewClick = () => {
    this.setState({
      formTipusValue: null,
      formAllapotValue: null,
      formSzamlaszam: [],
      bankszamlatortenetid: null
    }, () => {
      _muvelet = 0;
      this.toggleBaseNew();
    });
  }

  bizonylatszamItemClick = (item) => {
    Services.getCheck(this.state.currentId, item.penznem, (err, res) => {
      if (!err) {
        this.setState({
          arfolyamVisible: res[0].toString() === 'true',
          dropdownOpen: false,
          formBizonylatszam: {
            hely: item.hely,
            id: item.id,
            nev: item.bizonylatszam + ' (' + item.kibocsato + ')',
            penz: item.penznem
          },
          fkviszam: item.fkviszam,
        });
      }
    });
  }

  handleBaseEditClick = (cell) => {
    _muvelet = 1;
    this._currentEditId = cell;
    Services.getAnalitika(cell, (err, res) => {
      if (!err) {
        let bankszamlak = [];
        if (res[0].szamlabean) {
          res[0].szamlabean.bankszamla.forEach((item) => {
            bankszamlak.push(
              <option value={item.id.toString()}>
                {item.szamlaszam + ' (' + item.penzintezet + ')'}
              </option>
            );
          });
        }
        this.setState({
          osszeg: res[0].osszeg.toString(),
          formPartner: { id: res[0].szamlabean.id, nev: res[0].szamlabean.nev },
          formTipusValue: res[0].tipus.name,
          formAllapotValue: res[0].allapot.name,
          formSzamlaszam: bankszamlak,
          bankszamlatortenetid: res[0].bankszamlatortenetid,
          fkviszam: res[0].fkviszam,
          formSzamlakibocsato: '',
          formBizonylatszam: {
            hely: res[0].szamlabean && res[0].szamlabean.szamla[0] && res[0].szamlabean.szamla[0].hely,
            id: res[0].szamlabean && res[0].szamlabean.szamla[0] && res[0].szamlabean.szamla[0].id,
            nev: res[0].szamlabean && res[0].szamlabean.szamla[0] && res[0].szamlabean.szamla[0].bizonylatszam + ' (' + res[0].szamlabean.szamla[0].osszeg + ' ' + res[0].szamlabean.szamla[0].penznem + ')\n'
          }
        }, () => {
          this.setState(prevState => ({
            modalBaseNew: !prevState.modalBaseNew,
          }));
        });
      }
    });
  }

  handleBaseDeleteClick = (id) => {
    this._currentDeleteId = id;
    this.toggleBaseDelete();
  }

  handleBaseDeleteAllClick = () => {
    this.toggleBaseDeleteAll();
  }

  handleChangeSzamlakibocsato = (value) => {
    if (value && value.length !== 0) {
      this.setState({
        formSzamlakibocsato: value,
      });
    } else {
      this.setState({ formSzamlakibocsato: '' });
    }
  }

  isFormtypeSzamlaOrKkamat = () => {
    return (this.state.formTipusValue === 'SZAMLA' || this.state.formTipusValue === 'KKAMAT');
  }

  handleChangeJogtulaj = (value) => {
    let dropdownStatus = this.isFormtypeSzamlaOrKkamat();
    if (value && value.length !== 0) {
      let bankszamlak = [];
      if (value.bankszamla) {
        value.bankszamla.forEach((item) => {
          bankszamlak.push(
            <option value={item.id.toString()}>
              {item.szamlaszam + ' (' + item.penzintezet + ')'}
            </option>
          );
        });
      }
      let szamlaObj = [];
      if (value && value.szamla) {
        value.szamla.forEach((item) => {
          szamlaObj.push(
            <ListGroupItem
              style={{
                cursor: 'pointer',
                display: 'block'
              }}
              onClick={() => this.bizonylatszamItemClick(item)}
            >
              <span className="pull-left">
                {item.bizonylatszam}
              </span>
              <Badge pill className="pull-right" color="info" style={{ padding: 5 }}>
                {NumberFormatter.addSpacesToString(item.osszeg, NumberFormatter.OSSZEG) + ' ' + item.penznem}
              </Badge>
              <Badge pill className="pull-right" color="warning" style={{ padding: 5, marginRight: 5 }}>
                {NumberFormatter.addSpacesToString(item.fennmarado, NumberFormatter.OSSZEG) + ' ' + item.penznem}
              </Badge>
            </ListGroupItem>
          );
        });
      }
      this.setState({
        formSzamlaszam: bankszamlak,
        currentBizszamok: szamlaObj,
        formPartner: value,
        dropdownOpen: dropdownStatus,
      });
    } else {
      this.setState({ formPartner: '' });
    }
  }

  handleChangeBizonylatszam = (value) => {
    if (value && value.length !== 0) {
      Services.getCheck(this.state.currentId, value.penznem, (err, res) => {
        if (!err) {
          this.setState({
            arfolyamVisible: res[0].toString() === 'true',
          });
        }
      });
      let bankszamlak = [];
      if (value.bankszamla) {
        value.bankszamla.forEach((item) => {
          bankszamlak.push(
            <option value={item.id.toString()}>
              {item.szamlaszam + ' (' + item.penzintezet + ')'}
            </option>
          );
        });
      }
      this.setState({
        formBizonylatszam: {
          id: value.id,
          nev: value.nev,
          penz: value.penznem,
          hely: value.hely
        },
        formPartner: {
          id: value.partnerid,
          nev: value.partnernev
        },
        formSzamlaszam: bankszamlak,
        fkviszam: value.fkviszam,
      });
    } else {
      this.setState({ formBizonylatszam: '' });
    }
  }

  deleteTetel = (id) => {
    this.toggleBaseDelete();
    Services.deleteAnalitika(id, (err) => {
      if (!err) {
        this.updateBaseTable();
        this.props.globals.addSuccess('Bankkivonat tétel törölve!');
        this.props.refreshTranzakcio();
        this.props.refreshFej();
      }
    });
  }

  compare = (a, b) => {
    if (a.nev < b.nev) return -1;
    if (a.nev > b.nev) return 1;
    return 0;
  }

  handleOsszegChange = (event, value2) => {
    const { target } = event;
    let value;
    if (target) {
      if (target.name) {
        switch (target.name) {
          case 'osszeg': {
            ({ value } = target);
            if (target.value === '') {
              value = 0;
            }
            break;
          }
          case 'formTipusValue': {
            this.setState({
              formBizonylatszam: null,
              formTipusValue: null,
              formSzamlaszam: [],
              formPartner: null,
              arfolyamVisible: false
            });
            value = value2;
            break;
          }
          case 'bankszamlatortenetid': {
            value = value2;
            break;
          }
          default:
            break;
        }
      }
      // value = target.type === 'checkbox' ? target.checked : target.value;
    } else {
      value = event;
    }
    const { name } = target;

    this.setState({
      [name]: value
    });
  }

  getSzkId = () => {
    if (!this.state.formSzamlakibocsato || this.state.formSzamlakibocsato === '') {
      return null;
    }
    return (this.state.formSzamlakibocsato && this.state.formSzamlakibocsato.id);
  }

  loadJogtulajChange = (input, callback) => {
    if (input.length !== 0) {
      if (this.state.formTipusValue !== 'EGYEB') {
        Services.findForBankkivonatTetel(null, input, this.state.formTipusValue, this.state.currentPenznemId, this.state.currentTipus, this.state.formTipusValue, this.getSzkId(), (err, res) => {
          if (!err) {
            let newArray = [];
            res[0].forEach((item) => {
              let newItem = item;
              newItem.nev += ' (' + newItem.cim + ') ';
              newArray.push(newItem);
            });
            callback(newArray);
          }
        });
      } else {
        Services.getPartner(input, (err, res) => {
          if (!err) {
            let newArray = [];
            res[0].forEach((item) => {
              let newItem = item;
              newItem.nev += ' (' + newItem.cim + ') ';
              newArray.push(newItem);
            });
            callback(newArray);
          }
        });
      }
    }
  }

  loadBizonylatszamChange = (input, callback) => {
    if (input.length !== 0) {
      Services.findForBankkivonatTetel(input, null, this.state.formTipusValue, this.state.currentPenznemId, this.state.currentTipus, this.state.formTipusValue, this.getSzkId(), (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            let baseObj = {};
            baseObj.bankszamla = item.bankszamla;
            baseObj.partnerid = item.id;
            baseObj.partnerString = item.nev;
            baseObj.partnernev = item.nev + ' (' + item.cim + ')';
            item.szamla.forEach((subItem) => {
              let newItem = Object.assign({}, baseObj, subItem);
              newItem.nev = newItem.bizonylatszam + ' (' + newItem.osszeg + ' ' + newItem.penznem + ') \n' + newItem.kibocsato;
              if (newArray.indexOf(newItem) === -1) {
                newArray.push(newItem);
              }
            });
          });
          newArray.sort(this.compare);
          callback(newArray);
        }
      });
    }
  }

  loadSzamlakibocsatoChange = (input, callback) => {
    if (input.length !== 0) {
      Services.getPartnerSzamlakibocsato('BANK_TETEL_FORM', input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            let baseObj = {};
            baseObj.id = item.id;
            baseObj.nev = item.nev + ' (' + item.cim + ')';
            newArray.push(baseObj);
          });
          callback(newArray);
        }
      });
    }
  }

  baseTableIconFormatter = (cell, row) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'BANK_PUTELJ', 'KARB') && (
          <React.Fragment>
            {row.d && (
              <React.Fragment>
                <Button
                  className="icon-pencil table-action-icon"
                  id={'action-edit-base-analitika-' + cell}
                  onClick={() => this.handleBaseEditClick(cell)}
                />
                <UncontrolledTooltip
                  placement="left"
                  target={'action-edit-base-analitika-' + cell}
                  delay={0}
                >
                  {'Módosítás'}
                </UncontrolledTooltip>
              </React.Fragment>
            )}
            <Button
              className="icon-trash table-action-icon"
              id={'action-delete-base-analitika-' + cell}
              onClick={() => this.handleBaseDeleteClick(cell)}
            />
            <UncontrolledTooltip
              placement="left"
              target={'action-delete-base-analitika-' + cell}
              delay={0}
            >
              {'Törlés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  deleteTetelAll = () => {
    let submitList = [];
    if (this.tetelTable && this.tetelTable.state && this.tetelTable.state.selectedRowKeys && this.tetelTable.state.selectedRowKeys.length > 0) {
      submitList = this.tetelTable.state.selectedRowKeys;
    } else {
      submitList = [];
    }

    this.toggleBaseDeleteAll();
    Services.deleteAllAnalitika(submitList, (err) => {
      if (!err) {
        this.updateBaseTable();
        this.props.globals.addSuccess('Bankkivonat tétel(ek) törölve!');
        this.props.refreshTranzakcio();
        this.props.refreshFej();
      }
    });
  }

  renderUploadButtons = () => {
    return (
      <div className="row">
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'BANK_PUTELJ', 'KARB') && (
          <div className="col-md-12">
            <Button
              color="primary"
              disabled={!this.state.currentId}
              onClick={() => { this.handleBaseNewClick(); }}
            >
              {'Új tétel'}
            </Button>
            {' '}
            {!this.state.disableButtons && (
              <Button
                color="danger"
                onClick={() => { this.handleBaseDeleteAllClick(); }}
              >
                {'Csoportos törlés'}
              </Button>
            )}
            { !this.state.currentId ? (
              <React.Fragment>
                <Button
                  style={{ marginTop: 0 }}
                  id="base-kivonat-buttons-info"
                  className="fa fa-info-circle table-action-icon transp-icon-button"
                />
                <UncontrolledTooltip
                  placement="right"
                  target="base-kivonat-buttons-info"
                  delay={0}
                >
                  {'Kijelölt bankkivonat tranzakció nélkül nem lehetséges új adatot feltölteni!'}
                </UncontrolledTooltip>
              </React.Fragment>
            ) : <span /> }
          </div>
        )}
      </div>
    );
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  onSelectAll = (isSelected) => {
    if (isSelected) {
      if (this.tetelTable.store.filteredData) {
        this.setState({ disableButtons: false });
        return this.tetelTable.store.filteredData.map(row => row.i);
      }
      this.setState({ disableButtons: false });
      return this.state.baseJson.map(row => row.i);
    }
    this.setState({ disableButtons: true });
    return [];
  }

  onRowSelect = (row, isSelected, e) => {
    if (isSelected) {
      this.setState({ disableButtons: false });
    }
    if (!isSelected && this.tetelTable.state.selectedRowKeys.length === 1) {
      this.setState({ disableButtons: true });
    }
  }

  renderBaseTable = () => {
    const pagination = true;

    const selectRowProp = {
      onSelectAll: this.onSelectAll,
      onSelect: this.onRowSelect,
      mode: 'checkbox'
    };

    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };
    return (
      <div>
        { this.state.baseJson.length ? (
          <BootstrapTable
            data={this.state.baseJson}
            pagination={pagination}
            options={options}
            ref={((c) => { this.tetelTable = c; })}
            selectRow={selectRowProp}
            className="margin-top-30"
          >
            <TableHeaderColumn
              dataField="t"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            >
              {'Tétel típus'}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="p"
              tdStyle={{ whiteSpace: 'pre-line' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            >
              {'Számlatulajdonos'}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="b"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            >
              {'Befizető azonosító'}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="z"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            >
              {'Bizonylatszám'}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="o"
              tdStyle={{ whiteSpace: 'normal', textAlign: 'right' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              dataFormat={NumberFormatter.formatterSpaceOsszeg}
            >
              {'Összeg'}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="e"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            >
              {'Pénznem'}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="a"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            >
              {'Állapot'}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="f"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            >
              {'Főkönyviszám'}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="i"
              tdStyle={{ whiteSpace: 'normal' }}
              isKey
              hidden={!Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'BANK_PUTELJ', 'KARB')}
              dataFormat={this.baseTableIconFormatter}
            >
              {'Műveletek'}
            </TableHeaderColumn>
          </BootstrapTable>
        ) : <div className="margin-top-35"><DataEmptyState /></div>}
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderUploadButtons()}
        {{
          QUERY: (<QueryEmptyState
            hasImage
            subtitle="Kérem válasszon felettes rekordot!"
          />),
          LOAD: (<Loader />),
          DATA: (<DataEmptyState
            hasImage
            subtitle="Próbálj más előszűrést vagy vigyél fel új adatokat!"
          />),
          NETW: (<NetworkEmptyState
            hasImage
            submitClick={() => this.updateTable()}
          />),
          TABL: (this.renderBaseTable())
        }[this.state.currentStatusBaseTable]}

        <Modal isOpen={this.state.modalBaseDelete} toggle={this.toggleBaseDelete} className={this.props.className} backdrop="static">
          <ModalHeader>
            {'Törlés'}
          </ModalHeader>
          <ModalBody>
            {'Valóban törölni kívánja a kiválasztott tételt?'}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteTetel(this._currentDeleteId)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleBaseDelete}>Mégsem</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalBaseDeleteAll} toggle={this.toggleBaseDeleteAll} className={this.props.className} backdrop="static">
          <ModalHeader>
            {'Csoportos törlés'}
          </ModalHeader>
          <ModalBody>
            {'Valóban törölni kívánja a kiválasztott tétel(eke)t?'}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteTetelAll()}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleBaseDeleteAll}>Mégsem</Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalBaseNew}
          toggle={this.toggleBaseNew}
          className={this.props.className}
          backdrop="static"
          // size="lg"
        >

          <AvForm onValidSubmit={_muvelet === 0 ? this.addAnalitika : this.modifyBanktetel}>
            {_muvelet === 0 ? <ModalHeader>Új bankkivonat tétel rögzítése</ModalHeader> : <ModalHeader>Meglévő bankkivonat tétel módosítása</ModalHeader> }
            <ModalBody>
              {/* <h5>Alapadatok:</h5> */}
              <div className="row">

                <div className="col-md-12">
                  <AvField
                    type="select"
                    name="formTipusValue"
                    label="Típus: "
                    errorMessage="Kötelező mező!"
                    required
                    disabled={!this.state.formTipus.length || _muvelet === 1}
                    value={this.state.formTipusValue}
                    onChange={this.handleOsszegChange}
                  >
                    <option value="">
                      {'Válassz típust...'}
                    </option>
                    {this.state.formTipus}
                  </AvField>
                </div>

                { this.state.formTipusValue && (
                  <React.Fragment>
                    <div className="col-md-12">
                      <SlaveAvInput
                        id="osszeg"
                        label="Összeg: *"
                        min="0"
                        max="999999999"
                        required
                        onChange={this.handleOsszegChange}
                        value={this.state.osszeg}
                        szuroTipus={NumberFormatter.OSSZEG}
                        errorMessage='Kötelező mező! (0-999 999 999)'
                        inputGroupAddon={this.state.currentPenznem}
                        emptyStringOnZero
                      />
                    </div>
                    {this.isFormtypeSzamlaOrKkamat() && (
                      <div className="col-md-12" style={{ marginBottom: 15 }}>
                        <Label for="bizonylatszam">
                          {'Számlakibocsátó:'}
                        </Label>
                        <Select
                          styles={{
                            control: base => ({
                              ...base,
                              '&:focus': { borderColor: 'red' }, // border style on hover
                              border: '1px solid lightgray', // default border color
                              boxShadow: 'none', // no box-shadow
                              borderRadius: '0'
                            }),
                            whiteSpace: 'pre-line'
                          }}
                          name="bizonylatszam"
                          id="bizonylatszam"
                          placeholder="Kezdjen el gépelni..."
                          noOptionsMessage={() => 'Nincs keresési eredmény'}
                          searchPromptText="Kezdjen el gépelni a kereséshez..."
                          loadingMessage={() => 'Keresés...'}
                          onChange={v => this.handleChangeSzamlakibocsato(v)}
                          isClearable
                          ignoreAccents={false}
                          loadOptions={_.debounce(this.loadSzamlakibocsatoChange, 1000)}
                          isLoading={false}
                          getOptionLabel={option => option.nev}
                          getOptionValue={option => option.id}
                          value={this.state.formSzamlakibocsato}
                          // disabled={this.state.fizTeljElerheto}
                          cache={false}
                          autoload={false}
                        />
                      </div>
                    )}
                    <div className="col-md-12">
                      <Label for="ptid">
                        {'Partner: *'}
                      </Label>
                      <Select
                        styles={{
                          control: base => ({
                            ...base,
                            '&:focus': { borderColor: 'red' }, // border style on hover
                            border: '1px solid lightgray', // default border color
                            boxShadow: 'none', // no box-shadow
                            borderRadius: '0'
                          }),
                        }}
                        name="ptid"
                        id="ptid"
                        placeholder="Kezdjen el gépelni..."
                        noOptionsMessage={() => 'Nincs keresési eredmény'}
                        searchPromptText="Kezdjen el gépelni a kereséshez..."
                        loadingMessage={() => 'Keresés...'}
                        onChange={v => this.handleChangeJogtulaj(v)}
                        isClearable
                        ignoreAccents={false}
                        loadOptions={_.debounce(this.loadJogtulajChange, 1000)}
                        isLoading={false}
                        getOptionLabel={option => option.nev}
                        getOptionValue={option => option.id}
                        value={this.state.formPartner}
                        // disabled={this.state.fizTeljElerheto}
                        cache={false}
                        autoload={false}
                      />
                    </div>
                    <div className="col-md-12" style={{ marginBottom: 16 }}>
                      <Dropdown
                        isOpen={this.state.dropdownOpen}
                        toggle={() => {
                          this.setState({
                            dropdownOpen: false
                          });
                        }}
                      >
                        <DropdownToggle tag="div">
                          {''}
                        </DropdownToggle>
                        <DropdownMenu
                          className="w-100"
                          modifiers={{
                            setMaxHeight: {
                              enabled: true,
                              order: 890,
                              fn: (data) => {
                                return {
                                  ...data,
                                  styles: {
                                    ...data.styles,
                                    overflow: 'auto',
                                    maxHeight: 230,
                                  },
                                };
                              },
                            },
                          }}
                        >
                          <ListGroup>
                            <ListGroupItem
                              style={{
                                // textAlign: 'center',
                                display: 'block'
                              }}
                              className="justify-content-between"
                            >
                              <span className="pull-left">
                                {'Bizonylatszámok'}
                              </span>
                              <span className="pull-right">
                                {'Fennmaradó / Összeg'}
                              </span>
                            </ListGroupItem>
                            {this.state.currentBizszamok}
                          </ListGroup>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                    <div className="col-md-12">
                      <AvField
                        type="select"
                        name="bankszamlatortenetid"
                        label="Partner bankszámlaszám: "
                        errorMessage="Kötelező mező!"
                        onChange={this.handleOsszegChange}
                        value={this.state.bankszamlatortenetid}
                        disabled={!this.state.formSzamlaszam.length}
                      >
                        <option value="">
                          {'Válassz bankszámlaszámot...'}
                        </option>
                        {this.state.formSzamlaszam}
                      </AvField>
                    </div>
                    {this.isFormtypeSzamlaOrKkamat() && (
                      <div className="col-md-12" style={{ marginBottom: 15 }}>
                        <Label for="bizonylatszam">
                          {'Bizonylatszám: *'}
                        </Label>
                        <Select
                          styles={{
                            control: base => ({
                              ...base,
                              '&:focus': { borderColor: 'red' }, // border style on hover
                              border: '1px solid lightgray', // default border color
                              boxShadow: 'none', // no box-shadow
                              borderRadius: '0'
                            }),
                            whiteSpace: 'pre-line'
                          }}
                          name="bizonylatszam"
                          id="bizonylatszam"
                          placeholder="Kezdjen el gépelni..."
                          noOptionsMessage={() => 'Nincs keresési eredmény'}
                          searchPromptText="Kezdjen el gépelni a kereséshez..."
                          loadingMessage={() => 'Keresés...'}
                          onChange={v => this.handleChangeBizonylatszam(v)}
                          components={{ Option }}
                          isClearable
                          ignoreAccents={false}
                          loadOptions={_.debounce(this.loadBizonylatszamChange, 1000)}
                          isLoading={false}
                          getOptionLabel={option => option.nev}
                          getOptionValue={option => option.id}
                          value={this.state.formBizonylatszam}
                          // disabled={this.state.fizTeljElerheto}
                          cache={false}
                          autoload={false}
                        />
                      </div>
                    )}
                    { this.state.arfolyamVisible && (
                      <div className="col-md-12">
                        <AvGroup>
                          <Label for="arfolyam">
                            {'Árfolyam: *'}
                          </Label>
                          <AvInput
                            name="arfolyam"
                            id="arfolyam"
                            type="number"
                            min="0"
                            max="999999999"
                            required
                            onChange={this.handleArfolyamChange}
                            value={this.state.arfolyam}
                          />
                          <AvFeedback>
                            {'Kötelező mező! (0-999 999 999)'}
                          </AvFeedback>
                        </AvGroup>
                      </div>
                    )}
                    { 
                      <div className="col-md-12">
                        <AvGroup>
                          <Label for="fkviszam">
                            {'Főkönyviszám: '}
                          </Label>
                          <AvInput
                            name="fkviszam"
                            id="fkviszam"
                            onChange={(e,v) => this.setState({fkviszam : v})}
                            value={this.state.fkviszam}
                          />
                        </AvGroup>
                      </div>
                    }
                  </React.Fragment>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary">
                {'Mentés'}
              </Button>
              {' '}
              <Button type="button" color="secondary" onClick={this.toggleBaseNew}>
                {'Mégse'}
              </Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </div>
    );
  }
}

BanktetelAnalitikaTable.propTypes = {
  className: PropTypes.string,
  globals: PropTypes.object,
  tableData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  tableStatus: PropTypes.object,
  tableStatusChange: PropTypes.func,
};

export default BanktetelAnalitikaTable;
