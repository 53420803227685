import Lib from '../../commons/lib';

export default class Service {
  static udulesijogUrl = window.location.origin + '/api/udulesijog/';

  static udulesijogszlaUrl = window.location.origin + '/api/udulesijogszla/';

  static apartmanUrl = window.location.origin + '/api/apartman/';

  static partnerUrl = window.location.origin + '/api/partner/';

  // RECEPCIÓ START

  static getSzallodaList(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'szalloda/recepcio',
      args: { requestType: 'szalloda-recepcio-get' }
    }], fnDone);
  }

  static getAktivApartmanBySzallodaId(szallodaId, text, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'apartman/recepcio',
      args: { requestType: 'apartman-recepcio-get' },
      params: { szalloda: szallodaId, text: text }
    }], fnDone);
  }

  static getJogtulaj(szallodaId, apartmanId, apartmanNev, hetSorszam, nevQuery, ev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'recepcio/searchpartner',
      args: { requestType: 'udulesijog-recepcio-searchpartner' },
      params: {
        szalloda: szallodaId,
        apartmanid: apartmanId,
        apartmannev: apartmanNev,
        het: hetSorszam,
        nev: nevQuery,
        ev: ev
      }
    }], fnDone);
  }

  static getRecepcioBaseList(szallodaId, apartmanId, apartmanNev, hetSorszam, jogtulajId, ev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'recepcio/list',
      args: { requestType: 'recepcio-recepcio-list' },
      params: {
        szalloda: szallodaId,
        apartmanid: apartmanId,
        apartmannev: apartmanNev,
        het: hetSorszam,
        partner: jogtulajId,
        ev: ev
      }
    }], fnDone);
  }

  static getRecepcioChildList(baseId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'recepcio/getszamla',
      args: { requestType: 'recepcio-recepcio-getszamla' },
      params: {
        id: baseId
      }
    }], fnDone);
  }

  static getHetiPdf(szallodaId, hetSorszam, ev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'recepcio/hetipdf',
      acceptType: 'application/json; charset=utf-8, text/plain; charset=utf-8, application/octet-stream',
      args: { requestType: 'recepcio-recepcio-list' },
      params: {
        szalloda: szallodaId,
        het: hetSorszam,
        ev: ev
      }
    }], fnDone);
  }

  static getUdulesijog(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'udulesijog',
      args: { requestType: 'udulesijog-get' },
      params: { id: id }
    }], fnDone);
  }

  static getUdulesijogEvHet(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'kompenzacio/list/evhet/get',
      args: { requestType: 'udulesijog-evhet-get' },
      params: { id: id }
    }], fnDone);
  }

  static getWeekPDF(szallodaId, hetSorszam, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + '',
      args: { requestType: '' },
      params: {
        szalloda: szallodaId,
        het: hetSorszam
      }
    }], fnDone);
  }

  static getRciData(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'udulesijog/deponalasok',
      args: { requestType: 'udulesijog-deponalasok' },
      params: { udulesijogId: id }
    }], fnDone);
  }

  // SZERZŐDÉS START
  static listSzerzodes(szallitoid, vevoid, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szerzodesUrl + 'szerzodes/list',
      args: { requestType: 'szerzodes-list' },
      params: {
        szallitoid: szallitoid,
        vevoid: vevoid
      }
    }], fnDone);
  }

  static listSzallitoVevo(type, text, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'partnerUrl-search' },
      params: {
        type: type,
        text: text
      }
    }], fnDone);
  }

  static listSzerzodesDokumentumok(szerzodesid, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szerzodesUrl + 'szerzodes/list',
      args: { requestType: 'szerzodes-list' },
      params: { id: szerzodesid }
    }], fnDone);
  }

  static getAktivPartnerByCsoport(type, text, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'search-getlist' },
      params: {
        type: type,
        text: text,
        id: id
      }
    }], fnDone);
  }

  static listSzerzodesTipus(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szerzodesUrl + 'szerzodestipus/list',
      args: { requestType: 'szerzodestipus-list' }
    }], fnDone);
  }

  static getSzerzodes(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szerzodesUrl + 'szerzodes',
      args: { requestType: 'szerzodes-get' },
      params: { id: id }
    }], fnDone);
  }

  static listSzerzodesEnums(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szerzodesUrl + 'szerzodes/enum',
      args: { requestType: 'szerzodes-enum-list' }
    }], fnDone);
  }

  static createSzerzodes(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.szerzodesUrl + 'szerzodes',
      args: { requestType: 'szerzodes-create' },
      data: data,
    }], fnDone);
  }

  static listAktivPenznem(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'penznem/aktiv',
      args: { requestType: 'penznem-list' }
    }], fnDone);
  }

  static listAktivFizetesimod(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'fizmod/aktiv',
      args: { requestType: 'fizmod-list' }
    }], fnDone);
  }

  static deleteSzerzodes(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.szerzodesUrl + 'szerzodes',
      args: { requestType: 'szerzodes-delete' },
      params: { id: id }
    }], fnDone);
  }

  static updateSzerzodes(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.szerzodesUrl + 'szerzodes',
      args: { requestType: 'szerzodes-update' },
      data: data,
    }], fnDone);
  }

  static downloadObject(objid, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szerzodesUrl + 'szerzodes/object',
      args: { requestType: 'szerzodes-get-object' },
      acceptType: 'application/octet-stream',
      params: { id: objid }
    }], fnDone);
  }
  // SZERZŐDÉS END

  // ÜDÜLÉSIJOG START

  static initFilter(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'kompenzacio/initfilter',
      args: { requestType: 'kompenzacio-initfilter' }
    }], fnDone);
  }

  static listAktivSzallodak(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'szalloda/listudulesijogszallodak',
      args: { requestType: 'udulesijog-listudulesijogszallodak' }
    }], fnDone);
  }

  static listAktivEpuletek(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'epulet/listudulesijogepuletek',
      args: { requestType: 'udulesijog-listudulesijogepuletek' }
    }], fnDone);
  }

  static listAktivEpuletekById(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'epulet/listudulesijogepuletek',
      args: { requestType: 'udulesijog-listudulesijogepuletek' },
      params: { id: id }
    }], fnDone);
  }

  static listUdulesijog(szallodaid, epuletid, partnerid, kompenzacio, ev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'udulesijog/list',
      args: { requestType: 'udulesijog-list' },
      params: {
        szallodaid,
        ev,
        partnerid,
        kompenzacio
      }
    }], fnDone);
  }

  static listUdulesijogSzall(szallodaid, partnerid, ev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'kompenzacio/list/evhet',
      args: { requestType: 'kompenzacio-list-evhet' },
      params: {
        szallodaid,
        ev,
        partnerid,
      }
    }], fnDone);
  }

  static getEvHetSzallodaList(udulesiJogId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'kompenzacio/list/evhet/szalloda',
      args: { requestType: 'kompenzacio-list-evhet-szalloda' },
      params: { id: udulesiJogId }
    }], fnDone);
  }

  static listUdulesijogBySzerzodesId(szerzodesId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'udulesijog/list/szerzodes',
      args: { requestType: 'udulesijog-list-szerzodes' },
      params: { id: szerzodesId }
    }], fnDone);
  }

  static listSzerzodesByUdulesijogId(udulesijogId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szerzodesUrl + 'szerzodes/list/udulesijog',
      args: { requestType: 'szerzodes-list-udulesijog' },
      params: { id: udulesijogId }
    }], fnDone);
  }

  static listUdulesiJogEnums(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'udulesijog/enum',
      args: { requestType: 'udulesijog-enum-list' }
    }], fnDone);
  }

  static listSzallodak(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'szalloda/aktiv',
      args: { requestType: 'szalloda-aktiv-list' },
    }], fnDone);
  }

  static listEpuletekById(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'epulet/aktiv',
      args: { requestType: 'epulet-aktiv-list' },
      params: { id: id }
    }], fnDone);
  }

  static listApartmanokById(epulet, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'apartman/getepuletapartman',
      args: { requestType: 'epulet-getepuletapartman-list' },
      params: { epulet: epulet }
    }], fnDone);
  }

  static deleteUdulesijog(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.udulesijogUrl + 'udulesijog',
      args: { requestType: 'udulesijog-delete' },
      params: { id: id }
    }], fnDone);
  }

  static createUdulesijog(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.udulesijogUrl + 'udulesijog',
      args: { requestType: 'udulesijog-create' },
      data: data,
    }], fnDone);
  }

  static updateUdulesijog(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.udulesijogUrl + 'udulesijog',
      args: { requestType: 'udulesijog-update' },
      data: data,
    }], fnDone);
  }

  static getSzezonSzin(apartmanid, sorszam, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'apartman/getapartmanbean',
      args: { requestType: 'apartman-get-apartmanbean' },
      params: {
        id: apartmanid,
        sorszam: sorszam,
        role: true
      }
    }], fnDone);
  }

  // static listSzamlazo(type ,name , fnDone) {
  //   Lib.MicroServices.requestService([{
  //     method: 'GET',
  //     url: Service.partnerUrl + 'search',
  //     args: { requestType: 'szerzodestipus-list' },
  //     params: {
  //       tipus: 'APSZLA',
  //       nev: nev
  //     }
  //   }], fnDone);
  // }

  static listUdulesijogTulaj(type, text, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'partner-search' },
      params: {
        type: type,
        text: text
      }
    }], fnDone);
  }

  // ÜDÜLÉSIJOG END

  // ÉVESDÍJ START

  static getEvesdijEv(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'evesdij/getdistinctev',
      args: { requestType: 'evesdij-getev' }
    }], fnDone);
  }

  static getEvesdijUdulesijog(nev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'evesdij/getudulesijogazonbynev',
      args: { requestType: 'evesdij-getudulesijogazonbynev' },
      params: { nev: nev }
    }], fnDone);
  }

  static listUdulesijogSzallodak(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'szalloda/listudulesijogszallodak',
      args: { requestType: 'szalloda-listudulesijogszallodak' }
    }], fnDone);
  }

  static listEvesdijEpulet(szallodaId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'epulet/aktiv',
      args: { requestType: 'epulet-aktiv' },
      params: { id: szallodaId }
    }], fnDone);
  }

  static getSzamlakibocsato(nev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'evesdij/searchpartner',
      args: { requestType: 'evesdij-searchpartner' },
      params: { nev: nev }
    }], fnDone);
  }

  static getEvesdijList(ev, szallodaId, epuletId, udulesijogId, het, kibocsatoId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'evesdij/list',
      args: { requestType: 'evesdij-list' },
      params: {
        ev: ev,
        szalloda: szallodaId,
        epulet: epuletId,
        udulesijog: udulesijogId,
        het: het,
        kibocsato: kibocsatoId
      }
    }], fnDone);
  }
  // ÉVESDÍJ END

  // Máté Kompenzáció

  static getKompenzacioTetel(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'kompenzacio',
      args: { requestType: 'Kompenzacio-get' },
      params: { id: id }
    }], fnDone);
  }

  static getKompenzacioInit(uid, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'kompenzacio/init',
      args: { requestType: 'Kompenzacio-init' },
      params: { uid, id }
    }], fnDone);
  }

  static getKompenzacioTetelek(id, recepcio, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'kompenzacio/list',
      args: { requestType: 'Kompenzacio-list' },
      params: {
        id: id,
        recepcio: recepcio
      }
    }], fnDone);
  }

  static getKompenzacioTetelList(id, ev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'kompenzacio/list/kompenzacio',
      args: { requestType: 'Kompenzacio-list' },
      params: {
        id: id,
        ev: ev
      }
    }], fnDone);
  }

  static insertKompenzacioTetel(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.udulesijogUrl + 'kompenzacio',
      args: { requestType: 'Kompenzacio-insert' },
      data: data,
    }], fnDone);
  }

  static deleteKompenzacioTetel(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.udulesijogUrl + 'kompenzacio',
      args: { requestType: 'kompenzacio-delete' },
      params: { id: id }
    }], fnDone);
  }

  static updateKompenzacioTetel(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.udulesijogUrl + 'kompenzacio',
      args: { requestType: 'Kompenzacio-update' },
      data: data,
    }], fnDone);
  }

  static updateUdulesijogKompenzaciostatuszVegleges(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.udulesijogUrl + 'udulesijog/vegleges',
      args: { requestType: 'udulesijog-updatekompstatus' },
      params: { udulesiJogEntityId: id },
    }], fnDone);
  }

  static updateUdulesijogkompenzaciostatuszLezar(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.udulesijogUrl + 'udulesijog/lezar',
      args: { requestType: 'udulesijog-updatekompstatuslezar' },
      params: { udulesiJogEntityId: id },
    }], fnDone);
  }

  static updateKompenzacioTetelStatuszLezar(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.udulesijogUrl + 'kompenzacio/lezart',
      args: { requestType: 'kompenzacio-delete' },
      params: { kompenzacioEntityId: id }
    }], fnDone);
  }

  static kompenzacioIgenybevett(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.udulesijogUrl + 'kompenzacio/igenybevett',
      args: { requestType: 'udulesijog-kompenzacio-igenybevett' },
      params: { id: id },
    }], fnDone);
  }

  // Évek és hetek
  static getEvekEsHetek(uid, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'evhet',
      args: { requestType: 'Evekeshetek-list' },
      params: {
        udulesiid: uid,
      }
    }], fnDone);
  }
}
