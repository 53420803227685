import React from 'react';
import YouTube from '../../../commons/youtube';
import KompenzacioListaContent from './KompenzacioListaContent';

const videoData = [
  { text: 'Kompenzáció Lista', id: 'KAxQV77nCGQ' }
];

const KompenzacioListaBase = (props) => {
  let cardStyle = {
    padding: 20
  };

  return (
    <div className="animated fadeIn">
      <div className="card">
        <div className="card-header">
          {'Kompenzáció lista'}
        </div>
        <div className="row" style={cardStyle}>
          <div className="col-md-12">
            <KompenzacioListaContent
              globals={props.route.globals}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default KompenzacioListaBase;
