import React, { Component } from 'react';

const kartyak = false;

class Profil extends Component {
  render() {
    return (
      <div className="animated fadeIn">
       {kartyak ? <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="card card-accent-primary">
              <div className="card-header">
                Cégadatok
              </div>
              <div className="card-block">
                Valami leírás, hogy mi történik itt
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="card card-accent-secondary">
              <div className="card-header">
                Készlet
              </div>
              <div className="card-block">
                Valami leírás, hogy mi történik itt
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="card card-accent-success">
              <div className="card-header">
                Munkalap
              </div>
              <div className="card-block">
                Valami leírás, hogy mi történik itt
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="card card-accent-success">
              <div className="card-header">
                Számlázás
              </div>
              <div className="card-block">
                Valami leírás, hogy mi történik itt
              </div>
            </div>
          </div>
        </div>:
          <div className="card card-accent-info">
            <div className="card-header">
              <h5>Információ</h5>
            </div>
            <div className="card-block">
              Kérem válasszon a bal oldali menüből!
            </div>
          </div> }
      </div>
    );
  }
}

export default Profil;
