import React from 'react';
import SzabadApartmanLekerdezesContent from './SzabadApartmanLekerdezesContent';

const SzabadApartmanLekerdezesBase = (props) => {
  let cardStyle = {
    padding: 20
  };

  return (
    <div className="animated fadeIn">
      <div className="card">
        <div className="card-header">
          {'Szabad apartman lekérdezés'}
        </div>
        <div className="row szabadapartman" style={cardStyle}>
          <div className="col-md-12">
            <SzabadApartmanLekerdezesContent
              globals={props}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SzabadApartmanLekerdezesBase;
