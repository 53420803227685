import React, { Component } from 'react';
import { AvForm } from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {
  selectFilter,
  textFilter,
} from 'react-bootstrap-table2-filter';
import {
  Button, Col, Input, Label, Row, UncontrolledTooltip
} from 'reactstrap';
import { browserHistory } from 'react-router';
import AvInput from 'availity-reactstrap-validation/lib/AvInput';
import Services from './services';
import Loader from '../../../commons/loader';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import FelhasznalokRoleCards from './FelhasznalokRoleCards';

const cardStyle = {
  padding: 20,
};

class FelhasznalokCsoportForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      groupName: null,
      rolesAvailable: [],
      rolesSelected: [],
    };
  }

  componentDidMount() {
    this.getGroup();
  }

  isEdit = () => {
    return this.props.route.type === 'MOD';
  }

  getGroup = () => {
    if (this.isEdit) {
      let params = new URL(window.location.href).searchParams;
      let id = params.get('id');
      if (id) {
        Services.getGroup(id, (err, res) => {
          if (!err) {
            let array = [];
            if (res[0].clientRoles) {
              array = res[0].clientRoles.sort((a, b) => a.roleName.localeCompare(b.roleName));
            }
            this.setState({ id: res[0].id, groupName: res[0].name, rolesSelected: array });
            this.getRoles(res[0].id, array);
          }
        });
      }
    }
  }

  getRoles = (id, exclusionArray) => {
    Services.getRoleList(id, (err, res) => {
      if (!err) {
        let array = [];
        if (res[0]) { array = res[0].filter(role => !exclusionArray.map(role2 => role2.id).includes(role.id)).sort((a, b) => a.roleName.localeCompare(b.roleName)); }
        this.setState({ rolesAvailable: array });
      }
    });
  }

  onSubmit = (reopen) => {
    if (!this.state.groupName) {
      this.props.route.globals.addError('Csoportnév megadása kötelező!');
      return true;
    }
    if (this.isEdit()) {
      let obj = {
        id: this.state.id,
        name: this.state.groupName,
        clientRoleIds: this.state.rolesSelected.map((role) => { return { id: role.id }; })
      };
      Services.editGroup(obj, (err) => {
        if (!err) {
          this.props.route.globals.addSuccess('Csoport módosítva!');
        }
      });
    } else {
      Services.insertGroup({ name: this.state.groupName }, (err, res) => {
        if (!err) {
          this.props.route.globals.addSuccess('Csoport hozzáadva!');
          if (reopen) {
            this.handleReopen(res[0]);
            this.setState({ id: res[0] });
            this.getRoles(res[0], []);
          } else { this.handleBack(); }
        }
      });
    }
  }

  addRoles = (roles) => {
    if (!roles || !roles.length) {
      this.props.route.globals.addError('Legalább egy jogosultság kiválasztása kötelező a hozzáadáshoz!');
      return;
    }

    this.setState((prevState) => {
      let rolesSelectedNew = prevState.rolesAvailable.filter(role => roles.includes(role.id)).concat(prevState.rolesSelected).sort((a, b) => a.roleName.localeCompare(b.roleName));
      let rolesAvailableNew = prevState.rolesAvailable.filter(role => !rolesSelectedNew.includes(role));
      return { rolesAvailable: rolesAvailableNew, rolesSelected: rolesSelectedNew };
    });
  }

  removeRoles = (roles) => {
    if (!roles || !roles.length) {
      this.props.route.globals.addError('Legalább egy jogosultság kiválasztása kötelező a törléshez!');
      return;
    }
    this.setState((prevState) => {
      let rolesAvailableNew = prevState.rolesSelected.filter(role => roles.includes(role.id)).concat(prevState.rolesAvailable).sort((a, b) => a.roleName.localeCompare(b.roleName));
      let rolesSelectedNew = prevState.rolesSelected.filter(role => !rolesAvailableNew.includes(role));
      return { rolesAvailable: rolesAvailableNew, rolesSelected: rolesSelectedNew };
    });
  }

  handleReopen = (id) => {
    browserHistory.push(''.concat('/profil/felhasznalok/csoport/modositas?id=' + id));
  }

  handleBack = () => {
    browserHistory.push(''.concat('/profil/felhasznalok'));
  }

  handleInputChange = (e) => {
    const { target } = e;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [name]: value
    });
  }

  tableIconFormatter = (cell) => {
    return (
      <>
        <Button
          className="icon-pencil table-action-icon"
          id={'action-view-' + cell + 'modositas'}
          onClick={() => this.handleEditClick(cell)}
          type="button"
        />
        <UncontrolledTooltip
          style={{ float: 'left' }}
          placement="top"
          target={'action-view-' + cell + 'modositas'}
          delay={50}
        >
          {'Módosítás'}
        </UncontrolledTooltip>
        <Button
          className="icon-trash table-action-icon"
          id={'action-view-' + cell + 'file'}
          onClick={() => this.handleDeleteClick(cell)}
          type="button"
        />
        <UncontrolledTooltip
          style={{ float: 'left' }}
          placement="top"
          target={'action-view-' + cell + 'file'}
          delay={50}
        >
          {'Törlés'}
        </UncontrolledTooltip>
      </>
    );
  };

  tableStatusFormatter = (cell) => {
    return cell === true ? (
      <span className="badge badge-success">Aktív</span>
    ) : (
      <span className="badge badge-default">Inaktív</span>
    );
  };

  renderUserTable = () => {
    const selectOptions = {
      true: 'Aktív',
      false: 'Inaktív',
    };

    const { users } = this.state;
    const columns = [
      {
        dataField: 'username',
        text: 'Felhasználó név',
        filter: textFilter({
          delay: 400,
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
        },
      },
      {
        dataField: 'szemelynev',
        text: 'Felhasználó neve',
        filter: textFilter({
          delay: 400,
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
        },
      },
      {
        dataField: 'email',
        text: 'Email cím',
        filter: textFilter({
          delay: 400,
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
        },
      },
      {
        dataField: 'enabled',
        text: 'Állapot',
        filter: selectFilter({
          options: selectOptions,
          placeholder: 'Összes',
        }),
        style: {
          whiteSpace: 'pre-line',
          textAlign: 'center',
        },
        formatter: this.tableStatusFormatter,
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        formatter: this.tableIconFormatter,
      },
    ];
    return (
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={users}
        columns={columns}
        filter={filterFactory()}
      />
    );
  };

  renderButtons = () => {
    const { route } = this.props;
    return (
      <Row className="mt-2 justify-content-end">
        <Col md="auto">
          {route.type === 'FEL' && <Button className="mr-1" type="button" color="success" onClick={() => this.onSubmit(true)}>Mentés és jogosultság szerkesztése</Button>
          }
          <Button className="mr-1" type="button" color="primary" onClick={() => this.onSubmit(false)}>Mentés</Button>
          <Button type="button" color="secondary" onClick={this.handleBack}>Mégsem</Button>
        </Col>
      </Row>
    );
  }

  renderForm() {
    const { groupName } = this.state;
    return (
      <div className="row">
        <div className="col-md-12">
          <Row>
            <Col md="6">
              <Label>Csoport neve</Label>
              <Input
                id="groupName"
                name="groupName"
                value={groupName}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          {this.renderButtons()}
        </div>
      </div>
    );
  }

  render() {
    const { route } = this.props;
    const { rolesAvailable, rolesSelected } = this.state;
    return (
      <div className="animated fadeIn">
        <div className="card">
          <div className="card-header">
            {route.type === 'FEL'
              ? 'Jogosultság csoport rögzítése'
              : 'Jogosultság csoport módosítása'}
          </div>
          <div className="row" style={cardStyle}>
            <div className="col-md-12">
              {this.renderForm()}
            </div>
            {this.isEdit() && (
              <Col md="12">
                <FelhasznalokRoleCards rolesAvailable={rolesAvailable} rolesSelected={rolesSelected} addRoles={this.addRoles} removeRoles={this.removeRoles} selectedColumnName="Csoport jogosultságai" />
              </Col>
            )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default FelhasznalokCsoportForm;
