import Lib from '../../../commons/lib';

export default class Service {
  static udulesijogUrl = window.location.origin + '/api/udulesijog/';

  static partnerUrl = window.location.origin + '/api/partner/';

  static apartmanUrl = window.location.origin + '/api/apartman/';

  // KOMPENZACIO LISTA START

  /* static exportOsszevontXlsx(data, globals, setButtonStat) {
    setButtonStat({ listButtonLoading: true });
    Request
      .post(Service.udulesijogUrl + 'kompenzacio/list/osszevont/xls')
      .set('Accept', 'application/json, application/octet-stream')
      .responseType('arraybuffer')
      .send(data)
      .then((res) => {
        setButtonStat({ listButtonLoading: false });
        globals.addSuccess('Xlsx fájl letöltés indítása...');
        fileSaver.saveAs(new Blob([res.xhr.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', responseType: 'arraybuffer' }), res.header['content-disposition'] ? res.header['content-disposition'] : 'osszevont_lista_' + moment().format('YYYY-MM-DD') + '.xlsx');
      })
      .catch(() => {
        setButtonStat({ listButtonLoading: false });
        globals.addError('Hálozati hiba!');
      });
  } */

  static exportOsszevontXlsx(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.udulesijogUrl + 'kompenzacio/list/xlsx/osszevont',
      args: { requestType: 'kompenzacio-list-osszevont' },
      acceptType: 'application/json, application/octet-stream',
      responseType: 'arraybuffer',
      data: data
    }], fnDone);
  }

  static exportReszletesXlsx(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.udulesijogUrl + 'kompenzacio/list/xlsx/reszletes',
      args: { requestType: 'kompenzacio-list-osszevont' },
      acceptType: 'application/json, application/octet-stream',
      responseType: 'arraybuffer',
      data: data
    }], fnDone);
  }

  static exportFoglalasXlsx(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.udulesijogUrl + 'kompenzacio/list/xlsx/foglalas',
      args: { requestType: 'kompenzacio-list-foglalas' },
      acceptType: 'application/json, application/octet-stream',
      responseType: 'arraybuffer',
      data: data
    }], fnDone);
  }

  static getSzamlaviselo(text, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'partner-search' },
      params: {
        type: 'KOMPENZACIO_LISTA_SZAMLAVISELO_ELO',
        text: text
      }
    }], fnDone);
  }

  static getJogtulaj(text, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'partner-search' },
      params: {
        type: 'KOMPENZACIO_LISTA_JOGTULAJDONOS_ELO',
        text: text
      }
    }], fnDone);
  }

  /* static exportReszletesXlsx(data, globals, setButtonStat) {
    setButtonStat({ listButtonLoading: true });
    Request
      .post(Service.udulesijogUrl + 'kompenzacio/list/reszletes/xls')
      .set('Accept', 'application/json, application/octet-stream')
      .responseType('arraybuffer')
      .send(data)
      .then((res) => {
        setButtonStat({ listButtonLoading: false });
        globals.addSuccess('Xlsx fájl letöltés indítása...');
        fileSaver.saveAs(new Blob([res.xhr.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', responseType: 'arraybuffer' }), res.header['content-disposition'] ? res.header['content-disposition'] : 'reszletes_lista_' + moment().format('YYYY-MM-DD') + '.xlsx');
      })
      .catch(() => {
        setButtonStat({ listButtonLoading: false });
        globals.addError('Hálozati hiba!');
      });
  } */

  static getEloszuresValues(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'kompenzacio/list/getelo',
      args: { requestType: 'kompenzacio-list-getelo' },
    }], fnDone);
  }

  static getSzallodaValues(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'szalloda/listkompenzacioszallodak',
      args: { requestType: 'szalloda-listkompenzacioszallodak ' },
    }], fnDone);
  }

  static getKompenzacioEnum(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'kompenzacio/enum',
      args: { requestType: 'kompenzacio-enum' },
    }], fnDone);
  }

  // KOMPENZACIO LISTA END
}
