import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Badge,
  Button,
  FormGroup,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
  UncontrolledCollapse,
  UncontrolledTooltip
} from 'reactstrap';
import {
  AvField,
  AvFeedback,
  AvForm,
  AvGroup,
  AvInput
} from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import fileSaver from 'file-saver';
import moment from 'moment';
import { browserHistory } from 'react-router';
import Select from 'react-select/lib/Async';
import Service from './services';
import { ProgressButton } from '../../../commons/ProgressButton';
import DataEmptyState from '../../../commons/dataemptystate';
import QueryEmptyState from '../../../commons/queryempty';
import { DatePicker, PaginationTotal } from '../../../commons/controls';
import Lib from '../../../commons/lib';
import Constants from '../../../commons/constants';
import NumberFormatter from '../../../commons/NumberFormatter';
import SlaveAvInput from '../../../commons/SlaveAvInput';

class PartnertorzsLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalMegtekintes: false,
      dataLoaded: null,
      dataViewLoaded: null,
      queryStarted: null,
      partnerJson: [],

      nemOptions: [],
      tipusOptions: [],
      szallitoVevoOptions: [],
      statuszOptions: [],
      partnerCsoportOptions: [],
      cimTipusOptions: [],
      modalTorles: false,
      modalGdpr: false,
      cimDisabled: false,
      keresesFilterObj: null,

      statusz: [],
      filterOptions: {},
    };
  }

  // componentWillMount() {
  //   this.getInitData();
  // }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      if (this.props.active) {
        this.getInitData();
      }
    }
  }

  getInitData = () => {
    Service.initPartner((err, res) => {
      if (!err) {
        this.setState({ tipusOptions: res[0].tipus.map(e => (<option key={e.name} value={e.name}>{e.caption}</option>)) });

        let filters = {};
        let options = res[0].statusz.map((e) => {
          filters[e.name] = e.caption;

          return <option key={e.name} value={e.name}>{e.caption}</option>;
        });

        this.setState({ statuszOptions: options, filterOptions: filters, statusz: res[0].statusz });

        this.setState({ szallitoVevoOptions: res[0].szallitovevo.map(e => (<option key={e.name} value={e.name}>{e.caption}</option>)) });

        this.setState({ partnerCsoportOptions: res[0].partnercsoport.map(e => (<option key={e.id} value={e.id}>{e.nev}</option>)) });

        this.setState({ nemOptions: res[0].nem.map(e => (<option key={e.name} value={e.name}>{e.caption}</option>)) });

        this.setState({ cimTipusOptions: res[0].cimtipus.map(e => (<option key={e.name} value={e.name}>{e.caption}</option>)) });

        this.setState({
          tipusValue: res[0].tipus[1].name,
          statuszValue: res[0].statusz[0].name,
          cimDisabled: true,
          fileName: res[0].tipus[0].name
        });
      }
    });
  }

  getFilterObj = () => {
    let filterObj = {};

    filterObj.tipus = this.emptyOrNull(this.state.tipusValue);
    filterObj.statusz = this.emptyOrNull(this.state.statuszValue);
    filterObj.szallitovevo = this.emptyOrNull(this.state.szallitoVevoValue);
    filterObj.partnercsoportid = this.emptyOrNull(this.state.partnerCsoportValue);
    filterObj.nem = this.emptyOrNull(this.state.nemValue);
    filterObj.szuletesiidokezdete = this.emptyOrNull(this.state.tolValue);
    filterObj.szuletesiidovege = this.emptyOrNull(this.state.igValue);
    filterObj.nev = this.emptyOrNull(this.state.nev);
    filterObj.cimtipus = this.emptyOrNull(this.state.cimTipusValue);
    filterObj.adoszam = this.state.adoszam ? NumberFormatter.removeHyphensFromString(this.state.adoszam) : null;

    filterObj.orszag = this.state.orszagValue && this.emptyOrNull(this.state.orszagValue.nev);
    filterObj.orszagid = this.state.orszagValue && this.emptyOrNull(this.state.orszagValue.orszagid);
    filterObj.iranyitoszam = this.emptyOrNull(this.state.iranyitoszam);
    filterObj.telepulesid = this.state.telepulesValue && this.emptyOrNull(this.state.telepulesValue.telepulesid);
    filterObj.telepules = this.emptyOrNull(this.state.telepulesnev) === null
      ? this.state.telepulesValue && this.emptyOrNull(this.state.telepulesValue.telepulesnev) : this.emptyOrNull(this.state.telepulesnev);

    return filterObj;
  }

  updateTable = () => {
    let filterObj = this.getFilterObj();
    this.setState({
      dataLoaded: false,
      queryStarted: true,
      keresesFilterObj: filterObj
    });

    Service.listPartner(filterObj, (err, res) => {
      if (!err) {
        let newArray = [];
        if (res[0] && Array.isArray(res[0])) {
          res[0].forEach((item) => {
            let itemRow = item.p.split('⛄');
            let itemObj = {
              i: itemRow[0] === '⛲' ? '' : itemRow[0],
              a: itemRow[1] === '⛲' ? '' : itemRow[1],
              n: itemRow[2] === '⛲' ? '' : itemRow[2],
              p: itemRow[3] === '⛲' ? '' : itemRow[3],
              c: itemRow[4] === '⛲' ? '' : itemRow[4],
              e: itemRow[5] === '⛲' ? '' : itemRow[5],
              t: itemRow[6] === '⛲' ? '' : itemRow[6],
              s: itemRow[7] === '⛲' ? '' : itemRow[7],
            };
            newArray.push(itemObj);
          });
        }
        this.setState({
          partnerJson: newArray,
          dataLoaded: true,
          queryStarted: false,
          fileName: filterObj.tipus ? filterObj.tipus : 'OSSZES',
        });
      } else {
        this.setState({
          dataLoaded: null, queryStarted: null, fileName: null, partnerJson: [], keresesFilterObj: null
        });
      }
    });
  }

  exportXls = () => {
    this.setState({ xlsButtonLoading: true });
    Service.exportCustomXls(this.currentGdprId, (err, res) => {
      if (!err) {
        this.props.globals.addSuccess('GDPR XLS letöltése...');
        fileSaver.saveAs(new Blob([res[0]], { type: 'application/vnd.ms-excel', responseType: 'arraybuffer' }), 'GDPR_' + moment().format('YYYY-MM-DD') + '.xls');
      }
      this.setState({ xlsButtonLoading: false });
    });
  }

  exportXlsx = () => {
    this.setState({ xlsxButtonLoading: true });
    Service.exportCustomXlsx(this.currentGdprId, (err, res) => {
      if (!err) {
        this.props.globals.addSuccess('GDPR XLSX letöltése...');
        fileSaver.saveAs(new Blob([res[0]], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', responseType: 'arraybuffer' }), 'GDPR_' + moment().format('YYYY-MM-DD') + '.xlsx');
      }
      this.setState({ xlsxButtonLoading: false });
    });
  }

  partnerListaXlsx = () => {
    this.setState({ listButtonLoading: true }, () => {
      Service.listXlsx(this.state.keresesFilterObj, (err, res) => {
        this.setState({ listButtonLoading: false });
        if (!err) {
          this.props.globals.addSuccess('Partner lista XLSX letöltése...');
          fileSaver.saveAs(new Blob([res[0]], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', responseType: 'arraybuffer' }), 'Partnerlista_' + this.state.fileName + '_' + moment().format('YYYYMMDD_HHMMSS') + '.xlsx');
        }
      });
    });
  }

  /**
    * Paraméterben kapott partner ID-jű rekord törlése adatbázisból
    * @param {number} id
    */
   deletePartner = (id) => {
     this.toggleTorles();
     Service.deletePartner(id, (err) => {
       if (!err) {
         this.updateTable();
         this.props.globals.addSuccess('Partner törölve!');
       }
     });
   }

   navToAdd = () => {
     browserHistory.push('/torzsadatok/partnerek/felvetel');
   }

  toggleMegtekintes = () => {
    this.setState({ modalMegtekintes: !this.state.modalMegtekintes });
  }

  toggleTorles = () => {
    this.setState({ modalTorles: !this.state.modalTorles });
  }

  toggleGdpr = () => {
    this.setState({ modalGdpr: !this.state.modalGdpr });
  }

  toggleModositas = () => {
    this.setState({ modalModositas: !this.state.modalModositas });
  }

  iconFormatter = (cell, row) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'PARTNER_TORZS', 'LEK') && (
          <React.Fragment>
            <Button className="icon-eye table-action-icon" id={'action-view-' + cell} onClick={() => this.handleViewClick(cell)} />
            <UncontrolledTooltip placement="left" target={'action-view-' + cell} delay={0}>
              Megtekintés
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'PARTNER_TORZS', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-edit-' + cell} onClick={() => this.handleEditClick(cell, row)} />
            <UncontrolledTooltip style={{ float: 'right' }} placement="right" target={'action-edit-' + cell} delay={0}>
              Módosítás
            </UncontrolledTooltip>
            <Button className="icon-trash table-action-icon" id={'action-delete-' + cell + 'level'} onClick={() => this.handleDeleteClick(cell)} />
            <UncontrolledTooltip style={{ float: 'right' }} placement="right" target={'action-delete-' + cell + 'level'} delay={0}>
              Törlés
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'GDPR_EXPORT', 'LEK') && (
          <React.Fragment>
            <Button aria-label="Exportálás" className="fa fa-sign-out table-action-icon" id={'action-view-' + cell + 'exportalas'} onClick={() => this.handleGdprClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'exportalas'} delay={0}>
              Exportálás
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  handleViewClick = (id) => {
    this.setState({ dataViewLoaded: false });
    Service.getPartner(id, (err, res) => {
      if (!err) {
        if (res[0].adoszam) {
          res[0].adoszam = NumberFormatter.addHyphensToString(res[0].adoszam);
        }
        this.setState({
          partnerNezetObj: res[0],
          dataViewLoaded: true
        });
      }
    });
    this.toggleMegtekintes();
  }

  handleEditClick = (id) => {
    browserHistory.push('/torzsadatok/partnerek/modositas?id=' + id);
  }

  handleDeleteClick = (id) => {
    this._currentDeleteId = id;
    this.toggleTorles();
  }

  handleGdprClick = (id) => {
    this.currentGdprId = id;
    Service.listExport(id, (err, res) => {
      if (!err) {
        this.setState({ dataGdpr: res[0] }, () => {
          this.toggleGdpr();
        });
      }
    });
  }

  handleDateInputChange = (e, v, name) => {
    const value = v === '' ? null : v;
    this.setState({ [name]: value });
  }

  handleChangeTipus = (e, value) => {
    if (value && value.length !== 0) {
      this.setState({ tipusValue: value });

      if (value === 'CEG') {
        this.setState({
          nemValue: '',
          tolValue: null,
          igValue: null
        });
      }
    } else {
      this.setState({ tipusValue: '' });
    }
  }

  handleChangeStatusz = (e, value) => {
    if (value && value.length !== 0) {
      this.setState({ statuszValue: value });
    } else {
      this.setState({ statuszValue: '' });
    }
  }

  handleChangeSzallitoVevo = (e, value) => {
    if (value && value.length !== 0) {
      this.setState({ szallitoVevoValue: value });
    } else {
      this.setState({ szallitoVevoValue: '' });
    }
  }

  handleChangeNem = (e, value) => {
    if (value && value.length !== 0) {
      this.setState({ nemValue: value });
    } else {
      this.setState({ nemValue: '' });
    }
  }

  handleChangePartnerCsoport = (e, value) => {
    if (value && value.length !== 0) {
      this.setState({ partnerCsoportValue: value });
    } else {
      this.setState({ partnerCsoportValue: '' });
    }
  }

  handleChangeCimTipus = (e, value) => {
    if (value && value.length !== 0) {
      this.setState({
        cimTipusValue: value,
        cimDisabled: false
      });
    } else {
      this.setState({
        cimTipusValue: '',
        cimDisabled: true,
        telepulesSelect: false,
        orszagValue: '',
        iranyitoszam: '',
        telepulesValue: '',
        telepulesnev: ''
      });
    }
  }

  handleChangeOrszag = (value) => {
    if (value && value.length !== 0) {
      this.setState({
        telepulesSelect: value.keresheto,
        orszagValue: {
          orszagid: value.orszagid,
          nev: value.nev
        }
      }, () => {
        if (this.state.telepulesSelect) this.handleIrszamChange(null, this.state.iranyitoszam);
      });
    } else {
      this.setState({
        orszagValue: {
          orszagid: '',
          nev: ''
        }
      });
    }

    this.setState({
      iranyitoszam: '',
      telepulesValue: {
        telepulesid: '',
        telepulesnev: ''
      },
      telepulesnev: ''
    });
  }

  handleChangeTelepules = (value) => {
    if (value && value.length !== 0) {
      this.setState({
        telepulesValue: {
          telepulesid: value.telepulesid,
          telepulesnev: value.telepulesnev
        }
      });
    } else {
      this.setState({
        telepulesValue: {
          telepulesid: '',
          telepulesnev: ''
        }
      });
    }
  }

  handleIrszamChange = (event, value) => {
    this.setState({ iranyitoszam: value });
    if (this.state.telepulesSelect) {
      if (value && value.length >= 4) {
        Service.existsIrsz(this.state.orszagValue.orszagid, value, (err, res) => {
          if (!err && res[0] === 'false') {
            this.props.globals.addWarning('Ehhez az irányítószámhoz nem tartozik település!');
          }
        });
      }
    }
  }

  loadOrszagOptions = (input, callback) => {
    if (input.length !== 0) {
      Service.listOrszag(input, (err, res) => {
        if (!err) {
          callback(res[0]);
        }
      });
    }
  }

  loadTelepulesOptions = (input, callback) => {
    if (input.length !== 0) {
      Service.listTelepulesByOrszag(input, (this.state.orszagValue ? this.state.orszagValue.orszagid : null), (err, res) => {
        if (!err) {
          callback(res[0]);
        }
      });
    }
  }

  csvDecode = (cell, row) => {
    let statusz = this.state.statusz.find(x => x.name === row.s);

    return (cell === undefined ? '' : statusz.caption);
  }

  statusFormatter = (cell) => {
    let statusz = this.state.statusz.find(x => x.name === cell);
    return statusz ? <span className={'badge badge-' + statusz.bagde}>{statusz.caption}</span> : 'N/A';
  }

   emptyOrNull = (data) => {
     if (!data || data === '') return null;
     return data;
   }

   renderEloSzures = () => {
     return (
       <AvForm onValidSubmit={this.updateTable}>
         <div className="row">
           <div className="col-md-3">
             <AvField
               type="select"
               name="tipus"
               label="Típus:"
               onChange={this.handleChangeTipus}
               value={this.state.tipusValue}
             >
               <option value="" key="">Összes</option>
               {this.state.tipusOptions}
             </AvField>
           </div>
           <div className="col-md-3">
             <AvField
               type="select"
               name="statusz"
               label="Státusz:"
               onChange={this.handleChangeStatusz}
               value={this.state.statuszValue}
             >
               <option value="" key="">Összes</option>
               {this.state.statuszOptions}
             </AvField>
           </div>
           <div className="col-md-3">
             <AvField
               type="select"
               name="szallitovevo"
               label="Szállító/Vevő:"
               onChange={this.handleChangeSzallitoVevo}
             >
               <option value="">
                 {'Válassz szállító/vevő típust...'}
               </option>
               {this.state.szallitoVevoOptions}
             </AvField>
           </div>
           <div className="col-md-3">
             <AvField
               type="select"
               name="partnercsoport"
               label="Partnercsoport:"
               onChange={this.handleChangePartnerCsoport}
             >
               <option value="">
                 {'Válassz partnercsoportot...'}
               </option>
               {this.state.partnerCsoportOptions}
             </AvField>
           </div>
           <div className="col-md-3">
             <AvGroup>
               <Label for="nev">
                 {'Név:'}
               </Label>
               <AvInput
                 name="nev"
                 id="nev"
                 onChange={this.handleInputChange}
                 value={this.state.nev}
               />
             </AvGroup>
           </div>
           <div className="col-md-3">
             <SlaveAvInput
               id="adoszam"
               label="Adószám:"
               maxLength="30"
               onChange={this.handleInputChange}
               value={this.state.adoszam}
               szuroTipus={NumberFormatter.ADOSZAM}
             />
           </div>
           <div className="col-md-2">
             <AvField
               type="select"
               name="neme"
               label="Férfi/Nő:"
               onChange={this.handleChangeNem}
               disabled={this.state.tipusValue === 'CEG'}
               value={this.state.nemValue}
             >
               <option value="">
                 {'Válassz nemet...'}
               </option>
               {this.state.nemOptions}
             </AvField>
           </div>
           <div className="col-md-2">
             <Label for="datumKezdete">
               {'Születési dátum -tól:'}
             </Label>
             <DatePicker
               placeholder="éééé-hh-nn"
               onChange={(e, v) => this.handleDateInputChange(e, v, 'tolValue')}
               disabled={this.state.tipusValue === 'CEG'}
               value={this.state.tolValue && new Date(this.state.tolValue)}
               type="select"
               name="datumKezdete"
             />
           </div>
           <div className="col-md-2">
             <Label for="datumVege">
               {'Születési dátum -ig:'}
             </Label>
             <DatePicker
               placeholder="éééé-hh-nn"
               onChange={(e, v) => this.handleDateInputChange(e, v, 'igValue')}
               disabled={this.state.tipusValue === 'CEG'}
               value={this.state.igValue && new Date(this.state.igValue)}
               type="select"
               name="datumVege"
             />
           </div>
           <div className="col-md-2">
             <AvField
               type="select"
               name="cimtipus"
               label="Cím típusa:"
               onChange={this.handleChangeCimTipus}
             >
               <option value="">
                 {'Válassz cím típust...'}
               </option>
               {this.state.cimTipusOptions}
             </AvField>
           </div>
           <div className="col-md-2">
             <Label for="form-field-name">
               Ország:
             </Label>
             <Select
               styles={{
                 control: (base, { isDisabled }) => ({
                   ...base,
                   '&:focus': { borderColor: 'red' }, // border style on hover
                   border: '1px solid lightgray', // default border color
                   boxShadow: 'none', // no box-shadow
                   borderRadius: '0',
                   height: '36px !important',
                   minHeight: '36px',
                   backgroundColor: isDisabled ? '#cfd8dc' : null
                 }),
                 placeholder: (defaultStyles) => {
                   return {
                     ...defaultStyles,
                     color: '#345667',
                   };
                 }
               }}
               name="form-field-name"
               id="form-field-name"
               placeholder="Kezdjen el gépelni..."
               noOptionsMessage={() => 'Nincs keresési eredmény'}
               searchPromptText="Kezdjen el gépelni a kereséshez..."
               loadingMessage={() => 'Keresés...'}
               onChange={v => this.handleChangeOrszag(v)}
               isClearable
               ignoreAccents={false}
               loadOptions={_.debounce(this.loadOrszagOptions, 1000)}
               isLoading={false}
               getOptionLabel={option => option.nev}
               getOptionValue={option => option.orszagid}
               isDisabled={this.state.cimDisabled}
               value={this.state.orszagValue}
               cache={false}
               autoload={false}
             />
           </div>
           <div className="col-md-2">
             <AvGroup>
               <Label for="iranyitoszam">
                 Irányítószám:
               </Label>
               {this.state.telepulesSelect
                 ? (
                   <div>
                     <AvInput
                       name="iranyitoszam"
                       id="iranyitoszam"
                       minLength="4"
                       maxLength="20"
                       pattern="^[0-9]*$"
                       onChange={this.handleIrszamChange}
                       disabled={this.state.cimDisabled}
                       value={this.state.iranyitoszam}
                     />
                     <AvFeedback>
                       4 számjegyű mező!
                     </AvFeedback>
                   </div>
                 ) : (
                   <div>
                     <AvInput
                       name="iranyitoszam"
                       id="iranyitoszam"
                       maxLength="20"
                       onChange={this.handleInputChange}
                       disabled={this.state.cimDisabled}
                       value={this.state.iranyitoszam}
                     />
                   </div>
                 )}
             </AvGroup>
           </div>
           <div className="col-md-3">
             {this.state.telepulesSelect ? (
               <FormGroup>
                 <Label for="form-field-name">
                   Település:
                 </Label>
                 <Select
                   styles={{
                     control: base => ({
                       ...base,
                       '&:focus': { borderColor: 'red' }, // border style on hover
                       border: '1px solid lightgray', // default border color
                       boxShadow: 'none', // no box-shadow
                       borderRadius: '0'
                     }),
                   }}
                   name="form-field-name"
                   id="form-field-name"
                   placeholder="Kezdjen el gépelni..."
                   noOptionsMessage={() => 'Nincs keresési eredmény'}
                   searchPromptText="Kezdjen el gépelni a kereséshez..."
                   loadingMessage={() => 'Keresés...'}
                   onChange={v => this.handleChangeTelepules(v)}
                   isClearable
                   ignoreAccents={false}
                   loadOptions={_.debounce(this.loadTelepulesOptions, 1000)}
                   isLoading={false}
                   getOptionLabel={option => option.telepulesnev}
                   getOptionValue={option => option.telepulesid}
                   disabled={this.state.cimDisabled}
                   value={this.state.telepulesValue}
                   cache={false}
                   autoload={false}
                 />
               </FormGroup>
             ) : (
               <div>
                 <Label for="telepulesnev">
                   Település:
                 </Label>
                 <AvInput
                   name="telepulesnev"
                   id="telepulesnev"
                   onChange={this.handleInputChange}
                   disabled={this.state.cimDisabled}
                   value={this.state.telepulesnev}
                 />
               </div>
             )}
           </div>
           <div className="col-md-3" style={{ textAlign: 'right' }}>
             {this.state.partnerJson.length !== 0 ? (
               <div style={{ marginTop: 21 }}>
                 <ProgressButton className="margin-top-0" color="success" style={{ marginTop: 8 }} loading={this.state.listButtonLoading} onClick={this.partnerListaXlsx} disabled={!this.state.keresesFilterObj}>Exportálás XLSX-be</ProgressButton>
                 <Button className="margin-top-0" color="primary" style={{ marginLeft: 8, marginTop: 8 }}>Keresés</Button>
               </div>
             ) : (
               <Button className="margin-top-0" color="primary" style={{ marginTop: 29 }}>Keresés</Button>
             )}
           </div>
         </div>
       </AvForm>
     );
   }

   renderGdprData = () => {
     let gdprData = [];
     if (this.state.dataGdpr && this.state.dataGdpr.elements && this.state.dataGdpr.elements[0] && this.state.dataGdpr.elements[0].adatok) {
       this.state.dataGdpr.elements[0].adatok.forEach((elem, idx) => {
         gdprData.push(
           <div style={{ margin: 10, minWidth: '100%', cursor: 'pointer' }} id={'selector' + idx.toString()}>
             <h5 style={{ float: 'right' }}>
               <i className="icon-action fa fa-chevron-down" style={{ paddingRight: 15 }} />
             </h5>
             <h5 style={{ float: 'left' }}>
               {elem.k.split('|', 1)}
             </h5>
           </div>
         );
         elem.v.forEach((elem2) => {
           gdprData.push(this.getListElem(elem2, idx));
         });
       });
     }
     return gdprData;
   }

   getListElem = (json, idx) => {
     return (
       <UncontrolledCollapse toggler={'#selector' + idx.toString()} style={{ minWidth: '100%' }}>
         <ListGroup style={{ minWidth: '100%' }}>
           <ListGroupItem style={{ padding: 0 }}>
             {json.to ? (
               <div
                 className="d-flex flex-column"
                 style={{
                   width: 100,
                   alignItems: 'center',
                   justifyContent: 'center',
                   backgroundColor: Constants.COLOR.DANGER,
                   color: 'white',
                   paddingRight: 15,
                   paddingLeft: 15,
                   minHeight: 105
                 }}
               >
                 <div style={{ paddingTop: 16 }}>
                   <h6 style={{ margin: 2 }}>
                     LEJÁRT
                   </h6>
                 </div>
                 <div>
                   <h6 style={{ margin: 2, textAlign: 'center', paddingTop: 10 }}>
                     {json.es ? json.es : 'Lejárat nélküli'}
                   </h6>
                 </div>
                 <div style={{ paddingBottom: 20 }} />
               </div>
             ) : (
               <div
                 className="d-flex flex-column"
                 style={{
                   width: 100,
                   alignItems: 'center',
                   justifyContent: 'center',
                   backgroundColor: Constants.COLOR.SUCCESS,
                   color: 'white',
                   paddingRight: 15,
                   paddingLeft: 15,
                   minHeight: 105
                 }}
               >
                 <div style={{ paddingTop: 16 }}>
                   <h6 style={{ margin: 2 }}>
                     LEJÁR
                   </h6>
                 </div>
                 <div>
                   <h6 style={{ margin: 2, textAlign: 'center', paddingTop: 10 }}>
                     {json.es ? json.es : 'Lejárat nélküli'}
                   </h6>
                 </div>
                 <div style={{ paddingBottom: 20 }} />
               </div>
             )}
             <div className="d-flex align-items-start flex-column">
               <div style={{ padding: 5 }}>
                 <h5 style={{ margin: 2, marginLeft: 5 }}>
                   {json.az ? json.az : '-'}
                 </h5>
               </div>
               <div style={{ padding: 5, marginLeft: 5 }}>
                 <p style={{ marginBottom: 0 }}>
                   felvétel dátum: {json.fe ? json.fe : '-'} | elévülési idő: {!isNaN(json.ev) ? json.ev + ' év' : '-'}
                 </p>
                 <p>
                   lezárás dátum: {json.le ? json.le : '-'} | elévülés dátum: {json.el ? json.el : '-'}
                 </p>
               </div>
             </div>
           </ListGroupItem>
         </ListGroup>
       </UncontrolledCollapse>
     );
   }

   renderAdatok = () => {
     let telefonszamList = [];
     let bankszamlaList = [];
     if (this.state.partnerNezetObj && this.state.partnerNezetObj.telefonszamok) {
       this.state.partnerNezetObj.telefonszamok.forEach((item) => {
         telefonszamList.push(<li><b>Típus: </b>{item.tipus.caption}<br /></li>);
         telefonszamList.push(<span><b>Előhívószám: </b>+{item.orszag.num}, <span className={'flag-icon flag-icon-' + item.orszag.name.toLowerCase()} /> {item.orszag.caption} <br /></span>);
         telefonszamList.push(<span><b>Szám: </b>{item.szam}<br /></span>);
         telefonszamList.push(<span><b>Elsődleges: </b>{item.elsodleges ? 'Igen' : 'Nem'}<br /><br /></span>);
       });
     }
     if (this.state.partnerNezetObj && this.state.partnerNezetObj.bankszamlaszamok) {
       this.state.partnerNezetObj.bankszamlaszamok.forEach((item) => {
         bankszamlaList.push(<li><b>Pénzintézet neve: </b>{item.penzintezet}<br /></li>);
         bankszamlaList.push(<span><b>Bankszámlaszám: </b>{item.bankszamlaszam}<br /></span>);
         bankszamlaList.push(<span><b>IBAN szám: </b>{item.ibanszam}<br /><br /></span>);
       });
     }

     let cimOrszag = this.state.partnerNezetObj && this.state.partnerNezetObj.orszag && this.state.partnerNezetObj.orszag.nev
       && this.state.partnerNezetObj.orszag.nev !== 'Magyarország' ? ' (' + this.state.partnerNezetObj.orszag.nev + ')' : '';
     let cimSzallOrszag = this.state.partnerNezetObj && this.state.partnerNezetObj.szallOrszag && this.state.partnerNezetObj.szallOrszag.nev
       && this.state.partnerNezetObj.szallOrszag.nev !== 'Magyarország' ? ' (' + this.state.partnerNezetObj.szallOrszag.nev + ')' : '';
     let cimSzamOrszag = this.state.partnerNezetObj && this.state.partnerNezetObj.szamOrszag && this.state.partnerNezetObj.szamOrszag.nev
       && this.state.partnerNezetObj.szamOrszag.nev !== 'Magyarország' ? ' (' + this.state.partnerNezetObj.szamOrszag.nev + ')' : '';

     return this.state.dataViewLoaded ? (
       <div>
         <h6>Alapadatok:</h6>
         <b>Típus: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.tipus && this.state.partnerNezetObj.tipus.caption}<br />
         <b>Szállító/Vevő: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.szallitovevo && this.state.partnerNezetObj.szallitovevo.caption}<br />
         <b>Partner azonosító: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.azonosito}<br />
         <b>Státusz: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.statusz && this.state.partnerNezetObj.statusz.caption}<br />
         <b>Csoport: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.partnercsoportentity && this.state.partnerNezetObj.partnercsoportentity.nev}<br />
         {this.state.partnerNezetObj.tipus.name === 'CEG' ? (
           <span>
             <b>Cégnév: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.cegnev}<br />
             <b>Cégjegyzékszám: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.cegjegyzekszam}<br />
             <b>Kapcsolattartó: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.kapcsolattarto}<br />
           </span>
         ) : (
           <span>
             <b>Titulus: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.titulus}<br />
             <b>Vezetéknév: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.vezeteknev}<br />
             <b>Keresztnév: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.keresztnev}<br />
             <b>Születési név: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.szulvezeteknev} {this.state.partnerNezetObj && this.state.partnerNezetObj.szulkeresztnev}<br />
           </span>
         )}
         <br />
         <h6>Elérhetőségek:</h6>
         <b>Hivatalos cím: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.cim}{cimOrszag}<br />
         <b>Szállítási cím eltérő: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.szallcimeltero ? 'Igen' : 'Nem'}<br />
         <b>Szállítási cím: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.szallitasicim}{cimSzallOrszag}<br />
         <b>Levelezési cím eltérő: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.szamcimeltero ? 'Igen' : 'Nem'}<br />
         <b>Levelezési cím: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.szamlazasicim}{cimSzamOrszag}<br />
         <b>Postafiók: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.postafiok}<br />
         <b>Email: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.email}<br /><br />
         <h6>Telefonszámok:</h6>
         <ul>
           {telefonszamList}
         </ul>
         <h6>Egyéb adatok:</h6>
         <b>Kedvezmény: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.kedvezmeny} {this.state.partnerNezetObj && this.state.partnerNezetObj.kedvezmeny && '%'}<br />
         {this.state.partnerNezetObj.tipus.name === 'CEG' ? <span /> : (
           <span>
             <b>Adóazonosító jel: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.adoazonosito}<br />
           </span>
         )}
         <b>Adószám: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.adoszam}<br />
         <b>Közösségi adószám: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.kozossegiadoszam}<br />
         <br />
         <h6>Bank adatok:</h6>
         <ul>
           {bankszamlaList}
         </ul>
         <h6>Kiegészítő adatok:</h6>
         <b>Megjegyzés: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.megjegyzes}<br />
         {this.state.partnerNezetObj.tipus.name === 'CEG' ? <span /> : (
           <span>
             <b>Születési idő: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.szuletesiido}<br />
             <b>Neme: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.neme && this.state.partnerNezetObj.neme.caption}<br />
             <b>Névnap: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.nevnap}<br />
             <b>TAJ szám: </b>{this.state.partnerNezetObj && this.state.partnerNezetObj.tajszam}<br />
           </span>
         )}
       </div>
     ) : this.renderLoader();
   }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value
    });
  }

  renderTable = () => {
    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      exportCSVText: 'Exportálás CSV-be',
      exportCSVSeparator: ';',
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    return this.state.partnerJson.length ? (
      <div>
        <BootstrapTable data={this.state.partnerJson} pagination options={options} withoutTabIndex exportCSV csvFileName="partner.csv">
          <TableHeaderColumn csvHeader="AZONOSÍTÓ" dataField="a" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Partner azonosító</TableHeaderColumn>
          <TableHeaderColumn csvHeader="NÉV" dataField="n" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Partner neve</TableHeaderColumn>
          <TableHeaderColumn csvHeader="PARTNERCSOPORT" dataField="p" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Partnercsoport</TableHeaderColumn>
          <TableHeaderColumn csvHeader="CÍM" dataField="c" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Cím</TableHeaderColumn>
          <TableHeaderColumn csvHeader="EMAIL" dataField="e" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Email</TableHeaderColumn>
          <TableHeaderColumn csvHeader="TELEFON" dataField="t" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Telefon</TableHeaderColumn>
          <TableHeaderColumn
            dataField="s"
            dataFormat={this.statusFormatter}
            filter={{
              type: 'SelectFilter',
              options: this.state.filterOptions,
              placeholder: 'Összes',
              condition: 'eq'
            }}
            dataAlign="center"
            export={false}
          >
          Státusz
          </TableHeaderColumn>
          <TableHeaderColumn
            csvHeader="STÁTUSZ"
            width="120"
            dataField="i"
            isKey
            dataFormat={this.iconFormatter}
            csvFormat={(cell, row) => this.csvDecode(cell, row)}
          >
            Műveletek
          </TableHeaderColumn>
        </BootstrapTable>
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className} backdrop="static">
          <ModalHeader>
            Törlés
          </ModalHeader>
          <ModalBody>
            Valóban törölni kívánja a kiválasztott partnert?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.deletePartner(this._currentDeleteId)}>
              OK
            </Button>
            <Button type="button" color="secondary" onClick={this.toggleTorles}>
              Mégsem
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalGdpr}
          toggle={this.toggleGdpr}
          className={this.props.className}
          backdrop="static"
          size="export"
        >
          <ModalHeader>
            {this.state.dataGdpr && this.state.dataGdpr.nev && this.state.dataGdpr.nev + ' '}
            (
            {this.state.dataGdpr && this.state.dataGdpr.azon && this.state.dataGdpr.azon}
            )
          </ModalHeader>
          <ModalBody>
            <ListGroup>
              <ListGroupItem>
                {this.renderGdprData().length > 0 ? this.renderGdprData() : (
                  <UncontrolledAlert color="info" toggle={null} style={{ width: '100%', marginBottom: 0 }}>
                    A partner egyik modulban sincs használatban!
                  </UncontrolledAlert>
                )}
              </ListGroupItem>
            </ListGroup>
          </ModalBody>
          <ModalFooter>
            <ProgressButton
              className="btn-cursor-pointer"
              type="button"
              color="primary"
              onClick={this.exportXls}
              loading={this.state.xlsButtonLoading}
            >
              Exportálás (xls)
            </ProgressButton>
            <ProgressButton
              className="btn-cursor-pointer"
              type="button"
              color="primary"
              onClick={this.exportXlsx}
              loading={this.state.xlsxButtonLoading}
            >
              Exportálás (xlsx)
            </ProgressButton>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleGdpr}>
              Vissza
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    ) : <DataEmptyState />;
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-12">
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'PARTNER_TORZS', 'KARB')
              && <Button className="margin-bottom-20" color="primary" onClick={this.navToAdd}>Új partner</Button>
            }
          </div>
        </div>
        {this.renderEloSzures()}
        <div className="row">
          <div className="col-md-12" style={{ marginTop: 8 }}>
            { this.state.queryStarted ? this.renderLoader() : this.state.dataLoaded ? this.renderTable() : <QueryEmptyState /> }
          </div>
        </div>

        <Modal isOpen={this.state.modalMegtekintes} toggle={this.toggleMegtekintes} className={this.props.className} backdrop="static">
          <ModalHeader>Megtekintés</ModalHeader>
          <ModalBody>{this.state.modalMegtekintes ? this.renderAdatok(this._currentViewId) : <div />}</ModalBody>
          <ModalFooter><Button type="button" color="primary" onClick={this.toggleMegtekintes} className="btn-cursor-pointer">Vissza</Button></ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalModositas} toggle={this.toggleModositas} className={this.props.className} backdrop="static" size="lg">
          <AvForm onValidSubmit={this.toggleModositas}>
            <ModalHeader>
              Módosítás
            </ModalHeader>
            <ModalBody>
              { !this.state.dataModifyLoading ? (
                <div className="row">
                  {/* {this.renderModositoForm()} */}
                </div>
              )
                : this.renderLoader()}
            </ModalBody>
            <ModalFooter>
              <Button type="button" color="primary" onClick={() => { this.edit(); }}>Mentés</Button>
              {' '}
              <Button type="button" color="secondary" onClick={this.toggleModositas}>Mégse</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </div>
    );
  }
}

PartnertorzsLista.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  globals: PropTypes.object,
};

export default PartnertorzsLista;
