import Lib from '../../../commons/lib';

export default class Service {
  static cegadatUrl = window.location.origin + '/api/cegadat/';

  static telepulesUrl = window.location.origin + '/api/telepules/';

  // SZÉKHELY START

  static listSzekhely(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cegadatUrl + 'szekhely/list',
      args: { requestType: 'szekhely-list' }
    }], fnDone);
  }

  static getSzekhely(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cegadatUrl + 'szekhely',
      args: { requestType: 'szekhely-get' },
      params: { id: id }
    }], fnDone);
  }

  static addSzekhely(szekhelyObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.cegadatUrl + 'szekhely',
      args: { requestType: 'szekhely-post' },
      data: szekhelyObject
    }], fnDone);
  }

  static editSzekhely(szekhelyObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.cegadatUrl + 'szekhely',
      args: { requestType: 'szekhely-put' },
      data: szekhelyObject
    }], fnDone);
  }

  static contentOfSzekhelyDoc(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cegadatUrl + 'cegadat/content',
      args: { requestType: 'cegadat-empty-list' },
      params: { id: id }
    }], fnDone);
  }

  static activateSzekhely(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.cegadatUrl + 'szekhely/aktiv',
      args: { requestType: 'szekhely-activate' },
      params: { id: id }
    }], fnDone);
  }

  static listActivSzekhely(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cegadatUrl + 'szekhely/aktiv',
      args: { requestType: 'szekhely-list-active' }
    }], fnDone);
  }

  static checkActivSzekhely(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cegadatUrl + 'szekhely/checkszekhely',
      args: { requestType: 'szekhely-check-active' }
    }], fnDone);
  }

  static downloadObject(objid, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cegadatUrl + 'cegadat/object',
      args: { requestType: 'cegadat-get-object' },
      acceptType: 'application/octet-stream',
      params: { id: objid }
    }], fnDone);
  }

  // SZÉKHELY END

  // TELEPHELY START

  static listTelephely(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cegadatUrl + 'telephely/list',
      args: { requestType: 'telephely-list' }
    }], fnDone);
  }

  static getTelephely(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cegadatUrl + 'telephely',
      args: { requestType: 'telephely-get' },
      params: { id: id }
    }], fnDone);
  }

  static addTelephely(telephelyObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.cegadatUrl + 'telephely',
      args: { requestType: 'telephely-post' },
      data: telephelyObject
    }], fnDone);
  }

  static editTelephely(telephelyObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.cegadatUrl + 'telephely',
      args: { requestType: 'telephely-put' },
      data: telephelyObject
    }], fnDone);
  }

  static listActivTelephely(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cegadatUrl + 'telephely/aktiv',
      args: { requestType: 'telephely-list-active' },
      params: {
        from: false,
        munkalap: false
      }
    }], fnDone);
  }

  static deleteTelephely(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.cegadatUrl + 'telephely',
      args: { requestType: 'telephely-delete' },
      params: { id: id }
    }], fnDone);
  }

  // TELEPHELY END

  // FIÓKTELEP START

  static listFioktelep(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cegadatUrl + 'fioktelep/list',
      args: { requestType: 'fioktelep-list' }
    }], fnDone);
  }

  static getFioktelep(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cegadatUrl + 'fioktelep',
      args: { requestType: 'fioktelep-get' },
      params: { id: id }
    }], fnDone);
  }

  static addFioktelep(fioktelepObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.cegadatUrl + 'fioktelep',
      args: { requestType: 'fioktelep-post' },
      data: fioktelepObject
    }], fnDone);
  }

  static editFioktelep(fioktelepObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.cegadatUrl + 'fioktelep',
      args: { requestType: 'fioktelep-put' },
      data: fioktelepObject
    }], fnDone);
  }

  static listActivFioktelep(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cegadatUrl + 'fioktelep/aktiv',
      args: { requestType: 'fioktelep-list-active' },
    }], fnDone);
  }

  static deleteFioktelep(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.cegadatUrl + 'fioktelep',
      args: { requestType: 'fioktelep-delete' },
      params: { id: id }
    }], fnDone);
  }

  // FIÓKTELEP END

  // TELEPÜLÉS START

  static getTelepulesByIrszam(irszam, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'telepules',
      args: { requestType: 'telepules-get-irszam' },
      params: { iranyitoszam: irszam }
    }], fnDone);
  }

  static getTelepulesById(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'telepules/' + id,
      args: { requestType: 'telepules-get-id' }
    }], fnDone);
  }

  // TELEPÜLÉS END


  // ORSZÁG START

  static listOrszagByKod(kod, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'orszag/kod',
      args: { requestType: 'orszag-list-kod' },
      params: { kod: kod }
    }], fnDone);
  }

  static listOrszag(name, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'orszag/list',
      args: { requestType: 'orszag-list' },
      params: { name: name }
    }], fnDone);
  }

  // ORSZÁG END

  // NEW CÉGADAT START

  static getCegadatList(aktiv, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cegadatUrl + 'cegadat/list',
      args: { requestType: 'cegadat-list' },
      params: { aktiv: aktiv }
    }], fnDone);
  }

  static getCegadat(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cegadatUrl + 'cegadat',
      args: { requestType: 'cegadat-get' },
      params: { id: id }
    }], fnDone);
  }

  static modifyCegadat(data, cegadatValtozas, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.cegadatUrl + 'cegadat',
      args: { requestType: 'cegadat-put' },
      params: { cegadatvaltozas: cegadatValtozas },
      data: data,
    }], fnDone);
  }

  static checkBank(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.cegadatUrl + 'cegadat/checkbank',
      args: { requestType: 'cegadat-checkbank-put' },
      data: data
    }], fnDone);
  }

  static addCegadat(cegadatObj, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.cegadatUrl + 'cegadat',
      args: { requestType: 'cegadat-post' },
      data: cegadatObj
    }], fnDone);
  }

  static deleteCegadat(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.cegadatUrl + 'cegadat',
      args: { requestType: 'cegadat-delete' },
      params: { id: id }
    }], fnDone);
  }

  static getCegadatEnum(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cegadatUrl + 'cegadat/enum',
      args: { requestType: 'cegadat-get-enum' },
    }], fnDone);
  }

  static listIranyitoszam(nev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'telepules/irsz',
      args: { requestType: 'iranyitoszam-list' },
      params: { telepulesnev: nev }
    }], fnDone);
  }

  static listTelepules(irszam, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'telepules',
      args: { requestType: 'telepules-get-irszam' },
      params: { iranyitoszam: irszam }
    }], fnDone);
  }

  static listCim(text, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'telepules/like',
      args: { requestType: 'telepules-get-listcim' },
      params: { text: text }
    }], fnDone);
  }

  static editFile(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.cegadatUrl + 'cegadat/file',
      args: { requestType: 'cegadat-file-put' },
      data: data
    }], fnDone);
  }

  static getAktivSzekhelyList(ids, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.cegadatUrl + 'search',
      args: { requestType: 'cegadat-search-put' },
      params: {
        aktiv: true,
        resultclass: '10',
        tipus: 'S'
      },
      data: ids
    }], fnDone);
  }


  // NEW CÉGADAT END
}
