import React, { useState, useEffect, useRef} from 'react';
import { 
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter} from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import Loader from '../../../commons/loader';
import Services from '../services';
import AjanlatFilter from './AjanlatFilter';
import { PaginationTotal } from '../../../commons/controls';
import { ProgressButton } from '../../../commons/ProgressButton';
import NumberFormatter from '../../../commons/NumberFormatter';
import Lib from '../../../commons/lib';
import moment from 'moment';
import { browserHistory } from 'react-router';
import ReactHtmlParser from "react-html-parser";

const AjanlatContent = (props) => {
  const {
    route,
    active
  } = props;

  const [filters, setFilters] = useState([]);
  const [rows, setRows] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [viewObj, setViewObj] = useState({});
  const [currentId, setCurrentId] = useState(null);
  const [selected, setSelected] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [printUrl, setPrintUrl] = useState(null);
  const [tableStatus, setTableStatus] = useState('TABL'); // ['LOAD', 'DATA', 'NETW', 'TABL']
  const [viewStatus, setViewStatus] = useState('DATA'); // ['LOAD', 'DATA', 'NETW'] 
  const [deleteModal, toggleDeleteModal] = useState(false);
  const [viewModal, toggleViewModal] = useState(false);
  const [penznem, setPenznem] = useState(null);

  useEffect(() => {
    setTableStatus('LOAD');
    setDefaultPenznem();
  }, []);


  useEffect(() => {
    switch(tableStatus){
      case "LOAD": 
        listAjanlat(filters);
      break;
    }
  },[tableStatus]);

  useEffect(() => {
  },[expandedRows]);

  useEffect(() => {
    if(currentId) setViewStatus('LOAD');
  },[currentId]);

  useEffect(() => {
    if(!deleteId) setTableStatus('LOAD');
  },[deleteId]);

  useEffect(() => {
    if(!deleteModal) setDeleteId(null);
  },[deleteModal]);

  useEffect(() => {
    switch(viewStatus){
      case "LOAD": 
        getAjanlat();
        toggleViewModal(!viewModal);
      break;
    }
  },[viewStatus]);

  useEffect(() => {
    if(printUrl){
      window.setTimeout(() => {

        window.frames["pdf-area-ajanlat-rendeles"].focus();
        window.frames["pdf-area-ajanlat-rendeles"].print();
        window.setTimeout(() => {
        window.close();
        }, 50);

      }, 1000);
      route.globals.addSuccess('Ajánlat rendelés nyomtatás!');
      setTableStatus('LOAD');
    }
  }, [printUrl]);

  // TODO kivenni, ideiglenes megoldás amíg csak fixen 1 pénznem lehet
  const setDefaultPenznem = () => {
    Services.getDefaultCurrency(true, (err, res) => {
      if (!err) {
        setPenznem(res[0]);
      }
    });
  };

  const roundNumber = (number) => {
    if (penznem) {
      let multiplier = (10 ** penznem.tizedesjegy);
      let num = number * multiplier;
      if (penznem.kerekitesiszabaly === 'HALF_UP') {
        num = Math.round(num);
      } else if (penznem.kerekitesiszabaly === 'HALF_DOWN') {
        // A toFixed() nem megbízható (pl 1.005 => 1.00, 1.006 => 1.01) ezért a round van használva (.5-nél "pozitív irányba" kerekít, tehát -2.5 => -2, 2.5 => 3)
        num = -Math.round(-num);
      }
      return num / multiplier;
    }
  };

  const listAjanlat = async (defaultFilters = {kelte: new Date()}) => {
    try{
      setRows(await Services.listAjanlat((filters && filters != {})  ? filters : defaultFilters));
      //setExpandedRows([]);
      setTableStatus('TABL');
    }catch(ex){
      setTableStatus('NETW');
    }
  }

  const getAjanlat = async() => {
    try{
      setViewObj(await Services.getAjanlat({id: currentId}));
      setViewStatus('DATA');
    }catch(ex){
      setViewStatus('NETW');
    }
  }

  const loadExpandedRows = async (row) => {
    if(expandedRows.find(x => x.id == row.id)){
      setExpandedRows([]);
    }else{
      try{
        const cikkek = await Services.listAjanlatCikk({id: row.id});
        setExpandedRows([{id: row.id, data: cikkek}]);
      }catch(ex){}
    }
  }

  const handleForm = (id) => {
    setCurrentId(id);
    if(!id){
      browserHistory.push("".concat('/rendelesek/rendeles/vevoi/ajanlat/felvetel'));
    }else{
      browserHistory.push("".concat('/rendelesek/rendeles/vevoi/ajanlat/modositas?id=' + id));
    }
  }

  const handleDelete = (id) => {
    setDeleteId(id);
    toggleDeleteModal(!deleteModal);
  }

  const deleteRow = async (id) => {
    try{
      const res = await Services.deleteAjanlat({id: deleteId});
      toggleDeleteModal(!deleteModal);
    }catch(ex){}
  }

  const printRow = async (id) => {
    try{
      const res = await Services.printAjanlat({id: id});
      setPrintUrl(URL.createObjectURL(new Blob([res], { type: 'application/pdf' })));
    }catch(ex){}
  }

  const sendRow = async (id) => {
    try{
      const res = await Services.sendAjanlat({id: id});
      listAjanlat(filters);
      route.globals.addSuccess('Ajánlat rendelés elküldve!');
    }catch(ex){}
  }

  const duplicateRow = async (id) => {
    try{
      const res = await Services.duplicateAjanlat({id: id});
      listAjanlat(filters);
      route.globals.addSuccess('Ajánlat duplikálás elkészült!');
    }catch(ex){}
  }

  const abortRow = async (id) => {
    try{
      const res = await Services.abortAjanlat({id: id});
      listAjanlat(filters);
      route.globals.addSuccess('Ajánlat meghiúsult!');
    }catch(ex){}
  }

  const tableIconFormatter = (id, row) => {
    const isRogzitett = row && row.allapot && row.allapot.name === 'ROGZITETT';
    const isElkuldott = row && (row.nyomtatasIdopont || row.kuldesIdopont);
    const isMeghiusult = row && row.allapot && row.allapot.name === 'MEGHIUSULT';
    return (
      <div>
        <React.Fragment>
          {Lib.KeyCloak.hasHigherRole(route.globals.keycloak, 'RENDELES_AJANLAT', 'LEK') && (
            <React.Fragment>
              {/* TODO Ideiglenes megoldás, cserélni kell */}
              <Button className="icon-eye table-action-icon" id={'action-view-' + id + 'megtekint'} onClick={() => { if (currentId == id) { setViewStatus('LOAD'); } setCurrentId(id); }} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
              <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + id + 'megtekint'} delay={50}>
                {'Megtekintés'}
              </UncontrolledTooltip>
            </React.Fragment>
          )}

        </React.Fragment>
        {Lib.KeyCloak.hasHigherRole(route.globals.keycloak, 'RENDELES_AJANLAT', 'KARB') && (
          <React.Fragment>

            {isRogzitett && !isElkuldott && (
              <React.Fragment>
                <Button className="icon-pencil table-action-icon" id={'action-view-' + id + 'modositas'} onClick={() => handleForm(id)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + id + 'modositas'} delay={50}>
                  {'Módosítás'}
                </UncontrolledTooltip>
              </React.Fragment>
            )}

            <React.Fragment>
              <Button className="icon-docs table-action-icon" id={'action-view-' + id + 'duplicate'} onClick={() => duplicateRow(id)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
              <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + id + 'duplicate'} delay={50}>
                {'Duplikálás'}
              </UncontrolledTooltip>
            </React.Fragment>

            {isRogzitett && isElkuldott && (
              <React.Fragment>
                <Button className="icon-close table-action-icon" id={'action-view-' + id + 'abort'} onClick={() => abortRow(id)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + id + 'abort'} delay={50}>
                  {'Meghiúsítás'}
                </UncontrolledTooltip>
              </React.Fragment>
            )}

            {!isMeghiusult && (
              <React.Fragment>
                <Button className="icon-printer table-action-icon" id={'action-view-' + id + 'print'} onClick={() => printRow(id)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + id + 'print'} delay={50}>
                  {'Visszaigazolás (Nyomtatás)'}
                </UncontrolledTooltip>
              </React.Fragment>
            )}

            {!isMeghiusult && (
              <React.Fragment>
                <Button className="icon-envelope table-action-icon" id={'action-view-' + id + 'send'} onClick={() => sendRow(id)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + id + 'send'} delay={50}>
                  {'Visszaigazolás (E-mail küldés)'}
                </UncontrolledTooltip>
              </React.Fragment>
            )}

            {isRogzitett && !isElkuldott && (
              <React.Fragment>
                <Button className="icon-trash table-action-icon" id={'action-view-' + id + 'delete'} onClick={() => handleDelete(id)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + id + 'delete'} delay={50}>
                  {'Törlés'}
                </UncontrolledTooltip>
              </React.Fragment>
            )}

          </React.Fragment>
        )}
      </div>
    );
  }

  const renderViewBase = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          {{
            LOAD: (<Loader />),
            NETW: (<NetworkEmptyState
              hasImage
              submitClick={() => getAjanlat(currentId)}
            />),
            DATA: renderView()
          }[viewStatus]}
        </div>
      </div>
    );
  }


  const renderView = () => {
    return (
      viewObj && (
        <div>
          <h6>Ajánlat rendelés adatai:</h6>
          <b>Állapot: </b>{viewObj.allapot && viewObj.allapot.caption}<br />
          <b>Rendelés kelte: </b>{moment(viewObj.kelte).format("YYYY-MM-DD")}<br />
          <b>Érvényesség: </b>{moment(viewObj.ervenyesseg).format("YYYY-MM-DD")}<br />
          <b>Nyomtatás időpontja: </b>{viewObj.nyomtatasIdopont && moment(viewObj.nyomtatasIdopont).format("YYYY-MM-DD")}<br />
          <b>Küldés időpontja: </b>{viewObj.kuldesIdopont && moment(viewObj.kuldesIdopont).format("YYYY-MM-DD")}<br />
          <b>Bizonylatszám: </b>{viewObj.bizonylat ? viewObj.bizonylat.bizonylatszam : ''}<br />
          <b>Ügyfél: </b>{viewObj.partner ? viewObj.partner.nev  + ' (' + viewObj.partner.cim + ')' : ''}<br/>
          <b>Ügyfél e-mail címe: </b>{viewObj.partner ? viewObj.partner.email : ''}<br/>
          <b>Ügyfél telefonszáma: </b>{viewObj.partner ? viewObj.partner.tel : ''}<br/>
          <b>Cégadat: </b>{viewObj.cegadat ? viewObj.cegadat.teljesnev + ' (' + viewObj.cegadat.cim + ')' : ''}<br/>
          <b>Pénznem: </b>{viewObj.penznem ? viewObj.penznem.azonosito : ''}<br />
          <b>Tárgy: </b>{viewObj.targy}<br />
          <b>Bevezető: </b>{ReactHtmlParser(viewObj.bevezeto)}<br />
          <b>Záró: </b>{viewObj.zaro}<br />
          <b>Megjegyzés: </b>{viewObj.megjegyzes}<br />
          <br />
          {viewObj.uvd &&(
            <div>
              <b>UVD: </b>
              <b>Rögzítés időpontja: </b>{moment(viewObj.uvd.rogzitesidopont).format("YYYY-MM-DD HH:mm:ss")}<br />
              <b>Rögzítő személy: </b>{viewObj.uvd.rogzszemelynev}<br />
              <b>Módosítás időpontja: </b>{moment(viewObj.uvd.modositasidopont).format("YYYY-MM-DD HH:mm:ss")}<br />
              <b>Módosító személy: </b>{viewObj.uvd.modszemelynev}<br />
              <b>Véglegezés időpontja: </b>{viewObj.veglegezesdatum ? moment(viewObj.veglegezesdatum).format("YYYY-MM-DD HH:mm:ss") : ''}<br />
              <b>Véglegező személy: </b>{viewObj.veglegezesszemelynev}<br />
            </div>
          )}
        </div>
      )
    );
  }

  const renderTableBase = () => {
    return(
      <div className="col-md-12">
        {{
          LOAD: (<Loader />),
          DATA: (<DataEmptyState
            hasImage
            subtitle="Vigyél fel új tételt!"
          />),
          NETW: (<NetworkEmptyState
            hasImage
            submitClick={() => listAjanlat(filters)}
          />),
          TABL: renderTable()
        }[tableStatus]}
      </div>
    );
  }

  const renderFuncButtons = (start, to, total) => {
    return (
      <span>
        {' '}
        <Button className="text-black" >
          { start } - { to } / { total + ' ' } tétel megjelenítése
        </Button>
        {' '}
      </span>
    );
  }

  const renderModals = () => {
    return (
      <React.Fragment>
        <Modal isOpen={deleteModal} toggle={() => toggleDeleteModal(!deleteModal)} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>Valóban törölni kívánja a kiválasztott tételt?</ModalBody>
          <ModalFooter>
            <ProgressButton
              className="btn-cursor-pointer"
              color="primary"
              onClick={() => deleteRow()}
            >
              {'Igen'}
            </ProgressButton>
            <Button type="button" color="secondary" onClick={() => toggleDeleteModal(!deleteModal)}>Mégsem</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={viewModal} toggle={() => toggleViewModal(!viewModal)} backdrop="static">
          <ModalHeader>Megtekintés</ModalHeader>
          <ModalBody>
            {renderViewBase()}
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" className="btn-cursor-pointer" onClick={() => toggleViewModal(!viewModal)}>Vissza</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }

  const renderTable = () => {

    const options = {
      sizePerPage: 5,
      showTotal: true,
      paginationTotalRenderer: renderFuncButtons,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const columns = [
      {
        dataField: 'partner',
        text: 'Ügyfél',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
        },
        sort: true,
        formatter: cell => cell.nev + ' (' + cell.cim + ')'
      },
      {
        dataField: 'kelte',
        text: 'Ajánlat kelte',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
        },
        sort: true,
        formatter: cell => cell ? moment(cell).format("YYYY-MM-DD") : ''
      },
      {
        dataField: 'ervenyesseg',
        text: 'Érvényesség',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
        },
        sort: true,
        formatter: cell => cell ? moment(cell).format("YYYY-MM-DD") : ''
      },
      {
        dataField: 'bizonylat.bizonylatszam',
        text: 'Bizonylatszám',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
        },
        sort: true,
      },
      {
        dataField: 'nettoOsszeg',
        text: 'Nettó érték',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
        },
        sort: true,
        formatter: (cell, row) => NumberFormatter.formatterSpaceOsszegPenznem(roundNumber(cell), row),
      },
      {
        dataField: 'bruttoOsszeg',
        text: 'Bruttó érték',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
        },
        sort: true,
        formatter: (cell, row) => NumberFormatter.formatterSpaceOsszegPenznem(roundNumber(cell), row),
      },
      {
        dataField: 'allapot.caption',
        text: 'Állapot',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
        },
        sort: true,
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        csvExport: false,
        formatter: (id, row) => tableIconFormatter(id, row, expandedRows)
      }
    ];

    const cikkColumns = [
      {
        dataField: 'cikk.cikkszam',
        text: 'Cikkszám',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
        },
        sort: true
      },
      {
        dataField: 'cikkNev',
        text: 'Megnevezés',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
        },
        sort: true,
      },
      {
        dataField: 'mennyiseg',
        text: 'Mennyiség',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: NumberFormatter.styleTextAlignWhiteSpace,
        formatter: (cell, row) => NumberFormatter.formatterSpaceOsszeg(cell) + (row && row.cikk ? ' ' + row.cikk.mennyisegiEgyseg : ''),
        sort: true,
      },
      {
        dataField: 'cikk.aktualisAfaErtek',
        text: 'ÁFA%',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: NumberFormatter.styleTextAlignWhiteSpace,
        formatter: NumberFormatter.formatterSpaceOsszeg,
        sort: true,
        formatter: (cell) => cell + " %"
      },
      {
        dataField: 'nettoErtek',
        text: 'Nettó érték',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: NumberFormatter.styleTextAlignWhiteSpace,
        formatter: (cell, row) => NumberFormatter.formatterSpaceOsszegPenznem(cell, row),
        sort: true
      },
      {
        dataField: 'bruttoErtek',
        text: 'Bruttó érték',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: NumberFormatter.styleTextAlignWhiteSpace,
        formatter: (cell, row) => NumberFormatter.formatterSpaceOsszegPenznem(cell, row),
        sort: true
      },
    ];

    const expandRow = {
      renderer: row => expandedRows.find(x => x.id == row.id) && (
          <React.Fragment>
            <div className="row">
              <div className="col-md-2">
                <h6>Cikkek</h6>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={expandedRows.find(x => x.id == row.id).data}
              columns={cikkColumns}
              filter={filterFactory()}
              noDataIndication="A rendeléshez még nem lett cikk kiválasztva!"
            />
          </React.Fragment>
      ),
      expanded: expandedRows.map( x => x.id),
      onExpand: row => loadExpandedRows(row),
      showExpandColumn: true,
      expandByColumnOnly: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        return isAnyExpands ? <b>-</b> : <b>+</b>;
      },
      expandColumnRenderer: ({ expanded }) => {
        return expanded ? <b>-</b> : <b>+</b>;
      }
    };

    return (
      <div className="animated fadeIn">
        <React.Fragment>
          <div style={{margin: "20px"}}>
            {Lib.KeyCloak.hasHigherRole(route.globals.keycloak, 'RENDELES_AJANLAT', 'KARB') && (
              <Button type="button" className="pull-left table-new-button" style={{marginRight: "20px"}} color="primary" onClick={() => handleForm(null)}>
                {'Új ajánlat'}
              </Button>
            )}
          </div>
          <BootstrapTable
            {...props.baseProps }
            bootstrap4
            keyField="id"
            data={rows}
            expandRow={expandRow}
            //rowClasses="kartya-row-class"
            columns={columns}
            filter={filterFactory()}
            pagination={paginationFactory(options)}
            noDataIndication="Nincs megjelenítendő adat!"
          />
        </React.Fragment>
      </div>
    );
  }

  return(
    <React.Fragment>
        <iframe name="pdf-area-ajanlat-rendeles" id="pdf-area-ajanlat-rendeles" src={printUrl} style={{ visibility: 'hidden', height: 0, width: 0 }} />
        <div className="row">
          <AjanlatFilter setFilter={filters => setFilters(filters)}/>
          <div className="col-md-2">
            <Button type="button" color="primary" onClick={() => listAjanlat(filters)}> 
              <span><i className="fa fa-search" aria-hidden="true"></i></span>
            </Button>
          </div>
        </div>
        {renderTableBase()}
        {renderModals()}
      </React.Fragment>
  );
};

export default AjanlatContent;
