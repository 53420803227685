import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Badge,
  Button,
  Card,
  CardText,
  CardTitle,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert,
  UncontrolledTooltip
} from 'reactstrap';
import Select from 'react-select/lib/Async';
import FileSaver from 'file-saver';
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
  AvField
} from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import MomentTimezone from 'moment-timezone';
import DataEmptyState from '../../commons/dataemptystate';
import QueryEmptyState from '../../commons/queryempty';
import NetworkEmptyState from '../../commons/networkemptystate';
import Loader from '../../commons/loader';
import Services from './services';
import ProgressButton from '../../commons/ProgressButton';
import { FileCard, PaginationTotal, DatePicker } from '../../commons/controls';
import Lib from '../../commons/lib';
import NumberFormatter from '../../commons/NumberFormatter';

class FizetesiFelszolitoLista extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // ['QUERY', 'LOAD', 'DATA', 'NETW', 'TABL']
      currentStatusBaseTable: 'QUERY',
      // ['LOAD', 'LOADED', 'NETW']
      currentStatusBaseView: 'LOAD',

      modalSzamlacsere: false,
      modalLetoltes: false,
      szamlaData: [],
      baseJson: [],
      felszBaseJson: [],
      files: null,

      formSzamlakibocsato: '',
      formFojogtulajdonos: '',
      tipusOptions: [],
      formTipus: null,
      formKkamat: null,
      formEvtol: null,
      formEvig: null,
      formGentol: null,
      formGenig: null,
      formIktatoszam: null,
      allapotOptions: [],
      formAllapot: null,

      disableMezo: false,
      modalVeglegesites: false,
      modalTorles: false,
      currentId: null,
      selected: 0,
      disabledRows: [],
      forras: null, // forras && táblázat alóli törlés
      xlsxButtonLoading: false,
      disableExport: true
    };

    this.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  // componentDidMount() {
  //   this.initForm();
  // }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.active !== prevProps.active) {
      if (this.props.active) {
        this.initForm();
      }
    }
  }

  initForm = () => {
    Services.initForm((err, res) => {
      if (!err) {
        let tipusok = res[0].tipus.map(e => <option key={e.name} value={e.name}>{e.caption}</option>);
        let allapotok = res[0].allapot.map(e => <option key={e.name} value={e.name}>{e.caption}</option>);
        this.setState({
          tipusOptions: tipusok,
          allapotOptions: allapotok,
        });
      }
    });
  }

  toggleSzamlacsere = () => {
    this.setState(prevState => ({ modalSzamlacsere: !prevState.modalSzamlacsere }));
  }

  toggleLetoltesNezet = () => {
    this.setState(prevState => ({ modalLetoltes: !prevState.modalLetoltes }));
  }

  toggleVeglegesites = () => {
    this.setState(prevState => ({ modalVeglegesites: !prevState.modalVeglegesites }));
  }

  toggleTorles = () => {
    this.setState(prevState => ({ modalTorles: !prevState.modalTorles }));
  }

  loadSzamlakibocsatoChange = (input, isGen, callback) => {
    if (input.length !== 0) {
      Services.getSzamlaKibocsato(isGen ? 'SZAMLA_SZAMLAKIBOCSATO_ELO' : 'FIZFELSZOL_SZAMLAKIBOCSATO_ELO', input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            item.nev += ' (' + item.cim + ') ';
            if (item.adoszam) {
              item.nev += '[' + NumberFormatter.addHyphensToString(item.adoszam) + '] ';
            }
            newArray.push(item);
          });
          callback(newArray);
        }
      });
    }
  }

  loadFojogtulajChange = (input, isGen, callback) => {
    if (input.length !== 0) {
      Services.getSzamlaKibocsato(isGen ? 'SZAMLA_SZAMLAFIZETO_ELO' : 'FIZFELSZOL_JOGTULAJDONOS_ELO', input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            item.nev += ' (' + item.cim + ') ';
            if (item.adoszam) {
              item.nev += '[' + NumberFormatter.addHyphensToString(item.adoszam) + '] ';
            }
            newArray.push(item);
          });
          callback(newArray);
        }
      });
    }
  }

  loadIktatoszamChange = (input, callback) => {
    if (input.length >= 6) {
      Services.searchIktatoszam(input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            let [id, iktato] = item;
            let i = {};
            i.id = id;
            i.iktato = iktato;
            newArray.push(i);
          });
          callback(newArray);
        }
      });
    }
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({ [name]: value, disableExport: true });
  }

  handleDateInputChange = (e, v, name) => {
    const value = v === '' ? null : v;
    this.setState({ [name]: value, disableExport: true });
  }

  handleChangeSzamlakibocsato = (value) => {
    if (value && value.length !== 0) {
      this.setState({ formSzamlakibocsato: value, disableExport: true });
    } else {
      this.setState({ formSzamlakibocsato: '', disableExport: true });
    }
  }

  handleChangeFojogtulajdonos = (value) => {
    if (value && value.length !== 0) {
      this.setState({ formFojogtulajdonos: value, disableExport: true });
    } else {
      this.setState({ formFojogtulajdonos: '', disableExport: true });
    }
  }

  handleChangeIktatoszam = (value) => {
    if (value && value.length !== 0) {
      this.setState(prevState => ({ formIktatoszam: value, disableMezo: prevState.formTipus === 'FEL', disableExport: true }));
    } else {
      this.setState({ formIktatoszam: '', disableMezo: false, disableExport: true });
    }
  }

  updateTable = () => {
    this.setState({
      currentStatusBaseTable: 'LOAD',
      afterSearchValue: this.state.formTipus,
      selected: 0
    });
    if (this.state.formTipus === 'GEN') {
      Services.listKKamat(this.state.formSzamlakibocsato && this.state.formSzamlakibocsato.id, this.state.formFojogtulajdonos && this.state.formFojogtulajdonos.id,
        this.state.formEvtol, this.state.formEvig, this.state.formKkamat, (err, res) => {
          if (err) {
            this.setState({
              currentStatusBaseTable: 'QUERY'
            });
          } else {
            let baseJSON = JSON.parse(JSON.stringify(res[0]));
            baseJSON.forEach((element) => {
              element.lezartudjogString = element.lezartudjog ? 1 : 0;
              element.aktivfotulajString = element.aktivfotulaj ? 1 : 0;
              element.szamlafelfuggString = element.szamlafelfugg ? 1 : 0;
              element.fizetesimeghagyasalattString = element.fizetesimeghagyasalatt ? 1 : 0;
            });
            this.setState({
              currentStatusBaseTable: res[0].length > 0 ? 'TABL' : 'DATA',
              baseJson: baseJSON
            });
          }
        });
    } else if (this.state.formTipus === 'FEL') {
      Services.listFelsz(this.state.formSzamlakibocsato && this.state.formSzamlakibocsato.id, this.state.formFojogtulajdonos && this.state.formFojogtulajdonos.id,
        this.state.formEvtol, this.state.formEvig, this.state.formKkamat, this.state.formGentol, this.state.formGenig, this.state.formIktatoszam && this.state.formIktatoszam.id, this.state.formAllapot, (err, res) => {
          if (err) {
            this.setState({
              currentStatusBaseTable: 'QUERY'
            });
          } else {
            let disabledRows = [];
            res[0].forEach((item) => {
              if (item && item.allapot && item.allapot.name !== 'ROGZITETT') {
                disabledRows.push(item.id);
              }
            });
            this.setState({
              currentStatusBaseTable: res[0].length > 0 ? 'TABL' : 'DATA',
              felszBaseJson: res[0],
              disabledRows: disabledRows,
              disableExport: res[0].length === 0
            });
          }
        });
    } else {
      this.setState({
        currentStatusBaseTable: 'NETW'
      });
    }
  }

  exportXLSXFizFelszol = () => {
    this.setState({
      xlsxButtonLoading: true
    });
    Services.exportXLSXFizFelszol(this.state.formSzamlakibocsato && this.state.formSzamlakibocsato.id, this.state.formFojogtulajdonos && this.state.formFojogtulajdonos.id,
      this.state.formEvtol, this.state.formEvig, this.state.formKkamat, this.state.formGentol, this.state.formGenig, this.state.formIktatoszam && this.state.formIktatoszam.id, this.state.formAllapot, (err, res) => {
        if (!err) {
          this.setState({
            xlsxButtonLoading: false,
          });
          this.props.globals.addSuccess('Xlsx fájl letöltés indítása...');
          FileSaver.saveAs(new Blob([res[0]], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', responseType: 'arraybuffer' }), `Fizetési felszólító - ${MomentTimezone.tz(new Date(), this.tz).format('YYYY-MM-DD HHmmss')}.xlsx`);
        } else {
          this.setState({
            xlsxButtonLoading: false
          });
        }
      });
  }

  handleBaseSwapClick = (cell, row) => {
    this.setState({
      szamlaData: row
    });
    this.toggleSzamlacsere();
  }

  baseTableIconFormatter = (cell, row) => {
    return (
      <div>
        {/* <Button
          className="icon-eye table-action-icon"
          id={'action-view-base-' + cell}
          // onClick={() => this.handleBaseViewClick(cell)}
        />
        <UncontrolledTooltip
          placement="left"
          target={'action-view-base-' + cell}
          delay={0}
        >
          Megtekintés
        </UncontrolledTooltip> */}
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_FIZFELSZ', 'KARB') && (
        <React.Fragment>
          <Button
            className="fa fa-exchange table-action-icon"
            id={'action-swap-base-' + cell}
            onClick={() => this.handleBaseSwapClick(cell, row)}
          />
          <UncontrolledTooltip
            placement="left"
            target={'action-swap-base-' + cell}
            delay={0}
          >
            {'Bankszámlaszám csere'}
          </UncontrolledTooltip>
        </React.Fragment>
        )}
      </div>
    );
  }

  handleFileDownloadClick = (id) => {
    Services.getObject(id, (err, res) => {
      if (!err) {
        this.setState({
          files: res[0]
        }, () => {
          this.toggleLetoltesNezet();
        });
      }
    });
  }

  handleFinalizeClick = (cell) => {
    this.setState({
      forras: null,
      currentId: cell
    }, () => {
      this.toggleVeglegesites();
    });
  }

  handleFinalizeNClick = () => {
    this.setState({
      forras: 'N'
    }, () => {
      this.toggleVeglegesites();
    });
  }

  finalizeFizFelszol = () => {
    this.setState({
      deleteButtonLoading: true
    });
    Services.finalizeFizFelszol([this.state.currentId], (err) => {
      if (!err) {
        this.toggleVeglegesites();
        this.props.globals.addSuccess('Fizetési felszólító véglegesítve!');
        this.setState({
          deleteButtonLoading: false
        });
        this.updateTable();
      } else {
        this.setState({
          deleteButtonLoading: false
        });
      }
    });
  }

  finalizeFizFelszolN = () => {
    this.setState({
      deleteButtonLoading: true
    });
    Services.finalizeFizFelszol(this.dataTableFizFelszol.store.selected, (err) => {
      if (!err) {
        this.toggleVeglegesites();
        this.props.globals.addSuccess('A kijelölt fizetési felszólító(k) véglegesítve!');
        this.setState({
          deleteButtonLoading: false
        });
        this.updateTable();
      } else {
        this.setState({
          deleteButtonLoading: false
        });
      }
    });
  }

  handleDeleteClick = (cell) => {
    this.setState({
      forras: null,
      currentId: cell
    }, () => {
      this.toggleTorles();
    });
  }

  handleDeleteNClick = () => {
    this.setState({
      forras: 'N'
    }, () => {
      this.toggleTorles();
    });
  }

  deleteFizFelszol = () => {
    this.setState({
      deleteButtonLoading: true
    });
    Services.deleteFizFelszol([this.state.currentId], (err) => {
      if (!err) {
        this.toggleTorles();
        this.props.globals.addSuccess('Fizetési felszólító törölve!');
        this.setState({
          deleteButtonLoading: false
        });
        this.updateTable();
      } else {
        this.setState({
          deleteButtonLoading: false
        });
      }
    });
  }

  deleteFizFelszolN = () => {
    this.setState({
      deleteButtonLoading: true
    });
    Services.deleteFizFelszol(this.dataTableFizFelszol.store.selected, (err) => {
      if (!err) {
        this.toggleTorles();
        this.props.globals.addSuccess('A kijelölt fizetési felszólító(k) törölve!');
        this.setState({
          deleteButtonLoading: false
        });
        this.updateTable();
      } else {
        this.setState({
          deleteButtonLoading: false
        });
      }
    });
  }

  baseFelszTableIconFormatter = (cell, row) => {
    let hasRoleLek = Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_FIZFELSZ', 'LEK');
    let hasRoleKarb = Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_FIZFELSZ', 'KARB');
    return (
      <div>
        {(hasRoleLek || hasRoleKarb) && (
        <React.Fragment>
          <Button className="fa fa-cloud-download table-action-icon" id={'action-download-' + cell} onClick={() => this.handleFileDownloadClick(cell)} />
          <UncontrolledTooltip placement="left" target={'action-download-' + cell} delay={0}>
            {'Dokumentumok'}
          </UncontrolledTooltip>
        </React.Fragment>
        )}
        {hasRoleKarb && row.allapot && row.allapot.name === 'ROGZITETT' && (
          <React.Fragment>
            <Button className="fa fa-check table-action-icon" id={'action-finalize-' + cell} onClick={() => this.handleFinalizeClick(cell)} />
            <UncontrolledTooltip placement="left" target={'action-finalize-' + cell} delay={0}>
              {'Véglegesítés'}
            </UncontrolledTooltip>
            <Button className="fa fa-trash-o table-action-icon" id={'action-delete-' + cell} onClick={() => this.handleDeleteClick(cell)} />
            <UncontrolledTooltip placement="left" target={'action-delete-' + cell} delay={0}>
              {'Törlés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  bankszamlaFormatter = (cell) => {
    return Lib.bankAccountFormatter(cell[0].szamlaszam) + ' (' + cell[0].penzintezet + ')';
  }

  kibocsatoFormatter = (cell) => {
    if (cell && cell.nev) {
      return cell.nev;
    }
    return '';
  }

  jogtulajFormatter = (cell) => {
    if (cell && cell.nev) {
      return cell.nev;
    }
    return '';
  }

  allapotFormatter = (cell) => {
    if (cell && cell.alias) {
      return cell.alias;
    }
    return '';
  }

  allapotFormatterFizFelsz = (cell) => {
    if (cell) {
      return <Badge color={cell.color}>{cell.caption}</Badge>;
    }
    return '-';
  }

  kovetelesFormatter = (cell, row) => {
    return cell + ' ' + row.penznem;
  }

  booleanFormatter = (cell) => {
    switch (cell) {
      case 0:
        return 'Nem';
      case 1:
        return 'Igen';
      default:
        return '-';
    }
  }

  maxLengthCheck =(object) => {
    if (object.target.value.length > object.target.maxLength) object.target.value = object.target.value.slice(0, object.target.maxLength);
  }

  handleCardClick = (kibNev, tulajNev, base, szamla, idx) => {
    base.forEach((row) => {
      if ((kibNev === row.szamlaKibocsato.nev) && (tulajNev === row.foJogtulajdonos.nev)) {
        let szamlaAfterSwap = row.bankszamlaSzamok;
        let firstElement = {};
        szamlaAfterSwap.forEach((item, itemIdx) => {
          if (itemIdx === idx) {
            firstElement = szamlaAfterSwap[0];
            szamlaAfterSwap[0] = item;
            szamlaAfterSwap[itemIdx] = firstElement;
          }
        });
        this.setState({ szamlaData: row });
      }
    });
    this.props.globals.addSuccess('Bankszámlaszám megcserélve!');
  }

  generate = () => {
    let generatedObj = JSON.parse(JSON.stringify(this.dataTable.props.data));
    let sendObj = [];

    generatedObj.forEach((item) => {
      if (this.dataTable.state.selectedRowKeys.includes(item.id)) {
        if (item.szamlaAllapot.name) item.szamlaAllapot = item.szamlaAllapot.name;
        delete item.fizetesikedvezmeny;
        sendObj.push(item);
      }
    });
    Services.generateFizfel(sendObj, (err, res) => {
      if (!err) {
        this.props.globals.addSuccess('Zip fájl letöltése megkezdődött...');
        FileSaver.saveAs(new Blob([res[0]], { type: 'application/zip', responseType: 'arraybuffer' }), new Date().toISOString().slice(0, 10));
      }
    });
  }

  onTipusChange = (e, v) => {
    this.setState(prevState => ({ formTipus: v, disableMezo: v === 'FEL' && prevState.formIktatoszam && prevState.formIktatoszam.id, disableExport: true }));
  }

  onAllapotChange = (e, v) => {
    this.setState({ formAllapot: v, disableExport: true });
  }

  renderEloSzures = () => {
    return (
      // <AvForm style={{ marginBottom: 20 }} onValidSubmit={this.updateTable}>
      <AvForm style={{ marginBottom: 20 }}>
        <div className="row">
          <div className="col-md-12">
            <div><h5 style={{ marginBottom: 20 }}>Fizetési felszólító:</h5></div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <Label for="formSzamlakibocsato">Számlakibocsátó:</Label>
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="formSzamlakibocsato"
              id="formSzamlakibocsato"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeSzamlakibocsato(v)}
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce((input, callback) => this.loadSzamlakibocsatoChange(input, this.state.formTipus === 'GEN', callback), 1000)}
              isLoading={false}
              getOptionLabel={option => option.nev}
              getOptionValue={option => option.id}
              value={this.state.formSzamlakibocsato}
              isDisabled={this.state.disableMezo}
              cache={false}
              autoload={false}
            />
          </div>
          <div className="col-md-3">
            <Label for="formFojogtulajdonos">Fő jogtulajdonos:</Label>
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="formFojogtulajdonos"
              id="formFojogtulajdonos"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeFojogtulajdonos(v)}
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce((input, callback) => this.loadFojogtulajChange(input, this.state.formTipus === 'GEN', callback), 1000)}
              isLoading={false}
              getOptionLabel={option => option.nev}
              getOptionValue={option => option.id}
              value={this.state.formFojogtulajdonos}
              isDisabled={this.state.disableMezo}
              cache={false}
              autoload={false}
            />
          </div>
          <div className={'col-md-' + (this.state.formTipus !== 'FEL' ? '3' : '2')}>
            <AvField onChange={this.onTipusChange} type="select" name="formTipus" id="formTipus" label="Típus: " errorMessage="Kötelező mező!" value={this.state.formTipus} required>
              <option value="">
                Válassz típust...
              </option>
              {this.state.tipusOptions}
            </AvField>
          </div>
          { this.state.formTipus === 'FEL' && (
            <div className="col-md-2">
              <Label for="formIktatoszam">Iktatószám:</Label>
              <Select
                styles={{
                  control: base => ({
                    ...base,
                    '&:focus': { borderColor: 'red' }, // border style on hover
                    border: '1px solid lightgray', // default border color
                    boxShadow: 'none', // no box-shadow
                    borderRadius: '0'
                  }),
                }}
                name="formIktatoszam"
                id="formIktatoszam"
                placeholder="Kezdjen el gépelni..."
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingMessage={() => 'Keresés...'}
                onChange={v => this.handleChangeIktatoszam(v)}
                isClearable
                ignoreAccents={false}
                loadOptions={_.debounce(this.loadIktatoszamChange, 1000)}
                isLoading={false}
                getOptionLabel={option => option.iktato}
                getOptionValue={option => option.id}
                value={this.state.formIktatoszam}
                cache={false}
                autoload={false}
              />
            </div>
          )}
          <div className={'col-md-' + (this.state.formTipus !== 'FEL' ? '3' : '2')}>
            <AvGroup>
              <Label for="formKkamat">
                Késedelmi kamat:
              </Label>
              <InputGroup>
                <AvInput
                  name="formKkamat"
                  id="formKkamat"
                  type="number"
                  maxLength="2"
                  onInput={this.maxLengthCheck}
                  disabled={this.state.disableMezo}
                  onChange={this.handleInputChange}
                  value={this.state.formKkamat}
                />
                <InputGroupAddon addonType="prepend">
                  {'%'}
                </InputGroupAddon>
              </InputGroup>
              <AvFeedback>
                Kötelező mező!
              </AvFeedback>
            </AvGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <AvGroup>
              <Label for="formEvtol">
                Év(-től):
              </Label>
              <AvInput
                name="formEvtol"
                id="formEvtol"
                maxLength="4"
                // onInput={this.maxLengthCheck}
                type="number"
                placeholder="éééé"
                disabled={this.state.disableMezo}
                onChange={this.handleInputChange}
                value={this.state.formEvtol}
              />
              <AvFeedback>
                Kötelező mező!
              </AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-2">
            <AvGroup>
              <Label for="formEvig">
                Év(-ig):
              </Label>
              <AvInput
                name="formEvig"
                id="formEvig"
                maxLength="4"
                onInput={this.maxLengthCheck}
                type="number"
                placeholder="éééé"
                disabled={this.state.disableMezo}
                onChange={this.handleInputChange}
                value={this.state.formEvig}
              />
              <AvFeedback>
                Kötelező mező!
              </AvFeedback>
            </AvGroup>
          </div>
          { this.state.formTipus === 'FEL' && (
            <React.Fragment>
              <div className="col-md-2">
                <Label for="formGentol">Generálás dátuma(-tól):</Label>
                <DatePicker
                  id="formGentol"
                  type="select"
                  name="formGentol"
                  disabled={this.state.disableMezo}
                  value={this.state.formGentol && new Date(this.state.formGentol)}
                  onChange={(e, v) => this.handleDateInputChange(e, v, 'formGentol')}
                />
              </div>
              <div className="col-md-2">
                <Label for="formGenig">Generálás dátuma(-ig):</Label>
                <DatePicker
                  id="formGenig"
                  type="select"
                  name="formGenig"
                  disabled={this.state.disableMezo}
                  value={this.state.formGenig && new Date(this.state.formGenig)}
                  onChange={(e, v) => this.handleDateInputChange(e, v, 'formGenig')}
                />
              </div>
              <div className="col-md-2">
                <AvField onChange={this.onAllapotChange} type="select" name="formAllapot" id="formAllapot" label="Állapot: " errorMessage="Kötelező mező!" disabled={this.state.disableMezo} value={this.state.formAllapot}>
                  <option value="">
                    Válassz állapotot...
                  </option>
                  {this.state.allapotOptions}
                </AvField>
              </div>
            </React.Fragment>
          )}
          <div className={'col-md-' + (this.state.formTipus !== 'FEL' ? '8' : '2')}>
            {this.state.formTipus === 'FEL' && this.state.felszBaseJson && this.state.felszBaseJson.length > 0 && (
              <ProgressButton className="margin-top-30 margin-right-10" color="success" onClick={this.exportXLSXFizFelszol} loading={this.state.xlsxButtonLoading} disabled={this.state.disableExport}>Exportálás XLSX-be</ProgressButton>
            )}
            <Button className="margin-top-30 pull-right" color="primary" onClick={this.updateTable} disabled={!this.state.formTipus}>Keresés</Button>
          </div>
        </div>
      </AvForm>
    );
  }

  onRowSelect = (row, isSelected) => {
    this.setState(prevState => ({ selected: prevState.selected + (isSelected ? 1 : -1) }));
  }

  onSelectAll = (isSelected) => {
    if (isSelected) {
      if (this.dataTable.store.filteredData) {
        let ids = this.dataTable.store.filteredData.map(row => row.id);
        this.setState({ selected: ids.length });
        return ids;
      }
      let ids = this.state.baseJson.map(row => row.id);
      this.setState({ selected: ids.length });
      return ids;
    }
    this.setState({ selected: 0 });
    return [];
  }

  onSelectAllFizFelszol = (isSelected) => {
    if (isSelected) {
      if (this.dataTableFizFelszol.store.filteredData) {
        let ids = this.dataTableFizFelszol.store.filteredData.filter(row => row.allapot && row.allapot.name === 'ROGZITETT').map(row => row.id);
        this.setState({ selected: ids.length });
        return ids;
      }
      let ids = this.state.felszBaseJson.filter(row => row.allapot && row.allapot.name === 'ROGZITETT').map(row => row.id);
      this.setState({ selected: ids.length });
      return ids;
    }
    this.setState({ selected: 0 });
    return [];
  }

  renderBaseTable = () => {
    const selectRowProp = {
      onSelectAll: this.onSelectAll,
      onSelect: this.onRowSelect,
      mode: 'checkbox',
      hideSelectColumn: !Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_FIZFELSZ', 'KARB'),
    };

    const booleanTypes = {
      0: 'Nem',
      1: 'Igen'
    };

    const options = {
      sizePerPage: 10,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };
    return (
      <div className="animated fadeIn">
        <BootstrapTable
          ref={((c) => { this.dataTable = c; })}
          data={this.state.baseJson}
          options={options}
          selectRow={selectRowProp}
          pagination
        >
          <TableHeaderColumn
            dataField="szamlaKibocsato"
            tdStyle={{ whiteSpace: 'normal' }}
            dataFormat={this.kibocsatoFormatter}
          >
            {'Számlakibocsátó'}
          </TableHeaderColumn>
          {/* <TableHeaderColumn
            dataField="bankszamlaSzamok"
            tdStyle={{ whiteSpace: 'pre-line' }}
            dataFormat={this.bankszamlaFormatter}
          >
            {'Bankszámlaszám'}
          </TableHeaderColumn> */}
          <TableHeaderColumn
            dataField="foJogtulajdonos"
            tdStyle={{ whiteSpace: 'normal' }}
            dataFormat={this.jogtulajFormatter}
          >
            {'Főjogtulaj'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="bizonylatszam"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          >
            {'Biz. szám'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="kelte"
            tdStyle={{ whiteSpace: 'pre-line' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          >
            {'Kelte'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="szamlaAllapot"
            tdStyle={{ whiteSpace: 'normal' }}
            dataFormat={this.allapotFormatter}
          >
            {'Állapot'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="koveteles"
            tdStyle={{ whiteSpace: 'pre-line', textAlign: 'right' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            dataFormat={(cell, row) => { return NumberFormatter.addSpacesToString(cell, NumberFormatter.OSSZEG) + ' ' + row.penznem; }}
          >
            {'Követelés'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="lezartudjogString"
            tdStyle={{ whiteSpace: 'normal' }}
            dataFormat={this.booleanFormatter}
            filter={{ type: 'SelectFilter', options: booleanTypes, placeholder: 'Összes' }}
          >
            {'Lezárt az üdülési jog?'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="aktivfotulajString"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'SelectFilter', options: booleanTypes, placeholder: 'Összes' }}
            dataFormat={this.booleanFormatter}
          >
            {'Van aktív fő jogtulajdonos?'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="szamlafelfuggString"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'SelectFilter', options: booleanTypes, placeholder: 'Összes' }}
            dataFormat={this.booleanFormatter}
          >
            {'Számlázás felfüggesztve?'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="fizetesimeghagyasalattString"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'SelectFilter', options: booleanTypes, placeholder: 'Összes' }}
            dataFormat={this.booleanFormatter}
          >
            {'Fizetési meghagyás'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="fizetesikedvezmeny"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          >
            {'Számla fizetési kedvezmény értéke'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="id"
            tdStyle={{ whiteSpace: 'normal' }}
            isKey
            dataFormat={this.baseTableIconFormatter}
            width="100"
          >
            {'Műveletek'}
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }

  renderFelTable = () => {
    const selectRowProp = {
      unselectable: this.state.disabledRows,
      onSelectAll: this.onSelectAllFizFelszol,
      onSelect: this.onRowSelect,
      mode: 'checkbox',
      hideSelectColumn: this.state.disabledRows.length === this.state.felszBaseJson.length || !Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_FIZFELSZ', 'KARB'),
    };

    const options = {
      sizePerPage: 10,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };
    return (
      <div className="animated fadeIn">
        <BootstrapTable
          ref={((c) => { this.dataTableFizFelszol = c; })}
          data={this.state.felszBaseJson}
          options={options}
          selectRow={selectRowProp}
          pagination
        >
          <TableHeaderColumn
            dataField="iktatoszam"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          >
            Iktatószám
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="szamlaKibocsato"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          >
            Számlakibocsátó
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="fojogTulajdonos"
            tdStyle={{ whiteSpace: 'pre-line' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          >
            Fő jogtulajdonos
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="befizetoAzon"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          >
            Befizető azonosító
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="kelte"
            tdStyle={{ whiteSpace: 'normal', textAlign: 'center' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          >
            Generálás időpontja
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="osszeg"
            tdStyle={{ whiteSpace: 'pre-line', textAlign: 'right' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            dataFormat={(cell, row) => { return NumberFormatter.addSpacesToString(cell, NumberFormatter.OSSZEG) + ' ' + row.penznem; }}
          >
            Összeg
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="allapot"
            tdStyle={{ whiteSpace: 'normal', textAlign: 'center' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            filterValue={cell => cell && cell.caption}
            dataFormat={this.allapotFormatterFizFelsz}
          >
            Állapot
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="veglegesitve"
            tdStyle={{ whiteSpace: 'normal', textAlign: 'center' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          >
            Véglegesítés időpontja
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="id"
            tdStyle={{ whiteSpace: 'normal' }}
            isKey
            dataFormat={this.baseFelszTableIconFormatter}
            width="120"
          >
            Műveletek
          </TableHeaderColumn>
        </BootstrapTable>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_FIZFELSZ', 'KARB') && this.state.afterSearchValue === 'FEL' && this.state.selected > 0 && (
          <div>
            <Button className="btn-cursor-pointer" type="button" color="primary" onClick={this.handleFinalizeNClick}>{'Véglegesítés (' + this.state.selected + ')'}</Button>
            <Button className="btn-cursor-pointer" type="button" color="danger" onClick={this.handleDeleteNClick} style={{ marginLeft: '10px' }}>{'Törlés (' + this.state.selected + ')'}</Button>
          </div>
        )}
      </div>
    );
  }

  viewObject = (obj) => {
    let fileURL = URL.createObjectURL(new Blob([obj], { type: 'application/pdf' }));
    window.open(fileURL);
  }

  downloadObject = (obj) => {
    FileSaver.saveAs(new Blob([obj], { type: 'application/pdf' }), 'felszolito.pdf');
  }

  renderSzamlaCards = () => {
    if (this.state.szamlaData.bankszamlaSzamok) {
      return this.state.szamlaData.bankszamlaSzamok.map((szamla, idx) => {
        return (
          <div className="col-md-12" key={szamla.id}>
            <Card body inverse={idx === 0} color={idx === 0 ? 'success' : 'default'}>
              <CardTitle style={{ textAlign: 'center' }}>
                {szamla.penzintezet}
                {idx === 0 ? '(Kiválasztott)' : null}
              </CardTitle>
              <CardText style={{ textAlign: 'center' }}>{szamla.szamlaszam}</CardText>
              <Button
                style={idx === 0 ? {} : { cursor: 'pointer' }}
                disabled={idx === 0}
                onClick={() => this.handleCardClick(
                  this.state.szamlaData.szamlaKibocsato.nev,
                  this.state.szamlaData.foJogtulajdonos.nev,
                  this.state.baseJson,
                  szamla,
                  idx
                )}
              >
                Aktiválás
              </Button>
            </Card>
          </div>
        );
      });
    }

    return '';
  }

  renderAfterSearchTable = () => {
    if (this.state.afterSearchValue === 'GEN') return this.renderBaseTable();
    if (this.state.afterSearchValue === 'FEL') return this.renderFelTable();
    return null;
  }

  renderFiles = () => {
    let files = [];
    if (this.state.files && this.state.files.size > 0) {
      files.push(<FileCard title="Fizetési felszólító" length="" onDownloadClick={() => this.downloadObject(this.state.files)} onViewClick={() => this.viewObject(this.state.files)} />);
    }
    return files;
  }

  render() {
    return (
      <div>
        {this.renderEloSzures()}
        {{
          QUERY: (<QueryEmptyState
            hasImage
          />),
          LOAD: (<Loader />),
          DATA: (<DataEmptyState
            hasImage
            subtitle="Próbálj más előszűrést vagy vigyél fel új adatokat!"
          />),
          NETW: (<NetworkEmptyState
            hasImage
            submitClick={() => this.updateTable()}
          />),
          TABL: (this.renderAfterSearchTable())
        }[this.state.currentStatusBaseTable]}
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_FIZFELSZ', 'KARB') && this.state.afterSearchValue === 'GEN' && this.state.selected > 0 && (
          <Button
            color="success"
            onClick={() => this.generate()}
            disabled={this.state.currentStatusBaseTable !== 'TABL'}
          >
            {'Felszólító generálása (' + this.state.selected + ')'}
          </Button>
        )}
        <Modal
          isOpen={this.state.modalSzamlacsere}
          toggle={this.toggleSzamlacsere}
          className={this.props.className}
          backdrop="static"
          size="md"
        >
          <ModalHeader>Bankszámla csere</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-md-12">
                <UncontrolledAlert color="info">
                  <b>Megjegyzés:</b>
                  Számlakibocsátó és fő jogtulajdonos egyezésnél minden bankszámlaszám át lesz állítva!
                </UncontrolledAlert>
              </div>
              {this.renderSzamlaCards()}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="primary"
              onClick={this.toggleSzamlacsere}
              className="btn-cursor-pointer"
            >
              Vissza
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalLetoltes} toggle={this.toggleLetoltesNezet} className={this.props.className} backdrop="static">
          <ModalHeader>
            Dokumentumok megtekintése
          </ModalHeader>
          <ModalBody>
            {this.renderFiles()}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleLetoltesNezet}>Vissza</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalVeglegesites} toggle={this.toggleVeglegesites} backdrop="static">
          <ModalHeader>Véglegesítés</ModalHeader>
          <ModalBody>{'Valóban véglegesíteni kívánja a kiválasztott fizetési felszólító' + (this.state.forras && this.state.selected > 1 ? 'ka' : '') + 't?'}</ModalBody>
          <ModalFooter>
            <ProgressButton loading={this.state.deleteButtonLoading} color="primary" onClick={() => (this.state.forras ? this.finalizeFizFelszolN() : this.finalizeFizFelszol())}>Igen</ProgressButton>
            <Button type="button" color="secondary" onClick={this.toggleVeglegesites}>Mégsem</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>{'Valóban törölni kívánja a kiválasztott fizetési felszólító' + (this.state.forras && this.state.selected > 1 ? 'ka' : '') + 't?'}</ModalBody>
          <ModalFooter>
            <ProgressButton loading={this.state.deleteButtonLoading} color="primary" onClick={() => (this.state.forras ? this.deleteFizFelszolN() : this.deleteFizFelszol())}>Igen</ProgressButton>
            <Button type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>

      </div>
    );
  }
}

FizetesiFelszolitoLista.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool
};

export default FizetesiFelszolitoLista;
