import React from 'react';

/**
 * Loader.
 *
 * @version 1.0.0
 * @author [Nagy Gergely](https://github.com/gergely-nagy)
 */
const Page404 = () => {
  return (
    <div className="animated fadeIn">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="clearfix">
            <h1 className="float-left display-3 mr-2">404</h1>
            <h4 className="pt-1">Hoppá! Eltévedtél.</h4>
            <p className="text-muted">A keresett oldal nem található.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page404;
