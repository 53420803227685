import React, { Component } from 'react';
import _ from 'lodash';
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback,
  AvField,
  AvRadio,
  AvRadioGroup
} from 'availity-reactstrap-validation';
import {
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from 'reactstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { browserHistory } from 'react-router';
// import Select from 'react-select';
import Select from 'react-select/lib/Async';
import SelectInput, { components } from 'react-select';
import { DatePicker, PhoneCard } from '../../commons/controls';
import { ProgressButton } from '../../commons/ProgressButton';
import PhoneNumbers from '../../commons/PhoneNumbers';
import Services from './services';
import NumberFormatter from '../../commons/NumberFormatter';

class KartotekrendszerForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalPartnerFelvitel: false,
      modalSzemuvegTorles: false,
      modalLencseTorles: false,
      modalSzemuvegNezet: false,
      modalLencseNezet: false,
      modalSzemuvegModosit: false,
      modalLencseModosit: false,
      modalPhone: false,
      currentKartonId: null,
      modositoStatus: false,
      felvetelStatus: false,
      vizsgalatStatus: false,
      modalVizsgalatiEredmeny: false,
      vizsgalatModositasStatus: false,
      vizsgalatTipus: null, // 0 - szemüveg, 1 - lencse
      balszemTipus: null,
      jobbszemTipus: null,
      balDisabled: true,
      jobbDisabled: true,
      telepulesMezo: [],
      telepulesMezoInaktiv: true,
      telefonszamok: [],
      megjegyzes: '',
      options: [],
      vizsgaloOptions: [],
      selectValue: {
        kontrolldatum: null,
        vizsgalatdatum: null,
        kontrollido: null,
      },
      szemuvegEredmenyEntities: [],
      lencseEredmenyEntities: [],
      szemuvegModositoObj: null,
      lencseModositoObj: null,
      currentVizsgalatId: null,
      orszagValue: {},
      telepulesSelect: true,
      cegadatOptions: [],

      telefonTipusOptions: [],
      orszagOptions: [],
      formModalOrszaghivo: '',
      formModalTelefonszam: '',
      formModalTipus: '',
      formPhoneModalType: 'FEL',
      modalPhoneTitle: 'Új telefonszám hozzáadása',
      modalPhoneButtonText: 'Hozzáadás'
    };

    this.defaultOrszagKod = 'HUN';
    this.toggleVizsgalatiEredmenyModal = this.toggleVizsgalatiEredmenyModal.bind(this);
    this.updateText = this.updateText.bind(this);
    this.updateValue = this.updateValue.bind(this);
    this.addVizsgalat = this.addVizsgalat.bind(this);
    // this.handleChange = this.handleChange.bind(this);
    this.addPartner = this.addPartner.bind(this);
  }

  componentWillMount() {
    let params = new URL(window.location.href).searchParams;
    let id = params.get('id');
    this.setState({ currentKartonId: id });

    if ((this.props.route.vizsgalat === 'true' || this.props.route.modositas === 'true') && this.props.route.vizsgalatModositas === 'false') {
      if (id) {
        Services.getKarton(id, (err, res) => {
          if (!err) {
            let telefonszamok = [];
            let telefon = {};
            res[0].kartonPartner.telefonszam.forEach((item) => {
              telefon = item;
              telefon.orszag = { num: item.elohivo, name: item.orszag, caption: item.orszagnev };
              telefonszamok.push(telefon);
            });
            this.setState({ megjegyzes: res[0].megjegyzes, selectValue: res[0].kartonPartner, telefonszamok: telefonszamok }, () => {
              this.getVizsgaloOptions();
              this.getCegadatOptions(null);
            });
          }
        });
      } else {
      // alert('not haha');
      }
    }
    if (this.props.route.vizsgalatModositas === 'true') {
      if (id) {
        Services.getVizsgalat(id, (err, res) => {
          if (!err) {
            let modObj = res[0].kartonPartner;
            modObj.vizsgalatMegjegyzes = res[0].vizsgalatentities[0].megjegyzes;
            modObj.vizsgalatDiagnozis = res[0].vizsgalatentities[0].diagnozis;
            modObj.vizsgalatdatum = res[0].vizsgalatentities[0].vizsgalatdatum;
            modObj.kontrolldatum = res[0].vizsgalatentities[0].kontrolldatum;
            modObj.kontrollido = res[0].vizsgalatentities[0].kontrollido;
            modObj.vizsgalonev = res[0].vizsgalatentities[0].vizsgaloszemelyentity.id;
            modObj.cegadat = res[0].vizsgalatentities[0].cegadatid;
            let szemuvegEredmeny = [];
            let lencseEredmeny = [];
            if (res[0].vizsgalatentities[0].orderederedmenyentities[0]) {
              if (!res[0].vizsgalatentities[0].orderederedmenyentities[0][0].empty) {
                szemuvegEredmeny.push(res[0].vizsgalatentities[0].orderederedmenyentities[0][0]);
              }
              if (!res[0].vizsgalatentities[0].orderederedmenyentities[0][1].empty) {
                szemuvegEredmeny.push(res[0].vizsgalatentities[0].orderederedmenyentities[0][1]);
              }
              this.setState({ szemuvegEredmenyEntities: szemuvegEredmeny });
            }
            if (res[0].vizsgalatentities[0].orderederedmenyentities[1]) {
              if (!res[0].vizsgalatentities[0].orderederedmenyentities[1][0].empty) {
                lencseEredmeny.push(res[0].vizsgalatentities[0].orderederedmenyentities[1][0]);
              }
              if (!res[0].vizsgalatentities[0].orderederedmenyentities[1][1].empty) {
                lencseEredmeny.push(res[0].vizsgalatentities[0].orderederedmenyentities[1][1]);
              }
              this.setState({ lencseEredmenyEntities: lencseEredmeny });
            }
            let telefonszamok = [];
            let telefon = {};
            res[0].kartonPartner.telefonszam.forEach((item) => {
              telefon = item;
              telefon.orszag = { num: item.elohivo, name: item.orszag, caption: item.orszagnev };
              telefonszamok.push(telefon);
            });
            this.setState({
              telefonszamok: telefonszamok,
              currentVizsgalatId: res[0].id,
              megjegyzes: res[0].megjegyzes,
              selectValue: modObj
            }, () => {
              this.getVizsgaloOptions();
              this.getCegadatOptions(res[0].vizsgalatentities[0].cegadatid);
            });
          }
        });
      }
    }
  }

  componentDidMount() {
    this.getTelepulesEnum();
    if (this.props.route.modositas === 'true') {
      this.setState({ modositoStatus: true });
    }
    if (this.props.route.vizsgalat === 'true') {
      this.setState({ vizsgalatStatus: true });
    }
    if (this.props.route.felvetel === 'true') {
      this.setState({ felvetelStatus: true });
    }
    if (this.props.route.vizsgalatModositas === 'true') {
      this.setState({ vizsgalatModositasStatus: true });
    }
  }

  getVizsgaloOptions = () => {
    Services.getActiveVizsgalo((err, res) => {
      if (!err) {
        let resOptions = res[0].map(e => <option key={e.id} value={e.id}>{e.nev}</option>);
        this.setState({ vizsgaloOptions: resOptions });
      }
    });
  }

  getCegadatOptions = (id) => {
    Services.listAktivCegadat(id, (err, res) => {
      if (!err) {
        let resOptions = res[0].map(e => <option key={e.id} value={e.id}>{e.nev + ' (' + e.tipus + ')'}</option>);
        this.setState({ cegadatOptions: resOptions });
      }
    });
  }

  getTelepulesEnum = () => {
    Services.listTelefonEnum((err, res) => {
      if (!err) {
        let telefonTipusOptions = res[0][1].map(e => (
          <option
            key={e.name}
            value={e.name}
          >
            {e.caption}
          </option>
        ));
        this.setState({
          telefonTipusOptions: telefonTipusOptions,
          orszagOptions: res[0][0]
        });
      }
    });
  }

  toggleVizsgalatiEredmenyModal() {
    this.setState({
      modalVizsgalatiEredmeny: !this.state.modalVizsgalatiEredmeny,
      vizsgalatTipus: null,
      balDisabled: true,
      jobbDisabled: true
    });
  }

  toggleSzemuvegTorles = () => {
    this.setState({
      modalSzemuvegTorles: !this.state.modalSzemuvegTorles
    });
  }

  togglePhoneModal = () => {
    this.setState(prevState => ({ modalPhone: !prevState.modalPhone }));
  }

  toggleLencseTorles = () => {
    this.setState({
      modalLencseTorles: !this.state.modalLencseTorles
    });
  }

  togglePartnerFelvitel = () => {
    this.setState({
      modalPartnerFelvitel: !this.state.modalPartnerFelvitel,
      telepulesMezoInaktiv: true
    }, () => this.setOrszagDefaultValue());
  }

  toggleSzemuvegNezet = () => {
    this.setState({
      modalSzemuvegNezet: !this.state.modalSzemuvegNezet
    });
  }

  toggleLencseNezet = () => {
    this.setState({
      modalLencseNezet: !this.state.modalLencseNezet
    });
  }

  toggleSzemuvegModositas = () => {
    this.setState({
      modalSzemuvegModosit: !this.state.modalSzemuvegModosit
    });
  }

  toggleLencseModositas = () => {
    this.setState({
      modalLencseModosit: !this.state.modalLencseModosit
    });
  }

  uuidv4 = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
  }

  modifySzemuveg = (event, szemObj) => {
    szemObj.uuid = this._currentEditSzemuvegObj.uuid;
    szemObj.vizsgalattipus = this._currentEditSzemuvegObj.vizsgalattipus;
    szemObj.oldal = this._currentEditSzemuvegObj.oldal;

    let resultArray = JSON.parse(JSON.stringify(this.state.szemuvegEredmenyEntities));
    // this.state.szemuvegEredmenyEntities.forEach((element, index) => {
    //   if (element.uuid === szemObj.uuid) {
    //     resultArray.push(szemObj);
    //   } else {
    //     resultArray.push(element);
    //   }
    // });

    if (szemObj.oldal.name === 'JOBB' || szemObj.oldal === 'JOBB') {
      resultArray[0] = szemObj;
      this.setState({ szemuvegEredmenyEntities: resultArray });
    } else {
      resultArray[1] = szemObj;
      this.setState({ szemuvegEredmenyEntities: resultArray });
    }

    this.toggleSzemuvegModositas();
    this.props.route.globals.addSuccess('Vizsgálati adat módosítva!');
  }

  modifyLencse = (event, lencseObj) => {
    lencseObj.uuid = this._currentEditLencseObj.uuid;
    lencseObj.vizsgalattipus = this._currentEditLencseObj.vizsgalattipus;
    lencseObj.oldal = this._currentEditLencseObj.oldal;

    let resultArray = JSON.parse(JSON.stringify(this.state.lencseEredmenyEntities));
    // this.state.lencseEredmenyEntities.forEach((element, index) => {
    //   if (element.uuid === lencseObj.uuid) {
    //     resultArray.push(lencseObj);
    //   } else {
    //     resultArray.push(element);
    //   }
    // });
    //

    if (lencseObj.oldal.name === 'JOBB' || lencseObj.oldal === 'JOBB') {
      resultArray[0] = lencseObj;
      this.setState({ lencseEredmenyEntities: resultArray });
    } else {
      resultArray[1] = lencseObj;
      this.setState({ lencseEredmenyEntities: resultArray });
    }

    // this.setState({ lencseEredmenyEntities: resultArray });
    this.toggleLencseModositas();
    this.props.route.globals.addSuccess('Vizsgálati adat módosítva!');
  }

  addVizsgalat(event, szemObj) {
    let id;
    if (szemObj.vizsgalattipus === 'SZEMUVEG') {
      let szemuvegEntities = [];
      id = this.uuidv4();
      szemuvegEntities.push({
        uuid: id,
        vizsgalattipus: 'SZEMUVEG',
        oldal: 'JOBB',
        sph: szemObj.jobb_sph,
        cyl: szemObj.jobb_cyl,
        tengely: szemObj.jobb_teng,
        add: szemObj.jobb_add,
        pd: szemObj.jobb_pd,
        vizus: szemObj.jobb_v_1,
        korrvizus: szemObj.jobb_v_2,
        prizmadioptria: szemObj.jobb_prizma_diop,
        prizmaalap: szemObj.jobb_prizma_a,
        prizmav: szemObj.jobb_prizma_v
      });
      id = this.uuidv4();
      szemuvegEntities.push({
        uuid: id,
        vizsgalattipus: 'SZEMUVEG',
        oldal: 'BAL',
        sph: szemObj.bal_sph,
        cyl: szemObj.bal_cyl,
        tengely: szemObj.bal_teng,
        add: szemObj.bal_add,
        pd: szemObj.bal_pd,
        vizus: szemObj.bal_v_1,
        korrvizus: szemObj.bal_v_2,
        prizmadioptria: szemObj.bal_prizma_diop,
        prizmaalap: szemObj.bal_prizma_a,
        prizmav: szemObj.bal_prizma_v
      });
      this.setState({ szemuvegEredmenyEntities: szemuvegEntities });
    } else if (szemObj.vizsgalattipus === 'KONTAKTLENCSE') {
      let lencseEntities = [];
      id = this.uuidv4();
      lencseEntities.push({
        uuid: id,
        vizsgalattipus: 'KONTAKTLENCSE',
        oldal: 'JOBB',
        sph: szemObj.jobb_sph,
        cyl: szemObj.jobb_cyl,
        tengely: szemObj.jobb_teng,
        add: szemObj.jobb_add,
        bc: szemObj.jobb_bc,
        dia: szemObj.jobb_dia,
        dominans: szemObj.jobb_dominans,
        tipus: szemObj.jobb_tipus
      });
      id = this.uuidv4();
      lencseEntities.push({
        uuid: id,
        vizsgalattipus: 'KONTAKTLENCSE',
        oldal: 'BAL',
        sph: szemObj.bal_sph,
        cyl: szemObj.bal_cyl,
        tengely: szemObj.bal_teng,
        add: szemObj.bal_add,
        bc: szemObj.bal_bc,
        dia: szemObj.bal_dia,
        dominans: szemObj.bal_dominans,
        tipus: szemObj.bal_tipus
      });
      this.setState({ lencseEredmenyEntities: lencseEntities });
    }
    this.toggleVizsgalatiEredmenyModal();
    // console.log(this.state.szemuvegEredmenyEntities);
    // console.log(this.state.lencseEredmenyEntities);
  }

  addPartner(event, partnerObj) {
    partnerObj.tipus = 'MAGANSZEMELY';
    partnerObj.szallitovevo = 'VEVO';
    partnerObj.statusz = 'AKTIV';
    partnerObj.szallcimeltero = false;
    partnerObj.szamcimeltero = false;
    partnerObj.orszag = this.state.orszagValue;

    Services.createPartner(partnerObj, (err, res) => {
      if (!err) {
        res[0].value = res[0].id;
        res[0].label = res[0].nev;
        this.setState({ selectValue: res[0] });
        this.togglePartnerFelvitel();
        this.props.route.globals.addSuccess('Partner felvéve!');
      }
    });
  }

  addJobbSzem(event, jobbszemObj) {
    this.toggleJobbSzemModal();
  }

  clickOnSzemuveg = (event) => {
    this.setState({ vizsgalatTipus: 0 });
  }

  clickOnKontaktlencse = (event) => {
    this.setState({ vizsgalatTipus: 1 });
  }

  backToList = () => {
    browserHistory.push('/kartotek');
  }

  handleChangeOrszag = (value) => {
    if (value && value.length !== 0) {
      let telepulesSelect;

      if (value.kod === 'HUN') telepulesSelect = true;
      else telepulesSelect = false;

      this.setState({ telepulesSelect: telepulesSelect, orszagValue: { orszagid: value.orszagid, nev: value.nev } }, () => {
        if (this.state.telepulesSelect) this.handleIrszamChange(null, this.refs.iranyitoszam.state.value);
      });
    } else {
      this.setState({ orszagValue: { orszagid: '', nev: '' } });
    }
  }

  // handleIrszamChange = (event, value) => {
  //   this.setState({ telepulesMezoInaktiv: true });
  //   if (this.state.telepulesSelect) {
  //     if (value && value.length >= 4) {
  //       console.log('value');
  //       let url = telepulesurl + '/telepules';
  //       let self = this;
  //
  //       /** Adott irányítószámhoz tartozó települések lekérdezése. */
  //       superagent.get(url)
  //         .set('Content-Type', 'application/json')
  //         .set('Accept', 'application/json')
  //         .query({ iranyitoszam: value })
  //         .end((err, res) => {
  //           if (err) {
  //             if (res) {
  //               if (res.body.msg) {
  //                 self.addNotification('Hiba', res.body.msg, 'error');
  //               }
  //             } else {
  //               self.addNotification('Hiba', 'Szolgáltatási hiba!', 'error');
  //             }
  //           } else if (res.body.length > 0) {
  //             self.setState({
  //               telepulesMezoInaktiv: false,
  //               telepulesMezo: res.body,
  //             });
  //           } else {
  //             self.setState({
  //               telepulesMezo: [],
  //             });
  //           }
  //         });
  //     } else {
  //       this.setState({
  //         telepulesMezo: []
  //       });
  //     }
  //   }
  // }

  updateText(text) {
    if (text.length === 0) {
      this.setState({ options: [] });
    } else {
      this.setState({ options: [] });
      Services.listKartotekUgyfel(text, (err, res) => {
        if (!err) {
          res[0].forEach((ugyfel) => {
            let obj = {
              value: ugyfel.id,
              label: ugyfel.nev,
              azonosito: ugyfel.azonosito,
              rogzdatum: ugyfel.rogzdatum,
              cim: ugyfel.cim,
              tajszam: ugyfel.tajszam,
              telefon: ugyfel.telefon,
              szuletesiido: ugyfel.szuletesiido,
              email: ugyfel.email
            };
            this.setState({ options: [...this.state.options, obj] });
          });
        }
      });
    }
  }

  updateValue(newValue) {
    let emptyObj = {
      value: '',
      label: '',
      azonosito: '',
      rogzdatum: '',
      cim: '',
      tajszam: '',
      telefon: '',
      szuletesiido: '',
      email: ''
    };
    if (newValue === null) {
      this.setState({ selectValue: emptyObj });
    } else {
      if (newValue.azonosito === null) newValue.azonosito = '';
      if (newValue.tajszam === null) newValue.tajszam = '';
      if (newValue.szuletesiido === null) newValue.szuletesiido = '';
      if (newValue.telefon === null) newValue.telefon = '';
      if (newValue.email === null) newValue.email = '';
      this.setState({ selectValue: newValue });
    }
  }

  clone = (obj) => {
    if (obj == null || typeof (obj) !== 'object') return obj;
    let temp = new obj.constructor();
    for (let key in obj) temp[key] = this.clone(obj[key]);
    return temp;
  }

  mentes = (event, resultObj) => {
    let kartonJson = {};
    if (this.state.modositoStatus) {
      console.log('-----------------1---------------------');
      if (resultObj.ugyfelTajszam === '') {
        kartonJson.ugyfelTajszam = null;
      } else {
        kartonJson.ugyfelTajszam = resultObj.ugyfelTajszam;
      }
      let submitTelefonszam = this.clone(this.state.telefonszamok);
      let telefonszamok = [];
      submitTelefonszam.forEach((item) => {
        if (item.orszag && item.orszag.name) {
          item.orszag = item.orszag.name.toUpperCase();
        }
        if (item.tipus && item.tipus.name) {
          item.tipus = item.tipus.name;
        }
        telefonszamok.push(item);
      });
      kartonJson.megjegyzes = resultObj.megjegyzes;
      kartonJson.id = this.state.currentKartonId;
      kartonJson.partnertortenetid = this.state.selectValue.id;
      kartonJson.vanvizsgalat = null;

      kartonJson.kartonPartner = {
        p: 'KartonPartner',
        telefonszam: telefonszamok,
        id: this.state.selectValue.id,
        azonosito: resultObj.azon,
        cim: resultObj.cim,
        email: resultObj.email,
        tajszam: kartonJson.ugyfelTajszam,
        szuletesiido: resultObj.ugyfelSzuletesnap
      };

      Services.updateKarton(kartonJson, (err) => {
        if (!err) {
          this.backToList();
          this.props.route.globals.addSuccess('Karton módosítva!');
        }
      });
    } else if (this.state.vizsgalatStatus && !this.state.vizsgalatModositasStatus) {
      console.log(resultObj);
      console.log('-----------------2---------------------');
      if (resultObj.ugyfelTajszam === '') {
        kartonJson.ugyfelTajszam = null;
      } else {
        kartonJson.ugyfelTajszam = resultObj.ugyfelTajszam;
      }
      let submitTelefonszam = this.clone(this.state.telefonszamok);
      let telefonszamok = [];
      submitTelefonszam.forEach((item) => {
        if (item.orszag && item.orszag.name) {
          item.orszag = item.orszag.name.toUpperCase();
        }
        if (item.tipus && item.tipus.name) {
          item.tipus = item.tipus.name;
        }
        telefonszamok.push(item);
      });
      kartonJson.id = this.state.currentKartonId;
      kartonJson.partnertortenetid = this.state.selectValue.id;
      kartonJson.megjegyzes = resultObj.megjegyzes;
      kartonJson.kartonPartner = {
        p: 'KartonPartner',
        telefonszam: telefonszamok,
        id: this.state.selectValue.id,
        azonosito: resultObj.azon,
        cim: resultObj.cim,
        email: resultObj.email,
        tajszam: kartonJson.ugyfelTajszam,
        szuletesiido: resultObj.ugyfelSzuletesnap
      };
      kartonJson.vizsgalatentities = [];
      let vizsgalatObj = {
        megjegyzes: resultObj.megjegyzesvizsgalat,
        diagnozis: resultObj.diagnozisvizsgalat,
        vizsgalatdatum: resultObj.vizsgalatdatum,
        kontrolldatum: resultObj.kontrolldatum,
        kontrollido: resultObj.kontrollido,
        vizsgalonev: resultObj.vizsgalonev,
        // cegadattipus: resultObj.cegadat.split(';')[1],
        cegadatid: resultObj.cegadat,
      };
      vizsgalatObj.vizsgaloszemelyentity = {
        id: resultObj.vizsgalonev
      };
      vizsgalatObj.vizsgalateredmenyentities = this.state.szemuvegEredmenyEntities.concat(this.state.lencseEredmenyEntities);
      kartonJson.vizsgalatentities.push(vizsgalatObj);

      Services.createVizsgalat(kartonJson, false, (err, res) => {
        if (!err) {
          this.backToList();
          this.props.route.globals.addSuccess('Vizsgálat mentve!');
        }
      });
    } else if (this.state.felvetelStatus) {
      console.log(this.state.selectValue);
      console.log('-----------------3---------------------');
      let submitTelefonszam = this.clone(this.state.telefonszamok);
      let telefonszamok = [];
      submitTelefonszam.forEach((item) => {
        if (item.orszag && item.orszag.name) {
          item.orszag = item.orszag.name.toUpperCase();
        }
        if (item.tipus && item.tipus.name) {
          item.tipus = item.tipus.name;
        }
        telefonszamok.push(item);
      });
      if (resultObj.ugyfelTajszam === '') {
        kartonJson.ugyfelTajszam = null;
      } else {
        kartonJson.ugyfelTajszam = resultObj.ugyfelTajszam;
      }
      kartonJson.partnertortenetid = this.state.selectValue.id;
      kartonJson.megjegyzes = resultObj.megjegyzes;
      kartonJson.kartonPartner = {
        p: 'KartonPartner',
        telefonszam: telefonszamok,
        id: this.state.selectValue.id,
        azonosito: resultObj.azon,
        cim: resultObj.cim,
        telefon: resultObj.telefonszam,
        email: resultObj.email,
        tajszam: kartonJson.ugyfelTajszam,
        szuletesiido: resultObj.ugyfelSzuletesnap
      };

      Services.createKarton(kartonJson, false, (err, res) => {
        if (!err) {
          this.backToList();
          this.props.route.globals.addSuccess('Karton mentve!');
        }
      });
    } else if (this.state.vizsgalatModositasStatus) {
      console.log('-----------------4---------------------');
      if (resultObj.ugyfelTajszam === '') {
        kartonJson.ugyfelTajszam = null;
      } else {
        kartonJson.ugyfelTajszam = resultObj.ugyfelTajszam;
      }
      let submitTelefonszam = this.clone(this.state.telefonszamok);
      let telefonszamok = [];
      submitTelefonszam.forEach((item) => {
        if (item.orszag && item.orszag.name) {
          item.orszag = item.orszag.name.toUpperCase();
        }
        if (item.tipus && item.tipus.name) {
          item.tipus = item.tipus.name;
        }
        telefonszamok.push(item);
      });
      kartonJson.id = this.state.currentVizsgalatId;
      kartonJson.partnertortenetid = this.state.selectValue.id;
      kartonJson.megjegyzes = resultObj.megjegyzes;
      kartonJson.kartonPartner = {
        p: 'KartonPartner',
        telefonszam: telefonszamok,
        id: this.state.selectValue.id,
        azonosito: resultObj.azon,
        cim: resultObj.cim,
        telefon: resultObj.telefonszam,
        email: resultObj.email,
        tajszam: kartonJson.ugyfelTajszam,
        szuletesiido: resultObj.ugyfelSzuletesnap
      };
      kartonJson.vizsgalatentities = [];
      let vizsgalatObj = {
        id: this.state.currentKartonId,
        megjegyzes: resultObj.megjegyzesvizsgalat,
        diagnozis: resultObj.diagnozisvizsgalat,
        vizsgalatdatum: resultObj.vizsgalatdatum,
        kontrolldatum: resultObj.kontrolldatum,
        kontrollido: resultObj.kontrollido,
        vizsgalonev: resultObj.vizsgalonev,
        // cegadattipus: resultObj.cegadat.split(';')[1],
        cegadatid: resultObj.cegadat,
      };
      vizsgalatObj.vizsgaloszemelyentity = {
        id: resultObj.vizsgalonev
      };
      vizsgalatObj.vizsgalateredmenyentities = this.state.szemuvegEredmenyEntities.concat(this.state.lencseEredmenyEntities);
      // console.log(vizsgalatObj.vizsgalateredmenyentities);
      if (vizsgalatObj.vizsgalateredmenyentities) {
        vizsgalatObj.vizsgalateredmenyentities.forEach((item) => {
          if (item.vizsgalattipus && item.vizsgalattipus.name) {
            item.vizsgalattipus = item.vizsgalattipus.name;
          }
          if (item.oldal && item.oldal.name) {
            item.oldal = item.oldal.name;
          }
        });
      }

      kartonJson.vizsgalatentities.push(vizsgalatObj);

      console.log('kartonJson: ', kartonJson);
      Services.updateVizsgalat(kartonJson, false, (err, res) => {
        if (!err) {
          this.backToList();
          this.props.route.globals.addSuccess('Vizsgálat módosítva!');
        }
      });
    }
  }

  deleteSzemuvegVizsgalat = (id) => {
    this.setState({
      szemuvegEredmenyEntities: this.state.szemuvegEredmenyEntities.filter(eredmeny => eredmeny.uuid !== id)
    });
    this.toggleSzemuvegTorles();
  }

  deleteLencseVizsgalat = (id) => {
    this.setState({
      lencseEredmenyEntities: this.state.lencseEredmenyEntities.filter(eredmeny => eredmeny.uuid !== id)
    });
    this.toggleLencseTorles();
  }

  /** Dropdown input mező értékek renderelése. */
  renderOptions = () => {
    return this.state.telepulesMezo.map(e => <option key={e.telepulesid} value={e.telepulesid}>{e.telepulesnev}</option>);
  }

  numberFormatter = (cell, row) => {
    if( cell > 0) return '+' + cell;
    return cell;
  }


  vizsgalatIconFormatter = (cell, row) => {
    return (
      <div>
        <Button className="icon-eye table-action-icon" onClick={() => this.handleViewSzemuvegVizsgalatClick(cell, row)} />
        <Button className="icon-pencil table-action-icon" onClick={() => this.handleEditSzemuvegVizsgalatClick(cell, row)} />
        <Button className="icon-trash table-action-icon" onClick={() => this.handledeleteSzemuvegVizsgalatClick(cell)} />
      </div>
    );
  }

  vizsgalatLencseFormatter = (cell, row) => {
    return (
      <div>
        <Button className="icon-eye table-action-icon" onClick={() => this.handleViewLencseVizsgalatClick(cell, row)} />
        <Button className="icon-pencil table-action-icon" onClick={() => this.handleEditLencseVizsgalatClick(cell, row)} />
        <Button className="icon-trash table-action-icon" onClick={() => this.handledeleteLencseVizsgalatClick(cell)} />
      </div>
    );
  }

  dominansFormatter = (cell) => {
    switch (cell) {
      case true:
        return 'Igen';
      case false:
        return 'Nem';
      default:
        return null;
    }
  }

  handledeleteSzemuvegVizsgalatClick = (cell) => {
    this._currentDeleteSzemuvegId = cell;
    this.toggleSzemuvegTorles();
  }

  handledeleteLencseVizsgalatClick = (cell) => {
    this._currentDeleteLencseId = cell;
    this.toggleLencseTorles();
  }

  handleEditSzemuvegVizsgalatClick = (cell, row) => {
    this._currentEditSzemuvegId = cell;
    this._currentEditSzemuvegObj = row;
    this.setState({ szemuvegModositoObj: row });
    this.toggleSzemuvegModositas();
  }

  handleEditLencseVizsgalatClick = (cell, row) => {
    this._currentEditLencseId = cell;
    this._currentEditLencseObj = row;
    this.setState({ lencseModositoObj: row });
    this.toggleLencseModositas();
  }

  handleViewSzemuvegVizsgalatClick = (cell, row) => {
    this._currentViewSzemuvegId = row.id;
    this.toggleSzemuvegNezet();
  }

  handleViewLencseVizsgalatClick = (cell, row) => {
    this._currentViewLencseId = row.id;
    this.toggleLencseNezet();
  }

  handleChangeUgyfel = (value) => {
    if (value && value.length !== 0) {
      let ids = [];
      ids.push(value.id);
      Services.getKartonData(ids, (err, res) => {
        if (!err) {
          this.setState({ selectValue: res[0][0] });
        }
      });
    } else {
      this.setState({ selectValue: null });
    }
  }

  handleInputchangeUgyfel = (value) => {
  }

  flagFormatter = (props, idx) => {
    let handleMouseDown = (event) => {
      this.setValue(props.option.num, idx);
    };

    return (
      <div onMouseDown={handleMouseDown} className="flag-format">
        <span
          className={'flag-icon flag-icon-' + props.option.name.toLowerCase()}
        /> <b>{props.option.caption}</b> +{props.option.num}
      </div>
    );
  }

  kontrollDatumChange = (e, v) => {
    let currentState = this.state.selectValue;
    let currentValue = v === '' ? null : v;
    currentState.kontrolldatum = currentValue;
    this.setState({ selectValue: currentState });
  }

  vizsgalatDatumChange = (e, v) => {
    let currentState = this.state.selectValue;
    let currentValue = v === '' ? null : v;
    currentState.vizsgalatdatum = currentValue;
    this.setState({ selectValue: currentState });
  }

  numberWithPrefix = (number) => {
    if(number > 0) return '+' + number;
    return number;
  }

  renderSzemuvegAdatok = (id) => {
    let szemuvegObj = this.state.szemuvegEredmenyEntities.find(szemuveg => szemuveg.id === id);
    return (
      <div>
        <h6>Szemüveg adatok:</h6>
        <b>{'Oldal : '}</b>
        {szemuvegObj.oldal.name}
        <br />
        <b>Nyers V. : </b>
        {this.numberWithPrefix(szemuvegObj.vizus)}
        <br />
        <b>SPH. : </b>{this.numberWithPrefix(szemuvegObj.sph)}<br />
        <b>CYL. : </b>{this.numberWithPrefix(szemuvegObj.cyl)}<br />
        <b>TENG. : </b>{this.numberWithPrefix(szemuvegObj.tengely)}<br />
        <b>Korrigált V. : </b>{this.numberWithPrefix(szemuvegObj.korrvizus)}<br />
        <b>ADD. : </b>{this.numberWithPrefix(szemuvegObj.add)}<br />
        <b>Prizma DIOP. : </b>{this.numberWithPrefix(szemuvegObj.prizmadioptria)}<br />
        <b>Prizma A. : </b>{this.numberWithPrefix(szemuvegObj.prizmaalap)}<br />
        <b>PD. : </b>{this.numberWithPrefix(szemuvegObj.pd)}<br />
      </div>
    );
  }

  renderLencseAdatok = (id) => {
    let lencseObj = this.state.lencseEredmenyEntities.find(lencse => lencse.id === id);
    return (
      <div>
        <h6>Kontaktlencse adatok:</h6>
        <b>Oldal : </b>{this.numberWithPrefix(lencseObj.oldal.name)}<br />
        <b>SPH. : </b>{this.numberWithPrefix(lencseObj.sph)}<br />
        <b>CYL. : </b>{this.numberWithPrefix(lencseObj.cyl)}<br />
        <b>TENG. : </b>{this.numberWithPrefix(lencseObj.tengely)}<br />
        <b>ADD. : </b>{this.numberWithPrefix(lencseObj.add)}<br />
        <b>BC. : </b>{this.numberWithPrefix(lencseObj.bc)}<br />
        <b>DIA. : </b>{this.numberWithPrefix(lencseObj.dia)}<br />
        <b>Típus : </b>{lencseObj.tipus}<br />
        <b>Domináns : </b>{lencseObj.dominans}<br />
      </div>
    );
  }

  renderSzemuvegEditInputFields = () => {
    return (
      <div className="row">
        <div className="col-md-4">
          <AvGroup>
            <Label for="vizus">Nyers V. :</Label>
            <AvInput name="vizus" id="vizus" type="text" maxLength="10" value={this.state.szemuvegModositoObj && this.state.szemuvegModositoObj.vizus} />
            <AvFeedback>Maximum 10 hosszúságú szöveg!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4">
          <AvGroup>
            <Label for="sph">SPH. :</Label>
            <AvInput name="sph" id="sph" type="number" step="0.25" value={this.state.szemuvegModositoObj && this.state.szemuvegModositoObj.sph} />
            <AvFeedback>Hibás érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4">
          <AvGroup>
            <Label for="cyl">CYL. :</Label>
            <AvInput name="cyl" id="cyl" type="number" step="0.25" value={this.state.szemuvegModositoObj && this.state.szemuvegModositoObj.cyl} />
            <AvFeedback>Hibás érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4">
          {/* <AvField name="tengely" label="TENG. :" type="number" min="0" max="180" step="1" errorMessage="0 és 180 közötti érték!" value={this.state.szemuvegModositoObj && this.state.szemuvegModositoObj.tengely} /> */}
          <AvGroup>
            <Label for="tengely">TENG. :</Label>
            <AvInput name="tengely" id="tengely" type="number" min="0" max="180" step="1" value={this.state.szemuvegModositoObj && this.state.szemuvegModositoObj.tengely} />
            <AvFeedback>0 és 180 közötti érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4">
          <AvGroup>
            <Label for="korrvizus">Korrigált V:</Label>
            <AvInput name="korrvizus" id="korrvizus" type="number" value={this.state.szemuvegModositoObj && this.state.szemuvegModositoObj.korrvizus} />
            <AvFeedback>Hibás érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4">
          {/* <AvField name="add" label="ADD. :" type="number" min="0" max="3" step="0.25" errorMessage="0 és 3 közötti érték!" value={this.state.szemuvegModositoObj && this.state.szemuvegModositoObj.add} /> */}
          <AvGroup>
            <Label for="add">ADD. :</Label>
            <AvInput name="add" id="add" type="number" min="0" max="3.5" step="0.25" value={this.state.szemuvegModositoObj && this.state.szemuvegModositoObj.add} />
            <AvFeedback>0 és 3,5 közötti érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4">
          <AvGroup>
            <Label for="prizmadioptria">Prizma DIOP. :</Label>
            <AvInput name="prizmadioptria" id="prizmadioptria" type="number" min="0" step="0.25" value={this.state.szemuvegModositoObj && this.state.szemuvegModositoObj.prizmadioptria} />
            <AvFeedback>0-nál nagyobb érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4">
          <AvGroup>
            <Label for="prizmaalap">Prizma A. :</Label>
            <AvInput name="prizmaalap" id="prizmaalap" type="number" min="0" max="270" step="90" value={this.state.szemuvegModositoObj && this.state.szemuvegModositoObj.prizmaalap} />
            <AvFeedback>0 és 270 közötti érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4">
          <AvGroup>
            <Label for="pd">PD. :</Label>
            <AvInput name="pd" id="pd" type="number" step="1" value={this.state.szemuvegModositoObj && this.state.szemuvegModositoObj.pd} />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
      </div>
    );
  }

  renderLencseEditInputFields = () => {
    return (
      <div className="row">
        <div className="col-md-4">
          <AvGroup>
            <Label for="sph">SPH. :</Label>
            <AvInput name="sph" id="sph" type="number" step="0.25" value={this.state.lencseModositoObj && this.state.lencseModositoObj.sph} />
            <AvFeedback>Hibás érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4">
          {/* <AvField name="cyl" label="CYL. :" type="number" min="-10" max="10" step="0.25" errorMessage="-10 és 10 közötti érték!" value={this.state.lencseModositoObj && this.state.lencseModositoObj.cyl} /> */}
          <AvGroup>
            <Label for="cyl">CYL. :</Label>
            <AvInput name="cyl" id="cyl" type="number" step="0.25" value={this.state.lencseModositoObj && this.state.lencseModositoObj.cyl} />
            <AvFeedback>Hibás érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4">
          {/* <AvField name="tengely" label="TENG. :" type="number" min="0" max="180" step="1" errorMessage="0 és 180 közötti érték!" value={this.state.lencseModositoObj && this.state.lencseModositoObj.tengely} /> */}
          <AvGroup>
            <Label for="tengely">TENG. :</Label>
            <AvInput name="tengely" id="tengely" type="number" min="0" max="180" step="1" value={this.state.lencseModositoObj && this.state.lencseModositoObj.tengely} />
            <AvFeedback>0 és 180 közötti érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4">
          {/* <AvField name="add" label="ADD. :" type="number" min="0" max="3" step="0.25" errorMessage="0 és 3 közötti érték!" value={this.state.lencseModositoObj && this.state.lencseModositoObj.add} /> */}
          <AvGroup>
            <Label for="add">ADD. :</Label>
            <AvInput name="add" id="add" type="number" min="0" max="3.5" step="0.25" value={this.state.lencseModositoObj && this.state.lencseModositoObj.add} />
            <AvFeedback>0 és 3,5 közötti érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4">
          <AvGroup>
            <Label for="bc">BC. :</Label>
            <AvInput name="bc" id="bc" type="number" value={this.state.lencseModositoObj && this.state.lencseModositoObj.bc} />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4">
          <AvGroup>
            <Label for="dia">DIA. :</Label>
            <AvInput name="dia" id="dia" type="number" value={this.state.lencseModositoObj && this.state.lencseModositoObj.dia} />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-6">
          <AvGroup>
            <Label for="tipus">Típus:</Label>
            <AvInput name="tipus" id="tipus" value={this.state.lencseModositoObj && this.state.lencseModositoObj.tipus} />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-6">
          <AvGroup>
            <Label for="dominans" style={{ paddingRight: 5 }}>Domináns: </Label>
            <AvInput name="dominans" id="dominans" type="checkbox" value={this.state.lencseModositoObj && this.state.lencseModositoObj.dominans} />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
      </div>
    );
  }

  renderEredmenyEntities = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };
    // console.log(this.props.route.vizsgalat);
    return this.props.route.vizsgalat === 'true' ? (
      <div className="row">
        <div className="col-md-2">
          <Label for="vizsgalatdatum">
            Vizsgálat dátuma: *
          </Label>
          <DatePicker
            onChange={this.vizsgalatDatumChange}
            required
            type="select"
            name="vizsgalatdatum"
            format="YYYY-MM-DD"
            value={this.state.selectValue.vizsgalatdatum && new Date(this.state.selectValue.vizsgalatdatum)}
          />
        </div>
        <div className="col-md-2">
          <Label for="kontrolldatum">
            Kontroll dátum:
          </Label>
          <DatePicker
            onChange={this.kontrollDatumChange}
            required
            type="select"
            name="kontrolldatum"
            format="YYYY-MM-DD"
            value={this.state.selectValue.kontrolldatum && new Date(this.state.selectValue.kontrolldatum)}
          />
        </div>
        <div className="col-md-2">
          <AvGroup>
            <Label for="kontrollido">
              Kontroll idő:
            </Label>
            <AvInput
              placeholder="óó:pp"
              name="kontrollido"
              id="kontrollido"
              maxLength="5"
              value={this.state.selectValue && this.state.selectValue.kontrollido}
            />
            <AvFeedback>
              Kötelező mező!
            </AvFeedback>
          </AvGroup>
        </div>
        {/* <div className="col-md-3">
            <AvGroup>
              <Label for="vizsgalonev">Vizsgálatot végezte: *</Label>
              <AvInput required name="vizsgalonev" id="vizsgalonev" value={this.state.selectValue && this.state.selectValue.vizsgalonev} />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
        */}
        <div className="col-md-3">
          <AvField
            type="select"
            name="cegadat"
            id="cegadat"
            label="Cégadat: *"
            errorMessage="Kötelező mező!"
            required
            value={this.state.selectValue && this.state.selectValue.cegadat}
          >
            <option value="">Válasszon cégadatot...</option>
            {this.state.cegadatOptions}
          </AvField>
        </div>
        <div className="col-md-3">
          <AvField
            type="select"
            name="vizsgalonev"
            id="vizsgalonev"
            label="Vizsgálatot végezte: *"
            errorMessage="Kötelező mező!"
            required
            value={this.state.selectValue && this.state.selectValue.vizsgalonev}
          >
            <option value="">Válasszon személyt...</option>
            {this.state.vizsgaloOptions}
          </AvField>
        </div>
        <div className="col-md-12">
          <div><h5 style={titleStyle}>Vizsgálati adatok:</h5></div>
        </div>
        <div className="col-md-12">
          <Button className="munkalap-ugyfel-btn" type="button" color="success" onClick={this.toggleVizsgalatiEredmenyModal}>Új vizsgálati eredmény</Button>
        </div>

        { this.state.szemuvegEredmenyEntities.length ? (
          <div>
            <div className="col-md-12">
              <div><h5 style={titleStyle}>Szemüveg:</h5></div>
            </div>
            <div className="col-md-12">
              <BootstrapTable data={this.state.szemuvegEredmenyEntities}>
                <TableHeaderColumn dataField="vizus" dataFormat={this.numberFormatter}>Nyers V.</TableHeaderColumn>
                <TableHeaderColumn dataField="sph" dataFormat={this.numberFormatter}>SPH.</TableHeaderColumn>
                <TableHeaderColumn dataField="cyl" dataFormat={this.numberFormatter}>CYL.</TableHeaderColumn>
                <TableHeaderColumn dataField="tengely" dataFormat={this.numberFormatter}>TENG.</TableHeaderColumn>
                <TableHeaderColumn dataField="korrvizus" dataFormat={this.numberFormatter}>Korrigált V.</TableHeaderColumn>
                <TableHeaderColumn dataField="add" dataFormat={this.numberFormatter}>ADD.</TableHeaderColumn>
                <TableHeaderColumn dataField="prizmadioptria" dataFormat={this.numberFormatter}>Prizma DIOP.</TableHeaderColumn>
                <TableHeaderColumn dataField="prizmaalap" dataFormat={this.numberFormatter}>Prizma A.</TableHeaderColumn>
                <TableHeaderColumn dataField="pd" dataFormat={this.numberFormatter}>PD.</TableHeaderColumn>
                <TableHeaderColumn
                  dataField="uuid"
                  isKey
                  dataFormat={this.vizsgalatIconFormatter}
                  width="105"
                >
                  {'Műveletek'}
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
           </div>
      ) : <div /> }
        { this.state.lencseEredmenyEntities.length ? (
          <div>
            <div className="col-md-12">
              <div><h5 style={titleStyle}>Kontaktlencse:</h5></div>
            </div>
            <div className="col-md-12">
              <BootstrapTable data={this.state.lencseEredmenyEntities}>
                <TableHeaderColumn dataField="sph" dataFormat={this.numberFormatter}>SPH.</TableHeaderColumn>
                <TableHeaderColumn dataField="cyl" dataFormat={this.numberFormatter}>CYL.</TableHeaderColumn>
                <TableHeaderColumn dataField="tengely" dataFormat={this.numberFormatter}>TENG.</TableHeaderColumn>
                <TableHeaderColumn dataField="add" dataFormat={this.numberFormatter}>ADD.</TableHeaderColumn>
                <TableHeaderColumn dataField="bc" dataFormat={this.numberFormatter}>BC.</TableHeaderColumn>
                <TableHeaderColumn dataField="dia" dataFormat={this.numberFormatter}>DIA.</TableHeaderColumn>
                <TableHeaderColumn dataField="tipus" width="300">Típus</TableHeaderColumn>
                <TableHeaderColumn dataField="dominans" dataFormat={this.dominansFormatter} dataAlign="center">Domináns</TableHeaderColumn>
                <TableHeaderColumn
                  dataField="uuid"
                  isKey
                  dataFormat={this.vizsgalatLencseFormatter}
                  width="105"
                >
                  {'Műveletek'}
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
    ) : <div /> }
        <div className="col-md-12">
          <br />
          <AvGroup>
            <Label for="megjegyzesvizsgalat">Megjegyzés:</Label>
            <AvInput className="munkalap-profil-textarea" type="textarea" name="megjegyzesvizsgalat" id="megjegyzesvizsgalat" value={this.state.selectValue && this.state.selectValue.vizsgalatMegjegyzes} />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-12">
          <AvGroup>
            <Label for="diagnozisvizsgalat">Diagnózis:</Label>
            <AvInput className="munkalap-profil-textarea" type="textarea" name="diagnozisvizsgalat" id="diagnozisvizsgalat"  value={this.state.selectValue && this.state.selectValue.vizsgalatDiagnozis} />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
      </div>
    )
      : <p />;
  }

  renderPartnerInputFields = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };

    return (
      <div className="row">
        <div className="col-md-12">
          <div><h5 style={titleStyle}>Alapadatok:</h5></div>
        </div>
        <div className="col-md-4">
          <AvGroup>
            <Label for="titulus">Titulus: </Label>
            <AvInput name="titulus" id="titulus" />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4">
          <AvGroup>
            <Label for="vezeteknev">Vezetéknév: *</Label>
            <AvInput name="vezeteknev" id="vezeteknev" required />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4">
          <AvGroup>
            <Label for="keresztnev">Keresztnév: *</Label>
            <AvInput name="keresztnev" id="keresztnev" required />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-12">
          <div><h5 style={titleStyle}>Elérhetőségek:</h5></div>
        </div>
        <div className="col-md-4">
          <Label for="formOrszag">Ország: *</Label>
          <Select
            styles={{
              control: base => ({
                ...base,
                '&:focus': { borderColor: 'red' }, // border style on hover
                border: '1px solid lightgray', // default border color
                boxShadow: 'none', // no box-shadow
                borderRadius: '0'
              }),
            }}
            name="formOrszag"
            id="formOrszag"
            placeholder="Kezdjen el gépelni..."
            noOptionsMessage={() => 'Nincs keresési eredmény'}
            searchPromptText="Kezdjen el gépelni a kereséshez..."
            loadingMessage={() => 'Keresés...'}
            onChange={v => this.handleChangeOrszag(v)}
            isClearable
            ignoreAccents={false}
            // loadOptions={_.debounce(this.loadOrszagOptions, 1000)}
            isLoading={false}
            getOptionLabel={option => option.nev}
            getOptionValue={option => option.orszagid}
            value={this.state.orszagValue}
            cache={false}
            autoload={false}
          />
        </div>
        <div className="col-md-4">
          <AvGroup>
            <Label for="iranyitoszam">Irányítószám: *</Label>
            {this.state.telepulesSelect
              ? <div>
                <AvInput name="iranyitoszam" id="iranyitoszam" ref="iranyitoszam" onChange={this.handleIrszamChange} minLength="4" maxLength="4" pattern="^[0-9]*$" required />
                <AvFeedback>Kötelező 4 számjegyű mező!</AvFeedback>
              </div>
              : <div>
                <AvInput name="iranyitoszam" id="iranyitoszam" maxLength="10" required />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </div> }
          </AvGroup>
        </div>
        <div className="col-md-4">
          <AvGroup>
            {this.state.telepulesSelect
              ? <div>
                <AvField type="select" name="telepulesid" id="telepulesid" label="Település: *" disabled={this.state.telepulesMezoInaktiv} errorMessage="Kötelező mező!" required>
                  <option value="">Válassz települést...</option>
                  {this.renderOptions()}
                </AvField>
              </div>
              : <div> <Label for="telepulesnev">Település: *</Label>
                <AvInput name="telepulesnev" id="telepulesnev" required />
              </div>}
          </AvGroup>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="kozterulet">Közterület neve: *</Label>
            <AvInput name="kozterulet" id="kozterulet" required />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="kozteruletjelleg">Közterület jellege: *</Label>
            <AvInput name="kozteruletjelleg" id="kozteruletjelleg" required />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="hazszam">Házszám: *</Label>
            <AvInput name="hazszam" id="hazszam" required />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
          <PhoneNumbers
            numbers={[]}
            ref={(phoneNumberFelvitelRef) => { this.phoneNumberFelvitelRef = phoneNumberFelvitelRef; }}
          />
        </div>
      );
  }

  renderSzemuvegInputFields = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };

    return this.state.vizsgalatTipus === 0 && (
      <div className="row">
        <div className="col-md-12">
          <div><h5 style={titleStyle}>Jobb:</h5></div>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="jobb_v_1">Nyers V. :</Label>
            <AvInput name="jobb_v_1" id="jobb_v_1_s" type="text" maxLength="10" />
            <AvFeedback>Maximum 10 hosszúságú szöveg!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          {/* <AvField name="jobb_sph" label="SPH. :" type="number" min="-20" max="20" step="0.25" errorMessage="" /> */}
          <AvGroup>
            <Label for="jobb_sph">SPH. :</Label>
            <AvInput name="jobb_sph" id="jobb_sph_s" type="number" step="0.25" />
            <AvFeedback>Hibás érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          {/* <AvField name="jobb_cyl" label="CYL. :" type="number" min="-10" max="10" step="0.25" errorMessage="-10 és 10 közötti érték!" /> */}
          <AvGroup>
            <Label for="jobb_cyl">CYL. :</Label>
            <AvInput name="jobb_cyl" id="jobb_cyl_s" type="number" step="0.25" />
            <AvFeedback>Hibás érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          {/* <AvField name="jobb_teng" label="TENG. :" type="number" min="0" max="180" step="1" errorMessage="0 és 180 közötti érték!" /> */}
          <AvGroup>
            <Label for="jobb_teng">TENG. :</Label>
            <AvInput name="jobb_teng" id="jobb_teng_s" type="number" min="0" max="180" step="1" />
            <AvFeedback>0 és 180 közötti érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="jobb_v_2">Korrigált V:</Label>
            <AvInput name="jobb_v_2" id="jobb_v_2_s" type="number" />
            <AvFeedback>Hibás érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          {/* <AvField name="jobb_add" label="ADD. :" type="number" min="0" max="3" step="0.25" errorMessage="0 és 3 közötti érték!" /> */}
          <AvGroup>
            <Label for="jobb_add">ADD. :</Label>
            <AvInput name="jobb_add" id="jobb_add_s" type="number" min="0" max="3.5" step="0.25" />
            <AvFeedback>0 és 3,5 közötti érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="jobb_prizma_diop">Prizma DIOP. :</Label>
            <AvInput name="jobb_prizma_diop" id="jobb_prizma_diop_s" type="number" min="0" step="0.25" />
            <AvFeedback>0-nál nagyobb érték</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="jobb_prizma_a">Prizma A. :</Label>
            <AvInput name="jobb_prizma_a" id="jobb_prizma_a_s" type="number" min="0" max="270" step="90" />
            <AvFeedback>0 és 270 közötti érték!</AvFeedback>
          </AvGroup>
        </div>
        {/* <div className="col-md-3">
                <AvGroup>
                  <Label for="jobb_pd">PD. :</Label>
                  <AvInput name="jobb_pd" id="jobb_pd_s" type="number" step="1" />
                  <AvFeedback>Kötelező mező!</AvFeedback>
                </AvGroup>
          </div> */}
        <div className="col-md-12">
          {/* <Button className="munkalap-ugyfel-btn" type="button" color="success" onClick={this.adatokMasolasa}>Adatok másolása</Button> */}
          <h5 style={titleStyle}>Bal:</h5>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="bal_v_1">Nyers V. :</Label>
            <AvInput name="bal_v_1" id="bal_v_1_s" type="text" maxLength="10" />
            <AvFeedback>Maximum 10 hosszúságú szöveg!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="bal_sph">SPH. :</Label>
            <AvInput name="bal_sph" id="bal_sph_s" type="number" step="0.25" />
            <AvFeedback>Hibás érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          {/* <AvField name="bal_cyl" label="CYL. :" type="number" min="-10" max="10" step="0.25" errorMessage="-10 és 10 közötti érték!" /> */}
          <AvGroup>
            <Label for="bal_cyl">CYL. :</Label>
            <AvInput name="bal_cyl" id="bal_cyl_s" type="number" step="0.25" />
            <AvFeedback>Hibás érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          {/* <AvField name="bal_teng" label="TENG. :" type="number" min="0" max="180" step="1" errorMessage="0 és 180 közötti érték!" /> */}
          <AvGroup>
            <Label for="bal_teng">TENG. :</Label>
            <AvInput name="bal_teng" id="bal_teng_s" type="number" min="0" max="180" step="1" />
            <AvFeedback>0 és 180 közötti érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="bal_v_2">Korrigált V:</Label>
            <AvInput name="bal_v_2" id="bal_v_2_s" type="number" />
            <AvFeedback>Hibás érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          {/* <AvField name="bal_add" label="ADD. :" type="number" min="0" max="3" step="0.25" errorMessage="0 és 3 közötti érték!" /> */}
          <AvGroup>
            <Label for="bal_add">ADD. :</Label>
            <AvInput name="bal_add" id="bal_add_s" type="number" min="0" max="3.5" step="0.25" />
            <AvFeedback>0 és 3,5 közötti érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="bal_prizma_diop">Prizma DIOP. :</Label>
            <AvInput name="bal_prizma_diop" id="bal_prizma_diop_s" type="number" min="0" step="0.25" />
            <AvFeedback>0-nál nagyobb érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="bal_prizma_a">Prizma A. :</Label>
            <AvInput name="bal_prizma_a" id="bal_prizma_a_s" type="number" min="0" max="270" step="90" />
            <AvFeedback>0 és 270 közötti érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="bal_pd">PD. :</Label>
            <AvInput name="bal_pd" id="bal_pd_s" type="number" step="1" />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
      </div>
    );
  }

  renderKontaktlencseInputFields = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };

    return this.state.vizsgalatTipus === 1 && (
      <div className="row">
        <div className="col-md-12">
          <div><h5 style={titleStyle}>Jobb:</h5></div>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="jobb_sph">SPH. :</Label>
            <AvInput name="jobb_sph" id="jobb_sph" type="number" step="0.25" />
            <AvFeedback>Hibás érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          {/* <AvField name="jobb_cyl" label="CYL. :" type="number" min="-10" max="10" step="0.25" errorMessage="-10 és 10 közötti érték!" /> */}
          <AvGroup>
            <Label for="jobb_cyl">CYL. :</Label>
            <AvInput name="jobb_cyl" id="jobb_cyl" type="number" step="0.25" />
            <AvFeedback>Hibás érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          {/* <AvField name="jobb_teng" label="TENG. :" type="number" min="0" max="180" step="1" errorMessage="0 és 180 közötti érték!" /> */}
          <AvGroup>
            <Label for="jobb_teng">TENG. :</Label>
            <AvInput name="jobb_teng" id="jobb_teng" type="number" min="0" max="180" step="1" />
            <AvFeedback>0 és 180 közötti érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          {/* <AvField name="jobb_add" label="ADD. :" type="number" min="0" max="3" step="0.25" errorMessage="0 és 3 közötti érték!" /> */}
          <AvGroup>
            <Label for="jobb_add">ADD. :</Label>
            <AvInput name="jobb_add" id="jobb_add" type="number" min="0" max="3.5" step="0.25" />
            <AvFeedback>0 és 3,5 közötti érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="jobb_bc">BC. :</Label>
            <AvInput name="jobb_bc" id="jobb_bc" type="number" />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="jobb_dia">DIA. :</Label>
            <AvInput name="jobb_dia" id="jobb_dia" type="number" />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="jobb_tipus">Típus:</Label>
            <AvInput name="jobb_tipus" id="jobb_tipus" />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="jobb_dominans" style={{ paddingRight: 5 }}>Domináns: </Label>
            <AvInput name="jobb_dominans" id="jobb_dominans" type="checkbox" />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-12">
          {/* <Button className="munkalap-ugyfel-btn" type="button" color="success" onClick={this.adatokMasolasa}>Adatok másolása</Button> */}
          <h5 style={titleStyle}>Bal:</h5>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="bal_sph">SPH. :</Label>
            <AvInput name="bal_sph" id="bal_sph" type="number" step="0.25" />
            <AvFeedback>Hibás érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          {/* <AvField name="bal_cyl" label="CYL. :" type="number" min="-10" max="10" step="0.25" errorMessage="-10 és 10 közötti érték!" /> */}
          <AvGroup>
            <Label for="bal_cyl">CYL. :</Label>
            <AvInput name="bal_cyl" id="bal_cyl" type="number" step="0.25" />
            <AvFeedback>Hibás érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          {/* <AvField name="bal_teng" label="TENG. :" type="number" min="0" max="180" step="1" errorMessage="0 és 180 közötti érték!" /> */}
          <AvGroup>
            <Label for="bal_teng">TENG. :</Label>
            <AvInput name="bal_teng" id="bal_teng" type="number" min="0" max="180" step="1" />
            <AvFeedback>0 és 180 közötti érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          {/* <AvField name="bal_add" label="ADD. :" type="number" min="0" max="3" step="0.25" errorMessage="0 és 3 közötti érték!" /> */}
          <AvGroup>
            <Label for="bal_add">ADD. :</Label>
            <AvInput name="bal_add" id="bal_add" type="number" min="0" max="3.5" step="0.25" />
            <AvFeedback>0 és 3,5 közötti érték!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="bal_bc">BC. :</Label>
            <AvInput name="bal_bc" id="bal_bc" type="number" />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="bal_dia">DIA. :</Label>
            <AvInput name="bal_dia" id="bal_dia" type="number" />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="bal_tipus">Típus:</Label>
            <AvInput name="bal_tipus" id="bal_tipus" />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="bal_dominans" style={{ paddingRight: 5 }}>Domináns: </Label>
            <AvInput name="bal_dominans" id="bal_dominans" type="checkbox" />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
      </div>
    );
  }

  renderInputFields = () => {
    if (this.state.vizsgalatTipus === 0) {
      return (
        <div>
          {this.renderSzemuvegInputFields()}
        </div>
      );
    }
    if (this.state.vizsgalatTipus === 1) {
      return (
        <div>
          {this.renderKontaktlencseInputFields()}
        </div>
      );
    }
    return <div />
    // return (<div>{{
    //       0: (this.renderSzemuvegInputFields()),
    //       1: (this.renderKontaktlencseInputFields())
    //   }[this.state.vizsgalatTipus]}
    //   <div className="col-md-3" hidden>
    //     <AvGroup>
    //       <Label for="hidden">hidden</Label>
    //       <AvInput name="hidden" id="hidden" type="number" />
    //       <AvFeedback>Kötelező mező!</AvFeedback>
    //     </AvGroup>
    //   </div>
    // </div>);
  }

  loadUgyfelChange = (input, callback) => {
    if (input.length !== 0) {
      Services.listKartotekUgyfel(input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            item.nev += ' (' + item.cim + ') ';
            if (item.adoszam) {
              item.nev += '[' + NumberFormatter.addHyphensToString(item.adoszam) + '] ';
            }
            newArray.push(item);
          });
          callback(newArray);
        }
      });
    }
  }

  renderTelefonszamok = () => {
    let telefonszamok = [];
    this.state.telefonszamok.forEach((item, idx) => {
      telefonszamok.push(
        <div className="col-md-4" key={idx.toString()}>
          <PhoneCard
            idx={idx.toString()}
            key={idx.toString()}
            szam={item.szam}
            orszag={item.orszag.caption}
            num={'+' + item.orszag.num}
            tipus={item.tipus}
            isElsodleges={item.elsodleges}
            onModifyClick={() => this.handlePhoneChangeClick(idx)}
            onDeleteClick={() => this.handlePhoneDelete(idx)}
            onElsodlegesClick={() => this.handlePhoneElsodlegesClick(idx)}
          />
        </div>
      );
    });
    return (
      telefonszamok
    );
  }

  handlePhoneElsodlegesClick = (idx) => {
    let newTelefonszamok = [];
    this.state.telefonszamok.forEach((item, itemidx) => {
      if (itemidx === idx) {
        item.elsodleges = true;
        newTelefonszamok.push(item);
      } else {
        item.elsodleges = false;
        newTelefonszamok.push(item);
      }
    });
    this.setState({
      telefonszamok: newTelefonszamok
    });
  }

  handlePhoneDelete = (index) => {
    this.setState(prevState => ({
      telefonszamok: [...prevState.telefonszamok.slice(0, index), ...prevState.telefonszamok.slice(index + 1)]
    }));
  }

  addPhone = (event, phoneObj) => {
    this.setState({ phoneButtonLoading: true });

    let submitObj = {
      orszag: (this.state.formModalOrszaghivo === '' || this.state.formModalOrszaghivo === null) ? null : this.state.formModalOrszaghivo.name,
      tipus: this.state.formModalTipus,
      szam: this.state.formModalTelefonszam
    };

    Services.checkPhone(submitObj, (err) => {
      if (!err) {
        if (this.state.formPhoneModalType === 'FEL') {
          this.setState(prevState => ({
            phoneButtonLoading: false,
            telefonszamok: [...prevState.telefonszamok, {
              elsodleges: false,
              szam: prevState.formModalTelefonszam,
              tipus: prevState.formModalTipus,
              orszag: prevState.formModalOrszaghivo
            }]
          }));
        } else {
          let prevTelefonszamok = JSON.parse(JSON.stringify(this.state.telefonszamok));
          prevTelefonszamok[this.state.currentPhoneId] = {
            elsodleges: prevTelefonszamok[this.state.currentPhoneId].elsodleges,
            szam: submitObj.szam,
            tipus: submitObj.tipus,
            orszag: this.state.formModalOrszaghivo
          };
          this.setState({
            phoneButtonLoading: false,
            telefonszamok: prevTelefonszamok
          });
        }
        this.togglePhoneModal();
      } else {
        this.setState({ phoneButtonLoading: false });
      }
    });
  }

  handleNewPhoneClick = () => {
    this.setState({
      formModalOrszaghivo: '',
      formModalTelefonszam: '',
      formModalTipus: '',
      formPhoneModalType: 'FEL',
      modalPhoneTitle: 'Új telefonszám hozzáadása',
      modalPhoneButtonText: 'Hozzáadás'
    }, () => {
      this.togglePhoneModal();
    });
  }

  handlePhoneChangeClick = (idx) => {
    this.setState(prevState => ({
      formModalOrszaghivo: prevState.telefonszamok[idx].orszag,
      formModalTelefonszam: prevState.telefonszamok[idx].szam,
      formModalTipus: prevState.telefonszamok[idx].tipus,
      modalPhoneTitle: 'Telefonszám módosítása',
      currentPhoneId: idx,
      formPhoneModalType: 'MOD',
      modalPhoneButtonText: 'Módosítás'
    }), () => {
      this.togglePhoneModal();
    });
  }

  setValue = (value) => {
    this.state.orszagOptions.forEach((item) => {
      if (item.num === value) {
        this.setState({ formModalOrszaghivo: { num: value, name: item.name.toUpperCase(), caption: item.caption } });
        return;
      }
    });
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  render() {
    const Option = ({ children, ...props }) => {
      const { onMouseMove, onMouseOver, ...rest } = props.innerProps;
      const newProps = Object.assign(props, { innerProps: rest });
      return (
        <components.Option
          {...newProps}
        >
          <div className="flag-format" onClick={() => this.setValue(props.data.num)}>
            <span
              className={'flag-icon flag-icon-' + props.data.name.toLowerCase()}
            />
            <b>{props.data.caption}</b> + {props.data.num}
          </div>
        </components.Option>
      );
    };

    let cardStyle = {
      padding: 20
    };

    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };
    // console.log(this.state.selectValue.telefonszam);
    return (
      <div>
        <div className="animated fadeIn">
          <div className="card">
            <div className="card-header">
              {this.state.vizsgalatStatus ? 'Új vizsgálat' : 'Új karton'}
            </div>
            <div style={cardStyle}>
              <AvForm onValidSubmit={this.mentes}>
                <div className="row">
                  {/* {this.state.modositoStatus || this.state.vizsgalatStatus ? <p /> : <div className="col-md-10"><Button className="munkalap-ugyfel-btn" type="button" color="success" onClick={this.togglePartnerFelvitel}>Új ügyfél rögzítése</Button> </div>} */}
                  <div className="col-md-12">
                    <div><h5 style={titleStyle}>Ügyfél adatok:</h5></div>
                  </div>
                  {this.state.modositoStatus || this.state.vizsgalatStatus ? <p /> : (
                    <div className="col-md-3">
                      {/* <Label for="ugyfelId">Ügyfél neve: *</Label>
                          <Select.Async
                            name="ugyfelId"
                            id="ugyfelId"
                            placeholder="Kezdjen el gépelni..."
                            noResultsText="Nincs keresési eredmény"
                            searchPromptText="Kezdjen el gépelni a kereséshez..."
                            loadingPlaceholder="Keresés..."
                            onChange={v => this.handleChangeUgyfel(v)}
                            onInputChange={v => this.handleInputchangeUgyfel(v)}
                            clearable
                            ignoreAccents={false}
                            loadOptions={_.debounce((input, callback) => this.loadUgyfelChange(input, callback), 1000)}
                            isLoading={false}
                            valueKey="id"
                            labelKey="nev"
                            value={this.state.selectValue}
                            cache={false}
                            autoload={false}
                      /> */}
                      <Label for="ugyfelId">Ügyfél neve: *</Label>
                      <Select
                        styles={{
                          control: base => ({
                            ...base,
                            '&:focus': { borderColor: 'red' }, // border style on hover
                            border: '1px solid lightgray', // default border color
                            boxShadow: 'none', // no box-shadow
                            borderRadius: '0'
                          }),
                        }}
                        name="ugyfelId"
                        id="ugyfelId"
                        placeholder="Kezdjen el gépelni..."
                        noOptionsMessage={() => 'Nincs keresési eredmény'}
                        searchPromptText="Kezdjen el gépelni a kereséshez..."
                        loadingMessage={() => 'Keresés...'}
                        onChange={v => this.handleChangeUgyfel(v)}
                        onInputChange={v => this.handleInputchangeUgyfel(v)}
                        isClearable
                        ignoreAccents={false}
                        loadOptions={_.debounce(this.loadUgyfelChange, 1000)}
                        isLoading={false}
                        getOptionLabel={option => option.nev}
                        getOptionValue={option => option.id}
                        value={this.state.selectValue}
                        cache={false}
                        autoload={false}
                      />
                    </div>
                  )}
                  { !this.state.felvetelStatus ? <div className="col-md-3">
                    <AvGroup>
                      <Label for="azon">Ügyfél neve: *</Label>
                      <AvInput name="azon" id="azon" required disabled value={this.state.selectValue ? this.state.selectValue.nev : ''} />
                      <AvFeedback>Kötelező mező!</AvFeedback>
                    </AvGroup>
                  </div> : <span /> }
                  <div className="col-md-3">
                    <AvGroup>
                      <Label for="nyilvDatum">Nyilv. vétel dátuma: *</Label>
                      <AvInput name="nyilvDatum" id="nyilvDatum" required disabled value={this.state.selectValue ? this.state.selectValue.rogzdatum : ''} />
                      <AvFeedback>Kötelező mező!</AvFeedback>
                    </AvGroup>
                  </div>
                  <div className="col-md-6">
                    <AvGroup>
                      <Label for="cim">Ügyfél címe: *</Label>
                      <AvInput name="cim" id="cim" required disabled value={this.state.selectValue ? this.state.selectValue.cim : ''} />
                      <AvFeedback>Kötelező mező!</AvFeedback>
                    </AvGroup>
                  </div>
                  <div className="col-md-12">
                    <div><h5 style={titleStyle}>Egyéb adatok:</h5></div>
                  </div>
                  <div className="col-md-3">
                    <AvGroup>
                      <Label for="ugyfelTajszam">Tajszám:</Label>
                      <AvInput name="ugyfelTajszam" id="ugyfelTajszam" disabled={this.state.selectValue && this.state.selectValue.tajszam && !this.state.modositoStatus && !this.state.felvetelStatus} value={this.state.selectValue ? this.state.selectValue.tajszam : ''} />
                      <AvFeedback>Kötelező 9 jegyű számmező!</AvFeedback>
                    </AvGroup>
                  </div>
                  <div className="col-md-3">
                    <Label for="ugyfelSzuletesnap">Születési idő:</Label>
                    <DatePicker
                      required
                      type="select"
                      name="ugyfelSzuletesnap"
                      ref="date"
                      disabled={this.state.selectValue && this.state.selectValue.szuletesiido && !this.state.modositoStatus && !this.state.felvetelStatus}
                      value={this.state.selectValue && this.state.selectValue.szuletesiido ? new Date(this.state.selectValue.szuletesiido) : null}
                      onChange={(v) => { let selectValue = Object.assign({}, this.state.selectValue); selectValue.szuletesiido = v; this.setState({ selectValue: selectValue }); }}
                    />
                  </div>
                  <div className="col-md-12">
                    <div><h5 style={titleStyle}>Elérhetőség:</h5></div>
                  </div>
                  <div className="col-md-3">
                    <AvGroup>
                      <Label for="email">Email: </Label>
                      <AvInput
                        name="email"
                        id="email"
                        disabled={this.state.selectValue && this.state.selectValue.email && !this.state.modositoStatus && !this.state.felvetelStatus}
                        value={this.state.selectValue ? this.state.selectValue.email : ''}
                      />
                      <AvFeedback>Kötelező mező!</AvFeedback>
                    </AvGroup>
                  </div>
                  <div className="col-md-9" />
                  <div className="col-md-12">
                    <div>
                      <h5 style={titleStyle}>
                        {'Telefonszámok:'}
                      </h5>
                    </div>
                  </div>
                  {this.renderTelefonszamok()}
                  <div className="col-md-12">
                    <Button
                      className="pull-left table-new-button"
                      color="success"
                      onClick={() => this.handleNewPhoneClick()}
                    >
                      {'Telefonszám hozzáadása'}
                    </Button>
                  </div>
                  {/* <PhoneNumbers
                    numbers={this.state.selectValue && this.state.selectValue.telefonszam}
                    ref={(phoneNumberRef) => {this.phoneNumberRef = phoneNumberRef;}}
                  /> */}
                  <div className="col-md-12">
                    <div style={{ width: '110px' }}>
                      <h5 id="AnamnezesTooltip" style={titleStyle}>
                        {'Anamnézis:'}
                      </h5>
                      <UncontrolledTooltip placement="right" target="AnamnezesTooltip">
                        {'Kórelőzmény.'}
                      </UncontrolledTooltip>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <AvGroup>
                      <Label for="megjegyzes">Megjegyzés:</Label>
                      <AvInput className="munkalap-profil-textarea" type="textarea" name="megjegyzes" id="megjegyzes" value={this.state.megjegyzes} />
                      <AvFeedback>Kötelező mező!</AvFeedback>
                    </AvGroup>
                  </div>
                </div>
                {this.renderEredmenyEntities()}
                <div className="amnezis-btn">
                  <Button className="munkalap-ugyfel-btn" color="primary">Mentés</Button>
                  <Button className="munkalap-ugyfel-btn" type="button" color="secondary" onClick={this.backToList}>Mégse</Button>
                </div>
              </AvForm>
              {/* SZEM URLAP */}
              <Modal isOpen={this.state.modalVizsgalatiEredmeny} toggle={this.toggleVizsgalatiEredmenyModal} className={this.props.className} backdrop="static" size="lg">
                <AvForm onValidSubmit={this.addVizsgalat}>
                  <ModalHeader>
                    Vizsgálati eredmény rögzítése
                  </ModalHeader>
                  <ModalBody>
                    <div className="row">
                      <div className="col-md-12">
                        <div>
                          <h5 style={titleStyle}>
                            Vizsgálat típusa:
                          </h5>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <AvRadioGroup name="vizsgalattipus" required>
                          <AvRadio label="Szemüveg" value="SZEMUVEG" id="radioOption1" onChange={this.clickOnSzemuveg} disabled={this.state.szemuvegEredmenyEntities.length || this.state.vizsgalatTipus !== null} />
                          <AvRadio label="Kontaktlencse" value="KONTAKTLENCSE" id="radioOption2" onChange={this.clickOnKontaktlencse} disabled={this.state.lencseEredmenyEntities.length || this.state.vizsgalatTipus !== null} />
                        </AvRadioGroup>
                      </div>
                    </div>
                    {this.renderInputFields()}
                  </ModalBody>
                  <ModalFooter>
                    <Button color="primary">
                      Mentés
                    </Button>
                    {' '}
                    <Button type="button" color="secondary" onClick={this.toggleVizsgalatiEredmenyModal}>Mégse</Button>
                  </ModalFooter>
                </AvForm>
              </Modal>
              <Modal isOpen={this.state.modalPartnerFelvitel} toggle={this.togglePartnerFelvitel} className={this.props.className} backdrop="static" size="lg" >
                <AvForm onValidSubmit={this.addPartner}>
                  <ModalHeader>Partner felvétel</ModalHeader>
                  <ModalBody>{this.renderPartnerInputFields()}</ModalBody>
                  <ModalFooter>
                    <Button color="primary">Mentés</Button>
                    <Button type="button" color="secondary" onClick={this.togglePartnerFelvitel}>Vissza</Button>
                  </ModalFooter>
                </AvForm>
              </Modal>
              <Modal isOpen={this.state.modalSzemuvegTorles} toggle={this.toggleSzemuvegTorles} className={this.props.className} backdrop="static">
                <ModalHeader>Törlés</ModalHeader>
                <ModalBody>
                  {'Valóban törölni kívánja a kiválasztott tételt?'}
                </ModalBody>
                <ModalFooter>
                  <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteSzemuvegVizsgalat(this._currentDeleteSzemuvegId)}>OK</Button>
                  <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleSzemuvegTorles}>Mégsem</Button>
                </ModalFooter>
              </Modal>
              <Modal isOpen={this.state.modalLencseTorles} toggle={this.toggleLencseTorles} className={this.props.className} backdrop="static">
                <ModalHeader>Törlés</ModalHeader>
                <ModalBody>
                  {'Valóban törölni kívánja a kiválasztott tételt?'}
                </ModalBody>
                <ModalFooter>
                  <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteLencseVizsgalat(this._currentDeleteLencseId)}>OK</Button>
                  <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleLencseTorles}>Mégsem</Button>
                </ModalFooter>
              </Modal>
              <Modal isOpen={this.state.modalSzemuvegNezet} toggle={this.toggleSzemuvegNezet} className={this.props.className} backdrop="static">
                <ModalHeader>Megtekintés</ModalHeader>
                <ModalBody>{this.state.modalSzemuvegNezet ? this.renderSzemuvegAdatok(this._currentViewSzemuvegId) : <div />}</ModalBody>
                <ModalFooter>
                  <Button
                    type="button"
                    color="primary"
                    onClick={this.toggleSzemuvegNezet}
                    className="btn-cursor-pointer"
                  >
                    {'Vissza'}
                  </Button>
                </ModalFooter>
              </Modal>
              <Modal isOpen={this.state.modalLencseNezet} toggle={this.toggleLencseNezet} className={this.props.className} backdrop="static">
                <ModalHeader>Megtekintés</ModalHeader>
                <ModalBody>{this.state.modalLencseNezet ? this.renderLencseAdatok(this._currentViewLencseId) : <div />}</ModalBody>
                <ModalFooter> <Button type="button" color="primary" onClick={this.toggleLencseNezet} className="btn-cursor-pointer" >Vissza</Button></ModalFooter>
              </Modal>
              <Modal isOpen={this.state.modalSzemuvegModosit} toggle={this.toggleSzemuvegModositas} className={this.props.className} backdrop="static" size="lg">
                <AvForm onValidSubmit={this.modifySzemuveg}>
                  <ModalHeader>Módosítás</ModalHeader>
                  <ModalBody>{this.renderSzemuvegEditInputFields()}</ModalBody>
                  <ModalFooter>
                    <Button color="primary">Mentés</Button>
                    <Button type="button" color="secondary" onClick={this.toggleSzemuvegModositas} className="btn-cursor-pointer" >Mégsem</Button>
                  </ModalFooter>
                </AvForm>
              </Modal>
              <Modal isOpen={this.state.modalLencseModosit} toggle={this.toggleLencseModositas} className={this.props.className} backdrop="static">
                <AvForm onValidSubmit={this.modifyLencse}>
                  <ModalHeader>Módosítás</ModalHeader>
                  <ModalBody>{this.renderLencseEditInputFields()}</ModalBody>
                  <ModalFooter>
                    <Button color="primary">Mentés</Button>
                    <Button type="button" color="secondary" onClick={this.toggleLencseModositas} className="btn-cursor-pointer" >Mégsem</Button>
                  </ModalFooter>
                </AvForm>
              </Modal>
              <Modal isOpen={this.state.modalPhone} toggle={this.togglePhoneModal} backdrop="static">
                <AvForm onValidSubmit={this.addPhone}>
                  <ModalHeader>{this.state.modalPhoneTitle}</ModalHeader>
                  <ModalBody>
                    <div>
                      <AvField
                        type="select"
                        name="formModalTipus"
                        id="formModalTipus"
                        label="Típus: *"
                        errorMessage="Kötelező mező!"
                        value={this.state.formModalTipus}
                        onChange={this.handleInputChange}
                        required
                      >
                        <option value="">
                          {'Válassz típust...'}
                        </option>
                        {this.state.telefonTipusOptions}
                      </AvField>
                    </div>
                    <div className="form-group">
                      <Label for="formModalOrszaghivo">
                        {'Országhívó: *'}
                      </Label>
                      <SelectInput
                        name="formModalOrszaghivo"
                        id="formModalOrszaghivo"
                        // components={{
                        //   Option: props => (
                        //     this.flagFormatter(props.data, props)
                        //   )
                        // }}
                        components={{ Option }}
                        isSearchable={false}
                        placeholder="Válassz országot..."
                        value={this.state.formModalOrszaghivo}
                        getOptionLabel={option => option.caption}
                        getOptionValue={option => option.num}
                        // options={this.state.orszagOptions}
                        options={this.state.orszagOptions}
                        // styles={colourStyles}
                      />
                    </div>
                    <div>
                      <AvGroup>
                        <Label for="formModalTelefonszam">
                          {'Telefonszám: *'}
                        </Label>
                        <AvInput
                          name="formModalTelefonszam"
                          id="formModalTelefonszam"
                          required
                          value={this.state.formModalTelefonszam}
                          onChange={this.handleInputChange}
                        />
                        <AvFeedback>
                          {'Kötelező mező!'}
                        </AvFeedback>
                      </AvGroup>
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <ProgressButton color="primary" loading={this.state.phoneButtonLoading}>{this.state.modalPhoneButtonText}</ProgressButton>
                    <Button type="button" color="secondary" onClick={this.togglePhoneModal}>Mégsem</Button>
                  </ModalFooter>
                </AvForm>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default KartotekrendszerForm;
