import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import classNames from 'classnames';
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox
} from 'availity-reactstrap-validation';
import 'moment/locale/hu';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import Select from 'react-select/lib/Async';
import momentLocalizer from 'react-widgets-moment';
import {
  Card,
  Button,
  CardTitle,
  CardText,
  UncontrolledTooltip,
  FormGroup,
  Col,
  FormText,
  Label,
  CustomInput
} from 'reactstrap';

const colSizes = ['xs', 'sm', 'md', 'lg', 'xl'];

/**
* Dátumválasztó
*/
export class DatetimePicker extends DateTimePicker {
  // http://momentjs.com/docs/#/displaying/format
  static defaultProps = Object.assign({}, DateTimePicker.defaultProps, {
    format: 'YYYY-MM-DD HH:mm:ss',
    time: true,
    messages: {
      calendarButton: 'dátum', 
      timeButton: 'idő',
    }
  });
  constructor(props) {
    super(props);
    momentLocalizer(Moment);
  }
}

export class DatePicker extends DatetimePicker {
  static defaultProps = Object.assign({}, DatetimePicker.defaultProps, {
    format: 'YYYY-MM-DD',
    time: false,
    // onBlur: (d) => {alert('onBlur')}
  });

  static contextTypes = {
    FormCtrl: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.context.FormCtrl.register(this);
  }

  getValue() {
    if (this && this.inner && this.inner.inputRef && this.inner.inputRef.state) {
      return this.inner.inputRef.state.textValue;
    }
    return null;
  }
}

export class AvSelect extends Select {
  static propTypes = Object.assign({}, AvInput.propTypes, Select.propTypes, {
    label: PropTypes.node,
    labelHidden: PropTypes.bool,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    id: PropTypes.string,
    inputClass: PropTypes.string,
    labelClass: PropTypes.string,
    helpMessage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    errorMessage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    labelAttrs: PropTypes.object,
    groupAttrs: PropTypes.object,
    grid: PropTypes.object,
  });

  static contextTypes = {
    FormCtrl: PropTypes.object.isRequired,
  };

  static childContextTypes = {
    FormCtrl: PropTypes.object.isRequired,
  };

  getChildContext() {
    this.FormCtrl = { ...this.context.FormCtrl };
    const registerValidator = this.FormCtrl.register;
    this.FormCtrl.register = (input, updater = input && input.setState && input.setState.bind(input)) => {
      registerValidator(input, () => {
        this.setState({});
        if (updater) updater({});
      });
    };
    return {
      FormCtrl: this.FormCtrl,
    };
  }

  render() {
    let row = false;
    const col = {};
    const labelCol = {};
    const {
      helpMessage,
      label,
      labelHidden,
      inputClass,
      labelClass,
      children,
      id = this.props.name,
      size,
      disabled,
      readOnly,
      grid,
      labelAttrs,
      groupAttrs,
      ...attributes
    } = this.props;

    if (grid) {
      colSizes.forEach(colSize => {
        if (grid[colSize]) {
          row = true;
          const sizeNum = parseInt(grid[colSize], 10);
          col[colSize] = sizeNum;
          labelCol[colSize] = 12 - sizeNum;
        }
      });
    }

    const input = (<Select
      id={id}
      className={inputClass}
      size={size}
      disabled={disabled}
      readOnly={readOnly}
      {...attributes}
    >
      {children}
    </Select>);

    const validation = this.context.FormCtrl.getInputState(this.props.name);

    const feedback = validation.errorMessage ? (<AvFeedback>{validation.errorMessage}</AvFeedback>) : null;
    const help = helpMessage ? (<FormText>{helpMessage}</FormText>) : null;
    const inputRow = row ? (
      <Col {...col}>
        {input}
        {feedback}
        {help}
      </Col>
    ) : input;

    return (
      <AvGroup disabled={disabled} row={row} {...groupAttrs}>
        {label && (
          <Label
            for={id}
            className={labelClass}
            hidden={labelHidden}
            size={size}
            {...labelCol}
            {...labelAttrs}
          >
            {label}
          </Label>
        )}
        {inputRow}
        {!row && feedback}
        {!row && help}
      </AvGroup>
    );
  }

}

export class TimePicker extends DatetimePicker {
  static defaultProps = Object.assign({}, DatetimePicker.defaultProps, {
    format: 'HH:mm',
    date: false,
    // open: 'time'
    // onBlur: (d) => {alert('onBlur')}
  });

  static contextTypes = {
    FormCtrl: PropTypes.object.isRequired,
  };

  componentDidMount() {
    this.context.FormCtrl.register(this);
  }

  getValue() {
    if (this && this.refs.inner) {
      return this.refs.inner.refs.valueInput.state.textValue;
    }
    return null;
  }
}

export const PaginationTotal = (start, to, total) => {
  return (
    <span>
      {' '}
      <Button className="text-black" style={{ backgroundColor: '#eceff1' }} >
        { start } - { to } / { total + ' ' } tétel megjelenítése
      </Button>
      {' '}
    </span>
  );
};

export const BankCard = (props) => {
  const {
    bankszamlaszam,
    ibanszam,
    penzintezet,
    onDeleteClick,
    onModifyClick,
  } = props;

  return (
    <Card body>
      <CardText>
        <b>Pénzintézet:</b>
        {' '}
        {penzintezet}
        <br />
        <b>Bankszámlaszám:</b>
        {' '}
        {bankszamlaszam}
        <br />
        <b>IBAN szám:</b>
        {' '}
        {ibanszam}
        <br />
      </CardText>
      <div>
        {onModifyClick ? <Button style={{ cursor: 'pointer' }} color="primary" onClick={onModifyClick}><i className="icon-pencil" aria-hidden="true" /></Button> : <span />}{' '}
        {onDeleteClick ? <Button style={{ cursor: 'pointer' }} color="danger" onClick={onDeleteClick}><i className="fa fa-trash-o" aria-hidden="true" /></Button> : <span />}{' '}
      </div>
    </Card>
  );
};

export const FileCard = (props) => {
  const {
    idx,
    title,
    length,
    onViewClick,
    onDownloadClick,
    onDeleteClick,
    onLogoClick,
    activeLogo,
    mime
  } = props;

  let selector = 'selector-' + title.replace(/[^a-zA-Z]/g, '');

  return (
    <Card body style={activeLogo ? { backgroundColor: 'bisque' } : null}>
      <CardTitle><b>{title + (activeLogo ? ' (aktív logó)' : '')}</b></CardTitle>
      <CardText>{length}</CardText>
      <div>
        {onViewClick ? (
          <React.Fragment>
            <Button color="primary" type="button" onClick={onViewClick} id={'view-' + selector}>
              <i className="icon-eye" aria-hidden="true" />
            </Button>
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'view-' + selector} delay={50}>
              {'Fájl megtekintése'}
            </UncontrolledTooltip>
          </React.Fragment>
        ) : <span />}
        {' '}
        {onDownloadClick ? (
          <React.Fragment>
            <Button color="primary" type="button" onClick={onDownloadClick} id={'download-' + selector}>
              <i className="fa fa-arrow-down" aria-hidden="true" />
            </Button>
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'download-' + selector} delay={50}>
              {'Fájl letöltése'}
            </UncontrolledTooltip>
          </React.Fragment>
        ) : <span />}
        {' '}
        {onDeleteClick ? (
          <React.Fragment>
            <Button color="danger" type="button" onClick={onDeleteClick} id={'delete-' + selector}>
              <i className="fa fa-trash-o" aria-hidden="true" />
            </Button>
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'delete-' + selector} delay={50}>
              {'Fájl törlése'}
            </UncontrolledTooltip>
          </React.Fragment>
        ) : <span />}
        {' '}
        {onLogoClick && !activeLogo && (mime === 'image/jpeg' || mime === 'image/png') ? (
          <React.Fragment>
            <Button color="success" type="button" onClick={onLogoClick} id={'logo-' + selector}>
              <i className="fa fa-check" aria-hidden="true" />
            </Button>
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'logo-' + selector} delay={50}>
              {'Logó aktiválása'}
            </UncontrolledTooltip>
          </React.Fragment>
        ) : <span />}
        {' '}
      </div>
    </Card>
  );
};

export const PhoneCard = (props) => {
  const {
    idx,
    orszag,
    num,
    tipus,
    szam,
    onViewClick,
    onModifyClick,
    onDeleteClick,
    onElsodlegesClick,
    isElsodleges,
  } = props;

  let formatTipus = (name) => {
    // TODO : DROP THIS!!!!!!
    switch (name) {
      case 'FAX':
        return 'Fax';
      case 'MOBIL':
        return 'Mobiltelefon';
      case 'VEZETEK':
        return 'Vezetékes';
      default:
        return 'Ismeretlen';
    }
  };

  return (
    <Card body style={isElsodleges ? { backgroundColor: 'bisque' } : null}>
      <CardTitle><b>{formatTipus(tipus) + (isElsodleges ? ' (Elsődleges)' : '')}</b></CardTitle>
      <CardText>
        <b>
          {'Telefonszám: '}
        </b>
        {szam}
        <br />
        <b>
          {'Országhívó: '}
        </b>
        {orszag + (num ? ' (' + num + ')' : '')}
      </CardText>
      <div>
        {onViewClick ? (
          <React.Fragment>
            <Button color="primary" onClick={onViewClick} id={'view-' + idx} onMouseDown={(e) => { if (e) { e.preventDefault(); } }}>
              <i className="icon-eye" aria-hidden="true" />
            </Button>
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'view-' + idx} delay={50}>
              {'Telefonszám megtekintése'}
            </UncontrolledTooltip>
          </React.Fragment>
        ) : <span />}
        {' '}
        {onModifyClick ? (
          <React.Fragment>
            <Button color="primary" onClick={onModifyClick} id={'modify-' + idx} onMouseDown={(e) => { if (e) { e.preventDefault(); } }}>
              <i className="icon-pencil" aria-hidden="true" />
            </Button>
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'modify-' + idx} delay={50}>
              {'Telefonszám módositása'}
            </UncontrolledTooltip>
          </React.Fragment>
        ) : <span />}
        {' '}
        {onDeleteClick ? (
          <React.Fragment>
            <Button color="danger" onClick={onDeleteClick} id={'delete-' + idx} onMouseDown={(e) => { if (e) { e.preventDefault(); } }}>
              <i className="fa fa-trash-o" aria-hidden="true" />
            </Button>
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'delete-' + idx} delay={50}>
              {'Telefonszám törlése'}
            </UncontrolledTooltip>
          </React.Fragment>
        ) : <span />}
        {' '}
        {!isElsodleges ? (
          <React.Fragment>
            <Button color="success" onClick={onElsodlegesClick} id={'logo-' + idx} onMouseDown={(e) => { if (e) { e.preventDefault(); } }}>
              <i className="fa fa-check" aria-hidden="true" />
            </Button>
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'logo-' + idx} delay={50}>
              {'Elsődleges'}
            </UncontrolledTooltip>
          </React.Fragment>
        ) : <span />}
        {' '}
      </div>
    </Card>
  );
};
