import React, { Component } from 'react';
import PropTypes from 'prop-types';
import UzenetClient from '../components/UzenetClient';
import Services from '../../services';

class UzenetekContentView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      loading: false
    };
  }

  componentDidMount() {
    this.getPostalada();
  }

  getPostalada = () => {
    this.setState({
      loading: true
    });
    Services.getPostalada((err, res) => {
      if (!err) {
        this.setState({
          data: res[0],
          loading: false
        });
      }
    });
  }

  render() {
    return (
      <UzenetClient
        data={this.state.data}
        loading={this.state.loading}
        refreshCall={this.getPostalada}
        globals={this.props.globals}
      />
    );
  }
}

UzenetekContentView.propTypes = {
  className: PropTypes.string
};

export default UzenetekContentView;
