import React from 'react';
import KartotekrendszerList from './KartotekrendszerList';

const KartotekrendszerBase = (props) => {
  let cardStyle = {
    padding: 20
  };

  return (
    <div>
      <div className="animated fadeIn">
        <div className="card">
          <div className="card-header">
            {'Kartotékrendszer'}
          </div>
          <div className="row" style={cardStyle}>
            <div className="col-md-12">
              <KartotekrendszerList globals={props.route.globals} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KartotekrendszerBase;
