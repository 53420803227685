import React, { Component } from 'react';

class Keszlet extends Component {
  render() {
    return (
      <div className="animated fadeIn">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div className="card card-accent-primary">
              <div className="card-header">
                <h5>
                                Információ
                </h5>
              </div>
              <div className="card-block">
                          Kérem válasszon a bal oldali menüből!
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Keszlet;
