const tooltips = {
  munkalap: 'Munkalapok nyilvántartására és karbantartására szolgáló felület. A táblázatban a munkalap azonosító adatait és aktuális státuszát mutató adatok kérdezhetők le rögzítési dátum szerint csökkenő rendezéssel. A táblázatban kiválasztott munkalapokra nyomtatás indítható.',
  uj_munkalap: 'Munkalapok rögzítő felülete. Itt adhatók meg a munkalapok rögzítéséhez szükséges adatok. A munkalapok adattartalma változó attól függően, hogy a rendszer mely moduljában nyilvántartott adathoz kerül hozzákapcsolásra. Ez a feladat forrásának kiválasztásával szabályozható.',
  kiallito_adatai: 'A munkalapot kiállító cég adatai. A rögzítés feltétele a cégadat törzs megfelelő adatokkal történő feltöltése. Kiválasztása lenyíló listából.',
  partner_adatai: 'Megadása minden munkalap esetén kötelező. Értéke a partnertörzs adataiból emelhető át egy partner kiválasztásán keresztül. A felhasználó legalább 3 karakter megadásával indíthat keresést vevő típusú partnerekre. A rögzítés során a munkalaphoz hozzárendelhető adatok esetén a megadott partner szűrőként funkcionál, emiatt a munkalap későbbi fázisaiban már nem módosítható.',
  feladat_valasztas: 'A munkalap fő paramétereit tartalmazza a munkalap következő blokkja, a feladat leírását, felelősét, szabad szöveges leírását.',
  feladat_vegrehajtoja: 'A munkalaphoz hozzárendelhető egy a rendszerben felhasználóként rögzített személy, mint annak felelőse.',
  uj_feladat: 'A felületen választhatók ki a megadott partnerre szűrve a rendszer más moduljaiban rögzített előzmények. A választási lehetőségeket a forrás mező tartalmazza, majd a Feladat mezőben választhatók ki azonosító számra szűrve a munkalap előzménye.\nA kiválasztással egyidejűleg az előzmény adatai is átemelésre kerülnek a munkalapra. Egy munkalaphoz egyszerre több azonos forrásból származó és többféle forrásból is hozzárendelhető előzmény.',
  mentes: 'Rögzített adatok mentése',
  munka_elkezd: 'A munkalap előzményeinek rögzítése menthető és a munkalaphoz kötődő teljesítési adatok megadásának megkezdése indítható a nyomógomb megnyomása után.',
  uj_cikk_foglalasa: 'Ezen a felületen rögzíthetők a munkalap által meghatározott feladathoz szükséges cikkek és azoknak a paraméterei (mennyiség, árazás). A rögzítés során a rendszerben nyilvántartott készletinformációk alapján kiválaszthatók azok a raktárak is, amelyekből a készletek tényleges kiadása meg fog történni.',
  alairas: 'A munkalap ellátható aláírással érintőképernyő, vagy egér segítségével.',
  cikk_valszto: 'A legördülő listában a cikkek két csoportban jelennek meg. Az egyik a munkalap forrásai által meghatározott cikkek, a másik csoport maga a rendszer cikktörzse.',
  kapcs_dok: 'A felhasználó digitális dokumentumokat csatolhat a munkalaphoz.',
  munka_befejez: 'A munkalap rögzítésének lezárása.'
};

export default function getTooltip(name) {
  return tooltips[name];
}
