import Lib from '../../commons/lib';

export default class Service {
  static feladatlistaUrl = window.location.origin + '/api/feladatlista/';

  static partnerUrl = window.location.origin + '/api/partner/';

  static telepulesUrl = window.location.origin + '/api/telepules/';

  static karbantartasUrl = window.location.origin + '/api/karbantartas/';

  // ÁLLAPOT START

  static listAllapot(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.feladatlistaUrl + 'allapot/list',
      args: { requestType: 'allapot-list' }
    }], fnDone);
  }

  static getAllapot(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.feladatlistaUrl + 'allapot/get',
      args: { requestType: 'allapot-get' },
      params: { id: id }
    }], fnDone);
  }

  static saveTipusAllapot(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.feladatlistaUrl + 'allapot/save',
      data: data,
    }], fnDone);
  }

  static deleteAllapot(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.feladatlistaUrl + 'allapot/delete',
      args: { requestType: 'allapot-delete' },
      params: { id: id }
    }], fnDone);
  }

  // ÁLLAPOT END

  // FELADATTÍPUS START

  static getFeladattipusList(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.feladatlistaUrl + 'tipus/list',
      args: { requestType: 'tipus-list' }
    }], fnDone);
  }

  static getFeladattipus(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.feladatlistaUrl + 'tipus',
      args: { requestType: 'tipus' },
      params: { id: id }
    }], fnDone);
  }

  static initTipusForm(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.feladatlistaUrl + 'tipus/init',
      args: { requestType: 'tipus-init' },
      params: { id: id }
    }], fnDone);
  }

  static createFeladattipus(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.feladatlistaUrl + 'tipus/save',
      args: { requestType: 'tipus-post' },
      data: data,
    }], fnDone);
  }

  static updateFeladattipus(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.feladatlistaUrl + 'tipus/save',
      args: { requestType: 'tipus-update' },
      data: data,
    }], fnDone);
  }

  static deleteFeladatTipus(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.feladatlistaUrl + 'tipus/delete',
      args: { requestType: 'tipus-delete' },
      params: { id: id },
    }], fnDone);
  }

  // FELADATTÍPUS END

  // FELADATLISTA START

  static getFeladat(id, copy, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.feladatlistaUrl + 'feladat/view',
      params: {
        id: id,
        copy: copy
      }
    }], fnDone);
  }

  static getFeladatList(filters, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.feladatlistaUrl + 'feladat/list',
      data: filters
    }], fnDone);
  }

  static getFeladatListInit(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.feladatlistaUrl + 'feladat/init/list'
    }], fnDone);
  }

  static getFeladatAllapotok(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.feladatlistaUrl + 'feladat/list/allapot',
      params: {
        id
      }
    }], fnDone);
  }

  static saveFeladatAllapot(fid, tid, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.feladatlistaUrl + 'feladat/save/allapot',
      params: {
        fid,
        tid
      }
    }], fnDone);
  }

  static insertFeladat(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.feladatlistaUrl + 'feladat/save',
      data: data
    }], fnDone);
  }

  static updateFeladat(data, isVegleges, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.feladatlistaUrl + 'feladat',
      data: data,
      params: { end: isVegleges }
    }], fnDone);
  }

  static deleteFeladat(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.feladatlistaUrl + 'feladat/delete',
      params: { id: id }
    }], fnDone);
  }

  static endFeladat(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.feladatlistaUrl + 'feladat/befejez',
      params: { id: id }
    }], fnDone);
  }

  static initForm(feladatListaId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.feladatlistaUrl + 'feladat/init/form',
      params: {
        id: feladatListaId
      }
    }], fnDone);
  }

  static searchPartner(nev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'partner-search' },
      params: {
        type: 'FELADATLISTA_UJ_FELADAT_FORM',
        text: nev
      }
    }], fnDone);
  }

  static searchPartnerList(nev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'partner-search' },
      params: {
        type: 'FELADATLISTA_FELADAT_ELO',
        text: nev
      }
    }], fnDone);
  }

  static listOrszag(name, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'orszag/list',
      params: { name: name }
    }], fnDone);
  }

  static listIranyitoszam(nev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'telepules/irsz',
      params: { telepulesnev: nev }
    }], fnDone);
  }

  static listTelepules(irszam, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'telepules',
      params: { iranyitoszam: irszam }
    }], fnDone);
  }

  static listCim(text, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'telepules/like',
      params: { text: text }
    }], fnDone);
  }

  // tipus = true : cikk, false = karbantartott cikk
  static searchCikkOrKarbantartottCikk(tipus, nev, pid, fnDone) { // cikk vagy karbantartott cikk keresése a feladatlistában
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.karbantartasUrl + 'search/feladat',
      params: {
        tipus: tipus,
        nev: nev,
        pid: pid,
      }
    }], fnDone);
  }

  static searchEllenorzesiListaByCikkId(cikkid, fnDone) { // cikk kiválasztása után hozzá tartozó ellenőrzési listák kiválasztása
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.karbantartasUrl + 'elllista/listbycikkid',
      params: { id: cikkid }
    }], fnDone);
  }

  static modifyFeladatListaBefejez(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.feladatlistaUrl + 'feladat/befejez',
      params: { id: id }
    }], fnDone);
  }

  // FELADATLISTA END
}
