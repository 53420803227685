import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Collapse } from 'reactstrap';
import BankfejTable from './BankfejTable';
import BanktetelTable from './BanktetelTable';
import BanktetelAnalitikaTable from './BanktetelAnalitikaTable';

class PenzugyiteljesitesMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseBankfej: true,
      collapseBanktetel: false,
      collapseBanktetelAnalitika: false,
      tableBankfejData: null,
      tableBankkivonatData: null,
      refreshfej: false,
      tableStatus: {
        fej: 'LOADED',
        tranzakcio: 'LOADED',
        tetel: 'LOADED'
      }
    };
  }

  tableStatusChange = (obj) => {
    this.setState({
      tableStatus: obj,
    });
  }

  changeBankfejData = (obj) => {
    this.setState({
      tableBankfejData: obj,
      collapseBanktetel: true
    });
  }

  changeBankkivonatTetel = (obj) => {
    this.setState({
      tableBankkivonatData: obj,
      collapseBanktetelAnalitika: true
    });
  }

  // clearlv1Data

  refreshTranzakcio = () => {
    const bankfejData = JSON.parse(JSON.stringify(this.state.tableBankfejData));

    this.setState({
      tableBankfejData: { id: null, bankszamlaid: null }
    }, () => {
      this.setState({
        tableBankfejData: bankfejData
      });
    });
  }

  refreshFej = () => {
    this.setState({
      refreshfej: true
    }, () => {
      this.setState({
        refreshfej: false
      });
    });
  }

  collapseBankfej = () => {
    this.setState({
      collapseBankfej: !this.state.collapseBankfej,
    });
  }

  collapseBanktetel = () => {
    this.setState({
      collapseBanktetel: !this.state.collapseBanktetel
    });
  }

  collapseBanktetelAnalitika = () => {
    this.setState({
      collapseBanktetelAnalitika: !this.state.collapseBanktetelAnalitika
    });
  }

  closeBanktetelAnalitika = () => {
    this.setState({
      collapseBanktetelAnalitika: false
    });
  }

  closeBanktetel = () => {
    this.setState({
      collapseBanktetel: false
    });
  }

  render() {
    return (
      <div>
        <Button
          className="szerzodes-collapse-buttons"
          color="success"
          onClick={this.collapseBankfej}
        >
          Bankkivonat fej {this.state.collapseBankfej ? '↑' : '↓' }
        </Button>
        <br />
        <Collapse isOpen={this.state.collapseBankfej}>
          <BankfejTable
            changeData={this.changeBankfejData}
            globals={this.props.globals}
            refresh={this.state.refreshfej}
            tableStatus={this.state.tableStatus}
            tableStatusChange={this.tableStatusChange}
            closeBanktetelAnalitika={this.closeBanktetelAnalitika}
            closeBanktetel={this.closeBanktetel}
          />
        </Collapse>
        <Button
          className="szerzodes-collapse-buttons"
          color="success"
          onClick={this.collapseBanktetel}
        >
          Bankkivonat tranzakció {this.state.collapseBanktetel ? '↑' : '↓' }
        </Button>
        <br />
        <Collapse isOpen={this.state.collapseBanktetel}>
          <BanktetelTable
            changeData={this.changeBankkivonatTetel}
            globals={this.props.globals}
            tableData={this.state.tableBankfejData}
            refreshFej={this.refreshFej}
            tableStatus={this.state.tableStatus}
            tableStatusChange={this.tableStatusChange}
          />
        </Collapse>
        <Button
          className="szerzodes-collapse-buttons"
          color="success"
          onClick={this.collapseBanktetelAnalitika}
        >
          Bankkivonat tétel {this.state.collapseBanktetelAnalitika ? '↑' : '↓' }
        </Button>
        <br />
        <Collapse isOpen={this.state.collapseBanktetelAnalitika}>
          <BanktetelAnalitikaTable
            globals={this.props.globals}
            tableData={this.state.tableBankkivonatData}
            tableStatus={this.state.tableStatus}
            tableStatusChange={this.tableStatusChange}
            refreshFej={this.refreshFej}
            refreshTranzakcio={this.refreshTranzakcio}
          />
        </Collapse>
      </div>
    );
  }
}

PenzugyiteljesitesMenu.propTypes = {
  globals: PropTypes.object
};

export default PenzugyiteljesitesMenu;
