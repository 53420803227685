import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button
} from 'reactstrap';
import UzenetItem from './UzenetItem';
import UzenetDetailedItem from './UzenetDetailedItem';
import Loader from '../../../../commons/loader';
import Services from '../../services';
import Lib from '../../../../commons/lib';

class UzenetClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: this.getMessageData(),
      messageLoading: true,
      messageData: [],
      selectedMails: [],
      modalTorles: false,
      messageNumber: 0,
      unreadMessageNumber: 0,
      currentPage: 0,
      currentMessageTitle: '',
      // 'ALL', 'UNREAD', 'DETAILED'
      type: 'ALL',
      // 'ALL', 'UNREAD', 'DETAILED'
      prevType: 'ALL',
    };
  }

  componentDidMount() {
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.data) {
      return;
    }

    if (this.props.data !== nextProps.data) {
      this.setState({
        data: nextProps.data,
        messageNumber: nextProps.data.length,
        unreadMessageNumber: nextProps.data.filter(this.checkUnread).length
      });
    }

    if (this.props.loading !== nextProps.loading) {
      this.setState({
        loading: nextProps.loading,
      });
    }
  }

  getMessageData() {
    const { data } = this.props;
    let result = data;

    this.setState({
      messageNumber: result.length,
      unreadMessageNumber: result.filter(this.checkUnread).length
    }, () => {
      return result;
    });

    return [];
  }

  renderData = () => {
    if (this.state.data.length) {
      if (this.state.type === 'ALL') {
        return this.state.data.map((item) => {
          return (<UzenetItem
            key={item.id}
            user={item.fromSzemelyNev}
            userSec={item.fromUserName}
            unread={!item.megnyitasidopont}
            title={item.subject}
            description={item.body}
            date={item.kuldesidopont}
            id={item.id}
            onUzenetClick={() => this.onUzenetClick(item.subject, item.id)}
          />
          );
        });
      }
      if (this.state.type === 'UNREAD') {
        return this.state.data.filter(this.checkUnread).map((item) => {
          return (<UzenetItem
            key={item.id}
            user={item.fromSzemelyNev}
            userSec={item.fromUserName}
            unread={!item.megnyitasidopont}
            title={item.subject}
            description={item.body}
            date={item.kuldesidopont}
            id={item.id}
            onUzenetClick={() => this.onUzenetClick(item.subject, item.id)}
          />
          );
        });
      }
      if (this.state.type === 'DETAILED') {
        return (
          <UzenetDetailedItem
            data={this.state.messageData}
            loading={this.state.messageLoading}
          />
        );
      }
      return 'Hibás könyvtár';
    }
    return (
      <p style={{ margin: 20 }}>
        {'Nincs beérkezett üzenete!'}
      </p>
    );
  }

  onBeerkezettClick = () => {
    this.setState({ type: 'ALL' });
  }

  onOlvasatlanClick = () => {
    this.setState({ type: 'UNREAD' });
  }

  onUzenetClick = (title, id) => {
    let selectedmails = [];
    selectedmails.push(id);
    this.setState(prevState => ({
      currentMessageTitle: title,
      selectedMails: selectedmails,
      type: 'DETAILED',
      prevType: 'ALL',
      messageLoading: true,
    }));
    Services.getLevelDetails(id, (err, res) => {
      if (!err) {
        this.setState(prevState => ({
          currentMessageTitle: title,
          type: 'DETAILED',
          prevType: 'ALL',
          messageLoading: false,
          messageData: res[0]
        }));
      }
    });
  }

  handleBackClick = () => {
    this.setState(prevState => ({
      type: prevState.prevType,
    }), () => {
      this.props.refreshCall();
    });
  }

  deleteMails = (mails) => {
    Services.deleteMails(mails, (err) => {
      if (!err) {
        this.toggleTorles();
        this.setState({
          type: 'ALL'
        }, () => {
          this.props.refreshCall();
        });
        this.props.globals.addSuccess('Üzenet(ek) törölve!');
      }
    });
  }

  checkUnread = (message) => {
    return !message.megnyitasidopont;
  }

  toggleTorles = () => {
    this.setState({
      modalTorles: !this.state.modalTorles
    });
  }

  render() {
    const disabledFeatures = false;
    return (
      <div>
        <div className="container-fluid" style={{ padding: 0 }}>
          <div id="ui-view">
            <div>
              <div className="animated fadeIn">
                <div className="email-app mb-4">
                  <nav>
                    {/*<a className="btn btn-danger btn-block" href="page-inbox-compose.html">Új Email</a>*/}
                    <ul className="nav">
                      <li className="nav-item email-link active" onClick={ () => {this.onBeerkezettClick()}} style={{ cursor: 'pointer' }}>
                        <a className="nav-link">
                          <i className="fa fa-inbox" />
                          {'Beérkezett'}
                          <span className="badge badge-info">{this.state.messageNumber}</span>
                        </a>
                      </li>
                      <li className="nav-item email-link" onClick={ () => {this.onOlvasatlanClick()}} style={{ cursor: 'pointer' }}>
                        <a className="nav-link">
                          <i className="fa fa-envelope" />
                          {'Olvasatlan'}
                          <span className="badge badge-danger">{this.state.unreadMessageNumber}</span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                  <main className="inbox">
                    <div className="toolbar">
                      { disabledFeatures && (
                      <React.Fragment>
                        <div className="btn-group">
                          <button className="btn btn-light" type="button">
                            <span className="fa fa-envelope" />
                          </button>
                          <button className="btn btn-light" type="button">
                            <span className="fa fa-star" />
                          </button>
                          <button className="btn btn-light" type="button">
                            <span className="fa fa-star-o" />
                          </button>
                          <button className="btn btn-light" type="button">
                            <span className="fa fa-bookmark-o" />
                          </button>
                        </div>
                        <div className="btn-group">
                          <button className="btn btn-light" type="button">
                            <span className="fa fa-mail-reply" />
                          </button>
                          <button className="btn btn-light" type="button">
                            <span className="fa fa-mail-reply-all" />
                          </button>
                          <button className="btn btn-light" type="button">
                            <span className="fa fa-mail-forward" />
                          </button>
                        </div>
                      </React.Fragment>
                      )}
                      <div className="btn-group">
                        {this.state.type === 'DETAILED' ? (
                          <React.Fragment>
                            <button className="btn btn-light" type="button" onClick={() => { this.handleBackClick(); }} style={{ cursor: 'pointer', backgroundColor: '#ffffff' }}>
                              <span className="fa fa-chevron-left" />
                            </button>
                          </React.Fragment>) : (
                            <button className="btn btn-light" type="button" onClick={this.props.refreshCall} style={{ cursor: 'pointer', backgroundColor: '#ffffff' }}>
                              <span className="fa fa-refresh" />
                            </button>)
                      }
                        <h5 style={{ paddingTop: 6 }}>
                          {this.state.type === 'ALL' ? 'Beérkezett' : null}
                          {this.state.type === 'UNREAD' ? 'Olvasatlan' : null}
                          {this.state.type === 'DETAILED' ? this.state.currentMessageTitle : null}
                        </h5>
                        {(this.state.type === 'DETAILED') && (Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'DASHBOARD_POSTALADA', 'KARB')) ? (
                          <React.Fragment>
                            <button
                              className="btn btn-light"
                              type="button"
                              style={{ cursor: 'pointer', backgroundColor: '#ffffff' }}
                              onClick={() => { this.toggleTorles(); }}
                            >
                              <span className="fa fa-trash-o" />
                            </button>
                          </React.Fragment>) : null
                      }
                      </div>
                      {this.state.type === 'DETAILED' ? null : (
                        <div className="btn-group float-right">
                          <button
                            type="button"
                            className="text-black btn btn-secondary"
                            style={{ backgroundColor: 'rgb(236, 239, 241)', marginRight: 1 }}
                          >
                            {this.state.type === 'ALL' ? '1 - ' + this.state.messageNumber + ' / ' + this.state.messageNumber : null}
                            {this.state.type === 'UNREAD' ? '1 - ' + this.state.unreadMessageNumber + ' / ' + this.state.unreadMessageNumber : null}
                          </button>
                          <button
                            className={'btn btn-light ' + (this.state.currentPage === 0 ? 'disabled' : '')}
                            type="button"
                          >
                            <span className="fa fa-chevron-left" />
                          </button>
                          <button className="btn btn-light disabled" type="button">
                            <span className="fa fa-chevron-right" />
                          </button>
                        </div>)}
                    </div>
                    <ul className="messages">
                      {this.state.loading ? <Loader style={{ paddingTop: 30 }} /> : this.renderData()}
                    </ul>
                  </main>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>Valóban törölni kívánja a kiválasztott üzenete(ke)t ?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.deleteMails(this.state.selectedMails)}>Igen</Button>
            <Button type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

UzenetClient.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  currentPage: PropTypes.number,
  sizePerPage: PropTypes.number,
  reloadButton: PropTypes.bool,
  refreshCall: PropTypes.func.isRequired
};

UzenetClient.defaultProps = {
  data: [],
  loading: false,
  currentPage: 0,
  sizePerPage: 5,
};

export default UzenetClient;
