import React, { Component } from 'react';
import { AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { Label } from 'reactstrap';

class Mezo extends Component {
  render() {
    return (
      <div className="col-md-4">
        <AvGroup>
          <Label for={this.props.mezonev}>{this.props.mezonev}:</Label>
          <AvInput name={this.props.mezonev} id={this.props.mezonev} value={this.props.tartalom} onChange={this.props.updateMeta} />
          <AvFeedback>Kötelező mező!</AvFeedback>
        </AvGroup>
      </div>
    );
  }
}

export default Mezo;
