import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import { browserHistory } from 'react-router';
import SelectAsync from 'react-select/lib/Async';
import Select from 'react-select';
import CikkSelect from '../CikkSelect.jsx';
import _ from 'lodash';
import { DatePicker, DatetimePicker } from '../../../../../commons/controls.js';
import { ProgressButton } from '../../../../../commons/ProgressButton';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { PaginationTotal } from '../../../../../commons/controls';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Service from '../services.js';
import moment from 'moment';
import Lib from '../../../../../commons/lib';
import NumberFormatter from '../../../../../commons/NumberFormatter';


const STYLE_ROW = {marginTop: '15px'};
class KeszletcsokkenesForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasRendelesModule: false,

      modalTorles: false,
      modalCikk: false,
      modalAccept: false,
      modalPartnerFelvitel: false,
      modalRendelesCikk: false,

      rendelesCikkSelected: [],

      formRaktar: null,
      formPenznem: null,
      formFizmod: null,
      formPartner: null,
      // checkForRendelesModule()-ból kivenni az alapérték állítást, ha más értéket is felvehet a mező/nem szükséges a default
      formFunkcioNev: null,
      formFunkcio: null,
      formMozgasTipus: null,
      formKulsoAzon: null,
      formMozgasDatum: new Date(),
      formFizetesihatarido: null,
      telepulesMezo: [],
      csokkenes: {},
      editobj: {},
      cikkek: [],

      formKeszletInfoRaktarak: [],
      formKeszletLoadingStatus: 'LOAD',
      mentesLoadingButton: false,
      szabadMennyiseg: null,
    };
    this.raktarAzonosito = 'KESZLET';
  }

  componentWillMount() {
    this.checkForRendelesModule();
    let params = new URL(window.location.href).searchParams;
    let id = params.get('id');
    if (this.props.route.modositas === 'true') {
      if (id) this.getData(id);
    } else {
      this.setState({
        csokkenes: {}
      });
    }
  }

  modosit = () => {
    browserHistory.push("".concat('/keszlet/keszletkezeles/keszletcsokkenes/felvetel'));
  }

  handleBackClick = () => {
    browserHistory.push("".concat('/keszlet/keszletkezeles?page=2'));
  }

  toggle = () => {
    this.setState(prevState => ({ modal: !prevState.modal }));
  }

  toggleTorles = () => {
    this.setState({ modalTorles: !this.state.modalTorles });
  }

  toggleAccept = () => {
    this.setState({ modalAccept: !this.state.modalAccept });
  }

  toggleRendelesCikk = () => {
    this.setState({ modalRendelesCikk: !this.state.modalRendelesCikk, rendelesCikkSelected: [] });
  }

  toggleCikk = () => {
    this.setState({ modalCikk: !this.state.modalCikk });
  }

  togglePartnerFelvitel = () => {
    this.setState({ modalPartnerFelvitel: !this.state.modalPartnerFelvitel, telepulesMezoInaktiv: true });
  }

  getData = (id) => {
    Service.getCsokkenes(id, (err, res) => {
      if (!err) {
        let csokkenes = res[0];
        this.setState({ 
          csokkenes: csokkenes, 
          cikkek: res[0].cikkek, 
          formKulsoAzon: res[0].kulsoAzon,
          formRaktar: res[0].raktar,
          formPartner: res[0].partner,
          formMozgasTipus: res[0].mozgasTipus,
          formMegjegyzes: res[0].megjegyzes,
          formMunkalapszam: res[0].munkalapszam,
          formFizetesihatarido: res[0].fizetesihatarido,
          formFizmod: res[0].fizmod,
          formMozgasDatum: res[0].mozgasDatum,
          formFunkcio: res[0].funkcioId && { id: res[0].funkcioId },
          formFunkcioNev: res[0].funkcio && res[0].funkcio.name
        }, () => {
          if (this.state.hasRendelesModule && res[0].partner) {
            Service.listRendelesCsokkenes(res[0].partner.id, res[0].funkcioId, (err2, res2) => {
              if (!err2) {
                this.setState({ funkcioOptions: res2[0], formFunkcio: res2[0].find(r => r.id == res[0].funkcioId) });
              }
            });
          }
        });
      }
    });
  }

  checkForRendelesModule = () => {
    Service.checkForRendelesModule((err, res) => {
      if (!err) {
        this.setState({ hasRendelesModule: res[0] == true });
        if (res[0]) {
          this.setState({ formFunkcioNev: 'VEVOI_RENDELES' });
        }
      }
    });
  }

  handleRendelesCikkSubmit = () => {
    const { formFunkcio, rendelesCikkSelected } = this.state;
    const rendelescikkek = formFunkcio.rendelescikkbeans.filter(rc => rendelesCikkSelected.includes(rc.id));
    let cikkekNew = [];
    rendelescikkek.forEach((rc) => {
      cikkekNew.push({ cikk: rc.cikk, mennyiseg: rc.mennyiseg, cikkid: rc.cikk.id, funkciotetelId: rc.id, funkciotetel: 'VEVOI_RENDELES_CIKK' });
    });
    let { cikkek } = this.state;
    cikkek.push(...cikkekNew);
    this.setState({ cikkek, rendelesCikkSelected: [], modalRendelesCikk: false });
  }

  save = (event, csokkenesObj) => {
    let submitType = document.activeElement.getAttribute('id');
    if(this.state.cikkek && this.state.cikkek.length === 0 && submitType !== 'save-and-close'){
      this.props.globals.addWarning('Nem lett cikk kiválasztva!');
    }

    let submitObj = {};
    submitObj.cikkek = this.state.cikkek.map((c) => { return { ...c, funkciotetel: typeof c.funkciotetel === 'string' ? c.funkciotetel : c.funkciotetel && c.funkciotetel.name }; });
    submitObj.mozgasDatum =  this.state.formMozgasDatum && moment(this.state.formMozgasDatum).format("YYYY-MM-DDTHH:mm:ss.SSSZZ");
    submitObj.partnerid = this.state.formPartner && this.state.formPartner.id;
    submitObj.raktarid = this.state.formRaktar && this.state.formRaktar.id;
    submitObj.fizmodId = this.state.formFizmod && this.state.formFizmod.id;
    submitObj.kulsoAzon = this.state.kulsoAzon;
    submitObj.fizetesihatarido = this.state.formFizetesihatarido;
    submitObj.megjegyzes = this.state.formMegjegyzes;
    submitObj.munkalapszam = this.state.formMunkalapszam;
    submitObj.mozgasTipus = this.state.formMozgasTipus && this.state.formMozgasTipus.id;
    if (this.state.hasRendelesModule && this.state.formFunkcio) {
      submitObj.funkcio = this.state.formFunkcioNev;
      submitObj.funkcioId = this.state.formFunkcio && this.state.formFunkcio.id;
    }

    if (submitType === 'save-and-close' && !this.state.modalAccept) {
      this._currentAcceptObj = csokkenesObj;
      this.toggleAccept();
      return;
    } else if (submitType === 'save-and-close' && this.state.modalAccept) {
      this.toggleAccept();
    }

    if (this.state.csokkenes && this.state.csokkenes.id) {
      this.editKeszlet(submitObj); 
    } else {
      this.addKeszlet(submitObj); 
    }
  }

  addKeszlet(csokkenesObj) {
    let submitType = document.activeElement.getAttribute('id');

    if (submitType === 'save-and-close') {
      this.setState({mentesLoadingButton: true}, () => {
        Service.acceptAddCsokkenes(csokkenesObj, (err, res) => {
          if (!err) {
            this.props.globals.addSuccess('Készletcsökkenési tétel véglegesítve!');
            browserHistory.push("".concat('/keszlet/keszletkezeles?page=2'));
            this.setState({mentesLoadingButton: false});
          }else{
            this.setState({mentesLoadingButton: false});
          }
        });
      });

    } else {
      this.setState({mentesLoadingButton: true}, () => {
        Service.addCsokkenes(csokkenesObj, (err, res) => {
          if (!err) {
            this.props.globals.addSuccess('Készletcsökkenési tétel hozzáadva!');
            browserHistory.push("".concat('/keszlet/keszletkezeles?page=2'));
            this.setState({mentesLoadingButton: false});
          }else{
            this.setState({mentesLoadingButton: false});
          }
        });
      });
    }
  }

  editKeszlet(csokkenesObj) {
    let submitType = document.activeElement.getAttribute('id');

    if (submitType === 'save-and-close') {
      this.setState({mentesLoadingButton: true}, () => {
        Service.acceptEditCsokkenes(csokkenesObj, this.state.csokkenes.id, (err, res) => {
          if (!err) {
            this.props.globals.addSuccess('Készletcsökkenési tétel módosítva és véglegesítve!');
            browserHistory.push("".concat('/keszlet/keszletkezeles?page=2'));
            this.setState({mentesLoadingButton: false});
          }else{
            this.setState({mentesLoadingButton: false});
          }
        });
      });
    } else {
      this.setState({mentesLoadingButton: true}, () => {
        Service.editCsokkenes(csokkenesObj, this.state.csokkenes.id, (err, res) => {
          if (!err) {
            this.props.globals.addSuccess('Készletcsökkenési tétel módosítva!');
            browserHistory.push("".concat('/keszlet/keszletkezeles?page=2'));
            this.setState({mentesLoadingButton: false});
          }else{
            this.setState({mentesLoadingButton: false});
          }
        });
      });
    }
  }

  loadMozgasTipusOptions = (input, callback) => {
    let leltarRole = Lib.KeyCloak.hasRoleSpec(this.props.route.globals.keycloak, ['KESZLETNYILV_LELTAR_KORREKCIO']);
    Service.listMozgastipusCsokkenes((err, res) => {
      if (!err) {
        let options = [];
        res[0]
          .filter(m => m.name.toLowerCase().includes(input.toLowerCase()))
          .filter(m => !m.name.includes("LELTAR") || (m.name.includes("LELTAR") && leltarRole))
          .forEach((item) => {
            options.push(item);
          });
        callback(options);
      }
    });
  }


  loadFizmodOptions = (input, callback) => {
    Service.listAktivFizetesimod((err, res) => {
      if (!err) {
        let options = [];
        res[0].filter(k => k.nev.toLowerCase().includes(input.toLowerCase())).forEach((item) => {
          options.push(item);
        });
        callback(options);
      }
    });
  }

  loadRaktarOptions = (input, callback) => {
    Service.listBizonylatRaktar(this.raktarAzonosito, null, (err, res) => {
      if (!err) {
        let options = [];
        res[0].filter(k => k.nev.toLowerCase().includes(input.toLowerCase())).forEach((item) => {
          options.push(item);
        });
        callback(options);
      }
    });
  }

  loadPartnerOptions = (text, callback) => {
    let newArray = [];
    if (text && text.length === 0) {
      callback(newArray);
      return;
    } 
    Service.queryPartner('KESZLET_VEVO_FORM', text, (err, res) => {
      if (!err) {
        res[0].forEach(item => {
          newArray.push(item);
        });
        callback(newArray);
      }
    });
  }

  loadKeszletInfo = () => {
    const { formRaktar, } = this.state;
    this.setState({ formKeszletLoadingStatus: 'LOAD' }, () => {
      Service.getRaktarList([this.state.selectedCikkId], formRaktar && formRaktar.id ? [formRaktar.id] : [], (err, res) => {
        if (!err) {
          this.setState({
            formKeszletInfoRaktarak: res[0],
            formKeszletLoadingStatus: 'DATA',
          });
        } else {
          this.setState({
            formKeszletInfoRaktarak: [],
            formKeszletLoadingStatus: 'NETW'
          });
        }
      });
    });
  }

  handleNewCikkClick = (id) => {
    this.setState({ editmode: false, editobj: undefined}, () => {
      this.toggleCikk();
    });
  }

  handleEditCikkClick = (id, row) => {
    this.setState({ editmode: true, editobj: row, selectedCikkId: id }, () => {
      this.toggleCikk();
    });
  }

  handleDeleteCikkClick = (cikkobj) => {
    this._currentDeleteObj = cikkobj;
    this.toggleTorles();
  }

  handleListItemClick = (raktarId) => {
    let newRaktarak = [];
    const raktarak = JSON.parse(JSON.stringify(this.state.formKeszletInfoRaktarak));

    raktarak.forEach((item) => {
      item.raktar.selected = item.raktarId === raktarId;
      newRaktarak.push(item);
    });
    this.setState({ formKeszletInfoRaktarak: newRaktarak });
  }

  onSaveKeszletInfo = (raktarId, szabadMennyiseg, raktarNev) => {
    this.setState({ szabadMennyiseg: szabadMennyiseg, formRaktar: { id: raktarId, nev: raktarNev }});
  }

  addRendelesFunkcioTetelId = (cikkObj) => {
    if (this.state.hasRendelesModule && this.state.formFunkcio) {
      let obj = this.state.formFunkcio.rendelescikkbeans.find(rc => rc.cikkid == cikkObj.cikkid);
      cikkObj.funkciotetelId = obj ? obj.id : null;
      cikkObj.funkciotetel = obj ? 'VEVOI_RENDELES_CIKK' : null;
    }
  }

  addCikk = (event, cikkObj) => {
    this.addRendelesFunkcioTetelId(cikkObj);
    this.setState({ cikkek: [...this.state.cikkek, cikkObj] , editobj: cikkObj}, () => this.toggleCikk());
  }

  editCikk = (event, cikkObj) => {
    // Funkciotétel átmásolása, ha nem változott a cikk, mert egyébként elveszne, mivel a Form nem tartalmazza a mezőt
    if (this.state.editobj && this.state.editobj.cikkid == cikkObj.cikkid) {
      cikkObj.funkciotetelId = this.state.editobj.funkciotetelId;
      cikkObj.funkciotetel = this.state.editobj.funkciotetel;
    } else {
      this.addRendelesFunkcioTetelId(cikkObj);
    }
    this.state.cikkek[this.state.cikkek.indexOf(this.state.editobj)] = cikkObj;
    this.setState({ cikkek: this.state.cikkek, editmode: false, editobj: cikkObj }, () => this.toggleCikk());
  }

  deleteCikk = (cikkobj) => {
    const cikkek = this.state.cikkek.filter(cikk => cikk !== cikkobj);
    let formRaktar = cikkek.length > 0 ? this.state.formRaktar : {nev: '', id: null};
    this.setState({ formRaktar, cikkek}, () => this.toggleTorles());
  }

  checkDate = (date) => {
    return date ? new Date(date) : new Date();
  }

  handleDateInputChange = (e, v, name) => {
    const value = v === '' ? null : v;
    this.setState({
      [name]: value
    });
  }

  handleInputChange = (e) => {
    const { target } = e;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [name]: value
    });
  }

  handlePartnerChange = (v) => {
    this.setState({ formPartner: v, formFunkcio: null });
    if (v && this.state.hasRendelesModule) {
      Service.listRendelesCsokkenes(v.id, null, (err, res) => {
        if (!err) {
          this.setState({ funkcioOptions: res[0] });
        }
      });
    } else {
      this.setState({ formFunkcio: null });
    }
  }

  isMozgasTipusEquals = (mozgasTipus) => {
    return this.state.formMozgasTipus && this.state.formMozgasTipus.id === mozgasTipus;
  }

  cikkIconFormatter = (cell, row) => {
    return (
      <div>
        <Button className="icon-pencil table-action-icon" onClick={() => this.handleEditCikkClick(cell, row)} />
        <Button className="icon-trash table-action-icon" onClick={() => this.handleDeleteCikkClick(row)} />
      </div>
    );
  }

  renderCikkTable = () => {
    const options = {
      sizePerPage: 5,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const columns = [
      {
        dataField: 'cikk.cikkszam',
        text: 'Cikkszám',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
          textAlign: 'right' 
        },
        sort: true
      },
      {
        dataField: 'cikk.megnevezes',
        text: 'Megnevezés',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
          textAlign: 'right'
        },
        sort: true
      },
      {
        dataField: 'mennyiseg',
        text: 'Mennyiség',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: NumberFormatter.styleTextAlignWhiteSpace,
        formatter: (cell, row) => NumberFormatter.formatterSpaceOsszeg(cell) + (row && row.cikk ? ' ' + row.cikk.mennyisegiEgyseg : ''),
        sort: true
      },
      {
        dataField: 'cikk.id',
        text: 'Műveletek',
        align: 'center',
        headerStyle: { textAlign: 'center' },
        formatter: this.cikkIconFormatter
      }
    ];
    return (
      <React.Fragment>
        <Button className="table-new-button" type="button" color="success" onClick={this.handleNewCikkClick}>Cikk kiválasztása</Button>
        <div className="animated fadeIn">
          <BootstrapTable
            bootstrap4
            keyField="cikk.id"
            data={this.state.cikkek}
            columns={columns}
            filter={filterFactory()}
            pagination={paginationFactory(options)}
            noDataIndication="Nincs megjelenítendő adat!"
          />
        </div>
      </React.Fragment>
    );
  }

  renderRendelesCikkTable = () => {
    const handleRowSelect = (row, isSelect) => {
      this.setState((previousState) => {
        let array = previousState.rendelesCikkSelected;
        if (isSelect) {
          array.push(row.id);
        } else {
          array = array.filter(id => id != row.id);
        }
        return { rendelesCikkSelected: array };
      });
    };

    const handleRowSelectAll = (isSelect, rows) => {
      this.setState((previousState) => {
        let array = previousState.rendelesCikkSelected;
        if (isSelect) {
          array = rows.map(cikk => cikk.id);
        } else {
          array = [];
        }
        return { rendelesCikkSelected: array };
      });
    };

    const options = {
      sizePerPage: 5,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      selected: this.state.rendelesCikkSelected,
      onSelect: handleRowSelect,
      onSelectAll: handleRowSelectAll
    };

    const filterCikkek = (list) => {
      if (!list) {
        return [];
      }
      const funkciotetelIdList = this.state.cikkek.map(c => c.funkciotetelId);
      return list.filter(rc => !funkciotetelIdList.includes(rc.id));
    };

    const columns = [
      {
        dataField: 'cikk',
        text: 'Cikk megnevezése (Cikkszám)',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
          textAlign: 'right'
        },
        sort: true,
        formatter: (cell) => {
          return cell.megnevezes + ' (' + cell.cikkszam + ')';
        }
      },
      {
        dataField: 'mennyiseg',
        text: 'Mennyiség\n(fennmaradó)',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        headerStyle: { whiteSpace: 'pre-line' },
        style: NumberFormatter.styleTextAlignWhiteSpace,
        formatter: (cell, row) => NumberFormatter.formatterSpaceOsszeg(cell) + ' ' + row.cikk.mennyisegiEgyseg,
        sort: true
      }
    ];
    return (
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={filterCikkek(this.state.formFunkcio && this.state.formFunkcio.rendelescikkbeans)}
        columns={columns}
        filter={filterFactory()}
        pagination={paginationFactory(options)}
        selectRow={selectRow}
      />
    );
  }

  renderForm = () => {
    return(
      <React.Fragment>
        <div className="row" style={STYLE_ROW}>
          <div className="col-md-4">
            <Label for="formRaktar">Raktár:</Label>
            <SelectAsync
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="formRaktar"
              id="formRaktar"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              onChange={v => this.setState({formRaktar: v})}
              loadOptions={_.debounce(this.loadRaktarOptions, 1000)}
              getOptionLabel={option => option.nev}
              getOptionValue={option => option.id}
              value={this.state.formRaktar}
              defaultOptions
              isClearable
            />
          </div>
          <div className="col-md-4">
            <Label for="formMozgasDatum">Mozgás dátuma:</Label>
            <DatetimePicker 
              name="formMozgasDatum"
              value={this.checkDate(this.state.formMozgasDatum)}
              onChange={(e, v) => this.handleDateInputChange(e, v, 'formMozgasDatum')}
              max={new Date()}
              required={this.isMozgasTipusEquals('SZAM')} />
          </div>
        </div>
        {!this.isMozgasTipusEquals('LELTAR_HIANY')
          && (
            <div style={STYLE_ROW}>
              <Label for="formPartner">Vevő partner:</Label>
              <div className="row">
                <div className="col-md-4 form-group">
                  <SelectAsync
                    styles={{
                      control: base => ({
                        ...base,
                        '&:focus': { borderColor: 'red' }, // border style on hover
                        border: '1px solid lightgray', // default border color
                        boxShadow: 'none', // no box-shadow
                        borderRadius: '0'
                      }),
                    }}
                    name="partnerId"
                    id="partnerId"
                    placeholder="Kezdjen el gépelni a kereséshez..."
                    noOptionsMessage={() => 'Nincs keresési eredmény'}
                    searchPromptText="Kezdjen el gépelni a kereséshez..."
                    loadingMessage={() => 'Keresés...'}
                    onChange={this.handlePartnerChange}
                    loadOptions={_.debounce(this.loadPartnerOptions, 1000)}
                    value={this.state.formPartner}
                    getOptionValue={option => option.id}
                    getOptionLabel={option => option.nev}
                    defaultOptions
                    isClearable
                  />
                </div>
                {this.state.hasRendelesModule
                  && (
                    <>
                      <div className="col-md-4">
                        <Input
                          type="select"
                          name="formFunkcioNev"
                          value={this.state.formFunkcioNev}
                          onChange={this.handleInputChange}
                          disabled={!this.state.formPartner}
                        >
                          <option value="VEVOI_RENDELES">Vevői rendelés</option>
                        </Input>
                      </div>
                      <div className="col-md-4">
                        <Select
                          styles={{
                            control: base => ({
                              ...base,
                              '&:focus': { borderColor: 'red' }, // border style on hover
                              border: '1px solid lightgray', // default border color
                              boxShadow: 'none', // no box-shadow
                              borderRadius: '0'
                            }),
                          }}
                          name="funkcioId"
                          id="funkcioId"
                          placeholder="Kezdjen el gépelni a kereséshez..."
                          noOptionsMessage={() => 'Nincs keresési eredmény'}
                          searchPromptText="Kezdjen el gépelni a kereséshez..."
                          loadingMessage={() => 'Keresés...'}
                          onChange={v => this.setState({ formFunkcio: v })}
                          options={this.state.funkcioOptions}
                          value={this.state.formFunkcio}
                          getOptionValue={option => option.id}
                          getOptionLabel={option => option && option.bizonylatBean && option.bizonylatBean.bizonylatszam}
                          isDisabled={!this.state.formPartner || this.state.cikkek.length !== 0}
                          isClearable
                        />
                      </div>
                    </>
                  )
                }
              </div>
              {this.state.hasRendelesModule
                && (
                  <div className="row">
                    <div className="col-md-2">
                      <Button
                        type="button"
                        color="primary"
                        hidden={!this.state.formFunkcio || this.state.formFunkcioNev !== 'VEVOI_RENDELES'}
                        onClick={this.toggleRendelesCikk}
                      >
                        Rendelés aktív tételei
                      </Button>
                    </div>
                  </div>
                )
              }
            </div>
          )
        }
        {this.isMozgasTipusEquals('ERTE')
          && (
            <div className="row" style={STYLE_ROW}>
              <div className="col-md-4">
                <Label for="formFizmod">Fizetési mód:</Label>
                <SelectAsync
                  styles={{
                    control: base => ({
                      ...base,
                      '&:focus': { borderColor: 'red' }, // border style on hover
                      border: '1px solid lightgray', // default border color
                      boxShadow: 'none', // no box-shadow
                      borderRadius: '0'
                    }),
                  }}
                  name="formFizmod"
                  id="formFizmod"
                  placeholder="Kezdjen el gépelni..."
                  noOptionsMessage={() => 'Nincs keresési eredmény'}
                  onChange={v => this.setState({ formFizmod: v })}
                  loadOptions={_.debounce(this.loadFizmodOptions, 1000)}
                  getOptionLabel={option => option.nev}
                  getOptionValue={option => option.id}
                  value={this.state.formFizmod}
                  defaultOptions
                  isClearable
                />
              </div>
              <div className="col-md-4">
                <Label for="formFizetesihatarido">Fizetési határidő:</Label>
                <DatePicker
                  name="formFizetesihatarido"
                  value={this.state.formFizetesihatarido ? new Date(this.state.formFizetesihatarido) : null}
                  onChange={(e, v) => this.handleDateInputChange(e, v, 'formFizetesihatarido')}
                  required={this.isMozgasTipusEquals('ERTE')}
                />
              </div>
            </div>
          )
        }
        <div className="row" style={STYLE_ROW}>
          {this.isMozgasTipusEquals('FELH')
            && (
              <div className="col-md-4">
                <AvGroup>
                  <Label for="formMunkalapszam">Munkalap száma:</Label>
                  <AvInput
                    name="formMunkalapszam"
                    id="formMunkalapszam"
                    value={this.state.formMunkalapszam}
                    onChange={this.handleInputChange}
                  />
                  <AvFeedback>Kötelező mező!</AvFeedback>
                </AvGroup>
              </div>
            )
          }
        </div>
        <div className="row" style={STYLE_ROW}>
          <div className="col-md-4">
            <AvGroup>
              <Label for="formMegjegyzes">Megjegyzés:</Label>
              <AvInput 
                  name="formMegjegyzes" 
                  id="formMegjegyzes" 
                  value={this.state.formMegjegyzes}
                  onChange={this.handleInputChange} 
                />
            </AvGroup>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderModals = () => {
    return(
      <React.Fragment>
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className} backdrop="static">
          <ModalHeader >Törlés</ModalHeader>
          <ModalBody>Valóban törölni kívánja a kiválasztott tételt?</ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteCikk(this._currentDeleteObj)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalAccept} toggle={this.toggleAccept} className={this.props.className} backdrop="static">
          <ModalHeader>Mentés és véglegesítés</ModalHeader>
          <ModalBody>„Mentés és véglegesítés" után az adott tétel nem módosítható, törölhető, csak stornózható! Valóban elfogadja rögzített adatok mentését?</ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" id="save-and-close" onClick={() => this.save(null, this._currentAcceptObj)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleAccept}>Mégsem</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalRendelesCikk} toggle={this.toggleRendelesCikk} className={this.props.className} backdrop="static">
          <ModalHeader>Cikkek választása</ModalHeader>
          <ModalBody>
            {this.renderRendelesCikkTable()}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" type="button" color="primary" onClick={this.handleRendelesCikkSubmit}>Kiválasztás</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleRendelesCikk}>Mégse</Button>
          </ModalFooter>
        </Modal>

        <CikkSelect
          isOpen={this.state.modalCikk}
          toggle={this.toggleCikk}
          add={this.addCikk}
          cikkek={this.state.cikkek}
          editmode={this.state.editmode}
          editobj={this.state.editobj}
          edit={this.editCikk}
          mozgasTipus={this.state.formMozgasTipus}
          raktar={this.state.formRaktar}
          formKeszletInfoRaktarak={this.state.formKeszletInfoRaktarak}
          formKeszletLoadingStatus={this.state.formKeszletLoadingStatus}
          getData={this.loadKeszletInfo}
          handleListItemClick={this.handleListItemClick}
          selectedCikkId={this.state.selectedCikkId}
          changeCikkId={cikkid => this.setState({ selectedCikkId: cikkid})}
          price={false}
          keszletinfo
          globals={this.props.globals}
          selectedSzabad={this.state.szabadMennyiseg}
          onSave={this.onSaveKeszletInfo}
        />
      </React.Fragment>
    );
  }

  renderButtons = () => {
    return(
      <div className="top-15-margin">
        <ProgressButton color="primary" id="save" loading={this.state.mentesLoadingButton}>Mentés</ProgressButton>{' '}
        <ProgressButton color="primary" id="save-and-close" loading={this.state.mentesLoadingButton} >Mentés és véglegesítés</ProgressButton>{' '}
        <Button type="button" color="secondary" onClick={this.handleBackClick}>Mégse</Button>
      </div>
    );
  }

  render() {
    return (
      <div>
        <AvForm onValidSubmit={this.save}>
          <div className="row" style={STYLE_ROW}>
            <div className="col-md-8">
              <Label for="formMozgastipus">Mozgástípus:</Label>
              <SelectAsync
                styles={{
                  control: base => ({
                    ...base,
                    '&:focus': { borderColor: 'red' }, // border style on hover
                    border: '1px solid lightgray', // default border color
                    boxShadow: 'none', // no box-shadow
                    borderRadius: '0'
                  }),
                }}
                name="formMozgastipus"
                id="formMozgastipus"
                placeholder="Kezdjen el gépelni..."
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                onChange={v => this.setState({formMozgasTipus: v })}
                loadOptions={_.debounce(this.loadMozgasTipusOptions, 1000)}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                value={this.state.formMozgasTipus}
                isDisabled={!!this.state.csokkenes.id}
                defaultOptions
                isClearable
                />
            </div>
          </div>
          {this.state.formMozgasTipus && this.renderForm()}
          {this.state.formMozgasTipus && this.renderCikkTable()}
          {this.renderButtons()}
        </AvForm>
        {this.renderModals()}
      </div>
    );
  }
}

export default KeszletcsokkenesForm;
