import React from 'react';
import GDPRLista from './GDPRLista';

const cardStyle = {
  padding: 20
};

const GDPRBase = (props) => {
  return (
    <div className="animated fadeIn">
      <div className="card">
        <div className="card-header">
          GDPR
        </div>
        <div className="row" style={cardStyle}>
          <div className="col-md-12">
            <GDPRLista
              globals={props.route.globals}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GDPRBase;
