import React, { Component } from 'react';
import _ from 'lodash';
import { Label, Button } from 'reactstrap';
import { AvForm, AvGroup, AvInput } from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import BootstrapTable2 from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import Select from 'react-select';
import Excel from 'exceljs';
import FileSaver from 'file-saver';
import moment from 'moment';

import Service from './services';
import { PaginationTotal } from '../../../commons/controls';
import QueryEmptyState from '../../../commons/queryempty';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import Loader from '../../../commons/loader';
import NumberFormatter from '../../../commons/NumberFormatter';
import CustomTooltip from '../../../components/Tooltip/CustomTooltip';
import getTooltip from '../KeszletTooltips';

class KeszletinformacioLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cikkek: [],
      raktarakJson: [],
      raktarak: [],
      cikk: null,
      raktar: null,
      id: null,
      cikkszam: '',
      megnevezes: '',
      mennyisegiEgyseg: '',
      foglalasok: null,
      queryText: '',
      raktarFormStatus: 'LOAD', // [LOAD', NETW', 'LIST', 'DATA', 'QUERY']
      foglalasFormStatus: 'LOAD', // [LOAD', NETW', 'LIST', 'DATA', 'QUERY']
    };

    this.options = {
      sizePerPage: 5,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ]
    };
  }

  componentDidMount() {
    this.listRaktar();
  }

  listRaktar = () => {
    Service.queryRaktar('', (err, res) => {
      if (!err) {
        this.setState({
          raktarakJson: res[0]
        });
      }
    });
  }

  handleInputChangeCikkek = (value, { action }) => {
    switch (action) {
      case 'input-change':
        if (value && value.length > 0) {
          Service.queryCikk(value, false, false, (err, res) => {
            if (!err) {
              this.setState({
                cikkek: res[0]
              });
            }
          });
        }
        break;
      default:
    }
  }

  handleCikkChange = (newValue) => {
    const emptyCikk = {
      cikkszam: '',
      megnevezes: '',
      mennyisegiEgyseg: ''
    }
    if (!newValue || newValue.length == 0) {
      this.setState({ cikk: emptyCikk, raktarak: [], foglalasok: null, raktarFormStatus: 'DATA' });
    } else {
      this.setState({ cikk: newValue });
    }
  }

  handleRaktarChange = (newValue) => {
    const emptyRaktar = {};
    if (!newValue || newValue.length == 0) {
      this.setState({ raktar: emptyRaktar, raktarak: [], foglalasok: null, raktarFormStatus: 'DATA' });
    } else {
      this.setState({ raktar: newValue });
    }
  }

  loadData = () => {
    let cikkids = [];
    let raktarids = [];
    if (this.state.cikk)
      cikkids.push(this.state.cikk.id);
    if (this.state.raktar)
      raktarids.push(this.state.raktar.id);
      let jo = false;
      for (let i = 0; i < cikkids.length; i++) {
        if (cikkids[i]) {
          jo = true;
          break;
        }
      }
      if (!jo) {
        for (let i = 0; i < raktarids.length; i++) {
          if (raktarids[i]) {
            jo = true;
            break;
          }
        }
      }
    if (!jo) {
      this.setState({ raktarFormStatus: 'QUERY'});
    } else {
      Service.getRaktarList(cikkids, raktarids, (err, res) => {
        if (!err) {
          let raktarFormStatus;
          if (res[0].length > 0) raktarFormStatus = 'LIST'; else raktarFormStatus = 'DATA';
          this.setState({ raktarak: res[0], foglalasok: null, raktarFormStatus }, () => {
          });
        } else {
          this.setState({ raktarFormStatus: 'NETW' });
        }
      });
    }
  }

  onRowSelect = (nyilv) => {
    if (nyilv) {
      Service.getFoglalasok(nyilv.cikkid, nyilv.raktar.id, (err, res) => {
        if (!err) {
          let foglalasFormStatus;
          if (res[0].length > 0) foglalasFormStatus = 'LIST'; else foglalasFormStatus = 'DATA';
          this.setState({ foglalasok: res[0], foglalasFormStatus });
        } else {
          this.setState({ foglalasFormStatus: 'NETW' });
        }
      });
    }
  }

  todayDate = () => {
    let date = new Date();
    let month = date.getMonth() + 1;
    return date.getFullYear() + '_' + (month < 10 ? '0' + month : month) + '_' + date.getDate();
  }

  mozgastipusFormatter = (mozgastipus) => {
    switch (mozgastipus) {
      case 'MUNKALAP': return 'Munkalap';
      case 'VEVOI_RENDELES': return 'Vevői rendelés';
      case null: return 'Készletcsökkenés';
      default: return 'Ismeretlen';
    }
  }

  renderTable = () => {
    const selectRowProp = {
      mode: 'radio',
      onSelect: this.onRowSelect
    };

    const columns = [
      {
        dataField: 'raktarNev',
        text: 'Raktár',
        filter: textFilter({
          delay: 400,
          placeholder: 'Keresés...'
        }),
        sort: true,
        csvText: 'Raktar'
      },
      {
        dataField: 'cikkNev',
        text: 'Cikk',
        filter: textFilter({
          delay: 400,
          placeholder: 'Keresés...'
        }),
        sort: true
      },
      {
        dataField: 'teljesMennyiseg',
        text: 'Teljes készlet',
        csvText: 'Teljes keszlet',
        formatter: NumberFormatter.formatterSpaceOsszeg,
        style: NumberFormatter.styleTextAlign
      },
      {
        dataField: 'foglaltMennyiseg',
        text: 'Foglalt készlet',
        csvText: 'Foglalt keszlet',
        formatter: NumberFormatter.formatterSpaceOsszeg,
        style: NumberFormatter.styleTextAlign
      },
      {
        dataField: 'szabadMennyiseg',
        text: 'Szabad készlet',
        csvText: 'Szabad keszlet',
        formatter: NumberFormatter.formatterSpaceOsszeg,
        style: NumberFormatter.styleTextAlign
      }
    ];

    return (
      <div className="animated fadeIn">
        <button
          type="button"
          className="btn btn-success"
          style={{ marginBottom: 10 }}
          onClick={() => {
            const datas = JSON.parse(JSON.stringify(this.lista.filterContext.data));
            let xlsDatas = [];
            datas.forEach((element) => {
              xlsDatas.push([
                element.raktar.nev, element.cikkNev, NumberFormatter.addSpacesToString(element.teljesMennyiseg, NumberFormatter.OSSZEG), NumberFormatter.addSpacesToString(element.foglaltMennyiseg, NumberFormatter.OSSZEG), NumberFormatter.addSpacesToString(element.szabadMennyiseg, NumberFormatter.OSSZEG)
              ]);
            });

            const wb = new Excel.Workbook();
            const ws = wb.addWorksheet('Készletinformáció');

            ws.columns = [
              { key: 'raktar', width: 35 },
              { key: 'cikk', width: 35 },
              { key: 'teljes', width: 20, style: { alignment: { horizontal: 'right' }}},
              { key: 'foglalt', width: 20, style: { alignment: { horizontal: 'right' }}},
              { key: 'szabad', width: 20, style: { alignment: { horizontal: 'right' }}},
            ];

            ws.addTable({
              name: 'Táblázat',
              ref: 'A1',
              headerRow: true,
              style: {
                theme: 'TableStyleLight1',
                showRowStripes: true,
              },
              columns: [
                { name: 'Raktár', filterButton: true },
                { name: 'Cikk', filterButton: true },
                { name: 'Teljes készlet', filterButton: true },
                { name: 'Foglalt készlet', filterButton: true },
                { name: 'Szabad készlet', filterButton: true },
              ],
              rows: xlsDatas,
            });
            ws.getRow(1).alignment = 'left';
            if (datas.length > 0) {
              wb.xlsx.writeBuffer()
                .then(buffer => FileSaver.saveAs(new Blob([buffer]), 'keszletinfo_' + this.todayDate() + '.xlsx'))
                .catch();
            }
          }}
        >
          Export xlsx
        </button>
        <BootstrapTable2
          bootstrap4
          ref={((c) => { this.lista = c; })}
          keyField="id"
          filter={filterFactory()}
          data={this.state.raktarak}
          columns={columns}
          pagination={paginationFactory(this.options)}
          selectRow={selectRowProp}
        />
      </div>
    );
  }

    renderTablefoglalasok = () => {
      const columns = [
        {
          dataField: 'funkcio.caption',
          text: 'Funkció',
        },
        {
          dataField: 'mennyiseg',
          text: 'Mennyiség',
          formatter: NumberFormatter.formatterSpaceOsszeg,
          style: NumberFormatter.styleTextAlign
        },
        {
          dataField: 'cikk',
          text: 'Mennyiségi egység',
          formatter: c => c.mennyisegiEgyseg
        },
        {
          dataField: 'mozgasDatum',
          text: 'Foglalás dátuma',
          formatter: d => moment(d).format('YYYY-MM-DD HH:mm:ss')
        },
      ];

      return (
        <div className="animated fadeIn">
          <BootstrapTable2
            bootstrap4
            keyField="id"
            data={this.state.foglalasok}
            columns={columns}
            pagination={paginationFactory(this.options)}
          />
        </div>
      );
    }


    render() {
      return (
        <div className="animated fadeIn">
          <div className="card">
            <div className="card-header">
              Készletinformáció
              <CustomTooltip text={getTooltip('keszletinformacio')} id="keszletinformacioTT" />
            </div>
            <AvForm>
              <div className="row" style={{ paddingLeft: 20, paddingTop: 20, paddingRight: 20 }}>
                <div className="col-md-8" >
                  <Label for="raktarnev">
                    <h6>Raktár keresés:</h6>
                  </Label>
                  <Select
                    styles={{
                      control: (base) => ({
                        ...base,
                        '&:focus': { borderColor: 'red' },
                        border: '1px solid lightgray',
                        boxShadow: 'none',
                        borderRadius: '0'
                      }),
                    }}
                    name="raktarnev"
                    id="raktarnev"
                    noOptionsMessage={() => "Nincs keresési eredmény"}
                    searchPromptText="Kezdjen el gépelni a kereséshez..."
                    placeholder="Kezdjen el gépelni a kereséshez..."
                    loadingMessage={() => "Keresés..."}
                    isClearable
                    ignoreAccents={false}
                    isLoading={false}
                    onChange={this.handleRaktarChange}
                    options={this.state.raktarakJson}
                    getOptionValue={option => option}
                    getOptionLabel={option => option.nev}
                  />
                </div>
              </div>
              <div className="row" style={{ padding: 20 }}>
                <div className="col-md-8" >
                  <Label for="cikknev">
                    <h6>Cikk keresés:</h6>
                  </Label>
                  <Select
                    styles={{
                      control: (base) => ({
                        ...base,
                        '&:focus': { borderColor: 'red' },
                        border: '1px solid lightgray',
                        boxShadow: 'none',
                        borderRadius: '0'
                      }),
                    }}
                    name="cikknev"
                    id="cikknev"
                    noOptionsMessage={() => "Nincs keresési eredmény"}
                    searchPromptText="Kezdjen el gépelni a kereséshez..."
                    placeholder="Kezdjen el gépelni a kereséshez..."
                    loadingMessage={() => "Keresés..."}
                    isClearable
                    ignoreAccents={false}
                    isLoading={false}
                    onInputChange={_.debounce(this.handleInputChangeCikkek, 1000)}
                    onChange={this.handleCikkChange}
                    options={this.state.cikkek}
                    getOptionValue={option => option}
                    getOptionLabel={option => option.megnevezes}
                  />
                </div>
              </div>
              <div className="row" style={{ padding: 20 }} >
                <div className="col-md-2">
                  <AvGroup>
                    <Label for="cikkszam">Cikk szám: </Label>
                    <AvInput disabled name="cikkszam" id="cikkszam" value={this.state.cikk ? this.state.cikk.cikkszam : ''} />
                  </AvGroup>
                </div>
                <div className="col-md-4">
                  <AvGroup>
                    <Label for="cikknev">Cikk megnevezése: </Label>
                    <AvInput disabled name="cikknev" id="cikknev" value={this.state.cikk ? this.state.cikk.megnevezes : ''} />
                  </AvGroup>
                </div>
                <div className="col-md-2">
                  <AvGroup>
                    <Label for="mennyiseg">Mennyiség egység: </Label>
                    <AvInput disabled name="mennyiseg" id="mennyiseg" value={this.state.cikk ? this.state.cikk.mennyisegiEgyseg : ''} />
                  </AvGroup>
                </div>
              </div>
              <div className="row" style={{ paddingLeft: 20 }}>
                <div className="col-md-12">
                  <Button type="button" color="primary" onClick={() => this.loadData()}>
                    Keresés
                  </Button>
                </div>
              </div>
            </AvForm>
            <div className="row" style={{ padding: 20 }}>
              <div className="col-md-12">
                <div>
                  {{
                    LIST: (this.renderTable()),
                    // LOAD:  (<Loader />),
                    NETW: (<NetworkEmptyState hasImage />),
                    DATA: (<DataEmptyState hasImage title="Nincs megjeleníthető adat!" subtitle="" />),
                    QUERY: (<QueryEmptyState hasImage />)
                  }[this.state.raktarFormStatus]}
                </div>
              </div>
            </div>
            <hr style={{ height: '10px', boreder: '10px' }} />
            {this.state.foglalasok &&
              <React.Fragment>
                <div className="row" style={{ paddingLeft: 20 }}>
                  <div className="col-md-2">
                    <h5>Foglalások</h5>
                  </div>
                </div>
                <div className="row" style={{ padding: 20 }}>
                  <div className="col-md-12">
                    <div>
                      {{
                        LIST: (this.renderTablefoglalasok()),
                        LOAD: (<Loader />),
                        NETW: (<NetworkEmptyState hasImage />),
                        DATA: (<DataEmptyState hasImage title="Nincs megjeleníthető adat!" subtitle="" />)
                      }[this.state.foglalasFormStatus]}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            }
          </div>
        </div>
      );
    }
}

export default KeszletinformacioLista;
