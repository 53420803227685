import React, { Component } from 'react';
import _ from 'lodash';
import {
  Label
} from 'reactstrap';
import {
  AvForm,
  AvField,
} from 'availity-reactstrap-validation';
import { ProgressButton } from '../../../commons/ProgressButton';
import Select from 'react-select/lib/Async';
import NetworkEmptyState from '../../../commons/networkemptystate';
import Loader from '../../../commons/loader';
import Services from './services';
import fileSaver from 'file-saver';
import moment from 'moment';
import NumberFormatter from '../../../commons/NumberFormatter';

class UdulesiJogListaContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      csakAktiv: null,
      jogtulajdonosValue: '',
      listButtonLoading: false,
      statusz: '',
      statuszEnum: [],
      szallodaValue: '',
      aktivSzallodak: [],
      // [LOAD', NETW', 'LIST']
      formStatus: 'LIST'
    };
  }

  componentDidMount() {
    this.getAktivSzallodak();
    this.getStatuszEnum();
  }

  onSubmit = () => {
    this.setState({ listButtonLoading: true }, () => {
      if (this.checkParam()) {
        Services.exportUdulesijogXLSX(this.state.csakAktiv, this.state.statusz, this.state.szallodaValue, this.state.jogtulajdonosValue != null ? this.state.jogtulajdonosValue.id : null, (err, res) => {
          this.setState({ listButtonLoading: false });
          if (!err) {
            this.props.globals.addSuccess('Üdülésijog lista XLSX letöltése...');
            fileSaver.saveAs(new Blob([res[0]], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', responseType: 'arraybuffer' }), 'udulesijoglista-' + moment().format('YYYY-MM-DD') + '.xlsx');
          }
        });
      } else {
        this.setState({ listButtonLoading: false });
      }
    });
  }

  checkParam = () => {
    if (this.state.jogtulajdonosValue === '' && (this.state.szallodaValue === '' || this.state.statusz === '')) {
      this.props.globals.addError("Legalább jogtulajdonos vagy szálloda és státusz szűrése kötelező!");
      return false;
    }
    return true;
  }

  getAktivSzallodak = () => {
    Services.listAktivSzallodak((err, res) => {
      if (!err) {
        let aktivSzallodak = res[0].map(e => <option key={e.id} value={e.id}>{e.szallodanev}</option>);
        this.setState({ aktivSzallodak: aktivSzallodak });
      }
    });
  }

  selectFeltetel = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({ csakAktiv: value === "true" ? true : value == "false" ? false : null });
  }

  handleChangeJogtulajdonos = (value) => {
    if (value && value.length !== 0) {
      this.setState({ jogtulajdonosValue: value });
    } else {
      this.setState({ jogtulajdonosValue: '' });
    }
  }

  loadJogtulajdonosChange = (input, callback) => {
    if (input.length !== 0) {
      Services.listUdulesijogTulaj('LISTA_UDULESIJOG_TULAJ_ELO', input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            item.nev += ' (' + item.cim + ') ';
            if (item.adoszam) {
              item.nev += '[' + NumberFormatter.addHyphensToString(item.adoszam) + '] ';
            }
            newArray.push(item);
          });
          callback(newArray);
        }
      });
    }
  }

  szallodaChange = (e) => {
    this.setState({
      szallodaHasValue: true, aktivEpuletek: [], szallodaValue: e.target.value, epuletValue: ''
    });
    this.getAktivEpuletById(e.target.value);
  }

  getStatuszEnum = () => {
    Services.getEnum((err, res) => {
      if (!err) {
        let resOptions = res[0].map(e => <option key={e.name} value={e.name}>{e.caption}</option>);
        this.setState({ statuszEnum: resOptions });
      }
    });
  }

  getAktivEpuletById = (id) => {
    Services.listAktivEpuletekById(id, (err, res) => {
      if (!err) {
        let aktivEpuletek = res[0].map(e => <option key={e.id} value={e.id}>{e.epuletnev}</option>);
        this.setState({ aktivEpuletek: aktivEpuletek });
      }
    });
  }

  selectStatusz = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({ statusz: value });
  }

  render() {
    return (
      <AvForm onValidSubmit={this.onSubmit}>
        {this.state.formStatus === 'LIST' ? (
          <div className="row">
            <div className="col-md-3">
              <AvField
                type="select"
                name="szallodaid"
                id="szallodaid"
                label="Szálloda: "
                errorMessage="Kötelező mező!"
                value={this.state.szallodaValue}
                onChange={this.szallodaChange}
              >
                <option value="">Válassz szállodát...</option>
                {this.state.aktivSzallodak}
              </AvField>
            </div>
            <div className="col-md-3">
              <Label for="jogtulajId">Jogtulajdonos:</Label>
              <Select
                styles={{
                  control: base => ({
                    ...base,
                    '&:focus': { borderColor: 'red' }, // border style on hover
                    border: '1px solid lightgray', // default border color
                    boxShadow: 'none', // no box-shadow
                    borderRadius: '0'
                  }),
                }}
                name="jogtulajId"
                id="jogtulajId"
                placeholder="Kezdjen el gépelni..."
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingMessage={() => 'Keresés...'}
                onChange={v => this.handleChangeJogtulajdonos(v)}
                isClearable
                ignoreAccents={false}
                loadOptions={_.debounce(this.loadJogtulajdonosChange, 1000)}
                isLoading={false}
                getOptionLabel={option => option.nev}
                getOptionValue={option => option.id}
                value={this.state.jogtulajdonosValue}
                cache={false}
                autoload={false}
              />
            </div>
            <div className="col-md-3">
              <AvField
                type="select"
                name="udulesijogstatusz"
                label="Üdülésijog státusz: "
                onChange={this.selectStatusz}
              >
                <option value="">
                  {'Válassz státuszt...'}
                </option>
                {this.state.statuszEnum}
              </AvField>
            </div>
            <div className="col-md-3">
              <AvField
                type="select"
                name="csakAktiv"
                label="Szűrési feltétel: "
                onChange={this.selectFeltetel}
              >
                <option value={null}>
                  {'Összes jogtulajdonos'}
                </option>
                <option value={true}>
                  {'Csak aktív fő jogtulajdonosok'}
                </option>
                <option value={false}>
                  {'Csak aktív jogtulajdonosok'}
                </option>
              </AvField>
            </div>
            <div className="col-md-12" />
            <div className="col-md-4">
              <ProgressButton
                color="primary"
                loading={this.state.listButtonLoading}
              >
                {'Lista futtatása'}
              </ProgressButton>
              {' '}
            </div>
          </div>
        ) : <span />}
        {this.state.formStatus === 'LOAD' ? <Loader /> : <span />}
        {this.state.formStatus === 'NETW' ? (
          <NetworkEmptyState
            hasImage
          />
        ) : <span />}
      </AvForm>
    );
  };
}

export default UdulesiJogListaContent;
