import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import fileSaver from 'file-saver';
import DataEmptyState from '../../commons/dataemptystate';
import { DatePicker, PaginationTotal } from '../../commons/controls';
import Loader from '../../commons/loader';
import NetworkEmptyState from '../../commons/networkemptystate';
import QueryEmptyState from '../../commons/queryempty';
import Services from './services';
import Lib from '../../commons/lib';
import moment from 'moment';
import NumberFormatter from '../../commons/NumberFormatter';
import SlaveAvInput from '../../commons/SlaveAvInput';

// 0 -> Felvitel
// 1 -> módosítás
let _muvelet = 0;

class BankfejTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      /*
       *  FLOW STATE CONTROLLER
       */
      // ['QUERY', 'LOAD', 'DATA', 'NETW', 'TABL']
      currentStatusBaseTable: 'QUERY',
      // ['LOAD', 'LOADED', 'NETW']
      currentStatusBaseEdit: 'LOADED',

      /*
       *  FORM SELECT OPTIONS
       */
      aktivSzamlak: [],
      aktivSzamlakForm: [],

      /*
       *  MODAL CONTROLLERS
       */
      modalBaseEdit: false,
      modalBaseFeladas: false,
      modalBaseDelete: false,

      /*
       *  FORM VALUES
       */
      elsoNyito: false,
      nyito: 0,
      jovairas: 0,
      terheles: 0,
      zaro: 0,
      sorszam: null,
      bankszamlaid: null,
      datum: null,

      eloszuresValues: {
        id: '',
        from: null,
        to: null
      },

      /*
       *  TABLE DATA
       */
      baseJson: [],
    };
  }

  componentDidMount() {
    this.getAktivSzamlak();
    this.getAktivSzamlakForm();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.refresh !== nextProps.refresh) {
      if (nextProps.refresh) {
        this.updateBaseTable();
      }
    }
  }

  onRowSelect = (row) => {
    this.props.tableStatusChange({
      fej: 'LOADED',
      tranzakcio: 'LOADED',
      tetel: 'RELOAD'
    });
    this.props.changeData({
      id: row.i,
      bankszamlaid: row.b
    });
    this.props.closeBanktetelAnalitika();
  }

  getAktivSzamlak = () => {
    Services.listSzamlak((err, res) => {
      if (!err) {
        let aktivSzamlak = res[0].map(e => <option key={e.id} value={e.id}>{Lib.bankAccountFormatter(e.szamlaszam) + ' [' + e.penznem + '] (' + e.nev + ') '}</option>);
        this.setState({ aktivSzamlak: aktivSzamlak });
      }
    });
  }

  getAktivSzamlakForm = () => {
    Services.listAktivSzamlak('BANK', (err, res) => {
      if (!err) {
        let aktivSzamlak = res[0].map(e => <option key={e.id} value={e.id}>{Lib.bankAccountFormatter(e.szamlaszam) + ' [' + e.penznem + '] (' + e.nev + ') '}</option>);
        this.setState({ aktivSzamlakForm: aktivSzamlak });
      }
    });
  }

  refreshAllLevel = () => {
    this.props.tableStatusChange({
      fej: 'LOADED',
      tranzakcio: 'RELOAD',
      tetel: 'RELOAD'
    });
    this.props.closeBanktetelAnalitika();
    this.props.closeBanktetel();
  }

  onVegeChange = (e, v) => {
    let currentState = this.state.eloszuresValues;
    let currentValue = v === '' ? null : v;
    currentState.to = currentValue;
    this.setState({ eloszuresValues: currentState });
  }

  onKezdeteChange = (e, v) => {
    let currentState = this.state.eloszuresValues;
    let currentValue = v === '' ? null : v;
    currentState.from = currentValue;
    this.setState({ eloszuresValues: currentState });
  }

  onSzamlaChange = (e) => {
    let currentState = this.state.eloszuresValues;
    let currentValue = e.target.value === '' ? '' : e.target.value;
    currentState.id = currentValue;
    this.setState({ eloszuresValues: currentState });
  }

  handleFormChange = (event) => {
    const { target } = event;
    let value;
    if (target) {
      if (target.name) {
        // console.log(target.name);
        switch (target.name) {
          case 'bankszamlaid': {
            let id = target.value === '' ? null : target.value;
            if (this.state.datum) {
              Services.getNyito(id, this.state.datum, (err, res) => {
                if (!err) {
                  this.setState({
                    elsoNyito: res[0] === '',
                    nyito: res[0] === '' ? 0 : res[0],
                    jovairas: 0,
                    terheles: 0,
                    zaro: res[0] === '' ? 0 : res[0],
                  });
                }
              });
            }
            value = id;
            break;
          }
          case 'jovairas': {
            ({ value } = target);
            if (target.value === '') {
              value = 0;
            }
            value = value.replace(/\s/g, '');
            this.setState({
              zaro: (Number(this.state.nyito) + Number(value)) - Number(this.state.terheles)
            });
            break;
          }
          case 'nyito': {
            ({ value } = target);
            if (target.value === '') {
              value = 0;
            }
            value = value.replace(/\s/g, '');
            this.setState({
              zaro: (Number(value) + Number(this.state.jovairas)) - Number(this.state.terheles)
            });
            break;
          }
          case 'sorszam':
            ({ value } = target);
            break;
          case 'terheles': {
            ({ value } = target);
            if (target.value === '') {
              value = 0;
            }
            value = value.replace(/\s/g, '');
            this.setState({
              zaro: (Number(this.state.nyito) + Number(this.state.jovairas)) - Number(value)
            });
            break;
          }
          default:
            break;
        }
      }
      // value = target.type === 'checkbox' ? target.checked : target.value;
    } else {
      value = event;
    }
    const { name } = target;

    // console.log(this.state);

    this.setState({
      [name]: value
    }, () => {
      // console.log(this.state);
    });
  }

  formDatumChange = (e, v) => {
    let currentValue = v === '' ? null : v;
    this.setState({ datum: currentValue });
    if (this.state.bankszamlaid) {
      Services.getNyito(this.state.bankszamlaid, currentValue, (err, res) => {
        if (!err) {
          if (res[0] != '') {
            this.setState({
              nyito: res[0],
              zaro: res[0],
              elsoNyito: false
            });
          } else {
            this.setState({
              nyito: 0,
              zaro: 0,
              elsoNyito: true
            });
          }
        }
      });
    }
  }

  toggleBaseEdit = (id) => {
    this.setState({
      modalBaseEdit: !this.state.modalBaseEdit,
      nyito: 0,
      jovairas: 0,
      terheles: 0,
      zaro: 0,
      sorszam: '',
      bankszamlaid: null,
      datum: null,
      elsoNyito: false
    });
    if (id !== 'undefined' && Number.isInteger(id) && _muvelet === 1) {
      this.setState({ currentStatusBaseEdit: 'LOAD' });
      Services.getBankfej(id, (err, res) => {
        if (!err) {
          this.setState({
            nyito: res[0].nyito,
            jovairas: res[0].jovairas,
            terheles: res[0].terheles,
            zaro: res[0].zaro,
            sorszam: res[0].sorszam,
            bankszamlaid: res[0].bankszamlaid,
            datum: res[0].datum,
            currentStatusBaseEdit: 'LOADED',
          });
        } else {
          this.setState({
            currentStatusBaseEdit: 'NETW',
          });
        }
      });
    }
  }

  toggleBaseFeladas = () => {
    this.setState({
      modalBaseFeladas: !this.state.modalBaseFeladas
    });
  }

  toggleBaseDelete = () => {
    this.setState({
      modalBaseDelete: !this.state.modalBaseDelete
    });
  }

  updateBaseTable = () => {
    this.setState({
      currentStatusBaseTable: 'LOAD'
    });
    let sendObj = this.state.eloszuresValues;
    if (!sendObj.id) delete sendObj.id;
    if (!sendObj.from) delete sendObj.from;
    if (!sendObj.to) delete sendObj.to;
    Services.listTable(sendObj, (err, res) => {
      if (!err) {
        res[0].forEach((element, idx) => {
          if (element.bankszamlabean && element.bankszamlabean.szamlaszam) {
            res[0][idx].szamlaszam = element.bankszamlabean.szamlaszam;
          }
        });
        this.setState({
          baseJson: res[0],
          currentStatusBaseTable: 'TABL',
        });
        this.getAktivSzamlak();
      } else {
        this.setState({
          currentStatusBaseTable: 'QUERY'
        });
      }
    });
  }

  addBankfej = () => {
    let submitObj = {
      datum: this.state.datum,
      bankszamlaid: this.state.bankszamlaid,
      sorszam: this.state.sorszam,
      nyito: Number.isNaN(this.state.nyito) ? this.state.nyito.replace(/\s/g, '') : this.state.nyito,
      jovairas: Number.isNaN(this.state.jovairas) ? this.state.jovairas.replace(/\s/g, '') : this.state.jovairas,
      terheles: Number.isNaN(this.state.terheles) ? this.state.terheles.replace(/\s/g, '') : this.state.terheles,
      zaro: Number.isNaN(this.state.zaro) ? this.state.zaro.replace(/\s/g, '') : this.state.zaro
    };

    this.emptyToNull(submitObj);

    Services.addBankfej(submitObj, (err) => {
      if (!err) {
        let obj = JSON.parse(JSON.stringify(this.state.eloszuresValues));
        obj.id = submitObj.bankszamlaid;
        this.setState({
          eloszuresValues: obj
        }, () => {
          this.toggleBaseEdit();
          this.updateBaseTable();
          this.getAktivSzamlak();
          this.props.globals.addSuccess('Bankkivonat fej felvéve!');
          this.refreshAllLevel();
        });
      }
    });
  }

  modifyBankfej = () => {
    let submitObj = {
      id: this._currentEditId,
      bankszamlaid: this.state.bankszamlaid,
      datum: this.state.datum,
      sorszam: this.state.sorszam,
      nyito: Number.isNaN(this.state.nyito) ? this.state.nyito.replace(/\s/g, '') : this.state.nyito,
      jovairas: Number.isNaN(this.state.jovairas) ? this.state.jovairas.replace(/\s/g, '') : this.state.jovairas,
      terheles: Number.isNaN(this.state.terheles) ? this.state.terheles.replace(/\s/g, '') : this.state.terheles,
      zaro: Number.isNaN(this.state.zaro) ? this.state.zaro.replace(/\s/g, '') : this.state.zaro
    };

    this.emptyToNull(submitObj);
    Services.editBankfej(this._currentEditId, submitObj, (err) => {
      if (!err) {
        this.toggleBaseEdit();
        this.updateBaseTable();
        this.getAktivSzamlak();
        this.props.globals.addSuccess('Bankkivonat fej módosítva!');
        this.refreshAllLevel();
      }
    });
  }

  handleBaseNewClick = () => {
    _muvelet = 0;
    this.toggleBaseEdit();
  }

  handleBaseDeleteClick = (id) => {
    this._currentDeleteId = id;
    this.toggleBaseDelete();
  }

  handleBaseFeladasClick = (id) => {
    this._currentFeladasId = id;
    this.toggleBaseFeladas();
  }

  handleBaseEditClick = (id) => {
    _muvelet = 1;
    this._currentEditId = id;
    this.toggleBaseEdit(id);
  }

  handleBaseXlsClick = (id) => {
    Services.exportBankalapXLS(id, (err, res) => {
      if (!err) {
        this.props.globals.addSuccess('Bank alaplista XLSX letöltése...');
        fileSaver.saveAs(new Blob([res[0]], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', responseType: 'arraybuffer' }), 'Bankalaplista-'+moment().format('YYYY-MM-DD')+'.xlsx');    
      }
    });
  }

  deleteBankfej = (id) => {
    this.toggleBaseDelete();
    Services.deleteBankfej(id, (err) => {
      if (!err) {
        this.updateBaseTable();
        this.props.globals.addSuccess('Bankkivonat fej törölve!');
        this.getAktivSzamlak();
        this.refreshAllLevel();
      }
    });
  }

  fokonyviFeladas = (id) => {
    this.toggleBaseFeladas();
    Services.downloadZip(id, (err, res) => {
      if (!err) {
        this.props.globals.addSuccess('Zip fájl letöltés indítása...');
        fileSaver.saveAs(new Blob([res[0]], { type: 'application/zip;charset=ANSI', responseType: 'arraybuffer' }), new Date().toISOString().slice(0, 10) + '.zip');
      }
    });
  }

  /** Objektumot bejárva az üres stringeket NULL-ra állítja */
  emptyToNull = (obj) => {
    if (!obj || typeof obj !== 'object') return;
    for (let p in obj) {
      if (typeof obj[p] === 'object' && obj[p] !== null) {
        this.emptyToNull(obj[p]);
      } else if (obj.hasOwnProperty(p)) {
        if (obj[p] === '' || obj[p] === undefined) {
          obj[p] = null;
        }
      }
    }
  }

  baseTableIconFormatter = (cell) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'BANK_PUTELJ', 'KARB') && (
        <React.Fragment>
          <Button
            className="icon-pencil table-action-icon"
            id={'action-edit-base-fej-' + cell}
            onClick={() => this.handleBaseEditClick(cell)}
          />
          <UncontrolledTooltip
            placement="left"
            target={'action-edit-base-fej-' + cell}
            delay={0}
          >
            Módosítás
          </UncontrolledTooltip>
          <Button
            className="fa fa-file-text-o table-action-icon"
            id={'action-feladas-base-fej-' + cell}
            onClick={() => this.handleBaseFeladasClick(cell)}
          />
          <UncontrolledTooltip
            placement="left"
            target={'action-feladas-base-fej-' + cell}
            delay={0}
          >
            Főkönyvi feladás
          </UncontrolledTooltip>
          <Button
            className="icon-trash table-action-icon"
            id={'action-delete-base-fej-' + cell}
            onClick={() => this.handleBaseDeleteClick(cell)}
          />
          <UncontrolledTooltip
            placement="left"
            target={'action-delete-base-fej-' + cell}
            delay={0}
          >
            Törlés
          </UncontrolledTooltip>
        </React.Fragment>
        )}
        {Lib.KeyCloak.hasRole(this.props.globals.keycloak, ['BANK_PUTELJ']) && (
          <React.Fragment>
            <Button
            className="fa fa-download table-action-icon"
            id={'action-xls-base-fej-' + cell}
            onClick={() => this.handleBaseXlsClick(cell)}
          />
          <UncontrolledTooltip
            placement="left"
            target={'action-xls-base-fej-' + cell}
            delay={0}
          >
            XLS export
          </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  renderEloSzures = () => {
    return (
      <AvForm>
        <div className="row">
          <div className="col-md-3">
            <AvField
              type="select"
              name="id"
              id="id"
              label="Bankszámlaszám: "
              errorMessage="Kötelező mező!"
              onChange={this.onSzamlaChange}
              value={this.state.eloszuresValues.id}
              disabled={!this.state.aktivSzamlak.length}
            >
              <option value="">
                Válassz bankszámlát...
              </option>
              {this.state.aktivSzamlak}
            </AvField>
          </div>
          <div className="col-md-2">
            <Label for="from">
              Időszak kezdete:
            </Label>
            <DatePicker
              required
              placeholder="éééé-hh-nn"
              onChange={this.onKezdeteChange}
              type="select"
              name="from"
              value={this.state.eloszuresValues && this.state.eloszuresValues.from && new Date(this.state.eloszuresValues.from)}
            />
          </div>
          <div className="col-md-2">
            <Label for="to">
              Időszak vége:
            </Label>
            <DatePicker
              required
              placeholder="éééé-hh-nn"
              onChange={this.onVegeChange}
              type="select"
              name="to"
              value={this.state.eloszuresValues && this.state.eloszuresValues.to && new Date(this.state.eloszuresValues.to)}
            />
          </div>
          <div className="col-md-2">
            <Button
              className="margin-top-30"
              color="primary"
              onClick={() => {
                this.updateBaseTable();
                this.refreshAllLevel();
              }}
            >
              Keresés
            </Button>
          </div>
        </div>
      </AvForm>
    );
  }

  renderNewButton = () => {
    return (
      <div className="row">
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'BANK_PUTELJ', 'KARB') && (
          <div className="col-md-12">
            <Button
              // className="margin-top-30"
              color="primary"
              onClick={this.handleBaseNewClick}
            >
              Új bankkivonat
            </Button>
          </div>
        )}
      </div>
    );
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  renderForm = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          <AvField
            type="select"
            name="bankszamlaid"
            id="bankszamlaid"
            value={this.state.bankszamlaid}
            onChange={this.handleFormChange}
            label="Bankszámlaszám: *"
            errorMessage="Kötelező mező!"
            disabled={!this.state.aktivSzamlakForm.length}
          >
            <option value="">Válassz bankszámlát...</option>
            {this.state.aktivSzamlakForm}
          </AvField>
        </div>
        <div className="col-md-12">
          <Label for="datum">Dátum: *</Label>
          <DatePicker
            required
            placeholder="éééé-hh-nn"
            onChange={this.formDatumChange}
            value={this.state.datum && new Date(this.state.datum)}
            type="select"
            name="datum"
          />
        </div>
        <div className="col-md-12">
          <AvGroup>
            <Label for="sorszam">Kivonat sorszáma: *</Label>
            <AvInput
              name="sorszam"
              id="sorszam"
              maxLength="15"
              required
              onChange={this.handleFormChange}
              value={this.state.sorszam}
            />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-6">
          <SlaveAvInput 
            id="nyito"
            label="Nyitó egyenleg: *"
            required
            onChange={this.handleFormChange}
            max="9999999999"
            min="-9999999999"
            disabled={!this.state.elsoNyito}
            value={this.state.nyito.toString()}
            szuroTipus={NumberFormatter.OSSZEG}
          />
        </div>
        {_muvelet !== 0 && (
        <React.Fragment>
          <div className="col-md-6">
            <SlaveAvInput
              id="jovairas"
              label="Jóváírás: *"
              required
              onChange={this.handleFormChange}
              max="9999999999"
              min="-9999999999"
              disabled={_muvelet !== 0}
              value={this.state.jovairas.toString()}
              szuroTipus={NumberFormatter.OSSZEG}
            />
          </div>
          <div className="col-md-6">
            <SlaveAvInput 
              id="terheles"
              label="Terhelés: *"
              required
              onChange={this.handleFormChange}
              max="9999999999"
              min="-9999999999"
              disabled={_muvelet !== 0}
              value={this.state.terheles.toString()}
              szuroTipus={NumberFormatter.OSSZEG}
            />
          </div>
        </React.Fragment>
        )}
        <div className="col-md-6">
          <SlaveAvInput 
            id="zaro"
            label="Záró egyenleg: *"
            required
            max="9999999999"
            min="-9999999999"
            value={this.state.zaro.toString()}
            disabled
            szuroTipus={NumberFormatter.OSSZEG}
          />
        </div>
      </div>
    );
  }

  renderBaseTable = () => {
    const pagination = true;

    const selectRowProp = {
      mode: 'radio',
      onSelect: this.onRowSelect
    };

    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };
    return (
      <div>
        { this.state.baseJson.length ? (
          <BootstrapTable data={this.state.baseJson} pagination={pagination} options={options} selectRow={selectRowProp} className="margin-top-30" >
            <TableHeaderColumn
              dataField="s"
              tdStyle={{ whiteSpace: 'normal' }}
              width="180"
            >
              Bankszámlaszám
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="d"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              width="110"
            >
              Dátum
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="o"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            >
              Kivonat sorszáma
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="n"
              tdStyle={{ whiteSpace: 'normal', textAlign: 'right' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              dataFormat={NumberFormatter.formatterSpaceOsszeg}
            >
              Nyitó egyenleg
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="j"
              tdStyle={{ whiteSpace: 'normal', textAlign: 'right' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              dataFormat={NumberFormatter.formatterSpaceOsszeg}
            >
              Jóváírás
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="t"
              tdStyle={{ whiteSpace: 'normal', textAlign: 'right' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              dataFormat={NumberFormatter.formatterSpaceOsszeg}
            >
              Terhelés
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="z"
              tdStyle={{ whiteSpace: 'normal', textAlign: 'right' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              dataFormat={NumberFormatter.formatterSpaceOsszeg}
            >
              Záró egyenleg
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="p"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            >
              Pénznem
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="i"
              tdStyle={{ whiteSpace: 'normal' }}
              isKey
              dataFormat={this.baseTableIconFormatter}
              width="110"
              hidden={!Lib.KeyCloak.hasRole(this.props.globals.keycloak, ['BANK_PUTELJ'])}
            >
              Műveletek
            </TableHeaderColumn>
          </BootstrapTable>) : <DataEmptyState />}
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderEloSzures()}
        {this.renderNewButton()}
        {{
          QUERY: (<QueryEmptyState
            hasImage
          />),
          LOAD: (<Loader />),
          DATA: (<DataEmptyState
            hasImage
            subtitle="Próbálj más előszűrést vagy vigyél fel új adatokat!"
          />),
          NETW: (<NetworkEmptyState
            hasImage
            submitClick={() => this.updateTable()}
          />),
          TABL: (this.renderBaseTable())
        }[this.state.currentStatusBaseTable]}

        <Modal
          isOpen={this.state.modalBaseEdit}
          toggle={this.toggleBaseEdit}
          className={this.props.className}
          backdrop="static"
          // size="lg"
        >
          <AvForm onValidSubmit={_muvelet === 0 ? this.addBankfej : this.modifyBankfej}>
            {_muvelet === 0 ? <ModalHeader>Új bankkivonat rögzítése</ModalHeader> : <ModalHeader>Meglévő bankkivonat módosítása</ModalHeader> }
            <ModalBody>
              {{
                LOAD: (<Loader />),
                NETW: (<NetworkEmptyState
                  hasImage
                  submitClick={() => this.updateTable()}
                />),
                LOADED: (this.renderForm())
              }[this.state.currentStatusBaseEdit]}
            </ModalBody>
            <ModalFooter>
              <Button color="primary">Mentés</Button>{' '}
              <Button type="button" color="secondary" onClick={this.toggleBaseEdit} >Mégse</Button>
            </ModalFooter>
          </AvForm>
        </Modal>

        <Modal isOpen={this.state.modalBaseDelete} toggle={this.toggleBaseDelete} className={this.props.className} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>
            Valóban törölni kívánja a kiválasztott tételt?
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteBankfej(this._currentDeleteId)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleBaseDelete}>Mégsem</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalBaseFeladas} toggle={this.toggleBaseFeladas} className={this.props.className} backdrop="static">
          <ModalHeader>Főkönyvi feladás</ModalHeader>
          <ModalBody>
            Valóban fel kívánja adni a kiválasztott tételt?
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.fokonyviFeladas(this._currentFeladasId)}>Mentés</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleBaseFeladas}>Mégsem</Button>
          </ModalFooter>
        </Modal>

      </div>
    );
  }
}

BankfejTable.propTypes = {
  closeBanktetel: PropTypes.func,
  tableStatusChange: PropTypes.func,
  closeBanktetelAnalitika: PropTypes.func,
  className: PropTypes.string,
  globals: PropTypes.object,
  changeData: PropTypes.func,
};

export default BankfejTable;
