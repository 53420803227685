import React from 'react';

import FizetesiFelszolitoBase from './FizetesiFelszolitoBase';

const FizetesiFelszolito = (props) => {
  let cardStyle = {
    padding: 20
  };

  return (
    <div className="animated fadeIn">
      <div className="card">
        <div className="card-header">
          Fizetési felszólító
        </div>
        <div className="row" style={cardStyle}>
          <div className="col-md-12">
            <FizetesiFelszolitoBase
              globals={props.route.globals}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FizetesiFelszolito;
