import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  UncontrolledTooltip
} from 'reactstrap';
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback
} from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import QueryEmptyState from '../../../commons/queryempty';
import Loader from '../../../commons/loader';
import Services from './services';
import { PaginationTotal } from '../../../commons/controls';
import Lib from '../../../commons/lib';

// 0 -> Felvitel
// 1 -> módosítás
let _muvelet = 0;
let _modositoObj = null;

class Jogalap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ['QUERY', 'LOAD', 'DATA', 'NETW', 'TABL']
      currentStatusBaseTable: 'LOAD',
      modalNezet: false,
      modalFelvitel: false,
      modalTorles: false,
      dataLoaded: false,
      dataFailed: false,
      baseJson: [],
      /*
       *  FORM VALUES
       */
      nev: '',
      kod: '',
      statusz: '',
      elevules: '',
      szoveg: '',
      jogszabalyiHivatkozas: '',

      statuszok: [],
      statuszOptions: [],
      filterOptions: {},
    };

    this.updateTable = this.updateTable.bind(this);
  }

  componentDidMount() {
    this.updateTable();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.type === 'JOGALAP') {
      this.updateTable();
    }
  }

  updateTable = () => {
    this.setState({
      currentStatusBaseTable: 'LOAD'
    });
    Services.listJogalap((err, res) => {
      if (err) {
        this.setState({
          currentStatusBaseTable: 'NETW'
        });
      } else {
        let filters = {};
        let options = res[0].statusz.map((e) => {
          filters[e.name] = e.caption;

          return <option key={e.name} value={e.name}>{e.caption}</option>;
        });

        this.setState({
          baseJson: res[0].list,
          currentStatusBaseTable: res[0].list.length > 0 ? 'TABL' : 'DATA',
          statuszok: res[0].statusz,
          statuszOptions: options,
          filterOptions: filters,
        });
      }
    });
  }

  fillModositoObj = () => {
    this.setState({ modifyDataLoaded: false, modfiyDataFailed: false });
    Services.getJogalap(this._currentEditId, (err, res) => {
      if (err) {
        this.setState({ modifyDataLoaded: true, modfiyDataFailed: true });
      } else {
        this.setState({
          nev: res[0].nev,
          kod: res[0].kod,
          statusz: res[0].statusz,
          elevules: res[0].elevules,
          szoveg: res[0].szoveg,
          jogszabalyiHivatkozas: res[0].jogszabalyiHivatkozas
        }, () => {
          this.toggleModositas();
        });
      }
    });
  }

  clearModositoObj = () => {
    _modositoObj = {
      id: '',
      ev: '',
      tipus: '',
      mertek: '',
      statusz: '',
    };
  }

  toggleNezet = () => {
    this.setState({ modalNezet: !this.state.modalNezet });
  }

  toggleModositas = () => {
    this.setState({
      modalFelvitel: !this.state.modalFelvitel
    });
  }

  toggleFelvitel = () => {
    this.setState({
      nev: '',
      kod: '',
      statusz: '',
      elevules: '',
      szoveg: '',
      jogszabalyiHivatkozas: '',
      modalFelvitel: !this.state.modalFelvitel
    });
  }

  toggleTorles = () => {
    this.setState({ modalTorles: !this.state.modalTorles });
  }

  handleEditClick = (id) => {
    _muvelet = 1;
    this._currentEditId = id;
    this.fillModositoObj();
  }

  handleNewClick = () => {
    _muvelet = 0;
    this.clearModositoObj();
    this.toggleFelvitel();
    this.setState({ statusz: 'AKTIV' });
  }

  handleDeleteClick = (id) => {
    this._currentDeleteId = id;
    this.toggleTorles();
  }

  handleViewClick = (id) => {
    this._currentViewId = id;
    this.toggleNezet();
  }

  iconFormatter = (cell, row) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'GDPR_JOGALAP', 'LEK') && (
          <React.Fragment>
            <Button className="icon-eye table-action-icon" id={'action-view-' + cell} onClick={() => this.handleViewClick(cell)} />
            <UncontrolledTooltip placement="left" target={'action-view-' + cell} delay={10}>
              {'Megtekintés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        { Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'GDPR_JOGALAP', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-edit-' + cell} onClick={() => this.handleEditClick(cell, row)} />
            <Button className="icon-trash table-action-icon" id={'action-delete-' + cell} onClick={() => this.handleDeleteClick(cell)} />
            <UncontrolledTooltip placement="left" target={'action-edit-' + cell} delay={10}>
              {'Módosítás'}
            </UncontrolledTooltip>
            <UncontrolledTooltip placement="left" target={'action-delete-' + cell} delay={10}>
              {'Törlés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  felhasznalvaFormatter = (cell) => {
    return cell ? 'Igen' : 'Nem';
  }

  addJogalap = () => {
    let submitObj = {
      kod: this.state.kod,
      nev: this.state.nev,
      szoveg: this.state.szoveg,
      jogszabalyiHivatkozas: this.state.jogszabalyiHivatkozas,
      elevules: this.state.elevules,
      statusz: this.state.statusz
    };

    Services.addJogalap(submitObj, (err) => {
      if (!err) {
        this.toggleFelvitel();
        this.updateTable();
        this.props.globals.addSuccess('Jogalap felvéve!');
      }
    });
  }

  updateJogalap = () => {
    let submitObj = {
      id: this._currentEditId,
      kod: this.state.kod,
      nev: this.state.nev,
      szoveg: this.state.szoveg,
      jogszabalyiHivatkozas: this.state.jogszabalyiHivatkozas,
      elevules: this.state.elevules,
      statusz: this.state.statusz
    };

    Services.editJogalap(submitObj, (err) => {
      if (!err) {
        this.toggleFelvitel();
        this.updateTable();
        this.props.globals.addSuccess('Jogalap módosítva!');
      }
    });
  }

  deleteJogalap = (id) => {
    this.toggleTorles();
    Services.deleteJogalap(id, (err) => {
      if (!err) {
        this.updateTable();
        this.props.globals.addSuccess('Jogalap törölve!');
      }
    });
  }

  filterType = (cell) => {
    // just return type for filtering or searching.
    return cell.caption;
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  statusFormatter = (cell) => {
    let statusz = this.state.statuszok.find(x => x.name === cell);
    return statusz ? <span className={'badge badge-' + statusz.bagde}>{statusz.caption}</span> : 'N/A';
  }

  renderBaseTable = () => {
    const pagination = true;
    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ],
    };

    return (
      <BootstrapTable data={this.state.baseJson} pagination={pagination} options={options}>
        <TableHeaderColumn
          dataField="kod"
          tdStyle={{ whiteSpace: 'normal' }}
          filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          width="130"
        >
          {'Kód'}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="nev"
          tdStyle={{ whiteSpace: 'normal' }}
          filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
        >
          {'Megnevezés'}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="szoveg"
          tdStyle={{ whiteSpace: 'normal' }}
          filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
        >
          {'Szöveg'}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="jogszabalyiHivatkozas"
          tdStyle={{ whiteSpace: 'normal' }}
          filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
        >
          {'Jogszabályi hivatkozás'}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="statusz"
          dataFormat={this.statusFormatter}
          tdStyle={{ whiteSpace: 'normal' }}
          filter={{ type: 'SelectFilter', options: this.state.filterOptions, placeholder: 'Összes', condition: 'eq' }}
          dataAlign="center"
          width="150"
        >
          {'Státusz'}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="id"
          isKey
          dataFormat={this.iconFormatter}
          width="100"
        >
          {'Műveletek'}
        </TableHeaderColumn>
      </BootstrapTable>
    );
  }

  renderAdatok = (id) => {
    let jogalapObj = this.state.baseJson.find(jogalap => jogalap.id === id);
    let statusz = jogalapObj && this.state.statuszok.find(x => x.name === jogalapObj.statusz);
    return (
      <div>
        <h6>
          {'Jogalap információ:'}
        </h6>
        <b>
          {'Kód:'}
        </b>
        {' ' + jogalapObj.kod}
        <br />
        <b>
          {'Megnevezés:'}
        </b>
        {' ' + jogalapObj.nev}
        <br />
        <b>
          {'Szöveg:'}
        </b>
        {' ' + jogalapObj.szoveg}
        <br />
        <b>
          {'Jogszabály hivatkozás:'}
        </b>
        {' ' + jogalapObj.jogszabalyiHivatkozas}
        <br />
        <b>
          {'Státusz:'}
        </b>
        {statusz && ' ' + statusz.caption}
        <br />
        <b>
          {'Elévülési idő:'}
        </b>
        {' ' + jogalapObj.elevules + ' év'}
        <br />
      </div>
    );
  }

  render() {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'GDPR_JOGALAP', 'KARB') && (
          <div className="row">
            <div className="col-sm-12">
              <Button
                className="pull-left table-new-button"
                color="primary"
                onClick={this.handleNewClick}
              >
                {'Új jogalap'}
              </Button>
            </div>
          </div>
        )}
        {{
          QUERY: (<QueryEmptyState
            hasImage
          />),
          LOAD: (<Loader />),
          DATA: (<DataEmptyState
            hasImage
            subtitle="Próbálj más előszűrést vagy vigyél fel új adatokat!"
          />),
          NETW: (<NetworkEmptyState
            hasImage
            submitClick={() => this.updateTable()}
          />),
          TABL: (this.renderBaseTable())
        }[this.state.currentStatusBaseTable]}
        <Modal isOpen={this.state.modalNezet} toggle={this.toggleNezet} className={this.props.className} backdrop="static">
          <ModalHeader>
            {'Megtekintés'}
          </ModalHeader>
          <ModalBody>{this.state.modalNezet ? this.renderAdatok(this._currentViewId) : <div />}</ModalBody>
          <ModalFooter> <Button type="button" color="primary" onClick={this.toggleNezet} className="btn-cursor-pointer" >Vissza</Button></ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className} backdrop="static">
          <ModalHeader>
            {'Törlés'}
          </ModalHeader>
          <ModalBody>
            {'Valóban törölni kívánja a kiválasztott tételt?'}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteJogalap(this._currentDeleteId)}>
              {'OK'}
            </Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleTorles}>
              {'Mégsem'}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal size="lg" isOpen={this.state.modalFelvitel} toggle={this.toggleFelvitel} className={this.props.className} backdrop="static">
          <AvForm onValidSubmit={_muvelet === 0 ? this.addJogalap : this.updateJogalap}>
            {_muvelet === 0 ? <ModalHeader>Új jogalap rögzítése</ModalHeader> : <ModalHeader>Meglévő jogalap módosítása</ModalHeader> }
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  <AvGroup>
                    <Label for="nev">
                      {'Megnevezés: *'}
                    </Label>
                    <AvInput
                      name="nev"
                      id="nev"
                      required
                      value={this.state.nev}
                      onChange={this.handleInputChange}
                    />
                    <AvFeedback>
                      {'Kötelező mező!'}
                    </AvFeedback>
                  </AvGroup>
                </div>
                <div className="col-md-4">
                  <AvGroup>
                    <Label for="kod">
                      {'Kód: *'}
                    </Label>
                    <AvInput
                      name="kod"
                      id="kod"
                      required
                      disabled={!(_muvelet === 0)}
                      value={this.state.kod}
                      onChange={this.handleInputChange}
                    />
                    <AvFeedback>
                      {'Kötelező mező!'}
                    </AvFeedback>
                  </AvGroup>
                </div>
                <div className="col-md-4">
                  <AvField
                    type="select"
                    required
                    name="statusz"
                    id="statusz"
                    label="Státusz: *"
                    errorMessage="Kötelező mező!"
                    value={this.state.statusz}
                    onChange={this.handleInputChange}
                  >
                    {this.state.statuszOptions}
                  </AvField>
                </div>
                <div className="col-md-4">
                  <AvGroup>
                    <Label for="elevules">
                      {'Elévülési idő (év): *'}
                    </Label>
                    <AvInput
                      name="elevules"
                      id="elevules"
                      required
                      value={this.state.elevules}
                      onChange={this.handleInputChange}
                    />
                    <AvFeedback>
                      {'Kötelező mező!'}
                    </AvFeedback>
                  </AvGroup>
                </div>
                <div className="col-md-12">
                  <AvGroup>
                    <Label for="szoveg">
                      {'Szöveg:'}
                    </Label>
                    <AvInput
                      className="munkalap-profil-textarea"
                      type="textarea"
                      name="szoveg"
                      id="szoveg"
                      value={this.state.szoveg}
                      onChange={this.handleInputChange}
                    />
                    <AvFeedback>
                      {'Kötelező mező!'}
                    </AvFeedback>
                  </AvGroup>
                </div>
                <div className="col-md-12">
                  <AvGroup>
                    <Label for="jogszabalyiHivatkozas">
                      {'Jogszabályi hivatkozás:'}
                    </Label>
                    <AvInput
                      className="munkalap-profil-textarea"
                      type="textarea"
                      name="jogszabalyiHivatkozas"
                      id="jogszabalyiHivatkozas"
                      value={this.state.jogszabalyiHivatkozas}
                      onChange={this.handleInputChange}
                    />
                    <AvFeedback>
                      {'Kötelező mező!'}
                    </AvFeedback>
                  </AvGroup>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary">
                {'Mentés'}
              </Button>
              {' '}
              <Button type="button" color="secondary" onClick={this.toggleFelvitel}>
                {'Mégse'}
              </Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </div>
    );
  }
}

export default Jogalap;
