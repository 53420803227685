import React from 'react';

const Footer = () => {
  return (
    <footer className="app-footer">
      <a href="https://zszportal.zalaszam.hu/">Copyright 2018-{new Date().getFullYear()}</a> &copy; Zalaszám Informatika Kft.
      {/* <span className="float-right">Social networks</span> */}
    </footer>
  );
};

export default Footer;
