import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from 'reactstrap';
import {
  AvGroup,
  AvInput,
  AvFeedback,
  AvForm,
  AvField
} from 'availity-reactstrap-validation';
import {
  BootstrapTable,
  TableHeaderColumn
} from 'react-bootstrap-table';
import Lib from '../../../commons/lib';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import Loader from '../../../commons/loader';
import Services from './services';
import { PaginationTotal } from '../../../commons/controls';
import { ProgressButton } from '../../../commons/ProgressButton';

class VizsgaloContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // ['LOAD', 'DATA', 'NETW', 'TABL']
      tableStatus: 'LOAD',
      // ['LOAD', 'DATA', 'NETW']
      formStatus: 'LOAD',
      // ['FEL', 'MOD']
      formType: 'FEL',
      // FORM VALUES
      formAzonosito: '',
      formTitulus: '',
      formVezeteknev: '',
      formKeresztnev: '',
      formStatusz: '',
      // UTILS
      currentId: null,
      baseJson: [],
      modalState: false,
      saveButtonLoading: false
    };
  }

  componentDidMount() {
    this.getVizsgaloList();
  }

  toggleModal = () => {
    this.setState(prevState => ({ modalState: !prevState.modalState }));
  }

  getVizsgaloList = () => {
    this.setState({
      tableStatus: 'LOAD'
    });
    Services.getVizsgaloList((err, res) => {
      if (!err) {
        res[0].forEach((item) => {
          if (item.statusz) item.aktiv = item.statusz.name === 'AKTIV' ? 1 : 0;
        });
        this.setState({
          tableStatus: 'TABL',
          baseJson: res[0]
        });
      } else {
        this.setState({
          tableStatus: 'NETW'
        });
      }
    });
  }

  getVizsgalo = (id) => {
    this.setState({
      formStatus: 'LOAD'
    });
    Services.getVizsgalo(id, (err, res) => {
      if (!err) {
        this.setState({
          formStatus: 'DATA',
          formAzonosito: res[0].azonosito ? res[0].azonosito : '',
          formTitulus: res[0].titulus ? res[0].titulus : '',
          formVezeteknev: res[0].vezeteknev ? res[0].vezeteknev : '',
          formKeresztnev: res[0].keresztnev ? res[0].keresztnev : '',
          formStatusz: (res[0].statusz && res[0].statusz.name) ? res[0].statusz.name : '',
        });
      } else {
        this.setState({
          formStatus: 'NETW'
        });
      }
    });
  }

  handleSubmit = () => {
    const {
      formAzonosito,
      formTitulus,
      formVezeteknev,
      formKeresztnev,
      formStatusz,
      formType,
      currentId
    } = this.state;

    this.setState({
      saveButtonLoading: true
    });

    if (formType === 'FEL') {
      let submitObj = {
        azonosito: formAzonosito,
        titulus: formTitulus === '' ? null : formTitulus,
        vezeteknev: formVezeteknev,
        keresztnev: formKeresztnev,
        statusz: formStatusz
      };

      Services.createVizsgalo(submitObj, (err) => {
        if (!err) {
          this.toggleModal();
          this.getVizsgaloList();
          this.props.globals.addSuccess('Vizsgáló felvéve!');
          this.setState({
            saveButtonLoading: false
          });
        } else {
          this.setState({
            saveButtonLoading: false
          });
        }
      });
    }

    if (formType === 'MOD') {
      let submitObj = {
        id: currentId,
        azonosito: formAzonosito,
        titulus: formTitulus === '' ? null : formTitulus,
        vezeteknev: formVezeteknev,
        keresztnev: formKeresztnev,
        statusz: formStatusz
      };

      Services.updateVizsgalo(submitObj, (err) => {
        if (!err) {
          this.toggleModal();
          this.getVizsgaloList();
          this.props.globals.addSuccess('Vizsgáló módosítva!');
          this.setState({
            saveButtonLoading: false
          });
        } else {
          this.setState({
            saveButtonLoading: false
          });
        }
      });
    }
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  handleNewClick = () => {
    this.setState({
      formType: 'FEL',
      formStatus: 'DATA',
      formAzonosito: '',
      formTitulus: '',
      formVezeteknev: '',
      formKeresztnev: '',
      formStatusz: '',
    }, () => {
      this.toggleModal();
    });
  }

  handleEditClick = (cell) => {
    this.setState({
      formType: 'MOD',
      formStatus: 'LOAD',
      currentId: cell,
      formAzonosito: '',
      formTitulus: '',
      formVezeteknev: '',
      formKeresztnev: '',
      formStatusz: '',
    }, () => {
      this.getVizsgalo(cell);
      this.toggleModal();
    });
  }

  tableIconFormatter = (cell) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KARTOTEK_VIZSGALO', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => this.handleEditClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'modositas'} delay={0}>
              {'Módosítás'}
            </UncontrolledTooltip>
          </React.Fragment>
        )
      }
      </div>
    );
  }

  tableStatusFormatter = (cell) => {
    return cell
      ? <span className="badge badge-success">Aktív</span>
      : <span className="badge badge-default">Inaktív</span>;
  }

  renderFormTitle = () => {
    switch (this.state.formType) {
      case 'FEL':
        return 'Új vizsgáló személy felvitele';
      case 'MOD':
        return 'Vizsgáló személy módosítása';
      default:
        return '';
    }
  }

  renderForm = () => {
    const {
      formAzonosito,
      formTitulus,
      formVezeteknev,
      formKeresztnev,
      formStatusz
    } = this.state;

    return (
      <React.Fragment>
        <AvGroup>
          <Label for="formAzonosito">Azonosító: *</Label>
          <AvInput
            name="formAzonosito"
            id="formAzonosito"
            disabled={this.state.formType === 'MOD'}
            required
            maxLength="10"
            value={formAzonosito}
            onChange={this.handleInputChange}
          />
          <AvFeedback>Kötelező mező!</AvFeedback>
        </AvGroup>
        <AvGroup>
          <Label for="formTitulus">Titulus:</Label>
          <AvInput
            name="formTitulus"
            id="formTitulus"
            maxLength="50"
            value={formTitulus}
            onChange={this.handleInputChange}
          />
          <AvFeedback>Kötelező mező!</AvFeedback>
        </AvGroup>
        <AvGroup>
          <Label for="formVezeteknev">Vezetéknév: *</Label>
          <AvInput
            name="formVezeteknev"
            id="formVezeteknev"
            required
            maxLength="50"
            value={formVezeteknev}
            onChange={this.handleInputChange}
          />
          <AvFeedback>Kötelező mező!</AvFeedback>
        </AvGroup>
        <AvGroup>
          <Label for="formKeresztnev">Keresztnév: *</Label>
          <AvInput
            name="formKeresztnev"
            id="formKeresztnev"
            required
            maxLength="50"
            value={formKeresztnev}
            onChange={this.handleInputChange}
          />
          <AvFeedback>Kötelező mező!</AvFeedback>
        </AvGroup>
        <AvField
          type="select"
          name="formStatusz"
          id="formStatusz"
          label="Státusz: *"
          errorMessage="Kötelező mező!"
          required
          value={formStatusz}
          onChange={this.handleInputChange}
        >
          <option value="">Válasszon státuszt...</option>
          <option value="INAKTIV">Inaktív</option>
          <option value="AKTIV">Aktív</option>
        </AvField>
      </React.Fragment>
    );
  }

  renderTable = () => {
    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const statusType = {
      0: 'Inaktív',
      1: 'Aktív'
    };

    return (
      <div className="animated fadeIn">
        <BootstrapTable
          data={this.state.baseJson}
          options={options}
          pagination
        >
          <TableHeaderColumn
            dataField="azonosito"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          >
            {'Azonosító'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="titulus"
            tdStyle={{ whiteSpace: 'pre-line' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          >
            {'Titulus'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="vezeteknev"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          >
            {'Vezetéknév'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="keresztnev"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          >
            {'Keresztnév'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="aktiv"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'SelectFilter', options: statusType, placeholder: 'Összes' }}
            dataAlign="center"
            dataFormat={this.tableStatusFormatter}
          >
            {'Státusz'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="id"
            isKey
            hidden={!Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KARTOTEK_VIZSGALO', 'KARB')}
            width="100"
            dataFormat={this.tableIconFormatter}
          >
            {'Műveletek'}
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KARTOTEK_VIZSGALO', 'KARB') && (
              <Button className="pull-left table-new-button" color="primary" onClick={this.handleNewClick}>
                {'Új vizsgáló személy'}
              </Button>
            )}
          </div>
          <div className="col-md-12">
            {{
              LOAD: (<Loader />),
              DATA: (<DataEmptyState
                hasImage
                subtitle="Vigyél fel új tételt!"
              />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.getVizsgaloList()}
              />),
              TABL: (this.renderTable())
            }[this.state.tableStatus]}
          </div>
        </div>
        <Modal isOpen={this.state.modalState} toggle={this.toggleModal} backdrop="static">
          <AvForm onValidSubmit={this.handleSubmit}>
            <ModalHeader>{this.renderFormTitle()}</ModalHeader>
            <ModalBody>
              {{
                LOAD: (<Loader />),
                NETW: (<NetworkEmptyState
                  hasImage
                  submitClick={() => this.getVizsgalo(this.state.currentId)}
                />),
                DATA: (this.renderForm())
              }[this.state.formStatus]}
            </ModalBody>
            <ModalFooter>
              <ProgressButton
                className="btn-cursor-pointer"
                color="primary"
                loading={this.state.saveButtonLoading}
              >
                {'Mentés'}
              </ProgressButton>
              <Button type="button" color="secondary" className="btn-cursor-pointer" onClick={this.toggleModal}>Mégsem</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </React.Fragment>
    );
  }
}

VizsgaloContent.propTypes = {
  globals: PropTypes.object
};

export default VizsgaloContent;
