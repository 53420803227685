import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, UncontrolledTooltip } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import Services from './services';
import { PaginationTotal } from '../../../commons/controls';
import Lib from '../../../commons/lib';

let _muvelet = 0; // 0 -> Felvitel
// 1 -> módosítás

let _modositoObj = null;

class EpuletLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataViewLoaded: null,
      modalFelvitel: false,
      modalNezet: false,
      modalTorles: false,
      dataLoaded: false,
      dataFailed: false,
      aktivSzallodak: [],
      epuletJson: [],
      enums: [],
      viewObj: {}
    };

    this.updateTable = this.updateTable.bind(this);
  }

  componentDidMount() {
    this.updateTable();
    this.renderOptions();
  }

  updateTable = () => {
    this.renderOptions();
    this.getEnums();
    this.setState({ dataLoaded: false, dataFailed: false });

    Services.listEpulet((err, res) => {
      if (err) {
        this.setState({ dataLoaded: true, dataFailed: true });
      } else {
        let newArray = res[0];
        // Státusz stringgé alakítása
        newArray.forEach((pcs) => {
          if (pcs.statusz) {
            pcs.statusz = JSON.stringify(pcs.statusz);
          }
        });
        this.setState({ epuletJson: newArray, dataLoaded: true, dataFailed: false });
      }
    });
  }

  /** Dinamikusan töltendő szűkítő feltételek lekérdezése */
  getEnums = () => {
    this.setState({ enums: [] });
    Services.listEpuletEnums((err, res) => {
      if (!err) {
        this.setState({ enums: res[0] });
      }
    });
  }

  fillModositoObj = () => {
    this.setState({ modifyDataLoaded: false, modfiyDataFailed: false });
    Services.getEpulet(this._currentEditId, (err, res) => {
      if (err) {
        this.setState({ modifyDataLoaded: true, modfiyDataFailed: true });
      } else {
        this.setState({ modifyDataLoaded: true, modfiyDataFailed: false });
        _modositoObj = res[0];
        this.toggleFelvitel();
      }
    });
  }

  clearModositoObj = () => {
    _modositoObj = {
      id: '',
      email: '',
      fax: '',
      hazszam: '',
      hrsz: '',
      iranyitoszam: '',
      kapcsolattarto: '',
      kozterulet: '',
      kozteruletjelleg: '',
      nev: '',
      orszag: '',
      orszagid: '',
      hotelrciazon: '',
      hotelhwazon: '',
      postafiok: '',
      telefon: '',
      telepulesid: '',
      telepulesnev: '',
      statusz: { name: 'AKTIV' },
      szallodaentity: { id: '' }
    };
  }

  toggleFelvitel = () => {
    this.setState({ modalFelvitel: !this.state.modalFelvitel });
  }

  toggleTorles = () => {
    this.setState({ modalTorles: !this.state.modalTorles });
  }

  toggleNezet = () => {
    this.setState({ modalNezet: !this.state.modalNezet });
  }

  handleEditClick = (id) => {
    _muvelet = 1;
    this._currentEditId = id;
    this.renderOptions();
    this.fillModositoObj();
  }

  handleDeleteClick = (id) => {
    this._currentDeleteId = id;
    this.toggleTorles();
  }

  handleViewClick = (id) => {
    this.setState({ dataViewLoaded: false });
    Services.getEpulet(id, (err, res) => {
      if (!err) {
        this.setState({
          viewObj: res[0],
          dataViewLoaded: true
        });
      }
    });
    this.toggleNezet();
  }

  handleNewClick = () => {
    _muvelet = 0;
    this.renderOptions();
    this.clearModositoObj();
    this.toggleFelvitel();
  }

  updateView = () => {
    this.setState({ viewDataLoaded: false, viewDataFailed: false });
    Services.getSzalloda(this._currentViewId, (err, res) => {
      if (err) {
        this.setState({ viewDataLoaded: true, viewDataFailed: true });
      } else {
        this.setState({ viewObj: res[0], viewDataLoaded: true, viewDataFailed: false });
      }
    });
  }

  iconFormatter = (cell, row) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'APARTMAN_EPULET', 'LEK') && (
          <React.Fragment>
            <Button className="icon-eye table-action-icon" id={'action-view-' + cell + 'megtekintes'} onClick={() => this.handleViewClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'megtekintes'} delay={0}>
              Megtekintés
            </UncontrolledTooltip>
          </React.Fragment>
        )}

        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'APARTMAN_EPULET', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => this.handleEditClick(cell, row)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'modositas'} delay={0}>
              Módosítás
            </UncontrolledTooltip>
            <Button className="icon-trash table-action-icon" id={'action-view-' + cell + 'torles'} onClick={() => this.handleDeleteClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'torles'} delay={0}>
              Törlés
            </UncontrolledTooltip>
            {/* <Button className="fa fa-sign-out table-action-icon" onClick={() => this.handleGdprClick(cell)} /> */}
          </React.Fragment>
        )}
      </div>
    );
  }

  renderStatusOptions = () => {
    return this.state.enums.map(e => <option key={e.name} value={e.name}>{e.caption}</option>);
  }

  renderStatusTypes = () => {
    let statusType = {};
    this.state.enums.map(e => Object.assign(statusType, { [JSON.stringify(e)]: e.caption }));
    return statusType;
  }

  /**
  * Visszaadja a partnercsoporthoz tartozó státus jelvényeket.
  * @param {boolean} cell - Cella adatot tartalmazó szöveg.
  * @return {ReactElement} Státusz jelvényt tartalmazó react objektum.
  */
  statusFormatter = (cell) => {
    if (!cell) return;
    if (cell === '{"name":"AKTIV","caption":"Aktív"}') {
      return <span className="badge badge-success">Aktív</span>;
    }
    return <span className="badge badge-default">Inaktív</span>;
  }

  addEpulet = (event, epuletObj) => {
    epuletObj.szallodaentity = { id: epuletObj.szalloda };

    Services.createEpulet(epuletObj, (err, res) => {
      if (!err) {
        this.toggleFelvitel();
        this.updateTable();
        this.props.globals.addSuccess('Épület felvéve!');
      }
    });
  }

  updateEpulet = (event, epuletObj) => {
    epuletObj.id = this._currentEditId;
    epuletObj.szallodaentity = { id: epuletObj.szalloda };

    Services.updateEpulet(epuletObj, (err, res) => {
      if (!err) {
        this.toggleFelvitel();
        this.updateTable();
        this.getEnums();
        this.props.globals.addSuccess('Épület módosítva!');
      }
    });
  }

  deleteEpulet = (id) => {
    this.toggleTorles();
    Services.deleteEpulet(id, (err, res) => {
      if (!err) {
        this.updateTable();
        this.props.globals.addSuccess('Épület törölve!');
      }
    });
  }

  /**
  * Visszaadja a paraméterben kapott path-oz tartozó szálloda nevét.
  * @return {Array.ReactElement} Szállodák .
  */
  renderOptions = () => {
    // Aktív szállodák.
    Services.listAktivSzallodak((err, res) => {
      if (!err) {
        let aktivSzallodak = res[0].map(e => <option key={e.id} value={e.id}>{e.nev}</option>);
        this.setState({ aktivSzallodak: aktivSzallodak });
      }
    });
  }

  renderTable = () => {
    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    // Státusz típusok meghatározása
    let statusType = {};
    if (this.state.enums.length) {
      this.state.enums.map(e => Object.assign(statusType, { [JSON.stringify(e)]: e.caption }));
    }

    return this.state.epuletJson.length ? (
      <BootstrapTable data={this.state.epuletJson} pagination options={options} >
        <TableHeaderColumn dataField="szalloda" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Szálloda</TableHeaderColumn>
        <TableHeaderColumn dataField="nev" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Épület neve</TableHeaderColumn>
        <TableHeaderColumn dataField="azon" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Épület azonosítója</TableHeaderColumn>
        {/* <TableHeaderColumn dataField="haz" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Ház</TableHeaderColumn> */}
        <TableHeaderColumn
          dataField="statusz"
          dataFormat={this.statusFormatter}
          filter={{ type: 'SelectFilter', options: this.renderStatusTypes(), placeholder: 'Összes' }}
          dataAlign="center"
        >
          Státusz
        </TableHeaderColumn>
        <TableHeaderColumn dataField="id" width="110" isKey dataFormat={this.iconFormatter}>
          Műveletek
        </TableHeaderColumn>
      </BootstrapTable>) : <DataEmptyState />;
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  renderAdatok = () => {
    return (this.state.dataViewLoaded ? (
      <div>
        <h6>Épület információ:</h6>
        <b>Szálloda: </b>{this.state.viewObj && this.state.viewObj.szallodanev}<br />
        <b>Épület neve: </b>{this.state.viewObj && this.state.viewObj.nev}<br />
        <b>Épület azonosítója: </b>{this.state.viewObj && this.state.viewObj.azonosito}<br />
        <b>Hotel RCI azonosító: </b>{this.state.viewObj && this.state.viewObj.hotelrciazon}<br />
        <b>Hotel HostWare azonosító: </b>{this.state.viewObj && this.state.viewObj.hotelhwazon}<br />
        {/* <b>Ház: </b>{this.state.viewObj && this.state.viewObj.haz}<br /> */}
        <b>Státusz: </b>{this.state.viewObj && this.state.viewObj.statusz && this.state.viewObj.statusz.caption}<br />
      </div>) : this.renderLoader()
    );
  }


  render() {
    return (
      <div>
        {(this.state.dataLoaded && !this.state.dataFailed) ? (
          <div className="row">
            <div className="col-sm-12">
              {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'APARTMAN_EPULET', 'KARB') && (
                <Button className="pull-left table-new-button" color="primary" onClick={this.handleNewClick}>
                  Új épület
                </Button>
              )}
            </div>
          </div>
        ) : <span />}
        {(this.state.dataLoaded && !this.state.dataFailed) ? this.renderTable() : (this.state.dataFailed) ? <NetworkEmptyState submitClick={this.updateTable} /> : this.renderLoader()}
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>
              Valóban törölni kívánja a kiválasztott tételt?
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteEpulet(this._currentDeleteId)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalNezet} toggle={this.toggleNezet} className={this.props.className} backdrop="static">
          <ModalHeader >Megtekintés</ModalHeader>
          <ModalBody>{this.state.modalNezet ? this.renderAdatok(this._currentViewId) : <div />}</ModalBody>
          <ModalFooter> <Button type="button" color="primary" onClick={this.toggleNezet} className="btn-cursor-pointer" >Vissza</Button></ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalFelvitel} toggle={this.toggleFelvitel} className={this.props.className} backdrop="static" >
          <AvForm onValidSubmit={_muvelet === 0 ? this.addEpulet : this.updateEpulet}>
            {_muvelet === 0 ? <ModalHeader>Új épület rögzítése</ModalHeader> : <ModalHeader>Meglévő épület módosítása</ModalHeader> }
            <ModalBody>
              <AvField type="select" name="szalloda" label="Szálloda: *" errorMessage="Kötelező mező!" required value={_modositoObj && _modositoObj.szallodaentity.id} disabled={_muvelet !== 0}>
                <option value="" disabled >Válassz szállodát...</option>
                {this.state.aktivSzallodak}
              </AvField>
              <AvGroup>
                <Label for="nev">Épület neve: *</Label>
                <AvInput name="nev" id="nev" required value={_modositoObj && _modositoObj.nev} maxLength="20" />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="azonosito">Épület azonosítója: *</Label>
                <AvInput name="azonosito" id="azonosito" required disabled={_muvelet !== 0} maxLength="5" value={_modositoObj && _modositoObj.azonosito} />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="hotelhwazon">Hotel HostWare azonosító: </Label>
                <AvInput name="hotelhwazon" id="hotelhwazon" maxLength="2" value={_modositoObj && _modositoObj.hotelhwazon} />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="hotelrciazon">Hotel RCI azonosító: </Label>
                <AvInput name="hotelrciazon" id="hotelrciazon" maxLength="4" value={_modositoObj && _modositoObj.hotelrciazon} />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
              {/* <AvGroup>
                <Label for="haz">Ház: *</Label>
                <AvInput name="haz" id="haz" required maxLength="5" value={_modositoObj && _modositoObj.haz} />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup> */}
              <AvField type="select" name="statusz" label="Státusz: *" errorMessage="Kötelező mező!" required value={_modositoObj && _modositoObj.statusz.name}>
                <option value="" disabled >Válassz státuszt...</option>
                <option value="AKTIV">Aktív</option>
                <option value="INAKTIV">Inaktív</option>
              </AvField>
            </ModalBody>
            <ModalFooter>
              <Button color="primary">Mentés</Button>{' '}
              <Button type="button" color="secondary" onClick={this.toggleFelvitel} >Mégse</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </div>
    );
  }
}

export default EpuletLista;
