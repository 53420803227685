import Lib from '../../commons/lib';

export default class Service {

  static cegadatUrl = window.location.origin + '/api/cegadat/';

  static rendelesUrl = window.location.origin + '/api/rendeles/';

  //EMAIL

  static listEmailSablon(param) { return new Promise((resolve, reject)=> {
    Lib.MicroServices.requestService([{
      method: "GET",
      url: Service.rendelesUrl + "email/list",
      args: {requestType: "sablon-list"},
    }], (err, res)=> {
      if (err) reject(err); else resolve(res[0]);
    });
  })}

  static listEmailSablonTipus(param) { return new Promise((resolve, reject)=> {
    Lib.MicroServices.requestService([{
      method: "GET",
      url: Service.rendelesUrl + "email/listtipus",
      args: {requestType: "sablon-listtipus"},
    }], (err, res)=> {
      if (err) reject(err); else resolve(res[0]);
    });
  })}

  static getEmailSablon(param) { return new Promise((resolve, reject)=> {
    Lib.MicroServices.requestService([{
      method: "GET",
      url: Service.rendelesUrl + "email/",
      args: {requestType: "sablon-get"},
      params: param
    }], (err, res)=> {
      if (err) reject(err); else resolve(res[0]);
    });
  })}

  static addEmailSablon(param) { return new Promise((resolve, reject)=> {
    Lib.MicroServices.requestService([{
      method: "POST",
      url: Service.rendelesUrl + "email/",
      args: {requestType: "sablon-add"},
      data: param,
    }], (err, res)=> {
      if (err) reject(err); else resolve(res[0]);
    });
  })}

  static editEmailSablon(param) { return new Promise((resolve, reject)=> {
    Lib.MicroServices.requestService([{
      method: "PUT",
      url: Service.rendelesUrl + "email/",
      args: {requestType: "sablon-edit"},
      params: {id: param.id},
      data: param,
    }], (err, res)=> {
      if (err) reject(err); else resolve(res[0]);
    });
  })}

  static deleteEmailSablon(param) { return new Promise((resolve, reject)=> {
    Lib.MicroServices.requestService([{
      method: "DELETE",
      url: Service.rendelesUrl + "email/",
      args: {requestType: "sablon-delete"},
      params: param
    }], (err, res)=> {
      if (err) reject(err); else resolve(res[0]);
    });
  })}

  static downloadObject(param) { return new Promise((resolve, reject)=> {
    Lib.MicroServices.requestService([{
      method: "GET",
      url: Service.rendelesUrl + "email/object",
      args: {requestType: "sablon-object"},
      params: {id: param.id},
      acceptType: 'application/json; charset=utf-8, text/plain; charset=utf-8, application/octet-stream',
    }], (err, res)=> {
      if (err) reject(err); else resolve(res[0]);
    });
  })}

  // EMAIL


  // CÉGADAT START

  static listCegadat(param, fnDone) {
    Lib.MicroServices.requestService([{
      method: "PUT",
      url: Service.cegadatUrl + "search/",
      args: {requestType: "cegadat-list"},
      params: {
        resultclass: 90,
        aktiv: true
      },
    }], fnDone);
  }

  // CÉGADAT END

}