const tooltips = {
  rendelesek: 'A Rendelések modul szolgál a felhasználó ajánlatainak, rendeléseinek nyilvántartására.',
  ajanlat: 'A vevői ajánlatok adatainak rögzítésére és nyilvántartására szolgáló felület. A nyilvántartás működéséhez szükséges törzsadatok: cégadat, partner törzs, cikktörzs.\nÚj ajánlat rögzíthető az Új ajánlat nyomógombbal és korábban rögzített ajánlat másolásával, duplikálásával (Műveletekben található ikon).\nA rögzítés könnyítéséhez az érvényességi idő a Beállítások felületen paraméterezhető, ekkor új ajánlat rögzítésekor mindig a megadott nappal számoljuk ki az ajánlat érvényességének az idejét.\nA rögzített ajánlatok nyomtathatók (pdf generálás) a személyes ügyintézéshez, illetve küldhetőek emailben. Az emailek sablonja szabadon szerkeszthető a felhasználó által az Email sablonokmenüpontban. A felhasználó által rögzített sablonból kerül átemelésre a tárgy és a bevezető szöveg az új rendelés rögzítésekor.\nA fentieknek megfelelően a rendeléseknek 4 különböző állapota lehet: rögzített, kiküldött (nyomtatott, emailben küldött), vevői rendeléshez kapcsolt, meghiúsult.\nA kiküldött státuszú ajánlatok adatai a vevői rendelések rögzítésekor átemelhetők.\n',
  rendeles: 'Vevői rendelések kezelésére szolgáló felület. Megrendelés adatai rögzíthetők a felhasználó által szabadon, vagy kiküldött státuszú ajánlatok tételeinek átemelésével. Azok a vevői ajánlat tételek emelhetők be a rendelésbe, amelyeknek még van vevői rendeléshez nem kapcsolt tétele. Egy vevői ajánlat csak egy rendeléshez kapcsolható.\nA rögzítés könnyítésére a felhasználónak lehetősége van a szállítási és fizetési határidő általános beállítására (hány napos határidőt használ) a Beállítások felületen.\nA vevői rendelésből a felhasználónak a rendszer lehetőséget biztosít visszaigazolás generálására pdf formátumban és emailben.\nAmennyiben a felhasználó a rendszerben a készlet nyilvántartást is vezet, úgy a vevői rendelések adatai átemelhetőek lesznek a készletcsökkenési bizonylatra rögzítéskor.\n',
  szallitoi: 'Szállítói rendelések kezelésére szolgáló felület. A felületen találhatók olyan törzsadatok, melyek a rendszer más menüpontjaiban rögzíthetőek (szállító partner, cégadat, pénznem, fizetési mód, cikk) és olyan paraméter is, amelyet a szállítói rendelés felületen lehet rögzíteni és kiválasztani (szállítási mód).\nA szállítói rendelések esetén cikkszámonként rögzítheti a felhasználó a visszaigazolás dátumát a visszaigazolt mennyiséget, ezáltal ütemezhető a készlet rendelkezésre állása.\nAmennyiben mégis módosulnak ezek az adatok, a meglévő tételek a bontás funkció segítségével bonthatók a visszaigazolási adatoknak megfelelően. Ez azért is lehet fontos, hogy a készlet bevételezési bizonylatra a rendelés tételek könnyen beválaszthatóak legyenek.\nA szállítói rendelések nyomtathatóak (pdf), emailben küldhetőek. Teljesülésük esetén pedig lezárhatóak.\n',
  rendelesAdoszam: 'Amennyiben a Vevő rendelkezik adószámmal, akkor az adószámnak itt szerepelnie kell, ha az adószám mégsem szerepelne a mezőben, akkor azt a partnertörzsben pótolni kell.\n',
};

export default function getTooltip(name) {
  return tooltips[name];
}
