import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Button,
  InputGroup,
  InputGroupAddon,
  Label,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from 'reactstrap';
import {
  BootstrapTable,
  TableHeaderColumn
} from 'react-bootstrap-table';
import {
  AvFeedback,
  AvField, 
  AvForm,
  AvGroup,
  AvInput
} from 'availity-reactstrap-validation';
import Moment from 'moment';
import _ from 'lodash';
import Select from 'react-select/lib/Async';
import MomentTimezone from 'moment-timezone';
import momentLocalizer from 'react-widgets-moment';
import { browserHistory } from 'react-router';
import Excel from 'exceljs';
import FileSaver from 'file-saver';
import Lib from '../../commons/lib';
import DataEmptyState from '../../commons/dataemptystate';
import NetworkEmptyState from '../../commons/networkemptystate';
import QueryEmptyState from '../../commons/queryempty';
import Loader from '../../commons/loader';
import Services from './services';
import { DatePicker, PaginationTotal } from '../../commons/controls';
import { ProgressButton } from '../../commons/ProgressButton';

momentLocalizer(Moment);

class FeladatlistaContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // ['QUERY','LOAD', 'DATA', 'NETW', 'TABL']
      tableStatus: 'QUERY',
      formStatus: 'LOAD',
      modalTorles: false,
      editedRow: null,
      editedData: [],
      modalVeglegesites: false,
      torlesId: null,
      veglegesitesId: null,
      baseJson: [],
      deleteButtonLoading: false,
      veglegesitesButtonLoading: false,
      modalView: false,
      viewObj: null,
      allapotOptions: [],
      tipusOptions: [],
      prioritasOptions: [],
      // ELOSZURES
      formKereses: '',
      formPartner: '',
      formFeladatAzon: '',
      formFeladatTipus: '',
      formAllapot: '',
      formPrioritas: '',
      formLetrehozvaTol: null,
      formLetrehozvaIg: null,
      formEsedekessegTol: null,
      formEsedekessegIg: null
    };
    this.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  componentDidMount() {
    this.getFeladatListaInit();
  }

  toggleTorles = () => {
    this.setState(prevState => ({ modalTorles: !prevState.modalTorles }));
  }

  toggleVeglegesites = () => {
    this.setState(prevState => ({ modalVeglegesites: !prevState.modalVeglegesites }));
  }

  toggleViewModal = () => {
    this.setState(prevState => ({ modalView: !prevState.modalView }));
  }

  getFeladatListaInit = () => {
    this.setState({
      searchButtonLoading: true
    });
    Services.getFeladatListInit((err, res) => {
      if (!err) {
        let allapotOptions = res[0].allapot.map(e => <option key={e.id} value={e.id}>{e.nev}</option>);
        let tipusOptions = res[0].tipus.map(e => <option key={e.id} value={e.id}>{e.nev}</option>);
        let prioritasOptions = res[0].prioritas.map(e => <option key={e.name} value={e.name}>{e.caption}</option>);
        this.setState({
          allapotOptions,
          tipusOptions,
          prioritasOptions,
          searchButtonLoading: false
        });
      } else {
        this.setState({
          searchButtonLoading: false
        });
      }
    });
  }

  getFeladatListaList = () => {
    const {
      formFeladatAzon,
      formFeladatTipus,
      formAllapot,
      formPrioritas,
      formLetrehozvaTol,
      formLetrehozvaIg,
      formEsedekessegTol,
      formEsedekessegIg,
      formPartner,
      formKereses
    } = this.state;

    this.setState({
      tableStatus: 'LOAD',
      editedRow: null,
      searchButtonLoading: true
    });

    let filters = {};
    filters.orszagid = (formKereses && formKereses.telepulesid) ? 97 : null;
    filters.iranyitoszamid = this.emptyOrNull(formKereses && formKereses.irszid);
    filters.telepulesid = this.emptyOrNull(formKereses && formKereses.telepulesid);
    filters.azonosito = this.emptyOrNull(formFeladatAzon);
    filters.allapotid = this.emptyOrNull(formAllapot);
    filters.partnerid = this.emptyOrNull(formPartner && formPartner.id);
    filters.letrehozas = {
      sta: this.dateToTimezone(formLetrehozvaTol),
      end: this.dateToTimezone(formLetrehozvaIg)
    };
    filters.esedekesseg = {
      sta: this.dateToTimezone(formEsedekessegTol),
      end: this.dateToTimezone(formEsedekessegIg)
    };
    filters.tipusid = this.emptyOrNull(formFeladatTipus);
    filters.prioritas = this.emptyOrNull(formPrioritas);

    Services.getFeladatList(filters, (err, res) => {
      if (!err) {
        this.setState({
          tableStatus: 'TABL',
          baseJson: res[0],
          searchButtonLoading: false
        });
      } else {
        this.setState({
          tableStatus: 'QUERY',
          searchButtonLoading: false
        });
      }
    });
  }

  processRow = (row) => {
    let finalVal = '';
    for (let j = 0; j < row.length; j++) {
      let innerValue = row[j] === null ? '' : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) {
        result = '"' + result + '"';
      }
      if (j > 0) {
        finalVal += ',';
      }
      finalVal += result;
    }
    return finalVal + '\n';
  }

  /* exportCSV = () => {
    let filename = 'feladatok_' + this.todayDate() + '.csv';
    let rows = [];
    rows.push(['Prioritás', 'Azonosító', 'Feladat típusa', 'Ügyfél', 'Helyszín', 'Esedékesség', 'Állapot']);
    let csvFile = '';
    for (let i = 0; i < rows.length; i++) {
      csvFile += this.processRow(rows[i]);
    }

    let blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      let link = document.createElement('a');
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  } */

  exportCSV = () => {
    let csv = '';
    let filename = 'feladatok_' + this.todayDate() + '.csv';
    let rows = [];
    rows.push(['Prioritás', 'Azonosító', 'Feladat típusa', 'Ügyfél', 'Helyszín', 'Létrehozva', 'Esedékesség', 'Állapot']);
    const datas = JSON.parse(JSON.stringify(this.state.baseJson));
    datas.forEach((element) => {
      rows.push([
        element.prioritas.caption, element.azonosito, element.tipus, element.partner, element.tejlescim,
        this.timezoneToDate(element.letrehozas), this.timezoneToDate(element.esedekesseg), element.allapot
      ]);
    });
    for (let i = 0; i < rows.length; i++) {
      let row = rows[i];
      for (let j = 0; j < row.length; j++) {
        let val = row[j] === null || row[j] === undefined ? '' : row[j].toString();
        val = val.replace(/\t/gi, ' ');
        if (j > 0) {
          csv += '\t';
        }
        csv += val;
      }
      csv += '\n';
    }

    // for UTF-16
    let cCode; let
      bArr = [];
    bArr.push(255, 254);
    for (let i = 0; i < csv.length; ++i) {
      cCode = csv.charCodeAt(i);
      bArr.push(cCode & 0xff);
      bArr.push(cCode / 256 >>> 0);
    }

    let blob = new Blob([new Uint8Array(bArr)], { type: 'text/csv;charset=UTF-16LE;' });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(blob, filename);
    } else {
      let link = document.createElement('a');
      if (link.download !== undefined) {
        let url = window.URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      }
    }
  }

  exportXlsx = () => {
    const datas = JSON.parse(JSON.stringify(this.state.baseJson));
    let xlsDatas = [];
    datas.forEach((element) => {
      xlsDatas.push([
        element.prioritas.caption, element.azonosito, element.tipus, element.partner, element.tejlescim, this.timezoneToDate(element.letrehozas), this.timezoneToDate(element.esedekesseg), element.allapot
      ]);
    });

    const wb = new Excel.Workbook();
    const ws = wb.addWorksheet('Feladatok');

    ws.columns = [
      { key: 'prioritas', width: 30 },
      { key: 'azonosito', width: 35 },
      { key: 'tipus', width: 30 },
      { key: 'partner', width: 35 },
      { key: 'tejlescim', width: 55 },
      { key: 'letrehozas', width: 35 },
      { key: 'esedekesseg', width: 35 },
      { key: 'allapot', width: 30 },
    ];

    ws.addTable({
      name: 'Táblázat',
      ref: 'A1',
      headerRow: true,
      style: {
        theme: 'TableStyleLight1',
        showRowStripes: true,
      },
      columns: [
        { name: 'Prioritás', filterButton: true },
        { name: 'Azonosító', filterButton: true },
        { name: 'Feladat típusa', filterButton: true },
        { name: 'Ügyfél', filterButton: true },
        { name: 'Helyszín', filterButton: true },
        { name: 'Létrehozva', filterButton: true },
        { name: 'Esedékesség', filterButton: true },
        { name: 'Állapot', filterButton: true },
      ],
      rows: xlsDatas,
    });

    ws.getRow(1).alignment = 'left';

    if (datas.length > 0) {
      wb.xlsx.writeBuffer()
        .then(buffer => FileSaver.saveAs(new Blob([buffer]), 'feladatok_' + this.todayDate() + '.xlsx'))
        .catch();
    }
  }

  todayDate = () => {
    let date = new Date();
    let month = date.getMonth() + 1;
    return date.getFullYear() + '_' + (month < 10 ? '0' + month : month) + '_' + date.getDate();
  }

  emptyOrNull = (data) => {
    if (!data || data === '') return null;
    return data;
  }

  deleteFeladat = () => {
    this.setState({
      deleteButtonLoading: true
    });
    Services.deleteFeladat(this.state.torlesId, (err) => {
      if (!err) {
        this.toggleTorles();
        this.getFeladatListaList();
        this.props.globals.addSuccess('Feladat törölve!');
        this.setState({
          deleteButtonLoading: false
        });
      } else {
        this.setState({
          deleteButtonLoading: false
        });
      }
    });
  }

  endFeladat = () => {
    this.setState({
      veglegesitesButtonLoading: true
    });
    Services.endFeladat(this.state.veglegesitesId, (err) => {
      if (!err) {
        this.toggleVeglegesites();
        this.getFeladatListaList();
        this.props.globals.addSuccess('Feladat véglegesítve!');
        this.setState({
          veglegesitesButtonLoading: false
        });
      } else {
        this.setState({
          veglegesitesButtonLoading: false
        });
      }
    });
  }

  getData = (id, copy) => {
    this.setState({
      formStatus: 'LOAD',
      // formId: id
    });
    Services.getFeladat(id, copy, (err, res) => {
      if (!err) {
        this.setState({
          viewObj: res[0],
          formStatus: 'DATA'
        });
      } else {
        this.setState({
          formStatus: 'NETW'
        });
      }
    });
  }

  getFeladatAllapotok = (id) => {
    Services.getFeladatAllapotok(id, (err, res) => {
      if (!err) {
        this.setState({
          editedRow: id,
          editedData: res[0]
        });
      }
    });
  }

  saveFeladatAllapot = (fid, row) => {
    Services.saveFeladatAllapot(fid, row.id, (err, res) => {
      if (!err) {
        this.setState({
          editedRow: null,
        }, () => {
          let datas = JSON.parse(JSON.stringify(this.state.baseJson));
          datas.forEach((element) => {
            if (fid == element.id) {
              element.modositastorles = (res[0] == true);
              element.allapot = row.allapotnev;
              element.allapotszin = row.allapotszin;
            }
          });
          this.setState({ baseJson: datas });
        });
      }
    });
  }

  loadPartnerChange = (input, callback) => {
    if (input.length !== 0) {
      Services.searchPartnerList(input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            let newItem = item;
            newItem.nev = newItem.nev + ' (' + newItem.azon + ') ';
            newArray.push(newItem);
          });
          callback(newArray);
        }
      });
    }
  }

  loadKeresesOptions = (input, callback) => {
    if (input.length !== 0) {
      Services.listCim(input, (err, res) => {
        if (!err) {
          let options = [];
          res[0].forEach((item) => {
            item.telepuirszek.forEach((iranyitoszam) => {
              options.push({
                telepulesnev: item.telepulesnev + ' (' + iranyitoszam.iranyitoszam + ')',
                telepulesid: item.telepulesid,
                iranyitoszam: iranyitoszam.iranyitoszam,
                irszid: iranyitoszam.telepuirszid,
                nev: item.telepulesnev,
                // irsz:
              });
            });
          });
          callback(options);
        }
      });
    }
  }

  handleNewClick = () => {
    this.setState({
    }, () => {
      browserHistory.push({
        pathname: '/feladatlista/felvetel',
      });
    });
  }

  handleViewClick = (cell) => {
    this.setState({
      formStatus: 'LOAD',
      currentId: cell,
    }, () => {
      this.getData(cell, false);
      this.toggleViewModal();
    });
  }

  handleEditClick = (id) => {
    this.setState({
    }, () => {
      browserHistory.push({
        pathname: '/feladatlista/modositas',
        search: '?id=' + id,
        state: {
          feladatId: id
        }
      });
    });
  }

  handleIsmetlodoClick = (id) => {
    this.setState({
    }, () => {
      browserHistory.push({
        pathname: '/feladatlista/masolas',
        search: '?id=' + id,
        state: {
          feladatId: id
        }
      });
    });
  }

  handleVeglegesitesClick = (cell) => {
    this.setState({
      veglegesitesId: cell
    }, () => {
      this.toggleVeglegesites();
    });
  }

  handleDeleteClick = (cell) => {
    this.setState({
      torlesId: cell
    }, () => {
      this.toggleTorles();
    });
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  handleDateInputChange = (e, v, name) => {
    const value = v === '' ? null : v;
    this.setState({
      [name]: value
    });
  }

  handleChangePartner = (value) => {
    if (value && value !== '') {
      this.setState({
        formPartner: value,
      });
    } else {
      this.setState({
        formPartner: '',
      });
    }
  }

  handleChangeKeresesInput = (value) => {
    if (value && value !== '') {
      this.setState({
        formKereses: value,
        formTelepulesNev: value.nev,
        formIranyitoszamNev: value.iranyitoszam
      });
    } else {
      this.setState({
        formKereses: '',
        formTelepulesNev: '',
        formIranyitoszamNev: ''
      });
    }
  }

  tableIconFormatter = (cell, row) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'FELADATLISTA_FELADAT', 'LEK') && (
          <React.Fragment>
            <Button className="icon-eye table-action-icon" id={'action-view-' + cell + 'view'} onClick={() => this.handleViewClick(cell)} />
            <UncontrolledTooltip placement="right" target={'action-view-' + cell + 'view'} delay={50}>
              {'Megtekintés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'FELADATLISTA_FELADAT', 'KARB') && (
          <React.Fragment>
            {row.modositastorles && (
              <React.Fragment>
                <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => this.handleEditClick(cell)} />
                <UncontrolledTooltip placement="right" target={'action-view-' + cell + 'modositas'} delay={50}>
                  {'Módosítás'}
                </UncontrolledTooltip>
              </React.Fragment>
            )}
            {row.modositastorles && (
              <React.Fragment>
                <Button className="icon-trash table-action-icon" id={'action-view-' + cell + 'torles'} onClick={() => this.handleDeleteClick(cell)} />
                <UncontrolledTooltip placement="right" target={'action-view-' + cell + 'torles'} delay={50}>
                  {'Törlés'}
                </UncontrolledTooltip>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    );
  }

  tablePrioritasFormatter = (cell) => {
    return <Badge color={cell.color}>{cell.caption}</Badge>;
  }

  renderTipusSelect = (fid) => {
    return (
      <React.Fragment>
        {this.state.editedData.map((row) => {
          return (
            <React.Fragment>
              <Badge
                style={{
                  backgroundColor: '#' + row.allapotszin,
                  cursor: 'pointer'
                }}
                onClick={() => { this.saveFeladatAllapot(fid, row); }}
              >
                {row.allapotnev}
              </Badge>
              <br />
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }

  tableAllapotFormatter = (cell, row) => {
    return (this.state.editedRow == row.id ? (
      this.renderTipusSelect(row.id)
    ) : (
      <Badge
        style={{
          backgroundColor: '#' + row.allapotszin,
          cursor: 'pointer'
        }}
        onClick={() => { this.getFeladatAllapotok(row.id); }}
      >
        {row.allapot}
        <span className="icon-pencil table-action-icon" />
      </Badge>
    )
    );
  }

  renderTable = () => {
    const options = {
      sizePerPage: 25,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    return (
      <div className="animated fadeIn">
        <div className="row">
          <div className="col-md-12" style={{ marginTop: 20 }}>
            <Button className="pull-right table-new-button" color="success" style={{ marginLeft: 10 }} onClick={this.exportXlsx}>
              {'Exportálás XLSX-be'}
            </Button>
            {' '}
            <Button className="pull-right table-new-button" color="success" onClick={this.exportCSV}>
              {'Exportálás CSV-be'}
            </Button>
          </div>
          <div className="col-md-12">
            <BootstrapTable
              data={this.state.baseJson}
              options={options}
              pagination
            >
              <TableHeaderColumn
                dataField="prioritas"
                tdStyle={{ whiteSpace: 'normal', textAlign: 'center' }}
                // filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
                dataFormat={this.tablePrioritasFormatter}
              >
                {'Prioritás'}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataSort
                dataField="azonosito"
                tdStyle={{ whiteSpace: 'pre-line' }}
                filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              >
                {'Azonosító'}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataSort
                dataField="tipus"
                tdStyle={{ whiteSpace: 'pre-line' }}
                filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              >
                {'Típus'}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataSort
                dataField="partner"
                tdStyle={{ whiteSpace: 'normal' }}
                filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              >
                {'Ügyfél'}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataSort
                dataField="tejlescim"
                tdStyle={{ whiteSpace: 'normal' }}
                filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              >
                {'Helyszín'}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataSort
                dataField="letrehozas"
                tdStyle={{ whiteSpace: 'normal', textAlign: 'center' }}
                filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
                dataFormat={this.idopontFormatter}
                >
                {'Létrehozva'}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataSort
                dataField="esedekesseg"
                tdStyle={{ whiteSpace: 'normal', textAlign: 'center' }}
                filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
                dataFormat={this.idopontFormatter}
              >
                {'Esedékesség'}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="allapot"
                tdStyle={{ whiteSpace: 'normal', textAlign: 'center' }}
                // filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
                dataFormat={this.tableAllapotFormatter}
              >
                {'Állapot'}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField="id"
                isKey
                // hidden={!Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'FELADATLISTA_FELADAT', 'KARB')}
                width="150"
                dataFormat={this.tableIconFormatter}
              >
                {'Műveletek'}
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        </div>
      </div>
    );
  }

  renderView = () => {
    const {
      viewObj
    } = this.state;

    return (
      <div>
        <h6>Feladat:</h6>
        <b>Azonosító: </b>
        {viewObj && viewObj.azonosito}
        <br />
        <b>Funkció: </b>
        {viewObj && viewObj.funkcio}
        <br />
        <b>Kapcsolódó azonosító: </b>
        {viewObj && viewObj.kapcsolodoazonosito}
        <br />
        <b>Típus: </b>
        {viewObj && viewObj.tipus}
        <br />
        <b>Állapot: </b>
        {viewObj && viewObj.allapot}
        <br />
        <b>Prioritás: </b>
        <Badge color={viewObj && viewObj.prioritas && viewObj.prioritas.color}>{viewObj && viewObj.prioritas && viewObj.prioritas.caption}</Badge>
        <br />
        <b>Esedékesség: </b>
        {viewObj && this.timezoneToDate(viewObj.esedekesseg)}
        <br />
        <b>Feladat leírása: </b>
        <div style={{ whiteSpace: 'pre-line' }}>
          {viewObj && viewObj.leiras}
        </div>
        <br />
        <br />
        <h6>Ügyfél adatok:</h6>
        <b>Partner: </b>
        {(viewObj && viewObj.partner && viewObj.partner.nev)}
        {' ('}
        {(viewObj && viewObj.partner && viewObj.partner.azon)}
        {')'}
        <br />
        <b>Cím: </b>
        {viewObj && viewObj.teljescim}
        <br />
        <b>Kapcsolattartó neve: </b>
        {viewObj && viewObj.kapcsolattartonev}
        <br />
        <b>Kapcsolattartó elérhetősége: </b>
        {viewObj && viewObj.kapcsolattartoelerhetoseg}
        <br />
        <br />
        <b>Rögzítő felhasználó: </b>
        {viewObj && viewObj.rogzuser}
        <br />
        <b>Rögzítés ideje: </b>
        {viewObj && this.timezoneToDate(viewObj.rogzido)}
        <br />
        {this.renderModositoAdatok(viewObj)}
        {/* {viewObj && viewObj.feladatcikk && Array.isArray(viewObj.feladatcikk) && viewObj.feladatcikk.length > 0 */}
        {/* && this.renderEllenorzesiListak(viewObj)} */}
        {viewObj && viewObj.metaadat && Array.isArray(viewObj.metaadat) && viewObj.metaadat.length > 0 && this.renderEgyebTulajdonsagok(viewObj)}
      </div>
    );
  }

  renderModositoAdatok = (viewObj) => {
    if (viewObj && viewObj.moduser && viewObj.modido) {
      return (
        <React.Fragment>
          <br />
          <b>Módosító felhasználó: </b>
          {viewObj && viewObj.moduser}
          <br />
          <b>Módosítás ideje: </b>
          {viewObj && this.timezoneToDate(viewObj.modido)}
          <br />
        </React.Fragment>
      );
    }

    return '';
  }

  renderEllenorzesiListak = (viewObj) => {
    return (
      <React.Fragment>
        <br />
        <b>
          {'Ellenőrzési listák: '}
        </b>
        <br />
        <ul style={{ paddingLeft: '1rem', marginBottom: 0 }}>
          {viewObj.feladatcikk.map((item) => {
            return (
              <span>
                <b>{item.cikkbean.n + (item.karbcikkid ? ' karbantartott' : '') + ' cikkhez:'}</b>
                <ul style={{ marginBottom: 0, listStyle: 'square' }}>
                  {item.elllistak.map((ell) => {
                    return (
                      <span>
                        <li>{ell.nev}</li>
                      </span>
                    );
                  })}
                </ul>
              </span>
            );
          })}
        </ul>
      </React.Fragment>
    );
  }

  renderEgyebTulajdonsagok = (viewObj) => {
    return (
      <React.Fragment>
        <br />
        <b>
          {'Kiegészítő adatok: '}
        </b>
        <ul style={{ paddingLeft: '1rem', listStyle: 'none' }}>
          {viewObj.metaadat.map((item) => {
            return (
              <span>
                <li>
                  <b>{item[0] + ': '}</b>
                  {item[1]}
                </li>
              </span>
            );
          })}
        </ul>
      </React.Fragment>
    );
  }

  idopontFormatter = (cell) => {
    return this.timezoneToDate(cell);
  }

  dateToTimezone = (date) => {
    if (!date || date === '') return null;
    return MomentTimezone.tz(date, this.tz).format('YYYY-MM-DDT00:00:00.000ZZ');
  }


  timezoneToDate = (date) => {
    if (!date || date === '') {
      return null;
    }

    return MomentTimezone.tz(date, this.tz).format('YYYY-MM-DD HH:mm');
  }

  renderEloSzures = () => {
    const {
      formFeladatAzon,
      formLetrehozvaTol,
      formLetrehozvaIg,
      formEsedekessegTol,
      formEsedekessegIg,
      formPartner,
      formKereses
    } = this.state;

    return (
      <AvForm onValidSubmit={() => {}}>
        <div className="row">
          <div className="col-md-12">
            <h5 style={{ marginBottom: 20 }}>
              Feladatok:
            </h5>
          </div>
          {/* <div className="col-md-6 form-group">
            <Label for="szallitoId">
              Ügyfél:
            </Label>
             <Select.Async
              name="szallitoId"
              id="szallitoId"
              placeholder="Kezdjen el gépelni..."
              noResultsText="Nincs keresési eredmény"
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingPlaceholder="Keresés..."
              onChange={v => this.handleChangeSzamlakibocsato(v)}
              clearable
              ignoreAccents={false}
              loadOptions={_.debounce((input, callback) => this.loadSzamlakibocsatoChange(input, callback), 1000)}
              isLoading={false}
              valueKey="id"
              labelKey="nev"
              value={this.state.szamlakibocsatoValue}
              cache={false}
              autoload={false}
            />
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="szallitoId"
              id="szallitoId"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              // onChange={v => this.handleChangeSzamlakibocsato(v)}
              isClearable
              ignoreAccents={false}
              // loadOptions={_.debounce(this.loadSzamlakibocsatoChange, 1000)}
              isLoading={false}
              getOptionLabel={option => option.nev}
              getOptionValue={option => option.id}
              value={this.state.szamlakibocsatoValue}
              // disabled={this.state.fizTeljElerheto}
              cache={false}
              autoload={false}
            />
          </div> */}
          <div className="col-md-3">
            <AvGroup>
              <Label for="formFeladatAzon">Feladat azonosítója:</Label>
              <AvInput
                name="formFeladatAzon"
                id="formFeladatAzon"
                value={formFeladatAzon}
                onChange={this.handleInputChange}
                type="text"
                maxLength="50"
                autoComplete="off"
              />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-3">
            <Label for="formKereses">Helyszín: </Label>
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="formKereses"
              id="formKereses"
              placeholder="Település vagy irányítószám"
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeKeresesInput(v)}
              isClearable
              ignoreAccents={false}
              // loadOptions={(input, callback) => this.loadKeresesOptions(input, callback)}
              loadOptions={_.debounce(this.loadKeresesOptions, 1000)}
              getOptionLabel={option => option.telepulesnev}
              getOptionValue={option => option.telepulesid}
              isLoading={false}
              value={formKereses}
              cache={false}
              autoload={false}
              isDisabled={this.state.formFeladatAzon}
            />
          </div>
          <div className="col-md-2 form-group">
            <AvField
              ref="formFeladatTipus"
              type="select"
              name="formFeladatTipus"
              id="formFeladatTipus"
              value={this.state.formFeladatTipus}
              onChange={this.handleInputChange}
              label="Feladat típusa: "
              disabled={this.state.formFeladatAzon}
            >
              <option value="">Válassz értéket</option>
              {this.state.tipusOptions}
            </AvField>
          </div>
          <div className="col-md-2 form-group">
            <AvField
              ref="formAllapot"
              type="select"
              name="formAllapot"
              id="formAllapot"
              value={this.state.formAllapot}
              onChange={this.handleInputChange}
              label="Állapot: "
              disabled={this.state.formFeladatAzon}
            >
              <option value="">Válassz értéket</option>
              {this.state.allapotOptions}
            </AvField>
          </div>
          <div className="col-md-2 form-group">
            <AvField
              ref="formPrioritas"
              type="select"
              name="formPrioritas"
              id="formPrioritas"
              value={this.state.formPrioritas}
              onChange={this.handleInputChange}
              label="Prioritás: "
              disabled={this.state.formFeladatAzon}
            >
              <option value="">Válassz értéket</option>
              {this.state.prioritasOptions}
            </AvField>
          </div>
          <div className="col-md-4">
            <Label for="formPartner">Partner: </Label>
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="formPartner"
              id="formPartner"
              // placeholder="Kezdjen el gépelni..."
              placeholder="Keresés partner neve alapján..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangePartner(v)}
              clearable
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadPartnerChange, 1000)}
              isLoading={false}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.nev}
              value={formPartner}
              cache={false}
              autoload={false}
              isDisabled={this.state.formFeladatAzon}
            />
          </div>
          <div className="col-md-2 form-group">
            <Label for="formLetrehozvaTol">
              Létrehozva(-tól):
            </Label>
            <DatePicker
              type="select"
              name="formLetrehozvaTol"
              value={formLetrehozvaTol && new Date(formLetrehozvaTol)}
              onChange={(e, v) => this.handleDateInputChange(e, v, 'formLetrehozvaTol')}
              // onChange={this.handleTeljesitesChange}
              disabled={this.state.formFeladatAzon}
            />
          </div>
          <div className="col-md-2 form-group">
            <Label for="formLetrehozvaIg">
              Létrehozva(-ig):
            </Label>
            <DatePicker
              type="select"
              name="formLetrehozvaIg"
              value={formLetrehozvaIg && new Date(formLetrehozvaIg)}
              onChange={(e, v) => this.handleDateInputChange(e, v, 'formLetrehozvaIg')}
              // onChange={this.handleTeljesitesChange}
              disabled={this.state.formFeladatAzon}
            />
          </div>
          <div className="col-md-2 form-group">
            <Label for="formEsedekessegTol">
              Esedékesség(-tól):
            </Label>
            <DatePicker
              type="select"
              name="formEsedekessegTol"
              value={formEsedekessegTol && new Date(formEsedekessegTol)}
              onChange={(e, v) => this.handleDateInputChange(e, v, 'formEsedekessegTol')}
              // onChange={this.handleTeljesitesChange}
              disabled={this.state.formFeladatAzon}
            />
          </div>
          <div className="col-md-2 form-group">
            <Label for="formEsedekessegIg">
              Esedékesség(-ig):
            </Label>
            <DatePicker
              type="select"
              name="formEsedekessegIg"
              value={formEsedekessegIg && new Date(formEsedekessegIg)}
              onChange={(e, v) => this.handleDateInputChange(e, v, 'formEsedekessegIg')}
              // onChange={this.handleTeljesitesChange}
              disabled={this.state.formFeladatAzon}
            />
          </div>

          <div className="col-md-12">
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'FELADATLISTA_FELADAT', 'KARB') && (
              <Button className="pull-left table-new-button" color="primary" onClick={this.handleNewClick}>
                {'Új feladat'}
              </Button>
            )}
            {' '}
            <ProgressButton
              className="btn-cursor-pointer pull-right"
              color="primary"
              loading={this.state.searchButtonLoading}
              onClick={() => {
                this.getFeladatListaList();
              }}
            >
              {'Keresés'}
            </ProgressButton>
          </div>
        </div>
      </AvForm>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderEloSzures()}
        <div className="row">
          <div className="col-md-12">
            {{
              QUERY: (<QueryEmptyState
                hasImage
              />),
              LOAD: (<Loader />),
              DATA: (<DataEmptyState
                hasImage
                subtitle="Vigyél fel új tételt!"
              />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.getFeladatListaList()}
              />),
              TABL: (this.renderTable())
            }[this.state.tableStatus]}
          </div>
        </div>
        <Modal isOpen={this.state.modalView} toggle={this.toggleViewModal} backdrop="static">
          <ModalHeader>Megtekintés</ModalHeader>
          <ModalBody>
            {{
              LOAD: (<Loader />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.getData(this.state.currentId, false)}
              />),
              DATA: (this.renderView())
            }[this.state.formStatus]}
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" className="btn-cursor-pointer" onClick={this.toggleViewModal}>Vissza</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>Valóban törölni kívánja a kiválasztott tételt?</ModalBody>
          <ModalFooter>
            <ProgressButton
              className="btn-cursor-pointer"
              color="primary"
              onClick={() => this.deleteFeladat()}
              loading={this.state.deleteButtonLoading}
            >
              {'Igen'}
            </ProgressButton>
            <Button type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalVeglegesites} toggle={this.toggleVeglegesites} backdrop="static">
          <ModalHeader>Véglegesítés</ModalHeader>
          <ModalBody>Valóban véglegesíteni kívánja a kiválasztott tételt?</ModalBody>
          <ModalFooter>
            <ProgressButton
              className="btn-cursor-pointer"
              color="primary"
              onClick={() => this.endFeladat()}
              loading={this.state.veglegesitesButtonLoading}
            >
              {'Igen'}
            </ProgressButton>
            <Button type="button" color="secondary" onClick={this.toggleVeglegesites}>Mégsem</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

FeladatlistaContent.propTypes = {
  globals: PropTypes.object
};

export default FeladatlistaContent;
