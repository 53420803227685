import Lib from '../../../../commons/lib';

export default class Service {
  static keszletUrl = window.location.origin + '/api/keszletnyilv/';

  static partnerUrl = window.location.origin + '/api/partner/';

  static telepulesUrl = window.location.origin + '/api/telepules/';

  static raktarUrl = window.location.origin + '/api/raktar/';

  static bizonylatUrl = window.location.origin + '/api/bizonylat/';

  static cikkUrl = window.location.origin + '/api/cikk/';

  static penzugyUrl = window.location.origin + '/api/penzugykozos/';

  static szervezetUrl = window.location.origin + '/api/szervezet/';

  static configUrl = window.location.origin + '/api/config/';

  // BEVÉTELEZÉS START

  static listBevetelezes(filters, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'bevetelezes/list',
      args: { requestType: 'bevetelezes-list' },
      data: filters
    }], fnDone);
  }

  static addBevetelezes(bevetelezesObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.keszletUrl + 'bevetelezes',
      args: { requestType: 'bevetelezes-post' },
      data: bevetelezesObject
    }], fnDone);
  }

  static acceptAddBevetelezes(bevetelezesObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.keszletUrl + 'bevetelezes/accept',
      args: { requestType: 'bevetelezes-accept-post' },
      data: bevetelezesObject
    }], fnDone);
  }

  static editBevetelezes(bevetelezesObject, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'bevetelezes',
      args: { requestType: 'bevetelezes-put' },
      data: bevetelezesObject,
      params: { id: id }
    }], fnDone);
  }

  static acceptEditBevetelezes(bevetelezesObject, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'bevetelezes/accept',
      args: { requestType: 'bevetelezes-accept-put' },
      data: bevetelezesObject,
      params: { id: id }
    }], fnDone);
  }

  static getBevetelezes(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'bevetelezes',
      args: { requestType: 'bevetelezes-get' },
      params: { id: id }
    }], fnDone);
  }

  static deleteBevetelezes(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.keszletUrl + 'bevetelezes',
      args: { requestType: 'bevetelezes-delete' },
      params: { id: id },
    }], fnDone);
  }

  static listMozgastipusBevetelezes(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'bevetelezes/enum/mozgastipus',
      args: { requestType: 'bevetelezes-mozgastipus-get' },
    }], fnDone);
  }

  static listRendelesBevetelezes(partnerid, fixedrendelesid, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'bevetelezes/listrendeles',
      args: { requestType: 'bevetelezes-listrendeles-get' },
      params: { partnerid, fixedrendelesid }
    }], fnDone);
  }

  // BEVÉTELEZÉS END

  // CSÖKKENTÉS START

  static listCsokkenes(filters, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'csokkenes/list',
      args: { requestType: 'csokkenes-list' },
      data: filters,
    }], fnDone);
  }

  static addCsokkenes(csokkenesObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.keszletUrl + 'csokkenes',
      args: { requestType: 'csokkenes-post' },
      data: csokkenesObject
    }], fnDone);
  }

  static acceptAddCsokkenes(csokkenesObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.keszletUrl + 'csokkenes/accept',
      args: { requestType: 'csokkenes-accept-post' },
      data: csokkenesObject
    }], fnDone);
  }

  static editCsokkenes(csokkenesObject, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'csokkenes',
      args: { requestType: 'csokkenes-put' },
      data: csokkenesObject,
      params: { id: id }
    }], fnDone);
  }

  static acceptEditCsokkenes(csokkenesObject, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'csokkenes/accept',
      args: { requestType: 'csokkenes-accept-put' },
      data: csokkenesObject,
      params: { id: id }
    }], fnDone);
  }

  static getCsokkenes(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'csokkenes',
      args: { requestType: 'csokkenes-get' },
      params: { id: id }
    }], fnDone);
  }

  static deleteCsokkenes(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.keszletUrl + 'csokkenes',
      args: { requestType: 'csokkenes-delete' },
      params: { id: id },
    }], fnDone);
  }

  static listMozgastipusCsokkenes(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'csokkenes/enum/mozgastipus',
      args: { requestType: 'csokkenes-mozgastipus-get' },
    }], fnDone);
  }

  static listRendelesCsokkenes(partnerid, fixedrendelesid, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'csokkenes/listrendeles',
      args: { requestType: 'csokkenes-listrendeles-get' },
      params: { partnerid, fixedrendelesid }
    }], fnDone);
  }

  // CSÖKKENTÉS END

  // SELEJTEZÉS START

  static listSelejtezes(filters, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'selejtezes/list',
      args: { requestType: 'selejtezes-list' },
      data: filters, 
    }], fnDone);
  }

  static addSelejtezes(selejtezesObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.keszletUrl + 'selejtezes',
      args: { requestType: 'selejtezes-post' },
      data: selejtezesObject
    }], fnDone);
  }

  static acceptAddSelejtezes(selejtezesObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.keszletUrl + 'selejtezes/accept',
      args: { requestType: 'selejtezes-accept-post' },
      data: selejtezesObject
    }], fnDone);
  }

  static editSelejtezes(selejtezesObject, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'selejtezes',
      args: { requestType: 'selejtezes-put' },
      data: selejtezesObject,
      params: { id: id }
    }], fnDone);
  }

  static acceptEditSelejtezes(selejtezesObject, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'selejtezes/accept',
      args: { requestType: 'selejtezes-accept-put' },
      data: selejtezesObject,
      params: { id: id }
    }], fnDone);
  }

  static getSelejtezes(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'selejtezes',
      args: { requestType: 'selejtezes-get' },
      params: { id: id }
    }], fnDone);
  }

  static deleteSelejtezes(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.keszletUrl + 'selejtezes',
      args: { requestType: 'selejtezes-delete' },
      params: { id: id },
    }], fnDone);
  }

  static listSelejtezesOka(param, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'selejtezes/oka/list',
      params: { param : param }
    }], fnDone);
  }

  static addSelejtezesOka(oka, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.keszletUrl + 'selejtezes/oka',
      data: oka
    }], fnDone);
  }

  static deleteSelejtezesOka(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.keszletUrl + 'selejtezes/oka',
      params: { id: id }
    }], fnDone);
  }

  // SELEJTEZÉS END

  // ÁTADÁS START

  static listAtadas(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'atadas/list',
      args: { requestType: 'atadas-list' }
    }], fnDone);
  }

  static addAtadas(atadasObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.keszletUrl + 'atadas',
      args: { requestType: 'atadas-post' },
      data: atadasObject
    }], fnDone);
  }

  static acceptAddAtadas(atadasObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.keszletUrl + 'atadas/accept',
      args: { requestType: 'atadas-accept-post' },
      data: atadasObject
    }], fnDone);
  }

  static editAtadas(atadasObject, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'atadas',
      args: { requestType: 'atadas-put' },
      data: atadasObject,
      params: { id: id }
    }], fnDone);
  }

  static acceptEditAtadas(atadasObject, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'atadas/accept',
      args: { requestType: 'atadas-accept-put' },
      data: atadasObject,
      params: { id: id }
    }], fnDone);
  }

  static declineAtadas(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'atadas/decline',
      args: { requestType: 'atadas-accept-decline' },
      params: { id: id }
    }], fnDone);
  }

  static receiveAtadas(beerkezes, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'atadas/receive',
      args: { requestType: 'atadas-accept-receive' },
      params: { id: id, beerkezes: beerkezes }
    }], fnDone);
  }

  static declineReceiveAtadas(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'atadas/decline/accept',
      args: { requestType: 'atadas-accept-decline-receive' },
      params: { id: id }
    }], fnDone);
  }

  static getAtadas(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'atadas',
      args: { requestType: 'atadas-get' },
      params: { id: id }
    }], fnDone);
  }

  static deleteAtadas(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.keszletUrl + 'atadas',
      args: { requestType: 'atadas-delete' },
      params: { id: id },
    }], fnDone);
  }

  // ÁTADÁS END

  // KÉSZLETINFO START

  static getRaktarList(cikkids, raktarids, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'keszletinfo/list',
      args: { requestType: 'keszletinfo-list' },
      params: { cikkek: cikkids, raktarak: raktarids }
    }], fnDone);
  }

  // KÉSZLETINFO END

  // KÉSZLET START

  static generateBizonylatPdf(id, muvelet, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'keszlet/pdf',
      args: { requestType: 'keszlet-pdf' },
      acceptType: 'application/json; charset=utf-8, text/plain; charset=utf-8, application/octet-stream',
      params: { id: id, muvelet: muvelet }
    }], fnDone);
  }

  static listIdoszakiForgalom(filters, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'keszlet/listidoszakiforgalom',
      args: { requestType: 'keszlet-listidoszakiforgalom' },
      params: {
        kezdete: filters.kezdete,
        vege: filters.vege,
        raktarid: filters.raktarid,
        cikkcsoportid: filters.cikkcsoportid,
        cikkid: filters.cikkid
      }
    }], fnDone);
  }

  static listIdoszakiReszletesForgalom(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'keszlet/listidoszakiforgalomreszletesxlsx',
      acceptType: 'application/json, application/octet-stream',
      params: data,
    }], fnDone);
  }

  static listKeszletCikk(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'keszlet/cikk/list',
      args: { requestType: 'keszlet-cikk-list' },
      params: {id: id}
    }], fnDone);
  }

  static checkForRendelesModule(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'keszlet/checkrendeles',
      args: { requestType: 'keszlet-checkrendeles-get' },
    }], fnDone);
  }

  // KÉSZLET END

  // LELTÁR START

  static listLeltar(filters, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'leltar/list',
      args: { requestType: 'leltar-list' },
      data: filters, // {forduloNap: }
    }], fnDone);
  }

  static addLeltar(leltarObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.keszletUrl + 'leltar',
      args: { requestType: 'leltar-post' },
      data: leltarObject
    }], fnDone);
  }

    static acceptLeltar(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'leltar/accept',
      args: { requestType: 'leltar-accept-put' },
      params: { id }
    }], fnDone);
  }

  static editLeltar(leltarObject, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'leltar',
      args: { requestType: 'leltar-put' },
      data: leltarObject,
      params: { id: id }
    }], fnDone);
  }

  static acceptEditLeltar(leltarObject, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'leltar/accept',
      args: { requestType: 'leltar-accept-put' },
      data: leltarObject,
      params: { id: id }
    }], fnDone);
  }

  static getLeltar(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'leltar',
      args: { requestType: 'leltar-get' },
      params: { id: id }
    }], fnDone);
  }

  static getLeltarInit(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'leltar/init',
      args: { requestType: 'leltar-init' }
    }], fnDone);
  }

  static deleteLeltar(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.keszletUrl + 'leltar',
      args: { requestType: 'leltar-delete' },
      params: { id: id },
    }], fnDone);
  }

  static getLeltarKorrekcio(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'leltar/korrekcio',
      args: { requestType: 'leltar-korrekcio' },
      params: { id: id }
    }], fnDone);
  }

  static getLeltarCikk(raktarid, excludeids, fordulo, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'leltar/list/cikk',
      args: { requestType: 'leltar-list-cikk' },
      params: { raktarId: raktarid, excludeids, fordulo }
    }], fnDone);
  }

  static getLeltarPdf(id, type, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'leltar/pdf',
      args: { requestType: 'keszlet-pdf' },
      acceptType: 'application/json; charset=utf-8, text/plain; charset=utf-8, application/octet-stream',
      params: { id: id, tipus: type }
    }], fnDone);
  }

  static listFelelos(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'leltar/list/felelos',
      args: { requestType: 'leltar-list-felelos-get' },
    }], fnDone);
  }

  // LELTÁR END

  // LELTÁRÍV START

  static listLeltarIv(filters, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'leltariv/list',
      args: { requestType: 'leltariv-list' },
      params: filters,
    }], fnDone);
  }

  static addLeltarIv(leltarivObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.keszletUrl + 'leltariv',
      args: { requestType: 'leltariv-post' },
      data: leltarivObject
    }], fnDone);
  }

  static acceptAddLeltarIv(leltarivObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.keszletUrl + 'leltariv/accept',
      args: { requestType: 'leltariv-accept-post' },
      data: leltarivObject
    }], fnDone);
  }

  static editLeltarIv(leltarivObject, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'leltariv',
      args: { requestType: 'leltariv-put' },
      data: leltarivObject,
      params: { id: id }
    }], fnDone);
  }

  static acceptEditLeltarIv(leltarivObject, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'leltariv/accept',
      args: { requestType: 'leltariv-accept-put' },
      data: leltarivObject,
      params: { id: id }
    }], fnDone);
  }

  static getLeltarIv(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'leltariv',
      args: { requestType: 'leltariv-get' },
      params: { id: id }
    }], fnDone);
  }

  static deleteLeltarIv(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.keszletUrl + 'leltariv',
      args: { requestType: 'leltariv-delete' },
      params: { id: id },
    }], fnDone);
  }

  static getLeltarivPdf(id, type, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'leltariv/pdf',
      args: { requestType: 'keszlet-pdf' },
      acceptType: 'application/json; charset=utf-8, text/plain; charset=utf-8, application/octet-stream',
      params: { id: id, tipus: type }
    }], fnDone);
  }

  // LELTÁRIV END

  // LELTÁRÍVCIKK START

  static listLeltarIvCikk(filters, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'leltarivcikk/list',
      args: { requestType: 'leltarivcikk-list' },
      data: filters,
    }], fnDone);
  }

  static addLeltarIvCikk(leltarivcikkObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.keszletUrl + 'leltar',
      args: { requestType: 'leltarivcikk-post' },
      data: leltarivcikkObject
    }], fnDone);
  }

  static acceptAddLeltarIvCikk(leltarivcikkObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.keszletUrl + 'leltarivcikk/accept',
      args: { requestType: 'leltarivcikk-accept-post' },
      data: leltarivcikkObject
    }], fnDone);
  }

  static editLeltarIvCikk(leltarivcikkObject, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'leltarivcikk',
      args: { requestType: 'leltarivcikk-put' },
      data: leltarivcikkObject,
      params: { id: id }
    }], fnDone);
  }

  static acceptEditLeltarIvCikk(leltarivcikkObject, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.keszletUrl + 'leltarivcikk/accept',
      args: { requestType: 'leltarivcikk-accept-put' },
      data: leltarivcikkObject,
      params: { id: id }
    }], fnDone);
  }

  static getLeltarIvCikk(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'leltarivcikk',
      args: { requestType: 'leltarivcikk-get' },
      params: { id: id }
    }], fnDone);
  }

  static deleteLeltarIvCikk(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.keszletUrl + 'leltarivcikk',
      args: { requestType: 'leltarivcikk-delete' },
      params: { id: id },
    }], fnDone);
  }

  // LELTÁRIVCIKK END

  // PARTNER START

  static addPartner(partnerObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.partnerUrl + 'partner',
      args: { requestType: 'partner-post' },
      data: partnerObject
    }], fnDone);
  }

  static queryPartner(tipus, nev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'partner-search' },
      params: { type: tipus, text: nev }
    }], fnDone);
  }

  // PARTNER END

  // TELEPÜLÉS START

  static getTelepulesByIrszam(irszam, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'telepules',
      args: { requestType: 'telepules-get-irszam' },
      params: { iranyitoszam: irszam }
    }], fnDone);
  }

  static getTelepulesById(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'telepules/' + id,
      args: { requestType: 'telepules-get-id' }
    }], fnDone);
  }
  // TELEPÜLÉS END

  // RAKTÁR START

  static listRaktar(param, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.raktarUrl + 'raktar/list',
      args: { requestType: 'raktar-list' },
      params: param
    }], fnDone);
  }

  static searchRaktar(text, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.raktarUrl + 'raktar/searchbytext',
      args: { requestType: 'raktar-searchbytext' },
      params: { text: text }
    }], fnDone);
  }

  // RAKTÁR END

  // BIZONYLAT START

  static listBizonylatRaktar(azonosito, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bizonylatUrl + 'biztomb/list/raktarak',
      args: { requestType: 'bizonylat-raktar-list' },
      params: { azonosito: azonosito, id: id } // 'LELTAR', null
    }], fnDone);
  }

  // BIZONYLAT START

  // CIKK START

  static queryCikk(text, receptura, raktaronkivuli, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cikkUrl + 'cikk/query',
      args: { requestType: 'cikk-query' },
      params: { param: text, receptura: receptura, raktar: raktaronkivuli }
    }], fnDone);
  }

  static queryLeltarCikk(text, raktarid, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'leltar/list/cikk/outof',
      args: { requestType: 'leltar-list' },
      params: { param: text, raktarId: raktarid }
    }], fnDone);
  }

  static queryCikkcsoport(text, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cikkUrl + 'cikkcsoport/query',
      args: { requestType: 'cikkcsoport-query' },
      params: { param: text }
    }], fnDone);
  }

  // CIKK END


  // PENZUGY START

  static listAktivFizetesimod(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'fizmod/aktiv',
      args: { requestType: 'fizmod-list' }
    }], fnDone);
  }

  static listAktivPenznem(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'penznem/aktiv',
      args: { requestType: 'penznem-aktiv' }
    }], fnDone);
  }

  // PENZUGY END

  // SZEMÉLY START

  static listSzemely(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szervezetUrl + 'szemely/getaktivszemelyek',
      args: { requestType: 'szemely-list' }
    }], fnDone);
  }

  static existsSzervezet(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.keszletUrl + 'leltar/exists/szervezet',
      args: { requestType: 'szervezet-exists' }
    }], fnDone);
  }

  // SZEMÉLY END
}
