import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  UncontrolledTooltip
} from 'reactstrap';
import {
  AvFeedback,
  AvField,
  AvForm,
  AvGroup,
  AvInput,
} from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Service from './services';
import { PaginationTotal } from '../../../commons/controls';
import NumberFormatter from '../../../commons/NumberFormatter';
import SlaveAvInput from '../../../commons/SlaveAvInput';
import CustomTooltip from '../../../components/Tooltip/CustomTooltip';
import getTooltip from './CikkTooltips';
import Lib from '../../../commons/lib';

/**
 * Osztály listázza az áfákat.
 * @extends Component
 */
class AfaLista extends Component {
  /**
  * AfaLista konstruktor.
  * @param {object} props
  */
  constructor(props) {
    super(props);

    /**
    * @type {object}
    * @property {boolean} modalFelvetel Új áfa felviteli modalt vezérlő logikai változó.
    * @property {boolean} modalNezet Meglévő áfa megtekintési modalt vezérlő logikai változó.
    * @property {boolean} modalModosit Meglévő áfa módosító modalt vezérlő logikai változó.
    * @property {{statusz: string, megnevezes: string, ertek: number, id: number }} afaJson Áfa adatokat tartalmazó tömb.
    */
    this.state = {
      modalFelvetel: false,
      modalTorles: false,
      modalNezet: false,
      modalModosit: false,
      afaJson: [],
      afaModositObj: {
        id: '', ertek: '', megnevezes: '', statusz: '', kulcs: '',
      },
      statusz: [],
      statuszOptions: [],
      filterOptions: {},
    };
  }

  /** Lefut, amikor az AfaLista komponens betöltődik. */
  // componentDidMount() {
  //   this.updateTable();
  // }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      if (this.props.active) {
        this.updateTable();
      }
    }
  }

  /** ÁFA felviteli modal. */
  toggleFelvetel = () => {
    this.setState({
      modalFelvetel: !this.state.modalFelvetel,
    });
  }

  /** ÁFA nézet modal. */
  toggleNezet = () => {
    this.setState({
      modalNezet: !this.state.modalNezet
    });
  }

  /** ÁFA módosító modal. */
  toggleModosit = () => {
    this.setState({
      modalModosit: !this.state.modalModosit
    });
  }

  /** ÁFA törlés modal. */
  toggleTorles = () => {
    this.setState({
      modalTorles: !this.state.modalTorles
    });
  }

  /** Táblázat adatainak frissítése adatbázisból. */
  updateTable = () => {
    this.setState({ afaJson: [] });
    Service.listAfa((err, res) => {
      if (!err) {
        let newArray = this.state.afaJson.concat(res[0].list);
        let filters = {};
        let options = res[0].statusz.map((e) => {
          filters[e.name] = e.caption;

          return <option key={e.name} value={e.name}>{e.caption}</option>;
        });

        this.setState({
          afaJson: newArray,
          statusz: res[0].statusz,
          statuszOptions: options,
          filterOptions: filters
        });
      }
    });
  }

  /**
  * Paraméterként kapott áfa objektum felvétele adatbázisba.
  * @param {SytheticEvent} event
  * @param {object} afaObj Fioktelep adatokat tartalamzó objektum.
  */
  addAfa = (event, afaObj) => {
    afaObj.ertek = NumberFormatter.removeSpacesFromString(afaObj.ertek);
    Service.addAfa(afaObj, (err) => {
      if (!err) {
        this.toggleFelvetel();
        this.updateTable();
        this.props.globals.addSuccess('Áfa hozzáadva!');
      }
    });
  }

  /**
   * Törli az adott áfát a paraméterként kapott id alapján.
   * @param {string} id - ÁFA sajatpath attributuma.
   */
  deleteAfa = (id) => {
    this.toggleTorles();
    Service.deleteAfa(id, (err) => {
      if (!err) {
        this.updateTable();
        this.props.globals.addSuccess('Áfa törölve!');
      }
    });
  }

  /**
  * Paraméterként kapott áfa objektum módosítása adatbázisba.
  * @param {SytheticEvent} event
  * @param {object} afaObj áfa adatokat tartalamzó objektum.
  */
  modifyAfa = (event, afaObj) => {
    afaObj.id = this.state.afaModositObj.id;
    afaObj.ertek = NumberFormatter.removeSpacesFromString(afaObj.ertek);
    Service.editAfa(afaObj, (err) => {
      if (!err) {
        this.toggleModosit();
        this.updateTable();
        this.props.globals.addSuccess('Áfa módosítva!');
      }
    });
  }

  /**
  * Visszaadja at áfához tartozó státusz jelvényeket.
  * @param {boolean} cell - Cella adatot tartalmazó szöveg.
  * @return {ReactElement} Státusz jelvényt tartalmazó react objektum.
  */
  statusFormatter = (cell) => {
    let statusz = this.state.statusz.find(x => x.name === cell);
    return statusz ? <span className={'badge badge-' + statusz.bagde}>{statusz.caption}</span> : 'N/A';
  }

  /**
  * Visszaadja az áfához tartozó művelet gombokat.
  * @param {string} cell - Cella adatot tartalmazó szöveg.
  * @return {ReactElement} Megtekintés, módosítás gombokat tartalmazó react objektum.
  */
  iconFormatter = (cell) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'CIKK_AFA', 'LEK') && (
          <React.Fragment>
            <Button className="icon-eye table-action-icon" id={'action-view-' + cell + 'megtekintes'} onClick={() => this.handleViewClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'megtekintes'} delay={0}>
              Megtekintés
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'CIKK_AFA', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => this.handleEditClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'modositas'} delay={0}>
              Módosítás
            </UncontrolledTooltip>
            <Button className="icon-trash table-action-icon" id={'action-view-' + cell + 'torles'} onClick={() => this.handleDeleteClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'torles'} delay={0}>
              Törlés
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  /**
  * Megtekintés akció gomb funckió lekezelése.
  * @param {number} id Megtekinteni kívánt áfa id-ja.
  */
  handleViewClick = (id) => {
    this._currentViewId = id;
    this.toggleNezet();
  }

  /**
  * Törlés akció gomb funckió lekezelése.
  * @param {number} id Törölni kívánt áfa id-ja.
  */
  handleDeleteClick = (id) => {
    this._currentDeleteId = id;
    this.toggleTorles();
  }

  /**
  * Módosító akció gomb funckió lekezelése.
  * @param {number} id
  */
  handleEditClick = (id) => {
    let afa = this.state.afaJson.find(element => element.id === id);
    this._currentEditId = id;

    this.setState({ afaModositObj: afa });
    this.toggleModosit();
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  /**
  * Visszaadja a afaJson paramtérben kapott id-jű elemének az adatait.
  * @param {number} id - Kiválaszott áfa id-ja.
  * @return {Array.ReactElement} Áfa részletes információ.
  */
  renderReszletes = (id) => {
    const afa = this.state.afaJson.find(element => element.id === id);
    let statusz = afa && this.state.statusz.find(x => x.name === afa.statusz);
    return (
      <div>
        <h6>Áfa információ:</h6>
        <b>Megnevezés: </b>{afa.megnevezes}<br />
        <b>Érték (%): </b>{NumberFormatter.addSpacesToString(afa.ertek, NumberFormatter.OSSZEG)}<br />
        <b>ÁFA kulcs: </b>{afa.kulcs}<br />
        <b>Státusz: </b>{statusz.caption}<br />
      </div>
    );
  }

  /**
   * React render.
   * @return {ReactElement} Áfa modul.
   */
  render() {
    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ]
    };

    return (
      <div>
        <div className="row">
          {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'CIKK_AFA', 'KARB') && (
            <div className="col-sm-12">
              <Button className="pull-left table-new-button" color="primary" onClick={this.toggleFelvetel}>Új ÁFA</Button>
            </div>
          )}
        </div>
        <BootstrapTable data={this.state.afaJson} pagination options={options}>
          <TableHeaderColumn dataField="megnevezes" filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Megnevezés</TableHeaderColumn>
          <TableHeaderColumn dataField="ertek" tdStyle={NumberFormatter.styleTextAlign} dataFormat={NumberFormatter.formatterSpaceOsszeg}>ÁFA értéke (%)</TableHeaderColumn>
          <TableHeaderColumn dataField="kulcs" tdStyle={NumberFormatter.styleTextAlign} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>ÁFA kulcs</TableHeaderColumn>
          <TableHeaderColumn
            dataField="statusz"
            dataFormat={this.statusFormatter}
            filter={{
              type: 'SelectFilter',
              options: this.state.filterOptions,
              placeholder: 'Összes',
              condition: 'eq'
            }}
            dataAlign="center"
          >
            Státusz
          </TableHeaderColumn>
          <TableHeaderColumn dataField="id" isKey dataFormat={this.iconFormatter}>Műveletek</TableHeaderColumn>
        </BootstrapTable>
        <Modal isOpen={this.state.modalFelvetel} toggle={this.toggleFelvetel} className={this.props.className} backdrop="static">
          <AvForm onValidSubmit={this.addAfa}>
            <ModalHeader>ÁFA felvétel</ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  <AvGroup>
                    <Label for="megnevezes">Megnevezés: *</Label>
                    <AvInput name="megnevezes" id="megnevezes" type="text" maxLength="50" required />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
                <div className="col-md-12">
                  <SlaveAvInput
                    id="ertek"
                    label="Érték: *"
                    required
                    type="text"
                    maxLength="5"
                    szuroTipus={NumberFormatter.OSSZEG}
                    inputGroupAddon="%"
                  />
                </div>
                <div className="col-md-12">
                  <AvGroup>
                    <Label for="kulcs">
                      ÁFA kulcs:
                      <CustomTooltip text={getTooltip('afaKulcs')} id="afaKulcsTT" />
                    </Label>
                    <AvInput name="kulcs" id="kulcs" type="text" maxLength="10" />
                  </AvGroup>
                </div>
                <div className="col-md-12">
                  <AvField type="select" name="statusz" label="Státusz: *" errorMessage="Kötelező mező!" required value="AKTIV">
                    {this.state.statuszOptions}
                  </AvField>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button className="btn-cursor-pointer" color="primary">Mentés</Button>
              {' '}
              <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleFelvetel}>Mégse</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
        <Modal isOpen={this.state.modalModosit} toggle={this.toggleModosit} className={this.props.className} backdrop="static">
          <AvForm onValidSubmit={this.modifyAfa}>
            <ModalHeader>ÁFA módosítás</ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  <AvGroup>
                    <Label for="megnevezes">Megnevezés: *</Label>
                    <AvInput name="megnevezes" id="megnevezes" type="text" maxLength="50" value={this.state.afaModositObj.megnevezes} required />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
                <div className="col-md-12">
                  <SlaveAvInput
                    id="ertek"
                    label="Érték: *"
                    required
                    type="text"
                    maxLength="5"
                    value={this.state.afaModositObj.ertek.toString()}
                    szuroTipus={NumberFormatter.OSSZEG}
                    inputGroupAddon="%"
                  />
                </div>
                <div className="col-md-12">
                  <AvGroup>
                    <Label for="kulcs">
                      ÁFA kulcs:
                      <CustomTooltip text={getTooltip('afaKulcs')} id="afaKulcsTT2" />
                    </Label>
                    <AvInput name="kulcs" id="kulcs" type="text" maxLength="10" value={this.state.afaModositObj.kulcs} />
                  </AvGroup>
                </div>
                <div className="col-md-12">
                  <AvField type="select" name="statusz" label="Státusz: *" errorMessage="Kötelező mező!" value={this.state.afaModositObj.statusz} required>
                    {this.state.statuszOptions}
                  </AvField>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button className="btn-cursor-pointer" color="primary">Mentés</Button>
              {' '}
              <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleModosit}>Mégse</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
        <Modal isOpen={this.state.modalNezet} toggle={this.toggleNezet} className={this.props.className} backdrop="static">
          <ModalHeader>Megtekintés</ModalHeader>
          <ModalBody>{this.state.modalNezet ? this.renderReszletes(this._currentViewId) : <div />}</ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" type="button" color="primary" onClick={this.toggleNezet}>Vissza</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>Valóban törölni kívánja a kiválasztott tételt?</ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteAfa(this._currentDeleteId)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

AfaLista.propTypes = {
  globals: PropTypes.object,
  active: PropTypes.bool,
  className: PropTypes.string
};

export default AfaLista;
