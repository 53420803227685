import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {
  selectFilter,
  textFilter,
} from 'react-bootstrap-table2-filter';
import {
  Button, Col, Input, Label, Row, UncontrolledTooltip
} from 'reactstrap';
import { browserHistory } from 'react-router';
import Select from 'react-select';
import Services from './services';
import FelhasznalokRoleCards from './FelhasznalokRoleCards';
import getTooltip from './FelhasznaloTooltips';
import CustomTooltip from '../../../components/Tooltip/CustomTooltip';

const cardStyle = {
  padding: 20,
};

class FelhasznalokForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      username: null,
      enabled: true,
      groupsSelected: [],
      groups: [],
      rolesAvailable: [],
      rolesSelected: [],
    };
  }

  componentDidMount() {
    this.getUser();
    this.getGroups();
  }

  isEdit = () => {
    return this.props.route.type === 'MOD';
  }

  getGroups = () => {
    Services.getGroupList((err, res) => {
      if (!err) {
        this.setState({ groups: res[0].sort((a, b) => a.name.localeCompare(b.name)) });
      }
    });
  }

  getUser = () => {
    if (this.isEdit) {
      let params = new URL(window.location.href).searchParams;
      let id = params.get('id');
      if (id) {
        Services.getUser(id, (err, res) => {
          if (!err) {
            let array = [];
            if (res[0].clientRoles) {
              array = res[0].clientRoles.sort((a, b) => a.roleName.localeCompare(b.roleName));
            }
            this.setState({ 
              id: res[0].id, 
              username: res[0].username,
              firstName: res[0].firstName,
              lastName: res[0].lastName,
              email: res[0].email,
              enabled: res[0].enabled,
              temppwd: null,
              groupsSelected: res[0].groups,
              rolesSelected: array
            });
            this.getRoles(res[0].id);
          }
        });
      }
    }
  }

  getRoles = (id) => {
    Services.getRoleList(id, (err, res) => {
      if (!err) {
        let array = [];
        if (res[0]) { array = res[0].sort((a, b) => a.roleName.localeCompare(b.roleName)); }
        this.setState({ rolesAvailable: array });
      }
    });
  }

  onSubmit = (reopen) => {
    if (!this.state.username) {
      this.props.route.globals.addError('Felhasználónév megadása kötelező!');
      return;
    }
    if (!this.isEdit() && !this.state.temppwd) {
      this.props.route.globals.addError('Ideiglenes jelszó megadása kötelező!');
      return;
    }
    let obj = {
      id: this.state.id,
      username: this.state.username,
      lastName: this.state.lastName,
      firstName: this.state.firstName,
      email: this.emptyOrNull(this.state.email),
      enabled: this.state.enabled,
      temppwd: this.state.temppwd,
      groups: this.state.groupsSelected,
      clientRoles: this.state.rolesSelected
    };
    if (this.isEdit()) {
      Services.editUser(obj, (err) => {
        if (!err) {
          this.props.route.globals.addSuccess('Felhasználó módosítva!');
        }
      });
    } else {
      Services.insertUser(obj, (err, res) => {
        if (!err) {
          this.props.route.globals.addSuccess('Felhasználó hozzáadva!');
          if (reopen) {
            this.handleReopen(res[0]);
            this.setState({ id: res[0] });
            this.getRoles(res[0]);
          } else { this.handleBack(); }
        }
      });
    }
  }

  addRoles = (roles) => {
    if (!roles || !roles.length) {
      this.props.route.globals.addError('Legalább egy jogosultság kiválasztása kötelező a hozzáadáshoz!');
      return;
    }
    this.setState((prevState) => {
      let rolesSelectedNew = prevState.rolesAvailable.filter(role => roles.includes(role.id)).concat(prevState.rolesSelected).sort((a, b) => a.roleName.localeCompare(b.roleName));
      let rolesAvailableNew = prevState.rolesAvailable.filter(role => !rolesSelectedNew.includes(role));
      return { rolesAvailable: rolesAvailableNew, rolesSelected: rolesSelectedNew };
    });
  }

  removeRoles = (roles) => {
    if (!roles || !roles.length) {
      this.props.route.globals.addError('Legalább egy jogosultság kiválasztása kötelező a törléshez!');
      return;
    }
    this.setState((prevState) => {
      let rolesAvailableNew = prevState.rolesSelected.filter(role => roles.includes(role.id)).concat(prevState.rolesAvailable).sort((a, b) => a.roleName.localeCompare(b.roleName));
      let rolesSelectedNew = prevState.rolesSelected.filter(role => !rolesAvailableNew.includes(role));
      return { rolesAvailable: rolesAvailableNew, rolesSelected: rolesSelectedNew };
    });
  }

  handleReopen = (id) => {
    browserHistory.push(''.concat('/profil/felhasznalok/felhasznalo/modositas?id=' + id));
  }

  handleBack = () => {
    browserHistory.push(''.concat('/profil/felhasznalok'));
  }

  handleInputChange = (e) => {
    const { target } = e;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [name]: value
    });
  }

  emptyOrNull = (data) => {
    if (!data || data === '') return null;
    return data;
  }

  tableIconFormatter = (cell) => {
    return (
      <>
        <Button
          className="icon-pencil table-action-icon"
          id={'action-view-' + cell + 'modositas'}
          onClick={() => this.handleEditClick(cell)}
          type="button"
        />
        <UncontrolledTooltip
          style={{ float: 'left' }}
          placement="top"
          target={'action-view-' + cell + 'modositas'}
          delay={50}
        >
          {'Módosítás'}
        </UncontrolledTooltip>
        <Button
          className="icon-trash table-action-icon"
          id={'action-view-' + cell + 'file'}
          onClick={() => this.handleDeleteClick(cell)}
          type="button"
        />
        <UncontrolledTooltip
          style={{ float: 'left' }}
          placement="top"
          target={'action-view-' + cell + 'file'}
          delay={50}
        >
          {'Törlés'}
        </UncontrolledTooltip>
      </>
    );
  };

  tableStatusFormatter = (cell) => {
    return cell === true ? (
      <span className="badge badge-success">Aktív</span>
    ) : (
      <span className="badge badge-default">Inaktív</span>
    );
  };

  renderUserTable = () => {
    const selectOptions = {
      true: 'Aktív',
      false: 'Inaktív',
    };

    const { users } = this.state;
    const columns = [
      {
        dataField: 'username',
        text: 'Felhasználó név',
        filter: textFilter({
          delay: 400,
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
        },
      },
      {
        dataField: 'szemelynev',
        text: 'Felhasználó neve',
        filter: textFilter({
          delay: 400,
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
        },
      },
      {
        dataField: 'email',
        text: 'Email cím',
        filter: textFilter({
          delay: 400,
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
        },
      },
      {
        dataField: 'enabled',
        text: 'Állapot',
        filter: selectFilter({
          options: selectOptions,
          placeholder: 'Összes',
        }),
        style: {
          whiteSpace: 'pre-line',
          textAlign: 'center',
        },
        formatter: this.tableStatusFormatter,
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        formatter: this.tableIconFormatter,
      },
    ];
    return (
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={users}
        columns={columns}
        filter={filterFactory()}
      />
    );
  };

  renderButtons = () => {
    const { route } = this.props;
    return (
      <Row className="mt-2 justify-content-end">
        <Col md="auto">
          {route.type === 'FEL' && <Button className="mr-1" type="button" color="success" onClick={() => this.onSubmit(true)}>Mentés és jogosultság szerkesztése</Button>
          }
          <Button className="mr-1" type="button" color="primary" onClick={() => this.onSubmit(false)}>Mentés</Button>
          <Button type="button" color="secondary" onClick={this.handleBack}>Mégsem</Button>
        </Col>
      </Row>
    );
  }

  renderForm() {
    const {
      username, email, lastName, firstName, enabled, temppwd
    } = this.state;
    return (
      <div className="row">
        <div className="col-md-12">
          <Row>
            <Col md="3">
              <Label>Felhasználónév</Label>
              <Input
                id="username"
                name="username"
                value={username}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col md="3">
              <Label>Email cím</Label>
              <Input
                id="email"
                name="email"
                value={email}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col md="3">
              <Label>Vezetéknév</Label>
              <Input
                // Akarattal van megcserélve a first/lastname OW-2031
                id="firstName"
                name="firstName"
                value={firstName}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col md="3">
              <Label>Keresztnév</Label>
              <Input
                id="lastName"
                name="lastName"
                value={lastName}
                onChange={this.handleInputChange}
              />
            </Col>
          </Row>
          <Row className="mt-1">
            <Col md="2">
              <Label>Állapot</Label>
              <Input
                id="enabled"
                name="enabled"
                type="select"
                value={enabled}
                onChange={this.handleInputChange}
              >
                <option value>Aktív</option>
                <option value={false}>Inaktív</option>
              </Input>
            </Col>
            <Col md="4">
              <Label>
                Ideiglenes jelszó
                <CustomTooltip text={getTooltip('temppwd')} id="temppwdTT" />
              </Label>
              <Input
                id="temppwd"
                name="temppwd"
                type="password"
                value={temppwd}
                onChange={this.handleInputChange}
              />
            </Col>
            <Col md="6">
              <Label>Jogosultság csoportok</Label>
              <Select
                styles={{
                  control: base => ({
                    ...base,
                    '&:focus': { borderColor: 'red' }, // border style on hover
                    border: '1px solid lightgray', // default border color
                    boxShadow: 'none', // no box-shadow
                    borderRadius: '0'
                  }),
                }}
                name="groups"
                id="groups"
                placeholder="Kezdjen el gépelni..."
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                onChange={(v) => { this.setState({ groupsSelected: v }); }}
                options={this.state.groups}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                value={this.state.groupsSelected}
                isClearable
                isMulti
              />
            </Col>
          </Row>
          {this.renderButtons()}
        </div>
      </div>
    );
  }

  render() {
    const { route } = this.props;
    const { rolesAvailable, rolesSelected } = this.state;
    return (
      <div className="animated fadeIn">
        <div className="card">
          <div className="card-header">
            {route.type === 'FEL'
              ? 'Felhasználó rögzítése'
              : 'Felhasználó módosítása'}
          </div>
          <div className="row" style={cardStyle}>
            <div className="col-md-12">
              {this.renderForm()}
            </div>
            {this.isEdit() && (
              <Col md="12">
                <FelhasznalokRoleCards rolesAvailable={rolesAvailable} rolesSelected={rolesSelected} addRoles={this.addRoles} removeRoles={this.removeRoles} selectedColumnName="Felhasználó jogosultságai" />
              </Col>
            )
            }
          </div>
        </div>
      </div>
    );
  }
}

export default FelhasznalokForm;
