
import React, { useState, useEffect } from 'react';
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import {
  AvFeedback,
  AvForm,
  AvInput,
  AvGroup,
} from 'availity-reactstrap-validation';
import Select from 'react-select/lib/Async';
import _ from 'lodash';
import SlaveAvInput from '../../../commons/SlaveAvInput';
import Services from '../services';
import NumberFormatter from '../../../commons/NumberFormatter';


const ROW_STYLE = { marginBottom: '15px' };
const AjanlatCikkForm = (props) => {
  const {
    id,
    parentId,
    route,
    isElkuldott,
    cikkek,
    penznem,
    setCikkek,
    toggleShowCikk,
  } = props;

  const [cikk, setCikk] = useState(null);
  const [cikkNev, setCikkNev] = useState('');
  const [newCikk, toggleNewCikk] = useState(false);
  const [mennyiseg, setMennyiseg] = useState('');
  const [afaId, setAfaId] = useState('');
  const [afaSzazalek, setAfaSzazalek] = useState('');
  const [nettoEgysegar, setNettoEgysegar] = useState('');
  const [afaEgysegar, setAfaEgysegar] = useState('');
  const [bruttoEgysegar, setBruttoEgysegar] = useState('');
  const [nettoOsszAr, setNettoOsszAr] = useState('');
  const [afaOsszAr, setAfaOsszAr] = useState('');
  const [bruttoOsszAr, setBruttoOsszAr] = useState('');
  const [cikkModal, toggleCikkModal] = useState(true);
  const [afaKulcsJSON, setAfaKulcsJSON] = useState([]);
  const [afaKulcsOptions, setAfaKulcsOptions] = useState([]);
  const [isNullaAfa, setIsNullaAfa] = useState(false);
  const [szamolasIrany, setSzamolasIrany] = useState(null); // 1 = nettó egységártól előre, 2 = bruttó értéktől vissza

  const { removeRedundantZeros } = NumberFormatter;

  useEffect(() => {
    getAjanlatCikkRendeles();
  }, []);

  useEffect(() => {
    toggleShowCikk(cikkModal);
  }, [cikkModal]);

  const loadCikkOptions = (input, callback) => {
    if (_.isEmpty(input)) callback([]);
    Services.listCikk({ param: input, receptura: false, ignoretipus: true }, (err, res) => {
      if (!err) {
        const options = res[0].filter(k => k.megnevezes.toLowerCase().includes(input.toLowerCase()));
        callback(options);
      } else {
        callback([]);
      }
    });
  };

  const generateUUID = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
  };

  const clearPrices = () => {
    setNettoEgysegar('');
    setAfaEgysegar('');
    setBruttoEgysegar('');
    setNettoOsszAr('');
    setAfaOsszAr('');
    setBruttoOsszAr('');
  };

  const round = (value, precision) => {
    const multiplier = 10 ** (precision || 0);
    return Math.round(value * multiplier) / multiplier;
  };

  const calculate = (netUnit, grossVal, quantity, vatValue) => {
    const decDigit = penznem ? penznem.tizedesjegy : 6;
    const decMult = decDigit === 0 ? 1 : decDigit;

    let netto = parseFloat(netUnit || nettoEgysegar ? NumberFormatter.removeSpacesFromString(netUnit || nettoEgysegar) : 0);
    let brutto = parseFloat(grossVal || bruttoOsszAr ? NumberFormatter.removeSpacesFromString(grossVal || bruttoOsszAr) : 0);
    let menny = parseFloat(quantity || mennyiseg ? NumberFormatter.removeSpacesFromString(quantity || mennyiseg) : 0);
    let afaSzazalekErtek = parseFloat(vatValue || vatValue === 0 ? vatValue : afaSzazalek);

    if (menny <= 0) {
      return;
    }

    afaSzazalekErtek /= 100;

    if (netUnit || quantity || vatValue || vatValue === 0) {
      let bruttoE = ((netto * (1 + afaSzazalekErtek)) * (10 ** decMult)) / (10 ** decMult);
      let bruttoO = ((netto * (1 + afaSzazalekErtek)) * menny * (10 ** decMult)) / (10 ** decMult);
      let afaO = round((round(bruttoO, decDigit) / (1 + afaSzazalekErtek) * afaSzazalekErtek) * (10 ** decMult) / (10 ** decMult), decDigit);
      let nettoO = (round(bruttoO, decDigit) - afaO) * (10 ** decMult) / (10 ** decMult);
      let afaE = (netto * afaSzazalekErtek * (10 ** decMult)) / (10 ** decMult);

      setNettoEgysegar(removeRedundantZeros(netUnit || nettoEgysegar));
      setAfaEgysegar(removeRedundantZeros(afaE.toFixed(6)));
      setBruttoEgysegar(removeRedundantZeros(bruttoE.toFixed(6)));
      setNettoOsszAr(removeRedundantZeros(nettoO.toFixed(decDigit)));
      setAfaOsszAr(removeRedundantZeros(round(afaO, decDigit).toFixed(decDigit)));
      setBruttoOsszAr(removeRedundantZeros(round(bruttoO, decDigit).toFixed(decDigit)));
      setSzamolasIrany(1);
    } else if (grossVal) {
      let afaO = round((brutto / (1 + afaSzazalekErtek) * afaSzazalekErtek * (10 ** decMult)) / (10 ** decMult), decDigit);
      let nettoO = brutto - afaO;
      let afaE = afaO / menny;
      let nettoE = nettoO / menny;
      let bruttoE = brutto / menny;

      setNettoEgysegar(removeRedundantZeros(nettoE.toFixed(6)));
      setAfaEgysegar(removeRedundantZeros(afaE.toFixed(6)));
      setBruttoEgysegar(removeRedundantZeros(bruttoE.toFixed(6)));
      setNettoOsszAr(removeRedundantZeros(nettoO.toFixed(decDigit)));
      setAfaOsszAr(removeRedundantZeros(afaO.toFixed(decDigit)));
      setBruttoOsszAr(removeRedundantZeros(grossVal || bruttoEgysegar));
      setSzamolasIrany(2);
    }
  };

  const getAjanlatCikkRendeles = () => {
    const edited = id ? cikkek.find(c => c.id === id) : null;
    if (edited) {
      toggleNewCikk(edited.new);
      setCikk(edited.cikk);
      setCikkNev(edited.cikkNev);
      setMennyiseg(edited.mennyiseg);
      setNettoEgysegar(edited.nettoAr);
      setAfaEgysegar(edited.afaAr);
      setBruttoEgysegar(edited.bruttoAr);
      setNettoOsszAr(edited.nettoOssz);
      setAfaOsszAr(edited.afaOssz);
      setBruttoOsszAr(edited.bruttoOssz);
      setAfaId(edited.afaId);
      setAfaSzazalek(edited.afaSzazalek);
      setIsNullaAfa(edited.afaSzazalek == 0);
    }

    Services.listAktivAfa(edited ? edited.afaId : null, (err, res) => {
      if (!err) {
        let afaJSON = [];
        let afaOptions = res[0].map((e) => {
          let afa = {};
          afa.id = e.id;
          afa.ertek = e.ertek;
          afaJSON.push(afa);

          return <option key={e.id} value={e.id}>{e.kulcs + ' (' + e.ertek + '%)'}</option>;
        });

        setAfaKulcsJSON(afaJSON);
        setAfaKulcsOptions(afaOptions);
      }
    });
  };

  const saveAjanlatCikkRendeles = () => {
    if (cikkek.find(c => !id && c.cikkId === cikk.id)) {
      route.globals.addWarning('A kiválaszott cikk már szerepel a felsorolásban!');
      return;
    }
    if (!id) {
      let submitObj = {};
      submitObj.id = generateUUID();
      submitObj.new = true;
      submitObj.cikk = cikk;
      submitObj.cikkNev = cikkNev;
      submitObj.cikkId = cikk && cikk.id;
      submitObj.mennyiseg = NumberFormatter.removeSpacesFromString(mennyiseg);
      submitObj.nettoAr = NumberFormatter.removeSpacesFromString(nettoEgysegar);
      submitObj.afaAr = NumberFormatter.removeSpacesFromString(afaEgysegar);
      submitObj.bruttoAr = NumberFormatter.removeSpacesFromString(bruttoEgysegar);
      submitObj.afaId = afaId && parseInt(afaId, 10);
      submitObj.afaSzazalek = afaSzazalek;
      submitObj.nettoOssz = NumberFormatter.removeSpacesFromString(nettoOsszAr);
      submitObj.afaOssz = NumberFormatter.removeSpacesFromString(afaOsszAr);
      submitObj.bruttoOssz = NumberFormatter.removeSpacesFromString(bruttoOsszAr);
      submitObj.irany = szamolasIrany;

      cikkek.push(submitObj);
      setCikkek(cikkek);
    } else {
      let submitObj = {};
      submitObj.id = id;
      submitObj.new = newCikk;
      submitObj.cikk = cikk;
      submitObj.cikkNev = cikkNev;
      submitObj.cikkId = cikk && cikk.id;
      submitObj.mennyiseg = NumberFormatter.removeSpacesFromString(mennyiseg);
      submitObj.nettoAr = NumberFormatter.removeSpacesFromString(nettoEgysegar);
      submitObj.afaAr = NumberFormatter.removeSpacesFromString(afaEgysegar);
      submitObj.bruttoAr = NumberFormatter.removeSpacesFromString(bruttoEgysegar);
      submitObj.afaId = afaId && parseInt(afaId, 10);
      submitObj.afaSzazalek = afaSzazalek;
      submitObj.nettoOssz = NumberFormatter.removeSpacesFromString(nettoOsszAr);
      submitObj.afaOssz = NumberFormatter.removeSpacesFromString(afaOsszAr);
      submitObj.bruttoOssz = NumberFormatter.removeSpacesFromString(bruttoOsszAr);
      submitObj.irany = szamolasIrany;

      const editedIdx = cikkek.findIndex(c => c.id == id);
      let newCikkek = [...cikkek];
      newCikkek[editedIdx] = submitObj;
      setCikkek(newCikkek);
    }

    toggleCikkModal();
  };

  const renderForm = () => {
    const selectStyles = {
      control: base => ({
        ...base,
        '&:focus': { borderColor: 'red' }, // border style on hover
        border: '1px solid lightgray', // default border color
        boxShadow: 'none', // no box-shadow
        borderRadius: '0'
      }),
    };
    return (
      <React.Fragment>
        <div className="row" style={ROW_STYLE}>
          <div className="col-md-12" style={{ paddingRight: '2px' }}>
            <Label for="cikk">Cikk: *</Label>
            <Select
              styles={selectStyles}
              name="cikk"
              id="cikk"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              onChange={(v) => {
                if (!v) {
                  setMennyiseg('');
                  clearPrices();
                }
                setCikk(v);
                setCikkNev(v ? v.megnevezes : '');
                setAfaId(v ? v.aktualisAfaId : '');
                setAfaSzazalek(v ? v.aktualisAfaErtek : '');
                setIsNullaAfa(v && v.aktualisAfaErtek === 0);
              }}
              loadOptions={_.debounce(loadCikkOptions, 1000)}
              getOptionLabel={option => option.megnevezes + ' (' + option.cikkszam + ')'}
              getOptionValue={option => option.id}
              value={cikk}
              isDisabled={isElkuldott}
              isClearable
              // defaultOptions
              required
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-7" style={ROW_STYLE}>
            <Label for="megnevezes">Megnevezés: </Label>
            <AvInput
              name="megnevezes"
              id="megnevezes"
              value={cikkNev}
              onChange={(e, v) => setCikkNev(v)}
              disabled={isElkuldott || !cikk}
              autoComplete="off"
            />
          </div>
          <div className="col-md-5" style={ROW_STYLE}>
            <Label for="cikkszam">Cikkszám: </Label>
            <AvInput
              name="cikkszam"
              id="cikkszam"
              value={cikk ? cikk.cikkszam : ''}
              disabled
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-7" style={ROW_STYLE}>
            <SlaveAvInput
              key="mennyiseg"
              id="mennyiseg"
              label="Mennyiség: *"
              required
              disabled={!cikk}
              value={mennyiseg}
              autoComplete="off"
              onChange={(e, value) => {
                setMennyiseg(value);
                calculate(null, null, value || -1, null);
                if (!value || value === '0') {
                  clearPrices();
                }
              }}
              szuroTipus={NumberFormatter.OSSZEG_6}
              emptyStringOnZero
              inputGroupAddon={cikk ? cikk.mennyisegiEgyseg : ''}
            />
          </div>
          <div className="col-md-5" style={ROW_STYLE}>
            <Label for="afa">ÁFA kulcs: *</Label>
            <AvGroup>
              <AvInput
                type="select"
                name="afa"
                id="afa"
                value={afaId}
                onChange={(e, value) => {
                  setAfaId(value);
                  const { ertek } = value && afaKulcsJSON.find(a => a.id === parseInt(value, 10));
                  setAfaSzazalek(ertek);
                  setIsNullaAfa(ertek === 0);
                  calculate(null, null, null, ertek);
                }}
                disabled={!cikk}
                required
              >
                {cikk && afaKulcsOptions}
              </AvInput>
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <b>{'Egységár' + (penznem ? ' (' + penznem.jel + '):' : ':')}</b>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <SlaveAvInput
              key="nettoEgysegar"
              id="nettoEgysegar"
              label="Nettó egységár: *"
              required={mennyiseg}
              disabled={!cikk || !mennyiseg || mennyiseg === '0'}
              value={nettoEgysegar}
              onChange={(e, value) => calculate(value, null, null, null)}
              szuroTipus={NumberFormatter.OSSZEG_6}
              emptyStringOnZero
              autoComplete="off"
            />
          </div>
          <div className="col-md-4">
            <SlaveAvInput
              id="afa"
              label="ÁFA érték:"
              disabled
              value={afaEgysegar}
              szuroTipus={NumberFormatter.OSSZEG_6}
              emptyStringOnZero={!isNullaAfa || (!nettoEgysegar || !bruttoEgysegar)}
            />
          </div>
          <div className="col-md-4">
            <SlaveAvInput
              id="bruttoEgysegar"
              label="Bruttó egységár:"
              disabled
              value={bruttoEgysegar}
              szuroTipus={NumberFormatter.OSSZEG_6}
              emptyStringOnZero
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <b>{'Össz. érték' + (penznem ? ' (' + penznem.jel + '):' : ':')}</b>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <SlaveAvInput
              key="netto"
              id="nettoErtek"
              label="Nettó érték:"
              disabled
              value={nettoOsszAr}
              szuroTipus={NumberFormatter.OSSZEG_6}
              emptyStringOnZero
            />
          </div>
          <div className="col-md-4">
            <SlaveAvInput
              id="afa"
              label="ÁFA érték:"
              disabled
              value={afaOsszAr}
              szuroTipus={NumberFormatter.OSSZEG_6}
              emptyStringOnZero={!isNullaAfa || (!nettoEgysegar || !bruttoEgysegar)}
            />
          </div>
          <div className="col-md-4">
            <SlaveAvInput
              id="bruttoErtek"
              label="Bruttó érték: *"
              required={mennyiseg}
              disabled={!cikk || !mennyiseg || mennyiseg === '0'}
              value={bruttoOsszAr}
              onChange={(e, value) => calculate(null, value, null, null)}
              szuroTipus={NumberFormatter.OSSZEG_6}
              emptyStringOnZero
              autoComplete="off"
            />
          </div>
        </div>
      </React.Fragment>
    );
  };

  const renderButtons = () => {
    return (
      <div className="row" style={{ marginTop: 50 }}>
        <div className="col-md-12">
          <Button
            className="btn-cursor-pointer"
            color="success"
            type="submit"
            style={{ marginRight: '10px' }}
          >
            Mentés
          </Button>
          <Button type="button" color="secondary" onClick={() => toggleCikkModal()}>
            Mégsem
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal isOpen={cikkModal} toggle={() => toggleCikkModal(!cikkModal)} backdrop="static">
      <AvForm onValidSubmit={saveAjanlatCikkRendeles}>
        <ModalHeader>
          Cikk
          {id ? ' módosítása' : ' hozzáadása'}
        </ModalHeader>
        <ModalBody>
          <div className="row" style={{ padding: 20 }}>
            <div className="col-md-12">
              {renderForm()}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          {renderButtons()}
        </ModalFooter>
      </AvForm>
    </Modal>
  );
};

export default AjanlatCikkForm;
