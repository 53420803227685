class NumberFormatter {
  static ADOSZAM = {
    lepesszam: 8
  };

  static ADOSZAMAZON = {
    lepesszam: 10,
  };

  static BANKSZAMLA = {
    lepesszam: 8,
  };

  static OSSZEG = {
    lepesszam: 3,
  };

  static OSSZEG_6 = {
    lepesszam: 3,
  };

  static EGYSEGAR = {
    lepesszam: 6,
  };

  static MENNYISEG = {
    lepesszam: 3,
  };

  static TAJSZAM = {
    lepesszam: 3,
  };

  static formatterSpaceOsszegPenznem = (cell, row, jel) => {
    let penznemJel = row && row.penznem ? row.penznem.jel : '';
    penznemJel = jel || penznemJel;
    return (NumberFormatter.addSpacesToString(cell, NumberFormatter.OSSZEG) + ' ' + penznemJel).trim();
  }

  static formatterSpaceOsszeg = (cell) => {
    return NumberFormatter.addSpacesToString(cell, NumberFormatter.OSSZEG);
  }

  static styleTextAlign = () => {
    return { textAlign: 'right' };
  }

  static styleTextAlignWhiteSpace = () => {
    return { whiteSpace: 'pre-line', textAlign: 'right' };
  }

  static addHyphensToString = (string) => {
    if (string) {
      if (string.indexOf('-') > -1)
        string = NumberFormatter.removeHyphensFromString(string);
      return string.slice(0, 8) + (string.length >= 8 ? '-' : '') + string.slice(8, 9) + (string.length >= 9 ? '-' : '') + string.slice(9, 11);
    }
  }

  static addHyphensToStringBankszamla = (string) => {
    if (string) {
      if (string.indexOf('-') > -1)
        string = NumberFormatter.removeHyphensFromString(string);
      return string.slice(0, 8) + (string.length >= 8 ? '-' : '') + string.slice(8, 16) + (string.length > 16 ? '-' : '') + string.slice(16);
    }
  }

  static addSpacesToString = (eredetiString, tipus, returnEmptyStringOnZero) => {
    if (eredetiString == 0) {
      if (returnEmptyStringOnZero)
        return '';
      else
        return '0';
    } else if (eredetiString && tipus && tipus.lepesszam) {
      eredetiString += '';
      eredetiString = NumberFormatter.removeSpacesFromString(eredetiString);
      let levagottKarakter = "";
      if (eredetiString.charAt(0) == '-') {
        levagottKarakter = eredetiString.slice(0, 1);
        eredetiString = eredetiString.slice(1);
      }
      let string = eredetiString, valaszto = -1;
      if (tipus == NumberFormatter.OSSZEG || tipus == NumberFormatter.OSSZEG_6 || tipus == NumberFormatter.EGYSEGAR) {
        valaszto = string.indexOf('.');
        if (valaszto > -1) {
          string = string.slice(0, valaszto);
        }
      }
      let array = string.split('');
      for (let i = array.length - 1, o = 1; i >= 0; i-- , o++) {
        if (o % tipus.lepesszam == 0 && i != 0)
          array.splice(i, 0, ' ');
      }
      return levagottKarakter + array.join('') + (valaszto > -1 ? eredetiString.slice(valaszto) : '');
    }
  }

  static removeSpacesFromString = (string) => {
    if (string) {
      string += '';
      return string.replace(/\s/g, '');
    }
  }

  static removeHyphensFromString = (string) => {
    if (string) {
      return string.replace(/-/g, '');
    }
  }

  // Ha van pont a számban, akkor levágja az utolsó 0-kat, valamint a pontot is, ha csupa 0-ra végződik
  static removeRedundantZeros = (s) => {
    if (s && s.toString().indexOf('.') > -1) {
      const regex = /\.?0*$/;
      return s.toString().replace(regex, '');
    }
    return s;
  };
}
export default NumberFormatter;
