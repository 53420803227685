import React, { Component } from 'react';
import { Label } from 'reactstrap';
import _ from 'lodash';
import { AvForm } from 'availity-reactstrap-validation'
import Select from 'react-select';
import { DatePicker } from '../../../../../commons/controls.js';
import { ProgressButton } from '../../../../../commons/ProgressButton';
import BootstrapTable from 'react-bootstrap-table-next';
import { PaginationTotal } from '../../../../../commons/controls';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Service from '../services.js';
import Loader from '../../../../../commons/loader';
import NetworkEmptyState from '../../../../../commons/networkemptystate';
import QueryEmptyState from '../../../../../commons/queryempty';
import DataEmptyState from '../../../../../commons/dataemptystate';
import FileSaver from 'file-saver';
import moment from 'moment';
import Excel from 'exceljs';
import NumberFormatter from '../../../../../commons/NumberFormatter.js';

class ForgalomLista extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalNezet: false,
      novekedesJson: [],
      novekedesView: {},
      modalTorles: false,
      listLoadingButton: false,
      tableStatus: 'QUERY', // [LOAD', NETW', 'LIST', 'QUERY', DATA]
      cikkekJson: [],

      // FILTER VALUES
      kezdete: new Date(),
      vege: new Date(),
      filterRaktar: null,
      filterCikk: null,
      filterCikkcsoport: null,

    };
  }

  checkKotelezoMezok = () => {
    if (!this.state.kezdete) {
      this.props.globals.addError("A kezdete mező kitöltése kötelező!");
    } else if (!this.state.vege) {
      this.props.globals.addError("A vége mező kitöltése kötelező!");
    } else if (this.state.kezdete > this.state.vege) {
      this.props.globals.addError("A kezdete dátum nem lehet később a vége dátumnál!");
    } else
      return true;
  }

  updateTable = () => {
    const {
      kezdete,
      vege,
      filterRaktar,
      filterCikkcsoport,
      filterCikk,
    } = this.state;

    if (this.checkKotelezoMezok()) {
      let filters = {};
      filters.kezdete = kezdete ? moment(kezdete).format("YYYY-MM-DD") : null;
      filters.vege = vege ? moment(vege).format("YYYY-MM-DD") : null;
      filters.raktarid = filterRaktar ? filterRaktar.id : null;
      filters.cikkcsoportid = filterCikkcsoport ? filterCikkcsoport.id : null;
      filters.cikkid = filterCikk ? filterCikk.id : null;

      this.setState({ listLoadingButton: true, tableStatus: 'LOAD' }, () => {
        Service.listIdoszakiForgalom(filters, (err, res) => {
          if (!err) {
            let newForgalom = res[0];
            this.setState({
              forgalomJson: newForgalom,
              listLoadingButton: false,
              tableStatus: newForgalom && newForgalom.length > 0 ? 'LIST' : 'DATA',
            });
          } else {
            this.setState({
              listLoadingButton: false,
              tableStatus: err && err.responseJSON && err.responseJSON.hibaazon == "EMPTY_FILTERS" ? 'QUERY' : 'NETW',
            });
          }
        });
      });
    }
  }

  handleDateInputChange = (e, v, name) => {
    const value = v === '' ? null : v;
    this.setState({
      [name]: value
    });
  }

  handleUpdateCikkcsoportInput = (value, { action }) => {
    switch (action) {
      case 'input-change':
        if (value && value.length > 2) {
          this.setState({ cikkcsoportLoading: true }, () => {
            Service.queryCikkcsoport(value, (err, res) => {
              if (!err) {
                this.setState({ cikkcsoportJson: res[0] });
              }
              this.setState({ cikkcsoportLoading: false });
            });
          });
        }
        break;
      default:
    }
  }

  handleUpdateCikkInput = (value, { action }) => {
    switch (action) {
      case 'input-change':
        if (value && value.length > 2) {
          this.setState({ cikkLoading: true }, () => {
            Service.queryCikk(value, true, false, (err, res) => {
              if (!err) {
                this.setState({ cikkJson: res[0] });
              }
              this.setState({ cikkLoading: false });
            });
          });
        }
        break;
      default:
    }
  }

  handleUpdateRaktarInput = (value, { action }) => {
    switch (action) {
      case 'input-change':
        this.setState({ raktarLoading: true }, () => {
          Service.searchRaktar(value, (err, res) => {
            if (!err) {
              this.setState({ raktarJson: res[0] });
            }
            this.setState({ raktarLoading: false });
          });
        });
        break;
      default:
    }
  }

  renderFilters = () => {
    const {
      kezdete: kezdete,
      vege: vege,
      filterCikkcsoport,
      filterCikk,
      filterRaktar,
    } = this.state;
    return (
      <React.Fragment>
        <AvForm>
          <div className="row">
            <div className="col-md-2">
              <Label for="idoszakkezdete">Időszak kezdete:</Label>
              <DatePicker
                name="idoszakkezdete"
                value={kezdete && new Date(kezdete)}
                onChange={(e, v) => this.handleDateInputChange(e, v, "kezdete")}
              />
            </div>
            <div className="col-md-2">
              <Label for="idoszakvege">Időszak vége:</Label>
              <DatePicker
                name="idoszakvege"
                value={vege && new Date(vege)}
                onChange={(e, v) => this.handleDateInputChange(e, v, "vege")}
              />
            </div>

          </div>
          <div className="row">
            <div className="col-md-3" >
              <Label for="filterRaktar">Raktár:</Label>
              <Select
                styles={{
                  control: (base) => ({
                    ...base,
                    '&:focus': { borderColor: 'red' },
                    border: '1px solid lightgray',
                    boxShadow: 'none',
                    borderRadius: '0'
                  }),
                }}
                name="filterRaktar"
                id="filterRaktar"
                placeholder="Kezdjen el gépelni a kereséshez..."
                noOptionsMessage={() => "Nincs keresési eredmény"}
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingMessage={() => "Keresés..."}
                onChange={v => this.setState({ filterRaktar: v })}
                onInputChange={_.debounce(this.handleUpdateRaktarInput, 1000)}
                options={this.state.raktarJson}
                value={filterRaktar}
                isClearable
                isLoading={this.state.raktarLoading}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.nev}
              />
            </div>
            <div className="col-md-3" >
              <Label for="filterCikkcsoport">Cikkcsoport:</Label>
              <Select
                styles={{
                  control: (base) => ({
                    ...base,
                    '&:focus': { borderColor: 'red' },
                    border: '1px solid lightgray',
                    boxShadow: 'none',
                    borderRadius: '0'
                  }),
                }}
                name="filterCikkcsoport"
                id="filterCikkcsoport"
                placeholder="Kezdjen el gépelni a kereséshez..."
                noOptionsMessage={() => "Nincs keresési eredmény"}
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingMessage={() => "Keresés..."}
                onChange={v => this.setState({ filterCikkcsoport: v })}
                onInputChange={_.debounce(this.handleUpdateCikkcsoportInput, 1000)}
                options={this.state.cikkcsoportJson}
                value={filterCikkcsoport}
                isClearable
                isLoading={this.state.cikkcsoportLoading}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.nev}
              />
            </div>
            <div className="col-md-3" >
              <Label for="filterCikk">Cikk:</Label>
              <Select
                styles={{
                  control: (base) => ({
                    ...base,
                    '&:focus': { borderColor: 'red' },
                    border: '1px solid lightgray',
                    boxShadow: 'none',
                    borderRadius: '0'
                  }),
                }}
                name="filterCikk"
                id="filterCikk"
                placeholder="Kezdjen el gépelni a kereséshez..."
                noOptionsMessage={() => "Nincs keresési eredmény"}
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingMessage={() => "Keresés..."}
                onChange={v => this.setState({ filterCikk: v })}
                onInputChange={_.debounce(this.handleUpdateCikkInput, 1000)}
                options={this.state.cikkJson}
                value={filterCikk}
                isClearable
                isLoading={this.state.cikkLoading}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.megnevezes + ' (' + option.cikkszam + ')'}
              />
            </div>
            <div className="col-md-2" style={{ paddingTop: "28px" }}>
              <ProgressButton color="primary" id="save" loading={this.state.listLoadingButton} onClick={e => this.updateTable(e)} >Keresés</ProgressButton>{' '}
            </div>
          </div>
        </AvForm>
      </React.Fragment>
    )
  }

  renderXlsxButton = () => {
    return <button
      type="button"
      className="btn btn-success"
      style={{ marginBottom: 10 }}
      onClick={() => {
        const datas = JSON.parse(JSON.stringify(this.state.forgalomJson));

        let xlsDatas = [];
        datas.forEach((element) => {

          xlsDatas.push([
            element.raktarnev, element.cikkcsoportNeve, element.cikkNeve, element.cikkKodja, element.mennyisegEgyseg,
            element.bevetelezesMennyiseg, element.csokkenesMennyiseg, element.leltarTobbletMennyiseg, element.leltarHianySelejtMennyiseg
          ]);
        });

        let kezdeteFormated = moment(this.state.kezdete).format('YYYYMMDD');
        let vegeFormated = moment(this.state.vege).format('YYYYMMDD');

        const wb = new Excel.Workbook();
        const ws = wb.addWorksheet(kezdeteFormated + '-' + vegeFormated);

        ws.columns = [
          { key: 'raktarnev', width: 35 },
          { key: 'cikkcsoportNeve', width: 35 },
          { key: 'cikkNeve', width: 35 },
          { key: 'cikkKodja', width: 35 },
          { key: 'mennyisegEgyseg', width: 35 },
          { key: 'bevetelezesMennyiseg', width: 35, style: { alignment: {horizontal: 'right'}}},
          { key: 'csokkenesMennyiseg', width: 35, style: { alignment: {horizontal: 'right'}}},
          { key: 'leltarTobbletMennyiseg', width: 35, style: { alignment: { horizontal: 'right'}}},
          { key: 'leltarHianySelejtMennyiseg', width: 35, style: { alignment: { horizontal: 'right'}}},
        ];

        ws.addTable({
          name: 'Táblázat',
          ref: 'A1',
          headerRow: true,
          style: {
            theme: null,
            showRowStripes: true,
          },
          columns: [
            { name: 'Raktár', filterButton: true },
            { name: 'Cikkcsoport neve', filterButton: true },
            { name: 'Cikk neve', filterButton: true },
            { name: 'Cikkszám', filterButton: true },
            { name: 'Mennyiségi egység', filterButton: true },
            { name: 'Növekedés', filterButton: true },
            { name: 'Csökkenés', filterButton: true },
            { name: 'Leltár többlet', filterButton: true },
            { name: 'Leltár hiány, selejtezés', filterButton: true },
          ],
          rows: xlsDatas,
        });
        ws.getRow(1).alignment = 'left';
        ws.getRow(1).font = { bold: true, size: 16 };
        if (datas.length > 0) {
          wb.xlsx.writeBuffer()
            .then(buffer => FileSaver.saveAs(new Blob([buffer]), 'keszlet_forgalom_' + kezdeteFormated + '-' + vegeFormated + '.xlsx'))
            .catch();
        }
      }
      }
    >
      Összesített forgalmi adatok xlsx export
    </button >
  }

  renderXlsxReszletesButton = () => {
    return (
      <button
        type="button"
        className="btn btn-success"
        style={{ marginBottom: 10, marginLeft: 20 }}
        onClick={() => {
          let {
            kezdete,
            vege,
            filterRaktar,
            filterCikkcsoport,
            filterCikk
          } = this.state;
          let filters = {};
          filters.kezdete = kezdete ? moment(kezdete).format("YYYY-MM-DD") : undefined;
          filters.vege = vege ? moment(vege).format("YYYY-MM-DD") : undefined;
          filters.raktarid = filterRaktar ? filterRaktar.id : undefined;
          filters.cikkcsoportid = filterCikkcsoport ? filterCikkcsoport.id : undefined;
          filters.cikkid = filterCikk ? filterCikk.id : undefined;
          Service.listIdoszakiReszletesForgalom(filters, (err, res) => {
            if (!err) {
              FileSaver.saveAs(new Blob([res[0]],
                {
                  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  responseType: 'arraybuffer'
                }),
              'keszlet_reszletes_forgalom_' + filters.kezdete + ' - ' + filters.vege + '.xlsx');
            }
          });
        }}
      >
        Részletes forgalmi adatok xlsx export
      </button>
    );
  }

renderTable = () => {
  const options = {
    sizePerPage: 50,
    showTotal: true,
    paginationTotalRenderer: PaginationTotal,
    sizePerPageList: [
      { text: '25', value: 25 },
      { text: '50', value: 50 },
      { text: '100', value: 100 }
    ],
  };

  const columns = [
    {
      dataField: 'raktarnev',
      text: 'Raktár',
      filter: textFilter({
        delay: 400,
        placeholder: 'Keresés...',
      }),
      style: {
        whiteSpace: 'pre-line'
      },
      sort: true
    },
    {
      dataField: 'cikkcsoportNeve',
      text: 'Cikkcsoport neve',
      filter: textFilter({
        delay: 400,
        placeholder: 'Keresés...',
      }),
      style: {
        whiteSpace: 'pre-line'
      },
      sort: true
    },
    {
      dataField: 'cikkNeve',
      text: 'Cikk neve',
      filter: textFilter({
        delay: 400,
        placeholder: 'Keresés...',
      }),
      style: {
        whiteSpace: 'pre-line'
      },
      sort: true,
    },
    {
      dataField: 'cikkKodja',
      text: 'Cikkszám',
      filter: textFilter({
        delay: 400,
        placeholder: 'Keresés...',
      }),
      style: {
        whiteSpace: 'pre-line'
      },
      sort: true
    },
    {
      dataField: 'mennyisegEgyseg',
      text: 'Mennyiségi egység',
      filter: textFilter({
        delay: 400,
        placeholder: 'Keresés...',
      }),
      style: {
        whiteSpace: 'pre-line',
        textAlign: 'right'
      },
      sort: true
    },
    {
      dataField: 'bevetelezesMennyiseg',
      text: 'Növekedés',
      formatter: NumberFormatter.formatterSpaceOsszeg,
      style: NumberFormatter.styleTextAlignWhiteSpace,
      sort: true,
    },
    {
      dataField: 'csokkenesMennyiseg',
      text: 'Csökkenés',
      formatter: NumberFormatter.formatterSpaceOsszeg,
      style: NumberFormatter.styleTextAlignWhiteSpace,
      sort: true
    },
    {
      dataField: 'leltarTobbletMennyiseg',
      text: 'Leltár többlet',
      formatter: NumberFormatter.formatterSpaceOsszeg,
      style: NumberFormatter.styleTextAlignWhiteSpace,
      sort: true,
    },
    {
      dataField: 'leltarHianySelejtMennyiseg',
      text: 'Leltár hiány, selejtezés',
      formatter: NumberFormatter.formatterSpaceOsszeg,
      style: NumberFormatter.styleTextAlignWhiteSpace,
      sort: true,
    },
  ];

  return (
    <div>
      {this.state.forgalomJson && this.state.forgalomJson.length > 0 ? this.renderXlsxButton() : <div />}
      {this.state.forgalomJson && this.state.forgalomJson.length > 0 && this.renderXlsxReszletesButton()}
      <div className="animated fadeIn">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={this.state.forgalomJson}
          columns={columns}
          filter={filterFactory()}
          pagination={paginationFactory(options)}
          noDataIndication="Nincs megjelenítendő adat!"
        />
      </div>
    </div>
  );
}

render() {
  return (
    <div>
      {this.renderFilters()}
      {this.state.tableStatus === 'LOAD' ? <Loader /> : <span />}
      {this.state.tableStatus === 'QUERY' ? <QueryEmptyState hasImage /> : <span />}
      {this.state.tableStatus === 'DATA' ? <DataEmptyState hasImage subtitle="Próbálj más előszűrést vagy vigyél fel új adatokat!" /> : <span />}
      <div style={{ paddingTop: '20px' }}>
        {this.state.tableStatus === 'LIST' ? this.renderTable() : <span />}
        {this.state.tableStatus === 'NETW' ? (
          <NetworkEmptyState
            hasImage
            submitClick={() => this.updateTable()}
          />) : <span />}
      </div>
    </div>
  );
}
}

export default ForgalomLista;
