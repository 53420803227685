import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import classnames from 'classnames';
import YouTube from '../../../commons/youtube';
import Lib from '../../../commons/lib';
import CustomTooltip from '../../../components/Tooltip/CustomTooltip';
import PartnercsoportLista from './PartnercsoportLista';
import PartnertorzsLista from './PartnertorzsLista';
import getTooltip from './PartnerTooltips';

const videoData = [
  { text: 'Partnercsoport', id: 'HE-fbG-VTbs' },
  { text: 'Partnercsoport törlés', id: 'Xfep-8Vz_aA' },
  { text: 'Partnertörzs', id: 'tBd-2XbRpzg' }
];

class PartnerBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: undefined,
    };
  }

  componentDidMount() {
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['PARTNER_CSOPORT'], true)) this.toggle('1');
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['PARTNER_TORZS'], true)) this.toggle('2');
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  // hasRole = (role) => {
  // return Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, [role]);
  // }

  render() {
    let cardStyle = {
      padding: 10
    };

    return (
      <div>
        <div className="animated fadeIn">
          <div className="card">
            <div className="card-header">
              Partner
              <YouTube data={videoData} />
            </div>
            <div className="row" style={cardStyle}>
              <div className="col-md-12">
                <Nav tabs>
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['PARTNER_CSOPORT'], true) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => { this.toggle('1'); }}
                      >
                        Partnercsoport
                        <CustomTooltip text={getTooltip('partnercsoport')} id="partnercsoportTT" />
                      </NavLink>
                    </NavItem>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['PARTNER_TORZS'], true) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => { this.toggle('2'); }}
                      >
                        Partnertörzs
                        <CustomTooltip text={getTooltip('partnertorzs')} id="partnertorzsTT" />
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['PARTNER_CSOPORT'], true) && (
                    <TabPane tabId="1">
                      <PartnercsoportLista globals={this.props.route.globals} type={this.state.type} active={this.state.activeTab === '1'} />
                    </TabPane>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['PARTNER_TORZS'], true) && (
                    <TabPane tabId="2">
                      <PartnertorzsLista globals={this.props.route.globals} type={this.state.type} active={this.state.activeTab === '2'} />
                    </TabPane>
                  )}
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PartnerBase.propTypes = {
  route: PropTypes.object,
  globals: PropTypes.object
};

export default PartnerBase;
