import React, { Component } from 'react';
import _ from 'lodash';
import {
  Label
} from 'reactstrap';
import {
  AvForm,
  AvField,
} from 'availity-reactstrap-validation';
import { DatePicker } from '../../../commons/controls';
import { ProgressButton } from '../../../commons/ProgressButton';
import NetworkEmptyState from '../../../commons/networkemptystate';
import Loader from '../../../commons/loader';
import Services from './services';
import fileSaver from 'file-saver';
import moment from 'moment';

class BankAlaplistaContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bankszamlaList: [],
      bankTetelTipusList: [],
      bankszamla: null,
      tol: null,
      ig: null,
      bankTetelTipus: null,
      listButtonLoading: false,
      // [LOAD', NETW', 'LIST']
      formStatus: 'LOAD'
    };
  }

  componentDidMount() {
    this.getData();
  }

  onSubmit = () => {
    this.setState({ listButtonLoading: true }, () => {
      Services.exportBankalapXLS(this.state.bankszamla, this.state.tol, this.state.ig, this.state.bankTetelTipus, (err, res) => {
        this.setState({ listButtonLoading: false });
        if (!err) {
          this.props.globals.addSuccess('Bank alaplista XLSX letöltése...');
          fileSaver.saveAs(new Blob([res[0]], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', responseType: 'arraybuffer' }), 'Bankalaplista-' + moment().format('YYYY-MM-DD') + '.xlsx');
        }
      });
    });
  }

  /** Objektumot bejárva az üres stringeket NULL-ra állítja */
  emptyToNull = (obj) => {
    if (!obj || typeof obj !== 'object') return;
    for (let p in obj) {
      if (typeof obj[p] === 'object' && obj[p] !== null) {
        this.emptyToNull(obj[p]);
      } else if (obj.hasOwnProperty(p)) {
        if (obj[p] === '' || obj[p] === undefined) {
          obj[p] = null;
        }
      }
    }
  }

  getBankszamlaList = () => {
    Services.listSzamlak((err, res) => {
      if (!err) {
        let bankszamlaList = res[0].map(e => <option key={e.id} value={e.id}>{e.nev}</option>);
        this.setState({ bankszamlaList: bankszamlaList });
      }
    });
  }

  getBankTetelTipusList = () => {
    Services.getEnum((err, res) => {
      if (!err) {
        let resOptions = res[0][4].map(e => (
          <option key={e.name} value={e.name}>
            {e.caption}
          </option>
        ));
        this.setState({ bankTetelTipusList: resOptions });
      }
    });
  }

  getData = () => {
    this.setState({ formStatus: 'LOAD' });
    Promise.all([
      this.getBankszamlaList(),
      this.getBankTetelTipusList()
    ])
      .then(() => {
        this.setState({
          formStatus: 'LIST'
        });
      })
      .catch(() => {
        this.setState({
          formStatus: 'NETW'
        });
      });
  }

  handleDateInputChange = (e, v, name) => {
    const value = v === '' ? null : v;
    this.setState({
      [name]: value
    });
  }

  selectBankszamla = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({bankszamla: value});
  }

  selectBankTetelTipus = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({bankTetelTipus: value});
  }

  render() {
    return (
      <AvForm onValidSubmit={this.onSubmit}>
        {this.state.formStatus === 'LIST' ? (
          <div className="row">
            <div className="col-md-3">
              <AvField
                type="select"
                name="bankszamla"
                label="Bankszámla: "
                onChange={this.selectBankszamla}
              >
                <option value="">
                  {'Válassz bankszámlát...'}
                </option>
                {this.state.bankszamlaList}
              </AvField>
            </div>
            <div className="col-md-3">
              <Label for="datumKezdete">
                {'Kivonat dátum kezdete:'}
              </Label>
              <DatePicker
                placeholder="éééé-hh-nn"
                onChange={(e, v) => this.handleDateInputChange(e, v, 'tol')}
                value={this.state.tol && new Date(this.state.tol)}
                type="select"
                name="datumKezdete"
              />
            </div>
            <div className="col-md-3">
              <Label for="datumVege">
                {'Kivonat dátum vége:'}
              </Label>
              <DatePicker
                placeholder="éééé-hh-nn"
                onChange={(e, v) => this.handleDateInputChange(e, v, 'ig')}
                value={this.state.ig && new Date(this.state.ig)}
                type="select"
                name="datumVege"
              />
            </div>
            <div className="col-md-3">
              <AvField
                type="select"
                name="bankteteltipus"
                label="Bank tétel típusok: "
                onChange={this.selectBankTetelTipus}
              >
                <option value="">
                  {'Válassz típust...'}
                </option>
                {this.state.bankTetelTipusList}
              </AvField>
            </div>
            <div className="col-md-12" />
            <div className="col-md-4">
              <ProgressButton
                color="primary"
                loading={this.state.listButtonLoading}
              >
                {'Lista futtatása'}
              </ProgressButton>
              {' '}
            </div>
          </div>
        ) : <span />}
        {this.state.formStatus === 'LOAD' ? <Loader /> : <span />}
        {this.state.formStatus === 'NETW' ? (
          <NetworkEmptyState
            hasImage
            submitClick={() => this.getData()}
          />
        ) : <span />}
      </AvForm>
    );
  }
}

export default BankAlaplistaContent;
