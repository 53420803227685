import React, { Component } from 'react';
import _ from 'lodash';
import {
  Label
} from 'reactstrap';
import FileSaver from 'file-saver';
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback
} from 'availity-reactstrap-validation';
import Select from 'react-select/lib/Async';
import { DatePicker } from '../../../commons/controls';
import { ProgressButton } from '../../../commons/ProgressButton';
import NetworkEmptyState from '../../../commons/networkemptystate';
import Loader from '../../../commons/loader';
import Services from './services';
import NumberFormatter from '../../../commons/NumberFormatter';

class SzamlaListaContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aktivRCIhetek: [],
      aktivPenznem: null,
      aktivFizmod: null,
      aktivSzallodak: null,
      aktivEpuletek: [],
      aktivApartmanok: [],
      aktivPartertipusok: [],
      aktivSzamlatipusok: [],
      listButtonLoading: false,
      // FORM VALUES
      szamlakibocsatoValue: '',
      fojogtulajdonosValue: '',
      szalloda: '',
      epulet: '',
      apartman: '',
      // FORM STATUS
      // [LOAD', NETW', 'LIST']
      formStatus: 'LOAD'
    };
  }

  componentDidMount() {
    this.getData();
    this.genWeeks();
  }

  onSubmit = (event, submitObj) => {
    submitObj.szamlakibocsatoId = this.state.szamlakibocsatoValue && this.state.szamlakibocsatoValue.id === '' ? null : this.state.szamlakibocsatoValue.id;
    submitObj.fotulajId = this.state.fojogtulajdonosValue && this.state.fojogtulajdonosValue.id === '' ? null : this.state.fojogtulajdonosValue.id;
    submitObj.szallodaId = this.state.szalloda;
    submitObj.epuletId = this.state.epulet;
    submitObj.apartmanId = this.state.apartman;
    let beforeHetek = JSON.parse(JSON.stringify(submitObj));
    beforeHetek.hetek = submitObj.hetek.filter(Number);
    let fullObj = JSON.parse(JSON.stringify(beforeHetek));
    this.emptyToNull(fullObj);

    this.setState({ listButtonLoading: true });
    Services.exportSzamlaXlsx(fullObj, (err, res) => {
      if (!err) {
        this.props.globals.addSuccess('Számla lista XLSX letöltése...');
        FileSaver.saveAs(new Blob([res[0]], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', responseType: 'arraybuffer' }), 'SZAMLA_LISTA.xlsx');
        // let fileURL = URL.createObjectURL(new Blob([res[0]], { type: 'application/pdf' }));
        // window.open(fileURL);
      }

      this.setState({ listButtonLoading: false })});
  }

  setButtonStat = (obj) => {
    this.setState(obj);
  }

  /** Objektumot bejárva az üres stringeket NULL-ra állítja */
  emptyToNull = (obj) => {
    if (!obj || typeof obj !== 'object') return;
    for (let p in obj) {
      if (typeof obj[p] === 'object' && obj[p] !== null) {
        this.emptyToNull(obj[p]);
      } else if (obj.hasOwnProperty(p)) {
        if (obj[p] === '' || obj[p] === undefined) {
          obj[p] = null;
        }
      }
    }
  }

  getPartnerEnum = () => {
    return new Promise((resolve, reject) => {
      Services.getPartnerEnum((err, res) => {
        if (!err) {
          let resOptions = res[0][0].map(e => (
            <option key={e.caption} value={e.caption}>
              {e.caption}
            </option>
          ));
          this.setState({ aktivPartertipusok: resOptions });
          resolve(resOptions);
        } else {
          reject(new Error('Hálozati hiba!'));
        }
      });
    });
  }

  getAktivPenznem = () => {
    return new Promise((resolve, reject) => {
      Services.getPenznem('SZAMLA', (err, res) => {
        if (!err) {
          let resOptions = res[0].map(e => (<option key={e.id} value={e.id}>{e.nev + ' (' + e.azonosito + ')'}</option>));
          this.setState({ aktivPenznem: resOptions });
          resolve(resOptions);
        } else {
          reject(new Error('Hálozati hiba!'));
        }
      });
    });
  }

  getSzamlaEnum = () => {
    return new Promise((resolve, reject) => {
      Services.getSzamlaEnum((err, res) => {
        if (!err) {
          let resOptions = res[0][2] && res[0][2].map(e => (<option key={e.name} value={e.name}>{e.caption}</option>));
          this.setState({ aktivSzamlatipusok: resOptions });
          resolve(resOptions);
        } else {
          reject(new Error('Hálozati hiba!'));
        }
      });
    });
  }

  getAktivFizmod = () => {
    return new Promise((resolve, reject) => {
      Services.getFizmod('SZAMLA', (err, res) => {
        if (!err) {
          let resOptions = res[0].map(e => <option key={e.variansid ? e.variansid : e.id} value={e.variansid ? e.variansid : e.id}>{e.variansnev ? e.variansnev : e.nev}</option>);
          this.setState({ aktivFizmod: resOptions });
          resolve(resOptions);
        } else {
          reject(new Error('Hálozati hiba!'));
        }
      });
    });
  }

  renderSzallodaOptions = () => {
    return new Promise((resolve, reject) => {
      Services.listSzallodak((err, res) => {
        if (!err) {
          let aktivSzallodak = res[0].map(e => (
            <option key={e.id} value={e.id}>
              {e.nev}
            </option>
          ));
          this.setState({ aktivSzallodak: aktivSzallodak });
          resolve(aktivSzallodak);
        } else {
          reject(new Error('Hálozati hiba!'));
        }
      });
    });
  }

  sleep = (time) => {
    return new Promise(resolve => setTimeout(resolve, time));
  }

  getData = () => {
    this.setState({ formStatus: 'LOAD' });
    Promise.all([
      this.getAktivPenznem(),
      this.getAktivFizmod(),
      this.renderSzallodaOptions(),
      this.getPartnerEnum(),
      this.getSzamlaEnum(),
    ])
      .then(() => {
        this.setState({
          formStatus: 'LIST'
        });
      })
      .catch(() => {
        this.setState({
          formStatus: 'NETW'
        });
      });
  }

  genWeeks = () => {
    let weeks = [];
    for (let i = 1; i < 53; i += 1) {
      weeks.push(
        <option
          key={i.toString()}
          value={i}
        >
          {i.toString() + '. hét'}
        </option>
      );
    }
    this.setState({ aktivRCIhetek: weeks });
  }

  changeSzallodaValue = (e) => {
    this.setState({
      szalloda: e.target.value,
    });
    Services.listEpuletekById(e.target.value, (err, res) => {
      if (!err) {
        let aktivEpuletek = res[0].map(el => (
          <option key={el.id} value={el.id}>
            {el.nev}
          </option>
        ));
        if (res[0].length <= 0) this.props.globals.addInfo('A szállodához nincs aktív épület rendelve!');
        this.setState({
          apartman: '',
          epulet: '',
          aktivEpuletek: aktivEpuletek,
          aktivApartmanok: []
        });
      }
    });
  }

  changeEpuletValue = (e) => {
    this.setState({
      epulet: e.target.value,
    });
    Services.listApartmanokById(e.target.value, (err, res) => {
      if (!err) {
        let aktivApartmanok = res[0].map(el => (
          <option key={el.id} value={el.id}>
            {el.nev}
          </option>
        ));
        if (res[0].length <= 0) this.props.globals.addInfo('Az épülethez nincs aktív apartman rendelve!');
        this.setState({
          apartman: '',
          aktivApartmanok: aktivApartmanok,
        });
      }
    });
  }

  changeApartmanValue = (e) => {
    this.setState({
      apartman: e.target.value,
    });
  }

  handleChangeSzamlakibocsato = (value) => {
    if (value && value.length !== 0) {
      this.setState({ szamlakibocsatoValue: value });
    } else {
      this.setState({ szamlakibocsatoValue: '' });
    }
  }

  handleChangeFojogtulajdonos = (value) => {
    if (value && value.length !== 0) {
      this.setState({ fojogtulajdonosValue: value });
    } else {
      this.setState({ fojogtulajdonosValue: '' });
    }
  }

  loadSzamlakibocsatoChange = (input, callback) => {
    if (input.length !== 0) {
      Services.getSzamlaKibocsato('SZAMLA_SZAMLAKIBOCSATO_ELO', input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            item.nev += ' (' + item.cim + ') ';
            if (item.adoszam) {
              item.nev += '[' + NumberFormatter.addHyphensToString(item.adoszam) + '] ';
            }
            newArray.push(item);
          });
          callback(newArray);
        }
      });
    }
  }

  loadFojogtulajChange = (input, callback) => {
    if (input.length !== 0) {
      Services.getSzamlaKibocsato('SZAMLA_SZAMLAFIZETO_ELO', input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            item.nev += ' (' + item.cim + ') ';
            if (item.adoszam) {
              item.nev += '[' + NumberFormatter.addHyphensToString(item.adoszam) + '] ';
            }
            newArray.push(item);
          });
          callback(newArray);
        }
      });
    }
  }

  render() {
    return (
      <AvForm onValidSubmit={this.onSubmit}>
        {this.state.formStatus === 'LIST' ? (
          <div className="row">
            <div className="col-md-2">
              <AvGroup>
                <Label for="ev">
                  {'Év:'}
                </Label>
                <AvInput
                  name="ev"
                  id="ev"
                  maxLength="4"
                  onChange={this.evChange}
                  value={this.state.eloszuresEv}
                  placeholder="éééé"
                />
                <AvFeedback>
                  {'Kötelező mező!'}
                </AvFeedback>
              </AvGroup>
            </div>
            <div className="col-md-5 form-group">
              <Label for="szallitoId">
                {'Számlakibocsátó:'}
              </Label>
              <Select
                styles={{
                  control: base => ({
                    ...base,
                    '&:focus': { borderColor: 'red' }, // border style on hover
                    border: '1px solid lightgray', // default border color
                    boxShadow: 'none', // no box-shadow
                    borderRadius: '0'
                  }),
                }}
                name="szallitoId"
                id="szallitoId"
                placeholder="Kezdjen el gépelni..."
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingMessage={() => 'Keresés...'}
                onChange={v => this.handleChangeSzamlakibocsato(v)}
                isClearable
                ignoreAccents={false}
                loadOptions={_.debounce(this.loadSzamlakibocsatoChange, 1000)}
                isLoading={false}
                getOptionLabel={option => option.nev}
                getOptionValue={option => option.id}
                value={this.state.szamlakibocsatoValue}
                cache={false}
                autoload={false}
              />
            </div>
            <div className="col-md-5 form-group">
              <Label for="jogtulajId">
                {'Fő jogtulajdonos:'}
              </Label>
              <Select
                styles={{
                  control: base => ({
                    ...base,
                    '&:focus': { borderColor: 'red' }, // border style on hover
                    border: '1px solid lightgray', // default border color
                    boxShadow: 'none', // no box-shadow
                    borderRadius: '0'
                  }),
                }}
                name="jogtulajId"
                id="jogtulajId"
                placeholder="Kezdjen el gépelni..."
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingMessage={() => 'Keresés...'}
                onChange={v => this.handleChangeFojogtulajdonos(v)}
                isClearable
                ignoreAccents={false}
                loadOptions={_.debounce(this.loadFojogtulajChange, 1000)}
                isLoading={false}
                getOptionLabel={option => option.nev}
                getOptionValue={option => option.id}
                value={this.state.fojogtulajdonosValue}
                cache={false}
                autoload={false}
              />
            </div>
            <div className="col-md-4">
              <AvField
                type="select"
                name="penznemId"
                label="Pénznem: "
                errorMessage="Kötelező mező!"
              >
                <option value="">
                  {'Válassz típust...'}
                </option>
                {this.state.aktivPenznem}
              </AvField>
            </div>
            <div className="col-md-4">
              <AvField
                type="select"
                name="fizmodId"
                label="Fizetési mód: "
                errorMessage="Kötelező mező!"
              >
                <option value="">
                  {'Válassz típust...'}
                </option>
                {this.state.aktivFizmod}
              </AvField>
            </div>
            <div className="col-md-12" />
            <div className="col-md-4">
              <AvField
                type="select"
                name="szalloda"
                label="Szálloda: "
                errorMessage="Kötelező mező!"
                value={this.state.szalloda}
                onChange={e => this.changeSzallodaValue(e)}
              >
                <option value="">
                  {'Válassz típust...'}
                </option>
                {this.state.aktivSzallodak}
              </AvField>
            </div>
            <div className="col-md-4">
              <AvField
                type="select"
                name="epulet"
                label="Épület: "
                errorMessage="Kötelező mező!"
                value={this.state.epulet}
                onChange={e => this.changeEpuletValue(e)}
                disabled={this.state.szalloda === '' || (this.state.aktivEpuletek && this.state.aktivEpuletek.length === 0)}
              >
                <option value="">
                  {'Válassz típust...'}
                </option>
                {this.state.aktivEpuletek}
              </AvField>
            </div>
            <div className="col-md-4">
              <AvField
                type="select"
                name="apartman"
                label="Apartman: "
                errorMessage="Kötelező mező!"
                value={this.state.apartman}
                onChange={e => this.changeApartmanValue(e)}
                disabled={this.state.szalloda === '' || (this.state.aktivApartmanok && this.state.aktivApartmanok.length === 0)}
              >
                <option value="">
                  {'Válassz típust...'}
                </option>
                {this.state.aktivApartmanok}
              </AvField>
            </div>
            <div className="col-md-12" />
            <div className="col-md-4">
              <AvField
                type="select"
                name="hetek"
                id="hetek"
                label="Üdülési hét: "
                errorMessage="Kötelező mező!"
                size="6"
                multiple
              >
                <option value="">
                  {'Válassz hete(ke)t...'}
                </option>
                {this.state.aktivRCIhetek}
              </AvField>
            </div>
            <div className="col-md-4">
              <Label for="kelteKezdete">
                {'Számla kelte kezdete:'}
              </Label>
              <DatePicker
                required
                placeholder="éééé-hh-nn"
                onChange={this.formDatumChange}
                value={this.state.kelteKezdete && new Date(this.state.kelteKezdete)}
                type="select"
                name="kelteKezdete"
              />
              <AvField
                type="select"
                name="allapot"
                label="Számla állapot: "
                errorMessage="Kötelező mező!"
              >
                <option value="">
                  {'Válassz típust...'}
                </option>
                {this.state.aktivSzamlatipusok}
              </AvField>
            </div>
            <div className="col-md-4">
              <Label for="kelteVege">
                {'Számla kelte vége:'}
              </Label>
              <DatePicker
                required
                placeholder="éééé-hh-nn"
                onChange={this.formDatumChange}
                value={this.state.kelteVege && new Date(this.state.kelteVege)}
                type="select"
                name="kelteVege"
              />
              <AvField
                type="select"
                name="partnerTipus"
                label="Partner típus: "
                errorMessage="Kötelező mező!"
                value={this.state.partnerTipus}
              >
                <option value="">
                  {'Válassz típust...'}
                </option>
                {this.state.aktivPartertipusok}
              </AvField>
            </div>
            <div className="col-md-4">
              <AvField
                type="select"
                name="partnerOrszagTipus"
                label="Partner ország típus: "
                errorMessage="Kötelező mező!"
              >
                <option value="">
                  {'Válassz típust...'}
                </option>
                <option value="Belföldi">
                  {'Belföldi'}
                </option>
                <option value="Külföldi">
                  {'Külföldi'}
                </option>
              </AvField>
            </div>
            <div className="col-md-4">
              <AvField
                type="select"
                name="vanFizfelszol"
                label="Fizetési felszólító kiküldve: "
                errorMessage="Kötelező mező!"
              >
                <option value="">
                  {'Válassz értéket...'}
                </option>
                <option value="true">
                  {'Igen'}
                </option>
                <option value="false">
                  {'Nem'}
                </option>
              </AvField>
            </div>
            <div className="col-md-4">
              <AvGroup>
                <Label for="azonosito">
                  {'Egyedi azonosító:'}
                </Label>
                <AvInput
                  name="azonosito"
                  id="azonosito"
                />
                <AvFeedback>
                  {'Kötelező mező!'}
                </AvFeedback>
              </AvGroup>
            </div>
            <div className="col-md-12" />
            <div className="col-md-4">
              <ProgressButton
                disabled={this.state.disabled}
                color="primary"
                loading={this.state.listButtonLoading}
              >
                {'Lista futtatása'}
              </ProgressButton>
              {' '}
            </div>
          </div>
        ) : <span /> }
        {this.state.formStatus === 'LOAD' ? <Loader /> : <span />}
        {this.state.formStatus === 'NETW' ? (
          <NetworkEmptyState
            hasImage
            submitClick={() => this.getData()}
          />
        ) : <span />}
      </AvForm>
    );
  }
}

export default SzamlaListaContent;
