import Lib from '../../../commons/lib';

export default class Service {
  static apartmanUrl = window.location.origin + '/api/apartman/';

  static listApartman(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'szalloda/aktiv',
      args: { requestType: 'szalloda-aktiv-list' }
    }], fnDone);
  }

  static exportSzabadApartmanokXlsx(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'apartman/list/szabadApartmanok',
      acceptType: 'application/json, application/octet-stream',
      params: data,
    }], fnDone);
  }
}
