import React, { Component } from 'react';
import classNames from 'classnames';
import {
  Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';
import Lib from '../../../commons/lib';
import FelhasznalokContent from './FelhasznaloContent';
import FelhasznalokCsoportContent from './FelhasznaloCsoportContent';

class FelhasznalokBase extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
    };
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  render() {
    let cardStyle = {
      padding: 10,
    };

    return (
      <div className="animated fadeIn">
        <div className="card">
          <div className="card-header">Felhasználók</div>
          <div className="row" style={cardStyle}>
            <div className="col-md-12">
              <Nav tabs>
                {Lib.KeyCloak.hasHigherRole(this.props.route.globals.keycloak, 'CONFIG_USER', 'KARB') && (
                  <NavItem>
                    <NavLink
                      href="#"
                      className={classNames({ active: this.state.activeTab === '1', })}
                      onClick={() => { this.toggle('1'); }}
                    >
                      Felhasználó
                    </NavLink>
                  </NavItem>
                )}
                {Lib.KeyCloak.hasHigherRole(this.props.route.globals.keycloak, 'CONFIG_USER', 'KARB') && (
                  <NavItem>
                    <NavLink
                      href="#"
                      className={classNames({ active: this.state.activeTab === '2', })}
                      onClick={() => { this.toggle('2'); }}
                    >
                      Csoport
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                {Lib.KeyCloak.hasHigherRole(this.props.route.globals.keycloak, 'CONFIG_USER', 'KARB') && (
                  <TabPane tabId="1">
                    <FelhasznalokContent globals={this.props.route.globals} />
                  </TabPane>
                )}
                {Lib.KeyCloak.hasHigherRole(this.props.route.globals.keycloak, 'CONFIG_USER', 'KARB') && (
                  <TabPane tabId="2">
                    <FelhasznalokCsoportContent globals={this.props.route.globals} />
                  </TabPane>
                )}
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FelhasznalokBase;
