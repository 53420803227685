import React from 'react';
import PropTypes from 'prop-types';

/**
 * Empty state for empty data in tables.
 *
 * @version 1.0.0
 * @author [Nagy Gergely](https://github.com/gergely-nagy)
 */
const DataEmptyState = (props) => {
  return (
    <div className="empty-state animated fadeIn">
      {props.hasImage && (
        <img
          src={process.env.PUBLIC_URL + '/img/no-data.png'}
          className="img-fluid"
          alt="img-no-data"
        />
      )}
      <h3>{props.title}</h3>
      <p>{props.subtitle}</p>
    </div>
  );
};

DataEmptyState.propTypes = {
  /** boolean to control the image visibility of the DataEmptyState */
  hasImage: PropTypes.bool,
  /** custom title of the DataEmptyState */
  title: PropTypes.string,
  /** custom subtitle of the DataEmptyState */
  subtitle: PropTypes.string,
};

// Specifies the default values for props:
DataEmptyState.defaultProps = {
  hasImage: false,
  title: 'Nincsenek rekordok!',
  subtitle: 'Töltsd fel adataidat most'
};

export default DataEmptyState;
