import Lib from '../../../commons/lib';

export default class Service {
  static cikkUrl = window.location.origin + '/api/cikk/';
  static keszletUrl = window.location.origin + '/api/keszletnyilv/';
  static raktarUrl = window.location.origin + '/api/raktar/';

// CIKK START


static queryCikk(text, receptura, raktar, fnDone) {
  Lib.MicroServices.requestService([{
    method: 'GET',
    url: Service.cikkUrl + 'cikk/query',
    args: { requestType: 'cikk-query' },
    params: { param: text, receptura, raktar }
  }], fnDone);
}

// CIKK END

// KÉSZLET START


static getRaktarList(cikkek, raktarak, fnDone) {
  Lib.MicroServices.requestService([{
    method: 'GET',
    url: Service.keszletUrl + 'keszletinfo/list',
    args: { requestType: 'keszletinfo-list' },
    params: { cikkek: cikkek , raktarak: raktarak, keszletinfoSzures: true }
  }], fnDone);
}

static getFoglalasok(cikkid, raktarid, fnDone) {
  Lib.MicroServices.requestService([{
    method: 'GET',
    url: Service.keszletUrl + 'keszletinfo/foglalasok',
    args: { requestType: 'keszletinfo-foglalasok' },
    params: { cikk: cikkid , raktar: raktarid}
  }], fnDone);
}

// KÉSZLET END

// RAKTÁR START

static queryRaktar(text, fnDone) {
  Lib.MicroServices.requestService([{
    method: 'GET',
    url: Service.raktarUrl + 'raktar/searchbytext',
    args: { requestType: 'raktar-searchbytext' },
    params: { text: text }
  }], fnDone);
}

// RAKTÁR END

}
