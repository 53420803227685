import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DataEmptyState from '../../commons/dataemptystate';
import { PaginationTotal } from '../../commons/controls';
import Services from './services';
import Loader from '../../commons/loader';
import NetworkEmptyState from '../../commons/networkemptystate';
import QueryEmptyState from '../../commons/queryempty';

class PenztartetelAnalitikaTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ['QUERY', 'LOAD', 'DATA', 'NETW', 'TABL']
      currentStatusBaseTable: 'QUERY',
      baseJson: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    console.log('sexyprop', this.props);
    console.log('notoosexyprop', nextProps);
    // console.log('prevState', prevState);
    if (this.props.tableData !== nextProps.tableData) {
      this.setState({
        parentId: nextProps.tableData,
        currentStatusBaseTable: 'LOAD'
      });
      Services.listPenztarTetelTable(nextProps.tableData, (err, res) => {
        if (!err) {
          let tableObj = JSON.parse(JSON.stringify(res[0]));
          this.setState({ baseJson: tableObj, currentStatusBaseTable: 'TABL' });
        } else {
          this.setState({
            currentStatusBaseTable: 'QUERY'
          });
        }
      });
    } 

    if (this.props.tableStatus !== nextProps.tableStatus) {
      if (nextProps.tableStatus && nextProps.tableStatus.tetel) {
        if (nextProps.tableStatus.tetel === 'RELOAD') {
          this.setState({
            currentStatusBaseTable: 'QUERY',
            baseJson: [],
          });
          this.props.tableStatusChange({
            fej: 'LOADED',
            tranzakcio: 'LOADED',
            tetel: 'LOADED'
          });
        }
      }
    }
  }

  updateBaseTable = () => {
    this.setState({
      currentStatusBaseTable: 'LOAD'
    });
    Services.listAnalitika(this.state.parentId, (err, res) => {
      if (!err) {
        this.setState({ baseJson: res[0], currentStatusBaseTable: 'TABL' });
      } else {
        this.setState({
          currentStatusBaseTable: 'QUERY'
        });
      }
    });
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  renderBaseTable = () => {
    const pagination = true;

    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };
    return (
      <div>
        { this.state.baseJson.length ? (
          <BootstrapTable
            data={this.state.baseJson}
            pagination={pagination}
            options={options}
            className="margin-top-30"
          >
            <TableHeaderColumn
              dataField="b"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            >
              Bizonylatszám
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="p"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            >
              Partner
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="o"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            >
              Összeg
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="c"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              width="120"
            >
              Pénznem
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="a"
              tdStyle={{ whiteSpace: 'normal' }}
            >
              Bef. azonosító
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="i"
              tdStyle={{ whiteSpace: 'normal' }}
              isKey
              hidden
            >
              Műveletek
            </TableHeaderColumn>
          </BootstrapTable>) : (
            <div className="margin-top-35">
              <DataEmptyState />
            </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <div>
        {{
          QUERY: (<QueryEmptyState
            hasImage
            subtitle="Kérem válasszon felettes rekordot!"
          />),
          LOAD: (<Loader />),
          DATA: (<DataEmptyState
            hasImage
            subtitle="Próbálj más előszűrést vagy vigyél fel új adatokat!"
          />),
          NETW: (<NetworkEmptyState
            hasImage
            submitClick={() => this.updateTable()}
          />),
          TABL: (this.renderBaseTable())
        }[this.state.currentStatusBaseTable]}
      </div>
    );
  }
}

PenztartetelAnalitikaTable.propTypes = {
  tableData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
};

export default PenztartetelAnalitikaTable;
