import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import { PaginationTotal } from '../../../../../commons/controls';
import { browserHistory } from 'react-router';
import SelectAsync from 'react-select/lib/Async';
import Select from 'react-select';
import CikkSelect from '../CikkSelect.jsx';
import _ from 'lodash';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { DatetimePicker, DatePicker } from '../../../../../commons/controls.js';
import { ProgressButton } from '../../../../../commons/ProgressButton';
import Service from '../services.js';
import moment from 'moment';
import Lib from '../../../../../commons/lib';
import NumberFormatter from '../../../../../commons/NumberFormatter';


const STYLE_ROW = {marginTop: '15px'};
class BevetelezesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasRendelesModule: false,

      modalTorles: false,
      modalCikk: false,
      modalAccept: false,
      modalPartnerFelvitel: false,
      modalRendelesCikk: false,
      mentesLoadingButton: false,
      szallitoError: false,

      rendelesCikkSelected: [],

      formRaktar: null,
      formPenznem: null,
      formFizmod: null,
      formPartner: null,
      // checkForRendelesModule()-ból kivenni az alapérték állítást, ha más értéket is felvehet a mező/nem szükséges a default
      formFunkcioNev: null,
      formFunkcio: null,
      formSzallitolevelszam: '',
      formSzamlaszam: '',
      formFizetesihatarido: null,
      formMozgasDatum: new Date(), 

      telepulesMezo: [],
      editobj: {},
      bevetel: {},
      cikkek: [],
      penznem: {},
    };
    this.raktarAzonosito = 'BEVETEL';
  }

  componentWillMount(){
    this.checkForRendelesModule();
    let params = new URL(window.location.href).searchParams;
    let id = params.get('id');
    if (this.props.route.modositas === 'true') {
      if (id){
        this.getData(id);
      } 
    }else{
      Service.listAktivPenznem((err, res) => {
        if (!err) {
          let defaultPenznem = [];
          res[0].filter(x => x.defaultcurrency).forEach((item) => {
            defaultPenznem = item;
          });
          this.setState({
            formPenznem: defaultPenznem,
            bevetel: {}
          });
        }
      });
    }
  }

  modosit = () => {
    browserHistory.push("".concat('/keszlet/keszletkezeles/bevetelezes/felvetel'));
  }

  handleBackClick = () => {
    browserHistory.push("".concat('/keszlet/keszletkezeles/'));
  }

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  }

  toggleTorles = () => {
    this.setState({ modalTorles: !this.state.modalTorles });
  }

  toggleAccept = () => {
    this.setState({ modalAccept: !this.state.modalAccept });
  }

  toggleCikk = () => {
    this.setState({ modalCikk: !this.state.modalCikk });
  }

  togglePartnerFelvitel = () => {
    this.setState({ modalPartnerFelvitel: !this.state.modalPartnerFelvitel, telepulesMezoInaktiv: true });
  }

  toggleRendelesCikk = () => {
    this.setState({ modalRendelesCikk: !this.state.modalRendelesCikk, rendelesCikkSelected: [] });
  }

  getData = (id) => {
    Service.getBevetelezes(id, (err, res) => {
      if (!err) {
        let bevetel = res[0];
        this.setState({ 
          bevetel: bevetel, 
          cikkek: res[0].cikkek, 
          formPartner: res[0].partner,
          formPenznem: res[0].penznem,
          formRaktar: res[0].raktar,
          formFizmod: res[0].fizmod,
          formMozgasTipus: res[0].mozgasTipus,
          formSzallitolevelszam: res[0].szallitolevelszam,
          formSzamlaszam: res[0].szamlaszam,
          formFizetesihatarido: res[0].fizetesihatarido,
          formMozgasDatum: res[0].mozgasDatum, 
        }, () => {
          if (this.state.hasRendelesModule && res[0].partner) {
            Service.listRendelesBevetelezes(res[0].partner.id, res[0].funkcioId, (err2, res2) => {
              if (!err2) {
                this.setState({ funkcioOptions: res2[0], formFunkcio: res2[0].find(r => r.id == res[0].funkcioId) });
              }
            });
          }
        });
      }
    });
  }

  checkForRendelesModule = () => {
    Service.checkForRendelesModule((err, res) => {
      if (!err) {
        this.setState({ hasRendelesModule: res[0] == true });
        if (res[0]) {
          this.setState({ formFunkcioNev: 'SZALLITOI_RENDELES' });
        }
      }
    });
  }

  handleRendelesCikkSubmit = () => {
    const { formFunkcio, rendelesCikkSelected } = this.state;
    const rendelescikkek = formFunkcio.rendelescikkbeans.filter(rc => rendelesCikkSelected.includes(rc.id));
    let cikkekNew = [];
    rendelescikkek.forEach((rc) => {
      cikkekNew.push({
        cikk: rc.cikk,
        mennyiseg: rc.mennyiseg,
        cikkid: rc.cikk.id,
        funkciotetelId: rc.id,
        funkciotetel: 'SZALLITOI_RENDELES_CIKK',
        egysegarnetto: rc.nettoEgysegar,
        egysegarbrutto: rc.bruttoEgysegar,
        beszerzesnetto: rc.nettoBeszerzesiar,
        beszerzesbrutto: rc.bruttoBeszerzesiar,
      });
    });
    let { cikkek } = this.state;
    cikkek.push(...cikkekNew);
    this.setState({ cikkek, rendelesCikkSelected: [], modalRendelesCikk: false });
  }


  save = (event, bevetelObj) => {
    let submitType = document.activeElement.getAttribute('id');
    const { cikkek } = this.state;

    if(cikkek && cikkek.length === 0 && submitType !== 'save-and-close'){
      this.props.globals.addWarning('Cikk kiválasztása kötelező!');
      return;
    }

    let submitObj = {};
    submitObj.partnerid = this.state.formPartner && this.state.formPartner.id;
    submitObj.penznemid = this.state.formPenznem && this.state.formPenznem.id;
    submitObj.raktarid = this.state.formRaktar && this.state.formRaktar.id;
    submitObj.fizmodId = this.state.formFizmod && this.state.formFizmod.id;
    submitObj.mozgasDatum =  this.state.formMozgasDatum && moment(this.state.formMozgasDatum).format("YYYY-MM-DDTHH:mm:ss.SSSZZ");
    submitObj.mozgasTipus = this.state.formMozgasTipus && this.state.formMozgasTipus.id;
    submitObj.szallitolevelszam = this.state.formSzallitolevelszam;
    submitObj.szamlaszam = this.state.formSzamlaszam;
    submitObj.fizetesihatarido = this.state.formFizetesihatarido && moment(this.state.formFizetesihatarido).format("YYYY-MM-DD");
    if (this.state.hasRendelesModule && this.state.formFunkcio) {
      submitObj.funkcio = this.state.formFunkcioNev;
      submitObj.funkcioId = this.state.formFunkcio && this.state.formFunkcio.id;
    }
    this.setState({ szallitoError: !this.state.formPartner}); 

    if (submitType === 'save-and-close' && !this.state.modalAccept) {
      this._currentAcceptObj = bevetelObj;
      this.toggleAccept();
      return;
    } else if (submitType === 'save-and-close' && this.state.modalAccept) {
      this.toggleAccept();
    }

    if (this.state.bevetel && this.state.bevetel.id) {
      this.editKeszlet(submitObj); 
    } else {
      this.addKeszlet(submitObj); 
    }
  }

  addKeszlet(bevetelObj) {
    let submitType = document.activeElement.getAttribute('id');
    bevetelObj.cikkek = this.state.cikkek.map((c) => { return { ...c, funkciotetel: typeof c.funkciotetel === 'string' ? c.funkciotetel : c.funkciotetel && c.funkciotetel.name }; });

    if (submitType === 'save-and-close') {
      this.setState({mentesLoadingButton: true}, ()=> {
        Service.acceptAddBevetelezes(bevetelObj, (err, res) => {
          if (!err) {
            this.props.globals.addSuccess('Készletnövekedés tétel véglegesítve!');
            browserHistory.push("".concat('/keszlet/keszletkezeles?page=1'));
          }
          this.setState({mentesLoadingButton: false});
        });
      });
    } else {
      this.setState({mentesLoadingButton: true}, ()=> {
        Service.addBevetelezes(bevetelObj, (err, res) => {
          if (!err) {
            this.props.globals.addSuccess('Készletnövekedés tétel hozzáadva!');
            browserHistory.push("".concat('/keszlet/keszletkezeles?page=1'));
          }
            this.setState({mentesLoadingButton: false});
        });
      });
    }
  }

  editKeszlet(bevetelObj) {
    let submitType = document.activeElement.getAttribute('id');
    bevetelObj.cikkek = this.state.cikkek.map((c) => { return { ...c, funkciotetel: typeof c.funkciotetel === 'string' ? c.funkciotetel : c.funkciotetel && c.funkciotetel.name }; });


    if (submitType === 'save-and-close') {
      this.setState({mentesLoadingButton: true}, ()=> {
        Service.acceptEditBevetelezes(bevetelObj, this.state.bevetel.id, (err, res) => {
          if (!err) {
            this.props.globals.addSuccess('Készletnövekedés tétel módosítva és véglegesítve!');
            browserHistory.push("".concat('/keszlet/keszletkezeles?page=1'));  
          }
          this.setState({mentesLoadingButton: false});
        });
      });
    } else {
      this.setState({mentesLoadingButton: true}, ()=> {
        Service.editBevetelezes(bevetelObj, this.state.bevetel.id, (err, res) => {
          if (!err) {
            this.props.globals.addSuccess('Készletnövekedés tétel módosítva!');
            browserHistory.push("".concat('/keszlet/keszletkezeles?page=1'));
          }
          this.setState({mentesLoadingButton: false});
        });
      });
    }
  }

  loadFizmodOptions = (input, callback) => {
    Service.listAktivFizetesimod((err, res) => {
      if (!err) {
        let options = [];
        res[0].filter(k => k.nev.toLowerCase().includes(input.toLowerCase())).forEach((item) => {
          options.push(item);
        });
        callback(options);
      }
    });
  }


  loadRaktarOptions = (input, callback) => {
    Service.listBizonylatRaktar(this.raktarAzonosito, null, (err, res) => {
      if (!err) {
        let options = [];
        res[0].filter(k => k.nev.toLowerCase().includes(input.toLowerCase())).forEach((item) => {
          options.push(item);
        });
        callback(options);
      }
    });
  }

  loadPenznemOptions = (input, callback) => {
    Service.listAktivPenznem((err, res) => {
      if (!err) {
        let options = [];
        res[0].filter(k => k.defaultcurrency && k.nev.toLowerCase().includes(input.toLowerCase())).forEach((item) => {
          options.push(item);
        });
        callback(options);
      }
    });
  }

  loadPartnerOptions = (input, callback) => {
    Service.listHelyszin(input, (err, res) => {
      if (!err) {
        let options = [];
        res[0].filter(k => k.nev.toLowerCase().includes(input.toLowerCase())).forEach((item) => {
          options.push(item);
        });
        callback(options);
      }
    });
  }

  loadMozgasTipusOptions = (input, callback) => {
    let leltarRole = Lib.KeyCloak.hasRoleSpec(this.props.route.globals.keycloak, ['KESZLETNYILV_LELTAR_KORREKCIO']);

    Service.listMozgastipusBevetelezes((err, res) => {
      if (!err) {
        let options = [];
        res[0]
          .filter(m => m.name.toLowerCase().includes(input.toLowerCase()))
          .filter(m => !m.name.includes("LELTAR") || (m.name.includes("LELTAR") && leltarRole))
          .forEach((item) => {
            options.push(item);
          });
        callback(options);
      }
    });
  }

  iconCikkFormatter = (cell, row) => {
    return (
      <div>
        <Button className="icon-pencil table-action-icon" onClick={() => this.handleEditCikkClick(cell, row)} />
        <Button className="icon-trash table-action-icon" onClick={() => this.handleDeleteCikkClick(row)} />
      </div>
    );
  }

  handleNewCikkClick = () => {
    this.setState({ editmode: false, editobj: undefined}, () => {
      this.toggleCikk();
    });
  }

  handleEditCikkClick = (id, cikkobj) => {
    this.setState({ editmode: true,  editobj: cikkobj }, () => this.toggleCikk());

  }

  handleDeleteCikkClick = (cikkobj) => {
    this._currentDeleteObj = cikkobj;
    this.toggleTorles();
  }

  loadPartnerOptions = (text, callback) => {
    let newArray = [];
    if (text && text.length === 0) {
      callback(newArray);
      return;
    } 
    Service.queryPartner('KESZLET_SZALLITO_FORM', text, (err, res) => {
      if (!err) {
        res[0].forEach((item) => {
          newArray.push(item);
        });
        callback(newArray);
      }
    });
  }

  handlePartnerChange = (v) => {
    this.setState({ formPartner: v, formFunkcio: null });
    if (v && this.state.hasRendelesModule) {
      Service.listRendelesBevetelezes(v.id, null, (err, res) => {
        if (!err) {
          this.setState({ funkcioOptions: res[0] });
        }
      });
    } else {
      this.setState({ formFunkcio: null });
    }
  }

  handleInputChange = (e) => {
    const { target } = e;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [name]: value
    });
  }

  addPartner = (event, partnerObj) => {
    partnerObj.tipus = 'MAGANSZEMELY';
    partnerObj.szallitovevo = 'SZALLITO';
    partnerObj.statusz = 'AKTIV';
    partnerObj.szallcimeltero = false;
    Service.addPartner(partnerObj, (err, res) => {
      if (!err) {
        this.props.globals.addSuccess('Partner hozzáadva!');
        let bevetel = Object.assign({}, this.state.bevetel);
        bevetel['partner'] = { value: res[0].id, label: res[0].nev };
        this.setState({ bevetel: bevetel });
        this.togglePartnerFelvitel();
      }
    });
  }

  /**
  * Irányítószám input mező kezelése.
  * @param {SytheticEvent} event
  */
  handleIrszamChange = (event, irszam) => {
    this.setState({ telepulesMezoInaktiv: true });
    if (irszam.length === 4) {
      Service.getTelepulesByIrszam(irszam, (err, res) => {
        if (!err) {
          if (res[0].length > 0) {
            this.setState({ telepulesMezoInaktiv: false, telepulesMezo: res[0], });
          } else {
            this.setState({ telepulesMezo: [], });
            this.props.globals.addWarning('Ehhez az irányítószámhoz nem tartozik település!');
          }
        }
      });
    } else {
      this.setState({ telepulesMezo: [] });
    }
  }

  addRendelesFunkcioTetelId = (cikkObj) => {
    if (this.state.hasRendelesModule && this.state.formFunkcio) {
      let array = [...this.state.formFunkcio.rendelescikkbeans];
      array.sort((a, b) => {
        if (a.hatarido < b.hatarido) return -1;
        if (b.hatarido < a.hatarido) return 1;
        return 0;
      });
      let obj = array.find(rc => rc.cikkid == cikkObj.cikkid);
      cikkObj.funkciotetelId = obj ? obj.id : null;
      cikkObj.funkciotetel = 'SZALLITOI_RENDELES_CIKK';
    }
  }

  addCikk = (event, cikkObj) => {
    this.addRendelesFunkcioTetelId(cikkObj);
    this.setState({ cikkek: [...this.state.cikkek, cikkObj], editobj: cikkObj }, () => this.toggleCikk());
  }

  editCikk = (event, cikkObj) => {
    // Funkciotétel átmásolása, ha nem változott a cikk, mert egyébként elveszne, mivel a Form nem tartalmazza a mezőt
    if (this.state.editobj && this.state.editobj.cikkid == cikkObj.cikkid) {
      cikkObj.funkciotetelId = this.state.editobj.funkciotetelId;
      cikkObj.funkciotetel = this.state.editobj.funkciotetel;
    } else {
      this.addRendelesFunkcioTetelId(cikkObj);
    }
    this.state.cikkek[this.state.cikkek.indexOf(this.state.editobj)] = cikkObj;
    this.setState({ cikkek: this.state.cikkek, editmode: false, editobj: cikkObj }, () => this.toggleCikk());
  }

  deleteCikk = (cikkobj) => {
    this.setState({ cikkek: this.state.cikkek.filter(cikk => cikk !== cikkobj) }, () => this.toggleTorles());
  }

  checkDate = (date) => {
    return date ? new Date(date) : new Date();
  }

  handleDateInputChange = (e, v, name) => {
    const value = v === '' ? null : v;
    this.setState({
      [name]: value
    });
  }

  mozgasTipusRequired = (mozgasTipus) => {
    return this.state.formMozgasTipus && this.state.formMozgasTipus.id === mozgasTipus;
  }

  loadTelepulesOptions = () => {
    return this.state.telepulesMezo.map(e => <option key={e.telepulesid} value={e.telepulesid}>{e.telepulesnev}</option>);
  }

  isFullForm = () => {
    return this.state.bevetel && this.state.formMozgasTipus && this.state.formMozgasTipus.name.includes('Bevételezés') ; 
  }


  renderPartnerInputFields = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };
    return (
      <div className="row">
        <div className="col-md-12">
          <div><h5 style={titleStyle}>Alapadatok:</h5></div>
        </div>
        <div className="col-md-4">
          <AvGroup>
            <Label for="titulus">Titulus: </Label>
            <AvInput name="titulus" id="titulus" />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4">
          <AvGroup>
            <Label for="vezeteknev">Vezetéknév: *</Label>
            <AvInput name="vezeteknev" id="vezeteknev" required />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4">
          <AvGroup>
            <Label for="keresztnev">Keresztnév: *</Label>
            <AvInput name="keresztnev" id="keresztnev" required />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-12">
          <div><h5 style={titleStyle}>Elérhetőségek:</h5></div>
        </div>
        <div className="col-md-4">
          <AvGroup>
            <Label for="orszag">Ország: *</Label>
            <AvInput name="orszag" id="orszag" value="Magyarország" type="text" maxLength="50" disabled required />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4">
          <AvGroup>
            <Label for="iranyitoszam">Irányítószám: *</Label>
            <AvInput name="iranyitoszam" id="iranyitoszam" onChange={this.handleIrszamChange} minLength="4" maxLength="4" pattern="^[0-9]*$" required />
            <AvFeedback>Kötelező 4 számjegyű mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-4">
          <AvField type="select" name="telepulesid" id="telepulesid" label="Település: *" disabled={this.state.telepulesMezoInaktiv} errorMessage="Kötelező mező!" required>
            <option value="" disabled >Válassz települést...</option>
            {this.loadTelepulesOptions()}
          </AvField>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="kozterulet">Közterület neve: *</Label>
            <AvInput name="kozterulet" id="kozterulet" required />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="kozteruletjelleg">Közterület jellege: *</Label>
            <AvInput name="kozteruletjelleg" id="kozteruletjelleg" required />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="hazszam">Házszám: *</Label>
            <AvInput name="hazszam" id="hazszam" required />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-3">
          <AvGroup>
            <Label for="telefon">Telefon: *</Label>
            <AvInput name="telefon" id="telefon" required />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
      </div>
    );
  }

  renderForm = () => {
    return (
    <div style={{marginBottom: '15px'}}>
      <div className="row" style={STYLE_ROW}>
        <div className="col-md-4">
          <Label for="formRaktar">Raktár:</Label>
          <SelectAsync
            styles={{
              control: base => ({
                ...base,
                '&:focus': { borderColor: 'red' }, // border style on hover
                border: '1px solid lightgray', // default border color
                boxShadow: 'none', // no box-shadow
                borderRadius: '0'
              }),
            }}
            name="formRaktar"
            id="formRaktar"
            placeholder="Kezdjen el gépelni..."
            noOptionsMessage={() => 'Nincs keresési eredmény'}
            onChange={v => this.setState({formRaktar: v})}
            loadOptions={_.debounce(this.loadRaktarOptions, 1000)}
            getOptionLabel={option => option.nev}
            getOptionValue={option => option.id}
            value={this.state.formRaktar}
            defaultOptions
            isClearable
          />
        </div>
        <div className="col-md-4">
          <Label for="formMozgasDatum">Mozgás dátuma:</Label>
          <DatetimePicker 
            name="formMozgasDatum"
            value={this.checkDate(this.state.formMozgasDatum)}
            onChange={(e, v) => this.handleDateInputChange(e, v, 'formMozgasDatum')}
            max={new Date()}
            //required={this.mozgasTipusRequired('SZAM')}
          />
        </div>
      </div>
      {this.isFullForm() &&
      <React.Fragment>
        <div style={STYLE_ROW}>
          <Label for="formPartner">Szállító:</Label>
          <div className="row">
            <div className="col-md-4 form-group">
                <SelectAsync
                  styles={{
                    control: base => ({
                      ...base,
                      '&:focus': { borderColor: 'red' }, // border style on hover
                      border: '1px solid lightgray', // default border color
                      boxShadow: 'none', // no box-shadow
                      borderRadius: '0'
                    }),
                  }}
                  name="formPartner"
                  id="formPartner"
                  placeholder="Kezdjen el gépelni..."
                  noOptionsMessage={() => 'Nincs keresési eredmény'}
                  onChange={this.handlePartnerChange}
                  loadOptions={_.debounce(this.loadPartnerOptions, 1000)}
                  getOptionLabel={option => option.nev}
                  getOptionValue={option => option.id}
                  value={this.state.formPartner}
                  defaultOptions
                  isClearable
                />
              </div>
              {this.state.hasRendelesModule
                && (
                  <>
                    <div className="col-md-4">
                      <Input
                        type="select"
                        name="formFunkcioNev"
                        value={this.state.formFunkcioNev}
                        onChange={this.handleInputChange}
                        disabled={!this.state.formPartner}
                      >
                        <option value="SZALLITOI_RENDELES">Szállítói rendelés</option>
                      </Input>
                    </div>
                    <div className="col-md-4">
                      <Select
                        styles={{
                          control: base => ({
                            ...base,
                            '&:focus': { borderColor: 'red' }, // border style on hover
                            border: '1px solid lightgray', // default border color
                            boxShadow: 'none', // no box-shadow
                            borderRadius: '0'
                          }),
                        }}
                        name="funkcioId"
                        id="funkcioId"
                        placeholder="Kezdjen el gépelni a kereséshez..."
                        noOptionsMessage={() => 'Nincs keresési eredmény'}
                        searchPromptText="Kezdjen el gépelni a kereséshez..."
                        loadingMessage={() => 'Keresés...'}
                        onChange={v => this.setState({ formFunkcio: v })}
                        options={this.state.funkcioOptions}
                        value={this.state.formFunkcio}
                        getOptionValue={option => option.id}
                        getOptionLabel={option => option && option.bizonylatBean && option.bizonylatBean.bizonylatszam}
                        isDisabled={!this.state.formPartner || this.state.cikkek.length !== 0}
                        isClearable
                      />
                    </div>
                  </>
                )
              }
          </div>
        </div>
        {this.state.hasRendelesModule
          && (
            <div className="row">
              <div className="col-md-2">
                <Button
                  type="button"
                  color="primary"
                  hidden={!this.state.formFunkcio || this.state.formFunkcioNev !== 'SZALLITOI_RENDELES'}
                  onClick={this.toggleRendelesCikk}
                >
                  Rendelés aktív tételei
                </Button>
              </div>
            </div>
          )
        }
        <div className="row" style={STYLE_ROW}>
          <div className="col-md-8">
            <AvGroup>
              <Label for="formSzallitolevelszam">Szállítólevél száma:</Label>
              <AvInput 
                name="formSzallitolevelszam" 
                id="formSzallitolevelszam" 
                value={this.state.formSzallitolevelszam} 
                onChange={this.handleInputChange}
                />
            </AvGroup>
          </div>
        </div>
        <div className="row" style={STYLE_ROW}>
          <div className="col-md-8">
            <AvGroup>
              <Label for="formSzamlaszam">Számla száma:</Label>
              <AvInput 
                name="formSzamlaszam" 
                id="formSzamlaszam"
                value={this.state.formSzamlaszam} 
                onChange={this.handleInputChange}
                />
            </AvGroup>
          </div>
        </div>
      </React.Fragment>}
      <div className="row" style={STYLE_ROW}>
        <div className="col-md-4">
          <Label for="formPenznem">Pénznem:</Label>
          <SelectAsync
            styles={{
              control: base => ({
                ...base,
                '&:focus': { borderColor: 'red' }, // border style on hover
                border: '1px solid lightgray', // default border color
                boxShadow: 'none', // no box-shadow
                borderRadius: '0'
              }),
            }}
            name="formPenznem"
            id="formPenznem"
            placeholder="Kezdjen el gépelni..."
            noOptionsMessage={() => 'Nincs keresési eredmény'}
            onChange={v => this.setState({formPenznem: v})}
            loadOptions={_.debounce(this.loadPenznemOptions, 1000)}
            getOptionLabel={option => option.nev}
            getOptionValue={option => option.id}
            value={this.state.formPenznem}
            defaultOptions
            isClearable
          />
        </div>
      {this.isFullForm() &&
        <React.Fragment>
          <div className="col-md-4">
            <Label for="formFizmod">Fizetési mód:</Label>
            <SelectAsync
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="formFizmod"
              id="formFizmod"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              onChange={v => this.setState({formFizmod: v})}
              loadOptions={_.debounce(this.loadFizmodOptions, 1000)}
              getOptionLabel={option => option.nev}
              getOptionValue={option => option.id}
              value={this.state.formFizmod}
              defaultOptions
              isClearable
            />
        </div>
        <div className="col-md-4">
          <Label for="formFizetesihatarido">Fizetési határidő dátuma:</Label>
          <DatePicker 
            name="formFizetesihatarido"
            value={this.state.formFizetesihatarido ? new Date(this.state.formFizetesihatarido) : null}
            onChange={(e, v) => this.handleDateInputChange(e, v, 'formFizetesihatarido')}
            //required={this.mozgasTipusRequired('SZAM')}
          />
        </div>
      </React.Fragment>}
    </div>
  </div>);
}

renderButtons = () => {
  return(
  <div className="top-15-margin">
    <ProgressButton color="primary" id="save" loading={this.state.mentesLoadingButton}> Mentés </ProgressButton>{' '}
    <ProgressButton color="primary" id="save-and-close" loading={this.state.mentesLoadingButton}>Mentés és véglegesítés</ProgressButton>{' '}
    <Button type="button" color="secondary" onClick={this.handleBackClick}>Mégse</Button>
  </div>
  );
}

renderCikkTable = () => {
  const options = {
    sizePerPage: 5,
    showTotal: true,
    paginationTotalRenderer: PaginationTotal,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '25', value: 25 }
    ],
  };

  const columns = [
    {
      dataField: 'cikk.cikkszam',
      text: 'Cikkszám',
      filter: textFilter({
        delay: 400, // default is 500ms
        placeholder: 'Keresés...',
      }),
      style: {
        whiteSpace: 'pre-line',
        textAlign: 'right' 
      },
      sort: true
    },
    {
      dataField: 'cikk.megnevezes',
      text: 'Megnevezés',
      filter: textFilter({
        delay: 400, // default is 500ms
        placeholder: 'Keresés...',
      }),
      style: {
        whiteSpace: 'pre-line',
        textAlign: 'right' 
      },
      sort: true
    },
    {
      dataField: 'mennyiseg',
      text: 'Mennyiség',
      filter: textFilter({
        delay: 400, // default is 500ms
        placeholder: 'Keresés...',
      }),
      style: NumberFormatter.styleTextAlignWhiteSpace,
      formatter: (cell, row) => NumberFormatter.formatterSpaceOsszeg(cell) + (row && row.cikk ? ' ' + row.cikk.mennyisegiEgyseg : ''),
      sort: true
    },
    {
      dataField: 'cikk.aktualisAfaErtek',
      text: 'ÁFA%',
      filter: textFilter({
        delay: 400, // default is 500ms
        placeholder: 'Keresés...',
      }),
      style: NumberFormatter.styleTextAlignWhiteSpace,
      formatter: NumberFormatter.formatterSpaceOsszeg,
      sort: true
    },
    {
      dataField: 'egysegarnetto',
      text: 'Nettó egységár',
      filter: textFilter({
        delay: 400, // default is 500ms
        placeholder: 'Keresés...',
      }),
      style: NumberFormatter.styleTextAlignWhiteSpace,
      formatter: (cell, row) => NumberFormatter.formatterSpaceOsszegPenznem(cell, row),
      sort: true
    },
    {
      dataField: 'egysegarbrutto',
      text: 'Bruttó egységár',
      filter: textFilter({
        delay: 400, // default is 500ms
        placeholder: 'Keresés...',
      }),
      style: NumberFormatter.styleTextAlignWhiteSpace,
      formatter: (cell, row) => NumberFormatter.formatterSpaceOsszegPenznem(cell, row),
      sort: true
    },
    {
      dataField: 'beszerzesnetto',
      text: 'Nettó beszerzési ár',
      filter: textFilter({
        delay: 400, // default is 500ms
        placeholder: 'Keresés...',
      }),
      style: NumberFormatter.styleTextAlignWhiteSpace,
      formatter: (cell, row) => NumberFormatter.formatterSpaceOsszegPenznem(cell, row),
      sort: true
    },
    {
      dataField: 'beszerzesbrutto',
      text: 'Bruttó beszerzési ár',
      filter: textFilter({
        delay: 400, // default is 500ms
        placeholder: 'Keresés...',
      }),
      style: NumberFormatter.styleTextAlignWhiteSpace,
      formatter: (cell, row) => NumberFormatter.formatterSpaceOsszegPenznem(cell, row),
      sort: true
    },
    {
      dataField: 'cikk.id',
      text: 'Műveletek',
      align: 'center',
      headerStyle: { textAlign: 'center' },
      formatter: this.iconCikkFormatter
    }
  ];
  return (
    <React.Fragment>
      <Button className="table-new-button" type="button" color="success" onClick={this.handleNewCikkClick}>Cikk kiválasztása</Button>
      <div className="animated fadeIn">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={this.state.cikkek}
          columns={columns}
          filter={filterFactory()}
          pagination={paginationFactory(options)}
          noDataIndication="Nincs megjelenítendő adat!"
        />
      </div>
    </React.Fragment>
  );
}

renderRendelesCikkTable = () => {
  const handleRowSelect = (row, isSelect) => {
    this.setState((previousState) => {
      let array = previousState.rendelesCikkSelected;
      if (isSelect) {
        array.push(row.id);
      } else {
        array = array.filter(id => id != row.id);
      }
      return { rendelesCikkSelected: array };
    });
  };

  const handleRowSelectAll = (isSelect, rows) => {
    this.setState((previousState) => {
      let array = previousState.rendelesCikkSelected;
      if (isSelect) {
        array = rows.map(rc => rc.id);
      } else {
        array = [];
      }
      return { rendelesCikkSelected: array };
    });
  };

  const options = {
    sizePerPage: 5,
    showTotal: true,
    paginationTotalRenderer: PaginationTotal,
    sizePerPageList: [
      { text: '5', value: 5 },
      { text: '10', value: 10 },
      { text: '25', value: 25 }
    ],
  };

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
    selected: this.state.rendelesCikkSelected,
    onSelect: handleRowSelect,
    onSelectAll: handleRowSelectAll
  };

  const filterCikkek = (list) => {
    if (!list) {
      return [];
    }
    const funkciotetelIdList = this.state.cikkek.map(c => c.funkciotetelId);
    return list.filter(rc => !funkciotetelIdList.includes(rc.id));
  };

  const columns = [
    {
      dataField: 'cikk',
      text: 'Cikk megnevezése (Cikkszám)',
      filter: textFilter({
        delay: 400, // default is 500ms
        placeholder: 'Keresés...',
      }),
      style: {
        whiteSpace: 'pre-line',
        textAlign: 'right'
      },
      sort: true,
      formatter: (cell) => {
        return cell.megnevezes + ' (' + cell.cikkszam + ')';
      }
    },
    {
      dataField: 'mennyiseg',
      text: 'Mennyiség (fennmaradó)',
      filter: textFilter({
        delay: 400, // default is 500ms
        placeholder: 'Keresés...',
      }),
      style: NumberFormatter.styleTextAlignWhiteSpace,
      formatter: (cell, row) => NumberFormatter.formatterSpaceOsszeg(cell) + ' ' + row.cikk.mennyisegiEgyseg,
      sort: true
    },
    {
      dataField: 'nettoEgysegar',
      text: 'Nettó egységár',
      filter: textFilter({
        delay: 400, // default is 500ms
        placeholder: 'Keresés...',
      }),
      style: NumberFormatter.styleTextAlignWhiteSpace,
      formatter: cell => NumberFormatter.formatterSpaceOsszeg(cell),
      sort: true
    },
    {
      dataField: 'hatarido',
      text: 'Szállítási határidő',
      filter: textFilter({
        delay: 400, // default is 500ms
        placeholder: 'Keresés...',
      }),
      filterValue: cell => moment(cell).format('YYYY. MM. DD'),
      formatter: cell => moment(cell).format('YYYY. MM. DD'),
      sort: true
    },
    {
      dataField: 'vevoiBizonylatBean.bizonylatszam',
      text: 'Vevői rendelés bizonylatszám',
      filter: textFilter({
        delay: 400, // default is 500ms
        placeholder: 'Keresés...',
      }),
      sort: true
    }
  ];
  return (
    <BootstrapTable
      bootstrap4
      keyField="id"
      data={filterCikkek(this.state.formFunkcio && this.state.formFunkcio.rendelescikkbeans)}
      columns={columns}
      filter={filterFactory()}
      pagination={paginationFactory(options)}
      selectRow={selectRow}
    />
  );
}

renderModals = () => {
  return(
  <React.Fragment>
    <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className} backdrop="static">
      <ModalHeader >Törlés</ModalHeader>
      <ModalBody>Valóban törölni kívánja a kiválasztott tételt?</ModalBody>
      <ModalFooter>
        <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteCikk(this._currentDeleteObj)}>OK</Button>
        <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
      </ModalFooter>
    </Modal>

    <Modal isOpen={this.state.modalAccept} toggle={this.toggleAccept} className={this.props.className} backdrop="static">
      <ModalHeader >Mentés és véglegesítés</ModalHeader>
      <ModalBody>„Mentés és véglegesítés” után az adott tétel nem módosítható, törölhető, csak stornózható! Valóban elfogadja rögzített adatok mentését?</ModalBody>
      <ModalFooter>
        <Button className="btn-cursor-pointer" color="primary" id="save-and-close" onClick={() => this.save(null, this._currentAcceptObj)}>OK</Button>
        <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleAccept}>Mégsem</Button>
      </ModalFooter>
    </Modal>

    <Modal isOpen={this.state.modalRendelesCikk} toggle={this.toggleRendelesCikk} className={this.props.className} backdrop="static" size="lg">
      <ModalHeader>Cikkek választása</ModalHeader>
      <ModalBody>
        {this.renderRendelesCikkTable()}
      </ModalBody>
      <ModalFooter>
        <Button className="btn-cursor-pointer" type="button" color="primary" onClick={this.handleRendelesCikkSubmit}>Kiválasztás</Button>
        <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleRendelesCikk}>Mégse</Button>
      </ModalFooter>
    </Modal>

    <CikkSelect
      isOpen={this.state.modalCikk}
      toggle={this.toggleCikk}
      add={this.addCikk}
      cikkek={this.state.cikkek}
      editmode={this.state.editmode}
      editobj={this.state.editobj}
      edit={this.editCikk}
      mozgasTipus={this.state.formMozgasTipus}
      penznem={this.state.formPenznem}
      price
      globals={this.props.globals}
    />

    {/* Partner felvitel modal */}
    <Modal isOpen={this.state.modalPartnerFelvitel} toggle={this.togglePartnerFelvitel} className={this.props.className} backdrop="static" size="lg" >
      <AvForm onValidSubmit={this.addPartner}>
        <ModalHeader>Partner felvétel</ModalHeader>
        <ModalBody>{this.renderPartnerInputFields()}</ModalBody>
        <ModalFooter>
          <Button color="primary">Mentés</Button>
          <Button type="button" color="secondary" onClick={this.togglePartnerFelvitel}>Vissza</Button>
        </ModalFooter>
      </AvForm>
    </Modal>
  </React.Fragment>
  );
}

render() {

  return (
    <div>
      <AvForm onValidSubmit={this.save}>
        <div className="row" style={STYLE_ROW}>
          <div className="col-md-4">
            <Label for="formMozgastipus">Mozgástípus:</Label>
            <SelectAsync
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="formMozgastipus"
              id="formMozgastipus"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              onChange={v => this.setState({formMozgasTipus: v })}
              loadOptions={_.debounce(this.loadMozgasTipusOptions, 1000)}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              value={this.state.formMozgasTipus}
              isDisabled={!!this.state.bevetel.id}
              defaultOptions
              isClearable
            />
          </div>
        </div>
          {this.state.formMozgasTipus && this.renderForm()}
          {this.state.formMozgasTipus && this.renderCikkTable()}
          {this.renderButtons()}
        </AvForm>
        {this.renderModals()}
      </div>
    );
  }
}

export default BevetelezesForm;
