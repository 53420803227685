import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import classnames from 'classnames';
import SzamlaLista from './SzamlaLista';
import SzamlaElokeszitesLista from './SzamlaElokeszitesLista';
import SzamlaKuldesLista from './SzamlaKuldesLista';
import FizetesiFelszolitoLista from './FizetesiFelszolitoLista';
import Profil from './Profil';
import YouTube from '../../commons/youtube';
import Lib from '../../commons/lib';

const videoData = [
  { text: 'Számla előkészítés', id: 'FWGIT32Y3PQ' },
  { text: 'Számla küldés', id: '5ugllRivDZU' },
  { text: 'Fizetési felszólító', id: 'jjH6DZf22kw' }
];

class SzamlazasMenuBase extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: undefined,
      // TYPE:
      //    SZAMLA_INFO,
      //    SZAMLA_ELO,
      //    SZAMLA_KULD,
      //    SZAMLA_PROFIL
      //    FIZ_FELSZ
      type: 'SZAMLA_INFO'
    };
  }

  componentDidMount() {
    this.initTab();
  }

  initTab = () => {
    let activeTab = '1';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOGSZLA_FIZFELSZ'], true)) activeTab = '5';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOGSZLA_SZAMLAPROFIL'], true)) activeTab = '4';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOGSZLA_SZAMLAKULDES'], true)) activeTab = '3';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOGSZLA_SZAMLAKULDES'], true)) activeTab = '2';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOGSZLA_SZAMLAKEZELES'], true)) activeTab = '1';
    this.setState({ activeTab: activeTab });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      switch (tab) {
        case '1':
          this.setState({ type: 'SZAMLA_INFO' });
          break;
        case '2':
          this.setState({ type: 'SZAMLA_ELO' });
          break;
        case '3':
          this.setState({ type: 'SZAMLA_KULD' });
          break;
        case '4':
          this.setState({ type: 'SZAMLA_PROFIL' });
          break;
        case '5':
          this.setState({ type: 'FIZ_FELSZ' });
          break;
        default:
          this.setState({ type: 'FIZ_FELSZ' });
      }
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    let cardStyle = {
      padding: 10
    };

    return (
      <div>
        <div className="animated fadeIn">
          <div className="card">
            <div className="card-header">
              Számla előkészítés
              <YouTube data={videoData} />
            </div>
            <div className="row" style={cardStyle}>
              <div className="col-md-12">
                <Nav tabs>
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOGSZLA_SZAMLAKEZELES'], true) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => { this.toggle('1'); }}
                      >
                        Számlák
                      </NavLink>
                    </NavItem>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOGSZLA_SZAMLAKULDES'], true) && (
                    <React.Fragment>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({ active: this.state.activeTab === '2' })}
                          onClick={() => { this.toggle('2'); }}
                        >
                          Számla előkészítés
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({ active: this.state.activeTab === '3' })}
                          onClick={() => { this.toggle('3'); }}
                        >
                          Számla küldés
                        </NavLink>
                      </NavItem>
                    </React.Fragment>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOGSZLA_SZAMLAPROFIL'], true) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '4' })}
                        onClick={() => { this.toggle('4'); }}
                      >
                        Profil
                      </NavLink>
                    </NavItem>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOGSZLA_FIZFELSZ'], true) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '5' })}
                        onClick={() => { this.toggle('5'); }}
                      >
                        Fizetési felszólító
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOGSZLA_SZAMLAKEZELES'], true) && (
                    <TabPane tabId="1">
                      <SzamlaLista
                        globals={this.props.route.globals}
                        ref={(instance) => { this.child1 = instance; }}
                        type={this.state.type}
                        active={this.state.activeTab === '1'}
                      />
                    </TabPane>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOGSZLA_SZAMLAKULDES'], true) && (
                    <React.Fragment>
                      <TabPane tabId="2">
                        <SzamlaElokeszitesLista
                          globals={this.props.route.globals}
                          ref={(instance) => { this.child2 = instance; }}
                          type={this.state.type}
                          active={this.state.activeTab === '2'}
                        />
                      </TabPane>
                      <TabPane tabId="3">
                        <SzamlaKuldesLista
                          globals={this.props.route.globals}
                          ref={(instance) => { this.child3 = instance; }}
                          type={this.state.type}
                          active={this.state.activeTab === '3'}
                        />
                      </TabPane>
                    </React.Fragment>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOGSZLA_SZAMLAPROFIL'], true) && (
                    <TabPane tabId="4">
                      <Profil
                        globals={this.props.route.globals}
                        ref={(instance) => { this.child4 = instance; }}
                        type={this.state.type}
                        active={this.state.activeTab === '4'}
                      />
                    </TabPane>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOGSZLA_FIZFELSZ'], true) && (
                    <TabPane tabId="5">
                      <FizetesiFelszolitoLista
                        globals={this.props.route.globals}
                        ref={(instance) => { this.child5 = instance; }}
                        type={this.state.type}
                        active={this.state.activeTab === '5'}
                      />
                    </TabPane>
                  )}
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SzamlazasMenuBase.propTypes = {
  route: PropTypes.object,
  globals: PropTypes.object
};

export default SzamlazasMenuBase;
