import React from 'react';

const Listak = () => {
  return (
    <div className="animated fadeIn">
      <div className="card card-accent-info">
        <div className="card-header">
          <h5>
            Információ
          </h5>
        </div>
        <div className="card-block">
          Kérem válasszon a bal oldali menüből!
        </div>
      </div>
    </div>
  );
};

export default Listak;
