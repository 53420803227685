import React from 'react';

const Kezdolap = () => {
  return (
    <div className="animated fadeIn">
      <div className="row justify-content-center">
        <div className="col-md-6">
          <div className="clearfix">
            <div className="card">
              <div className="card-header">
                {'Üdvözlünk az OrganW-ben'}
              </div>
              <div className="row justify-content-center" style={{ marginTop: 15 }}><h5>OrganW kezdőlap</h5></div>
              <div className="row justify-content-center"><p>verzió: 1.0</p></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kezdolap;
