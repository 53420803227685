import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import fileSaver from 'file-saver';
import DropZone from 'react-dropzone';
import {
  AvForm,
  AvGroup,
  AvFeedback,
  AvInput
} from 'availity-reactstrap-validation';
import _ from 'lodash';
import { browserHistory } from 'react-router';
// import Select from 'react-select';
import Select from 'react-select/lib/Async';
import { DatePicker, FileCard, BankCard } from '../../../commons/controls';
import { ProgressButton } from '../../../commons/ProgressButton';
import NetworkEmptyState from '../../../commons/networkemptystate';
import SwichButton from '../../../commons/SwitchButton';
import Loader from '../../../commons/loader';
import Services from './services';
import Lib from '../../../commons/lib';
import NumberFormatter from '../../../commons/NumberFormatter';
import SlaveAvInput from '../../../commons/SlaveAvInput';

class CegadatForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cegadatId: '',
      cegadatValtozas: false,
      modositas: false,
      // FORM VALUES
      formTitle: '-',
      formTipus: '',
      formTeljesnev: '',
      formSzekhely: '',
      formRovidnev: '',
      formCegforma: '',
      formAdoszam: '',
      formKozossegiadoszam: '',
      formCegjegyzekszam: '',
      formKezdete: null,
      formVege: null,
      formBejegyzesdatum: null,
      formTelefon: '',
      formMobil: '',
      formFax: '',
      formHonlap: '',
      formEmail: '',
      formBankszamlaszam: [],
      formOrszag: '',
      formIranyitoszam: '',
      formIranyitoszamId: '',
      formTelepules: '',
      formTelepulesId: '',
      formKereses: '',
      formKozterulet: '',
      formKozteruletjelleg: '',
      formHazszam: '',
      formLepcsohaz: '',
      formEpulet: '',
      formEmelet: '',
      formAjto: '',
      formPostafiok: '',
      formKapcsolattartonev: '',
      formKapcsolattartotelefon: '',
      formKapcsolattartoemail: '',
      formObjectParam: [],
      formModalPenzintezet: '',
      formModalBankszamlaszam: '',
      formModalIbanszam: '',
      formIranyitoszamNev: '',
      formTelepulesNev: '',
      // -
      formMegjegyzes: '',
      formMunkalapType: '',
      formMunkalapId: '',
      formCegadat: '',
      formCegadatId: '',
      formPartner: '',
      formLeiras: '',
      formSpecialisID: '',
      formSpecialisHely: '',
      // ['LOAD', 'DATA', 'NETW']
      formKeszletLoadingStatus: 'LOAD',
      // FORM SECTIONS
      vizsgalatEredmenyData: null,
      cikkkezeles: false,
      autoveglegezeskezeles: false,
      leiraskezeles: false,
      osszegkezeles: false,
      workflowkezeles: false,
      // MODAL UTILS
      modalBankadatTitle: '-',
      modalBankadatButtonText: '-',
      // ['FEL', 'MOD']
      formBankadatType : 'FEL',
      modalBankadat: false,
      cimSwitchActive: true,
      // FORM UTILS
      // ['SEARCH', 'SELECT', 'TEXT']
      formIranyitoszamType: 'SEARCH',
      // ['SEARCH', 'SELECT', 'TEXT']
      formTelepulesType: 'SEARCH',
      files: [],
      bankszamlaszamok: [],
      cegadatTipusOptions: [],
      cegadatFormaOptions: [],
      cegadatSzekhelyOptions: [],
      lek: null,
      formCegadatValues: {
        tipus: {
          nev: null
        },
        nev: null,
        cim: null,
        telefon: null,
        email: null,
        adoszam: null,
      },
      submitButtonLoading: false,
      bankButtonLoading: false,
      // STATUS UTILS
      // ['LOAD', 'DATA', 'NETW']
      formCardStatus: 'LOAD',
    };
  }

  componentDidMount() {
    if (this.props.route.type === 'MOD') {
      let params = new URL(window.location.href).searchParams;
      let id = params.get('id');
      this.setState({
        formTitle: 'Cégadat módosítás',
        cegadatId: id,
        modositas: true,
        lek: this.props && this.props.location && this.props.location.state && this.props.location.state.lek,
        cegadatValtozas: params.get('cegadatvaltozas') == 'true'
      }, () => {
        this.getCegadatEnum();
        this.getCegadat(id);
      });
    }
    if (this.props.route.type === 'FEL') {
      this.setState({
        cegadatValtozas: true,
        formTitle: 'Cégadat felvitel',
        formCardStatus: 'DATA',
        lek: this.props && this.props.location && this.props.location.state && this.props.location.state.lek
      });
      this.getCegadatEnum();
      this.getAktivSzekhelyList();
      // this.getTipusList();
      // this.getMegjegyzes();
    }
    // this.listActiveTelephely();
  }

  toggleBankadat = () => {
    this.setState(prevState => ({ modalBankadat: !prevState.modalBankadat }));
  }

  toggleCimSwitch = (trueFalse) => {
    if (trueFalse !== this.state.cimSwitchActive) {
      this.setState(prevState => ({
        cimSwitchActive: !prevState.cimSwitchActive,
        formPostafiok: '',
        formKozterulet: '',
        formKozteruletjelleg: '',
        formHazszam: '',
        formLepcsohaz: '',
        formEpulet: '',
        formEmelet: '',
        formAjto: ''
      }));
    }
  }

  handleNewBankadatClick = () => {
    this.setState({
      formModalPenzintezet: '',
      formModalBankszamlaszam: '',
      formModalIbanszam: '',
      formBankadatType: 'FEL',
      modalBankadatTitle: 'Új banktétel hozzáadása',
      modalBankadatButtonText: 'Hozzáadás'
    }, () => {
      this.toggleBankadat();
    });
  }

  handleBankadatChange = (idx) => {
    this.setState(prevState => ({
      formModalPenzintezet: this.nullToString(prevState.formBankszamlaszam[idx].penzintezet),
      formModalBankszamlaszam: this.nullToString(prevState.formBankszamlaszam[idx].bankszamlaszam),
      formModalIbanszam: this.nullToString(prevState.formBankszamlaszam[idx].ibanszam),
      modalBankadatTitle: 'Banktétel módosítása',
      currentBankId: idx,
      formBankadatType: 'MOD',
      modalBankadatButtonText: 'Módosítás'
    }), () => {
      this.toggleBankadat();
    });
  }

  handleBankadatDelete = (index) => {
    this.setState(prevState => ({
      formBankszamlaszam: [...prevState.formBankszamlaszam.slice(0, index), ...prevState.formBankszamlaszam.slice(index + 1)]
    }));
  }

  addBankadat = (event, bankObj) => {
    this.setState({ bankButtonLoading: true });

    let checkObj = {
      bankszamlaszam: bankObj.formModalBankszamlaszam,
      ibanszam: bankObj.formModalIbanszam,
      penzintezet: this.emptyOrNull(bankObj.formModalPenzintezet)
    };

    Services.checkBank(checkObj, (err) => {
      if (!err) {
        if (this.state.formBankadatType === 'FEL') {
          this.setState(prevState => ({
            bankButtonLoading: false,
            formBankszamlaszam: [...prevState.formBankszamlaszam, {
              bankszamlaszam: bankObj.formModalBankszamlaszam,
              ibanszam: bankObj.formModalIbanszam,
              penzintezet: this.emptyOrNull(bankObj.formModalPenzintezet)
            }]
          }));
        } else {
          let prevBankszamlaszam = JSON.parse(JSON.stringify(this.state.formBankszamlaszam));
          prevBankszamlaszam[this.state.currentBankId] = {
            bankszamlaszam: bankObj.formModalBankszamlaszam,
            ibanszam: bankObj.formModalIbanszam,
            penzintezet: this.emptyOrNull(bankObj.formModalPenzintezet)
          };
          this.setState({
            bankButtonLoading: false,
            formBankszamlaszam: prevBankszamlaszam
          });
        }
        this.toggleBankadat();
      } else {
        this.setState({ bankButtonLoading: false });
      }
    });
  }

  getCegadatEnum = () => {
    Services.getCegadatEnum((err, res) => {
      if (!err) {
        let cegadatTipusOptions = [];
        res[0][0].forEach((item) => {
          cegadatTipusOptions.push(
            <option
              value={item.name}
              key={item.name}
            >
              {item.caption}
            </option>
          );
        });
        let cegadatFormaOptions = [];
        res[0][1].forEach((item) => {
          cegadatFormaOptions.push(
            <option
              value={item.name}
              key={item.name}
            >
              {item.caption}
            </option>
          );
        });
        this.setState({
          cegadatTipusOptions: cegadatTipusOptions,
          cegadatFormaOptions: cegadatFormaOptions
        }, () => {
          // this.getSpecialis(res[0].specialis);
        });
      }
    });
  }

  getAktivSzekhelyList = (ids) => {
    Services.getAktivSzekhelyList(ids, (err, res) => {
      if (!err) {
        let szekhelyOptions = [];
        res[0].forEach((item) => {
          szekhelyOptions.push(
            <option
              value={item.id}
              key={item.id}
            >
              {item.nev}
            </option>
          );
        });
        this.setState({
          cegadatSzekhelyOptions: szekhelyOptions,
        }, () => {
          // this.getSpecialis(res[0].specialis);
        });
      }
    });
  }

  getCegadat = (id) => {
    Services.getCegadat(id, (err, res) => {
      if (!err) {
        let adoszam = this.emptyOrNull(res[0].adoszam);
        adoszam = adoszam ? NumberFormatter.addHyphensToString(adoszam) : this.nullToString(adoszam);
        this.setState({
          formCardStatus: 'DATA',
          formTipus: this.nullToString(res[0].tipus && res[0].tipus.name),
          formTeljesnev: this.nullToString(res[0].teljesnev),
          formSzekhely: this.nullToString(res[0].szekhelyentity && res[0].szekhelyentity.id),
          formRovidnev: this.nullToString(res[0].rovidnev),
          formAdoszam: adoszam,
          formKozossegiadoszam: this.nullToString(res[0].kozossegiadoszam),
          formCegforma: this.nullToString(res[0].cegforma && res[0].cegforma.name),
          formBejegyzesdatum: res[0].bejegyzesdatum,
          formCegjegyzekszam: this.nullToString(res[0].cegjegyzekszam),
          formKezdete: res[0].kezdete,
          formVege: res[0].vege,
          formTelefon: this.nullToString(res[0].telefon),
          formMobil: this.nullToString(res[0].mobil),
          formFax: this.nullToString(res[0].fax),
          formHonlap: this.nullToString(res[0].honlap),
          formEmail: this.nullToString(res[0].email),
          formKozterulet: this.nullToString(res[0].kozterulet),
          formKozteruletjelleg: this.nullToString(res[0].kozteruletjelleg),
          formHazszam: this.nullToString(res[0].hazszam),
          formLepcsohaz: this.nullToString(res[0].lepcsohaz),
          formEpulet: this.nullToString(res[0].epulet),
          formEmelet: this.nullToString(res[0].emelet),
          formAjto: this.nullToString(res[0].ajto),
          formPostafiok: this.nullToString(res[0].postafiok),
          cimSwitchActive: !res[0].postafiok,
          formKapcsolattartonev: this.nullToString(res[0].kapcsolattartonev),
          formKapcsolattartotelefon: this.nullToString(res[0].kapcsolattartotelefon),
          formKapcsolattartoemail: this.nullToString(res[0].kapcsolattartoemail),
          formTelepulesNev: this.nullToString(res[0].telepules),
          formIranyitoszamNev: this.nullToString(res[0].irsz),
          formOrszag: {
            keresheto: this.nullToString(res[0].keresheto),
            nev: this.nullToString(res[0].orszag),
            orszagid: this.nullToString(res[0].orszagid),
          },
          formKereses: {
            iranyitoszam: this.nullToString(res[0].irsz),
            irszid: this.nullToString(res[0].irszid),
            nev: this.nullToString(res[0].telepules),
            telepulesid: this.nullToString(res[0].telepulesid),
            telepulesnev: this.nullToString(res[0].telepules + ' (' + res[0].irsz + ')'),
          },
          files: res[0].objectParam,
          formBankszamlaszam: res[0].bankszamlaszam
        }, () => {
          // this.getSpecialis(res[0].specialis);
          this.getAktivSzekhelyList([this.state.formSzekhely]);
        });
      }
    });
  }

  addCegadat = (event, cegadatObj) => {
    const {
      cegadatId,
      modositas,
      formTipus,
      formTeljesnev,
      formSzekhely,
      formRovidnev,
      formCegforma,
      formBejegyzesdatum,
      formCegjegyzekszam,
      formAdoszam,
      formKozossegiadoszam,
      formTelefon,
      formMobil,
      formFax,
      formHonlap,
      formEmail,
      formKozterulet,
      formKozteruletjelleg,
      formHazszam,
      formLepcsohaz,
      formEpulet,
      formEmelet,
      formAjto,
      formPostafiok,
      formKapcsolattartonev,
      formKapcsolattartotelefon,
      formKapcsolattartoemail,
      formKezdete,
      formVege,
      formOrszag,
      formBankszamlaszam,
      // formIranyitoszam,
      // formTelepules,
      formIranyitoszamNev,
      formTelepulesNev,
      formKereses
    } = this.state;
    let submitObj = {};

    this.setState({ submitButtonLoading: true });

    if (formOrszag && formOrszag.keresheto) {
      submitObj.telepulesid = this.emptyOrNull(formKereses.telepulesid);
      submitObj.irszid = this.emptyOrNull(formKereses.irszid);
    } else {
      submitObj.telepules = this.emptyOrNull(formTelepulesNev);
      submitObj.irsz = this.emptyOrNull(formIranyitoszamNev);
    }

    if (modositas) submitObj.id = this.emptyOrNull(cegadatId);
    submitObj.tipus = this.emptyOrNull(formTipus);
    submitObj.teljesnev = this.emptyOrNull(formTeljesnev);
    submitObj.szekhelyentity = { id: formTipus !== 'S' ? this.emptyOrNull(formSzekhely) : null };
    submitObj.rovidnev = formTipus === 'S' ? this.emptyOrNull(formRovidnev) : null;
    submitObj.cegforma = formTipus === 'S' ? this.emptyOrNull(formCegforma) : null;
    submitObj.bejegyzesdatum = formTipus === 'S' ? this.emptyOrNull(formBejegyzesdatum) : null;
    submitObj.cegjegyzekszam = formTipus === 'S' ? this.emptyOrNull(formCegjegyzekszam) : null;
    let adoszam = this.emptyOrNull(cegadatObj.formAdoszam);
    submitObj.adoszam = adoszam ? NumberFormatter.removeHyphensFromString(adoszam) : null;
    submitObj.kozossegiadoszam = this.emptyOrNull(formKozossegiadoszam);
    submitObj.kezdet = this.emptyOrNull(formKezdete);
    submitObj.veg = this.emptyOrNull(formVege);
    submitObj.telefon = this.emptyOrNull(formTelefon);
    submitObj.mobil = this.emptyOrNull(formMobil);
    submitObj.fax = this.emptyOrNull(formFax);
    submitObj.honlap = this.emptyOrNull(formHonlap);
    submitObj.email = this.emptyOrNull(formEmail);
    submitObj.orszagid = this.emptyOrNull(formOrszag.orszagid);
    submitObj.kozterulet = this.emptyOrNull(formKozterulet);
    submitObj.kozteruletjelleg = this.emptyOrNull(formKozteruletjelleg);
    submitObj.hazszam = this.emptyOrNull(formHazszam);
    submitObj.lepcsohaz = this.emptyOrNull(formLepcsohaz);
    submitObj.epulet = this.emptyOrNull(formEpulet);
    submitObj.emelet = this.emptyOrNull(formEmelet);
    submitObj.ajto = this.emptyOrNull(formAjto);
    submitObj.postafiok = this.emptyOrNull(formPostafiok);
    submitObj.kapcsolattartonev = this.emptyOrNull(formKapcsolattartonev);
    submitObj.kapcsolattartotelefon = this.emptyOrNull(formKapcsolattartotelefon);
    submitObj.kapcsolattartoemail = this.emptyOrNull(formKapcsolattartoemail);
    submitObj.bankszamlaszam = formBankszamlaszam;
    let submitObjParam = JSON.parse(JSON.stringify(this.state.files));
    submitObjParam.forEach((item) => {
      if (item.new) {
        delete item.id;
      }
    });
    submitObj.objectParam = submitObjParam;
    // HRSZ TODO

    if (modositas) {
      Services.modifyCegadat(submitObj, this.state.cegadatValtozas, (err, res) => {
        if (!err) {
          this.setState({ submitButtonLoading: false });
          this.props.route.globals.addSuccess('Cégadat módosítva!');
          this.onBack();
        } else {
          this.setState({ submitButtonLoading: false });
        }
      });
    } else {
      Services.addCegadat(submitObj, (err, res) => {
        if (!err) {
          this.setState({ submitButtonLoading: false });
          this.props.route.globals.addSuccess('Cégadat felvéve!');
          this.onBack();
        } else {
          this.setState({ submitButtonLoading: false });
        }
      });
    }
  }

  emptyOrNull = (data) => {
    if (!data || data === '') return null;
    return data;
  }

  nullToString = (data) => {
    if (!data) return '';
    return data;
  }

  emptyToZero = (data) => {
    if (!data || data === '') return 0;
    return data;
  }

  setActiveLogo = (file) => {
    let newFiles = JSON.parse(JSON.stringify(this.state.files));
    newFiles.forEach((item, idx) => {
      if (item.id === file.id) {
        item.logo = true;
      } else {
        item.logo = false;
      }
    });
    this.setState({
      files: newFiles
    });
  }

  onDropObject = (acceptedfiles) => {
    acceptedfiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        let files = this.state.files || [];
        files.push({
          content: btoa(fileAsBinaryString),
          uploadDate: new Date(),
          docname: file.name,
          preview: file.preview,
          logo: false,
          mime: file.type || 'application/octet-stream',
          new: true,
          length: file.size,
          id: Lib.Browser.uuidv4()
        });
        this.setState({ files: files });
      };
      reader.readAsBinaryString(file);
    });
  }

  downloadObject = (file) => {
    if (file.preview) {
      window.open(file.preview, '_blank');
    } else {
      this.getContent(file.id);
    }
  }

  removeObject = (file) => {
    if (file.preid) {
      let files = this.state.files.filter(f => f.preid !== file.preid);
      this.setState({ files: files });
    } else {
      let files = this.state.files.filter(f => f.id !== file.id);
      this.setState({ files: files });
    }
  }

  getContent = (objid) => {
    let obj = this.state.files.find(f => f.id == objid);
    Services.downloadObject(objid, (err, res) => {
      if (!err) {
        fileSaver.saveAs(new Blob([res[0]], { type: obj.mime }), obj.docname);
      }
    });
  }

  formatBytes = (bytes) => {
    if (bytes < 1024) return bytes + ' bájt';
    if (bytes < 1048576) return (bytes / 1024).toFixed(3) + ' KB';
    if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + ' MB';
    return (bytes / 1073741824).toFixed(3) + ' GB';
  };

  onBack = () => {
    browserHistory.push({
      pathname: '/profil/cegadatok',
      state: {
        lek: this.state.lek
      }
    });
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  handleDateInputChange = (e, v, name) => {
    const value = v === '' ? null : v;
    this.setState({
      [name]: value
    });
  }

  loadOrszagOptions = (input, callback) => {
    if (input.length !== 0) {
      Services.listOrszag(input, (err, res) => {
        if (!err) {
          callback(res[0]);
        }
      });
    }
  }

  loadKeresesOptions = (input, callback) => {
    if (input.length !== 0) {
      Services.listCim(input, (err, res) => {
        if (!err) {
          let options = [];
          res[0].forEach((item) => {
            item.telepuirszek.forEach((iranyitoszam) => {
              console.log(iranyitoszam)
              options.push({
                telepulesnev: item.telepulesnev + ' (' + iranyitoszam.iranyitoszam + ')',
                telepulesid: item.telepulesid,
                iranyitoszam: iranyitoszam.iranyitoszam,
                irszid: iranyitoszam.telepuirszid,
                nev: item.telepulesnev,
                // irsz:
              });
            });
          });
          callback(options);
        }
      });
    }
  }

  loadTelepulesOptions = (input, callback) => {
    if (input.length !== 0) {
      Services.listIranyitoszam(input, (err, res) => {
        if (!err) {
          callback(null, { options: res[0] });
        }
      });
    }
  }

  loadIranyitoszamOptions = (input, callback) => {
    if (input.length !== 0) {
      Services.listTelepules(input, (err, res) => {
        if (!err) {
          let options = [];
          res[0].forEach((item) => {
            item.telepuirszek.forEach((iranyitoszam) => {
              options.push({
                nev: iranyitoszam,
                id: iranyitoszam
              });
            });
          });
          callback(null, { options: options });
        }
      });
    }
  }

  handleChangeOrszagInput = (value) => {
    if (value && value !== '') {
      this.setState({
        formOrszag: value,
        formKereses: '',
        formIranyitoszamNev: '',
        formTelepulesNev: ''
      });
    } else {
      this.setState({
        formOrszag: '',
        formKereses: '',
        formIranyitoszamNev: '',
        formTelepulesNev: ''
      });
    }
  }

  handleChangeKeresesInput = (value) => {
    if (value && value !== '') {
      this.setState({
        formKereses: value,
        formTelepulesNev: value.nev,
        formIranyitoszamNev: value.iranyitoszam
      });
    } else {
      this.setState({
        formKereses: '',
        formTelepulesNev: '',
        formIranyitoszamNev: ''
      });
    }
  }

  handleChangeTelepulesInput = (value) => {
    if (value && value !== '') {
      this.setState({
        formTelepules: value,
      });
    } else {
      this.setState({
        formTelepules: '',
      });
    }
  }

  renderFormCardLogic = () => {
    const {
      formTipus,
      formTeljesnev,
      formSzekhely,
      formRovidnev,
      formCegforma,
      formAdoszam,
      formKozossegiadoszam,
      formCegjegyzekszam,
      formTelefon,
      formMobil,
      formFax,
      formHonlap,
      formEmail,
      formKozterulet,
      formKozteruletjelleg,
      formHazszam,
      formLepcsohaz,
      formEpulet,
      formEmelet,
      formAjto,
      formPostafiok,
      formKapcsolattartonev,
      formKapcsolattartotelefon,
      formKapcsolattartoemail,
      formKezdete,
      formVege,
      formBejegyzesdatum,
      formOrszag,
      // formIranyitoszam,
      // formTelepules,
      formIranyitoszamNev,
      formTelepulesNev,
      formKereses,
      cimSwitchActive
    } = this.state;

    const titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            <h5 style={titleStyle}>Cég adatai:</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <AvGroup>
              <Label for="formTipus">Cégadat típus: *</Label>
              <AvInput
                type="select"
                name="formTipus"
                id="formTipus"
                required
                value={formTipus}
                disabled={this.state.modositas}
                onChange={this.handleInputChange}
              >
                <option value="">Válassz típust...</option>
                {this.state.cegadatTipusOptions}
              </AvInput>
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-8" />
        </div>
        <div className="row">
          <div className="col-md-4">
            <AvGroup>
              <Label for="formTeljesnev">Cég teljes neve: *</Label>
              <AvInput
                name="formTeljesnev"
                id="formTeljesnev"
                type="text"
                value={formTeljesnev}
                onChange={this.handleInputChange}
                maxLength="150"
                required
              />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
          {(formTipus === 'T' || formTipus === 'F') && (
            <div className="col-md-6">
              <AvGroup>
                <Label for="formSzekhely">Hozzárendelt székhely: </Label>
                <AvInput
                  type="select"
                  name="formSzekhely"
                  id="formSzekhely"
                  value={formSzekhely}
                  onChange={this.handleInputChange}
                >
                  <option value="">Válassz értéket...</option>
                  {this.state.cegadatSzekhelyOptions}
                </AvInput>
              </AvGroup>
            </div>
          )}
          {(formTipus === '' || formTipus === 'S') && (
            <div className="col-md-4">
              <AvGroup>
                <Label for="formRovidnev">Rövidített neve: *</Label>
                <AvInput
                  name="formRovidnev"
                  id="formRovidnev"
                  type="text"
                  value={formRovidnev}
                  onChange={this.handleInputChange}
                  maxLength="100"
                  required
                />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
            </div>
          )}
          {(formTipus === '' || formTipus === 'S') && (
            <div className="col-md-4">
              <AvGroup>
                <Label for="formCegforma">Cégforma: </Label>
                <AvInput
                  type="select"
                  name="formCegforma"
                  id="formCegforma"
                  value={formCegforma}
                  onChange={this.handleInputChange}
                >
                  <option value="">Válassz értéket...</option>
                  {this.state.cegadatFormaOptions}
                </AvInput>
              </AvGroup>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-4">
            <Label for="formKezdete">
              {'Kezdete: *'}
            </Label>
            <DatePicker
              required
              placeholder="éééé-hh-nn"
              onChange={(e, v) => this.handleDateInputChange(e, v, 'formKezdete')}
              value={formKezdete && new Date(formKezdete)}
              type="select"
              id="formKezdete"
              name="formKezdete"
              disabled={this.state.cegadatValtozas != true}
            />
          </div>
          <div className="col-md-4">
            <Label for="formVege">
              {'Vége:'}
            </Label>
            <DatePicker
              required
              placeholder="éééé-hh-nn"
              onChange={(e, v) => this.handleDateInputChange(e, v, 'formVege')}
              value={formVege && new Date(formVege)}
              min={formKezdete && new Date(formKezdete)}
              type="select"
              id="formVege"
              name="formVege"
            />
          </div>
          {(formTipus === '' || formTipus === 'S') && (
            <div className="col-md-4">
              <Label for="formBejegyzesdatum">
                {'Bejegyzés dátuma: '}
              </Label>
              <DatePicker
                required
                placeholder="éééé-hh-nn"
                onChange={(e, v) => this.handleDateInputChange(e, v, 'formBejegyzesdatum')}
                value={formBejegyzesdatum && new Date(formBejegyzesdatum)}
                type="select"
                id="formBejegyzesdatum"
                name="formBejegyzesdatum"
              />
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-4">
            <SlaveAvInput
              id="formAdoszam"
              label="Adószám: *"
              type="text"
              value={formAdoszam}
              required
              szuroTipus={NumberFormatter.ADOSZAM}
            />
          </div>
          <div className="col-md-4">
            <AvGroup>
              <Label for="formKozossegiadoszam">Közösségi adószám: </Label>
              <AvInput
                name="formKozossegiadoszam"
                id="formKozossegiadoszam"
                type="text"
                value={formKozossegiadoszam}
                onChange={this.handleInputChange}
                maxLength="100"
              />
              {/* <AvFeedback>Kötelező mező!</AvFeedback> */}
            </AvGroup>
          </div>
          {(formTipus === '' || formTipus === 'S') && (
            <div className="col-md-4">
              <AvGroup>
                <Label for="formCegjegyzekszam">Cégjegyzékszám: </Label>
                <AvInput
                  name="formCegjegyzekszam"
                  id="formCegjegyzekszam"
                  type="text"
                  value={formCegjegyzekszam}
                  onChange={this.handleInputChange}
                />
              </AvGroup>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-12">
            <h5 style={titleStyle}>Elérhetőségek:</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <AvGroup>
              <Label for="formTelefon">Telefon: *</Label>
              <AvInput
                name="formTelefon"
                id="formTelefon"
                value={formTelefon}
                onChange={this.handleInputChange}
                type="text"
                maxLength="20"
                required
              />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-4">
            <AvGroup>
              <Label for="formMobil">Mobiltelefon:</Label>
              <AvInput
                name="formMobil"
                id="formMobil"
                value={formMobil}
                onChange={this.handleInputChange}
                type="text"
                maxLength="20"
              />
            </AvGroup>
          </div>
          <div className="col-md-4">
            <AvGroup>
              <Label for="formFax">Fax:</Label>
              <AvInput
                name="formFax"
                id="formFax"
                value={formFax}
                onChange={this.handleInputChange}
                type="text"
                maxLength="20"
              />
            </AvGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <AvGroup>
              <Label for="formHonlap">Honlap:</Label>
              <AvInput
                name="formHonlap"
                id="formHonlap"
                value={formHonlap}
                onChange={this.handleInputChange}
                pattern="(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})"
                type="text"
                maxLength="100"
              />
              <AvFeedback>Hibás honlap formátum! (http|https://www.xyz.xyz)</AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-4">
            <AvGroup>
              <Label for="formEmail">E-mail:</Label>
              <AvInput
                name="formEmail"
                id="formEmail"
                type="email"
                value={formEmail}
                onChange={this.handleInputChange}
                maxLength="100"
              />
              <AvFeedback>Hibás E-mail formátum!</AvFeedback>
            </AvGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h5 style={titleStyle}>Bank adatok:</h5>
          </div>
        </div>
        <div className="row">
          {this.state.formBankszamlaszam && this.state.formBankszamlaszam.map((item, idx) => {
            return (
              <div className="col-md-4" key={idx.toString()}>
                <BankCard
                  idx={idx.toString()}
                  key={idx.toString()}
                  bankszamlaszam={item.bankszamlaszam}
                  ibanszam={item.ibanszam}
                  penzintezet={item.penzintezet}
                  onModifyClick={() => this.handleBankadatChange(idx)}
                  onDeleteClick={() => this.handleBankadatDelete(idx)}
                />
              </div>
            );
          })}
        </div>
        <div className="row">
          <div className="col-md-12">
            <Button className="pull-left table-new-button" color="success" onClick={() => this.handleNewBankadatClick()}>Új hozzáadása</Button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h5 style={titleStyle}>Cím:</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3" style={{ marginBottom: '1rem' }}>
            <Label for="formOrszag">Ország: *</Label>
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="formOrszag"
              id="formOrszag"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeOrszagInput(v)}
              clearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadOrszagOptions, 1000)}
              isLoading={false}
              getOptionLabel={option => option.nev}
              getOptionValue={option => option.orszagid}
              value={formOrszag}
              cache={false}
              autoload={false}
            />
          </div>
          { formOrszag && formOrszag.keresheto && (
            <div className="col-md-3" style={{ marginBottom: '1rem' }}>
              <Label for="formKereses">Keresés: </Label>
              <Select
                styles={{
                  control: base => ({
                    ...base,
                    '&:focus': { borderColor: 'red' }, // border style on hover
                    border: '1px solid lightgray', // default border color
                    boxShadow: 'none', // no box-shadow
                    borderRadius: '0'
                  }),
                }}
                name="formKereses"
                id="formKereses"
                placeholder="Település vagy irányítószám"
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingMessage={() => 'Keresés...'}
                onChange={v => this.handleChangeKeresesInput(v)}
                // isClearable
                ignoreAccents={false}
                // loadOptions={(input, callback) => this.loadKeresesOptions(input, callback)}
                loadOptions={_.debounce(this.loadKeresesOptions, 1000)}
                getOptionLabel={option => option.telepulesnev}
                getOptionValue={option => option.telepulesid}
                isLoading={false}
                value={formKereses}
                cache={false}
                autoload={false}
              />
            </div>
          )}
          <div className="col-md-3">
            <AvGroup>
              <Label for="formIranyitoszamNev">Irányítószám: *</Label>
              <AvInput
                name="formIranyitoszamNev"
                id="formIranyitoszamNev"
                type="text"
                maxLength="20"
                value={formIranyitoszamNev}
                onChange={this.handleInputChange}
                disabled={(formOrszag && formOrszag.keresheto) || (formOrszag === '')}
                required
              />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-3">
            <AvGroup>
              <Label for="formTelepulesNev">Település: *</Label>
              <AvInput
                name="formTelepulesNev"
                id="formTelepulesNev"
                type="text"
                value={formTelepulesNev}
                onChange={this.handleInputChange}
                disabled={(formOrszag && formOrszag.keresheto) || (formOrszag === '')}
                required
              />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
          {/* <div className="col-md-3">
            <Label for="formTelepules">Település: *</Label>
            <Select.Async
              name="formTelepules"
              id="formTelepules"
              placeholder="Kezdjen el gépelni..."
              noResultsText="Nincs keresési eredmény"
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingPlaceholder="Keresés..."
              onChange={v => this.handleChangeTelepulesInput(v)}
              clearable
              ignoreAccents={false}
              loadOptions={_.debounce((input, callback) => this.loadTelepulesOptions(input, callback), 1000)}
              isLoading={false}
              valueKey="telepulesid"
              labelKey="telepulesnev"
              value={formTelepules}
              cache={false}
              autoload={false}
            />
        </div> */}
        </div>
        <div className="row">
          <div className="col-md-12">
            <SwichButton
              active={cimSwitchActive}
              trueToggle={() => this.toggleCimSwitch(true)}
              falseToggle={() => this.toggleCimSwitch(false)}
              style={{ marginBottom: 15, marginTop: 8 }}
              trueLabel="Cím"
              falseLabel="Postafiók"
            />
          </div>
        </div>
        { cimSwitchActive && (
          <React.Fragment>
            <div className="row">
              <div className="col-md-3">
                <AvGroup>
                  <Label for="formKozterulet">Közterület neve: *</Label>
                  <AvInput
                    name="formKozterulet"
                    id="formKozterulet"
                    type="text"
                    value={formKozterulet}
                    onChange={this.handleInputChange}
                    maxLength="50"
                    required
                  />
                  <AvFeedback>Kötelező mező!</AvFeedback>
                </AvGroup>
              </div>
              <div className="col-md-3">
                <AvGroup>
                  <Label for="formKozteruletjelleg">Közterület jellege: *</Label>
                  <AvInput
                    name="formKozteruletjelleg"
                    id="formKozteruletjelleg"
                    value={formKozteruletjelleg}
                    onChange={this.handleInputChange}
                    type="text"
                    maxLength="20"
                    required
                  />
                  <AvFeedback>Kötelező mező!</AvFeedback>
                </AvGroup>
              </div>
              <div className="col-md-3">
                <AvGroup>
                  <Label for="formHazszam">Házszám: *</Label>
                  <AvInput
                    name="formHazszam"
                    id="formHazszam"
                    value={formHazszam}
                    onChange={this.handleInputChange}
                    type="text"
                    maxLength="20"
                    required
                  />
                  <AvFeedback>Kötelező mező!</AvFeedback>
                </AvGroup>
              </div>
              <div className="col-md-3">
                <AvGroup>
                  <Label for="formEpulet">Épület:</Label>
                  <AvInput
                    name="formEpulet"
                    id="formEpulet"
                    value={formEpulet}
                    onChange={this.handleInputChange}
                    type="text"
                    maxLength="4"
                  />
                </AvGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <AvGroup>
                  <Label for="formLepcsohaz">Lépcsőház: </Label>
                  <AvInput
                    name="formLepcsohaz"
                    id="formLepcsohaz"
                    value={formLepcsohaz}
                    onChange={this.handleInputChange}
                    type="text"
                    maxLength="4"
                  />
                </AvGroup>
              </div>
              <div className="col-md-3">
                <AvGroup>
                  <Label for="formEmelet">Emelet:</Label>
                  <AvInput
                    name="formEmelet"
                    id="formEmelet"
                    value={formEmelet}
                    onChange={this.handleInputChange}
                    type="text"
                    maxLength="10"
                  />
                </AvGroup>
              </div>
              <div className="col-md-3">
                <AvGroup>
                  <Label for="formAjto">Ajtó:</Label>
                  <AvInput
                    name="formAjto"
                    id="formAjto"
                    value={formAjto}
                    onChange={this.handleInputChange}
                    type="text"
                    maxLength="15"
                  />
                  <AvFeedback>Szám mező!</AvFeedback>
                </AvGroup>
              </div>
            </div>
          </React.Fragment>
        )}
        { !cimSwitchActive && (
          <div className="row">
            <div className="col-md-3">
              <AvGroup>
                <Label for="formPostafiok">Postafiók: *</Label>
                <AvInput
                  name="formPostafiok"
                  id="formPostafiok"
                  value={formPostafiok}
                  onChange={this.handleInputChange}
                  required
                  type="text"
                  maxLength="40"
                />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-12">
            <h5 style={titleStyle}>Kapcsolattartó adatai:</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <AvGroup>
              <Label for="formKapcsolattartonev">Név:</Label>
              <AvInput
                name="formKapcsolattartonev"
                id="formKapcsolattartonev"
                value={formKapcsolattartonev}
                onChange={this.handleInputChange}
                type="text"
                maxLength="150"
              />
            </AvGroup>
          </div>
          <div className="col-md-3">
            <AvGroup>
              <Label for="formKapcsolattartotelefon">Telefon:</Label>
              <AvInput
                name="formKapcsolattartotelefon"
                id="formKapcsolattartotelefon"
                value={formKapcsolattartotelefon}
                onChange={this.handleInputChange}
                type="text"
                maxLength="20"
              />
            </AvGroup>
          </div>
          <div className="col-md-3">
            <AvGroup>
              <Label for="formKapcsolattartoemail">E-mail:</Label>
              <AvInput
                name="formKapcsolattartoemail"
                id="formKapcsolattartoemail"
                value={formKapcsolattartoemail}
                onChange={this.handleInputChange}
                type="email"
                maxLength="100"
              />
              <AvFeedback>Hibás E-mail formátum!</AvFeedback>
            </AvGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h5 style={titleStyle}>Fájl feltöltése:</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <DropZone
              multiple
              onDrop={this.onDropObject}
              className="custom-dropzone"
            >
              <div className="dropzone-text">
                {'Húzd ide a fájlokat vagy kattints a négyzetbe egy kép feltöltéséhez!'}
              </div>
            </DropZone>
          </div>
        </div>
        <div className="row">
          {this.state.files && this.state.files.map((item, idx) => {
            return (
              <div className="col-md-4" key={idx.toString()}>
                <FileCard
                  key={idx.toString()}
                  mime={item.mime}
                  title={item.docname}
                  length={this.formatBytes(item.length)}
                  activeLogo={item.logo}
                  onLogoClick={() => this.setActiveLogo(item)}
                  onDownloadClick={() => this.downloadObject(item)}
                  onDeleteClick={() => this.removeObject(item)}
                />
              </div>
            );
          })}
        </div>
        <div className="row">
          <div className="col-md-12">
            <ProgressButton
              color="primary"
              style={{ cursor: 'pointer' }}
              loading={this.state.submitButtonLoading}
            >
              {'Mentés'}
            </ProgressButton>
            {' '}
            <Button
              color="secondary"
              onClick={this.onBack}
              style={{ cursor: 'pointer' }}
            >
              {'Vissza'}
            </Button>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderFormCard = () => {
    const cardStyle = {
      padding: 20
    };

    return (
      <div className="animated fadeIn">
        <div className="card">
          <div className="card-header">
            {this.state.formTitle}
          </div>
          <AvForm onValidSubmit={this.addCegadat}>
            <div style={cardStyle}>
              {{
                LOAD: (<div className="col-md-12"><Loader /></div>),
                NETW: (<NetworkEmptyState
                  hasImage
                  submitClick={() => this.getTipusList()}
                />),
                DATA: (this.renderFormCardLogic())
              }[this.state.formCardStatus]}
            </div>
          </AvForm>
        </div>
      </div>
    );
  }

  render() {
    const {
      formModalBankszamlaszam,
      formModalPenzintezet,
      formModalIbanszam
    } = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            {this.renderFormCard()}
          </div>
        </div>
        <Modal isOpen={this.state.modalBankadat} toggle={this.toggleBankadat} backdrop="static">
          <AvForm onValidSubmit={this.addBankadat}>
            <ModalHeader>{this.state.modalBankadatTitle}</ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  <AvGroup>
                    <Label for="formModalPenzintezet">Számlavezető pénzintézet neve: *</Label>
                    <AvInput
                      name="formModalPenzintezet"
                      id="formModalPenzintezet"
                      value={formModalPenzintezet}
                      onChange={this.handleInputChange}
                      type="text"
                      maxLength="100"
                      required
                    />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <AvGroup>
                    <Label for="formModalBankszamlaszam">Bankszámlaszám: *</Label>
                    <AvInput
                      name="formModalBankszamlaszam"
                      id="formModalBankszamlaszam"
                      value={formModalBankszamlaszam}
                      onChange={this.handleInputChange}
                      pattern="^[\d-]+$"
                      type="text"
                      maxLength="100"
                      required
                    />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <AvGroup>
                    <Label for="formModalIbanszam">IBAN szám: </Label>
                    <AvInput
                      name="formModalIbanszam"
                      id="formModalIbanszam"
                      value={formModalIbanszam}
                      onChange={this.handleInputChange}
                      type="text"
                      maxLength="100"
                    // required
                    />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <ProgressButton color="primary" loading={this.state.bankButtonLoading}>{this.state.modalBankadatButtonText}</ProgressButton>
              <Button type="button" color="secondary" onClick={this.toggleBankadat}>Mégsem</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </React.Fragment>
    );
  }
}

CegadatForm.propTypes = {
  globals: PropTypes.object
};

export default CegadatForm;
