import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes, { bool } from 'prop-types';
import {
  Badge,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Row
} from 'reactstrap';
import Select from 'react-select/lib/Async';
import {
  AvGroup,
  AvInput,
  AvFeedback,
  AvForm,
  AvField
} from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DataEmptyState from '../../commons/dataemptystate';
import QueryEmptyState from '../../commons/queryempty';
import NetworkEmptyState from '../../commons/networkemptystate';
import Loader from '../../commons/loader';
import Services from './services';
import { PaginationTotal } from '../../commons/controls';
import NumberFormatter from '../../commons/NumberFormatter';
import Lib from '../../commons/lib';

class RecepcioLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ['QUERY', 'LOAD', 'DATA', 'NETW', 'TABL']
      currentStatusBaseTable: 'QUERY',
      // ['LOAD', 'LOADED', 'NETW']
      currentStatusBaseView: 'LOAD',
      // ['HIDE', 'LOAD', 'DATA', 'NETW', 'TABL']
      currentStatusChildTable: 'HIDE',
      // ['LOAD', 'LOADED', 'NETW']
      currentStatusBaseRciView: 'LOAD',
      currentRciId: null,
      baseViewData: null,
      szallodaHasValue: false,
      modalBaseView: false,
      modalRciNezet: false,
      aktivSzallodak: null,
      aktivApartmanok: null,
      aktivRCIhetek: null,
      eloszuresEv: new Date().getFullYear().toString(),
      eloszuresSzallodaId: null,
      eloszuresApartmanId: null,
      eloszuresApartman: null,
      eloszuresRCIhet: null,
      eloszuresJogtulaj: '',
      baseJson: [],
      disabledRows: [],
      childJson: [],
      rciViewObj: [],
      showNote: false,
      showChildNote: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      if (this.props.active) {
        this.getAktivSzallodak();
        this.genWeeks();
      }
    }
  }

  onRowSelect = (row) => {
    this.updateChildTable(row.id);
  }

  getAktivSzallodak = () => {
    Services.getSzallodaList((err, res) => {
      if (!err) {
        let aktivSzallodak = res[0].map(e => <option key={e.id} value={e.id}>{e.nev}</option>);
        this.setState({ aktivSzallodak: aktivSzallodak });
      }
    });
  }

  getAktivApartmanBySzallodaId = (szallodaId) => {
    Services.getAktivApartmanBySzallodaId(szallodaId, (err, res) => {
      if (!err) {
        let aktivApartmanok = res[0].map(e => <option key={e.id} value={e.id}>{e.nev}</option>);
        this.setState({ aktivApartmanok: aktivApartmanok });
      }
    });
  }

  toggleBaseView = () => {
    this.setState({
      modalBaseView: !this.state.modalBaseView
    });
  }

  toggleRciNezet = () => {
    this.setState({ modalRciNezet: !this.state.modalRciNezet });
  }

  genWeeks = () => {
    let weeks = [];
    for (let i = 1; i < 53; i += 1) {
      weeks.push(<option key={i.toString()} value={i}>{i.toString() + '. hét'}</option>);
    }
    this.setState({ aktivRCIhetek: weeks });
  }

  updateBaseTable = () => {
    let szallodaId = this.state.eloszuresSzallodaId === '' ? null : this.state.eloszuresSzallodaId;
    let apartmanId = this.state.eloszuresApartman && this.state.eloszuresApartman.id && this.state.eloszuresApartman.id;
    let apartmanNev = this.state.eloszuresApartman && this.state.eloszuresApartman.id ? null : this.state.eloszuresApartman && this.state.eloszuresApartman.nev;
    let rcihet = this.state.eloszuresRCIhet === '' ? null : this.state.eloszuresRCIhet;
    let jogtulaj = this.state.eloszuresJogtulaj && this.state.eloszuresJogtulaj.id && this.state.eloszuresJogtulaj.id;
    let ev = this.state.eloszuresEv === '' ? null : this.state.eloszuresEv;
    this.setState({
      currentStatusBaseTable: 'LOAD',
      childJson: [],
      currentStatusChildTable: 'HIDE'
    });
    Services.getRecepcioBaseList(szallodaId, apartmanId, apartmanNev, rcihet, jogtulaj, ev, (err, res) => {
      if (err) {
        this.setState({
          currentStatusBaseTable: 'QUERY'
        });
      } else {
        let data = [];
        let rows = res[0];
        let disabledRows = [];
        let showNote = false;
        rows.forEach((item, idx) => {
          // if (item.tipus && item.tipus.name && item.tipus.name === 'U') {
          //   disabledRows.push(idx);
          // }
          item.idx = idx;
          data.push(item);
          if (!showNote && item.ujrahasznosithato) {
            showNote = true;
          }
        });
        this.setState({
          baseJson: data,
          disabledRows: disabledRows,
          currentStatusBaseTable: res[0].length > 0 ? 'TABL' : 'DATA',
          showNote: showNote
        });
      }
    });
  }

  updateChildTable = (id) => {
    this.setState({
      currentStatusChildTable: 'LOAD'
    });
    Services.getRecepcioChildList(id, (err, res) => {
      if (err) {
        this.setState({
          currentStatusChildTable: 'NETW'
        });
      } else {
        const childObj = JSON.parse(JSON.stringify(res[0]))
        let hasChildNote = false;
        childObj.forEach((item) => {
          if (item.fizetesimeghagyasalatt) hasChildNote = true;
          item.kkamatosszegek = item.kkamatgeneralt + ' ' + item.penznem + ' / \n' + item.puteljkkamat + ' ' + item.penznem;
          item.tenyltart = (item.brutto) - (item.puteljszamla);
        });
        this.setState({
          childJson: childObj,
          showChildNote: hasChildNote,
          currentStatusChildTable: res[0].length > 0 ? 'TABL' : 'DATA'
        });
      }
    });
  }

  downloadPdf = () => {
    let szallodaId = this.state.eloszuresSzallodaId === '' ? null : this.state.eloszuresSzallodaId;
    let rcihet = this.state.eloszuresRCIhet === '' ? null : this.state.eloszuresRCIhet;
    let ev = this.state.eloszuresEv === '' ? null : this.state.eloszuresEv;
    Services.getHetiPdf(szallodaId, rcihet, ev, (err, res) => {
      if (!err) {
        let fileURL = URL.createObjectURL(new Blob([res[0]], { type: 'application/pdf' }));
        window.open(fileURL);
      }
    });
  }

  handleBaseViewClick = (id, row) => {
    this.setState({
      currentStatusBaseView: 'LOAD',
      modalBaseView: true
    });
    Services.getUdulesijog(id, (err, res) => {
      if (err) {
        this.setState({
          currentStatusBaseView: 'NETW'
        });
      } else {
        this.setState({
          baseViewData: res[0],
          currentStatusBaseView: 'LOADED'
        });
      }
    });
  }

  handleRciViewClick = (id) => {
    this.setState({
      currentRciId: id,
      currentStatusBaseRciView: 'LOAD',
      modalRciNezet: true
    });
    Services.getRciData(id, (err, res) => {
      if (err) {
        this.setState({
          currentStatusBaseRciView: 'NETW'
        });
      } else {
        this.setState({
          rciViewObj: res[0],
          currentStatusBaseRciView: 'LOADED'
        });
      }
    });
  }

  szallodaChange = (e) => {
    if (e.target.value === '') {
      this.setState({
        szallodaHasValue: false,
        aktivApartmanok: null,
        eloszuresSzallodaId: '',
        eloszuresJogtulaj: '',
        eloszuresApartmanId: '',
        eloszuresRCIhet: '',
      });
    } else {
      this.setState({ eloszuresSzallodaId: e.target.value, szallodaHasValue: true, eloszuresApartmanId: '', }, () => {
      });
    }
  }

  apartmanChange = (e) => {
    this.setState({
      eloszuresApartmanId: e.target.value,
      eloszuresRCIhet: '',
      eloszuresJogtulaj: '',
    });
  }

  evChange = (e) => {
    this.setState({
      eloszuresEv: e.target.value,
    });
  }

  rcihetChange = (e) => {
    this.setState({
      eloszuresRCIhet: e.target.value,
      eloszuresJogtulaj: ''
    });
  }

  handleChangeJogtulaj = (value) => {
    if (value && value.length !== 0) {
      this.setState({ eloszuresJogtulaj: value });
    } else {
      this.setState({ eloszuresJogtulaj: '' });
    }
  }

  handleApartmanChange = (value) => {
    if (value && value.length !== 0) {
      this.setState({ eloszuresApartman: value });
    } else {
      this.setState({ eloszuresApartman: '' });
    }
  }

  loadJogtulajChange = (input, callback) => {
    if (input.length !== 0) {
      let apartmanId = this.state.eloszuresApartman && this.state.eloszuresApartman.id && this.state.eloszuresApartman.id;
      let apartmanNev = this.state.eloszuresApartman && this.state.eloszuresApartman.id ? null : this.state.eloszuresApartman && this.state.eloszuresApartman.nev;
      Services.getJogtulaj(this.state.eloszuresSzallodaId, apartmanId, apartmanNev, this.state.eloszuresRCIhet, input, this.state.eloszuresEv, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            let newItem = item;
            newItem.nev += ' (' + newItem.cim + ') ';
            if (newItem.adoszam) {
              newItem.nev += '[' + NumberFormatter.addHyphensToString(newItem.adoszam) + '] ';
            }
            newArray.push(newItem);
          });
          callback(newArray);
        }
      });
    }
  }

  loadApartmanOptions = (input, callback) => {
    if (input.length !== 0) {
      Services.getAktivApartmanBySzallodaId(this.state.eloszuresSzallodaId, input, (err, res) => {
        if (!err) {
          callback(res[0]);
        }
      });
    }
  }

  tartozasFormatter = (cell) => {
    if (cell !== undefined) {
      return cell ? <Badge color="danger">Van</Badge> : <Badge color="success">Nincs</Badge>;
    }

    return '';
  }

  szallodaNameFormatter = (cell) => {
    return (
      cell.szallodanev && cell.epuletnev
    );
  }

  epuletNameFormatter = (cell) => {
    return (
      cell.epuletnev && cell.epuletnev
    );
  }

  apartmanNameFormatter = (cell) => {
    return (
      cell.apartmannev && cell.apartmannev
    );
  }

  baseTableIconFormatter = (cell, row) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOG_RECEPCIO', 'LEK') && (
          <React.Fragment>
            <Button
              className="icon-eye table-action-icon"
              id={'action-view-base-' + cell}
              onClick={() => this.handleBaseViewClick(cell, row)}
            />
            <UncontrolledTooltip
              placement="left"
              target={'action-view-base-' + cell}
              delay={40}
            >
              {'Megtekintés'}
            </UncontrolledTooltip>
            <Button
              className="fa fa-building-o table-action-icon"
              id={'action-rci-view-' + cell}
              onClick={() => this.handleRciViewClick(cell)}
            />
            <UncontrolledTooltip
              placement="left"
              target={'action-rci-view-' + cell}
              delay={40}
            >
              {'RCI adatok megtekintés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  priceFormatter = (cell, row) => {
    return (!cell || cell === '') ? '' : (NumberFormatter.addSpacesToString(cell, NumberFormatter.OSSZEG) + ' ' + row.penznem);
  }

  doubePriceFormatter = (cell) => {
    if (!cell || cell === '') {
      return '';
    }

    let firstSpace = cell.indexOf(' ');
    let lastSpace = cell.lastIndexOf(' ');
    let newRow = cell.indexOf('\n') + 1;
    return NumberFormatter.addSpacesToString(cell.slice(0, firstSpace), NumberFormatter.OSSZEG) + cell.slice(firstSpace, newRow) + NumberFormatter.addSpacesToString(cell.slice(newRow, lastSpace), NumberFormatter.OSSZEG) + cell.slice(lastSpace);
  }

  handleInputChangeApartman = (value, { action }) => {
    switch (action) {
      case 'input-change':
        if (value && value.length !== '') {
          this.setState({
            eloszuresApartman: {
              nev: value,
              id: null
            }
          });
        } else {
          this.setState({ eloszuresApartman: '' });
        }
        break;
      default:
    }
  }

  renderEloSzures = () => {
    return (
      <AvForm style={{ marginBottom: 20 }}>
        <div className="row">
          <div className="col-md-2">
            <AvField
              type="select"
              name="szallodaid"
              id="szallodaid"
              label="Szálloda: "
              errorMessage="Kötelező mező!"
              onChange={this.szallodaChange}
              value={this.state.eloszuresSzallodaId}
            >
              <option value="">Válassz szállodát...</option>
              {this.state.aktivSzallodak}
            </AvField>
          </div>
          <div className="col-md-3">
            <Label for="apartmanSeach">Apartman:</Label>
            {/* <AvField
              type="select"
              name="apartmanid"
              id="apartmanid"
              label="Apartman: "
              errorMessage="Kötelező mező!"
              onChange={this.apartmanChange}
              value={this.state.eloszuresApartmanId}
              disabled={!this.state.szallodaHasValue}
            >
              <option value="">Válassz apartmant...</option>
              {this.state.aktivApartmanok}
            </AvField> */}
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="apartmanSeach"
              id="apartmanSeach"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleApartmanChange(v)}
              onInputChange={this.handleInputChangeApartman}
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadApartmanOptions, 1000)}
              isLoading={false}
              getOptionLabel={option => option.nev}
              getOptionValue={option => option.id}
              value={this.state.eloszuresApartman}
              isDisabled={!this.state.szallodaHasValue}
              cache={false}
              autoload={false}
            />
          </div>
          <div className="col-md-2">
            <AvGroup>
              <Label for="ev">Év: *</Label>
              <AvInput
                name="ev"
                id="ev"
                maxLength="4"
                onChange={this.evChange}
                disabled={!this.state.szallodaHasValue}
                value={this.state.eloszuresEv}
                placeholder="éééé"
              />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-2">
            <AvField
              type="select"
              name="rcihet"
              id="rcihet"
              label="RCI hét: "
              errorMessage="Kötelező mező!"
              onChange={this.rcihetChange}
              value={this.state.eloszuresRCIhet}
              disabled={!this.state.szallodaHasValue}
            >
              <option value="">Válassz hetet...</option>
              {this.state.aktivRCIhetek}
            </AvField>
          </div>
          <div className="col-md-3">
            <Label for="jogtulaj">Jogtulajdonos:</Label>
            {/* <Select.Async
              name="jogtulaj"
              id="jogtulaj"
              placeholder="Kezdjen el gépelni..."
              noResultsText="Nincs keresési eredmény"
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingPlaceholder="Keresés..."
              onChange={v => this.handleChangeJogtulaj(v)}
              clearable
              ignoreAccents={false}
              loadOptions={_.debounce((input, callback) => this.loadJogtulajChange(input, callback), 1000)}
              isLoading={false}
              valueKey="id"
              labelKey="nev"
              value={this.state.eloszuresJogtulaj}
              cache={false}
              autoload={false}
              disabled={!this.state.szallodaHasValue}
            /> */}
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="jogtulaj"
              id="jogtulaj"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeJogtulaj(v)}
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadJogtulajChange, 1000)}
              isLoading={false}
              getOptionLabel={option => option.nev}
              getOptionValue={option => option.id}
              value={this.state.eloszuresJogtulaj}
              isDisabled={!this.state.szallodaHasValue}
              cache={false}
              autoload={false}
            />
          </div>
          <div className="col-md-12">
            <Button
              color="primary"
              onClick={() => { this.updateBaseTable(); }}
              disabled={!this.state.szallodaHasValue}
            >
              {'Lekérdezés'}
            </Button>
            {' '}
            <Button
              color="success"
              className="icon-printer"
              disabled={!this.state.szallodaHasValue}
              onClick={() => { this.downloadPdf(); }}
            >
              {' '}
              <span className="basic-font">Heti lista</span>
            </Button>
          </div>
        </div>
      </AvForm>
    );
  }

  renderTulajdonosListItem = (tulajdonos) => {
    return (
      <li key={tulajdonos.id.toString()} style={tulajdonos.fotulajdonos ? { color: 'forestgreen' } : {}}>
        <b>{tulajdonos.tulajdonosbean.nev}</b><br />
        <span><b>Cím: </b>{tulajdonos.tulajdonosbean.cim}</span><br />
        <span><b>Érvényesség kezdete: </b>{tulajdonos.kezdete}</span><br />
        <span><b>Érvényesség vége: </b>{tulajdonos.vege}</span><br />
        {tulajdonos.elhunyt && (
          <React.Fragment>
            <span><b>Elhunyt: </b>Igen</span><br />
          </React.Fragment>
        )}
        {tulajdonos.eladta && (
          <React.Fragment>
            <span><b>Eladta: </b>Igen</span><br />
          </React.Fragment>
        )}
        <span><b>RCI tag: </b>{tulajdonos.rcitag ? 'Igen' : 'Nem'}</span><br />
        <span><b>RCI státusz: </b>{tulajdonos.rcistatusz && tulajdonos.rcistatusz.caption}</span><br />
        <span><b>RCI Weeks azonosító: </b>{tulajdonos.rciweeksazon}</span><br />
        <span><b>RCI Points azonosító: </b>{tulajdonos.rcipointsazon}</span><br />
        <span><b>RCI típus: </b>{tulajdonos.rcitipus && tulajdonos.rcitipus.caption}</span><br /><br />
      </li>
    );
  }

  renderBaseViewData = () => {
    let listItems = [];
    if (this.state.baseViewData) {
      this.state.baseViewData.udulesijogtulajdonosentities.filter(item => !item.kompenzacios).forEach((item) => {
        listItems.push(this.renderTulajdonosListItem(item));
      });
    }
    listItems.sort((a, b) => a.fotulajdonos - b.fotulajdonos);

    return (
      <div>
        <h6>Üdülési jog információ:</h6>
        <b>Szerződés azonosító: </b>{this.state.baseViewData && this.state.baseViewData.szerzodesazonosito}<br />
        <b>Üdülési jog azonosító: </b>{this.state.baseViewData && this.state.baseViewData.azonosito}<br />
        <b>Érvényesség kezdete: </b>{this.state.baseViewData && this.state.baseViewData.kezdete}<br />
        <b>Érvényesség vége: </b>{this.state.baseViewData && this.state.baseViewData.vege}<br />
        <b>Számlakibocsátó: </b>{this.state.baseViewData && this.state.baseViewData.szamlakibocsatobean && this.state.baseViewData.szamlakibocsatobean.nev}<br />
        <b>Szálloda: </b>{this.state.baseViewData && this.state.baseViewData.apartmanbean && this.state.baseViewData.apartmanbean.szallodanev}<br />
        <b>Épület: </b>{this.state.baseViewData && this.state.baseViewData.apartmanbean && this.state.baseViewData.apartmanbean.epuletnev}<br />
        <b>Apartman: </b>{this.state.baseViewData && this.state.baseViewData.apartmanbean && this.state.baseViewData.apartmanbean.apartmannev +
          (this.state.baseViewData.apartmanbean.kategoria && ' (' + this.state.baseViewData.apartmanbean.kategoria + ')')}<br />
        <b>Hét sorszáma: </b>{this.state.baseViewData && this.state.baseViewData.het}<br />
        <b>Páros/páratlan év: </b>{this.state.baseViewData && this.state.baseViewData.gyakorisag && this.state.baseViewData.gyakorisag.caption}<br />
        <b>Osztott díj: </b>{this.state.baseViewData && this.state.baseViewData.reszlet ? 'Igen' : 'Nem' }<br />
        <b>Szín: </b>{this.state.baseViewData && this.state.baseViewData.apartmanbean && this.state.baseViewData.apartmanbean.hetszine}<br />
        <b>Jellemző: </b>{this.state.baseViewData && this.state.baseViewData.jellemzo}<br />
        <b>Lejárati státusz: </b>{this.state.baseViewData && this.state.baseViewData.lejaratistatusz && this.state.baseViewData.lejaratistatusz.caption}<br /><br />
        <h6>Pénzügyi adatok:</h6>
        <b>Pénznem: </b>{this.state.baseViewData && this.state.baseViewData.penznem}<br />
        <b>Fizetési mód: </b>{this.state.baseViewData && this.state.baseViewData.fizmod}<br />
        <b>Számlázás nyelve: </b>{this.state.baseViewData && this.state.baseViewData.nyelv && this.state.baseViewData.nyelv.caption}<br />
        <b>Státusz: </b>{this.state.baseViewData && this.state.baseViewData.statusz && this.state.baseViewData.statusz.caption}<br />
        <b>Lezárás oka: </b>{this.state.baseViewData && this.state.baseViewData.lezarasoka && this.state.baseViewData.lezarasoka.caption}<br /><br />
        <h6>Jogtulajdonos(ok) adatai:</h6>
        <ul>
          {listItems}
        </ul>
      </div>);
  }

  renderRciAdatok = () => {
    let listItems = [];
    if (this.state.rciViewObj) {
      this.state.rciViewObj.forEach((item, index) => {
        listItems.push(this.renderRciListItem(item, index));
      });
    }
    return (
      <React.Fragment>
        <h6>
          {'Üdülési jog információ:'}
        </h6>
        <div className="row">
          {listItems}
        </div>
      </React.Fragment>
    );
  }

  renderRciListItem = (data, idx) => {
    return (
      <div className="col-md-6" key={idx.toString()}>
        <span><b>Adatátvétel dátuma: </b>{data.deponalas && data.deponalas.erkezesDatum}</span><br />
        <span><b>Deponálás: </b></span><br />
        <div style={{ marginLeft: 10 }}>
          <span><b>Fő jogtulajdonos: </b>{data.deponalas && data.deponalas.deponaloVendegNev}</span><br />
          <span><b>RCI azonosító: </b>{data.deponalas && data.deponalas.rciAzonosito}</span><br />
          <span><b>Deponálás éve: </b>{data.deponalas && data.deponalas.deponalasEv}</span><br />
          <span><b>Deponáló vendég: </b>{data.deponalas && data.deponalas.deponaloVendegNev}</span><br />
          <span><b>Deponálás vége: </b>{data.deponalas && data.deponalas.deponalasVegeDatum}</span><br />
          <span><b>Bejövő státusz: </b>{data.deponalas && data.deponalas.bejovoStatusz}</span><br />
        </div>
        <span><b>Foglalás: </b></span><br />
        <div style={{ marginLeft: 10 }}>
          <span><b>Vendég neve: </b>{data.foglalas && data.foglalas.vendegNev}</span><br />
          <span><b>További vendég neve: </b>{data.foglalas && data.foglalas.vendegNev2}</span><br />
          <span><b>Vendég email címe: </b>{data.foglalas && data.foglalas.vendegEmail}</span><br />
          <span><b>Vendég telefonszáma: </b>{data.foglalas && data.foglalas.vendegTelefon}</span><br />
          <span><b>Vendég címe: </b>{data.foglalas && data.foglalas.vendegCim}</span><br />
          <span><b>Országkód: </b>{data.foglalas && data.foglalas.vendegOrszagkod}</span><br />
          <span><b>Irányítószám: </b>{data.foglalas && data.foglalas.vendegIrszam}</span><br />
          <span><b>Városnév: </b>{data.foglalas && data.foglalas.vendegVarosnev}</span><br /><br />
        </div>
      </div>
    );
  }

  columnClassNameFormat = (fieldValue, row, rowIdx, colIdx) => {
    return row.ujrahasznosithato ? 'td-column-recepcio-ujrahaszn' : '';
  }

  childcolumnClassNameFormat = (fieldValue, row, rowIdx, colIdx) => {
    return row.fizetesimeghagyasalatt ? 'td-column-szamla-fizmeghagy' : '';
  }

  renderBaseTable = () => {
    const selectRowProp = {
      mode: 'radio',
      unselectable: this.state.disabledRows,
      onSelect: this.onRowSelect
    };

    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };
    return (
      <div className="animated fadeIn">
        <Row>
          <div className="col-md-6">
            <h5>Recepció lista</h5>
          </div>
          {this.state.showNote && (
            <div className="col-md-6" style={{textAlign: 'right'}}>
              <h6>
                {'Újrahasznosítható apartman: '}
                <i className="fa fa-square" aria-hidden="true" style={{color: '#fcbb41'}}/>
              </h6>
            </div>
          )}
        </Row>
        <BootstrapTable
          data={this.state.baseJson}
          options={options}
          selectRow={selectRowProp}
          pagination
        >
          <TableHeaderColumn
            dataField="apartman"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            columnClassName={this.columnClassNameFormat}
          >
            Apartman (HostWare)
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="jogtulajdonos"
            tdStyle={{ whiteSpace: 'pre-line' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            columnClassName={this.columnClassNameFormat}
          >
            Jogtulajdonos(ok)
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="azonosito"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            columnClassName={this.columnClassNameFormat}
          >
            Üdülési jog azonosító
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="het"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            dataAlign="center"
            width="110"
            columnClassName={this.columnClassNameFormat}
          >
            RCI hét
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="tartozas"
            tdStyle={{ whiteSpace: 'normal' }}
            dataFormat={this.tartozasFormatter}
            width="110"
            dataAlign="center"
            columnClassName={this.columnClassNameFormat}
          >
            Tartozás
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="id"
            tdStyle={{ whiteSpace: 'normal' }}
            dataFormat={this.baseTableIconFormatter}
            width="100"
            isKey
          >
            Műveletek
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }

  renderChildTable = () => {
    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };
    return (
      <div className="animated fadeIn">
        <Row>
          <div className="col-md-6">
            <h5>Számla lista</h5>
          </div>
          {this.state.showChildNote && (
            <div className="col-md-6" style={{ textAlign: 'right' }}>
              <h6>
                {'Fizetési meghagyás alatt: '}
                <i className="fa fa-square" aria-hidden="true" style={{ color: '#ff5050' }} />
              </h6>
            </div>
          )}
        </Row>
        { this.state.childJson.length ? (
          <BootstrapTable
            data={this.state.childJson}
            pagination
            options={options}
          >
            <TableHeaderColumn
              dataField="bizonylatszam"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              columnClassName={this.childcolumnClassNameFormat}
            >
              <p className="whitespace-normal">Számlaszám</p>
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="szamlafizeto"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              columnClassName={this.childcolumnClassNameFormat}
            >
              <p className="whitespace-normal">Számlafizető neve</p>
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="kelt"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              columnClassName={this.childcolumnClassNameFormat}
            >
              <p className="whitespace-normal">Számla kelte</p>
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="esedekesseg"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              columnClassName={this.childcolumnClassNameFormat}
            >
              <p className="whitespace-normal">Esedékesség</p>
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="tipus"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              columnClassName={this.childcolumnClassNameFormat}
            >
              <p className="whitespace-normal">Számlatípus</p>
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="brutto"
              tdStyle={{ whiteSpace: 'normal', textAlign: 'right' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              columnClassName={this.childcolumnClassNameFormat}
              dataFormat={this.priceFormatter}
            >
              <p className="whitespace-normal">Bruttó összeg</p>
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="puteljszamla"
              tdStyle={{ whiteSpace: 'normal', textAlign: 'right' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              columnClassName={this.childcolumnClassNameFormat}
              dataFormat={this.priceFormatter}
            >
              <p className="whitespace-normal">Pénzügyi teljesítés</p>
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="tenyltart"
              tdStyle={{ whiteSpace: 'normal', textAlign: 'right' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              dataFormat={this.priceFormatter}
              columnClassName={this.childcolumnClassNameFormat}
            >
              <p className="whitespace-normal">Tényleges tartozás</p>
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="kkamatosszegek"
              tdStyle={{ whiteSpace: 'pre-line', textAlign: 'right' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              dataFormat={this.doubePriceFormatter}
              columnClassName={this.childcolumnClassNameFormat}
            >
              <p className="whitespace-normal">Generált késedelmi kamat / Rendezett</p>
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="kkamat"
              tdStyle={{ whiteSpace: 'normal', textAlign: 'right' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              dataFormat={this.priceFormatter}
              columnClassName={this.childcolumnClassNameFormat}
            >
              <p className="whitespace-normal">Aktuális késedelmi kamat</p>
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="kapcsbizonylatszam"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              columnClassName={this.childcolumnClassNameFormat}
            >
              <p className="whitespace-normal">Kapcsolódó bizonylatszám</p>
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="id"
              hidden
              isKey
            >
              <p className="whitespace-normal">Műveletek</p>
            </TableHeaderColumn>
          </BootstrapTable>
        ) : <DataEmptyState />}
      </div>
    );
  }

  renderPrintButton = () => {
    return (
      <div>
        <Button
          color="success"
          className="icon-printer"
          style={{ marginBottom: 20 }}
        >
          {' '}
          <span className="basic-font">Heti lista</span>
        </Button>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderEloSzures()}
        {{
          QUERY: (<QueryEmptyState
            hasImage
          />),
          LOAD: (<Loader />),
          DATA: (<DataEmptyState
            hasImage
            subtitle="Próbálj más előszűrést vagy vigyél fel új adatokat!"
          />),
          NETW: (<NetworkEmptyState
            hasImage
            submitClick={() => this.updateBaseTable()}
          />),
          TABL: (this.renderBaseTable())
        }[this.state.currentStatusBaseTable]}

        {{
          HIDE: (<span />),
          LOAD: (<Loader />),
          DATA: (<DataEmptyState
            hasImage
            subtitle="Próbálj más előszűrést vagy vigyél fel új adatokat!"
          />),
          NETW: (<NetworkEmptyState
            hasImage
            submitClick={() => this.updateBaseTable()}
          />),
          TABL: (this.renderChildTable())
        }[this.state.currentStatusChildTable]}
        <Modal
          isOpen={this.state.modalBaseView}
          toggle={this.toggleBaseView}
          className={this.props.className}
          backdrop="static"
        >
          <ModalHeader>
            Megtekintés
          </ModalHeader>
          <ModalBody>
            {{
              LOAD: (<Loader />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.handleBaseViewClick()}
              />),
              LOADED: (this.renderBaseViewData())
            }[this.state.currentStatusBaseView]}
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-cursor-pointer"
              type="button"
              color="secondary"
              onClick={this.toggleBaseView}
            >
                Vissza
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modalRciNezet}
          toggle={this.toggleRciNezet}
          className={this.props.className}
          backdrop="static"
          size="lg"
        >
          <ModalHeader>
            {'RCI adatok megtekintés'}
          </ModalHeader>
          <ModalBody>
            {{
              LOAD: (<Loader />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.handleRciViewClick(this.state.currentRciId)}
              />),
              LOADED: (this.renderRciAdatok())
            }[this.state.currentStatusBaseRciView]}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleRciNezet}>
              {'Vissza'}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

RecepcioLista.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool,
};

export default RecepcioLista;
