import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledAlert
} from 'reactstrap';
// import Select from 'react-select';
import Select from 'react-select/lib/Async';
import { AvForm } from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DataEmptyState from '../../commons/dataemptystate';
import QueryEmptyState from '../../commons/queryempty';
import NetworkEmptyState from '../../commons/networkemptystate';
import Loader from '../../commons/loader';
import Services from './services';
import { DatePicker, PaginationTotal } from '../../commons/controls';
import { ProgressButton } from '../../commons/ProgressButton';
import Lib from '../../commons/lib';
import NumberFormatter from '../../commons/NumberFormatter';

class GDPRLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ['QUERY', 'LOAD', 'DATA', 'NETW', 'TABL']
      currentStatusBaseTable: 'QUERY',
      modalBaseView: false,
      eloszuresPartner: '',
      datum: moment().format('YYYY-MM-DD'),
      elevulesCheckText: '',
      elofordulasCheckTexT: '',
      elofordulasEmpty: false,
      elofordulasIds: [],
      baseJson: [],
      // [0,1,2,3]
      deleteStatus: 0,
      disabledRows: [],
      modalDelete: false
    };
  }

  componentDidMount() {
    // this.updateBaseTable();
  }

  onRowSelect = (row) => {
    this.updateChildTable(row.id);
  }

  formDatumChange = (e, v) => {
    let currentValue = v === '' ? null : v;
    this.setState({ datum: currentValue });
  }

  toggleBaseView = () => {
    this.setState({
      modalBaseView: !this.state.modalBaseView
    });
  }

  toggleDelete = () => {
    this.setState({
      modalDelete: !this.state.modalDelete,
      deleteStatus: 0,
      elofordulasEmpty: false,
      elofordulasIds: []
    });
  }

  toggleDeleteAndReload = () => {
    this.setState({
      modalDelete: !this.state.modalDelete,
      deleteStatus: 0,
      elofordulasEmpty: false,
      elofordulasIds: []
    }, () => {
      this.updateBaseTable();
    });
  }

  emptyOrNull = (data) => {
    if (!data || data === '') return null;
    return data;
  }

  updateBaseTable = () => {
    let partnerId = this.emptyOrNull(this.state.eloszuresPartner.id);
    this.setState({
      currentStatusBaseTable: 'LOAD',
    });
    Services.getGDPRList(partnerId, this.state.datum, (err, res) => {
      if (err) {
        this.setState({
          currentStatusBaseTable: 'QUERY'
        });
      } else {
        let data = [];
        let rows = res[0];
        let disabledRows = [];
        let now = moment().format('YYYY-MM-DD');
        rows.forEach((item, idx) => {
          if (item.e === null || item.l > now) {
            disabledRows.push(item.id);
          }
          item.idx = idx;
          data.push(item);
        });
        this.setState({
          baseJson: data,
          disabledRows: disabledRows,
          currentStatusBaseTable: res[0].length > 0 ? 'TABL' : 'DATA'
        }, () => { this.forceUpdate(); });
      }
    });
  }

  handleChangePartner = (value) => {
    if (value && value.length !== 0) {
      this.setState({ eloszuresPartner: value });
    } else {
      this.setState({ eloszuresPartner: '' });
    }
  }

  loadPartnerChange = (input, callback) => {
    if (input.length !== 0) {
      Services.getPartner(input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            let newItem = item;
            newItem.nev += (newItem.cim ? ' (' + newItem.cim + ') ' : '');
            if (newItem.adoszam) {
              newItem.nev += '[' + NumberFormatter.addHyphensToString(newItem.adoszam) + '] ';
            }
            newArray.push(newItem);
          });
          callback(newArray);
        }
      });
    }
  }

  renderEloSzures = () => {
    return (
      <AvForm style={{ marginBottom: 20 }}>
        <div className="row">
          <div className="col-md-4">
            <Label for="jogtulaj">
              {'Partner:'}
            </Label>
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="jogtulaj"
              id="jogtulaj"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangePartner(v)}
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadPartnerChange, 1000)}
              isLoading={false}
              getOptionLabel={option => option.nev}
              getOptionValue={option => option.id}
              value={this.state.eloszuresPartner}
              cache={false}
              autoload={false}
            />
          </div>
          <div className="col-md-3">
            <Label for="datum">
              {'Elévülés dátuma:'}
            </Label>
            <DatePicker
              required
              placeholder="éééé-hh-nn"
              onChange={this.formDatumChange}
              value={this.state.datum && new Date(this.state.datum)}
              type="select"
              name="datum"
            />
          </div>
          <div className="col-md-4" style={{ marginTop: '29px' }}>
            <Button
              color="primary"
              onClick={() => { this.updateBaseTable(); }}
            >
              {'Lekérdezés'}
            </Button>
          </div>
        </div>
      </AvForm>
    );
  }

  delete = () => {
    let result = [];
    if (this.lista && this.lista.state && this.lista.state.selectedRowKeys && this.lista.state.selectedRowKeys.length > 0) {
      let rowKeys = this.lista.state.selectedRowKeys;
      let cloneData = JSON.parse(JSON.stringify(this.state.baseJson));
      cloneData.forEach((item) => {
        rowKeys.forEach((item2) => {
          if (item2 === item.id) {
            let nextItem = JSON.parse(JSON.stringify(item));
            nextItem.f = nextItem.d;
            result.push(nextItem);
          }
        });
      });
    }

    Services.checkElevules(result, (err, res) => {
      if (!err) {
        this.toggleDelete();
        this.setState({
          elevulesCheckText: res[0].result,
          elofordulasIds: result
        });
      }
    });
  }

  onSelectAll = (isSelected) => {
    if (isSelected) {
      if (this.lista.store.filteredData) {
        return this.lista.store.filteredData.map((row) => {
          if (row.e !== null && !this.state.disabledRows.includes(row.id)) {
            return row.id;
          }
          return null;
        });
      }
      return this.state.baseJson.map((row) => {
        if (row.e !== null && !this.state.disabledRows.includes(row.id)) {
          return row.id;
        }
        return null;
      });
    }
    return [];
  }

  rowClassNameFormat = (row) => {
    return row.l <= moment().format('YYYY-MM-DD') ? 'td-column-gdpr-outdated' : '';
  }

  renderBaseTable = () => {
    const selectRowProp = {
      unselectable: this.state.disabledRows,
      onSelectAll: this.onSelectAll,
      mode: 'checkbox',
      hideSelectColumn: this.state.disabledRows.length === this.state.baseJson.length || !Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'GDPR_NAPLO', 'KARB')
    };

    const options = {
      sizePerPage: 10,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };
    return (
      <div className="animated fadeIn">
        <h5>
          {'GDPR lista'}
        </h5>
        <BootstrapTable
          ref={((c) => { this.lista = c; })}
          data={this.state.baseJson}
          options={options}
          selectRow={selectRowProp}
          trClassName={this.rowClassNameFormat}
          pagination
        >
          <TableHeaderColumn
            dataField="f"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          >
            Funkció
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="a"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          >
            Azonosító
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="p"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            dataAlign="center"
          >
            Partner neve
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="d"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            dataAlign="center"
            width="140"
          >
            Funkció dátum
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="e"
            tdStyle={{ whiteSpace: 'pre-line' }}
            dataAlign="center"
            width="130"
          >
            Elévülési idő (Év)
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="l"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            dataAlign="center"
            width="140"
          >
            Elévülés dátuma
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="id"
            tdStyle={{ whiteSpace: 'normal' }}
            width="100"
            hidden
            isKey
          >
            Műveletek
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }

  getProgressClassName = (currentPos) => {
    let className = '';
    if (currentPos === this.state.deleteStatus) {
      if (currentPos === 1 || currentPos === 3) {
        className = 'nav-item active';
      } else {
        className = 'nav-item current';
      }
    } else if (currentPos < this.state.deleteStatus) {
      className = 'nav-item active';
    } else {
      className = 'nav-item';
    }
    return className;
  }

  renderProgressState = () => {
    return (
      <div>
        {{
          0: (
            <UncontrolledAlert color="warning" className="animated fadeIn" toggle={null} style={{ marginTop: 20, whiteSpace: 'pre-wrap' }}>
              {this.state.elevulesCheckText}
            </UncontrolledAlert>),
          1: (
            <UncontrolledAlert color="success" className="animated fadeIn" toggle={null} style={{ marginTop: 20, whiteSpace: 'pre-wrap' }}>
              Napló törölve! Tovább lép?
            </UncontrolledAlert>),
          2: (
            <UncontrolledAlert color="info" className="animated fadeIn" toggle={null} style={{ marginTop: 20, whiteSpace: 'pre-wrap' }}>
              {this.state.elofordulasCheckTexT}
            </UncontrolledAlert>),
          3: (
            <UncontrolledAlert color="success" className="animated fadeIn" toggle={null} style={{ marginTop: 20, whiteSpace: 'pre-wrap' }}>
              Partner törölve!
            </UncontrolledAlert>),
        }[this.state.deleteStatus]}
      </div>
    );
  }

  nextProgress = () => {
    let state = this.state.deleteStatus;
    if (state < 3) {
      if (state === 0) {
        let array = JSON.parse(JSON.stringify(this.state.elofordulasIds));
        let ids = [];
        array.forEach((item) => {
          ids.push([item.id, item.idp]);
        });
        Services.deleteBySelect(ids, (err, res) => {
          if (!err) {
            state = 2;
            this.setState({
              deleteStatus: 2,
              elofordulasCheckTexT: res[0].result,
            });
          }
        });
      } else if (state === 2) {
        state += 1;
        this.setState({
          deleteStatus: state,
        });
      }
    }
  }

  render() {
    return (
      <div>
        {this.renderEloSzures()}
        {{
          QUERY: (<QueryEmptyState
            hasImage
          />),
          LOAD: (<Loader />),
          DATA: (<DataEmptyState
            hasImage
            subtitle="Próbálj más előszűrést vagy vigyél fel új adatokat!"
          />),
          NETW: (<NetworkEmptyState
            hasImage
            submitClick={() => this.updateBaseTable()}
          />),
          TABL: (this.renderBaseTable())
        }[this.state.currentStatusBaseTable]}
        { Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'GDPR_NAPLO', 'KARB') && (
          <div>
            <ProgressButton
              type="button"
              className="pull-left"
              color="danger"
              onClick={this.delete}
              loading={this.state.torlesButtonLoading}
              disabled={this.state.disabledRows.length === this.state.baseJson.length}
            >
              {'Törlés'}
            </ProgressButton>
          </div>
        )}
        <Modal
          isOpen={this.state.modalDelete}
          toggle={this.toggleDelete}
          className={this.props.className}
          backdrop="static"
          size="GDPR"
        >
          <ModalHeader>
            Törlés folyamat
          </ModalHeader>
          <ModalBody>
            <div id="smartwizard" className="sw-main sw-theme-arrows">
              <ul className="nav nav-tabs step-anchor">
                <li className={this.getProgressClassName(0)}>
                  <a>
                    Elévülés
                    <br />
                    <small>
                      Vizsgálat
                    </small>
                  </a>
                </li>
                <li className={this.getProgressClassName(1)}>
                  <a>
                    Napló
                    <br />
                    <small>
                      Törlés/Anonimizálás
                    </small>
                  </a>
                </li>
                <li className={this.getProgressClassName(2)}>
                  <a>
                    Előfordulás
                    <br />
                    <small>
                      Vizsgálat
                    </small>
                  </a>
                </li>
                {/* <li className={this.getProgressClassName(3)}>
                  <a>
                    Partner
                    <br />
                    <small>
                      Törlés
                    </small>
                  </a>
                </li> */}
              </ul>
              <div />
            </div>
            <div>
              {this.renderProgressState()}
            </div>
          </ModalBody>
          <ModalFooter>
            { ((this.state.deleteStatus < 2) && (!this.state.elofordulasEmpty)) ? (
              <Button
                className="btn-cursor-pointer"
                type="button"
                color="secondary"
                onClick={this.toggleDeleteAndReload}
              >
                  Nem
              </Button>
            ) : <span />}
            { ((this.state.deleteStatus < 2) && (!this.state.elofordulasEmpty)) ? (
              <Button
                className="btn-cursor-pointer"
                type="button"
                color="primary"
                onClick={this.nextProgress}
              >
                Igen
              </Button>
            ) : (
              <Button
                className="btn-cursor-pointer"
                type="button"
                color="secondary"
                onClick={this.toggleDeleteAndReload}
              >
                Bezárás
              </Button>
            )}
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

GDPRLista.propTypes = {
  className: PropTypes.string
};

export default GDPRLista;
