const tooltips = {
  keszlet: 'A menüpontban elérhető felületek szolgálnak a felhasználó készleteinek nyilvántartására. A felületek egyszerre rögzítő és lekérdező felületek a modulban.\nA készletek nyilvántartásához szükséges törzsállományok: cikk, raktár, partner, bizonylatkezelés, cégadat.\nKészletnyilvántartás cikkenként és raktáranként tartja nyilván és szolgáltat információt a felhasználó készleteiről. A készletnyilvántartásban megkülönböztetünk szabad és foglalt készleteket. Foglalt készletek ténylegesen még a raktári készlet részét képezik, csak a rendszer valamely funkciója foglalta le egy későbbi időpontban történő kiadáshoz.',
  keszletnovekedes: 'Raktári készletek bevételezésének lekérdező és rögzítő felülete. A bevételezés lehet Induló készlet vagy sima bevételezés. A rögzített bizonylaton lévő készletek a készletmennyiségben a mentéssel még nem kerül kimutatásra, az majd csak a bizonylat véglegesítése után valósul meg. Ezután a bizonylat már nem szerkeszthető, csak stornózható.',
  keszletcsokkenes: 'Raktári készletek kiadásának lekérdező és rögzítő felülete. A kiadás lehet felhasználás vagy értékesítés. A rögzített bizonylaton lévő készletek a készletmennyiségben a mentéssel foglalásra kerülnek, foglalt készletként jelennek meg, de tényleges készletcsökkenés csak a véglegezéssel történik meg. A készletcsökkenéseket a FIFO elv szerint árazzuk.',
  forgalom: 'A felület szolgál a készletforgalom különböző szempontok szerint paraméterezett lekérdezésére. Az adatok a megadott paraméterekre szűrten kerülnek megjelenítésre.',
  leltar: 'Teljes körű leltározás folyamatokat támogató funkciók érhetők el a felületen:\n \u{00A0}\u{00A0}\u{00A0} - leltárívek generálása \n \u{00A0}\u{00A0}\u{00A0} - rögzítő felület a leltározáshoz \n \u{00A0}\u{00A0}\u{00A0} - jegyzőkönyv nyomtatás \n \u{00A0}\u{00A0}\u{00A0} - véglegezés után a leltárkülönbözetek átvezetése (többlet, hiány \n \u{00A0}\u{00A0}\u{00A0}\u{00A0}\u{00A0} rögzítéséhez bizonylat generálás) automatikusan elvégezhető a korrekció \n \u{00A0}\u{00A0}\u{00A0}\u{00A0}\u{00A0} funkció segítségével.\nA leltározás lehet teljes, vagy részleges, melyet a felhasználó a leltárívek rögzítése során tud szabályozni. Leltárívekre kiválaszthatók cikkek egyenként, cikkcsoport választásával a cikkcsoporthoz tartozó cikkek, vagy az összes cikk.\nA leltár lehet tükör leltár (leltározó látja a raktári készlet mennyiségeket), illetve vak leltár (leltár során a tényleges készlet nem látható).',
  keszletinformacio: 'A felhasználó ezen a felületen tudja lekérdezni a készletben rögzített adatok alapján az aktuális készlet mennyiségét raktáranként, cikkenként. A táblázat tartalmazza a teljes készletet és az abból foglalt készletet is.',
};

export default function getTooltip(name) {
  return tooltips[name];
}
