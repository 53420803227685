import React, { useState, useEffect } from "react";
import {
  App,
  Toolbar,
  ToolbarGroup,
  ToolbarItem,
  Editor,
} from "@organw/wysiwyg-editor";

const EditorEmail  = props => {

  const {
    text,
    setText,
  } = props;


  const onEditorTartalomChange = ({value}) => {
    setText(value);
  }

  return (
    <div className="row">
      <div className="col-md-12">
        <App fontId="Editor1" value={text} onChange={onEditorTartalomChange}   gallery={{ listImages: () => {}, addImage: () => {} }} style={{ overflow: "scroll" }}>
          <Toolbar style={{ position: 'relative', padding: '0px' }}>
            <ToolbarGroup>
              <ToolbarItem type="mark" name="bold" id="bold" tooltip="Félkövér">
                <i className="fa fa-bold" aria-hidden="true" />
              </ToolbarItem>
              <ToolbarItem type="mark" name="italic" tooltip="Dőlt">
                <i className="fa fa-italic" aria-hidden="true" />
              </ToolbarItem>
              <ToolbarItem type="mark" name="underline" tooltip="Aláhúzott">
                <i className="fa fa-underline" aria-hidden="true" />
              </ToolbarItem>
              <ToolbarItem type="fontsize" tag="fontsize" name="fontsize" tooltip="Betűméret">
                Betűméret&nbsp;
              </ToolbarItem>
              <ToolbarItem type="align" name="align-left" tooltip="Balra igazítás">
                <i className="fa fa-align-left" aria-hidden="true" />
              </ToolbarItem>
              <ToolbarItem type="align" name="align-center" tooltip="Középre igazítás">
                <i className="fa fa-align-center" aria-hidden="true" />
              </ToolbarItem>
              <ToolbarItem type="align" name="align-right" tooltip="Jobbra igazítás">
                <i className="fa fa-align-right" aria-hidden="true" />
              </ToolbarItem>
              <ToolbarItem type="block" name="heading-one" tooltip="Címsor 1">
                <span>H1</span>
              </ToolbarItem>
              <ToolbarItem type="block" name="heading-two" tooltip="Címsor 2">
                <span>H2</span>
              </ToolbarItem>
              <ToolbarItem type="block" name="heading-three" tooltip="Címsor 3">
                <span>H3</span>
              </ToolbarItem>
              <ToolbarItem type="link" tag="a" name="link" tooltip="Link">
                <i className="fa fa-link" aria-hidden="true" />
              </ToolbarItem>
            </ToolbarGroup>
          </Toolbar>
          <Editor/>
        </App>
      </div>
    </div>
  );
}

export default EditorEmail;