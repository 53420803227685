import React from 'react';
import PropTypes from 'prop-types';
import VizsgaloContent from './VizsgaloContent';

const cardStyle = {
  padding: 20
};

const VizsgaloBase = (props) => {
  return (
    <div>
      <div className="animated fadeIn">
        <div className="card">
          <div className="card-header">
            {'Vizsgáló személy'}
          </div>
          <div className="row" style={cardStyle}>
            <div className="col-md-12">
              <VizsgaloContent globals={props.route.globals} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

VizsgaloBase.propTypes = {
  route: PropTypes.object,
  globals: PropTypes.object
};

export default VizsgaloBase;
