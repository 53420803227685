import React, { useState, useEffect } from 'react';
import {
  TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import classnames from 'classnames';
import Lib from '../../commons/lib';
import SzallitoiContent from './Szallitoi/SzallitoiContent';
import AjanlatContent from './Vevoi/AjanlatContent';
import RendelesContent from './Vevoi/RendelesContent';
import BeallitasContent from './Beallitas/BeallitasContent';
import CustomTooltip from '../../components/Tooltip/CustomTooltip';
import getTooltip from './RendelesTooltips';

const cardStyle = { padding: 10 };
const RendelesBase = (props) => {
  const { location, route } = props;

  const [tab, setTab] = useState('1');

  useEffect(() => {
    if (location.query.page) {
      switch (location.query.page) {
        case '3':
          setTab('3');
          break;
        default:
          setTab('1');
      }
    } else if (Lib.KeyCloak.hasRole(route.globals.keycloak, ['RENDELES_AJANLAT'], true)) {
      setTab('1');
    } else if (Lib.KeyCloak.hasRole(route.globals.keycloak, ['RENDELES_VEVOI'], true)) {
      setTab('2');
    } else if (Lib.KeyCloak.hasRole(route.globals.keycloak, ['RENDELES_SZALLITOI'], true)) {
      setTab('3');
    } else if (Lib.KeyCloak.hasRoleSpec(route.globals.keycloak, ['RENDELES_BEALLITAS'])) {
      setTab('4');
    }
  }, []);

  return (
    <div className="animated fadeIn">
      <div className="card">
        <div className="card-header">
          Rendelés
          <CustomTooltip text={getTooltip('rendelesek')} id="rendelesekTT" />
          {/* <YouTube data={videoData} /> */}
        </div>
        <div style={cardStyle}>
          <div className="col-md-12">
            {tab && (
              <React.Fragment>
                <Nav tabs>
                  {Lib.KeyCloak.hasRole(route.globals.keycloak, [
                    'RENDELES_AJANLAT'
                  ], true) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: tab === '1' })}
                        onClick={() => setTab('1')}
                      >
                        Vevői ajánlat
                        <CustomTooltip text={getTooltip('ajanlat')} id="ajanlatTT" />
                      </NavLink>
                    </NavItem>
                  )}
                  {Lib.KeyCloak.hasRole(route.globals.keycloak, [
                    'RENDELES_VEVOI'
                  ], true) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: tab === '2' })}
                        onClick={() => setTab('2')}
                      >
                        Vevői rendelés
                        <CustomTooltip text={getTooltip('rendeles')} id="rendelesTT" />
                      </NavLink>
                    </NavItem>
                  )}
                  {Lib.KeyCloak.hasRole(route.globals.keycloak, [
                    'RENDELES_SZALLITOI'
                  ], true) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: tab === '3' })}
                        onClick={() => setTab('3')}
                      >
                        Szállítói rendelés
                        <CustomTooltip text={getTooltip('szallitoi')} id="szallitoiTT" />
                      </NavLink>
                    </NavItem>
                  )}
                  {Lib.KeyCloak.hasRoleSpec(route.globals.keycloak, [
                    'RENDELES_BEALLITAS'
                  ]) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: tab === '4' })}
                        onClick={() => setTab('4')}
                      >
                        Beállítások
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
                <TabContent activeTab={tab}>
                  {Lib.KeyCloak.hasRole(route.globals.keycloak, [
                    'RENDELES_AJANLAT'
                  ], true) && (
                    <TabPane tabId="1">
                      <AjanlatContent {...props} active={tab === '1'} />
                    </TabPane>
                  )}
                  {Lib.KeyCloak.hasRole(route.globals.keycloak, [
                    'RENDELES_VEVOI'
                  ], true) && (
                    <TabPane tabId="2">
                      <RendelesContent {...props} active={tab === '2'} />
                    </TabPane>
                  )}
                  {Lib.KeyCloak.hasRole(route.globals.keycloak, [
                    'RENDELES_SZALLITOI'
                  ], true) && (
                    <TabPane tabId="3">
                      <SzallitoiContent {...props} active={tab === '3'} />
                    </TabPane>
                  )}
                  {Lib.KeyCloak.hasRoleSpec(route.globals.keycloak, [
                    'RENDELES_BEALLITAS'
                  ]) && (
                    <TabPane tabId="4">
                      <BeallitasContent {...props} active={tab === '4'} />
                    </TabPane>
                  )}
                </TabContent>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RendelesBase;
