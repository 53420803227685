import Lib from '../../../commons/lib.js';

export default class Service {
  static apartmanUrl = window.location.origin + '/api/apartman/';
  static partnerUrl = window.location.origin + '/api/partner/';
  static telepulesUrl = window.location.origin + '/api/telepules/';

  // APARTMAN START

  static listApartman(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'apartman/list',
      args: { requestType: 'apartman-list' }
    }], fnDone);
  }

  static getApartman(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'apartman',
      args: { requestType: 'apartman-get' },
      params: { id: id },
    }], fnDone);
  }

  static createApartman(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.apartmanUrl + 'apartman',
      args: { requestType: 'apartman-create' },
      data: data,
    }], fnDone);
  }

  static updateApartman(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.apartmanUrl + 'apartman',
      args: { requestType: 'apartman-update' },
      data: data,
    }], fnDone);
  }

  static deleteApartman(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.apartmanUrl + 'apartman',
      args: { requestType: 'apartman-delete' },
      params: { id: id },
    }], fnDone);
  }

  static listApartmanEnums(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'apartman/enum',
      args: { requestType: 'apartman-enum-list' }
    }], fnDone);
  }

  static listSzamlazoFenntarto(type, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'szamlazofenntarto-list' },
      params: {
        type: type,
      },
    }], fnDone);
  }

  static getSzabadHetek(id, het, ev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'apartman/szabadApartmanok',
      args: { requestType: 'apartman-get' },
      params: {
        apartman: id,
        het,
        ev
      },
    }], fnDone);
  }

  // APARTMAN END

  // ÉPÜLET START

  static listEpulet(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'epulet/list',
      args: { requestType: 'epulet-list' }
    }], fnDone);
  }

  static getEpulet(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'epulet',
      args: { requestType: 'epulet-get' },
      params: { id: id },
    }], fnDone);
  }

  static createEpulet(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.apartmanUrl + 'epulet',
      args: { requestType: 'epulet-create' },
      data: data,
    }], fnDone);
  }

  static updateEpulet(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.apartmanUrl + 'epulet',
      args: { requestType: 'epulet-update' },
      data: data,
    }], fnDone);
  }

  static deleteEpulet(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.apartmanUrl + 'epulet',
      args: { requestType: 'epulet-delete' },
      params: { id: id },
    }], fnDone);
  }

  static listEpuletEnums(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'epulet/enum',
      args: { requestType: 'epulet-enum-list' }
    }], fnDone);
  }

  static listAktivEpuletek(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'epulet/aktiv',
      args: { requestType: 'epulet-aktiv-list' },
      params: { id: id },
    }], fnDone);
  }

  // ÉPÜLET END

  // SZÁLLODA START

  static listSzalloda(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'szalloda/list',
      args: { requestType: 'szalloda-list' }
    }], fnDone);
  }

  static listSzallodaHet(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'szallodahet',
      args: { requestType: 'szallodahet-list' },
      params: { id: id },
    }], fnDone);
  }

  static listSzallodaEnums(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'szalloda/enum',
      args: { requestType: 'szalloda-enum-list' }
    }], fnDone);
  }

  static getSzalloda(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'szalloda',
      args: { requestType: 'szalloda-get' },
      params: { id: id },
    }], fnDone);
  }

  static createSzalloda(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.apartmanUrl + 'szalloda',
      args: { requestType: 'szalloda-create' },
      data: data,
    }], fnDone);
  }

  static updateSzalloda(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.apartmanUrl + 'szalloda',
      args: { requestType: 'szalloda-update' },
      data: data,
    }], fnDone);
  }

  static updateHetek(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.apartmanUrl + 'szallodahet',
      args: { requestType: 'szallodahet-update' },
      data: data,
    }], fnDone);
  }

  static deleteSzalloda(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.apartmanUrl + 'szalloda',
      args: { requestType: 'szalloda-delete' },
      params: { id: id },
    }], fnDone);
  }

  static listTelepules(iranyitoszam, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'telepules',
      args: { requestType: 'telepules-list' },
      params: { iranyitoszam: iranyitoszam },
    }], fnDone);
  }

  static listOrszag(name, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'orszag/list',
      args: { requestType: 'telepules-orszag-list' },
      params: { name: name },
    }], fnDone);
  }

  static getOrszag(kod, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'orszag/kod',
      args: { requestType: 'telepules-orszag-get' },
      params: { kod: kod },
    }], fnDone);
  }

  static listAktivSzallodak(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'szalloda/aktiv',
      args: { requestType: 'szalloda-aktiv-list' },
    }], fnDone);
  }

  // SZÁLLODA END
}
