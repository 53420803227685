import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import moment from 'moment';
import {
  AvGroup,
  AvInput,
  AvFeedback,
  AvForm,
  AvField
} from 'availity-reactstrap-validation';
import _ from 'lodash';
import { browserHistory } from 'react-router';
// import Select from 'react-select';
import Select from 'react-select/lib/Async';
import update from 'immutability-helper';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import MomentTimezone from 'moment-timezone';
import SwichButton from '../../commons/SwitchButton';
import NetworkEmptyState from '../../commons/networkemptystate';
import { DatePicker } from '../../commons/controls';
import { DateTimePicker } from 'react-widgets';
import Loader from '../../commons/loader';
import Services from './services';
import Mezo from './Mezo';
import EllenorzesiListaRow from './EllenorzesiListaRow';
import { ProgressButton } from '../../commons/ProgressButton';
import CustomTooltip from '../../components/Tooltip/CustomTooltip';
import getTooltip from './FeladatlistaTooltips';

momentLocalizer(Moment);

class FeladatlistaForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // ['FEL', 'MOD']
      formType: null,
      feladatListaId: '',
      saveButtonLoading: false,
      // FORM VALUES
      formPartner: '',
      formKapcsolattartoNev: '',
      formKapcsolattartoElerh: '',

      cimSwitchActive: true,
      formOrszag: '',
      formKereses: '',
      formIranyitoszamNev: '',
      formTelepulesNev: '',
      formKozterulet: '',
      formKozteruletjelleg: '',
      formHazszam: '',
      formHrsz: '',
      formEpulet: '',
      formLepcsohaz: '',
      formEmelet: '',
      formAjto: '',

      formKapcsolodoAzonosito: '',
      formAzonosito: '',
      formFeladatTipus: '',
      formPrioritas: '',
      formAllapot: '',
      formFunkcio: '',

      formFeladatLeiras: '',

      formLetrehozva: moment().format('YYYY-MM-DD'),
      formEsedekesseg: null,
      formIsmetlodo: '',
      formIsmetlesDatum: null,

      formMezoNev: '',
      formMezoErtek: '',
      formMetaAdatJson: [],

      formEllenorzesiLista: [],

      // MODAL UTILS
      // modalKiegeszitoAdatok: false,
      // STATUS UTILS
      // ['LOAD', 'DATA', 'NETW']
      tipusCardStatus: 'DATA',

      // INIT
      // OPTIONS
      feladatTipusRaw: [],
      allapotOptions: [],
      feladatTipusOptions: [],
      prioritasOptions: [],
    };

    this.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  componentDidMount() {
    if (this.props.route.type === 'MOD') {
      let params = new URL(window.location.href).searchParams;
      let id = params.get('id');
      // this.initForm(id);
      this.setState({
        formType: this.props.route.type,
        feladatListaId: id,
      }, () => {
        this.initForm(id);
      });
    } else if (this.props.route.type === 'FEL') {
      this.setState({
        formType: this.props.route.type,
      }, () => {
        this.getData(null, true, false);
      });
    } else if (this.props.route.type === 'MAS') {
      let params = new URL(window.location.href).searchParams;
      let id = params.get('id');
      // this.initForm(id);
      this.setState({
        formType: this.props.route.type,
        feladatListaId: id,
      }, () => {
        this.getData(id, false, true);
      });
    }
    // if (this.props.route.type === 'FEL') {
    // }
  }

  dateToTimezone = (date) => {
    if (!date || date === '') return null;
    return MomentTimezone.tz(date, this.tz).format('YYYY-MM-DDT00:00:00.000ZZ');
  }

  timezoneToDate = (date) => {
    if (!date || date === '') {
      return null;
    }

    return MomentTimezone.tz(date, this.tz).format('YYYY-MM-DD HH:mm');
  }

  timezoneToDateYear = (date) => {
    if (!date || date === '') {
      return null;
    }

    return MomentTimezone.tz(date, this.tz).format('YYYY-MM-DD');
  }

  getData = (id, isFelvitel, isCopy) => {
    this.setState({
      tipusCardStatus: 'LOAD',
      feladatListaId: id
    });
    Promise.all([
      // this.getFeladatLista(id, isFelvitel),
      this.initForm(id, isFelvitel, isCopy)
    ])
      .then(() => {
        this.setState({
          tipusCardStatus: 'DATA'
        });
      })
      .catch(() => {
        this.setState({
          tipusCardStatus: 'NETW'
        });
      });
  }

  initForm = (id, isFelvitel, isCopy) => {
    return new Promise((resolve, reject) => {
      Services.initForm(id, (err, res) => {
        if (!err) {
          let ids = [];
          let feladatTipusArray = res[0].tipusallapot.map((e) => {
            if (!ids.includes(e.tipusid)) {
              ids.push(e.tipusid);
              return <option key={e.tipusid} value={e.tipusid}>{e.tipusnev}</option>;
            }
          });
          let prioritasArray = res[0].prioritas.map(e => <option key={e.name} value={JSON.stringify({ name: e.name, caption: e.caption })} className={'text-' + e.color}>{e.caption}</option>);
          this.setState({
            feladatTipusRaw: res[0].tipusallapot,
            feladatTipusOptions: feladatTipusArray,
            prioritasOptions: prioritasArray,
            // formAllapot: res[0].allapot,
            formIsmetlodo: true,
          }, () => {
            if (isFelvitel) {
              resolve();
            } else {
              this.getFeladat(id, isCopy, resolve, reject, res[0].elllista, res[0].feladat.tipusid, res[0].feladat.cim);
            }
          });
          // resolve(res[0]);
        } else {
          reject(new Error('Hálozati hiba!'));
        }
      });
    });
  }

  getFeladat = (id, isCopy, resolve, reject, options, tipusid, cim) => {
    Services.getFeladat(id, isCopy, (err, res) => {
      let ellista = [];
      /* res[0].feladatcikk.forEach((item) => {
        let ellistaObj = {};
        let opts = options.filter(x => x.cikkid === item.cikkid);
        ellistaObj.aktiv = !(item.cikkbean && item.cikkbean.kid);
        ellistaObj.cikkValue = item.cikkbean;
        let listaValue = [];
        item.elllistak.forEach((listaItem) => {
          listaValue.push({
            id: listaItem.elllistaid,
            nev: listaItem.nev
          });
        });
        ellistaObj.listaValue = listaValue;
        ellistaObj.listaOptions = opts;
        ellista.push(ellistaObj);
      }); */

      let prioritas = res[0].prioritas;
      delete prioritas.color;

      let transformedMetaadat = [];
      res[0].metaadat.forEach((metaadat) => {
        let obj = {};
        obj[metaadat[0]] = metaadat[1];
        transformedMetaadat.push(obj);
      });

      if (!err) {
        this.setState({
          formPartner: {
            id: this.nullToString(res[0].partner && res[0].partner.id),
            nev: this.nullToString(res[0].partner && res[0].partner.nev) + ' (' + this.nullToString(res[0].partner && res[0].partner.azon) + ')',
          },
          formKapcsolattartoNev: this.nullToString(res[0].kapcsolattartonev),
          formKapcsolattartoElerh: this.nullToString(res[0].kapcsolattartoelerhetoseg),

          cimSwitchActive: true,
          formOrszag: {
            keresheto: this.nullToString(cim && cim.keresheto),
            nev: this.nullToString(cim && cim.orszag),
            orszagid: this.nullToString(cim && cim.orszagid),
          },
          formKereses: {
            iranyitoszam: this.nullToString(cim && cim.irsz),
            irszid: this.nullToString(cim && cim.irszid),
            nev: this.nullToString(cim && cim.telepules),
            telepulesid: this.nullToString(cim && cim.telepulesid),
            telepulesnev: this.nullToString(cim && cim.telepules + ' (' + cim.irsz + ')'),
          },
          formTelepulesNev: this.nullToString(cim && cim.telepules),
          formIranyitoszamNev: this.nullToString(cim && cim.irsz),
          formKozterulet: this.nullToString(cim && cim.kozterulet),
          formKozteruletjelleg: this.nullToString(cim && cim.kozteruletjelleg),
          formHazszam: this.nullToString(cim && cim.hazszam),
          formHrsz: this.nullToString(cim && cim.hrsz),
          formEpulet: this.nullToString(cim && cim.epulet),
          formLepcsohaz: this.nullToString(cim && cim.lepcsohaz),
          formEmelet: this.nullToString(cim && cim.emelet),
          formAjto: this.nullToString(cim && cim.ajto),

          formKapcsolodoAzonosito: this.nullToString(res[0].kapcsolodoazonosito),
          formFunkcio: this.nullToString(res[0].funkcio),
          formAzonosito: this.nullToString(res[0].azonosito),
          formFeladatTipus: this.nullToString(tipusid),
          formPrioritas: this.nullToString(JSON.stringify(prioritas)),
          formAllapot: this.nullToString(res[0].allapot.caption),
          formFeladatLeiras: this.nullToString(res[0].leiras),

          formLetrehozva: this.timezoneToDate(res[0].rogzido),
          formEsedekesseg: this.timezoneToDate(res[0].esedekesseg),
          formIsmetlodo: this.nullToBoolean(res[0].ismetlodo),
          formIsmetlesDatum: this.emptyOrNull(res[0].ismetlesdatum),

          formEllenorzesiLista: ellista,

          formMetaAdatJson: transformedMetaadat,
        });
        resolve(res[0]);
      } else {
        reject(new Error('Hálozati hiba!'));
      }
    });
  }

  saveFormBack = () => {
    const {
      feladatListaId,
      formPartner,
      formKapcsolattartoNev,
      formKapcsolattartoElerh,
      cimSwitchActive,
      formOrszag,
      formKereses,
      formTelepulesNev,
      formKozterulet,
      formKozteruletjelleg,
      formHrsz,
      formHazszam,
      formEpulet,
      formLepcsohaz,
      formEmelet,
      formAjto,
      formFeladatTipus,
      formPrioritas,
      formFeladatLeiras,
      formKapcsolodoAzonosito,
      formLetrehozva,
      formEsedekesseg,
      formIsmetlodo,
      formIsmetlesDatum,
      formMetaAdatJson,
      formEllenorzesiLista,
      formIranyitoszamNev,
      formType,
      formAllapot,
    } = this.state;

    this.setState({
      saveButtonLoading: true
    });

    if (formType === 'FEL' || formType === 'MAS') {
      let submitObj = {};
      let feladatcikklist = [];
      JSON.parse(JSON.stringify(formEllenorzesiLista)).forEach((item) => {
        let feladatcikk = {};
        let feladatcikkids = [];
        item.listaValue.forEach((listitem) => {
          feladatcikkids.push(listitem.id);
        });
        feladatcikk.cikkid = item.cikkValue.cid;
        feladatcikk.karbcikkid = item.cikkValue.kid;
        feladatcikk.elllistaids = feladatcikkids;
        feladatcikklist.push(feladatcikk);
      });

      submitObj.partnertortenetid = this.emptyOrNull(formPartner.id);
      submitObj.kapcsolattartonev = this.emptyOrNull(formKapcsolattartoNev);
      submitObj.kapcsolattartoelerhetoseg = this.emptyOrNull(formKapcsolattartoElerh);
      submitObj.elterohelyszin = this.emptyOrNull(cimSwitchActive);

      submitObj.cim = {};
      submitObj.cim.orszagid = this.emptyOrNull(formOrszag && formOrszag.orszagid);
      submitObj.cim.irszid = this.emptyOrNull(formKereses && formKereses.irszid);
      submitObj.cim.irsz = this.emptyOrNull(formIranyitoszamNev);
      submitObj.cim.telepulesid = this.emptyOrNull(formKereses && formKereses.telepulesid);
      submitObj.cim.telepules = this.emptyOrNull(formTelepulesNev);
      submitObj.cim.postafiok = null; // TODO: Partner címéből
      submitObj.cim.kozterulet = this.emptyOrNull(formKozterulet);
      submitObj.cim.kozteruletjelleg = this.emptyOrNull(formKozteruletjelleg);
      submitObj.cim.hrsz = this.emptyOrNull(formHrsz);
      submitObj.cim.hazszam = this.emptyOrNull(formHazszam);
      submitObj.cim.epulet = this.emptyOrNull(formEpulet);
      submitObj.cim.lepcsohaz = this.emptyOrNull(formLepcsohaz);
      submitObj.cim.emelet = this.emptyOrNull(formEmelet);
      submitObj.cim.ajto = this.emptyOrNull(formAjto);

      submitObj.kapcsolodoazonosito = this.emptyOrNull(formKapcsolodoAzonosito);
      submitObj.feladattipusentity = { id: this.emptyOrNull(formFeladatTipus) };
      submitObj.prioritas = this.emptyOrNull(JSON.parse(formPrioritas).name);
      submitObj.leiras = this.emptyOrNull(formFeladatLeiras);
      submitObj.letrehozasdatum = this.dateToTimezone(formLetrehozva);
      submitObj.esedekesseg = this.dateToTimezone(formEsedekesseg);
      submitObj.ismetlodo = this.emptyOrNull(formIsmetlodo);
      submitObj.ismetlesdatum = this.dateToTimezone(formIsmetlesDatum);
      submitObj.feladatcikk = feladatcikklist;

      // metaadat átalakitása
      let metaadatok = JSON.parse(JSON.stringify(formMetaAdatJson));
      let metaresult = [];

      metaadatok.forEach((meta) => {
        for (const [key, value] of Object.entries(meta)) {
          console.log(`${key}: ${value}`);
          let a = [];
          a.push(key);
          a.push(value);
          metaresult.push(a);
        }
      });

      submitObj.metaadat = metaresult;

      submitObj.tipusid = this.emptyOrNull(formFeladatTipus);
      // submitObj.tipusallapotid = this.emptyOrNull(formAllapot);

      Services.insertFeladat(submitObj, (err) => {
        if (!err) {
          this.props.route.globals.addSuccess('Feladat felvéve!');
          this.onBack();
          this.setState({
            saveButtonLoading: false
          });
        } else {
          this.setState({
            saveButtonLoading: false
          });
        }
      });
    }

    if (formType === 'MOD') {
      let submitObj = {};
      let feladatcikklist = [];
      JSON.parse(JSON.stringify(formEllenorzesiLista)).forEach((item) => {
        let feladatcikk = {};
        let feladatcikkids = [];
        item.listaValue.forEach((listitem) => {
          feladatcikkids.push(listitem.id);
        });
        feladatcikk.cikkid = item.cikkValue.cid;
        feladatcikk.karbcikkid = item.cikkValue.kid;
        feladatcikk.elllistaids = feladatcikkids;
        feladatcikklist.push(feladatcikk);
      });

      submitObj.id = this.emptyOrNull(feladatListaId);
      submitObj.partnertortenetid = this.emptyOrNull(formPartner.id);
      submitObj.kapcsolattartonev = this.emptyOrNull(formKapcsolattartoNev);
      submitObj.kapcsolattartoelerhetoseg = this.emptyOrNull(formKapcsolattartoElerh);
      submitObj.elterohelyszin = this.emptyOrNull(cimSwitchActive);

      submitObj.cim = {};
      submitObj.cim.orszagid = this.emptyOrNull(formOrszag && formOrszag.orszagid);
      submitObj.cim.irszid = this.emptyOrNull(formKereses && formKereses.irszid);
      submitObj.cim.irsz = this.emptyOrNull(formIranyitoszamNev);
      submitObj.cim.telepulesid = this.emptyOrNull(formKereses && formKereses.telepulesid);
      submitObj.cim.telepules = this.emptyOrNull(formTelepulesNev);
      submitObj.cim.postafiok = null; // TODO: Partner címéből
      submitObj.cim.kozterulet = this.emptyOrNull(formKozterulet);
      submitObj.cim.kozteruletjelleg = this.emptyOrNull(formKozteruletjelleg);
      submitObj.cim.hrsz = this.emptyOrNull(formHrsz);
      submitObj.cim.hazszam = this.emptyOrNull(formHazszam);
      submitObj.cim.epulet = this.emptyOrNull(formEpulet);
      submitObj.cim.lepcsohaz = this.emptyOrNull(formLepcsohaz);
      submitObj.cim.emelet = this.emptyOrNull(formEmelet);
      submitObj.cim.ajto = this.emptyOrNull(formAjto);

      submitObj.kapcsolodoazonosito = this.emptyOrNull(formKapcsolodoAzonosito);
      // submitObj.feladattipusentity = { id: this.emptyOrNull(formFeladatTipus) };
      submitObj.prioritas = this.emptyOrNull(JSON.parse(formPrioritas).name);
      submitObj.leiras = this.emptyOrNull(formFeladatLeiras);
      submitObj.letrehozasdatum = this.dateToTimezone(formLetrehozva);
      submitObj.esedekesseg = this.dateToTimezone(formEsedekesseg);
      submitObj.ismetlodo = this.emptyOrNull(formIsmetlodo);
      submitObj.ismetlesdatum = this.dateToTimezone(formIsmetlesDatum);
      submitObj.feladatcikk = feladatcikklist;

      // metaadat átalakitása
      let metaadatok = JSON.parse(JSON.stringify(formMetaAdatJson));
      let metaresult = [];

      metaadatok.forEach((meta) => {
        for (const [key, value] of Object.entries(meta)) {
          console.log(`${key}: ${value}`);
          let a = [];
          a.push(key);
          a.push(value);
          metaresult.push(a);
        }
      });

      submitObj.metaadat = metaresult;

      // submitObj.tipusid = this.emptyOrNull(formFeladatTipus);

      Services.insertFeladat(submitObj, (err) => {
        if (!err) {
          this.props.route.globals.addSuccess('Feladat módosítva!');
          this.onBack();
          this.setState({
            saveButtonLoading: false
          });
        } else {
          this.setState({
            saveButtonLoading: false
          });
        }
      });
    }
  }

  toggleCimSwitch = (trueFalse) => {
    if (trueFalse !== this.state.cimSwitchActive) {
      this.setState(prevState => ({
        cimSwitchActive: !prevState.cimSwitchActive,
        formKozterulet: '',
        formKozteruletjelleg: '',
        formHazszam: '',
        formLepcsohaz: '',
        formEpulet: '',
        formEmelet: '',
        formAjto: ''
      }));
    }
  }

  loadPartnerChange = (input, callback) => {
    if (input.length !== 0) {
      Services.searchPartner(input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            let newItem = item;
            newItem.nev = newItem.nev + ' (' + newItem.azon + ') ';
            newArray.push(newItem);
          });
          callback(newArray);
        }
      });
    }
  }

  loadCikkOptions = (input, callback) => {
    if (input.length !== 0) {
      Services.searchCikkOrKarbantartottCikk(true, input, null, (err, res) => {
        if (!err) {
          callback(res[0]);
        }
      });
    }
  }

  loadKarbantartottCikkOptions = (input, callback) => {
    let partnerId = (this.state.formPartner && this.state.formPartner.id && this.state.formPartner.id);
    if (input.length !== 0) {
      Services.searchCikkOrKarbantartottCikk(false, input, partnerId, (err, res) => {
        if (!err) {
          callback(res[0]);
        }
      });
    }
  }

  nullToString = (data) => {
    if (!data) return '';
    return data;
  }

  nullToBoolean = (data) => {
    if (!data) return 'false';
    return 'true';
  }

  emptyOrNull = (data) => {
    if (!data || data === '') return null;
    return data;
  }

  onBack = () => {
    browserHistory.push({
      pathname: '/feladatlista',
      state: {
      }
    });
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  handleIdopontChange = (name, value) => {
    this.setState({
      [name]: value ? this.dateToTimezone(value) : null
    });
  }

  dateToTimezone = (date) => {
    if (!date || date === '') return null;
    return MomentTimezone.tz(date, this.tz).format('YYYY-MM-DDTHH:mm:00.000ZZ');
  }


  timezoneToDate = (date) => {
    if (!date || date === '') {
      return null;
    }

    return MomentTimezone.tz(date, this.tz).format('YYYY-MM-DD HH:mm');
  }

  getFunckioByValue = (value, options) => {
    let result = '';
    options.forEach((element) => {
      if (element.tipusid == value) {
        result = element.funkcio;
      }
    });
    return result ? result : '';
  }

  handleFeladatInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    let funkcio = this.getFunckioByValue(value, JSON.parse(JSON.stringify(this.state.feladatTipusRaw)));
    this.setState({
      formAllapot: '',
      formFunkcio: funkcio,
      [name]: value
    }, () => {
      let allapotOptions = [];
      let rawOptions = JSON.parse(JSON.stringify(this.state.feladatTipusRaw));
      rawOptions.forEach((element) => {
        if (element.tipusid == this.state.formFeladatTipus) {
          allapotOptions.push(<option key={element.allapotid} value={element.allapotid}>{element.allapotnev}</option>)
        }
      });
      this.setState({
        allapotOptions
      });
    });
  }

  handleIsmetlodoChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value,
      formIsmetlesDatum: null
    });
  }

  handleDateInputChange = (e, v, name) => {
    const value = v === '' ? null : v;
    this.setState({
      [name]: value
    }, () => {
      if (this.timezoneToDateYear(this.state.formLetrehozva) > this.timezoneToDateYear(this.state.formEsedekesseg)) {
        this.setState({ formEsedekesseg: null });
      }
    });
  }

  handleChangePartner = (value) => {
    if (value && value !== '') {
      this.setState({
        formPartner: value,

        formOrszag: {
          keresheto: this.nullToString(value.cim.keresheto),
          nev: this.nullToString(value.cim.orszag),
          orszagid: this.nullToString(value.cim.orszagid),
        },
        formKereses: {
          iranyitoszam: this.nullToString(value.cim.irsz),
          irszid: this.nullToString(value.cim.irszid),
          nev: this.nullToString(value.cim.telepules),
          telepulesid: this.nullToString(value.cim.telepulesid),
          telepulesnev: this.nullToString(value.cim.telepules + ' (' + value.cim.irsz + ')'),
        },
        formTelepulesNev: this.nullToString(value.cim.telepules),
        formIranyitoszamNev: this.nullToString(value.cim.irsz),
        formKozterulet: this.nullToString(value.cim.kozterulet),
        formKozteruletjelleg: this.nullToString(value.cim.kozteruletjelleg),
        formHazszam: this.nullToString(value.cim.hazszam),
        formHrsz: this.nullToString(value.cim.hrsz),
        formEpulet: this.nullToString(value.cim.epulet),
        formLepcsohaz: this.nullToString(value.cim.lepcsohaz),
        formEmelet: this.nullToString(value.cim.emelet),
        formAjto: this.nullToString(value.cim.ajto),

        formKapcsolattartoNev: this.nullToString(value.kapcsolattarto),
        formKapcsolattartoElerh: this.nullToString(value.telefon),
      });
    } else {
      this.setState({
        formPartner: '',
      });
    }
  }

  renderFormCardLogic = () => {
    const {
      formType,

      formPartner,
      formKapcsolattartoNev,
      formKapcsolattartoElerh,

      cimSwitchActive,
      formOrszag,
      formKereses,
      formTelepulesNev,
      formIranyitoszamNev,
      formKozterulet,
      formKozteruletjelleg,
      formHazszam,
      formHrsz,
      formEpulet,
      formLepcsohaz,
      formEmelet,
      formAjto,

      formAzonosito,
      formKapcsolodoAzonosito,
      formFeladatTipus,
      formPrioritas,
      formAllapot,
      formFunkcio,

      formFeladatLeiras,

      formLetrehozva,
      formEsedekesseg,
      formIsmetlodo,
      formIsmetlesDatum,

      formMezoNev,
      formMezoErtek
    } = this.state;

    const titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };

    return (
      <React.Fragment>
        <AvForm
          onValidSubmit={() => this.saveFormBack()}
          style={
            {
              width: '100%',
              marginRight: 20,
              marginBottom: 20,
              marginLeft: 20
            }
          }
        >
          <div className="row">
            <div className="col-md-12">
              <div>
                <h5 style={titleStyle}>
                  {'Ügyfél adatok:'}
                </h5>
              </div>
            </div>
          </div>
          <div className="row">
            <div style={{ marginBottom: 20 }} className="col-md-4">
              <Label for="formPartner">Partner: *</Label>
              <Select
                styles={{
                  control: base => ({
                    ...base,
                    '&:focus': { borderColor: 'red' }, // border style on hover
                    border: '1px solid lightgray', // default border color
                    boxShadow: 'none', // no box-shadow
                    borderRadius: '0'
                  }),
                }}
                name="formPartner"
                id="formPartner"
                // placeholder="Kezdjen el gépelni..."
                placeholder="Keresés partner neve alapján..."
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                loadingMessage={() => 'Keresés...'}
                onChange={v => this.handleChangePartner(v)}
                clearable
                ignoreAccents={false}
                loadOptions={_.debounce(this.loadPartnerChange, 1000)}
                isLoading={false}
                getOptionValue={option => option.id}
                getOptionLabel={option => option.nev}
                value={formPartner}
                cache={false}
                autoload={false}
                isDisabled={formType === 'MOD'}
              />
            </div>
            <div className="col-md-4">
              <AvGroup>
                <Label for="formKapcsolattartoNev">Kapcsolattartó neve: </Label>
                <AvInput
                  name="formKapcsolattartoNev"
                  id="formKapcsolattartoNev"
                  maxLength="50"
                  value={formKapcsolattartoNev}
                  onChange={this.handleInputChange}
                />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
            </div>
            <div className="col-md-4">
              <AvGroup>
                <Label for="formKapcsolattartoElerh">Kapcsolattartó elérhetősége: </Label>
                <AvInput
                  name="formKapcsolattartoElerh"
                  id="formKapcsolattartoElerh"
                  maxLength="50"
                  value={formKapcsolattartoElerh}
                  onChange={this.handleInputChange}
                />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <div>
                <h5 style={titleStyle}>
                  {'Helyszín:'}
                </h5>
              </div>
            </div>
            <div className="col-md-10">
              {/* <SwichButton
                active={cimSwitchActive}
                trueLabel="Eltérő helyszín"
                trueToggle={() => this.toggleCimSwitch(true)}
                trueWidth={127}
                falseToggle={() => this.toggleCimSwitch(false)}
                falseLabel="Partner címe"
                falseWidth={115}
                style={{ marginBottom: 15, marginTop: 8 }}
                disabled
              /> */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-3" style={{ marginBottom: '1rem' }}>
              <Label for="formOrszag">Ország:</Label>
              <Select
                styles={{
                  control: base => ({
                    ...base,
                    '&:focus': { borderColor: 'red' }, // border style on hover
                    border: '1px solid lightgray', // default border color
                    boxShadow: 'none', // no box-shadow
                    borderRadius: '0'
                  }),
                }}
                name="formOrszag"
                id="formOrszag"
                placeholder="Kezdjen el gépelni..."
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                loadingMessage={() => 'Keresés...'}
                onChange={v => this.handleChangeOrszagInput(v)}
                isClearable
                ignoreAccents={false}
                loadOptions={_.debounce(this.loadOrszagOptions, 1000)}
                isLoading={false}
                getOptionLabel={option => option.nev}
                getOptionValue={option => option.orszagid}
                value={formOrszag}
                cache={false}
                autoload={false}
                isDisabled={!cimSwitchActive}
              />
            </div>
            { formOrszag && formOrszag.keresheto && (
              <div className="col-md-3" style={{ marginBottom: '1rem' }}>
                <Label for="formKereses">Keresés:</Label>
                <Select
                  styles={{
                    control: base => ({
                      ...base,
                      '&:focus': { borderColor: 'red' }, // border style on hover
                      border: '1px solid lightgray', // default border color
                      boxShadow: 'none', // no box-shadow
                      borderRadius: '0'
                    }),
                  }}
                  name="formKereses"
                  id="formKereses"
                  placeholder="Település vagy irányítószám"
                  noOptionsMessage={() => 'Nincs keresési eredmény'}
                  searchPromptText="Kezdjen el gépelni a kereséshez..."
                  loadingMessage={() => 'Keresés...'}
                  onChange={v => this.handleChangeKeresesInput(v)}
                  // isClearable
                  ignoreAccents={false}
                  // loadOptions={(input, callback) => this.loadKeresesOptions(input, callback)}
                  loadOptions={_.debounce(this.loadKeresesOptions, 1000)}
                  getOptionLabel={option => option.telepulesnev}
                  getOptionValue={option => option.telepulesid}
                  isLoading={false}
                  value={formKereses}
                  cache={false}
                  autoload={false}
                  isDisabled={!cimSwitchActive}
                />
              </div>
            )}
            <div className="col-md-3">
              <AvGroup>
                <Label for="formIranyitoszamNev">Irányítószám:</Label>
                <AvInput
                  name="formIranyitoszamNev"
                  id="formIranyitoszamNev"
                  type="text"
                  maxLength="20"
                  value={formIranyitoszamNev}
                  onChange={this.handleInputChange}
                  disabled={!cimSwitchActive || (formOrszag && formOrszag.keresheto) || (formOrszag === '')}
                  // required
                />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
            </div>
            <div className="col-md-3">
              <AvGroup>
                <Label for="formTelepulesNev">Település:</Label>
                <AvInput
                  name="formTelepulesNev"
                  id="formTelepulesNev"
                  type="text"
                  value={formTelepulesNev}
                  onChange={this.handleInputChange}
                  disabled={!cimSwitchActive || (formOrszag && formOrszag.keresheto) || (formOrszag === '')}
                  // required
                />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <AvGroup>
                <Label for="formKozterulet">Közterület neve:</Label>
                <AvInput
                  name="formKozterulet"
                  id="formKozterulet"
                  type="text"
                  value={formKozterulet}
                  onChange={this.handleInputChange}
                  maxLength="50"
                  // required
                  disabled={!cimSwitchActive}
                />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
            </div>
            <div className="col-md-3">
              <AvGroup>
                <Label for="formKozteruletjelleg">Közterület jellege:</Label>
                <AvInput
                  name="formKozteruletjelleg"
                  id="formKozteruletjelleg"
                  value={formKozteruletjelleg}
                  onChange={this.handleInputChange}
                  type="text"
                  maxLength="20"
                  disabled={!cimSwitchActive}
                />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
            </div>
            <div className="col-md-3">
              <AvGroup>
                <Label for="formHazszam">
                  {'Házszám:'}
                  {/* {((formHrsz === '' && formOrszag !== '')) && ' *'} */}
                </Label>
                <AvInput
                  name="formHazszam"
                  id="formHazszam"
                  value={formHazszam}
                  onChange={this.handleInputChange}
                  type="text"
                  maxLength="20"
                  required={(formOrszag !== '' || formTelepulesNev !== '') && formHrsz === ''}
                  disabled={!cimSwitchActive || formHrsz !== ''}
                />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
            </div>
            <div className="col-md-3">
              <AvGroup>
                <Label for="formHrsz">
                  {'Helyrajzi szám:'}
                  {/* {(formHazszam === '' && formOrszag !== '') && ' *'} */}
                </Label>
                <AvInput
                  name="formHrsz"
                  id="formHrsz"
                  value={formHrsz}
                  onChange={this.handleInputChange}
                  type="text"
                  maxLength="20"
                  required={(formOrszag !== '' || formTelepulesNev !== '') && formHazszam === ''}
                  disabled={!cimSwitchActive || formHazszam !== ''}
                />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <AvGroup>
                <Label for="formEpulet">Épület:</Label>
                <AvInput
                  name="formEpulet"
                  id="formEpulet"
                  value={formEpulet}
                  onChange={this.handleInputChange}
                  type="text"
                  maxLength="4"
                  disabled={!cimSwitchActive}
                />
              </AvGroup>
            </div>
            <div className="col-md-3">
              <AvGroup>
                <Label for="formLepcsohaz">Lépcsőház: </Label>
                <AvInput
                  name="formLepcsohaz"
                  id="formLepcsohaz"
                  value={formLepcsohaz}
                  onChange={this.handleInputChange}
                  type="text"
                  maxLength="4"
                  disabled={!cimSwitchActive}
                />
              </AvGroup>
            </div>
            <div className="col-md-3">
              <AvGroup>
                <Label for="formEmelet">Emelet:</Label>
                <AvInput
                  name="formEmelet"
                  id="formEmelet"
                  value={formEmelet}
                  onChange={this.handleInputChange}
                  type="text"
                  maxLength="10"
                  disabled={!cimSwitchActive}
                />
              </AvGroup>
            </div>
            <div className="col-md-3">
              <AvGroup>
                <Label for="formAjto">Ajtó:</Label>
                <AvInput
                  name="formAjto"
                  id="formAjto"
                  value={formAjto}
                  onChange={this.handleInputChange}
                  type="text"
                  maxLength="15"
                  disabled={!cimSwitchActive}
                />
                <AvFeedback>Szám mező!</AvFeedback>
              </AvGroup>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div>
                <h5 style={titleStyle}>
                  {'Feladat adatok:'}
                </h5>
              </div>
            </div>
          </div>
          <div className="row">
            {formAzonosito && (
              <div className="col-md-2">
                <AvGroup>
                  <Label for="formAzonosito">Azonosító:</Label>
                  <AvInput
                    name="formAzonosito"
                    id="formAzonosito"
                    value={formAzonosito}
                    // onChange={this.handleInputChange}
                    type="text"
                    disabled
                  />
                  {/* <AvFeedback>Szám mező!</AvFeedback> */}
                </AvGroup>
              </div>
            )}
            <div className="col-md-3">
              <AvGroup>
                <Label for="formFunkcio">Funkció:</Label>
                <AvInput
                  name="formFunkcio"
                  id="formFunkcio"
                  // maxLength="50"
                  value={formFunkcio}
                  // onChange={this.handleInputChange}
                  disabled
                />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
            </div>
            <div className={formAzonosito ? 'col-md-2' : 'col-md-3'}>
              <AvGroup>
                <Label for="formKapcsolodoAzonosito">Kapcsolódó azonosító:</Label>
                <AvInput
                  name="formKapcsolodoAzonosito"
                  id="formKapcsolodoAzonosito"
                  value={formKapcsolodoAzonosito}
                  onChange={this.handleInputChange}
                  type="text"
                  // disabled
                />
                {/* <AvFeedback>Szám mező!</AvFeedback> */}
              </AvGroup>
            </div>
            <div className="col-md-3">
              <AvField
                type="select"
                name="formFeladatTipus"
                id="formFeladatTipus"
                label="Feladat típusa: *"
                errorMessage="Kötelező mező!"
                value={formFeladatTipus}
                onChange={this.handleFeladatInputChange}
                disabled={formType === 'MOD'}
                required
              >
                <option value="">
                  {'Válassz típust...'}
                </option>
                {this.state.feladatTipusOptions}
              </AvField>
            </div>
            <div className={formAzonosito ? 'col-md-2' : 'col-md-3'}>
              <AvField
                type="select"
                name="formPrioritas"
                id="formPrioritas"
                label="Prioritás: *"
                errorMessage="Kötelező mező!"
                value={formPrioritas}
                onChange={this.handleInputChange}
                required
              >
                <option disabled value="">
                  {'Válassz értéket...'}
                </option>
                {this.state.prioritasOptions}
              </AvField>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <FormGroup>
                <Label for="formFeladatLeiras">Feladat leírása:</Label>
                <Input
                  className="munkalap-profil-textarea"
                  type="textarea"
                  name="formFeladatLeiras"
                  id="formFeladatLeiras"
                  value={formFeladatLeiras}
                  onChange={this.handleInputChange}
                />
              </FormGroup>
            </div>
          </div>
          <div className="row">
            {formType === 'MOD' && (
              <div className="col-md-3">
                <React.Fragment>
                  <Label for="formLetrehozva">
                    {'Létrehozva:'}
                  </Label>
                  <DateTimePicker
                    containerClassName="organw-date-time"
                    format="YYYY-MM-DD HH:mm"
                    messages={{ dateButton: 'dátum', timeButton: 'idő' }}
                    onChange={v => this.handleIdopontChange('formLetrehozva', v)}
                    value={formLetrehozva && new Date(formLetrehozva)}
                    name="formLetrehozva"
                    id="formLetrehozva"
                    disabled
                  />
                </React.Fragment>
              </div>
            )}
            <div className="col-md-3">
              <Label for="formEsedekesseg">
                {'Esedékesség: *'}
              </Label>
              <DateTimePicker
                containerClassName="organw-date-time"
                format="YYYY-MM-DD HH:mm"
                messages={{ dateButton: 'dátum', timeButton: 'idő' }}
                onChange={v => this.handleIdopontChange('formEsedekesseg', v)}
                value={formEsedekesseg && new Date(formEsedekesseg)}
                name="formEsedekesseg"
                id="formEsedekesseg"
                min={formLetrehozva && new Date(formLetrehozva)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div>
                <h5 style={titleStyle}>
                  {'Kiegészítő adatok:'}
                </h5>
              </div>
            </div>
          </div>
          <div className="row">
            {this.renderMetaAdat()}
          </div>
          <div className="row">
            <div className="col-md-3 col-sm-6">
              <AvGroup>
                <Label for="formMezoNev">Mező neve:</Label>
                <AvInput
                  name="formMezoNev"
                  id="formMezoNev"
                  value={formMezoNev}
                  onChange={this.handleInputChange}
                  type="text"
                  maxLength="30"
                />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
            </div>
            <div className="col-md-3 col-sm-6">
              <AvGroup>
                <Label for="formMezoErtek">Mező értéke:</Label>
                <AvInput
                  name="formMezoErtek"
                  id="formMezoErtek"
                  value={formMezoErtek}
                  onChange={this.handleInputChange}
                  type="text"
                  maxLength="200"
                />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
            </div>
            <div className="col-md-3 col-sm-6">
              <Button color="primary" onClick={() => this.addMetaAdat(formMezoNev, formMezoErtek)} style={{ marginTop: 29 }}>Hozzáadás</Button>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12" style={{ textAlign: 'right' }}>
              <ProgressButton
                className="btn-cursor-pointer"
                color="primary"
                loading={this.state.saveButtonLoading}
              >
                {'Mentés'}
              </ProgressButton>
              {' '}
              <Button
                color="secondary"
                onClick={this.onBack}
                type="button"
              >
                {'Vissza'}
              </Button>
            </div>
          </div>
        </AvForm>
      </React.Fragment>
    );
  }

  renderFormCard = () => {
    const cardStyle = {
      padding: 20
    };

    return (
      <div className="animated fadeIn">
        <div className="card">
          <div className="card-header">
            {this.state.feladatListaId ? 'Feladat módosítás' : 'Feladat felvétel'}
            {!this.state.feladatListaId && <CustomTooltip text={getTooltip('uj_feladat')} id="uj_feladatTT" />}
          </div>
          <div className="row" style={cardStyle}>
            {{
              LOAD: (<div className="col-md-12"><Loader /></div>),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.getData(this.state.feladatListaId)}
              />),
              DATA: (this.renderFormCardLogic())
            }[this.state.tipusCardStatus]}
          </div>
        </div>
      </div>
    );
  }

  // MetaAdat kezelés BEGIN

  renderMetaAdat = () => {
    let mezok = [];

    this.state.formMetaAdatJson.forEach((x, idx) => {
      let mezonev = Object.keys(x)[0];
      let tartalom = Object.values(x)[0];
      mezok.push(
        <Mezo
          mezonev={mezonev + ':'}
          tartalom={tartalom}
          updateMetaAdat={e => this.updateMetaAdat(e, mezonev, idx)}
          key={idx.toString()}
          removeMetaAdat={() => this.removeMetaAdat(idx)}
        />
      );
    });

    return mezok;
  }

  addMetaAdat = (mezonev, tartalom) => {
    let capMezoNev = mezonev.charAt(0).toUpperCase() + mezonev.slice(1);

    if (!mezonev || mezonev === '' || !tartalom || tartalom === '') {
      this.props.route.globals.addWarning('Az új mező nevének és értékének megadása kötelező!');
    } else {
      let isSet = true;
      this.state.formMetaAdatJson.forEach((x) => {
        if (Object.prototype.hasOwnProperty.call(x, capMezoNev)) {
          this.props.route.globals.addWarning(capMezoNev + ' nevű mező már létezik!');
          isSet = false;
        }
      });
      if (isSet) {
        this.setState(prevState => ({
          formMetaAdatJson: [...prevState.formMetaAdatJson, { [capMezoNev]: tartalom }],
        }));
      }
    }
    this.setState({
      formMezoNev: '',
      formMezoErtek: ''
    });
  }

  updateMetaAdat = (event, mezonev, index) => {
    let e = event.target.value;
    this.setState(prevState => ({
      formMetaAdatJson: update(
        prevState.formMetaAdatJson, {
          [index]: { [mezonev]: { $set: e } }
        }
      )
    }));
  }

  removeMetaAdat = (index) => {
    this.setState(prevState => ({
      formMetaAdatJson: update(
        prevState.formMetaAdatJson, {
          $splice: [[[index], 1]]
        }
      )
    }));
  }

  // MetaAdat kezelés END

  // EllenorzesLista kezelés BEGIN

  renderEllenorzesiListForm = () => {
    let rows = [];

    this.state.formEllenorzesiLista.forEach((item, idx) => {
      rows.push(
        <EllenorzesiListaRow
          aktiv={item.aktiv}
          cikkValue={item.cikkValue}
          listaValue={item.listaValue}
          ellenorzesiListaValue={item.ellenorzesiListaValue}
          listaOptions={item.listaOptions}
          toggleEllenorzesiListaType={this.toggleEllenorzesiListaType}
          loadCikkOptions={this.loadCikkOptions}
          loadKarbantartottCikkOptions={this.loadKarbantartottCikkOptions}
          handleCikkChange={this.handleCikkChange}
          handleListaChange={this.handleListaChange}
          idx={idx}
          key={idx.toString()}
          removeEllenorzesiLista={() => this.removeEllenorzesiLista(idx)}
        />
      );
    });

    return rows;
  }

  addEllenorzesiLista = () => {
    this.setState(prevState => ({
      formEllenorzesiLista: [...prevState.formEllenorzesiLista,
        {
          aktiv: true,
          cikkValue: '',
          listaValue: [],
          listaOptions: []
        }
      ],
    }));
  }

  removeEllenorzesiLista = (index) => {
    this.setState(prevState => ({
      formEllenorzesiLista: update(
        prevState.formEllenorzesiLista, {
          $splice: [[[index], 1]]
        }
      )
    }));
  }

  toggleEllenorzesiListaType = (trueFalse, index) => {
    if (trueFalse !== this.state.formEllenorzesiLista[index].aktiv) {
      this.setState(prevState => ({
        formEllenorzesiLista: update(
          prevState.formEllenorzesiLista, {
            [index]: {
              aktiv: { $set: !prevState.formEllenorzesiLista[index].aktiv },
              listaOptions: { $set: [] },
              listaValue: { $set: [] },
              cikkValue: { $set: '' }
            }
          }
        )
      }));
    }
  }

  handleListaChange = (value, index) => {
    this.setState(prevState => ({
      formEllenorzesiLista: update(
        prevState.formEllenorzesiLista, {
          [index]: { listaValue: { $set: value } }
        }
      )
    }));
  }

  handleCikkChange = (value, index) => {
    if (value) {
      this.setState(prevState => ({
        formEllenorzesiLista: update(
          prevState.formEllenorzesiLista, {
            [index]: { cikkValue: { $set: value } }
          }
        )
      }), () => {
        Services.searchEllenorzesiListaByCikkId(value.cid, (err, res) => {
          if (!err) {
            this.setState(prevState => ({
              formEllenorzesiLista: update(
                prevState.formEllenorzesiLista, {
                  [index]: { listaOptions: { $set: res[0] } }
                }
              )
            }));
          }
        });
      });
    } else {
      this.setState(prevState => ({
        formEllenorzesiLista: update(
          prevState.formEllenorzesiLista, {
            [index]: { cikkValue: { $set: '' }, listaOptions: { $set: [] }, listaValue: { $set: [] } }
          }
        )
      }));
    }
  }

  // EllenorzesLista kezelés END

  // Helyszín kezelés BEGIN

  loadOrszagOptions = (input, callback) => {
    if (input.length !== 0) {
      Services.listOrszag(input, (err, res) => {
        if (!err) {
          callback(res[0]);
        }
      });
    }
  }

  loadKeresesOptions = (input, callback) => {
    if (input.length !== 0) {
      Services.listCim(input, (err, res) => {
        if (!err) {
          let options = [];
          res[0].forEach((item) => {
            item.telepuirszek.forEach((iranyitoszam) => {
              options.push({
                telepulesnev: item.telepulesnev + ' (' + iranyitoszam.iranyitoszam + ')',
                telepulesid: item.telepulesid,
                iranyitoszam: iranyitoszam.iranyitoszam,
                irszid: iranyitoszam.telepuirszid,
                nev: item.telepulesnev,
                // irsz:
              });
            });
          });
          callback(options);
        }
      });
    }
  }

  loadTelepulesOptions = (input, callback) => {
    if (input.length !== 0) {
      Services.listIranyitoszam(input, (err, res) => {
        if (!err) {
          callback(null, { options: res[0] });
        }
      });
    }
  }

  loadIranyitoszamOptions = (input, callback) => {
    if (input.length !== 0) {
      Services.listTelepules(input, (err, res) => {
        if (!err) {
          let options = [];
          res[0].forEach((item) => {
            item.telepuirszek.forEach((iranyitoszam) => {
              options.push({
                nev: iranyitoszam,
                id: iranyitoszam
              });
            });
          });
          callback(null, { options: options });
        }
      });
    }
  }

  handleChangeOrszagInput = (value) => {
    if (value && value !== '') {
      this.setState({
        formOrszag: value,
        formKereses: '',
        formIranyitoszamNev: '',
        formTelepulesNev: '',
        formKozterulet: '',
        formKozteruletjelleg: '',
        formHazszam: '',
        formHrsz: '',
        formEpulet: '',
        formLepcsohaz: '',
        formEmelet: '',
        formAjto: '',
      });
    } else {
      this.setState({
        formOrszag: '',
        formKereses: '',
        formIranyitoszamNev: '',
        formTelepulesNev: '',
        formKozterulet: '',
        formKozteruletjelleg: '',
        formHazszam: '',
        formHrsz: '',
        formEpulet: '',
        formLepcsohaz: '',
        formEmelet: '',
        formAjto: '',
      });
    }
  }

  handleChangeKeresesInput = (value) => {
    if (value && value !== '') {
      this.setState({
        formKereses: value,
        formTelepulesNev: value.nev,
        formIranyitoszamNev: value.iranyitoszam
      });
    } else {
      this.setState({
        formKereses: '',
        formTelepulesNev: '',
        formIranyitoszamNev: ''
      });
    }
  }

  // Helyszín kezelés END

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            {this.renderFormCard()}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

FeladatlistaForm.propTypes = {
  globals: PropTypes.object,
  route: PropTypes.object
};

export default FeladatlistaForm;
