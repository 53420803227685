import Lib from '../../../commons/lib';

export default class Service {
  static kartotekUrl = window.location.origin + '/api/kartotek/';

  // VIZSGALO START

  static getVizsgaloList(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.kartotekUrl + 'vizsgaloszemely/list',
      args: { requestType: 'vizsgaloszemely-list' }
    }], fnDone);
  }

  static getVizsgalo(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.kartotekUrl + 'vizsgaloszemely',
      args: { requestType: 'vizsgaloszemely' },
      params: { id: id }
    }], fnDone);
  }

  static getAktivVizsgalo(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.kartotekUrl + 'vizsgaloszemely/aktiv',
      args: { requestType: 'vizsgaloszemely-aktiv' }
    }], fnDone);
  }

  static createVizsgalo(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.kartotekUrl + 'vizsgaloszemely',
      args: { requestType: 'vizsgaloszemely-post' },
      data: data,
    }], fnDone);
  }

  static updateVizsgalo(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.kartotekUrl + 'vizsgaloszemely',
      args: { requestType: 'vizsgaloszemely-update' },
      data: data,
    }], fnDone);
  }

  // VIZSGALO END
}
