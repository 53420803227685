import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, UncontrolledTooltip, Input
} from 'reactstrap';
import {
  AvForm, AvField, AvGroup, AvInput, AvFeedback
} from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Select from 'react-select/lib/Async';
import DataEmptyState from '../../commons/dataemptystate';
import QueryEmptyState from '../../commons/queryempty';
import Services from './services';
import Lib from '../../commons/lib';
import { DatePicker } from '../../commons/controls';
import ProgressButton from '../../commons/ProgressButton';
import NumberFormatter from '../../commons/NumberFormatter';
import SlaveAvInput from '../../commons/SlaveAvInput';

class SzamlaKuldesLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      /*
       *   BUTTONS LOADING STATES
       */
      atadasraButtonLoading: false,
      atadhatoraButtonLoading: false,
      keresesButtonLoading: false,
      szamlakibocsatoSzamlaKuldesValue: '',
      disableButtons: true,
      disableTorles: true,
      alapAdatok: [],
      szamlakibocsatoValue: '',
      dataUdulesijogLoaded: null,
      dataModifyLoaded: null,
      dataViewLoaded: null,
      modalNezet: false,
      modalSikertelenTorles: false,
      modalTorles: false,
      modalUdulesijog: false,
      modalModositas: false,
      udulesijogJson: [],
      szamlaJson: [],
      szamlaObj: null,
      modositasJson: {},
      selected: [],
      kijelolesszam: '0',
      filteredObj: null
    };

    this._currentEv = null;
  }

  // componentWillMount() {
  //   this.getAktivPenznem();
  //   this.getAktivFizmod();
  //   this.getSzamlaKuldesAlapadat();
  // }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.active !== prevProps.active) {
      if (this.props.active) {
        this.getAktivPenznem();
        this.getAktivFizmod();
        this.getSzamlaKuldesAlapadat();
      }
    }
  }

  getAktivPenznem = () => {
    Services.getPenznem('KULD', (err, res) => {
      if (!err) {
        let resOptions = res[0].map(e => <option key={e.id} value={e.id}>{e.nev + ' (' + e.azonosito + ')'}</option>);
        this.setState({ aktivPenznem: resOptions });
      }
    });
  }

  getAktivFizmod = () => {
    Services.getFizmod('KULD', (err, res) => {
      if (!err) {
        let resOptions = res[0].map(e => <option key={e.variansid ? e.variansid : e.id} value={e.variansid ? e.variansid : e.id}>{e.variansnev ? e.variansnev : e.nev}</option>);
        this.setState({ aktivFizmod: resOptions });
      }
    });
  }

  getSzamlaKuldesAlapadat = () => {
    Services.getSzamlaKuldesAlapadat((err, res) => {
      if (!err) {
        res[0].kibocsato = res[0].SZAMLA_KIBOCSATO[0];
        res[0].afa = res[0].SZAMLA_AFA[0];
        res[0].teljesites = res[0].SZAMLA_TELJESITES[0];
        res[0].fizhatarido = res[0].SZAMLA_FIZHAT[0];
        res[0].kibocsatonev = res[0].SZAMLA_KIBOCSATO_NEV[0];
        this.setState({ alapAdatok: res[0] });
      }
    });
  }

  updateTable = (event, searchObj) => {
    this.setState({
      keresesButtonLoading: true,
      dataLoaded: false,
      dataFailed: false,
      queryLoaded: false,
      queryStarted: true,
      disableButtons: true
    });
    if (searchObj && searchObj.ev === '') delete searchObj.ev;
    if (searchObj && searchObj.penznem === '') delete searchObj.penznem;
    if (searchObj && searchObj.fizmod === '') delete searchObj.fizmod;
    Services.listSzamlaKuldes(this.state.szamlakibocsatoValue && this.state.szamlakibocsatoValue.id && this.state.szamlakibocsatoValue.id, searchObj && searchObj.ev, searchObj && searchObj.penznemid, searchObj && searchObj.fizmodid, (err, res) => {
      if (err) {
        this.setState({
          keresesButtonLoading: false,
          dataLoaded: null,
          dataFailed: null,
          queryLoaded: null,
          queryStarted: null
        });
      } else {
        let newState = res[0].list;
        this.setState({ kijelolesszam: ((res[0].param).toString()) });     
        newState.forEach((item, idx) => {
          switch (item.al) {
            case 'Előkészített':
              item.al = 0;
              break;
            case 'Számlázásra átadható':
              item.al = 1;
              break;
            case 'Számlázásra átadott':
              item.al = 2;
              break;
            case 'Sikertelen':
              item.al = 3;
              break;
            default:
              item.al = -1;
              break;
          }
        });
        this.setState({
          keresesButtonLoading: false,
          szamlaJson: newState,
          dataLoaded: true,
          dataFailed: false,
          queryLoaded: true,
          queryStarted: false
        });
      }
    });
  }

  deleteSikertelenSzamlakuldes = (id) => {
    Services.deleteSikertelenSzamlakuldes(id, (err, res) => {
      if (!err) {
        this.updateTable();
        this.props.globals.addSuccess('Számlaküldés törölve!');
        this.toggleSikertelenTorles();
      }
    });
  }

  toggleNezet = () => {
    this.setState({ modalNezet: !this.state.modalNezet });
  }

  toggleModositas = () => {
    this.setState({ modalModositas: !this.state.modalModositas });
  }

  toggleSikertelenTorles = () => {
    this.setState({ modalSikertelenTorles: !this.state.modalSikertelenTorles });
  }

  toggleUdulesijog = () => {
    this.setState({ modalUdulesijog: !this.state.modalUdulesijog });
  }

  toggleTorles = () => {
    this.setState({ modalTorles: !this.state.modalTorles });
  }

  iconFormatter = (cell, row) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_SZAMLAKULDES', 'LEK') && (
          <React.Fragment>
            <Button className="icon-eye table-action-icon" id={'action-view-' + cell} onClick={() => this.handleViewClick(cell)} />
            <UncontrolledTooltip placement="left" target={'action-view-' + cell} delay={0}>
              Megtekintés
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_SZAMLAKULDES', 'KARB') && (
          <React.Fragment>
            {row.mo ? (
              <span>
                <Button className="icon-pencil table-action-icon" id={'action-edit-' + cell} onClick={() => this.handleEditClick(cell, row)} />
                <UncontrolledTooltip placement="left" target={'action-edit-' + cell} delay={0}>
                  Módosítás
                </UncontrolledTooltip>
              </span>
            ) : <span /> }
            {row.to ? (
              <span>
                <Button className="icon-trash table-action-icon" id={'action-delete-' + cell} onClick={() => this.handleDeleteClick(cell)} />
                <UncontrolledTooltip placement="left" target={'action-delete-' + cell} delay={0}>
                  Törlés
                </UncontrolledTooltip>
              </span>
            ) : <span /> }
          </React.Fragment>
        )}
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_SZAMLAKULDES', 'LEK') && (
          <React.Fragment>
            <Button className="fa fa-file-text-o table-action-icon" id={'action-new-' + cell} onClick={() => this.handleUdulesijogClick(cell, row)} />
            <UncontrolledTooltip placement="left" target={'action-new-' + cell} delay={0}>
              Üdülési jog adatok
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  roundNumber = (num, scale) => {
    if (!('' + num).includes('e')) {
      return +(Math.round(num + 'e+' + scale) + 'e-' + scale);
    }
    let arr = ('' + num).split('e');
    let sig = '';
    if (+arr[1] + scale > 0) {
      sig = '+';
    }
    return +(Math.round(+arr[0] + 'e' + sig + (+arr[1] + scale)) + 'e-' + scale);
  }

  szamlaKibocsatoFormatter = (cell) => {
    return (
      cell.nev
    );
  }

  csvDecode = (cell, row, items) => {
    return (cell === undefined ? '' : items[cell]);
  }

  csvEmpty = (cell) => {
    return cell === null ? '' : cell;
  }

  allapotFormatter = (cell) => {
    switch (cell) {
      case 0:
        return 'Előkészített';
      case 1:
        return 'Számlázásra átadható';
      case 2:
        return 'Számlázásra átadott';
      case 3:
        return 'Sikertelen számlázás';
      default:
        return 'Ismeretlen';
    }
  }

  handleDeleteClick = (id) => {
    this._currentDeleteId = id;
    this.toggleSikertelenTorles();
  }

  handleEditClick = (id, row) => {
    this.getSzamlaKuldesAlapadat();
    this.setState({ dataModifyLoaded: false, szamlakibocsatoSzamlaKuldesValue: '' });
    this._currentEv = row.ev;
    Services.getUdulesijogPrices(id, (err, res) => {
      if (!err) {
        this.setState({
          modositasJson: res[0],
          dataModifyLoaded: true
        });
      }
    });
    this.toggleModositas();
  }

  handleKijelolesszamChange = (e) => {
    let ertek = e.target.value;
    let kijelolesInput = document.getElementById('kijelolesszam');
    if ((ertek === '' || !isNaN(ertek)) && (ertek !== '.') && !(ertek.includes('.')) && !(ertek.includes(' ')) && kijelolesInput) {
      kijelolesInput.style.borderColor = 'grey';
      this.setState({ kijelolesszam: ertek });
    }
  }

  handleUdulesijogClick = (id) => {
    this.setState({ dataUdulesijogLoaded: false });
    Services.getUdulesijogData(id, (err, res) => {
      if (!err) {
        this.setState({
          udulesijogJson: res[0],
          dataUdulesijogLoaded: true
        });
      }
    });
    this.toggleUdulesijog();
  }

  handleViewClick = (id) => {
    this.setState({ dataViewLoaded: false });
    Services.getSzamlaKuldes(id, (err, res) => {
      if (!err) {
        this.setState({
          szamlaObj: res[0],
          dataViewLoaded: true
        });
      }
    });
    this.toggleNezet();
  }

  loadSzamlakibocsatoChange = (input, callback) => {
    if (input.length !== 0) {
      Services.getSzamlaKibocsato('SZAMLAKULDES_SZAMLAKIBOCSATO_ELO', input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            item.nev += ' (' + item.cim + ') ';
            if (item.adoszam) {
              item.nev += '[' + NumberFormatter.addHyphensToString(item.adoszam) + '] ';
            }
            newArray.push(item);
          });
          callback(newArray);
        }
      });
    }
  }

  loadSzamlakibocsatoModositasChange = (input, callback) => {
    if (input.length !== 0) {
      Services.getSzamlaKibocsatoChange('SZAMLAKULDES_MODOSITAS_SZAMLAKIBOCSATO_FORM', input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            item.nev += ' (' + item.cim + ') ';
            if (item.adoszam) {
              item.nev += '[' + NumberFormatter.addHyphensToString(item.adoszam) + '] ';
            }
            newArray.push(item);
          });
          callback(newArray);
        }
      });
    }
  }

  handleChangeSzamlakibocsato = (value) => {
    if (value && value.length !== 0) {
      this.setState({ szamlakibocsatoValue: value });
    } else {
      this.setState({ szamlakibocsatoValue: '' });
    }
  }

  handleChangeSzamlakibocsatoSzamlazas = (value) => {
    if (value && value.length !== 0) {
      this.setState({ szamlakibocsatoSzamlaKuldesValue: value });
    } else {
      this.setState({ szamlakibocsatoSzamlaKuldesValue: '' });
    }
  }

  handleBruttoDijChange = (e, idx) => {
    let value = NumberFormatter.removeSpacesFromString(e.target.value);
    let bruttoOsszeg = 0;
    let nettoOsszeg = 0;
    let szazalekertek = 100 + this.state.modositasJson.afa;

    let newState = this.state.modositasJson;
    if (value && value.length !== 0 && !value.isInteger) {
      newState.udulesijogok[idx].bruttodij = value;
      newState.udulesijogok[idx].nettodij = (value / szazalekertek) * 100;
      newState.udulesijogok.forEach((item) => {
        bruttoOsszeg += parseFloat(item.bruttodij);
        nettoOsszeg += parseFloat(item.nettodij);
      });
      newState.bruttodij = this.roundNumber(bruttoOsszeg, this.state.modositasJson.kerekitesbrutto);
      newState.nettodij = this.roundNumber(nettoOsszeg, this.state.modositasJson.kerekitesnetto);
      newState.udulesijogok[idx].nettodij = this.roundNumber(newState.udulesijogok[idx].nettodij, this.state.modositasJson.kerekitesnetto);
      this.setState({ modositasJson: newState, });
    } else {
      newState.udulesijogok[idx].bruttodij = newState.udulesijogok[idx].bruttodij;
      this.setState({ modositasJson: newState });
    }
  }

  handleNettoOsszegChange = (e) => {
    let { value } = e.target;
    let newState = this.state.modositasJson;
    if (value && value.length !== 0) {
      newState.nettodij = value;
      this.setState({ modositasJson: newState });
    } else {
      newState.nettodij = 0;
      this.setState({ modositasJson: newState });
    }
  }

  handleBruttoOsszegChange = (e) => {
    let { value } = e.target;
    let newState = this.state.modositasJson;
    if (value && value.length !== 0) {
      newState.bruttodij = value;
      this.setState({ modositasJson: newState });
    } else {
      newState.bruttodij = 0;
      this.setState({ modositasJson: newState });
    }
  }

  handlePenznemChange = (e) => {
    let { value } = e.target;
    let newState = this.state.modositasJson;
    if (value && value.length !== 0) {
      newState.penznem = value;
      this.setState({ modositasJson: newState });
    } else {
      newState.penznem = '';
      this.setState({ modositasJson: newState });
    }
  }

  handleFizmodChange = (e) => {
    let { value } = e.target;
    let newState = this.state.modositasJson;
    if (value && value.length !== 0) {
      newState.fizmod = value;
      this.setState({ modositasJson: newState });
    } else {
      newState.fizmod = '';
      this.setState({ modositasJson: newState });
    }
  }

  handleTermeknevChange = (e) => {
    let { value } = e.target;
    let newState = this.state.modositasJson;
    if (value && value.length !== 0) {
      newState.kstermeknev = value;
      this.setState({ modositasJson: newState });
    } else {
      newState.kstermeknev = '';
      this.setState({ modositasJson: newState });
    }
  }

  handleChangeSzamlakibocsato = (value) => {
    if (value && value.length !== 0) {
      this.setState({ szamlakibocsatoValue: value });
    } else {
      this.setState({ szamlakibocsatoValue: '' });
    }
  }

  handleFizhatTeljDateInputChange = (e, v, name) => {
    let value = v === '' ? null : v;
    this.setState(prevState => ({
      modositasJson: Object.assign(prevState.modositasJson, { [name]: value })
    }));
  }

  modositas = () => {
    let submitObj = JSON.parse(JSON.stringify(this.state.modositasJson));

    submitObj.kibocsato = this.state.szamlakibocsatoSzamlaKuldesValue ? this.state.szamlakibocsatoSzamlaKuldesValue.id : '';
    submitObj.ev = this._currentEv;

    Services.updateSzamlaKuldes(submitObj, (err) => {
      if (!err) {
        this.updateTable();
        this.props.globals.addSuccess('Számlaküldés módosítva!');
        this.toggleModositas();
      }
    });
  }

  atadas = () => {
    this.setState({
      atadasraButtonLoading: true
    });
    let submitList = [];
    if (this.kuldesTabla && this.kuldesTabla.state && this.kuldesTabla.state.selectedRowKeys && this.kuldesTabla.state.selectedRowKeys.length > 0) {
      submitList = this.kuldesTabla.state.selectedRowKeys;
    } else {
      submitList = [];
    }
    Services.sendToAtadas(submitList, (err) => {
      if (!err) {
        this.setState({
          atadasraButtonLoading: false
        });
        this.props.globals.addSuccess('Állapotok frissítve!');
        this.updateTable();
      } else {
        this.setState({
          atadasraButtonLoading: false
        });
      }
    });
  }

  atadhato = () => {
    this.setState({
      atadhatoraButtonLoading: true
    });
    let submitList = [];
    if (this.kuldesTabla && this.kuldesTabla.state && this.kuldesTabla.state.selectedRowKeys && this.kuldesTabla.state.selectedRowKeys.length > 0) {
      submitList = this.kuldesTabla.state.selectedRowKeys;
    } else {
      submitList = [];
    }
    Services.sendToAtadhato(submitList, (err) => {
      if (!err) {
        this.setState({
          atadhatoraButtonLoading: false
        });
        this.props.globals.addSuccess('Állapotok frissítve!');
        this.updateTable();
      } else {
        this.setState({
          atadhatoraButtonLoading: false
        });
      }
    });
  }

  torles = (ids) => {
    Services.deleteSzamlakuldes(ids, (err, res) => {
      if (!err) {
        this.updateTable();
        this.props.globals.addSuccess('Számlaküldés(ek) törölve!');
        this.toggleTorles();
      }
    });
  }

  renderAdatok = () => {
    return (this.state.dataViewLoaded ? (
      <div>
        <h6>Számla küldés információ:</h6>
        <b>Év: </b>
        {this.state.szamlaObj && this.state.szamlaObj.ev}
        <br />
        <b>Üdülési jog: </b>
        {this.state.szamlaObj && this.state.szamlaObj.udulesijogok}
        <br />
        <b>Fizetési mód: </b>
        {this.state.szamlaObj && this.state.szamlaObj.fizmodnev}
        <br />
        <b>Fizetési határidő: </b>
        {this.state.szamlaObj && this.state.szamlaObj.fizhat}
        <br />
        {/* <b>Esedékesség: </b>{this.state.szamlaObj && this.state.szamlaObj.alapadatentity && this.state.szamlaObj.alapadatentity.esedekesseg}<br /> */}
        <b>Teljesítés: </b>
        {this.state.szamlaObj && this.state.szamlaObj.teljesites}
        <br />
        {/* <b>Főkönyvi szám: </b>{this.state.szamlaObj && this.state.szamlaObj.alapadatentity && this.state.szamlaObj.alapadatentity.fokonyviszam}<br /> */}
        <b>Áfa: </b>
        {this.state.szamlaObj && this.state.szamlaObj.afa}
        {' %'}
        <br />
        <b>Áfa főkönyvi szám: </b>
        {this.state.szamlaObj && this.state.szamlaObj.szamlaprofilentity && this.state.szamlaObj.szamlaprofilentity.afafkviszam}
        <br />
        <b>Fenntartási díj (nettó): </b>
        {this.state.szamlaObj && NumberFormatter.addSpacesToString(this.state.szamlaObj.dijnetto, NumberFormatter.OSSZEG)}
        {' '}
        {this.state.szamlaObj && this.state.szamlaObj.penznem}
        <br />
        <b>Fenntartási díj (bruttó): </b>
        {this.state.szamlaObj && NumberFormatter.addSpacesToString(this.state.szamlaObj.dijbrutto, NumberFormatter.OSSZEG)}
        {' '}
        {this.state.szamlaObj && this.state.szamlaObj.penznem}
        <br />
        {/* <b>Bizonylatszám: </b>{this.state.szamlaObj && this.state.szamlaObj.bizonylatszam}<br />
        <b>Árfolyam: </b>{this.state.szamlaObj && this.state.szamlaObj.arfolyam}<br /> */}
        <b>Számla állapota: </b>
        {this.state.szamlaObj && this.state.szamlaObj.allapot && this.state.szamlaObj.allapot.caption}
        <br />
        <b>Termékmegnevezés: </b>
        {this.state.szamlaObj && this.state.szamlaObj.kstermeknev}
        <br />
        <br />
        <b>Név: </b>
        {this.state.szamlaObj && this.state.szamlaObj.kibocsatobean && this.state.szamlaObj.kibocsatobean.nev}
        <br />
        <b>Cím: </b>
        {this.state.szamlaObj && this.state.szamlaObj.kibocsatobean && this.state.szamlaObj.kibocsatobean.cim }
        <br />
        <b>Adószám: </b>
        {this.state.szamlaObj && this.state.szamlaObj.kibocsatobean && NumberFormatter.addHyphensToString(this.state.szamlaObj.kibocsatobean.adoszam)}
        <br />
        <b>Főkönyvi szám: </b>
        {this.state.szamlaObj && this.state.szamlaObj.szamlaprofilentity && this.state.szamlaObj.szamlaprofilentity.szallitofkviszam}
        <br />
        <br />
        <h6>Számlafizető:</h6>
        <b>Név: </b>
        {this.state.szamlaObj && this.state.szamlaObj.jogtulajbean && this.state.szamlaObj.jogtulajbean.nev}
        <br />
        <b>Cím: </b>
        {this.state.szamlaObj && this.state.szamlaObj.jogtulajbean && this.state.szamlaObj.jogtulajbean.cim }
        <br />
        <b>Adószám: </b>
        {this.state.szamlaObj && this.state.szamlaObj.jogtulajbean && NumberFormatter.addHyphensToString(this.state.szamlaObj.jogtulajbean.adoszam)}
        <br />
        <b>Főkönyvi szám: </b>
        {this.state.szamlaObj && this.state.szamlaObj.szamlaprofilentity && this.state.szamlaObj.szamlaprofilentity.vevofkviszam}
        <br />
        <br />
      </div>
    ) : this.renderLoader()
    );
  }

  renderEloSzures = () => {
    return (
      <AvForm onValidSubmit={this.updateTable}>
        <div className="row">
          <div className="col-md-12">
            <div><h5 style={{ marginBottom: 20 }}>Számla küldés:</h5></div>
          </div>
          <div className="col-md-12 form-group">
            <Label for="szallitoId">Számlakibocsátó:</Label>
            {/* <Select.Async
              name="szallitoId"
              id="szallitoId"
              placeholder="Kezdjen el gépelni..."
              noResultsText="Nincs keresési eredmény"
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingPlaceholder="Keresés..."
              onChange={v => this.handleChangeSzamlakibocsato(v)}
              clearable
              ignoreAccents={false}
              loadOptions={_.debounce((input, callback) => this.loadSzamlakibocsatoChange(input, callback), 1000)}
              isLoading={false}
              valueKey="id"
              labelKey="nev"
              value={this.state.szamlakibocsatoValue}
              cache={false}
              autoload={false}
            /> */}
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="szallitoId"
              id="szallitoId"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeSzamlakibocsato(v)}
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadSzamlakibocsatoChange, 1000)}
              isLoading={false}
              getOptionLabel={option => option.nev}
              getOptionValue={option => option.id}
              value={this.state.szamlakibocsatoValue}
              // disabled={this.state.fizTeljElerheto}
              cache={false}
              autoload={false}
            />
          </div>
          <div className="col-md-3">
            <AvGroup>
              <Label for="ev">
                Év:
              </Label>
              <AvInput name="ev" id="ev" maxLength="4" placeholder="éééé" />
              <AvFeedback>
                Kötelező mező!
              </AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-3">
            <AvField type="select" name="penznemid" id="penznemid" label="Pénznem: " errorMessage="Kötelező mező!">
              <option value="">
                Válassz pénznemet...
              </option>
              {this.state.aktivPenznem}
            </AvField>
          </div>
          <div className="col-md-3">
            <AvField type="select" name="fizmodid" id="fizmodid" label="Fizetési mód: " errorMessage="Kötelező mező!">
              <option value="">
                Válassz fizetési módot...
              </option>
              {this.state.aktivFizmod}
            </AvField>
          </div>
          <div className="col-md-3">
            <ProgressButton
              className="margin-top-30"
              color="primary"
              loading={this.state.keresesButtonLoading}
            >
              Keresés
            </ProgressButton>
          </div>
        </div>
      </AvForm>
    );
  }

  renderSendButton = () => {
    return (
      <div className="row">
        <div className="col-md-12" style={{ marginTop: 10 }}>
          <ProgressButton
            color="success"
            onClick={this.atadas}
            disabled={this.state.disableButtons}
            loading={this.state.atadasraButtonLoading}
          >
            {'Átadásra'}
          </ProgressButton>
          {' '}
          <ProgressButton
            color="secondary"
            onClick={this.atadhato}
            disabled={this.state.disableButtons}
            loading={this.state.atadhatoraButtonLoading}
          >
            {'Átadhatóra'}
          </ProgressButton>
          {' '}
          <ProgressButton
            color="danger"
            onClick={this.toggleTorles}
            disabled={this.state.disableButtons}
          >
            {'Törlés'}
          </ProgressButton>
        </div>
      </div>
    );
  }

  renderModositoForm = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };
    let modositoMezok = [];
    modositoMezok.push(
      <div key="cim" className="col-md-12">
        <div><h5 style={titleStyle}>Általános adatok:</h5></div>
      </div>
    );
    modositoMezok.push(
      <AvGroup key="penznem" className="col-md-6">
        <Label for="penznem">Pénznem: </Label>
        <AvInput
          name="penznem"
          id="penznem"
          disabled
          onChange={e => this.handlePenznemChange(e)}
          value={this.state.modositasJson && this.state.modositasJson.penznem}
        />
        <AvFeedback>Kötelező mező!</AvFeedback>
      </AvGroup>
    );
    modositoMezok.push(
      <AvGroup key="fizmod" className="col-md-6">
        <Label for="fizmod">Fizetési mód: </Label>
        <AvInput
          name="fizmod"
          id="fizmod"
          disabled
          onChange={e => this.handleFizmodChange(e)}
          value={this.state.modositasJson && this.state.modositasJson.fizetesimod}
        />
        <AvFeedback>Kötelező mező!</AvFeedback>
      </AvGroup>
    );
    modositoMezok.push(
      <AvGroup key="parkibocsatobean" className="col-md-6">
        <Label for="parkibocsatobean">Jelenlegi számlakibocsátó: </Label>
        <AvInput
          name="parkibocsatobean"
          id="parkibocsatobean"
          disabled
          value={this.state.modositasJson && this.state.modositasJson.kibocsatobean.nev}
        />
      </AvGroup>
    );
    modositoMezok.push(
      <AvGroup key="kibocsatobean" className="col-md-6">
        <div>
          <Label for="szallitoId">Új számlakibocsátó:</Label>
          <Select
            styles={{
              control: base => ({
                ...base,
                '&:focus': { borderColor: 'red' }, // border style on hover
                border: '1px solid lightgray', // default border color
                boxShadow: 'none', // no box-shadow
                borderRadius: '0'
              }),
            }}
            name="szallitoId"
            id="szallitoId"
            placeholder="Kezdjen el gépelni..."
            noOptionsMessage={() => 'Nincs keresési eredmény'}
            searchPromptText="Kezdjen el gépelni a kereséshez..."
            loadingMessage={() => 'Keresés...'}
            onChange={v => this.handleChangeSzamlakibocsatoSzamlazas(v)}
            isClearable
            ignoreAccents={false}
            loadOptions={_.debounce(this.loadSzamlakibocsatoModositasChange, 1000)}
            isLoading={false}
            getOptionLabel={option => option.nev}
            getOptionValue={option => option.id}
            value={this.state.szamlakibocsatoSzamlaKuldesValue}
            cache={false}
            autoload={false}
          />
        </div>

        <AvFeedback>Kötelező mező!</AvFeedback>
      </AvGroup>
    );
    modositoMezok.push(
      <AvGroup key="kstermeknev" className="col-md-12">
        <Label for="kstermeknev">Terméknév: </Label>
        <AvInput
          name="kstermeknev"
          id="kstermeknev"
          onChange={e => this.handleTermeknevChange(e)}
          value={this.state.modositasJson && this.state.modositasJson.kstermeknev}
        />
      </AvGroup>
    );
    modositoMezok.push(
      <AvGroup key="fizhat" className="col-md-6">
        <Label for="fizhat">Fizetési határidő: *</Label>
        <DatePicker
          placeholder="éééé-hh-nn"
          onChange={(e, v) => this.handleFizhatTeljDateInputChange(e, v, 'fizhat')}
          value={this.state.modositasJson && this.state.modositasJson.fizhat && new Date(this.state.modositasJson.fizhat)}
          type="select"
          name="fizhat"
          errorMessage="OOOO"
          required
        />
        <AvFeedback>Kötelező mező!</AvFeedback>
      </AvGroup>
    );
    modositoMezok.push(
      <AvGroup key="telj" className="col-md-6">
        <Label for="telj">Teljesítés: *</Label>
        <DatePicker
          onChange={(e, v) => this.handleFizhatTeljDateInputChange(e, v, 'telj')}
          placeholder="éééé-hh-nn"
          value={this.state.modositasJson && this.state.modositasJson.telj && new Date(this.state.modositasJson.telj)}
          type="select"
          name="telj"
          required
          errorMessage="OOOO"
        />
        <AvFeedback>Kötelező mező!</AvFeedback>
      </AvGroup>
    );
    modositoMezok.push(
      <div key="udjog" className="col-md-12">
        <div><h5 style={titleStyle}>Üdülési jogok:</h5></div>
      </div>
    );
    if (this.state.modositasJson.udulesijogok) {
      this.state.modositasJson.udulesijogok.forEach((item, idx) => {
        modositoMezok.push(
          <h5 key={'nev-' + idx.toString()} className="col-md-12">
            {item.azonosito}
            :
          </h5>
        );
        modositoMezok.push(
          <div className="col-md-6">
            <SlaveAvInput
              id="nettodij"
              key={'netto-' + idx.toString()}
              label="Fenntartási díj (nettó): "
              disabled
              errorMessage="Kötelező szám mező!"
              value={this.state.modositasJson && this.state.modositasJson.udulesijogok && this.state.modositasJson.udulesijogok[idx].nettodij}
              szuroTipus={NumberFormatter.OSSZEG}
              inputGroupAddon={this.state.modositasJson && this.state.modositasJson.penznem}
            />
          </div>
        );
        modositoMezok.push(
          <div className="col-md-6">
            <SlaveAvInput
              id={'bruttodij-' + idx}
              key={'brutto-' + idx.toString()}
              label="Fenntartási díj (bruttó): "
              maxLength={11}
              onChange={e => this.handleBruttoDijChange(e, idx)}
              value={this.state.modositasJson && this.state.modositasJson.udulesijogok && this.state.modositasJson.udulesijogok[idx].bruttodij}
              inputGroupAddon={this.state.modositasJson && this.state.modositasJson.penznem}
              szuroTipus={NumberFormatter.OSSZEG}
            />
          </div>
        );
      });
    }
    modositoMezok.push(<h5 key="osszesen" className="col-md-12">Összesen:</h5>);
    modositoMezok.push(
      <div className="col-md-6">
        <SlaveAvInput
          id="nettodij"
          key="osszesen-netto"
          label="Fenntartási díj (nettó): "
          disabled
          value={this.state.modositasJson && this.state.modositasJson.nettodij}
          inputGroupAddon={this.state.modositasJson && this.state.modositasJson.penznem}
          szuroTipus={NumberFormatter.OSSZEG}
        />
      </div>
    );
    modositoMezok.push(
      <div className="col-md-6">
        <SlaveAvInput
          id="bruttodij"
          key="osszesen-brutto"
          label="Fenntartási díj (bruttó): "
          disabled
          value={this.state.modositasJson && this.state.modositasJson.bruttodij}
          inputGroupAddon={this.state.modositasJson && this.state.modositasJson.penznem}
          szuroTipus={NumberFormatter.OSSZEG}
        />
      </div>
    );
    return modositoMezok;
  }

  onSelectAll = (isSelected) => {
    let ertek = this.state.kijelolesszam ? parseInt(this.state.kijelolesszam, 10) : '';
    let ids = [];
    if (isSelected) {
      if (ertek === 0) {
        ids = [];
        this.setState({ selected: ids, disableButtons: true });
      }
      if (ertek !== 0) {
        if (ertek === '') {
          (this.state.filteredObj ? this.state.filteredObj : this.state.szamlaJson).map((e) => {
            ids.push(e.id);
          });
          this.setState({ selected: ids, disableButtons: false });
        } else {
          (this.state.filteredObj ? this.state.filteredObj : this.state.szamlaJson).map((e, index) => {
            if (index < ertek) {
              ids.push(e.id);
              this.setState({ selected: ids, disableButtons: false });
            }
          });
        }
      }
    }

    if (!isSelected) {
      ids = [];
      this.setState({ selected: ids, disableButtons: true });
    }
  }

  onRowSelect = (row, isSelected, e) => {
    let select = this.state.selected;
    if (isSelected) {
      this.setState(prevState => ({ disableButtons: false, selected: [...prevState.selected, row.id] }));
    }
    if (!isSelected) {
      select = select.filter((deselected) => {
        if (deselected !== row.id) {
          return deselected;
        }
      });
      if (select.length === 0) {
        this.setState({ disableButtons: true });
      }
      this.setState({ selected: select });
    }
  }

  handleFilter = (filterObj) => {
    let szamlaJson = JSON.parse(JSON.stringify(this.state.szamlaJson));
    if (Object.keys(filterObj).length === 0) {
      this.setState({ filteredObj: szamlaJson });
      return;
    }

    const data = szamlaJson.filter((szamlak) => {
      let valid = true;
      let filterValue;
      for (const key in filterObj) {
        const targetValue = szamlak[key];
        switch (filterObj[key].type) {
          case 'NumberFilter': {
            filterValue = filterObj[key].value.number;
            valid = this.filterNumber(targetValue, filterValue, filterObj[key].value.comparator);
            break;
          }
          default: {
            filterValue = (typeof filterObj[key].value === 'string') ?
              filterObj[key].value.toLowerCase() : filterObj[key].value;
            valid = this.filterText(targetValue, filterValue);
            break;
          }
        }

        if (!valid) {
          break;
        }
      }

      return valid;
    });
    this.setState({
      filteredObj: data
    });
  }

  renderTable = () => {
    const selectRowProp = {
      onSelectAll: this.onSelectAll,
      onSelect: this.onRowSelect,
      mode: 'checkbox',
      selected: this.state.selected,
      hideSelectColumn: !Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_SZAMLAKULDES', 'KARB')
    };

    const options = {
      ...this.props.globals.bootstraptableProps,
      onFilterChange: this.handleFilter
    }

    const atadasAllapot = {
      0: 'Előkészített',
      1: 'Számlázásra átadható',
      2: 'Számlázásra átadott',
      3: 'Sikertelen számlázás'
    };

    return this.state.szamlaJson.length ? (
      <BootstrapTable ref={ c => this.kuldesTabla = c } data={this.state.szamlaJson} pagination options={options} selectRow={selectRowProp} exportCSV csvFileName="szamla_kuldes.csv">
        <TableHeaderColumn dataField="ev" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }} csvHeader="Év">Év</TableHeaderColumn>
        <TableHeaderColumn dataField="kb" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }} csvHeader="Számlakibocsátó">Számlakibocsátó</TableHeaderColumn>
        <TableHeaderColumn dataField="jt" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }} csvHeader="Jogtulajdonos">Jogtulajdonos</TableHeaderColumn>
        <TableHeaderColumn dataField="jtc" tdStyle={{ whiteSpace: 'normal' }} csvHeader="Jogtulajdonos címe">Jogtulajdonos címe</TableHeaderColumn>
        <TableHeaderColumn dataField="az" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }} csvHeader="Üdülési jog az.">Üdülési jog az.</TableHeaderColumn>
        <TableHeaderColumn dataField="ne" tdStyle={{ whiteSpace: 'normal', textAlign: 'right' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }} dataFormat={NumberFormatter.formatterSpaceOsszeg} csvHeader="Fenntartási díj össz. (nettó)">Fenntartási díj össz. (nettó)</TableHeaderColumn>
        <TableHeaderColumn dataField="br" tdStyle={{ whiteSpace: 'normal', textAlign: 'right' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }} dataFormat={NumberFormatter.formatterSpaceOsszeg} csvHeader="Fenntartási díj össz. (bruttó)">Fenntartási díj össz. (bruttó)</TableHeaderColumn>
        <TableHeaderColumn dataField="pe" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }} csvHeader="Pénznem">Pénznem</TableHeaderColumn>
        <TableHeaderColumn dataField="al" dataFormat={this.allapotFormatter} tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'SelectFilter', options: atadasAllapot, placeholder: 'Összes' }} csvHeader="Átadás állapot" csvFormat={(cell, row) => this.csvDecode(cell, row, atadasAllapot)}>Átadás állapot</TableHeaderColumn>
        <TableHeaderColumn dataField="ad" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }} csvHeader="Átadás dátum" csvFormat={cell => this.csvEmpty(cell)}>Átadás dátum</TableHeaderColumn>
        <TableHeaderColumn dataField="id" tdStyle={{ whiteSpace: 'normal' }} isKey dataFormat={this.iconFormatter} export={false}>Műveletek</TableHeaderColumn>
      </BootstrapTable>
    ) : <DataEmptyState />;
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  createFeedback = () => {
    let kijelolesszamInput = document.getElementById('kijeloldiv');
    let feedBack = document.getElementById('input_feedback');
    if (!feedBack) {
      feedBack = document.createElement('div');
      feedBack.id = 'input_feedback';
      if (kijelolesszamInput && feedBack) {
        kijelolesszamInput.appendChild(feedBack);
      }
    }
  }

  validateKijelolesszam = (result) => {
    this.createFeedback();
    let kijelolesszamInput = document.getElementById('kijelolesszam');
    let feedBack = document.getElementById('input_feedback');
    if (kijelolesszamInput && feedBack) {
      if (result < 1) {
        kijelolesszamInput.style.borderColor = 'red';
        feedBack.hidden = false;
        feedBack.style.color = 'red';
        feedBack.innerText = 'Csak 0-nál nagyobb pozitív számot lehet beírni!';
      }
      if (!(result % 1 === 0)) {
        kijelolesszamInput.style.borderColor = 'red';
        feedBack.style.color = 'red';
        feedBack.hidden = false;
        feedBack.innerText = 'Csak egész számot lehet beírni!';
      }
      if ((result % 1 === 0) && (result > 1) && (parseInt(result, 10) >= 1) && (parseInt(result, 10) % 1 === 0) || result === '') {
        kijelolesszamInput.style.borderColor = 'grey';
        feedBack.hidden = true;
      }
    }
  }

  filterNumber = (targetVal, filterVal, comparator) => {
    let valid = true;
    switch (comparator) {
      case '=': {
        if (targetVal !== filterVal) {
          valid = false;
        }
        break;
      }
      case '>': {
        if (targetVal <= filterVal) {
          valid = false;
        }
        break;
      }
      case '<=': {
        if (targetVal > filterVal) {
          valid = false;
        }
        break;
      }
      default: {
        break;
      }
    }
    return valid;
  }

  filterText = (targetVal, filterVal) => {
    if (targetVal && targetVal.toString().toLowerCase().indexOf(filterVal) === -1) {
      return false;
    }

    return true;
  }

  render() {
    return (
      <div>
        {this.renderEloSzures()}
        <div className="row" hidden={!this.state.dataLoaded} style={{ lineHeight: '30px', height: '30px', marginLeft: '2px' }}>
          Az első &nbsp;
          <div className="col-md-1">
            <Input type="text" className="kijelolesszam" style={{ WebkitBoxShadow: 'none' }} onBlur={this.validateKijelolesszam(this.state.kijelolesszam)} pattern="0[0-9]*[1-9][0-9]*$" id="kijelolesszam" value={this.state.kijelolesszam} onChange={v => this.handleKijelolesszamChange(v)} />
          </div>
          <div className="col-md-2">
            tétel kijelölése:
          </div>
          <div id="kijeloldiv" />
        </div>
        <div className="margin-top-30">
          { this.state.queryStarted ? this.renderLoader() : this.state.dataLoaded ? this.renderTable() : <QueryEmptyState /> }
        </div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_SZAMLAKULDES', 'KARB') && this.renderSendButton()}
        <Modal isOpen={this.state.modalNezet} toggle={this.toggleNezet} className={this.props.className} backdrop="static">
          <ModalHeader>
            Megtekintés
          </ModalHeader>
          <ModalBody>{this.state.modalNezet ? this.renderAdatok(this._currentViewId) : <div />}</ModalBody>
          <ModalFooter>
            {' '}
            <Button type="button" color="primary" onClick={this.toggleNezet} className="btn-cursor-pointer">Vissza</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalUdulesijog} toggle={this.toggleUdulesijog} className={this.props.className} backdrop="static" size="lg">
          <ModalHeader>
            Üdülési jogok
          </ModalHeader>
          <ModalBody>
            {this.state.dataUdulesijogLoaded ? (
              <BootstrapTable data={this.state.udulesijogJson} pagination>
                <TableHeaderColumn isKey dataField="azonosito" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Azonosító</TableHeaderColumn>
                <TableHeaderColumn dataField="apartman" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Apartman</TableHeaderColumn>
                <TableHeaderColumn dataField="ev" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Év</TableHeaderColumn>
                <TableHeaderColumn dataField="het" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Hét</TableHeaderColumn>
                <TableHeaderColumn dataField="datum" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Dátum</TableHeaderColumn>
              </BootstrapTable>
            ) : this.renderLoader()}
          </ModalBody>
          <ModalFooter>
            {' '}
            <Button type="button" color="primary" onClick={this.toggleUdulesijog} className="btn-cursor-pointer">Vissza</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalSikertelenTorles} toggle={this.toggleSikertelenTorles} className={this.props.className} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>
            Valóban törölni kívánja a kiválasztott tételt?
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteSikertelenSzamlakuldes(this._currentDeleteId)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleSikertelenTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalModositas} toggle={this.toggleModositas} className={this.props.className} backdrop="static" size="lg">
          <AvForm onValidSubmit={this.toggleModositas}>
            <ModalHeader>
              Módosítás
            </ModalHeader>
            <ModalBody>
              { this.state.dataModifyLoaded ? (
                <div className="row">
                  {this.renderModositoForm()}
                </div>
              )
                : this.renderLoader()}
            </ModalBody>
            <ModalFooter>
              <Button type="button" color="primary" onClick={this.modositas}>Mentés</Button>
              {' '}
              <Button type="button" color="secondary" onClick={this.toggleModositas}>Mégse</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>
            Valóban törölni kívánja a kiválasztott tétel(eke)t?
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.torles(this.state.selected)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

SzamlaKuldesLista.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool
};

export default SzamlaKuldesLista;
