import Lib from '../../commons/lib';

export default class Service {
  static szerzodesUrl = window.location.origin + '/api/szerzodes/';

  static udulesijogUrl = window.location.origin + '/api/udulesijog/';

  static szamlazasUrl = window.location.origin + '/api/udulesijogszla/';

  static apartmanUrl = window.location.origin + '/api/apartman/';

  static penzugyUrl = window.location.origin + '/api/penzugykozos/';

  static partnerUrl = window.location.origin + '/api/partner/';

  // SZERZŐDÉS START
  static listSzerzodes(szallitoid, vevoid, bizonylatszam, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szerzodesUrl + 'szerzodes/list',
      args: { requestType: 'szerzodes-list' },
      params: {
        szallitoid: szallitoid,
        vevoid: vevoid,
        bizszam: bizonylatszam
      }
    }], fnDone);
  }

  static listSzallitoVevo(type, text, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'partnerUrl-search' },
      params: {
        type: type,
        text: text
      }
    }], fnDone);
  }

  static listSzerzodesDokumentumok(szerzodesid, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szerzodesUrl + 'szerzodes/list',
      args: { requestType: 'szerzodes-list' },
      params: { id: szerzodesid }
    }], fnDone);
  }

  static getAktivPartnerByCsoport(type, text, id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'search-getlist' },
      params: {
        type: type,
        text: text,
        id: id
      }
    }], fnDone);
  }

  static listSzerzodesTipus(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szerzodesUrl + 'szerzodestipus/list',
      args: { requestType: 'szerzodestipus-list' }
    }], fnDone);
  }

  static getSzerzodes(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szerzodesUrl + 'szerzodes',
      args: { requestType: 'szerzodes-get' },
      params: { id: id }
    }], fnDone);
  }

  static initFormSzerzodes(id, isView, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szerzodesUrl + 'szerzodes/init',
      args: { requestType: 'szerzodes-init' },
      params: { id: id, view: isView }
    }], fnDone);
  }

  static createSzerzodes(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.szerzodesUrl + 'szerzodes',
      args: { requestType: 'szerzodes-create' },
      data: data,
    }], fnDone);
  }

  static listAktivPenznem(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'penznem/aktiv',
      args: { requestType: 'penznem-list' }
    }], fnDone);
  }

  static listAktivFizetesimod(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'fizmod/aktiv',
      args: { requestType: 'fizmod-list' }
    }], fnDone);
  }

  static deleteSzerzodes(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.szerzodesUrl + 'szerzodes',
      args: { requestType: 'szerzodes-delete' },
      params: { id: id }
    }], fnDone);
  }

  static updateSzerzodes(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.szerzodesUrl + 'szerzodes',
      args: { requestType: 'szerzodes-update' },
      data: data,
    }], fnDone);
  }

  static downloadObject(objid, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szerzodesUrl + 'szerzodes/object',
      args: { requestType: 'szerzodes-get-object' },
      acceptType: 'application/octet-stream',
      params: { id: objid }
    }], fnDone);
  }

  static listBizonylatFilterValues(text, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'udulesijog/searchazonosito',
      params: {
        azon: text
      }
    }], fnDone);
  }

  static listSzerzodesBizonylatFilterValues(text, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szerzodesUrl + 'szerzodes/searchbizonylat',
      params: {
        bizszam: text
      }
    }], fnDone);
  }


  // SZERZŐDÉS END

  // ÜDÜLÉSIJOG START

  static listAktivSzallodak(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'szalloda/listudulesijogszallodak',
      args: { requestType: 'udulesijog-listudulesijogszallodak' }
    }], fnDone);
  }

  static listAktivEpuletek(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'epulet/listudulesijogepuletek',
      args: { requestType: 'udulesijog-listudulesijogepuletek' }
    }], fnDone);
  }

  static listAktivEpuletekById(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'epulet/listudulesijogepuletek',
      args: { requestType: 'udulesijog-listudulesijogepuletek' },
      params: { id: id }
    }], fnDone);
  }

  static listUdulesijog(ev, szallodaid, epuletid, partnerid, kompenzacio, azon, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'udulesijog/list',
      args: { requestType: 'udulesijog-list' },
      params: {
        ev: ev,
        szallodaid: szallodaid,
        epuletid: epuletid,
        partnerid: partnerid,
        azon: azon
      }
    }], fnDone);
  }

  static listUdulesijogBySzerzodesId(szerzodesId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'udulesijog/list/szerzodes',
      args: { requestType: 'udulesijog-list-szerzodes' },
      params: { id: szerzodesId }
    }], fnDone);
  }

  static listSzerzodesByUdulesijogId(udulesijogId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szerzodesUrl + 'szerzodes/list/udulesijog',
      args: { requestType: 'szerzodes-list-udulesijog' },
      params: { id: udulesijogId }
    }], fnDone);
  }

  static listSzallodak(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'szalloda/aktiv',
      args: { requestType: 'szalloda-aktiv-list' },
    }], fnDone);
  }

  static listEpuletekById(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'epulet/aktiv',
      args: { requestType: 'epulet-aktiv-list' },
      params: { id: id }
    }], fnDone);
  }

  static listApartmanokById(epulet, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'apartman/getepuletapartman',
      args: { requestType: 'epulet-getepuletapartman-list' },
      params: { epulet: epulet }
    }], fnDone);
  }

  static getUdulesijog(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'udulesijog',
      args: { requestType: 'udulesijog-get' },
      params: { id: id }
    }], fnDone);
  }

  static initForm(id, isView, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'udulesijog/init',
      args: { requestType: 'udulesijog-init' },
      params: { id: id, view: isView }
    }], fnDone);
  }

  static getRciData(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'udulesijog/deponalasok',
      args: { requestType: 'udulesijog-deponalasok' },
      params: { udulesijogId: id }
    }], fnDone);
  }

  static deleteUdulesijog(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.udulesijogUrl + 'udulesijog',
      args: { requestType: 'udulesijog-delete' },
      params: { id: id }
    }], fnDone);
  }

  static createUdulesijog(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.udulesijogUrl + 'udulesijog',
      args: { requestType: 'udulesijog-create' },
      data: data,
    }], fnDone);
  }

  static updateUdulesijog(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.udulesijogUrl + 'udulesijog',
      args: { requestType: 'udulesijog-update' },
      data: data,
    }], fnDone);
  }

  static getSzezonSzin(apartmanid, sorszam, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'apartman/getapartmanbean',
      args: { requestType: 'apartman-get-apartmanbean' },
      params: {
        id: apartmanid,
        sorszam: sorszam,
        role: true
      }
    }], fnDone);
  }

  // static listSzamlazo(type ,name , fnDone) {
  //   Lib.MicroServices.requestService([{
  //     method: 'GET',
  //     url: Service.partnerUrl + 'search',
  //     args: { requestType: 'szerzodestipus-list' },
  //     params: {
  //       tipus: 'APSZLA',
  //       nev: nev
  //     }
  //   }], fnDone);
  // }

  static listUdulesijogTulaj(type, text, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'partner-search' },
      params: {
        type: type,
        text: text
      }
    }], fnDone);
  }

  static updateSzamlazasfelfuggesztve(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.udulesijogUrl + 'udulesijog/updateszamlazasfelfuggesztve',
      args: { requestType: 'udulesijog-updateszamlazasfelfuggesztve' },
      data: data,
    }], fnDone);
  }

  static initSzamlafelfuggesztes(ids, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.udulesijogUrl + 'udulesijog/initszamlazasfelfuggesztve',
      args: { requestType: 'udulesijog-initszamlazasfelfuggesztve' },
      data: ids,
    }], fnDone);
  }

  // ÜDÜLÉSIJOG END

  // ÉVESDÍJ START

  static getEvesdijEv(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'evesdij/getdistinctev',
      args: { requestType: 'evesdij-getev' }
    }], fnDone);
  }

  static getEvesdijUdulesijog(nev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'evesdij/getudulesijogazonbynev',
      args: { requestType: 'evesdij-getudulesijogazonbynev' },
      params: { nev: nev }
    }], fnDone);
  }

  static listUdulesijogSzallodak(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'szalloda/listudulesijogszallodak',
      args: { requestType: 'szalloda-listudulesijogszallodak' }
    }], fnDone);
  }

  static listEvesdijEpulet(szallodaId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'epulet/aktiv',
      args: { requestType: 'epulet-aktiv' },
      params: { id: szallodaId }
    }], fnDone);
  }

  static getSzamlakibocsato(nev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'evesdij/searchpartner',
      args: { requestType: 'evesdij-searchpartner' },
      params: { nev: nev }
    }], fnDone);
  }

  static getEvesdijList(ev, szallodaId, epuletId, udulesijogId, het, kibocsatoId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'evesdij/list',
      args: { requestType: 'evesdij-list' },
      params: {
        ev: ev,
        szalloda: szallodaId,
        epulet: epuletId,
        udulesijog: udulesijogId,
        het: het,
        kibocsato: kibocsatoId
      }
    }], fnDone);
  }

  static getSzamlafelfuggesztes(evesdijId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'szlafelfugg',
      args: { requestType: 'szlafelfugg-get' },
      params: { id: evesdijId }
    }], fnDone);
  }

  static updateSzamlafelfuggesztes(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.udulesijogUrl + 'szlafelfugg',
      args: { requestType: 'szlafelfugg-get' },
      data: data
    }], fnDone);
  }

  // ÉVESDÍJ END

  // Máté Kompenzáció

  static getKompenzacioTetel(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'kompenzacio',
      args: { requestType: 'Kompenzacio-get' },
      params: { id: id }
    }
    ], fnDone);
  }

  static getKompenzacioTetelek(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'kompenzacio/list',
      args: { requestType: 'Kompenzacio-list' },
      params: { id: id }
    }], fnDone);
  }

  static insertKompenzacioTetel(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.udulesijogUrl + 'kompenzacio',
      args: { requestType: 'Kompenzacio-insert' },
      data: data,
    }], fnDone);
  }

  static deleteKompenzacioTetel(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.udulesijogUrl + 'kompenzacio',
      args: { requestType: 'kompenzacio-delete' },
      params: { id: id }
    }], fnDone);
  }

  static updateKompenzacioTetel(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.udulesijogUrl + 'kompenzacio',
      args: { requestType: 'Kompenzacio-update' },
      data: data,
    }], fnDone);
  }

  static updateUdulesijogKompenzaciostatuszVegleges(submitObj, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.udulesijogUrl + 'udulesijog/vegleges',
      args: { requestType: 'udulesijog-updatekompstatus' },
      data: submitObj,
    }], fnDone);
  }

  static updateUdulesijogkompenzaciostatuszLezar(submitObj, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.udulesijogUrl + 'udulesijog/lezar',
      args: { requestType: 'udulesijog-updatekompstatuslezar' },
      data: submitObj,
    }], fnDone);
  }

  static updateKompenzacioTetelStatusz(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.udulesijogUrl + 'kompenzacio/lezart',
      args: { requestType: 'kompenzacio-delete' },
      params: { kompenzacioEntityId: id }
    }], fnDone);
  }

  // Részvények
  static getReszvenyek(uid, jogid, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'reszveny',
      args: { requestType: 'Reszveny-list' },
      params: {
        udulesiid: uid,
        jogtulajid: jogid
      }
    }], fnDone);
  }

  static getUdulesReszvenyek(uid, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'reszveny/udulesilista',
      args: { requestType: 'Reszveny-Udules-list' },
      params: {
        udulesiid: uid,
      }
    }], fnDone);
  }


  static setReszvenyek(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.udulesijogUrl + 'reszveny',
      args: { requestType: 'Reszveny-insert' },
      data: data,
    }], fnDone);
  }

  static deleteRszveny(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.udulesijogUrl + 'reszveny',
      args: { requestType: 'Reszveny-delete' },
      params: { id: id }
    }], fnDone);
  }

  static getRCIhet(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'naptar/getrcihet',
      args: { requestType: 'udulesjogszla-rcihet' },
    }], fnDone);
  }

  static loadRciAdatok(szallodaId, hetKezdet, hetVege, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'udulesijog/rci',
      args: { requestType: 'udulesjogs-rci' },
      params: {
        szallodaId,
        hetKezdet,
        hetVege
      }
    }], fnDone);
  }

  static getHostwareNaploList(udjogAzon, datum, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'udulesijog/reservation/naplo',
      args: { requestType: 'udulesijog-hwnaplo' },
      params: {
        udjogazon: udjogAzon,
        datum: datum
      }
    }], fnDone);
  }

  static deleteHostwareNaploList(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.udulesijogUrl + 'udulesijog/reservation/naplo',
      args: { requestType: 'udulesijog-hwnaplo-del' },
    }], fnDone);
  }
}
