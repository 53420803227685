import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Collapse,
  Badge
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import _ from 'lodash';
import SelectAsync from 'react-select/lib/Async';
import Select from 'react-select';
import filterFactory from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import memoize from 'memoize-one';
import { listenerCount } from 'superagent';
import NetworkEmptyState from '../../commons/networkemptystate';
import Loader from '../../commons/loader';
import Services from './services';
import { PaginationTotal } from '../../commons/controls';
import NumberFormatter from '../../commons/NumberFormatter';
import CustomTooltip from '../../components/Tooltip/CustomTooltip';
import getTooltip from './MunkalapTooltips';

class MunkalapCikkCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // ['LOAD', 'DATA', 'NETW']
      cikkCardStatus: 'DATA',
      cikkJson: [],
      modalCikkFoglalas: false,
      collapseArazas: false,
      isEdit: false,

      formMunkalapCikkId: null,
      formCikkRendelesOptions: [],
      formCikk: null,
      formRaktar: null,
      formCikkKedvezmenyOptions: [],
      formCikkKiadandoMennyiseg: null,
      formRaktaronKivul: false,
      formRaktarOptions: [],
      formCikkEladasiAr: null,
    };
  }

  componentDidMount() {
    this.getCikkList();
  }

  toggleCikkFoglalasModal = () => {
    this.setState(prevState => ({ modalCikkFoglalas: !prevState.modalCikkFoglalas }));
  }

  toggleTorles = () => {
    this.setState(prevState => ({ modalTorles: !prevState.modalTorles }));
  }

  toggleArazas = () => {
    this.setState(prevState => ({ collapseArazas: !prevState.collapseArazas }));
  }

  onCikkFoglalasClick = () => {
    this.clearFormFields();
    this.createCikkSelectRendelesList();
    this.setState({ modalCikkFoglalas: true, isEdit: false });
  }

  loadCikkChange = (input, callback) => {
    const { formRaktaronKivul, formCikkRendelesOptions } = this.state;
    if (input.length > 0) {
      Services.queryCikk(input, formRaktaronKivul, true, true, (err, res) => {
        if (!err) {
          res[0] = res[0].map((c) => {
            return {
              id: c.id,
              cikknev: c.megnevezes,
              cikkszam: c.cikkszam,
              mennyisegiegyseg: c.mennyiseg && c.mennyiseg.megnevezes,
              afa: c.aktualisAfaErtek,
              tipus: c.tipus
            };
          });
          let array = [{
            label: 'Cikktörzs',
            options: res[0]
          }];
          callback(formCikkRendelesOptions.concat(array));
        }
      });
    } else {
      callback(formCikkRendelesOptions);
    }
  }

  getCikkList = (munkalapIdForm) => {
    let munkalapId = munkalapIdForm || this.props.munkalapId;
    if (munkalapId && munkalapId !== '') {
      this.setState({
        cikkCardStatus: 'LOAD'
      });
      Services.getCikkInit(munkalapId, (err, res) => {
        if (!err) {
          const { cikkek, kedvezmenyek } = res[0].munkalap;
          this.setState({
            cikkCardStatus: 'DATA',
            cikkJson: cikkek || [],
            formCikkKedvezmenyOptions: kedvezmenyek
          });
        } else {
          this.setState({
            cikkCardStatus: 'NETW'
          });
        }
      });
    }
  }

  createCikkSelectRendelesList = () => {
    const { specialisok } = this.props;
    let object = {};
    let array = [];
    if (specialisok) {
      specialisok.filter(s => s.forras === 'RENDELES').forEach((rendeles) => {
        if (!object[rendeles.feladat.bizonylatszam] && rendeles.feladat.cikkek) {
          object[rendeles.feladat.bizonylatszam] = rendeles.feladat.cikkek.map((c) => {
            return {
              ...c, funkcioseged: 'VEVOI_RENDELES', munkalapspecialisid: rendeles.id, szerviztetelid: c.id, id: c.cikkid
            };
          });
        }
      });
      for (const [bizonylatszam, cikkek] of Object.entries(object)) {
        array.push({
          label: 'Vevői rendelés - ' + bizonylatszam,
          options: cikkek
        });
      }
      this.setState({ formCikkRendelesOptions: array });
    }
  }

  createSzolgaltatasList = memoize(
    (specialisok) => {
      let array = [];
      if (!specialisok) return array;
      specialisok.filter(s => s.forras === 'RENDELES').forEach((rendeles) => {
        if (rendeles.feladat)
          array.push(...rendeles.feladat.cikkek.filter(cikk => cikk.tipus === 'SZ'));
      });
      return array;
    }
  );

  deleteCikk = () => {
    Services.deleteCikk(this.state.torlesId, (err) => {
      if (!err) {
        this.toggleTorles();
        this.getCikkList();
        this.props.globals.addSuccess('Cikk törölve!');
      }
    });
  }

  clearFormFields = () => {
    this.setState({
      formMunkalapCikkId: null,
      formCikk: null,
      formRaktar: null,
      formRaktarOptions: [],
      formCikkKiadandoMennyiseg: null,
      formCikkNettoEgysegAr: null,
      formCikkNettoErtek: null,
      formCikkBruttoEgysegAr: null,
      formCikkBruttoErtek: null,
      formCikkKedvezmenyMertek: null,
      formCikkKedvezmeny: null,
      formCikkEladasiAr: null,
    });
  }

  handleCikkEdit = (id) => {
    this.createCikkSelectRendelesList();
    this.setState((prevState) => {
      const cikk = prevState.cikkJson.find(c => c.sajatid === id);
      return {
        modalCikkFoglalas: true,
        isEdit: true,

        formMunkalapCikkId: cikk.sajatid,
        formRaktaronKivul: false,
        formCikk: cikk,
        formRaktar: cikk.raktarnev && { nev: cikk.raktarnev },
        formRaktarOptions: [],
        formCikkKiadandoMennyiseg: cikk.mennyiseg,
        formCikkNettoEgysegAr: cikk.nettoegysegar,
        formCikkNettoErtek: cikk.nettoar,
        formCikkBruttoEgysegAr: cikk.bruttoegysegar,
        formCikkBruttoErtek: cikk.bruttoar,
        formCikkKedvezmeny: cikk.kedvezmenytipus,
        formCikkKedvezmenyMertek: cikk.kedvezmenymertek,
        formCikkEladasiAr: cikk.eladasiar,
      };
    });
  }

  handleCikkDelete = (id) => {
    this.setState({ modalTorles: true, torlesId: id });
  }

  handleRaktarStatusChange = (event) => {
    this.setState({
      formRaktaronKivul: event.target.value === 'true',
      formCikk: null,
      formCikkBruttoErtek: null,
      formCikkKedvezmeny: null,
      formCikkKedvezmenyMertek: null,
      formCikkKiadandoMennyiseg: null,
      formCikkNettoEgysegAr: null,
      formRaktar: null,
    });
  }

  handleCikkChange = (cikk) => {
    const { formCikkKiadandoMennyiseg } = this.state;
    this.setState({ formRaktar: null });
    if (cikk) {
      Services.getRaktarList(cikk.id, (err, res) => {
        if (!err) {
          let array = res[0].map((keszletinfo) => {
            return {
              raktarid: keszletinfo.raktar.id,
              nev: keszletinfo.raktar.nev,
              teljes: keszletinfo.teljesMennyiseg,
              foglalt: keszletinfo.foglaltMennyiseg,
              szabad: keszletinfo.szabadMennyiseg
            };
          });
          this.setState({ formRaktarOptions: array });
        }
      });
      // Ha vevői rendelés csoportból lett választva
      if (cikk && cikk.funkcioseged === 'VEVOI_RENDELES') {
        let mennyiseg = cikk.mennyiseg - cikk.foglalt - cikk.kiadott;
        if (mennyiseg <= 0) mennyiseg = null;
        Services.getCikk(cikk.id, (err, res) => {
          if (!err) {
            let formCikk = {
              id: res[0].id,
              cikknev: res[0].megnevezes,
              cikkszam: res[0].cikkszam,
              mennyisegiegyseg: res[0].mennyisegiEgyseg,
              afa: res[0].afak && res[0].afak[0] && res[0].afak[0].afaErtek,
              munkalapspecialisid: cikk.munkalapspecialisid,
              szerviztetelid: cikk.szerviztetelid,
              tipus: cikk.tipus
            };
            this.setState({ formCikkKiadandoMennyiseg: mennyiseg, formCikk });
            this.setPrices(cikk.nettoegysegar, null, mennyiseg, formCikk);
          }
        });
      } else {
        this.setState({ formCikk: cikk });
      }
    } else {
      this.clearFormFields();
    }
  }

  handleCikkKiadandoMennyisegChange = (event) => {
    let mennyiseg = event.target.value;
    this.setPrices(this.state.formCikkNettoEgysegAr, null, mennyiseg);
    this.setState({ formCikkKiadandoMennyiseg: mennyiseg });
  }

  handleCikkKedvezmenyMertekChange = (event) => {
    this.setState({ formCikkKedvezmenyMertek: event.target.value }, () => { this.calculateEladasiAr(); });
  }

  handleCikkKedvezmenyChange = (value) => {
    this.setState({ formCikkKedvezmeny: value }, () => { this.calculateEladasiAr(); });
  }

  setPrices = (nettoValue, bruttoValue, mennyiseg, formCikk) => {
    // TODO pénznem kezelést berakni amikor kialakult
    const decDigit = 6;
    const { afa } = formCikk || this.state.formCikk;
    if (!mennyiseg) mennyiseg = 1;
    let nettoEgyseg;
    let nettoErtek;
    let bruttoEgyseg;
    let bruttoErtek;
    // Number(Number(x).toFixed(y)) kerekít felesleges 0-k nélkül
    if (nettoValue) {
      if (typeof nettoValue === 'string') nettoValue = NumberFormatter.removeSpacesFromString(nettoValue);
      nettoEgyseg = nettoValue;
      nettoErtek = Math.round(nettoEgyseg * mennyiseg);
      bruttoEgyseg = Number(Number(Number(nettoEgyseg) + (nettoEgyseg * afa / 100)).toFixed(decDigit));
      bruttoErtek = Number(nettoErtek) + Math.round(nettoErtek * afa / 100);
    } else if (bruttoValue) {
      if (typeof bruttoValue === 'string') bruttoValue = NumberFormatter.removeSpacesFromString(bruttoValue);
      bruttoErtek = Math.round(bruttoValue);
      nettoErtek = Math.round(bruttoErtek / (1 + (afa / 100)));
      bruttoEgyseg = Number(Number(bruttoErtek / mennyiseg).toFixed(decDigit));
      nettoEgyseg = Number(Number(nettoErtek / mennyiseg).toFixed(decDigit));
    }
    this.setState({
      formCikkNettoEgysegAr: nettoEgyseg,
      formCikkNettoErtek: nettoErtek,
      formCikkBruttoEgysegAr: bruttoEgyseg,
      formCikkBruttoErtek: bruttoErtek
    }, () => { this.calculateEladasiAr(); });
  }

  calculateEladasiAr = () => {
    let eladasiAr;
    const { formCikkBruttoErtek, formCikkKedvezmeny, formCikkKedvezmenyMertek } = this.state;
    if (!formCikkKedvezmeny || !formCikkKedvezmenyMertek) eladasiAr = formCikkBruttoErtek;
    else if (formCikkKedvezmeny.name === 'SZAZALEK') eladasiAr = Math.round(formCikkBruttoErtek * (100 - formCikkKedvezmenyMertek) / 100);
    else eladasiAr = formCikkBruttoErtek - formCikkKedvezmenyMertek;
    this.setState({ formCikkEladasiAr: eladasiAr });
  }

  checkFields = () => {
    const {
      formCikk,
      formCikkKiadandoMennyiseg,
      formCikkKedvezmeny,
      formCikkKedvezmenyMertek,
      formCikkEladasiAr,
      formRaktar,
    } = this.state;
    if (!formCikk) {
      this.props.globals.addError('Cikk választása kötelező!');
    } else if (!formCikkKiadandoMennyiseg) {
      this.props.globals.addError('Mennyiség megadása kötelező!');
    } else if (!formCikkKedvezmeny !== !formCikkKedvezmenyMertek) {
      // logikai kizáró vagy
      this.props.globals.addError('Kedvezmény megadása esetén mindkét mező kitöltése kötelező!');
    } else if (formCikkKedvezmeny && formCikkKedvezmeny.name === 'SZAZALEK' && formCikkKedvezmenyMertek > 100) {
      this.props.globals.addError('A kedvezmény mértéke nem lehet nagyobb 100%-nál!');
    } else if (formCikkKedvezmeny && formCikkKedvezmeny.name === 'OSSZEG' && formCikkEladasiAr < 0) {
      // formCikkEladasiAr = formCikkBruttoErtek - formCikkKedvezmenyMertek
      this.props.globals.addError('A kedvezmény mértéke nem lehet nagyobb mint a bruttó érték!');
    } else if (formRaktar && formRaktar.szabad < formCikkKiadandoMennyiseg) {
      this.props.globals.addError('Nincs elég szabad készlet a raktárban!');
    } else {
      return true;
    }
    return false;
  }

  generateCikkObject = () => {
    const {
      formMunkalapCikkId,
      formCikk,
      formRaktar,
      formCikkKiadandoMennyiseg,
      formCikkNettoEgysegAr,
      formCikkKedvezmenyMertek,
      formCikkKedvezmeny,
    } = this.state;
    const { emptyOrNull } = this.props;
    return {
      id: formMunkalapCikkId,
      cikkObj: formCikk,
      munkalapspecialisid: formCikk.munkalapspecialisid,
      szerviztetelid: formCikk.szerviztetelid,
      cikk: formCikk.cikknev,
      cikkid: formCikk.id,
      kedvezmeny: emptyOrNull(formCikkKedvezmenyMertek),
      tipus: formCikkKedvezmeny,
      mennyiseg: formCikkKiadandoMennyiseg,
      nettoegysegar: emptyOrNull(formCikkNettoEgysegAr),
      raktar: formRaktar,
      raktarid: formRaktar && formRaktar.raktarid,
    };
  }

  onMentes = (hozzaadas) => {
    const { formCikk, formRaktar, formRaktaronKivul } = this.state;
    if (!formRaktaronKivul && !formRaktar && formCikk && formCikk.tipus === 'T') {
      this.props.globals.addError('Ha nem raktáron kívüli a cikk, akkor a raktár választása kötelező!');
      return;
    }
    let array = [];
    if (formCikk) {
      if (!this.checkFields()) return;
      array.push(this.generateCikkObject());
    }
    if (!formCikk && !array.length) {
      this.props.globals.addError('Mentéshez cikk adatok kitöltése kötelező!');
      return;
    }
    array.forEach((item) => {
      item.raktar = null;
      if (item.tipus) item.tipus = item.tipus.name;
    });
    if (!this.state.isEdit) {
      Services.saveCikk(this.props.munkalapId, array, (err) => {
        if (!err) {
          if (hozzaadas) {
            this.clearFormFields();
          } else {
            this.toggleCikkFoglalasModal();
          }
          this.getCikkList();
          this.props.globals.addSuccess('Cikk foglalva!');
        }
      });
    } else {
      Services.editCikk(array[0], (err) => {
        if (!err) {
          this.toggleCikkFoglalasModal();
          this.getCikkList();
          this.props.globals.addSuccess('Cikk módosítva!');
        }
      });
    }
  }

  iconFormatter = (cell) => {
    return (
      <>
        <Button className="icon-pencil table-action-icon" id={'munkalap-cikk-edit-' + cell} onClick={() => this.handleCikkEdit(cell)} />
        <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'munkalap-cikk-edit-' + cell} delay={40}>
          {'Módosítás'}
        </UncontrolledTooltip>
        <Button className="icon-trash table-action-icon" id={'munkalap-cikk-delete-' + cell} onClick={() => this.handleCikkDelete(cell)} />
        <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'munkalap-cikk-delete-' + cell} delay={40}>
          {'Törlés'}
        </UncontrolledTooltip>
      </>
    );
  }

  // TODO átrakni, hogy ne beégetett pénznem legyen
  // Be van égetve a pénznem, mert nincs még kezelve
  formatDashOnEmptyCell = (cell, penznem) => {
    if (!cell) return '-';
    if (penznem) {
      return NumberFormatter.formatterSpaceOsszegPenznem(cell, { penznem: { jel: penznem } });
    }
    return cell;
  }

  styleColumnAlignCenter = (cell) => {
    if (cell) {
      return {
        whiteSpace: 'pre-line',
        textAlign: 'right'
      };
    }
    return {
      whiteSpace: 'pre-line',
      textAlign: 'center'
    };
  }

  gridRowBreak = () => {
    return <div className="w-100 d-md-none d-lg-block" />;
  }

  renderCikkSelectLogic = () => {
    const buttonStyle = {
      marginLeft: 5,
      marginRight: 5,
    };

    return (
      <>
        <div className="row">
          <div className="col-md-12 col-lg-9" style={{ marginBottom: 16 }}>
            <Label for="formCikk">
              Cikk keresés:
              <CustomTooltip text={getTooltip('cikk_valszto')} id="cikk_valsztoTT" />
            </Label>

            <SelectAsync
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="formCikk"
              id="formCikk"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={this.handleCikkChange}
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadCikkChange, 1000)}
              defaultOptions
              getOptionLabel={option => option.cikknev}
              getOptionValue={option => option}
              isLoading={false}
              isDisabled={this.state.isEdit}
              value={this.state.formCikk}
              cache={false}
              autoload={false}
            />
          </div>
          <div className="col-md-12 col-lg-3">
            <FormGroup>
              <Label for="formRaktaronKivuli">Raktáron kívüli cikk:</Label>
              <Input
                type="select"
                name="formRaktaronKivuli"
                id="formRaktaronKivuli"
                value={this.state.formRaktaronKivul}
                disabled={this.state.isEdit}
                onChange={this.handleRaktarStatusChange}
              >
                <option value="false">Nem</option>
                <option value="true">Igen</option>
              </Input>
            </FormGroup>
          </div>
          <div className="col-md-12 col-lg-9">
            <FormGroup>
              <Label for="formCikkmegnevezes">Cikk megnevezés:</Label>
              <Input
                name="formCikkmegnevezes"
                id="formCikkmegnevezes"
                value={(this.state.formCikk && this.state.formCikk.cikknev) || ''}
                disabled
              />
            </FormGroup>
          </div>
          <div className="col-md-12 col-lg-3">
            <FormGroup>
              <Label for="formCikkszam">Cikkszám:</Label>
              <Input
                name="formCikkszam"
                id="formCikkszam"
                value={(this.state.formCikk && this.state.formCikk.cikkszam) || ''}
                disabled
              />
            </FormGroup>
          </div>
          <div className="col-md-12" style={{ marginBottom: 16 }}>
            <Label for="formRaktar">Raktár választása: </Label>
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="formRaktar"
              id="formRaktar"
              placeholder={!this.state.formRaktarOptions || !this.state.formRaktarOptions.length || this.state.formRaktaronKivul ? 'A kiválaszott cikk nem található egy raktárban sem' : 'Kezdjen el gépelni...'}
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.setState({ formRaktar: v || null })}
              value={this.state.formRaktar}
              isClearable
              ignoreAccents={false}
              options={this.state.formRaktarOptions}
              getOptionLabel={option => (
                <>
                  {option.nev}
                  {option.teljes
                  && (
                  <>
                    <Badge color="teljes" pill style={{ marginLeft: 5 }}>
                      {NumberFormatter.addSpacesToString(option.teljes, NumberFormatter.OSSZEG)}
                    </Badge>
                    <Badge color="foglalt" pill style={{ marginLeft: 5 }}>
                      {NumberFormatter.addSpacesToString(option.foglalt, NumberFormatter.OSSZEG)}
                    </Badge>
                    <Badge color="szabad" pill style={{ marginLeft: 5 }}>
                      {NumberFormatter.addSpacesToString(option.szabad, NumberFormatter.OSSZEG)}
                    </Badge>
                  </>
                  )
                  }
                </>
              )}
              getOptionValue={option => option}
              isLoading={false}
              isDisabled={this.state.formRaktaronKivul || this.state.isEdit || !this.state.formRaktarOptions || !this.state.formRaktarOptions.length}
              cache={false}
              autoload={false}
            />
          </div>
          <div className="col-md-6 col-lg-4 offset-lg-2">
            <FormGroup>
              <Label for="formCikkKiadandoMennyiseg">Kiadandó mennyiség:</Label>
              <Input
                name="formCikkKiadandoMennyiseg"
                id="formCikkKiadandoMennyiseg"
                onChange={this.handleCikkKiadandoMennyisegChange}
                value={this.state.formCikkKiadandoMennyiseg || ''}
                disabled={!this.state.formCikk}
              />
            </FormGroup>
          </div>
          <div className="col-md-6 col-lg-4">
            <FormGroup>
              <Label for="formCikkMennyisegiEgyseg">Mennyiségi egység:</Label>
              <Input
                name="formCikkMennyisegiEgyseg"
                id="formCikkMennyisegiEgyseg"
                value={(this.state.formCikk && this.state.formCikk.mennyisegiegyseg) || ''}
                disabled
              />
            </FormGroup>
          </div>
          <div className="col-md-4 col-lg-3 mb-1">
            <Button
              color="success"
              onClick={this.toggleArazas}
            >
              {'Cikk árazás '}
              {this.state.collapseArazas ? '↑' : '↓'}
            </Button>
          </div>
          <div className="w-100" />
          <div className="col">
            <Collapse isOpen={this.state.collapseArazas}>
              <div className="row">
                <div className="col-md-6 col-lg-3 offset-lg-0">
                  <FormGroup>
                    <Label for="formCikkNettoEgysegAr">Nettó egységár:</Label>
                    <Input
                      name="formCikkNettoEgysegAr"
                      id="formCikkNettoEgysegAr"
                      value={NumberFormatter.formatterSpaceOsszeg(this.state.formCikkNettoEgysegAr) || ''}
                      style={NumberFormatter.styleTextAlignWhiteSpace()}
                      onChange={e => this.setPrices(e.target.value, null, this.state.formCikkKiadandoMennyiseg)}
                      disabled={!this.state.formCikk || !this.state.formCikkKiadandoMennyiseg}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-6 col-lg-3">
                  <FormGroup>
                    <Label for="formCikkNettoEladasiAr">Nettó érték:</Label>
                    <Input
                      name="formCikkNettoEladasiAr"
                      id="formCikkNettoEladasiAr"
                      value={NumberFormatter.formatterSpaceOsszeg(this.state.formCikkNettoErtek) || ''}
                      style={NumberFormatter.styleTextAlignWhiteSpace()}
                      disabled
                    />
                  </FormGroup>
                </div>
                <div className="col-md-6 col-lg-3">
                  <FormGroup>
                    <Label for="formCikkAfa">Áfa:</Label>
                    <Input
                      name="formCikkAfa"
                      id="formCikkAfa"
                      value={(this.state.formCikk && this.state.formCikk.afa) || ''}
                      disabled
                    />
                  </FormGroup>
                </div>
                {this.gridRowBreak()}
                <div className="col-md-6 col-lg-3">
                  <FormGroup>
                    <Label for="formCikkBruttoEladasiEgysegAr">Bruttó egységár:</Label>
                    <Input
                      name="formCikkBruttoEladasiEgysegAr"
                      id="formCikkBruttoEladasiEgysegAr"
                      value={NumberFormatter.formatterSpaceOsszeg(this.state.formCikkBruttoEgysegAr) || ''}
                      style={NumberFormatter.styleTextAlignWhiteSpace()}
                      disabled
                    />
                  </FormGroup>
                </div>
                <div className="col-md-6 col-lg-3">
                  <FormGroup>
                    <Label for="formCikkBruttoErtek">Bruttó érték:</Label>
                    <Input
                      name="formCikkBruttoErtek"
                      id="formCikkBruttoErtek"
                      onChange={e => this.setPrices(null, e.target.value, this.state.formCikkKiadandoMennyiseg)}
                      value={NumberFormatter.formatterSpaceOsszeg(this.state.formCikkBruttoErtek) || ''}
                      style={NumberFormatter.styleTextAlignWhiteSpace()}
                      disabled={!this.state.formCikk || !this.state.formCikkKiadandoMennyiseg}
                    />
                  </FormGroup>
                </div>
                {this.gridRowBreak()}
                <div className="col-md-6 col-lg-3">
                  <FormGroup>
                    <Label for="formCikkKedvezmenyMertek">Kedvezmény mértéke:</Label>
                    <Input
                      name="formCikkKedvezmenyMertek"
                      id="formCikkKedvezmenyMertek"
                      value={this.state.formCikkKedvezmenyMertek || ''}
                      style={NumberFormatter.styleTextAlignWhiteSpace()}
                      onChange={this.handleCikkKedvezmenyMertekChange}
                      disabled={!this.state.formCikk || !this.state.formCikkKiadandoMennyiseg}
                    />
                  </FormGroup>
                </div>
                <div className="col-md-6 col-lg-3">
                  <Label for="formCikkKedvezmeny">Kedvezmény típusa:</Label>
                  <Select
                    styles={{
                      control: base => ({
                        ...base,
                        '&:focus': { borderColor: 'red' }, // border style on hover
                        border: '1px solid lightgray', // default border color
                        boxShadow: 'none', // no box-shadow
                        borderRadius: '0'
                      }),
                    }}
                    name="formCikkKedvezmeny"
                    id="formCikkKedvezmeny"
                    placeholder="Kezdjen el gépelni..."
                    noOptionsMessage={() => 'Nincs keresési eredmény'}
                    searchPromptText="Kezdjen el gépelni a kereséshez..."
                    loadingMessage={() => 'Keresés...'}
                    onChange={this.handleCikkKedvezmenyChange}
                    ignoreAccents={false}
                    options={this.state.formCikkKedvezmenyOptions}
                    getOptionLabel={option => option.caption}
                    getOptionValue={option => option}
                    value={this.state.formCikkKedvezmeny}
                    isDisabled={!this.state.formCikk || !this.state.formCikkKiadandoMennyiseg}
                    isClearable
                  />
                </div>
                {this.gridRowBreak()}
                <div className="col-md-6 col-lg-3">
                  <FormGroup>
                    <Label for="formCikkEladasiAr">Eladási ár:</Label>
                    <Input
                      name="formCikkEladasiAr"
                      id="formCikkEladasiAr"
                      value={NumberFormatter.formatterSpaceOsszeg(this.state.formCikkEladasiAr) || ''}
                      style={NumberFormatter.styleTextAlignWhiteSpace()}
                      disabled
                    />
                  </FormGroup>
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        <div className="row justify-content-end">
          <div style={{ paddingRight: 10 }}>
            {!this.state.isEdit && (
              <Button
                className="btn-cursor-pointer"
                color="success"
                onClick={() => this.onMentes(true)}
                style={buttonStyle}
                type="button"
              >
                {'Hozzáadás'}
              </Button>
            )}
            <Button
              className="btn-cursor-pointer"
              color="primary"
              onClick={() => this.onMentes(false)}
              style={buttonStyle}
              type="button"
            >
              {'Mentés'}
            </Button>
            <Button
              color="secondary"
              className="btn-cursor-pointer"
              onClick={this.toggleCikkFoglalasModal}
              style={buttonStyle}
              type="button"
            >
              Mégsem
            </Button>
          </div>
        </div>
      </>
    );
  }

  renderModalMain() {
    const { modalCikkFoglalas, isEdit } = this.state;
    return (
      <Modal isOpen={modalCikkFoglalas} toggle={this.toggleCikkFoglalasModal} backdrop="static" size="lg">
        <ModalHeader>
          {isEdit ? 'Cikk módosítása' : 'Új cikk foglalása'}
          <CustomTooltip text={getTooltip('uj_cikk_foglalasa')} id="uj_cikk_foglalasaTT" />
        </ModalHeader>
        <ModalBody>
          {this.renderCikkSelectLogic()}
        </ModalBody>
      </Modal>
    );
  }

  renderModalDelete() {
    return (
      <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} backdrop="static">
        <ModalHeader>Törlés</ModalHeader>
        <ModalBody>Valóban törölni kívánja a kiválasztott tételt?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => this.deleteCikk()}>Igen</Button>
          <Button type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
        </ModalFooter>
      </Modal>
    );
  }

  renderCardCikkLogic = (szolgaltatasList) => {
    const { cikkJson, cikkCardStatus } = this.state;
    const { isFolyamatban, isView, isLezart } = this.props;
    // TODO később lehet át kellene/lehetne írni isRogzitett-re, ami ezeknek az ellentetje lenne
    let dataArray = szolgaltatasList && !isFolyamatban && !isView && !isLezart ? [...szolgaltatasList, ...cikkJson] : cikkJson;

    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    let columns = [
      {
        dataField: 'cikkszam',
        text: 'Cikkszám',
        style: {
          whiteSpace: 'pre-line',
          textAlign: 'right'
        },
        sort: true
      },
      {
        dataField: 'cikknev',
        text: 'Megnevezés',
        style: {
          whiteSpace: 'pre-line',
          textAlign: 'right'
        },
        sort: true
      },
      {
        dataField: 'raktarnev',
        text: 'Raktár',
        style: this.styleColumnAlignCenter,
        sort: true,
        formatter: cell => (cell || '-')
      },
      {
        dataField: 'mennyiseg',
        text: 'Kiadandó mennyiség',
        style: {
          whiteSpace: 'pre-line',
          textAlign: 'right'
        },
        sort: true,
        formatter: (cell, row) => cell + ' ' + row.mennyisegiegyseg
      },
      {
        dataField: 'nettoegysegar',
        text: 'Nettó egységár',
        style: this.styleColumnAlignCenter,
        sort: true,
        formatter: cell => this.formatDashOnEmptyCell(cell, 'Ft')
      },
      {
        dataField: 'bruttoegysegar',
        text: 'Bruttó egységár',
        style: this.styleColumnAlignCenter,
        sort: true,
        formatter: cell => this.formatDashOnEmptyCell(cell, 'Ft')
      },
      {
        dataField: 'kedvezmenymertek',
        text: 'Kedvezmény',
        style: this.styleColumnAlignCenter,
        sort: true,
        formatter: (cell, row) => this.formatDashOnEmptyCell(cell, cell && row.kedvezmenytipus.name === 'SZAZALEK' ? '%' : 'Ft')
      },
      {
        dataField: 'eladasiar',
        text: 'Eladási ár',
        style: this.styleColumnAlignCenter,
        sort: true,
        formatter: (cell, row) => {
          // Ha automatikusan rögzített a szolgáltatás, akkor az eladási közvetlen ismeretlen, számolni kell
          if (row.nettoegysegar && !cell) {
            return this.formatDashOnEmptyCell(Math.round(row.bruttoegysegar * row.mennyiseg), 'Ft');
          }
          return this.formatDashOnEmptyCell(cell, 'Ft');
        }
      },

    ];

    if (isFolyamatban) {
      columns.push(
        {
          dataField: 'sajatid',
          text: 'Műveletek',
          formatter: this.iconFormatter
        },
      );
    }

    return (
      <div className="row">
        {isFolyamatban
          && (
            <div className="col-md-12" style={{ marginBottom: 10 }}>
              <Button
                color="success"
                onClick={this.onCikkFoglalasClick}
              >
                Új cikk foglalása
              </Button>
            </div>
          )
        }
        {{
          LOAD: <Loader />,
          NETW: <NetworkEmptyState hasImage submitClick={this.getCikkList} />,
          DATA: dataArray && dataArray.length > 0 && (
            <div className="col-md-12">
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={dataArray}
                columns={columns}
                filter={filterFactory()}
                pagination={paginationFactory(options)}
              />
            </div>
          )
        }[cikkCardStatus]}
      </div>
    );
  }

  render() {
    const szolgaltatasList = this.createSzolgaltatasList(this.props.specialisok);
    return (this.props.isFolyamatban || (szolgaltatasList && szolgaltatasList.length > 0) || this.state.cikkJson.length > 0) && (
      <>
        <div className="card">
          <div className="card-header">
            {'Cikk:'}
          </div>
          <div className="card-body-content">
            {this.renderCardCikkLogic(szolgaltatasList)}
          </div>
        </div>
        {this.renderModalMain()}
        {this.renderModalDelete()}
      </>
    );
  }
}

MunkalapCikkCard.propTypes = {
  munkalapId: PropTypes.number.isRequired,
  emptyOrNull: PropTypes.func.isRequired,
  isFolyamatban: PropTypes.bool.isRequired,
  isView: PropTypes.bool.isRequired,
  isLezart: PropTypes.bool.isRequired,
  specialisok: PropTypes.array,
  globals: PropTypes.shape({
    addSuccess: PropTypes.func.isRequired,
    addError: PropTypes.func.isRequired,
  }),
};

export default MunkalapCikkCard;
