import React, { Component } from 'react';
import { Button, Popover, PopoverHeader, PopoverBody, Modal, ModalHeader, ModalBody, ModalFooter, UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }).isRequired)
};

const targetid = 'video-info-button';


/**
 * Modal for user manual youtube vids.
 *
 * @version 1.0.0
 * @author [Nagy Gergely](https://github.com/gergely-nagy)
 */
class YouTube extends Component {
  constructor(props) {
    super(props);

    this.state = {
      popoverOpen: false,
      modalOpen: false,
      currentData: { text: '', id: '' },
      videoList: [],
    };
  }

  componentWillMount() {
    let dataList = [];
    for (let element of this.props.data) {
      dataList.push(<li key={element.id}><a href="#" onClick={() => this.handleModalClick(element.text, element.id)}>{element.text}</a></li>);
    }
    this.setState({ videoList: dataList });
  }

   toggle = () => {
     this.setState({
       popoverOpen: !this.state.popoverOpen
     });
   }

   toggleModal = () => {
     this.setState({
       popoverOpen: false,
       modalOpen: !this.state.modalOpen
     });
   }

   handleModalClick = (text, id) => {
     this.setState({ currentData: { text: text, id: id } });
     this.toggleModal();
   }

   render() {
     return (
       <span>
         <Button
           style={{ marginTop: 0 }}
           id={targetid}
           aria-label="Kezelési útmutató"
           className="fa fa-info-circle table-action-icon transp-icon-button"
           onClick={this.toggle}
         />
         <UncontrolledTooltip
           placement="top"
           target={targetid}
           delay={0}
         >
           {'Kezelési útmutató'}
         </UncontrolledTooltip>
         <Popover placement="bottom" isOpen={this.state.popoverOpen} target={targetid} toggle={this.toggle}>
           <PopoverHeader>Kezelési útmutató</PopoverHeader>
           <PopoverBody>
             <ul style={{ paddingLeft: 20 }}>
               {this.state.videoList}
             </ul>
           </PopoverBody>
         </Popover>
         <Modal
           isOpen={this.state.modalOpen}
           toggle={this.toggleModal}
           className={this.props.className}
           backdrop="static"
           size="lg"
         >
           <ModalHeader>{this.state.currentData.text}</ModalHeader>
           <ModalBody className="m-0">
             <div className="intrinsic-container intrinsic-container-16x9">
               <iframe
                 width="720"
                 height="480"
                 title={this.state.currentData.id}
                 src={'https://youtube.com/embed/' + this.state.currentData.id + '?rel=0&vq=hd720&modestbranding=1&hl=hu'}
                 allowFullScreen
               />
             </div>
           </ModalBody>
           <ModalFooter>
             <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleModal}>Bezárás</Button>
           </ModalFooter>
         </Modal>
       </span>
     );
   }
}

export default YouTube;
