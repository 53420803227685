import Lib from '../../../commons/lib';

export default class Service {
  static raktarUrl = window.location.origin + '/api/raktar/';

  static cegadatUrl = window.location.origin + '/api/cegadat/';

  // RAKTÁR START

  static getRaktarList(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.raktarUrl + 'raktar/list',
      args: { requestType: 'raktar-list' }
    }], fnDone);
  }

  static getRaktar(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.raktarUrl + 'raktar',
      args: { requestType: 'raktar-get' },
      params: { id: id }
    }], fnDone);
  }

  static addRaktar(raktarObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.raktarUrl + 'raktar',
      args: { requestType: 'raktar-post' },
      data: raktarObject
    }], fnDone);
  }

  static editRaktar(raktarObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.raktarUrl + 'raktar',
      args: { requestType: 'raktar-put' },
      data: raktarObject
    }], fnDone);
  }

  static listTipusRaktar(param, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.raktarUrl + 'raktar/tipus',
      args: { requestType: 'raktar-list-tipus' },
      params: param
    }], fnDone);
  }

  static deleteRaktar(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.raktarUrl + 'raktar',
      args: { requestType: 'raktar-delete' },
      params: { id: id }
    }], fnDone);
  }

  // RAKTÁR END

  // CÉGADAT START

  static listAktivCegadat(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.cegadatUrl + 'search',
      params: {
        aktiv: true,
        resultclass: '30'
      },
      data: [id]
    }], fnDone);
  }

  static existsNew(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cegadatUrl + 'cegadat/existsnew',
      args: { requestType: 'cegadat-existsnew' },
      params: {
        id: id,
        datum: null
      }
    }], fnDone);
  }

  // CÉGADAT END
}
