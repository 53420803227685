import React, { Component } from 'react';
import {
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import {
  AvForm,
  AvGroup,
  AvInput,
} from 'availity-reactstrap-validation';
import Services from './services';
import Lib from '../../../commons/lib';

class PartnerAdatok extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savedTop: false,
      formDisabled: true,
      modifyObj: {},
      modalText: '',
      /*
       *  MODAL CONTROLLERS
       */
      modalMegerosites: false,
      /*
       *  BASE FORM VALUES
       */
      szuletesinev: false,
      partnercsoport: false,
      cim: false,
      szallitasicim: false,
      szamlazasicim: false,
      email: false,
      telefon: false,
      kedvezmeny: false,
      adoazonosito: false,
      adoszam: false,
      kozossegiadoszam: false,
      bankszamla: false,
      /*
       *  EXTENDED FORM VALUES
       */
      szuletesiido: false,
      nem: false,
      nevnap: false,
      tajszam: false,
      megjegyzes: false,
      fajl: false
    };
  }

  componentDidMount() {
    this.updateGDPRdata();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.type === 'PARTNER_ADATOK') {
      this.updateGDPRdata();
    }
  }

  setDisableTop = () => {
    this.setState({ savedTop: true });
  }

  setOpenTop = () => {
    this.setState({ savedTop: false });
  }

  setDisableBottom = () => {
    this.setState({ formDisabled: true });
  }

  setOpenBottom = () => {
    this.setState({ formDisabled: false });
  }

  toggleMegerositoModal = () => {
    this.setState({
      modalMegerosites: !this.state.modalMegerosites
    });
  }

  updateGDPRdata = () => {
    Services.getGDPRprofil((err, res) => {
      if (!err) {
        if (res[0]) {
          this.setState({
            szuletesinev: res[0].szuletesinev,
            azonosito: true,
            nev: true,
            partnercsoport: true,
            cim: res[0].cim,
            szallitasicim: res[0].szallitasicim,
            szamlazasicim: res[0].szamlazasicim,
            email: res[0].email,
            telefon: res[0].telefon,
            kedvezmeny: res[0].kedvezmeny,
            adoazonosito: res[0].adoazonosito,
            bankszamla: res[0].bankszamla,
            szuletesiido: res[0].szuletesiido,
            nem: res[0].nem,
            adoszam: res[0].adoszam,
            kozossegiadoszam: res[0].kozossegiadoszam,
            nevnap: res[0].nevnap,
            tajszam: res[0].tajszam,
            megjegyzes: res[0].megjegyzes,
            fajl: res[0].fajl
          });
        }
      }
    });
  }

  submitModify = (resultObj) => {
    Services.check2modify(resultObj, false, (errCheck2) => {
      if (!errCheck2) {
        this.toggleMegerositoModal();
        this.props.globals.addSuccess('Partner adatok módosítva!');
        this.setState({
          formDisabled: true
        }, () => {
          this.updateGDPRdata();
        });
      }
    });
  }

  submit = (event, resultObj) => {
    Services.check1(resultObj, (errCheck1) => {
      if (!errCheck1) {
        Services.check2modify(resultObj, true, (errCheck2, res2) => {
          if (!errCheck2) {
            if (res2[0].length > 0) {
              this.setState({
                modifyObj: resultObj,
                modalText: res2[0]
              });
              this.toggleMegerositoModal();
            } else {
              this.props.globals.addSuccess('Partner adatok módosítva!');
              this.setState({
                formDisabled: true
              }, () => {
                this.updateGDPRdata();
              });
            }
          }
        });
      }
    });
  }

  backClick = () => {
    this.updateGDPRdata();
    this.setState({
      formDisabled: true
    });
  }

  renderTopEditBottom = () => {
    return this.state.savedTop ? (
      <Button
        type="button"
        color="success"
        onClick={this.setOpenTop}
      >
        Módosítás
      </Button>
    ) : '';
  }

  renderBottomEditBottom = () => {
    return this.state.formDisabled ? (
      <Button
        type="button"
        color="success"
        onClick={this.setOpenBottom}
        style={{ marginTop: 10 }}
      >
        Módosítás
      </Button>
    ) : '';
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  renderBottomSaveBottom = () => {
    return this.state.formDisabled ? '' : (
      <React.Fragment>
        <Button
          color="primary"
          style={{ marginTop: 10 }}
        >
          Mentés
        </Button>
        {' '}
        <Button
          color="seccondary"
          type="button"
          style={{ marginTop: 10 }}
          onClick={this.backClick}
        >
          Mégse
        </Button>
      </React.Fragment>
    );
  }

  render() {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };
    return (
      <div className="animated fadeIn">
        <AvForm onValidSubmit={this.submit}>
          <div className="row">
            <div className="col-md-12">
              <div>
                <h5 style={titleStyle}>
                  Kötelező adatok:
                </h5>
              </div>
              <div style={{ paddingLeft: 0 }}>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      name="azonosito"
                      disabled
                      checked={this.state.azonosito}
                      value
                    />
                    {' Azonosító' }
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      name="nev"
                      disabled
                      checked={this.state.nev}
                      value
                    />
                    {' Név' }
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      name="partnercsoport"
                      disabled
                      checked={this.state.partnercsoport}
                      value
                    />
                    {' Csoport' }
                  </Label>
                </AvGroup>
              </div>
            </div>
            <div className="col-md-12">
              <div>
                <h5 style={titleStyle}>
                  Alap adatok:
                </h5>
              </div>
              <div style={{ paddingLeft: 0 }}>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      name="szuletesinev"
                      checked={this.state.szuletesinev}
                      disabled={this.state.formDisabled}
                      onChange={this.handleInputChange}
                    />
                    {' Születési név' }
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      name="cim"
                      checked={this.state.cim}
                      onChange={this.handleInputChange}
                      disabled={this.state.formDisabled}
                    />
                    {' Hivatalos cím' }
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      name="szallitasicim"
                      checked={this.state.szallitasicim}
                      onChange={this.handleInputChange}
                      disabled={this.state.formDisabled}
                    />
                    {' Szállítási cím' }
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      name="szamlazasicim"
                      checked={this.state.szamlazasicim}
                      onChange={this.handleInputChange}
                      disabled={this.state.formDisabled}
                    />
                    {' Levelezési cím' }
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      name="email"
                      checked={this.state.email}
                      onChange={this.handleInputChange}
                      disabled={this.state.formDisabled}
                    />
                    {' E-mail' }
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      name="telefon"
                      checked={this.state.telefon}
                      onChange={this.handleInputChange}
                      disabled={this.state.formDisabled}
                    />
                    {' Telefonszám(ok)' }
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      name="kedvezmeny"
                      checked={this.state.kedvezmeny}
                      onChange={this.handleInputChange}
                      disabled={this.state.formDisabled}
                    />
                    {' Kedvezmény (%)' }
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      name="adoazonosito"
                      checked={this.state.adoazonosito}
                      onChange={this.handleInputChange}
                      disabled={this.state.formDisabled}
                    />
                    {' Adóazonosító jel' }
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      name="adoszam"
                      checked={this.state.adoszam}
                      onChange={this.handleInputChange}
                      disabled={this.state.formDisabled}
                    />
                    {' Adószám' }
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      name="kozossegiadoszam"
                      checked={this.state.kozossegiadoszam}
                      onChange={this.handleInputChange}
                      disabled={this.state.formDisabled}
                    />
                    {' Közösségi adószám' }
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      name="bankszamla"
                      checked={this.state.bankszamla}
                      onChange={this.handleInputChange}
                      disabled={this.state.formDisabled}
                    />
                    {' Bank adatok' }
                  </Label>
                </AvGroup>
              </div>
            </div>
            <div className="col-md-12">
              <div>
                <h5 style={titleStyle}>
                  Kiegészítő adatok:
                </h5>
              </div>
              <div style={{ paddingLeft: 0 }}>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      name="szuletesiido"
                      checked={this.state.szuletesiido}
                      onChange={this.handleInputChange}
                      disabled={this.state.formDisabled}
                    />
                    {' Születési idő' }
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      name="nem"
                      checked={this.state.nem}
                      onChange={this.handleInputChange}
                      disabled={this.state.formDisabled}
                    />
                    {' Neme' }
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      name="nevnap"
                      checked={this.state.nevnap}
                      onChange={this.handleInputChange}
                      disabled={this.state.formDisabled}
                    />
                    {' Névnap' }
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      name="tajszam"
                      checked={this.state.tajszam}
                      onChange={this.handleInputChange}
                      disabled={this.state.formDisabled}
                    />
                    {' TAJ szám' }
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      name="megjegyzes"
                      checked={this.state.megjegyzes}
                      onChange={this.handleInputChange}
                      disabled={this.state.formDisabled}
                    />
                    {' Megjegyzés' }
                  </Label>
                </AvGroup>
                <AvGroup check>
                  <Label check>
                    <AvInput
                      type="checkbox"
                      name="fajl"
                      checked={this.state.fajl}
                      onChange={this.handleInputChange}
                      disabled={this.state.formDisabled}
                    />
                    {' Fájlkezelés' }
                  </Label>
                </AvGroup>
              </div>
            </div>
          </div>
          {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'GDPR_PROFIL', 'KARB') && (
            <>
              {this.renderBottomEditBottom()}
              {this.renderBottomSaveBottom()}
            </>
          )}
        </AvForm>
        <Modal
          isOpen={this.state.modalMegerosites}
          toggle={this.toggleMegerositoModal}
          className={this.props.className}
          backdrop="static"
        >
          <ModalHeader>
            Megerősítés
          </ModalHeader>
          <ModalBody>
            {this.state.modalText}
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-cursor-pointer"
              color="primary"
              onClick={() => this.submitModify(this.state.modifyObj)}
            >
              Igen
            </Button>
            <Button
              className="btn-cursor-pointer"
              type="button"
              color="secondary"
              onClick={this.toggleMegerositoModal}
            >
              Mégsem
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default PartnerAdatok;
