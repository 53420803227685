import React, { Component } from 'react';
import { render } from 'react-dom';
import {
  Button, Col, Input, Row,
} from 'reactstrap';
import { filterArray } from './FelhasznalokRoleFilterFunc';

class FelhasznalokRoleCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rolesAvailableIds: [],
      rolesSelectedIds: [],
      rolesAvailableFilter: null,
      rolesSelectedFilter: null,
      rolesAvailableFiltered: [],
      rolesSelectedFiltered: [],
      // Az utoljára nem shifttel megnyomott role gomb id-je
      lastButtonAvailable: null,
      lastButtonSelected: null,
    };
  }

  static getDerivedStateFromProps = (props, state) => {
    let selectedArray = filterArray(props.rolesSelected, state.rolesSelectedFilter);
    let availableArray = filterArray(props.rolesAvailable, state.rolesAvailableFilter);
    return { rolesSelectedFiltered: selectedArray, rolesAvailableFiltered: availableArray };
  }

  handleShiftClick = (rolesIds, rolesFiltered, prevId, currId) => {
    let rolesFilteredIds = rolesFiltered.map(role => role.id);
    let firstPos = Math.max(0, rolesFilteredIds.indexOf(prevId));
    let secondPos = rolesFilteredIds.indexOf(currId);
    // A vizsgált intervallum, +1 az end paraméter, hogy tartalmazza az utolsó elemet is
    let array = rolesFilteredIds.slice(Math.min(firstPos, secondPos), Math.max(firstPos, secondPos) + 1);
    let rolesIdsFiltered = rolesIds.filter(roleId => !array.includes(roleId));
    // Van-e olyan gomb ami nincs kijelölve
    if (array.some(roleId => !rolesIds.includes(roleId))) {
      // Intervallum hozzáadása (a már kijelöltek törlésre kerültek előző lépésben, így nincs duplikáció)
      rolesIdsFiltered = rolesIdsFiltered.concat(array);
    }
    return rolesIdsFiltered;
  }

  // Button toggle
  handleButtonSelect = (event) => {
    const { target, shiftKey } = event;
    target.active = true;
    let array;
    // A baloldali oszlopba tartozik-e a gomb
    let isAvailableGroup = target.attributes && target.attributes.group && target.attributes.group.value === 'A';
    if (isAvailableGroup) {
      array = [...this.state.rolesAvailableIds];
      if (!shiftKey) {
        this.setState({ lastButtonAvailable: target.id });
      }
    } else {
      array = [...this.state.rolesSelectedIds];
      if (!shiftKey) {
        this.setState({ lastButtonSelected: target.id });
      }
    }

    if (!shiftKey) {
      if (array.includes(target.id)) {
        array = array.filter(id => id !== target.id);
      } else {
        array.push(target.id);
      }
    }

    if (isAvailableGroup) {
      if (shiftKey) {
        this.setState((prevState) => { return { rolesAvailableIds: this.handleShiftClick(array, prevState.rolesAvailableFiltered, prevState.lastButtonAvailable, target.id) }; });
      } else {
        this.setState({ rolesAvailableIds: array });
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (shiftKey) {
        this.setState((prevState) => { return { rolesSelectedIds: this.handleShiftClick(array, prevState.rolesSelectedFiltered, prevState.lastButtonSelected, target.id) }; });
      } else {
        this.setState({ rolesSelectedIds: array });
      }
    }
  }

  filterArray = (array, filter) => {
    return array.filter(role => !filter || role.roleName.toUpperCase().includes(filter.toUpperCase()));
  }

  handleFilterInputAvailable = (event) => {
    const rolesAvailableFilter = event.target.value;
    this.setState({ rolesAvailableFilter, rolesAvailableFiltered: filterArray(this.props.rolesAvailable, rolesAvailableFilter) });
  }

  handleFilterInputSelected = (event) => {
    const rolesSelectedFilter = event.target.value;
    this.setState({ rolesSelectedFilter, rolesSelectedFiltered: filterArray(this.props.rolesSelected, rolesSelectedFilter) });
  }

  handleAddAllClick = (type) => {
    if (type === 'SELECTED') {
      this.setState((prevState) => { return { rolesSelectedIds: this.addAllRoles(prevState.rolesSelectedFiltered, prevState.rolesSelectedIds) }; });
    } else if (type === 'AVAILABLE') {
      this.setState((prevState) => { return { rolesAvailableIds: this.addAllRoles(prevState.rolesAvailableFiltered, prevState.rolesAvailableIds) }; });
    }
  }

  addAllRoles = (rolesFiltered, rolesIds) => {
    let rolesFilteredIdArray = rolesFiltered.map(role => role.id);
    // Az összes szűrt gomb ki van-e jelölve a gombnyomás előtt
    let isAllSelected = rolesFilteredIdArray.every(roleId => rolesIds.includes(roleId));
    let array = rolesIds.filter(roleId => !rolesFilteredIdArray.includes(roleId));
    if (!isAllSelected) {
      array = array.concat(rolesFilteredIdArray);
    }
    return array;
  }

  render() {
    const {
      rolesAvailableIds, rolesAvailableFiltered, rolesSelectedIds, rolesSelectedFiltered
    } = this.state;
    const columnNameStyle = {
      fontSize: 20
    };
    return (
      <>
        <Row className="no-gutters mt-2">
          <Col md="6 justify-content-center row mb-1" style={columnNameStyle}>Elérhető jogosultságok</Col>
          <Col md="6 justify-content-center row mb-1" style={columnNameStyle}>{this.props.selectedColumnName}</Col>
          <Col md="6" className="mb-2 justify-content-center row">
            <Col md="8">
              <Input placeholder="Kezdjen el gépelni a kereséshez..." onChange={this.handleFilterInputAvailable} />
            </Col>
            <Col md="8" style={{ textAlign: 'center' }}>
              {rolesAvailableFiltered.map(role => role.id).filter(roleId => rolesAvailableIds.includes(roleId)).length + ' / ' + rolesAvailableFiltered.length}
            </Col>
          </Col>
          <Col md="6" className="mb-2 justify-content-center row">
            <Col md="8">
              <Input placeholder="Kezdjen el gépelni a kereséshez..." onChange={this.handleFilterInputSelected} />
            </Col>
            <Col md="8" style={{ textAlign: 'center' }}>
              {rolesSelectedFiltered.map(role => role.id).filter(roleId => rolesSelectedIds.includes(roleId)).length + ' / ' + rolesSelectedFiltered.length}
            </Col>
          </Col>
          <Col md="6" className="no-gutters row justify-content-between mb-1" style={{ paddingRight: 5, paddingLeft: 5 }}>
            <Button onClick={() => this.handleAddAllClick('AVAILABLE')}>
              Összes kijelölése
            </Button>
            <Button onClick={() => { this.props.addRoles(rolesAvailableIds); this.setState({ rolesAvailableIds: [] }); }} color="success">
              Hozzáadás
              {' '}
              <i className="fa fa-arrow-right" />
            </Button>
          </Col>
          <Col md="6" className="no-gutters row justify-content-between mb-1" style={{ paddingRight: 5, paddingLeft: 5 }}>
            <Button onClick={() => { this.props.removeRoles(rolesSelectedIds); this.setState({ rolesSelectedIds: [] }); }} color="primary">
              <i className="fa fa-arrow-left" />
              {' '}
              Eltávolítás
            </Button>
            <Button onClick={() => this.handleAddAllClick('SELECTED')}>
              Összes kijelölése
            </Button>
          </Col>
          <Col md="6" id="availablebox">
            <Row className="no-gutters">
              {rolesAvailableFiltered.map(role => (
                <Col md="4" style={{ marginBottom: 10, paddingLeft: 5, paddingRight: 5 }}>
                  <Button
                    style={{
                      width: '100%', height: '100%', whiteSpace: 'pre-line', paddingLeft: '3%', paddingRight: '3%', fontSize: 10
                    }}
                    type="button"
                    group="A"
                    key={role.id}
                    id={role.id}
                    title={role.description}
                    onClick={this.handleButtonSelect}
                    active={rolesAvailableIds.includes(role.id)}
                    color={rolesAvailableIds.includes(role.id) ? 'success' : 'secondary'}
                  >
                    {role.roleName}
                  </Button>
                </Col>
              ))}
            </Row>
          </Col>
          <Col md="6">
            <Row className="no-gutters">
              {rolesSelectedFiltered.map(role => (
                <Col md="4" style={{ marginBottom: 10, paddingLeft: 5, paddingRight: 5 }}>
                  <Button
                    style={{
                      width: '100%', height: '100%', whiteSpace: 'pre-line', paddingLeft: '3%', paddingRight: '3%', fontSize: 10
                    }}
                    type="button"
                    group="S"
                    key={role.id}
                    id={role.id}
                    title={role.description}
                    onClick={this.handleButtonSelect}
                    active={rolesSelectedIds.includes(role.id)}
                    color={rolesSelectedIds.includes(role.id) ? 'primary' : 'secondary'}
                  >
                    {role.roleName}
                  </Button>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

export default FelhasznalokRoleCards;
