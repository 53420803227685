import React, { Component } from 'react';
import _ from 'lodash';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Badge,
  UncontrolledTooltip
} from 'reactstrap';
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback
} from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Select from 'react-select/lib/Async';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import Services from './services';
import { PaginationTotal } from '../../../commons/controls';
import Lib from '../../../commons/lib';
import SlaveAvInput from '../../../commons/SlaveAvInput';
import NumberFormatter from '../../../commons/NumberFormatter';

const weekColors = [
  { name: 'URES', color: 'rgb(192,192,192)' },
  { name: 'HOLTSZEZON', color: 'rgb(0,176,240)' },
  { name: 'ELO_UTOSZEZON', color: 'rgb(255,255,255)' },
  { name: 'FOSZEZON', color: 'rgb(255,0,0)' },
  { name: 'CSUCSSZEZON', color: 'rgb(255,51,153)' }
];

// 0 -> Felvitel
// 1 -> módosítás
let _muvelet = 0;

let _modositoObj = null;

class SzallodaLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalFelvitel: false,
      modalNezet: false,
      modalTorles: false,
      modalHetek: false,
      dataLoaded: false,
      dataFailed: false,
      dataWeeksLoaded: false,
      dataWeeksEnabled: false,
      viewData: {},
      viewDataLoaded: false,
      viewDataFailed: false,
      modifyDataLoaded: false,
      modfiyDataFailed: false,
      currentSzallodaNev: '',
      orszagValue: {},
      viewObj: {},
      szallodaJson: [],
      telepulesMezo: [],
      telepulesMezoInaktiv: true,
      telepulesSelect: true,
      enums: [],
      weeks: {},
    };

    this.defaultOrszagKod = 'HUN';
    this.updateTable = this.updateTable.bind(this);
  }

  componentDidMount() {
    this.updateTable();
    this.getEnums();
  }

  updateTable = () => {
    this.getEnums();
    this.setState({ dataLoaded: false, dataFailed: false, dataWeeksEnabled: false });

    Services.listSzalloda((err, res) => {
      if (err) {
        this.setState({ dataLoaded: true, dataFailed: true });
      } else {
        let newArray = res[0];
        // Státusz stringgé alakítása
        newArray.forEach((pcs) => {
          if (pcs.statusz) {
            pcs.statusz = JSON.stringify(pcs.statusz);
          }
        });
        this.setState({ szallodaJson: newArray, dataLoaded: true, dataFailed: false });
      }
    });
  }

  /** Települések lekérdezése irányítószám alapján */
  getTelepelusek = (irszam, callback) => {
    Services.listTelepules(irszam, (err, res) => {
      if (!err) {
        callback(res[0]);
      }
    });
  }

  updateView = () => {
    this.setState({ viewDataLoaded: false, viewDataFailed: false });
    Services.getSzalloda(this._currentViewId, (err, res) => {
      if (err) {
        this.setState({ viewDataLoaded: true, viewDataFailed: true });
      } else {
        this.setState({ viewObj: res[0], viewDataLoaded: true, viewDataFailed: false });
      }
    });
  }

  /** Dinamikusan töltendő szűkítő feltételek lekérdezése */
  getEnums = () => {
    this.setState({ enums: [] });

    Services.listSzallodaEnums((err, res) => {
      if (!err) {
        this.setState({ enums: res[0] });
      }
    });
  }

  clearModositoObj = () => {
    _modositoObj = {
      id: '',
      email: '',
      fax: '',
      hazszam: '',
      hrsz: '',
      iranyitoszam: '',
      kapcsolattarto: '',
      kozterulet: '',
      kozteruletjelleg: '',
      nev: '',
      orszag: '',
      orszagid: '',
      postafiok: '',
      telefon: '',
      telepulesid: '',
      telepulesnev: '',
      hwPrice: '',
      hwRateCode: '',
      rciPrice: '',
      rciRateCode: '',
      ftpdirectory: '',
      statusz: { name: 'AKTIV' }
    };
  }

  fillModositoObj = () => {
    this.setState({ modifyDataLoaded: false, modfiyDataFailed: false });

    Services.getSzalloda(this._currentEditId, (err, res) => {
      if (err) {
        this.setState({ modifyDataLoaded: true, modfiyDataFailed: true });
      } else {
        // Cím irányítószám alapján feltöltjük a
        // hozzá tartozó település lenyílót
        this.getTelepelusek(res[0].iranyitoszam, (telepulesek) => {
          if (telepulesek.length > 0) {
            this.setState({
              telepulesMezoInaktiv: false,
              telepulesMezo: telepulesek
            });
          } else {
            this.setState({ telepulesMezo: [] });
          }
        });

        this.setState({ modifyDataLoaded: true, modfiyDataFailed: false });
        this.setState({
          orszagValue: { orszagid: res[0].orszagid, nev: res[0].orszag },
          telepulesSelect: new String(res[0].orszag).toUpperCase() === 'MAGYARORSZÁG',
        });

        _modositoObj = res[0];

        // set price as string

        _modositoObj.hwPrice = _modositoObj.hwPrice || _modositoObj.hwPrice === 0 ? _modositoObj.hwPrice.toString() : "";

        this.toggleFelvitel();
      }
    });
  }

  loadOrszagOptions = (input, callback) => {
    if (input.length !== 0) {
      Services.listOrszag(input, (err, res) => {
        if (!err) {
          callback(res[0]);
        }
      });
    }
  }

  handleChangeOrszag = (value) => {
    if (value && value.length !== 0) {
      let telepulesSelect;

      if (value.kod === 'HUN') telepulesSelect = true;
      else telepulesSelect = false;

      this.setState({ telepulesSelect: telepulesSelect, orszagValue: { orszagid: value.orszagid, nev: value.nev } }, () => {
        if (this.state.telepulesSelect) this.handleIrszamChange(null, this.refs.iranyitoszam.state.value);
      });
    } else {
      this.setState({ orszagValue: { orszagid: '', nev: '' } });
    }
  }

  setOrszagDefaultValue = () => {
    Services.getOrszag(this.defaultOrszagKod, (err, res) => {
      if (!err) {
        if (res[0] && res[0][0]) this.setState({ orszagValue: { orszagid: res[0][0].orszagid, nev: res[0][0].nev } });
      }
    });
  }

  handleIrszamChange = (event, value) => {
    this.setState({ telepulesMezoInaktiv: true });
    if (this.state.telepulesSelect) {
      if (value && value.length >= 4) {
        /** Adott irányítószámhoz tartozó települések lekérdezése. */
        Services.listTelepules(value, (err, res) => {
          if (!err) {
            if (res[0].length > 0) {
              this.setState({
                telepulesMezoInaktiv: false,
                telepulesMezo: res[0],
              });
            } else {
              this.setState({
                telepulesMezo: [],
              });
            }
          }
        });
      } else {
        this.setState({
          telepulesMezo: []
        });
      }
    }
  }

  /** Dropdown input mező értékek renderelése. */
  renderOptions = () => {
    return this.state.telepulesMezo.map(e => <option key={e.telepulesid} value={e.telepulesid}>{e.telepulesnev}</option>);
  }

  renderStatusOptions = () => {
    return this.state.enums.map(e => <option key={e.name} value={e.name}>{e.caption}</option>);
  }

  renderStatusTypes = () => {
    let statusType = {};
    this.state.enums.map(e => Object.assign(statusType, { [JSON.stringify(e)]: e.caption }));
    return statusType;
  }

  toggleFelvitel = () => {
    this.setState({ modalFelvitel: !this.state.modalFelvitel });
  }

  toggleTorles = () => {
    this.setState({ modalTorles: !this.state.modalTorles });
  }

  toggleNezet = () => {
    this.setState({ modalNezet: !this.state.modalNezet });
  }

  toggleHetekFelvitel = () => {
    this.setState({ modalHetek: !this.state.modalHetek });
  }

  handleNewClick = () => {
    this.setState({
      telepulesSelect: true,
      telepulesMezoInaktiv: true
    });
    _muvelet = 0;
    this.clearModositoObj();
    this.toggleFelvitel();
    this.setOrszagDefaultValue();
  }

  handleEditClick = (id) => {
    _muvelet = 1;
    this._currentEditId = id;
    this.fillModositoObj();
  }

  handleDeleteClick = (id) => {
    this._currentDeleteId = id;
    this.toggleTorles();
  }

  handleViewClick = (id) => {
    this._currentViewId = id;
    this.updateView();
    this.toggleNezet();
  }

  handleCalendaEditClick = (id) => {
    this._currentCalenderId = id;
    this.setState({ dataWeeksLoaded: false });

    Services.listSzallodaHet(id, (err, res) => {
      if (err) {
        this.setState({ dataWeeksLoaded: false });
      } else {
        this.setState({ dataWeeksLoaded: true, weeks: res[0] });
      }
    });
    this.toggleHetekFelvitel();
  }

  genNextWeekState = (currentStateName) => {
    let weekArrSize = weekColors.length;
    let currentIndex = weekColors.findIndex(x => x.name === currentStateName);
    if (currentIndex + 1 >= weekArrSize) {
      return weekColors[0].name;
    }
    return weekColors[currentIndex + 1].name;
  }

  handleWeekChange = (event) => {
    let modifyid = event.target.value;
    let newArray = this.state.weeks;
    let modifyObj = this.state.weeks.find(x => x.sorszam == modifyid);
    modifyObj.szezon.name = this.genNextWeekState(modifyObj.szezon.name);
    newArray[modifyObj.sorszam - 1] = modifyObj;
    this.setState({ weeks: newArray });
  }

  iconFormatter = (cell, row) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'APARTMAN_SZALLODA', 'LEK') && (
          <React.Fragment>
            <Button className="icon-eye table-action-icon" id={'action-view-' + cell + 'megtekintes'} onClick={() => this.handleViewClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'megtekintes'} delay={0}>
              {'Megtekintés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'APARTMAN_SZALLODA', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => this.handleEditClick(cell, row)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'modositas'} delay={0}>
              {'Módosítás'}
            </UncontrolledTooltip>
            <Button className="icon-trash table-action-icon" id={'action-view-' + cell + 'torles'} onClick={() => this.handleDeleteClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'torles'} delay={0}>
              {'Törlés'}
            </UncontrolledTooltip>
            <Button className="icon-calendar table-action-icon" id={'action-view-' + cell + 'kalendar'} onClick={() => this.handleCalendaEditClick(cell, row)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'kalendar'} delay={0}>
              {'Kalendár'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  /**
  * Visszaadja a partnercsoporthoz tartozó státus jelvényeket.
  * @param {boolean} cell - Cella adatot tartalmazó szöveg.
  * @return {ReactElement} Státusz jelvényt tartalmazó react objektum.
  */
  statusFormatter = (cell) => {
    if (!cell) return;
    if (cell === '{"name":"AKTIV","caption":"Aktív"}') {
      return <span className="badge badge-success">Aktív</span>;
    }
    return <span className="badge badge-default">Inaktív</span>;
  }

  removeOsszegMezoSpaces = (szallodaObj) => {
    szallodaObj.hwPrice = NumberFormatter.removeSpacesFromString(szallodaObj.hwPrice);
    szallodaObj.rciPrice = NumberFormatter.removeSpacesFromString(szallodaObj.rciPrice);
    return szallodaObj;
  }

  addSzalloda = (event, szallodaObj) => {
    szallodaObj.orszag = this.state.orszagValue.nev;
    szallodaObj.orszagid = this.state.orszagValue.orszagid;
    szallodaObj = this.removeOsszegMezoSpaces(szallodaObj);

    Services.createSzalloda(szallodaObj, (err) => {
      if (!err) {
        this.toggleFelvitel();
        this.updateTable();
        this.getEnums();
        this.props.globals.addSuccess('Szálloda felvéve!');
      }
    });
  }

  updateSzalloda = (event, szallodaObj) => {
    szallodaObj.orszag = this.state.orszagValue.nev;
    szallodaObj.orszagid = this.state.orszagValue.orszagid;
    szallodaObj.id = this._currentEditId;
    szallodaObj = this.removeOsszegMezoSpaces(szallodaObj);

    Services.updateSzalloda(szallodaObj, (err) => {
      if (!err) {
        this.toggleFelvitel();
        this.updateTable();
        this.getEnums();
        this.props.globals.addSuccess('Szálloda módosítva!');
      }
    });
  }

  submitWeeks = () => {
    let submitObj = {};
    let hetek = [];

    for (let j = 0; j < this.state.weeks.length; j++) {
      hetek.push({
        id: this.state.weeks[j].id, sorszam: this.state.weeks[j].sorszam, szezon: this.state.weeks[j].szezon.name
      });
    }

    submitObj.szallodaid = this._currentCalenderId;
    submitObj.hetek = hetek;

    Services.updateHetek(submitObj, (err) => {
      if (!err) {
        this.toggleHetekFelvitel();
        this.getEnums();
        this.props.globals.addSuccess('Hetek módosítva!');
      }
    });
  }

  deleteSzalloda = (id) => {
    this.toggleTorles();

    Services.deleteSzalloda(id, (err) => {
      if (!err) {
        this.updateTable();
        this.getEnums();
        this.props.globals.addSuccess('Szálloda törölve!');
      }
    });
  }


  renderTable = () => {
    // Státusz típusok meghatározása
    let statusType = {};
    if (this.state.enums.length) {
      this.state.enums.map(e => Object.assign(statusType, { [JSON.stringify(e)]: e.caption }));
    }

    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    return this.state.szallodaJson.length ? <BootstrapTable data={this.state.szallodaJson} pagination options={options}>
      <TableHeaderColumn dataField="nev" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Név</TableHeaderColumn>
      <TableHeaderColumn dataField="cim" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Cím</TableHeaderColumn>
      <TableHeaderColumn dataField="email" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Email</TableHeaderColumn>
      <TableHeaderColumn dataField="telefonok" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Telefon</TableHeaderColumn>
      <TableHeaderColumn
        dataField="statusz"
        dataFormat={this.statusFormatter}
        filter={{ type: 'SelectFilter', options: this.renderStatusTypes(), placeholder: 'Összes' }}
        dataAlign="center"
      >Státusz
      </TableHeaderColumn>
      <TableHeaderColumn dataField="id" width="130" isKey dataFormat={this.iconFormatter}>Műveletek</TableHeaderColumn>
    </BootstrapTable> : <DataEmptyState />;
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  renderAdatok = () => {
    return (
      <div>
        <h6>Szálloda információ:</h6>
        <b>Szálloda neve: </b>{this.state.viewObj.nev}<br />
        <b>Azonosító: </b>{this.state.viewObj.azonosito}<br />
        <b>Státusz: </b>{this.state.viewObj.statusz && this.state.viewObj.statusz.caption}<br /><br />
        <h6>Elérhetőségek:</h6>
        <b>Ország: </b>{this.state.viewObj.orszag}<br />
        <b>Irányítószám: </b>{this.state.viewObj.iranyitoszam}<br />
        <b>Település: </b>{this.state.viewObj.telepulesnev}<br />
        <b>Közterület neve: </b>{this.state.viewObj.kozterulet}<br />
        <b>Közterület jellege: </b>{this.state.viewObj.kozteruletjelleg}<br />
        <b>Házszám: </b>{this.state.viewObj.hazszam}<br />
        <b>Postafiók: </b>{this.state.viewObj.postafiok}<br />
        <b>Helyrajzi szám: </b>{this.state.viewObj.hrsz}<br />
        <b>Kapcsolattartó neve: </b>{this.state.viewObj.kapcsolattarto}<br />
        <b>Email: </b>{this.state.viewObj.email}<br />
        <b>Telefon: </b>{this.state.viewObj.telefon}<br />
        <b>Fax: </b>{this.state.viewObj.fax}<br />
      </div>
    );
  }

  renderMagyarazat = () => {
    let newArray = [];
    newArray.push(<Badge pill color="ures" key="1">Üres</Badge>);
    newArray.push(<Badge pill color="holtszezon" key="2">Holt szezon</Badge>);
    newArray.push(<Badge pill color="eloutoszezon" key="3">Elő és utószezon</Badge>);
    newArray.push(<Badge pill color="foszezon" key="4">Főszezon</Badge>);
    newArray.push(<Badge pill color="csucsszezon" key="5">Csúcsszezon</Badge>);
    return newArray;
  }

  renderButtons = () => {
    let newArray = [];

    this.state.weeks.forEach((element) => {
      if (element.szezon.name === 'FOSZEZON') {
        newArray.push(<Button value={element.sorszam} key={element.sorszam} onClick={event => this.handleWeekChange(event)} style={{ width: 50, height: 50, backgroundColor: 'rgb(255,0,0)' }}>{element.sorszam}</Button>);
      } else if (element.szezon.name === 'ELO_UTOSZEZON') {
        newArray.push(<Button value={element.sorszam} key={element.sorszam} onClick={event => this.handleWeekChange(event)} style={{ width: 50, height: 50, backgroundColor: 'rgb(255,255,255)' }}>{element.sorszam}</Button>);
      } else if (element.szezon.name === 'HOLTSZEZON') {
        newArray.push(<Button value={element.sorszam} key={element.sorszam} onClick={event => this.handleWeekChange(event)} style={{ width: 50, height: 50, backgroundColor: 'rgb(0,176,240)' }}>{element.sorszam}</Button>);
      } else if (element.szezon.name === 'CSUCSSZEZON') {
        newArray.push(<Button value={element.sorszam} key={element.sorszam} onClick={event => this.handleWeekChange(event)} style={{ width: 50, height: 50, backgroundColor: 'rgb(255,51,153)' }}>{element.sorszam}</Button>);
      } else if (element.szezon.name === 'URES') {
        newArray.push(<Button value={element.sorszam} key={element.sorszam} onClick={event => this.handleWeekChange(event)} style={{ width: 50, height: 50, backgroundColor: 'rgb(192,192,192)' }}>{element.sorszam}</Button>);
      }
      if (element.sorszam % 9 === 0) newArray.push(<br key={element.sorszam * 10} />);
    });
    return newArray;
  }

  render() {
    let weekOptions = [];
    for (let i = 1; i < 53; i++) {
      weekOptions.push(<option key={i} value={i}>{i}. hét</option>);
    }

    return (
      <div>
        {(this.state.dataLoaded && !this.state.dataFailed) ? <div className="row">
          <div className="col-sm-12">
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'APARTMAN_SZALLODA', 'KARB') &&
              <Button className="pull-left table-new-button" color="primary" onClick={this.handleNewClick}>Új szálloda</Button>
            }
          </div>
        </div> : <span />}
        {(this.state.dataLoaded && !this.state.dataFailed) ? this.renderTable() : (this.state.dataFailed) ? <NetworkEmptyState submitClick={this.updateTable} /> : this.renderLoader()}
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>
              Valóban törölni kívánja a kiválasztott tételt?
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteSzalloda(this._currentDeleteId)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalNezet} toggle={this.toggleNezet} className={this.props.className} backdrop="static">
          <ModalHeader>Megtekintés</ModalHeader>
          <ModalBody>{this.state.modalNezet ? this.renderAdatok(this._currentViewId) : <div />}</ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="primary"
              onClick={this.toggleNezet}
              className="btn-cursor-pointer"
            >
              {'Vissza'}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalFelvitel} toggle={this.toggleFelvitel} className={this.props.className} backdrop="static" size="lg">
          <AvForm onValidSubmit={_muvelet === 0 ? this.addSzalloda : this.updateSzalloda}>
            {_muvelet === 0 ? <ModalHeader>Új szálloda rögzítése</ModalHeader> : <ModalHeader>Meglévő szálloda módosítása</ModalHeader> }
            <ModalBody>
              <h5>Alapadatok:</h5>
              <div className="row">
                <div className="col-md-12">
                  <AvGroup>
                    <Label for="nev">Szálloda neve: *</Label>
                    <AvInput name="nev" id="nev" required value={_modositoObj && _modositoObj.nev} disabled={_muvelet !== 0} />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
                <div className="col-md-6">
                  <AvGroup>
                    <Label for="azonosito">Azonosító: *</Label>
                    <AvInput name="azonosito" id="azonosito" required value={_modositoObj && _modositoObj.azonosito} disabled={_muvelet !== 0} />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
                <div className="col-md-6">
                  <AvField type="select" name="statusz" label="Státusz: *" errorMessage="Kötelező mező!" required value={_modositoObj && _modositoObj.statusz.name}>
                    <option value="" disabled >Válassz státuszt...</option>
                    <option value="AKTIV">Aktív</option>
                    <option value="INAKTIV">Inaktív</option>
                  </AvField>
                </div>
              </div>
              <h5>Elérhetőségek:</h5>
              <div className="row">
                <div className="col-md-4">
                  <Label for="form-field-name">Ország:</Label>
                  {/* <Select.Async
                    name="form-field-name"
                    id="form-field-name"
                    placeholder="Kezdjen el gépelni a kereséshez..."
                    noResultsText="Nincs keresési eredmény"
                    clearable
                    ignoreAccents={false}
                    loadOptions={_.debounce((input, callback) => this.loadOrszagOptions(input, callback), 1000)}
                    isLoading={false}
                    valueKey="orszagid"
                    labelKey="nev"
                    onChange={v => this.handleChangeOrszag(v)}
                    value={this.state.orszagValue}
                    cache={false}
                    autoload={false}
                    searchPromptText="Gépeljen a kereséshez..."
                    loadingPlaceholder="Töltés..."
                  /> */}
                  <Select
                    styles={{
                      control: base => ({
                        ...base,
                        '&:focus': { borderColor: 'red' }, // border style on hover
                        border: '1px solid lightgray', // default border color
                        boxShadow: 'none', // no box-shadow
                        borderRadius: '0'
                      }),
                    }}
                    name="form-field-name"
                    id="form-field-name"
                    placeholder="Kezdjen el gépelni..."
                    noOptionsMessage={() => 'Nincs keresési eredmény'}
                    searchPromptText="Kezdjen el gépelni a kereséshez..."
                    loadingMessage={() => 'Keresés...'}
                    onChange={v => this.handleChangeOrszag(v)}
                    isClearable
                    ignoreAccents={false}
                    loadOptions={_.debounce(this.loadOrszagOptions, 1000)}
                    isLoading={false}
                    getOptionLabel={option => option.nev}
                    getOptionValue={option => option.orszagid}
                    value={this.state.orszagValue}
                    // disabled={this.state.fizTeljElerheto}
                    cache={false}
                    autoload={false}
                  />
                </div>
                <div className="col-md-4">
                  <AvGroup>
                    <Label for="iranyitoszam">Irányítószám: *</Label>
                    {this.state.telepulesSelect
                      ? <div>
                        <AvInput name="iranyitoszam" id="iranyitoszam" ref="iranyitoszam" onChange={this.handleIrszamChange} minLength="4" maxLength="20" pattern="^[0-9]*$" required value={_modositoObj && _modositoObj.iranyitoszam} />
                        <AvFeedback>Kötelező 4 számjegyű mező!</AvFeedback>
                        </div>
                      : <div>
                        <AvInput name="iranyitoszam" id="iranyitoszam" maxLength="20" required value={_modositoObj && _modositoObj.iranyitoszam} />
                        <AvFeedback>Kötelező mező!</AvFeedback>
                        </div> }
                  </AvGroup>
                </div>
                <div className="col-md-4">
                  <AvGroup>
                    {this.state.telepulesSelect
                      ? <div>
                        <AvField type="select" name="telepulesid" id="telepulesid" label="Település: *" disabled={this.state.telepulesMezoInaktiv} errorMessage="Kötelező mező!" required value={_modositoObj && _modositoObj.telepulesid}>
                          <option value="">Válassz települést...</option>
                          {this.renderOptions()}
                        </AvField>
                        </div>
                      : <div> <Label for="telepulesnev">Település: *</Label>
                        <AvInput name="telepulesnev" id="telepulesnev" required value={_modositoObj && _modositoObj.telepulesnev} />
                        <AvFeedback>Kötelező mező!</AvFeedback>
                        </div>}
                  </AvGroup>
                </div>
                <div className="col-md-6">
                  <AvGroup>
                    <Label for="kozterulet">Közterület neve: *</Label>
                    <AvInput name="kozterulet" id="kozterulet" required value={_modositoObj && _modositoObj.kozterulet} maxLength="50" />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
                <div className="col-md-3">
                  <AvGroup>
                    <Label for="kozteruletjelleg">Közterület jellege: *</Label>
                    <AvInput name="kozteruletjelleg" id="kozteruletjelleg" required value={_modositoObj && _modositoObj.kozteruletjelleg} maxLength="20" />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
                <div className="col-md-3">
                  <AvGroup>
                    <Label for="hazszam">Házszám: *</Label>
                    <AvInput name="hazszam" id="hazszam" required value={_modositoObj && _modositoObj.hazszam} maxLength="20" />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
                <div className="col-md-4">
                  <AvGroup>
                    <Label for="postafiok">Postafiók: </Label>
                    <AvInput name="postafiok" id="postafiok" value={_modositoObj && _modositoObj.postafiok} maxLength="40" />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
                <div className="col-md-4">
                  <AvGroup>
                    <Label for="hrsz">Helyrajzi szám: </Label>
                    <AvInput name="hrsz" id="hrsz" value={_modositoObj && _modositoObj.hrsz} maxLength="20" />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
                <div className="col-md-4">
                  <AvGroup>
                    <Label for="kapcsolattarto">Kapcsolattartó neve: </Label>
                    <AvInput name="kapcsolattarto" id="kapcsolattarto" value={_modositoObj && _modositoObj.kapcsolattarto} maxLength="30" />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
                <div className="col-md-4">
                  <AvGroup>
                    <Label for="email">Email: </Label>
                    <AvInput name="email" id="email" value={_modositoObj && _modositoObj.email} maxLength="100" />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
                <div className="col-md-4">
                  <AvGroup>
                    <Label for="telefon">Telefon: </Label>
                    <AvInput name="telefon" id="telefon" value={_modositoObj && _modositoObj.telefon} maxLength="20" />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
                <div className="col-md-4">
                  <AvGroup>
                    <Label for="fax">Fax: </Label>
                    <AvInput name="fax" id="fax" value={_modositoObj && _modositoObj.fax} maxLength="20" />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
                <div className="col-md-4">
                  <AvGroup>
                    <Label for="hwRateCode">HostWare ratecode: </Label>
                    <AvInput name="hwRateCode" id="hwRateCode" value={_modositoObj && _modositoObj.hwRateCode} maxLength="20" />
                  </AvGroup>
                </div>
                <div className="col-md-4">
                  <SlaveAvInput
                    id="hwPrice"
                    label="HostWare price: "
                    value={_modositoObj && _modositoObj.hwPrice}
                    szuroTipus={NumberFormatter.OSSZEG}
                    emptyStringOnZero
                  />
                </div>
                <div className="col-md-4">
                  <AvGroup>
                    <Label for="rciRateCode">RCI ratecode: </Label>
                    <AvInput name="rciRateCode" id="rciRateCode" value={_modositoObj && _modositoObj.rciRateCode} maxLength="20" />
                  </AvGroup>
                </div>
                <div className="col-md-4">
                  <SlaveAvInput
                    id="rciPrice"
                    label="RCI price: "
                    value={_modositoObj && _modositoObj.rciPrice}
                    szuroTipus={NumberFormatter.OSSZEG}
                    emptyStringOnZero
                  />
                </div>
                <div className="col-md-4">
                {this.props.globals.keycloak.hasResourceRole('ROLE_UDULESIJOG_RCIFTP_KARB') &&
                  <AvGroup>
                    <Label for="ftpDirectory" >Ftp könyvtár: 
                      <span id="ftpDirectory" className="fa fa-info-circle table-action-icon transp-icon-button">
                        <UncontrolledTooltip placement="right" target="ftpDirectory" delay={0}>
                          {'Könyvtár struktúra is megadható "/" jellel elválasztva. Pl: az/en/szallodam'}
                        </UncontrolledTooltip>
                      </span>
                    </Label>
                    <AvInput name="ftpDirectory" id="ftpDirectory" value={_modositoObj && _modositoObj.ftpDirectory} />
                  </AvGroup>
                }
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary">Mentés</Button>{' '}
              <Button type="button" color="secondary" onClick={this.toggleFelvitel} >Mégse</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
        <Modal isOpen={this.state.modalHetek} toggle={this.toggleHetekFelvitel} className={this.props.className} backdrop="static">
          <ModalHeader>Hetek rögzítése</ModalHeader>
          <ModalBody className="m-auto">
            {this.state.dataWeeksLoaded ? <div>
              <div className="hetek-magyarazat" style={{ backgroundColor: 'rgb(220,220,220)' }}>
                {this.renderMagyarazat()}
              </div>
              {this.renderButtons()}
                                          </div> : this.renderLoader()}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.submitWeeks}>Mentés</Button>
            {' '}
            <Button type="button" color="secondary" onClick={this.toggleHetekFelvitel}>Mégse</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default SzallodaLista;
