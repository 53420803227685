import React from 'react';
import PropTypes from 'prop-types';
import YouTube from '../../commons/youtube';
import PenztarMenu from './PenztarMenu';

const videoData = [
  { text: 'Pénzügyi teljesítés', id: 'KAxQV77nCGQ' }
];

const Penzugy = (props) => {
  let cardStyle = {
    padding: 20
  };

  return (
    <div className="animated fadeIn">
      <div className="row" style={cardStyle}>
        <div className="col-md-12">
          <PenztarMenu
            globals={props.props.route.globals}
          />
        </div>
      </div>
    </div>
  );
};

Penzugy.propTypes = {
  route: PropTypes.object,
};

export default Penzugy;
