import React, { Component } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import classnames from 'classnames';
import CikkcsoportLista from './CikkcsoportLista';
import CikktorzsContent from './CikktorzsContent';
import AfaLista from './AfaLista';
import getTooltip from './CikkTooltips';
import Lib from '../../../commons/lib';
import CustomTooltip from '../../../components/Tooltip/CustomTooltip';

class CikkBase extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '0'
    };
  }

  componentDidMount() {
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['CIKK_CSOPORT'], true)) {
      this.toggle('1');
    } else if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['CIKK_TORZS'], true)) {
      this.toggle('2');
    } else if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['CIKK_AFA'], true)) {
      this.toggle('3');
    }
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    let cardStyle = {
      padding: 10
    };
    return (
      <div>
        <div className="animated fadeIn">
          <div className="card">
            <div className="card-header">
              Cikk
              {/* <CustomTooltip text={getTooltip('torles')} id="torlesTT" /> */}
            </div>
            <div className="row" style={cardStyle}>
              <div className="col-md-12">
                <Nav tabs>
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['CIKK_CSOPORT'], true) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => { this.toggle('1'); }}
                      >
                        Cikkcsoport
                        <CustomTooltip text={getTooltip('cikkcsoport')} id="cikkcsoportTT" />
                      </NavLink>
                    </NavItem>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['CIKK_TORZS'], true) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => { this.toggle('2'); }}
                      >
                        Cikktörzs
                        <CustomTooltip text={getTooltip('cikktorzs')} id="cikktorzsTT" />
                      </NavLink>
                    </NavItem>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['CIKK_AFA'], true) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '3' })}
                        onClick={() => { this.toggle('3'); }}
                      >
                        Áfa
                        <CustomTooltip text={getTooltip('afa')} id="afaTT" />
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['CIKK_CSOPORT'], true) && (
                    <TabPane tabId="1">
                      <CikkcsoportLista globals={this.props.route.globals} active={this.state.activeTab === '1'} />
                    </TabPane>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['CIKK_TORZS'], true) && (
                    <TabPane tabId="2">
                      <CikktorzsContent globals={this.props.route.globals} active={this.state.activeTab === '2'} />
                    </TabPane>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['CIKK_AFA'], true) && (
                    <TabPane tabId="3">
                      <AfaLista globals={this.props.route.globals} active={this.state.activeTab === '3'} />
                    </TabPane>
                  )}
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CikkBase;
