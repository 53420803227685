import React, { Component } from 'react';
import { Button, Modal, Label, ModalHeader, ModalBody, ModalFooter, Badge, UncontrolledTooltip } from 'reactstrap';
import { AvField, AvForm } from 'availity-reactstrap-validation'
import _ from 'lodash';
import Select from 'react-select/lib/Async';
import { DatePicker} from '../../../../../commons/controls.js';
import { ProgressButton } from '../../../../../commons/ProgressButton';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { browserHistory } from 'react-router';
import Service from '../services';
import Loader from '../../../../../commons/loader';
import Lib from '../../../../../commons/lib';
import { PaginationTotal } from '../../../../../commons/controls';
import NetworkEmptyState from '../../../../../commons/networkemptystate';
import QueryEmptyState from '../../../../../commons/queryempty';
import DataEmptyState from '../../../../../commons/dataemptystate';
import FileSaver from 'file-saver';
import moment from 'moment';
import NumberFormatter from '../../../../../commons/NumberFormatter';

class SelejtezesLista extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalNezet: false,
      selejtezesJson: [],
      selejtezesView: {},
      modalTorles: false,
      listLoadingButton: false,
      tableStatus: 'QUERY', // [LOAD', NETW', 'LIST', 'QUERY', DATA]
      expandedItems: [],

      // FILTER VALUES
      filterMozgasdatum: new Date(), 
      filterMozgastipus: null,
      filterRaktar: null,

    };
  }

  updateTable = () => {
    const{
      filterMozgasdatum,
      filterRaktar,
      filterMozgastipus
    } = this.state;

    let filters = {};
    filters.mozgasDatum = filterMozgasdatum ? moment(filterMozgasdatum).format("YYYY-MM-DD") : null;
    filters.raktarId = filterRaktar ? filterRaktar.id : null;
    filters.mozgasTipus = filterMozgastipus;

    this.setState({ listLoadingButton: true, tableStatus: 'LOAD', expandedItems: [] }, ()=> {
      Service.listSelejtezes(filters, (err, res) => {
        if (!err) {
          let newSelejtezes = res[0];
          newSelejtezes.forEach( csokk => {
            csokk.mozgasTipus = csokk.mozgasTipus.name;
            switch(csokk.allapot.id){
              case 'FOLYAMATBAN': 
              csokk.allapot = 0; break;
              case 'NORMAL': 
              csokk.allapot = 1; break;
              case 'SZTORNO': 
              csokk.allapot = 2; break;
              case 'SZTORNOZOTT': 
              csokk.allapot = 3; break;
              default: 
              csokk.allapot = -1; break;
            }
          });
          this.setState({ 
            selejtezesJson: newSelejtezes,
            listLoadingButton: false,
            tableStatus: newSelejtezes && newSelejtezes.length > 0 ? 'LIST' : 'DATA', 
            cikkekJson: [],
            //expanded: [],
          });
        }else {
          this.setState({
            listLoadingButton: false,
            tableStatus: err && err.responseJSON && err.responseJSON.hibaazon == "EMPTY_FILTERS" ? 'QUERY' : 'NETW', 
            cikkekJson: [],
          });

        }
      });
    });
  }

  deleteSelejtezes = (id) => {
    Service.deleteSelejtezes(id, (err, res) => {
      if (!err) {
        this.setState({ cikkekJson: [] });
        this.updateTable();
        this.handleDeleteClick();
        this.props.globals.addSuccess('Készlet törölve!');
      }
    });
  }

  handleAddKeszlet = () => {
    browserHistory.push("".concat('/keszlet/keszletkezeles/selejtezes/felvetel'));
  }

  handleEditClick = (id) => {
    browserHistory.push("".concat('/keszlet/keszletkezeles/selejtezes/modositas?id=' + id));
  }


  addSelejtezes = (event, selejtezesObj) => {
    this.setState({ selejtezesJson: [...this.state.selejtezesJson, selejtezesObj] });
    this.toggle();
  }

  handlePdfClick = (id) => {
    let selejtezes = this.state.selejtezesJson.find(selejtezes => selejtezes.id === id);
    Service.generateBizonylatPdf(id, 'SELEJTEZES', (err,res)=>{
      if (!err) {
        FileSaver.saveAs(new Blob([res[0]], { type: 'application/pdf' }), selejtezes.bizonylatszam);
      }
    });
  }

  handleDeleteClick = (id) => {
    if(id) this._currentDeleteId = id;
    this.setState({
      modalTorles: !this.state.modalTorles
    });
  }

  handleViewClick = (id) => {
    if(!this.state.modalNezet){
      Service.getSelejtezes(id, (err, res) => {
        if (!err) {
          if(id) this._currentViewId = id;
          this.setState({ 
            selejtezesView: res[0],
            modalNezet: !this.state.modalNezet,
          });
        }
      });
    }else{
      this.setState({ 
        selejtezesView: {},
        modalNezet: !this.state.modalNezet,
      });
    }
  }

  handleDateInputChange = (e, v, name) => {
    const value = v === '' ? null : v;
    this.setState({
      [name]: value
    });
  }

  handleUpdateRaktarInput = (input, callback) => {
    if(!input) callback([]);
    Service.listBizonylatRaktar("KESZLET", null, (err, res) => {
      if (!err) {
        let options = [];
        res[0].filter(item => item.nev.toLowerCase().includes(input.toLowerCase())).forEach(item => {
          options.push(item);
        });
        callback(options);
      }
    });
  }

  iconFormatter = (cell, row) => {
    switch (row.allapot) {
      case 0: // FOLYAMATBAN
        return (
          <React.Fragment>
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KESZLETNYILV_SELEJTEZES', 'LEK') && (
              <React.Fragment>
                <Button className="icon-eye table-action-icon" id={'selejtezes-view-' + row.allapot + '-view-' + row.id} onClick={() => this.handleViewClick(cell)} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'selejtezes-view-' + row.allapot + '-view-' + row.id} delay={40}> {'Megtekintés'} </UncontrolledTooltip>
              </React.Fragment>
            )}
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KESZLETNYILV_SELEJTEZES', 'KARB') && (
              <React.Fragment>
                <Button className="icon-pencil table-action-icon" id={'selejtezes-view-' + row.allapot + '-edit-' + row.id} onClick={() => this.handleEditClick(cell)} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'selejtezes-view-' + row.allapot + '-edit-' + row.id} delay={40}> {'Módosítás'} </UncontrolledTooltip>
                <Button className="icon-trash table-action-icon" id={'selejtezes-view-' + row.allapot + '-delete-' + row.id} onClick={() => this.handleDeleteClick(cell)} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'selejtezes-view-' + row.allapot + '-delete-' + row.id} delay={40}> {'Törlés'} </UncontrolledTooltip>
              </React.Fragment>
            )}
          </React.Fragment>
        );
      case 1: // NORMAL
        return (
          <React.Fragment>
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KESZLETNYILV_SELEJTEZES', 'LEK') && (
              <React.Fragment>
                <Button className="fa fa-file-pdf-o table-action-icon" id={'selejtezes-view-' + row.allapot + '-pdf-' + row.id} onClick={() => this.handlePdfClick(cell)} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'selejtezes-view-' + row.allapot + '-pdf-' + row.id} delay={40}> {'Bizonylat PDF generálása'} </UncontrolledTooltip>
                <Button className="icon-eye table-action-icon" id={'selejtezes-view-' + row.allapot + '-view-' + row.id} onClick={() => this.handleViewClick(cell)} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'selejtezes-view-' + row.allapot + '-view-' + row.id} delay={40}> {'Megtekintés'} </UncontrolledTooltip>
              </React.Fragment>
            )}
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KESZLETNYILV_SELEJTEZES', 'KARB') && (
              <React.Fragment>
                <Button className="icon-trash table-action-icon" id={'selejtezes-view-' + row.allapot + '-delete-' + row.id} onClick={() => this.handleDeleteClick(cell)} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'selejtezes-view-' + row.allapot + '-delete-' + row.id} delay={40}> {'Sztornó'} </UncontrolledTooltip>
              </React.Fragment>
            )}
          </React.Fragment>
        );
      case 2: // SZTORNO
        return (
          <React.Fragment>
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KESZLETNYILV_SELEJTEZES', 'LEK') && (
              <React.Fragment>
                <Button className="fa fa-file-pdf-o table-action-icon" id={'selejtezes-view-' + row.allapot + '-pdf-' + row.id} onClick={() => this.handlePdfClick(cell)} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'selejtezes-view-' + row.allapot + '-pdf-' + row.id} delay={40}> {'Bizonylat PDF generálása'} </UncontrolledTooltip>
                <Button className="icon-eye table-action-icon" id={'selejtezes-view-' + row.allapot + '-view-' + row.id} onClick={() => this.handleViewClick(cell)} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'selejtezes-view-' + row.allapot + '-view-' + row.id} delay={40}> {'Megtekintés'} </UncontrolledTooltip>
              </React.Fragment>
            )}
          </React.Fragment>
        );
      case 3: // SZTORNOZOTT
        return (
          <React.Fragment>
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KESZLETNYILV_SELEJTEZES', 'LEK') && (
              <React.Fragment>
                <Button className="fa fa-file-pdf-o table-action-icon" id={'selejtezes-view-' + row.allapot + '-pdf-' + row.id} onClick={() => this.handlePdfClick(cell)} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'selejtezes-view-' + row.allapot + '-pdf-' + row.id} delay={40}> {'Bizonylat PDF generálása'} </UncontrolledTooltip>
                <Button className="icon-eye table-action-icon" id={'selejtezes-view-' + row.allapot + '-view-' + row.id} onClick={() => this.handleViewClick(cell)} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'selejtezes-view-' + row.allapot + '-view-' + row.id} delay={40}> {'Megtekintés'} </UncontrolledTooltip>
              </React.Fragment>
            )}
          </React.Fragment>
        );
      default:
        return ' ';
    }
  }

  tableStatusFormatter = (cell, row, enumobject) => {
    switch (cell) {
      case 0:
        return <Badge color="dark">folyamatban</Badge>;
      case 1:
        return <Badge color="success">normál</Badge>;
      case 2:
        return <Badge color="info">sztornó</Badge>;
      case 3:
        return <Badge color="primary">sztornózott</Badge>;
      default:
        return ' ';
    }
  }

  viewStatusFormatter = (cell, row, enumobject) => {
    switch (cell) {
      case 'FOLYAMATBAN':
        return <Badge color="dark">folyamatban</Badge>;
      case 'NORMAL':
        return <Badge color="success">normál</Badge>;
      case 'SZTORNO':
        return <Badge color="info">sztornó</Badge>;
      case 'SZTORNOZOTT':
        return <Badge color="primary">sztornózott</Badge>;
      default:
        return ' ';
    }
  }

  columnClassNameFormat = (fieldValue, row, rowIdx, colIdx) => {
    return row.modosithato ? 'td-column-nem-mentett' : 'td-column-mentett';
  }

  iconCikkFormatter = (cell) => {
    return (
    <div>
      <Button className="icon-eye table-action-icon" onClick={() => this.handleViewClick(cell)} />
    </div>);
  }

  szamlaFormatter = (cell) => {
    return cell ? '✓' : 'X';
  }

  onRowSelect = (row, isSelected, e) => {
    Service.listKeszletCikk(row.id, (err, res) => {
      if(!err){
        this.setState({
          expandedItems: [{id: row.id, data: res[0]}],
        });
        if (row.cikkek && !row.cikkek.length) {
            this.props.globals.addWarning('A kiválasztott tételhez nem tartoznak cikkek!');
        }
      }
    });
  }

  renderAdatok = (id) => {
    return (
      <div>
        <h6>Selejtezés információk:</h6>
        <b>Mozgás típusa: </b>{this.state.selejtezesView.mozgasTipus.name}<br />
        <b>Mozgás dátum: </b>{moment(this.state.selejtezesView.mozgasDatum).format("YYYY-MM-DD HH:mm:ss.SSS")}<br />
        <b>Raktár: </b>{this.state.selejtezesView.raktar.nev}<br />
        <b>Bizonylat azonosító: </b>{this.state.selejtezesView.bizonylatszam}<br />
        <b>Kapcsolódó azonosító: </b>{this.state.selejtezesView.kapcsolodoszam}<br />
        <b>Állapot: </b>{this.viewStatusFormatter(this.state.selejtezesView.allapot.id)}<br />
        <b>Megjegyzés: </b>{this.state.selejtezesView.megjegyzes}<br />
        <br />
        <b>UVD: </b>
          <div>
            <b>Rögzítés időpontja: </b>{moment(this.state.selejtezesView.uvd.rogzitesidopont).format("YYYY-MM-DD HH:mm:ss")}<br />
            <b>Rögzítő személy: </b>{this.state.selejtezesView.uvd.rogzszemelynev}<br />
            <b>Módosítás időpontja: </b>{moment(this.state.selejtezesView.uvd.modositasidopont).format("YYYY-MM-DD HH:mm:ss")}<br />
            <b>Módosító személy: </b>{this.state.selejtezesView.uvd.modszemelynev}<br />
            <b>Véglegezés időpontja: </b>{this.state.selejtezesView.veglegezesdatum ? moment(this.state.selejtezesView.veglegezesdatum).format("YYYY-MM-DD HH:mm:ss") : ''}<br />
            <b>Véglegező személy: </b>{this.state.selejtezesView.veglegezesszemelynev}<br />
          </div>
      </div>
    );
  }

  renderFilters = () => {
    const{
      filterMozgasdatum,
      filterRaktar,
      filterMozgastipus,
    } = this.state;
    return(
      <React.Fragment>
        <AvForm>
          <div className="row">
            <div className="col-md-2">
                <Label for="filterMozgasdatum">Mozgás dátuma (-tól):</Label>
                <DatePicker 
                  name="filterMozgasdatum"
                  value={filterMozgasdatum && new Date(filterMozgasdatum)}
                  onChange={(e,v) => this.handleDateInputChange(e, v, "filterMozgasdatum")}
                  max={new Date()}
                />
            </div>
            <div className="col-md-3" >
              <Label for="filterRaktar">Raktár:</Label>
              <Select
                styles={{
                control: (base) => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                  }),
                }}
                name="filterRaktar"
                id="filterRaktar"
                placeholder="Kezdjen el gépelni a kereséshez..."
                noOptionsMessage={() => "Nincs keresési eredmény"}
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingMessage={() => "Keresés..."}
                onChange={v => this.setState({filterRaktar: v})}
                loadOptions={_.debounce(this.handleUpdateRaktarInput, 1000)}
                value={filterRaktar}
                isClearable
                defaultOptions
                getOptionValue={ option => option.id}
                getOptionLabel={ option => option.nev}
              />
            </div>
            <div className="col-md-2" style={{paddingTop: "28px"}}>
              <ProgressButton color="primary" id="save" loading={this.state.listLoadingButton} onClick={e => this.updateTable(e)} >Keresés</ProgressButton>{' '}
            </div>
          </div>
        </AvForm>
      </React.Fragment>
    )
  }

  renderTable = () => {
    const options = {
      sizePerPage: 5,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const selectOptions = {
      AKTIV: 'Aktív',
      INAKTIV: 'Inaktív',
    };

    const selectRow = {
      mode: 'radio',
      clickToSelect: true,
      onSelect: this.onRowSelect,
      //style: { background: 'red' }
    };

    const allapotType = {
      0: 'Folyamatban',
      1: 'Normál',
      2: 'Sztornó',
      3: 'Sztornózott'
    };

    const columns = [
      {
        dataField: 'mozgasTipus',
        text: 'Mozgástípus',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'raktar.nev',
        text: 'Raktár',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'mozgasDatum',
        text: 'Mozgásdátum',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true,
        formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : ""
      },
      {
        dataField: 'bizonylatszam',
        text: 'Bizonylat azonosító',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'kapcsolodoszam',
        text: 'Kapcsolódó azonosító',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'allapot',
        align: 'center',
        headerStyle: { textAlign: 'center' },
        text: 'Állapot',
        filter: selectFilter({
          options: allapotType, 
          placeholder: 'Összes',
        }),
        style: {
          whiteSpace: 'pre-line',
        },
        formatter: this.tableStatusFormatter
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        hidden: !Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KESZLETNYILV_SELEJTEZES', 'KARB'),
        formatter: this.iconFormatter
      }
    ];

    const cikkColumns = [
      {
        dataField: 'cikk.cikkszam',
        text: 'Cikkszám   ',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
          textAlign: 'right' 
        },
        sort: true
      },
      {
        dataField: 'cikk.megnevezes',
        text: 'Megnevezés   ',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
          textAlign: 'right' 
        },
        sort: true
      },
      {
        dataField: 'mennyiseg',
        text: 'Mennyiség',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        formatter: NumberFormatter.formatterSpaceOsszeg,
        style: NumberFormatter.styleTextAlignWhiteSpace,
        sort: true
      },
      {
        dataField: 'cikk.mennyisegiEgyseg',
        text: 'Mennyiségi egység',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
          textAlign: 'right' 
        },
        sort: true
      },
      {
        dataField: 'selejtezesOka',
        text: 'Selejtezés oka',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
          textAlign: 'right' 
        },
        sort: true
      },
    ];

    const expandRow = {
      renderer: row => ( this.state.expandedItems.find(x => x.id == row.id) &&
          <React.Fragment>
            <div className="row">
              <div className="col-md-2">
                <h5>Cikkek</h5>
              </div>  
            </div>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  data={this.state.expandedItems.find(x => x.id == row.id).data}
                  columns={cikkColumns}
                  //selectRow={selectRow}
                  filter={filterFactory()}
                  pagination={paginationFactory(options)}
                  noDataIndication="Nincs megjelenítendő adat!"
                />
              </div>
            </div>
          </React.Fragment>
      ),
      expanded: this.state.expandedItems.map( x => x.id),
      expandByColumnOnly: true,
    };

    return (
      <div className="animated fadeIn">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={this.state.selejtezesJson}
          columns={columns}
          expandRow={expandRow}
          filter={filterFactory()}
          pagination={paginationFactory(options)}
          selectRow={selectRow}
          noDataIndication="Nincs megjelenítendő adat!"
        />
      </div>
    );
  }


  render() {
    return (
      <div>
        {this.renderFilters()}

        <div className="row">
          {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KESZLETNYILV_SELEJTEZES', 'KARB') && (
            <div className="col-md-12">
              <Button className="pull-left table-new-button" color="primary" onClick={this.handleAddKeszlet}>Új selejtezés</Button>
            </div>
          )}
        </div>

        {this.state.tableStatus === 'LOAD' ? <Loader /> : <span />}
        {this.state.tableStatus === 'QUERY' ? <QueryEmptyState hasImage /> : <span />}
        {this.state.tableStatus === 'DATA' ? <DataEmptyState hasImage subtitle="Próbálj más előszűrést vagy vigyél fel új adatokat!" /> : <span />}
        <div>
          {this.state.tableStatus === 'LIST' ? this.renderTable() : <span />}
          {this.state.tableStatus === 'NETW' ? (
          <NetworkEmptyState
            hasImage
            submitClick={() => this.updateTable()}
          />) : <span />}
        </div>

        <Modal isOpen={this.state.modalTorles} toggle={this.handleDeleteClick} className={this.props.className} backdrop="static">
          <ModalHeader >Törlés</ModalHeader>
          <ModalBody>Valóban törölni kívánja a kiválasztott tételt?</ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteSelejtezes(this._currentDeleteId)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.handleDeleteClick}>Mégsem</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalNezet} toggle={this.handleViewClick} className={this.props.className} backdrop="static">
          <ModalHeader >Megtekintés</ModalHeader>
          <ModalBody>{this.state.modalNezet ? this.renderAdatok(this._currentViewId) : <div />}</ModalBody>
          <ModalFooter> <Button type="button" color="primary" onClick={this.handleViewClick}>Vissza</Button></ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default SelejtezesLista;
