import React from 'react';
import RciProfilContent from './RciProfilContent';


const RciProfilBase = (props) => {
  let cardStyle = {
    padding: 20
  };

  return (
    <div className="animated fadeIn">
      <div className="card">
        <div className="card-header">
          Rci profil
        </div>
        <div className="row" style={cardStyle}>
          <div className="col-md-12">
            <RciProfilContent
              globals={props.route.globals}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RciProfilBase;
