import React, { Component } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Lib from '../../../commons/lib';
import OrszagContent from './OrszagContent';
import TelepulesContent from './TelepulesContent';

class FoldrajziEgysegBase extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: undefined,
      isOrszagTabActive: false,
      isTelepulesTabActive: false,
    };
  }

  componentDidMount() {
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['TELEPULES_ORSZAG'], true)) {
      this.toggle('1');
    } else if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['TELEPULES_TELEPULES'], true)) {
      this.toggle('2');
    }
  }

  toggle(tab) {
    this.setState({
      isOrszagTabActive: tab === '1',
      isTelepulesTabActive: tab === '2',
    });
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    let cardStyle = {
      padding: 10
    };

    return (
      <div>
        <div className="animated fadeIn">
          <div className="card">
            <div className="card-header">
              {'Földrajzi egységek'}
            </div>
            <div className="row" style={cardStyle}>
              <div className="col-md-12">
                <Nav tabs>
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['TELEPULES_ORSZAG'], true) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => {
                          this.toggle('1');
                          // if (this.child1) this.child1.updateTable();
                        }}
                      >
                        {'Országok'}
                      </NavLink>
                    </NavItem>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['TELEPULES_TELEPULES'], true) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => {
                          this.toggle('2');
                          // if (this.child2) this.child2.updateTable();
                        }}
                      >
                        {'Települések'}
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['TELEPULES_ORSZAG'], true) && (
                    <TabPane tabId="1">
                      <OrszagContent
                        {...this.props}
                        globals={this.props.route.globals}
                        active={this.state.isOrszagTabActive}
                        ref={(instance) => { this.child1 = instance; }}
                      />
                    </TabPane>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['TELEPULES_TELEPULES'], true) && (
                    <TabPane tabId="2">
                      <TelepulesContent
                        {...this.props}
                        globals={this.props.route.globals}
                        active={this.state.isTelepulesTabActive}
                        ref={(instance) => { this.child2 = instance; }}
                      />
                    </TabPane>
                  )}
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FoldrajziEgysegBase.propTypes = {
  route: PropTypes.object,
  globals: PropTypes.object
};

export default FoldrajziEgysegBase;
