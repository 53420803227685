import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Badge,
  Button,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label
} from 'reactstrap';
import {
  AvFeedback,
  AvField,
  AvForm,
  AvGroup,
  AvInput
} from 'availity-reactstrap-validation';
import FileSaver from 'file-saver';
import DropZone from 'react-dropzone';
import BootstrapTable from 'react-bootstrap-table-next';
import Moment from 'moment';
import MomentTimezone from 'moment-timezone';
import momentLocalizer from 'react-widgets-moment';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import Select from 'react-select';
import SelectAsync from 'react-select/lib/Async';
import Lib from '../../commons/lib';
import DataEmptyState from '../../commons/dataemptystate';
import QueryEmptyState from '../../commons/queryempty';
import NetworkEmptyState from '../../commons/networkemptystate';
import Loader from '../../commons/loader';
import Services from './services';
import { DatePicker, PaginationTotal, FileCard } from '../../commons/controls';
import { ProgressButton } from '../../commons/ProgressButton';
import CustomTooltip from '../../components/Tooltip/CustomTooltip';
import getTooltip from './MunkalapTooltips';

momentLocalizer(Moment);

class MunkalapContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // ['QUERY','LOAD', 'DATA', 'NETW', 'TABL']
      tableStatus: 'QUERY',
      // ['LOAD', 'DATA', 'NETW']
      fileStatus: 'LOAD',
      files: [],
      fileMunkalapId: null,
      currentId: null,
      currentAllapot: '',
      modalTorles: false,
      modalFile: false,
      torlesId: null,
      baseJson: [],

      formUgyfel: '',
      formAllapot: '',
      formLetrehozvaTol: new Date(new Date().setDate(new Date().getDate() - 14)),
      formLetrehozvIg: null,
      allapotOptions: [],

      isPartnerInputLoading: false,
      optionsLoaded: false,
      partnerOptions: []
    };
    this.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  componentDidMount() {
    this.getMunkalapInitFilter();
  }

  toggleTorles = () => {
    this.setState(prevState => ({ modalTorles: !prevState.modalTorles }));
  }

  toggleFile = () => {
    this.setState(prevState => ({ modalFile: !prevState.modalFile }));
  }

  getMunkalapInitFilter = () => {
    this.setState({ searchButtonLoading: true });
    Services.getMunkalapInitFilter((err, res) => {
      if (!err) {
        let allapotOptions = res[0].allapot.map(e => <option key={e.name} value={e.name}>{e.caption}</option>);
        console.log(res[0].sta)
        console.log(this.timezoneToDateYear(res[0].sta))
        let formLetrehozvaTol = new Date(this.timezoneToDateYear(res[0].sta));
        this.setState({ allapotOptions, searchButtonLoading: false });
        this.getMunkalapList();
      } else {
        this.setState({ searchButtonLoading: false });
      }
    });
  }

  getMunkalapList = () => {
    this.setState({
      tableStatus: 'LOAD',
      searchButtonLoading: true
    });
    Services.getMunkalapList(
      {
        partnerid: this.state.formUgyfel && this.emptyOrNull(this.state.formUgyfel.id),
        allapot: this.emptyOrNull(this.state.formAllapot),
        user: this.state.formPartner && this.emptyOrNull(this.state.formPartner.id),
        datum: {
          sta: this.emptyOrNull(this.dateToTimezone(this.state.formLetrehozvaTol)),
          end: this.emptyOrNull(this.dateToTimezone(this.state.formLetrehozvaIg)),
        },
      }, (err, res) => {
        if (!err) {
          this.setState({
            tableStatus: (res[0].length > 0) ? 'TABL' : 'DATA',
            baseJson: res[0],
            searchButtonLoading: false
          });
        } else {
          this.setState({
            tableStatus: 'QUERY',
            searchButtonLoading: false
          });
        }
      });
  }

  deleteMunkalap = () => {
    Services.deleteMunkalap(this.state.torlesId, (err) => {
      if (!err) {
        this.toggleTorles();
        this.getMunkalapList();
        this.props.globals.addSuccess('Munkalap törölve!');
      }
    });
  }

  getMunkalapFile = (id) => {
    Services.getMunkalapFiles(id, (err, res) => {
      if (!err) {
        this.setState({
          fileStatus: 'DATA',
          fileMunkalapId: id,
          files: res[0]
        });
      } else {
        this.setState({
          fileStatus: 'NETW'
        });
      }
    });
  }

  handleNewClick = () => {
    this.setState({
    }, () => {
      browserHistory.push({
        pathname: '/munkalap/felvetel',
      });
    });
  }

  handleEditClick = (id) => {
    this.setState({
    }, () => {
      browserHistory.push({
        pathname: '/munkalap/modositas',
        search: '?id=' + id,
        state: {
          munkalapId: id
        }
      });
    });
  }

  handleViewClick = (id) => {
    this.setState({
    }, () => {
      browserHistory.push({
        pathname: '/munkalap/megtekintes',
        search: '?id=' + id,
        state: {
          munkalapId: id
        }
      });
    });
  }

  handleFileClick = (cell, row) => {
    this.setState({
      fileStatus: 'LOAD',
      currentAllapot: row.allapot.name,
      currentId: cell,
    }, () => {
      this.getMunkalapFile(cell);
      this.toggleFile();
    });
  }

  handleDeleteClick = (cell) => {
    this.setState({
      torlesId: cell
    }, () => {
      this.toggleTorles();
    });
  }

  handlePdfClick = (id, row) => {
    Services.getMunkalapPdf(id, (err, res) => {
      if (!err) {
        this.props.globals.addSuccess('Munkalap PDF letöltése...');
        FileSaver.saveAs(new Blob([res[0]], { type: 'application/pdf' }), 'Munkalap_' + row.azonosito + '_' + Moment().format('YYYY-MM-DD') + '.pdf');
        // let fileURL = URL.createObjectURL(new Blob([res[0]], { type: 'application/pdf' }));
        // window.open(fileURL);
      }
    });
  }

  handleEmailClick = (id, row) => {
    Services.sendEmail(id, (err, res) => {
      if (!err) {
        this.props.globals.addSuccess('Sikeres email küldés!');
      }
    });
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  handleDateInputChange = (e, v, name) => {
    const value = v === '' ? null : v;
    this.setState({
      [name]: value
    });
  }

  tableAllapotFormatter = (cell) => {
    if (cell) {
      return <Badge color={cell.color}>{cell.caption}</Badge>;
    }
    return '-';
  }

  tableUserFormatter = (cell) => {
    if (cell && cell.length > 0) {
      return cell.map((user) => {
        return <p style={{ padding: 0, margin: 0 }}>{user}</p>;
      });
    }
    return '-';
  }

  dateToTimezone = (date) => {
    if (!date || date === '') return null;
    return MomentTimezone.tz(date, this.tz).format('YYYY-MM-DDT00:00:00.000ZZ');
  }


  timezoneToDate = (date) => {
    if (!date || date === '') {
      return null;
    }

    return MomentTimezone.tz(date, this.tz).format('YYYY-MM-DD HH:mm');
  }

  timezoneToDateYear = (date) => {
    if (!date || date === '') {
      return null;
    }

    return MomentTimezone.tz(date, this.tz).format('YYYY-MM-DD');
  }

  tableDateFormatter = (date) => {
    if (!date || date === '') {
      return null;
    }

    return MomentTimezone.tz(date, this.tz).format('YYYY-MM-DD HH:mm');
  }

  tableDateRogzFormatter = (date, row) => {
    let user = '';
    if (!date || date === '') {
      return '-';
    }

    if (row.rogzitesuser && row.rogzitesuser !== '') {
      user = row.rogzitesuser;
    }

    return MomentTimezone.tz(date, this.tz).format('YYYY-MM-DD HH:mm') + '\n [' + user + ']';
  }

  tableDateModFormatter = (date, row) => {
    let user = '';
    if (!date || date === '') {
      return '-';
    }

    if (row.modositasuser && row.modositasuser !== '') {
      user = row.modositasuser;
    }

    return MomentTimezone.tz(date, this.tz).format('YYYY-MM-DD HH:mm') + '\n [' + user + ']';
  }

  tableIconFormatter = (cell, row) => {
    return (
      <React.Fragment>
        {(Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'MUNKALAP_OSSZ', 'LEK') || Lib.KeyCloak.hasRoleSpec(this.props.globals.keycloak, ['MUNKALAP_SAJAT_KARB'])) && (
          <React.Fragment>
            <Button className="icon-eye table-action-icon" id={'action-view-' + cell + 'view'} onClick={() => this.handleViewClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'view'} delay={50}>
              {'Megtekintés'}
            </UncontrolledTooltip>
            <Button className="icon-printer table-action-icon" id={'action-view-' + cell + 'nyomtatas'} onClick={() => this.handlePdfClick(cell, row)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'nyomtatas'} delay={0}>
              {'Nyomtatás'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        {(Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'MUNKALAP_OSSZ', 'KARB') || Lib.KeyCloak.hasRoleSpec(this.props.globals.keycloak, ['MUNKALAP_SAJAT_KARB'])) && row.modosithato && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => this.handleEditClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'modositas'} delay={0}>
              {'Módosítás'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        {(Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'MUNKALAP_OSSZ', 'LEK') || Lib.KeyCloak.hasRoleSpec(this.props.globals.keycloak, ['MUNKALAP_SAJAT_KARB'])) && (
          <React.Fragment>
            <Button className="fa fa-file-text-o table-action-icon" id={'action-view-' + cell + 'file'} onClick={() => this.handleFileClick(cell, row)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'file'} delay={50}>
              {'Kapcsolódó dokumentumok'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        {(Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'MUNKALAP_OSSZ', 'KARB') || Lib.KeyCloak.hasRoleSpec(this.props.globals.keycloak, ['MUNKALAP_SAJAT_KARB'])) && (
          <React.Fragment>
            <Button className="icon-envelope-letter table-action-icon" id={'action-send-' + cell + 'mail'} onClick={() => this.handleEmailClick(cell, row)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-send-' + cell + 'mail'} delay={50}>
              {'Email küldés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        {(Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'MUNKALAP_OSSZ', 'KARB') || Lib.KeyCloak.hasRoleSpec(this.props.globals.keycloak, ['MUNKALAP_SAJAT_KARB'])) && row.modosithato && (
          <React.Fragment>
            <Button className="icon-trash table-action-icon" id={'action-view-' + cell + 'torles'} onClick={() => this.handleDeleteClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'torles'} delay={0}>
              {'Törlés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  renderTable = () => {
    const options = {
      sizePerPage: 5,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const columns = [
      {
        dataField: 'azonosito',
        text: 'Munkalap azonosító',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'partner',
        text: 'Ügyfél név és elérhetőség',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true,
      },
      {
        dataField: 'cim',
        text: 'Feladat címe',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true,
      },
      {
        dataField: 'userek',
        text: 'Feladat elvégzője',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true,
        formatter: this.tableUserFormatter
      },
      {
        dataField: 'allapot',
        text: 'Állapot',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        filterValue: cell => cell && cell.caption,
        style: {
          whiteSpace: 'pre-line',
          textAlign: 'center'
        },
        sort: true,
        sortValue: cell => cell && cell.caption,
        formatter: this.tableAllapotFormatter
      },
      {
        dataField: 'rogzitesidopont',
        text: 'Rögzítés dátuma',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
          textAlign: 'center'
        },
        sort: true,
        formatter: this.tableDateRogzFormatter
      },
      {
        dataField: 'modositasidopont',
        text: 'Módosítás dátuma',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
          textAlign: 'center'
        },
        sort: true,
        formatter: this.tableDateModFormatter
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        hidden: !Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'MUNKALAP_OSSZ', 'KARB') && !Lib.KeyCloak.hasRoleSpec(this.props.globals.keycloak, ['MUNKALAP_SAJAT_KARB']),
        formatter: this.tableIconFormatter
      }
    ];

    return (
      <div className="animated fadeIn">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={this.state.baseJson}
          columns={columns}
          filter={filterFactory()}
          pagination={paginationFactory(options)}
          noDataIndication="Nincs megjelenítendő adat!"
        />
      </div>
    );
  }

  emptyOrNull = (data) => {
    if (!data || data === '') return null;
    return data;
  }

  onDropObject = (acceptedfiles) => {
    acceptedfiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        let files = this.state.files || [];
        files.push({
          content: btoa(fileAsBinaryString),
          uploadDate: new Date(),
          docname: file.name,
          preview: file.preview,
          logo: false,
          mime: file.type || 'application/octet-stream',
          new: true,
          length: file.size,
          id: Lib.Browser.uuidv4()
        });
        this.setState({ files: files });
      };
      reader.readAsBinaryString(file);
    });
  }

  downloadObject = (file) => {
    if (file.preview) {
      window.open(file.preview, '_blank');
    } else {
      this.getContent(file.id);
    }
  }

  removeObject = (file) => {
    if (file.preid) {
      let files = this.state.files.filter(f => f.preid !== file.preid);
      this.setState({ files: files });
    } else {
      let files = this.state.files.filter(f => f.id !== file.id);
      this.setState({ files: files });
    }
  }

  getContent = (objid) => {
    let obj = this.state.files.find(f => f.id == objid);
    Services.downloadObject(objid, (err, res) => {
      if (!err) {
        FileSaver.saveAs(new Blob([res[0]], { type: obj.mime }), obj.docname);
      }
    });
  }

  formatBytes = (bytes) => {
    if (bytes < 1024) return bytes + ' bájt';
    if (bytes < 1048576) return (bytes / 1024).toFixed(3) + ' KB';
    if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + ' MB';
    return (bytes / 1073741824).toFixed(3) + ' GB';
  };

  saveFile = () => {
    this.setState({ saveButtonLoading: true });
    let submitObjParam = JSON.parse(JSON.stringify(this.state.files));
    submitObjParam.forEach((item) => {
      if (item.new) {
        delete item.id;
      }
    });
    Services.editFile(this.state.fileMunkalapId, submitObjParam, (err) => {
      if (!err) {
        this.props.globals.addSuccess('Fájlok módosítva!');
        this.toggleFile();
        this.setState({ saveButtonLoading: false });
      } else {
        this.setState({ saveButtonLoading: false });
      }
    });
  }

  renderFile = () => {
    return (
      <React.Fragment>
        {this.state.currentAllapot !== 'LEZART' ? (
          <div className="row">
            <div className="col-md-12">
              <DropZone
                multiple
                onDrop={this.onDropObject}
                className="custom-dropzone"
                style={{ marginBottom: 20 }}
              >
                <div className="dropzone-text">
                  {'Húzd ide a fájlokat vagy kattints a négyzetbe egy kép feltöltéséhez!'}
                </div>
              </DropZone>
            </div>
          </div>
        ) : (
          <div className="row">
            {(this.state.files && this.state.files.length === 0) ? (
              <div className="col-md-12" style={{ textAlign: 'center', marginTop: 10 }}>
                <h6>Nincs feltöltött dokumentum</h6>
              </div>
            ) : null}
          </div>
        )}
        <div className="row">
          {this.state.files && this.state.files.map((item, idx) => {
            return (
              <div className="col-md-12" key={idx.toString()}>
                <FileCard
                  key={idx.toString()}
                  mime={item.mime}
                  title={item.docname}
                  length={this.formatBytes(item.length)}
                  onDownloadClick={() => this.downloadObject(item)}
                  onDeleteClick={() => this.removeObject(item)}
                />
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  loadPartnerChange = (input, callback) => {
    if (input.length > 2) {
      Services.searchPartnerList(input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            let newItem = item;
            newItem.nev = newItem.nev + ' (' + newItem.azon + ') ';
            newArray.push(newItem);
          });
          callback(newArray);
        }
      });
    }
  }

  handleChangeUgyfel = (value) => {
    if (value && value !== '') {
      this.setState({
        formUgyfel: value,
      });
    } else {
      this.setState({
        formUgyfel: '',
      });
    }
  }

  handleChangePartner = (value) => {
    if (value && value !== '') {
      this.setState({
        formPartner: value,
      });
    } else {
      this.setState({
        formPartner: '',
      });
    }
  }

  maybeLoadOptions = () => {
    if (!this.state.optionsLoaded) {
      this.setState({ isPartnerInputLoading: true });
      this.handleLoadOptions();
    }
  }

  handleLoadOptions = (input, callback) => {
    let options = []

    Services.getMunkalapInitFilterUser((err, res) => {
      if (!err) {
        this.setState({ 
          optionsLoaded: true,
          partnerOptions: res[0],
          isPartnerInputLoading: false
        });
      } else {
        this.setState({ 
          optionsLoaded: true,
          isPartnerInputLoading: false
        });
      }
    });
  };

  renderEloSzures = () => {
    const {
      formUgyfel,
      formLetrehozvaTol,
      formLetrehozvaIg,
      formEsedekessegTol,
      formEsedekessegIg,
      formPartner,
      formKereses
    } = this.state;

    return (
      <AvForm onValidSubmit={() => {}}>
        <div className="row">
          <div className="col-md-3">
            <Label for="formUgyfel">Ügyfél neve: </Label>
            <SelectAsync
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="formUgyfel"
              id="formUgyfel"
              // placeholder="Kezdjen el gépelni..."
              placeholder="Keresés ügyfél neve alapján..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeUgyfel(v)}
              clearable
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadPartnerChange, 1000)}
              isLoading={false}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.nev}
              value={formUgyfel}
              cache={false}
              autoload={false}
            />
          </div>
          <div className="col-md-2 form-group">
            <AvField
              ref="formAllapot"
              type="select"
              name="formAllapot"
              id="formAllapot"
              value={this.state.formAllapot}
              onChange={this.handleInputChange}
              label="Állapot: "
            >
              <option value="">Válassz értéket</option>
              {this.state.allapotOptions}
            </AvField>
          </div>
          <div className="col-md-3">
            <Label for="formPartner">Feladat elvégzője: </Label>
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="formPartner"
              id="formPartner"
              // placeholder="Kezdjen el gépelni..."
              placeholder="Keresés név alapján..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangePartner(v)}
              clearable
              isClearable
              ignoreAccents={false}
              //loadOptions={_.debounce(this.loadPartnerChange, 1000)}
              options={this.state.partnerOptions}
              isLoading={this.state.isPartnerInputLoading}
              onFocus={this.maybeLoadOptions}
              getOptionValue={option => option.id}
              getOptionLabel={(option) => {
                return option.szemelynev === '' ? option.username : (option.szemelynev + ' (' + option.username + ')');
              }}
              value={formPartner}
            />
          </div>

          <div className="col-md-2 form-group">
            <Label for="formLetrehozvaTol">
              Rögzítés(-tól):
            </Label>
            <DatePicker
              type="select"
              name="formLetrehozvaTol"
              value={formLetrehozvaTol && new Date(formLetrehozvaTol)}
              onChange={(e, v) => this.handleDateInputChange(e, v, 'formLetrehozvaTol')}
              // onChange={this.handleTeljesitesChange}
            />
          </div>
          <div className="col-md-2 form-group">
            <Label for="formLetrehozvaIg">
              Rögzítés(-ig):
            </Label>
            <DatePicker
              type="select"
              name="formLetrehozvaIg"
              value={formLetrehozvaIg && new Date(formLetrehozvaIg)}
              onChange={(e, v) => this.handleDateInputChange(e, v, 'formLetrehozvaIg')}
              // onChange={this.handleTeljesitesChange}
            />
          </div>
          <div className="col-md-12">
            {(Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'MUNKALAP_OSSZ', 'KARB') || Lib.KeyCloak.hasRoleSpec(this.props.globals.keycloak, ['MUNKALAP_SAJAT_KARB'])) && (
              <Button className="pull-left table-new-button" color="primary" onClick={this.handleNewClick}>
                {'Új munkalap'}
                <CustomTooltip text={getTooltip('uj_munkalap')} id="uj_munkalapTT" />
              </Button>
            )}
            {' '}
            <ProgressButton
              className="btn-cursor-pointer pull-right"
              color="primary"
              loading={this.state.searchButtonLoading}
              onClick={() => {
                this.getMunkalapList();
              }}
            >
              {'Keresés'}
            </ProgressButton>
          </div>
        </div>
      </AvForm>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.renderEloSzures()}
        <div className="row">
          <div className="col-md-12">
            {{
              QUERY: (<QueryEmptyState
                hasImage
              />),
              LOAD: (<Loader />),
              DATA: (<DataEmptyState
                hasImage
                subtitle="Vigyél fel új tételt, vagy használj másik előszűrést!"
              />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.getMunkalapList()}
              />),
              TABL: (this.renderTable())
            }[this.state.tableStatus]}
          </div>
        </div>
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>Valóban törölni kívánja a kiválasztott tételt?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.deleteMunkalap()}>Igen</Button>
            <Button type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalFile} toggle={this.toggleFile} backdrop="static">
          <ModalHeader>Fájlkezelés</ModalHeader>
          <ModalBody>
            {{
              LOAD: (<Loader />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.getMunkalapFile(this.state.currentId)}
              />),
              DATA: (this.renderFile())
            }[this.state.fileStatus]}
          </ModalBody>
          <ModalFooter>
            {this.state.currentAllapot !== 'LEZART' ? (
              <ProgressButton loading={this.state.saveButtonLoading} color="primary" onClick={this.saveFile}>Mentés</ProgressButton>
            ) : null}
            <Button type="button" color="secondary" onClick={this.toggleFile}>Mégsem</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

MunkalapContent.propTypes = {
  globals: PropTypes.object
};

export default MunkalapContent;
