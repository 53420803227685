const tooltips = {
  feladatlista: 'Ez a modul szolgál a rendszerben a felhasználók feladatainak nyilvántartására, ütemezésére. A modulba a felhasználó rögzíthet saját kezűleg, de akár más modulból is kaphat feladatot a feladatlista.\nA modul használatát a törzsállományok kialakításával szükséges kezdeni: feladattípusok, állapotok.',
  feladatok: 'A felhasználó feladatainak határidő (esedékesség) alapján felállított időrendi nyilvántartása. Feladatnyilvántartás egyszerre biztosít felületet a felhasználónak a feladatok lekérdezésére és karbantartásáhra.\nA nyilvántartásba feladatok bekerülhetnek direkt rögzítéssel a felhasználó által, illetve más nyilvántartásból történő automatikus adatátvétellel. Ezeket a felhasználó feladattípuson keresztül tudja elkülöníteni.',
  feladattipusallapotok: 'A feladatok a felhasználó által különböző állapotokkal láthatók el. Ezeknek az állapotoknak a sorrendjét a rendszerben feladattípusonként maga a felhasználó tudja paraméterezni. Ehhez azonban szükséges, hogy ezen a felületen a felhasználó a különböző állapotokat kialakítsa.',
  feladattipusok: 'Ezen felületen rögzíthetők a különböző feladattípusok, melyekkel a felhasználónak lehetősége van az általa nyilvántartott feladatokat típusokkal elkülöníteni. Ez a felület szolgál továbbá az állapotkódok feladattípusokhoz rendelésére is.',
  feladattipus_allapotok: 'Minden feladattípus alapértelmezetten két fix állapottal rendelkezik: Rögzített, Befejezett. Ezek közé illeszthetők be új állapotok. A sorrend a kártyák mozgatásával lehetséges.',
  feladattipus_funkcio: 'A feladatok képződhetnek más funkció által történő más modulból történő automatikus rögzítéssel is. Ezekhez a funkciókhoz lehet és kell egy feladattípust hozzárendelni a funkció mezőn keresztül.',
  uj_feladat: 'Partner kiválasztásával átemelésre kerülnek az adatok a rögzítés támogatásaként, melyet a felhasználó szabadon szerkeszthet a feladathoz kapcsolódva. A módosításoknak a partner adatokra nem lesz kihatása.\nMás nyilvántartásból generált feladatok esetén a funkció mező mutatja a forrás nyilvántartást és a kapcsolódó azonosító a forrás nyilvántartásban megadott azonosítót.\nValamennyi feladat közös tulajdonsága a prioritás és az esedékesség (határidő). Emellett a feladatok rögzítése során az alaptulajdonságokat a felhasználó az adott feladathoz kapcsolódóan szabadon bővítheti az általa meghatározott paraméterekkel. Ezek a kiegészítő adatok adhatók meg a mező neve és értéke mezőpárosokban.\nA feladatok állapota a típusnál meghatározott állapotfolyamat szerint bármikor módosítható, ha a felhasználó a táblázatban a módosítandó adat esetén az állapot mező értékét megnyomva kiválasztja a számára szükséges állapotot.'
};

export default function getTooltip(name) {
  return tooltips[name];
}
