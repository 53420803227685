import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DataEmptyState from '../../commons/dataemptystate';
import { PaginationTotal } from '../../commons/controls';
import Loader from '../../commons/loader';
import NetworkEmptyState from '../../commons/networkemptystate';
import QueryEmptyState from '../../commons/queryempty';
import Services from './services';

class PenztartetelTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ['QUERY', 'LOAD', 'DATA', 'NETW', 'TABL']
      currentStatusBaseTable: 'QUERY',
      // ['LOAD', 'LOADED', 'NETW']
      currentId: null,
      baseJson: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.tableData !== nextProps.tableData) {
      this.setState({
        currentStatusBaseTable: 'LOAD'
      });
      Services.listPenztarTranzakcioTable(nextProps.tableData.id, (err, res) => {
        if (!err) {
          this.setState({ baseJson: res[0], currentId: nextProps.tableData.id, currentStatusBaseTable: 'TABL' });
        } else {
          this.setState({
            currentStatusBaseTable: 'QUERY'
          });
        }
      });
    }

    if (this.props.tableStatus !== nextProps.tableStatus) {
      if (nextProps.tableStatus && nextProps.tableStatus.tranzakcio) {
        if (nextProps.tableStatus.tetel === 'RELOAD') {
          this.setState({
            currentStatusBaseTable: 'QUERY',
            baseJson: [],
          });
          this.props.tableStatusChange({
            fej: 'LOADED',
            tranzakcio: 'LOADED',
            tetel: 'LOADED'
          });
        }
      }
    }
  }

  onRowSelect = (row) => {
    this.props.changeData(row.i);
  }

  updateBaseTable = () => {
    this.setState({
      currentStatusBaseTable: 'LOAD'
    });
    Services.listKivonat(this.state.currentId, (err, res) => {
      if (!err) {
        res[0].forEach((element, idx) => {
          if (element.jelleg && element.jelleg.caption) {
            res[0][idx].jelleg = element.jelleg.caption;
          }
          if (element.tipus && element.tipus.caption) {
            res[0][idx].tipus = element.tipus.caption;
          }
          if (element.partnerbean && element.partnerbean.nev) {
            res[0][idx].partner = element.partnerbean.nev;
          }
          if (element.bankkivonatentity && element.bankkivonatentity.bankszamlabean) {
            res[0][idx].partnerbankszamlaszam = element.bankkivonatentity.bankszamlabean.szamlaszam;
          }
        });
        this.setState({ baseJson: res[0], currentStatusBaseTable: 'TABL' });
      } else {
        this.setState({
          currentStatusBaseTable: 'QUERY'
        });
      }
    });
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  renderBaseTable = () => {
    const pagination = true;

    const selectRowProp = {
      mode: 'radio',
      onSelect: this.onRowSelect
    };

    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };
    return (
      <div>
        { this.state.baseJson.length ? (
          <BootstrapTable data={this.state.baseJson} pagination={pagination} options={options} selectRow={selectRowProp} className="margin-top-30">
            <TableHeaderColumn
              dataField="t"
              tdStyle={{ whiteSpace: 'normal' }}
            >
              Pénzmozgás típusa
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="j"
              tdStyle={{ whiteSpace: 'normal' }}
            >
              Pénzmozgás jellege
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="o"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            >
              Összeg
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="p"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            >
              Pénznem
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="h"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            >
              Összeg Ft-ban
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="a"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            >
              Árfolyam
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="i"
              tdStyle={{ whiteSpace: 'normal' }}
              isKey
              hidden
            >
              Műveletek
            </TableHeaderColumn>
          </BootstrapTable>) : (
            <div className="margin-top-35">
              <DataEmptyState />
            </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <div>
        {{
          QUERY: (<QueryEmptyState
            subtitle="Kérem válasszon felettes rekordot!"
            hasImage
          />),
          LOAD: (<Loader />),
          DATA: (<DataEmptyState
            hasImage
            subtitle="Próbálj más előszűrést vagy vigyél fel új adatokat!"
          />),
          NETW: (<NetworkEmptyState
            hasImage
            submitClick={() => this.updateTable()}
          />),
          TABL: (this.renderBaseTable())
        }[this.state.currentStatusBaseTable]}
      </div>
    );
  }
}

PenztartetelTable.propTypes = {
  tableData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  changeData: PropTypes.func,
};

export default PenztartetelTable;
