import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from 'reactstrap';
import {
  AvForm,
  AvGroup,
  AvFeedback,
  AvInput,
} from 'availity-reactstrap-validation';
import { GithubPicker } from 'react-color';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DataEmptyState from '../../commons/dataemptystate';
import NetworkEmptyState from '../../commons/networkemptystate';
import Loader from '../../commons/loader';
import Services from './services';
import { PaginationTotal } from '../../commons/controls';
import { ProgressButton } from '../../commons/ProgressButton';
import Lib from '../../commons/lib';

class AllapotContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // ['LOAD', 'DATA', 'NETW', 'TABL']
      tableStatus: 'TABL',
      // ['LOAD', 'DATA', 'NETW']
      formStatus: 'DATA',
      // ['FEL', 'MOD']
      formType: 'FEL',
      // DATA
      baseJson: [],
      currentId: undefined,
      torlesId: undefined,
      // MODAL UTILS
      modalForm: false,
      modalDelete: false,
      saveButtonLoading: false,
      deleteButtonLoading: false,
      // FORM VALUES
      formNev: '',
      formAzonosito: '',
      formSzinkod: '',
    };
  }

  // componentDidMount() {
  //   this.listAllapot();
  // }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.active !== prevProps.active) {
      if (this.props.active) {
        this.listAllapot();
      }
    }
  }

  toggleFormModal = () => {
    this.setState(prevState => ({ modalForm: !prevState.modalForm }));
  }

  toggleDeleteModal = () => {
    this.setState(prevState => ({ modalDelete: !prevState.modalDelete }));
  }

  getCorrectTextColor = (hex) => {
    /*
    From this W3C document: http://www.webmasterworld.com/r.cgi?f=88&d=9769&url=http://www.w3.org/TR/AERT#color-contrast

    Color brightness is determined by the following formula:
    ((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000

    I know this could be more compact, but I think this is easier to read/explain.

    */

    let threshold = 130; /* about half of 256. Lower threshold equals more dark text on dark background  */

    function cutHex(h) { return (h.charAt(0) === '#') ? h.substring(1, 7) : h; }
    function hexToR(h) { return parseInt((cutHex(h)).substring(0, 2), 16); }
    function hexToG(h) { return parseInt((cutHex(h)).substring(2, 4), 16); }
    function hexToB(h) { return parseInt((cutHex(h)).substring(4, 6), 16); }

    let hRed = hexToR(hex);
    let hGreen = hexToG(hex);
    let hBlue = hexToB(hex);

    let cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
    if (cBrightness > threshold) { return '#000000'; } return '#ffffff';
  }

  listAllapot = () => {
    this.setState({
      tableStatus: 'LOAD'
    }, () => {
      Services.listAllapot((err, res) => {
        if (!err) {
          this.setState({
            tableStatus: res[0].length > 0 ? 'TABL' : 'DATA',
            baseJson: res[0],
          });
        } else {
          this.setState({
            tableStatus: 'NETW'
          });
        }
      });
    });
  }

  getAllapot = (id) => {
    this.setState({
      formStatus: 'LOAD'
    });
    Services.getAllapot(id, (err, res) => {
      if (!err) {
        this.setState({
          formStatus: 'DATA',

          formNev: this.nullToString(res[0].nev),
          formAzonosito: this.nullToString(res[0].azonosito),
          formSzinkod: this.nullToString(res[0].szin),
        });
      } else {
        this.setState({
          formStatus: 'NETW'
        });
      }
    });
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  handleColorChange = (color) => {
    this.setState({ formSzinkod: color.hex.substr(1, 6) });
  }

  handleEditClick = (cell) => {
    this.setState({
      formType: 'MOD',
      currentId: cell,
      modalForm: true
    }, () => {
      this.getAllapot(cell);
    });
  }

  handleDeleteClick = (cell) => {
    this.setState({
      torlesId: cell
    }, () => {
      this.toggleDeleteModal();
    });
  }

  deleteAllapot = () => {
    this.setState({
      deleteButtonLoading: true
    });
    Services.deleteAllapot(this.state.torlesId, (err) => {
      if (!err) {
        this.toggleDeleteModal();
        this.listAllapot();
        this.props.globals.addSuccess('Állapot törölve!');
        this.setState({
          deleteButtonLoading: false
        });
      } else {
        this.setState({
          deleteButtonLoading: false
        });
      }
    });
  }

  emptyOrNull = (data) => {
    if (!data || data === '') return null;
    return data;
  }

  nullToString = (data) => {
    if (!data) return '';
    return data;
  }

  handleNewClick = () => {
    this.setState({
      formType: 'FEL',
      formStatus: 'DATA',
      formNev: '',
      formAzonosito: '',
      formSzinkod: '',
    }, () => {
      this.toggleFormModal();
    });
  }

  submitForm = () => {
    const {
      currentId,
      formNev,
      formAzonosito,
      formSzinkod,
      formType
    } = this.state;

    this.setState({
      saveButtonLoading: true
    });

    let message;
    switch (formType) {
      case 'FEL':
        message = 'felvéve';
        break;
      case 'MOD':
        message = 'módosítva';
        break;
      default:
        message = '';
    }

    let submitObj = {};

    submitObj.id = currentId;
    submitObj.azonosito = this.emptyOrNull(formAzonosito);
    submitObj.nev = this.emptyOrNull(formNev);
    submitObj.szin = this.emptyOrNull(formSzinkod);

    Services.saveTipusAllapot(submitObj, (err) => {
      if (!err) {
        this.props.globals.addSuccess('Feladattípus állapot ' + message + '!');
        this.setState({
          saveButtonLoading: false
        }, () => {
          this.toggleFormModal();
          this.listAllapot();
        });
      } else {
        this.setState({
          saveButtonLoading: false
        });
      }
    });
  }

  tableIconFormatter = (cell, row) => {
    return (
      <div>
        <React.Fragment>
          {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'FELADATLISTA_FELADATTIPUS', 'KARB') && (
            <React.Fragment>
              <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => this.handleEditClick(cell)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
              <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'modositas'} delay={50}>
                {'Módosítás'}
              </UncontrolledTooltip>
              {row.torolheto && (
                <React.Fragment>
                  <Button className="icon-trash table-action-icon" id={'action-view-' + cell + 'file'} onClick={() => this.handleDeleteClick(cell)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
                  <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'file'} delay={50}>
                    {'Törlés'}
                  </UncontrolledTooltip>
                </React.Fragment>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      </div>
    );
  }

  tableBooleanFormatter = (cell) => {
    return cell
      ? <span className="badge badge-success">Igen</span>
      : <span className="badge badge-danger">Nem</span>;
  }

  styleSzinkod = (szinkod) => {
    return {
      textAlign: 'center',
      whiteSpace: 'pre-line',
      backgroundColor: '#' + szinkod,
      color: this.getCorrectTextColor('#' + szinkod)
    };
  }

  renderFormTitle = () => {
    switch (this.state.formType) {
      case 'FEL':
        return 'Új állapot létrehozása';
      case 'MOD':
        return 'Állapot módosítása';
      default:
        return '';
    }
  }

  renderTable = () => {
    const options = {
      sizePerPage: 5,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const columns = [
      {
        dataField: 'azonosito',
        text: 'Azonosító',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
        },
        sort: true
      },
      {
        dataField: 'nev',
        text: 'Név',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
        },
        sort: true
      },
      {
        dataField: 'szin',
        text: 'Szín',
        style: this.styleSzinkod,
        formatter: (cell) => { return '#' + cell; },
        sort: false
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        hidden: !Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'FELADATLISTA_FELADATTIPUS', 'KARB'),
        formatter: this.tableIconFormatter
      }
    ];

    return (
      <div className="animated fadeIn">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={this.state.baseJson}
          columns={columns}
          wrapperClasses="table-responsive"
          classes="table-scroll"
          headerClasses="table-th"
          filter={filterFactory()}
          pagination={paginationFactory(options)}
          noDataIndication="Nincs megjelenítendő adat!"
        />
      </div>
    );
  }

renderForm = () => {
  const {
    formNev,
    formAzonosito,
    formSzinkod,
    formType
  } = this.state;

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-12">
          <AvGroup>
            <Label for="formAzonosito">Azonosító: *</Label>
            <AvInput
              name="formAzonosito"
              id="formAzonosito"
              maxLength="10"
              disabled={formType === 'MOD'}
              type="text"
              value={formAzonosito}
              onChange={this.handleInputChange}
            />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-12">
          <AvGroup>
            <Label for="formNev">Név: *</Label>
            <AvInput
              name="formNev"
              id="formNev"
              type="text"
              value={formNev}
              onChange={this.handleInputChange}
            />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-12">
          <AvGroup>
            <Label for="formSzinkod">Színkód: *</Label>
            <AvInput
              name="formSzinkod"
              id="formSzinkod"
              type="text"
              value={formSzinkod}
              disabled
              // onChange={this.handleInputChange}
            />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-12">
          <GithubPicker
            color={formSzinkod}
            onChangeComplete={this.handleColorChange}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

render() {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-12">
          {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'FELADATLISTA_FELADATTIPUS', 'KARB') && (
            <Button className="pull-left table-new-button" color="primary" onClick={this.handleNewClick}>
              {'Új állapot'}
            </Button>
          )}
        </div>
        <div className="col-md-12">
          {{
            LOAD: (<Loader />),
            DATA: (<DataEmptyState
              hasImage
              subtitle="Vigyél fel új tételt!"
            />),
            NETW: (<NetworkEmptyState
              hasImage
              submitClick={() => { this.listAllapot(); }}
            />),
            TABL: (this.renderTable())
          }[this.state.tableStatus]}
        </div>
      </div>
      <Modal isOpen={this.state.modalForm} toggle={this.toggleFormModal} backdrop="static" size="sm">
        <AvForm onValidSubmit={this.submitForm}>
          <ModalHeader>{this.renderFormTitle()}</ModalHeader>
          <ModalBody>
            {{
              LOAD: (<Loader />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => { this.state.currentId ? this.getAllapot(this.state.currentId) : this.handleNewClick(); }}
              />),
              DATA: (this.renderForm())
            }[this.state.formStatus]}
          </ModalBody>
          <ModalFooter>
            <ProgressButton
              className="btn-cursor-pointer"
              color="primary"
              loading={this.state.saveButtonLoading}
            >
              {'Mentés'}
            </ProgressButton>
            <Button type="button" color="secondary" className="btn-cursor-pointer" onClick={this.toggleFormModal}>Mégsem</Button>
          </ModalFooter>
        </AvForm>
      </Modal>
      <Modal isOpen={this.state.modalDelete} toggle={this.toggleDeleteModal} backdrop="static">
        <ModalHeader>Törlés</ModalHeader>
        <ModalBody>Valóban törölni kívánja a kiválasztott tételt?</ModalBody>
        <ModalFooter>
          <ProgressButton
            className="btn-cursor-pointer"
            color="primary"
            onClick={() => this.deleteAllapot()}
            loading={this.state.deleteButtonLoading}
          >
            {'Igen'}
          </ProgressButton>
          <Button type="button" color="secondary" onClick={this.toggleDeleteModal}>Mégsem</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
}

AllapotContent.propTypes = {
  active: PropTypes.bool,
  globals: PropTypes.object
};

export default AllapotContent;
