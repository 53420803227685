import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, UncontrolledTooltip, Badge } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import Services from './services';
import Lib from '../../../commons/lib';
import { PaginationTotal } from '../../../commons/controls';
import moment from 'moment';

// 0 -> Felvitel
// 1 -> módosítás
let _muvelet = 0;

let _modositoObj = null;

class ApartmanLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apartmanViewObject: null,
      modalFelvitel: false,
      modalNezet: false,
      modalTorles: false,
      modalKalendar: false,
      dataLoaded: false,
      dataViewLoaded: false,
      dataWeeksLoaded: false,
      dataFailed: false,
      dataFailedSzabadHetek: false,
      apartmanJson: [],
      aktivSzallodak: [],
      aktivEpuletek: [],
      aktivKategoriak: [],
      aktivSzamtulajdonosok: [],
      currentSzallodaEntity: null,
      aktivFenntartok: [],
      enums: [],
      kategoriaenum: [],
      weeks: [],
      kompenzaciora: 'false',
    };

    this.updateTable = this.updateTable.bind(this);
  }

  componentDidMount() {
    // this.getApartmanok();
    this.updateTable();
  }

    /** Dinamikusan töltendő szűkítő feltételek lekérdezése */
    getEnums = () => {
      this.setState({ enums: [], kategoriaenum: [] });
      Services.listApartmanEnums((err, res) => {
        if (!err) {
          this.setState({ enums: res[0][1], kategoriaenum: res[0][0] });
        }
      });
    }

  updateTable = () => {
    this.renderKategoriaOptions();
    this.renderSzallodaOptions();
    this.getEnums();
    this.setState({ dataLoaded: false, dataFailed: false });

    Services.listApartman((err, res) => {
      if (err) {
        this.setState({ dataLoaded: true, dataFailed: true });
      } else {
        let newArray = res[0];
        // Státusz stringgé alakítása
        newArray.forEach((pcs) => {
          if (pcs.statusz) {
            pcs.statusz = JSON.stringify(pcs.statusz);
          }
        });
        this.setState({ apartmanJson: newArray, dataLoaded: true, dataFailed: false });
      }
    });
  }

  fillModositoObj = () => {
    this.setState({ modifyDataLoaded: false, modfiyDataFailed: false });

    Services.getApartman(this._currentEditId, (err, res) => {
      if (err) {
        this.setState({ modifyDataLoaded: true, modfiyDataFailed: true });
      } else {
        this.setState({ modifyDataLoaded: true, modfiyDataFailed: false, kompenzaciora: res[0].kompenzaciora.toString() });
        _modositoObj = res[0];
        this.toggleFelvitel();
      }
    });
  }

  clearModositoObj = () => {
    _modositoObj = {
      id: '',
      azonosito: '',
      rciazonosito: '',
      apartmanhwazon: '',
      statusz: { name: 'AKTIV' },
      epuletentity: { id: '', szallodaentity: { id: '' } },
      fenntartoid: '',
      kategoria: '',
      kompenzaciora: 'false'
    };
  }

  toggleFelvitel = () => {
    this.setState({ modalFelvitel: !this.state.modalFelvitel });
  }

  toggleTorles = () => {
    this.setState({ modalTorles: !this.state.modalTorles });
  }

  toggleNezet = () => {
    this.setState({ modalNezet: !this.state.modalNezet });
  }

  toggleKalendar = () => {
    this.setState({ modalKalendar: !this.state.modalKalendar });
  }

  handleEditClick = (id) => {
    this.setState({ aktivEpuletek: [] });
    _muvelet = 1;
    this._currentEditId = id;
    this.fillModositoObj();
  }

  handleNewClick = () => {
    this.renderFormOptions();
    this.setState({ aktivEpuletek: [], kompenzaciora: 'false' });
    _muvelet = 0;
    this.clearModositoObj();
    this.toggleFelvitel();
  }

  handleDeleteClick = (id) => {
    this._currentDeleteId = id;
    this.toggleTorles();
  }

  handleViewClick = (id) => {
    this._currentViewId = id;
    this.setState({ dataViewLoaded: false });
    Services.getApartman(id, (err, res) => {
      if (!err) {
        this.setState({ apartmanViewObject: res[0], dataViewLoaded: true });
      }
    });
    this.toggleNezet();
  }

  handleCalendarClick = (id) => {
    this._currentViewId = id;
    this.setState({ dataWeeksLoaded: false, dataFailedSzabadHetek: false });
    Services.getSzabadHetek(id, null, null, (err, res) => {
      if (!err) {
        let weeks = JSON.parse(JSON.stringify(res[0]));
        let weeksMap = weeks.map(item => item.szabad);
        this.setState({ weeks: weeksMap, dataWeeksLoaded: true, dataFailedSzabadHetek: false });
      } else {
        this.setState({ dataWeeksLoaded: true, dataFailedSzabadHetek: true });
      }
    });
    this.toggleKalendar();
  }

  /**
    * Kezeli a szálloda választást.
    * @param {event} event - Aktuális event objektum.
    */
  szallodaInputChange = (event, value) => {
    Services.listAktivEpuletek(value, (err, res) => {
      if (!err) {
        let aktivEpuletek = res[0].map(e => <option key={e.id} value={e.id}>{e.nev} ({e.haz})</option>);
        this.setState({ aktivEpuletek: aktivEpuletek, aktivFenntartok: [], aktivSzamtulajdonosok: [] });
        this.renderFormOptions();
      }
    });
  }

  iconFormatter = (cell, row) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'APARTMAN_APARTMAN', 'LEK') && (
          <React.Fragment>
            <Button className="icon-eye table-action-icon" id={'action-view-' + cell + 'megtekintes'} onClick={() => this.handleViewClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'megtekintes'} delay={0}>
              Megtekintés
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        {Lib.KeyCloak.hasRoleSpec(this.props.globals.keycloak, ['APARTMAN_APARTMAN_SZABAD']) && (
          <React.Fragment>
            <Button className="icon-calendar table-action-icon" id={'action-view-' + cell + 'kalendar'} onClick={() => this.handleCalendarClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'kalendar'} delay={0}>
              Szabad hetek
            </UncontrolledTooltip>
          </React.Fragment>
        )}

        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'APARTMAN_APARTMAN', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => this.handleEditClick(cell, row)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'modositas'} delay={0}>
              Módosítás
            </UncontrolledTooltip>
            <Button className="icon-trash table-action-icon" id={'action-view-' + cell + 'torles'} onClick={() => this.handleDeleteClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'torles'} delay={0}>
              Törlés
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  /**
  * Visszaadja az apartmanhoz tartozó státus jelvényeket.
  * @param {boolean} cell - Cella adatot tartalmazó szöveg.
  * @return {ReactElement} Státusz jelvényt tartalmazó react objektum.
  */
  statusFormatter = (cell) => {
    return cell
      ? <span className="badge badge-success">Aktív</span>
      : <span className="badge badge-default">Inaktív</span>;
  }

  renderStatusOptions = () => {
    return this.state.enums.map(e => <option key={e.name} value={e.name}>{e.caption}</option>);
  }

  renderStatusTypes = () => {
    let statusType = {};
    if (this.state.enums) {
      this.state.enums.map(e => Object.assign(statusType, { [JSON.stringify(e)]: e.caption }));
    }
    return statusType;
  }

  renderSzallodaOptions = () => {
    // Aktív szállodák.
    Services.listAktivSzallodak((err, res) => {
      if (!err) {
        let aktivSzallodak = res[0].map(e => <option key={e.id} value={e.id}>{e.nev}</option>);
        this.setState({ aktivSzallodak: aktivSzallodak });
      }
    });
  }

  renderKategoriaOptions = () => {
    Services.listApartmanEnums((err, res) => {
      if (!err) {
        let aktivKategoriak = res[0][0].map(e => <option key={e.name} value={e.name}>{e.caption}</option>);
        this.setState({ aktivKategoriak: aktivKategoriak });
      }
    });
  }

  renderFormOptions = () => {
    Services.listSzamlazoFenntarto('APARTMAN_FENNTARTO_LIST_FORM', (err, res) => {
      if (!err) {
        let aktivFenntartok = res[0].map(e => <option key={e.id} value={e.id}>{e.nev}</option>);
        this.setState({ aktivFenntartok: aktivFenntartok });
      }
    });
  }

  /**
  * Visszaadja a partnercsoporthoz tartozó státus jelvényeket.
  * @param {boolean} cell - Cella adatot tartalmazó szöveg.
  * @return {ReactElement} Státusz jelvényt tartalmazó react objektum.
  */
  statusFormatter = (cell) => {
    if (!cell) return;
    if (cell === '{"name":"AKTIV","caption":"Aktív"}') {
      return <span className="badge badge-success">Aktív</span>;
    }
    return <span className="badge badge-default">Inaktív</span>;
  }

  addApartman = (event, apartmanObj) => {
    if (apartmanObj.kategoria === '') apartmanObj.kategoria = null;
    apartmanObj.besorolas = 'BESOROLAS1';
    apartmanObj.szezon = 'SZEZON1';
    apartmanObj.epuletentity = { id: apartmanObj.epuletid, szallodaentity: { id: apartmanObj.szallodaid } };

    Services.createApartman(apartmanObj, (err, res) => {
      if (!err) {
        this.toggleFelvitel();
        this.updateTable();
        this.props.globals.addSuccess('Apartman felvéve!');
      }
    });
  }

  updateApartman = (event, apartmanObj) => {
    _modositoObj.epuletentity.statusz = _modositoObj.epuletentity.statusz.name;
    apartmanObj.besorolas = 'BESOROLAS1';
    apartmanObj.szezon = 'SZEZON1';
    apartmanObj.id = this._currentEditId;
    apartmanObj.epuletentity = _modositoObj.epuletentity;
    apartmanObj.epuletentity.szallodaentity.statusz = apartmanObj.epuletentity.szallodaentity.statusz.name;
    apartmanObj.kompenzaciora = this.state.kompenzaciora.toString();
    if (apartmanObj.kategoria === '') {
      apartmanObj.kategoria = null;
    }

    Services.updateApartman(apartmanObj, (err) => {
      if (!err) {
        this.toggleFelvitel();
        this.updateTable();
        this.props.globals.addSuccess('Apartman módosítva!');
      }
    });
  }

  deleteApartman = (id) => {
    this.toggleTorles();
    Services.deleteApartman(id, (err) => {
      if (!err) {
        this.updateTable();
        this.props.globals.addSuccess('Apartman törölve!');
      }
    });
  }

  renderTable = () => {
    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ],
    };

    // Státusz típusok meghatározása
    let statusType = {};
    if (this.state.enums) {
      if (this.state.enums.length) {
        this.state.enums.map(e => Object.assign(statusType, { [JSON.stringify(e)]: e.caption }));
      }
    }

    return this.state.apartmanJson.length ? (
      <BootstrapTable data={this.state.apartmanJson} pagination options={options}>
        <TableHeaderColumn dataField="szalloda" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Szálloda</TableHeaderColumn>
        <TableHeaderColumn dataField="epulet" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Épület</TableHeaderColumn>
        {/* <TableHeaderColumn dataField="haz" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Ház</TableHeaderColumn> */}
        <TableHeaderColumn dataField="nev" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Apartman</TableHeaderColumn>
        <TableHeaderColumn dataField="hwazon" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>HostWare azonosító</TableHeaderColumn>
        <TableHeaderColumn dataField="szoba" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Szobaszám</TableHeaderColumn>
        <TableHeaderColumn
          dataField="statusz"
          dataFormat={this.statusFormatter}
          filter={{ type: 'SelectFilter', options: this.renderStatusTypes(), placeholder: 'Összes' }}
          dataAlign="center"
        >
          Státusz
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="id"
          isKey
          dataFormat={this.iconFormatter}
          width="125"
        >
          Műveletek
        </TableHeaderColumn>
      </BootstrapTable>
    ) : <DataEmptyState />;
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  renderAdatok = () => {
    return this.state.dataViewLoaded ? (
      <div>
        <h6>Apartman információ:</h6>
        <b>Azonosító: </b>{this.state.apartmanViewObject && this.state.apartmanViewObject.azonosito}<br />
        <b>Név: </b>{this.state.apartmanViewObject && this.state.apartmanViewObject.nev}<br />
        <b>Szálloda: </b>{this.state.apartmanViewObject && this.state.apartmanViewObject.epuletentity && this.state.apartmanViewObject.epuletentity.szallodanev}<br />
        <b>Épület: </b>{this.state.apartmanViewObject && this.state.apartmanViewObject.epuletentity && this.state.apartmanViewObject.epuletentity.nev}<br />
        {/*<b>Ház: </b>{this.state.apartmanViewObject && this.state.apartmanViewObject.epuletentity && this.state.apartmanViewObject.epuletentity.haz}<br />*/}
        <b>Kategória: </b>{this.state.apartmanViewObject && this.state.apartmanViewObject.kategoria && this.state.apartmanViewObject.kategoria.caption}<br />
        <b>Fenntartó: </b>{this.state.apartmanViewObject && this.state.apartmanViewObject.fenntarto}<br />
        <b>Szobaszám: </b>{this.state.apartmanViewObject && this.state.apartmanViewObject.szobaszam}<br />
        <b>Apartman HostWare azonosító: </b>{this.state.apartmanViewObject && this.state.apartmanViewObject.apartmanhwazon}<br />
        <b>Apartman RCI azonosító: </b>{this.state.apartmanViewObject && this.state.apartmanViewObject.rciazonosito}<br />
        <b>Kompenzációra igénybevehető: </b>{this.state.apartmanViewObject && this.state.apartmanViewObject.kompenzaciora ? 'Igen' : 'Nem'}<br />
        <b>Státusz: </b>{this.state.apartmanViewObject && this.state.apartmanViewObject.statusz && this.state.apartmanViewObject.statusz.caption}<br />
      </div>
    ) : this.renderLoader();
  }

  renderMagyarazat = () => {
    let newArray = [];
    newArray.push(<Badge pill color="light" key="1" style={{ color: 'black' }}>Szabad</Badge>);
    newArray.push(<Badge pill color="danger" key="2" style={{ color: 'black' }}>Foglalt</Badge>);
    return newArray;
  }

  renderButtons = () => {
    let newArray = [];
    console.log(this.state.weeks);
    this.state.weeks.map((item, index) => {
      let i = index + 1;
      if (item === true) {
        newArray.push(<Button value={i} key={i} style={{ width: 50, height: 50, backgroundColor: 'rgb(255,255,255)' }}>{i}</Button>);
      } else {
        newArray.push(<Button value={i} key={i} style={{ width: 50, height: 50, backgroundColor: 'rgb(255,0,0)' }}>{i}</Button>);
      }
    });

    // for (let i = 1; i < 53; i += 1) {
    //   if (this.state.weeks.includes(i)) {
    //     newArray.push(<Button value={i} key={i} style={{ width: 50, height: 50, backgroundColor: 'rgb(255,255,255)' }}>{i}</Button>);
    //   } else {
    //     newArray.push(<Button value={i} key={i} style={{ width: 50, height: 50, backgroundColor: 'rgb(255,0,0)' }}>{i}</Button>);
    //   }
    // }

    return newArray;
  }

  renderSzabadHetek = () => {
    return (
      (this.state.dataWeeksLoaded && !this.state.dataFailedSzabadHetek) ? (
        <div>
          <div className="hetek-magyarazat" style={{ backgroundColor: 'rgb(220,220,220)' }}>
            {this.renderMagyarazat()}
          </div>
          <div>
            {this.renderButtons()}
          </div>
        </div>
      )
        : (this.state.dataFailedSzabadHetek ? <NetworkEmptyState /> : this.renderLoader())
      );
  }

  renderSzallodaEpuletInputs = () => {
    return (
      <div>
        <AvField type="select" name="szallodaid" label="Szálloda: *" errorMessage="Kötelező mező!" required value={_modositoObj && _modositoObj.epuletentity.szallodaentity.id} onChange={this.szallodaInputChange}>
          <option value="" disabled>
            Válassz szállodát...
          </option>
          {this.state.aktivSzallodak}
        </AvField>
        <AvField type="select" name="epuletid" label="Épület: *" errorMessage="Kötelező mező!" disabled={!this.state.aktivEpuletek.length} required value={_modositoObj && _modositoObj.epuletentity.id}>
          <option value="" disabled>
            Válassz épületet...
          </option>
          {this.state.aktivEpuletek}
        </AvField>
      </div>
    );
  }

  render() {
    return (
      <div>
        {(this.state.dataLoaded && !this.state.dataFailed) ? (
          <div className="row">
            <div className="col-sm-12">
              {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'APARTMAN_APARTMAN', 'KARB') && (
                <Button className="pull-left table-new-button" color="primary" onClick={this.handleNewClick}>
                  Új apartman
                </Button>
              )}
            </div>
          </div>
        ) : <span />}
        {(this.state.dataLoaded && !this.state.dataFailed) ? this.renderTable() : (this.state.dataFailed) ? <NetworkEmptyState submitClick={this.updateTable} /> : this.renderLoader()}
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>
            Valóban törölni kívánja a kiválasztott tételt?
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteApartman(this._currentDeleteId)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalNezet} toggle={this.toggleNezet} className={this.props.className} backdrop="static">
          <ModalHeader >Megtekintés</ModalHeader>
          <ModalBody>{this.renderAdatok()}</ModalBody>
          <ModalFooter> <Button type="button" color="primary" onClick={this.toggleNezet} className="btn-cursor-pointer" >Vissza</Button></ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalKalendar} toggle={this.toggleKalendar} className={this.props.className} backdrop="static">
          <ModalHeader>Szabad hetek {moment().format('YYYY')} </ModalHeader>
          <ModalBody>
          {this.renderSzabadHetek()}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.toggleKalendar()}>OK</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalFelvitel} toggle={this.toggleFelvitel} className={this.props.className} backdrop="static" >
          <AvForm onValidSubmit={_muvelet === 0 ? this.addApartman : this.updateApartman}>
            {_muvelet === 0 ? <ModalHeader>Új apartman rögzítése</ModalHeader> : <ModalHeader>Meglévő apartman módosítása</ModalHeader> }
            <ModalBody>
              <AvGroup>
                <Label for="azonosito">Apartman azonosítója: *</Label>
                <AvInput name="azonosito" id="azonosito" required disabled={_muvelet !== 0} value={_modositoObj && _modositoObj.azonosito} maxLength="6" />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="nev">Apartman neve: *</Label>
                <AvInput name="nev" id="nev" required value={_modositoObj && _modositoObj.nev} maxLength="20" />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
              { _muvelet === 0 ? this.renderSzallodaEpuletInputs() : <span />}
              <AvGroup>
                <Label for="szobaszam">Szobaszám: </Label>
                <AvInput name="szobaszam" id="szobaszam" value={_modositoObj && _modositoObj.szobaszam} maxLength="5" />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="apartmanhwazon">Apartman HostWare azonosító:</Label>
                <AvInput name="apartmanhwazon" id="apartmanhwazon" value={_modositoObj && _modositoObj.apartmanhwazon} maxLength="4" />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="rciazonosito">Apartman RCI azonosító:</Label>
                <AvInput name="rciazonosito" id="rciazonosito" value={_modositoObj && _modositoObj.rciazonosito} maxLength="5" />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
              <AvField type="select" name="fenntartoid" label="Fenntartó: " errorMessage="Kötelező mező!" value={_modositoObj && _modositoObj.fenntartoid} disabled={!this.state.aktivEpuletek.length && _muvelet === 0}>
                <option value="" disabled>Válassz fenntartót...</option>
                {this.state.aktivFenntartok}
              </AvField>
              <AvField type="select" name="kategoria" label="Kategória: " errorMessage="Kötelező mező!" value={(_modositoObj && _modositoObj.kategoria) && _modositoObj.kategoria.name} disabled={!this.state.aktivEpuletek.length && _muvelet === 0}>
                <option value="" disabled>Válassz kategóriát...</option>
                {this.state.aktivKategoriak}
              </AvField>
              <AvField type="select" name="kompenzaciora" label="Kompenzációra igénybevehető: *" errorMessage="Kötelező mező!" value={this.state.kompenzaciora} disabled={!this.state.aktivEpuletek.length && _muvelet === 0} onChange={e => this.setState({ kompenzaciora: e.target.value.toString() })}>
                <option value="false">Nem</option>
                <option value="true">Igen</option>
              </AvField>
              <AvField type="select" name="statusz" label="Státusz: *" errorMessage="Kötelező mező!" value={_modositoObj && _modositoObj.statusz.name} disabled={!this.state.aktivEpuletek.length && _muvelet === 0}>
                <option value="AKTIV">Aktív</option>
                <option value="INAKTIV">Inaktív</option>
              </AvField>
            </ModalBody>
            <ModalFooter>
              <Button color="primary">Mentés</Button>{' '}
              <Button type="button" color="secondary" onClick={this.toggleFelvitel}>Mégse</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </div>
    );
  }
}

export default ApartmanLista;
