import React from 'react';
import YouTube from '../../../commons/youtube';
import SzamlaListaContent from './SzamlaListaContent';

const videoData = [
  { text: 'Számla lista', id: 'KAxQV77nCGQ' }
];

const SzamlaListaBase = (props) => {
  let cardStyle = {
    padding: 20
  };

  return (
    <div className="animated fadeIn">
      <div className="card">
        <div className="card-header">
          {'Számla lista'}
          {/* <YouTube data={videoData} /> */}
        </div>
        <div className="row" style={cardStyle}>
          <div className="col-md-12">
            <SzamlaListaContent
              globals={props.route.globals}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SzamlaListaBase;
