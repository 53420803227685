import Lib from '../../commons/lib';

export default class Service {
  static penzugyUrl = window.location.origin + '/api/penzugykozos/';

  static bankUrl = window.location.origin + '/api/bank/';

  static partnerUrl = window.location.origin + '/api/partner/';

  // BANKFEJ START
  static listSzamlak(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'bankszamlapenztar/listbankkivonatbankszamla',
      args: { requestType: 'bankszamlapenztar-listbankkivonatbankszamla' }
    }], fnDone);
  }

  static getEnums(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bankUrl + 'bankkivonat/enum',
      args: { requestType: 'bankkivonat-enum' },
    }], fnDone);
  }

  static listAktivSzamlak(type, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'bankszamlapenztar/listaktivbeans',
      args: { requestType: 'bankszamlapenztar-listaktivbeans' },
      params: {
        type: type
      }
    }], fnDone);
  }

  static listTable(params, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bankUrl + 'bankkivonat/list',
      args: { requestType: 'bankkivonat-list' },
      params: params
    }], fnDone);
  }

  static getAktivPartner(text, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'search-getlist' },
      params: {
        type: 'BANKKIVONATTETEL_PARTNER_FORM',
        text: text
      }
    }], fnDone);
  }

  static getBankfej(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bankUrl + 'bankkivonat',
      args: { requestType: 'bankkivonat-get' },
      params: { id: id }
    }], fnDone);
  }

  static addBankfej(kivonatObj, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.bankUrl + 'bankkivonat',
      args: { requestType: 'bankkivonat-post' },
      data: kivonatObj
    }], fnDone);
  }

  static deleteBankfej(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.bankUrl + 'bankkivonat',
      args: { requestType: 'bankkivonat-delete' },
      params: { id: id }
    }], fnDone);
  }

  static downloadZip(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.bankUrl + 'bankkivonat/feladasKS',
      args: { requestType: 'bankkivonat-feladasKS' },
      acceptType: 'application/json, application/octet-stream',
      responseType: 'arraybuffer',
      params: { id: id }
    }], fnDone);
  }

  static editBankfej(id, kivonatObj, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.bankUrl + 'bankkivonat',
      args: { requestType: 'bankkivonat-put' },
      data: kivonatObj,
      params: { id: id }
    }], fnDone);
  }

  static getNyito(id, datum, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bankUrl + 'bankkivonat/getnextnyito',
      args: { requestType: 'bankkivonat-getnextnyito' },
      params: { 
        id: id,
        datum: datum
      }
    }], fnDone);
  }

  // BANKFEJ END

  // BANKKIVONAT START

  static listKivonat(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bankUrl + 'btranzakcio/list',
      args: { requestType: 'tranzakcio-list' },
      params: {
        id: id
      }
    }], fnDone);
  }

  static addKivonat(kivonatObj, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.bankUrl + 'btranzakcio',
      args: { requestType: 'tranzakcio-post' },
      data: kivonatObj
    }], fnDone);
  }

  static deleteKivonat(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.bankUrl + 'btranzakcio',
      args: { requestType: 'tranzakcio-delete' },
      params: { id: id }
    }], fnDone);
  }

  static getKivonat(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bankUrl + 'btranzakcio',
      args: { requestType: 'tranzakcio-get' },
      params: { id: id }
    }], fnDone);
  }

  static sendFile(id, data, file, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.bankUrl + 'btranzakcio/csekkesallomany',
      args: {
        requestType: 'tranzakcio-csekkesallomany-post',
      },
      contentType: 'application/octet-stream',
      data: data,
      params: { id: id, file },
    }], fnDone);
  }

  static editKivonat(id, kivonatObj, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.bankUrl + 'btranzakcio',
      args: { requestType: 'tranzakcio-put' },
      data: kivonatObj,
      params: { id: id }
    }], fnDone);
  }

  static getSzamlaByPartner(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'bankszamlatortenet',
      args: { requestType: 'bankszamlatortenet-list' },
      params: {
        id: id
      }
    }], fnDone);
  }

  static listAktivPenznem(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'penznem/aktiv',
      args: { requestType: 'penznem-aktiv' }
    }], fnDone);
  }

  static getDefaultPenznem(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'penznem/defaultcurrency',
      args: { requestType: 'penznem-defaultcurrency' }
    }], fnDone);
  }

  static exportBankalapXLS(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bankUrl + 'bankkivonat/bankalaplista/xls',
      acceptType: 'application/json, application/octet-stream',
      params: {
        listak: false,
        bankkivonatid: id
      }
    }], fnDone);
  }

  // BANKKIVONAT END

  // ANALITIKA START

  static listAnalitika(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bankUrl + 'kivonattetel/list',
      args: { requestType: 'kivonattetel-list' },
      params: {
        id: id
      }
    }], fnDone);
  }

  static getAnalitika(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bankUrl + 'kivonattetel',
      args: { requestType: 'kivonattetel-get' },
      params: {
        id: id
      }
    }], fnDone);
  }

  static findForBankkivonatTranzakcio(biz, nev, enumm, szkid, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bankUrl + 'search/findforbtranz',
      args: { requestType: 'search-findforbtranz' },
      params: {
        azon: biz,
        nev: nev,
        enum: enumm,
        szkid: szkid
      }
    }], fnDone);
  }

  static findForBankkivonatTetel(biz, nev, ttip, penz, tip, enumm, szkid, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bankUrl + 'search/findforbtetel',
      args: { requestType: 'search-findforbtetel' },
      params: {
        azon: biz,
        nev: nev,
        penz: penz,
        tip: tip,
        enum: enumm,
        szkid: szkid
      }
    }], fnDone);
  }

  static getPartnerSzamlakibocsato(type, text, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'search-findszamla' },
      params: {
        type: type,
        text: text
      }
    }], fnDone);
  }

  static getPartner(nev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search/bankkivonattetelform',
      args: { requestType: 'search-findszamla' },
      params: {
        nev: nev,
      }
    }], fnDone);
  }

  static addAnalitika(analObj, arfolyam, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.bankUrl + 'kivonattetel',
      args: { requestType: 'kivonattetel-post' },
      params: {
        arfolyam: arfolyam
      },
      data: analObj
    }], fnDone);
  }

  static editAnalitika(id, kivonatObj, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.bankUrl + 'kivonattetel',
      args: { requestType: 'kivonattetel-put' },
      data: kivonatObj,
      params: { id: id }
    }], fnDone);
  }

  static deleteAnalitika(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.bankUrl + 'kivonattetel',
      args: { requestType: 'kivonattetel-delete' },
      params: { id: id }
    }], fnDone);
  }

  static deleteAllAnalitika(ids, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.bankUrl + 'kivonattetel/deleten',
      args: { requestType: 'kivonattetel-delete' },
      data: ids
    }], fnDone);
  }

  static getCheck(id, penz, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bankUrl + 'kivonattetel/check',
      args: { requestType: 'kivonattetel-check' },
      params: {
        id: id,
        penz: penz
      }
    }], fnDone);
  }

  // ANALITIKA END

  // PÉNZTÁR FEJ START

  static listPenztarak(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'bankszamlapenztar/listhazipenztarpenztar',
      args: { requestType: 'bankszamlapenztar-listhazipenztarpenztar' }
    }], fnDone);
  }

  static listPenztarFejTable(params, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bankUrl + 'penztarfej/list',
      args: { requestType: 'penztarfej-list' },
      params: params
    }], fnDone);
  }

  // PÉNZTÁR FEJ END

  // PÉNZTÁR TRANZAKCIO START

  static listPenztarTranzakcioTable(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bankUrl + 'ptranzakcio/list',
      args: { requestType: 'ptranzakcio-list' },
      params: {
        id: id
      }
    }], fnDone);
  }

  // PÉNZTÁR TRANZAKCIO END


  // PÉNZTÁR TÉTEL START

  static listPenztarTetelTable(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bankUrl + 'penztartetel/list',
      args: { requestType: 'penztartetel-list' },
      params: {
        id: id
      }
    }], fnDone);
  }

  // PÉNZTÁR TÉTEL END
}
