const tooltips = {
  cikkcsoport: 'A Cikktörzsben rögzített / rögzítendő cikkek egyedi igények szerint, több szintű cikkcsoportokba rendezhetők a Cikkcsoport funkció segítségével, a felhasználók tényleges működésének megfelelően. A struktúrát mindig fentről lefelé kell rögzíteni. A felettes cikkcsoportokat kell először, majd az alábontásokhoz hozzá kell rendelni a közvetlen felettes cikkcsoportját.\nCikkek mindig csak a cikkcsoport struktúra legalsó szintjéhez rögzíthetők. Törlés esetén pedig a felhasználónak mindig alulról felfelé kell a törlést végrehajtani. További feltétel, hogy a törlendő cikkcsoporthoz nem tartozhat cikkszám a nyilvántartásban.',
  cikktorzs: 'A felület a rendszerben nyilvántartott cikkek (termékek és szolgáltatások) kezelésére szolgál. Ez a nyilvántartás lesz több modul alapja. A nyilvántartásban a cikkek felhasználó számára fontos tulajdonságai rögzíthetők. A cikkeknek vannak kötelező tartalmi elemei – ezeket fogja későbbiekben a rendszer más modulokban használni – és vannak szabadon kialakítható tulajdonságai, melyeket a felhasználó a cikk felületen rögzíthet az Új mező nyomógomb segítségével (ezek száma nincs korlátozva a rendszerben). Tulajdonságoknál a felhasználó a tulajdonság nevét és annak értékét tudja megadni.',
  cikktipus: 'A cikkek lehetnek egyszerű és összetett (Receptúra) cikkek. Összetett cikkek esetén a felhasználó több cikket tud összekapcsolni és mozgatni a cikkek megfelelő mennyiségével együtt.',
  cikkszam: 'A cikkek rögzítése során a rendszer nem oszt ki cikkszámokat, azokat a felhasználó saját maga alakíthatja ki. A mezőbe az angol ABC kis- és nagybetűi, számok, valamint pont ".", alulvonás "_", kötőjel "-", tört vonal "/" speciális karakterek rögzíthetők.',
  mennyisegiegyseg: 'A cikkek rögzítéséhez szükséges mennyiségi egységek törzsállománya dinamikusan bővíthető a felhasználó által a cikk rögzítési felületen az Új mennyiségi egység nyomógomb segítségével. Ezek a rendszerben tárolásra kerülnek, majd később a további cikkek rögzítésénél a felhasználó által kiválaszthatók.',
  torles: 'Cikkek csak addig törölhetők, míg a rendszerben további felhasználás nem került rögzítésre hozzájuk. Minden egyéb esetben csak inaktiválhatók.',
  afa: 'A cikk rögzítéshez itt alakítható ki az az áfa törzs, melynek értékei hozzárendelhetők a cikkszámokhoz.',
  afaKulcs: 'A mező a számlázási szabályoknak megfelelő kulcsokkal töltendő.',
};

export default function getTooltip(name) {
  return tooltips[name];
}
