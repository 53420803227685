import Lib from '../../commons/lib';

export default class Service {
  static gdprUrl = window.location.origin + '/api/gdpr/';

  static partnerUrl = window.location.origin + '/api/partner/';

  // GDPR START

  static getGDPRList(partnerId, date, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.gdprUrl + 'naplo/list',
      args: { requestType: 'gdpr-naplo-list' },
      params: {
        id: partnerId,
        date: date
      }
    }], fnDone);
  }

  static getPartner(text, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'search-getlist' },
      params: {
        type: 'GDPR_NAPLO_PARTNER_ELO',
        text: text
      }
    }], fnDone);
  }

  static checkElevules(ids, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.gdprUrl + 'naplo/checkelevules',
      args: {
        requestType: 'gdprUrl-checkelevules',
      },
      data: ids
    }], fnDone);
  }

  static deleteBySelect(ids, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.gdprUrl + 'naplo/deletebyselect',
      args: {
        requestType: 'gdprUrl-deletebyselect',
      },
      data: ids
    }], fnDone);
  }

  static deletePartner(ids, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.gdprUrl + 'naplo/deletepartner',
      args: { requestType: 'gdprUrl-deletepartner' },
      data: ids
    }], fnDone);
  }

  // GDPR END
}
