
import React, { useState, useEffect } from 'react';
import { 
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { AvForm, AvInput, } from 'availity-reactstrap-validation';
import Services from '../services';
import Select from 'react-select/lib/Async';
import { DateTimePicker } from 'react-widgets';
import NumberFormatter from '../../../commons/NumberFormatter';
import _ from 'lodash';
import moment from 'moment';
import SlaveAvInput from '../../../commons/SlaveAvInput.js';
import { setNettoPrices } from './SzallitoiPriceCalculator';


const ROW_STYLE = { marginBottom: '15px' };
const SzallitoiCikkForm = props => {
    const {
      id,
      parentId,
      route,
      isBontas,
      isElkuldott,
      cikkek,
      penznem,
      setCikkek,
      toggleShowCikk, 
    } = props;

    const [cikkCsoportArray, setCikkcsoportArray] = useState([]);
    const [cikk, setCikk] = useState(null);
    const [cikkNev, setCikkNev] = useState('');
    const [newCikk, toggleNewCikk] = useState(false);
    const [mennyiseg, setMennyiseg] = useState('');
    const [visszaMennyiseg, setVisszaMennyiseg] = useState(null);
    const [hatarido, setHatarido] = useState(null);
    const [visszaHatarido, setVisszaHatarido] = useState(null);
    const [nettoEgysegar, setNettoEgysegar] = useState('');
    const [bruttoEgysegar, setBruttoEgysegar] = useState('');
    const [nettoBeszerzesiar, setNettoBeszerzesiar] = useState('');
    const [bruttoBeszerzesiar, setBruttoBeszerzesiar] = useState('');
    const [megjegyzes, setMegjegyzes] = useState('');
    const [cikkModal, toggleCikkModal] = useState(true);
    const [bontasArray, setBontasArray] = useState([{id: 0,  new: true, visszaMennyiseg: '', visszaHatarido: null}]);

    const { removeRedundantZeros } = NumberFormatter;

    useEffect(() => {
      if(id) getSzallitoiCikkRendeles();
    }, []);

    useEffect(() => {
      toggleShowCikk(cikkModal);
    }, [cikkModal]);

    const loadCikkCsoportOptions = (input, callback) => {
      if(_.isEmpty(input)) callback([]);
      Services.listCikkCsoport({param: input}, (err, res) => {
        if(!err){
          const options = res[0].filter( k => k.nev.toLowerCase().includes(input.toLowerCase()))
          callback(options);
        }else{
          callback([]);
        }
      });
    }

    const loadCikkOptions =  (input, callback) => {
      if(_.isEmpty(input)) callback([]);
      Services.listCikk({param: input, receptura: false, csoportIds: (cikkCsoportArray && cikkCsoportArray.map(cs => cs.id)) || []}, (err, res) => {
        if(!err){
          const options = res[0].filter( k => k.megnevezes.toLowerCase().includes(input.toLowerCase()))
          callback(options);
        }else{
          callback([]);
        }
      });
    }

    const generateUUID = () => {
      return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16));
    }

    const setMennyisegPrice = async (value) => {
      if(nettoEgysegar && nettoEgysegar !== '' && bruttoBeszerzesiar && bruttoBeszerzesiar !== ''){
        await setPrices(null, null, value);
      }
      setMennyiseg(value);
    }

    const setPrices = (netto, brutto, newMennyiseg) => {
      const decDigit = penznem ? penznem.tizedesjegy : 6;
      const decMult = decDigit == 0 ? 1 : decDigit;
      let nettoEgyseg, nettoBesz, bruttoEgyseg, bruttoBesz;
      if(netto){
        if (typeof netto == "string") netto = NumberFormatter.removeSpacesFromString(netto);
        nettoEgyseg = netto;
        let pricesArray = setNettoPrices(netto, newMennyiseg, decDigit, decMult, cikk);
        nettoBesz = pricesArray[0];
        bruttoEgyseg = pricesArray[1];
        bruttoBesz =  pricesArray[2];
      }
      if(brutto){
        if (typeof brutto == "string") brutto = NumberFormatter.removeSpacesFromString(brutto);
        bruttoBesz = brutto;
        nettoEgyseg = (Math.round(Number( brutto / newMennyiseg / (1 + cikk.aktualisAfaErtek / 100)) * Math.pow(10, decMult)) / Math.pow(10, decMult)).toFixed(6);
        nettoBesz =  (Math.round((Number(nettoEgyseg) * newMennyiseg) * Math.pow(10, decMult)) / Math.pow(10, decMult)).toFixed(decDigit);
        bruttoEgyseg = (Math.round(Number(nettoEgyseg * (1 + (cikk.aktualisAfaErtek / 100))) * Math.pow(10, 6)) / Math.pow(10, 6)).toFixed(6);
      }
      if(!netto && !brutto){
        const diff = newMennyiseg / mennyiseg;
        nettoEgyseg = (Math.round((nettoEgysegar * diff) * Math.pow(10, decMult)) / Math.pow(10, decMult)).toFixed(decDigit);
        nettoBesz = (Math.round((nettoBeszerzesiar * diff) * Math.pow(10, decMult)) / Math.pow(10, decMult)).toFixed(decDigit);
        bruttoEgyseg = (Math.round((bruttoEgysegar * diff) * Math.pow(10, decMult)) / Math.pow(10, decMult)).toFixed(decDigit);
        bruttoBesz = (Math.round((bruttoBeszerzesiar * diff) * Math.pow(10, decMult)) / Math.pow(10, decMult)).toFixed(decDigit);
      }
      setNettoEgysegar(removeRedundantZeros(nettoEgyseg && nettoEgyseg.toString()));
      setNettoBeszerzesiar(removeRedundantZeros(nettoBesz && nettoBesz.toString()));
      setBruttoEgysegar(removeRedundantZeros(bruttoEgyseg && bruttoEgyseg.toString()));
      setBruttoBeszerzesiar(removeRedundantZeros(bruttoBesz && bruttoBesz.toString()));
    }

    const getSzallitoiCikkRendeles = () => {
      const edited = cikkek.find(c => c.id == id);
      if(edited){
        toggleNewCikk(edited.new);
        setCikk(edited.cikk);
        setCikkNev(edited.cikkNev);
        setMennyiseg(edited.mennyiseg);
        setHatarido(edited.hatarido);
        setNettoEgysegar(edited.nettoEgysegar);
        setBruttoEgysegar(edited.bruttoEgysegar);
        setNettoBeszerzesiar(edited.nettoBeszerzesiar);
        setBruttoBeszerzesiar(edited.bruttoBeszerzesiar);
        setMegjegyzes(edited.megjegyzes);
        if(!isBontas){
          setVisszaMennyiseg(edited.visszaMennyiseg);
          setVisszaHatarido(edited.visszaHatarido);
        }else{
          if(!Array.isArray(edited.bontasok)){
            edited.bontasok = [];
          }
          setBontasArray([...edited.bontasok, ...bontasArray]);
        }
      }
    }

    const setBontottValue = (value, idx, field) => {
      let newVal = value;
      bontasArray[idx][field] = newVal;
      setBontasArray([...bontasArray]);
    }

    const saveSzallitoiCikkRendeles = () => {
      if (!cikk) {
        route.globals.addWarning('Cikk választása kötelező!');
        return;
      }
      if (!hatarido) {
        route.globals.addWarning('Határidő megadása kötelező!');
        return;
      }
      if(cikkek.filter(cikk => !cikk.rendelesCikkEntity).find(c => !id && c.cikkId == cikk.id)){
        route.globals.addWarning('A kiválaszott cikk már szerepel a felsorolásban!');
        return;
      }
      if(isBontas && bontasArray && mennyiseg < bontasArray.reduce((acc, curr) => acc + curr.visszaMennyiseg), 0){
        route.globals.addWarning('A bontások visszaigazolt mennyisége nem lehet több az eredetileg megadottnál!');
        return;
      }

      let bontottArray;
      if (isBontas) {
        bontottArray = bontasArray.filter(b => b.mennyiseg && !isNaN(b.mennyiseg) && b.hatarido);
        if (bontottArray.length < 2) {
          route.globals.addWarning('A bontás során legalább kettő mennyiséggel és határidővel töltött részre kell bontani a cikket!');
          return;
        }
        if (bontottArray.some(b => b.mennyiseg <= 0)) {
          route.globals.addWarning('Nulla vagy annál kisebb mennyiség nem megengedett!');
          return;
        }
        if (bontottArray.reduce((prevValue, currentObj) => prevValue + Number(currentObj.mennyiseg), 0) != mennyiseg) {
          route.globals.addWarning('A bontások mennyiségének összege nem egyezik meg a bontott cikk mennyiségével!');
          return;
        }
        if (bontottArray.some(b => !!b.visszaHatarido ^ !!b.visszaMennyiseg)) {
          route.globals.addWarning('A visszaigazolt mennyiség és határidő megadása kötelező együttesen!');
          return;
        }
        // Egy új bontott határidő egyezhet az eredeti határidővel, ezt engedélyezi ez a változó
        let throwErrorOnMatch = false;
        for (let i = 0; i < bontottArray.length - 1; i++) {
          const bElso = bontottArray[i];
          for (let o = i + 1; o < bontottArray.length; o++) {
            const bMasodik = bontottArray[o];
            const bElsoMoment = moment(bElso.hatarido).utcOffset(0).format("YYYY-MM-DDTHH:mm:ss.SSSZZ");
            const bMasodikMoment = moment(bMasodik.hatarido).utcOffset(0).format("YYYY-MM-DDTHH:mm:ss.SSSZZ");
            // TODO optimalizálni a futásidőt vagy az egész ellenőrzést, ha gondot jelent
            if (bElsoMoment == bMasodikMoment || ((bElsoMoment == hatarido) && throwErrorOnMatch)  || ((bMasodikMoment == hatarido) && throwErrorOnMatch)) {
              route.globals.addWarning('A bontás utáni határidőknek egyedieknek kell lenniük egymáshoz képest!');
              return;
            }
            if (!throwErrorOnMatch && (bElsoMoment == hatarido || bMasodikMoment == hatarido)) {
              throwErrorOnMatch = true;
            }
          }
        }
      }

      if (!id) {
        let submitObj = {};
        submitObj.id = generateUUID();
        submitObj.new = true;
        submitObj.cikk = cikk;
        submitObj.cikkNev = cikkNev;
        submitObj.cikkId = cikk && cikk.id;
        submitObj.mennyiseg = mennyiseg;
        submitObj.visszaMennyiseg = visszaMennyiseg;
        submitObj.hatarido = hatarido ? moment(hatarido).format("YYYY-MM-DDTHH:mm:ss.SSSZZ") : null;
        submitObj.visszaHatarido =  visszaHatarido ? moment(visszaHatarido).format("YYYY-MM-DDTHH:mm:ss.SSSZZ") : null;
        submitObj.nettoEgysegar = nettoEgysegar;
        submitObj.bruttoEgysegar = bruttoEgysegar;
        submitObj.nettoBeszerzesiar = nettoBeszerzesiar;
        submitObj.bruttoBeszerzesiar = bruttoBeszerzesiar;
        submitObj.megjegyzes = megjegyzes;
        if(isBontas){
          submitObj.bontasok = bontasArray.filter(b => b.visszaMennyiseg != '' && b.visszaHatarido);
        }
        cikkek.push(submitObj);
        setCikkek(cikkek);
      } else {
        let array = [...cikkek];
        const editedIdx = cikkek.findIndex(c => c.id == id);
        let submitObj = {};
        submitObj.id = id;
        submitObj.new = isBontas ? cikkek[editedIdx].new : newCikk;
        submitObj.cikk = cikk;
        submitObj.cikkNev = cikkNev;
        submitObj.cikkId = cikk && cikk.id;
        submitObj.mennyiseg = mennyiseg;
        submitObj.visszaMennyiseg = visszaMennyiseg;
        submitObj.hatarido = hatarido ? moment(hatarido).format("YYYY-MM-DDTHH:mm:ss.SSSZZ") : null;
        submitObj.visszaHatarido =  visszaHatarido ? moment(visszaHatarido).format("YYYY-MM-DDTHH:mm:ss.SSSZZ") : null;
        submitObj.nettoEgysegar = nettoEgysegar;
        submitObj.bruttoEgysegar = bruttoEgysegar;
        submitObj.nettoBeszerzesiar = nettoBeszerzesiar;
        submitObj.bruttoBeszerzesiar = bruttoBeszerzesiar;
        submitObj.megjegyzes = megjegyzes;
        submitObj.eredeti = cikkek[editedIdx].eredeti;
        if(isBontas){
          // A splice + fix editedIdx miatt megfordul a sorrend, a reverse ezt kompenzálja
          bontottArray.reverse().forEach(bontas => {
            let bontasObj = {
              ...submitObj,
              id: generateUUID(),
              eredeti: { id },
              new: true,
              hatarido: moment(bontas.hatarido).format("YYYY-MM-DDTHH:mm:ss.SSSZZ"),
              mostbontott: true,
              mennyiseg: bontas.mennyiseg,
              visszaMennyiseg: bontas.visszaMennyiseg,
              visszaHatarido: bontas.visszaHatarido ? moment(bontas.visszaHatarido).format("YYYY-MM-DDTHH:mm:ss.SSSZZ") : null,
              nettoBeszerzesiar: bontas.mennyiseg * submitObj.nettoEgysegar,
              bruttoBeszerzesiar: bontas.mennyiseg * submitObj.bruttoEgysegar,
            };
            array.splice(editedIdx + 1, 0, bontasObj);
          });
        }
        array[editedIdx] = submitObj;
        setCikkek(array);
      }
      toggleCikkModal();
    }

    const renderAfa = loc => {
      return(
        <div className="col-md-2">
          <SlaveAvInput
            id={"afa-" + loc}
            label="ÁFA%:"
            required
            disabled
            value={cikk ? cikk.aktualisAfaErtek : ''}
            szuroTipus={NumberFormatter.OSSZEG}
          />                
        </div>
      );
    }

    const renderBontas = () => {
      return( isBontas &&
        <React.Fragment>
          <div className="row" style={ROW_STYLE}>
              <div className="col-md-12" style={{paddingRight: '2px'}}>
                <h6>Cikkek felbontása</h6>
              </div>
          </div>
          {bontasArray.map( (bontott, idx) => (
          <div className="row" style={ROW_STYLE} key={idx}>
            <div className="col-md-3" >
              <Label for={"hatarido" + idx}>Határidő: </Label>
              <DateTimePicker
                id={"hatarido" + idx}
                name={"hatarido" + idx}
                value={bontott.hatarido && new Date(bontott.hatarido) }
                onChange={(e,v) => setBontottValue(v, idx, 'hatarido')}
                containerClassName={ "organw-date-time"}
                time={true}
                format="YYYY-MM-DD HH:mm:ss"
                parse={['YYYY-MM-DD', 'YYYY-MM-DD HH:mm']}
                messages={{ dateButton: "dátum" , timeButton: "idő"}}
              />
            </div>
            <div className="col-md-2">
              <Label for={"mennyiseg" + idx}>Mennyiség: </Label>
              <AvInput
                style={{textAlign: "right"}}
                name={"mennyiseg" + idx}
                id={"mennyiseg" + idx}
                value={bontott.mennyiseg}
                onChange={(e,v) => setBontottValue(v, idx, 'mennyiseg')}
              />
            </div>
            <div className="col-md-2">
              <Label for={"visszaMennyiseg" + idx}>Vissz.ig. mennyiség: </Label>
              <AvInput
                style={{textAlign: "right"}}
                name={"visszaMennyiseg" + idx}
                id={"visszaMennyiseg" + idx}
                value={bontott.visszaMennyiseg}
                onChange={(e,v) => setBontottValue(v, idx, 'visszaMennyiseg')}
              />
            </div>
            <div className="col-md-3" >
              <Label for={"visszaHatarido" + idx}>Visszaigazolt határidő: </Label>
              <DateTimePicker
                id={"visszaHatarido" + idx}
                name={"visszaHatarido" + idx}
                value={bontott.visszaHatarido && new Date(bontott.visszaHatarido) }
                onChange={(e,v) => setBontottValue(v, idx, 'visszaHatarido')}
                containerClassName={ "organw-date-time"}
                time={true}
                format="YYYY-MM-DD HH:mm:ss"
                parse={['YYYY-MM-DD', 'YYYY-MM-DD HH:mm']}
                messages={{ dateButton: "dátum" , timeButton: "idő"}}
              />
            </div>
            <div className="col-md-1">
              <Button color="secondary" style={{ marginTop: '28px', width: "100%" }} type="button" onClick={() => setBontasArray([...bontasArray, {id: generateUUID(), new: true, visszaMennyiseg: '', visszaHatarido: null}])}>
                <i className="fa fa-plus"></i>
              </Button>
            </div>
            <div className="col-md-1">
              <Button color="secondary" style={{ marginTop: '28px', width: "100%" }} type="button" onClick={() => setBontasArray(bontasArray.filter(b => b.id != bontott.id))}>
                <i className="fa fa-trash"></i>
              </Button>
            </div>
          </div>
          ))}
        </React.Fragment>
      );
    }

    const renderForm = () => {
      const selectStyles = {
        control: base => ({
          ...base,
          '&:focus': { borderColor: 'red' }, // border style on hover
          border: '1px solid lightgray', // default border color
          boxShadow: 'none', // no box-shadow
          borderRadius: '0'
        }),
      };
      return (
        <React.Fragment>
          {!id &&
            <div className="row" style={ROW_STYLE}>
              <div className="col-md-12" style={{paddingRight: '2px'}}>
                <Label for="cikkCsoportArray">Cikkcsoport:</Label>
                <Select
                  styles={selectStyles}
                  name="cikkCsoportArray"
                  id="cikkCsoportArray"
                  placeholder="Kezdjen el gépelni..."
                  noOptionsMessage={() => 'Nincs keresési eredmény'}
                  onChange={v => setCikkcsoportArray(v)}
                  loadOptions={_.debounce(loadCikkCsoportOptions, 1000)}
                  getOptionLabel={option => option.nev}
                  getOptionValue={option => option.id}
                  value={cikkCsoportArray}
                  isDisabled={isElkuldott}
                  isClearable
                  defaultOptions
                  isMulti
                />
              </div>
            </div>
          }
          <div className="row" style={ROW_STYLE}>
            <div className="col-md-12" style={{paddingRight: '2px'}}>
              <Label for="cikk">Cikk: *</Label>
              <Select
                styles={selectStyles}
                name="cikk"
                id="cikk"
                placeholder="Kezdjen el gépelni..."
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                onChange={v => {setCikk(v); setCikkNev(v ? v.megnevezes : "")}}
                loadOptions={_.debounce(loadCikkOptions, 1000)}
                getOptionLabel={option => option.megnevezes}
                getOptionValue={option => option.id}
                value={cikk}
                isDisabled={isElkuldott}
                isClearable
                //defaultOptions
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-5" style={ROW_STYLE}>
              <Label for="megnevezes">Megnevezés: </Label>
              <AvInput
                name={"megnevezes"}
                id={"megnevezes"}
                value={cikkNev}
                onChange={(e,v) => setCikkNev(v)}
                disabled={isElkuldott}
              />
            </div>
            <div className="col-md-5" style={ROW_STYLE}>
            <Label for="cikkszam">Cikkszám: </Label>
              <AvInput
                name={"cikkszam"}
                id={"cikkszam"}
                value={cikk ? cikk.cikkszam : ''}
                disabled
              />
            </div>
            <div className="col-md-2" style={ROW_STYLE}>
              <Label for="mennyisegiEgyseg">Me.: </Label>
              <AvInput
                name={"mennyisegiEgyseg"}
                id={"mennyisegiEgyseg"}
                value={cikk ? cikk.mennyisegiEgyseg : ''}
                disabled
              />
            </div>
          </div>
          <div className="row">
            <div className={parentId && !isBontas ? "col-md-8" : "col-md-6"} style={ROW_STYLE}>
              <Label for="mennyiseg">Mennyiség: *</Label>
              <AvInput
                style={{textAlign: "right"}}
                name={"mennyiseg"}
                id={"mennyiseg"}
                value={mennyiseg}
                onChange={(e,v) => setMennyisegPrice(v)}
                required
                disabled={isElkuldott}
              />
            </div>
            {!isBontas && isElkuldott &&
            <div className="col-md-4" style={ROW_STYLE}>
              <Label for="visszaMennyiseg">Visszaigazolt mennyiség: </Label>
              <AvInput
                style={{textAlign: "right"}}
                name={"visszaMennyiseg"}
                id={"visszaMennyiseg"}
                value={visszaMennyiseg}
                onChange={(e,v) => setVisszaMennyiseg(v)}
              />
            </div>
            }
          {/* </div>  
          <div className="row"> */}
            <div className={parentId && !isBontas ? "col-md-8" : "col-md-6"} style={ROW_STYLE}>
              <Label for="hatarido">Határidő: *</Label>
              <DateTimePicker
                id="hatarido"
                name="hatarido"
                value={hatarido && new Date(hatarido) }
                disabled={isElkuldott}
                onChange={(e,v) => setHatarido(v === '' ? null : v)}
                containerClassName={ "organw-date-time"}
                time={true}
                format="YYYY-MM-DD HH:mm:ss"
                parse={['YYYY-MM-DD', 'YYYY-MM-DD HH:mm']}
                messages={{ dateButton: "dátum" , timeButton: "idő"}}
                required
              />
            </div>
            {!isBontas && isElkuldott &&
            <div className="col-md-4" style={ROW_STYLE}>
              <Label for="visszaHatarido">Visszaigazolt határidő: </Label>
              <DateTimePicker
                id="visszaHatarido"
                name="visszaHatarido"
                value={visszaHatarido && new Date(visszaHatarido) }
                onChange={(e,v) => setVisszaHatarido(v === '' ? null : v)}
                containerClassName={ "organw-date-time"}
                time={true}
                format="YYYY-MM-DD HH:mm:ss"
                parse={['YYYY-MM-DD', 'YYYY-MM-DD HH:mm']}
                messages={{ dateButton: "dátum" , timeButton: "idő"}}
                required
              />
            </div>
            }
          </div>
          <div className="row">
            <div className="col-md-3">
              Egységár
            </div>
            <div className="col-md-3">
              <SlaveAvInput
                id="nettoEgysegar"
                label="Nettó:"
                required
                disabled={!mennyiseg || !cikk || isElkuldott}
                value={nettoEgysegar}
                onChange={(e,v) => setPrices(v, null, mennyiseg)}
                szuroTipus={NumberFormatter.EGYSEGAR}
                emptyStringOnZero
              />
            </div>
            {renderAfa('egysegar')}
            <div className="col-md-3">
              <SlaveAvInput
                id="bruttoEgysegar"
                label="Bruttó:"
                required
                disabled
                value={bruttoEgysegar}
                szuroTipus={NumberFormatter.EGYSEGAR}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              Beszerzési érték
            </div>
            <div className="col-md-3">
              <SlaveAvInput
                id="nettoBeszerzesiar"
                label="Nettó:"
                required
                disabled
                value={nettoBeszerzesiar}
                szuroTipus={NumberFormatter.OSSZEG}
              />
            </div>
            {renderAfa('beszerzesiar')}
            <div className="col-md-3">
              <SlaveAvInput
                id="bruttoBeszerzesiar"
                label="Bruttó:"
                required
                disabled={!mennyiseg || !cikk || isElkuldott}
                value={bruttoBeszerzesiar}
                onChange={(e,v) => setPrices( null, v ,mennyiseg)}
                szuroTipus={NumberFormatter.OSSZEG}
                emptyStringOnZero
              />     
            </div>
          </div>
          {renderBontas()}
          <div className="row">
            <div className="col-md-12" style={ROW_STYLE}>
              <Label for="megjegyzes">Megjegyzés: </Label>
              <AvInput
                className="munkalap-profil-textarea"
                type="textarea"
                name={"megjegyzes"}
                id={"megjegyzes"}
                value={megjegyzes}
                disabled={isElkuldott}
                onChange={(e, v) => setMegjegyzes(v)}
              />
            </div>
          </div>
        </React.Fragment>
      );
    }

    const renderButtons = () => {
      return(
        <div className="row" style={{marginTop: 50}}>
          <div className="col-md-12">
            <Button
              className="btn-cursor-pointer"
              color="success"
              type="submit"
            >
              Mentés
            </Button>
            <Button type="button" color="secondary" onClick={() => toggleCikkModal()}>
              Mégsem
            </Button>
          </div>
        </div>
      );
    }

    return (
      <Modal isOpen={cikkModal} toggle={() => toggleCikkModal(!cikkModal)} backdrop="static" size="lg">
        <AvForm onValidSubmit={saveSzallitoiCikkRendeles}>
          <ModalHeader>Cikk {id ? 'módosítása' : 'hozzáadása'}</ModalHeader>
          <ModalBody>
            <div className="row" style={{padding: 20}}>
              <div className="col-md-12">
                {renderForm()}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {renderButtons()}
          </ModalFooter>
        </AvForm>
      </Modal>
    );
}

export default SzallitoiCikkForm;