import Lib from '../../../commons/lib';

export default class Service {
  static szamlazasUrl = window.location.origin + '/api/udulesijogszla/';

  // FIZETÉSI FELSZÓLÍTÓ PROFIL START

  static getProfilFelso(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.szamlazasUrl + 'parameter/string/nbyn',
      args: { requestType: 'munkalap-profil-base' },
      data: ['KKAMAT_PROFIL_FELSO'],
    }], fnDone);
  }

  static updateProfilFelso(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.szamlazasUrl + 'parameter',
      args: { requestType: 'parameter-profil' },
      data: data
    }], fnDone);
  }

  static updateProfilAlso(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.szamlazasUrl + 'parameter',
      args: { requestType: 'parameter-profil' },
      data: data
    }], fnDone);
  }

  static getProfilAlso(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.szamlazasUrl + 'parameter/string/nbyn',
      args: { requestType: 'munkalap-profil-base' },
      data: ['KKAMAT_PROFIL_ALSO'],
    }], fnDone);
  }

  // FIZETÉSI FELSZÓLÍTÓ PROFIL END
}
