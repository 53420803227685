import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import BevetelezesLista from './components/Bevetelezes/BevetelezesLista.js';
import SelejtezesLista from './components/Selejtezes/SelejtezesLista.js';
import KeszletcsokkenesLista from './components/Keszletcsokkenes/KeszletcsokkenesLista.js';
// import RaktarkoziatadasLista from './components/Raktarkoziatadas/RaktarkoziatadasLista.js';
import ForgalomLista from './components/Forgalom/ForgalomLista';
import LeltarLista from './components/Leltar/LeltarLista';
import Lib from '../../../commons/lib';
import CustomTooltip from '../../../components/Tooltip/CustomTooltip.js';
import getTooltip from '../KeszletTooltips.js';

class Keszletkezeles extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1'
    };
  }

  /** Lefut, amikor a komponens betöltődik. */
  componentDidMount() {
    if(this.props.location.query.page){
      switch (this.props.location.query.page) {
        case '1':
          this.toggle('1');
          break;
        case '2':
          this.toggle('2');
          break;
        case '3':
          this.toggle('3');
          break;
        case '4':
          this.toggle('4');
          break;
        case '5':
          this.toggle('5');
          break;
        case '6':
          this.toggle('6'); 
          break;
        default:
          this.toggle('1');
      }
    }else{
      if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KESZLETNYILV_BEVETELEZES'], true)) {
        this.toggle('1');
      } else if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KESZLETNYILV_CSOKKENES'], true)) {
        this.toggle('2');
      } else if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KESZLETNYILV_SELEJTEZES'], true)) {
        this.toggle('3');
      } else if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KESZLETNYILV_FORGALOM'], true)) {
        this.toggle('5');
      } else if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KESZLETNYILV_LELTAR'], true)) {
        this.toggle('6');
      }
    }
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  render() {
    let cardStyle = {
      padding: 10
    };

    return (
      <div>
        <div className="animated fadeIn">
          <div className="card">
            <div className="card-header">
              Készletnyilvántartás
              <CustomTooltip text={getTooltip('keszlet')} id="keszletTT" />
            </div>
            <div className="row" style={cardStyle}>
              <div className="col-md-12">
                <Nav tabs>
                  {
                    Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KESZLETNYILV_BEVETELEZES'], true) && (
                    <NavItem>
                      <NavLink href="#" className={classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                        Készletnövekedés
                        <CustomTooltip text={getTooltip('keszletnovekedes')} id="keszletnovekedesTT" />
                      </NavLink>
                    </NavItem>
                    )
                  }
                  {
                    Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KESZLETNYILV_CSOKKENES'], true) && (
                    <NavItem>
                      <NavLink href="#" className={classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                        Készletcsökkenés
                        <CustomTooltip text={getTooltip('keszletcsokkenes')} id="keszletcsokkenesTT" />
                      </NavLink>
                    </NavItem>
                    )
                  }
                  {
                    Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KESZLETNYILV_SELEJTEZES'], true) && (
                    <NavItem>
                      <NavLink href="#" className={classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
                        Selejtezés
                      </NavLink>
                    </NavItem>
                    )
                  }
                  {/* 
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['ATADAS']) &&
                    <NavItem>
                      <NavLink href="#" className={classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.toggle('4'); }}>
                        Raktárközi átadás
                      </NavLink>
                    </NavItem>
                  } 
                  */}
                  {
                    Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KESZLETNYILV_FORGALOM'], true) && (
                    <NavItem>
                      <NavLink href="#" className={classnames({ active: this.state.activeTab === '5' })} onClick={() => { this.toggle('5'); }}>
                        Forgalom
                        <CustomTooltip text={getTooltip('forgalom')} id="forgalomTT" />
                      </NavLink>
                    </NavItem>
                    )
                  }
                  {
                    Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KESZLETNYILV_LELTAR'], true) && (
                    <NavItem>
                      <NavLink href="#" className={classnames({ active: this.state.activeTab === '6' })} onClick={() => { this.toggle('6'); }}>
                        Leltár
                        <CustomTooltip text={getTooltip('leltar')} id="leltarTT" />
                      </NavLink>
                    </NavItem>
                    )
                  }
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KESZLETNYILV_BEVETELEZES'], true) && (
                    <TabPane tabId="1">
                      <BevetelezesLista globals={this.props.route.globals} />
                    </TabPane>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KESZLETNYILV_CSOKKENES'], true) && (
                    <TabPane tabId="2">
                      <KeszletcsokkenesLista globals={this.props.route.globals} />
                    </TabPane>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KESZLETNYILV_SELEJTEZES'], true) && (
                    <TabPane tabId="3">
                      <SelejtezesLista globals={this.props.route.globals} /> 
                    </TabPane>
                  )}
                  {/*
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['ATADAS']) &&
                    <TabPane tabId="4">
                      <RaktarkoziatadasLista globals={this.props.route.globals} />
                    </TabPane>
                  )}
                  */}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KESZLETNYILV_FORGALOM'], true) && (
                    <TabPane tabId="5">
                      <ForgalomLista globals={this.props.route.globals} />
                    </TabPane>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KESZLETNYILV_LELTAR'], true) && (
                    <TabPane tabId="6">
                      <LeltarLista globals={this.props.route.globals} />
                    </TabPane>
                  )}
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Keszletkezeles;
