import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Services from './services';
import Lib from '../../../commons/lib';

class CikkProfilContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: true,
      value: null,
    };
  }

  componentDidMount() {
    this.updateValue();
  }

  onSubmit = (event, submitObj) => {
    submitObj.azonosito = 'CIKK_RAKTARON_KIVUL';
    submitObj.tipus = 'BOOLEAN';
    submitObj.ertek = submitObj.kivul;
    submitObj.technikai = false;
    submitObj.nev = 'CIKK_RAKTARON_KIVUL';

    Services.updateCikkProfil(submitObj, (err) => {
      if (!err) {
        this.props.globals.addSuccess('Cikk profil rögzítve!');
        this.toggleDisable();
      }
    });
  }

  updateValue = () => {
    Services.getCikkProfil((err, res) => {
      if (!err) {
        this.setState({
          value: res[0].toString()
        });
      }
    });
  }

  toggleDisable = () => {
    this.setState({
      disabled: !this.state.disabled
    });
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-3">
          <AvForm onValidSubmit={this.onSubmit}>
            <AvField
              type="select"
              name="kivul"
              label="Kezel raktáron kívüli cikket? *"
              errorMessage="Kötelező mező!"
              disabled={this.state.disabled}
              value={this.state.value}
              required
            >
              <option value="">
                Válassz értéket...
              </option>
              <option value="false">
                Nem
              </option>
              <option value="true">
                Igen
              </option>
            </AvField>
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, ['CIKK_PROFIL'], 'KARB') && (
              <>
                <Button disabled={this.state.disabled} color="primary">
                Mentés
                </Button>
                {' '}
                <Button onClick={this.toggleDisable} disabled={!this.state.disabled} type="button" color="success">
                  Módosítás
                </Button>
              </>
            )}
            {' '}
          </AvForm>
        </div>
      </div>
    );
  }
}

export default CikkProfilContent;
