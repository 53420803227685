import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  UncontrolledAlert,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  UncontrolledTooltip
} from 'reactstrap';
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback
} from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Select from 'react-select/lib/Async';
import DataEmptyState from '../../commons/dataemptystate';
import QueryEmptyState from '../../commons/queryempty';
import Services from './services';
import { DatePicker } from '../../commons/controls';
import Lib from '../../commons/lib';
import ProgressButton from '../../commons/ProgressButton';
import NumberFormatter from '../../commons/NumberFormatter';

class SzamlaElokeszitesLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      /*
       *   BUTTONS LOADING STATES
       */
      kuldesButtonLoading: false,
      megerositesButtonLoading: false,
      keresesButtonLoading: false,

      generalasLoaded: true,
      eloszuresEv: '',
      modalKKamat: false,
      modalNezet: false,
      modalMegerosites: false,
      fojogtulajdonosValue: '',
      szamlakibocsatoValue: '',
      szamlakibocsatoSzamlazasValue: '',
      dataLoaded: null,
      queryStarted: null,
      alapAdatok: {},
      aktivPenznem: null,
      aktivFizmod: null,
      aktivEv: null,
      viewObj: null,
      kamatChangeObj: [],
      reSubmitObj: [],
      currentinflacio: null,
      inflacioAdatok: [],
      szamlaElokeszitesJson: []
    };
  }

  // componentWillMount() {
  //   Services.getSzamlaKuldesAlapadat((err, res) => {
  //     if (!err) {
  //       res[0].kibocsato = res[0].SZAMLA_KIBOCSATO[0];
  //       res[0].afa = res[0].SZAMLA_AFA[0];
  //       res[0].teljesites = res[0].SZAMLA_TELJESITES[0];
  //       res[0].fizhatarido = res[0].SZAMLA_FIZHAT[0];
  //       res[0].ksprodname = res[0].SZAMLA_KS_PROD_NAME[0];
  //       res[0].ptid = res[0].SZAMLA_KIBOCSATO_NEV.length === 0 ? null : res[0].SZAMLA_KIBOCSATO_NEV[0].ptid;
  //       res[0].pid = res[0].SZAMLA_KIBOCSATO_NEV.length === 0 ? null : res[0].SZAMLA_KIBOCSATO_NEV[0].id;
  //       if (res[0].SZAMLA_KIBOCSATO_NEV.length === 1) {
  //         res[0].SZAMLA_KIBOCSATO_NEV[0].nev = res[0].SZAMLA_KIBOCSATO_NEV[0].nev + ' (' + res[0].SZAMLA_KIBOCSATO_NEV[0].cim + ') [' + NumberFormatter.addHyphensToString(res[0].SZAMLA_KIBOCSATO_NEV[0].adoszam) + ']';
  //       }
  //       this.setState({ alapAdatok: res[0], szamlakibocsatoSzamlazasValue: res[0].SZAMLA_KIBOCSATO_NEV[0] });
  //     }
  //   });
  //   Services.getInflacioList((err, res) => {
  //     if (!err) {
  //       let resOptions = res[0].map(e => <option key={e.id} value={e.ev}>{e.ev}</option>);
  //       this.setState({ inflacioAdatok: res[0], aktivEv: resOptions });
  //     }
  //   });
  //   this.getAktivPenznem();
  //   this.getAktivFizmod();
  // }

  componentDidMount() {
    // let cleaveNumeral = new Cleave('.input-ev', {
    //   numeral: true,
    //   numeralThousandsGroupStyle: 'thousand'
    // });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.type === 'SZAMLA_ELO') {
      Services.getSzamlaKuldesAlapadat((err, res) => {
        if (!err) {
          res[0].afa = res[0].SZAMLA_AFA[0];
          res[0].teljesites = res[0].SZAMLA_TELJESITES[0];
          res[0].fizhatarido = res[0].SZAMLA_FIZHAT[0];
          res[0].kibocsato = res[0].SZAMLA_KIBOCSATO[0];
          res[0].kibocsatonev = res[0].SZAMLA_KIBOCSATO_NEV[0];
          res[0].ksprodname = res[0].SZAMLA_KS_PROD_NAME[0];
          res[0].ptid = res[0].SZAMLA_KIBOCSATO_NEV.length === 0 ? null : res[0].SZAMLA_KIBOCSATO_NEV[0].ptid;
          res[0].pid = res[0].SZAMLA_KIBOCSATO_NEV.length === 0 ? null : res[0].SZAMLA_KIBOCSATO_NEV[0].id;
          if (res[0].SZAMLA_KIBOCSATO_NEV.length === 1) {
            res[0].SZAMLA_KIBOCSATO_NEV[0].nev = res[0].SZAMLA_KIBOCSATO_NEV[0].nev + ' (' + res[0].SZAMLA_KIBOCSATO_NEV[0].cim + ')' + (res[0].SZAMLA_KIBOCSATO_NEV[0].adoszam ? ' [' + NumberFormatter.addHyphensToString(res[0].SZAMLA_KIBOCSATO_NEV[0].adoszam) + ']' : '');
          }
          this.setState({ alapAdatok: res[0], szamlakibocsatoSzamlazasValue: res[0].SZAMLA_KIBOCSATO_NEV[0] });
        }
      });
      Services.getInflacioList((err, res) => {
        if (!err) {
          let resOptions = res[0].map(e => <option key={e.id} value={e.ev}>{e.ev}</option>);
          this.setState({ inflacioAdatok: res[0], aktivEv: resOptions });
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.active !== prevProps.active) {
      if (this.props.active) {
        Services.getSzamlaKuldesAlapadat((err, res) => {
          if (!err) {
            res[0].kibocsato = res[0].SZAMLA_KIBOCSATO[0];
            res[0].afa = res[0].SZAMLA_AFA[0];
            res[0].teljesites = res[0].SZAMLA_TELJESITES[0];
            res[0].fizhatarido = res[0].SZAMLA_FIZHAT[0];
            res[0].ksprodname = res[0].SZAMLA_KS_PROD_NAME[0];
            res[0].ptid = res[0].SZAMLA_KIBOCSATO_NEV.length === 0 ? null : res[0].SZAMLA_KIBOCSATO_NEV[0].ptid;
            res[0].pid = res[0].SZAMLA_KIBOCSATO_NEV.length === 0 ? null : res[0].SZAMLA_KIBOCSATO_NEV[0].id;
            if (res[0].SZAMLA_KIBOCSATO_NEV.length === 1) {
              res[0].SZAMLA_KIBOCSATO_NEV[0].nev = res[0].SZAMLA_KIBOCSATO_NEV[0].nev + ' (' + res[0].SZAMLA_KIBOCSATO_NEV[0].cim + ')' + (res[0].SZAMLA_KIBOCSATO_NEV[0].adoszam ? ' [' + NumberFormatter.addHyphensToString(res[0].SZAMLA_KIBOCSATO_NEV[0].adoszam) + ']' : '');
            }
            this.setState({ alapAdatok: res[0], szamlakibocsatoSzamlazasValue: res[0].SZAMLA_KIBOCSATO_NEV[0] });
          }
        });
        Services.getInflacioList((err, res) => {
          if (!err) {
            let resOptions = res[0].map(e => <option key={e.id} value={e.ev}>{e.ev}</option>);
            this.setState({ inflacioAdatok: res[0], aktivEv: resOptions });
          }
        });
        this.getAktivPenznem();
        this.getAktivFizmod();
      }
    }
  }

  getAktivPenznem = () => {
    Services.getPenznem('ELO', (err, res) => {
      if (!err) {
        let resOptions = res[0].map(e => <option key={e.id} value={e.id}>{e.nev + ' (' + e.azonosito + ')'}</option>);
        this.setState({ aktivPenznem: resOptions });
      }
    });
  }

  getAktivFizmod = () => {
    Services.getFizmod('ELO', (err, res) => {
      if (!err) {
        let resOptions = res[0].map(e => <option key={e.variansid ? e.variansid : e.id} value={e.variansid ? e.variansid : e.id}>{e.variansnev ? e.variansnev : e.nev}</option>);
        this.setState({ aktivFizmod: resOptions });
      }
    });
  }

  toggleInflacioGen = () => {
    this.setState({ modalMegerosites: !this.state.modalMegerosites });
  }

  toggleKKamat = () => {
    this.setState({
      modalKKamat: !this.state.modalKKamat
    });
  }

  loadSzamlakibocsatoChange = (input, callback) => {
    if (input.length !== 0) {
      Services.getSzamlaKibocsato('SZAMLAELOKESZITES_SZAMLAKIBOCSATO_ELO', input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            let newItem = item;
            newItem.nev += ' (' + newItem.cim + ') ';
            if (newItem.adoszam) {
              newItem.nev += '[' + NumberFormatter.addHyphensToString(newItem.adoszam) + '] ';
            }
            newArray.push(newItem);
          });
          callback(newArray);
        }
      });
      // this.setState({alapAdatok})
    }
  }

  loadSzamlakibocsatoAlapChange = (input, callback) => {
    if (input.length !== 0) {
      Services.getSzamlaKibocsato('SZAMLAELOKESZITES_SZAMLAKIBOCSATO_PARAM_FORM', input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            let newItem = item;
            newItem.nev += ' (' + newItem.cim + ') ';
            if (newItem.adoszam) {
              newItem.nev += '[' + NumberFormatter.addHyphensToString(newItem.adoszam) + '] ';
            }
            newArray.push(newItem);
          });
          callback(newArray);
        }
      });
      // this.setState({alapAdatok})
    }
  }

  loadFojogtulajdonosChange = (input, callback) => {
    if (input.length !== 0) {
      Services.getSzamlaKibocsato('SZAMLAELOKESZITES_TULAJ_ELO', input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            let newItem = item;
            newItem.nev += ' (' + newItem.cim + ') ';
            if (newItem.adoszam) {
              newItem.nev += '[' + NumberFormatter.addHyphensToString(newItem.adoszam) + '] ';
            }
            newArray.push(newItem);
          });
          callback(newArray);
        }
      });
    }
  }

  handleChangeSzamlakibocsato = (value) => {
    if (value && value.length !== 0) {
      this.setState({ szamlakibocsatoValue: value });
    } else {
      this.setState({ szamlakibocsatoValue: '' });
    }
  }

  handleChangeSzamlakibocsatoSzamlazas = (value) => {
    if (value && value.length !== 0) {
      this.setState({ szamlakibocsatoSzamlazasValue: value });
    } else {
      this.setState({ szamlakibocsatoSzamlazasValue: '' });
    }
  }

  handleChangeFojogtulajdonos = (value) => {
    if (value && value.length !== 0) {
      this.setState({ fojogtulajdonosValue: value });
    } else {
      this.setState({ fojogtulajdonosValue: '' });
    }
  }

  handleEloszuresEvChange = (e) => {
    if (e.target.value && e.target.value.length !== 0) {
      this.setState({ fojogtulajdonosValue: e.target.value });
    } else {
      this.setState({ fojogtulajdonosValue: '' });
    }
  }

  changeInflacioValue = (v) => {
    let arr = this.state.inflacioAdatok;
    let currentInflacio = null;
    arr.forEach((item) => {
      if (item.ev == v.target.value) currentInflacio = item.mertek;
    });
    this.setState({
      inflacioValue: v.target.value,
      currentinflacio: currentInflacio
    });
  }

  updateTable = (event, searchObj) => {
    this.setState({
      dataLoaded: false,
      dataFailed: false,
      queryLoaded: false,
      queryStarted: true,
      keresesButtonLoading: true
    });
    if (searchObj && searchObj.ev === '') delete searchObj.ev;
    if (searchObj && searchObj.penznem === '') delete searchObj.penznem;
    if (searchObj && searchObj.fizmod === '') delete searchObj.fizmod;
    Services.listSzamlaElokeszites(this.state.szamlakibocsatoValue && this.state.szamlakibocsatoValue.id && this.state.szamlakibocsatoValue.id, this.state.fojogtulajdonosValue && this.state.fojogtulajdonosValue.id && this.state.fojogtulajdonosValue.id, this.state.eloszuresEv, searchObj && searchObj.penznemid, searchObj && searchObj.fizmodid, (err, res) => {
      if (err) {
        this.setState({
          dataLoaded: null, dataFailed: null, queryLoaded: null, queryStarted: null, keresesButtonLoading: false
        });
      } else {
        this.setState({
          szamlaElokeszitesJson: res[0], dataLoaded: true, dataFailed: false, queryLoaded: true, queryStarted: false, keresesButtonLoading: false
        });
      }
    });
  }

  toggleNezet = () => {
    this.setState({ modalNezet: !this.state.modalNezet });
  }

  iconFormatter = (cell, row) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_SZAMLAKEZELES', 'LEK') && (
          <React.Fragment>
            <Button className="icon-eye table-action-icon" id={'action-view-' + cell} onClick={() => this.handleViewClick(cell, row)} />
            <UncontrolledTooltip placement="left" target={'action-view-' + cell} delay={0}>
              Megtekintés
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  szamlaKibocsatoFormatter = (cell) => {
    if (cell && cell.nev) return (cell.nev);
    return '';
  }

  jogtulajFormatter = (cell) => {
    if (cell && cell.nev) return (cell.nev+'<br />'+cell.cim);
    return '';
  }

  handleViewClick = (id, row) => {
    this.setState({ viewObj: row }, this.toggleNezet());
  }

  saveInflacio = () => {
    let data = {
      ev: this.state.inflacioAdatok.ev,
      inflacio: this.state.inflacioAdatok.inflacio
    };
    Services.updateInflacio(data, (err) => {
      if (!err) {
        this.props.globals.addSuccess('Infláció mentve!');
      }
    });
  }

  checkInflacio = (event, submitObj) => {
    this.setState({
      generalasLoaded: false
    });
    Services.getGenInflacio(submitObj.genEv, (err2) => {
      if (!err2) {
        this.setState({
          generalasLoaded: true
        });
        this.props.globals.addSuccess('Éves díj generálva!');
      } else {
        this.setState({
          generalasLoaded: true
        });
      }
    });
  }

  doGen = () => {
    Services.getGenInflacio(this.state.inflacioAdatok.ev, (err) => {
      if (!err) {
        this.toggleInflacioGen();
        this.props.globals.addSuccess('Infláció generálva!');
      }
    });
  }

  handleEvChange = (event) => {
    let newState = this.state.inflacioAdatok;
    newState.ev = event.target.value;
    this.setState({ inflacioAdatok: newState });
  }

  handleEvEloszuresChange = (event) => {
    this.setState({ eloszuresEv: event.target.value });
  }

  handleInflacioChange = (event) => {
    let newState = this.state.inflacioAdatok;
    newState.inflacio = event.target.value;
    this.setState({ inflacioAdatok: newState });
  }

  handleAfaChange = (e) => {
    let value = e.target.value;
    let newState = this.state.alapAdatok;
    if (value && value.length !== 0) {
      newState.afa = value;
      this.setState({ alapAdatok: newState });
    } else {
      newState.afa = '';
      this.setState({ alapAdatok: newState });
    }
  }

  handleKsProdNameChange = (e) => {
    let value = e.target.value;
    let newState = JSON.parse(JSON.stringify(this.state.alapAdatok));
    if (value && value.length !== 0) {
      newState.ksprodname = value;
      this.setState({ alapAdatok: newState });
    } else {
      newState.ksprodname = '';
      this.setState({ alapAdatok: newState });
    }
  }

  handleKibocsatoChange = (e, v) => {
    let currentValue = v === '' ? null : v;
    let newState = this.state.alapAdatok;
    newState.kibocsato = currentValue;
    this.setState({ alapAdatok: newState });
  }

  handleFizhataridoChange = (e, v) => {
    let currentValue = v === '' ? null : v;
    let newState = this.state.alapAdatok;
    newState.fizhatarido = currentValue;
    this.setState({ alapAdatok: newState });
  }

  handleTeljesitesChange = (e, v) => {
    let currentValue = v === '' ? null : v;
    let newState = this.state.alapAdatok;
    newState.teljesites = currentValue;
    this.setState({ alapAdatok: newState });
  }

  send = () => {
    this.setState({ kuldesButtonLoading: true });
    let submitObj = {};
    let alapadatObj = this.state.alapAdatok;
    submitObj.kibocsato = this.state.szamlakibocsatoSzamlazasValue && this.state.szamlakibocsatoSzamlazasValue.id;
    // submitObj.parkibocsatoPtId = alapadatObj.ptid;
    submitObj.afa = alapadatObj.afa;
    submitObj.kstermeknev = alapadatObj.ksprodname;
    submitObj.teljesites = alapadatObj.teljesites;
    submitObj.fizhat = alapadatObj.fizhatarido;
    // submitObj.alapadat = this.state.alapAdatok;
    if (this.lista && this.lista.state && this.lista.state.selectedRowKeys && this.lista.state.selectedRowKeys.length > 0) {
      submitObj.lista = [];
      let rowKeys = this.lista.state.selectedRowKeys;
      let data = this.lista.store.data;
      data.forEach((dataItem) => {
        rowKeys.forEach((key) => {
          if (dataItem.id === key) {
            submitObj.lista.push(dataItem);
          }
        });
      });
    } else {
      submitObj.lista = [];
    }
    let newSubmitList = submitObj.lista;
    submitObj.lista = [];
    newSubmitList.forEach((item, idx) => {
      item.udulesijogok.forEach((udulItem) => {
        if (udulItem.gyakorisag && udulItem.gyakorisag.name) {
          udulItem.gyakorisag = udulItem.gyakorisag.name;
        }
      });
      submitObj.lista.push(item);
    });
    this.setState({
      reSubmitObj: submitObj
    });
    Services.sendSzamlaElokeszites(submitObj, (err, res) => {
      if (!err) {
        if (res[0].length <= 0) {
          this.props.globals.addSuccess('Számla továbbküldve!');
          this.updateTable();
        } else {
          this.setState({
            kamatChangeObj: res[0],
          });
          this.toggleKKamat();
        }
        this.setState({ kuldesButtonLoading: false });
      } else {
        this.setState({ kuldesButtonLoading: false });
      }
    });
  }

  reSend = (event, data) => {
    this.setState({ megerositesButtonLoading: true });
    let changedMaps = [];
    Object.keys(data).forEach((key) => {
      changedMaps.push({
        id: parseInt(key.split('-')[1], 10),
        value: parseInt(data[key], 10)
      });
    });
    let sendObj = JSON.parse(JSON.stringify(this.state.reSubmitObj));
    sendObj.lista.forEach((item) => {
      changedMaps.forEach((changedItem) => {
        if (changedItem.id === item.id) {
          item.udulesijogok.forEach((udjogItem) => {
            udjogItem.kkamat = changedItem.value;
          });
        }
      });
    });

    Services.sendSzamlaElokeszites(sendObj, (err, res) => {
      if (!err) {
        if (res[0].length <= 0) {
          this.toggleKKamat();
          this.props.globals.addSuccess('Számla továbbküldve!');
          this.updateTable();
        } else {
          this.setState({
            kamatChangeObj: res[0],
          });
          this.toggleKKamat();
        }
        this.setState({ megerositesButtonLoading: false });
      } else {
        this.setState({ megerositesButtonLoading: false });
      }
    });
  }

  save = () => {
    let saveObj = {
      SZAMLA_KIBOCSATO: this.state.szamlakibocsatoSzamlazasValue && this.state.szamlakibocsatoSzamlazasValue.id,
      SZAMLA_AFA: this.state.alapAdatok.afa,
      SZAMLA_TELJESITES: this.state.alapAdatok.teljesites.replace(/-/g, '.'),
      SZAMLA_FIZHAT: this.state.alapAdatok.fizhatarido.replace(/-/g, '.'),
      SZAMLA_KS_PROD_NAME: this.state.alapAdatok.ksprodname
    };
    Services.updateAlapadat(saveObj, (err) => {
      if (!err) {
        this.props.globals.addSuccess('Számlázási adatok mentve!');
        this.setState({
          szamlaElokeszitesJson: [],
          dataLoaded: true,
          queryStarted: false,
          keresesButtonLoading: false
        });
      }
    });
  }

  renderKamatData = () => {
    let generatedObj = JSON.parse(JSON.stringify(this.state.kamatChangeObj));
    let renderObj = [];
    generatedObj.forEach((item, idx) => {
      renderObj.push(
        <Card key={'card-idx-' + idx.toString()}>
          <CardBody>
            <CardTitle>{item.udulesijogazonositok}</CardTitle>
            <CardText>
              <b>Év: </b>{item.ev}<br />
              <b>Fizetési mód: </b>{item.fizmod}<br />
              <b>Pénznem: </b>{item.penznem}<br />
              <b>Fő jogtulajdonos: </b>{item.jogtulajbean.nev}<br />
              <b>Számlakibocsátó: </b>{item.kibocsatobean.nev}<br />
            </CardText>
            <AvGroup>
              <Label for={'kkamat-' + item.id}>Késedelmi kamat (%): </Label>
              <AvInput
                name={'kkamat-' + item.id}
                id={'kkamat-' + item.id}
                style={{ width: '75px', textAlign: 'right' }}
                type="number"
                min="0"
                max="100"
                required
              />
              <AvFeedback>Kötelező szám mező! (0-100)</AvFeedback>
            </AvGroup>
          </CardBody>
        </Card>
      );
    });
    return (
      renderObj
    );
  }

  renderInflacioGen = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };
    return (
      <AvForm onValidSubmit={this.checkInflacio} style={{ marginBottom: 20 }}>
        <div className="row szamla-border-bottom szamla-border-top" >
          <div className="col-md-12">
            <div>
              <h5 style={titleStyle}>
                Nettó érték generálása:
              </h5>
            </div>
          </div>
          <div className="col-md-3">
            <AvField
              type="select"
              name="genEv"
              id="genEv"
              label="Év: "
              errorMessage="Kötelező mező!"
              onChange={e => this.changeInflacioValue(e)}
            >
              <option value="">
                Válassz évet...
              </option>
              {this.state.aktivEv}
            </AvField>
          </div>
          <div className="col-md-3">
            <AvGroup>
              <Label for="inflacio">
                Infláció (%):
              </Label>
              <AvInput
                name="inflacio"
                id="inflacio"
                disabled
                value={this.state.currentinflacio}
              />
              <AvFeedback>
                Kötelező mező!
              </AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-6">
            {/* <Button className="margin-top-30" color="primary" type="button" onClick={this.saveInflacio} >Infláció mentése</Button>{'  '} */}
            <Button className="margin-top-30" color="success" disabled={!this.state.generalasLoaded}>
              {this.state.generalasLoaded ? 'Generálás' : '...'}
            </Button>
          </div>
        </div>
      </AvForm>
    );
  }

  renderEloSzures = () => {
    return (
      <AvForm onValidSubmit={this.updateTable}>
        <div className="row">
          <div className="col-md-12">
            <div><h5 style={{ marginBottom: 20 }}>Számla előkészítés:</h5></div>
          </div>
          <div className="col-md-6 form-group">
            <Label for="szallitoId">Számlakibocsátó:</Label>
            {/* <Select.Async
              name="szallitoId"
              id="szallitoId"
              placeholder="Kezdjen el gépelni..."
              noResultsText="Nincs keresési eredmény"
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingPlaceholder="Keresés..."
              onChange={v => this.handleChangeSzamlakibocsato(v)}
              clearable
              ignoreAccents={false}
              loadOptions={_.debounce((input, callback) => this.loadSzamlakibocsatoChange(input, callback), 1000)}
              isLoading={false}
              valueKey="id"
              labelKey="nev"
              value={this.state.szamlakibocsatoValue}
              cache={false}
              autoload={false}
            /> */}
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="szallitoId"
              id="szallitoId"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeSzamlakibocsato(v)}
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadSzamlakibocsatoChange, 1000)}
              isLoading={false}
              getOptionLabel={option => option.nev}
              getOptionValue={option => option.id}
              value={this.state.szamlakibocsatoValue}
              cache={false}
              autoload={false}
            />
          </div>
          <div className="col-md-6 form-group">
            <Label for="jogtulajId">Fő jogtulajdonos:</Label>
            {/* <Select.Async
              name="jogtulajId"
              id="jogtulajId"
              placeholder="Kezdjen el gépelni..."
              noResultsText="Nincs keresési eredmény"
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingPlaceholder="Keresés..."
              onChange={v => this.handleChangeFojogtulajdonos(v)}
              clearable
              ignoreAccents={false}
              loadOptions={_.debounce((input, callback) => this.loadFojogtulajdonosChange(input, callback), 1000)}
              isLoading={false}
              valueKey="id"
              labelKey="nev"
              value={this.state.fojogtulajdonosValue}
              cache={false}
              autoload={false}
            /> */}
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="jogtulajId"
              id="jogtulajId"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeFojogtulajdonos(v)}
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadFojogtulajdonosChange, 1000)}
              isLoading={false}
              getOptionLabel={option => option.nev}
              getOptionValue={option => option.id}
              value={this.state.fojogtulajdonosValue}
              // disabled={this.state.fizTeljElerheto}
              cache={false}
              autoload={false}
            />
          </div>
          <div className="col-md-3">
            <AvGroup>
              <Label for="ev">Év: </Label>
              <AvInput
                className="input-ev"
                placeholder="éééé"
                name="ev"
                id="ev"
                maxLength="4"
                value={this.state.eloszuresEv}
                onChange={v => this.handleEvEloszuresChange(v)}
              />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-3">
            <AvField type="select" name="penznemid" id="penznemid" label="Pénznem: " errorMessage="Kötelező mező!">
              <option value="">Válassz pénznemet...</option>
              {this.state.aktivPenznem}
            </AvField>
          </div>
          <div className="col-md-3">
            <AvField type="select" name="fizmodid" id="fizmodid" label="Fizetési mód: " errorMessage="Kötelező mező!">
              <option value="">Válassz fizetési módot...</option>
              {this.state.aktivFizmod}
            </AvField>
          </div>

          <div className="col-md-3">
            {/* <Button
              className="margin-top-30"
              color="primary"
              onClick={this.collapseSzerzodes}
            >
              Keresés
            </Button> */}
            <ProgressButton
              className="margin-top-30 btn-cursor-pointer"
              color="primary"
              loading={this.state.keresesButtonLoading}
            >
              Keresés
            </ProgressButton>
          </div>
        </div>
      </AvForm>
    );
  }

  onSelectAll = (isSelected) => {
    if (isSelected) {
      if (this.lista.store.filteredData) {
        return this.lista.store.filteredData.map(row => row.id);
      }
      return this.state.szamlaElokeszitesJson.map(row => row.id);
    }
    return [];
  }

  renderTable = () => {
    const selectRowProp = {
      onSelectAll: this.onSelectAll,
      mode: 'checkbox',
      hideSelectColumn: !Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_SZAMLAKULDES', 'KARB')
    };

    return this.state.szamlaElokeszitesJson.length ? (
      <BootstrapTable ref={((c) => { this.lista = c; })} data={this.state.szamlaElokeszitesJson} selectRow={selectRowProp} pagination options={this.props.globals.bootstraptableProps} /*exportCSV*/ csvFileName="szamla_elokeszites.csv">
        <TableHeaderColumn dataField="ev" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }} csvHeader="Év">Év</TableHeaderColumn>
        <TableHeaderColumn dataField="kibocsatobean" dataFormat={this.szamlaKibocsatoFormatter} tdStyle={{ whiteSpace: 'normal' }} csvHeader="Számlakibocsátó" csvFormat={(cell) => {return cell.nev}}>Számlakibocsátó</TableHeaderColumn>
        <TableHeaderColumn dataField="jogtulajbean" dataFormat={this.jogtulajFormatter} tdStyle={{ whiteSpace: 'normal' }} csvHeader="Jogtulajdonos" csvFormat={(cell) => {return cell.nev + ' ' + cell.cim}}>Jogtulajdonos</TableHeaderColumn>
        <TableHeaderColumn dataField="udulesijogazonositok" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }} csvHeader="Üdülési jog azonosító">Üdülési jog azonosító</TableHeaderColumn>
        <TableHeaderColumn dataField="elozoevesdijnetto" tdStyle={{ whiteSpace: 'normal', textAlign: 'right' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }} dataFormat={(cell) => { return NumberFormatter.addSpacesToString(cell, NumberFormatter.OSSZEG); }} csvHeader="Előző évi díj">Előző évi díj</TableHeaderColumn>
        <TableHeaderColumn dataField="penznem" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }} csvHeader="Pénznem">Pénznem</TableHeaderColumn>
        <TableHeaderColumn dataField="fizmod" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }} csvHeader="Fizetési mód">Fizetési mód</TableHeaderColumn>
        <TableHeaderColumn dataField="id" isKey dataFormat={this.iconFormatter} export={false} >Műveletek</TableHeaderColumn>
      </BootstrapTable>) : <DataEmptyState />;
  }

  renderalapAdatok = () => {
    return (
      <AvForm onValidSubmit={this.send}>
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <div>
              <h5 style={{ marginBottom: 20 }}>
                Számlázási adatok:
              </h5>
            </div>
          </div>
          <div className="col-md-6 col-lg-6">
            <Label for="szallitoId">Számlakibocsátó:</Label>
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="szallitoId"
              id="szallitoId"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeSzamlakibocsatoSzamlazas(v)}
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadSzamlakibocsatoAlapChange, 1000)}
              isLoading={false}
              getOptionLabel={option => option.nev}
              getOptionValue={option => option.id}
              value={this.state.szamlakibocsatoSzamlazasValue}
              cache={false}
              autoload={false}
            />
          </div>
          <div className="col-md-3 col-lg-3">
            <Label for="fizhatarido">
              Fizetési határidő:
            </Label>
            <DatePicker
              type="select"
              name="fizhatarido"
              value={this.state.alapAdatok && this.state.alapAdatok.fizhatarido && new Date(this.state.alapAdatok.fizhatarido)}
              onChange={this.handleFizhataridoChange}
            />
          </div>
          <div className="col-md-3 col-lg-3">
            <Label for="teljesites">
              Teljesítés:
            </Label>
            <DatePicker
              type="select"
              name="teljesites"
              value={this.state.alapAdatok && this.state.alapAdatok.teljesites && new Date(this.state.alapAdatok.teljesites)}
              onChange={this.handleTeljesitesChange}
            />
          </div>
          <div className="col-md-6 col-lg-6">
            <AvGroup>
              <Label for="ksprodname">
                Termékmegnevezés:
              </Label>
              <AvInput
                name="ksprodname"
                id="ksprodname"
                value={this.state.alapAdatok && this.state.alapAdatok.ksprodname}
                onChange={e => this.handleKsProdNameChange(e)}
              />
            </AvGroup>
          </div>
          <div className="col-md-3 col-lg-3">
            <AvGroup>
              <Label for="afa">
                Áfa (%):
              </Label>
              <AvInput
                type="number"
                min="0"
                max="100"
                name="afa"
                id="afa"
                value={this.state.alapAdatok && this.state.alapAdatok.afa && this.state.alapAdatok.afa.toString() }
                onChange={e => this.handleAfaChange(e)}
              />
              <AvFeedback>
                0 és 100 közötti érték!
              </AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-3 col-lg-3">
            <Button type="button" className="pull-left" color="primary" onClick={this.save} style={{ marginTop: 28 }}>
              Mentés
            </Button>
            {' '}
          </div>
        </div>
      </AvForm>
    );
  }

  renderAdatok = () => {
    return (
      <div>
        <h6>Számla információ:</h6>
        <b>Év: </b>{this.state.viewObj && this.state.viewObj.ev}<br />
        <b>Előző éves nettó díj: </b>{this.state.viewObj && NumberFormatter.addSpacesToString(this.state.viewObj.elozoevesdijnetto, NumberFormatter.OSSZEG)}<br />
        <b>Fizetési mód: </b>{this.state.viewObj && this.state.viewObj.fizmod}<br />
        <b>Pénznem: </b>{this.state.viewObj && this.state.viewObj.penznem}<br />
        <b>Üdülési jog azonosítók: </b>{this.state.viewObj && this.state.viewObj.udulesijogazonositok}<br /><br />
        <h6>Számlakibocsátó:</h6>
        <b>Név: </b>{this.state.viewObj && this.state.viewObj.kibocsatobean && this.state.viewObj.kibocsatobean.nev}<br />
        <b>Cím: </b>{this.state.viewObj && this.state.viewObj.kibocsatobean && this.state.viewObj.kibocsatobean.cim }<br />
        <b>Adószám: </b>{this.state.viewObj && this.state.viewObj.kibocsatobean && NumberFormatter.addHyphensToString(this.state.viewObj.kibocsatobean.adoszam)}<br /><br />
        <h6>Jogtulajdonos:</h6>
        <b>Név: </b>{this.state.viewObj && this.state.viewObj.jogtulajbean && this.state.viewObj.jogtulajbean.nev}<br />
        <b>Cím: </b>{this.state.viewObj && this.state.viewObj.jogtulajbean && this.state.viewObj.jogtulajbean.cim }<br />
        <b>Adószám: </b>{this.state.viewObj && this.state.viewObj.jogtulajbean && NumberFormatter.addHyphensToString(this.state.viewObj.jogtulajbean.adoszam)}<br /><br />
      </div>
    );
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_SZAMLAKULDES', 'KARB') && this.renderalapAdatok()}
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_SZAMLAKULDES', 'KARB') && this.renderInflacioGen()}
        {this.renderEloSzures()}
        <div className="row">
          <div className="col-md-12 margin-top-30">
            { this.state.queryStarted ? this.renderLoader() : this.state.dataLoaded ? this.renderTable() : <QueryEmptyState /> }
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_SZAMLAKULDES', 'KARB') && (
              // <Button type="button" className="pull-left" color="success" onClick={this.send}>Küldés</Button>
              <ProgressButton
                type="button"
                className="pull-left"
                color="success"
                onClick={this.send}
                loading={this.state.kuldesButtonLoading}
              >
                Küldés
              </ProgressButton>
            )}
          </div>
        </div>
        <Modal isOpen={this.state.modalNezet} toggle={this.toggleNezet} className={this.props.className} backdrop="static">
          <ModalHeader>Megtekintés</ModalHeader>
          <ModalBody>{this.state.modalNezet ? this.renderAdatok(this._currentViewId) : <div />}</ModalBody>
          <ModalFooter> <Button type="button" color="primary" onClick={this.toggleNezet} className="btn-cursor-pointer" >Vissza</Button></ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modalKKamat}
          toggle={this.toggleKKamat}
          className={this.props.className}
          backdrop="static"
          // size="lg"
        >
          <ModalHeader>
            Késedelmi kamat csere
          </ModalHeader>
          <AvForm onValidSubmit={this.reSend}>
            <ModalBody>
              <UncontrolledAlert color="danger">
                A következő tételekhez tartozó üdülési jogok késedelmi kamata eltérő. Küldéshez kérem adjon meg egységes értéket!
              </UncontrolledAlert>
              {this.renderKamatData()}
            </ModalBody>
            <ModalFooter>
              <Button
                type="button"
                color="secondary"
                onClick={this.toggleKKamat}
                className="btn-cursor-pointer"
              >
                Vissza
              </Button>
              <ProgressButton
                className="btn-cursor-pointer"
                color="success"
                loading={this.state.megerositesButtonLoading}
              >
                Küldés
              </ProgressButton>
            </ModalFooter>
          </AvForm>
        </Modal>
        <Modal isOpen={this.state.modalMegerosites} toggle={this.toggleInflacioGen} className={this.props.className} backdrop="static">
          <ModalHeader>
            Megerősítés
          </ModalHeader>
          <ModalBody>
            Ilyen évszám már létezik! Biztosan újra szeretné generálni?
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="success" onClick={this.doGen} className="btn-cursor-pointer">
              Generálás
            </Button>
            <Button type="button" color="secondary" onClick={this.toggleInflacioGen} className="btn-cursor-pointer">
              Vissza
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

SzamlaElokeszitesLista.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool
};

export default SzamlaElokeszitesLista;
