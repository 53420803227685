import React from 'react';
import PropTypes from 'prop-types';
import KeszletcsokkenesForm from './KeszletcsokkenesForm';

const cardStyle = {
  padding: 20
};

const KeszletcsokkenesBase = (props) => {
  return (
    <div className="animated fadeIn">
      <div className="card">
        <div className="card-header">
          {props.route.modositas == 'true' ? 'Készletcsökkenés módosítása' : 'Új készletcsökkenés'}
        </div>
        <div className="row" style={cardStyle}>
          <div className="col-md-12">
            <KeszletcsokkenesForm globals={props.route.globals} route={props.route} />
          </div>
        </div>
      </div>
    </div>
  );
};

KeszletcsokkenesBase.propTypes = {
  route: PropTypes.object,
  globals: PropTypes.object
};

export default KeszletcsokkenesBase;
