import React, { useState, useEffect, useRef} from 'react';
import { 
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { selectFilter, textFilter} from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import Loader from '../../../commons/loader';
import Services from '../services';
import SzallitoiForm from './SzallitoiForm';
import SzallitoiFilter from './SzallitoiFilter';
import { PaginationTotal } from '../../../commons/controls';
import { ProgressButton } from '../../../commons/ProgressButton';
import NumberFormatter from '../../../commons/NumberFormatter';
import Lib from '../../../commons/lib';
import moment from 'moment';
import { browserHistory } from 'react-router';

const SzallitoiContent = props => {
  const {
    route,
    active
  } = props;

  //const didMountRef = useRef(false);
  const [filters, setFilters] = useState([]);
  const [rows, setRows] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [viewObj, setViewObj] = useState({});
  const [currentId, setCurrentId] = useState(null);
  const [lockupId, setLockupId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [printUrl, setPrintUrl] = useState(null);
  const [tableStatus, setTableStatus] = useState('TABL'); // ['LOAD', 'DATA', 'NETW', 'TABL']
  const [viewStatus, setViewStatus] = useState('DATA'); // ['LOAD', 'DATA', 'NETW'] 
  const [deleteModal, toggleDeleteModal] = useState(false);
  const [viewModal, toggleViewModal] = useState(false);
  const [lockupModal, toggleLockupModal] = useState(false);

  useEffect(() => {
    setTableStatus('LOAD');
  }, []);


  useEffect(() => {
    switch(tableStatus){
      case "LOAD": 
        listSzallitoiRendeles(filters);
      break;
    }
  },[tableStatus]);

  useEffect(() => {
  },[expandedRows]);

  useEffect(() => {
    if(currentId) setViewStatus('LOAD');
  },[currentId]);

  useEffect(() => {
    if(!deleteId) setTableStatus('LOAD');
  },[deleteId]);

  useEffect(() => {
    if(!lockupId) setTableStatus('LOAD');
  },[lockupId]);

  useEffect(() => {
    if(!viewModal) setCurrentId(null);
  },[viewModal]);

  useEffect(() => {
    if(!deleteModal) setDeleteId(null);
  },[deleteModal]);

  useEffect(() => {
    if(!lockupModal) setLockupId(null);
  },[lockupModal]);

  useEffect(() => {
    switch(viewStatus){
      case "LOAD": 
        getSzallitoiRendeles();
        toggleViewModal(!viewModal);
      break;
    }
  },[viewStatus]);

  useEffect(() => {
    if(printUrl){
      window.setTimeout(() => {

        window.frames["pdf-area-szallitoi-rendeles"].focus();
        window.frames["pdf-area-szallitoi-rendeles"].print();
        window.setTimeout(() => {
        window.close();
        }, 50);

      }, 1000);
      route.globals.addSuccess('Szállítói rendelés nyomtatás!');
      setTableStatus('LOAD');
    }
  }, [printUrl]);

  const listSzallitoiRendeles = async (defaultFilters = {kelte: new Date()}) => {
    try{
      setRows(await Services.listSzallitoiRendeles((filters && filters != {})  ? filters : defaultFilters));
      //setExpandedRows([]);
      setTableStatus('TABL');
    }catch(ex){
      setTableStatus('NETW');
    }
  }

  const getSzallitoiRendeles = async() => {
    try{
      setViewObj(await Services.getSzallitoiRendeles({id: currentId}));
      setViewStatus('DATA');
    }catch(ex){
      setViewStatus('NETW');
    }
  }

  const loadExpandedRows = async (row) => {
    if(expandedRows.find(x => x.id == row.id)){
      setExpandedRows([]);
    }else{
      try{
        const cikkek = await Services.listSzallitoiCikkRendeles({id: row.id});
        setExpandedRows([{id: row.id, data: cikkek}]);
      }catch(ex){}
    }
  }

  const handleLockup = async id => {
    try{
      await toggleLockupModal(true);
      setLockupId(id);
    }catch(ex){}
  }

  const handleForm = (id) => {
    setCurrentId(id);
    if(!id){
      browserHistory.push("".concat('/rendelesek/rendeles/szallitoi/felvetel'));
    }else{
      browserHistory.push("".concat('/rendelesek/rendeles/szallitoi/modositas?id=' + id));
    }
  }

  const handleDelete = (id) => {
    setDeleteId(id);
    toggleDeleteModal(!deleteModal);
  }

  const deleteRow = async (id) => {
    try{
      const res = await Services.deleteSzallitoiRendeles({id: deleteId});
      toggleDeleteModal(!deleteModal);
    }catch(ex){}
  }

  const printRow = async (id) => {
    try{
      const res = await Services.printSzallitoiRendeles({id: id});
      setPrintUrl(URL.createObjectURL(new Blob([res], { type: 'application/pdf' })));
    }catch(ex){}
  }

  const sendRow = async (id) => {
    try{
      const res = await Services.sendSzallitoiRendeles({id: id});
      listSzallitoiRendeles(filters);
      route.globals.addSuccess('Szállítói rendelés elküldve!');
    }catch(ex){}
  }

  const lockupRow = async (id) => {
    try{
      const szallitoiRendeles = await Services.getSzallitoiRendeles({id: id});
      szallitoiRendeles.allapot = szallitoiRendeles.allapot.name;
      szallitoiRendeles.allapotEnum = null;
      await Services.editAcceptSzallitoiRendeles(szallitoiRendeles);
    }catch(ex){}

    toggleLockupModal(!lockupModal);
  }  

  const tableIconFormatter = (id, row) => {
    const isNotLezart = row && row.allapot && row.allapot.name !== 'LEZART';
    const isElkuldott = row && row.allapot && row.allapot.name === 'ELKULDOTT';
    return (
      <div>
        <React.Fragment>
        {Lib.KeyCloak.hasHigherRole(route.globals.keycloak, 'RENDELES_SZALLITOI', 'LEK') && (
          <React.Fragment>
            <Button className="icon-eye table-action-icon" id={'action-view-' + id + 'megtekint'} onClick={() => setCurrentId(id) } onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + id + 'megtekint'} delay={50}>
              {'Megtekintés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}

        </React.Fragment> 
        {Lib.KeyCloak.hasHigherRole(route.globals.keycloak, 'RENDELES_SZALLITOI', 'KARB') && (
          <React.Fragment>

            {isNotLezart && (
              <React.Fragment>
                <Button className="icon-pencil table-action-icon" id={'action-view-' + id + 'modositas'} onClick={() => handleForm(id)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + id + 'modositas'} delay={50}>
                  {'Módosítás'}
                </UncontrolledTooltip>
              </React.Fragment>
            )}

            {isNotLezart && (
              <React.Fragment>
                <Button className="icon-printer table-action-icon" id={'action-view-' + id + 'print'} onClick={() => printRow(id)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + id + 'print'} delay={50}>
                  {'Visszaigazolás (Nyomtatás)'}
                </UncontrolledTooltip>
              </React.Fragment>
            )}

            <React.Fragment>
              <Button className="icon-envelope table-action-icon" id={'action-view-' + id + 'send'} onClick={() => sendRow(id)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
              <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + id + 'send'} delay={50}>
                {'Visszaigazolás (E-mail küldés)'}
              </UncontrolledTooltip>
            </React.Fragment>

            {isElkuldott && (
              <React.Fragment>
                <Button className="icon-lock table-action-icon" id={'action-view-' + id + 'lezaras'} onClick={() => handleLockup(id)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + id + 'lezaras'} delay={50}>
                  {'Lezárás'}
                </UncontrolledTooltip>
              </React.Fragment>
            )}

          {isNotLezart && (
            <React.Fragment>
              <Button className="icon-trash table-action-icon" id={'action-view-' + id + 'delete'} onClick={() => handleDelete(id)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
              <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + id + 'delete'} delay={50}>
                {'Törlés'}
              </UncontrolledTooltip>
            </React.Fragment>
          )}

          </React.Fragment>
        )}
      </div>
    );
  }

  const renderViewBase = () => {
    return (
      <div className="row">
        <div className="col-md-12">
          {{
            LOAD: (<Loader />),
            NETW: (<NetworkEmptyState
              hasImage
              submitClick={() => getSzallitoiRendeles(currentId)}
            />),
            DATA: renderView()
          }[viewStatus]}
        </div>
      </div>
    );
  }


  const renderView = () => {
    return (
      viewObj && (
        <div>
          <h6>Szállítói rendelés adatai:</h6>
          <b>Rendelés kelte: </b>{moment(viewObj.kelte).format("YYYY-MM-DD HH:mm")}<br />
          <b>Bizonylatszám: </b>{viewObj.bizonylat ? viewObj.bizonylat.bizonylatszam : ''}<br />
          <b>Szállító: </b>{viewObj.partner ? viewObj.partner.nev + ' (' + viewObj.partner.cim + ')' : ''}<br/>
          <b>Szállító e-mail címe: </b>{viewObj.partner ? viewObj.partner.email : ''}<br/>
          <b>Szállító telefonszáma: </b>{viewObj.partner ? viewObj.partner.telefon : ''}<br/>
          <b>Cégadat: </b>{viewObj.cegadat ? viewObj.cegadat.teljesnev + ' (' + viewObj.cegadat.cim + ')' : ''}<br/>
          <b>Pénznem: </b>{viewObj.penznem ? viewObj.penznem.azonosito : ''}<br />
          <b>Fizetési mód: </b>{viewObj.fizmod ? viewObj.fizmod.nev : ''}<br />
          <b>Szállítási mód: </b>{viewObj.szallitasiMod ? viewObj.szallitasiMod.nev : ''}<br />
          <b>Megjegyzés: </b>{viewObj.megjegyzes}<br />
          <br />
          {viewObj.uvd &&(
            <div>
              <b>Rögzítés időpontja: </b>{moment(viewObj.uvd.rogzitesidopont).format("YYYY-MM-DD HH:mm:ss")}<br />
              <b>Rögzítő személy: </b>{viewObj.uvd.rogzszemelynev}<br />
              <b>Módosítás időpontja: </b>{moment(viewObj.uvd.modositasidopont).format("YYYY-MM-DD HH:mm:ss")}<br />
              <b>Módosító személy: </b>{viewObj.uvd.modszemelynev}<br />
              <b>Véglegezés időpontja: </b>{viewObj.veglegezesdatum ? moment(viewObj.veglegezesdatum).format("YYYY-MM-DD HH:mm:ss") : ''}<br />
              <b>Véglegező személy: </b>{viewObj.veglegezesszemelynev}<br />
            </div>
          )}
        </div>
      )
    );
  }

  const renderTableBase = () => {
    return(
      <div className="col-md-12">
        {{
          LOAD: (<Loader />),
          DATA: (<DataEmptyState
            hasImage
            subtitle="Vigyél fel új tételt!"
          />),
          NETW: (<NetworkEmptyState
            hasImage
            submitClick={() => listSzallitoiRendeles(filters)}
          />),
          TABL: renderTable()
        }[tableStatus]}
      </div>
    );
  }

  const renderModals = () => {
    return (
      <React.Fragment>
        <Modal isOpen={deleteModal} toggle={() => toggleDeleteModal(!deleteModal)} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>Valóban törölni kívánja a kiválasztott tételt?</ModalBody>
          <ModalFooter>
            <ProgressButton
              className="btn-cursor-pointer"
              color="primary"
              onClick={() => deleteRow()}
            >
              {'Igen'}
            </ProgressButton>
            <Button type="button" color="secondary" onClick={() => toggleDeleteModal(!deleteModal)}>Mégsem</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={viewModal} toggle={() => toggleViewModal(!viewModal)} backdrop="static">
          <ModalHeader>Megtekintés</ModalHeader>
          <ModalBody>
            {renderViewBase()}
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" className="btn-cursor-pointer" onClick={() => toggleViewModal(!viewModal)}>Vissza</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={lockupModal} toggle={() => toggleLockupModal(!lockupModal)} backdrop="static">
            <ModalHeader>Lezárás</ModalHeader>
            <ModalBody>Valóban lezárja a kiválasztott rendelést? Lezárást követen a rendelés nem módosítható.</ModalBody>
            <ModalFooter>
              <ProgressButton
                className="btn-cursor-pointer"
                color="primary"
                onClick={() => lockupRow(lockupId)}
              >
                {'Igen'}
              </ProgressButton>
              <Button type="button" color="secondary" onClick={() => toggleLockupModal(!lockupModal)}>Mégsem</Button>
            </ModalFooter>
          </Modal>
      </React.Fragment>
    );
  }

  const renderTable = () => {

    const options = {
      sizePerPage: 5,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const columns = [
      {
        dataField: 'partner',
        text: 'Szállító',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
        },
        sort: true,
        formatter: cell => cell ? cell.nev + ' (' + cell.cim + ')' : '',
        filterValue: cell => cell ? cell.nev + ' (' + cell.cim + ')' : ''
      },
      {
        dataField: 'cegadat',
        text: 'Cégadat',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
        },
        sort: true,
        formatter: cell => cell ? cell.teljesnev + ' (' + cell.cim + ')' : '',
        filterValue: cell => cell ? cell.teljesnev + ' (' + cell.cim + ')' : ''
      },
      {
        dataField: 'kelte',
        text: 'Rendelés kelte',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
        },
        sort: true,
        formatter: cell => cell ? moment(cell).format("YYYY-MM-DD HH:mm") : '',
        filterValue: cell => cell ? moment(cell).format("YYYY-MM-DD HH:mm") : ''
      },
      {
        dataField: 'bizonylat.bizonylatszam',
        text: 'Bizonylatszám',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
        },
        sort: true,
      },
      {
        dataField: 'allapot.caption',
        text: 'Állapot',
        filter: selectFilter({
          options: {
            Rögzített: 'Rögzített',
            Elküldött: 'Elküldött',
            Lezárt: 'Lezárt'
          },
          placeholder: 'Összes'
        }),
        style: {
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
        },
        sort: true,
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        csvExport: false,
        formatter: (id, row) => tableIconFormatter(id, row, expandedRows)
      }
    ];

    const cikkColumns = [
      {
        dataField: 'cikk.cikkszam',
        text: 'Cikkszám',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
        },
        sort: true
      },
      {
        dataField: 'cikkNev',
        text: 'Megnevezés',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
        },
        sort: true,
      },
      {
        dataField: 'mennyiseg',
        text: 'Mennyiség',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: NumberFormatter.styleTextAlignWhiteSpace,
        formatter: (cell, row) => NumberFormatter.formatterSpaceOsszeg(cell) + (row && row.cikk ? ' ' + row.cikk.mennyisegiEgyseg : ''),
        sort: true,
      },
      {
        dataField: 'hatarido',
        text: 'Határidő',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-word',
          whiteSpace: 'pre-line',
        },
        formatter: cell => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : '',
        sort: true,
      },
      {
        dataField: 'visszaMennyiseg',
        text: 'Vissza. mennyiség',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-word',
          ...NumberFormatter.styleTextAlignWhiteSpace
        },
        formatter: (cell, row) => {
          if (cell) return NumberFormatter.formatterSpaceOsszeg(cell) + (row && row.cikk ? ' ' + row.cikk.mennyisegiEgyseg : '');
          return '';
        },
        sort: true,
      },
      {
        dataField: 'beerkezettMennyiseg',
        text: 'Beérkezett mennyiség',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: NumberFormatter.styleTextAlignWhiteSpace,
        formatter: (cell, row) => {
          if (cell) return NumberFormatter.formatterSpaceOsszeg(cell) + (row && row.cikk ? ' ' + row.cikk.mennyisegiEgyseg : '');
          return '';
        },
        sort: true,
      },
      {
        dataField: 'rendelesbizonylatszam',
        text: 'Vevői rendelés bizonylatszám',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        headerStyle: { whiteSpace: 'pre-line' },
        style: { whiteSpace: 'pre-line' },
        sort: true
      },
      {
        dataField: 'cikk.aktualisAfaErtek',
        text: 'ÁFA%',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: NumberFormatter.styleTextAlignWhiteSpace,
        formatter: NumberFormatter.formatterSpaceOsszeg,
        sort: true
      },
      {
        dataField: 'nettoEgysegar',
        text: 'Nettó egységár',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: NumberFormatter.styleTextAlignWhiteSpace,
        formatter: (cell, row) => NumberFormatter.formatterSpaceOsszegPenznem(cell, row),
        sort: true
      },
      {
        dataField: 'nettoBeszerzesiar',
        text: 'Nettó beszerzési érték',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: NumberFormatter.styleTextAlignWhiteSpace,
        formatter: (cell, row) => NumberFormatter.formatterSpaceOsszegPenznem(cell, row),
        sort: true
      },
      {
        dataField: 'bruttoBeszerzesiar',
        text: 'Bruttó beszerzési érték',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: NumberFormatter.styleTextAlignWhiteSpace,
        formatter: (cell, row) => NumberFormatter.formatterSpaceOsszegPenznem(cell, row),
      },
    ];

    const expandRow = {
      renderer: row => expandedRows.find(x => x.id == row.id) && (
          <React.Fragment>
            <div className="row">
              <div className="col-md-2">
                <h6>Cikkek</h6>
              </div>  
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={expandedRows.find(x => x.id == row.id).data}
              columns={cikkColumns}
              filter={filterFactory()}
              pagination={paginationFactory(options)}
              noDataIndication="A rendeléshez még nem lett cikk kiválasztva!"
              rowStyle={row => row.bontott ? { backgroundColor: '#999999', color: '#5b5b5b' } : null}
            />
          </React.Fragment>
      ),
      expanded: expandedRows.map( x => x.id),
      onExpand: row => loadExpandedRows(row),
      showExpandColumn: true,
      expandByColumnOnly: true,
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        return isAnyExpands ? <b>-</b> : <b>+</b>;
      },
      expandColumnRenderer: ({ expanded }) => {
        return expanded ? <b>-</b> : <b>+</b>;
      }
    };

    return (
      <div className="animated fadeIn">
        <React.Fragment>
          <div style={{margin: "20px"}}>
            {
              Lib.KeyCloak.hasHigherRole(route.globals.keycloak, 'RENDELES_SZALLITOI', 'KARB') && 
              <Button type="button" className="pull-left table-new-button" style={{marginRight: "20px"}} color="primary" onClick={() => handleForm(null)}>
                {'Új szállítói rendelés'}
              </Button>
            }      
          </div>
          <BootstrapTable
            {...props.baseProps }
            bootstrap4
            keyField="id"
            data={rows}
            expandRow={expandRow}
            //rowClasses="kartya-row-class" 
            columns={columns}
            filter={filterFactory()}
            pagination={paginationFactory(options)}
            noDataIndication="Nincs megjelenítendő adat!"
          />
        </React.Fragment>
      </div>
    );  
  }

  return(
    <React.Fragment>
        <iframe name="pdf-area-szallitoi-rendeles" id="pdf-area-szallitoi-rendeles" src={printUrl} style={{ visibility: 'hidden', height: 0, width: 0 }} />
        <div className="row">
          <SzallitoiFilter setFilter={filters => setFilters(filters)}/>
          <div className="col-md-2">
            <Button type="button" color="primary" onClick={() => listSzallitoiRendeles(filters)}> 
              <span><i className="fa fa-search" aria-hidden="true"></i></span>
            </Button>
          </div>
        </div>
        {renderTableBase()}
        {renderModals()}
      </React.Fragment>
  );
}

export default SzallitoiContent;