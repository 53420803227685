module.exports = {
  COLOR: {
    PRIMARY: '#007bff',
    SUCCESS: '#28a745',
    INFO: '#17a2b8',
    WARNING: '#ffc107',
    DANGER: '#f86c6b',
    SECONDARY: '#6c757d',
    DARK: '#343a40',
    LIGHT: '#f8f9fa'
  },
};
