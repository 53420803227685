import Lib from '../../commons/lib';

export default class Service {
  static rendelesUrl = window.location.origin + '/api/rendeles/';

  static cegadatUrl = window.location.origin + '/api/cegadat/';

  static partnerUrl = window.location.origin + '/api/partner/';

  static penzugyUrl = window.location.origin + '/api/penzugykozos/';

  static cikkUrl = window.location.origin + '/api/cikk/';

  static telepulesUrl = window.location.origin + '/api/telepules/';

  // SZÁLLÍTÓI START

  static addSzallitoiRendeles(param) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'POST',
        url: Service.rendelesUrl + 'szallitoi/',
        args: { requestType: 'rendeles-insert' },
        data: param,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static editSzallitoiRendeles(param) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'PUT',
        url: Service.rendelesUrl + 'szallitoi/',
        args: { requestType: 'rendeles-edit' },
        params: { id: param.id },
        data: param,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static editAcceptSzallitoiRendeles(param) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'PUT',
        url: Service.rendelesUrl + 'szallitoi/accept',
        args: { requestType: 'rendeles-edit-accept' },
        params: { id: param.id },
        data: param,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static getSzallitoiRendeles(param) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'GET',
        url: Service.rendelesUrl + 'szallitoi/',
        args: { requestType: 'rendeles-get' },
        params: param,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static listSzallitoiRendeles(filters) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'PUT',
        url: Service.rendelesUrl + 'szallitoi/list',
        args: { requestType: 'rendeles-list' },
        data: filters,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static listSzallitoiCikkRendeles(param) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'GET',
        url: Service.rendelesUrl + 'szallitoi/list/cikk',
        args: { requestType: 'rendeles-list-cikk' },
        params: param,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static listAllapotSzallitoiRendeles(/* param */) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'GET',
        url: Service.rendelesUrl + 'szallitoi/enum/allapot',
        args: { requestType: 'rendeles-list-allapot' },
        // param: param,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static sendSzallitoiRendeles(param) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'PUT',
        url: Service.rendelesUrl + 'szallitoi/send',
        args: { requestType: 'rendeles-send' },
        params: param,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static printSzallitoiRendeles(param) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'GET',
        url: Service.rendelesUrl + 'szallitoi/print',
        args: { requestType: 'rendeles-print' },
        acceptType: 'application/json; charset=utf-8, text/plain; charset=utf-8, application/octet-stream',
        params: param,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static deleteSzallitoiRendeles(param) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'DELETE',
        url: Service.rendelesUrl + 'szallitoi/',
        args: { requestType: 'rendeles-delete' },
        params: param,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  // SZÁLLÍTÓI END

  // AJÁNLAT START

  static getAjanlatSablon(param) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'GET',
        url: Service.rendelesUrl + 'ajanlat/getsablon',
        args: { requestType: 'ajanlat-getsablon' },
        params: { cegadatid: param.id }
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static addAjanlat(param) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'POST',
        url: Service.rendelesUrl + 'ajanlat/',
        args: { requestType: 'ajanlat-insert' },
        data: param,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static editAjanlat(param) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'PUT',
        url: Service.rendelesUrl + 'ajanlat/',
        args: { requestType: 'ajanlat-edit' },
        params: { id: param.id },
        data: param,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static getAjanlat(param) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'GET',
        url: Service.rendelesUrl + 'ajanlat/',
        args: { requestType: 'ajanlat-get' },
        params: param,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static listAjanlat(filters) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'PUT',
        url: Service.rendelesUrl + 'ajanlat/list',
        args: { requestType: 'ajanlat-list' },
        data: filters,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static listAjanlatCikk(param) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'GET',
        url: Service.rendelesUrl + 'ajanlat/list/cikk',
        args: { requestType: 'ajanlat-list-cikk' },
        params: param,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static listAllapotAjanlat(/* param */) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'GET',
        url: Service.rendelesUrl + 'ajanlat/enum/allapot',
        args: { requestType: 'ajanlat-list-allapot' },
        // param: param,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static sendAjanlat(param) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'PUT',
        url: Service.rendelesUrl + 'ajanlat/send',
        args: { requestType: 'ajanlat-send' },
        params: param,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static abortAjanlat(param) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'PUT',
        url: Service.rendelesUrl + 'ajanlat/abort',
        args: { requestType: 'ajanlat-abort' },
        params: param,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static duplicateAjanlat(param) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'PUT',
        url: Service.rendelesUrl + 'ajanlat/duplicate',
        args: { requestType: 'ajanlat-duplicate' },
        params: param,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static printAjanlat(param) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'GET',
        url: Service.rendelesUrl + 'ajanlat/print',
        args: { requestType: 'ajanlat-print' },
        acceptType: 'application/json; charset=utf-8, text/plain; charset=utf-8, application/octet-stream',
        params: param,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static downloadAjanlatObject(param, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.rendelesUrl + 'ajanlat/object',
      args: { requestType: 'ajanlat-obj-content' },
      acceptType: 'application/json; charset=utf-8, text/plain; charset=utf-8, application/octet-stream',
      params: param,
    }], fnDone);
  }

  static deleteAjanlat(param) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'DELETE',
        url: Service.rendelesUrl + 'ajanlat/',
        args: { requestType: 'ajanlat-delete' },
        params: param,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  // AJÁNLAT END

  // SZÁLLÍTÁSIMÓD START

  static addSzallitasiModRendeles(nev) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'POST',
        url: Service.rendelesUrl + 'szallmod/',
        args: { requestType: 'rendeles-insert' },
        params: { nev },
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static listSzallitasiModRendeles(filters) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'GET',
        url: Service.rendelesUrl + 'szallmod/list',
        args: { requestType: 'rendeles-list' },
        params: filters,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static deleteSzallitasiModRendeles(id) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'DELETE',
        url: Service.rendelesUrl + 'szallmod/',
        args: { requestType: 'rendeles-delete' },
        params: { id },
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  // SZÁLLÍTÁSIMÓD START

  // RENDELÉS START

  static listVevoiRendeles(filters) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'POST',
        url: Service.rendelesUrl + 'rendeles/list',
        args: { requestType: 'rendeles-list' },
        data: filters
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static listVevoiRendelesCikk() {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'GET',
        url: Service.rendelesUrl + 'rendeles/listcikk',
        args: { requestType: 'rendeles-listcikk' },
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static insertVevoiRendeles(obj) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'POST',
        url: Service.rendelesUrl + 'rendeles',
        args: { requestType: 'rendeles-post' },
        data: obj
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static editVevoiRendeles(obj) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'PUT',
        url: Service.rendelesUrl + 'rendeles',
        args: { requestType: 'rendeles-put' },
        data: obj
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static deleteVevoiRendeles(id) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'DELETE',
        url: Service.rendelesUrl + 'rendeles',
        args: { requestType: 'rendeles-delete' },
        params: { id }
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static abortVevoiRendeles(id) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'PUT',
        url: Service.rendelesUrl + 'rendeles/abort',
        args: { requestType: 'rendeles-abort-put' },
        params: { id }
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static sendRendeles(params, data) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'POST',
        url: Service.rendelesUrl + 'rendeles/email',
        args: { requestType: 'rendeles-email' },
        params,
        data
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static printRendeles(params, data) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'POST',
        url: Service.rendelesUrl + 'rendeles/print',
        args: { requestType: 'rendeles-print' },
        acceptType: 'application/json; charset=utf-8, text/plain; charset=utf-8, application/octet-stream',
        params,
        data
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static getRendelesSablon(param) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'GET',
        url: Service.rendelesUrl + 'rendeles/getsablon',
        args: { requestType: 'rendeles-getsablon' },
        params: { cegadatid: param.id }
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  // RENDELÉS END

  // SZÁMLÁZÁS START

  static createSzamla(data) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'PUT',
        url: Service.rendelesUrl + 'rendeles/szamlazas/create',
        args: { requestType: 'rendeles-szamla-create' },
        data: data,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static previewSzamla(data) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'PUT',
        url: Service.rendelesUrl + 'rendeles/szamlazas/preview',
        args: { requestType: 'rendeles-szamla-preview' },
        acceptType: 'application/json; charset=utf-8, text/plain; charset=utf-8, application/octet-stream',
        data: data,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static downloadSzamla(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.rendelesUrl + 'rendeles/szamlazas/download',
      acceptType: 'application/json, application/octet-stream',
      withFileName: true,
      params: { id: id },
    }], fnDone);
  }

  static sztornoSzamla(data) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'PUT',
        url: Service.rendelesUrl + 'rendeles/szamlazas/create/sztorno',
        args: { requestType: 'rendeles-szamla-sztorno' },
        data: data,
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static downloadSztornoSzamla(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.rendelesUrl + 'rendeles/szamlazas/download/sztorno',
      acceptType: 'application/json, application/octet-stream',
      withFileName: true,
      params: { id: id },
    }], fnDone);
  }

  // SZÁMLÁZÁS END

  // BEÁLLÍTÁS START

  static listBeallitas() {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'GET',
        url: Service.rendelesUrl + 'beallitas/list',
        args: { requestType: 'beallitas-list' },
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }

  static editBeallitas(data) {
    return new Promise((resolve, reject) => {
      Lib.MicroServices.requestService([{
        method: 'PUT',
        url: Service.rendelesUrl + 'beallitas',
        args: { requestType: 'beallitas-put' },
        data
      }], (err, res) => {
        if (err) reject(err); else resolve(res[0]);
      });
    });
  }


  // BEÁLLÍTÁS END

  // PARTNER START

  static listPartner(param, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'partner-list' },
      params: param,
    }], fnDone);
  }

  // PARTNER END

  // CÉGADAT START

  static listCegadat(param, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.cegadatUrl + 'search',
      args: { requestType: 'cegadat-list' },
      params: {
        resultclass: 90,
        aktiv: true
      },
    }], fnDone);
  }

  // CÉGADAT START

  // BIZONYLAT START

  static listBizonylatRaktar(param, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.bizonylatUrl + 'biztomb/list/raktarak',
      args: { requestType: 'bizonylat-raktar-list' },
      params: param, //
    }], fnDone);
  }

  // BIZONYLAT START

  // CIKK START

  static listCikk(param, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cikkUrl + 'cikk/query',
      args: { requestType: 'cikk-query' },
      params: param
    }], fnDone);
  }

  static listCikkCsoport(param, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cikkUrl + 'cikkcsoport/query',
      args: { requestType: 'cikkcsoport-query' },
      params: param,
    }], fnDone);
  }

  static listAktivAfa(afaId, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cikkUrl + 'afa/list/aktiv',
      args: { requestType: 'afa-list-aktiv' },
      params: { id: afaId },
    }], fnDone);
  }

  // CIKK END

  // PENZUGY START

  static listAktivFizetesimod(param, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'fizmod/aktiv',
      args: { requestType: 'fizmod-list' }
    }], fnDone);
  }

  static listAktivPenznem(param, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'penznem/aktiv',
      args: { requestType: 'penznem-aktiv' }
    }], fnDone);
  }

  static getDefaultCurrency(withkerekitesiszabaly, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.penzugyUrl + 'penznem/defaultcurrency',
      args: { requestType: 'penznem-defaultcurrency' },
      params: { withkerekitesiszabaly }
    }], fnDone);
  }

  // PENZUGY END

  // TELEPÜLÉS START

  static listOrszag(name, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'orszag/list',
      args: { requestType: 'orszag-list' },
      params: { name: name }
    }], fnDone);
  }

  static listCim(text, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'telepules/like',
      args: { requestType: 'telepules-get-listcim' },
      params: { text: text }
    }], fnDone);
  }

  // TELEPÜLÉS END
}
