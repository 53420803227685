import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from 'reactstrap';
import {
  AvGroup,
  AvInput,
  AvFeedback,
  AvForm,
  AvField
} from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Lib from '../../commons/lib';
import DataEmptyState from '../../commons/dataemptystate';
import NetworkEmptyState from '../../commons/networkemptystate';
import Loader from '../../commons/loader';
import Services from './services';
import { PaginationTotal } from '../../commons/controls';
import { ProgressButton } from '../../commons/ProgressButton';

class SzempontokContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // ['LOAD', 'DATA', 'NETW', 'TABL']
      tableStatus: 'LOAD',
      // ['LOAD', 'DATA', 'NETW']
      formStatus: 'LOAD',
      // ['FEL', 'MOD']
      formType: 'FEL',
      // FORM VALUES
      formAzonosito: '',
      formNev: '',
      formStatusz: '',
      // UTILS
      currentId: null,
      statuszOptions: [],
      baseJson: [],
      modalState: false,
      modalTorles: false,
      torlesId: null,
      saveButtonLoading: false,
      deleteButtonLoading: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      if (this.props.active) {
        this.getSzempontokList();
      }
    }
  }

  toggleModal = () => {
    this.setState(prevState => ({ modalState: !prevState.modalState }));
  }

  toggleTorles = () => {
    this.setState(prevState => ({ modalTorles: !prevState.modalTorles }));
  }

  deleteSzempont = () => {
    this.setState({
      deleteButtonLoading: true
    });
    Services.deleteSzempont(this.state.torlesId, (err) => {
      if (!err) {
        this.setState({
          deleteButtonLoading: false
        });
        this.toggleTorles();
        this.getSzempontokList();
        this.props.globals.addSuccess('Ellenőrzési szempont törölve!');
      } else {
        this.setState({
          deleteButtonLoading: false
        });
      }
    });
  }

  getSzempontokList = () => {
    this.setState({
      tableStatus: 'LOAD'
    });
    Services.getSzempontokList((err, res) => {
      if (!err) {
        res[0].forEach((item) => {
          if (item.statusz) item.aktiv = item.statusz.name === 'AKTIV' ? 1 : 0;
        });
        this.setState({
          tableStatus: res[0].length ? 'TABL' : 'DATA',
          baseJson: res[0]
        });
      } else {
        this.setState({
          tableStatus: 'NETW'
        });
      }
    });
  }

  getSzempont = (id) => {
    this.setState({
      formStatus: 'LOAD'
    });
    Services.getSzempont(id, (err, res) => {
      if (!err) {
        this.setState({
          formStatus: 'DATA',
          formAzonosito: res[0].azonosito ? res[0].azonosito : '',
          formNev: res[0].nev ? res[0].nev : '',
          // formStatusz: (res[0].statusz && res[0].statusz.name) ? res[0].statusz.name : '',
          formStatusz: this.nullToString(JSON.stringify(res[0].statusz))
        });
      } else {
        this.setState({
          formStatus: 'NETW'
        });
      }
    });
  }

  getEnum = () => {
    this.setState({
      formStatus: 'LOAD',
    });
    Services.getEnum((err, res) => {
      if (!err) {
        let array = res[0][0].map(e => <option key={e.name} value={JSON.stringify({ name: e.name, caption: e.caption })}>{e.caption}</option>);
        this.setState({
          statuszOptions: array
        });
        this.setState({
          formStatus: 'DATA',
        });
      } else {
        this.setState({
          formStatus: 'NETW',
        });
      }
    });
  }

  nullToString = (data) => {
    if (!data) return '';
    return data;
  }

  handleSubmit = () => {
    const {
      formAzonosito,
      formNev,
      formStatusz,
      formType,
      currentId
    } = this.state;

    this.setState({
      saveButtonLoading: true
    });

    if (formType === 'FEL') {
      let submitObj = {
        azonosito: formAzonosito,
        nev: formNev,
        statusz: JSON.parse(formStatusz).name
      };

      Services.createSzempont(submitObj, (err) => {
        if (!err) {
          this.toggleModal();
          this.getSzempontokList();
          this.props.globals.addSuccess('Ellenőrzési szempont felvéve!');
          this.setState({
            saveButtonLoading: false
          });
        } else {
          this.setState({
            saveButtonLoading: false
          });
        }
      });
    }

    if (formType === 'MOD') {
      let submitObj = {
        id: currentId,
        azonosito: formAzonosito,
        nev: formNev,
        statusz: JSON.parse(formStatusz).name
      };

      Services.updateSzempont(submitObj, (err) => {
        if (!err) {
          this.toggleModal();
          this.getSzempontokList();
          this.props.globals.addSuccess('Ellenőrzési szempont módosítva!');
          this.setState({
            saveButtonLoading: false
          });
        } else {
          this.setState({
            saveButtonLoading: false
          });
        }
      });
    }
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  handleNewClick = () => {
    this.setState({
      formType: 'FEL',
      formStatus: 'DATA',
      formAzonosito: '',
      formNev: '',
      formStatusz: '{"name":"AKTIV","caption":"Aktív"}',
    }, () => {
      this.getEnum();
      this.toggleModal();
    });
  }

  handleEditClick = (cell) => {
    this.setState({
      formType: 'MOD',
      formStatus: 'LOAD',
      currentId: cell,
      formAzonosito: '',
      formNev: '',
      formStatusz: '',
    }, () => {
      this.getSzempont(cell);
      this.getEnum();
      this.toggleModal();
    });
  }

  handleDeleteClick = (cell) => {
    this.setState({
      torlesId: cell
    }, () => {
      this.toggleTorles();
    });
  }

  tableIconFormatter = (cell) => {
    return (
      <div>
        <React.Fragment>
          <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => this.handleEditClick(cell)} />
          <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'modositas'} delay={0}>
            {'Módosítás'}
          </UncontrolledTooltip>
          <Button className="icon-trash table-action-icon" id={'action-view-' + cell + 'torles'} onClick={() => this.handleDeleteClick(cell)} />
          <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'torles'} delay={0}>
            {'Törlés'}
          </UncontrolledTooltip>
        </React.Fragment>
      </div>
    );
  }

  renderFormTitle = () => {
    switch (this.state.formType) {
      case 'FEL':
        return 'Új ellenőrzési szempont létrehozása';
      case 'MOD':
        return 'Ellenőrzési szempont módosítása';
      default:
        return '';
    }
  }

  renderForm = () => {
    const {
      formAzonosito,
      formNev,
      formStatusz,
      statuszOptions,
      formType
    } = this.state;

    return (
      <React.Fragment>
        <AvGroup>
          <Label for="formAzonosito">Azonosító: *</Label>
          <AvInput
            name="formAzonosito"
            id="formAzonosito"
            disabled={formType === 'MOD'}
            required
            maxLength="15"
            value={formAzonosito}
            onChange={this.handleInputChange}
          />
          <AvFeedback>Kötelező mező!</AvFeedback>
        </AvGroup>
        <AvGroup>
          <Label for="formNev">Megnevezés: *</Label>
          <AvInput
            name="formNev"
            id="formNev"
            required
            maxLength="100"
            value={formNev}
            onChange={this.handleInputChange}
          />
          <AvFeedback>Kötelező mező!</AvFeedback>
        </AvGroup>
        <AvField
          type="select"
          name="formStatusz"
          id="formStatusz"
          label="Státusz: *"
          errorMessage="Kötelező mező!"
          required
          value={formStatusz}
          onChange={this.handleInputChange}
        >
          {/* <option value="">Válasszon státuszt...</option> */}
          {statuszOptions}
        </AvField>
      </React.Fragment>
    );
  }

  tableStatusFormatter = (cell) => {
    return cell === 'AKTIV'
      ? <span className="badge badge-success">Aktív</span>
      : <span className="badge badge-default">Inaktív</span>;
  }

  renderTable = () => {
    const options = {
      sizePerPage: 5,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const selectOptions = {
      AKTIV: 'Aktív',
      INAKTIV: 'Inaktív',
    };

    const columns = [
      {
        dataField: 'azonosito',
        text: 'Azonosító',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'nev',
        text: 'Megnevezés',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'statusz.name',
        align: 'center',
        text: 'Státusz',
        filter: selectFilter({
          options: selectOptions,
          placeholder: 'Összes',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        formatter: this.tableStatusFormatter
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        hidden: !Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KARBANTARTAS_ELLENORZESILISTA', 'KARB'),
        formatter: this.tableIconFormatter
      }
    ];

    return (
      <div className="animated fadeIn">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={this.state.baseJson}
          columns={columns}
          filter={filterFactory()}
          pagination={paginationFactory(options)}
          noDataIndication="Nincs megjelenítendő adat!"
        />
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KARBANTARTAS_ELLENORZESILISTA', 'KARB') && (
              <Button className="pull-left table-new-button" color="primary" onClick={this.handleNewClick}>
                {'Új ellenőrzési szempont'}
              </Button>
            )}
          </div>
          <div className="col-md-12">
            {{
              LOAD: (<Loader />),
              DATA: (<DataEmptyState
                hasImage
                subtitle="Vigyél fel új ellenőrzési szempontot!"
              />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.getSzempontokList()}
              />),
              TABL: (this.renderTable())
            }[this.state.tableStatus]}
          </div>
        </div>
        <Modal isOpen={this.state.modalState} toggle={this.toggleModal} backdrop="static">
          <AvForm onValidSubmit={this.handleSubmit}>
            <ModalHeader>{this.renderFormTitle()}</ModalHeader>
            <ModalBody>
              {{
                LOAD: (<Loader />),
                NETW: (<NetworkEmptyState
                  hasImage
                  submitClick={() => this.getSzempont(this.state.currentId)}
                />),
                DATA: (this.renderForm())
              }[this.state.formStatus]}
            </ModalBody>
            <ModalFooter>
              <ProgressButton
                className="btn-cursor-pointer"
                color="primary"
                loading={this.state.saveButtonLoading}
              >
                {'Mentés'}
              </ProgressButton>
              <Button type="button" color="secondary" className="btn-cursor-pointer" onClick={this.toggleModal}>Mégsem</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>Valóban törölni kívánja a kiválasztott tételt?</ModalBody>
          <ModalFooter>
            <ProgressButton
              className="btn-cursor-pointer"
              color="primary"
              onClick={() => this.deleteSzempont()}
              loading={this.state.deleteButtonLoading}
            >
              {'Igen'}
            </ProgressButton>
            <Button type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

SzempontokContent.propTypes = {
  globals: PropTypes.object,
  active: PropTypes.bool
};

export default SzempontokContent;
