import React, { Component } from 'react';

import BevetelezesForm from './BevetelezesForm.js';

class BevetelezesBase extends Component {
  render() {
    const cardStyle = {
      padding: 20
    };

    return (
      <div>
        <div className="animated fadeIn">
          <div className="card">
            <div className="card-header">
              {this.props.route.modositas == 'true' ? 'Készletnövekedés módosítása' : 'Új készletnövekedés'}
            </div>
            <div className="row" style={cardStyle}>
              <div className="col-md-12">
                <BevetelezesForm globals={this.props.route.globals} route={this.props.route}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BevetelezesBase;
