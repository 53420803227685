import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, UncontrolledTooltip, Badge } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import PropTypes from 'prop-types';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import QueryEmptyState from '../../../commons/queryempty';
import Loader from '../../../commons/loader';
import Services from './services';
import { PaginationTotal } from '../../../commons/controls';
import Lib from '../../../commons/lib';

// 0 -> Felvitel
// 1 -> módosítás
let _muvelet = 0;
let _modositoObj = null;

class PenztarLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ['QUERY', 'LOAD', 'DATA', 'NETW', 'TABL']
      currentStatusBaseTable: 'LOAD',
      modalFelvitel: false,
      modalTorles: false,
      modalValtozas: false,
      errorFromServer: '-',
      submitObj: null,
      idFromForm: null,
      idFromServer: null,
      dataLoaded: false,
      dataFailed: false,
      baseJson: [],
      aktivPenznem: [],
      aktivCegadat: [],
      modalNezet: false,
      statusz: [],
      statuszOptions: [],
      filterOptions: {},
      viewObj: null,
    };

    this.updateTable = this.updateTable.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      if (this.props.active) {
        this.updateTable();
      }
    }
  }

  getAktivPenznem = () => {
    Services.listAktivPenznem(null, null, (err, res) => {
      if (!err) {
        let resOptions = res[0].map(e => (
          <option key={e.id} value={e.id}>
            {e.nev}
          </option>
        ));
        this.setState({ aktivPenznem: resOptions });
      }
    });
  }

  getAktivCegadat = () => {
    Services.listAktivCegadat(null, (err, res) => {
      if (!err) {
        let resOptions = res[0].map(e => <option key={e.id} value={e.id}>{e.nev + ' (' + e.tipus + ')'}</option>);
        this.setState({ aktivCegadat: resOptions });
      }
    });
  }
  /** Objektumot bejárva az üres stringeket NULL-ra állítja */
  emptyToNull = (obj) => {
    if (!obj || typeof obj !== 'object') return;
    for (let p in obj) {
      if (typeof obj[p] === 'object' && obj[p] !== null) {
        this.emptyToNull(obj[p]);
      } else if (obj.hasOwnProperty(p)) {
        if (obj[p] === '' || obj[p] === undefined) {
          obj[p] = null;
        }
      }
    }
  }

  updateTable = () => {
    this.setState({
      currentStatusBaseTable: 'LOAD'
    });
    Services.listHaziPenztar((err, res) => {
      if (err) {
        this.setState({
          currentStatusBaseTable: 'NETW'
        });
      } else {
        let filters = {};
        let options = res[0].statusz.map((e) => {
          filters[e.name] = e.caption;

          return <option key={e.name} value={e.name}>{e.caption}</option>;
        });

        this.setState({
          baseJson: res[0].list,
          statusz: res[0].statusz,
          statuszOptions: options,
          filterOptions: filters,
          currentStatusBaseTable: res[0].list.length > 0 ? 'TABL' : 'DATA'
        }, () => {});
      }
    });
  }

  fillModositoObj = () => {
    this.setState({ modifyDataLoaded: false, modfiyDataFailed: false });
    Services.getHaziPenztar(this._currentEditId, (err, res) => {
      if (err) {
        this.setState({ modifyDataLoaded: true, modfiyDataFailed: true });
      } else {
        this.setState({ modifyDataLoaded: true, modfiyDataFailed: false });
        res[0].felhasznalva ? res[0].felhasznalva = 'true' : res[0].felhasznalva = 'false';
        _modositoObj = res[0];
        _modositoObj.cegadat = res[0].cegadatid;
        _modositoObj.penznemValue = res[0].penznem.id;
        Services.listAktivCegadat(res[0].cegadatid, (err2, res2) => {
          if (!err2) {
            let resOptions = res2[0].map(e => <option key={e.id} value={e.id}>{e.nev + ' (' + e.tipus + ')'}</option>);
            this.setState({ aktivCegadat: resOptions }, () => {
              this.toggleFelvitel();
            });
          }
        });

        Services.listAktivPenznem(_modositoObj.penznemValue, true, (err2, res2) => {
          if (!err2) {
            let resOptions = res2[0].map(e => (
              <option key={e.id} value={e.id}>
                {e.nev}
              </option>
            ));
            this.setState({ aktivPenznem: resOptions });
          }
        });
      }
    });
  }

  getViewObj = () => {
    Services.getBankszamla(this._currentViewId, (err, res) => {
      if (!err) {
        this.setState({ viewObj: res[0] });
      }
    });
  }

  clearModositoObj = () => {
    _modositoObj = {
      id: '',
      ev: '',
      tipus: '',
      mertek: '',
      statusz: 'AKTIV',
    };
  }


  toggleFelvitel = () => {
    this.setState({ modalFelvitel: !this.state.modalFelvitel });
  }

  toggleTorles = () => {
    this.setState({ modalTorles: !this.state.modalTorles });
  }

  toggleNezet = () => {
    this.setState({ modalNezet: !this.state.modalNezet });
  }

  toggleValtozas = () => {
    this.setState({ modalValtozas: !this.state.modalValtozas });
  }

  handleEditClick = (id) => {
    _muvelet = 1;
    this._currentEditId = id;
    this.fillModositoObj();
  }

  handleNewClick = () => {
    _muvelet = 0;
    this.clearModositoObj();
    this.getAktivPenznem();
    this.getAktivCegadat();
    this.toggleFelvitel();
  }

  handleDeleteClick = (id) => {
    this._currentDeleteId = id;
    this.toggleTorles();
  }


  handleViewClick = (id) => {
    this._currentViewId = id;
    this.getViewObj();
    this.toggleNezet();
  }

  iconFormatter = (cell, row) => {
    return (
      <div>
        { Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'BANK_PENZTAR', 'LEK') && (
          <React.Fragment>
            <Button className="icon-eye table-action-icon" id={'action-view-' + cell} onClick={() => this.handleViewClick(cell)} />
            <UncontrolledTooltip placement="left" target={'action-view-' + cell} delay={10}>
              {'Megtekintés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        { Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'BANK_PENZTAR', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-edit-' + cell} onClick={() => this.handleEditClick(cell, row)} />
            <UncontrolledTooltip placement="left" target={'action-edit-' + cell} delay={10}>
              {'Módosítás'}
            </UncontrolledTooltip>
            <Button className="icon-trash table-action-icon" id={'action-delete-' + cell} onClick={() => this.handleDeleteClick(cell)} />
            <UncontrolledTooltip placement="left" target={'action-delete-' + cell} delay={10}>
              {'Törlés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  /**
  * Visszaadja az apartmanhoz tartozó státus jelvényeket.
  * @param {boolean} cell - Cella adatot tartalmazó szöveg.
  * @return {ReactElement} Státusz jelvényt tartalmazó react objektum.
  */
  statusFormatter = (cell) => {
    let statusz = this.state.statusz.find(x => x.name === cell);
    return statusz ? <span className={'badge badge-' + statusz.bagde}>{statusz.caption}</span> : 'N/A';
  }

  felhasznalvaFormatter = (cell) => {
    return cell ? 'Igen' : 'Nem';
  }

  addHaziPenztar = (event, haziPenztarObj) => {
    this.emptyToNull(haziPenztarObj);

    let bean = {};
    bean.tipus = 'HAZI';
    bean.nev = haziPenztarObj.nev;
    bean.fokonyviszam = haziPenztarObj.fkviszam;
    bean.penznemid = haziPenztarObj.penznem;
    bean.cegadatid = haziPenztarObj.cegadat;
    bean.statusz = haziPenztarObj.statusz;

    Services.addHaziPenztar(bean, (err) => {
      if (!err) {
        this.toggleFelvitel();
        this.updateTable();
        this.props.globals.addSuccess('Házi pénztár felvéve!');
      }
    });
  }

  updateHaziPenztar = (event, haziPenztarObj) => {
    let bean = {};
    bean.id = this._currentEditId;
    bean.tipus = 'HAZI';
    bean.nev = haziPenztarObj.nev;
    bean.fokonyviszam = haziPenztarObj.fkviszam;
    bean.penznemid = haziPenztarObj.penznem;
    bean.cegadatid = haziPenztarObj.cegadat;
    bean.statusz = haziPenztarObj.statusz;

    haziPenztarObj.id = this._currentEditId;
    this.emptyToNull(haziPenztarObj);
    let penzid={id:haziPenztarObj.penznem};
    // let cegadat=haziPenztarObj.cegadat.split(';');
    if (haziPenztarObj.penznem) {
      haziPenztarObj.penznem= penzid;
    }
    haziPenztarObj.tipus = 'HAZI';
    // haziPenztarObj.cegadattipus=cegadat[1];
    haziPenztarObj.cegadatid=haziPenztarObj.cegadat;

    Services.existsNew(haziPenztarObj.cegadat, (err, res) => {
      if (!err) {
        if (res[0].hiba === null) {
          Services.editHaziPenztar(bean, (err2) => {
            if (!err2) {
              this.toggleFelvitel();
              this.updateTable();
              this.props.globals.addSuccess('Házi pénztár módosítva!');
            }
          });
        } else {
          this.setState({ errorFromServer: res[0].hiba, idFromServer: res[0].id, idFromForm: haziPenztarObj.cegadat, submitObj: haziPenztarObj }, () => {
            this.toggleValtozas();
          });
        }
      }
    });
  }

  editBankszamla = (id) => {
    let submitObj = JSON.parse(JSON.stringify(this.state.submitObj));
    submitObj.cegadatid = id;
    Services.editHaziPenztar(submitObj, (err2) => {
      if (!err2) {
        this.setState({ modalValtozas: false }, () => {
          this.setState({ modalFelvitel: false }, () => {
            this.updateTable();
            this.props.globals.addSuccess('Házi pénztár módosítva!');
          });
        });
      }
    });
  }

  deleteHaziPenztar = (id) => {
    this.toggleTorles();
    Services.deleteHaziPenztar(id, (err) => {
      if (!err) {
        this.updateTable();
        this.props.globals.addSuccess('Házi pénztár törölve!');
      }
    });
  }

  filterType = (cell) => {
    // just return type for filtering or searching.
    return cell.caption;
  }

  renderBaseTable = () => {
    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ],
    };

    return (
      <BootstrapTable data={this.state.baseJson} pagination options={options}>
        <TableHeaderColumn dataField="nev" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>
          {'Pénztár neve'}
        </TableHeaderColumn>
        <TableHeaderColumn dataField="ceg" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>
          {'Cégnév'}
        </TableHeaderColumn>
        <TableHeaderColumn dataField="penznem" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>
          {'Pénznem'}
        </TableHeaderColumn>
        <TableHeaderColumn dataField="fokonyviszam" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>
          {'Főkönyvi szám'}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="statusz"
          filter={{
            type: 'SelectFilter',
            options: this.state.filterOptions,
            placeholder: 'Összes',
            condition: 'eq'
          }}
          dataFormat={this.statusFormatter}
          tdStyle={{ whiteSpace: 'normal' }}
          width="170"
          dataAlign="center"
        >
          {'Státusz'}
        </TableHeaderColumn>
        <TableHeaderColumn dataField="id" isKey width="140" dataFormat={this.iconFormatter}>Műveletek </TableHeaderColumn>
      </BootstrapTable>
    );
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  renderAdatok = () => {
    let penztarObj = this.state.viewObj ? this.state.viewObj : {};
    return (
      <div>
        <b>Név: </b>{penztarObj.nev}<br />
        <b>Főkönyvi szám: </b>{penztarObj.fkviszam}<br />
        <b>Pénznem: </b>{penztarObj.penznem && (penztarObj.penznem.nev + '(' + penztarObj.penznem.jel + ') - ' + penztarObj.penznem.azonosito)}<br />
        <b>Státusz: </b>{penztarObj.statusz && this.state.statusz.find(x => x.name === penztarObj.statusz).caption}<br /><br />
        <h6>Cég információ:</h6>
        <b>Név: </b>{penztarObj.cegnev}<br />
        <b>Típus: </b>{penztarObj.cegtipus}<br />
      </div>
    );
  }


  render() {
    return (
      <div>
        { Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'BANK_PENZTAR', 'KARB') && (
          <div className="row">
            <div className="col-sm-12">
              <Button className="pull-left table-new-button" color="primary" onClick={this.handleNewClick}>Új pénztár</Button>
            </div>
          </div>
        )}
        {{
          QUERY: (<QueryEmptyState
            hasImage
          />),
          LOAD: (<Loader />),
          DATA: (<DataEmptyState
            hasImage
            subtitle="Próbálj más előszűrést vagy vigyél fel új adatokat!"
          />),
          NETW: (<NetworkEmptyState
            hasImage
            submitClick={() => this.updateBaseTable()}
          />),
          TABL: (this.renderBaseTable())
        }[this.state.currentStatusBaseTable]}

        <Modal isOpen={this.state.modalNezet} toggle={this.toggleNezet} className={this.props.className} backdrop="static">
          <ModalHeader>Megtekintés</ModalHeader>
          <ModalBody>{this.state.modalNezet ? this.renderAdatok() : <div />}</ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" onClick={this.toggleNezet} className="btn-cursor-pointer">Vissza</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>
            {'Valóban törölni kívánja a kiválasztott tételt?'}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteHaziPenztar(this._currentDeleteId)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalFelvitel} toggle={this.toggleFelvitel} className={this.props.className} backdrop="static">

          <AvForm onValidSubmit={_muvelet === 0 ? this.addHaziPenztar : this.updateHaziPenztar}>
            {_muvelet === 0 ? <ModalHeader>Új pénztár rögzítése</ModalHeader> : <ModalHeader>Meglévő pénztár módosítása</ModalHeader> }
            <ModalBody>
              <AvGroup>
                <Label for="nev">Név: *</Label>
                <AvInput name="nev" id="nev" required value={_modositoObj && _modositoObj.nev} />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
              <AvGroup>
                <Label for="fkviszam">Főkönyvi szám: </Label>
                <AvInput name="fkviszam" id="fkviszam" value={_modositoObj && _modositoObj.fkviszam} />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
              <AvField type="select" required name="penznem" id="penznem" label="Pénznem: *" errorMessage="Kötelező mező!" value={_modositoObj && _modositoObj.penznemValue} disabled={_muvelet === 1}>
                <option disabled value="">Válassz pénznemet...</option>
                {this.state.aktivPenznem}
              </AvField>
              {/* <AvGroup>
                <Label for="tipus">Tipus: *</Label>
                <AvInput name="tipus" id="tipus" required value={_modositoObj && _modositoObj.tipus.name} />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup> */}

              <AvField
                type="select"
                // disabled={_muvelet !== 0}
                name="cegadat"
                label="Cégadat: *"
                errorMessage="Kötelező mező!"
                required
                value={_modositoObj && _modositoObj.cegadat}
              >
                <option value="">Válassz cégadatot...</option>
                {this.state.aktivCegadat}
              </AvField>
              <AvField type="select" name="statusz" label="Státusz: *" errorMessage="Kötelező mező!" required value={_modositoObj ? _modositoObj.statusz : 'AKTIV'}>
                {this.state.statuszOptions}
              </AvField>
              <Modal isOpen={this.state.modalValtozas} toggle={this.toggleValtozas} className={this.props.className} backdrop="static">
                <ModalHeader>Cégadat módosult</ModalHeader>
                <ModalBody style={{ whiteSpace: 'pre-wrap' }}>
                  {this.state.errorFromServer}
                </ModalBody>
                <ModalFooter>
                  <Button type="button" className="btn-cursor-pointer" color="primary" onClick={() => this.editBankszamla(this.state.idFromServer)}>Igen</Button>
                  <Button type="button" className="btn-cursor-pointer" color="secondary" onClick={() => this.editBankszamla(this.state.idFromForm)}>Nem</Button>
                  <Button type="button" className="btn-cursor-pointer" color="secondary" onClick={() => this.toggleValtozas()}>Mégsem</Button>
                </ModalFooter>
              </Modal>
            </ModalBody>
            <ModalFooter>
              <Button color="primary">Mentés</Button>
              {' '}
              <Button type="button" color="secondary" onClick={this.toggleFelvitel}>Mégse</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </div>
    );
  }
}

PenztarLista.propTypes = {
  active: PropTypes.bool,
  globals: PropTypes.object,
  className: PropTypes.string,
};

export default PenztarLista;
