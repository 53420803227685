import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Badge,
  Label
} from 'reactstrap';
import {
  AvForm,
} from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { browserHistory } from 'react-router';
import Services from './services';
import { PaginationTotal, DatePicker } from '../../commons/controls';
import NetworkEmptyState from '../../commons/networkemptystate';
import Loader from '../../commons/loader';

class KartotekrendszerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ['LOAD', 'LOADED', 'NETW']
      currentStatusBaseView: 'LOAD',
      modalKartonNezet: false,
      dataLoaded: false,
      modal: false,
      modalTorles: false,
      modalLezaras: false,
      modalVizsgalatTorles: false,
      ugyfelJson: [],
      vizsgalatJson: []
    };

    this.iconFormatter = this.iconFormatter.bind(this);
    this.addUgyfel = this.addUgyfel.bind(this);
    this.ujKarton = this.ujKarton.bind(this);
    this.onRowSelect = this.onRowSelect.bind(this);
  }

  componentDidMount() {
    this.updateTable();
  }

  onRowSelect(row) {
    this.updateVizsgalatTable(row.id, row.lezart);
  }

  updateTable = () => {
    this.setState({ dataLoaded: false });
    Services.listKarton((err, res) => {
      if (!err) {
        res[0].forEach((item) => {
          item.lezartNum = item.lezart ? 1 : 0;
        });
        this.setState({ ugyfelJson: res[0], dataLoaded: true }, () => {
        });
      }
    });
  }

  updateVizsgalatTable = (id, lezart) => {
    Services.listVizsgalat(id, (err, res) => {
      if (!err) {
        res[0].vizsgalatentities.forEach((item) => {
          item.lezart = lezart;
        });
        this.setState({ vizsgalatJson: res[0].vizsgalatentities });
        if (res[0].vizsgalatentities === null) this.props.globals.addInfo('A kiválaszott kartonhoz nincs vizsgálati adat hozzárendelve!');
      }
    });
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  toggleKartonNezet = () => {
    this.setState({
      modalKartonNezet: !this.state.modalKartonNezet
    });
  }

  toggleLezaras = () => {
    this.setState({
      modalLezaras: !this.state.modalLezaras
    });
  }

  toggleTorles = () => {
    this.setState({
      modalTorles: !this.state.modalTorles
    });
  }

  toggleVizsgalatTorles = () => {
    this.setState({
      modalVizsgalatTorles: !this.state.modalVizsgalatTorles
    });
  }

  handleEditClick = (id, row) => {
    this._currentEditId = row.id;
    this.modositas(row.id);
  }

  handleUjVizsgalatClick = (id, row) => {
    this._currentEditId = id;
    this.ujVizsgalat(row.id);
  }

  handleDeleteClick = (id) => {
    this._currentDeleteId = id;
    this.toggleTorles();
  }

  handleDeleteVizsgalatClick = (id) => {
    this._currentDeleteVizsgalatId = id;
    this.toggleVizsgalatTorles();
  }

  handlePrintClick = (id, row) => {
    this.downloadPdf(id, row);
  }

  handleLezarasClick = (id) => {
    this._currentLezarasId = id;
    this.toggleLezaras();
  }

  downloadPdf = (id) => {
    Services.getPdf(id, (err, res) => {
      if (!err) {
        // FileSaver.saveAs(new Blob([res[0]], {type: "application/pdf"}), 'vizsgalati_lap_'+vizsgalat.vizsgalatdatum);
        let fileURL = URL.createObjectURL(new Blob([res[0]], { type: 'application/pdf' }));
        window.open(fileURL);
      }
    });
  }

  handleKartonViewClick = (id) => {
    this.setState({
      currentStatusBaseView: 'LOAD',
      modalKartonNezet: true
    });
    Services.getKarton(id, (err, res) => {
      if (err) {
        this.setState({
          currentStatusBaseView: 'NETW'
        });
      } else {
        this.setState({
          baseViewData: res[0],
          currentStatusBaseView: 'LOADED'
        });
      }
    });
  }

  handleEditVizsgalatClick = (id) => {
    browserHistory.push('/kartotek/vizsgalat/modositas?id=' + id);
  }

  ujKarton = () => {
    browserHistory.push('/kartotek/felvetel');
  }

  ujVizsgalat = (id) => {
    browserHistory.push('/kartotek/vizsgalat/felvetel?id=' + id);
  }

  modositas = (id) => {
    browserHistory.push('/kartotek/modositas?id=' + id);
  }

  allapotFormatter = (cell) => {
    switch (cell) {
      case 0:
        return (
          <Badge color="secondary">
            {'Nem'}
          </Badge>
        );
      case 1:
        return (
          <Badge color="success">
            {'Igen'}
          </Badge>
        );
      default:
        return (
          <Badge color="danger">
            {'Ismeretlen'}
          </Badge>
        );
    }
  }

  // dateFormatter = (cell, row) =>{
  //   if(cell.szuletesiido) return (new Date(cell.szuletesiido).getFullYear() + '-' + (new Date(cell.szuletesiido).getMonth() + 1) + '-' + new Date(cell.szuletesiido).getDate());
  //   else return " ";
  // }

  iconVizsgalatFormatter = (cell, row) => {
    return row.vanmunkalap ? (
      <div>
        <Button id={'action-pdf-child-' + cell} className="icon-printer table-action-icon" onClick={() => this.handlePrintClick(cell, row)} />
        <UncontrolledTooltip
          placement="left"
          target={'action-pdf-child-' + cell}
          delay={0}
        >
          {'PDF generálás'}
        </UncontrolledTooltip>
      </div>
    ) : (
      <div>
        <Button id={'action-pdf-child-' + cell} className="icon-printer table-action-icon" onClick={() => this.handlePrintClick(cell, row)} />
        <UncontrolledTooltip
          placement="left"
          target={'action-pdf-child-' + cell}
          delay={0}
        >
          {'PDF generálás'}
        </UncontrolledTooltip>
        {!row.lezart && (
        <React.Fragment>
          <Button id={'action-edit-child-' + cell} className="icon-pencil table-action-icon" onClick={() => this.handleEditVizsgalatClick(cell)} />
          <UncontrolledTooltip
            placement="left"
            target={'action-edit-child-' + cell}
            delay={0}
          >
            {'Vizsgálat módosítása'}
          </UncontrolledTooltip>
          <Button id={'action-delete-child-' + cell} className="icon-trash table-action-icon" onClick={() => this.handleDeleteVizsgalatClick(cell)} />
          <UncontrolledTooltip
            placement="left"
            target={'action-delete-child-' + cell}
            delay={0}
          >
            {'Vizsgálat törlése'}
          </UncontrolledTooltip>
        </React.Fragment>
        )}
      </div>
    );
  }

  booleanFormatter = (cell) => {
    return cell ? '✓' : 'X';
  }

  deleteUgyfel = (id) => {
    Services.deleteKarton(id, (err) => {
      if (!err) {
        this.toggleTorles();
        this.updateTable();
        this.props.globals.addSuccess('Karton törölve!');
      }
    });
  }

  deleteVizsgalat = (id) => {
    Services.deleteVizsgalat(id, (err) => {
      if (!err) {
        this.toggleVizsgalatTorles();
        this.updateTable();
        this.setState({ vizsgalatJson: [] });
        this.props.globals.addSuccess('Vizsgálat törölve!');
      }
    });
  }

  onLezaras = (event, lezarasObj) => {
    if (lezarasObj.date === '') {
      this.props.globals.addWarning('Lezárás dátum megadása kötelező!');
    } else {
      Services.lockKarton(this._currentLezarasId, lezarasObj.date, (err) => {
        if (!err) {
          this.setState({ vizsgalatJson: [] });
          this.updateTable();
          this.toggleLezaras();
          this.props.globals.addSuccess('Karton lezárva!');
        }
      });
    }
  }

  iconFormatter(cell, row) {
    return row.vanvizsgalat ? (
      <div style={{ height: 52 }}>
        <Button id={'action-view-base-' + cell} className="icon-eye table-action-icon" onClick={() => this.handleKartonViewClick(cell)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
        <UncontrolledTooltip
          placement="top"
          target={'action-view-base-' + cell}
          delay={0}
        >
          {'Karton megtekintés'}
        </UncontrolledTooltip>
        {!row.lezart && (
          <React.Fragment>
            <Button id={'action-edit-base-' + cell} className="icon-pencil table-action-icon" onClick={() => this.handleEditClick(cell, row)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
            <UncontrolledTooltip
              placement="top"
              target={'action-edit-base-' + cell}
              delay={0}
            >
              {'Karton módosítás'}
            </UncontrolledTooltip>
            <Button id={'action-new-base-' + cell} className="icon-note table-action-icon" onClick={() => this.handleUjVizsgalatClick(cell, row)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
            <UncontrolledTooltip
              placement="top"
              target={'action-new-base-' + cell}
              delay={0}
            >
              {'Új vizsgálat'}
            </UncontrolledTooltip>
            <Button id={'action-lock-base-' + cell} className="icon-lock table-action-icon" onClick={() => this.handleLezarasClick(cell, row)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
            <UncontrolledTooltip
              placement="top"
              target={'action-lock-base-' + cell}
              delay={0}
            >
              {'Karton lezárás'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    ) : (
      <div style={{ height: 52 }}>
        <Button id={'action-view-base-' + cell} className="icon-eye table-action-icon" onClick={() => this.handleKartonViewClick(cell)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
        <UncontrolledTooltip
          placement="top"
          target={'action-view-base-' + cell}
          delay={0}
        >
          {'Karton megtekintés'}
        </UncontrolledTooltip>
        {!row.lezart && (
          <React.Fragment>
            <Button id={'action-edit-base-' + cell} className="icon-pencil table-action-icon" onClick={() => this.handleEditClick(cell, row)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
            <UncontrolledTooltip
              placement="top"
              target={'action-edit-base-' + cell}
              delay={0}
            >
              {'Karton módosítás'}
            </UncontrolledTooltip>
            <Button id={'action-new-base-' + cell} className="icon-note table-action-icon" onClick={() => this.handleUjVizsgalatClick(cell, row)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
            <UncontrolledTooltip
              placement="top"
              target={'action-new-base-' + cell}
              delay={0}
            >
              {'Új vizsgálat'}
            </UncontrolledTooltip>
            <Button id={'action-delete-base-' + cell} className="icon-trash table-action-icon" onClick={() => this.handleDeleteClick(cell)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
            <UncontrolledTooltip
              placement="top"
              target={'action-delete-base-' + cell}
              delay={0}
            >
              {'Karton törlés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  addUgyfel(event, ugyfelObj) {
    this.setState({ ugyfelJson: [...this.state.ugyfelJson, ugyfelObj] });
    this.toggle();
  }

  renderVizsgalatTabla = () => {
    return this.state.vizsgalatJson && this.state.vizsgalatJson.length ? (
      <BootstrapTable data={this.state.vizsgalatJson}>
        <TableHeaderColumn dataField="vizsgalatdatum" dataFormat={c => c && (new Date(c).getFullYear() + '-' + (new Date(c).getMonth() + 1) + '-' + new Date(c).getDate())}>
          {'Utolsó vizsgálat'}
        </TableHeaderColumn>
        <TableHeaderColumn dataField="kontrolldatum" dataFormat={c => c && (new Date(c).getFullYear() + '-' + (new Date(c).getMonth() + 1) + '-' + new Date(c).getDate())}>
          {'Kontroll'}
        </TableHeaderColumn>
        <TableHeaderColumn dataField="vanszemuveg" dataFormat={this.booleanFormatter}>
          {'Szemüveg'}
        </TableHeaderColumn>
        <TableHeaderColumn dataField="vankontaktlencse" dataFormat={this.booleanFormatter}>
          {'Kontaktlencse'}
        </TableHeaderColumn>
        <TableHeaderColumn dataField="id" isKey dataFormat={this.iconVizsgalatFormatter}>
          {'Műveletek'}
        </TableHeaderColumn>
      </BootstrapTable>
    ) : <p />;
  }

  renderBaseViewData = () => {
    return (
      <div>
        <h6>
          {'Ügyfél információk:'}
        </h6>
        <b>Név: </b>{this.state.baseViewData && this.state.baseViewData.kartonPartner && this.state.baseViewData.kartonPartner.nev}<br />
        <b>Azonosító: </b>{this.state.baseViewData && this.state.baseViewData.kartonPartner && this.state.baseViewData.kartonPartner.azonosito}<br />
        <b>Cím: </b>{this.state.baseViewData && this.state.baseViewData.kartonPartner && this.state.baseViewData.kartonPartner.cim}<br />
        <b>Születési idő: </b>{this.state.baseViewData && this.state.baseViewData.kartonPartner && this.state.baseViewData.kartonPartner.szuletesiido}<br />
        <b>Tajszám: </b>{this.state.baseViewData && this.state.baseViewData.kartonPartner && this.state.baseViewData.kartonPartner.tajszam}<br />
        <b>Email: </b>{this.state.baseViewData && this.state.baseViewData.kartonPartner && this.state.baseViewData.kartonPartner.email}<br />
        <br />
        <h6>
          {'Karton információk:'}
        </h6>
        <b>Megjegyzés: </b>{this.state.baseViewData && this.state.baseViewData.megjegyzes}<br />
        <b>Lezárás dátuma: </b>{this.state.baseViewData && this.state.baseViewData.lezarasdatum}
        <br />
      </div>
    );
  }

  renderTable = () => {
    const selectRowProp = {
      mode: 'radio',
      onSelect: this.onRowSelect
    };

    const statuszok = {
      0: 'Nem',
      1: 'Igen'
    };

    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ]
    };

    return (
      <BootstrapTable data={this.state.ugyfelJson} pagination options={options} selectRow={selectRowProp}>
        <TableHeaderColumn dataField="nev" filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>
          {'Ügyfél neve'}
        </TableHeaderColumn>
        <TableHeaderColumn dataField="szuletesiido" filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>
          {'Születési dátum'}
        </TableHeaderColumn>
        <TableHeaderColumn dataField="cim" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>
          {'Cím'}
        </TableHeaderColumn>
        <TableHeaderColumn dataField="azonosito" filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>
          {'Ügyfél azonosító'}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="lezartNum"
          dataFormat={this.allapotFormatter}
          filter={{ type: 'SelectFilter', options: statuszok, placeholder: 'Összes' }}
          dataAlign="center"
        >
          {'Lezárt'}
        </TableHeaderColumn>
        <TableHeaderColumn dataField="id" isKey dataFormat={this.iconFormatter}>
          {'Műveletek'}
        </TableHeaderColumn>
      </BootstrapTable>
    );
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  renderAdatok = (id) => {
    let ugyfelObj = this.state.ugyfelJson.find(item => item.id === id);
    return (
      <div>
        <h6>Partner információ:</h6>
        <b>Név: </b>{ugyfelObj.kartotekPartnerBean.nev}<br />
        <b>Cím: </b>{ugyfelObj.kartotekPartnerBean.cim}<br />
        <b>Azonosító: </b>{ugyfelObj.kartotekPartnerBean.azonosito}<br />
        <b>Születési idő: </b>{ugyfelObj.kartotekPartnerBean.szuletesiido}<br /><br />
        <h6>Karton információ:</h6>
        <b>Megjegyzés: </b>{ugyfelObj.megjegyzes}<br />
      </div>
    );
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-sm-12">
            <Button className="pull-left table-new-button" color="primary" onClick={this.ujKarton}>
              {'Új karton'}
            </Button>
          </div>
        </div>
        {this.state.dataLoaded ? this.renderTable() : this.renderLoader()}
        {this.renderVizsgalatTabla()}
        <Modal
          isOpen={this.state.modalTorles}
          toggle={this.toggleTorles}
          className={this.props.className}
          backdrop="static"
        >
          <ModalHeader>
            {'Törlés'}
          </ModalHeader>
          <ModalBody>
            {'Valóban törölni kívánja a kiválasztott tételt?'}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteUgyfel(this._currentDeleteId)}>
              {'OK'}
            </Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleTorles}>
              {'Mégsem'}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modalLezaras}
          toggle={this.toggleLezaras}
          className={this.props.className}
          backdrop="static"
        >
          <AvForm onValidSubmit={this.onLezaras}>
            <ModalHeader>
              {'Lezárás'}
            </ModalHeader>
            <ModalBody>
              <div className="row">
                <div className="col-md-12">
                  <Label for="date">
                    {'Lezárás dátuma:'}
                  </Label>
                  <DatePicker
                    name="date"
                    placeholder="éééé-hh-nn"
                    max={new Date()}
                    required
                    type="select"
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button className="btn-cursor-pointer" color="primary">
                {'Lezárás'}
              </Button>
              <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleLezaras}>
                {'Mégsem'}
              </Button>
            </ModalFooter>
          </AvForm>
        </Modal>
        <Modal isOpen={this.state.modalVizsgalatTorles} toggle={this.toggleVizsgalatTorles} className={this.props.className} backdrop="static">
          <ModalHeader>
            {'Törlés'}
          </ModalHeader>
          <ModalBody>
            {'Valóban törölni kívánja a kiválasztott tételt?'}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteVizsgalat(this._currentDeleteVizsgalatId)}>
              {'OK'}
            </Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleVizsgalatTorles}>
              {'Mégsem'}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalKartonNezet} toggle={this.toggleKartonNezet} className={this.props.className} backdrop="static">
          <ModalHeader>Megtekintés</ModalHeader>
          <ModalBody>
            {{
              LOAD: (<Loader />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.handleBaseViewClick()}
              />),
              LOADED: (this.renderBaseViewData())
            }[this.state.currentStatusBaseView]}
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              color="primary"
              onClick={this.toggleKartonNezet}
              className="btn-cursor-pointer"
            >
              {'Vissza'}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default KartotekrendszerList;
