import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap';
import {
  AvFeedback,
  AvForm,
  AvGroup,
  AvInput
} from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import _ from 'lodash';
import Async from 'react-select/lib/Async';
import Moment from 'moment';
import MomentTimezone from 'moment-timezone';
import momentLocalizer from 'react-widgets-moment';
import { DateTimePicker } from 'react-widgets';
import Lib from '../../commons/lib';
import DataEmptyState from '../../commons/dataemptystate';
import QueryEmptyState from '../../commons/queryempty';
import NetworkEmptyState from '../../commons/networkemptystate';
import Loader from '../../commons/loader';
import Services from './services';
import FileSaver from 'file-saver';
import { PaginationTotal } from '../../commons/controls';

momentLocalizer(Moment);

class KarbantartasAlkalomContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // ['LOAD', 'DATA', 'NETW', 'TABL', 'QUERY']
      tableStatus: 'QUERY',

      // ['LOAD', 'DATA', 'NETW']
      formStatusEllLista: 'LOAD',


      // FORM VALUES
      eloszuresPartner: '',
      eloszuresAzonosito: '',
      eloszuresCikk: '',
      eloszuresKezdete: null,
      eloszuresVege: null,

      // UTILS
      baseJson: [],
      expandedItems: [],
      viewObjEllLista: null,
      modalVIewEllLista: false,
      modalTorlesAlkalom: false,
      torlesId: null,
    };

    this.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      if (this.props.active
        && (this.state.eloszuresPartner || this.state.eloszuresAzonosito || this.state.eloszuresCikk || this.state.eloszuresKezdete || this.state.eloszuresVege)) {
        this.getKarbantartasAlkalomListData();
      }
    }
  }

  onPrintClick = (id) => {
    Services.printFromAlkalom(id, (err, res) => {
      if (!err) {
        this.props.globals.addSuccess('Nyomtatás indítása...');
        let fileURL = URL.createObjectURL(new Blob([res[0]], { type: 'application/pdf' }), `Alkalom ellenőrzési lista ${MomentTimezone.tz(new Date(), this.tz).format('YYYY-MM-DD HH:mm:ss')}.pdf`);
        window.open(fileURL);
      } else {
        this.props.globals.addError('Hiba történt a nyomtatás során!');
      }
    });
  }

  toggleViewModalEllLista = () => {
    this.setState(prevState => ({ modalVIewEllLista: !prevState.modalVIewEllLista }));
  }

  toggleTorlesAlkalom = () => {
    this.setState(prevState => ({ modalTorlesAlkalom: !prevState.modalTorlesAlkalom }));
  }

  onRowSelect = (row) => {
    this.handleExpandEllListak(row.id);
  }

  deleteKarbantartasAlkalom = () => {
    Services.deleteKarbantartasAlkalom(this.state.torlesId, (err) => {
      if (!err) {
        this.toggleTorlesAlkalom();
        this.getKarbantartasAlkalomListData();
        this.props.globals.addSuccess('Karbantartás alkalom törölve!');
      }
    });
  }

  loadCikkChange = (input, callback) => {
    if (input.length !== 0) {
      Services.searchCikk(input, true, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            let newItem = item;
            newItem.nev = newItem.cikknev + ' (' + newItem.cikkszam + ') ';
            newArray.push(newItem);
          });
          callback(newArray);
        }
      });
    }
  }

  handleChangeCikk = (value) => {
    if (value && value !== '') {
      this.setState({
        eloszuresCikk: value,
      });
    } else {
      this.setState({
        eloszuresCikk: '',
      });
    }
  }

  loadPartnerChange = (input, callback) => {
    if (input.length !== 0) {
      Services.searchPartnerAlkalom(input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            let newItem = item;
            newItem.nev = newItem.nev + ' (' + newItem.azon + ') ';
            newArray.push(newItem);
          });
          callback(newArray);
        }
      });
    }
  }

  handleChangePartner = (value) => {
    if (value && value !== '') {
      this.setState({
        eloszuresPartner: value,
      });
    } else {
      this.setState({
        eloszuresPartner: '',
      });
    }
  }

  getKarbantartasAlkalomListData = () => {
    this.setState({
      tableStatus: 'LOAD',
    });
    Promise.all([
      this.getKarbantartasAlkalomList(),
    ])
      .then(() => {
        this.setState({
          tableStatus: 'TABL'
        });
      })
      .catch(() => {
        this.setState({
          tableStatus: 'QUERY'
        });
      });
  }

  getKarbantartasAlkalomList = () => {
    return new Promise((resolve, reject) => {
      let filterObj = {};
      filterObj.partnerid = this.state.eloszuresPartner && this.state.eloszuresPartner.id;
      filterObj.azonosito = this.emptyOrNull(this.state.eloszuresAzonosito);
      filterObj.cikkid = this.state.eloszuresCikk && this.state.eloszuresCikk.id;

      if (this.state.eloszuresKezdete || this.state.eloszuresVege) {
        let kvbObj = {};
        kvbObj.sta = this.dateToTimezone(this.state.eloszuresKezdete);
        kvbObj.end = this.dateToTimezone(this.state.eloszuresVege);
        filterObj.kvb = kvbObj;
      }

      Services.getKarbantartasAlkalomList(filterObj, (err, res) => {
        if (!err) {
          this.setState({
            baseJson: res[0],
            expandedItems: []
          }, () => {
            resolve(res[0]);
          });
        } else {
          reject(new Error('Hálózati hiba!'));
        }
      });
    });
  }

  getEllenorzesiListaData = (id) => {
    Promise.all([
      this.getEllenorzesiLista(id)
    ])
      .then(() => {
        this.setState({
          tableStatus: 'TABL'
        });
      })
      .catch(() => {
        this.setState({
          tableStatus: 'NETW'
        });
      });
  }

  getEllenorzesiLista = (id) => {
    return new Promise((resolve, reject) => {
      Services.getEllenorzesiListaList(id, (err, res) => {
        if (!err) {
          this.setState({
            expandedItems: [{ id: id, data: res[0] }],
          }, () => {
            resolve(res[0]);
          });
        } else {
          reject(new Error('Hálózati hiba!'));
        }
      });
    });
  }

  getEllListaData = (id, row) => {
    this.setState({
      formStatusEllLista: 'LOAD',
    });
    Promise.all([
      this.getEllLista(id, row),
    ])
      .then(() => {
        this.setState({
          formStatusEllLista: 'DATA'
        });
      })
      .catch(() => {
        this.setState({
          formStatusEllLista: 'NETW'
        });
      });
  }

  getEllLista = (id, row) => {
    return new Promise((resolve, reject) => {
      Services.getEllenorzesiListaSzempontList(id, (err, res) => {
        if (!err) {
          let viewObj = {
            cim: row.nev,
            szam: row.szam,
            data: res[0]
          };

          this.setState({
            viewObjEllLista: viewObj
          });
          resolve(res[0]);
        } else {
          reject(new Error('Hálózati hiba!'));
        }
      });
    });
  }

  nullToString = (data) => {
    if (!data) return '';
    return data;
  }

  emptyOrNull = (data) => {
    if (!data || data === '') return null;
    return data;
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  handleIdopontChange = (name, value) => {
    this.setState({
      [name]: value ? this.dateToTimezone(value) : null
    });
  }

  handleViewEllListaClick = (cell, row) => {
    this.setState({
      formStatusEllLista: 'LOAD',
      currentId: cell,
    }, () => {
      this.getEllListaData(cell, row);
      this.toggleViewModalEllLista();
    });
  }

  handleDeleteAlkalomClick = (cell) => {
    this.setState({
      torlesId: cell
    }, () => {
      this.toggleTorlesAlkalom();
    });
  }

  handleExpandEllListak = (id) => {
    if (this.state.expandedItems.find(x => x.id === id)) {
      this.setState({ expandedItems: [] });
    } else {
      this.getEllenorzesiListaData(id);
    }
  }

  tableIconFormatterAlkalom = (cell) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KARBANTARTAS_ALKALOM', 'LEK') && (
          <React.Fragment>
            <Button className="icon-doc table-action-icon" id={'action-print-' + cell} onClick={() => this.onPrintClick(cell)} />
            <UncontrolledTooltip style={{ float: 'top' }} placement="top" target={'action-print-' + cell} delay={0}>
              {'Nyomtatás'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KARBANTARTAS_ALKALOM', 'KARB') && (
          <React.Fragment>
            <Button className="icon-trash table-action-icon" id={'action-view-' + cell + 'torles'} onClick={() => this.handleDeleteAlkalomClick(cell)} />
            <UncontrolledTooltip style={{ float: 'top' }} placement="top" target={'action-view-' + cell + 'torles'} delay={0}>
              {'Törlés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  tableIconFormatterEllLista = (cell, row) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KARBANTARTAS_ALKALOM', 'LEK') && (
          <React.Fragment>
            <Button className="icon-eye table-action-icon" id={'action-view-' + cell + 'view'} onClick={() => this.handleViewEllListaClick(cell, row)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'view'} delay={50}>
              {'Megtekintés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  idopontFormatter = (cell) => {
    return this.timezoneToDate(cell);
  }

  dateToTimezone = (date) => {
    if (!date || date === '') return null;
    return MomentTimezone.tz(date, this.tz).format('YYYY-MM-DDT00:00:00.000ZZ');
  }


  timezoneToDate = (date) => {
    if (!date || date === '') {
      return null;
    }

    return MomentTimezone.tz(date, this.tz).format('YYYY-MM-DD HH:mm');
  }

  renderTable = () => {
    const options = {
      sizePerPage: 5,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const selectRow = {
      mode: 'radio',
      onSelect: this.onRowSelect,
      style: { backgroundColor: '#c8e6c9' },
    };

    const columns = [
      {
        dataField: 'partner',
        text: 'Partner',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'fejazonosito',
        text: 'Karbantartás azonosító',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'azonosito',
        text: 'Egyedi azonosító',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'cikk',
        text: 'Tétel',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'gyakorisag',
        text: 'Gyakoriság',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
        },
        sort: false,
      },
      {
        dataField: 'idopont',
        text: 'Időpont',
        filter: textFilter({
          delay: 400,
          placeholder: 'Keresés...',
        }),
        sort: true,
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
          textAlign: 'center'
        },
        formatter: this.idopontFormatter,
        sortFunc: (a, b, order) => {
          if (order === 'asc') return a.localeCompare(b);
          return b.localeCompare(a);
        }
      },
      {
        dataField: 'elvegezve',
        text: 'Elvégezve',
        filter: textFilter({
          delay: 400,
          placeholder: 'Keresés...',
        }),
        sort: true,
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
          textAlign: 'center'
        },
        formatter: this.idopontFormatter,
        sortFunc: (a, b, order) => {
          if (order === 'asc') return a.localeCompare(b);
          return b.localeCompare(a);
        }
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        formatter: this.tableIconFormatterAlkalom
      }
    ];

    const columns2 = [
      {
        dataField: 'nev',
        text: 'Ellenőrzési lista',
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'szam',
        text: 'Szempontok száma',
        style: {
          whiteSpace: 'pre-line',
          textAlign: 'right'
        },
        sort: true
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        formatter: this.tableIconFormatterEllLista
      }
    ];

    const expandRow = {
      renderer: row => (this.state.expandedItems.find(x => x.id === row.id) && (
      <React.Fragment>
        <div className="row">
          <div className="col-md-2">
            <h5>Ellenőrzési listák</h5>
          </div>
        </div>
        { this.state.expandedItems.find(x => x.id === row.id).data && this.state.expandedItems.find(x => x.id === row.id).data.length > 0 && (
          <div className="row">
            <div className="col-md-6">
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={this.state.expandedItems.find(x => x.id === row.id).data}
                columns={columns2}
                filter={filterFactory()}
                noDataIndication="Nincs megjelenítendő adat!"
              />
            </div>
          </div>
        )}
      </React.Fragment>
      )),
      expanded: this.state.expandedItems.map(x => x.id),
      expandByColumnOnly: true,
    };

    return (
      <div className="animated fadeIn">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={this.state.baseJson}
          columns={columns}
          selectRow={selectRow}
          expandRow={expandRow}
          filter={filterFactory()}
          pagination={paginationFactory(options)}
          noDataIndication="Nincs megjelenítendő adat!"
        />
      </div>
    );
  }

  renderViewEllLista = () => {
    const {
      viewObjEllLista
    } = this.state;

    let sorok = [];
    if (viewObjEllLista && viewObjEllLista.data && viewObjEllLista.data.length > 0) {
      viewObjEllLista.data.forEach((item) => {
        sorok.push(<li>{item.nev}</li>);
      });
    }

    return (
      <div>
        <h6>{viewObjEllLista && viewObjEllLista.szam && ('Ellenőrzési szempontok (' + (viewObjEllLista.szam + ' db):'))}</h6>
        <br />
        <ul>
          {sorok}
        </ul>
      </div>
    );
  }

  renderEloszures = () => {
    const {
      eloszuresPartner,
      eloszuresAzonosito,
      eloszuresCikk,
      eloszuresKezdete,
      eloszuresVege,
    } = this.state;

    return (
      <AvForm onValidSubmit={this.getKarbantartasAlkalomListData}>
        <div className="row">
          <div style={{ marginBottom: 20 }} className="col-md-2">
            <Label for="eloszuresPartner">Partner:</Label>
            <Async
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="eloszuresPartner"
              id="eloszuresPartner"
              // placeholder="Kezdjen el gépelni..."
              placeholder="Keresés partner neve alapján..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangePartner(v)}
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadPartnerChange, 1000)}
              isLoading={false}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.nev}
              value={eloszuresPartner}
              cache={false}
              autoload={false}
            />
          </div>
          <div className="col-md-2">
            <AvGroup>
              <Label for="eloszuresAzonosito">Egyedi azonosító:</Label>
              <AvInput
                name="eloszuresAzonosito"
                id="eloszuresAzonosito"
                value={eloszuresAzonosito}
                onChange={this.handleInputChange}
                autoComplete="off"
              />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
          <div style={{ marginBottom: 20 }} className="col-md-3">
            <Label for="eloszuresCikk">Cikk:</Label>
            <Async
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="eloszuresCikk"
              id="eloszuresCikk"
              // placeholder="Kezdjen el gépelni..."
              placeholder="Keresés cikkszám vagy megnevezés alapján..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeCikk(v)}
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadCikkChange, 1000)}
              isLoading={false}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.nev}
              value={eloszuresCikk}
              cache={false}
              autoload={false}
            />
          </div>
          <div className="col-md-2">
            <Label for="eloszuresKezdete">Kezdete (-tól):</Label>
            <DateTimePicker
              containerClassName="organw-date-time"
              // format="YYYY-MM-DD HH:mm"
              format="YYYY-MM-DD"
              time={false}
              messages={{ dateButton: 'dátum', timeButton: 'idő' }}
              onChange={v => this.handleIdopontChange('eloszuresKezdete', v)}
              value={eloszuresKezdete && new Date(eloszuresKezdete)}
              name="eloszuresKezdete"
              id="eloszuresKezdete"
            />
          </div>
          <div className="col-md-2">
            <Label for="eloszuresVege">Vége (-ig):</Label>
            <DateTimePicker
              containerClassName="organw-date-time"
              // format="YYYY-MM-DD HH:mm"
              format="YYYY-MM-DD"
              time={false}
              messages={{ dateButton: 'dátum', timeButton: 'idő' }}
              onChange={v => this.handleIdopontChange('eloszuresVege', v)}
              value={eloszuresVege && new Date(eloszuresVege)}
              name="eloszuresVege"
              id="eloszuresVege"
            />
          </div>
          <div className="col-md-1">
            <Button color="primary" className="table-new-button" style={{ marginTop: '29px' }}>Keresés</Button>
          </div>
        </div>
      </AvForm>
    );
  }


  render() {
    return (
      <React.Fragment>
        {this.renderEloszures()}
        <div className="row">
          <div className="col-md-12">
            {{
              QUERY: (<QueryEmptyState hasImage />),
              LOAD: (<Loader />),
              DATA: (<DataEmptyState
                hasImage
                subtitle="Vigyél fel új tételt!"
              />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.getKarbantartasAlkalomListData()}
              />),
              TABL: (this.renderTable())
            }[this.state.tableStatus]}
          </div>
        </div>
        <Modal isOpen={this.state.modalVIewEllLista} toggle={this.toggleViewModalEllLista} backdrop="static">
          <ModalHeader>{this.state.viewObjEllLista && this.state.viewObjEllLista.cim}</ModalHeader>
          <ModalBody>
            {{
              LOAD: (<Loader />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.getEllListaData(this.state.currentId, false, true)}
              />),
              DATA: (this.renderViewEllLista())
            }[this.state.formStatusEllLista]}
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" className="btn-cursor-pointer" onClick={this.toggleViewModalEllLista}>Vissza</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalTorlesAlkalom} toggle={this.toggleTorlesAlkalom} backdrop="static">
          <ModalHeader>Karbantartás alkalom törlés</ModalHeader>
          <ModalBody>Valóban törölni kívánja a kiválasztott karbantartás alkalmat?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.deleteKarbantartasAlkalom()}>Igen</Button>
            <Button type="button" color="secondary" onClick={this.toggleTorlesAlkalom}>Mégsem</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

KarbantartasAlkalomContent.propTypes = {
  globals: PropTypes.object,
  active: PropTypes.bool
};

export default KarbantartasAlkalomContent;
