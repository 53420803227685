import Lib from '../../../commons/lib';

export default class Service {
  static telepulesUrl = window.location.origin + '/api/telepules/';

  // ORSZAG START

  static listOrszag(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'orszag/list/ui',
      args: { requestType: 'orszag-list' }
    }], fnDone);
  }

  static getOrszag(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'orszag/' + id,
      args: { requestType: 'orszag-get' },
    }], fnDone);
  }

  static addOrszag(orszag, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.telepulesUrl + 'orszag',
      args: { requestType: 'orszag-post' },
      data: orszag
    }], fnDone);
  }

  static editOrszag(orszag, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.telepulesUrl + 'orszag',
      args: { requestType: 'orszag-put' },
      data: orszag
    }], fnDone);
  }

  static deleteOrszag(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.telepulesUrl + 'orszag/' + id,
      args: { requestType: 'orszag-delete' },
    }], fnDone);
  }

  // ORSZAG END

  // TELEPULES START

  static getOrszagList(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'orszag/list',
      args: { requestType: 'telepules-orszag-list' }
    }], fnDone);
  }

  static getTelepulesList(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'telepules/list',
      args: { requestType: 'telepules-list' }
    }], fnDone);
  }

  static getTelepules(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'telepules/' + id,
      args: { requestType: 'telepules-telepules-get' },
      // params: { id }
    }], fnDone);
  }

  static addTelepules(telepulesObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.telepulesUrl + 'telepules',
      args: { requestType: 'telepules-post' },
      data: telepulesObject
    }], fnDone);
  }

  static editTelepules(telepulesObject, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.telepulesUrl + 'telepules',
      args: { requestType: 'telepules-put' },
      data: telepulesObject
    }], fnDone);
  }

  static deleteTelepules(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.telepulesUrl + 'telepules',
      args: { requestType: 'telepules-telepules-delete' },
      params: { id: id }
    }], fnDone);
  }

  // TELEPULES END
}
