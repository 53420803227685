import React from 'react';
import PropTypes from 'prop-types';
import MunkalapContent from './MunkalapContent';
import CustomTooltip from '../../components/Tooltip/CustomTooltip';
import getTooltip from './MunkalapTooltips';

const cardStyle = {
  padding: 20
};

const MunkalapBase = (props) => {
  return (
    <div>
      <div className="animated fadeIn">
        <div className="card">
          <div className="card-header">
            {'Munkalap'}
            <CustomTooltip text={getTooltip('munkalap')} id="munkalapTT" />
          </div>
          <div className="row" style={cardStyle}>
            <div className="col-md-12">
              <MunkalapContent globals={props.route.globals} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MunkalapBase.propTypes = {
  route: PropTypes.object,
  globals: PropTypes.object
};

export default MunkalapBase;
