import React from 'react';
import PropTypes from 'prop-types';


/**
 * Empty state for empty array in tables.
 *
 * @version 1.0.0
 * @author [Nagy Gergely](https://github.com/gergely-nagy)
 */
const QueryEmptyState = (props) => {
  return (
    <div className="empty-state animated fadeIn">
      {props.hasImage &&
        <img
          src={process.env.PUBLIC_URL + '/img/search.png'}
          className="img-avatar"
          alt="img-avatar"
        />
      }
      <h3>{props.title}</h3>
      <p>{props.subtitle}</p>
    </div>
  );
};

QueryEmptyState.propTypes = {
  hasImage: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

// Specifies the default values for props:
QueryEmptyState.defaultProps = {
  hasImage: false,
  title: 'Az adatok megjelenítéséhez előszűrés szükséges!',
  subtitle: 'Kérem, használja a kereső funkciót!'
};

export default QueryEmptyState;
