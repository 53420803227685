import React from 'react';
import PropTypes from 'prop-types';
import SelejtezesForm from './SelejtezesForm';

const cardStyle = {
  padding: 20
};

const SelejtezesBase = (props) => {
  return (
    <div className="animated fadeIn">
      <div className="card">
        <div className="card-header">
          {'Új selejtezés'}
        </div>
        <div className="row" style={cardStyle}>
          <div className="col-md-12">
            <SelejtezesForm globals={props.route.globals} route={props.route} />
          </div>
        </div>
      </div>
    </div>
  );
};

SelejtezesBase.propTypes = {
  route: PropTypes.object,
  globals: PropTypes.object
};

export default SelejtezesBase;