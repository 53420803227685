import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from 'reactstrap';
import {
  AvForm,
  AvGroup,
  AvFeedback,
  AvInput,
  AvField
} from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Async from 'react-select/lib/Async';
// import _ from 'lodash';
// import Select from 'react-select/lib/Async';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ListaItem from './ListaItem';
import Lib from '../../commons/lib';
import DataEmptyState from '../../commons/dataemptystate';
import NetworkEmptyState from '../../commons/networkemptystate';
import Loader from '../../commons/loader';
import Services from './services';
import { PaginationTotal } from '../../commons/controls';
import { ProgressButton } from '../../commons/ProgressButton';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'white',
  padding: grid,
  width: '100%',
});

class ListakContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // ['LOAD', 'DATA', 'NETW', 'TABL']
      tableStatus: 'LOAD',
      items: [],
      // ['LOAD', 'DATA', 'NETW']
      formStatus: 'LOAD',
      // ['FEL', 'MOD']
      formType: 'FEL',
      // FORM VALUES
      formId: null,
      formNev: '',
      formStatusz: '',
      formSzempont: '',
      // UTILS
      statuszOptions: [],
      szempontOptions: [],
      szempontOptionsRaw: [],
      currentId: null,
      baseJson: [],
      modalState: false,
      modalView: false,
      modalTorles: false,
      torlesId: null,
      saveButtonLoading: false,
      viewObj: null
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      if (this.props.active) {
        this.getListakList();
      }
    }
  }

  toggleModal = () => {
    this.setState(prevState => ({ modalState: !prevState.modalState, formSzempont: '' }));
  }

  toggleViewModal = () => {
    this.setState(prevState => ({ modalView: !prevState.modalView }));
  }

  toggleTorles = () => {
    this.setState(prevState => ({ modalTorles: !prevState.modalTorles }));
  }

  deleteLista = () => {
    Services.deleteEllenorzesiLista(this.state.torlesId, (err) => {
      if (!err) {
        this.toggleTorles();
        this.getListakList();
        this.props.globals.addSuccess('Ellenőrzési lista törölve!');
      }
    });
  }

  getListakList = () => {
    this.setState({
      tableStatus: 'LOAD'
    });
    Services.listEllenorzesiLista((err, res) => {
      if (!err) {
        this.setState({
          tableStatus: 'TABL',
          baseJson: res[0]
        });
      } else {
        this.setState({
          tableStatus: 'NETW'
        });
      }
    });
  }

  getData = (id, isFelvitel) => {
    this.setState({
      formStatus: 'LOAD',
      formId: id
    });
    Promise.all([
      this.getEllenorzesiLista(id, isFelvitel),
      this.getEnum(),
      this.getAktivSzempontList(),
    ])
      .then(() => {
        this.setState({
          formStatus: 'DATA'
        });
      })
      .catch(() => {
        this.setState({
          formStatus: 'NETW'
        });
      });
  }

  getEllenorzesiLista = (id, isFelvitel) => {
    return new Promise((resolve, reject) => {
      if (isFelvitel) {
        resolve();
      } else {
        Services.getEllenorzesiLista(id, (err, res) => {
          if (!err) {
            let tetelList = [];
            res[0].tetelek.forEach((item) => {
              let itemToPush = item;
              itemToPush.content = this.nullToString(item.nev) + ' (' + this.nullToString(item.azonosito) + ')';
              itemToPush.id = this.nullToString(item.ellenorzesiszempontentity.id);
              tetelList.push(itemToPush);
            });
            this.setState({
              items: tetelList,
              formNev: this.nullToString(res[0].nev),
              formStatusz: this.nullToString(JSON.stringify(res[0].statusz)),
            });
            resolve(res[0]);
          } else {
            reject(new Error('Hálozati hiba!'));
          }
        });
      }
    });
  }

  getViewData = (id) => {
    this.setState({
      formStatus: 'LOAD',
      formId: id
    });
    Promise.all([
      this.getView(id)
    ])
      .then(() => {
        this.setState({
          formStatus: 'DATA'
        });
      })
      .catch(() => {
        this.setState({
          formStatus: 'NETW'
        });
      });
  }

  getView = (id) => {
    return new Promise((resolve, reject) => {
      Services.viewEllenorzesiLista(id, (err, res) => {
        if (!err) {
          this.setState({
            viewObj: res[0]
          });
          resolve(res[0]);
        } else {
          reject(new Error('Hálozati hiba!'));
        }
      });
    });
  }

  nullToString = (data) => {
    if (!data) return '';
    return data;
  }

  handleSubmit = () => {
    const {
      formId,
      formNev,
      formStatusz,
      items,
      formType
    } = this.state;

    this.setState({
      saveButtonLoading: true
    });

    if (formType === 'FEL') {
      let submitObj = {};
      let submitArray = [];

      items.forEach((item, idx) => {
        submitArray.push({
          sorszam: idx + 1,
          ellenorzesiszempontentity: { id: item.id }
        });
      });

      submitObj.id = null;
      submitObj.nev = formNev;
      submitObj.statusz = JSON.parse(formStatusz).name;
      submitObj.tetelek = submitArray;

      Services.insertEllenorzesiLista(submitObj, (err) => {
        if (!err) {
          this.toggleModal();
          this.getListakList();
          this.props.globals.addSuccess('Lista felvéve!');
          this.setState({
            saveButtonLoading: false
          });
        } else {
          this.setState({
            saveButtonLoading: false
          });
        }
      });
    }

    if (formType === 'MOD') {
      let submitObj = {};
      let submitArray = [];

      items.forEach((item, idx) => {
        submitArray.push({
          sorszam: idx + 1,
          ellenorzesiszempontentity: { id: item.id }
        });
      });

      submitObj.id = formId;
      submitObj.nev = formNev;
      submitObj.statusz = JSON.parse(formStatusz).name;
      submitObj.tetelek = submitArray;

      Services.updateEllenorzesiLista(submitObj, (err) => {
        if (!err) {
          this.toggleModal();
          this.getListakList();
          this.props.globals.addSuccess('Lista módosítva!');
          this.setState({
            saveButtonLoading: false
          });
        } else {
          this.setState({
            saveButtonLoading: false
          });
        }
      });
    }
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  handleChangeSzempont = (value) => {
    if (value && value !== '') {
      this.setState({
        formSzempont: value,
      });
    } else {
      this.setState({
        formSzempont: '',
      });
    }
  }

  loadEllenorzesiSzempontChange = (input, callback) => {
    if (input.length !== 0) {
      let rawSzempontok = JSON.parse(JSON.stringify(this.state.szempontOptionsRaw));
      let filteredArray = rawSzempontok.filter(szempont => szempont.content.toLowerCase().includes(input.toLowerCase()));
      callback(filteredArray);
    }
  }

  getAktivSzempontList = () => {
    return new Promise((resolve, reject) => {
      Services.getAktivSzempontList((err, res) => {
        if (!err) {
          let array = res[0].map(e => <option key={e.id} value={JSON.stringify({ id: e.id, content: e.megnevezes + ' (' + e.azonosito + ') ' })}>{e.megnevezes + ' (' + e.azonosito + ')'}</option>);
          let arrayRaw = res[0].map(e => ({ id: e.id, content: e.megnevezes + ' (' + e.azonosito + ') ' }));
          this.setState({
            szempontOptions: array,
            szempontOptionsRaw: arrayRaw
          });
          resolve(res[0]);
        } else {
          reject(new Error('Hálozati hiba!'));
        }
      });
    });
  };

  getEnum = () => {
    return new Promise((resolve, reject) => {
      Services.getEnum((err, res) => {
        if (!err) {
          let array = res[0][1].map(e => <option key={e.name} value={JSON.stringify({ name: e.name, caption: e.caption })}>{e.caption}</option>);
          this.setState({
            statuszOptions: array
          });
          resolve(res[0]);
        } else {
          reject(new Error('Hálozati hiba!'));
        }
      });
    });
  }

  handleNewClick = () => {
    this.setState({
      formType: 'FEL',
      formStatus: 'LOAD',
      formSzempont: '',
      formNev: '',
      formStatusz: '{"name":"AKTIV","caption":"Aktív"}',
      items: []
    }, () => {
      this.getData(null, true);
      this.toggleModal();
    });
  }

  handleViewClick = (cell) => {
    this.setState({
      formStatus: 'LOAD',
      currentId: cell,
    }, () => {
      this.getViewData(cell);
      this.toggleViewModal();
    });
  }

  handleEditClick = (cell) => {
    this.setState({
      formType: 'MOD',
      formStatus: 'LOAD',
      currentId: cell,
      formNev: '',
    }, () => {
      this.getData(cell, false);
      this.toggleModal();
    });
  }

  handleDeleteClick = (cell) => {
    this.setState({
      torlesId: cell
    }, () => {
      this.toggleTorles();
    });
  }

  handleDeleteItemClick = (id) => {
    let items = JSON.parse(JSON.stringify(this.state.items));
    items.splice(items.findIndex((item) => {
      return item.id === id;
    }), 1);
    this.setState({
      items: items
    });
  }

  handleNewSzempont = () => {
    let newValue = this.state.formSzempont;
    let newArray = JSON.parse(JSON.stringify(this.state.items));
    if (newArray.some(item => item.id === newValue.id)) {
      this.props.globals.addWarning('Ez az ellenőrzési szempont már szerepel a listában!');
    } else {
      newArray.push(newValue);
    }
    this.setState({
      items: newArray
    });
  }

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.items,
      result.source.index,
      result.destination.index
    );

    this.setState({
      items,
    });
  }

  tableIconFormatter = (cell) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KARBANTARTAS_ELLENORZESILISTA', 'LEK') && (
          <React.Fragment>
            <Button className="icon-eye table-action-icon" id={'action-view-' + cell + 'megtekintes'} onClick={() => this.handleViewClick(cell)} />
            <UncontrolledTooltip style={{ float: 'top' }} placement="top" target={'action-view-' + cell + 'megtekintes'} delay={0}>
              {'Megtekintés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KARBANTARTAS_ELLENORZESILISTA', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => this.handleEditClick(cell)} />
            <UncontrolledTooltip style={{ float: 'top' }} placement="top" target={'action-view-' + cell + 'modositas'} delay={0}>
              {'Módosítás'}
            </UncontrolledTooltip>
            <Button className="icon-trash table-action-icon" id={'action-view-' + cell + 'torles'} onClick={() => this.handleDeleteClick(cell)} />
            <UncontrolledTooltip style={{ float: 'top' }} placement="top" target={'action-view-' + cell + 'torles'} delay={0}>
              {'Törlés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  tableStatusFormatter = (cell) => {
    return cell === 'AKTIV'
      ? <span className="badge badge-success">Aktív</span>
      : <span className="badge badge-default">Inaktív</span>;
  }

  tableCountFormatter = (cell) => {
    return <span>{cell + ' db'}</span>;
  }

  renderFormTitle = () => {
    switch (this.state.formType) {
      case 'FEL':
        return 'Új ellenőrzési lista létrehozása';
      case 'MOD':
        return 'Ellenőrzési lista módosítása';
      default:
        return '';
    }
  }

  renderView = () => {
    const {
      viewObj
    } = this.state;

    let sorok = [];
    if (viewObj && viewObj.tetelek && viewObj.tetelek.length > 0) {
      viewObj.tetelek.forEach((item) => {
        sorok.push(<li>{item}</li>);
      });
    }

    return (
      <div>
        <b>Státusz: </b>
        {viewObj && viewObj.statusz}
        <br />
        <br />
        <h6>{viewObj && viewObj.tetelek && viewObj.tetelek.length > 0 && ('Ellenőrzési szempontok (' + (viewObj.tetelek.length + ' db):'))}</h6>
        <br />
        <ul>
          {sorok}
        </ul>
      </div>
    );
  }

  renderForm = () => {
    const {
      formNev,
      formStatusz,
      formSzempont,
      statuszOptions,
      szempontOptions,
      szempontOptionsRaw
    } = this.state;

    return (
      <React.Fragment>
        <div>
          <AvGroup>
            <Label for="formNev">Megnevezés: *</Label>
            <AvInput
              name="formNev"
              id="formNev"
              value={formNev}
              onChange={this.handleInputChange}
              type="text"
              maxLength="100"
              required
            />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div>
          <AvField
            type="select"
            name="formStatusz"
            id="formStatusz"
            label="Státusz: *"
            errorMessage="Kötelező mező!"
            required
            value={formStatusz}
            onChange={this.handleInputChange}
          >
            {/* <option value="">Válasszon státuszt...</option> */}
            {statuszOptions}
          </AvField>
        </div>
        <div className="row">
          <div style={{ marginBottom: 20 }} className="col-md-12">
            <Label for="formSzempont">Ellenőrzési szempont: </Label>
            <Async
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="formSzempont"
              id="formSzempont"
              // placeholder="Kezdjen el gépelni..."
              placeholder="Keresés név vagy azonosító alapján..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeSzempont(v)}
              isClearable
              ignoreAccents={false}
              defaultOptions={szempontOptionsRaw}
              loadOptions={this.loadEllenorzesiSzempontChange}
              isLoading={false}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.content}
              value={formSzempont}
              cache={false}
              autoload={false}
            />
          </div>
        </div>
        {/* <div>
          <AvField
            type="select"
            name="formSzempont"
            id="formSzempont"
            label="Ellenőrzési szempont: "
            errorMessage="Kötelező mező!"
            disabled={szempontOptions.length === 0}
            value={formSzempont}
            onChange={this.handleInputChange}
          >
            <option value="">Válassz szempontot...</option>
            {szempontOptions}
          </AvField>
        </div> */}
        <Button
          color="success"
          type="button"
          style={{ marginBottom: 20 }}
          disabled={!formSzempont || formSzempont === ''}
          onClick={() => { this.handleNewSzempont(); }}
        >
          {'Ellenőrzési szempont hozzáadása'}
        </Button>
        {this.state.items && this.state.items.length > 0 && (
          <React.Fragment>
            <h5>
              {'Ellenőrzési szempontok:'}
            </h5>
            <div>
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {this.state.items.map((item, index) => (
                        <Draggable key={item.id} draggableId={item.id} index={index}>
                          {(providedd, snapshott) => (
                            <ListaItem
                              id={item.id}
                              text={item.content}
                              provided={providedd}
                              snapshot={snapshott}
                              deleteItem={this.handleDeleteItemClick}
                            />
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </React.Fragment>
        )
        }
      </React.Fragment>
    );
  }

  renderTable = () => {
    const options = {
      sizePerPage: 5,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const selectOptions = {
      AKTIV: 'Aktív',
      INAKTIV: 'Inaktív',
    };

    const columns = [
      {
        dataField: 'nev',
        text: 'Megnevezés',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'count',
        text: 'Szempontok száma',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
          textAlign: 'right'
        },
        formatter: this.tableCountFormatter,
        sort: true
      },
      {
        dataField: 'statusz.name',
        align: 'center',
        text: 'Státusz',
        filter: selectFilter({
          options: selectOptions,
          placeholder: 'Összes',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        formatter: this.tableStatusFormatter
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        formatter: this.tableIconFormatter
      }
    ];

    return (
      <div className="animated fadeIn">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={this.state.baseJson}
          columns={columns}
          filter={filterFactory()}
          pagination={paginationFactory(options)}
          noDataIndication="Nincs megjelenítendő adat!"
        />
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KARBANTARTAS_ELLENORZESILISTA', 'KARB') && (
              <Button className="pull-left table-new-button" color="primary" onClick={this.handleNewClick}>
                {'Új ellenőrzési lista'}
              </Button>
            )}
          </div>
          <div className="col-md-12">
            {{
              LOAD: (<Loader />),
              DATA: (<DataEmptyState
                hasImage
                subtitle="Vigyél fel új tételt!"
              />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.getListakList()}
              />),
              TABL: (this.renderTable())
            }[this.state.tableStatus]}
          </div>
        </div>
        <Modal isOpen={this.state.modalView} toggle={this.toggleViewModal} backdrop="static">
          <ModalHeader style={{ wordBreak: 'break-all' }}>{this.state.viewObj && this.state.viewObj.nev}</ModalHeader>
          <ModalBody>
            {{
              LOAD: (<Loader />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.getViewData(this.state.currentId)}
              />),
              DATA: (this.renderView())
            }[this.state.formStatus]}
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" className="btn-cursor-pointer" onClick={this.toggleViewModal}>Vissza</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalState} toggle={this.toggleModal} backdrop="static">
          <AvForm onValidSubmit={this.handleSubmit}>
            <ModalHeader>{this.renderFormTitle()}</ModalHeader>
            <ModalBody>
              {{
                LOAD: (<Loader />),
                NETW: (<NetworkEmptyState
                  hasImage
                  submitClick={() => {
                    if (this.state.formType === 'FEL') {
                      this.getData(null, true);
                    } else if (this.state.formType === 'MOD') {
                      this.getData(this.state.currentId, false);
                    }
                  }}
                />),
                DATA: (this.renderForm())
              }[this.state.formStatus]}
            </ModalBody>
            <ModalFooter>
              <ProgressButton
                className="btn-cursor-pointer"
                color="primary"
                loading={this.state.saveButtonLoading}
              >
                {'Mentés'}
              </ProgressButton>
              <Button type="button" color="secondary" className="btn-cursor-pointer" onClick={this.toggleModal}>Mégsem</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>Valóban törölni kívánja a kiválasztott tételt?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.deleteLista()}>Igen</Button>
            <Button type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

ListakContent.propTypes = {
  globals: PropTypes.object,
  active: PropTypes.bool
};

export default ListakContent;
