import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Collapse } from 'reactstrap';

import SzerzodesTabla from './SzerzodesTabla';
import KompenzacioBase from './KompenzacioBase';
import Lib from '../../commons/lib';

class SzerzodesLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseSzerzodes: false,
      collapseUdulesiJog: true,
      tableSzerzodesData: null,
      tableUdulesijogData: null,
    };
  }

  componentDidMount() {
    this.checkCollapse();
  }

  checkCollapse = () => {
    if (this.props.location && this.props.location.state && this.props.location.state.type === 'UDULJ') {
      this.setState({ collapseUdulesiJog: true });
    }
    if (this.props.location && this.props.location.state && this.props.location.state.type === 'SZERZODES') {
      this.setState({ collapseSzerzodes: true, collapseUdulesiJog: false });
    }
  }

  changeSzerzodesData = (id) => {
    this.setState({
      tableSzerzodesData: id,
      collapseUdulesiJog: true
    });
  }

  changeUdulesijogData = (id) => {
    this.setState({
      tableUdulesijogData: id,
      collapseSzerzodes: true
    });
  }

  collapseSzerzodes = () => {
    this.setState({
      collapseSzerzodes: !this.state.collapseSzerzodes,
    });
  }

  collapseUdulesiJog = () => {
    this.setState({
      collapseUdulesiJog: !this.state.collapseUdulesiJog
    });
  }

  render() {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'SZERZODES_SZERZODES', 'LEK') && (
          <React.Fragment>
            <Button
              className="szerzodes-collapse-buttons"
              color="success"
              onClick={this.collapseSzerzodes}
            >
              {'Szerződés'}
              {this.state.collapseSzerzodes ? '↑' : '↓' }
            </Button>
            <br />
            <Collapse isOpen={this.state.collapseSzerzodes}>
              <SzerzodesTabla
                changeData={this.changeSzerzodesData}
                globals={this.props.globals}
                location={this.props.location}
                tableData={this.state.tableUdulesijogData}
              />
            </Collapse>
          </React.Fragment>
        )}
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOG_UDJOG', 'LEK') && (
        <React.Fragment>
          <Button
            className="szerzodes-collapse-buttons"
            color="success"
            onClick={this.collapseUdulesiJog}
          >
            {'Üdülési jog'}
            {this.state.collapseUdulesiJog ? '↑' : '↓' }
          </Button>
          <Collapse isOpen={this.state.collapseUdulesiJog}>
            <KompenzacioBase
              changeData={this.changeUdulesijogData}
              globals={this.props.globals}
              route={this.props.route}
              location={this.props.location}
              tableData={this.state.tableSzerzodesData}
            />
            {/* <UdulesiJogTabla
              changeData={this.changeUdulesijogData}
              globals={this.props.globals}
              location={this.props.location}
              tableData={this.state.tableSzerzodesData}
            /> */}
          </Collapse>
        </React.Fragment>
        )}
      </div>
    );
  }
}

SzerzodesLista.propTypes = {
  globals: PropTypes.object
};

export default SzerzodesLista;
