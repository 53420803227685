const tooltips = {
  rendelesmodalcim: 'Ezen a felületen lehet kiválasztani a "Megrendelt" állapotú rendelések tételei közül azokat, amelyek "Termék" típusúak.',
  rendelesmodalhatarido: 'Az itt megadott határidő lesz érvényes az összes olyan kijelölt tételre, amelyhez külön nem ad meg dátumot a határidő oszlopban.',
  rendelesmodalhataridooszlop: 'Itt lehet megadni tételenként a határidőt.',
  rendelesmodalnetto: 'Itt kell megadni az egyes tételeknek a nettó egységárát. A további értékek automatikusan számolódni fognak a rendszer által.'
};

export default function getTooltip(name) {
  return tooltips[name];
}
