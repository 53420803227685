import React, { Component } from 'react';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import classnames from 'classnames';
import YouTube from '../../commons/youtube';
import Lib from '../../commons/lib';

import Bank from './PenzugyiteljesitesBase';
import Penzugy from './Penzugy';

const videoData = [
  { text: 'Pénzügyi teljesítés', id: 'eCXNu7FuFnk' },
  { text: 'Házi pénztár', id: 'EuWwHOSA1fM' },
];

class PenzugyiBankMenu extends Component {
  /**
  * Cegadatok konstruktor.
  * @param {object} props
  */
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);

    /**
      * @type {object}
      * @property {string} activeTab Székhely, Telephely, Fióktelep közötti váltást vezérlő változó.
      */
    this.state = {
      activeTab: undefined
    };
  }

  componentDidMount() {
    this.initTab();
  }

  initTab = () => {
    let activeTab = '1';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['BANK_PENZTAR'])) activeTab = '2';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['BANK_PUTELJ'])) activeTab = '1';

    this.setState({ activeTab: activeTab });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  render() {
    let cardStyle = {
      padding: 20
    };

    return (
      <div className="animated fadeIn">
        <div className="card">
          <div className="card-header">
            Pénzügyi teljesítés
            <YouTube data={videoData} />
          </div>
          <div className="row" style={cardStyle}>
            <div className="col-md-12">
              <Nav tabs>
                {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['BANK_PUTELJ']) && (
                  <NavItem>
                    <NavLink
                      href="#"
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => { this.toggle('1'); }}
                    >
                      Bankkivonat
                    </NavLink>
                  </NavItem>
                )}
                {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['BANK_PENZTAR']) && (
                  <NavItem>
                    <NavLink
                      href="#"
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => { this.toggle('2'); }}
                    >
                      Házi pénztár
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  {this.state.activeTab === '1' ? <Bank props={this.props} /> : null}
                </TabPane>
                <TabPane tabId="2">
                  {this.state.activeTab === '2' ? <Penzugy props={this.props} /> : null}
                </TabPane>
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PenzugyiBankMenu;
