import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  UncontrolledTooltip
} from 'reactstrap';
import {
  AvFeedback,
  AvField,
  AvForm,
  AvGroup,
  AvInput,
} from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import PropTypes from 'prop-types';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import QueryEmptyState from '../../../commons/queryempty';
import Loader from '../../../commons/loader';
import Services from './services';
import { PaginationTotal } from '../../../commons/controls';
import Lib from '../../../commons/lib';
import SlaveAvInput from '../../../commons/SlaveAvInput';
import NumberFormatter from '../../../commons/NumberFormatter';

// 0 -> Felvitel
// 1 -> módosítás
let _muvelet = 0;
let _modositoObj = null;

class BankszamlaLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ['QUERY', 'LOAD', 'DATA', 'NETW', 'TABL']
      currentStatusBaseTable: 'LOAD',
      errorFromServer: '-',
      submitObj: null,
      idFromForm: null,
      idFromServer: null,
      modalNezet: false,
      modalFelvitel: false,
      modalTorles: false,
      modalValtozas: false,
      dataLoaded: false,
      dataFailed: false,
      baseJson: [],
      aktivBanktipus: [],
      aktivPenznem: [],
      aktivCegadat: [],
      statusz: [],
      statuszOptions: [],
      filterOptions: {},
      viewObj: null,
    };

    this.updateTable = this.updateTable.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      if (this.props.active) {
        this.updateTable();
      }
    }
  }

  getAktivPenznem = () => {
    Services.listAktivPenznem(null, null, (err, res) => {
      if (!err) {
        let resOptions = res[0].map(e => <option key={e.id} value={e.id}>{e.nev}</option>);
        this.setState({ aktivPenznem: resOptions });
      }
    });
  }

  getAktivCegadat = () => {
    Services.listAktivCegadat(null, (err, res) => {
      if (!err) {
        let resOptions = res[0].map(e => <option key={e.id} value={e.id}>{e.nev + ' (' + e.tipus + ')'}</option>);
        this.setState({ aktivCegadat: resOptions });
      }
    });
  }

  getAktivBanktipus = () => {
    Services.listBankttipus((err, res) => {
      if (!err) {
        let resOptions = res[0].map(e => <option key={e.id} value={e.id}>{e.nev}</option>);
        this.setState({ aktivBanktipus: resOptions });
      }
    });
  }

  /** Objektumot bejárva az üres stringeket NULL-ra állítja */
  emptyToNull = (obj) => {
    if (!obj || typeof obj !== 'object') return;
    for (let p in obj) {
      if (typeof obj[p] === 'object' && obj[p] !== null) {
        this.emptyToNull(obj[p]);
      } else if (obj.hasOwnProperty(p)) {
        if (obj[p] === '' || obj[p] === undefined) {
          obj[p] = null;
        }
      }
    }
  }

  updateTable = () => {
    this.setState({
      currentStatusBaseTable: 'LOAD'
    });
    Services.listBankszamla((err, res) => {
      if (err) {
        this.setState({
          currentStatusBaseTable: 'NETW'
        });
      } else {
        let filters = {};
        let options = res[0].statusz.map((e) => {
          filters[e.name] = e.caption;

          return <option key={e.name} value={e.name}>{e.caption}</option>;
        });

        this.setState({
          baseJson: res[0].list,
          statusz: res[0].statusz,
          statuszOptions: options,
          filterOptions: filters,
          currentStatusBaseTable: res[0].list.length > 0 ? 'TABL' : 'DATA'
        });
      }
    });
  }

  fillModositoObj = () => {
    this.setState({ modifyDataLoaded: false, modfiyDataFailed: false });

    Services.getBankszamla(this._currentEditId, (err, res) => {
      if (err) {
        this.setState({ modifyDataLoaded: true, modfiyDataFailed: true });
      } else {
        this.setState({ modifyDataLoaded: true, modfiyDataFailed: false });
        res[0].felhasznalva ? res[0].felhasznalva = 'true' : res[0].felhasznalva = 'false';
        res[0].penznem = res[0].penznem.id;
        res[0].banktipus = res[0].banktipus.id;
        res[0].cegadat = res[0].cegadatid;

        _modositoObj = res[0];
        Services.listAktivCegadat(res[0].cegadat, (err2, res2) => {
          if (!err2) {
            let resOptions = res2[0].map(e => <option key={e.id} value={e.id}>{e.nev + ' (' + e.tipus + ')'}</option>);
            this.setState({ aktivCegadat: resOptions }, () => {
              this.toggleFelvitel();
            });
          }
        });

        Services.listAktivPenznem(res[0].penznem, true, (err3, res3) => {
          if (!err3) {
            let resOptions = res3[0].map(e => <option key={e.id} value={e.id}>{e.nev}</option>);
            this.setState({ aktivPenznem: resOptions });
          }
        });
      }
    });
  }

  getViewObj = () => {
    Services.getBankszamla(this._currentViewId, (err, res) => {
      if (!err) {
        this.setState({ viewObj: res[0] });
      }
    });
  }

  clearModositoObj = () => {
    _modositoObj = {
      id: '',
      ev: '',
      tipus: '',
      mertek: '',
      statusz: 'AKTIV'
    };
  }

  toggleNezet = () => {
    this.setState({ modalNezet: !this.state.modalNezet });
  }

  toggleFelvitel = () => {
    this.setState({ modalFelvitel: !this.state.modalFelvitel });
  }

  toggleTorles = () => {
    this.setState({ modalTorles: !this.state.modalTorles });
  }

  toggleValtozas = () => {
    this.setState({ modalValtozas: !this.state.modalValtozas });
  }

  handleEditClick = (id) => {
    _muvelet = 1;
    this._currentEditId = id;
    this.fillModositoObj();
    this.getAktivBanktipus();
  }

  handleNewClick = () => {
    _muvelet = 0;
    this.getAktivPenznem();
    this.getAktivCegadat();
    this.getAktivBanktipus();
    this.clearModositoObj();
    this.toggleFelvitel();
  }

  handleDeleteClick = (id) => {
    this._currentDeleteId = id;
    this.toggleTorles();
  }

  handleViewClick = (id) => {
    this._currentViewId = id;
    this.getViewObj();
    this.toggleNezet();
  }

  iconFormatter = (cell, row) => {
    return (
      <div>
        { Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'PENZUGYKOZOS_BANKSZAMLA', 'LEK') && (
          <React.Fragment>
            <Button className="icon-eye table-action-icon" id={'action-view-' + cell} onClick={() => this.handleViewClick(cell)} />
            <UncontrolledTooltip placement="left" target={'action-view-' + cell} delay={10}>
              {'Megtekintés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        { Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'PENZUGYKOZOS_BANKSZAMLA', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-edit-' + cell} onClick={() => this.handleEditClick(cell, row)} />
            <UncontrolledTooltip placement="left" target={'action-edit-' + cell} delay={10}>
              {'Módosítás'}
            </UncontrolledTooltip>
            <Button className="icon-trash table-action-icon" id={'action-delete-' + cell} onClick={() => this.handleDeleteClick(cell)} />
            <UncontrolledTooltip placement="left" target={'action-delete-' + cell} delay={10}>
              {'Törlés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  /**
  * Visszaadja az apartmanhoz tartozó státus jelvényeket.
  * @param {boolean} cell - Cella adatot tartalmazó szöveg.
  * @return {ReactElement} Státusz jelvényt tartalmazó react objektum.
  */
  statusFormatter = (cell) => {
    let statusz = this.state.statusz.find(x => x.name === cell);
    return statusz ? <span className={'badge badge-' + statusz.bagde}>{statusz.caption}</span> : 'N/A';
  }

  felhasznalvaFormatter = (cell) => {
    return cell ? 'Igen' : 'Nem';
  }

  addBankszamla = (event, bankszamlaObj) => {
    let bean = {};
    bean.tipus = 'BANK';
    bean.banktipusid = bankszamlaObj.banktipus;
    bean.nev = bankszamlaObj.nev;
    bean.cim = bankszamlaObj.cim;
    bean.szamlaszam = NumberFormatter.removeSpacesFromString(bankszamlaObj.szamlaszam);
    bean.iban = bankszamlaObj.iban;
    bean.swift = bankszamlaObj.swift;
    bean.cegadatid = bankszamlaObj.cegadat;
    bean.statusz = bankszamlaObj.statusz;
    bean.fokonyviszam = bankszamlaObj.fkviszam;
    bean.penznemid = bankszamlaObj.penznem;

    this.emptyToNull(bankszamlaObj);
    Services.addBankszamla(bean, (err) => {
      if (!err) {
        this.toggleFelvitel();
        this.updateTable();
        this.props.globals.addSuccess('Bankszámla felvéve!');
      }
    });
  }

  updateBankszamla = (event, bankszamlaObj) => {
    let bean = {};
    bean.id = this._currentEditId;
    bean.tipus = 'BANK';
    bean.banktipusid = bankszamlaObj.banktipus;
    bean.nev = bankszamlaObj.nev;
    bean.cim = bankszamlaObj.cim;
    bean.szamlaszam = NumberFormatter.removeSpacesFromString(bankszamlaObj.szamlaszam);
    bean.iban = bankszamlaObj.iban;
    bean.swift = bankszamlaObj.swift;
    bean.cegadatid = bankszamlaObj.cegadat;
    bean.statusz = bankszamlaObj.statusz;
    bean.fokonyviszam = bankszamlaObj.fkviszam;
    bean.penznemid = bankszamlaObj.penznem;

    bankszamlaObj.id = this._currentEditId;
    bankszamlaObj.cegadatid = bankszamlaObj.cegadat;
    bankszamlaObj.banktipus = { id: bankszamlaObj.banktipus };
    bankszamlaObj.penznem = { id: bankszamlaObj.penznem };
    bankszamlaObj.tipus = 'BANK';
    bankszamlaObj.szamlaszam = NumberFormatter.removeSpacesFromString(bankszamlaObj.szamlaszam);

    Services.existsNew(bankszamlaObj.cegadat, (err, res) => {
      if (!err) {
        if (res[0].hiba === null) {
          Services.editBankszamla(bean, (err2, res2) => {
            if (!err2) {
              this.toggleFelvitel();
              this.updateTable();
              this.props.globals.addSuccess('Bankszámla módosítva!');
            }
          });
        } else {
          this.setState({
            errorFromServer: res[0].hiba,
            idFromServer: res[0].id,
            idFromForm: bankszamlaObj.cegadatid,
            submitObj: bankszamlaObj
          }, () => {
            this.toggleValtozas();
          });
        }
      }
    });
  }

  editBankszamla = (id) => {
    let submitObj = JSON.parse(JSON.stringify(this.state.submitObj));
    let bean = {};
    bean.id = this._currentEditId;
    bean.tipus = 'BANK';
    bean.banktipusid = submitObj.banktipus && submitObj.banktipus.id;
    bean.nev = submitObj.nev;
    bean.cim = submitObj.cim;
    bean.szamlaszam = NumberFormatter.removeSpacesFromString(submitObj.szamlaszam);
    bean.iban = submitObj.iban;
    bean.swift = submitObj.swift;
    bean.cegadatid = submitObj.cegadat;
    bean.statusz = submitObj.statusz;
    bean.fokonyviszam = submitObj.fkviszam;
    bean.penznemid = submitObj.penznem && submitObj.penznem.id;
    
    submitObj.cegadatid = id;
    Services.editBankszamla(bean, (err2, res2) => {
      if (!err2) {
        this.setState({ modalValtozas: false }, () => {
          this.setState({ modalFelvitel: false }, () => {
            this.updateTable();
            this.props.globals.addSuccess('Bankszámla módosítva!');
          });
        });
      }
    });
  }

  deleteBankszamla = (id) => {
    this.toggleTorles();
    Services.deleteBankszamla(id, (err) => {
      if (!err) {
        this.updateTable();
        this.props.globals.addSuccess('Bankszámla törölve!');
      }
    });
  }

  filterType = (cell) => {
    // just return type for filtering or searching.
    return cell.caption;
  }

  statusFormatter = (cell) => {
    let statusz = this.state.statusz.find(x => x.name === cell);
    return statusz ? <span className={'badge badge-' + statusz.bagde}>{statusz.caption}</span> : 'N/A';
  }

  renderBaseTable = () => {
    const pagination = true;
    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '50', value: 50 },
        { text: '100', value: 100 }
      ],
    };

    return (
      <BootstrapTable data={this.state.baseJson} pagination={pagination} options={options}>
        <TableHeaderColumn
          dataField="nev"
          tdStyle={{ whiteSpace: 'normal' }}
          filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
        >
          {'Bank név'}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="ceg"
          tdStyle={{ whiteSpace: 'normal' }}
          filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
        >
          {'Cégnév'}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="szamlaszam"
          tdStyle={{ whiteSpace: 'normal' }}
          filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
        >
          {'Számlaszám'}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="szamlanev"
          tdStyle={{ whiteSpace: 'normal' }}
          filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
        >
          {'Bankszámla neve'}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="statusz"
          dataFormat={this.statusFormatter}
          tdStyle={{ whiteSpace: 'normal' }}
          filter={{
            type: 'SelectFilter',
            options: this.state.filterOptions,
            placeholder: 'Összes',
            condition: 'eq'
          }}
          dataAlign="center"
        >
          {'Státusz'}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="fokonyviszam"
          tdStyle={{ whiteSpace: 'normal' }}
          filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
        >
          {'Főkönyviszám'}
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="id"
          isKey
          dataFormat={this.iconFormatter}
          width="100"
        >
          {'Műveletek'}
        </TableHeaderColumn>
      </BootstrapTable>
    );
  }

  renderAdatok = () => {
    let szamlaObj = this.state.viewObj ? this.state.viewObj : {};
    return (
      <div>
        <h6>Számlaszám információ:</h6>
        <b>Számlaszám: </b>{NumberFormatter.addSpacesToString(szamlaObj.szamlaszam, NumberFormatter.BANKSZAMLA)}<br />
        <b>Számlaszám neve: </b>{szamlaObj.nev}<br />
        <b>Főkönyvi szám: </b>{szamlaObj.fkviszam}<br />
        <b>IBAN szám: </b>{szamlaObj.iban}<br />
        <b>SWIFT: </b>{szamlaObj.swift}<br />
        <b>Pénznem: </b>{szamlaObj.penznem && (szamlaObj.penznem.nev + '(' + szamlaObj.penznem.jel + ') - ' + szamlaObj.penznem.azonosito)}<br />
        <b>Státusz: </b>{szamlaObj.statusz && this.state.statusz.find(x => x.name === szamlaObj.statusz).caption}<br /><br />
        <h6>Bank információ:</h6>
        <b>Név: </b>{szamlaObj.banktipus && szamlaObj.banktipus.nev}<br />
        <b>Azonosító: </b>{szamlaObj.banktipus && szamlaObj.banktipus.azonosito}<br />
        <b>Cím: </b>{szamlaObj.banktipus && szamlaObj.banktipus.cim}<br />
        <b>Szám: </b>{szamlaObj.banktipus && szamlaObj.banktipus.szam}<br /><br />
        <h6>Cég információ:</h6>
        <b>Név: </b>{szamlaObj.cegnev}<br />
        <b>Típus: </b>{szamlaObj.cegtipus}<br />
      </div>
    );
  }

  render() {
    return (
      <div>
        { Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'PENZUGYKOZOS_BANKSZAMLA', 'KARB') && (
          <div className="row">
            <div className="col-sm-12">
              <Button className="pull-left table-new-button" color="primary" onClick={this.handleNewClick}>Új számlaszám</Button>
            </div>
          </div>
        )}
        {{
          QUERY: (<QueryEmptyState
            hasImage
          />),
          LOAD: (<Loader />),
          DATA: (<DataEmptyState
            hasImage
            subtitle="Próbálj más előszűrést vagy vigyél fel új adatokat!"
          />),
          NETW: (<NetworkEmptyState
            hasImage
            submitClick={() => this.updateTable()}
          />),
          TABL: (this.renderBaseTable())
        }[this.state.currentStatusBaseTable]}
        <Modal isOpen={this.state.modalNezet} toggle={this.toggleNezet} className={this.props.className} backdrop="static">
          <ModalHeader>Megtekintés</ModalHeader>
          <ModalBody>{this.state.modalNezet ? this.renderAdatok() : <div />}</ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" onClick={this.toggleNezet} className="btn-cursor-pointer">
              {'Vissza'}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>
            {'Valóban törölni kívánja a kiválasztott tételt?'}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteBankszamla(this._currentDeleteId)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>
        <Modal size="lg" isOpen={this.state.modalFelvitel} toggle={this.toggleFelvitel} className={this.props.className} backdrop="static">
          <AvForm onValidSubmit={_muvelet === 0 ? this.addBankszamla : this.updateBankszamla}>
            {_muvelet === 0 ? <ModalHeader>Új számlaszám rögzítése</ModalHeader> : <ModalHeader>Meglévő számlaszám módosítása</ModalHeader> }
            <ModalBody>
              <h5>Alapadatok:</h5>
              <div className="row">
                <div className="col-md-6">
                  <SlaveAvInput
                    id="szamlaszam"
                    label="Számlaszám: *"
                    value={_modositoObj && _modositoObj.szamlaszam}
                    szuroTipus={NumberFormatter.BANKSZAMLA}
                    required
                    disabled={_muvelet === 1}
                  />
                </div>
                <div className="col-md-6">
                  <AvGroup>
                    <Label for="nev">Bankszámla neve: *</Label>
                    <AvInput name="nev" id="nev" required value={_modositoObj && _modositoObj.nev} />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
                <div className="col-md-6">
                  <AvGroup>
                    <Label for="fkviszam">Főkönyvi szám: *</Label>
                    <AvInput name="fkviszam" id="fkviszam" required value={_modositoObj && _modositoObj.fkviszam} disabled={_muvelet === 1} />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
                <div className="col-md-6">
                  <AvGroup>
                    <Label for="swift">SWIFT kód: </Label>
                    <AvInput name="swift" id="swift" value={_modositoObj && _modositoObj.swift} disabled={_muvelet === 1} />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
                <div className="col-md-6">
                  <AvField type="select" required name="penznem" id="penznem" label="Pénznem: *" errorMessage="Kötelező mező!" value={_modositoObj && _modositoObj.penznem} disabled={_muvelet === 1}>
                    <option value="">Válassz pénznemet...</option>
                    {this.state.aktivPenznem}
                  </AvField>
                </div>
                <div className="col-md-6">
                  <AvField type="select" id="banktipus" name="banktipus" label="Banktípus: *" errorMessage="Kötelező mező!" required value={_modositoObj && _modositoObj.banktipus} disabled={_muvelet === 1}>
                    <option value="">Válassz típust...</option>
                    {this.state.aktivBanktipus}
                  </AvField>
                </div>
                <div className="col-md-6">
                  <AvField type="select" name="cegadat" label="Cégadat: *" errorMessage="Kötelező mező!" required value={_modositoObj && _modositoObj.cegadat}>
                    <option value="">Válassz cégadatot...</option>
                    {this.state.aktivCegadat}
                  </AvField>
                </div>
                <div className="col-md-6">
                  <AvField type="select" name="statusz" label="Státusz: *" errorMessage="Kötelező mező!" required value={_modositoObj ? _modositoObj.statusz : 'AKTIV'}>
                    {this.state.statuszOptions}
                  </AvField>
                </div>
              </div>
              <h5>Elérhetőségek:</h5>
              <div className="row">
                <div className="col-md-12">
                  <AvGroup>
                    <Label for="cim">Cím: </Label>
                    <AvInput name="cim" id="cim" value={_modositoObj && _modositoObj.cim} maxLength="50" />
                    <AvFeedback>Kötelező mező!</AvFeedback>
                  </AvGroup>
                </div>
              </div>
              <Modal isOpen={this.state.modalValtozas} toggle={this.toggleValtozas} className={this.props.className} backdrop="static">
                <ModalHeader>Cégadat módosult</ModalHeader>
                <ModalBody style={{ whiteSpace: 'pre-wrap' }}>
                  {this.state.errorFromServer}
                </ModalBody>
                <ModalFooter>
                  <Button type="button" className="btn-cursor-pointer" color="primary" onClick={() => this.editBankszamla(this.state.idFromServer)}>Igen</Button>
                  <Button type="button" className="btn-cursor-pointer" color="secondary" onClick={() => this.editBankszamla(this.state.idFromForm)}>Nem</Button>
                  <Button type="button" className="btn-cursor-pointer" color="secondary" onClick={() => this.toggleValtozas()}>Mégsem</Button>
                </ModalFooter>
              </Modal>
            </ModalBody>
            <ModalFooter>
              <Button color="primary">Mentés</Button>
              {' '}
              <Button type="button" color="secondary" onClick={this.toggleFelvitel}>Mégse</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </div>
    );
  }
}

BankszamlaLista.propTypes = {
  active: PropTypes.bool,
  globals: PropTypes.object,
  className: PropTypes.string,
};

export default BankszamlaLista;
