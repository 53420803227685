import React, { Component } from 'react';
import { Label, Button } from 'reactstrap';
import {
  AvForm,
  AvGroup,
  AvFeedback,
  AvField,
  AvInput,
} from 'availity-reactstrap-validation';
import fileSaver from 'file-saver';
import moment from 'moment';
import Services from './services';


class SzabadApartmanLekerdezesContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aktivApartmanok: [],
      ev: null,
      het: null,
      szalloda: null,
    };
  }

  componentDidMount() {
    this.getSzallodak();
  }

  handleInputChange = (e) => {
    const { target } = e;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [name]: value
    });
  };

  getSzallodak = () => {
    Services.listApartman((err, res) => {
      if (!err) {
        this.setState({
          aktivApartmanok: res[0],
        });
      }
    });
  }

  renderSzallodakOptions = () => {
    let options = this.state.aktivApartmanok.map((e) => {
      return <option key={e.id} value={e.id}>{e.nev}</option>;
    });
    return options;
  }

  onSubmit = () => {
    let submitObj = {
      ev: this.state.ev,
      het: this.state.het,
      szalloda: this.state.szalloda
    };

    Services.exportSzabadApartmanokXlsx(submitObj, (err, res) => {
      if (!err) {
        fileSaver.saveAs(new Blob([res[0]],
          {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            responseType: 'arraybuffer'
          }),
        'SzabadApartmanok_lista' + moment().format('YYYY-MM-DD') + '.xlsx');
      }
    });
  }

  renderEloszures = () => {
    return (
      <AvForm onSubmit={this.onSubmit}>
        <div className="row">
          <div className="col-md-4">
            <AvGroup>
              <Label for="ev">
                {'Év:'}
              </Label>
              <AvInput
                name="ev"
                id="ev"
                minLength="4"
                maxLength="4"
                type="number"
                onChange={this.handleInputChange}
                value={this.state.ev}
                placeholder="Év"
              />
              <AvFeedback>
                {'Csak 4 számjegyű év lehet!'}
              </AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-4">
            <AvGroup>
              <Label for="het">
                {'Hét:'}
              </Label>
              <AvInput
                name="het"
                id="het"
                minLength="1"
                maxLength="2"
                min="1"
                max="53"
                type="number"
                onChange={this.handleInputChange}
                value={this.state.het}
                placeholder="Hét"
              />
              <AvFeedback>
                {'Csak egy vagy kétjegyű szám lehet (1-53 között)!'}
              </AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-4">
            <AvGroup>
              <Label for="szalloda">
                {'Szálloda:'}
              </Label>
              <AvField
                type="select"
                name="szalloda"
                onChange={this.handleInputChange}
                value={this.state.szalloda}
              >
                <option value="">Kérjük válasszon szállodát...</option>
                {this.renderSzallodakOptions()}
              </AvField>
              <AvFeedback>
                {'Kötelező mező!'}
              </AvFeedback>
            </AvGroup>
          </div>
        </div>
        <Button type="submit" color="success">Export XLSX</Button>
      </AvForm>
    );
  }

  render() {
    return (
      <div>{this.renderEloszures()}</div>
    );
  }
}

export default SzabadApartmanLekerdezesContent;
