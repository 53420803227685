import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from 'reactstrap';
import fileSaver from 'file-saver';
import DropZone from 'react-dropzone';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { browserHistory } from 'react-router';
import Lib from '../../../commons/lib';
import QueryEmptyState from '../../../commons/queryempty';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import Loader from '../../../commons/loader';
import Services from './services';
import { PaginationTotal, FileCard } from '../../../commons/controls';
import { ProgressButton } from '../../../commons/ProgressButton';
import NumberFormatter from '../../../commons/NumberFormatter';

class CegadatContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // ['LOAD', 'DATA', 'NETW', 'TABL', 'QUERY']
      tableStatus: 'QUERY',
      // ['LOAD', 'DATA', 'NETW']
      viewStatus: 'LOAD',
      // ['LOAD', 'DATA', 'NETW']
      fileStatus: 'LOAD',
      // ['false' - összes, 'true' - csak aktív]
      lekerdezesStatus: false,
      // ['null', 'ALL', 'ACTIVE']
      lekerdezesType: null,
      saveButtonLoading: false,
      // UTILS
      files: [],
      expanded: [],
      currentId: null,
      baseJson: [],
      viewObj: null,
      fileObj: null,
      modalView: false,
      modalFile: false,
      torlesId: null,
      lekerdezesButtonLoading: false,
      lekerdezesAktivButtonLoading: false
    };
  }

  componentDidMount() {
    if (this.emptyOrNull(this.props.lek) === 'ACTIVE') {
      this.getCegadatList(true);
    }
    if (this.emptyOrNull(this.props.lek) === 'ALL') {
      this.getCegadatList(false);
    }
  }

  toggleViewModal = () => {
    this.setState(prevState => ({ modalView: !prevState.modalView }));
  }

  toggleFile = () => {
    this.setState(prevState => ({ modalFile: !prevState.modalFile }));
  }

  deleteCegadat = () => {
    Services.deleteCegadat(this.state.torlesId, (err) => {
      if (!err) {
        this.toggleTorles();
        this.getCegadatList(this.state.lekerdezesStatus);
        this.props.globals.addSuccess('Cégadat törölve!');
      }
    });
  }

  getCegadatList = (aktiv) => {
    if (aktiv) {
      this.setState({
        lekerdezesButtonLoading: true,
        lekerdezesType: 'ACTIVE',
        tableStatus: 'LOAD'
      });
    } else {
      this.setState({
        lekerdezesAktivButtonLoading: true,
        lekerdezesType: 'ALL',
        tableStatus: 'LOAD'
      });
    }

    Services.getCegadatList(aktiv, (err, res) => {
      if (!err) {
        this.setState({
          tableStatus: res[0].length > 0 ? 'TABL' : 'DATA',
          baseJson: res[0],
          expanded: []
        });
        if (aktiv) {
          this.setState({
            lekerdezesButtonLoading: false,
          });
        } else {
          this.setState({
            lekerdezesAktivButtonLoading: false,
          });
        }
      } else {
        this.setState({
          tableStatus: 'NETW'
        });
        if (aktiv) {
          this.setState({
            lekerdezesButtonLoading: false,
          });
        } else {
          this.setState({
            lekerdezesAktivButtonLoading: false,
          });
        }
      }
    });
  }

  getCegadat = (id) => {
    this.setState({
      viewStatus: 'LOAD'
    });
    Services.getCegadat(id, (err, res) => {
      if (!err) {
        if (res[0].adoszam) {
          res[0].adoszam = NumberFormatter.addHyphensToString(res[0].adoszam);
        }
        this.setState({
          viewStatus: 'DATA',
          viewObj: res[0]
        });
      } else {
        this.setState({
          viewStatus: 'NETW'
        });
      }
    });
  }

  getCegadatFile = (id) => {
    this.setState({
      fileStatus: 'LOAD'
    });
    Services.getCegadat(id, (err, res) => {
      if (!err) {
        this.setState({
          fileStatus: 'DATA',
          fileObj: res[0],
          files: res[0].objectParam
        });
      } else {
        this.setState({
          fileStatus: 'NETW'
        });
      }
    });
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  handleNewClick = () => {
    this.setState({
    }, () => {
      browserHistory.push({
        pathname: '/profil/cegadatok/felvetel',
        state: {
          lek: this.state.lekerdezesType
        }
      });
    });
  }

  handleEditClick = (id, cegadatvaltozas) => {
    this.setState({
    }, () => {
      browserHistory.push({
        pathname: '/profil/cegadatok/modositas',
        search: '?id=' + id + '&cegadatvaltozas=' + cegadatvaltozas,
        state: {
          cegadatId: id,
          lek: this.state.lekerdezesType
        }
      });
    });
  }

  handleViewClick = (cell) => {
    this.setState({
      viewStatus: 'LOAD',
      currentId: cell,
    }, () => {
      this.getCegadat(cell);
      this.toggleViewModal();
    });
  }

  handleFileClick = (cell) => {
    this.setState({
      fileStatus: 'LOAD',
      currentId: cell,
    }, () => {
      this.getCegadatFile(cell);
      this.toggleFile();
    });
  }

  handleElozmenyClick = (id) => {
    if (!this.state.expanded.includes(id)) {
      this.setState(() => ({
        expanded: [...this.state.expanded, id]
      }));
    } else {
      this.setState(() => ({
        expanded: this.state.expanded.filter(x => x !== id)
      }));
    }
  }

  iconFormatter = (cell, row) => {
    let roleLek = Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'CEGADAT_CEGADAT', 'LEK');
    let roleKarb = Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'CEGADAT_CEGADAT', 'KARB');

    return (
      <div>
        {roleLek && (
          <React.Fragment>
            <Button className="icon-eye table-action-icon" id={'action-view-' + cell + 'view'} onClick={() => this.handleViewClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'view'} delay={50}>
              {'Megtekintés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )
        }
        {roleKarb && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => this.handleEditClick(cell, false)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'modositas'} delay={50}>
              {'Módosítás'}
            </UncontrolledTooltip>
            <Button className="fa fa-file-text-o table-action-icon" id={'action-view-' + cell + 'file'} onClick={() => this.handleFileClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'file'} delay={50}>
              {'Fájl karbantartás'}
            </UncontrolledTooltip>
          </React.Fragment>
        )
        }
        {roleLek && row.elozmenyek && (
          <React.Fragment>
            <Button className="fa fa-hourglass-end table-action-icon" id={'action-view-' + cell + 'elozmeny'} onClick={() => this.handleElozmenyClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'elozmeny'} delay={50}>
              {'Előzmények'}
            </UncontrolledTooltip>
          </React.Fragment>
        )
        }
        {roleKarb && (
          <React.Fragment>
            <Button className="fa fa-sitemap table-action-icon" id={'action-view-' + cell + 'cegadatvaltozas'} onClick={() => this.handleEditClick(cell, true)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'cegadatvaltozas'} delay={50}>
              {'Cégadatváltozás'}
            </UncontrolledTooltip>
          </React.Fragment>
        )
        }
      </div>
    );
  }

  iconElozmenyFormatter = (cell) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'CEGADAT_CEGADAT', 'LEK') && (
          <React.Fragment>
            <Button className="icon-eye table-action-icon-trans" id={'action-view-' + cell + 'view'} onClick={() => this.handleViewClick(cell)} style={{ background: 'transparent' }} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'view'} delay={50}>
              {'Megtekintés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  telefonIconFormatter = (cell) => {
    let formattedData = [];
    if (Array.isArray(cell.split('\n'))) {
      cell.split('\n').forEach((row, idx) => {
        formattedData.push(
          <span key={idx.toString()}>
            <span>
              <i className={'fa fa-fw fa-' + row.substr(0, row.indexOf(' '))} aria-hidden="true" />
              {' '}
              {row.substr(row.indexOf(' ') + 1)}
            </span>
            <br />
          </span>
        );
      });
    } else {
      return '-';
    }

    return formattedData;
  }

  emptyOrNull = (data) => {
    if (!data || data === '') return null;
    return data;
  }

  setActiveLogo = (file) => {
    let newFiles = JSON.parse(JSON.stringify(this.state.files));
    newFiles.forEach((item, idx) => {
      if (item.id === file.id) {
        item.logo = true;
      } else {
        item.logo = false;
      }
    });
    this.setState({
      files: newFiles
    });
  }

  onDropObject = (acceptedfiles) => {
    acceptedfiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        let files = this.state.files || [];
        files.push({
          content: btoa(fileAsBinaryString),
          uploadDate: new Date(),
          docname: file.name,
          preview: file.preview,
          logo: false,
          mime: file.type || 'application/octet-stream',
          new: true,
          length: file.size,
          id: Lib.Browser.uuidv4()
        });
        this.setState({ files: files });
      };
      reader.readAsBinaryString(file);
    });
  }

  downloadObject = (file) => {
    if (file.preview) {
      window.open(file.preview, '_blank');
    } else {
      this.getContent(file.id);
    }
  }

  removeObject = (file) => {
    if (file.preid) {
      let files = this.state.files.filter(f => f.preid !== file.preid);
      this.setState({ files: files });
    } else {
      let files = this.state.files.filter(f => f.id !== file.id);
      this.setState({ files: files });
    }
  }

  getContent = (objid) => {
    let obj = this.state.files.find(f => f.id == objid);
    Services.downloadObject(objid, (err, res) => {
      if (!err) {
        fileSaver.saveAs(new Blob([res[0]], { type: obj.mime }), obj.docname);
      }
    });
  }

  formatBytes = (bytes) => {
    if (bytes < 1024) return bytes + ' bájt';
    else if (bytes < 1048576) return (bytes / 1024).toFixed(3) + ' KB';
    else if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + ' MB';
    return (bytes / 1073741824).toFixed(3) + ' GB';
  };

  saveFile = () => {
    this.setState({ saveButtonLoading: true });
    let saveData = {};
    saveData.id = this.emptyOrNull(this.state.fileObj.id);
    saveData.tipus = this.emptyOrNull(this.state.fileObj.tipus.name);
    let submitObjParam = JSON.parse(JSON.stringify(this.state.files));
    submitObjParam.forEach((item) => {
      if (item.new) {
        delete item.id;
      }
    });
    saveData.objectParam = submitObjParam;
    Services.editFile(saveData, (err, res) => {
      if (!err) {
        this.props.globals.addSuccess('Fájlok módosítva!');
        this.toggleFile();
        this.setState({ saveButtonLoading: false });
      } else {
        this.setState({ saveButtonLoading: false });
      }
    });
  }

  renderFile = () => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            <DropZone
              multiple
              onDrop={this.onDropObject}
              className="custom-dropzone"
              style={{ marginBottom: 20 }}
            >
              <div className="dropzone-text">
                {'Húzd ide a fájlokat vagy kattints a négyzetbe egy kép feltöltéséhez!'}
              </div>
            </DropZone>
          </div>
        </div>
        <div className="row">
          {this.state.files && this.state.files.map((item, idx) => {
            return (
              <div className="col-md-12" key={idx.toString()}>
                <FileCard
                  key={idx.toString()}
                  mime={item.mime}
                  title={item.docname}
                  length={this.formatBytes(item.length)}
                  activeLogo={item.logo}
                  onLogoClick={() => this.setActiveLogo(item)}
                  onDownloadClick={() => this.downloadObject(item)}
                  onDeleteClick={() => this.removeObject(item)}
                />
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  renderView = () => {
    const {
      viewObj
    } = this.state;

    return (
      <div>
        <h6>Cégadat információ:</h6>
        <b>Teljes név: </b>
        {viewObj && viewObj.teljesnev}
        <br />

        {viewObj && viewObj.tipus && viewObj.tipus.name !== 'S' && (
          <React.Fragment>
            <b>Hozzárendelt székhely: </b>
            {viewObj && viewObj.szekhelyentity && viewObj.szekhelyentity.teljesnev}
            <br />
          </React.Fragment>
        )
        }

        <b>Rövid név: </b>
        {viewObj && viewObj.rovidnev}
        <br />
        <b>Típus: </b>
        {viewObj && viewObj.tipus && viewObj.tipus.caption}
        <br />
        <b>Kezdete: </b>
        {viewObj && viewObj.kezdete}
        <br />
        <b>Vége: </b>
        {viewObj && viewObj.vege}
        <br />
        <b>Cím: </b>
        {viewObj && viewObj.cim}
        <br />
        <b>Telefon: </b>
        {viewObj && viewObj.telefon}
        <br />
        <b>Mobil: </b>
        {viewObj && viewObj.mobil}
        <br />
        <b>Fax: </b>
        {viewObj && viewObj.fax}
        <br />
        <b>Email: </b>
        <a href={'mailto:' + (viewObj && viewObj.email)}>{viewObj && viewObj.email}</a>
        <br />
        <b>Honlap: </b>
        <a href={viewObj && viewObj.honlap}>{viewObj && viewObj.honlap}</a>
        <br />
        <b>Adószám: </b>
        {viewObj && viewObj.adoszam}
        <br />
        <b>Közösségi adószám: </b>
        {viewObj && viewObj.kozossegiadoszam}
        <br />
        <b>Feltöltött fájlok: </b>
        {viewObj && viewObj.objectParam && Array.isArray(viewObj.objectParam) && viewObj.objectParam.length + ' db'}
        <br />
        <b>Bankszámlaszámok: </b>
        {viewObj && viewObj.bankszamlaszam && Array.isArray(viewObj.bankszamlaszam) && viewObj.bankszamlaszam.map((item) => {
          return (
            <li key={item.id} style={{ marginLeft: 20 }}>
              <b>Pénzintézet:</b>
              {item.penzintezet}
              <br />
              <b>Bankszámlaszám:</b>
              {item.bankszamlaszam}
              <br />
              <b>IBAN szám:</b>
              {item.ibanszam}
              <br />
            </li>
          );
        })}
        <br />
        <br />
        <h6>Kapcsolattartó információ:</h6>
        <b>Név: </b>
        {viewObj && viewObj.kapcsolattartonev}
        <br />
        <b>Telefon: </b>
        {viewObj && viewObj.kapcsolattartotelefon}
        <br />
        <b>Email: </b>
        <a href={'mailto:' + (viewObj && viewObj.kapcsolattartoemail)}>{viewObj && viewObj.kapcsolattartoemail}</a>
        <br />
      </div>
    );
  }

  renderTable = () => {
    const options = {
      sizePerPage: 5,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const columns = [
      {
        dataField: 'tipus',
        text: 'Típus',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'nev',
        text: 'Név',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'cim',
        text: 'Cím',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        }
      },
      {
        dataField: 'tel',
        text: 'Telefonszámok',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        formatter: this.telefonIconFormatter
      },
      {
        dataField: 'adoszam',
        text: 'Adószám',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        }
      },
      {
        dataField: 'erv',
        text: 'Érvényesség',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'mod',
        text: 'Módosult',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        formatter: this.iconFormatter
      }
    ];

    const childColumns = [
      {
        dataField: 'tipus',
        text: 'Típus',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'nev',
        text: 'Név',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'cim',
        text: 'Cím',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        }
      },
      {
        dataField: 'tel',
        text: 'Telefonszámok',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        formatter: this.telefonIconFormatter
      },
      {
        dataField: 'adoszam',
        text: 'Adószám',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        }
      },
      {
        dataField: 'erv',
        text: 'Érvényesség',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'mod',
        text: 'Módosult',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        formatter: this.iconElozmenyFormatter
      }
    ];

    const expandRow = {
      renderer: row => (row.elozmenyek ?
        (
          <React.Fragment>
            <h5>Előzmények:</h5>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={row.elozmenyek}
              columns={childColumns}
              filter={filterFactory()}
              pagination={paginationFactory(options)}
              noDataIndication="Nincs megjelenítendő adat!"
            />
          </React.Fragment>
        )
        : null
      ),
      expanded: this.state.expanded,
      expandByColumnOnly: true
    };

    return (
      <div className="animated fadeIn">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={this.state.baseJson}
          columns={columns}
          filter={filterFactory()}
          pagination={paginationFactory(options)}
          expandRow={expandRow}
          noDataIndication="Nincs megjelenítendő adat!"
        />
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'CEGADAT_CEGADAT', 'KARB') && (
              <Button className="pull-left table-new-button" color="success" onClick={this.handleNewClick}>
                {'Új cégadat'}
              </Button>
            )}
          </div>
          <div className="col-md-12">
            <ProgressButton loading={this.state.lekerdezesAktivButtonLoading} className="pull-left table-new-button" color="primary" onClick={() => { this.setState({ lekerdezesStatus: false }, () => { this.getCegadatList(false); }); }} style={{ marginRight: 10 }}>
              {'Lekérdezés '}
              <i className="fa fa-search" aria-hidden="true" />
            </ProgressButton>
            {' '}
            <ProgressButton loading={this.state.lekerdezesButtonLoading} className="pull-left table-new-button" color="primary" onClick={() => { this.setState({ lekerdezesStatus: true }, () => { this.getCegadatList(true); }); }}>
              {'Lekérdezés (csak aktív) '}
              <i className="fa fa-search" aria-hidden="true" />
            </ProgressButton>
          </div>
          <div className="col-md-12">
            {{
              QUERY: (<QueryEmptyState hasImage title="Az adatok megjelenítéséhez lekérdezés szükséges!" subtitle="Kérem, használja a lekérdezés funkciót!" />),
              LOAD: (<Loader />),
              DATA: (<DataEmptyState
                hasImage
                subtitle="Vigyél fel új tételt!"
              />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.getCegadatList(this.state.lekerdezesStatus)}
              />),
              TABL: (this.renderTable())
            }[this.state.tableStatus]}
          </div>
        </div>
        <Modal isOpen={this.state.modalView} toggle={this.toggleViewModal} backdrop="static">
          <ModalHeader>Megtekintés</ModalHeader>
          <ModalBody>
            {{
              LOAD: (<Loader />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.getCegadat(this.state.currentId)}
              />),
              DATA: (this.renderView())
            }[this.state.viewStatus]}
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" className="btn-cursor-pointer" onClick={this.toggleViewModal}>Vissza</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalFile} toggle={this.toggleFile} backdrop="static">
          <ModalHeader>Fájlkezelés</ModalHeader>
          <ModalBody>
            {{
              LOAD: (<Loader />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.getCegadatFile(this.state.currentId)}
              />),
              DATA: (this.renderFile())
            }[this.state.fileStatus]}
          </ModalBody>
          <ModalFooter>
            <ProgressButton loading={this.state.saveButtonLoading} color="primary" onClick={this.saveFile}>Mentés</ProgressButton>
            <Button type="button" color="secondary" onClick={this.toggleFile}>Mégsem</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

CegadatContent.propTypes = {
  globals: PropTypes.object
};

export default CegadatContent;
