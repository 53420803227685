import React from 'react';
import Breadcrumbs from 'react-breadcrumbs';
import Header from '../../components/Header';
import Sidebar from '../../components/Sidebar/Sidebar';
import Aside from '../../components/Aside';
import Footer from '../../components/Footer';


const Full = (props) => {
  return (
    <div className="app">
      <Header {...props} />
      <div className="app-body">
        <Sidebar {...props} />
        <main className="main">
          <Breadcrumbs
            wrapperElement="ol"
            itemElement="li"
            wrapperClass="breadcrumb"
            itemClass="breadcrumb-item"
            separator=""
            routes={props.routes}
            params={props.params}
          />
          <div className="container-fluid">
            {props.children}
          </div>
        </main>
        <Aside />
      </div>
      <Footer />
    </div>
  );
};

export default Full;
