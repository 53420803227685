import React from 'react';
import UzenetekContentView from './UzenetekContentView';

const cardStyle = {
  padding: 20
};

const UzenetekBaseView = (props) => {
  return (
    <div className="animated fadeIn">
      <div className="card">
        <div className="card-header">
          {'Üzenetek'}
        </div>
        <div className="row" style={cardStyle}>
          <div className="col-md-12">
            <UzenetekContentView
              globals={props.route.globals}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UzenetekBaseView;
