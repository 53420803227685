import React from 'react';
import { Button } from 'reactstrap';

const lorem = 'Nulla sem lorem, dignissim sed porttitor lacinia, ultricies nec massa. Nulla facilisi. Sed dictum sapien nec arcu pulvinar pharetra. Ut commodo molestie mauris, ac blandit tellus tempor et. Cras ultricies tristique vehicula. Suspendisse potenti. Maecenas nec tempor risus. In vitae felis purus. Curabitur vestibulum odio mauris, vitae facilisis ligula rutrum eget. Maecenas in nisl non lectus semper tincidunt ac a augue. Vestibulum et viverra turpis. Nullam sodales, eros ac pharetra euismod, enim arcu luctus diam, in maximus quam felis ac diam.';
const kartyak = false;
const Torzsadatok = () => {
  return (
    <div className="animated fadeIn">
      {kartyak === true ? (
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="card card-accent-primary">
              <div className="card-header">
                Partner
              </div>
              <div className="card-block">
                {lorem}
              </div>
              <Button style={{ margin: 10 }} color="primary">Tovább</Button>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="card card-accent-secondary">
              <div className="card-header">
                Bizonylatkezelés
              </div>
              <div className="card-block">
                {lorem}
              </div>
              <Button style={{ margin: 10 }} color="secondary">Tovább</Button>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="card card-accent-success">
              <div className="card-header">
                Apartman
              </div>
              <div className="card-block">
                {lorem}
              </div>
              <Button style={{ margin: 10 }} color="success">Tovább</Button>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-3">
            <div className="card card-accent-info">
              <div className="card-header">
                Pénzügyi adatok
              </div>
              <div className="card-block">
                {lorem}
              </div>
              <Button style={{ margin: 10 }} color="info">Tovább</Button>
            </div>
          </div>
        </div>
      ) : (
        <div className="card card-accent-info">
          <div className="card-header">
            <h5>Információ</h5>
          </div>
          <div className="card-block">
            Kérem válasszon a bal oldali menüből!
          </div>
        </div>
      )}
    </div>
  );
};

export default Torzsadatok;
