import React from 'react';
import {
  Button,
} from 'reactstrap';
import PropTypes from 'prop-types';

const propTypes = {
  active: PropTypes.bool.isRequired,
  trueLabel: PropTypes.string.isRequired,
  trueToggle: PropTypes.func.isRequired,
  trueWidth: PropTypes.number,
  falseLabel: PropTypes.string.isRequired,
  falseToggle: PropTypes.func.isRequired,
  falseWidth: PropTypes.number,
  disabled: PropTypes.bool
};

const defaultProps = {
  trueWidth: 105,
  falseWidth: 105
};

/**
 * Kettő állapotú gomb
 *
 * @version 1.0.0
 * @author [Nagy Gergely](https://github.com/gergely-nagy)
 */
const SwitchButton = (props) => {
  const {
    active,
    trueLabel,
    trueToggle,
    trueWidth,
    falseLabel,
    falseToggle,
    falseWidth,
    disabled,
    ...attributes
  } = props;

  return (
    <div {...attributes}>
      <Button color={active ? 'primary' : 'secondary'} style={{ width: trueWidth }} type="button" onClick={trueToggle} disabled={disabled}>
        {trueLabel}
      </Button>
      <Button color={active ? 'secondary' : 'primary'} style={{ width: falseWidth }} type="button" onClick={falseToggle} disabled={disabled}>
        {falseLabel}
      </Button>
    </div>
  );
};

SwitchButton.propTypes = propTypes;
SwitchButton.defaultProps = defaultProps;

export default SwitchButton;
