import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import classnames from 'classnames';
import ApartmanLista from './ApartmanLista';
import EpuletLista from './EpuletLista';
import SzallodaLista from './SzallodaLista';
import YouTube from '../../../commons/youtube';
import Lib from '../../../commons/lib';

const videoData = [
  { text: 'Apartman, Épület, Szálloda', id: 'ZmRF81QvRiM' }
];

class ApartmanMenuBase extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1'
    };
  }

  componentDidMount() {
    this.initTab();
  }

  initTab = () => {
    let activeTab = '1';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['APARTMAN_SZALLODA'], true)) activeTab = '3';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['APARTMAN_EPULET'], true)) activeTab = '2';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['APARTMAN_APARTMAN'], true)) activeTab = '1';
    this.setState({ activeTab: activeTab });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    let cardStyle = {
      padding: 10
    };
    return (
      <div>
        <div className="animated fadeIn">
          <div className="card">
            <div className="card-header">
              Apartman
              <YouTube data={videoData} />
            </div>
            <div className="row" style={cardStyle}>
              <div className="col-md-12">
                <Nav tabs>
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['APARTMAN_APARTMAN'], true) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => { this.toggle('1'); this.child1.updateTable(); }}
                      >
                        Apartman
                      </NavLink>
                    </NavItem>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['APARTMAN_EPULET'], true) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => { this.toggle('2'); this.child2.updateTable(); }}
                      >
                        Épület
                      </NavLink>
                    </NavItem>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['APARTMAN_SZALLODA'], true) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '3' })}
                        onClick={() => { this.toggle('3'); this.child3.updateTable(); }}
                      >
                        Szálloda
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['APARTMAN_APARTMAN'], true) && (
                    <TabPane tabId="1">
                      <ApartmanLista globals={this.props.route.globals} ref={(instance) => { this.child1 = instance; }} />
                    </TabPane>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['APARTMAN_EPULET'], true) && (
                    <TabPane tabId="2">
                      <EpuletLista globals={this.props.route.globals} ref={(instance) => { this.child2 = instance; }} />
                    </TabPane>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['APARTMAN_SZALLODA'], true) && (
                    <TabPane tabId="3">
                      <SzallodaLista globals={this.props.route.globals} ref={(instance) => { this.child3 = instance; }} />
                    </TabPane>
                  )}
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ApartmanMenuBase.propTypes = {
  route: PropTypes.object,
  globals: PropTypes.object
};

export default ApartmanMenuBase;
