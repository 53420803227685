import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const cardStyle = {
  padding: 20
};

const OfflineSelect = (props) => {
  return (
    <Select
      styles={{
        control: (base, state) => ({
          ...base,
          borderColor: 'lightgray', // border style on hover
          boxShadow: 'none', // no box-shadow
          borderRadius: '0'
        }),
      }}
      isFocused
      name="formIranyitoSzam"
      className="formIranyitoSzam"
      id="offlineselect"
      placeholder="Kezdjen el gépelni..."
      noOptionsMessage={() => 'Nincs keresési eredmény'}
      options={props.options}
      isDisabled={props.isDisabled}
      isMulti
      required
      getOptionLabel={(option) => {
        return option.szemelynev === '' ? option.username : (option.szemelynev + ' (' + option.username + ')');
      }}
      value={props.value}
      onChange={props.onChange}
      getOptionValue={option => option.id}
      //value={this.state.formFeladatUsers}
      //onChange={v => this.setState({ formFeladatUsers: v })}

    />
  );
};

OfflineSelect.propTypes = {
  value: PropTypes.any,
  options: PropTypes.array
};

export default OfflineSelect;
