import React, { Component } from 'react';
import _ from 'lodash';
import {
  Button,
  Label,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input
} from 'reactstrap';
import Select from 'react-select/lib/Async';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import MomentTimezone from 'moment-timezone';
import { ProgressButton } from '../../commons/ProgressButton';
import DataEmptyState from '../../commons/dataemptystate';
import QueryEmptyState from '../../commons/queryempty';
import NetworkEmptyState from '../../commons/networkemptystate';
import Loader from '../../commons/loader';
import Services from './services';
import NumberFormatter from '../../commons/NumberFormatter';

class EvesdijBaseTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ['QUERY', 'LOAD', 'DATA', 'NETW', 'TABL']
      currentStatusBaseTable: 'QUERY',
      szamlaModalStatus: 'LOAD',
      szallodaHasValue: false,
      aktivEvek: null,
      aktivSzallodak: null,
      aktivEpuletek: null,
      aktivRCIhetek: null,
      eloszuresSzallodaId: null,
      eloszuresRCIhet: null,
      eloszuresUdulesiJog: null,
      eloszuresSzamlakibocsato: null,
      baseJson: [],
      naplo: [],
      modalSzamla: false,
      modalMegerosites: false,
      formMegjegyzes: '',
      formSzlafelfugg: ''
    };

    this.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  componentDidMount() {
    this.getAktivEvek();
    this.getAktivSzallodak();
    // this.getAktivEpuletek();
    this.genWeeks();
  }

  toggleSzamlaModal = () => {
    this.setState(prevState => ({ modalSzamla: !prevState.modalSzamla }));
  }

  toggleMegerositesModal = () => {
    this.setState(prevState => ({ modalMegerosites: !prevState.modalMegerosites }));
  }

  getSzamlafelfuggesztes = (evesdijId) => {
    this.setState({ szamlaModalStatus: 'LOAD', modalSzamla: true, currentFelfuggesztesId: evesdijId });
    Services.getSzamlafelfuggesztes(evesdijId, (err, res) => {
      if (!err) {
        let naplo = [];
        let id = 1;
        res[0].naploBeans.forEach((naploItem) => {
          naploItem.id = id;
          naploItem.formattedDate = MomentTimezone.tz(naploItem.rogzidopont, this.tz).format('LLLL');
          naplo.push(naploItem);
          id = id + 1;
        });
        this.setState({
          szamlaModalStatus: 'LOADED',
          formSzlafelfugg: res[0].szlafelfugg ? 'true' : 'false',
          naplo,
          formMegjegyzes: ''
        });
        // let aktivSzallodak = res[0].map(e => <option key={e.id} value={e.id}>{e.szallodanev}</option>);
        // this.setState({ aktivSzallodak: aktivSzallodak });
      } else {
        this.setState({ szamlaModalStatus: 'NETW' });
      }
    });
  }

  getAktivEvek = () => {
    Services.getEvesdijEv((err, res) => {
      if (!err) {
        let aktivEvek = res[0].map(e => <option key={e} value={e}>{e}</option>);
        this.setState({ aktivEvek: aktivEvek });
      }
    });
  }

  getAktivSzallodak = () => {
    Services.listUdulesijogSzallodak((err, res) => {
      if (!err) {
        let aktivSzallodak = res[0].map(e => <option key={e.id} value={e.id}>{e.szallodanev}</option>);
        this.setState({ aktivSzallodak: aktivSzallodak });
      }
    });
  }

  getAktivEpuletek = (id) => {
    Services.listEvesdijEpulet(id, (err, res) => {
      if (!err) {
        let aktivEpuletek = res[0].map(e => <option key={e.id} value={e.id}>{e.nev}</option>);
        this.setState({ aktivEpuletek: aktivEpuletek });
      }
    });
  }

  genWeeks = () => {
    let weeks = [];
    for (let i = 1; i < 53; i += 1) {
      weeks.push(<option key={i.toString()} value={i}>{i.toString() + '. hét'}</option>);
    }
    this.setState({ aktivRCIhetek: weeks });
  }

  evChange = (e) => {
    this.setState({
      eloszuresEv: e.target.value,
    });
  }

  szallodaChange = (e) => {
    if (e.target.value === '') {
      this.setState({
        szallodaHasValue: false,
        eloszuresSzallodaId: '',
        eloszuresEpuletId: ''
      });
    } else {
      this.setState({
        eloszuresSzallodaId: e.target.value,
        szallodaHasValue: true,
        eloszuresEpuletId: ''
      }, () => {
        this.getAktivEpuletek(this.state.eloszuresSzallodaId);
      });
    }
  }

  epuletChange = (e) => {
    this.setState({
      eloszuresEpuletId: e.target.value,
    });
  }

  rcihetChange = (e) => {
    this.setState({
      eloszuresRCIhet: e.target.value,
    });
  }

  handleChangeUdulesiJog = (value) => {
    if (value && value.length !== 0) {
      this.setState({ eloszuresUdulesiJog: value });
    } else {
      this.setState({ eloszuresUdulesiJog: '' });
    }
  }

  loadUdulesiJogChange = (input, callback) => {
    if (input.length !== 0) {
      Services.getEvesdijUdulesijog(input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            let newItem = item;
            newItem.nev += ' (' + newItem.cim + ') ';
            if (newItem.adoszam) {
              newItem.nev += '[' + NumberFormatter.addHyphensToString(newItem.adoszam) + '] ';
            }
            newArray.push(newItem);
          });
          callback(newArray);
        }
      });
    }
  }

  handleChangeSzamlakibocsato = (value) => {
    if (value && value.length !== 0) {
      this.setState({ eloszuresSzamlakibocsato: value });
    } else {
      this.setState({ eloszuresSzamlakibocsato: '' });
    }
  }

  loadSzamlakibocsatoChange = (input, callback) => {
    if (input.length !== 0) {
      Services.listSzallitoVevo('EVESDIJ_SZAMLAKIBOCSATO_ELO', input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            let newItem = item;
            newItem.nev += ' (' + newItem.cim + ') ';
            if (newItem.adoszam) {
              newItem.nev += '[' + NumberFormatter.addHyphensToString(newItem.adoszam) + '] ';
            }
            newArray.push(newItem);
          });
          callback(newArray);
        }
      });
    }
  }

  updateBaseTable = () => {
    let ev = this.state.eloszuresEv === '' ? null : this.state.eloszuresEv;
    let szallodaId = this.state.eloszuresSzallodaId === '' ? null : this.state.eloszuresSzallodaId;
    let epuletId = this.state.eloszuresEpuletId === '' ? null : this.state.eloszuresEpuletId;
    let rcihet = this.state.eloszuresRCIhet === '' ? null : this.state.eloszuresRCIhet;
    let udulesijogId = this.state.eloszuresUdulesiJog && this.state.eloszuresUdulesiJog.id && this.state.eloszuresUdulesiJog.id;
    let szamlakibocsatoId = this.state.eloszuresSzamlakibocsato && this.state.eloszuresSzamlakibocsato.id && this.state.eloszuresSzamlakibocsato.id;
    this.setState({
      currentStatusBaseTable: 'LOAD'
    });
    Services.getEvesdijList(ev, szallodaId, epuletId, udulesijogId, rcihet, szamlakibocsatoId, (err, res) => {
      if (err) {
        this.setState({
          currentStatusBaseTable: 'NETW'
        });
      } else {
        let newState = res[0];
        newState.forEach((item) => {
          switch (item.statusz.name) {
            case 'AKTIV':
              item.statusz = 0;
              break;
            case 'LEZART':
              item.statusz = 1;
              break;
            default:
              item.statusz = -1;
              break;
          }
        });

        newState.forEach((item) => {
          switch (item.gyakorisag.name) {
            case 'PARATLAN':
              item.gyakorisag = 0;
              break;
            case 'PAROS':
              item.gyakorisag = 1;
              break;
            default:
              item.gyakorisag = 2;
              break;
          }
        });

        newState.forEach((item) => {
          switch (item.osztott) {
            case true:
              item.osztott = 0;
              break;
            case false:
              item.osztott = 1;
              break;
            default:
              item.osztott = 2;
              break;
          }
        });

        newState.forEach((item) => {
          switch (item.szlafelfugg) {
            case true:
              item.szlafelfugg = 0;
              break;
            case false:
              item.szlafelfugg = 1;
              break;
            default:
              item.szlafelfugg = 2;
              break;
          }
        });

        this.setState({
          baseJson: newState,
          currentStatusBaseTable: res[0].length > 0 ? 'TABL' : 'DATA'
        });
      }
    });
  }

  renderEloSzures = () => {
    return (
      <AvForm style={{ marginBottom: 20 }}>
        <div className="row">
          <div className="col-md-2">
            <AvField
              type="select"
              name="ev"
              id="ev"
              label="Év: "
              errorMessage="Kötelező mező!"
              onChange={this.evChange}
              value={this.state.eloszuresEv}
            >
              <option value="">Válassz évet...</option>
              {this.state.aktivEvek}
            </AvField>
          </div>
          <div className="col-md-3">
            <AvField
              type="select"
              name="szallodaid"
              id="szallodaid"
              label="Szálloda: "
              errorMessage="Kötelező mező!"
              onChange={this.szallodaChange}
              value={this.state.eloszuresSzallodaId}
            >
              <option value="">Válassz szállodát...</option>
              {this.state.aktivSzallodak}
            </AvField>
          </div>
          <div className="col-md-3">
            <AvField
              type="select"
              name="epuletid"
              id="epuletid"
              label="Épület: "
              errorMessage="Kötelező mező!"
              onChange={this.epuletChange}
              value={this.state.eloszuresEpuletId}
              disabled={!this.state.szallodaHasValue}
            >
              <option value="">Válassz épületet...</option>
              {this.state.aktivEpuletek}
            </AvField>
          </div>
          <div className="col-md-2">
            <AvField
              type="select"
              name="rcihet"
              id="rcihet"
              label="RCI hét: "
              errorMessage="Kötelező mező!"
              onChange={this.rcihetChange}
              value={this.state.eloszuresRCIhet}
            >
              <option value="">Válassz hetet...</option>
              {this.state.aktivRCIhetek}
            </AvField>
          </div>
          <div className="col-md-4">
            <Label for="udulesijog">Üdülési jog:</Label>
            {/* <Select.Async
              name="udulesijog"
              id="udulesijog"
              placeholder="Kezdjen el gépelni..."
              noResultsText="Nincs keresési eredmény"
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingPlaceholder="Keresés..."
              onChange={v => this.handleChangeUdulesiJog(v)}
              clearable
              ignoreAccents={false}
              loadOptions={_.debounce((input, callback) => this.loadUdulesiJogChange(input, callback), 1000)}
              isLoading={false}
              valueKey="id"
              labelKey="azonosito"
              value={this.state.eloszuresUdulesiJog}
              cache={false}
              autoload={false}
            /> */}
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="udulesijog"
              id="udulesijog"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeUdulesiJog(v)}
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadUdulesiJogChange, 1000)}
              isLoading={false}
              getOptionLabel={option => option.azonosito}
              getOptionValue={option => option.id}
              value={this.state.eloszuresUdulesiJog}
              cache={false}
              autoload={false}
            />
          </div>
          <div className="col-md-6">
            <Label for="szamlakibocsato">Számlakibocsátó:</Label>
            {/* <Select.Async
              name="szamlakibocsato"
              id="szamlakibocsato"
              placeholder="Kezdjen el gépelni..."
              noResultsText="Nincs keresési eredmény"
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingPlaceholder="Keresés..."
              onChange={v => this.handleChangeSzamlakibocsato(v)}
              clearable
              ignoreAccents={false}
              loadOptions={_.debounce((input, callback) => this.loadSzamlakibocsatoChange(input, callback), 1000)}
              isLoading={false}
              valueKey="id"
              labelKey="nev"
              value={this.state.eloszuresSzamlakibocsato}
              cache={false}
              autoload={false}
            /> */}
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="szamlakibocsato"
              id="szamlakibocsato"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeSzamlakibocsato(v)}
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadSzamlakibocsatoChange, 1000)}
              isLoading={false}
              getOptionLabel={option => option.nev}
              getOptionValue={option => option.id}
              value={this.state.eloszuresSzamlakibocsato}
              cache={false}
              autoload={false}
            />
          </div>
          <div className="col-md-2">
            <Button
              className="margin-top-30"
              color="primary"
              onClick={() => { this.updateBaseTable(); }}
              // disabled={!this.state.szallodaHasValue}
            >
              {'Keresés'}
            </Button>
          </div>
        </div>
      </AvForm>
    );
  }

  statuszFormatter = (cell) => {
    switch (cell) {
      case 0:
        return <Badge color="success">Aktív</Badge>;
      case 1:
        return <Badge color="secondary">Lezárt</Badge>;
      default:
        return <Badge color="danger">Ismeretlen</Badge>;
    }
  }

  gyakorisagFormatter = (cell) => {
    switch (cell) {
      case 0:
        return <Badge color="success">Páratlan</Badge>;
      case 1:
        return <Badge color="secondary">Páros</Badge>;
      default:
        return <Badge color="danger">Mindkettő</Badge>;
    }
  }


  osztottFormatter = (cell) => {
    switch (cell) {
      case 0:
        return <Badge color="success">Igen</Badge>;
      case 1:
        return <Badge color="secondary">Nem</Badge>;
      default:
        return <Badge color="danger">Ismeretlen</Badge>;
    }
  }

  felfugesztesFormatter = (cell, row) => {
    switch (cell) {
      case 0:
        return <a href="#" className="badge badge-danger" onClick={() => { this.getSzamlafelfuggesztes(row.id); }}>Igen</a>;
      case 1:
        return <a href="#" className="badge badge-secondary" onClick={() => { this.getSzamlafelfuggesztes(row.id); }}>Nem</a>;
      default:
        return <a href="#" className="badge badge-danger" onClick={() => { this.getSzamlafelfuggesztes(row.id); }}>Ismeretlen</a>;
    }
  }

  csvDecode = (cell, row, items) => {
    return (cell === undefined ? '' : items[cell]);
  }

  csvNoWhiteSpace = (cell) => {
    return (cell === undefined ? '' : cell.replace(/\s/g, ' '));
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  updateSzamlafelfuggesztes = () => {
    // console.log(this.state.formFelfugesztesCheck, this.state.formEvesdij, this.state.formMegjegyzes)
    this.setState({ felfuggesztesButtonLoading: true });

    let submitObj = {
      id: this.state.currentFelfuggesztesId,
      szlafelfugg: this.state.formSzlafelfugg,
      megjegyzes: this.state.formMegjegyzes
    };

    Services.updateSzamlafelfuggesztes(submitObj, (err) => {
      if (!err) {
        this.props.globals.addSuccess('A módosítás megtörtént!');
        this.toggleSzamlaModal();
        this.toggleMegerositesModal();
        this.updateBaseTable();
      }
      this.setState({ felfuggesztesButtonLoading: false });
    });
  }

  renderSzamlaContent = () => {
    const options = this.props.globals.bootstraptableProps;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-6">
            <FormGroup>
              <Label for="formSzlafelfugg">Számlázás felfüggesztve:</Label>
              <Input type="select" name="formSzlafelfugg" id="formSzlafelfugg" value={this.state.formSzlafelfugg} onChange={this.handleInputChange}>
                <option value="">Válassz értéket...</option>
                <option value="true">Igen</option>
                <option value="false">Nem</option>
              </Input>
            </FormGroup>
          </div>
          <div className="col-md-6">
            <FormGroup>
              <Label for="formMegjegyzes">Megjegyzés:</Label>
              <Input type="text" name="formMegjegyzes" id="formMegjegyzes" maxLength={150} value={this.state.formMegjegyzes} onChange={this.handleInputChange} />
            </FormGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <h4>Előzmények:</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="animated fadeIn">
              <BootstrapTable
                data={this.state.naplo}
                options={options}
              >
                <TableHeaderColumn
                  dataField="szlafelfugg"
                  thStyle={{ height: '20px' }}
                  className="td-small-header"
                >
                  {'Számlázás módosítás jellege'}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="formattedDate"
                  thStyle={{ height: '20px' }}
                  className="td-small-header"
                >
                  {'Időpont (dátum)'}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="user"
                  thStyle={{ height: '20px' }}
                  className="td-small-header"
                >
                  {'Felhasználó'}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="megjegyzes"
                  thStyle={{ height: '20px' }}
                  tdStyle={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}
                >
                  {'Megjegyzés'}
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="id"
                  thStyle={{ height: '20px' }}
                  isKey
                  hidden
                >
                  {'Műveletek'}
                </TableHeaderColumn>
              </BootstrapTable>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderBaseTable = () => {
    const statuszok = {
      0: 'Aktív',
      1: 'Lezárt'
    };

    const gyakorisag = {
      0: 'Páratlan',
      1: 'Páros',
      2: 'Mindkettő'
    };

    const osztott = {
      0: 'Igen',
      1: 'Nem',
    };


    const options = this.props.globals.bootstraptableProps;
    return (
      <div className="animated fadeIn">
        <BootstrapTable data={this.state.baseJson} options={options} pagination exportCSV csvFileName="evesdij.csv">
          <TableHeaderColumn dataField="ev" tdStyle={{ whiteSpace: 'normal' }} width="80" csvHeader="ÉV" filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>
            {'Év'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="szalloda"
            tdStyle={{ whiteSpace: 'pre-line' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            csvHeader="SZÁLLODA"
          >
            {'Szálloda'}
          </TableHeaderColumn>
          {/* <TableHeaderColumn
            dataField="epulet"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            csvHeader="ÉPÜLET"
          >
            {'Épület'}
          </TableHeaderColumn> */}
          <TableHeaderColumn
            dataField="apartman"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            csvHeader="APARTMAN"
          >
            {'Apartman'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="azonosito"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            csvHeader="ÜDÜLÉSI JOG"
          >
            {'Üdülési jog'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="dij"
            tdStyle={{ whiteSpace: 'normal', textAlign: 'right' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            dataFormat={(cell) => { return NumberFormatter.addSpacesToString(cell, NumberFormatter.OSSZEG); }}
            csvHeader="DÍJ"
          >
            {'Díj'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="penznem"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            width="100"
            csvHeader="PÉNZNEM"
          >
            {'Pénznem'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="het"
            tdStyle={{ whiteSpace: 'normal' }}
            width="80"
            csvHeader="RCI HÉT"
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          >
            {'RCI hét'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="gyakorisag"
            width="160"
            dataAlign="center"
            dataFormat={this.gyakorisagFormatter}
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'SelectFilter', options: gyakorisag, placeholder: 'Összes' }}
            csvFormat={(cell, row, items) => this.csvDecode(cell, row, gyakorisag)}
            csvHeader="GYAKORISÁG"
          >
            {'Gyakoriság'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="osztott"
            width="160"
            dataAlign="center"
            dataFormat={this.osztottFormatter}
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'SelectFilter', options: osztott, placeholder: 'Összes' }}
            csvFormat={(cell, row, items) => this.csvDecode(cell, row, osztott)}
            csvHeader="OSZTOTT"
          >
            {'Osztott'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="szlafelfugg"
            width="160"
            dataAlign="center"
            dataFormat={this.felfugesztesFormatter}
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'SelectFilter', options: osztott, placeholder: 'Összes' }}
            csvFormat={(cell, row, items) => this.csvDecode(cell, row, osztott)}
            csvHeader="SZÁMLÁZÁS FELFÜGGESZTVE"
          >
            {'Számlázás felfüggesztve'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="statusz"
            width="160"
            dataAlign="center"
            dataFormat={this.statuszFormatter}
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'SelectFilter', options: statuszok, placeholder: 'Összes' }}
            csvFormat={(cell, row, items) => this.csvDecode(cell, row, statuszok)}
            csvHeader="STÁTUSZ"
          >
            {'Státusz'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="szamlakibocsato"
            tdStyle={{ whiteSpace: 'pre-line' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            csvFormat={cell => this.csvNoWhiteSpace(cell)}
            csvHeader="SZÁMLAKIBOCSÁTÓ"
          >
            {'Számlakibocsátó'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="id"
            tdStyle={{ whiteSpace: 'normal' }}
            isKey
            width="0"
            export={false}
          >
            {'Műveletek'}
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderEloSzures()}
        {{
          QUERY: (<QueryEmptyState
            hasImage
          />),
          LOAD: (<Loader />),
          DATA: (<DataEmptyState
            hasImage
            subtitle="Próbálj más előszűrést vagy vigyél fel új adatokat!"
          />),
          NETW: (<NetworkEmptyState
            hasImage
            submitClick={() => this.updateBaseTable()}
          />),
          TABL: (this.renderBaseTable())
        }[this.state.currentStatusBaseTable]}
        <Modal
          isOpen={this.state.modalSzamla}
          toggle={this.toggleSzamlaModal}
          backdrop="static"
          size="lg"
        >
          <ModalHeader>
            {'Számlázás felfüggesztése'}
          </ModalHeader>
          <ModalBody>
            {{
              LOAD: (<Loader />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => {}}
              />),
              LOADED: (this.renderSzamlaContent())
            }[this.state.szamlaModalStatus]}
          </ModalBody>
          <ModalFooter>
            <ProgressButton className="btn-cursor-pointer" color="primary" onClick={() => this.toggleMegerositesModal()}>Mentés</ProgressButton>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleSzamlaModal}>
              {'Mégse'}
            </Button>
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modalMegerosites}
          toggle={this.toggleMegerositesModal}
          backdrop="static"
          size="sm"
        >
          <ModalHeader>
            {'Számlázás felfüggesztés módosításának megerősítése'}
          </ModalHeader>
          <ModalBody>
            Valóban végrehajtja a módosítást a számlázás felfüggesztése kapcsán?
          </ModalBody>
          <ModalFooter>
            <ProgressButton className="btn-cursor-pointer" color="primary" loading={this.state.felfuggesztesButtonLoading} onClick={() => this.updateSzamlafelfuggesztes()}>Igen</ProgressButton>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleMegerositesModal}>
              {'Mégse'}
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default EvesdijBaseTable;
