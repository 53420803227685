import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import SzerzodesLista from './SzerzodesLista';
import YouTube from '../../commons/youtube';
import Lib from '../../commons/lib';

const videoData = [
  { text: 'Szerződés', id: 'iGDmVlZtFck' },
  { text: 'Üdülési jog', id: 'T0yJA0kLlgw' }
];

class SzerzodesBase extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['SZERZODES_SZERZODES', 'UDULESIJOG_UDJOG'], true) ? '1' : '2'
    };
  }

  componentDidMount() {
  //  console.log(this.props);
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    let cardStyle = {
      padding: 20
    };

    return (
      <div className="animated fadeIn">
        <div className="card">
          <div className="card-header">
            {'Szerződés'}
            <YouTube data={videoData} />
          </div>
          <div className="row" style={cardStyle}>
            <div className="col-md-12">
              <Nav tabs>
                {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['SZERZODES_SZERZODES', 'UDULESIJOG_UDJOG'], true) && (
                  <NavItem>
                    <NavLink
                      href="#"
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => { this.toggle('1'); }}
                    >
                      {'Szerződés'}
                    </NavLink>
                  </NavItem>
                )}
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['SZERZODES_SZERZODES', 'UDULESIJOG_UDJOG'], true) && (
                  <TabPane tabId="1">
                    <SzerzodesLista
                      globals={this.props.route.globals}
                      route={this.props.route}
                      addNotification={this.addNotification}
                      location={this.props.location}
                    />
                  </TabPane>
                )}
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SzerzodesBase.propTypes = {
  route: PropTypes.object
};

export default SzerzodesBase;
