import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ListGroup,
  ListGroupItem,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  UncontrolledTooltip,
  InputGroup,
  InputGroupAddon,
  UncontrolledCollapse
} from 'reactstrap';
import {
  BootstrapTable,
  TableHeaderColumn
} from 'react-bootstrap-table';
import fileSaver from 'file-saver';
import DropZone from 'react-dropzone';
import _ from 'lodash';
import SignaturePad from 'react-signature-pad';
import { browserHistory } from 'react-router';
import Select from 'react-select';
import SelectAsync from 'react-select/lib/Async';
import Moment from 'moment';
import momentLocalizer from 'react-widgets-moment';
import MomentTimezone from 'moment-timezone';
import OfflineSelect from './OfflineSelect';
import NetworkEmptyState from '../../commons/networkemptystate';
import Loader from '../../commons/loader';
import Services from './services';
import KeszletInfo from '../../commons/KeszletInfo';
import Lib from '../../commons/lib';
import { PaginationTotal, FileCard } from '../../commons/controls';
import MunkalapCikkCard from './MunkalapCikkCard';
import CustomTooltip from '../../components/Tooltip/CustomTooltip';
import getTooltip from './MunkalapTooltips';

momentLocalizer(Moment);

class MunkalapForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      base64: '',
      munkalapId: '',
      munkalapType: '',
      munkalapTypeId: '',
      files: [],
      // FORM VALUES
      szempontValues: [],
      formMegjegyzes: '',
      formFeladatLeirasa: '',
      formMunkalapType: '',
      formMunkalapId: '',
      formMunkalapAllapot: '',
      formCegadat: '',
      formPartner: '',
      formLeiras: '',
      formVizsgalatTipus: '',
      formVizsgalatId: '',
      formVizsgalatDatum: '',
      formVizsgalatEredmenyek: null,
      formCikkId: '',
      formCikkszam: '',
      formCikkmegnevezes: '',
      formCikkMennyisegiEgyseg: '',
      formCikkAfa: '',
      formCikkKiadandoMennyiseg: '',
      formCikkNettoEladasiEgysegAr: '',
      formCikkNettoEladasiAr: '',
      formCikkKedvezmeny: '',
      formCikkBruttoEladasiEgysegAr: '',
      formCikkEladasiAr: '',
      formSzabadMennyiseg: '',
      formSpecialisID: '',
      formSpecialisHely: '',
      cikkJson: [],
      feladatData: [],
      formFeladatUsers: [],
      // KÉSZLET INFO FORM VALUES
      formKeszletCikkId: '',
      formKeszletRaktarId: '',
      formKeszletCikkszam: '',
      formKeszletCikknev: '',
      formKeszletRaktarak: [],
      // ['LOAD', 'DATA', 'NETW']
      formKeszletLoadingStatus: 'LOAD',
      // FORM SECTIONS
      vizsgalatEredmenyData: null,
      cikkkezeles: false,
      autoveglegezeskezeles: false,
      leiraskezeles: false,
      osszegkezeles: false,
      workflowkezeles: false,
      alairaskezeles: false,
      megjegyzeskezeles: false,
      specialiskezeles: false,
      // FORM UTILS
      isFeladatOptionsLoaded: false,
      isFeladatInputLoading: false,
      kedvezemenyType: 'FT',
      formCegadatValues: {
        tipus: {
          nev: null
        },
        nev: null,
        cim: null,
        telefon: null,
        email: null,
        adoszam: null,
        honlap: null,
      },
      formPartnerValues: {
        azon: null,
        nev: null,
        cim: null,
        telefon: null,
        email: null,
      },
      formVizsgalatiEredmenyOptions: [],
      formForrasokOptions: [],
      formMunkalapTypeOptions: [],
      formCegadatOptions: [],
      formPartnerOptions: [],
      formFeladatOptions: [],
      openEllenorzesiListaIds: [],
      formFeladatElvegzok: [],
      torlesId: null,
      // STATUS UTILS
      // ['LOAD', 'DATA', 'NETW']
      tipusCardStatus: 'LOAD',
      megjegyzesCardStatus: 'DATA',
      cegadatCardStatus: 'LOAD',
      partnerCardStatus: 'LOAD',
      vizsgalatiEredmenyStatus: 'LOAD',
      // MODAL UTILS
      modalVizsgalatiEredmeny: false,
      modalCikkFoglalas: false,
      modalTorles: false,
      modalBefejezes: false,
    };

    this.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  componentDidMount() {
    if (this.props.route.type === 'MOD' || this.props.route.type === 'VIEW') {
      let params = new URL(window.location.href).searchParams;
      let id = params.get('id');
      // console.log('id');
      this.setState({
        munkalapId: id,
        munkalapTypeId: -1
      }, () => {
        this.getMunkalap(id);
      });
    }
    if (this.props.route.type === 'FEL') {
      this.getMunkalapInit();
      // this.getMegjegyzes();
    }

    // this.listActiveTelephely();
  }

  componentDidUpdate(prevProps) {
    if (this.props.route.type !== prevProps.route.type) {
      if (this.props.route.type === 'MOD' || this.props.route.type === 'VIEW') {
        let params = new URL(window.location.href).searchParams;
        let id = params.get('id');
        // console.log('id');
        this.setState({
          munkalapId: id,
          munkalapTypeId: -1
        }, () => {
          this.getMunkalap(id);
        });
      }
      if (this.props.route.type === 'FEL') {
        this.getMunkalapInit();
        // this.getMegjegyzes();
      }
    }
  }

  getMunkalapTypeById = (id) => {
    let result = '';
    let options = JSON.parse(JSON.stringify(this.state.formMunkalapTypeOptions));
    options.forEach((tipus) => {
      if (tipus.id == id) {
        result = tipus.azonosito;
      }
    });
    return result;
  }

  isFormView = () => {
    return this.props.route.type === 'VIEW';
  }

  isFormEdit = () => {
    return this.props.route.type === 'MOD';
  }

  isFormCreate = () => {
    return this.props.route.type === 'FEL';
  }

  toggleKedvezmeny = () => {
    // TODO
    this.setState(prevState => ({
      kedvezemenyType: prevState.kedvezemenyType === 'FT' ? 'FT' : 'FT',
      // formCikkKedvezmeny: ''
    }));
  }

  toggleTorles = () => {
    this.setState(prevState => ({ modalTorles: !prevState.modalTorles }));
  }

  toggleVizsgalatiEredmenyModal = () => {
    this.setState(prevState => ({ modalVizsgalatiEredmeny: !prevState.modalVizsgalatiEredmeny }));
  }

  toggleBefejezesModal = () => {
    this.setState(prevState => ({ modalBefejezes: !prevState.modalBefejezes }));
  }

  toggleEllenorzesiLista = (id, alkalomid) => {
    let ellenorzesilista = JSON.parse(JSON.stringify(this.state.openEllenorzesiListaIds));

    let index = ellenorzesilista.findIndex((element) => {
      return (element.id == id && element.alkalomid == alkalomid);
    });

    if (index !== -1) {
      ellenorzesilista.splice(index, 1);
    } else {
      ellenorzesilista.push({ id, alkalomid });
    }

    this.setState({
      openEllenorzesiListaIds: ellenorzesilista
    }, () => {
      /* Services.listFeladatSelect('KARBANTARTAS', id, (err, res) => {
        if (!err) {

        }
      }); */
    });
  }

  isEllenorzesiListaOpen = (id, alkalomid) => {
    let index = this.state.openEllenorzesiListaIds.findIndex((element) => {
      return (element.id == id && element.alkalomid == alkalomid);
    });

    return index !== -1;
  }

  timezoneToDateYear = (date) => {
    if (!date || date === '') {
      return null;
    }

    return MomentTimezone.tz(date, this.tz).format('YYYY-MM-DD');
  }

  timezoneToDateTime = (date) => {
    if (!date || date === '') {
      return null;
    }

    return MomentTimezone.tz(date, this.tz).format('YYYY-MM-DD HH:mm');
  }

  getFeladatElvegzokByIds = (ids, userek) => {
    let values = [];
    if (ids) {
      ids.forEach((id) => {
        userek.forEach((user) => {
          if (id === user.id) {
            values.push(user);
          }
        });
      });
    }
    return values;
  }

  getMunkalap = (id) => {
    Services.getMunkalapInit(id, (err, res) => {
      if (!err) {
        let feladatData = [];
        let szempontValues = [];

        if (res[0].munkalap && res[0].munkalap.specialisok && res[0].munkalap.specialisok.length) {
          // console.log('fill');
          let filledArray = new Array(res[0].munkalap.specialisok.length).fill([]);
          this.setState({
            formFeladatOptions: filledArray
          });
        }

        if (res[0].munkalap && res[0].munkalap.specialisok) {
          res[0].munkalap.specialisok.forEach((specialis) => {
            if (specialis.szuloszerviz) {
              specialis.kapcsolodoSzerviz = specialis.szerviz;
              specialis.kapcsolodoId = specialis.szervizid;
              specialis.szerviz = specialis.szuloszerviz;
              specialis.szervizid = specialis.szuloid;
            }
            switch (specialis.szerviz) {
              case 'FELADATLISTA':
                if (specialis.kapcsolodoSzerviz) {
                  let rawData = specialis.specialisok[1].ellenorzesilistak;
                  for (const property in rawData) {
                    let id = property.split('|')[0];
                    let szempontok = [];
                    let alkalomid = null;

                    rawData[property].forEach((szempont) => {
                      alkalomid = szempont.alkalomid
                      if (szempont.alkalomszempontpeldanyid) {
                        let value = 0;
                        if (szempont.eredmeny === false) value = 1;
                        if (szempont.eredmeny === true) value = 2;

                        szempontok.push({
                          id: szempont.elllistatetelid,
                          elllistatetelid: szempont.elllistatetelid,
                          alkalomid: szempont.alkalomid,
                          value: value,
                          megjegyzes: szempont.megjegyzes,
                          alkalomszempontpeldanyid: szempont.alkalomszempontpeldanyid
                        });
                      }
                    });
                    szempontValues.push({
                      listaid: id,
                      alkalomid,
                      szempontok
                    });
                  }

                  feladatData.push({
                    forras: specialis.szerviz,
                    kapcsolodoId: specialis.kapcsolodoId,
                    kapcsolodoSzerviz: specialis.kapcsolodoSzerviz,
                    feladat: {
                      cikklabel: specialis.specialisok[1] && (specialis.specialisok[1].cikknev + ' (' + specialis.specialisok[1].cikkszam + ')'),
                      id: specialis.specialisok[0].id,
                      label: specialis.specialisok[0].azonosito,
                      azonosito: specialis.specialisok[0].azonosito,
                      ellenorzesilistak: specialis.specialisok[1].ellenorzesilistak,
                      publeiras: specialis.specialisok[1] && specialis.specialisok[1].leiras,
                      leiras: specialis.specialisok[0].leiras,
                      tipus: specialis.specialisok[0].tipus,
                      cim: specialis.specialisok[0].cim,
                      esedekesseg: specialis.specialisok[0].esedekesseg
                    }
                  });
                } else {
                  feladatData.push({
                    forras: specialis.szerviz,
                    feladat: {
                      id: specialis.specialisok[0].id,
                      label: specialis.specialisok[0].azonosito,
                      azonosito: specialis.specialisok[0].azonosito,
                      leiras: specialis.specialisok[0].leiras,
                      tipus: specialis.specialisok[0].tipus,
                      cim: specialis.specialisok[0].cim,
                      esedekesseg: specialis.specialisok[0].esedekesseg
                    }
                  });
                }
                break;
              case 'KARBANTARTAS':
                let rawData = specialis.specialisok[0].ellenorzesilistak;
                for (const property in rawData) {
                  let id = property.split('|')[0];
                  let szempontok = [];
                  let alkalomid = null;

                  rawData[property].forEach((szempont) => {
                    alkalomid = szempont.alkalomid
                    if (szempont.alkalomszempontpeldanyid) {
                      let value = 0;
                      if (szempont.eredmeny === false) value = 1;
                      if (szempont.eredmeny === true) value = 2;

                      szempontok.push({
                        id: szempont.elllistatetelid,
                        elllistatetelid: szempont.elllistatetelid,
                        alkalomid: szempont.alkalomid,
                        value: value,
                        megjegyzes: szempont.megjegyzes,
                        alkalomszempontpeldanyid: szempont.alkalomszempontpeldanyid
                      });
                    }
                  });
                  szempontValues.push({
                    listaid: id,
                    alkalomid,
                    szempontok
                  });
                }

                // console.log('szempontValues', szempontValues);

                feladatData.push({
                  forras: specialis.szerviz,
                  feladat: {
                    id: specialis.specialisok[0].id,
                    ellenorzesilistak: specialis.specialisok[0].ellenorzesilistak,
                    label: specialis.specialisok[0].azonosito + ' (' + this.timezoneToDateYear(specialis.specialisok[0].idodont) + ')',
                    cikklabel: specialis.specialisok[0] && (specialis.specialisok[0].cikknev + ' (' + specialis.specialisok[0].cikkszam + ')'),
                    azonosito: specialis.specialisok[0].azonosito,
                    idopont: specialis.specialisok[0].idodont && this.timezoneToDateTime(specialis.specialisok[0].idodont),
                    leiras: specialis.specialisok[0].leiras,
                    tipus: specialis.specialisok[0].tipus,
                    cim: specialis.specialisok[0].cim
                  }
                });
                break;
              case 'RENDELES':
                feladatData.push({
                  id: specialis.id,
                  forras: specialis.szerviz,
                  feladat: {
                    id: specialis.specialisok[0].id,
                    cikkek: specialis.specialisok[0].cikkek,
                    label: specialis.specialisok[0].bizonylatszam,
                    azonosito: specialis.specialisok[0].azonosito,
                    bizonylatszam: specialis.specialisok[0].bizonylatszam,
                    tipus: specialis.specialisok[0].tipus,
                    cim: specialis.specialisok[0].cim
                  }
                });
                break;
              default:
                break;
            }
          });
        }

        let cardVisibilityUtil = this.getCardVisibleDataById(res[0].munkalap.tipusid, res[0].munkalap.tipusok);

        this.setState({
          feladatData: feladatData,
          cikkJson: res[0].munkalap.cikk,
          formPartner: {
            id: res[0].munkalap.partner.id,
            nev: res[0].munkalap.partner.nev,
            search: res[0].munkalap.partner.nev + ' (' + res[0].munkalap.partner.azon + ')',
          },
          szempontValues: szempontValues,
          formMunkalapAllapot: res[0].munkalap.allapot ? res[0].munkalap.allapot.name : '',
          formMunkalapTypeOptions: res[0].munkalap.tipusok ? res[0].munkalap.tipusok : [],
          formMunkalapType: res[0].munkalap.tipusid,
          munkalapTypeId: res[0].munkalap.tipusid,
          formPartnerValues: {
            azon: res[0].munkalap.partner.azon,
            nev: res[0].munkalap.partner.nev,
            cim: res[0].munkalap.partner.cim,
            telefon: res[0].munkalap.partner.telefon,
            email: res[0].munkalap.partner.email,
          },
          formForrasokOptions: res[0].munkalap.szervizek ? res[0].munkalap.szervizek : [],
          base64: res[0].munkalap.alairas,
          formFeladatLeirasa: res[0].munkalap.feladatleiras,
          megjegyzesCardStatus: 'DATA',
          formLeiras: res[0].munkalap.leiras,
          formMegjegyzes: res[0].munkalap.megjegyzes,
          formCegadatOptions: res[0].munkalap.cegadatok,
          formCegadat: res[0].munkalap.cegadatid,
          formFeladatElvegzok: this.getFeladatElvegzokByIds(res[0].munkalap.userids, res[0].munkalap.userek),
          formPartnerOptions: res[0].munkalap.userek,
          // formCegadat: JSON.stringify(res[0].munkalap.szallitobean),
          // formCegadatValues: res[0].munkalap.szallitobean,
          files: res[0].munkalap.mellekletek ? res[0].munkalap.mellekletek : [],
          alairaskezeles: cardVisibilityUtil.alairaskezeles,
          cikkkezeles: cardVisibilityUtil.cikkkezeles,
          leiraskezeles: cardVisibilityUtil.leiraskezeles,
          megjegyzeskezeles: cardVisibilityUtil.megjegyzeskezeles,
          specialiskezeles: cardVisibilityUtil.specialiskezeles,
        }, () => {
          this.setState({
            formCegadatValues: this.getCegadatValuesByID(res[0].munkalap.cegadatid),
            cegadatCardStatus: 'DATA'
          });
          // this.getSpecialis(res[0].specialis);
          // console.log(res[0].munkalap.alairas);
          if (this.isFormEdit()) {
            let signature = this.refs.SignaturePad;
            if (res[0].munkalap.alairas) signature.fromDataURL(res[0].munkalap.alairas);
          }
          /*
          console.log('scrolling')
          document.body.scrollTop = 0;
          document.getElementById('root').scrollTo(0, 0);
          window.scrollTop(0, 0);
          */
        });
      }
    });
  }

  getSpecialis = (specialisok) => {
  }

  getCardVisibleDataById = (tipusid, tipusok) => {
    let obj = {};
    tipusok.forEach((tipus) => {
      if (tipus.id == tipusid) {
        obj = {
          alairaskezeles: tipus.alairaskezeles,
          cikkkezeles: tipus.cikkkezeles,
          leiraskezeles: tipus.leiraskezeles,
          megjegyzeskezeles: tipus.megjegyzeskezeles,
          specialiskezeles: tipus.specialiskezeles,
        };
      }
    });
    return obj;
  }

  getEllenorzesiSzempontMegjegyzesValue = (listaid, szempontid, alkalomid) => {
    let result = '';
    let list = JSON.parse(JSON.stringify(this.state.szempontValues));

    list.forEach((element) => {
      if (element.listaid == listaid && element.alkalomid == alkalomid) {
        element.szempontok.forEach((szempont) => {
          if (szempont.id == szempontid) {
            result = szempont.megjegyzes;
          }
        });
      }
    });

    return result;
  }

  handleEllenorzesiSzempontMegjegyzesChange = (e, listaid, szempontid, alkalomid) => {
    // -1 - nem létezik ilyen lista;
    // 0 - lista létezik, de szempont nem;
    // 1 - lista és szempont is létezik;
    let result = -1;
    let list = JSON.parse(JSON.stringify(this.state.szempontValues));

    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    list.forEach((element) => {
      if (element.listaid == listaid && element.alkalomid == alkalomid) {
        result = 0;
        element.szempontok.forEach((szempont) => {
          if (szempont.id == szempontid) {
            result = 1;
            szempont.megjegyzes = value;
          }
        });
      }
    });

    if (result === -1) {
      list.push({
        listaid: listaid,
        alkalomid: alkalomid,
        szempontok: [{
          id: szempontid,
          value: '',
          megjegyzes: value,
          ellistatetelid: szempontid,
          alkalomid: alkalomid
        }]
      });

      this.setState({ szempontValues: list });
    }

    if (result === 0) {
      list.forEach((element) => {
        if (element.listaid == listaid && element.alkalomid == alkalomid) {
          element.szempontok.push({
            id: szempontid,
            value: '',
            megjegyzes: value,
            ellistatetelid: szempontid,
            alkalomid: alkalomid
          });
        }
      });
      this.setState({ szempontValues: list });
    }

    if (result === 1) {
      this.setState({ szempontValues: list });
    }
  }

  getEllenorzesiSzempontCheckboxValue = (listaid, szempontid) => {
    let result = '';
    let list = JSON.parse(JSON.stringify(this.state.szempontValues));

    list.forEach((element) => {
      if (element.listaid == listaid) {
        element.szempontok.forEach((szempont) => {
          if (szempont.id == szempontid) {
            result = szempont.value;
          }
        });
      }
    });

    return result;
  }

  changeEllenorzesiSzempontValue = (listaid, szempontid, alkalomid) => {
    // -1 - nem létezik ilyen lista;
    // 0 - lista létezik, de szempont nem;
    // 1 - lista és szempont is létezik;
    let result = -1;
    let list = JSON.parse(JSON.stringify(this.state.szempontValues));

    // console.log('IDS', listaid, szempontid);
    // console.log('IDS1', listaid, alkalomid );
    list.forEach((element) => {
      // console.log(element)
      // console.log('IDS2', element.listaid, element.alkalomid );
      if (element.listaid == listaid && element.alkalomid == alkalomid) {
        // console.log('element', element)
        result = 0;
        element.szempontok.forEach((szempont) => {
          if (szempont.id == szempontid) {
            result = 1;
            if (!szempont.value || szempont.value === '') {
              szempont.value = 1;
            } else if (szempont.value === 1) {
              szempont.value = 2;
            } else if (szempont.value === 2) {
              szempont.value = '';
            }
          }
        });
      }
    });

    if (result === -1) {
      list.push({
        listaid: listaid,
        alkalomid: alkalomid,
        szempontok: [{
          id: szempontid,
          value: 1,
          megjegyzes: '',
          ellistatetelid: szempontid,
          alkalomid: alkalomid
        }]
      });

      this.setState({ szempontValues: list });
    }

    if (result === 0) {
      list.forEach((element) => {
        if (element.listaid == listaid && element.alkalomid == alkalomid) {
          element.szempontok.push({
            id: szempontid,
            value: 1,
            megjegyzes: '',
            ellistatetelid: szempontid,
            alkalomid: alkalomid
          });
        }
      });
      this.setState({ szempontValues: list });
    }

    if (result === 1) {
      this.setState({ szempontValues: list });
    }
  }

  getEllenorzesiSzempontCheckboxIcon = (listaid, szempontid, alkalomid) => {
    // fa fa-square-o - ''
    // fa fa-plus-square-o - '1'
    // fa fa-check-square-o - '2'
    let result = '';
    let icon = 'fa fa-square-o';
    let list = JSON.parse(JSON.stringify(this.state.szempontValues));

    list.forEach((element) => {
      if (element.listaid == listaid && element.alkalomid == alkalomid) {
        element.szempontok.forEach((szempont) => {
          if (szempont.id == szempontid) {
            result = szempont.value;
          }
        });
      }
    });

    if (result == 1) {
      icon = 'fa fa-plus-square-o';
    } else if (result == 2) {
      icon = 'fa fa-check-square-o';
    }

    return icon;
  }

  saveForm = (vegl) => {
    let saveObj = {
      profilentity: {
        megjegyzes: null
      },
      tipusentity: {
        id: null
      },
      specialis: []
    };

    saveObj.id = this.emptyOrNull(this.state.munkalapId);
    saveObj.leiras = this.emptyOrNull(this.state.formLeiras);
    // saveObj.alairas = signature.toDataURL();
    // saveObj.cegadattipus = this.emptyOrNull(this.state.formCegadatValues.tipus.name);
    saveObj.cegadatid = this.emptyOrNull(this.state.formCegadatValues.id);
    saveObj.partnertortenetid = this.emptyOrNull(this.state.formPartner.id);
    saveObj.tipusentity.id = this.emptyOrNull(this.state.munkalapTypeId);
    saveObj.profilentity.megjegyzes = this.emptyOrNull(this.state.formMegjegyzes);
    if (this.emptyOrNull(this.state.formSpecialisHely) && this.emptyOrNull(this.state.formSpecialisID)) {
      saveObj.specialis.push({
        hely: this.emptyOrNull(this.state.formSpecialisHely),
        kulsoid: this.emptyOrNull(this.state.formSpecialisID),
      });
    }

    Services.saveForm(saveObj, vegl, (err, res) => {
      if (!err) {
        if (this.emptyOrNull(res[0])) {
          if (this.emptyOrNull(res[0].id)) {
            this.setState({
              munkalapId: res[0].id
            });
          }
        }
      } else {
        //
      }
    });
  }

  startMunkalap = () => {
    let saveObj = {
      specialisok: []
    };

    let specialisok = JSON.parse(JSON.stringify(this.state.feladatData));
    let specialisSave = [];
    specialisok.forEach((specialis) => {
      if (specialis.kapcsolodoSzerviz) {
        specialisSave.push({
          szerviz: specialis.kapcsolodoSzerviz,
          szervizid: specialis.kapcsolodoId,
          szuloszerviz: specialis.forras,
          szuloid: specialis.feladat.id,
        });
      } else {
        specialisSave.push({
          szerviz: specialis.forras,
          szervizid: specialis.feladat.id,
        });
      }
    });

    // saveObj.alairas = signature.toDataURL();
    // saveObj.id = this.emptyOrNull(this.state.munkalapId);
    saveObj.leiras = this.state.formLeiras;
    saveObj.feladatleiras = this.state.formFeladatLeirasa;
    saveObj.megjegyzes = this.state.formMegjegyzes;
    // saveObj.cegadattipus = this.emptyOrNull(this.state.formCegadatValues.tipus.name);
    saveObj.specialisok = specialisSave;
    if (this.isFormEdit()) saveObj.id = this.emptyOrNull(this.state.munkalapId);
    saveObj.cegadatid = this.emptyOrNull(this.state.formCegadatValues.id);
    saveObj.partnertortenetid = this.emptyOrNull(this.state.formPartner.id);
    saveObj.tipusid = this.emptyOrNull(this.state.munkalapTypeId);
    saveObj.userids = JSON.parse(JSON.stringify(this.state.formFeladatElvegzok)).map(item => item.id);
    // saveObj.profilentity.megjegyzes = this.emptyOrNull(this.state.formMegjegyzes);
    saveObj.mellekletek = this.getSubmitFiles();
    if (this.emptyOrNull(this.state.formSpecialisHely) && this.emptyOrNull(this.state.formSpecialisID)) {
      saveObj.specialis.push({
        hely: this.emptyOrNull(this.state.formSpecialisHely),
        kulsoid: this.emptyOrNull(this.state.formSpecialisID),
      });
    }

    if (this.hasEmptyForras(specialisok)) {
      this.props.route.globals.addError('Hibás feladatkiválasztás!');
    } else {
      if (this.isFormCreate()) {
        Services.saveForm(saveObj, (err, res) => {
          if (!err) {
            Services.startMunkalap(res[0].id, (errS, resS) => {
              if (!err) {
                this.setState({
                  formMunkalapAllapot: 'FOLYAMATBAN',
                  munkalapId: res[0].id
                });
                this.props.route.globals.addSuccess('Munkalap sikeresen mentve és folyamatban állapotra átállítva!');
                this.onBack(res[0].id);
                if (this.cikkCardRef) {
                  this.cikkCardRef.getCikkList(res[0].id);
                }
              }
            });
          }
        });
      }

      if (this.isFormEdit()) {
        Services.modifyForm(saveObj, (err, res) => {
          if (!err) {
            let newIds = res[0];
            let specialisok = JSON.parse(JSON.stringify(this.state.feladatData));

            console.log(newIds);
            console.log(newIds[0]);
            console.log(specialisok)
            specialisok.forEach((feladat) => {
              console.log(feladat)
              console.log((feladat.feladat.id === newIds[4]) && (feladat.forras === newIds[3]));
              console.log(feladat.feladat.id, newIds[4], feladat.forras, newIds[3])
              newIds.forEach((ids) => {
                if (feladat.feladat.id === ids[4] && feladat.forras === ids[3]) {
                  feladat.id = ids[0];
                }
              });

            });

            this.setState({ feladatData: specialisok });

            Services.startMunkalap(this.state.munkalapId, (errS, resS) => {
              if (!err) {
                this.setState({
                  formMunkalapAllapot: 'FOLYAMATBAN'
                });
                this.props.route.globals.addSuccess('Munkalap sikeresen mentve és folyamatban állapotra átállítva!');
                this.onBack(this.state.munkalapId);
                if (this.cikkCardRef) {
                  this.cikkCardRef.getCikkList(this.state.munkalapId);
                }
              }
            });
          }
        });
      }
    }
  }


  closeMunkalap = () => {
    let saveObj = {
      specialisok: []
    };

    // let szempontok = JSON.parse(JSON.stringify(this.state.szempontValues));

    let specialisok = JSON.parse(JSON.stringify(this.state.feladatData));
    let specialisSave = [];
    specialisok.forEach((specialis) => {
      if (specialis.kapcsolodoSzerviz) {
        specialisSave.push({
          szerviz: specialis.kapcsolodoSzerviz,
          szervizid: specialis.kapcsolodoId,
          szuloszerviz: specialis.forras,
          szuloid: specialis.feladat.id,
        });
      } else {
        let sas = 0;
        if (specialis.forras === 'KARBANTARTAS') {
          specialisSave.push({
            szerviz: specialis.forras,
            szervizid: specialis.feladat.id,
          });
        } else {
          specialisSave.push({
            szerviz: specialis.forras,
            szervizid: specialis.feladat.id,
          });
        }
      }
    });

    if (this.state.formMunkalapAllapot === 'FOLYAMATBAN' && this.state.alairaskezeles) {
      let signature = this.refs.SignaturePad;
      saveObj.alairas = signature.toDataURL();
    }

    saveObj.id = this.emptyOrNull(this.state.munkalapId);
    saveObj.leiras = this.state.formLeiras;
    saveObj.feladatleiras = this.state.formFeladatLeirasa;
    saveObj.megjegyzes = this.state.formMegjegyzes;
    // saveObj.cegadattipus = this.emptyOrNull(this.state.formCegadatValues.tipus.name);
    saveObj.cegadatid = this.emptyOrNull(this.state.formCegadatValues.id);
    saveObj.partnertortenetid = this.emptyOrNull(this.state.formPartner.id);
    saveObj.tipusid = this.emptyOrNull(this.state.munkalapTypeId);
    saveObj.userids = JSON.parse(JSON.stringify(this.state.formFeladatElvegzok)).map(item => item.id);
    saveObj.specialisok = specialisSave;
    saveObj.specialissave = [{ c: 'KARBANTARTAS', ellenorzesilistak: this.getEllenorzesiLista(0) }];
    // saveObj.profilentity.megjegyzes = this.emptyOrNull(this.state.formMegjegyzes);
    saveObj.mellekletek = this.getSubmitFiles();
    if (this.emptyOrNull(this.state.formSpecialisHely) && this.emptyOrNull(this.state.formSpecialisID)) {
      saveObj.specialis.push({
        hely: this.emptyOrNull(this.state.formSpecialisHely),
        kulsoid: this.emptyOrNull(this.state.formSpecialisID),
      });
    }

    if (this.hasEmptyForras(specialisok)) {
      this.props.route.globals.addError('Hibás feladatkiválasztás!');
    } else {
      Services.modifyForm(saveObj, (err, res) => {
        if (!err) {
          Services.closeMunkalap(this.state.munkalapId, (errR, resS) => {
            if (!errR) {
              this.onBack(null);
            }
          });
        }
      });
    }
  }


  saveFormBack = () => {
    // let signature = this.refs.SignaturePad;
    let saveObj = {};
    let specialisok = JSON.parse(JSON.stringify(this.state.feladatData));
    let specialisSave = [];
    specialisok.forEach((specialis) => {
      if (specialis.kapcsolodoSzerviz) {
        specialisSave.push({
          szerviz: specialis.kapcsolodoSzerviz,
          szervizid: specialis.kapcsolodoId,
          szuloszerviz: specialis.forras,
          szuloid: specialis.feladat.id,
        });
      } else {
        specialisSave.push({
          szerviz: specialis.forras,
          szervizid: specialis.feladat.id,
        });
      }
    });

    // saveObj.alairas = signature.toDataURL();
    // saveObj.id = this.emptyOrNull(this.state.munkalapId);
    saveObj.leiras = this.state.formLeiras;
    saveObj.feladatleiras = this.state.formFeladatLeirasa;
    saveObj.megjegyzes = this.state.formMegjegyzes;
    // saveObj.cegadattipus = this.emptyOrNull(this.state.formCegadatValues.tipus.name);
    saveObj.cegadatid = this.emptyOrNull(this.state.formCegadatValues.id);
    saveObj.partnertortenetid = this.emptyOrNull(this.state.formPartner.id);
    saveObj.specialisok = specialisSave;
    /* saveObj.specialisok = [{
      szuloszerviz: 'FELADATLISTA',
      szuloid: 1257,
      szerviz: 'KARBANTARTAS',
      szervizid: 511919
    },
    {
      szerviz: 'KARBANTARTAS',
      szervizid: 511804
    }
    ]; */
    saveObj.tipusid = this.emptyOrNull(this.state.munkalapTypeId);
    saveObj.userids = JSON.parse(JSON.stringify(this.state.formFeladatElvegzok)).map(item => item.id);
    // saveObj.profilentity.megjegyzes = this.emptyOrNull(this.state.formMegjegyzes);
    saveObj.mellekletek = this.getSubmitFiles();
    if (this.emptyOrNull(this.state.formSpecialisHely) && this.emptyOrNull(this.state.formSpecialisID)) {
      saveObj.specialis.push({
        hely: this.emptyOrNull(this.state.formSpecialisHely),
        kulsoid: this.emptyOrNull(this.state.formSpecialisID),
      });
    }

    if (this.hasEmptyForras(specialisok)) {
      this.props.route.globals.addError('Hibás feladatkiválasztás!');
    } else {
      Services.saveForm(saveObj, (err, res) => {
        if (!err) {
          this.onBack();
        }
      });
    }
  }

  getSubmitFiles = () => {
    // Újonnan hozzáadott fájlokból, id kivétele
    let result = [];
    let files = JSON.parse(JSON.stringify(this.state.files));

    files.forEach((file) => {
      if (file.new) {
        file.id = null;
      }
      result.push(file);
    });
    return result;
  }

  getEllenorzesiLista = (szervizid) => {
    let result = [];

    let szempontok = JSON.parse(JSON.stringify(this.state.szempontValues));
    // console.log(szempontok);
    if (szempontok && szempontok[0] && szempontok[0].szempontok) {
      szempontok.forEach((element) => {
        element.szempontok.forEach((szempont) => {
          // if (szervizid === szempont.alkalomid) {
          let eredmeny = null;
          if (szempont.value === 1) eredmeny = false;
          if (szempont.value === 2) eredmeny = true;
          result.push({
            alkalomid: szempont.alkalomid,
            alkalomszempontpeldanyid: szempont.alkalomszempontpeldanyid,
            elllistatetelid: szempont.ellistatetelid,
            eredmeny: eredmeny,
            megjegyzes: szempont.megjegyzes,
          });
        });
      });
    }
    return result;
  }

  hasEmptyForras = (feladatok) => {
    let hasEmpty = false;

    feladatok.forEach((feladat) => {
      if (!feladat.forras || feladat.forras === '') hasEmpty = true;
    });

    return hasEmpty;
  }

  modifyFormBack = () => {
    // console.log(this.state.szempontValues);

    let saveObj = {
      specialisok: []
    };

    // let szempontok = JSON.parse(JSON.stringify(this.state.szempontValues));

    let specialisok = JSON.parse(JSON.stringify(this.state.feladatData));
    let specialisSave = [];
    specialisok.forEach((specialis) => {
      if (specialis.kapcsolodoSzerviz) {
        specialisSave.push({
          szerviz: specialis.kapcsolodoSzerviz,
          szervizid: specialis.kapcsolodoId,
          szuloszerviz: specialis.forras,
          szuloid: specialis.feladat.id,
        });
      } else {
        if (specialis.forras === 'KARBANTARTAS') {
          specialisSave.push({
            szerviz: specialis.forras,
            szervizid: specialis.feladat.id,
          });
        } else {
          specialisSave.push({
            szerviz: specialis.forras,
            szervizid: specialis.feladat.id,
          });
        }
      }
    });

    if (this.state.formMunkalapAllapot === 'FOLYAMATBAN' && this.state.alairaskezeles) {
      let signature = this.refs.SignaturePad;
      saveObj.alairas = signature.toDataURL();
    }

    saveObj.id = this.emptyOrNull(this.state.munkalapId);
    saveObj.leiras = this.state.formLeiras;
    saveObj.feladatleiras = this.state.formFeladatLeirasa;
    saveObj.megjegyzes = this.state.formMegjegyzes;
    // saveObj.cegadattipus = this.emptyOrNull(this.state.formCegadatValues.tipus.name);
    saveObj.cegadatid = this.emptyOrNull(this.state.formCegadatValues.id);
    saveObj.partnertortenetid = this.emptyOrNull(this.state.formPartner.id);
    saveObj.tipusid = this.emptyOrNull(this.state.munkalapTypeId);
    saveObj.userids = JSON.parse(JSON.stringify(this.state.formFeladatElvegzok)).map(item => item.id);
    saveObj.specialisok = specialisSave;
    saveObj.specialissave = [{ c: 'KARBANTARTAS', ellenorzesilistak: this.getEllenorzesiLista(0) }];
    // saveObj.profilentity.megjegyzes = this.emptyOrNull(this.state.formMegjegyzes);
    saveObj.mellekletek = this.getSubmitFiles();
    if (this.emptyOrNull(this.state.formSpecialisHely) && this.emptyOrNull(this.state.formSpecialisID)) {
      saveObj.specialis.push({
        hely: this.emptyOrNull(this.state.formSpecialisHely),
        kulsoid: this.emptyOrNull(this.state.formSpecialisID),
      });
    }

    if (this.hasEmptyForras(specialisok)) {
      this.props.route.globals.addError('Hibás feladatkiválasztás!');
    } else {
      Services.modifyForm(saveObj, (err, res) => {
        if (!err) {
          this.onBack(null);
        }
      });
    }
  }

  saveFormFinalBack = () => {


    let saveObj = {
      profilentity: {
        megjegyzes: null
      },
      tipusentity: {
        id: null
      },
      specialis: []
    };

    saveObj.id = this.emptyOrNull(this.state.munkalapId);
    saveObj.leiras = this.emptyOrNull(this.state.formLeiras);
    // saveObj.cegadattipus = this.emptyOrNull(this.state.formCegadatValues.tipus.name);

    if (this.state.alairaskezeles) {
      let signature = this.refs.SignaturePad;
      saveObj.alairas = signature.toDataURL();
    }

    saveObj.cegadatid = this.emptyOrNull(this.state.formCegadatValues.id);
    saveObj.partnertortenetid = this.emptyOrNull(this.state.formPartner.id);
    saveObj.tipusentity.id = this.emptyOrNull(this.state.munkalapTypeId);
    saveObj.profilentity.megjegyzes = this.emptyOrNull(this.state.formMegjegyzes);
    if (this.emptyOrNull(this.state.formSpecialisHely) && this.emptyOrNull(this.state.formSpecialisID)) {
      saveObj.specialis.push({
        hely: this.emptyOrNull(this.state.formSpecialisHely),
        kulsoid: this.emptyOrNull(this.state.formSpecialisID),
      });
    }

    Services.saveForm(saveObj, true, (err, res) => {
      if (!err) {
        this.onBack(null);
      }
    });
  }

  emptyOrNull = (data) => {
    if (!data || data === '') return null;
    return data;
  }

  emptyToZero = (data) => {
    if (!data || data === '') return 0;
    return data;
  }

  onDropObject = (acceptedfiles) => {
    acceptedfiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        let files = this.state.files || [];
        files.push({
          content: btoa(fileAsBinaryString),
          uploadDate: new Date(),
          docname: file.name,
          preview: file.preview,
          logo: false,
          mime: file.type || 'application/octet-stream',
          new: true,
          length: file.size,
          id: Lib.Browser.uuidv4()
        });
        this.setState({ files: files });
      };
      reader.readAsBinaryString(file);
    });
  }

  downloadObject = (file) => {
    if (file.preview) {
      window.open(file.preview, '_blank');
    } else {
      this.getContent(file.id);
    }
  }

  removeObject = (file) => {
    if (file.preid) {
      let files = this.state.files.filter(f => f.preid !== file.preid);
      this.setState({ files: files });
    } else {
      let files = this.state.files.filter(f => f.id !== file.id);
      this.setState({ files: files });
    }
  }

  getContent = (objid) => {
    let obj = this.state.files.find(f => f.id == objid);
    Services.downloadObject(objid, (err, res) => {
      if (!err) {
        fileSaver.saveAs(new Blob([res[0]], { type: obj.mime }), obj.docname);
      }
    });
  }

  formatBytes = (bytes) => {
    if (bytes < 1024) return bytes + ' bájt';
    if (bytes < 1048576) return (bytes / 1024).toFixed(3) + ' KB';
    if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + ' MB';
    return (bytes / 1073741824).toFixed(3) + ' GB';
  };

  listActiveTelephely = () => {
    this.setState({
      cegadatCardStatus: 'LOAD'
    });
    Services.listActiveTelephely((err, res) => {
      if (!err) {
        let newOption = [];
        res[0].forEach((item) => {
          newOption.push(
            <option
              value={JSON.stringify(item)}
              key={item.id}
            >
              {item.nev + ' (' + item.tipus + ')'}
            </option>
          );
        });
        this.setState({
          cegadatCardStatus: 'DATA',
          formCegadatOptions: newOption
        });
      } else {
        this.setState({
          cegadatCardStatus: 'NETW'
        });
      }
    });
  }

  getRaktarList = (id) => {
    Services.getRaktarList(id, (err, res) => {
      if (!err) {
        this.setState({
          formKeszletRaktarak: res[0]
        });
      } else {
        this.setState({
          formKeszletRaktarak: []
        });
      }
    });
  }

  getMegjegyzes = () => {
    this.setState({
      megjegyzesCardStatus: 'LOAD'
    });
    Services.getMegjegyzes((err, res) => {
      if (!err) {
        this.setState({
          megjegyzesCardStatus: 'DATA',
          formMegjegyzes: res[0].megjegyzes
        });
      } else {
        this.setState({
          megjegyzesCardStatus: 'NETW'
        });
      }
    });
  }

  getMunkalapInit = () => {
    this.setState({
      tipusCardStatus: 'LOAD',
      cegadatCardStatus: 'LOAD'
    });
    Services.getMunkalapInit(null, (err, res) => {
      if (!err) {
        let newOption = [];
        /* res[0].forEach((item) => {
          newOption.push(
            <option
              value={JSON.stringify({
                id: item.id,
                azonosito: item.azonosito,
                cikkkezeles: item.cikkkezeles,
                autoveglegezeskezeles: item.autoveglegezeskezeles,
                leiraskezeles: item.leiraskezeles,
                osszegkezeles: item.osszegkezeles,
                workflowkezeles: item.workflowkezeles,
              })}
              key={item.id}
            >
              {item.nev}
            </option>
          );
        }); */
        this.setState({
          formMunkalapAllapot: 'ROGZITETT',
          tipusCardStatus: 'DATA',
          cegadatCardStatus: 'DATA',
          formMunkalapTypeOptions: res[0].tipusok ? res[0].tipusok : [],
          formCegadatOptions: res[0].cegadatok ? res[0].cegadatok : [],
          formForrasokOptions: res[0].szervizek ? res[0].szervizek : [],
          formPartnerOptions: res[0].userek ? res[0].userek : []
        }, () => {
          if (res[0].tipusok && res[0].tipusok.length === 1) {
            // console.log('ID', res[0].tipusok[0].id);
            this.setState({
              formMunkalapType: res[0].tipusok[0].id,
              formMunkalapId: res[0].tipusok[0].id
            }, () => {
              this.onTypeSelect();
            });
          }
        });
      } else {
        this.setState({
          tipusCardStatus: 'NETW',
          cegadatCardStatus: 'NETW'
        });
      }
    });
  }

  onVizsgalatClick = () => {
    this.setState({
      vizsgalatiEredmenyStatus: 'LOAD',
      modalVizsgalatiEredmeny: true,
    });
    Services.getVizsgalatok(this.state.formPartner.id, (err, res) => {
      if (!err) {
        this.setState({
          vizsgalatiEredmenyStatus: 'DATA',
          formVizsgalatiEredmenyOptions: res[0][0] ? res[0] : []
        });
      } else {
        this.setState({
          vizsgalatiEredmenyStatus: 'NETW'
        });
      }
    });
  }

  onVizsgalatMentes = () => {
    this.setState(prevState => ({
      formVizsgalatTipus: prevState.vizsgalatEredmenyData[0].vizsgalatok,
      formVizsgalatId: prevState.vizsgalatEredmenyData[0].id,
      formVizsgalatDatum: prevState.vizsgalatEredmenyData[0].datum,
      formVizsgalatEredmenyek: prevState.vizsgalatEredmenyData[0].eredmenyek,
    }), () => {
      this.toggleVizsgalatiEredmenyModal();
    });
  }

  onBack = (id) => {
    if (id) {
      browserHistory.push({
        pathname: '/munkalap/modositas',
        search: '?id=' + id,
        state: {
          munkalapId: id
        }
      });
    } else {
      browserHistory.push({
        pathname: '/munkalap',
        state: {
        }
      });
    }
  }

  getTipusValuesByID = (id) => {
    let result = null;
    this.state.formMunkalapTypeOptions.forEach((munkalap) => {
      if (munkalap.id == id) {
        result = munkalap;
      }
    });
    return result;
  }

  getCegadatValuesByID = (id) => {
    let result = null;
    this.state.formCegadatOptions.forEach((cegadat) => {
      if (cegadat.id == id) {
        result = cegadat;
      }
    });
    return result;
  }

  onTypeSelect = () => {
    let newFormValues = this.state.formMunkalapType;
    let obj = this.getTipusValuesByID(this.state.formMunkalapType);
    // console.log('OBJ', obj)
    this.setState({
      munkalapTypeId: this.state.formMunkalapType,
      munkalapType: obj && obj.azonosito,
      cikkkezeles: obj && obj.cikkkezeles,
      autoveglegezeskezeles: obj && obj.autoveglegezeskezeles,
      leiraskezeles: obj && obj.leiraskezeles,
      osszegkezeles: obj && obj.osszegkezeles,
      workflowkezeles: obj && obj.workflowkezeles,
      megjegyzeskezeles: obj && obj.megjegyzeskezeles,
      alairaskezeles: obj && obj.alairaskezeles,
      specialiskezeles: obj && obj.specialiskezeles,
    });
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  handleFeladatLeirasInputChange = (e, idx) => {
    const feladatData = JSON.parse(JSON.stringify(this.state.feladatData));
    let newData = [];
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    feladatData.forEach((feladat, feladatIdx) => {
      if (feladatIdx == idx) {
        feladat.feladat.leiras = value;
      }
      newData.push(feladat);
    });

    this.setState({
      feladatData: newData
    });
  }

  handleFeladatForrasChange = (e, idx) => {
    const feladatData = JSON.parse(JSON.stringify(this.state.feladatData));
    let newData = [];
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    // const { name } = target;

    feladatData.forEach((feladat, feladatIdx) => {
      if (feladatIdx == idx) {
        feladat.forras = value;
        feladat.feladat = '';
        feladat.kapcsolodoSzerviz = null;
        feladat.kapcsolodoId = null;
      }
      newData.push(feladat);
    });

    this.setState({
      feladatData: newData
    }, () => {
      // this.loadFeladatChange(this.state.formPartner.id, this.state.feladatData[idx].forras);
    });
  }

  handleCegadatChange = (e) => {
    if (e.target.value && e.target.value !== '') {
      let obj = this.getCegadatValuesByID(e.target.value);
      // console.log('obj', obj);
      this.setState({
        formCegadat: e.target.value,
        formCegadatValues: obj && obj,
      }, () => {
        // this.saveForm(false);
      });
    } else {
      this.setState({
        formCegadat: '',
        formCegadatValues: {
          tipus: {
            nev: null
          },
          nev: null,
          cim: null,
          telefon: null,
          email: null,
          honlap: null,
          adoszam: null,
        }
      });
    }
  }

  handleMunkalapTypeChange = (e) => {
    if (e.target.value && e.target.value !== '') {
      this.setState({
        formMunkalapType: e.target.value,
        formMunkalapId: JSON.parse(e.target.value).id,
      });
    } else {
      this.setState({
        formMunkalapType: '',
        formMunkalapId: '',
      });
    }
  }

  handleChangePartner = (value) => {
    if (value && value.length !== 0) {
      this.setState({
        formPartner: {
          id: value.id,
          nev: value.nev,
          search: value.nev + ' (' + value.azon + ')'
        },
        formPartnerValues: {
          azon: value.azon,
          nev: value.nev,
          cim: value.cim,
          telefon: value.telefon,
          email: value.email,
        },
        feladatData: [],
      }, () => {
        // this.saveForm(false);
      });
    } else {
      this.setState({
        feladatData: [],
        formPartner: '',
        formPartnerValues: {
          azon: null,
          nev: null,
          cim: null,
          telefon: null,
          email: null,
        },
      });
    }
  }

  isFeladatDuplicate = (value, idx) => {
    let result = false;
    const feladatData = JSON.parse(JSON.stringify(this.state.feladatData));
    feladatData.forEach((feladat, feladatIdx) => {
      // console.log(feladat)
      // console.log('id1, id2', value.specialisok[0].id, feladat.feladat.id)
      // console.log('forras1, forras2', this.state.feladatData[idx].forras, feladat.forras)
      // console.log('----')
      if ((value.specialisok[0].id === feladat.feladat.id) && (this.state.feladatData[idx].forras === feladat.forras)) {
        result = true;
      }
    });
    return result;
  }

  handleChangeFeladat = (value, idx) => {
    // console.log('Feladat', value, idx);
    let duplicate = false;
    if (value) {
      const feladatData = JSON.parse(JSON.stringify(this.state.feladatData));
      // console.log('feladatData', feladatData);
      let newData = [];
      /*
            feladatData.forEach((feladat, feladatIdx) => {
              if (feladatIdx == idx) {
                feladat.feladat = value.specialisok[0];

                if (value.specialisok[0].azonosito) {
                  feladat.feladat.label = value.specialisok[0].azonosito;
                  if (value.specialisok[0].idodont) {
                    feladat.feladat.label += ' (' + this.timezoneToDateYear(value.specialisok[0].idodont) + ')';
                    feladat.feladat.cikklabel = value.specialisok[0].cikknev + ' (' + value.specialisok[0].cikkszam + ')';
                  }
                }

                if (value.specialisok[0].bizonylatszam) {
                  feladat.feladat.label = value.specialisok[0].bizonylatszam;
                }
              }
              newData.push(feladat);
            });
      */
      // console.log('Duplicate', this.isFeladatDuplicate(value, idx));
      // console.log('Duplicate', this.isFeladatDuplicate(value, idx));
      // if (this.isFeladatDuplicate(value, idx)) { TODO
      if (false) {
        this.props.route.globals.addError('Ez a feladat már szerepel a munkalapon!');
      } else {
        let counter = 0;
        let isFeladatlistasKarbantartas = feladatData[idx].forras === 'FELADATLISTA' && value.forras === 'KARBANTARTAS';
        // if (value.szuloszerviz === 'FELADATLISTA' && value.szerviz === 'KARBANTARTAS') { TODO
        if (true) {
          Services.listFeladatSelect(feladatData[idx].forras, isFeladatlistasKarbantartas ? value.forras : feladatData[idx].forras, isFeladatlistasKarbantartas ? null : value.id, isFeladatlistasKarbantartas ? value.id : null, (errR, resS) => {
            if (!errR) {
              feladatData.forEach((feladat, feladatIdx) => {
                if (feladatIdx == idx) {
                  feladat.feladat = resS[0][0].specialisok[0];
                  if (resS[0][0].specialisok[0].azonosito) {
                    feladat.feladat.label = resS[0][0].specialisok[0].azonosito;
                    if (resS[0][0].specialisok[0].idodont) {
                      feladat.feladat.label += ' (' + this.timezoneToDateYear(resS[0][0].specialisok[0].idodont) + ')';
                      feladat.feladat.cikklabel = resS[0][0].specialisok[0].cikknev + ' (' + resS[0][0].specialisok[0].cikkszam + ')';
                      feladat.feladat.idopont = this.timezoneToDateTime(resS[0][0].specialisok[0].idodont);
                    }
                  }

                  if (resS[0][0].specialisok[0].bizonylatszam) {
                    feladat.feladat.label = resS[0][0].specialisok[0].bizonylatszam;
                  }
                }
                newData.push(feladat);
              });

              if (true) { // FELADATLISTA - KARBANTARTAS
                let { ellenorzesilistak } = resS[0][0].specialisok[0];

                newData.forEach((feladat, feladatIdx) => {
                  if (feladatIdx == idx) {
                    if (resS[0][0].specialisok.length === 2) {
                      console.log('resS[0][0].specialisok', JSON.parse(JSON.stringify(resS[0][0].specialisok)));
                      console.log(resS[0][0].specialisok[0].leiras, resS[0][0].specialisok[1].leiras)
                      feladat.feladat.label = resS[0][0].specialisok[1].azonosito;
                      feladat.feladat.cikklabel = resS[0][0].specialisok[0].cikknev + ' (' + resS[0][0].specialisok[0].cikkszam + ')';
                      feladat.feladat.azonosito = resS[0][0].specialisok[1].azonosito;
                      feladat.feladat.tipus = resS[0][0].specialisok[1].tipus;
                      feladat.feladat.esedekesseg = resS[0][0].specialisok[1].esedekesseg;
                      feladat.feladat.publeiras = resS[0][0].specialisok[0].leiras;
                      feladat.feladat.leiras = resS[0][0].specialisok[1].leiras;
                      feladat.feladat.id = resS[0][0].specialisok[1].id;
                      if (feladatData[idx].forras === 'FELADATLISTA') {
                        feladat.kapcsolodoSzerviz = resS[0][0].szerviz;
                        feladat.kapcsolodoId = resS[0][0].szervizid;
                      }
                    }
                    feladat.feladat.ellenorzesilistak = ellenorzesilistak;
                  }
                });

                this.setState({
                  feladatData: newData
                });
              }
            }
          });
        } else {
          this.setState({
            feladatData: newData
          });
        }
      }
    } else {
      // console.log('delete');
      let afterdelete = JSON.parse(JSON.stringify(this.state.feladatData));
      afterdelete[idx].feladat = '';
      afterdelete[idx].kapcsolodoId = null;
      afterdelete[idx].kapcsolodoSzerviz = null;
      this.setState({
        feladatData: afterdelete
      });
    }
  }

  handleChangeKeszletinfo = (value) => {
    if (value && value.length !== 0) {
      this.setState({
        formCikkId: value.id,
        formCikkszam: value.cikkszam,
        formCikkmegnevezes: value.megnevezes,
        formCikkMennyisegiEgyseg: value.mennyisegiEgyseg,
        formCikkAfa: value.aktualisAfaErtek,
      }, () => {
        this.getRaktarList(value.id);
      });
    } else {
      this.setState({
        formCikkId: '',
        formCikkszam: '',
        formCikkmegnevezes: '',
        formCikkMennyisegiEgyseg: '',
        formCikkAfa: '',
      });
    }
  }

  handleCikkKiadandoMennyisegChange = (event, value) => {
    if (event.target.value && event.target.value !== '') {
      this.setState({
        formCikkKiadandoMennyiseg: this.emptyToZero(event.target.value),
        formCikkNettoEladasiAr: this.emptyToZero(this.state.formCikkNettoEladasiEgysegAr) * this.emptyToZero(event.target.value),
        formCikkEladasiAr: this.emptyToZero(event.target.value) * this.emptyToZero(this.state.formCikkNettoEladasiEgysegAr) * (this.emptyToZero(this.state.formCikkAfa) / 100 + 1) - (this.state.formCikkKedvezmeny),
      });
    } else {
      this.setState({
        formCikkKiadandoMennyiseg: '',
        // formCikkNettoEladasiAr: '',
        // formCikkEladasiAr: '',
      });
    }
  }

  handleCikkNettoEladasiEgysegAr = (event, value) => {
    if (event.target.value && event.target.value !== '') {
      this.setState({
        formCikkNettoEladasiEgysegAr: this.emptyToZero(event.target.value),
        formCikkNettoEladasiAr: this.emptyToZero(this.state.formCikkKiadandoMennyiseg) * this.emptyToZero(event.target.value),
        formCikkEladasiAr: this.emptyToZero(event.target.value) * this.emptyToZero(this.state.formCikkKiadandoMennyiseg) * (this.emptyToZero(this.state.formCikkAfa) / 100 + 1) - (this.state.formCikkKedvezmeny),
        formCikkBruttoEladasiEgysegAr: (this.emptyToZero(this.state.formCikkAfa) / 100 + 1) * this.emptyToZero(event.target.value)
      });
    } else {
      this.setState({
        // formCikkKiadandoMennyiseg: '',
        formCikkNettoEladasiAr: '',
        // formCikkEladasiAr: '',
      });
    }
  }

  handleCikkKedvezmeny = (event, value) => {
    if (event.target.value && event.target.value !== '') {
      if (this.state.kedvezemenyType === 'FT') {
        this.setState({
          formCikkKedvezmeny: this.emptyToZero(event.target.value),
          formCikkEladasiAr: this.emptyToZero(this.state.formCikkNettoEladasiAr) * (this.emptyToZero(this.state.formCikkAfa) / 100 + 1) - (event.target.value),
        });
      }
      if (this.state.kedvezemenyType === '%') {
        this.setState({
          formCikkKedvezmeny: this.emptyToZero(event.target.value),
          formCikkEladasiAr: this.emptyToZero(this.state.formCikkNettoEladasiAr) * (this.emptyToZero(this.state.formCikkAfa) / 100 + 1),
        });
      }
    } else {
      this.setState({
        formCikkKedvezmeny: '',
        formCikkEladasiAr: this.emptyToZero(this.state.formCikkNettoEladasiAr) * (this.emptyToZero(this.state.formCikkAfa) / 100 + 1)
      });
    }
  }

  handleListItemClick = (raktarId) => {
    let newRaktarak = [];
    let raktarak = JSON.parse(JSON.stringify(this.state.formKeszletRaktarak));
    raktarak.forEach((item) => {
      if (item.raktar.id === raktarId) {
        item.raktar.selected = true;
      } else {
        item.raktar.selected = false;
      }
      newRaktarak.push(item);
    });
    this.setState({
      formKeszletRaktarak: newRaktarak,
      // formKeszletRaktarId: raktarId,
    });
  }

  handleKeszletInfoSave = (raktarId, szabadMennyiseg) => {
    this.setState({
      formKeszletRaktarId: raktarId,
      formSzabadMennyiseg: szabadMennyiseg
    });
  }

   handleFeladatElvegzokChange = (option) => {
     this.setState((state) => {
       return {
         formFeladatElvegzok: option
       };
     });
   }

  loadPartnerChange = (input, callback) => {
    if (input.length > 2) {
      Services.listPartner('MUNKALAP_VEVO_FORM', input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            let newItem = item;
            newItem.search = newItem.nev + ' (' + newItem.cim + ') ';
            newArray.push(newItem);
          });
          callback(newArray);
        }
      });
    }
  }

  getFormFeladatOptions = (idx) => {
    // console.log('OP', this.state.formFeladatOptions[idx])
    return [];
  }

  loadFeladatChange = (partnerid, forras, idx) => {
    let rawData = JSON.parse(JSON.stringify(this.state.formFeladatOptions));
    // console.log('r', rawData);
    let deletedData = [];
    rawData.forEach((options, optionIdx) => {
      if (idx != optionIdx) {
        deletedData.push(options);
      } else {
        deletedData.push([]);
      }
    });
    this.setState({
      formFeladatOptions: deletedData
    });
    Services.listFeladat(forras, '', partnerid, this.state.munkalapId, (err, res) => {
      if (!err) {
        let rawData = JSON.parse(JSON.stringify(this.state.formFeladatOptions));
        let newResult = [];
        let newArray = [];

        res[0].forEach((item) => {
          let labelWithDate = '';
          if (item[2] && item[2] !== 'KARBANTARTAS') labelWithDate += (' (' + this.timezoneToDateYear(item[2]) + ')')
          let newItem = {
            id: item[0],
            label: item[1] + labelWithDate,
            forras: item[2],
          };

          newArray.push(newItem);
        });

        rawData.forEach((options, optionIdx) => {
          if (idx != optionIdx) {
            newResult.push(options);
          } else {
            newResult.push(newArray);
          }
        });
        // console.log('r1', rawData);
        this.setState({
          isFeladatOptionsLoaded: true,
          isFeladatInputLoading: false,
          formFeladatOptions: newResult
        });
      } else {
        // console.log('r2', rawData);
        this.setState({ 
          isFeladatOptionsLoaded: true,
          isFeladatInputLoading: false
        });
      }
    });
  }

  loadFeladatChangeAsync = (input, partnerid, callback, forras) => {
    if (input.length !== 0) {
      Services.listFeladat(forras, input, partnerid, this.state.munkalapId, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            let newItem = item;
            if (newItem.specialisok[0].azonosito) {
              newItem.label = newItem.specialisok[0].azonosito;
              if (newItem.specialisok[0].idodont) {
                newItem.label += ' (' + this.timezoneToDateYear(newItem.specialisok[0].idodont) + ')';
              }
            }


            if (newItem.specialisok[0].bizonylatszam) {
              newItem.label = newItem.specialisok[0].bizonylatszam;
            }

            newArray.push(newItem);
          });
          callback(newArray);
        }
      });
    }
  }

  getCikkList = () => {
    this.setState({
      cikkCardStatus: 'LOAD'
    });
    Services.getMunkalap(this.state.munkalapId, (err, res) => {
      if (!err) {
        this.setState({
          cikkCardStatus: 'TABL',
          cikkJson: res[0].cikk
        });
      } else {
        this.setState({
          cikkCardStatus: 'NETW'
        });
      }
    });
  }

  maybeLoadOptions = (idx) => {
    // if (!this.state.isFeladatOptionsLoaded) {
    this.setState({ isFeladatInputLoading: true });
    this.handleLoadOptions(idx);
    // }
  }

  handleLoadOptions = (idx) => {
    let options = []

    this.loadFeladatChange(this.state.formPartner.id, this.state.feladatData[idx].forras, idx);

    /* Services.getMunkalapInitFilterUser((err, res) => {
      if (!err) {
        this.setState({ 
          isFeladatOptionsLoaded: true,
          partnerOptions: res[0],
          isFeladatInputLoading: false
        });
      } else {
        this.setState({ 
          isFeladatOptionsLoaded: true,
          isFeladatInputLoading: false
        });
      }
    }); */ 
  };

  deleteCikk = () => {
    Services.deleteCikk(this.state.torlesId, (err) => {
      if (!err) {
        this.toggleTorles();
        this.getCikkList();
        this.props.route.globals.addSuccess('Cikk törölve!');
      }
    });
  }

  renderTipusOptions = () => {
    return this.state.formMunkalapTypeOptions.map((munkalap) => {
      return (
        <option
          value={munkalap.id}
          key={munkalap.id}
        >
          {munkalap.nev}
        </option>
      );
    });
  }

  renderCegadatOptions = () => {
    return this.state.formCegadatOptions.map((cegadat) => {
      return (
        <option
          value={cegadat.id}
          key={cegadat.id}
        >
          {cegadat.nev + ' (' + cegadat.tipus + ' )'}
        </option>
      );
    });
  }

  renderForrasOptions = () => {
    return this.state.formForrasokOptions.map((forras) => {
      return (
        <option
          value={forras.name}
          key={forras.name}
        >
          {forras.caption}
        </option>
      );
    });
  }

  renderCardTipusLogic = () => {
    return (
      <React.Fragment>
        <div className="col-md-4">
          <FormGroup>
            <Label for="formMunkalapType">Munkalap típus:</Label>
            <Input
              type="select"
              name="formMunkalapType"
              id="formMunkalapType"
              value={this.state.formMunkalapType}
              onChange={this.handleMunkalapTypeChange}
            >
              <option value="">Válassz típust...</option>
              {this.renderTipusOptions()}
            </Input>
          </FormGroup>
        </div>
        <div className="col-md-12">
          <Button
            color="primary"
            onClick={this.onTypeSelect}
            disabled={!this.state.formMunkalapType || this.state.formMunkalapType === ''}
          >
            {'Tovább'}
          </Button>
          {' '}
          <Button
            color="secondary"
            onClick={() => { this.onBack(null); }}
          >
            {'Vissza'}
          </Button>
        </div>
      </React.Fragment>
    );
  }

  renderCardLeirasLogic = () => {
    return (
      <React.Fragment>
        <FormGroup>
          <Input
            className="munkalap-profil-textarea"
            type="textarea"
            name="formLeiras"
            id="formLeiras"
            value={this.state.formLeiras}
            onChange={this.handleInputChange}
            disabled={this.isFormView()}
          />
        </FormGroup>
      </React.Fragment>
    );
  }

  renderSzemuvegEredmenyek = () => {
    let szemuvegAdatok = [];
    if (this.state.formVizsgalatEredmenyek && this.state.formVizsgalatEredmenyek.length > 0) szemuvegAdatok = this.state.formVizsgalatEredmenyek.filter(eredmeny => eredmeny.fajta === 'Szemüveg');

    return (
      szemuvegAdatok.length > 0 && (
      <React.Fragment>
        <div className="col-md-6">
          <Card>
            <CardBody>
              <CardTitle>Szemüveg</CardTitle>
              <CardSubtitle>Bal</CardSubtitle>
            </CardBody>
            <CardBody style={{ paddingTop: 0 }}>
              <b>Nyers V:</b>
              {(szemuvegAdatok[1] && szemuvegAdatok[1].vizus) ? ' ' + szemuvegAdatok[1].vizus : ' -'}
              <br />
              <b>SPH:</b>
              {(szemuvegAdatok[1] && szemuvegAdatok[1].sph) ? ' ' + szemuvegAdatok[1].sph : ' -'}
              <br />
              <b>CYL:</b>
              {(szemuvegAdatok[1] && szemuvegAdatok[1].cyl) ? ' ' + szemuvegAdatok[1].cyl : ' -'}
              <br />
              <b>TENG:</b>
              {(szemuvegAdatok[1] && szemuvegAdatok[1].tengely) ? ' ' + szemuvegAdatok[1].tengely : ' -'}
              <br />
              <b>Korrigált V:</b>
              {(szemuvegAdatok[1] && szemuvegAdatok[1].korrvizus) ? ' ' + szemuvegAdatok[1].korrvizus : ' -'}
              <br />
              <b>ADD:</b>
              {(szemuvegAdatok[1] && szemuvegAdatok[1].add) ? ' ' + szemuvegAdatok[1].add : ' -'}
              <br />
              <b>Prizma DIOP:</b>
              {(szemuvegAdatok[1] && szemuvegAdatok[1].prizmadioptria) ? ' ' + szemuvegAdatok[1].prizmadioptria : ' -'}
              <br />
              <b>Prizma A:</b>
              {(szemuvegAdatok[1] && szemuvegAdatok[1].prizmaalap) ? ' ' + szemuvegAdatok[1].prizmaalap : ' -'}
              <br />
              <b>PD :</b>
              {(szemuvegAdatok[1] && szemuvegAdatok[1].pd) ? ' ' + szemuvegAdatok[1].pd : ' -'}
            </CardBody>
          </Card>
        </div>
        <div className="col-md-6">
          <Card>
            <CardBody>
              <CardTitle>Szemüveg</CardTitle>
              <CardSubtitle>Jobb</CardSubtitle>
            </CardBody>
            <CardBody style={{ paddingTop: 0 }}>
              <b>Nyers V:</b>
              {(szemuvegAdatok[0] && szemuvegAdatok[0].vizus) ? ' ' + szemuvegAdatok[0].vizus : ' -'}
              <br />
              <b>SPH:</b>
              {(szemuvegAdatok[0] && szemuvegAdatok[0].sph) ? ' ' + szemuvegAdatok[0].sph : ' -'}
              <br />
              <b>CYL:</b>
              {(szemuvegAdatok[0] && szemuvegAdatok[0].cyl) ? ' ' + szemuvegAdatok[0].cyl : ' -'}
              <br />
              <b>TENG:</b>
              {(szemuvegAdatok[0] && szemuvegAdatok[0].tengely) ? ' ' + szemuvegAdatok[0].tengely : ' -'}
              <br />
              <b>Korrigált V:</b>
              {(szemuvegAdatok[0] && szemuvegAdatok[0].korrvizus) ? ' ' + szemuvegAdatok[0].korrvizus : ' -'}
              <br />
              <b>ADD:</b>
              {(szemuvegAdatok[0] && szemuvegAdatok[0].add) ? ' ' + szemuvegAdatok[0].add : ' -'}
              <br />
              <b>Prizma DIOP:</b>
              {(szemuvegAdatok[0] && szemuvegAdatok[0].prizmadioptria) ? ' ' + szemuvegAdatok[0].prizmadioptria : ' -'}
              <br />
              <b>Prizma A:</b>
              {(szemuvegAdatok[0] && szemuvegAdatok[0].prizmaalap) ? ' ' + szemuvegAdatok[0].prizmaalap : ' -'}
              <br />
              <b>PD :</b>
              {(szemuvegAdatok[0] && szemuvegAdatok[0].pd) ? ' ' + szemuvegAdatok[0].pd : ' -'}
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
      )
    );
  }

  renderLencseEredmenyek = () => {
    let lencseAdatok = [];
    if (this.state.formVizsgalatEredmenyek && this.state.formVizsgalatEredmenyek.length > 0) lencseAdatok = this.state.formVizsgalatEredmenyek.filter(eredmeny => eredmeny.fajta === 'Kontaktlencse');

    return (
      lencseAdatok.length > 0 && (
      <React.Fragment>
        <div className="col-md-6">
          <Card>
            <CardBody>
              <CardTitle>Kontaktlencse</CardTitle>
              <CardSubtitle>Bal</CardSubtitle>
            </CardBody>
            <CardBody style={{ paddingTop: 0 }}>
              <b>SPH:</b>
              {(lencseAdatok[1] && lencseAdatok[1].sph) ? ' ' + lencseAdatok[1].sph : ' -'}
              <br />
              <b>CYL:</b>
              {(lencseAdatok[1] && lencseAdatok[1].cyl) ? ' ' + lencseAdatok[1].cyl : ' -'}
              <br />
              <b>TENG:</b>
              {(lencseAdatok[1] && lencseAdatok[1].tengely) ? ' ' + lencseAdatok[1].tengely : ' -'}
              <br />
              <b>ADD:</b>
              {(lencseAdatok[1] && lencseAdatok[1].add) ? ' ' + lencseAdatok[1].add : ' -'}
              <br />
              <b>BC:</b>
              {(lencseAdatok[1] && lencseAdatok[1].bc) ? ' ' + lencseAdatok[1].bc : ' -'}
              <br />
              <b>DIA:</b>
              {(lencseAdatok[1] && lencseAdatok[1].dia) ? ' ' + lencseAdatok[1].dia : ' -'}
              <br />
              <b>Típus:</b>
              {(lencseAdatok[1] && lencseAdatok[1].tipus) ? ' ' + lencseAdatok[1].tipus : ' -'}
            </CardBody>
          </Card>
        </div>
        <div className="col-md-6">
          <Card>
            <CardBody>
              <CardTitle>Kontaktlencse</CardTitle>
              <CardSubtitle>Jobb</CardSubtitle>
            </CardBody>
            <CardBody style={{ paddingTop: 0 }}>
              <b>SPH:</b>
              {(lencseAdatok[0] && lencseAdatok[0].sph) ? ' ' + lencseAdatok[0].sph : ' -'}
              <br />
              <b>CYL:</b>
              {(lencseAdatok[0] && lencseAdatok[0].cyl) ? ' ' + lencseAdatok[0].cyl : ' -'}
              <br />
              <b>TENG:</b>
              {(lencseAdatok[0] && lencseAdatok[0].tengely) ? ' ' + lencseAdatok[0].tengely : ' -'}
              <br />
              <b>ADD:</b>
              {(lencseAdatok[0] && lencseAdatok[0].add) ? ' ' + lencseAdatok[0].add : ' -'}
              <br />
              <b>BC:</b>
              {(lencseAdatok[0] && lencseAdatok[0].bc) ? ' ' + lencseAdatok[0].bc : ' -'}
              <br />
              <b>DIA:</b>
              {(lencseAdatok[0] && lencseAdatok[0].dia) ? ' ' + lencseAdatok[0].dia : ' -'}
              <br />
              <b>Típus:</b>
              {(lencseAdatok[0] && lencseAdatok[0].tipus) ? ' ' + lencseAdatok[0].tipus : ' -'}
            </CardBody>
          </Card>
        </div>
      </React.Fragment>
      ));
  }

  renderCardSpecialisLogic = () => {
    const titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };

    return (
      <div className="row">
        <div
          className="col-md-12"
          // style={{ textAlign: 'center' }}
        >
          <Button color="success" onClick={this.onVizsgalatClick} disabled={!this.state.formPartner || this.state.formPartner === ''}>
            {'Vizsgálati eredmény kiválasztása'}
          </Button>
        </div>
        <div
          className="col-md-12"
          // style={{ textAlign: 'center' }}
        >
          <h5 style={titleStyle}>
            {'Kartotékrendszer információk:'}
          </h5>
        </div>
        <div className="col-sm-12">
          <b>Vizsgálat típusa: </b>
          {this.state.formVizsgalatTipus ? ' ' + this.state.formVizsgalatTipus : ' -'}
        </div>
        <div className="col-sm-12" style={{ marginBottom: 20 }}>
          <b>Vizsgálat dátuma: </b>
          {this.state.formVizsgalatDatum ? ' ' + this.state.formVizsgalatDatum : ' -'}
        </div>
        {this.renderSzemuvegEredmenyek()}
        {this.renderLencseEredmenyek()}
      </div>
    );
  }

  renderCardSpecialisFeladatLogic = () => {
    const titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };

    return (
      <div className="row">
        <div
          className="col-md-12"
          style={{ marginBottom: 10 }}
        >
          {/* JSON.stringify(this.state.formFeladatElvegzok) */}
          <Label for="offlineselect">
            Feladat végrehajtója:
            <CustomTooltip text={getTooltip('feladat_vegrehajtoja')} id="feladat_vegrehajtojaTT" />
          </Label>
          <OfflineSelect
            options={this.state.formPartnerOptions}
            isDisabled={(this.isFormView() || (this.state.formMunkalapAllapot !== 'ROGZITETT'))}
            value={this.state.formFeladatElvegzok}
            onChange={this.handleFeladatElvegzokChange}
          />
        </div>
        <div
          className="col-md-12"
          // style={{ textAlign: 'center' }}
        >
          <FormGroup>
            <Label for="offlineselect">Feladat leírása: </Label>
            <Input
              className="munkalap-profil-textarea"
              type="textarea"
              name="formFeladatLeirasa"
              id="formFeladatLeirasa"
              value={this.state.formFeladatLeirasa}
              onChange={this.handleInputChange}
              disabled={this.isFormView()}
              maxLength={500}
            />
          </FormGroup>
        </div>
        <div
          className="col-md-12"
          // style={{ textAlign: 'center' }}
        >
          <hr style={{
            border: 0,
            height: 2,
            backgroundImage: 'linear-gradient(to right, transparent, #CCC, transparent)'
          }}
          />
        </div>
        {this.renderFeladatCards()}
        {/* JSON.stringify(this.state.feladatData) */}
        <div
          className="col-md-12"
          // style={{ textAlign: 'center' }}
        >
          <Button
            color="success"
            onClick={() => {
              this.setState(prevState => ({
                formFeladatOptions: [...prevState.formFeladatOptions, []],
              }), () => {
                // console.log(this.state.formFeladatOptions)
                this.setState(prevState => ({
                  feladatData: [...prevState.feladatData, { feladat: '', forras: '' }]
                }));
              });
            }}
            disabled={!this.state.formPartner.id || this.isFormView() || (this.state.formMunkalapAllapot !== 'ROGZITETT')}
          >
            {'Új feladat hozzáadása'}
            <CustomTooltip text={getTooltip('uj_feladat')} id="uj_feladatTT" />
          </Button>
          {/* JSON.stringify(this.state.feladatData) */}
          {/* '------\n ' */}
          {/* JSON.stringify(this.state.szempontValues) */}
        </div>

        {/* <div
          className="col-md-12"
          // style={{ textAlign: 'center' }}
        >
          <Button color="success" onClick={this.onVizsgalatClick} disabled={!this.state.formPartner || this.state.formPartner === ''}>
            {'Vizsgálati eredmény kiválasztása'}
          </Button>
        </div>
        <div
          className="col-md-12"
          // style={{ textAlign: 'center' }}
        >
          <h5 style={titleStyle}>
            {'Kartotékrendszer információk:'}
          </h5>
        </div>
        <div className="col-sm-12">
          <b>Vizsgálat típusa: </b>
          {this.state.formVizsgalatTipus ? ' ' + this.state.formVizsgalatTipus : ' -'}
        </div>
        <div className="col-sm-12" style={{ marginBottom: 20 }}>
          <b>Vizsgálat dátuma: </b>
          {this.state.formVizsgalatDatum ? ' ' + this.state.formVizsgalatDatum : ' -'}
        </div>
        {this.renderSzemuvegEredmenyek()}
        {this.renderLencseEredmenyek()} */}
      </div>
    );
  }

  renderFeladatCards = () => {
    return this.state.feladatData.map((feladat, idx) => {
      return (
        <React.Fragment>
          <div
            className="col-md-12"
                // style={{ textAlign: 'center' }}
          >
            <h5>{(idx + 1) + '. Feladat:'}</h5>
            {((this.state.formMunkalapAllapot === 'ROGZITETT') || this.isFormCreate()) && (
              <i
                className="fa fa-times pull-right"
                aria-hidden="true"
                style={{ fontSize: 15, cursor: 'pointer' }}
                onClick={() => {
                  const feladatData = JSON.parse(JSON.stringify(this.state.feladatData));
                  feladatData.splice(idx, 1);
                  this.setState({ feladatData });
                }}
              />
            )}
          </div>
          <div
            className="col-md-4"
                // style={{ textAlign: 'center' }}
          >
            <FormGroup>
              <Label
                for={'formForras' + idx}
              >
                Forrás:
              </Label>
              <Input
                type="select"
                name={'formForras' + idx}
                id={'formForras' + idx}
                value={this.state.feladatData[idx].forras}
                onChange={(e) => { this.handleFeladatForrasChange(e, idx); }}
                disabled={this.isFormView() || (this.state.formMunkalapAllapot !== 'ROGZITETT')}
              >
                <option value="">Válassz forrást...</option>
                {this.renderForrasOptions()}
              </Input>
            </FormGroup>
          </div>
          <div
            className="col-md-8"
                // style={{ textAlign: 'center' }}
          >
            <Label for={'formFeladat' + idx}>Feladat: </Label>
            {/* <SelectAsync
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name={'formFeladat' + idx}
              id={'formFeladat' + idx}
              placeholder="Kezdjen el gépelni a kereséshez..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeFeladat(v, idx)}
              isClearable
              ignoreAccents={false}
              isDisabled={!this.state.feladatData[idx].forras || this.state.feladatData[idx].forras === '' || this.isFormView() || (this.state.formMunkalapAllapot !== 'ROGZITETT')}
              // loadOptions={(input, callback) => this.loadKeresesOptions(input, callback)}
              loadOptions={_.debounce((input, callback) => this.loadFeladatChangeAsync(input, this.state.formPartner.id, callback, this.state.feladatData[idx].forras), 1000)}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.id}
              isLoading={false}
              value={this.state.feladatData[idx].feladat}
              cache={false}
              autoload={false}
            /> */}
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name={'formFeladat' + idx}
              id={'formFeladat' + idx}
              placeholder="Kezdjen el gépelni a kereséshez..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeFeladat(v, idx)}
              isClearable
              isDisabled={!this.state.feladatData[idx].forras || this.state.feladatData[idx].forras === '' || this.isFormView() || (this.state.formMunkalapAllapot !== 'ROGZITETT')}
              ignoreAccents={false}
              options={this.state.formFeladatOptions[idx]}
              isLoading={this.state.isFeladatInputLoading}
              onFocus={() => this.maybeLoadOptions(idx)}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.id}
              value={this.state.feladatData[idx].feladat}
            />
          </div>

          <div className="col-md-12">
            {{
              FELADATLISTA: (this.renderFeladatlistaContent(idx)),
              KARBANTARTAS: (this.renderKarbantartasContent(idx)),
              RENDELES: (this.renderRendelesContent(idx)),
              ALTALANOS: ('ALTALANOS')
            }[this.state.feladatData[idx].forras]}
          </div>

          <div
            className="col-md-12"
                // style={{ textAlign: 'center' }}
          >
            <hr style={{
              border: 0,
              height: 2,
              backgroundImage: 'linear-gradient(to right, transparent, #CCC, transparent)'
            }}
            />
          </div>
        </React.Fragment>
      );
    });
  }

  renderFeladatlistaContent = (idx) => {
    return (
      <React.Fragment>
        <b>Feladat azonosítója: </b>
        {this.state.feladatData[idx].feladat.azonosito}
        <br />
        <b>Feladat típusa: </b>
        {this.state.feladatData[idx].feladat.tipus}
        <br />
        <b>Cím: </b>
        {this.state.feladatData[idx].feladat.cim}
        <br />
        <b>Esedékesség: </b>
        {this.state.feladatData[idx].feladat.esedekesseg && this.timezoneToDateTime(this.state.feladatData[idx].feladat.esedekesseg)}
        <br />
        <b>Feladat leírása: </b>
        <br />
        <span style={{ whiteSpace: 'pre-line' }}>
        {this.state.feladatData[idx].feladat.leiras}
        </span>
        {this.state.feladatData[idx].feladat.publeiras && (
          <React.Fragment>
            <br />
            <b>Karbantartás leírása: </b>
            <br />
            {this.state.feladatData[idx].feladat.publeiras}
          </React.Fragment>
        )}
        <br />
        <br />
        <b>
        {this.state.feladatData[idx].feladat.cikklabel}
        {(this.state.feladatData[idx].feladat.cikklabel && this.state.feladatData[idx].feladat.cikklabel !== '')
          ? (
            <React.Fragment>
              {' cikkhez tartozó ellenőrzési listák:'}
              <br />
              <br />
            </React.Fragment>
          ) : null}
        </b>
        {this.renderKarbantartandoCikkek(idx)}
        <br />
        {/* <FormGroup>
          <Label for="offlineselect">Feladat leírása: </Label>
          <Input
            className="munkalap-profil-textarea"
            type="textarea"
            name="formFeladatLeirasa"
            id="formFeladatLeirasa"
            value={this.state.feladatData[idx].feladat.leiras}
            onChange={e => this.handleFeladatLeirasInputChange(e, idx)}
            maxLength={500}
          />
        </FormGroup> */}
      </React.Fragment>
    );
  }

  renderKarbantartasContent = (idx) => {
    return (
      <React.Fragment>
        <b>Feladat azonosítója: </b>
        {this.state.feladatData[idx].feladat.azonosito}
        <br />
        <b>Cím: </b>
        {this.state.feladatData[idx].feladat.cim}
        <br />
        <b>Leírás: </b>
        {this.state.feladatData[idx].feladat.leiras}
        <br />
        <b>Időpont: </b>
        {this.state.feladatData[idx].feladat.idopont}
        <br />
        <br />
        <b>
          {this.state.feladatData[idx].feladat.cikklabel}
          {(this.state.feladatData[idx].feladat.cikklabel && this.state.feladatData[idx].feladat.cikklabel !== '') ? ' cikkhez tartozó ellenőrzési listák:' : null}
        </b>
        <br />
        <br />
        {this.renderKarbantartandoCikkek(idx)}
      </React.Fragment>
    );
  }

  getAlkalomIdFromList = (lista) => {
    let result = -1;
    if (lista && lista[0] && lista[0].alkalomid) {
      result = lista[0].alkalomid;
    }
    return result;
  }

  renderKarbantartandoCikkek = (idx) => {
    const rawData = this.state.feladatData[idx].feladat.ellenorzesilistak;
    let szempontid = '';
    if (this.state.feladatData[idx].feldat && this.state.feladatData[idx].feldat !== '') {
      szempontid = JSON.parse(JSON.stringify(this.state.feladatData[idx].feladat.id));
    }
    let ellonorzolistak = [];
    for (const property in rawData) {
      ellonorzolistak.push({
        szempontid: szempontid,
        id: property.split('|')[0],
        nev: property.split('|')[1],
        lista: rawData[property],
        alkalomid: this.getAlkalomIdFromList(rawData[property]),
        open: false
      });
    }
    return (
      ellonorzolistak.map((ellenorzolista) => {
        return this.renderEllenorzoListaCard(ellenorzolista);
      })
    );
  }

  renderEllenorzesiSzempontok = (ellenorzolistak) => {
    // fa fa-square-o
    // fa fa-plus-square-o
    // fa fa-check-square-o
    return ellenorzolistak.lista.map((ellenorzolista) => {
      return (
        <div className="row" style={{ marginTop: 15, marginBottom: 5 }}>
          <div className="col-md-6" style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            {ellenorzolista.szempontnev}
            <span style={{
              fontSize: 20, paddingRight: 0, paddingLeft: 10, width: 30
            }}
            >
              <i
                onClick={() => {
                  if (this.state.formMunkalapAllapot === 'FOLYAMATBAN' && !this.isFormView()) {
                    this.changeEllenorzesiSzempontValue(ellenorzolistak.id, ellenorzolista.elllistatetelid, ellenorzolista.alkalomid);
                  }
                }}
                className={this.getEllenorzesiSzempontCheckboxIcon(ellenorzolistak.id, ellenorzolista.elllistatetelid, ellenorzolista.alkalomid)}
                style={{ fontSize: 20, cursor: 'pointer' }}
                aria-hidden="true"
              />
            </span>
          </div>
          <div className="col-md-6">
            <Input
              disabled={this.state.formMunkalapAllapot !== 'FOLYAMATBAN' || this.isFormView()}
              value={this.getEllenorzesiSzempontMegjegyzesValue(ellenorzolistak.id, ellenorzolista.elllistatetelid, ellenorzolista.alkalomid)}
              onChange={e => this.handleEllenorzesiSzempontMegjegyzesChange(e, ellenorzolistak.id, ellenorzolista.elllistatetelid, ellenorzolista.alkalomid)}
            />
          </div>
        </div>
      );
    });
  }

  renderEllenorzoListaCard = (ellenorzolista) => {
    return (
      <React.Fragment>
        <Card
          body
          color="primary"
          inverse
          style={{ cursor: 'pointer', marginBottom: 10 }}
          onClick={() => this.toggleEllenorzesiLista(ellenorzolista.id, ellenorzolista.alkalomid)}
        >
          <CardTitle tag="h5" style={{ marginBottom: 0 }}>
            {ellenorzolista.nev}
            <i className={('pull-right fa fa-arrow-circle-') + (this.isEllenorzesiListaOpen(ellenorzolista.id, ellenorzolista.alkalomid) ? 'up' : 'down')} aria-hidden="true" />
          </CardTitle>
        </Card>
        {this.isEllenorzesiListaOpen(ellenorzolista.id, ellenorzolista.alkalomid) && (
          <Card>
            <CardBody>
              <div className="row">
                <div className="col-md-6" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <b>Ellenőrzési szempontok</b>
                </div>
                <div className="col-md-6">
                  <b>Megjegyzések</b>
                </div>
              </div>
              {this.renderEllenorzesiSzempontok(ellenorzolista)}
            </CardBody>
          </Card>
        )}
      </React.Fragment>
    );
  }

  renderRendelesContent = (idx) => {
    return (
      <React.Fragment>
        <b>Bizonylatszám: </b>
        {this.state.feladatData[idx].feladat.bizonylatszam}
        <br />
        <br />
      </React.Fragment>
    );
  }

  renderCardMegjegyzesLogic = () => {
    return (
      <React.Fragment>
        <FormGroup>
          <Input
            className="munkalap-profil-textarea"
            type="textarea"
            name="formMegjegyzes"
            id="formMegjegyzes"
            value={this.state.formMegjegyzes}
            onChange={this.handleInputChange}
            disabled={this.isFormView()}
          />
        </FormGroup>
      </React.Fragment>
    );
  }

  renderCardAlairasLogic = () => {
    return (
      <React.Fragment>
        {this.isFormEdit() && (
          <SignaturePad ref="SignaturePad" />
        )}
        {/* <Input id="base64" name="base64" value={this.state.base64} onChange={this.handleInputChange} type="textarea" />
        <Button onClick={() => {
          let signature = this.refs.SignaturePad;
          this.setState({
            base64: signature.toDataURL()
          });
          //console.log(signature.toDataURL());
        }}>TOBASE64</Button>
        {' '}
        <Button color="primary" onClick={() => {
          let signature = this.refs.SignaturePad;
          signature.fromDataURL(this.state.base64);
        }}>FROMBASE64</Button>
        {' '}
        */}
        {(this.isFormView() && (this.state.base64 === '' || !this.state.base64)) && (
          <div className="row">
            <div className="col-md-12" style={{ textAlign: 'center', marginTop: 10 }}>
              <h6>Elektronikus aláírás hiányzik</h6>
            </div>
          </div>
        )}
        {(this.isFormView() && this.state.base64 !== '' && this.state.base64) && (<img style={{ height: 150, width: '100%' }} src={this.state.base64} alt="Alairas" />)}
        {this.isFormEdit() && (
          <Button
            color="success"
            onClick={() => {
              let signature = this.refs.SignaturePad;
              signature.clear();
            }}
          >
            Törlés
          </Button>
        )}

      </React.Fragment>
    );
  }

  renderCardDokumentumokLogic = () => {
    const titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };

    // TODO átírni rendes feltételre, akkor hamis, ha a megtekintésből lett megnyitva a form
    const isNotView = true;

    return (
      <React.Fragment>
        {(this.isFormEdit() || this.isFormCreate()) && (
          <React.Fragment>
            <div className="row">
              <div className="col-md-12">
                <h5 style={titleStyle}>Fájl feltöltése:</h5>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <DropZone
                  multiple
                  onDrop={this.onDropObject}
                  className="custom-dropzone"
                >
                  <div className="dropzone-text">
                    {'Húzd ide a fájlokat vagy kattints a négyzetbe egy kép feltöltéséhez!'}
                  </div>
                </DropZone>
              </div>
            </div>
          </React.Fragment>
        )
       }

        <div className="row">
          {(this.state.files && this.state.files.length === 0) && (this.isFormView()) ? (
            <div className="col-md-12" style={{ textAlign: 'center', marginTop: 10 }}>
              <h6>Nincs feltöltött dokumentum</h6>
            </div>
          ) : null
          }
          {this.state.files && this.state.files.map((item, idx) => {
            return (
              <div className="col-md-4" key={idx.toString()}>
                <FileCard
                  key={idx.toString()}
                  mime={item.mime}
                  title={item.docname}
                  length={this.formatBytes(item.length)}
                  onDownloadClick={() => this.downloadObject(item)}
                  onDeleteClick={(this.isFormEdit() || this.isFormCreate()) ? () => this.removeObject(item) : null}
                />
              </div>
            );
          })}
        </div>
      </React.Fragment>
    );
  }

  renderCardCegadatLogic = () => {
    return (
      <div className="row">
        <div className="col-sm-12">
          <FormGroup>
            {/* <Label for="formCegadat">Kiállító adatai:</Label> */}
            <Input
              type="select"
              name="formCegadat"
              id="formCegadat"
              value={this.state.formCegadat}
              onChange={this.handleCegadatChange}
              disabled={(this.isFormView() || (this.state.formMunkalapAllapot !== 'ROGZITETT'))}
            >
              <option value="">Válassz cégadatot...</option>
              {this.renderCegadatOptions()}
            </Input>
          </FormGroup>
        </div>
        <div className="col-sm-4">
          <b>Név:</b>
        </div>
        <div className="col-sm-8">
          {this.state.formCegadatValues.nev ? this.state.formCegadatValues.nev : '-'}
        </div>
        <div className="col-sm-4">
          <b>Cím:</b>
        </div>
        <div className="col-sm-8">
          {this.state.formCegadatValues.cim ? this.state.formCegadatValues.cim : '-'}
        </div>
        <div className="col-sm-4">
          <b>Adószám:</b>
        </div>
        <div className="col-sm-8">
          {this.state.formCegadatValues.adoszam ? this.state.formCegadatValues.adoszam : '-'}
        </div>
        <div className="col-sm-4">
          <b>Telefonszám:</b>
        </div>
        <div className="col-sm-8">
          {this.state.formCegadatValues.tel ? this.state.formCegadatValues.tel : '-'}
        </div>
        <div className="col-sm-4">
          <b>Email:</b>
        </div>
        <div className="col-sm-8">
          {this.state.formCegadatValues.email ? <a href={'mailto:' + this.state.formCegadatValues.email}>{this.state.formCegadatValues.email}</a> : '-'}
        </div>
        <div className="col-sm-4">
          <b>Honlap:</b>
        </div>
        <div className="col-sm-8">
          {this.state.formCegadatValues.honlap ? <a href={this.createHTTPSUrl()}>{this.state.formCegadatValues.honlap}</a> : '-'}
        </div>
      </div>
    );
  }

  createHTTPSUrl = () => {
    const rawUrl = JSON.parse(JSON.stringify(this.state.formCegadatValues.honlap));
    if (rawUrl.startsWith('http')) {
      return rawUrl;
    }
    return 'http://' + rawUrl;
  }


  renderCardPartnerLogic = () => {
    return (
      <div className="row">
        <div className="col-sm-12" style={{ marginBottom: 10 }}>
          {/* <Label for="formPartner">Ügyfél adatai:</Label>
          <Select.Async
            name="formPartner"
            id="formPartner"
            placeholder="Kezdjen el gépelni..."
            noResultsText="Nincs keresési eredmény"
            searchPromptText="Kezdjen el gépelni a kereséshez..."
            loadingPlaceholder="Keresés..."
            onChange={v => this.handleChangePartner(v)}
            clearable
            ignoreAccents={false}
            loadOptions={_.debounce(this.loadPartnerChange, 1000)}
            isLoading={false}
            valueKey="id"
            labelKey="nev"
            value={this.state.formPartner}
            cache={false}
            autoload={false}
    /> */}
          {/* <Label for="formPartner">Ügyfél adatai: </Label> */}
          <SelectAsync
            styles={{
              control: base => ({
                ...base,
                '&:focus': { borderColor: 'red' }, // border style on hover
                border: '1px solid lightgray', // default border color
                boxShadow: 'none', // no box-shadow
                borderRadius: '0'
              }),
            }}
            name="formPartner"
            id="formPartner"
            placeholder="Kezdjen el gépelni a kereséshez..."
            noOptionsMessage={() => 'Nincs keresési eredmény'}
            searchPromptText="Kezdjen el gépelni a kereséshez..."
            loadingMessage={() => 'Keresés...'}
            onChange={v => this.handleChangePartner(v)}
            isClearable
            ignoreAccents={false}
            // loadOptions={(input, callback) => this.loadKeresesOptions(input, callback)}
            loadOptions={_.debounce(this.loadPartnerChange, 1000)}
            getOptionLabel={option => option.search}
            getOptionValue={option => option.id}
            isLoading={false}
            isDisabled={(this.isFormView() || (this.state.formMunkalapAllapot !== 'ROGZITETT'))}
            value={this.state.formPartner}
            cache={false}
            autoload={false}
          />
        </div>
        <div className="col-sm-4">
          <b>Név:</b>
        </div>
        <div className="col-sm-8">
          {(this.state.formPartnerValues.nev && this.state.formPartnerValues.azon)
            ? (this.state.formPartnerValues.nev + ' (' + this.state.formPartnerValues.azon + ')') : '-'}
        </div>
        <div className="col-sm-4">
          <b>Cím:</b>
        </div>
        <div className="col-sm-8">
          {this.state.formPartnerValues.cim ? this.state.formPartnerValues.cim : '-'}
        </div>
        <div className="col-sm-4">
          <b>Telefonszám:</b>
        </div>
        <div className="col-sm-8">
          {this.state.formPartnerValues.telefon ? this.state.formPartnerValues.telefon : '-'}
        </div>
        <div className="col-sm-4">
          <b>Email:</b>
        </div>
        <div className="col-sm-8">
          {this.state.formPartnerValues.email ? this.state.formPartnerValues.email : '-'}
        </div>
      </div>
    );
  }

  handleEditClick = (id) => {
  }

  handleDeleteClick = (cell) => {
    this.setState({
      torlesId: cell
    }, () => {
      this.toggleTorles();
    });
  }

  renderCardCegadat = () => {
    return (
      <div className="card">
        <div className="card-header">
          {'Kiállító adatai:'}
          <CustomTooltip text={getTooltip('kiallito_adatai')} id="kiallito_adataiTT" />
        </div>
        <div className="card-body-content">
          {{
            LOAD: (<div className="col-md-12"><Loader /></div>),
            NETW: (<NetworkEmptyState
              hasImage
              submitClick={() => this.listActiveTelephely()}
            />),
            DATA: (this.renderCardCegadatLogic())
          }[this.state.cegadatCardStatus]}
        </div>
      </div>
    );
  }

  renderCardPartner = () => {
    return (
      <div className="card">
        <div className="card-header">
          {'Partner adatai:'}
          <CustomTooltip text={getTooltip('partner_adatai')} id="partner_adataiTT" />
        </div>
        <div className="card-body-content">
          {this.renderCardPartnerLogic()}
        </div>
      </div>
    );
  }

  renderCardLeiras = () => {
    return (
      <div className="card">
        <div className="card-header">
          {'Leírás:'}
        </div>
        <div className="card-body-content">
          {this.renderCardLeirasLogic()}
        </div>
      </div>
    );
  }

  renderCardSpecialis = () => {
    return (
      <div className="card">
        <div className="card-header">
          {'Speciális:'}
        </div>
        <div className="card-body-content">
          {this.renderCardSpecialisLogic()}
        </div>
      </div>
    );
  }

  renderCardSpecialisFeladat = () => {
    return (
      <div className="card">
        <div className="card-header">
          {'Feladat választás:'}
          <CustomTooltip text={getTooltip('feladat_valasztas')} id="feladat_valasztasTT" />
        </div>
        <div className="card-body-content">
          {this.renderCardSpecialisFeladatLogic()}
        </div>
      </div>
    );
  }

  renderCardCikk = () => {
    return (
      <MunkalapCikkCard
        munkalapId={this.state.munkalapId}
        emptyOrNull={this.emptyOrNull}
        globals={this.props.route.globals}
        isFolyamatban={this.isFormEdit() && this.state.formMunkalapAllapot === 'FOLYAMATBAN'}
        isView={this.isFormView() && this.state.formMunkalapAllapot === 'FOLYAMATBAN'}
        isLezart={this.state.formMunkalapAllapot === 'LEZART'}
        specialisok={this.state.feladatData}
        ref={(cardRef) => { this.cikkCardRef = cardRef; }}
      />
    );
  }

  renderCardMegjegyzes = () => {
    return (
      <div className="card">
        <div className="card-header">
          {'Megjegyzés:'}
        </div>
        <div className="card-body-content">
          {/* {
            LOAD: (<div className="col-md-12"><Loader /></div>),
            NETW: (<NetworkEmptyState
              hasImage
              submitClick={() => this.getMegjegyzes()}
            />),
            DATA: (this.renderCardMegjegyzesLogic())
          }[this.state.megjegyzesCardStatus] */}
          {this.renderCardMegjegyzesLogic()}
        </div>
      </div>
    );
  }

  renderCardAlairas = () => {
    return (
      <div className="card">
        <div className="card-header">
          {'Aláírás:'}
          <CustomTooltip text={getTooltip('alairas')} id="alairasTT" />
        </div>
        <div className="card-body-content">
          {this.renderCardAlairasLogic()}
        </div>
      </div>
    );
  }

  renderCardDokumentumok = () => {
    return (
      <div className="card">
        <div className="card-header">
          {'Kapcsolódó dokumentumok:'}
          <CustomTooltip text={getTooltip('kapcs_dok')} id="kapcs_dokTT" />
        </div>
        <div className="card-body-content">
          {this.renderCardDokumentumokLogic()}
        </div>
      </div>
    );
  }

  renderCardTipus = () => {
    const cardStyle = {
      padding: 20
    };

    return (
      <div className="animated fadeIn">
        <div className="card">
          <div className="card-header">
            {'Munkalap típus választás'}
          </div>
          <div className="row" style={cardStyle}>
            {{
              LOAD: (<div className="col-md-12"><Loader /></div>),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.getMunkalapInit()}
              />),
              DATA: (this.renderCardTipusLogic())
            }[this.state.tipusCardStatus]}
          </div>
        </div>
      </div>
    );
  }

  setActiveVizsgalatiEredmeny = (id, hely) => {
    let newVizsgalatiEredmeny = JSON.parse(JSON.stringify(this.state.formVizsgalatiEredmenyOptions));

    newVizsgalatiEredmeny.forEach((item) => {
      if (item.id === id) {
        item.aktiv = true;
      } else {
        item.aktiv = false;
      }
    });

    this.setState({
      formVizsgalatiEredmenyOptions: newVizsgalatiEredmeny,
      vizsgalatEredmenyData: newVizsgalatiEredmeny.filter((item) => { return item.id === id; }),
      formSpecialisID: id,
      formSpecialisHely: hely
    });
  }

  renderVizsgalatiEredmenyek = () => {
    if (this.state.formVizsgalatiEredmenyOptions.length === 0) return 'Az ügyfélhez nincs vizsgálati adat hozzárendelve!';
    let listItems = [];
    listItems = this.state.formVizsgalatiEredmenyOptions.map((item) => {
      return (
        <ListGroupItem
          key={item.id}
          onClick={() => this.setActiveVizsgalatiEredmeny(item.id, item.hely)}
          className="list-group-vizsgalat-item"
          active={item.aktiv}
          style={{
            borderStyle: 'dashed',
            borderWidth: '2px',
            borderColor: '#20a8d8',
            marginBottom: 10,
            cursor: 'pointer'
          }}
        >
          {item.datum + ': ' + item.vizsgalatok}
        </ListGroupItem>
      );
    });
    return (
      <ListGroup flush>
        {listItems}
      </ListGroup>
    );
  }

  renderFormButtons = () => {
    return (
      <React.Fragment>
        {(this.isFormCreate() || this.isFormEdit()) && (
          <Button
            color="primary"
            onClick={() => {
              if (this.state.munkalapId && this.state.munkalapId !== '') {
                this.modifyFormBack();
              } else {
                this.saveFormBack();
              }
            }}
          >
            {'Mentés'}
            <CustomTooltip text={getTooltip('mentes')} id="mentesiTT" />
          </Button>
        )}
        {' '}
        {(this.isFormCreate() || (this.isFormEdit() && this.state.formMunkalapAllapot === 'ROGZITETT')) && (
          <Button
            color="primary"
            onClick={this.startMunkalap}
          >
            {'Munka elkezdése'}
            <CustomTooltip text={getTooltip('munka_elkezd')} id="munka_elkezdTT" />
          </Button>
        )}
        {' '}
        {(this.isFormEdit() && this.state.formMunkalapAllapot === 'FOLYAMATBAN') && (
          <Button
            color="primary"
            onClick={this.toggleBefejezesModal}
          >
            {'Munka befejezése'}
            <CustomTooltip text={getTooltip('munka_befejez')} id="munka_befejezTT" />
          </Button>
        )}
        {' '}
        <Button
          color="secondary"
          onClick={() => { this.onBack(null); }}
        >
          {'Vissza'}
        </Button>
      </React.Fragment>
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          {(!this.state.munkalapTypeId || this.state.munkalapTypeId === '') ? this.renderCardTipus() : null }
        </div>
        { this.state.munkalapTypeId && this.state.munkalapTypeId !== '' && (
          <React.Fragment>
            <div className="col-md-6 col-lg-6">
              {this.renderCardCegadat()}
            </div>
            <div className="col-md-6 col-lg-6">
              {this.renderCardPartner()}
            </div>
            <div className="col-md-12 col-lg-12">
              {this.state.leiraskezeles && this.renderCardLeiras()}
            </div>
            <div className="col-md-12 col-lg-12">
              {this.state.specialiskezeles && this.renderCardSpecialisFeladat()}
            </div>
            <div className="col-md-12 col-lg-12">
              {false && this.renderCardSpecialis()}
            </div>
            <div className="col-md-12 col-lg-12">
              {this.state.cikkkezeles && this.renderCardCikk()}
            </div>
            <div className="col-md-12 col-lg-12">
              {this.state.megjegyzeskezeles && this.renderCardMegjegyzes()}
            </div>
            <div className="col-md-12 col-lg-12">
              {(this.state.alairaskezeles) && ((this.isFormEdit() && this.state.formMunkalapAllapot !== 'ROGZITETT') || this.isFormView()) ? this.renderCardAlairas() : null}
            </div>
            <div className="col-md-12 col-lg-12">
              {this.renderCardDokumentumok()}
            </div>
            <div className="col-md-12 col-lg-12" style={{ marginBottom: 20 }}>
              {this.renderFormButtons()}
            </div>
          </React.Fragment>
        )}
        <Modal isOpen={this.state.modalVizsgalatiEredmeny} toggle={this.toggleVizsgalatiEredmenyModal} backdrop="static">
          <ModalHeader>
            {'Vizsgálati eredmény kiválasztása'}
          </ModalHeader>
          <ModalBody>
            {{
              LOAD: (<Loader />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.onVizsgalatClick()}
              />),
              DATA: (this.renderVizsgalatiEredmenyek())
            }[this.state.vizsgalatiEredmenyStatus]}
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-cursor-pointer"
              color="primary"
              disabled={!this.state.vizsgalatEredmenyData}
              onClick={this.onVizsgalatMentes}
            >
              {'Mentés'}
            </Button>
            <Button type="button" color="secondary" className="btn-cursor-pointer" onClick={this.toggleVizsgalatiEredmenyModal}>Mégsem</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalBefejezes} toggle={this.toggleBefejezesModal} backdrop="static">
          <ModalHeader>
            {'Munka befejezése'}
          </ModalHeader>
          <ModalBody>
            Munka befejezése után a munkalap lezárásra kerül, az adatok nem módosíthatók!
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-cursor-pointer"
              color="primary"
              onClick={this.closeMunkalap}
            >
              {'Befejezés'}
            </Button>
            <Button type="button" color="secondary" className="btn-cursor-pointer" onClick={this.toggleBefejezesModal}>Mégsem</Button>
          </ModalFooter>
        </Modal>

      </div>
    );
  }
}

MunkalapForm.propTypes = {
  globals: PropTypes.object
};

export default MunkalapForm;
