import React, { Component } from 'react';
import {
  Row,
  Col,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  UncontrolledTooltip
} from 'reactstrap';
// import moment from 'moment';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import {
  AvInput,
  AvForm,
  AvGroup,
  AvFeedback
} from 'availity-reactstrap-validation';
import CreatableSelect from 'react-select/lib/Creatable';
import { PaginationTotal } from '../../../commons/controls';
// import FileSaver from 'file-saver';
import Lib from '../../../commons/lib';
import Service from './services';
import { ProgressButton } from '../../../commons/ProgressButton';

class SzemelyitorzsLista extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalNezet: false,
      modalTorles: false,
      keycloakTable: false,
      szemelyJson: [],
      keycloakJson: [],
      dataLoaded: false,
      dataViewLoaded: false,
      dataFailed: false,
      szemelyNezetObj: {},
      selectedSzemely: {},
      formType: 'FEL',
      beosztasOptions: [],
      helyszinekOptions: [],
      selectedKeycloakUser: [],
      torlesId: null,
      statusz: [],
      statuszOptions: [],
      filterOptions: {},
    };
  }

  componentDidMount() {
    this.updateTable();
    this.props.globals.keycloak.hasResourceRole('ROLE_SZERVEZET_SZEMELYI_KEYCLOAK') && this.getKeycloakUsers();
    this.getHelyszinekOptions();
    this.getBeosztasokOptions();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.type === 'SZEMELYITORZS') {
      this.updateTable();
      this.props.globals.keycloak.hasResourceRole('ROLE_SZERVEZET_SZEMELYI_KEYCLOAK') && this.getKeycloakUsers();
      this.getHelyszinekOptions();
      this.getBeosztasokOptions();
    }
  }

  getHelyszinekOptions = () => {
    Service.listHelyszin((err, res) => {
      if (!err) {
        let helyszinekOptions = [];
        res[0].forEach((item) => {
          helyszinekOptions.push({
            id: item.id,
            label: item.nev
          });
        });
        this.setState({ helyszinekOptions: helyszinekOptions });
      }
    });
  }

  getBeosztasokOptions = () => {
    Service.listBeosztasok((err, res) => {
      if (!err) {
        this.setState({
          beosztasOptions: res[0].map(e => ({ value: e.id, label: e.nev }))
        });
      }
    });
  }

  onSubmit = () => {
    let submitObj = {};
    if (this.state.formType === 'FEL') {
      submitObj.id = null;
      submitObj.statusz = this.state.selectedSzemely.statusz;
      submitObj.nev = this.state.selectedSzemely.nev;
      submitObj.rovidnev = this.state.selectedSzemely.rovidnev ? this.state.selectedSzemely.rovidnev : '';
      submitObj.beosztas = this.state.selectedSzemely.beosztas && this.state.selectedSzemely.beosztas.label;
      submitObj.beosztasId = this.state.selectedSzemely.beosztas && this.state.selectedSzemely.beosztas.value;
      submitObj.megjegyzes = this.state.selectedSzemely.megjegyzes;
      submitObj.email = this.state.selectedSzemely.email;
      submitObj.telefonszam = this.state.selectedSzemely.telefonszam;
      submitObj.helyszinIds = this.state.selectedSzemely.helyszinIds;

      Service.addSzemely(submitObj, (err) => {
        if (!err) {
          this.toggle();
          this.updateTable();
          this.props.globals.addSuccess('Személy sikeresen hozzáadva!');
        }
      });
    }

    if (this.state.formType === 'MOD') {
      submitObj.id = this.state.selectedSzemely.id;
      submitObj.statusz = this.state.selectedSzemely.statusz;
      submitObj.nev = this.state.selectedSzemely.nev;
      submitObj.rovidnev = this.state.selectedSzemely.rovidnev ? this.state.selectedSzemely.rovidnev : '';
      submitObj.beosztas = this.state.selectedSzemely.beosztas && this.state.selectedSzemely.beosztas.label;
      submitObj.beosztasId = this.state.selectedSzemely.beosztas && this.state.selectedSzemely.beosztas.value;
      submitObj.megjegyzes = this.state.selectedSzemely.megjegyzes;
      submitObj.email = this.state.selectedSzemely.email;
      submitObj.telefonszam = this.state.selectedSzemely.telefonszam;
      submitObj.helyszinIds = this.state.selectedSzemely.helyszinIds;
      this.toggle();
      Service.editSzemely(submitObj, (err) => {
        if (!err) {
          this.updateTable();
          this.props.globals.addSuccess('Személy sikeresen módosítva!');
        }
      });
    }
  }

  updateTable = () => {
    this.setState({
      szemelyJson: [],
      dataLoaded: false,
      dataFailed: false
    });
    Service.listSzemely((err, res) => {
      if (!err) {
        let szemelyekJson = JSON.parse(JSON.stringify(res[0].list));
        szemelyekJson.forEach((szemely) => {
          let helyszinekString = '';
          szemely.helyszinek.forEach((helyszin, idx) => {
            helyszinekString += helyszin.nev;
            if (idx < szemely.helyszinek.length - 1) {
              helyszinekString += ', ';
            }
          });
          szemely.helyszin = helyszinekString;
        });

        let filters = {};
        let options = res[0].statusz.map((e) => {
          filters[e.name] = e.caption;

          return <option key={e.name} value={e.name}>{e.caption}</option>;
        });

        this.setState({
          szemelyJson: szemelyekJson,
          dataFailed: false,
          filterOptions: filters,
          statusz: res[0].statusz,
          statuszOptions: options

        }, () => { this.setState({ dataLoaded: true }); });
      } else {
        this.setState({ dataLoaded: true, dataFailed: true });
      }
    });
  }

  toggle = (set) => {
    this.setState(prevState => ({ modalForm: !prevState.modalForm }));

    if (set) {
      Service.listHelyszin((err, res) => {
        if (!err && res[0].length === 1) {
          let szemely = JSON.parse(JSON.stringify(this.state.selectedSzemely));
          let helyszinek = [];
          let helyszinIds = [];
          helyszinek.push({ id: res[0][0].id, label: res[0][0].nev });
          helyszinIds.push(res[0][0].id);

          szemely.helyszinek = helyszinek;
          szemely.helyszinIds = helyszinIds;
          this.setState({ selectedSzemely: szemely });
        }
      });
    }
  }

  toggleKeycloack = () => {
    this.setState(prevState => ({ keycloakTable: !prevState.keycloakTable }));
  }

  toggleNezet = () => {
    this.setState(prevState => ({ modalNezet: !prevState.modalNezet }));
  }

  toggleTorles = () => {
    this.setState(prevState => ({ modalTorles: !prevState.modalTorles }));
  }

  renderFormTitle = () => {
    switch (this.state.formType) {
      case 'FEL':
        return 'Személy felvitele';
      case 'MOD':
        return 'Személy módosítása';
      default:
        return '';
    }
  }

  onNevChange = (e) => {
    let szemely = JSON.parse(JSON.stringify(this.state.selectedSzemely));
    szemely.nev = e.target.value;
    this.setState({
      selectedSzemely: szemely
    });
  }

  onRovidNevChange = (e) => {
    let szemely = JSON.parse(JSON.stringify(this.state.selectedSzemely));
    szemely.rovidnev = e.target.value;
    this.setState({
      selectedSzemely: szemely
    });
  }

  onStatuszChange = (e) => {
    let szemely = JSON.parse(JSON.stringify(this.state.selectedSzemely));
    szemely.statusz = e.target.value;
    this.setState({
      selectedSzemely: szemely
    });
  }

  onBeosztasChange = (e) => {
    let szemely = JSON.parse(JSON.stringify(this.state.selectedSzemely));
    szemely.beosztas = e.target.value;
    this.setState({
      selectedSzemely: szemely
    });
  }

  onMegjegyzesChange = (e) => {
    let szemely = JSON.parse(JSON.stringify(this.state.selectedSzemely));
    szemely.megjegyzes = e.target.value;
    this.setState({
      selectedSzemely: szemely
    });
  }

  onTelefonChange = (e) => {
    let szemely = JSON.parse(JSON.stringify(this.state.selectedSzemely));
    szemely.telefonszam = e.target.value;
    this.setState({
      selectedSzemely: szemely
    });
  }

  onEmailChange = (e) => {
    let szemely = JSON.parse(JSON.stringify(this.state.selectedSzemely));
    szemely.email = e.target.value;
    this.setState({
      selectedSzemely: szemely
    });
  }

  /** Módosítás gombra kattintás */
  handleEditClick = (id) => {
    this.setState({ dataViewLoaded: false, formType: 'MOD' });
    Service.getSzemely(id, (err, res) => {
      if (!err) {
        let selectedSzemely = res[0];
        let helyszinek = [];
        let helyszinIds = [];
        let beosztasok = {};
        if (selectedSzemely.beosztas) {
          beosztasok = { value: selectedSzemely.beosztas.id, label: selectedSzemely.beosztas.nev };
        }
        selectedSzemely.beosztas = beosztasok;
        selectedSzemely.helyszinek.forEach((item) => {
          helyszinek.push({ id: item.id, label: item.nev });
          helyszinIds.push(item.id);
        });
        selectedSzemely.helyszinek = helyszinek;
        selectedSzemely.helyszinIds = helyszinIds;
        this.setState({ selectedSzemely: selectedSzemely, dataViewLoaded: true });
      }
    });
    this.toggle();
  }

  handleDeleteClick = (id) => {
    this.setState({ torlesId: id });
    this.toggleTorles();
  }

  handleViewClick = (id) => {
    this.setState({ dataViewLoaded: false });
    Service.getSzemely(id, (err, res) => {
      if (!err) {
        this.setState({
          szemelyNezetObj: res[0],
          dataViewLoaded: true
        });
      }
    });
    this.toggleNezet();
  }

  iconFormatter = (cell) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'SZERVEZET_SZEMELYI', 'LEK') && (
          <React.Fragment>
            <Button aria-label="Megtekintés" className="icon-eye table-action-icon" id={'action-view-' + cell + 'megtekintes'} onClick={() => this.handleViewClick(cell)} />
            <UncontrolledTooltip style={{ float: 'top' }} placement="top" target={'action-view-' + cell + 'megtekintes'} delay={0}>
              Megtekintés
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'SZERVEZET_SZEMELYI', 'KARB') && (
        <React.Fragment>
          <Button aria-label="Módosítás" className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => this.handleEditClick(cell)} />
          <UncontrolledTooltip style={{ float: 'top' }} placement="top" target={'action-view-' + cell + 'modositas'} delay={0}>
            Módosítás
          </UncontrolledTooltip>
          <Button aria-label="Törlés" className="icon-trash table-action-icon" id={'action-view-' + cell + 'torles'} onClick={() => this.handleDeleteClick(cell)} />
          <UncontrolledTooltip style={{ float: 'top' }} placement="top" target={'action-view-' + cell + 'torles'} delay={0}>
            Törlés
          </UncontrolledTooltip>
        </React.Fragment>
        )}
      </div>
    );
  }

  statusFormatter = (cell) => {
    let statusz = this.state.statusz.find(x => x.name === cell);
    return statusz ? <span className={'badge badge-' + statusz.bagde}>{statusz.caption}</span> : 'N/A';
  }

  statusFilter = (cell) => {
    return cell.caption;
  }

  deleteSzemely = (id) => {
    this.toggleTorles();
    Service.deleteSzemely(id, (err) => {
      if (!err) {
        this.updateTable();
        this.props.globals.addSuccess('Személy törölve!');
      }
    });
  }

  formatCreateLabel = input => (
    <span>
      <i>Hozzáadás: </i>
      {input}
    </span>
  );

  handleBeosztasChange = (newValue) => {
    let szemely = JSON.parse(JSON.stringify(this.state.selectedSzemely));
    szemely.beosztas = newValue;
    this.setState({ selectedSzemely: szemely });
  };

  handleHelyszinChange = (newValue) => {
    let szemely = JSON.parse(JSON.stringify(this.state.selectedSzemely));
    szemely.helyszinek = newValue;
    let helyszinIds = [];
    let helyszinek = [];

    newValue.forEach((item) => {
      helyszinek.push(
        item
      );
      helyszinIds.push(
        item.id
      );
    });

    szemely.helyszinIds = helyszinIds;
    szemely.helyszin = helyszinek;
    this.setState({ selectedSzemely: szemely });
  };

  handleCreateBeosztas = (newBeosztas) => {
    const { beosztasOptions } = this.state;

    Service.addBeosztas(newBeosztas, (err, res) => {
      if (!err) {
        this.setState({
          beosztasOptions: [...beosztasOptions, { value: res[0].id, label: res[0].nev }]
        });
        this.props.globals.addSuccess('Beosztás sikeresen hozzáadva!');
      }
    });
  }

  deleteBeosztas = (id) => {
    const { beosztasOptions } = this.state.beosztasOptions;
    this.setState({
      beosztasOptions: beosztasOptions.filter(beosztas => id !== beosztas.id)
    });
    Service.delBeosztas(id, (err) => {
      if (!err) {
        this.props.globals.addSuccess('Beosztás sikeresen törölve!');
      }
    });
  }

  deleteHelyszin = (id) => {
    const { helyszinekOptions } = this.state.helyszinekOptions;
    this.setState({
      helyszinekOptions: helyszinekOptions.filter(helyszin => id !== helyszin.id)
    });
  }

  renderAdatok = () => {
    if (this.state.szemelyNezetObj) {
      let statusz = this.state.statusz.find(x => x.name === this.state.szemelyNezetObj.statusz);
      return (
        this.state.dataViewLoaded ? (
          <div>
            <h6>Alapadatok:</h6>
            <b>Azonosító: </b>
            {this.state.szemelyNezetObj && this.state.szemelyNezetObj.azonosito}
            <br />
            <b>Név: </b>
            {this.state.szemelyNezetObj && this.state.szemelyNezetObj.nev}
            <br />
            <b>Rövid név: </b>
            {this.state.szemelyNezetObj && this.state.szemelyNezetObj.rovidnev}
            <br />
            <b>Beosztás: </b>
            {(this.state.szemelyNezetObj && this.state.szemelyNezetObj.beosztas) ? this.state.szemelyNezetObj.beosztas.nev : 'Nincs megadott beosztás'}
            <br />
            <b>Státusz: </b>
            {statusz && statusz.caption}
            <br />
            <br />
            <h6>Elérhetőségek:</h6>
            <b>Telefonszám: </b>
            {(this.state.szemelyNezetObj && this.state.szemelyNezetObj) ? this.state.szemelyNezetObj.telefonszam : 'Nincs megadott telefonszáma'}
            <br />
            <b>Email cím: </b>
            {(this.state.szemelyNezetObj && this.state.szemelyNezetObj) ? this.state.szemelyNezetObj.email : 'Nincs megadott email'}
            <br />
            <br />
            <h6>Kiegészítő adatok:</h6>
            <b>Megjegyzés: </b>
            {(this.state.szemelyNezetObj && this.state.szemelyNezetObj) ? this.state.szemelyNezetObj.megjegyzes : 'Nincs megadott megjegyzés'}
            <br />
          </div>
        ) : this.renderLoader()
      );
    }
  }

  renderForm = () => {
    return (
      <div>
        <h6> Személy alapadatai: </h6>
        <AvGroup>
          <div className="row">
            <div className="col-md-6">
              <Label>Név: *</Label>
              <AvInput
                type="text"
                name="nev"
                id="nev"
                required
                onChange={this.onNevChange}
                value={this.state.selectedSzemely.nev}
              />
              <AvFeedback>Ez a mező kitöltése kötelező!</AvFeedback>
            </div>
            <div className="col-md-6">
              <Label>Rövid név: </Label>
              <AvInput
                type="text"
                name="rovidnev"
                id="rovidnev"
                onChange={this.onRovidNevChange}
                value={this.state.selectedSzemely.rovidnev}
              />
            </div>
          </div>
        </AvGroup>
        <AvGroup>
          <div className="row">
            <div className="col-md-12">
              <Label>Státusz: *</Label>
              <AvInput type="select" name="statusz" required onChange={this.onStatuszChange} value={this.state.selectedSzemely ? this.state.selectedSzemely.statusz : 'AKTIV'}>
                {this.state.statuszOptions}
              </AvInput>
              <AvFeedback>Ez a mező kitöltése kötelező!</AvFeedback>
            </div>
          </div>
        </AvGroup>
        <AvGroup>
          <div className="row">
            <div className="col-md-6">
              <Label for="beosztas">Beosztás:</Label>
              <CreatableSelect
                styles={{
                  control: base => ({
                    ...base,
                    '&:focus': { borderColor: 'red' }, // border style on hover
                    border: '1px solid lightgray', // default border color
                    boxShadow: 'none', // no box-shadow
                    borderRadius: '0'
                  }),
                }}
                name="beosztas"
                id="beosztas"
                isClearable
                placeholder="Kezdjen el gépelni..."
                formatCreateLabel={this.formatCreateLabel}
                onChange={this.handleBeosztasChange}
                onCreateOption={this.handleCreateBeosztas}
                onDeleteOption={this.deleteBeosztas}
                isMulti={false}
                options={this.state.beosztasOptions}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.value}
                value={this.state.selectedSzemely.beosztas ? this.state.selectedSzemely.beosztas : ''}
                cache={false}
                autoload={false}
              />
            </div>
            <div className="col-md-6">
              <Label>Megjegyzés:</Label>
              <AvInput
                type="text"
                name="megjegyzes"
                id="megjegyzes"
                onChange={this.onMegjegyzesChange}
                value={this.state.selectedSzemely.megjegyzes ? this.state.selectedSzemely.megjegyzes : ''}
              />
            </div>
          </div>
        </AvGroup>
        <div className="row" style={{ marginBottom: 8 }}>
          <div className="col-md-12">
            <Label>Helyszín: </Label>
            <CreatableSelect
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="helyszinek"
              id="helyszinek"
              isClearable
              placeholder="Kezdjen el gépelni..."
              onChange={this.handleHelyszinChange}
              onDeleteOption={this.deleteHelyszin}
              isMulti
              options={this.state.helyszinekOptions}
              getOptionLabel={option => option.label}
              getOptionValue={option => option.id}
              value={this.state.selectedSzemely.helyszinek && this.state.selectedSzemely.helyszinek}
              cache={false}
              autoload={false}
            />
          </div>
        </div>
        <h6> Személy elérhetőségei: </h6>
        <AvGroup>
          <div className="row">
            <div className="col-md-6">
              <Label>Telefonszám: </Label>
              <AvInput
                type="text"
                name="telefonszam"
                id="telefonszam"
                onChange={this.onTelefonChange}
                value={this.state.selectedSzemely.telefonszam ? this.state.selectedSzemely.telefonszam : ''}
              />
            </div>
            <div className="col-md-6">
              <Label>Email cím: </Label>
              <AvInput
                type="email"
                name="email"
                id="email"
                onChange={this.onEmailChange}
                value={this.state.selectedSzemely.email ? this.state.selectedSzemely.email : ''}
              />
            </div>
          </div>
        </AvGroup>
        <ModalFooter>
          <ProgressButton
            className="btn-cursor-pointer"
            color="primary"
            type="submit"
          >
            {'Mentés'}
          </ProgressButton>
          <Button type="button" color="secondary" onClick={this.toggle}>Mégsem</Button>
        </ModalFooter>
      </div>
    );
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  setButtonStat = (obj) => {
    this.setState(obj);
  }

  csvDecode = (cell, row, items) => {
    return (cell === undefined ? '' : items[row.s]);
  }

  renderSzemelyTable = () => {
    const options = {
      sizePerPage: 25,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 },
      ],
    };

    const columns = [
      {
        dataField: 'azonosito',
        text: 'Azonosító',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        align: 'left',
        sort: true,
      },
      {
        dataField: 'nev',
        text: 'Név',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        align: 'left',
        sort: true,
      },
      {
        dataField: 'email',
        text: 'Email',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        align: 'left',
        sort: true,
      },
      {
        dataField: 'helyszin',
        text: 'Helyszín',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        align: 'left',
        sort: true,
      },
      {
        dataField: 'beosztas.nev',
        text: 'Beosztás',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        align: 'left',
        sort: true,
      },
      {
        dataField: 'statusz',
        text: 'Státusz',
        filter: selectFilter({
          options: this.state.filterOptions,
          placeholder: 'Összes',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true,
        align: 'center',
        formatter: this.statusFormatter
      },
      {
        dataField: 'id',
        csvExport: false,
        text: 'Műveletek',
        hidden: !Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'SZERVEZET_SZEMELYI', 'KARB'),
        align: 'center',
        formatter: this.iconFormatter
      }
    ];
    return (
      <div className="animated fadeIn">
        <BootstrapTable
          bootstrap4
          keyField="azonosito"
          data={this.state.szemelyJson}
          columns={columns}
          filter={filterFactory()}
          pagination={paginationFactory(options)}
          noDataIndication="Nincs megjelenítendő adat!"
        />
      </div>
    );
  }

  getKeycloakUsers = () => {
    this.setState({ dataLoaded: false, dataFailed: false });
    Service.listSzemelyKeycloak((err, res) => {
      if (!err) {
        let users = res[0];
        this.setState({ keycloakJson: users, dataLoaded: true, dataFailed: false });
      } else {
        this.setState({ dataLoaded: true, dataFailed: true });
      }
    });
  }

  masolas = () => {
    Service.keycloakMasolas(this.state.selectedKeycloakUser, (err) => {
      if (!err) {
        this.toggleKeycloack();
        this.updateTable();
        this.props.globals.addSuccess('Személy(ek) sikeresen hozzáadva a személyi törzshöz!');
      }
    });
  }

  handleUserSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState((prevState) => { return { selectedKeycloakUser: [...prevState.selectedKeycloakUser, row] }; });
    } else {
      this.setState((prevState) => {
        return ({ selectedKeycloakUser: prevState.selectedKeycloakUser.filter(element => element !== row) });
      });
    }
  }


  renderKeycloakUsers = () => {
    const options = {
      sizePerPage: 25,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 },
      ],
    };
    const selectRow = {
      mode: 'checkbox',
      onSelect: this.handleUserSelect,
      selected: this.state.selectedKeycloakUser.map((element) => { return element.id; })
    };
    const columns = [
      {
        dataField: 'id',
        text: 'Id',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        hidden: true,
        align: 'left',
        sort: true,
      },
      {
        dataField: 'szemelynev',
        text: 'Név',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        align: 'left',
        sort: true,
      },
      {
        dataField: 'username',
        text: 'Felhasználónév',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        align: 'left',
        sort: true,
      },
      {
        dataField: 'email',
        text: 'E-mail cím',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        align: 'left',
        sort: true,
      }
    ];
    return (
      <div className="animated fadeIn">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={this.state.keycloakJson}
          columns={columns}
          filter={filterFactory()}
          pagination={paginationFactory(options)}
          selectRow={selectRow}
          noDataIndication="Nincs megjelenítendő adat!"
        />
      </div>
    );
  }

  render() {
    return (
      <div>
        <Row>
          <Col lg={12}>
            {this.props.globals.keycloak.hasResourceRole('ROLE_SZERVEZET_SZEMELYI_KEYCLOAK') &&
              <Button className="pull-left table-new-button" color="primary" onClick={this.toggleKeycloack}>Keycloak felhasználók <i className="fa fa-arrow-up" /></Button>
            }
          </Col>
        </Row>
        <br />
        <div hidden={!this.state.keycloakTable}>
          <div>{this.state.dataLoaded && !this.state.dataFailed ? this.renderKeycloakUsers() : (this.state.dataLoaded ? this.renderKeycloakUsers() : this.renderLoader())}</div>
          <div><Button color="success" onClick={this.masolas}>Másolás</Button></div>
        </div>
        <br />
        <Row>
          <Col lg={12}>
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'SZERVEZET_SZEMELYI', 'KARB') && (
              <Button
                color="success"
                onClick={() => {
                  this.setState({ formType: 'FEL', selectedSzemely: {} });
                  this.toggle(true);
                }}
              >
                Új dolgozó hozzáadása
              </Button>
            )
            }
          </Col>
        </Row>
        <br />
        <div>
          {this.state.dataLoaded && !this.state.dataFailed ? this.renderSzemelyTable() : (this.state.dataLoaded ? this.renderSzemelyTable() : this.renderLoader())}
        </div>
        <Modal size="lg" isOpen={this.state.modalForm} backdrop="static">
          <AvForm onValidSubmit={this.onSubmit}>
            <ModalHeader>{this.renderFormTitle()}</ModalHeader>
            <ModalBody>
              {this.renderForm()}
            </ModalBody>
          </AvForm>
        </Modal>
        <Modal isOpen={this.state.modalNezet} toggle={this.toggleNezet} className={this.props.className} backdrop="static">
          <ModalHeader>
            Személy megtekintése
          </ModalHeader>
          <ModalBody>
            {this.renderAdatok()}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" type="button" color="primary" onClick={this.toggleNezet}>OK</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className} backdrop="static">
          <ModalHeader>
            Törlés
          </ModalHeader>
          <ModalBody>
            Valóban törölni kívánja a kiválasztott tételt?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.deleteSzemely(this.state.torlesId)}>
              OK
            </Button>
            <Button type="button" color="secondary" onClick={this.toggleTorles}>
              Mégsem
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default SzemelyitorzsLista;
