import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Badge,
  ListGroup,
  ListGroupItem,
  DropdownToggle,
  Dropdown,
  DropdownMenu,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from 'reactstrap';
import {
  AvForm,
  AvField,
  AvFeedback,
  AvInput,
  AvGroup,
} from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DropZone from 'react-dropzone';
import fileSaver from 'file-saver';
import Select from 'react-select/lib/Async';
import Lib from '../../commons/lib';
import DataEmptyState from '../../commons/dataemptystate';
import { DatePicker, PaginationTotal, FileCard } from '../../commons/controls';
import Loader from '../../commons/loader';
import NetworkEmptyState from '../../commons/networkemptystate';
import QueryEmptyState from '../../commons/queryempty';
import Services from './services';
import NumberFormatter from '../../commons/NumberFormatter';
import { ProgressButton } from '../../commons/ProgressButton';

// 0 -> Felvitel
// 1 -> módosítás
let _muvelet = 0;
let _modositoObj = null;

class BanktetelTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ['QUERY', 'LOAD', 'DATA', 'NETW', 'TABL']
      currentStatusBaseTable: 'QUERY',
      // ['LOAD', 'LOADED', 'NETW']
      // currentStatusBaseView: 'LOAD',
      dataBaseViewLoaded: true, // TODO
      // ['LOAD', 'LOADED', 'NETW']
      currentStatusBaseEdit: 'LOADED',
      // currentViewData: {},
      currentParentId: null,
      // csekkesFeltoltesStep: [1, 2]
      csekkesFeltoltesStep: 1,
      csekkesFileResult: {
        file: '',
        osszesen: 0,
        sikeres: 0,
        sikertelen: []
      },
      disabledButtons: false,
      currentId: null,
      modalBaseView: false,
      modalBaseNewManual: false,
      modalBaseNewFile: false,
      modalBaseDelete: false,
      aktivPenznem: [],
      aktivPenzmozgasTipus: [],
      aktivPenzmozgasJelleg: [],
      aktivSzamlak: [],
      defaultPenznemId: null,
      formPartner: '',
      baseJson: [],
      files: [],

      /*
       *  FORM VALUES
       */
      formSzamlaszam: [],
      formSzamlakibocsato: '',
      formStatus: null,
      mozgastipus: null,
      mozgastipusCaption: null,
      tranzakciotipus: null,
      tranzakciotipusCaption: null,
      befizetoazonosito: '',
      penznem: null,
      penznemid: null,
      arfolyam: '',
      befizkelte: null,
      feldolgkelte: null,
      megjegyzes: '',
      kozlemeny: '',
      formBizonylatszam: {
        hely: null,
        id: null,
        nev: null,
        penz: null,
        befizazon: null
      }
    };
  }

  componentDidMount() {
    this.getAktivPenznem();
    this.getDefaultPenznem();
    this.getEnums();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.tableData !== nextProps.tableData) {
      this.setState({
        currentParentId: nextProps.tableData.bankszamlaid,
        currentStatusBaseTable: 'LOAD'
      });
      if (nextProps.tableData.id) {
        Services.listKivonat(nextProps.tableData.id, (err, res) => {
          if (!err) {
            res[0].forEach((element, idx) => {
              if (element.tranzakciotipus && element.tranzakciotipus.caption) {
                res[0][idx].tranzakciotipus = element.tranzakciotipus.caption;
              }
              if (element.mozgastipus && element.mozgastipus.caption) {
                res[0][idx].mozgastipus = element.mozgastipus.caption;
              }
              if (element.partnerbean && element.partnerbean.nev) {
                res[0][idx].partner = element.partnerbean.nev;
              }
              if (element.bankkivonatentity && element.bankkivonatentity.bankszamlabean) {
                res[0][idx].partnerbankszamlaszam = element.bankkivonatentity.bankszamlabean.szamlaszam;
              }
            });
            this.setState({ baseJson: res[0], currentId: nextProps.tableData.id, currentStatusBaseTable: 'TABL' });
          } else {
            this.setState({
              currentStatusBaseTable: 'QUERY'
            });
          }
        });
      }
    }

    if (this.props.tableStatus !== nextProps.tableStatus) {
      if (nextProps.tableStatus && nextProps.tableStatus.tranzakcio) {
        if (nextProps.tableStatus.tetel === 'RELOAD') {
          this.setState({
            currentStatusBaseTable: 'QUERY',
            baseJson: [],
            currentId: null
          });
          this.props.tableStatusChange({
            fej: 'LOADED',
            tranzakcio: 'LOADED',
            tetel: 'LOADED'
          });
        }
      }
    }
  }

  onRowSelect = (row) => {
    this.props.changeData({
      id: row.i,
      tipus: row.m.name,
      penznem: row.p,
      penznemId: row.pi
    });
  }

  onDropObject = (acceptedfiles) => {
    acceptedfiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        let files = this.state.files || [];
        files[0] = {
          content: reader.result,
          contentText: btoa(fileAsBinaryString),
          uploadDate: new Date(),
          docname: file.name,
          preview: file.preview,
          mime: file.type || 'application/octet-stream',
          id: null,
          length: file.size,
          preid: Lib.Browser.uuidv4()
        };
        this.setState({ files: files });
      };
      reader.readAsBinaryString(file);
    });
  }

  getAktivPenznem = () => {
    Services.listAktivPenznem((err, res) => {
      if (!err) {
        let resOptions = res[0].map(e => <option key={e.id} value={e.id}>{e.nev}</option>);
        this.setState({ aktivPenznem: resOptions });
      }
    });
  }

  getDefaultPenznem = () => {
    Services.getDefaultPenznem((err, res) => {
      if (!err) {
        this.setState({ defaultPenznemId: res[0].id });
      }
    });
  }

  getEnums = () => {
    Services.getEnums((err, res) => {
      let jellegOptions = [];
      let tipusOptions = [];
      if (res[0][0]) {
        jellegOptions = res[0][0].map(e => (
          <option key={e.name} value={e.name}>
            {e.caption}
          </option>
        ));
      }
      if (res[0][1]) {
        tipusOptions = res[0][1].map(e => (
          <option key={e.name} value={e.name}>
            {e.caption}
          </option>
        ));
      }
      this.setState({
        aktivPenzmozgasTipus: tipusOptions,
        aktivPenzmozgasJelleg: jellegOptions
      });
    });
  }

  downloadObject = (file) => {
    if (file.preview) {
      window.open(file.preview, '_blank');
    } else {
      this.getContent(file.id);
    }
  }

  removeObject = (file) => {
    if (file.preid) {
      let files = this.state.files.filter(f => f.preid !== file.preid);
      this.setState({ files: files });
    } else {
      let files = this.state.files.filter(f => f.id !== file.id);
      this.setState({ files: files });
    }
  }

  getContent = (objid) => {
    let obj = this.state.files.find(f => f.id == objid);
    Services.downloadObject(objid, (err, res) => {
      if (!err) {
        fileSaver.saveAs(new Blob([res[0]], { type: obj.mime }), obj.docname);
      }
    });
  }

  isEmpty = (data) => {
    return (data === null || data === '');
  }

  handleFormChange = (event) => {
    const { target } = event;
    let value;
    if (target) {
      if (target.name) {
        // console.log(target.name);
        switch (target.name) {
          case 'mozgastipus': {
            let name = target.value === '' ? null : target.value;
            value = name;
            break;
          }
          case 'tranzakciotipus': {
            let name = target.value === '' ? null : target.value;
            value = name;
            this.setState({
              formStatus: value
            });
            break;
          }
          case 'penznemid': {
            let name = target.value === '' ? null : target.value;
            value = name;
            break;
          }
          case 'arfolyam': {
            ({ value } = target);
            break;
          }
          case 'befizetoazonosito': {
            ({ value } = target);
            break;
          }
          case 'megjegyzes': {
            ({ value } = target);
            break;
          }
          case 'kozlemeny': {
            ({ value } = target);
            break;
          }
          default:
            break;
        }
      }
    } else {
      value = event;
    }
    const { name } = target;

    this.setState({
      [name]: value
    }, () => {
      // console.log(this.state);
    });
  }

  befizDatumChange = (e, v) => {
    let currentValue = v === '' ? null : v;
    this.setState({ befizkelte: currentValue });
  }

  feldolgDatumChange = (e, v) => {
    // console.log(v);
    let currentValue = v === '' ? null : v;
    this.setState({ feldolgkelte: currentValue });
  }

  formatBytes = (bytes) => {
    if (bytes < 1024) return bytes + ' bájt';
    if (bytes < 1048576) return (bytes / 1024).toFixed(3) + ' KB';
    if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + ' MB';
    return (bytes / 1073741824).toFixed(3) + ' GB';
  };


  toggleBaseView = () => {
    this.setState(prevState => ({ modalBaseView: !prevState.modalBaseView }));
  }

  toggleBaseNewManual = () => {
    this.setState({
      formStatus: null,
      modalBaseNewManual: !this.state.modalBaseNewManual,
      mozgastipus: null,
      tranzakciotipus: null,
      befizetoazonosito: '',
      penznem: null,
      penznemid: null,
      arfolyam: '',
      befizkelte: null,
      feldolgkelte: null,
      megjegyzes: '',
      kozlemeny: '',
      formBizonylatszam: {
        hely: null,
        id: null,
        nev: null,
        penz: null,
        befizazon: null
      },
      formPartner: {
        id: null,
        nev: null
      },
      formSzamlakibocsato: ''
    });
  }

  toggleBaseNewFile = () => {
    this.setState({
      files: [],
      modalBaseNewFile: !this.state.modalBaseNewFile,
      csekkesFeltoltesStep: 1
    });
  }

  toggleBaseDelete = () => {
    this.setState({
      modalBaseDelete: !this.state.modalBaseDelete
    });
  }

  fallbackCopyTextToClipboard = (text) => {
    let textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      let successful = document.execCommand('copy');
      let msg = successful ? 'successful' : 'unsuccessful';
      // console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      // console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
  }

  copyTextToClipboard = (text) => {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(() => {
      // console.log('Async: Copying to clipboard was successful!');
    }, (err) => {
      // console.error('Async: Could not copy text: ', err);
    });
  }

  copyToClipboard = () => {
    let value = '';
    this.state.csekkesFileResult.sikertelen.map((azonosito) => {
      value = value + azonosito + '\r\n';
    });
    this.copyTextToClipboard(value);
  }

  updateBaseTable = () => {
    this.setState({
      currentStatusBaseTable: 'LOAD'
    });
    Services.listKivonat(this.state.currentId, (err, res) => {
      if (!err) {
        res[0].forEach((element, idx) => {
          if (element.tranzakciotipus && element.tranzakciotipus.caption) {
            res[0][idx].tranzakciotipus = element.tranzakciotipus.caption;
          }
          if (element.mozgastipus && element.mozgastipus.caption) {
            res[0][idx].mozgastipus = element.mozgastipus.caption;
          }
          if (element.partnerbean && element.partnerbean.nev) {
            res[0][idx].partner = element.partnerbean.nev;
          }
          if (element.bankkivonatentity && element.bankkivonatentity.bankszamlabean) {
            res[0][idx].partnerbankszamlaszam = element.bankkivonatentity.bankszamlabean.szamlaszam;
          }
        });
        this.setState({ baseJson: res[0], currentStatusBaseTable: 'TABL' });
      } else {
        this.setState({
          currentStatusBaseTable: 'QUERY'
        });
      }
    });
  }

  handleBaseEditClick = (cell) => {
    _muvelet = 1;
    this._currentEditId = cell;
    Services.getKivonat(cell, (err, res) => {
      if (!err) {
        this.setState({
          formStatus: res[0].tranzakciotipus.name,
          mozgastipus: res[0].mozgastipus.name,
          tranzakciotipus: res[0].tranzakciotipus.name,
          befizetoazonosito: res[0].befizetoazonosito,
          penznemid: res[0].penznemid,
          arfolyam: res[0].arfolyam && res[0].arfolyam.toString(),
          befizkelte: res[0].befizkelte,
          feldolgkelte: res[0].feldolgkelte,
          megjegyzes: res[0].megjegyzes,
          kozlemeny: res[0].kozlemeny
        }, () => {
          this.setState({
            modalBaseNewManual: !this.state.modalBaseNewManual,
          });
        });
      }
    });
  }

  handleBaseViewClick = (cell) => {
    this.setState({
      formStatus: 'LOAD',
      currentId: cell,
    }, () => {
      Services.getKivonat(cell, (err, res) => {
        if (!err) {
          this.setState({
            formStatus: res[0].tranzakciotipus.name,
            mozgastipus: res[0].mozgastipus.name,
            mozgastipusCaption: res[0].mozgastipus.caption,
            tranzakciotipus: res[0].tranzakciotipus.name,
            tranzakciotipusCaption: res[0].tranzakciotipus.caption,
            befizetoazonosito: res[0].befizetoazonosito,
            penznem: res[0].penznem,
            penznemid: res[0].penznemid,
            arfolyam: res[0].arfolyam && res[0].arfolyam.toString(),
            befizkelte: res[0].befizkelte,
            feldolgkelte: res[0].feldolgkelte,
            megjegyzes: res[0].megjegyzes,
            kozlemeny: res[0].kozlemeny
          }, () => {
            this.toggleBaseView();
          });
        }
      });
    });
  }

  handleBaseNewManualClick = () => {
    _muvelet = 0;
    this.toggleBaseNewManual();
  }

  handleBaseNewFileClick = () => {
    this.toggleBaseNewFile();
  }

  handleBaseDeleteClick = (id) => {
    this._currentDeleteId = id;
    this.toggleBaseDelete();
  }

  bizonylatszamItemClick = (item) => {
    this.setState({
      dropdownOpen: false,
      formBizonylatszam: {
        hely: item.hely,
        id: item.id,
        nev: item.befizazon,
        penz: item.penznem,
        befizazon: item.befizazon
      }
    });
  }

  handleChangeSzamlakibocsato = (value) => {
    if (value && value.length !== 0) {
      this.setState({
        formSzamlakibocsato: value,
      });
    } else {
      this.setState({ formSzamlakibocsato: '' });
    }
  }

  loadSzamlakibocsatoChange = (input, callback) => {
    if (input.length !== 0) {
      Services.getPartnerSzamlakibocsato('BANK_TRANZAKCIO_FORM', input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            let baseObj = {};
            baseObj.id = item.id;
            baseObj.nev = item.nev + ' (' + item.cim + ')';
            newArray.push(baseObj);
          });
          callback(newArray);
        }
      });
    }
  }

  handleChangeJogtulaj = (value) => {
    if (value && value.length !== 0) {
      let bankszamlak = [];
      if (value.bankszamla) {
        value.bankszamla.forEach((item) => {
          bankszamlak.push(
            <option value={item.id.toString()}>
              {item.szamlaszam}
              {'('}
              {item.penzintezet}
              {')'}
            </option>
          );
        });
      }
      let szamlaObj = [];
      value.szamla.forEach((item) => {
        szamlaObj.push(
          <ListGroupItem
            style={{
              cursor: 'pointer',
              display: 'block'
            }}
            onClick={() => this.bizonylatszamItemClick(item)}
          >
            <span className="pull-left">
              {item.befizazon}
            </span>
            <Badge pill className="pull-right" color="info" style={{ padding: 5 }}>
              {item.osszeg + ' ' + item.penznem}
            </Badge>
          </ListGroupItem>
        );
      });
      this.setState({
        formSzamlaszam: bankszamlak,
        currentBizszamok: szamlaObj,
        formPartner: value,
        dropdownOpen: true
      });
    } else {
      this.setState({
        formPartner: {
          id: null,
          nev: null
        }
      });
    }
  }

  deleteTranzakcio = (id) => {
    this.toggleBaseDelete();
    Services.deleteKivonat(id, (err) => {
      if (!err) {
        this.updateBaseTable();
        this.props.globals.addSuccess('Bankkivonat tranzakció törölve!');
        this.props.refreshFej();
      }
    });
  }

  getSzkId = () => {
    if (!this.state.formSzamlakibocsato || this.state.formSzamlakibocsato === '') {
      return null;
    }
    return (this.state.formSzamlakibocsato && this.state.formSzamlakibocsato.id);
  }

  loadJogtulajChange = (input, callback) => {
    if (input.length !== 0) {
      Services.findForBankkivonatTranzakcio(null, input, this.state.tranzakciotipus, this.getSzkId(), (err, res) => {
        if (!err) {
          this.setState({
            formBizonylatszam: {
              id: null,
              nev: null
            }
          });
          let newArray = [];
          res[0].forEach((item) => {
            let newItem = item;
            newItem.nev += ' (' + newItem.cim + ') ';
            newArray.push(newItem);
          });
          callback(newArray);
        }
      });
    }
  }

  baseTableIconFormatter = (cell, row) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'BANK_PUTELJ', 'LEK') && (
          <React.Fragment>
            <Button
              className="icon-eye table-action-icon"
              id={'action-view-base-kivonat-' + cell}
              onClick={() => this.handleBaseViewClick(cell, row)}
            />
            <UncontrolledTooltip
              placement="left"
              target={'action-view-base-kivonat-' + cell}
              delay={0}
            >
              {'Megtekintés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'BANK_PUTELJ', 'KARB') && (
          <React.Fragment>
            {row.d && (
            <React.Fragment>
              <Button
                className="icon-pencil table-action-icon"
                id={'action-edit-base-kivonat-' + cell}
                onClick={() => this.handleBaseEditClick(cell, row)}
              />
              <UncontrolledTooltip
                placement="left"
                target={'action-edit-base-kivonat-' + cell}
                delay={0}
              >
                {'Módosítás'}
              </UncontrolledTooltip>
            </React.Fragment>
            )}
            <Button
              className="icon-trash table-action-icon"
              id={'action-delete-base-kivonat-' + cell}
              onClick={() => this.handleBaseDeleteClick(cell)}
            />
            <UncontrolledTooltip
              placement="left"
              target={'action-delete-base-kivonat-' + cell}
              delay={0}
            >
              {'Törlés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  tipusformatter = (cell) => {
    return (cell.caption);
  }

  addBanktetel = () => {
    let arfolyam = this.state.arfolyam;
    if (arfolyam)
      arfolyam = arfolyam.replace(',', '.');
    let submitObj = {
      mozgastipus: this.state.mozgastipus,
      tranzakciotipus: this.state.tranzakciotipus,
      befizetoazonosito: this.state.tranzakciotipus === 'FIZFELSZOL' ? this.state.formBizonylatszam.befizazon : this.state.befizetoazonosito,
      fizfelszolid: this.state.tranzakciotipus === 'FIZFELSZOL' ? this.state.formBizonylatszam.id : null,
      penznemid: this.state.tranzakciotipus === 'FIZFELSZOL' ? null : this.state.penznemid,
      arfolyam: this.state.tranzakciotipus === 'FIZFELSZOL' ? 1 : arfolyam,
      bankszamlatortenetid: this.state.tranzakciotipus === 'FIZFELSZOL' ? this.state.bankszamlatortenetid : null,
      befizkelte: this.state.befizkelte,
      feldolgkelte: this.state.feldolgkelte,
      megjegyzes: this.state.megjegyzes,
      kozlemeny: this.state.kozlemeny,
      bankkivonatentity: {
        id: this.state.currentId,
        bankszamlaid: this.state.currentParentId
      }
    };
    // this.emptyToNull(bankfejObj);
    Services.addKivonat(submitObj, (err) => {
      if (!err) {
        this.toggleBaseNewManual();
        this.updateBaseTable();
        this.props.globals.addSuccess('Bankkivonat tranzakció felvéve!');
      }
    });
  }

  modifyBanktetel = () => {
    let arfolyam = this.state.arfolyam;
    if (arfolyam)
      arfolyam = arfolyam.replace(',', '.');
    let submitObj = {
      id: this._currentEditId,
      mozgastipus: this.state.mozgastipus,
      tranzakciotipus: this.state.tranzakciotipus,
      befizetoazonosito: this.state.befizetoazonosito,
      penznemid: this.state.penznemid,
      arfolyam: arfolyam,
      befizkelte: this.state.befizkelte,
      feldolgkelte: this.state.feldolgkelte,
      megjegyzes: this.state.megjegyzes,
      kozlemeny: this.state.kozlemeny,
      bankkivonatentity: {
        id: this.state.currentId,
        bankszamlaid: this.state.currentParentId
      }
    };

    // this.emptyToNull(submitObj);
    Services.editKivonat(this._currentEditId, submitObj, (err) => {
      if (!err) {
        this.toggleBaseNewManual();
        this.updateBaseTable();
        this.props.globals.addSuccess('Bankkivonat tranzakció módosítva!');
      }
    });
  }

  sendFile = () => {
    this.setState({
      feltoltesButtonLoading: true
    });
    Services.sendFile(this.state.currentId, this.state.files[0].content, this.state.files[0].docname, (err, res) => {
      if (!err) {
        // this.toggleBaseNewFile();
        this.setState({
          csekkesFeltoltesStep: 2,
          csekkesFileResult: res[0]
        }, () => {
          this.updateBaseTable();
          this.props.globals.addSuccess('Bankkivonat tranzakció felvéve!');
        });
      }
      this.setState({
        feltoltesButtonLoading: false
      });
    });
  }

  renderUploadButtons = () => {
    return (
      <div className="row">
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'BANK_PUTELJ', 'KARB') && (
          <div className="col-md-12">
            <Button
              color="primary"
              disabled={!this.state.currentId}
              onClick={() => { this.handleBaseNewManualClick(); }}
            >
              {'Új tranzakció'}
            </Button>
            {' '}
            <Button
              color="primary"
              disabled={!this.state.currentId}
              onClick={() => { this.handleBaseNewFileClick(); }}
            >
              {'Állomány feltöltés'}
            </Button>
            { !this.state.currentId ? (
              <React.Fragment>
                <Button
                  style={{ marginTop: 0 }}
                  id="base-buttons-info"
                  className="fa fa-info-circle table-action-icon transp-icon-button"
                />
                <UncontrolledTooltip
                  placement="right"
                  target="base-buttons-info"
                  delay={0}
                >
                  {'Kijelölt bankkivonat fej nélkül nem lehetséges új adatot feltölteni!'}
                </UncontrolledTooltip>
              </React.Fragment>
            ) : <span />}
          </div>
        )}
      </div>
    );
  }

  loadBizonylatszamChange = (input, callback) => {
    if (input.length !== 0) {
      Services.findForBankkivonatTranzakcio(input, null, this.state.tranzakciotipus, this.getSzkId(), (err, res) => {
        if (!err) {
          this.setState({
            formPartner: {
              id: null,
              nev: null
            }
          });

          let result = [];
          res[0].forEach((item) => {
            item.szamla.forEach((subItem) => {
              let row = {};
              row.bankszamla = item.bankszamla;
              row.partnerid = item.id;
              row.partnernev = item.nev + ' (' + item.cim + ')';
              row.id = subItem.id;
              row.nev = subItem.befizazon + ' (' + subItem.osszeg + ' ' + subItem.penznem + ') \n' + row.partnernev + '\n' + subItem.kibocsato;
              row.befizazon = subItem.befizazon;
              result.push(row);
            });
          });
          result.sort(this.compareBefizetoAzon);
          callback(result);
        }
      });
    }
  }

  compareBefizetoAzon = (a, b) => {
    if (a.nev.split(' ')[0] > b.nev.split(' ')[0]) return 1;
    if (a.nev.split(' ')[0] < b.nev.split(' ')[0]) return -1;
    return 0;
  }

  handleChangeBizonylatszam = (value) => {
    if (value && value.length !== 0) {
      let bankszamlak = [];
      if (value.bankszamla) {
        value.bankszamla.forEach((item) => {
          bankszamlak.push(
            <option value={item.id.toString()}>
              {item.szamlaszam + ' (' + item.penzintezet + ')'}
            </option>
          );
        });
      }
      this.setState({
        formBizonylatszam: {
          id: value.id,
          nev: value.nev.split(' ')[0],
          penz: value.penznem,
          hely: value.hely,
          befizazon: value.befizazon
        },
        formPartner: {
          id: value.partnerid,
          nev: value.partnernev
        },
        formSzamlaszam: bankszamlak,
      });
    } else {
      this.setState({
        formBizonylatszam: {
          hely: null,
          id: null,
          nev: null,
          penz: null,
          befizazon: null
        }
      });
    }
  }

  handleOsszegChange = (event, value2) => {
    const { target } = event;
    let value;
    if (target) {
      if (target.name) {
        // console.log(target.name);
        switch (target.name) {
          case 'bankszamlatortenetid': {
            value = value2;
            break;
          }
          default:
            break;
        }
      }
      // value = target.type === 'checkbox' ? target.checked : target.value;
    } else {
      value = event;
    }
    const { name } = target;

    // console.log(this.state);

    this.setState({
      [name]: value
    }, () => {
      // console.log(this.state);
    });
  }

  renderBaseViewData = () => {
    return (
      <div>
        <h6>További információk:</h6>
        <b>Tranzakció típusa: </b>
        {this.state.tranzakciotipusCaption}
        <br />
        <b>Pénzmozgás típusa: </b>
        {this.state.mozgastipusCaption}
        <br />
        <b>Befizetőazonosító: </b>
        {this.state.befizetoazonosito}
        <br />
        <b>Pénznem: </b>
        {this.state.penznem}
        <br />
        <b>Árfolyam: </b>
        {this.state.arfolyam}
        <br />
        <b>Befizetés kelte: </b>
        {this.state.befizkelte}
        <br />
        <b>Feldolgozás kelte: </b>
        {this.state.feldolgkelte}
        <br />
        <b>Megjegyzés: </b>
        {this.state.megjegyzes}
        <br />
        <b>Közlemény: </b>
        {this.state.kozlemeny}
        <br />
      </div>
    );
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  renderBaseTable = () => {
    const pagination = true;

    const selectRowProp = {
      mode: 'radio',
      onSelect: this.onRowSelect
    };

    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };
    return (
      <div>
        { this.state.baseJson.length ? (
          <BootstrapTable
            data={this.state.baseJson}
            pagination={pagination}
            options={options}
            selectRow={selectRowProp}
            className="margin-top-30"
          >
            <TableHeaderColumn
              dataField="t"
              tdStyle={{ whiteSpace: 'normal' }}
              dataFormat={this.tipusformatter}
            >
              {'Tranzakció típusa'}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="m"
              tdStyle={{ whiteSpace: 'normal' }}
              dataFormat={this.tipusformatter}
            >
              {'Pénzmozgás típusa'}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="a"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            >
              {'Befizetőazonosító'}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="o"
              tdStyle={{ whiteSpace: 'normal', textAlign: 'right' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
              dataFormat={NumberFormatter.formatterSpaceOsszeg}
            >
              {'Összeg'}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="p"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            >
              {'Pénznem'}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="b"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            >
              {'Befizetés kelte'}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="f"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            >
              {'Feldolgozás kelte'}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="i"
              tdStyle={{ whiteSpace: 'normal' }}
              isKey
              dataFormat={this.baseTableIconFormatter}
            >
              {'Műveletek'}
            </TableHeaderColumn>
          </BootstrapTable>
        ) : (
          <div className="margin-top-35">
            <DataEmptyState />
          </div>
        )}
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderUploadButtons()}
        {{
          QUERY: (<QueryEmptyState
            subtitle="Kérem válasszon felettes rekordot!"
            hasImage
          />),
          LOAD: (<Loader />),
          DATA: (<DataEmptyState
            hasImage
            subtitle="Próbálj más előszűrést vagy vigyél fel új adatokat!"
          />),
          NETW: (<NetworkEmptyState
            hasImage
            submitClick={() => this.updateTable()}
          />),
          TABL: (this.renderBaseTable())
        }[this.state.currentStatusBaseTable]}
        <Modal
          isOpen={this.state.modalBaseView}
          toggle={this.toggleBaseView}
          className={this.props.className}
          backdrop="static"
        >
          <ModalHeader>
            {'Megtekintés'}
          </ModalHeader>
          <ModalBody>
            {this.renderBaseViewData()}
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-cursor-pointer"
              type="button"
              color="secondary"
              onClick={this.toggleBaseView}
            >
              {'Vissza'}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalBaseNewManual}
          toggle={this.toggleBaseNewManual}
          className={this.props.className}
          backdrop="static"
          size="lg"
        >
          <AvForm onValidSubmit={_muvelet === 0 ? this.addBanktetel : this.modifyBanktetel}>
            {_muvelet === 0 ? <ModalHeader>Új bankkivonat tranzakció rögzítése</ModalHeader> : <ModalHeader>Meglévő bankkivonat tranzakció módosítása</ModalHeader> }
            <ModalBody>
              {/* <h5>Alapadatok:</h5> */}
              <div className="row">
                <div className="col-md-6">
                  <AvField
                    type="select"
                    name="tranzakciotipus"
                    label="Tranzakció típusa: *"
                    errorMessage="Kötelező mező!"
                    required
                    onChange={this.handleFormChange}
                    value={this.state.tranzakciotipus}
                    disabled={_muvelet !== 0}
                  >
                    <option value="">
                      {'Válassz típust...'}
                    </option>
                    {this.state.aktivPenzmozgasJelleg}
                  </AvField>
                </div>
                <div className="col-md-6">
                  <AvField
                    type="select"
                    name="mozgastipus"
                    label="Pénzmozgás típusa: *"
                    errorMessage="Kötelező mező!"
                    required
                    onChange={this.handleFormChange}
                    value={this.state.mozgastipus}
                    disabled={_muvelet !== 0}
                  >
                    <option value="">
                      {'Válassz típust...'}
                    </option>
                    {this.state.aktivPenzmozgasTipus}
                  </AvField>
                </div>
                {!this.isEmpty(this.state.formStatus) && (
                  <React.Fragment>
                    {(this.state.formStatus === 'FIZFELSZOL') && (
                      <React.Fragment>
                        <div className="col-md-12" style={{ marginBottom: 15 }}>
                          <Label for="bizonylatszam">
                            {'Számlakibocsátó:'}
                          </Label>
                          <Select
                            styles={{
                              control: base => ({
                                ...base,
                                '&:focus': { borderColor: 'red' }, // border style on hover
                                border: '1px solid lightgray', // default border color
                                boxShadow: 'none', // no box-shadow
                                borderRadius: '0'
                              }),
                              whiteSpace: 'pre-line'
                            }}
                            name="bizonylatszam"
                            id="bizonylatszam"
                            placeholder="Kezdjen el gépelni..."
                            noOptionsMessage={() => 'Nincs keresési eredmény'}
                            searchPromptText="Kezdjen el gépelni a kereséshez..."
                            loadingMessage={() => 'Keresés...'}
                            onChange={v => this.handleChangeSzamlakibocsato(v)}
                            isClearable
                            ignoreAccents={false}
                            loadOptions={_.debounce(this.loadSzamlakibocsatoChange, 1000)}
                            isLoading={false}
                            getOptionLabel={option => option.nev}
                            getOptionValue={option => option.id}
                            value={this.state.formSzamlakibocsato}
                            // disabled={this.state.fizTeljElerheto}
                            cache={false}
                            autoload={false}
                          />
                        </div>
                        <div className="col-md-12" style={{ marginBottom: 16 }}>
                          <Label for="ptid">
                            {'Partner: *'}
                          </Label>
                          <Select
                            styles={{
                              control: base => ({
                                ...base,
                                '&:focus': { borderColor: 'red' }, // border style on hover
                                border: '1px solid lightgray', // default border color
                                boxShadow: 'none', // no box-shadow
                                borderRadius: '0'
                              }),
                            }}
                            name="ptid"
                            id="ptid"
                            placeholder="Kezdjen el gépelni..."
                            noOptionsMessage={() => 'Nincs keresési eredmény'}
                            searchPromptText="Kezdjen el gépelni a kereséshez..."
                            loadingMessage={() => 'Keresés...'}
                            onChange={v => this.handleChangeJogtulaj(v)}
                            isClearable
                            ignoreAccents={false}
                            loadOptions={_.debounce(this.loadJogtulajChange, 1000)}
                            isLoading={false}
                            getOptionLabel={option => option.nev}
                            getOptionValue={option => option.id}
                            value={this.state.formPartner}
                            // disabled={this.state.fizTeljElerheto}
                            cache={false}
                            autoload={false}
                          />
                        </div>
                        <div className="col-md-12" style={{ marginBottom: 16 }}>
                          <Dropdown
                            isOpen={this.state.dropdownOpen}
                            toggle={() => {
                              this.setState({
                                dropdownOpen: false
                              });
                            }}
                          >
                            <DropdownToggle tag="div">
                              {''}
                            </DropdownToggle>
                            <DropdownMenu
                              className="w-100"
                              modifiers={{
                                setMaxHeight: {
                                  enabled: true,
                                  order: 890,
                                  fn: (data) => {
                                    return {
                                      ...data,
                                      styles: {
                                        ...data.styles,
                                        overflow: 'auto',
                                        maxHeight: 230,
                                      },
                                    };
                                  },
                                },
                              }}
                            >
                              <ListGroup>
                                <ListGroupItem
                                  style={{
                                    // textAlign: 'center',
                                    display: 'block'
                                  }}
                                  className="justify-content-between"
                                >
                                  <span className="pull-left">
                                    {'Befizetőazonosító'}
                                  </span>
                                  <span className="pull-right">
                                    {'Összeg'}
                                  </span>
                                </ListGroupItem>
                                {this.state.currentBizszamok}
                              </ListGroup>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                        <div className="col-md-4">
                          <AvField
                            type="select"
                            name="bankszamlatortenetid"
                            label="Partner bankszámlaszám: "
                            errorMessage="Kötelező mező!"
                            onChange={this.handleOsszegChange}
                            value={this.state.bankszamlatortenetid}
                            disabled={!this.state.formSzamlaszam.length}
                          >
                            <option value="">
                              {'Válassz bankszámlaszámot...'}
                            </option>
                            {this.state.formSzamlaszam}
                          </AvField>
                        </div>
                        <div className="col-md-4" style={{ marginBottom: 15 }}>
                          <Label for="bizonylatszam">
                            {'Befizetőazonosító: *'}
                          </Label>
                          <Select
                            styles={{
                              control: base => ({
                                ...base,
                                '&:focus': { borderColor: 'red' }, // border style on hover
                                border: '1px solid lightgray', // default border color
                                boxShadow: 'none', // no box-shadow
                                borderRadius: '0'
                              }),
                            }}
                            name="bizonylatszam"
                            id="bizonylatszam"
                            placeholder="Kezdjen el gépelni..."
                            noOptionsMessage={() => 'Nincs keresési eredmény'}
                            searchPromptText="Kezdjen el gépelni a kereséshez..."
                            loadingMessage={() => 'Keresés...'}
                            onChange={v => this.handleChangeBizonylatszam(v)}
                            isClearable
                            ignoreAccents={false}
                            loadOptions={_.debounce(this.loadBizonylatszamChange, 1000)}
                            isLoading={false}
                            getOptionLabel={option => option.nev}
                            getOptionValue={option => option.id}
                            value={this.state.formBizonylatszam}
                            // disabled={this.state.fizTeljElerheto}
                            cache={false}
                            autoload={false}
                          />
                        </div>
                      </React.Fragment>
                    )}
                    <div className="col-md-4">
                      <AvField
                        type="select"
                        name="penznemid"
                        label="Pénznem: *"
                        errorMessage="Kötelező mező!"
                        required
                        onChange={this.handleFormChange}
                        value={this.state.formStatus === 'FIZFELSZOL' ? this.state.defaultPenznemId : this.state.penznemid}
                        disabled={_muvelet !== 0 || this.state.formStatus === 'FIZFELSZOL'}
                      >
                        <option value="">
                          {'Válassz pénznemet...'}
                        </option>
                        {this.state.aktivPenznem}
                      </AvField>
                    </div>
                    <div className="col-md-4">
                      <AvGroup>
                        <Label for="arfolyam">
                          {'Árfolyam:'}
                        </Label>
                        <AvInput
                          name="arfolyam"
                          id="arfolyam"
                          maxLength="50"
                          onChange={this.handleFormChange}
                          value={this.state.formStatus === 'FIZFELSZOL' ? '1' : this.state.arfolyam}
                          disabled={_muvelet !== 0 || this.state.formStatus === 'FIZFELSZOL'}
                        />
                        <AvFeedback>
                          {'Kötelező mező!'}
                        </AvFeedback>
                      </AvGroup>
                    </div>
                    <div className="col-md-4">
                      <Label for="befizkelte">
                        {'Befizetés kelte: *'}
                      </Label>
                      <DatePicker
                        required
                        placeholder="éééé-hh-nn"
                        type="select"
                        name="befizkelte"
                        onChange={this.befizDatumChange}
                        value={this.state.befizkelte && new Date(this.state.befizkelte)}
                      />
                    </div>
                    {(this.state.formStatus !== 'FIZFELSZOL') && (
                    <React.Fragment>
                      <div className="col-md-4">
                        <Label for="feldolgkelte">
                          {'Feldolgozás kelte:' }
                        </Label>
                        <DatePicker
                          required
                          placeholder="éééé-hh-nn"
                          type="select"
                          name="feldolgkelte"
                          onChange={this.feldolgDatumChange}
                          value={this.state.feldolgkelte && new Date(this.state.feldolgkelte)}
                        />
                      </div>
                      <div className="col-md-12">
                        <AvGroup>
                          <Label for="megjegyzes">
                            {'Megjegyzés:'}
                          </Label>
                          <AvInput
                            className="munkalap-profil-textarea"
                            type="textarea"
                            name="megjegyzes"
                            id="megjegyzes"
                            maxLength="250"
                            onChange={this.handleFormChange}
                            value={this.state.megjegyzes}
                          />
                          <AvFeedback>
                            {'Kötelező mező!'}
                          </AvFeedback>
                        </AvGroup>
                      </div>
                      <div className="col-md-12">
                        <AvGroup>
                          <Label for="kozlemeny">
                            {'Közlemény:'}
                          </Label>
                          <AvInput
                            className="munkalap-profil-textarea"
                            type="textarea"
                            name="kozlemeny"
                            id="kozlemeny"
                            maxLength="250"
                            onChange={this.handleFormChange}
                            value={this.state.kozlemeny}
                          />
                          <AvFeedback>
                            {'Kötelező mező!'}
                          </AvFeedback>
                        </AvGroup>
                      </div>
                    </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <Button color="primary">
                {'Mentés'}
              </Button>
              {' '}
              <Button type="button" color="secondary" onClick={this.toggleBaseNewManual}>Mégse</Button>
            </ModalFooter>
          </AvForm>
        </Modal>

        <Modal isOpen={this.state.modalBaseDelete} toggle={this.toggleBaseDelete} className={this.props.className} backdrop="static">
          <ModalHeader>
            {'Törlés'}
          </ModalHeader>
          <ModalBody>
            {'Valóban törölni kívánja a kiválasztott tételt?'}
          </ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteTranzakcio(this._currentDeleteId)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleBaseDelete}>Mégsem</Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalBaseNewFile}
          toggle={this.toggleBaseNewFile}
          className={this.props.className}
          backdrop="static"
        >
          <AvForm onValidSubmit={this.sendFile}>
            <ModalHeader>
              {'Állomány feltöltése'}
            </ModalHeader>
            <ModalBody>
              {/* <h5>Alapadatok:</h5> */}

              {this.state.csekkesFeltoltesStep === 1 ? (
                <div className="row">
                  <div className="col-md-12">
                    <AvField
                      type="select"
                      name="fajltipus"
                      label="Fájltípus: *"
                      errorMessage="Kötelező mező!"
                      required
                      value={_modositoObj && _modositoObj.statusz.name}
                    >
                      <option value="">
                        {'Válassz típust...'}
                      </option>
                      <option value="CSEKK">
                        {'Csekkes állomány befogadása(*.txt)'}
                      </option>
                    </AvField>
                  </div>
                  <div className="col-md-12">
                    <DropZone
                      accept=".txt, .chq"
                      multiple={false}
                      onDrop={this.onDropObject}
                      className="custom-dropzone"
                      style={{ marginBottom: 16 }}
                    >
                      <div className="dropzone-text">
                        {'Húzd ide a fájlokat vagy kattints a négyzetbe egy kép feltöltéséhez!'}
                      </div>
                    </DropZone>
                    {this.state.files && this.state.files.map((item, idx) => {
                      return (
                        <div className="col-md-12" style={{ padding: 0 }} key={idx.toString()}>
                          <FileCard
                            key={idx.toString()}
                            title={item.docname}
                            length={this.formatBytes(item.length)}
                            onDownloadClick={() => this.downloadObject(item)}
                            onDeleteClick={() => this.removeObject(item)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-12">
                    <b>Állomány neve: </b>
                    {this.state.csekkesFileResult.file}
                    <br />
                    <b>Rekordok száma: </b>
                    {this.state.csekkesFileResult.osszesen + ' db'}
                    <br />
                    <b>Sikeres feltöltések száma: </b>
                    {this.state.csekkesFileResult.sikeres + ' db'}
                    <br />
                    <br />
                    <br />
                    <b>Sikertelen feltöltések befizető azonosítója: ({this.state.csekkesFileResult.sikertelen.length + ' db'}) </b>
                    {this.state.csekkesFileResult.sikertelen.map((sikertelen) => {
                      return <p style={{ marginTop: 0, marginBottom: 0 }}>{sikertelen}</p>;
                    })}
                  </div>
                </div>
              )
            }
            </ModalBody>
            <ModalFooter>
              {this.state.csekkesFeltoltesStep === 1 ? (
                <React.Fragment>
                  <ProgressButton color="primary" className="btn-cursor-pointer" loading={this.state.feltoltesButtonLoading}>
                    {'Mentés'}
                  </ProgressButton>
                  {' '}
                  <Button type="button" color="secondary" onClick={this.toggleBaseNewFile}>
                    {'Mégse'}
                  </Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Button type="button" color="primary" onClick={this.copyToClipboard}>
                    {'Sikertelen azonosítók vágólapra másolása'}
                  </Button>
                  {' '}
                  <Button type="button" color="secondary" onClick={this.toggleBaseNewFile}>
                    {'Ablak bezárása'}
                  </Button>
                </React.Fragment>
              )}
            </ModalFooter>
          </AvForm>
        </Modal>
      </div>
    );
  }
}

BanktetelTable.propTypes = {
  tableStatusChange: PropTypes.func,
  changeData: PropTypes.func,
  tableStatus: PropTypes.string,
  className: PropTypes.string,
  globals: PropTypes.object,
  tableData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
};

export default BanktetelTable;
