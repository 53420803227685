import React, { Component } from 'react';
import { Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
import { browserHistory } from 'react-router';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false
    };
  }

  logout = () => {
    this.props.route.globals.keycloak.logout();
  }

  toggleFullscreen = () => {
    let docElm = document.documentElement;
    if (typeof document.fullscreen === 'boolean') {
      if (document.fullscreen) document.exitFullscreen();
      else docElm.requestFullscreen();
    } else if (typeof document.mozFullScreen === 'boolean') {
      if (document.mozFullScreen) document.mozCancelFullScreen();
      else docElm.mozRequestFullScreen();
    } else if (typeof document.webkitIsFullScreen === 'boolean') {
      if (document.webkitIsFullScreen) document.webkitCancelFullScreen();
      else docElm.webkitRequestFullScreen();
    } else if (typeof document.msFullscreenElement === 'boolean') {
      if (document.msFullscreenElement) document.msExitFullscreen();
      else docElm.msRequestFullscreen();
    }
  }

  navToSettings = () => {
    browserHistory.push('/felhasznalo');
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  sidebarToggle = (e) => {
    e.preventDefault();
    document.body.classList.toggle('sidebar-hidden');
  }

  mobileSidebarToggle = (e) => {
    e.preventDefault();
    document.body.classList.toggle('sidebar-mobile-show');
  }

  asideToggle = (e) => {
    e.preventDefault();
    document.body.classList.toggle('aside-menu-hidden');
  }

  render() {
    let padding = {
      paddingRight: 10,

    };

    return (
      <header className="app-header navbar">
        <button className="navbar-toggler mobile-sidebar-toggler hidden-lg-up" onClick={this.mobileSidebarToggle} type="button">&#9776;</button>
        <a className="navbar-brand" href="https://zszportal.zalaszam.hu/" aria-label="Navigálás a Zalaszám kft. weboldalára">{null}</a>
        <ul className="nav navbar-nav hidden-md-down">
          <li className="nav-item">
            <a className="nav-link navbar-toggler sidebar-toggler nav-head" onClick={this.sidebarToggle} >&#9776;</a>
          </li>
        </ul>
        <ul className="nav navbar-nav ml-auto">
          <li className="nav-item">
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle} style={padding}>
              <DropdownToggle
                className="header-button nav-head"
                style={{
                  backgroundColor: '#fff0',
                  borderColor: '#fff',
                  color: '#000',
                  width: '250px'
                }}
                caret
              >
                <img
                  src={process.env.PUBLIC_URL + '/img/avatars/undefined.png'}
                  className="img-avatar"
                  alt="img-avatar"
                />
                <span
                  className="hidden-md-down"
                >
                  {this.props.route.globals.keycloak.idTokenParsed.name}
                </span>
              </DropdownToggle>
              <DropdownMenu right>
                {/* <DropdownItem header className="text-center"><strong>Fiók</strong></DropdownItem>
                <DropdownItem><i className="fa fa-bell-o"></i> Értesítések<span className="badge badge-danger">5</span></DropdownItem>
                <DropdownItem header className="text-center"><strong>Beállítások</strong></DropdownItem>
                <DropdownItem><i className="fa fa-user"></i> Profil</DropdownItem>
                <DropdownItem><i className="fa fa-wrench"></i> Beállítások</DropdownItem>
                <DropdownItem divider /> */}
                {/* <DropdownItem><i className="fa fa-shield"></i> Lezárás</DropdownItem> */}
                <DropdownItem header className="text-center"><strong>Fiók</strong></DropdownItem>
                <DropdownItem className="text-center" style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                  <strong>{this.props.route.globals.keycloak.idTokenParsed.name && this.props.route.globals.keycloak.idTokenParsed.name} </strong>({this.props.route.globals.keycloak.idTokenParsed.preferred_username && this.props.route.globals.keycloak.idTokenParsed.preferred_username})<br />
                  <span>{this.props.route.globals.keycloak.idTokenParsed.email && this.props.route.globals.keycloak.idTokenParsed.email}</span>
                </DropdownItem>
                {/* <DropdownItem onClick={this.navToSettings} className="nav-head"><i className="fa fa-cog" /> Beállítások</DropdownItem> */}
                <DropdownItem onClick={this.toggleFullscreen} className="nav-head">
                  <i className="fa fa-arrows-alt" />
                  {'Teljes képernyő ki/be'}
                </DropdownItem>
                <DropdownItem onClick={this.logout} className="nav-head">
                  <i className="fa fa-lock" />
                  {'Kilépés'}
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </li>
        </ul>
      </header>
    );
  }
}

export default Header;
