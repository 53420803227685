import Request from 'superagent';
import fileSaver from 'file-saver';
import Lib from '../../../commons/lib';

export default class Service {
  static partnerUrl = window.location.origin + '/api/partner/';

  static szamlazasUrl = window.location.origin + '/api/udulesijogszla/';

  static apartmanUrl = window.location.origin + '/api/apartman/';
  // SZÁMLA LISTA START

  // tipus
  // számla: 'SZAMLA'
  // számlaelőkészítás: 'ELO'
  // számlaküldés: 'KULD'
  static getSzamlaKibocsato(type, text, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'search' },
      params: {
        type: type,
        text: text
      }
    }], fnDone);
  }

  static getPenznem(tipus, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'szamla/getdistinctszamlapenznem',
      args: { requestType: 'szamla-getdistinctszamlapenznem' },
      params: { tipus: tipus }
    }], fnDone);
  }

  static getFizmod(tipus, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'szamla/getdistinctszamlafizmod',
      args: { requestType: 'szamla-getdistinctszamlafizmod' },
      params: { tipus: tipus }
    }], fnDone);
  }

  static getPartnerEnum(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'partner/enum',
      args: { requestType: 'partner-enum' },
    }], fnDone);
  }

  static getSzamlaEnum(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.szamlazasUrl + 'szamla/enum',
      args: { requestType: 'szamla-enum' },
    }], fnDone);
  }

  static listSzallodak(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'szalloda/aktiv',
      args: { requestType: 'szalloda-aktiv-list' },
    }], fnDone);
  }

  static listEpuletekById(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'epulet/aktiv',
      args: { requestType: 'epulet-aktiv-list' },
      params: { id: id }
    }], fnDone);
  }

  static listApartmanokById(epulet, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'apartman/getepuletapartman',
      args: { requestType: 'epulet-getepuletapartman-list' },
      params: { epulet: epulet }
    }], fnDone);
  }

  // static exportSzamlaXlsx(data, globals, setButtonStat) {
  //   setButtonStat({ listButtonLoading: true });
  //   Request
  //     .post(Service.szamlazasUrl + 'szamla/list/xlsx')
  //     .set('Accept', 'application/json, application/octet-stream')
  //     .responseType('arraybuffer')
  //     .send(data)
  //     .then((res) => {
  //       setButtonStat({ listButtonLoading: false });
  //       globals.addSuccess('Xlsx fájl letöltés indítása...');
  //       fileSaver.saveAs(new Blob([res.xhr.response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', responseType: 'arraybuffer' }), res.header['content-disposition']);
  //     })
  //     .catch(() => {
  //       setButtonStat({ listButtonLoading: false });
  //       globals.addError('Hálozati hiba!');
  //     });
  // }

  static exportSzamlaXlsx(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.szamlazasUrl + 'szamla/list/xlsx',
      acceptType: 'application/json, application/octet-stream',
      data: data,
    }], fnDone);
  }

  // SZÁMLA LISTA END
}
