import React, { Component } from 'react';
import _ from 'lodash';
import { Button, Label, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import Select from 'react-select/lib/Async';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DataEmptyState from '../../commons/dataemptystate';
import QueryEmptyState from '../../commons/queryempty';
import NetworkEmptyState from '../../commons/networkemptystate';
import { DatePicker } from '../../commons/controls';
import Lib from '../../commons/lib';
import Loader from '../../commons/loader';
import Services from './services';
import { log } from 'util';

class HostwareNaploTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ['QUERY', 'LOAD', 'DATA', 'NETW', 'TABL']
      currentStatusBaseTable: 'QUERY',
      eloszuresUdjogAzon: null,
      eloszuresDatum: null,
      baseJson: [],
      modalTorles: false,
    };
  }

  udjogChange = (e) => {
    this.setState({
        eloszuresUdjogAzon: e.target.value,
    });
  }

  datumChange = (e) => {
    this.setState({
        eloszuresDatum: e.target.value,
    });
  }

  handleDateInputChange = (e, v, name) => {
    const value = v === '' ? null : v;
    this.setState({
      [name]: value
    });
  }

  updateBaseTable = () => {
    let udjogAzon = this.state.eloszuresUdjogAzon;
    let datum = this.state.eloszuresDatum;
    this.setState({
      currentStatusBaseTable: 'LOAD'
    });
    Services.getHostwareNaploList(udjogAzon, datum, (err, res) => {
      if (err) {
        this.setState({
          currentStatusBaseTable: 'NETW'
        });
      } else {
        let newState = res[0];
        this.setState({
          baseJson: newState,
          currentStatusBaseTable: res[0].length > 0 ? 'TABL' : 'DATA'
        });
      }
    });
  }

  toggleTorles = () => {
    this.setState({ modalTorles: !this.state.modalTorles });
  }

  deleteHwNaplo = () => {
    Services.deleteHostwareNaploList((err, res) => {
      if (!err) {
        this.updateBaseTable();
        this.toggleTorles();
      } 
    });
  }

  renderEloSzures = () => {
    return (
      <AvForm style={{ marginBottom: 20 }}>
        <div className="row">
          <div className="col-md-2">
            <AvField
              //type="select"
              name="udjogAzon"
              id="udjogAzon"
              label="Üdülésijog azonosító: "
              errorMessage="Kötelező mező!"
              onChange={this.udjogChange}
              value={this.state.eloszuresUdjogAzon}
            >
            </AvField>
          </div>
          <div className="col-md-3">
            <Label for="formLetrehozva">
            {'Dátum:'}
            </Label>
            <DatePicker
                placeholder="éééé-hh-nn"
                onChange={(e, v) => this.handleDateInputChange(e, v, 'eloszuresDatum')}
                value={this.state.eloszuresDatum && new Date(this.state.eloszuresDatum)}
                type="select"
                id="datum"
                name="datum"
              />
          </div>
          <div className="col-md-2">
            <Button
              className="margin-top-30"
              color="primary"
              onClick={() => { this.updateBaseTable(); }}
              // disabled={!this.state.szallodaHasValue}
            >
              {'Keresés'}
            </Button>
          </div>
          {this.props.globals.keycloak.hasResourceRole('ROLE_UDULESIJOG_HWNAPLO_KARB') &&
            <div className="col-md-2">
                <Button
                className="margin-top-30"
                color="danger"
                onClick={() => { this.toggleTorles(); }}
                >
                {'Törlés'}
                </Button>
            </div>
           }
        </div>
      </AvForm>
    );
  }

  renderBaseTable = () => {

    const options = this.props.globals.bootstraptableProps;

    return (
      <div className="animated fadeIn">
        <BootstrapTable
          data={this.state.baseJson}
          options={options}
          pagination
        >
          <TableHeaderColumn
            dataField="udjogazon"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          >
            {'Üdülésijog'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="visszaIgazolasDatuma"
            tdStyle={{ whiteSpace: 'pre-line' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          >
            {'Visszaigazolás dátuma'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="hwReservationId"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          >
            {'HostWare reservation id'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="hibaUzenet"
            tdStyle={{ whiteSpace: 'normal' }}
            filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          >
            {'Hibaüzenet'}
          </TableHeaderColumn>
          <TableHeaderColumn
            dataField="id"
            tdStyle={{ whiteSpace: 'normal' }}
            isKey
            width="0"
          >
            {'Műveletek'}
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }

  deleteModal = () => {
      return (
            <div>
                <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className} backdrop="static">
                    <ModalHeader >Törlés</ModalHeader>
                        <ModalBody>Valóban törölni kívánja az összes napló bejegyzést?</ModalBody>
                    <ModalFooter>
                        <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteHwNaplo()}>OK</Button>
                        <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
                    </ModalFooter>
                </Modal>
            </div>)
  }

  render(){
    return(
        <div>
            {this.renderEloSzures()}
            {this.deleteModal()}
            {{
            QUERY: (<QueryEmptyState
                hasImage
            />),
            LOAD: (<Loader />),
            DATA: (<DataEmptyState
                hasImage
                subtitle="Próbálj más előszűrést vagy vigyél fel új adatokat!"
            />),
            NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.updateBaseTable()}
            />),
            TABL: (this.renderBaseTable())
                }[this.state.currentStatusBaseTable]}
        </div>
    )
  }

}

export default HostwareNaploTable;