const tooltips = {
  fordulonap: 'Alapértelmezetten a legkorábbi befejezetlen leltár időpontjával kerül kitöltésre.',
  kezdetvege: 'Az első leltárív rögzítés időpontja és a számolt készlet véglegezése közti időszak. Forgalom blokkolása esetén módosítható a kívánt idő intervallumra.',
  fordulonaptable: 'Leltár során a Fordulónap időpontban érvényes készletszintek ellenőrzésére van lehetőség. A fordulónap időpont utáni készletmozgásokat a rendszer figyelmen kívül hagyja, a számolásnál is ugyanígy kell eljárni.',
  leltarmod: 'Vak leltár esetén amíg a számolás tart (Folyamatban állapot), a leltár menüpontban nem láthatók a leltár előtti készlet szintek.',
  forgalomblokk: 'Blokkolás esetén, amíg a leltár állapota  "Folyamatban” , az érintett raktárak érintett cikkeire készletmozgás nem véglegezhető. A leltár véglegezést követően (Jóváhagyásra vár / Lezárt / Törölt állapotok)  pedig csak a leltár fordulónapnál későbbi mozgásdátum használata engedélyezett készletmozgás esetén.',
  allapot: 'Folyamatban – leltárlisták és számolt mennyiségek rögzíthetők, módosíthatók\nJóváhagyásra vár – véglegezést követően további adatrögzítésre nincs lehetőség\nLezárt – a leltár során feltárt eltérések korrekciója megtörtént\nTörölt – a leltár korrekció nélkül került lezárásra',
  cikkmennyiseg: 'A leltárívhez utólag, a + műveletgomb segítségével hozzáadott cikkek esetén, ha a raktárban korábban nem volt készletmozgás, akkor a Mennyiség oszlop üres marad.',
  cikkleltarozott: 'Adatrögzítésnél a sorok között Tab / Shift+Tab segítségével is mozoghat',
  formfordulonap: 'A készletszintek meghatározása miatt csak akkor módosítható, ha nincs rögzített leltárív.',
  formkezdet: 'Ha a Forgalom blokkolása aktív, akkor módosítható adat, a blokkolás kezdő időpontját jelenti.\nHa a forgalom blokkolás nem aktív, akkor a legelső leltárív rögzítés időpontja kerül be automatikusan.',
  formvege: 'Ha a Forgalom blokkolása aktív, akkor módosítható adat, a blokkolás záró időpontja.\nA leltár véglegezésnél a véglegezés időpontja automatikusan bekerül, amely után további adatrögzítés, módosítás nem végezhető.',
  formleltarmod: 'Vak leltár esetén amíg a számolás tart (Folyamatban állapot), a leltár menüpontban nem láthatók a leltár előtti készlet szintek.',
  formblokk: 'Leltár kezdete és vége közti időablakban blokkolja a készletmozgások véglegezését az érintett raktárak érintett cikkei esetén.',
};

export default function getTooltip(name) {
  return tooltips[name];
}
