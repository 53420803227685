import Lib from '../../../commons/lib';

export default class Service {
  static cikkUrl = window.location.origin + '/api/cikk/';

  // CIKK PROFIL START

  static getCikkProfil(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.cikkUrl + 'parameter/boolean/1by1',
      args: { requestType: 'parameter-boolean-1by1' },
      params: { azon: 'CIKK_RAKTARON_KIVUL' },
    }], fnDone);
  }

  static updateCikkProfil(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.cikkUrl + 'parameter',
      args: { requestType: 'parameter-profil' },
      data: data
    }], fnDone);
  }

  // CIKK PROFIL END
}
