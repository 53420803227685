import React, { useState, useEffect } from 'react';
import EmailContent from './EmailContent';


const cardStyle = { padding: 10 };
const EmailBase = (props) => {

  return (
    <div className="animated fadeIn">
      <div className="card">
        <div className="card-header">
          Email sablonok
          {/* <YouTube data={videoData} /> */}
        </div>
        <div style={cardStyle}>
          <EmailContent globals={props.route.globals} />
        </div>
      </div>
    </div>
  );
};

export default EmailBase;
