import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import classnames from 'classnames';
// import YouTube from '../../../commons/youtube';
import Lib from '../../../commons/lib';
import PartnerAdatok from './PartnerAdatok';
import Jogalap from './Jogalap';
import FunkcioJogalapOsszerendeles from './FunkcioJogalapOsszerendeles';

/* const videoData = [
  { text: 'Számla előkészítés', id: 'ofmciGZYhz4' },
  { text: 'Számla küldés', id: '5ugllRivDZU' }
]; */

class PartnerGDPRBase extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      // TYPE:
      //    PARTNER_ADATOK,
      //    JOGALAP,
      //    FUNCKIO_JOGALAP,
      type: 'PARTNER_ADATOK'
    };
  }

  componentDidMount() {
    this.initTab();
  }

  initTab = () => {
    let activeTab = '1';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['GDPR_JOGALAP'], true)) activeTab = '4';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['GDPR_JOGALAP'], true)) activeTab = '3';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['GDPR_JOGALAP'], true)) activeTab = '2';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['GDPR_PROFIL'], true)) activeTab = '1';
    this.setState({ activeTab: activeTab });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      switch (tab) {
        case '1':
          this.setState({ type: 'PARTNER_ADATOK' });
          break;
        case '2':
          this.setState({ type: 'JOGALAP' });
          break;
        case '3':
          this.setState({ type: 'FUNCKIO_JOGALAP' });
          break;
        case '4':
          this.setState({ type: 'GDPR_PROFIL' });
          break;
        default:
          this.setState({ type: 'GDPR_PROFIL' });
      }
      this.setState({
        activeTab: tab
      });
    }
  }

  render() {
    let cardStyle = {
      padding: 10
    };

    return (
      <div>
        <div className="animated fadeIn">
          <div className="card">
            <div className="card-header">
              {'Partner GDPR'}
              {/* <YouTube data={videoData} /> */}
            </div>
            <div className="row" style={cardStyle}>
              <div className="col-md-12">
                <Nav tabs>
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['GDPR_PROFIL'], true) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => { this.toggle('1'); }}
                      >
                        {'Partner adatok'}
                      </NavLink>
                    </NavItem>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['GDPR_JOGALAP'], true) && (
                    <React.Fragment>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({ active: this.state.activeTab === '2' })}
                          onClick={() => { this.toggle('2'); }}
                        >
                          {'Jogalap'}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          href="#"
                          className={classnames({ active: this.state.activeTab === '3' })}
                          onClick={() => { this.toggle('3'); }}
                        >
                          {'Funkció-jogalap összerendelés'}
                        </NavLink>
                      </NavItem>
                    </React.Fragment>
                  )}
                  {/* {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['GDPR_PROFIL']) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '4' })}
                        onClick={() => { this.toggle('4'); }}
                      >
                        Marketing
                      </NavLink>
                    </NavItem>
                  )} */}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['GDPR_PROFIL'], true) && (
                    <TabPane tabId="1">
                      <PartnerAdatok
                        globals={this.props.route.globals}
                        ref={(instance) => { this.child1 = instance; }}
                        type={this.state.type}
                      />
                    </TabPane>
                  )}
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['GDPR_JOGALAP'], true) && (
                    <React.Fragment>
                      <TabPane tabId="2">
                        <Jogalap
                          globals={this.props.route.globals}
                          ref={(instance) => { this.child2 = instance; }}
                          type={this.state.type}
                        />
                      </TabPane>
                      <TabPane tabId="3">
                        <FunkcioJogalapOsszerendeles
                          globals={this.props.route.globals}
                          ref={(instance) => { this.child3 = instance; }}
                          type={this.state.type}
                        />
                      </TabPane>
                    </React.Fragment>
                  )}
                  {/* {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['GDPR_PROFIL']) && (
                    <TabPane tabId="4">
                      <Profil
                        globals={this.props.route.globals}
                        ref={(instance) => { this.child4 = instance; }}
                        type={this.state.type}
                      />
                    </TabPane>
                  )} */}
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PartnerGDPRBase.propTypes = {
  route: PropTypes.object,
  globals: PropTypes.object
};

export default PartnerGDPRBase;
