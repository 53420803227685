import React, { Component } from 'react';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import ReactDOM from 'react-dom';
import {
  Router,
  Route,
  IndexRoute,
  browserHistory
} from 'react-router';
import NotificationSystem from 'react-notification-system';
import Keycloak from 'keycloak-js';
import Lib from './commons/lib';
// Containers
import Full from './containers/Full';
// import Simple from './containers/Simple/'

// KEZDŐLAP [ / ]
import Kezdolap from './views/Kezdolap';

// DASHBOARD [ /dashboard ]
import Dashboard from './views/Dashboard';
import UzenetekBaseView from './views/Dashboard/Uzenetek/views/UzenetekBaseView';

// TÖRZSADATOK [ /torzsadatok ]
import Torzsadatok from './views/Torzsadatok/Torzsadatok';
import CikkBase from './views/Torzsadatok/Cikk/CikkBase';
import PartnerBase from './views/Torzsadatok/Partner/PartnerBase';
import PartnerUrlap from './views/Torzsadatok/Partner/PartnertorzsUrlap';
import Raktar from './views/Torzsadatok/Raktar/Raktar';
import Bizonylatkezeles from './views/Torzsadatok/Bizonylatkezeles/Bizonylatkezeles';
import ApartmanMenuBase from './views/Torzsadatok/Apartman/ApartmanMenuBase';
import PenzMenuBase from './views/Torzsadatok/Penzugy/PenzMenuBase';
import VizsgaloBase from './views/Torzsadatok/Vizsgalo/VizsgaloBase';
import SzemelyitorzsBase from './views/Torzsadatok/Szemelyitorzs/SzemelyitorzsBase';
import FoldrajziEgysegBase from './views/Torzsadatok/Foldrajziegyseg/FoldrajziEgysegBase';

// KÉSZLET [ /keszlet ]
import Keszlet from './views/Keszlet';
// import Leltar from './views/Keszlet/Leltar/LeltarBase';
import Keszletkezeles from './views/Keszlet/Keszletkezeles/Keszletkezeles';
import BevetelezesBase from './views/Keszlet/Keszletkezeles/components/Bevetelezes/BevetelezesBase';
import KeszletcsokkenesBase from './views/Keszlet/Keszletkezeles/components/Keszletcsokkenes/KeszletcsokkenesBase';
import SelejtezesBase from './views/Keszlet/Keszletkezeles/components/Selejtezes/SelejtezesBase';
// import RaktarkoziatadasBase from './views/Keszlet/Keszletkezeles/components/Raktarkoziatadas/RaktarkoziatadasBase';
import Keszletinformacio from './views/Keszlet/Keszletinformacio/KeszletinformacioLista';
// import RaktarkeszletBase from './views/Keszlet/Raktarkeszlet/RaktarkeszletBase';
import LeltarForm from './views/Keszlet/Keszletkezeles/components/Leltar/LeltarForm';

// RENDELÉS
import Rendelesek from './views/Rendeles/Rendelesek';
import RendelesBase from './views/Rendeles/RendelesBase';
import SzallitoiForm from './views/Rendeles/Szallitoi/SzallitoiForm';
import AjanlatForm from './views/Rendeles/Vevoi/AjanlatForm';

// KARTOTÉKRENDSZER [ /kartotek ]
import KartotekrendszerBase from './views/Kartotekrendszer/KartotekrendszerBase';
import KartotekrendszerForm from './views/Kartotekrendszer/KartotekrendszerForm';

// MUNKALAP [ /munkalap ]
import Munkalap from './views/Munkalap/MunkalapBase';
import MunkalapForm from './views/Munkalap/MunkalapForm';

// KARBANTARTÁS
import KarbantartasMenuBase from './views/Karbantartas/KarbantartasMenuBase';

// SZÁMLÁZÁS [ /szamlazas ]
import SzamlazasMenuBase from './views/Szamlazas/SzamlazasMenuBase';

// SZERZŐDÉS [ /szerzodes ]
import SzerzodesBase from './views/Szerzodes/SzerzodesBase';
import SzerzodesUrlap from './views/Szerzodes/SzerzodesUrlap';
import UdulesiJogUrlap from './views/Szerzodes/UdulesiJogUrlap';

// PÉNZÜGYI TELJESÍTÉS [ /penzugyiteljesites ]
import PenzugyiteljesitesBase from './views/Penzugyiteljesites/PenzugyBankMenu';

// RECEPCIO [ /recepcio ]
import RecepcioBase from './views/Recepcio/RecepcioBase';

// GDPR [ /gdpr ]
import GDPRBase from './views/GDPR/GDPRBase';

// FELHASZNÁLÓ PROFIL BEÁLLÍTÁSOK [ /felhasznalo ]
// import FelhasznaloBase from './views/Felhasznalo/FelhasznaloBase';

// PROFIL [ /profil ]
import Profil from './views/Profil';
import CikkProfilBase from './views/Profil/Cikk/CikkProfilBase';
import RciProfilBase from './views/Profil/Rci/RciProfilBase';
import CegadatBase from './views/Profil/Cegadatok/CegadatBase';
import CegadatForm from './views/Profil/Cegadatok/CegadatForm';
// import PartnerAdatbiztonsagProfilBase from './views/Profil/PartnerAdatbiztonsag/PartnerAdatbiztonsagProfilBase';
import PartnerGDPRBase from './views/Profil/PartnerGDPR/PartnerGDPRBase';
import FizetesiFelszolito from './views/Profil/FizetesiFelszolito/FizetesiFelszolito';
import FelhasznalokBase from './views/Profil/Felhasznalok/FelhasznalokBase';
import FelhasznalokForm from './views/Profil/Felhasznalok/FelhasznalokForm';
import FelhasznalokCsoportForm from './views/Profil/Felhasznalok/FelhasznalokCsoportForm';

// LISTAK [ /lista ]
import Listak from './views/Listak/Listak';
import KompenzacioListaBase from './views/Listak/Kompenzacio/KompenzacioListaBase';
import SzamlaListaBase from './views/Listak/Szamla/SzamlaListaBase';
import BankAlaplistaBase from './views/Listak/BankAlaplista/BankAlaplistaBase';
import SzabadApartmanLekerdezesBase from './views/Listak/SzabadApartmanLekerdezes/SzabadApartmanLekerdezesBase';
import UdulesiJogListaBase from './views/Listak/UdulesiJog/UdulesiJogListaBase';

// FELADATLISTA [ /feladatlista ]
import FeladatlistaMenuBase from './views/Feladatlista/FeladatlistaMenuBase';
import FeladatlistaForm from './views/Feladatlista/FeladatlistaForm';

// EMAIL [ /email ]
import EmailBase from './views/Email/EmailBase';

// 404 [ * ]
import Page404 from './views/Pages/Page404/Page404';

const keycloak = new Keycloak('/keycloak.json');
keycloak.init({ checkLoginIframe: false }).then(function(authenticated) {
  if (authenticated) {
    Lib.MicroServices.keycloak = keycloak;
    /*
     *  STRICT MODE
     */
    // ReactDOM.render(<React.StrictMode><Main /></React.StrictMode>, document.getElementById('root'));
    /*
     *  NORMAL MODE
     */
    ReactDOM.render(<Main />, document.getElementById('root'));
  } else {
    keycloak.login();
  }
}).catch(function() {
});

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Globals: {
        Lib: Lib,
        keycloak: keycloak,
        addError: this.addError,
        addSuccess: this.addSuccess,
        addWarning: this.addWarning,
        addInfo: this.addInfo,
        bootstraptableProps: Lib.bootstraptableProps
      }
    };
    Lib.MicroServices.errorHandler = err => this._handleServiceErrors(err);
    Lib.MicroServices.responseHandler = res => this._handleServiceResponse(res);
  }

  addError = (message) => {
    this.refs.notificationSystem.addNotification({
      title: 'Hiba',
      message: message,
      level: 'error',
      position: 'br'
    });
  }

  addSuccess = (message) => {
    this.refs.notificationSystem.addNotification({
      title: 'Sikeres művelet!',
      message: message,
      level: 'success',
      position: 'br'
    });
  }

  addWarning = (message) => {
    this.refs.notificationSystem.addNotification({
      title: 'Figyelmeztetés!',
      message: message,
      level: 'warning',
      position: 'br'
    });
  }

  addInfo = (message) => {
    this.refs.notificationSystem.addNotification({
      title: 'Információ!',
      message: message,
      level: 'info',
      position: 'br'
    });
  }

  hasRole = (roles, onlyLEK) => {
    for (let i = 0; i < roles.length; i += 1) {
      if (keycloak.hasResourceRole('ROLE_' + roles[i] + '_LEK') || (!onlyLEK && keycloak.hasResourceRole('ROLE_' + roles[i] + '_KARB'))) {
        return true;
      }
    }
    return false;
  }

  hasRoleUnique = (role) => {
    if (keycloak.hasResourceRole(role)) {
      return true;
    }
    return false;
  }

  _handleServiceResponse(res) { }

  _handleServiceErrors(err) {
    if (!this.state.loggedOff) {
      if (err.status && err.response) {
        let { response } = err;
        switch (err.status) {
          case 400: {
            if (response && response.body && response.body.msg) {
              let { msg } = response.body;
              try {
                msg = JSON.parse((msg.split('\n'))[1]).error;
              } catch (e) { }
              this.addError(msg);
              break;
            }
            break;
          }
          case 401: {
            // token expired
            keycloak.logout();
            break;
          }
          case 403: {
            this.addError('Hozzáférés megtagadva!');
            break;
          }
          case 404: {
            // this.addError("Nem elérhető a szolgáltatás!");
            break;
          }
          case 409:
          case 500: {
            if (response && response.body && response.body.msg) {
              let { msg } = response.body;
              try {
                msg = JSON.parse((msg.split('\n'))[1]).error;
              } catch (e) { }
              this.addError(msg);
              break;
            }
            break;
          }
          case 502: {
            this.addError('Hálózati hiba!');
            break;
          }
          default: {
            // let { statusText } = response;
            // if (!statusText) statusText = "";
            // let message = "";
            // if (this.state.Globals.CommonComponents.Alerts) {
            //   let title = "HIBA - " + status + " " + statusText;
            //   message = response.text ? response.text.substring(0, 100) + " " : "";
            //   if (response.body && response.body.error_description) {
            //     title = "HIBA - " + status + " " + response.body.error;
            //     message = response.body.error_description.substring(0, 50) + "...";
            //   }
            //   if (response.body && response.body.message) {
            //     title = "HIBA - " + status + " " + response.body.error;
            //     message = response.body.message + "... " + response.body.path;
            //   }
            //   if (response.body && response.body.uzenet) {
            //     title =  response.body.uzenet;
            //     message = null; // response.body.tartalom should be handled elsewhere (form specific, etc)
            //     if ([400, 500].indexOf(status) < 0)
            //       message = statusText;
            //   }
            //   //this.state.Globals.CommonComponents.Alerts.error(title, message);
            // }
          }
        }
      } else {
        // this.state.Globals.CommonComponents.Alerts.error("Ismeretlen hiba történt");
      }
    }
  }


  render() {
    return (
      <div>
        <NotificationSystem
          ref="notificationSystem"
        />
        <Router history={browserHistory}>
          <Route path="/" name="Kezdőlap" component={Full} globals={this.state.Globals}>
            <IndexRoute component={Kezdolap} />
            {this.hasRole(['DASHBOARD_DASHBOARD', 'DASHBOARD_POSTALADA'], true) && (
              <Route path="dashboard" name="Dashboard">
                <IndexRoute component={Dashboard} globals={this.state.Globals} />
                <Route path="uzenetek" name="Üzenetek" component={UzenetekBaseView} globals={this.state.Globals} />
              </Route>
            )}
            {(this.hasRole([
              'CIKK_TORZS',
              'CIKK_CSOPORT',
              'CIKK_AFA',
              'PARTNER_CSOPORT',
              'PARTNER_TORZS',
              'RAKTAR_RAKTAR',
              'BIZONYLAT_TOMB',
              'BIZONYLAT_TIPUS',
              'APARTMAN_APARTMAN',
              'APARTMAN_EPULET',
              'APARTMAN_SZALLODA',
              'PENZUGYKOZOS_PENZNEM',
              'PENZUGYKOZOS_FIZMOD',
              'PENZUGYKOZOS_INFLACIO',
              'PENZUGYKOZOS_BANKTIPUS',
              'PENZUGYKOZOS_BANKSZAMLA',
              'BANK_PENZTAR',
              'SZERVEZET_SZEMELYI',
              'TELEPULES_ORSZAG',
              'TELEPULES_TELEPULES'
            ], true)) && (
              <Route path="torzsadatok" name="Törzsadatok">
               <IndexRoute component={Torzsadatok} />
                {this.hasRole(['CIKK_TORZS', 'CIKK_CSOPORT', 'CIKK_AFA'], true) && (
                  <Route path="cikk" name="Cikk" component={CikkBase} globals={this.state.Globals} />
               )}
                {this.hasRole(['PARTNER_CSOPORT', 'PARTNER_TORZS'], true) && (
                  <React.Fragment>
                    <Route path="partnerek" name="Partner" component={PartnerBase} globals={this.state.Globals} />
                    <Route path="partnerek/felvetel" name="Partner felvitel" component={PartnerUrlap} globals={this.state.Globals} felvetel="true" modositas="false" />
                    <Route path="partnerek/modositas" name="Partner módosítás" component={PartnerUrlap} globals={this.state.Globals} felvetel="false" modositas="true" />
                  </React.Fragment>
                )}
                {this.hasRole(['RAKTAR_RAKTAR'], true) && (
                  <Route path="raktar" name="Raktár" component={Raktar} globals={this.state.Globals} />
               )}
                {this.hasRole(['BIZONYLAT_TOMB', 'BIZONYLAT_TIPUS'], true) && (
                  <Route path="bizonylatkezeles" name="Bizonylatkezelés" component={Bizonylatkezeles} globals={this.state.Globals} />
               )}
                {this.hasRole(['APARTMAN_APARTMAN', 'APARTMAN_EPULET', 'APARTMAN_SZALLODA'], true) && (
                  <Route path="apartman" name="Apartman" component={ApartmanMenuBase} globals={this.state.Globals} />
               )}
                {this.hasRole(['PENZUGYKOZOS_FIZMOD', 'PENZUGYKOZOS_PENZNEM', 'PENZUGYKOZOS_INFLACIO', 'PENZUGYKOZOS_BANKTIPUS', 'PENZUGYKOZOS_BANKSZAMLA', 'BANK_PENZTAR'], true) && (
                  <Route path="penzugy" name="Pénzügyi adatok" component={PenzMenuBase} globals={this.state.Globals} />
               )}
                {this.hasRole(['KARTOTEK_VIZSGALO'], true) && (
                  <Route path="vizsgalo" name="Vizsgáló személy" component={VizsgaloBase} globals={this.state.Globals} />
               )}
                {this.hasRole(['TELEPULES_ORSZAG', 'TELEPULES_TELEPULES'], true) && (
                  <Route path="foldrajziegyseg" name="Földrajzi egységek" component={FoldrajziEgysegBase} globals={this.state.Globals} />
                )}
                {this.hasRole(['SZERVEZET_SZEMELYI'], true) && (
                  <React.Fragment>
                    <Route path="szemelyitorzs" name="Személyi törzs" component={SzemelyitorzsBase} globals={this.state.Globals} />
                  </React.Fragment>
                )}
              </Route>
           )}
            {(this.hasRole(['KESZLETNYILV_SELEJTEZES', 'KESZLETNYILV_BEVETELEZES', 'KESZLETNYILV_CSOKKENES', 'KESZLETNYILV_KESZLETINFO', 'KESZLETNYILV_FORGALOM'], true)
              // || this.hasRoleUnique('ROLE_RAKTAR_RAKTARLISTA_LEK')
               || this.hasRoleUnique('ROLE_KESZLETNYILV_LELTAR_LEK')) && (
              <Route path="keszlet" name="Készlet">
                <IndexRoute component={Keszlet} />
                <Route path="keszletkezeles" name="Készletkezelés" component={Keszletkezeles} globals={this.state.Globals} />

                {this.hasRole(['KESZLETNYILV_BEVETELEZES'], true) && (
                  <React.Fragment>
                    <Route path="keszletkezeles/bevetelezes/felvetel" name="Új készletnövekedés" component={BevetelezesBase} globals={this.state.Globals} felvetel="true" modositas="false" />
                    <Route path="keszletkezeles/bevetelezes/modositas" name="Készletnövekedés módosítása" component={BevetelezesBase} globals={this.state.Globals} felvetel="false" modositas="true" />
                  </React.Fragment>
                )}

                {this.hasRole(['KESZLETNYILV_CSOKKENES'], true) && (
                  <React.Fragment>
                    <Route path="keszletkezeles/csokkenes/felvetel" name="Új készletcsökkenés" component={KeszletcsokkenesBase} globals={this.state.Globals} felvetel="true" modositas="false" />
                    <Route path="keszletkezeles/csokkenes/modositas" name="Készletcsökkenés módosítása" component={KeszletcsokkenesBase} globals={this.state.Globals} felvetel="false" modositas="true" />
                  </React.Fragment>
                )}

                {this.hasRole(['KESZLETNYILV_SELEJTEZES'], true) && (
                  <React.Fragment>
                    <Route path="keszletkezeles/selejtezes/felvetel" name="Új készletselejtezés" component={SelejtezesBase} globals={this.state.Globals} felvetel="true" modositas="false" />
                    <Route path="keszletkezeles/selejtezes/modositas" name="Készletselejtezés módosítása" component={SelejtezesBase} globals={this.state.Globals} felvetel="false" modositas="true" />
                  </React.Fragment>
                )}

                {/* {this.hasRole(['RAKTARKOZI', 'KESZLETNYILV_RAKTARKOZI'], true) && (
                  <React.Fragment>
                    <Route path="keszletkezeles/raktarkozi/felvetel" name="Új raktárközi átadás" component={RaktarkoziatadasBase} globals={this.state.Globals} felvetel="true" modositas="false" />
                    <Route path="keszletkezeles/raktarkozi/modositas" name="Raktárközi átadás módosítása" component={RaktarkoziatadasBase} globals={this.state.Globals} felvetel="false" modositas="true" />
                  </React.Fragment>
                )} */}

                {this.hasRoleUnique('ROLE_KESZLETNYILV_LELTAR_LEK') && (
                  <React.Fragment>
                    <Route path="keszletkezeles/leltar/felvetel" name="Új leltár" component={LeltarForm} globals={this.state.Globals} route={this.state.route} type="FEL" />
                    <Route path="keszletkezeles/leltar/modositas" name="Leltár módosítása" component={LeltarForm} globals={this.state.Globals} route={this.state.route} type="MOD" />
                  </React.Fragment>
                )}

                {this.hasRole(['KESZLETNYILV_KESZLETINFO'], true) && (
                  <React.Fragment>
                    <Route path="keszletinformacio" name="Készletinformáció" component={Keszletinformacio} globals={this.state.Globals} />
                  </React.Fragment>
                )}
                {/* {this.hasRoleUnique('ROLE_RAKTAR_RAKTARLISTA_LEK') && (
                  <Route path="raktarlista" name="Raktárkészlet lista" component={RaktarkeszletBase} globals={this.state.Globals} />
                )} */}
                {/* <Route path="leltar" name="Leltározás" component={Leltar} globals={this.state.Globals} /> */}
              </Route>
            )}
            {this.hasRole(['KARTOTEK_KARTON'], true) && (
              <Route path="kartotek" name="Kartotékrendszer">
                <IndexRoute component={KartotekrendszerBase} globals={this.state.Globals} />
                <Route path="felvetel" name="Új karton" component={KartotekrendszerForm} globals={this.state.Globals} felvetel="true" modositas="false" vizsgalat="false" vizsgalatModositas="false" />
                <Route path="modositas" name="Új karton" component={KartotekrendszerForm} globals={this.state.Globals} felvetel="false" modositas="true" vizsgalat="false" vizsgalatModositas="false" />
                <Route path="vizsgalat/felvetel" name="Új vizsgálat" component={KartotekrendszerForm} globals={this.state.Globals} felvetel="false" modositas="false" vizsgalat="true" vizsgalatModositas="false" />
                <Route path="vizsgalat/modositas" name="Vizsgálat módosítás" component={KartotekrendszerForm} globals={this.state.Globals} felvetel="false" modositas="false" vizsgalat="true" vizsgalatModositas="true" />
              </Route>
            )}

            {this.hasRole(['MUNKALAP_OSSZ'], true) && (
              <Route path="munkalap" name="Munkalap">
                <IndexRoute component={Munkalap} globals={this.state.Globals} />
                <Route path="megtekintes" name="Munkalap megtekintés" component={MunkalapForm} globals={this.state.Globals} type="VIEW" />
                <Route path="felvetel" name="Munkalap felvétel" component={MunkalapForm} globals={this.state.Globals} type="FEL" />
                <Route path="modositas" name="Munkalap módosítás" component={MunkalapForm} globals={this.state.Globals} type="MOD" />
              </Route>
            )}
            {this.hasRole(['UDULESIJOGSZLA_SZAMLAKEZELES', 'UDULESIJOGSZLA_SZAMLAKULDES', 'UDULESIJOGSZLA_SZAMLAPROFIL', 'UDULESIJOGSZLA_FIZFELSZ'], true) && (
              <Route path="szamlazas" name="Számla kezelés" component={SzamlazasMenuBase} globals={this.state.Globals} />
            )}
            {this.hasRole(['SZERZODES_SZERZODES', 'UDULESIJOG_UDJOG'], true) && (
              <Route path="szerzodes" name="Szerződés">
                <IndexRoute component={SzerzodesBase} globals={this.state.Globals} />
                <Route path="szerzodes/felvetel" name="Új szerződés" component={SzerzodesUrlap} globals={this.state.Globals} felvetel="true" modositas="false" />
                <Route path="szerzodes/modositas" name="Szerződés módosítás" component={SzerzodesUrlap} globals={this.state.Globals} felvetel="false" modositas="true" />
                <Route path="udulesijog/felvetel" name="Új üdülési jog" component={UdulesiJogUrlap} globals={this.state.Globals} felvetel="true" modositas="false" />
                <Route path="udulesijog/modositas" name="Üdülési jog módosítás" component={UdulesiJogUrlap} globals={this.state.Globals} felvetel="false" modositas="true" />
              </Route>
            )}
            {this.hasRole(['BANK_PUTELJ', 'BANK_PENZTAR'], true) && (
              <Route path="penzugyiteljesites" name="Pénzügyi teljesítés" component={PenzugyiteljesitesBase} globals={this.state.Globals} />
            )}
            {this.hasRole(['UDULESIJOG_RECEPCIO', 'UDULESIJOG_UDJOG'], true) && (
              <Route path="recepcio" name="Recepció" component={RecepcioBase} globals={this.state.Globals} />
            )}

            {this.hasRole(['KARBANTARTAS_KARBANTARTAS', 'KARBANTARTAS_ALKALOM', 'KARBANTARTAS_ELLENORZESILISTA'], true) && (
              <Route path="karbantartas" name="Karbantartás" component={KarbantartasMenuBase} globals={this.state.Globals} />
            )}

            {this.hasRole(['GDPR_NAPLO'], true) && (
              <Route path="gdpr" name="GDPR" component={GDPRBase} globals={this.state.Globals} />
            )}

            {this.hasRole(['FELADATLISTA_FELADAT', 'FELADATLISTA_FELADATTIPUS'], true) && (
              <Route path="feladatlista" name="Feladatlista">
                <IndexRoute component={FeladatlistaMenuBase} globals={this.state.Globals} />
                <Route path="felvetel" name="Feladat felvétel" component={FeladatlistaForm} globals={this.state.Globals} type="FEL" />
                <Route path="modositas" name="Feladat módosítás" component={FeladatlistaForm} globals={this.state.Globals} type="MOD" />
                <Route path="masolas" name="Feladat felvétel" component={FeladatlistaForm} globals={this.state.Globals} type="MAS" />
              </Route>
            )}

            {(this.hasRole(['RENDELES_SZALLITOI', 'RENDELES_AJANLAT', 'RENDELES_VEVOI', 'RENDELES_EMAILSABLON'], true) || this.hasRoleUnique('ROLE_RENDELES_BEALLITAS')) && (
              <Route path="rendelesek" name="Rendelések">
                <IndexRoute component={Rendelesek} />
                {(this.hasRole(['RENDELES_SZALLITOI', 'RENDELES_AJANLAT', 'RENDELES_VEVOI'], true) || this.hasRoleUnique('ROLE_RENDELES_BEALLITAS')) && (
                  <Route path="rendeles" name="Rendelés" globals={this.state.Globals}>
                    <IndexRoute component={RendelesBase} globals={this.state.Globals} />
                    {this.hasRole(['RENDELES_SZALLITOI'], true) && (
                      <React.Fragment>
                        <Route path="szallitoi/felvetel" name="Új szállítói rendelés" component={SzallitoiForm} globals={this.state.Globals} type="FEL" />
                        <Route path="szallitoi/modositas" name="Szállítói rendelés módosítás" component={SzallitoiForm} globals={this.state.Globals} type="MOD" />
                      </React.Fragment>
                    )}
                    {this.hasRole(['RENDELES_AJANLAT'], true) && (
                      <React.Fragment>
                        <Route path="vevoi/ajanlat/felvetel" name="Új vevői ajánlat" component={AjanlatForm} globals={this.state.Globals} type="FEL" />
                        <Route path="vevoi/ajanlat/modositas" name="Vevői ajánlat módosítás" component={AjanlatForm} globals={this.state.Globals} type="MOD" />
                      </React.Fragment>
                    )}
                  </Route>
                )}
                {this.hasRole(['RENDELES_EMAILSABLON'], true) && (
                  <Route path="email" name="Email" globals={this.state.Globals}>
                    <IndexRoute component={EmailBase} globals={this.state.Globals} />
                  </Route>
                )}
              </Route>
            )}

            {/* <Route path="felhasznalo" name="Felhasználó beállítások" component={FelhasznaloBase} globals={this.state.Globals} /> */}
            {(this.hasRole(['CEGADAT_CEGADAT', 'CIKK_PROFIL', 'UDULESIJOGSZLA_FIZFELSZPROFIL', 'GDPR_PROFIL', 'GDPR_JOGALAP', 'UDULESIJOG_RCIPROFIL'], true) || this.hasRoleUnique('ROLE_CONFIG_USER_KARB')) && (
              <Route path="profil" name="Profil">
                <IndexRoute component={Profil} />
                {this.hasRole(['CEGADAT_CEGADAT'], true) && (
                  <React.Fragment>
                    <Route path="cegadatok" name="Cégadatok" component={CegadatBase} globals={this.state.Globals} />
                    <Route path="cegadatok/felvetel" name="Cégadat felvétel" component={CegadatForm} globals={this.state.Globals} type="FEL" />
                    <Route path="cegadatok/modositas" name="Cégadat módosítás" component={CegadatForm} globals={this.state.Globals} type="MOD" />
                  </React.Fragment>
                )}
                {this.hasRole(['CIKK_PROFIL'], true) && (
                  <Route path="cikk" name="Cikk" component={CikkProfilBase} globals={this.state.Globals} />
                )}
                {this.hasRole(['UDULESIJOG_RCIPROFIL'], true) && (
                  <Route path="rci" name="Rci" component={RciProfilBase} globals={this.state.Globals} />
                )}
                {this.hasRole(['GDPR_PROFIL', 'GDPR_JOGALAP'], true) && (
                  <Route path="partnergdpr" name="Partner GDPR" component={PartnerGDPRBase} globals={this.state.Globals} />
                )}
                {this.hasRole(['UDULESIJOGSZLA_FIZFELSZPROFIL'], true) && (
                  <Route path="felszolito" name="Fizetési felszólító" component={FizetesiFelszolito} globals={this.state.Globals} />
                )}
                {this.hasRoleUnique('ROLE_CONFIG_USER_KARB') && (
                  <React.Fragment>
                    <Route path="felhasznalok" name="Felhasználók">
                      <IndexRoute component={FelhasznalokBase} globals={this.state.Globals} />
                      <Route path="felhasznalo/felvetel" name="Felhasználó felvétel" component={FelhasznalokForm} globals={this.state.Globals} type="FEL" />
                      <Route path="felhasznalo/modositas" name="Felhasználó módosítás" component={FelhasznalokForm} globals={this.state.Globals} type="MOD" />
                      <Route path="csoport/felvetel" name="Csoport felvétel" component={FelhasznalokCsoportForm} globals={this.state.Globals} type="FEL" />
                      <Route path="csoport/modositas" name="Csoport módosítás" component={FelhasznalokCsoportForm} globals={this.state.Globals} type="MOD" />
                    </Route>
                  </React.Fragment>
                )}
                {/* <Route path="keszlet" name="Készlet" component={KeszletProfil} globals={this.state.Globals} /> */}
                {/* <Route path="szamlazas" name="Számlázás" component={SzamlazasProfil} globals={this.state.Globals}/> */}
                {/* <IndexRoute component={Profil}/> */}
              </Route>
            )}
            {(this.hasRole(['UDULESIJOGSZLA_SZAMLAKEZELES', 'BANK_PUTELJ', 'UDULESIJOG_UDJOG'], true) || this.hasRoleUnique('ROLE_APARTMAN_APARTMAN_SZABAD')) && (
              <Route path="lista" name="Listák">
                <IndexRoute component={Listak} />
                {this.hasRole(['UDULESIJOG_UDJOG'], true) && (
                  <Route path="kompenzacio" name="Kompenzáció" component={KompenzacioListaBase} globals={this.state.Globals} />
               )}
                {this.hasRole(['UDULESIJOGSZLA_SZAMLAKEZELES'], true) && (
                  <Route path="szamla" name="Számla" component={SzamlaListaBase} globals={this.state.Globals} />
               )}
                {this.hasRole(['BANK_PUTELJ'], true) && (
                  <Route path="bankalaplista" name="Bank alaplista" component={BankAlaplistaBase} globals={this.state.Globals} />
               )}
                {this.hasRoleUnique('ROLE_APARTMAN_APARTMAN_SZABAD') && (
                  <Route path="szabadapartman" name="Szabad apartmanok" component={SzabadApartmanLekerdezesBase} globals={this.state.Globals} />
               )}
                {this.hasRole(['UDULESIJOG_UDJOG'], true) && (
                  <Route path="udulesijoglista" name="Üdülésijog lista" component={UdulesiJogListaBase} globals={this.state.Globals} />
                )}
              </Route>
            )}
            <Route path="*" name="404" component={Page404} />
          </Route>
        </Router>
      </div>
    );
  }
}

export default Main;
