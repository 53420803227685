import Lib from '../../../commons/lib';

export default class Service {
  static udulesijogUrl = window.location.origin + '/api/udulesijog/';

  // RCI PROFIL START

  static editRciKotelezoAdatok(data, params, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.udulesijogUrl + 'udulesijog/rci/karb',
      params: params,
      data: data
    }], fnDone);
  }

  static listRciKotelezoAdatok(params, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'udulesijog/rci/karb',
      params: params,
    }], fnDone);
  }

  // RCI PROFIL END
}
