import React from 'react';
import PropTypes from 'prop-types';
import CegadatContent from './CegadatContent';

const cardStyle = {
  padding: 20
};

const CegadatBase = (props) => {
  let lek = null;
  if (props.location.state) {
    lek = props.location.state.lek
  }
  return (
    <div>
      <div className="animated fadeIn">
        <div className="card">
          <div className="card-header">
            {'Cégadatok'}
          </div>
          <div className="row" style={cardStyle}>
            <div className="col-md-12">
              <CegadatContent globals={props.route.globals} lek={lek} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CegadatBase.propTypes = {
  route: PropTypes.object,
  globals: PropTypes.object,
  location: PropTypes.object,
  state: PropTypes.object,
};

export default CegadatBase;
