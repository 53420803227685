import React, { Component } from 'react';
import { AvForm } from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {
  selectFilter,
  textFilter,
} from 'react-bootstrap-table2-filter';
import { Button, Modal, UncontrolledTooltip } from 'reactstrap';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { browserHistory } from 'react-router';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import PropTypes from 'prop-types';
import Services from './services';
import Loader from '../../../commons/loader';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import { PaginationTotal } from '../../../commons/controls';
import Lib from '../../../commons/lib';

class FelhasznalokCsoportContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalDelete: false,
      // ['LOAD', 'DATA', 'NETW', 'TABL']
      tableStatus: 'TABL',
      groups: [],
    };
  }

  componentDidMount() {
    this.getGroupList();
  }

  getGroupList = () => {
    this.setState({ tableStatus: 'LOAD' });
    Services.getGroupList((err, res) => {
      if (!err) {
        let array = res[0].sort((a, b) => a.name.localeCompare(b.name));
        this.setState({
          groups: array,
          tableStatus: res[0].length > 0 ? 'TABL' : 'DATA',
        });
      } else {
        this.setState({ tableStatus: 'NETW' });
      }
    });
  };

  toggleDeleteModal = () => {
    this.setState((prevState) => { return { modalDelete: !prevState.modalDelete }; });
  }

  deleteGroup = () => {
    Services.deleteGroup(this._id, (err) => {
      if (!err) {
        this.props.globals.addSuccess('Csoport törölve!');
        this.getGroupList();
        this.toggleDeleteModal();
      }
    });
  }

  handleEditClick = (id) => {
    browserHistory.push(''.concat('/profil/felhasznalok/csoport/modositas?id=' + id));
  }

  handleDeleteClick = (id) => {
    this._id = id;
    this.toggleDeleteModal();
  }

  handleAddGroup = () => {
    browserHistory.push(''.concat('/profil/felhasznalok/csoport/felvetel'));
  };

  tableIconFormatter = (cell) => {
    return (
      <>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'CONFIG_USER', 'KARB') && (
          <React.Fragment>
            <Button
              className="icon-pencil table-action-icon"
              id={'action-view-' + cell + 'modositas'}
              onClick={() => this.handleEditClick(cell)}
              type="button"
            />
            <UncontrolledTooltip
              style={{ float: 'left' }}
              placement="top"
              target={'action-view-' + cell + 'modositas'}
              delay={50}
            >
              {'Módosítás'}
            </UncontrolledTooltip>
            <Button
              className="icon-trash table-action-icon"
              id={'action-view-' + cell + 'file'}
              onClick={() => this.handleDeleteClick(cell)}
              type="button"
            />
            <UncontrolledTooltip
              style={{ float: 'left' }}
              placement="top"
              target={'action-view-' + cell + 'file'}
              delay={50}
            >
              {'Törlés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </>
    );
  };

  tableStatusFormatter = (cell) => {
    return cell === true ? (
      <span className="badge badge-success">Aktív</span>
    ) : (
      <span className="badge badge-default">Inaktív</span>
    );
  };

  renderGroupTable = () => {
    const options = {
      sizePerPage: 25,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],
    };

    const { groups } = this.state;
    const columns = [
      {
        dataField: 'name',
        text: 'Csoport neve',
        filter: textFilter({
          delay: 400,
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
        },
        sort: true
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        formatter: this.tableIconFormatter,
      },
    ];
    return (
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={groups}
        columns={columns}
        filter={filterFactory()}
        pagination={paginationFactory(options)}
      />
    );
  };

  renderButtons = () => {
    return (
      <Row className="mb-1">
        <Col>
          <Button type="button" color="primary" onClick={this.handleAddGroup}>
            Csoport rögzítése
          </Button>
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          {this.renderButtons()}
          {
            {
              LOAD: <Loader />,
              DATA: (
                <DataEmptyState hasImage subtitle="Vigyél fel új tételt!" />
              ),
              NETW: (
                <NetworkEmptyState
                  hasImage
                  submitClick={() => {
                    this.getGroupList();
                  }}
                />
              ),
              TABL: this.renderGroupTable(),
            }[this.state.tableStatus]
          }
          <Modal
            isOpen={this.state.modalDelete}
            toggle={this.toggleDeleteModal}
            backdrop="static"
          >
            <ModalHeader>Csoport törlése</ModalHeader>
            <ModalBody>Ha törli a csoportot, a felhasználóktól is eltávolításra kerül</ModalBody>
            <ModalFooter>
              <Button type="button" color="primary" onClick={this.deleteGroup}>Igen</Button>
              <Button type="button" color="secondary" onClick={this.toggleDeleteModal}>Mégsem</Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

FelhasznalokCsoportContent.propTypes = {
  globals: PropTypes.object
};

export default FelhasznalokCsoportContent;
