import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import { PaginationTotal } from '../../../../../commons/controls';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { browserHistory } from 'react-router';
import Select from 'react-select/lib/Async';
import CikkSelect from '../CikkSelect.jsx';
import _ from 'lodash';
import { DatePicker, DatetimePicker } from '../../../../../commons/controls.js';
import { ProgressButton } from '../../../../../commons/ProgressButton';
import Service from '../services.js';
import moment from 'moment';
import NumberFormatter from '../../../../../commons/NumberFormatter';

/**
 * Osztály listázza a készletcsökkenés.
 * @extends Component
 */
class SelejtezesForm extends Component {

  constructor(props) {
    super(props);
    this.state = {

      modalTorles: false,
      modalCikk: false,
      modalAccept: false,

      formMozgasTipus: {id : "SELEJT"},
      formRaktar: null,
      formMegjegyzes: '',
      formMozgasDatum: new Date(),

      selejtezes: {},
      editobj: {},
      cikkek: [],

      formKeszletInfoRaktarak: [],
      formKeszletLoadingStatus: 'LOAD',
      mentesLoadingButton: false,
      szabadMennyiseg: null,
    };
    this.raktarAzonosito = 'KESZLET';
  }

  componentWillMount() {
    let params = new URL(window.location.href).searchParams;
    let id = params.get('id');
    if (this.props.route.modositas === 'true') {
      if (id) this.getData(id);
    } else {
      this.setState({
          formMozgasTipus: { id:  "SELEJT"},
      });
    }
  }

  modosit = () => {
    browserHistory.push("".concat('/keszlet/keszletkezeles/keszletselejtezes/felvetel'));
  }

  handleBackClick = () => {
    browserHistory.push("".concat('/keszlet/keszletkezeles?page=3'));
  }

  toggle = () => {
    this.setState(prevState => ({ modal: !prevState.modal }));
  }

  toggleTorles = () => {
    this.setState({ modalTorles: !this.state.modalTorles });
  }

  toggleAccept = () => {
    this.setState({ modalAccept: !this.state.modalAccept });
  }

  toggleCikk = () => {
    this.setState({ modalCikk: !this.state.modalCikk });
  }

  getData = (id) => {
    Service.getSelejtezes(id, (err, res) => {
      if (!err) {
        let selejtezes = res[0];
        this.setState({ 
          selejtezes: selejtezes, 
          cikkek: res[0].cikkek, 
          formRaktar: res[0].raktar,
          formMegjegyzes: res[0].megjegyzes,
          formMozgasDatum: res[0].mozgasDatum,
        });
      }
    });
  }

  save = (event, selejtezesObj) => {
    let submitType = document.activeElement.getAttribute('id');
    if(this.state.cikkek && this.state.cikkek.length === 0 && submitType !== 'save-and-close'){
      this.props.globals.addWarning('Nem lett cikk kiválasztva!');
    }

    let submitObj = {};
    submitObj.mozgasTipus = this.state.formMozgasTipus && this.state.formMozgasTipus.id;
    submitObj.mozgasDatum =  this.state.formMozgasDatum && moment(this.state.formMozgasDatum).format("YYYY-MM-DDTHH:mm:ss.SSSZZ");
    submitObj.raktarid = this.state.formRaktar && this.state.formRaktar.id;
    submitObj.megjegyzes = this.state.formMegjegyzes;
    submitObj.cikkek = this.state.cikkek;

    if (submitType === 'save-and-close' && !this.state.modalAccept) {
      this._currentAcceptObj = selejtezesObj;
      this.toggleAccept();
      return;
    } else if (submitType === 'save-and-close' && this.state.modalAccept) {
      this.toggleAccept();
    }

    if (this.state.selejtezes && this.state.selejtezes.id) {
      this.editKeszlet(submitObj); 
    } else {
      this.addKeszlet(submitObj); 
    }
  }

  addKeszlet(selejtezesObj) {
    let submitType = document.activeElement.getAttribute('id');
    if (submitType === 'save-and-close') {
      this.setState({mentesLoadingButton: true}, () => {
        Service.acceptAddSelejtezes(selejtezesObj, (err, res) => {
          if (!err) {
            this.props.globals.addSuccess('Selejtezési tétel véglegesítve!');
            browserHistory.push("".concat('/keszlet/keszletkezeles?page=3'));
            this.setState({mentesLoadingButton: false});
          }else{
            this.setState({mentesLoadingButton: false});
          }
        });
      });

    } else {
      this.setState({mentesLoadingButton: true}, () => {
        Service.addSelejtezes(selejtezesObj, (err, res) => {
          if (!err) {
            this.props.globals.addSuccess('Selejtezési tétel hozzáadva!');
            browserHistory.push("".concat('/keszlet/keszletkezeles?page=3'));
            this.setState({mentesLoadingButton: false});
          }else{
            this.setState({mentesLoadingButton: false});
          }
        });
      });
    }
  }

  editKeszlet(selejtezesObj) {
    let submitType = document.activeElement.getAttribute('id');
    if (submitType === 'save-and-close') {
      this.setState({mentesLoadingButton: true}, () => {
        Service.acceptEditSelejtezes(selejtezesObj, this.state.selejtezes.id, (err, res) => {
          if (!err) {
            this.props.globals.addSuccess('Selejtezési tétel módosítva és véglegesítve!');
            browserHistory.push("".concat('/keszlet/keszletkezeles?page=3'));
            this.setState({mentesLoadingButton: false});
          }else{
            this.setState({mentesLoadingButton: false});
          }
        });
      });
    } else {
      this.setState({mentesLoadingButton: true}, () => {
        Service.editSelejtezes(selejtezesObj, this.state.selejtezes.id, (err, res) => {
          if (!err) {
            this.props.globals.addSuccess('Selejtezési tétel módosítva!');
            browserHistory.push("".concat('/keszlet/keszletkezeles?page=3'));
            this.setState({mentesLoadingButton: false});
          }else{
            this.setState({mentesLoadingButton: false});
          }
        });
      });
    }
  }

  loadRaktarOptions = (input, callback) => {
    Service.listBizonylatRaktar(this.raktarAzonosito, null, (err, res) => {
      if (!err) {
        let options = [];
        res[0].filter(k => k.nev.toLowerCase().includes(input.toLowerCase())).forEach((item) => {
          options.push(item);
        });
        callback(options);
      }
    });
  }

  handleNewCikkClick = (id) => {
    this.setState({ editmode: false, editobj: undefined}, () => {
      this.toggleCikk();
    });
  }

  handleEditCikkClick = (id) => {
    const editobj = this.state.cikkek.find(keszletcikk => keszletcikk.cikkid === id);
    this.setState({ editmode: true, editobj, selectedCikkId: id }, () => {
      this.toggleCikk();
    });
  }

  handleDeleteCikkClick = (id) => {
    this._currentDeleteId = id;
    this.toggleTorles();
  }

  handleListItemClick = (raktarId) => {
    let newRaktarak = [];
    const raktarak = JSON.parse(JSON.stringify(this.state.formKeszletInfoRaktarak));
    raktarak.forEach((item) => {
      item.raktar.selected = item.raktar.id === raktarId;
      newRaktarak.push(item);
    });
    this.setState({ formKeszletInfoRaktarak: newRaktarak });
  }

  onSaveKeszletInfo = (raktarId, szabadMennyiseg, raktarNev) => {
    this.setState({ szabadMennyiseg: szabadMennyiseg, raktar: { id: raktarId, nev: raktarNev }});
  }

  getKeszletInfoData = () => {
    const { formRaktar, } = this.state;
    this.setState({ formKeszletLoadingStatus: 'LOAD' }, () => {
      Service.getRaktarList([this.state.selectedCikkId], formRaktar && formRaktar.id ? [formRaktar.id] : [], (err, res) => {
        if (!err) {
          this.setState({
            formKeszletInfoRaktarak: res[0],
            formKeszletLoadingStatus: 'DATA',
          });
        } else {
          this.setState({
            formKeszletInfoRaktarak: [],
            formKeszletLoadingStatus: 'NETW'
          });
        }
      });
    });
  }

  getRaktarList = (id) => {
    Service.getRaktarList(id, this.state.formRaktar.id, (err, res) => {
      if (!err) {
        this.setState({
          formKeszletInfoRaktarak: res[0]
        });
      } else {
        this.setState({
          formKeszletInfoRaktarak: []
        });
      }
    });
  }

  changeCikkId = (cikkid) => {
    this.setState({ selectedCikkId: cikkid});
  }

  addCikk = (event, cikkObj) => {
    this.setState({ cikkek: [...this.state.cikkek, cikkObj] , editobj: cikkObj}, () => this.toggleCikk());    
  }

  editCikk = (event, cikkObj) => {
    let edited = this.state.cikkek.find(keszletcikk => keszletcikk.cikkid === this.state.editobj.cikkid);
    this.state.cikkek[this.state.cikkek.indexOf(edited)] = cikkObj;
    this.setState({ cikkek: this.state.cikkek, editmode: false, editobj: cikkObj }, () =>  this.toggleCikk());
  }

  deleteCikk = async (id) => {
    const cikkek = await this.state.cikkek.filter(cikk => cikk.cikkid !== id);
    let raktar = cikkek.length !== 0 ? this.state.raktar : {nev: '', id: null};
    this.setState({ raktar, cikkek}, () => this.toggleTorles());
  }

  checkDate = (date) => {
    return date ? new Date(date) : new Date();
  }

  handleDateInputChange = (e, v, name) => {
    const value = v === '' ? null : v;
    this.setState({
      [name]: value
    });
  }

  handleInputChange = (e) => {
    const { target } = e;
    const { name } = target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      [name]: value
    });
  }

  isMozgasTipusEquals = (mozgasTipus) => {
    return this.state.formMozgasTipus && this.state.formMozgasTipus.id === mozgasTipus;
  }

  cikkIconFormatter = (cell) => {
    return (
      <div>
        <Button className="icon-pencil table-action-icon" onClick={() => this.handleEditCikkClick(cell)} />
        <Button className="icon-trash table-action-icon" onClick={() => this.handleDeleteCikkClick(cell)} />
      </div>
    );
  }

  renderCikkTable = () => {
    const options = {
      sizePerPage: 5,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const columns = [
      {
        dataField: 'cikk.cikkszam',
        text: 'Cikkszám',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
          textAlign: 'right' 
        },
        sort: true
      },
      {
        dataField: 'cikk.megnevezes',
        text: 'Megnevezés',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
          textAlign: 'right' 
        },
        sort: true
      },
      {
        dataField: 'mennyiseg',
        text: 'Mennyiség',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: NumberFormatter.styleTextAlignWhiteSpace,
        formatter: (cell, row) => NumberFormatter.formatterSpaceOsszeg(cell) + (row && row.cikk ? ' ' + row.cikk.mennyisegiEgyseg : ''),
        sort: true
      },
      {
        dataField: 'cikk.id',
        text: 'Műveletek',
        align: 'center',
        headerStyle: { textAlign: 'center' },
        formatter: this.cikkIconFormatter
      }
    ];
    return (
      <React.Fragment>
        <Button className="table-new-button" type="button" color="success" onClick={this.handleNewCikkClick}>Cikk kiválasztása</Button>
        <div className="animated fadeIn">
          <BootstrapTable
            bootstrap4
            keyField="id"
            data={this.state.cikkek}
            columns={columns}
            filter={filterFactory()}
            pagination={paginationFactory(options)}
            noDataIndication="Nincs megjelenítendő adat!"
          />
        </div>
      </React.Fragment>
    );
  }

  renderForm = () => {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-4">
            <Label for="formRaktar">Raktár:</Label>
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="formRaktar"
              id="formRaktar"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              onChange={v => this.setState({formRaktar: v})}
              loadOptions={_.debounce(this.loadRaktarOptions, 1000)}
              getOptionLabel={option => option.nev}
              getOptionValue={option => option.id}
              value={this.state.formRaktar}
              defaultOptions
              isClearable
            />
          </div>
          <div className="col-md-4">
            <Label for="formMozgasDatum">Mozgás dátuma:</Label>
            <DatetimePicker 
              name="formMozgasDatum"
              value={this.checkDate(this.state.formMozgasDatum)}
              onChange={(e, v) => this.handleDateInputChange(e, v, 'formMozgasDatum')}
              max={new Date()}
              required={this.isMozgasTipusEquals('SZAM')} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <AvGroup>
              <Label for="formMegjegyzes">Megjegyzés:</Label>
              <AvInput 
                  name="formMegjegyzes" 
                  id="formMegjegyzes" 
                  value={this.state.formMegjegyzes}
                  onChange={this.handleInputChange} 
                />
            </AvGroup>
          </div>
        </div>
      </React.Fragment>
    );
  }

  renderButtons = () => {
    return (
      <div className="top-15-margin">
        <ProgressButton color="primary" id="save" loading={this.state.mentesLoadingButton} >Mentés</ProgressButton>{' '}
        <ProgressButton color="primary" id="save-and-close" loading={this.state.mentesLoadingButton} >Mentés és véglegesítés</ProgressButton>{' '}
        <Button type="button" color="secondary" onClick={this.handleBackClick}>Mégse</Button>
      </div>
    );
  }

  renderModals = () => {
    return (
      <React.Fragment>
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className} backdrop="static">
          <ModalHeader >Törlés</ModalHeader>
          <ModalBody>Valóban törölni kívánja a kiválasztott tételt?</ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteCikk(this._currentDeleteId)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalAccept} toggle={this.toggleAccept} className={this.props.className} backdrop="static">
          <ModalHeader>Mentés és véglegesítés</ModalHeader>
          <ModalBody>„Mentés és véglegesítés" után az adott tétel nem módosítható, törölhető, csak stornózható! Valóban elfogadja rögzített adatok mentését?</ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" id="save-and-close" onClick={() => this.save(null, this._currentAcceptObj)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleAccept}>Mégsem</Button>
          </ModalFooter>
        </Modal>

        <CikkSelect
          isOpen={this.state.modalCikk}
          toggle={this.toggleCikk}
          add={this.addCikk}
          cikkek={this.state.cikkek}
          editmode={this.state.editmode}
          editobj={this.state.editobj}
          edit={this.editCikk}
          mozgasTipus={{id: "SELEJT"}}
          raktar={this.state.raktar}
          formKeszletInfoRaktarak={this.state.formKeszletInfoRaktarak}
          formKeszletLoadingStatus={this.state.formKeszletLoadingStatus}
          getData={this.getKeszletInfoData}
          handleListItemClick={this.handleListItemClick}
          selectedCikkId={this.state.selectedCikkId}
          changeCikkId={this.changeCikkId}
          price={false}
          keszletinfo
          globals={this.props.globals}
          selectedSzabad={this.state.szabadMennyiseg}
          onSave={this.onSaveKeszletInfo}
        />
      </React.Fragment>
    );
  }

  render = () =>  {
    return (
      <div>
        <AvForm onValidSubmit={this.save}>
          {this.state.formMozgasTipus && this.renderForm()}
          {this.state.formMozgasTipus && this.renderCikkTable()}
           {this.renderButtons()}
          </AvForm>
          {this.renderModals()}
      </div>
    );
  }
}

export default SelejtezesForm;
