import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  UncontrolledTooltip
} from 'reactstrap';
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback
} from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Service from './services';
import { PaginationTotal } from '../../../commons/controls';
import Lib from '../../../commons/lib';

/**
 * Osztály listázza a cikkcsoportokat.
 * @extends Component
 */
class CikkcsoportLista extends Component {
  /**
  * CikkcsoportLista konstruktor.
  * @param {object} props
  */
  constructor(props) {
    super(props);

    /**
    * @type {object}
    * @property {boolean} modal Új cikkcsoport felviteli modalt vezérlő logikai változó.
    * @property {boolean} modalTorles Cikkcsoport törlését megerősítő modalt vezértlő logikai változó.
    * @property {{statusz: string, nev: string, sajatpath: string, szulopath: string, vancikkcsoportja: boolean, vancikke: boolean}} cikkcsoportJson Cikkcsoportokat tartalmazó tömb.
    */
    this.state = {
      modal: false,
      modalTorles: false,
      modalModosit: false,
      felettesek: [],
      cikkcsoportJson: [],
      cikkcsoportModositObj: [],
      statusz: [],
      statuszOptions: [],
      filterOptions: {},
    };
  }

  /** Táblázatadatok frissítése miután felépültek a komponensek. */
  // componentDidMount() {
  //   this.updateTable();
  // }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      if (this.props.active) {
        this.updateTable();
      }
    }
  }

  /** Táblázat adatainak frissítése adatbázisból. */
  updateTable = () => {
    Service.listCikkcsoport((err, res) => {
      if (!err) {
        let filters = {};
        let options = res[0].statusz.map((e) => {
          filters[e.name] = e.caption;

          return <option key={e.name} value={e.name}>{e.caption}</option>;
        });

        this.setState({
          cikkcsoportJson: res[0].list,
          filterOptions: filters,
          statusz: res[0].statusz,
          statuszOptions: options
        });
      }
    });
  }

  /** Új cikkcsoport felviteli modal kapcsolása. */
  toggle = () => {
    this.setState({ modal: !this.state.modal });
    this._currentEditId = undefined;
    this.renderOptions();
  }

  /** Cikkcsoport módosító modal. */
  toggleModosit = () => {
    if (!this.state.modalModosit) {
      this.renderOptions();
    }
    this.setState({ modalModosit: !this.state.modalModosit });
  }

  /** Meglévő cikkcsoport törlés megerősítés modal kapcsolása. */
  toggleTorles = () => {
    this.setState({modalTorles: !this.state.modalTorles});
  }

  /**
  * Letárolja a módosítani kívánt cikkcsoport id-ját.
  * @param {string} id - Aktuális cikkcsoport id-ja.
  */
  handleEditClick = (id) => {
    const cikkcsoport = this.state.cikkcsoportJson.find(element => element.id === id);
    this._currentEditId = id;
    this.setState({ cikkcsoportModositObj: cikkcsoport });
    this.toggleModosit();
  }

  /**
  * Letárolja a törölni kívánt cikkcsoport id-ját.
  * @param {string} id - Aktuális cikkcsoport id-ja.
  */
  handleDeleteClick = (id) => {
    this._currentDeleteId = id;
    this.toggleTorles();
  }

  /**
  * Visszaadja a cikkcsoporthoz tartozó művelet gombokat.
  * @param {string} cell - Cella adatot tartalmazó szöveg.
  * @return {ReactElement} Módosítás, törlés gombokat tartalmazó react objektum.
  */
  iconFormatter = (cell) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'CIKK_CSOPORT', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => this.handleEditClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'modositas'} delay={0}>
              Módosítás
            </UncontrolledTooltip>
            <Button className="icon-trash table-action-icon" id={'action-view-' + cell + 'torles'} onClick={() => this.handleDeleteClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="left" target={'action-view-' + cell + 'torles'} delay={0}>
              Törlés
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  /**
  * Visszaadja a cikkcsoporthoz tartozó státus jelvényeket.
  * @param {boolean} cell - Cella adatot tartalmazó szöveg.
  * @return {ReactElement} Státusz jelvényt tartalmazó react objektum.
  */
  statusFormatter = (cell) => {
    let statusz = this.state.statusz.find(x => x.name === cell);
    return statusz ? <span className={'badge badge-' + statusz.bagde}>{statusz.caption}</span> : 'N/A';
  }

  /**
  * Paraméterként kapott cikkcsoport objektum felvétele adatbázisba.
  * @param {SytheticEvent} event
  * @param {object} cikkcsoportObj Cikkcsoport adatokat tartalamzó objektum.
  */
  addCikkcsoport = (event, cikkcsoportObj) => {
    Service.addCikkcsoport(cikkcsoportObj, (err, res) => {
      if (!err) {
        this.toggle();
        this.updateTable();
        this.props.globals.addSuccess('Cikkcsoport hozzáadva!');
      }
    });
  }

  /**
  * Visszaadja a paraméterben kapott path-oz tartozó cikkcsoport nevét.
  * @return {Array.ReactElement} Cikkcsoport neve.
  */
  renderOptions = () => {
    Service.listEmptyCikkcsoport(this._currentEditId, (err, res) => {
      if (!err) {
        let felettesek = res[0].map(e => <option key={e.id} value={e.id}>{e.nev}</option>);
        this.setState({ felettesek: felettesek });
      }
    });
  }

  /**
  * Törli az adott cikkcsoportot a paraméterként kapott id alapján.
  * @param {string} id - Cikkcsoport sajatpath attributuma.
  */
  deleteCikkcsoport = (id) => {
    this.toggleTorles();
    Service.deleteCikkcsoport(id, (err, res) => {
      if (!err) {
        this.updateTable();
        this.props.globals.addSuccess('Cikkcsoport törölve!');
      }
    });
  }

  /**
  * Paraméterként kapott fioktelep objektum módosítása adatbázisba.
  * @param {SytheticEvent} event
  * @param {object} cikkcsoportObj Fioktelep adatokat tartalamzó objektum.
  */
  modifyCikkcsoport = (event, cikkcsoportObj) => {
    cikkcsoportObj.id = this._currentEditId;

    Service.editCikkcsoport(cikkcsoportObj, (err) => {
      if (!err) {
        this.toggleModosit();
        this.updateTable();
        this.props.globals.addSuccess('Cikkcsoport módosítva!');
      }
    });
  }

  /**
   * React render.
   * @return {ReactElement} Cikkcsoport modul.
   */
  render() {
    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        {
          text: '5',
          value: 5
        },
        {
          text: '10',
          value: 10
        },
        {
          text: '25',
          value: 25
        },
      ]
    };

    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'CIKK_CSOPORT', 'KARB') && (
          <div className="row">
            <div className="col-sm-12">
              <Button className="pull-left table-new-button" color="primary" onClick={this.toggle}>Új cikkcsoport</Button>
            </div>
          </div>
        )}
        <BootstrapTable data={this.state.cikkcsoportJson} pagination options={options}>
          <TableHeaderColumn dataField="nev" filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Cikkcsoport neve</TableHeaderColumn>
          <TableHeaderColumn dataField="parentnev" filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Felettes cikkcsoport</TableHeaderColumn>
          <TableHeaderColumn
            dataField="statusz"
            dataFormat={this.statusFormatter}
            filter={{
              type: 'SelectFilter',
              options: this.state.filterOptions,
              placeholder: 'Összes',
              condition: 'eq'
            }}
            dataAlign="center"
          >
            Státusz
          </TableHeaderColumn>
          <TableHeaderColumn dataField="id" isKey dataFormat={this.iconFormatter} hidden={!Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'CIKK_CSOPORT', 'KARB')}>Műveletek</TableHeaderColumn>
        </BootstrapTable>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} backdrop="static">
          <AvForm onValidSubmit={this.addCikkcsoport}>
            <ModalHeader>Cikkcsoport felvétel</ModalHeader>
            <ModalBody>
              <AvGroup>
                <Label for="nev">Cikkcsoport neve: *</Label>
                <AvInput name="nev" id="nev" required />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
              <AvField type="select" name="parent" label="Felettes cikkcsoport:">
                {/* errorMessage="Kötelező mező!" required */}
                <option value="">Válassz felettest...</option>
                {/* <option value="null">Nincs</option> */}
                {this.state.felettesek}
              </AvField>
              <AvField type="select" name="statusz" label="Státusz: *" errorMessage="Kötelező mező!" value="AKTIV" required>
                {this.state.statuszOptions}
              </AvField>
            </ModalBody>
            <ModalFooter>
              <Button color="primary">Mentés</Button>
              {' '}
              <Button type="button" color="secondary" onClick={this.toggle}>Mégse</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
        <Modal isOpen={this.state.modalModosit} toggle={this.toggleModosit} className={this.props.className} backdrop="static">
          <AvForm onValidSubmit={this.modifyCikkcsoport} ref="asd">
            <ModalHeader>Cikkcsoport módosítás</ModalHeader>
            <ModalBody>
              <AvGroup>
                <Label for="nev">Cikkcsoport neve: *</Label>
                <AvInput name="nev" id="nev" value={this.state.cikkcsoportModositObj.nev} required />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
              <AvField type="select" name="parent" label="Felettes cikkcsoport:" value={this.state.cikkcsoportModositObj.parentid}>
                <option value="">Válassz felettest...</option>
                {this.state.felettesek}
              </AvField>
              <AvField type="select" name="statusz" label="Státusz: *" errorMessage="Kötelező mező!" value={this.state.cikkcsoportModositObj.statusz} required>
                {this.state.statuszOptions}
              </AvField>
            </ModalBody>
            <ModalFooter>
              <Button className="btn-cursor-pointer" color="primary">Mentés</Button>
              {' '}
              <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleModosit}>Mégse</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} className={this.props.className} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>
            Valóban törölni kívánja a kiválasztott tételt?
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.deleteCikkcsoport(this._currentDeleteId)}>OK</Button>
            <Button type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

CikkcsoportLista.propTypes = {
  globals: PropTypes.object,
  active: PropTypes.bool,
  className: PropTypes.string,
};

export default CikkcsoportLista;
