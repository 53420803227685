import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Button } from 'reactstrap';

const portal = document.createElement('div');
portal.classList.add('my-super-cool-portal');

if (!document.body) {
  throw new Error('body not ready for portal creation!');
}

document.body.appendChild(portal);

class ListaItem extends Component {
  render() {
    const {
      provided,
      snapshot,
      text,
      deleteItem,
      id,
      loading
    } = this.props;

    const usePortal = snapshot.isDragging;

    const child = (
      <div
        style={{ marginBotton: 10 }}
        className={snapshot.isDragging ? 'card text-white bg-primary dnd-list' : 'card bg-light dnd-list'}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        <div className="card-body" style={loading ? { padding: 10, backgroundColor: 'gainsboro', backgroundImage: 'repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(255,255,255,.5) 10px, rgba(255,255,255,.5) 20px)' } : { padding: 10 }}>
          <span>
            {text}
            {loading ? ' mentés...' : ''}
          </span>
          <span>
            <Button
              className="icon-trash table-action-icon pull-right"
              type="button"
              style={{ borderColor: 'transparent', backgroundColor: 'transparent', cursor: 'pointer' }}
              onClick={() => {
                deleteItem(id);
              }}
            />
          </span>
        </div>
      </div>
    );

    if (!usePortal) {
      return child;
    }

    // if dragging - put the item in a portal
    return ReactDOM.createPortal(child, portal);
  }
}

export default ListaItem;
