import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import EvesdijBaseTable from './EvesdijBaseTable';
import HostwareNaploTable from './HostwareNaploTable';
import KompenzacioLista from './KompenzacioLista';
import classnames from 'classnames';
import Lib from '../../commons/lib';
import KompenzacioLezarasaLista from './KompenzacioLezarasaLista';
import UdulesiJogTabla from './UdulesiJogTabla';
const cardStyle = {
  padding: 20
};

class KompenzacioBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOG_UDJOG'], true) ? '1' : '2',
      type: 'KOMPENZACIO'
    };
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  changeUdulesijogData = (id) => {
    this.setState({
      tableUdulesijogData: id,
      collapseSzerzodes: true
    });
  }

  render() {

    let cardStyle = {
      padding: 10
    };

    return (
      <div>
        <div className="animated fadeIn">
          <div className="row" style={cardStyle}>
            <div className="col-md-12">
              <Nav tabs>
                {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOG_UDJOG'], true) &&
                  <NavItem>
                    <NavLink
                      href="#"
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => { this.toggle('1'); }}
                    >
                      Üdülési jog
                    </NavLink>
                  </NavItem>
                }
                {/*{Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOG_UDJOG']) &&
                  <NavItem>
                    <NavLink
                      href="#"
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => { this.toggle('2'); }}
                    >
                      Kompenzáció
                    </NavLink>
                  </NavItem>
                }
                {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOG_UDJOG']) &&
                  <NavItem>
                    <NavLink
                      href="#"
                      className={classnames({ active: this.state.activeTab === '3' })}
                      onClick={() => { this.toggle('3'); }}
                      >
                      Kompenzáció lezárása
                    </NavLink>
                  </NavItem>
                }*/}
                {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOG_UDJOG'], true) &&
                  <NavItem>
                    <NavLink
                      href="#"
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => { this.toggle('2'); }}
                    >
                      Éves díj
                    </NavLink>
                  </NavItem>
                }
                {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOG_HWNAPLO'], true) &&
                  <NavItem>
                    <NavLink
                      href="#"
                      className={classnames({ active: this.state.activeTab === '3' })}
                      onClick={() => { this.toggle('3'); }}
                    >
                      Foglalás napló
                    </NavLink>
                  </NavItem>
                }
              </Nav>
              <TabContent activeTab={this.state.activeTab}>
                {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOG_UDJOG'], true) &&
                  <TabPane tabId="1">
                    <UdulesiJogTabla
                      changeData={this.props.changeData}//changeUdulesijogData}
                      globals={this.props.globals}
                      location={this.props.location}
                      tableData={this.props.tableData}
                    />
                  </TabPane>
                }
                {/*{Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOG_UDJOG']) &&
                  <TabPane tabId="2">
                    <KompenzacioLista globals={this.props.route.globals} type={this.state.type} />
                  </TabPane>
                }
                {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOG_UDJOG']) &&
                  <TabPane tabId="3">
                    <KompenzacioLezarasaLista globals={this.props.route.globals} />
                  </TabPane>
                }*/}
                {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOG_UDJOG'], true) &&
                  <TabPane tabId="2">
                    <EvesdijBaseTable
                      globals={this.props.route.globals}
                    />
                  </TabPane>
                }
                {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOG_HWNAPLO'], true) && 
                  <TabPane tabId="3">
                    <HostwareNaploTable
                      globals={this.props.route.globals}
                    />
                  </TabPane>
                }
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

KompenzacioBase.propTypes = {
route: PropTypes.object,
globals: PropTypes.object
};

export default KompenzacioBase;
