import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';
import classnames from 'classnames';
import Lib from '../../commons/lib';
import KarbantartasContent from './KarbantartasContent';
import KarbantartasAlkalomContent from './KarbantartasAlkalomContent';
import ListakContent from './ListakContent';
import SzempontokContent from './SzempontokContent';
import CustomTooltip from '../../components/Tooltip/CustomTooltip';
import getTooltip from './KarbantartasTooltips';

class KarbantartasMenuBase extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: undefined,
    };
  }

  componentDidMount() {
    this.initTab();
  }

  initTab = () => {
    let activeTab = '1';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KARBANTARTAS_ELLENORZESILISTA'], true)) activeTab = '4';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KARBANTARTAS_ELLENORZESILISTA'], true)) activeTab = '3';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KARBANTARTAS_ALKALOM'], true)) activeTab = '2';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KARBANTARTAS_KARBANTARTAS'], true)) activeTab = '1';

    this.setState({ activeTab: activeTab });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  render() {
    let cardStyle = {
      padding: 10
    };

    return (
      <div>
        <div className="animated fadeIn">
          <div className="card">
            <div className="card-header">
              Karbantartás
              <CustomTooltip text={getTooltip('karbantartas')} id="karbantartasTT" />
            </div>
            <div className="row" style={cardStyle}>
              <div className="col-md-12">
                <Nav tabs>
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KARBANTARTAS_KARBANTARTAS'], true)
                  && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => { this.toggle('1'); }}
                      >
                        Karbantartások
                        <CustomTooltip text={getTooltip('karbantartasok')} id="karbantartasokTT" />
                      </NavLink>
                    </NavItem>
                  )
                  }
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KARBANTARTAS_ALKALOM'], true)
                  && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => { this.toggle('2'); }}
                      >
                        Karbantartás alkalmak
                        <CustomTooltip text={getTooltip('karbantartasalkalmak')} id="karbantartasalkalmakTT" />
                      </NavLink>
                    </NavItem>
                  )
                  }
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KARBANTARTAS_ELLENORZESILISTA'], true)
                  && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '3' })}
                        onClick={() => { this.toggle('3'); }}
                      >
                        Ellenőrzési szempontok
                        <CustomTooltip text={getTooltip('ellenorzesiszempontok')} id="ellenorzesiszempontokTT" />
                      </NavLink>
                    </NavItem>
                  )
                  }
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KARBANTARTAS_ELLENORZESILISTA'], true)
                  && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '4' })}
                        onClick={() => { this.toggle('4'); }}
                      >
                        Ellenőrzési listák
                        <CustomTooltip text={getTooltip('ellenorzesilistak')} id="ellenorzesilistakTT" />
                      </NavLink>
                    </NavItem>
                  )
                  }
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KARBANTARTAS_KARBANTARTAS'], true)
                  && (
                    <TabPane tabId="1">
                      <KarbantartasContent
                        globals={this.props.route.globals}
                        ref={(instance) => { this.child1 = instance; }}
                        active={this.state.activeTab === '1'}
                      />
                    </TabPane>
                  )
                  }
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KARBANTARTAS_ALKALOM'], true)
                  && (
                    <TabPane tabId="2">
                      <KarbantartasAlkalomContent
                        globals={this.props.route.globals}
                        ref={(instance) => { this.child1 = instance; }}
                        active={this.state.activeTab === '2'}
                      />
                    </TabPane>
                  )
                  }
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KARBANTARTAS_ELLENORZESILISTA'], true)
                  && (
                    <TabPane tabId="3">
                      <SzempontokContent
                        globals={this.props.route.globals}
                        ref={(instance) => { this.child4 = instance; }}
                        active={this.state.activeTab === '3'}
                      />
                    </TabPane>
                  )
                }
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['KARBANTARTAS_ELLENORZESILISTA'], true)
                  && (
                    <TabPane tabId="4">
                      <ListakContent
                        globals={this.props.route.globals}
                        ref={(instance) => { this.child5 = instance; }}
                        active={this.state.activeTab === '4'}
                      />
                    </TabPane>
                  )
                  }
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

KarbantartasMenuBase.propTypes = {
  route: PropTypes.object,
  globals: PropTypes.object
};

export default KarbantartasMenuBase;
