import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CustomInput,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap';
import {
  AvFeedback,
  AvForm,
  AvGroup,
  AvInput,
} from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import Loader from '../../../commons/loader';
import Services from './services';
import { PaginationTotal } from '../../../commons/controls';
import { ProgressButton } from '../../../commons/ProgressButton';
import Lib from '../../../commons/lib';

class OrszagContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // ['LOAD', 'DATA', 'NETW', 'TABL']
      tableStatus: 'TABL',
      // ['LOAD', 'DATA', 'NETW']
      formStatus: 'DATA',
      // ['FEL', 'MOD']
      formType: 'FEL',
      // DATA
      baseJson: [],
      currentId: undefined,
      torlesId: undefined,
      // MODAL UTILS
      modalForm: false,
      modalDelete: false,
      saveButtonLoading: false,
      deleteButtonLoading: false,
      // FORM VALUES
      formNev: '',
      formInterNev: '',
      formKod: '',
      formPostaiKod: '',
      formEuTag: false,
      formKeresheto: false,
    };
  }

  // componentDidMount() {
  //   this.listOrszag();
  // }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.active !== prevProps.active) {
      if (this.props.active) this.listOrszag();
    }
  }

  toggleFormModal = () => {
    this.setState(prevState => ({ modalForm: !prevState.modalForm }));
  }

  toggleDeleteModal = () => {
    this.setState(prevState => ({ modalDelete: !prevState.modalDelete }));
  }

  listOrszag = () => {
    this.setState({
      tableStatus: 'LOAD'
    }, () => {
      Services.listOrszag((err, res) => {
        if (!err) {
          this.setState({
            tableStatus: res[0].length > 0 ? 'TABL' : 'DATA',
            baseJson: res[0],
          });
        } else {
          this.setState({
            tableStatus: 'NETW'
          });
        }
      });
    });
  }

  getOrszag = (id) => {
    this.setState({
      formStatus: 'LOAD'
    });
    Services.getOrszag(id, (err, res) => {
      if (!err) {
        this.setState({
          formStatus: 'DATA',

          formNev: this.nullToString(res[0].nev),
          formInterNev: this.nullToString(res[0].intername),
          formKod: this.nullToString(res[0].kod),
          formPostaiKod: this.nullToString(res[0].postaikod),
          formEuTag: res[0].eutag,
          formKeresheto: res[0].keresheto,
        });
      } else {
        this.setState({
          formStatus: 'NETW'
        });
      }
    });
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  handleEditClick = (cell) => {
    this.setState({
      formType: 'MOD',
      currentId: cell,
      modalForm: true
    }, () => {
      this.getOrszag(cell);
    });
  }

  handleDeleteClick = (cell) => {
    this.setState({
      torlesId: cell
    }, () => {
      this.toggleDeleteModal();
    });
  }

  deleteOrszag = () => {
    this.setState({
      deleteButtonLoading: true
    });
    Services.deleteOrszag(this.state.torlesId, (err) => {
      if (!err) {
        this.toggleDeleteModal();
        this.listOrszag();
        this.props.globals.addSuccess('Ország törölve!');
        this.setState({
          deleteButtonLoading: false
        });
      } else {
        this.setState({
          deleteButtonLoading: false
        });
      }
    });
  }

  emptyOrNull = (data) => {
    if (!data || data === '') return null;
    return data;
  }

  nullToString = (data) => {
    if (!data) return '';
    return data;
  }

  handleNewClick = () => {
    this.setState({
      formType: 'FEL',
      formStatus: 'DATA',
      formNev: '',
      formInterNev: '',
      formKod: '',
      formPostaiKod: '',
      formEuTag: false,
      formKeresheto: false
    }, () => {
      this.toggleFormModal();
    });
  }

  submitForm = () => {
    const {
      currentId,
      formNev,
      formInterNev,
      formKod,
      formPostaiKod,
      formEuTag,
      formKeresheto,
      formType
    } = this.state;

    this.setState({
      saveButtonLoading: true
    });

    if (formType === 'FEL') {
      let submitObj = {};

      submitObj.nev = this.emptyOrNull(formNev);
      submitObj.intername = this.emptyOrNull(formInterNev);
      submitObj.kod = this.emptyOrNull(formKod);
      submitObj.postaikod = this.emptyOrNull(formPostaiKod);
      submitObj.eutag = formEuTag;
      submitObj.keresheto = formKeresheto;

      Services.addOrszag(submitObj, (err) => {
        if (!err) {
          this.props.globals.addSuccess('Ország felvéve!');
          this.setState({
            saveButtonLoading: false
          }, () => {
            this.toggleFormModal();
            this.listOrszag();
          });
        } else {
          this.setState({
            saveButtonLoading: false
          });
        }
      });
    } else if (formType === 'MOD') {
      let submitObj = {};

      submitObj.orszagid = this.emptyOrNull(currentId);
      submitObj.nev = this.emptyOrNull(formNev);
      submitObj.intername = this.emptyOrNull(formInterNev);
      submitObj.kod = this.emptyOrNull(formKod);
      submitObj.postaikod = this.emptyOrNull(formPostaiKod);
      submitObj.eutag = formEuTag;
      submitObj.keresheto = formKeresheto;

      Services.editOrszag(submitObj, (err) => {
        if (!err) {
          this.props.globals.addSuccess('Ország módosítva!');
          this.setState({
            saveButtonLoading: false
          }, () => {
            this.toggleFormModal();
            this.listOrszag();
          });
        } else {
          this.setState({
            saveButtonLoading: false
          });
        }
      });
    }
  }

  tableIconFormatter = (cell) => {
    return (
      <div>
        <React.Fragment>
          {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'TELEPULES_ORSZAG', 'KARB') && (
            <React.Fragment>
              <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => this.handleEditClick(cell)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
              <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'modositas'} delay={50}>
                {'Módosítás'}
              </UncontrolledTooltip>
              <Button className="icon-trash table-action-icon" id={'action-view-' + cell + 'file'} onClick={() => this.handleDeleteClick(cell)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
              <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'file'} delay={50}>
                {'Törlés'}
              </UncontrolledTooltip>
            </React.Fragment>
          )}
        </React.Fragment>
      </div>
    );
  }

  tableBooleanFormatter = (cell) => {
    return cell ? <span className="badge badge-success">Igen</span> : <span className="badge badge-danger">Nem</span>;
  }

  renderFormTitle = () => {
    switch (this.state.formType) {
      case 'FEL':
        return 'Új ország létrehozása';
      case 'MOD':
        return 'Ország módosítása';
      default:
        return '';
    }
  }

  renderTable = () => {
    const options = {
      sizePerPage: 10,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const selectOptions = {
      true: 'Igen',
      false: 'Nem',
    };

    const columns = [
      {
        dataField: 'nev',
        text: 'Ország név',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
        },
        sort: true
      },
      {
        dataField: 'intername',
        text: 'Nemzetközi név',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
        },
        sort: true
      },
      {
        dataField: 'kod',
        text: 'Ország kód',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          width: '150px',
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
        },
        sort: true
      },
      {
        dataField: 'postaikod',
        text: 'Postai kód',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          width: '150px',
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
        },
        sort: true
      },
      {
        dataField: 'eutag',
        text: 'EU tagság',
        filter: selectFilter({
          options: selectOptions,
          placeholder: 'Összes',
        }),
        style: {
          width: '150px',
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
          textAlign: 'center'
        },
        formatter: this.tableBooleanFormatter,
        sort: true
      },
      {
        dataField: 'keresheto',
        text: 'Kereshetőség',
        filter: selectFilter({
          options: selectOptions,
          placeholder: 'Összes',
        }),
        style: {
          width: '150px',
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
          textAlign: 'center'
        },
        formatter: this.tableBooleanFormatter,
        sort: true
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        hidden: !Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'TELEPULES_ORSZAG', 'KARB'),
        formatter: this.tableIconFormatter
      }
    ];

    return (
      <div className="animated fadeIn">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={this.state.baseJson}
          columns={columns}
          wrapperClasses="table-responsive"
          classes="table-scroll"
          headerClasses="table-th"
          filter={filterFactory()}
          pagination={paginationFactory(options)}
          noDataIndication="Nincs megjelenítendő adat!"
        />
      </div>
    );
  }

renderForm = () => {
  const {
    formNev,
    formInterNev,
    formKod,
    formPostaiKod,
    formEuTag,
    formKeresheto,
    formType
  } = this.state;

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-12">
          <AvGroup>
            <Label for="formNev">Név: *</Label>
            <AvInput
              name="formNev"
              id="formNev"
              type="text"
              value={formNev}
              onChange={this.handleInputChange}
              required
            />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-12">
          <AvGroup>
            <Label for="formInterNev">Nemzetközi név: *</Label>
            <AvInput
              name="formInterNev"
              id="formInterNev"
              type="text"
              value={formInterNev}
              onChange={this.handleInputChange}
              required
            />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-12">
          <AvGroup>
            <Label for="formKod">Ország kód: *</Label>
            <AvInput
              name="formKod"
              id="formKod"
              maxLength="10"
              type="text"
              value={formKod}
              onChange={this.handleInputChange}
              required
            />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-12">
          <AvGroup>
            <Label for="formPostaiKod">Postai kód:</Label>
            <AvInput
              name="formPostaiKod"
              id="formPostaiKod"
              maxLength="2"
              type="text"
              value={formPostaiKod}
              onChange={this.handleInputChange}
            />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
        </div>
        <div className="col-md-12" style={{ marginTop: 15 }}>
          <CustomInput
            type="checkbox"
            id="formEuTag"
            name="formEuTag"
            label="Eu tagság"
            checked={formEuTag}
            onChange={this.handleInputChange}
          />
        </div>
        <div className="col-md-12" style={{ marginTop: 15 }}>
          <CustomInput
            type="checkbox"
            id="formKeresheto"
            name="formKeresheto"
            label="Kereshetőség"
            checked={formKeresheto}
            onChange={this.handleInputChange}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

render() {
  return (
    <React.Fragment>
      <div className="row">
        <div className="col-md-12">
          {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'TELEPULES_ORSZAG', 'KARB') && (
            <Button className="pull-left table-new-button" color="primary" onClick={this.handleNewClick}>
              {'Új ország'}
            </Button>
          )}
        </div>
        <div className="col-md-12">
          {{
            LOAD: (<Loader />),
            DATA: (<DataEmptyState
              hasImage
              subtitle="Vigyél fel új tételt!"
            />),
            NETW: (<NetworkEmptyState
              hasImage
              submitClick={() => { this.listOrszag(); }}
            />),
            TABL: (this.renderTable())
          }[this.state.tableStatus]}
        </div>
      </div>
      <Modal isOpen={this.state.modalForm} toggle={this.toggleFormModal} backdrop="static" size="sm">
        <AvForm onValidSubmit={this.submitForm}>
          <ModalHeader>{this.renderFormTitle()}</ModalHeader>
          <ModalBody>
            {{
              LOAD: (<Loader />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => {}}
              />),
              DATA: (this.renderForm())
            }[this.state.formStatus]}
          </ModalBody>
          <ModalFooter>
            <ProgressButton
              className="btn-cursor-pointer"
              color="primary"
              loading={this.state.saveButtonLoading}
            >
              {'Mentés'}
            </ProgressButton>
            <Button type="button" color="secondary" className="btn-cursor-pointer" onClick={this.toggleFormModal}>Mégsem</Button>
          </ModalFooter>
        </AvForm>
      </Modal>
      <Modal isOpen={this.state.modalDelete} toggle={this.toggleDeleteModal} backdrop="static">
        <ModalHeader>Törlés</ModalHeader>
        <ModalBody>Valóban törölni kívánja a kiválasztott tételt?</ModalBody>
        <ModalFooter>
          <ProgressButton
            className="btn-cursor-pointer"
            color="primary"
            onClick={() => this.deleteOrszag()}
            loading={this.state.deleteButtonLoading}
          >
            {'Igen'}
          </ProgressButton>
          <Button type="button" color="secondary" onClick={this.toggleDeleteModal}>Mégsem</Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
}
}

OrszagContent.propTypes = {
  active: PropTypes.bool,
  globals: PropTypes.object
};

export default OrszagContent;
