import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from 'reactstrap';
import {
  AvForm, AvGroup, AvInput, AvFeedback, AvField
} from 'availity-reactstrap-validation';
// import Select from 'react-select/lib/Async';
import CreatableSelect from 'react-select/lib/Creatable';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Lib from '../../../commons/lib';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import Loader from '../../../commons/loader';
import Services from './services';
import { PaginationTotal } from '../../../commons/controls';
import { ProgressButton } from '../../../commons/ProgressButton';

class TelepulesContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // ['LOAD', 'DATA', 'NETW', 'TABL']
      tableStatus: 'LOAD',
      // ['FEL', 'MOD']
      formType: 'FEL',
      // FORM VALUES
      formOrszag: '',
      formTelepules: undefined,
      formIranyitoSzam: [],
      irszOptions: '',
      orszagok: [],
      // forceModIrszamok: '',
      // resultJSON: undefined,
      // UTILS
      currentId: null,
      baseJson: [],
      modalState: false,
      // modalForceModify: false,
      modalTorles: false,
      torlesId: null,
      saveButtonLoading: false,
      deleteButtonLoading: false
    };
  }

  // componentDidMount() {
  //   this.getTelepulesList();
  //   this.getOrszagok();
  // }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.active !== prevProps.active) {
      if (this.props.active) {
        this.getTelepulesList();
        this.getOrszagok();
      }
    }
  }

  nullToString = (data) => {
    if (!data) return '';
    return data;
  }

  getTelepulesList = () => {
    this.setState({
      tableStatus: 'LOAD'
    });
    Services.getTelepulesList((err, res) => {
      if (!err) {
        this.setState({
          tableStatus: 'TABL',
          baseJson: res[0]
        });
      } else {
        this.setState({
          tableStatus: 'NETW'
        });
      }
    });
  }

  getOrszagok = () => {
    Services.getOrszagList((err, res) => {
      if (!err) {
        this.setState({ orszagok: res[0] });
      }
    });
  }

  renderOrszagOptions = () => {
    let options = [];
    this.state.orszagok.forEach((e) => {
      options.push(<option key={e.orszagid} value={e.orszagid}>{e.nev}</option>);
    });
    return options;
  }

  getTelepules = (id) => {
    Services.getTelepules(id, (err, res) => {
      if (!err) {
        let iranyitoszamok = [];
        res[0].irszamok.forEach((item) => {
          iranyitoszamok.push({ label: item, value: item });
        });
        this.setState({
          formOrszag: { orszagid: res[0].orszagid, orszagnev: res[0].orszagnev },
          // forceModOrszag: { orszagid: res[0].orszagid, orszagnev: res[0].orszagnev },
          // forceModTelepules: res[0].telepulesnev,
          formTelepules: res[0].telepulesnev,
          formIranyitoSzam: iranyitoszamok
        });
      }
    });
  }

  toggleModal = () => {
    this.setState(prevState => ({ modalState: !prevState.modalState }));
  }

  // toggleForceModify = () => {
  //   this.setState(prevState => ({ modalForceModify: !prevState.modalForceModify }));
  // }

  toggleTorles = () => {
    this.setState(prevState => ({ modalTorles: !prevState.modalTorles }));
  }

  // toggleColor = (isFocused) => {
  //   if (!isFocused) {
  //     return 'black';
  //   }
  //   return 'red';
  // }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  handleOrszagChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState({
      formOrszag: value
    });
  }

  handleCreateIrsz = (newIrsz) => {
    Services.addIrsz({ label: newIrsz }, (err, res) => {
      let options = this.state.formIranyitoSzam;
      if (!err) {
        this.setState({
          formIranyitoSzam: [...options, { value: res[0].value, label: res[0].label }]
        });
      }
    });
  }

  listIrsz = (result) => {
    let irszamok = '';
    result.forEach((item) => {
      irszamok = item.iranyitoszam;
    });
    return irszamok;
  }

  handleNewClick = () => {
    this.setState({
      formType: 'FEL',
      formOrszag: '',
      formTelepules: '',
      formIranyitoSzam: []
    }, () => {
      this.toggleModal();
    });
  }

  handleEditClick = (cell) => {
    this.setState({
      formType: 'MOD',
      currentId: cell
    }, () => {
      this.getTelepules(cell);
      this.toggleModal();
    });
  }

  handleDeleteClick = (cell) => {
    this.setState({
      torlesId: cell
    }, () => {
      this.toggleTorles();
    });
  }

  handleSubmit = () => {
    const {
      formOrszag,
      formTelepules,
      formIranyitoSzam,
      formType,
      currentId
    } = this.state;

    this.setState({
      saveButtonLoading: true,
    });

    if (formType === 'FEL') {
      let irszam = [];
      formIranyitoSzam.forEach((item) => {
        irszam.push(item.value);
      });
      let submitObj = {
        orszagid: formOrszag.orszagid ? formOrszag.orszagid : formOrszag,
        telepulesnev: formTelepules,
        irszamok: irszam
      };
      if (formOrszag && formTelepules && irszam.length !== 0) {
        Services.addTelepules(submitObj, (err) => {
          if (!err && submitObj.irszamok !== []) {
            this.toggleModal();
            this.getTelepulesList();
            this.props.globals.addSuccess('Település sikeresen felvéve!');
            this.setState({
              saveButtonLoading: false
            });
          } else if (submitObj.irszamok.length === 0) {
            this.setState({
              saveButtonLoading: false,
            });
            this.props.globals.addError('Minimum egy irányítószám megadása kötelező!');
          } else {
            this.setState({
              saveButtonLoading: false,
            });
          }
        });
      } else {
        this.setState({
          saveButtonLoading: false
        });
      }
    }

    if (formType === 'MOD') {
      let irszam = [];
      formIranyitoSzam.forEach((item) => {
        irszam.push(item.value);
      });
      let submitObj = {
        telepulesid: currentId,
        orszagid: formOrszag.orszagid ? formOrszag.orszagid : formOrszag,
        telepulesnev: formTelepules,
        irszamok: irszam,
      };
      if (formOrszag && formTelepules && irszam.length !== 0) {
        Services.editTelepules(submitObj, (err, res) => {
          if (!err && submitObj.irszamok && submitObj.irszamok.length !== 0 && res[0].length === 0) {
            this.toggleModal();
            this.getTelepulesList();
            this.props.globals.addSuccess('Település sikeresen módosítva!');
            this.setState({
              saveButtonLoading: false
            });
          } else if (irszam.length === 0) {
            this.setState({
              saveButtonLoading: false,
            });
            this.props.globals.addError('Minimum egy irányítószám megadása kötelező!');
          } else {
            this.setState({
              saveButtonLoading: false,
            });
          }

          // if (err && err.status === 409 && err.responseJSON.hibaazon === 'TELEPULESMODIFY') {
          //   this.toggleForceModify();
          //   this.setState({ resultJSON: err });
          //   // this.setState({ forceModIrszamok:  })
          // }
          // if (err && res[0].length !== 0) {
          //   // this.props.globals.addError('A törölni kívánt irányítószám(ok): ' + this.listIrsz(res[0]) + ' már más adatokhoz hozzárendelésre került(ek), így a(z) ' + this.listIrsz(res[0]) + ' irányítószám(ok) törlése nem lehetséges.');
          //   this.setState(prevState => ({
          //     formIranyitoSzam: [...prevState.formIranyitoSzam, { label: this.listIrsz(res[0]), value: this.listIrsz(res[0]) }],
          //     saveButtonLoading: false
          //   }));
          // }
        });
      } else {
        this.setState({
          saveButtonLoading: false,
        });
      }
    }
  }

  editTelepules = (id) => {
    let submitObj = JSON.parse(JSON.stringify(this.state.submitObj));
    submitObj.id = id;
    Services.editTelepules(submitObj, (err) => {
      if (!err) {
        this.setState({ modalState: false }, () => {
          this.getTelepulesList();
          this.props.globals.addSuccess('Település sikeresen módosítva!');
        });
      }
    });
  }

  deleteTelepules = () => {
    this.setState({
      deleteButtonLoading: true
    });
    Services.deleteTelepules(this.state.torlesId, (err) => {
      if (!err) {
        this.setState({
          deleteButtonLoading: false
        });
        this.toggleTorles();
        this.getTelepulesList();
        this.props.globals.addSuccess('Település sikeresen törölve!');
      } else {
        this.setState({
          deleteButtonLoading: false
        });
      }
    });
  }


  formatCreateLabel = input => (
    <span>
      <i>Hozzáadás: </i>
      {input}
    </span>
  );

  tableIconFormatter = (cell) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'TELEPULES_TELEPULES', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => this.handleEditClick(cell)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'modositas'} delay={40}>
              {'Módosítás'}
            </UncontrolledTooltip>
            <Button className="icon-trash table-action-icon" id={'action-view-' + cell + 'torles'} onClick={() => this.handleDeleteClick(cell)} onMouseDown={(e) => { if (e) { e.preventDefault(); } }} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'torles'} delay={40}>
              {'Törlés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )
      }
      </div>
    );
  }

  validateSelect = (formIranyitoSzam) => {
    let element = document.getElementsByClassName('formIranyitoSzam');
    if (element) {
      for (let i = 0; i < element.length; i += 1) {
        if (element[i].tagName === 'label' && formIranyitoSzam.length !== 0) {
          element[i].style.text = 'red';
        }
        if (element[i].id === 'formIranyitoSzam' && formIranyitoSzam.length !== 0) element[i].style.borderColor = 'red';
      }
    }
  }

  renderFormTitle = () => {
    switch (this.state.formType) {
      case 'FEL':
        return 'Új település felvitele';
      case 'MOD':
        return 'Település módosítása';
      default:
        return '';
    }
  }

  renderForm = () => {
    const {
      formOrszag,
      formTelepules,
      formIranyitoSzam
    } = this.state;

    return (
      <React.Fragment>
        <AvGroup>
          <AvField
            type="select"
            name="formOrszag"
            id="formOrszag"
            label="Ország: *"
            required
            errorMessage="Mező kitöltése kötelező!"
            onChange={this.handleOrszagChange}
            value={formOrszag.orszagid ? formOrszag.orszagid : formOrszag}
          >
            {formOrszag !== '' ? '' : <option value="">Kérjük válasszon országot!</option>}
            {this.renderOrszagOptions()}
          </AvField>
        </AvGroup>
        <AvGroup>
          <Label for="formTelepules">Település: *</Label>
          <AvInput
            name="formTelepules"
            id="formTelepules"
            required
            onChange={this.handleInputChange}
            value={formTelepules}
          />
          <AvFeedback>Mező kitöltése kötelező!</AvFeedback>
        </AvGroup>
        <div className="row">
          <div className="col-md-12">
            <Label className="formIranyitoSzam" for="formIranyitoSzam" style={{ color: formIranyitoSzam.length !== 0 ? 'black' : 'red' }}>Irányítószám(ok): *</Label>
            <CreatableSelect
              styles={{
                control: (base, state) => ({
                  ...base,
                  borderColor: !state.hasValue ? 'red' : 'lightgray', // border style on hover
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              isFocused
              name="formIranyitoSzam"
              className="formIranyitoSzam"
              id="formIranyitoSzam"
              placeholder={formIranyitoSzam ? 'Kezdjen el gépelni...' : <span className="text-danger">Kötelező mező!</span>}
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              formatCreateLabel={this.formatCreateLabel}
              options={this.state.irszOptions}
              isMulti
              required
              getOptionLabel={option => option.label}
              getOptionValue={option => option.value}
              value={formIranyitoSzam}
              onChange={v => this.setState({ formIranyitoSzam: v })}
              onCreateOption={this.handleCreateIrsz}
              cache={false}
              autoload={false}
            />
          </div>
        </div>
        <span hidden={formIranyitoSzam.length !== 0} className="text-danger">Kötelező mező!</span>
      </React.Fragment>
    );
  }

  handleCreateIrsz = (inputValue) => {
    let irsz = this.state.formIranyitoSzam;

    if (inputValue.length <= 20) {
      let newIrsz = {};
      newIrsz.label = inputValue;
      newIrsz.value = inputValue;

      irsz.push(newIrsz);
      this.setState({ formIranyitoSzam: irsz });
    } else {
      this.props.globals.addError('Az irányítószám legfeljebb 20 karakterből állhat!');
    }
  };

  renderTable = () => {
    const options = {
      sizePerPage: 10,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const columns = [
      {
        dataField: 'orszag',
        text: 'Ország',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'telepules',
        text: 'Település neve',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'irszamok',
        text: 'Irányítószám(ok)',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
        },
        // formatter: (cell) => {
        // return ' ' + cell;
        // },
        sort: true
      },
      {
        dataField: 'telepulesid',
        text: 'Műveletek',
        hidden: !Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'TELEPULES_TELEPULES', 'KARB'),
        formatter: this.tableIconFormatter
      }
    ];

    return (
      <div className="animated fadeIn">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={this.state.baseJson}
          columns={columns}
          filter={filterFactory()}
          pagination={paginationFactory(options)}
          noDataIndication="Nincs megjelenítendő adat!"
        />
      </div>
    );
  }

  render() {
    // const { forceModIrszamok, resultJSON } = this.state;
    this.validateSelect(this.state.formIranyitoSzam);
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'TELEPULES_TELEPULES', 'KARB') && (
              <Button className="pull-left table-new-button" color="primary" onClick={this.handleNewClick}>
                {'Új település'}
              </Button>
            )}
          </div>
          <div className="col-md-12">
            {{
              LOAD: (<Loader />),
              DATA: (<DataEmptyState
                hasImage
                subtitle="Vigyél fel új tételt!"
              />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.getTelepulesList()}
              />),
              TABL: (this.renderTable())
            }[this.state.tableStatus]}
          </div>
        </div>
        <Modal isOpen={this.state.modalState} toggle={this.toggleModal} backdrop="static">
          <AvForm onValidSubmit={this.handleSubmit}>
            <ModalHeader>{this.renderFormTitle()}</ModalHeader>
            <ModalBody>
              {this.renderForm()}
            </ModalBody>
            <ModalFooter>
              <ProgressButton
                className="btn-cursor-pointer"
                color="primary"
                loading={this.state.saveButtonLoading}
              >
                {'Mentés'}
              </ProgressButton>
              <Button type="button" color="secondary" className="btn-cursor-pointer" onClick={() => { this.toggleModal(); this.getTelepulesList(); }}>Mégsem</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
        {/* <Modal isOpen={this.state.modalForceModify} toggle={this.toggleForceModify} backdrop="static">
          <ModalHeader>Figyelmeztetés</ModalHeader>
          <ModalBody>
            A törölni kívánt irányítószám(ok)
            {' '}
            <strong>
              {this.listIrsz(resultJSON)}
            </strong>
            {' '}
            már más adatokhoz hozzárendelésre került(ek), így a(z)
            <strong>
              {this.listIrsz(resultJSON)}
            </strong>
            {' '}
            irányítószám(ok) nem törölhetőek.
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="secondary" onClick={() => { this.toggleForceModify(); this.setState(prevState => ({ saveButtonLoading: false, formIranyitoSzam: [...prevState.formIranyitoSzam, forceModIrszamok] })); }}>OK</Button>
          </ModalFooter>
        </Modal> */}
        <Modal isOpen={this.state.modalTorles} toggle={this.toggleTorles} backdrop="static">
          <ModalHeader>Törlés</ModalHeader>
          <ModalBody>Valóban törölni kívánja a kiválasztott tételt?</ModalBody>
          <ModalFooter>
            <ProgressButton loading={this.state.deleteButtonLoading} color="primary" onClick={() => this.deleteTelepules()}>Igen</ProgressButton>
            <Button type="button" color="secondary" onClick={this.toggleTorles}>Mégsem</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

TelepulesContent.propTypes = {
  active: PropTypes.bool,
  globals: PropTypes.object
};

export default TelepulesContent;
