import React, { useEffect, useState } from 'react';
import {
  Button, Col, Label, Row
} from 'reactstrap';
import { AvForm, AvGroup, AvField } from 'availity-reactstrap-validation';
import AvFeedback from 'availity-reactstrap-validation/lib/AvFeedback';
import Services from '../services';

const BeallitasContent = () => {
  const [ervenyesseg, setErvenyesseg] = useState();
  const [szallitasi, setSzallitasi] = useState();
  const [fizetesi, setFizetesi] = useState();
  const [editMode, setEditMode] = useState(false);

  const validatorObject = {
    pattern: {
      value: '^[0-9]+$',
      errorMessage: 'Csak egész szám írható be!'
    },
    required: {
      value: true,
      errorMessage: 'Kötelező mező!'
    }
  };

  const listBeallitas = async () => {
    try {
      const json = await Services.listBeallitas();
      setErvenyesseg(json.ervenyesseg);
      setSzallitasi(json.szallitasi);
      setFizetesi(json.fizetesi);
    } catch (ex) {}
  };

  useEffect(() => {
    listBeallitas();
  }, []);

  const handleOkClick = async () => {
    if (editMode) {
      try {
        await Services.editBeallitas({ ervenyesseg, szallitasi, fizetesi });
        listBeallitas();
        setEditMode(false);
      } catch (ex) {}
    } else {
      setEditMode(true);
    }
  };

  const handleCancelClick = () => {
    setEditMode(false);
    listBeallitas();
  };

  return (
    <>
      <AvForm className="no-gutters" onValidSubmit={handleOkClick}>
        <Row style={{ paddingRight: 10 }} className="no-gutters">
          <Col md="3" className="mr-1">
            <Label>Érvényességi idő (nap): </Label>
            <AvField name="e" type="text" value={ervenyesseg} onChange={e => setErvenyesseg(e.target.value)} validate={validatorObject} disabled={!editMode} />
          </Col>
          <Col md="3" className="mr-1">
            <Label>Szállítási határidő (nap): </Label>
            <AvField name="sz" type="text" value={szallitasi} onChange={e => setSzallitasi(e.target.value)} validate={validatorObject} disabled={!editMode} />
          </Col>
          <Col md="3" className="mr-1">
            <Label>Fizetési határidő (nap): </Label>
            <AvField name="f" type="text" value={fizetesi} onChange={e => setFizetesi(e.target.value)} validate={validatorObject} disabled={!editMode} />
          </Col>
        </Row>
        <Row>
          <Button type="submit" color="success" className="ml-1 mr-1">{editMode ? 'Mentés' : 'Módosítás'}</Button>
          { editMode && <Button type="button" onClick={handleCancelClick}>Mégse</Button>}
        </Row>
      </AvForm>
    </>
  );
};

export default BeallitasContent;
