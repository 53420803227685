const tooltips = {
  karbantartas: 'A Karbantartás képernyőkön elérhető funkciók segítségével különböző gépek, berendezések, eszközök, járművek karbantartási szerződésének adatai, a szerződéshez kapcsolódó feladatok (ellenőrzési szempontok), és a feladatok elvégzésének gyakorisága (alkalmak) kezelhető. A rögzítést az ellenőrzési szempontok meghatározásával, majd azok ellenőrzési listához rendelésével szükséges kezdeni. A felhasználó által összeállított ellenőrzési listákat lehet majd a karbantartásban szerződés tételeihez kapcsolni.',
  karbantartasok: 'Ez a felület lesz a karbantartási szerződéshez kapcsolódó adatok nyilvántartása. Új karbantartás rögzítésekor a felhasználó a karbantartási szerződés/partnerkapcsolat fő adatait adhatja meg: ügyfél/partner neve, szerződés azonosító szám, érvényesség kezdete és vége. Az adatok rögzítése után azt kijelölve nyílik meg a tételek rögzítésének lehetősége.',
  karbantartasitetel: 'Ide rögzíthetők az eszközök (cikkek, vagyis a karbantartás tárgya), eszköz azonosítók (gyári szám), a munkavégzés helye, az eszközhöz kapcsolódó karbantartási szolgáltatás érvényességi ideje. Egy karbantartáshoz kapcsolódóan adott partnerre vonatkozóan akár annak összes eszköze, karbantartási feladata rögzíthető a tételek közé.\nA karbantartási tételek rögzítése után lehet a tételekhez kapcsolni, korábban kialakított ellenőrzési listákat és azok gyakoriságát a Műveletekben található Ütemezés gomb által indítható felületen. Egy tételhez egyszerre több ellenőrzési lista is hozzákapcsolható azonos, de akár különböző periódussal is. Ezek az összerendelések és a szolgáltatás érvényességi időszaka határozza meg az egyes ellenőrzési szempontokra, hogy mikor kell azokat elvégezni.\nAz így meghatározott alkalmak kérdezhetők le a Karbantartási alkalmak között a lekérdezés napjától számított egy évre előre.',
  karbantartasalkalmak: 'A felületen kérdezhetők le a Karbantartásban rögzített adatok alapján előírt feladatok és azok adatai időrendben növekvő rendezettséggel a lekérdezés napjától számított egy évre előre.\nAz alkalmak a listából nyomtathatóak és törölhetők is a műveletekben található ikonok segítségével, amennyiben azokra a rendszer más részein még nem létezik hivatkozás.\nEgyes alkalmak kijelölésével lekérdezhetővé válnak az alkalom során elvégezendő ellenőrzési szempontok, feladatok a felhasználó számára. Mivel egy karbantartási tételhez több ellenőrzési lista hozzákapcsolható ezért az alkalmak is listánként külön – külön kerülnek meghatározásra. Amennyiben egy napra több lista alapján is képződne alkalom, az a lista kap prioritást, amelynek periódusa a leghosszabb (pl. ha a havi lista alapján és a féléves lista alapján is képződik alkalom, akkor a féléves periódusú ellenőrzési lista kerül hozzárendelésre az alkalomhoz). Így a nagyobb periódusú listáknak célszerű tartalmaznia azokat az ellenőrzési szempontokat is, amelyeket a kisebb periódusú lista tartalmaz.',
  ellenorzesiszempontok: 'Itt rögzíthető valamennyi szempont és feladat, melyet a felhasználónak a karbantartási modulban nyilvántartott szerződéseivel, eszközeivel kapcsolatosan el kell végeznie.',
  ellenorzesilistak: 'Ezen a felületen rendelhetők különböző csoportokba, listákhoz a rögzített ellenőrzési szempontok. A csoportosítás alapja lehet a gyakoriság – pl. félévente elvégezendő feladatok – vagy a karbantartási szerződés tárgya, vagy akár mindkettő is. Egy ellenőrzési szempont több listához is hozzákapcsolható.',
  tetelFeladatGeneralas: 'Az előzőleg generált feladatok az érték módosulásával nem kerülnek törlésre.',
  tetelPublikusLeiras: 'Ügyfél által is elérhető információ.',
  utemezesForm: 'A karbantartási alkalmak ütemezése maximum egy éves időtávra történik, a további alkalmak automatikusan megképzésre kerülnek.',
};

export default function getTooltip(name) {
  return tooltips[name];
}
