import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Button, Col, Label, Modal, Row, UncontrolledTooltip
} from 'reactstrap';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import BootstrapTable from 'react-bootstrap-table-next';
import SelectAsync from 'react-select/lib/Async';
import DropZone from 'react-dropzone';
import fileSaver from 'file-saver';
import { AvField, AvForm, AvInput } from 'availity-reactstrap-validation';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import moment from 'moment';
import { serializer } from '@organw/wysiwyg-editor';
import Services from './services';
import Loader from '../../commons/loader';
import DataEmptyState from '../../commons/dataemptystate';
import NetworkEmptyState from '../../commons/networkemptystate';
import { defaultValue } from '../../commons/EditorFunctions';
import EditorEmail from './EditorEmail';
import { FileCard } from '../../commons/controls';
import Lib from '../../commons/lib';

const EmailContent = (props) => {
  const { globals } = props;

  const EDITOR_INIT = defaultValue();

  const [tableStatus, setTableStatus] = useState('TABL'); // ['LOAD', 'DATA', 'NETW', 'TABL']
  const [emailSablonJSON, setEmailSablonList] = useState([]);
  const [emailSablonModal, setEmailSablonModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const [edit, setEdit] = useState(false);
  const [sablonId, setSablonId] = useState(null);
  const [cegadat, setCegadat] = useState(null);
  const [tipusOptions, setTipusOptions] = useState([]);
  const [tipus, setTipus] = useState(null);
  const [logo, setLogo] = useState(null);
  const [subject, setSubject] = useState(null);
  const [text, setText] = useState(EDITOR_INIT);

  const selectStyles = {
    control: base => ({
      ...base,
      '&:focus': { borderColor: 'red' }, // border style on hover
      border: '1px solid lightgray', // default border color
      boxShadow: 'none', // no box-shadow
      borderRadius: '0'
    }),
  };

  const getEmailSablonList = async () => {
    setTableStatus('LOAD');
    try {
      const json = await Services.listEmailSablon();
      if (!json.length) {
        setTableStatus('DATA');
      } else {
        setEmailSablonList(json);
        setTableStatus('TABL');
      }
    } catch (ex) {
      setTableStatus('NETW');
    }
  };

  const getEmailSablonTipusList = async () => {
    const json = await Services.listEmailSablonTipus();
    let array = [];
    json.forEach((sablontipus) => {
      array.push(<option value={sablontipus.name}>{sablontipus.caption}</option>);
    });
    setTipusOptions(array);
  };

  useEffect(() => {
    getEmailSablonList();
    getEmailSablonTipusList();
  }, []);

  const handleEditClick = async (id) => {
    try {
      const res = await Services.getEmailSablon({ id });
      setEdit(true);
      setSablonId(res.id);
      setCegadat(res.cegadat);
      setTipus(res.tipus && res.tipus.name);
      setLogo(res.object);
      setSubject(res.targy);
      setText(serializer.deserialize(res.szoveg));
      setEmailSablonModal(true);
    } catch (ex) { }
  };

  const handleDeleteClick = (id) => {
    setSablonId(id);
    setDeleteModal(true);
  };

  const deleteClick = async () => {
    try {
      await Services.deleteEmailSablon({ id: sablonId });
      getEmailSablonList();
      setDeleteModal(false);
    } catch (ex) { }
  };

  const handleNewSablonClick = () => {
    setEdit(false);
    setSablonId(null);
    setCegadat(null);
    setTipus(null);
    setLogo(null);
    setSubject(null);
    setText(EDITOR_INIT);
    setEmailSablonModal(true);
  };

  const saveEmailSablon = async () => {
    let submitObjParam;
    if (logo) {
      submitObjParam = JSON.parse(JSON.stringify(logo));
      if (submitObjParam.new) {
        delete submitObjParam.id;
      }
    }
    try {
      const obj = {
        id: sablonId,
        cegadat: cegadat,
        tipus: tipus,
        object: submitObjParam,
        targy: subject,
        szoveg: serializer.serialize(text)
      };
      if (!edit) await Services.addEmailSablon(obj);
      else await Services.editEmailSablon(obj);
      setEmailSablonModal(false);
      getEmailSablonList();
      globals.addSuccess('Email sablon rögzítve!');
    } catch (ex) { }
  };

  const loadCegadatOptions = (input, callback) => {
    if (_.isEmpty(input)) callback([]);
    Services.listCegadat({}, (err, res) => {
      if (!err) {
        const optionsCegadat = res[0].filter(k => k.nev.toLowerCase().includes(input.toLowerCase()));
        if (optionsCegadat.length == 1) setCegadat(optionsCegadat[0]);
        callback(optionsCegadat);
      } else {
        callback([]);
      }
    });
  };

  const onDropObject = (acceptedfiles) => {
    acceptedfiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        setLogo({
          content: btoa(fileAsBinaryString),
          uploadDate: new Date(),
          docname: file.name,
          preview: file.preview,
          logo: true,
          mime: file.type || 'application/octet-stream',
          new: true,
          length: file.size,
          id: Lib.Browser.uuidv4()
        });
      };
      reader.readAsBinaryString(file);
    });
  };

  const getContent = async () => {
    try {
      const res = await Services.downloadObject({ id: logo.id });
      fileSaver.saveAs(new Blob([res], { type: logo.mime }), logo.docname);
    } catch (ex) { }
  };

  const downloadObject = (e) => {
    e.preventDefault();
    if (logo.preview) {
      window.open(logo.preview, '_blank');
    } else {
      getContent();
    }
  };

  const removeObject = () => {
    setLogo(null);
  };

  const formatBytes = (bytes) => {
    if (bytes < 1024) return bytes + ' bájt';
    if (bytes < 1048576) return (bytes / 1024).toFixed(3) + ' KB';
    if (bytes < 1073741824) return (bytes / 1048576).toFixed(3) + ' MB';
    return (bytes / 1073741824).toFixed(3) + ' GB';
  };

  const tableIconFormatter = (cell, row) => {
    return (
      <>
        <React.Fragment>
          <Button
            className="icon-pencil table-action-icon"
            id={'action-view-' + cell + 'modositas'}
            onClick={() => handleEditClick(cell)}
            type="button"
          />
          <UncontrolledTooltip
            style={{ float: 'left' }}
            placement="top"
            target={'action-view-' + cell + 'modositas'}
            delay={50}
          >
            {'Módosítás'}
          </UncontrolledTooltip>
          <Button
            className="icon-trash table-action-icon"
            id={'action-view-' + cell + 'file'}
            onClick={() => handleDeleteClick(cell)}
            type="button"
          />
          <UncontrolledTooltip
            style={{ float: 'left' }}
            placement="top"
            target={'action-view-' + cell + 'file'}
            delay={50}
          >
            {'Törlés'}
          </UncontrolledTooltip>
        </React.Fragment>
      </>
    );
  };

  const renderEmailSablonTable = () => {
    const columns = [
      {
        dataField: 'tipus',
        text: 'Típus',
        filter: textFilter({
          delay: 400,
          placeholder: 'Keresés...',
        }),
        formatter: cell => cell && cell.caption,
        filterValue: cell => cell && cell.caption,
        style: {
          whiteSpace: 'pre-line',
        },
        sort: true,
      },
      {
        dataField: 'cegadat',
        text: 'Cégadat',
        filter: textFilter({
          delay: 400,
          placeholder: 'Keresés...',
        }),
        formatter: cell => cell && cell.teljesnev,
        filterValue: cell => cell && cell.teljesnev,
        style: {
          whiteSpace: 'pre-line',
        },
        sort: true,
      },
      {
        dataField: 'modidopont',
        text: 'Módosítás időpontja',
        style: {
          whiteSpace: 'pre-line',
        },
        sort: true,
        formatter: cell => cell && moment(cell).format('YYYY-MM-DD HH:mm'),
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        hidden: !Lib.KeyCloak.hasHigherRole(globals.keycloak, 'RENDELES_EMAILSABLON', 'KARB'),
        formatter: tableIconFormatter,
      },
    ];
    return (
      <BootstrapTable bootstrap4 keyField="id" data={emailSablonJSON} columns={columns} filter={filterFactory()} />
    );
  };

  const renderModals = () => {
    return (
      <>
        <Modal
          isOpen={emailSablonModal}
          toggle={() => setEmailSablonModal(!emailSablonModal)}
          backdrop="static"
          size="lg"
        >
          <AvForm
            onValidSubmit={saveEmailSablon}
          >
            <ModalHeader>
          Email sablon
              {' '}
              {sablonId ? 'módosítása' : 'felvitele'}
            </ModalHeader>
            <ModalBody>

              <Row>
                <Col md="6">
                  <Label>Cégadat:</Label>
                  <SelectAsync
                    styles={selectStyles}
                    name="cegadat"
                    id="cegadat"
                    placeholder="Kezdjen el gépelni a kereséshez..."
                    loadingMessage={() => 'Keresés...'}
                    noOptionsMessage={() => 'Nincs keresési eredmény'}
                    onChange={v => setCegadat(v || {})}
                    loadOptions={_.debounce(loadCegadatOptions, 1000)}
                    value={cegadat || null}
                    getOptionLabel={option => option.nev || option.teljesnev}
                    getOptionValue={option => option.id}
                    isDisabled={false}
                    isClearable
                    required
                  />
                </Col>
                <Col md="6">
                  <Label>Típus:</Label>
                  <AvField id="tipus" name="tipus" type="select" value={tipus} onChange={(e, v) => setTipus(v)}>
                    <option value="" disabled>Válassz típust...</option>
                    {tipusOptions}
                  </AvField>
                </Col>
                <Col md="6">
                  <h5 style={{ marginBottom: 20, marginTop: 10 }}>Logó feltöltése:</h5>
                </Col>
              </Row>
              <Row>
                {!logo && (
                <Col md="12">
                  <DropZone
                    onDrop={onDropObject}
                    className="custom-dropzone"
                  >
                    <div className="dropzone-text">
                      {'Húzd ide a logót vagy kattints a négyzetbe a feltöltéshez!'}
                    </div>
                  </DropZone>
                </Col>
                )}
                <Col md="12">
                  {logo && (
                  <div className="col-md-4" key="logo">
                    <FileCard
                      key="logo"
                      mime={logo.mime}
                      title={logo.docname}
                      length={formatBytes(logo.length)}
                      activeLogo={logo.logo}
                      onDownloadClick={downloadObject}
                      onDeleteClick={removeObject}
                    />
                  </div>
                  )}
                </Col>
                <Col md="12" className="mb-1">
                  <Label for="subject">Tárgy: </Label>
                  <AvInput
                    name="subject"
                    id="subject"
                    value={subject}
                    onChange={(e, v) => setSubject(v)}
                  />
                </Col>
                <Col md="12">
                  <EditorEmail
                    text={text}
                    setText={setText}
                  />
                </Col>
              </Row>

            </ModalBody>
            <ModalFooter>
              <Button type="submit" color="success">Mentés</Button>
              <Button type="button" onClick={() => setEmailSablonModal(false)}>Mégsem</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
        <Modal
          isOpen={deleteModal}
          toggle={() => {
            setDeleteModal(false);
          }}
          backdrop="static"
        >
          <ModalHeader>Sablon törlése</ModalHeader>
          <ModalBody>Biztosan törölni szeretné a sablont?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={deleteClick}>Igen</Button>
            <Button onClick={() => {
              setDeleteModal(false);
            }}
            >
          Mégse
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  };

  return (
    <div className="row">
      <div className="col-md-12">
        {Lib.KeyCloak.hasHigherRole(globals.keycloak, 'RENDELES_EMAILSABLON', 'KARB') && (
          <div className="mb-1">
            <Button color="primary" onClick={handleNewSablonClick}>Új sablon</Button>
          </div>
        )}
        {
          {
            LOAD: <Loader />,
            DATA: <DataEmptyState hasImage subtitle="Vigyél fel új tételt!" />,
            NETW: (
              <NetworkEmptyState
                hasImage
                submitClick={getEmailSablonList}
              />
            ),
            TABL: renderEmailSablonTable(),
          }[tableStatus]
        }
        {renderModals()}
      </div>
    </div>
  );
};

export default EmailContent;
