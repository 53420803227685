import React, { Component } from 'react';
import { Label } from 'reactstrap';
import _ from 'lodash';
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
} from 'availity-reactstrap-validation';
import fileSaver from 'file-saver';
import moment from 'moment';
import Select from 'react-select/lib/Async';
import Lib from '../../../commons/lib';
import Services from './services';
import NetworkEmptyState from '../../../commons/networkemptystate';
import Loader from '../../../commons/loader';
import { ProgressButton } from '../../../commons/ProgressButton';
import NumberFormatter from '../../../commons/NumberFormatter';

class KompenzacioListaContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      listButtonLoading: false,
      // "": UNSELECTED
      // "OSSZEVONT": Összevont lista
      // "RESZLETES": Részletes lista
      listType: '',
      aktivKompTipus: [],
      aktivFoglalasAllapot: [],
      aktivRCIhetek: [],
      aktivEvek: [],
      aktivSzallodak: [],
      // FORM STATUS
      // [LOAD', NETW', 'LIST']
      formStatus: 'LOAD',
      szamlaviseloValue: '',
      jogtulajdonosValue: '',
      csoportositValue: 'false',
      igenybevetelValue: 'false',
    };
  }

  componentDidMount() {
    this.getData();
    // this.genWeeks();
  }

  setButtonStat = (obj) => {
    this.setState(obj);
  }

  handleChangeSzamlaviselo = (value) => {
    if (value && value.length !== 0) {
      this.setState({ szamlaviseloValue: value });
    } else {
      this.setState({ szamlaviseloValue: '' });
    }
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  handleChangeJogtulaj = (value) => {
    if (value && value.length !== 0) {
      this.setState({ jogtulajdonosValue: value });
    } else {
      this.setState({ jogtulajdonosValue: '' });
    }
  }

  loadSzamlaviseloChange = (input, callback) => {
    if (input.length !== 0) {
      Services.getSzamlaviselo(input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            let newItem = item;
            newItem.nev += ' (' + newItem.cim + ') ';
            if (newItem.adoszam) {
              newItem.nev += '[' + NumberFormatter.addHyphensToString(newItem.adoszam) + '] ';
            }
            newArray.push(newItem);
          });
          callback(newArray);
        }
      });
    }
  }

  loadJogtulajChange = (input, callback) => {
    if (input.length !== 0) {
      Services.getJogtulaj(input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            let newItem = item;
            newItem.nev += ' (' + newItem.cim + ') ';
            if (newItem.adoszam) {
              newItem.nev += '[' + NumberFormatter.addHyphensToString(newItem.adoszam) + '] ';
            }
            newArray.push(newItem);
          });
          callback(newArray);
        }
      });
    }
  }

  onSubmit = (event, submitObj) => {
    if (submitObj.listatipus === 'OSSZEVONT') {
      let osszevontObj = {
        tipus: submitObj.tipus === '' ? null : submitObj.tipus,
        szamlakibocsato: this.state.szamlaviseloValue && this.state.szamlaviseloValue.id === '' ? null : this.state.szamlaviseloValue.id,
        fojogtulaj: this.state.jogtulajdonosValue && this.state.jogtulajdonosValue.id === '' ? null : this.state.jogtulajdonosValue.id,
      };
      this.setState({
        listButtonLoading: true
      });
      Services.exportOsszevontXlsx(osszevontObj, (err, res) => {
        if (!err) {
          this.setState({
            listButtonLoading: false
          });
          this.props.globals.addSuccess('Xlsx fájl letöltés indítása...');
          fileSaver.saveAs(new Blob([res[0]], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', responseType: 'arraybuffer' }), 'osszevont_lista_' + moment().format('YYYY-MM-DD') + '.xlsx');
        } else {
          this.setState({
            listButtonLoading: false
          });
        }
      });
    }
    if (submitObj.listatipus === 'RESZLETES' || submitObj.listatipus === 'FOGLALAS') {
      this.setState({
        listButtonLoading: true
      });

      let exportObj = {
        foglalasAllapot: submitObj.foglalasAllapot === '' ? null : submitObj.foglalasAllapot,
        szamlakibocsato: this.state.szamlaviseloValue && this.state.szamlaviseloValue.id === '' ? null : this.state.szamlaviseloValue.id,
        fojogtulaj: this.state.jogtulajdonosValue && this.state.jogtulajdonosValue.id === '' ? null : this.state.jogtulajdonosValue.id,
        rciHet: submitObj.rciHet === '' ? null : submitObj.rciHet,
        foglalasEve: submitObj.foglalasEve === '' ? null : submitObj.foglalasEve,
        szalloda: submitObj.szalloda === '' ? null : submitObj.szalloda,
        ugyszam: submitObj.ugyszam === '' ? null : submitObj.ugyszam
      };
      if (submitObj.listatipus === 'FOGLALAS') {
        exportObj.tipus = submitObj.tipus === '' ? null : submitObj.tipus;
      }

      if (submitObj.listatipus === 'RESZLETES') {
        Services.exportReszletesXlsx(exportObj, (err, res) => {
          if (!err) {
            this.setState({
              listButtonLoading: false
            });
            this.props.globals.addSuccess('Xlsx fájl letöltés indítása...');
            fileSaver.saveAs(new Blob([res[0]], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', responseType: 'arraybuffer' }), 'reszletes_lista_' + moment().format('YYYY-MM-DD') + '.xlsx');
          } else {
            this.setState({
              listButtonLoading: false
            });
          }
        });
      } else {
        exportObj.ugyszam = submitObj.ugyszam === '' ? null : submitObj.ugyszam;
        exportObj.szallodaCsoportositas = this.state.csoportositValue;
        if (this.state.csoportositValue) {
          exportObj.igenybevetel = this.state.igenybevetelValue;
        } else {
          exportObj.igenybevetel = false;
        }

        Services.exportFoglalasXlsx(exportObj, (err, res) => {
          if (!err) {
            this.setState({
              listButtonLoading: false
            });
            this.props.globals.addSuccess('Xlsx fájl letöltés indítása...');
            fileSaver.saveAs(new Blob([res[0]], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', responseType: 'arraybuffer' }), 'foglalasok_lista_' + moment().format('YYYY-MM-DD') + '.xlsx');
          } else {
            this.setState({
              listButtonLoading: false
            });
          }
        });
      }
    }
  }

  getEnums = () => {
    return new Promise((resolve, reject) => {
      Services.getKompenzacioEnum((err, res) => {
        if (!err) {
          let resOptions = res[0][0].map(e => (
            <option key={e.name} value={e.name}>
              {e.caption}
            </option>
          ));
          let resOptions2 = res[0][1].map(e => (
            <option key={e.name} value={e.name}>
              {e.caption}
            </option>
          ));
          this.setState({
            aktivKompTipus: resOptions,
            aktivFoglalasAllapot: resOptions2
          });
          resolve(resOptions);
        } else {
          reject(new Error('Hálozati hiba!'));
        }
      });
    });
  }

  getElo = () => {
    return new Promise((resolve, reject) => {
      Services.getEloszuresValues((err, res) => {
        if (!err) {
          let resOptions = res[0].ev.map(e => (
            <option key={e} value={e}>
              {e}
            </option>
          ));
          let resOptions2 = res[0].het.map(e => (
            <option key={e} value={e}>
              {e + '. hét'}
            </option>
          ));
          this.setState({
            aktivRCIhetek: resOptions2,
            aktivEvek: resOptions,
          });
          resolve(resOptions);
        } else {
          reject(new Error('Hálozati hiba!'));
        }
      });
    });
  }

  getSzalloda = () => {
    return new Promise((resolve, reject) => {
      Services.getSzallodaValues((err, res) => {
        if (!err) {
          let resOptions = res[0].map(e => (
            <option key={e.id} value={e.id}>
              {e.szallodanev}
            </option>
          ));
          this.setState({
            aktivSzallodak: resOptions,
          });
          resolve(resOptions);
        } else {
          reject(new Error('Hálozati hiba!'));
        }
      });
    });
  }

  getData = () => {
    this.setState({ formStatus: 'LOAD' });
    Promise.all([
      this.getEnums(),
      this.getElo(),
      this.getSzalloda()
    ])
      .then(() => {
        this.setState({
          formStatus: 'LIST'
        });
      })
      .catch(() => {
        this.setState({
          formStatus: 'NETW'
        });
      });
  }

  listaTipusChange = (e) => {
    this.setState({
      listType: e.target.value,
    });
  }

  genWeeks = () => {
    let weeks = [];
    for (let i = 1; i < 53; i += 1) {
      weeks.push(
        <option
          key={i.toString()}
          value={i}
        >
          {i.toString() + '. hét'}
        </option>
      );
    }
    this.setState({ aktivRCIhetek: weeks });
  }

  renderLogic = () => {
    return (
      <React.Fragment>
        <div className="col-md-4">
          <AvField
            type="select"
            name="listatipus"
            label="Lista típusa: *"
            errorMessage="Kötelező mező!"
            onChange={this.listaTipusChange}
            value={this.state.listType}
            required
          >
            <option value="">
              {'Válassz típust...'}
            </option>
            <option value="OSSZEVONT">
              {'Összevont lista'}
            </option>
            <option value="RESZLETES">
              {'Részletes lista'}
            </option>
            {Lib.KeyCloak.hasRoleSpec(this.props.globals.keycloak, ['UDULESIJOG_LISTA_FOGLALAS']) && (
              <option value="FOGLALAS">
                {'Foglalások'}
              </option>
            )}
          </AvField>
        </div>
        <div className="col-md-12" />
        {(this.state.listType === 'FOGLALAS') && Lib.KeyCloak.hasRoleSpec(this.props.globals.keycloak, ['UDULESIJOG_LISTA_FOGLALAS']) && this.renderFoglalasList()}
        {(this.state.listType === 'OSSZEVONT' || this.state.listType === 'RESZLETES' || this.state.listType === 'FOGLALAS') && this.renderBaseList()}
        {(this.state.listType === 'RESZLETES' || this.state.listType === 'FOGLALAS') && this.renderReszletesList()}
        <div className="col-md-12" />
        <div className="col-md-4">
          <ProgressButton loading={this.state.listButtonLoading} color="primary">
            {'Lista futtatása'}
          </ProgressButton>
          {' '}
        </div>
      </React.Fragment>
    );
  }

  renderBaseList = () => {
    return (
      <React.Fragment>
        <div className="col-md-4" style={{ marginBottom: 16 }}>
          <Label for="szamlakibocsato">
            {'Kompenzációs számlaviselő:'}
          </Label>
          <Select
            styles={{
              control: base => ({
                ...base,
                '&:focus': { borderColor: 'red' }, // border style on hover
                border: '1px solid lightgray', // default border color
                boxShadow: 'none', // no box-shadow
                borderRadius: '0'
              }),
            }}
            name="szamlakibocsato"
            id="szamlakibocsato"
            placeholder="Kezdjen el gépelni..."
            noOptionsMessage={() => 'Nincs keresési eredmény'}
            searchPromptText="Kezdjen el gépelni a kereséshez..."
            loadingMessage={() => 'Keresés...'}
            onChange={v => this.handleChangeSzamlaviselo(v)}
            isClearable
            ignoreAccents={false}
            loadOptions={_.debounce(this.loadSzamlaviseloChange, 1000)}
            isLoading={false}
            getOptionLabel={option => option.nev}
            getOptionValue={option => option.id}
            value={this.state.szamlaviseloValue}
            cache={false}
            autoload={false}
            isDisabled={this.state.ugyszam && this.state.listType !== 'OSSZEVONT'}
          />
        </div>
        <div className="col-md-4" style={{ marginBottom: 16 }}>
          <Label for="jogtulaj">
            {'Jogtulajdonos:'}
          </Label>
          <Select
            styles={{
              control: base => ({
                ...base,
                '&:focus': { borderColor: 'red' }, // border style on hover
                border: '1px solid lightgray', // default border color
                boxShadow: 'none', // no box-shadow
                borderRadius: '0'
              }),
            }}
            name="jogtulaj"
            id="jogtulaj"
            placeholder="Kezdjen el gépelni..."
            noOptionsMessage={() => 'Nincs keresési eredmény'}
            searchPromptText="Kezdjen el gépelni a kereséshez..."
            loadingMessage={() => 'Keresés...'}
            onChange={v => this.handleChangeJogtulaj(v)}
            isClearable
            ignoreAccents={false}
            loadOptions={_.debounce(this.loadJogtulajChange, 1000)}
            isLoading={false}
            getOptionLabel={option => option.nev}
            getOptionValue={option => option.id}
            value={this.state.jogtulajdonosValue}
            cache={false}
            autoload={false}
            isDisabled={this.state.ugyszam && this.state.listType !== 'OSSZEVONT'}
          />
        </div>
        <div className="col-md-4" />
        {this.state.listType !== 'RESZLETES' && (
          <div className="col-md-4">
            <AvField
              type="select"
              name="tipus"
              label="Kompenzáció típusa:"
              errorMessage="Kötelező mező!"
              value={this.state.tipus}
              onChange={this.handleInputChange}
              disabled={this.state.ugyszam && this.state.listType !== 'OSSZEVONT'}
            >
              <option value="">
                {'Válassz típust...'}
              </option>
              {this.state.aktivKompTipus}
            </AvField>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderFoglalasList = () => {
    return (
      <div className="col-md-12 row">
        <div className="col-md-4">
          <AvField
            type="select"
            name="csoportositValue"
            label="Szállodára csoportosít?"
            errorMessage="Kötelező mező!"
            value={this.state.csoportositValue}
            onChange={(e) => {
              this.handleInputChange(e);
              if (e.target.value === 'false') {
                this.setState({ igenybevetelValue: 'false' });
              }
            }}
            disabled={this.state.ugyszam}
          >
            <option value="true">
              {'Igen'}
            </option>
            <option selected value="false">
              {'Nem'}
            </option>
          </AvField>
        </div>
        <div className="col-md-4">
          <AvField
            type="select"
            name="igenybevetelValue"
            label="Igénybevétel megjelenítése?"
            errorMessage="Kötelező mező!"
            onChange={this.handleInputChange}
            value={this.state.igenybevetelValue}
            disabled={this.state.csoportositValue === 'false' || this.state.ugyszam}
          >
            <option value="true">
              {'Igen'}
            </option>
            <option selected value="false">
              {'Nem'}
            </option>
          </AvField>
        </div>
      </div>
    );
  }

  renderReszletesList = () => {
    return (
      <React.Fragment>
        <div className="col-md-4">
          <AvField
            type="select"
            name="foglalasAllapot"
            label="Foglalás állapota:"
            errorMessage="Kötelező mező!"
            value={this.state.foglalasAllapot}
            onChange={this.handleInputChange}
            disabled={this.state.ugyszam}
          >
            <option value="">
              {'Válassz típust...'}
            </option>
            {this.state.aktivFoglalasAllapot}
          </AvField>
        </div>
        <div className="col-md-4">
          <AvField
            type="select"
            name="szalloda"
            label="Szálloda:"
            errorMessage="Kötelező mező!"
            value={this.state.szalloda}
            onChange={this.handleInputChange}
            disabled={this.state.ugyszam}
          >
            <option value="">
              {'Válassz szállodát...'}
            </option>
            {this.state.aktivSzallodak}
          </AvField>
        </div>
        {/* <div className="col-md-12" /> */}
        <div className="col-md-2">
          <AvField
            type="select"
            name="foglalasEve"
            id="foglalasEve"
            label="Foglalás éve: "
            errorMessage="Kötelező mező!"
            // onChange={this.rcihetChange}
            value={this.state.foglalasEve}
            onChange={this.handleInputChange}
            disabled={this.state.ugyszam}
          >
            <option value="">
              {'Válassz évet...'}
            </option>
            {this.state.aktivEvek}
          </AvField>
        </div>
        <div className="col-md-2">
          <AvField
            type="select"
            name="rciHet"
            id="rciHet"
            label="RCI hét: "
            errorMessage="Kötelező mező!"
            // onChange={this.rcihetChange}
            value={this.state.rciHet}
            disabled={this.state.ugyszam}
            onChange={this.handleInputChange}
          >
            <option value="">
              {'Válassz hetet...'}
            </option>
            {this.state.aktivRCIhetek}
          </AvField>
        </div>
        <div className="col-md-4">
          <AvGroup>
            <Label for="ugyszam">
              Ügyszám:
            </Label>
            <AvInput
              type="text"
              name="ugyszam"
              id="ugyszam"
              maxLength="40"
              value={this.state.ugyszam}
              onChange={this.handleInputChange}
              autoComplete="off"
            />
          </AvGroup>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <AvForm onValidSubmit={this.onSubmit}>
        {this.state.formStatus === 'LOAD' ? <Loader /> : <span />}
        <div className="row">
          {this.state.formStatus === 'LIST' ? this.renderLogic() : <span />}
          {this.state.formStatus === 'NETW' ? (
            <NetworkEmptyState
              hasImage
              submitClick={() => this.getData()}
            />
          ) : <span />}
        </div>
      </AvForm>
    );
  }
}

export default KompenzacioListaContent;
