import Lib from '../../commons/lib';

export default class Service {
  static karbantartasUrl = window.location.origin + '/api/karbantartas/';

  static partnerUrl = window.location.origin + '/api/partner/';

  static telepulesUrl = window.location.origin + '/api/telepules/';

  // KARBANTARTAS START

  static getKarbantartasFejList(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.karbantartasUrl + 'karbantartas/fej/list'
    }], fnDone);
  }

  static getKarbantartasFej(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.karbantartasUrl + 'karbantartas/fej/get',
      params: { id: id }
    }], fnDone);
  }

  static viewKarbantartasFej(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.karbantartasUrl + 'karbantartas/fej/view',
      params: { id: id }
    }], fnDone);
  }

  static saveKarbantartasFej(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.karbantartasUrl + 'karbantartas/fej/save',
      data: data,
    }], fnDone);
  }

  static deleteKarbantartasFej(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.karbantartasUrl + 'karbantartas/fej/delete',
      params: { id: id }
    }], fnDone);
  }

  static getKarbantartasTetelList(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.karbantartasUrl + 'karbantartas/tetel/list',
      params: { id: id }
    }], fnDone);
  }

  static getKarbantartasTetel(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.karbantartasUrl + 'karbantartas/tetel/get',
      params: { id: id }
    }], fnDone);
  }

  static viewKarbantartasTetel(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.karbantartasUrl + 'karbantartas/tetel/view',
      params: { id: id }
    }], fnDone);
  }

  static saveKarbantartasTetel(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.karbantartasUrl + 'karbantartas/tetel/save',
      data: data,
    }], fnDone);
  }

  static deleteKarbantartasTetel(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.karbantartasUrl + 'karbantartas/tetel/delete',
      params: { id: id }
    }], fnDone);
  }

  static initKarbantartasTetelForm(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.karbantartasUrl + 'karbantartas/tetel/init',
      params: { id: id }
    }], fnDone);
  }

  static getKarbantartasUtemezes(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.karbantartasUrl + 'karbantartas/utemezes/get',
      params: { id: id }
    }], fnDone);
  }

  static saveKarbantartasUtemezes(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.karbantartasUrl + 'karbantartas/utemezes/save',
      data: data,
    }], fnDone);
  }

  static checkKarbantartasUtemezes(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.karbantartasUrl + 'karbantartas/utemezes/delete/check',
      params: { id: id }
    }], fnDone);
  }

  static getKarbantartasAlkalomList(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.karbantartasUrl + 'karbantartas/alkalom/list',
      data: data,
    }], fnDone);
  }

  static getEllenorzesiListaList(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.karbantartasUrl + 'karbantartas/alkalom/list/elllista',
      params: { id: id }
    }], fnDone);
  }

  static printFromAlkalom(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.karbantartasUrl + 'karbantartas/alkalom/print',
      acceptType: 'application/json, application/octet-stream',
      responseType: 'arraybuffer',
      withFileName: true,
      params: { id: id }
    }], fnDone);
  }

  static getEllenorzesiListaSzempontList(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.karbantartasUrl + 'elllista/list/szempont',
      params: { id: id }
    }], fnDone);
  }

  static deleteKarbantartasAlkalom(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.karbantartasUrl + 'karbantartas/alkalom/delete',
      params: { id: id }
    }], fnDone);
  }

  static searchPartner(nev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'partner-search' },
      params: {
        type: 'KARBANTARTAS_UJ_FORM',
        text: nev
      }
    }], fnDone);
  }

  static searchPartnerAlkalom(nev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'partner-search' },
      params: {
        type: 'KARBANTARTAS_ALKALOM_ELO',
        text: nev
      }
    }], fnDone);
  }

  static searchCikk(cikk, isFilter, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.karbantartasUrl + 'search/cikk',
      params: {
        nev: cikk,
        filter: isFilter
      }
    }], fnDone);
  }

  static searchAlkalomPartner(nev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.karbantartasUrl + 'search/partner',
      params: { nev: nev }
    }], fnDone);
  }

  static listOrszag(name, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'orszag/list',
      args: { requestType: 'orszag-list' },
      params: { name: name }
    }], fnDone);
  }

  // EDDIG OK

  static listIranyitoszam(nev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'telepules/irsz',
      args: { requestType: 'iranyitoszam-list' },
      params: { telepulesnev: nev }
    }], fnDone);
  }

  static listTelepules(irszam, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'telepules',
      args: { requestType: 'telepules-get-irszam' },
      params: { iranyitoszam: irszam }
    }], fnDone);
  }

  static listCim(text, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'telepules/like',
      args: { requestType: 'telepules-get-listcim' },
      params: { text: text }
    }], fnDone);
  }

  // KARBANTARTAS END

  // SZEMPONTOK START

  static getSzempontokList(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.karbantartasUrl + 'ellszempont/list',
    }], fnDone);
  }

  static getSzempont(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.karbantartasUrl + 'ellszempont',
      params: { id: id }
    }], fnDone);
  }

  static createSzempont(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.karbantartasUrl + 'ellszempont',
      data: data,
    }], fnDone);
  }

  static updateSzempont(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.karbantartasUrl + 'ellszempont',
      data: data,
    }], fnDone);
  }

  static deleteSzempont(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.karbantartasUrl + 'ellszempont',
      params: { id: id }
    }], fnDone);
  }

  // SZEMPONTOK END

  // LISTÁK START

  static listEllenorzesiLista(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.karbantartasUrl + 'elllista/list',
    }], fnDone);
  }

  static viewEllenorzesiLista(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.karbantartasUrl + 'elllista/view',
      params: {
        id: id
      }
    }], fnDone);
  }

  static getEllenorzesiLista(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.karbantartasUrl + 'elllista',
      params: {
        id: id
      }
    }], fnDone);
  }

  static getAktivSzempontList(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.karbantartasUrl + 'ellszempont/aktiv',
    }], fnDone);
  }

  static insertEllenorzesiLista(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.karbantartasUrl + 'elllista',
      data: data,
    }], fnDone);
  }

  static updateEllenorzesiLista(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.karbantartasUrl + 'elllista',
      data: data,
    }], fnDone);
  }

  static deleteEllenorzesiLista(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.karbantartasUrl + 'elllista',
      params: { id: id }
    }], fnDone);
  }

  static getEnum(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.karbantartasUrl + 'ellszempont/enum',
    }], fnDone);
  }

  // static queryCikk(cikknev, fnDone) {
  //   Lib.MicroServices.requestService([{
  //     method: 'GET',
  //     url: Service.karbantartasUrl + 'search/cikk',
  //     args: { requestType: 'cikk-query' },
  //     params: { nev: cikknev }
  //   }], fnDone);
  // }

  // LISTÁK END
}
