import React from 'react';

const Option = (props) => {
  console.log(props);
  const {
    children,
    className,
    cx,
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    innerRef,
    innerProps,
    data
  } = props;
  return (
    <div
      css={getStyles('option', props)}
      style={{
        borderTop: '1px solid gray',
        padding: 5,
        cursor: 'pointer',
        backgroundColor: isFocused ? '#b0d7fd' : 'white'
      }}
      className={cx(
        {
          option: true,
          'option--is-disabled': isDisabled,
          'option--is-focused': isFocused,
          'option--is-selected': isSelected,
        },
        className
      )}
      ref={innerRef}
      {...innerProps}
    >
      <table style={{ width: '100%' }}>
        <tr>
          <td style={{ width: '60%' }}>
            <i className="fa fa-file-text-o" />
            {' '}
            <b>
              {data.bizonylatszam}
            </b>
          </td>
          <td style={{ width: '40%', textAlign: 'right' }}>
            <b>
              {'Fennmaradó: '}
            </b>
            {data.fennmarado + ' ' + data.penznem}
          </td>
        </tr>
        <tr>
          <td style={{ width: '60%' }}>
            <i className="fa fa-user-o" />
            {' '}
            {data.partnerString}
          </td>
          <td style={{ width: '40%', textAlign: 'right' }}>
            <b>
              {'Összeg: '}
            </b>
            <span style={{ textAlign: 'right' }}>
              {data.osszeg + ' ' + data.penznem}
            </span>
          </td>
        </tr>
        <tr>
          <td style={{ width: '60%' }}>
            <i className="fa fa-university" />
            {' '}
            {data.kibocsato}
          </td>
          <td style={{ width: '40%' }} />
        </tr>
      </table>
    </div>
  );
};

export default Option;
