import React, { Component } from 'react';
import {
  Button,
  Label
} from 'reactstrap';
import {
  AvForm,
  AvGroup,
  AvInput,
  AvFeedback
} from 'availity-reactstrap-validation';
import Services from './services';
import Lib from '../../../commons/lib';

class FizetesiFelszolitoBase extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savedTop: false,
      savedBottom: false,
      felso: '',
      also: '',
      megjegyzesId: null,
    };
  }

  componentDidMount() {
    this.updatePageTop();
    this.updatePageBottom();
  }

  setDisableTop = () => {
    this.setState({ savedTop: true });
  }

  setOpenTop = () => {
    this.setState({ savedTop: false });
  }

  setDisableBottom = () => {
    this.setState({ savedBottom: true });
  }

  setOpenBottom = () => {
    this.setState({ savedBottom: false });
  }


  updatePageTop = () => {
    Services.getProfilFelso((err, res) => {
      if (!err) {
        if (res[0]) {
          this.setState({ savedTop: true, felso: res[0].KKAMAT_PROFIL_FELSO[0] });
        }
      }
    });
  }

  updatePageBottom = () => {
    Services.getProfilAlso((err, res) => {
      if (!err) {
        if (res[0]) {
          this.setState({ savedBottom: true, also: res[0].KKAMAT_PROFIL_ALSO[0] });
        }
      }
    });
  }

  submitTop = (event, resultObj) => {
    let sendObj = resultObj;
    sendObj.azonosito = 'KKAMAT_PROFIL_FELSO';
    sendObj.tipus = 'STRING';
    sendObj.ertek = resultObj.ertekFelso;
    delete sendObj.ertekFelso;
    sendObj.technikai = false;
    sendObj.nev = 'FELSO';
    if (!this.state.savedTop) {
      Services.updateProfilFelso(sendObj, (err) => {
        if (!err) {
          this.props.globals.addSuccess('Felső megjegyzés rögzítve!');
          this.setDisableTop();
          this.updatePageTop();
        }
      });
    }
  }

  submitBottom = (event, resultObj) => {
    let sendObj = resultObj;
    sendObj.azonosito = 'KKAMAT_PROFIL_ALSO';
    sendObj.tipus = 'STRING';
    sendObj.ertek = resultObj.ertekAlso;
    delete sendObj.ertekAlso;
    sendObj.technikai = false;
    sendObj.nev = 'ALSO';
    if (!this.state.savedBottom) {
      Services.updateProfilAlso(sendObj, (err) => {
        if (!err) {
          this.props.globals.addSuccess('Alsó megjegyzés rögzítve!');
          this.setDisableBottom();
          this.updatePageBottom();
        }
      });
    }
  }

  renderTopEditBottom = () => {
    return this.state.savedTop ? <Button
      type="button"
      color="success"
      onClick={this.setOpenTop}
    >
      Módosítás
    </Button> : '';
  }

  renderTopSaveBottom = () => {
    return this.state.savedTop ? '' : <Button
      color="primary"
    >
      Mentés
    </Button>;
  }

  renderBottomEditBottom = () => {
    return this.state.savedBottom ? <Button
      type="button"
      color="success"
      onClick={this.setOpenBottom}
    >
      Módosítás
    </Button> : '';
  }

  renderBottomSaveBottom = () => {
    return this.state.savedBottom ? '' : <Button
      color="primary"
    >
      Mentés
    </Button>;
  }

  render() {
    return (
      <div className="animated fadeIn">
        <AvForm onValidSubmit={this.submitTop}>
          <AvGroup>
            <Label for="ertekFelso">Felső megjegyzés:</Label>
            <AvInput
              className="munkalap-profil-textarea"
              disabled={this.state.savedTop}
              type="textarea"
              name="ertekFelso"
              id="ertekFelso"
              value={this.state.felso}
            />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
          {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_FIZFELSZPROFIL', 'KARB') && (
            <>
              {this.renderTopEditBottom()}
              {this.renderTopSaveBottom()}
            </>
          )}
        </AvForm>
        <AvForm onValidSubmit={this.submitBottom} className="margin-top-30">
          <AvGroup>
            <Label for="ertekAlso">Alsó megjegyzés:</Label>
            <AvInput
              className="munkalap-profil-textarea"
              disabled={this.state.savedBottom}
              type="textarea"
              name="ertekAlso"
              id="ertekAlso"
              value={this.state.also}
            />
            <AvFeedback>Kötelező mező!</AvFeedback>
          </AvGroup>
          {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_FIZFELSZPROFIL', 'KARB') && (
            <>
              {this.renderBottomEditBottom()}
              {this.renderBottomSaveBottom()}
            </>
          )}
        </AvForm>
      </div>
    );
  }
}

export default FizetesiFelszolitoBase;
