import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Collapse } from 'reactstrap';
import PenztarfejTable from './PenztarfejTable';
import PenztartetelTable from './PenztartetelTable';
import PenztartetelAnalitikaTable from './PenztartetelAnalitikaTable';

class PenztarMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsePenztarfej: true,
      collapsePenztartetel: false,
      collapsePenztartetelAnalitika: false,
      tablePenztarfejData: null,
      tablePenztarData: null,
      tableStatus: {
        fej: 'LOADED',
        tranzakcio: 'LOADED',
        tetel: 'LOADED'
      }
    };
  }

  tableStatusChange = (obj) => {
    this.setState({
      tableStatus: obj,
    });
  }

  changePenztarfejData = (obj) => {
    this.setState({
      tablePenztarfejData: obj,
      collapsePenztartetel: true
    });
  }

  changePenztarTetel = (id) => {
    this.setState({
      tablePenztarData: id,
      collapsePenztartetelAnalitika: true
    });
  }

  // clearlv1Data

  collapsePenztarfej = () => {
    this.setState({
      collapsePenztarfej: !this.state.collapsePenztarfej,
    });
  }

  collapsePenztartetel = () => {
    this.setState({
      collapsePenztartetel: !this.state.collapsePenztartetel
    });
  }

  collapsePenztartetelAnalitika = () => {
    this.setState({
      collapsePenztartetelAnalitika: !this.state.collapsePenztartetelAnalitika
    });
  }

  closePenztartetelAnalitika = () => {
    this.setState({
      collapsePenztartetelAnalitika: false
    });
  }

  closePenztartetel = () => {
    this.setState({
      collapsePenztartetel: false
    });
  }

  render() {
    return (
      <div>
        <Button
          className="szerzodes-collapse-buttons"
          color="success"
          onClick={this.collapsePenztarfej}
        >
          Pénztár fej {this.state.collapsePenztarfej ? '↑' : '↓' }
        </Button>
        <br />
        <Collapse isOpen={this.state.collapsePenztarfej}>
          <PenztarfejTable
            changeData={this.changePenztarfejData}
            globals={this.props.globals}
            tableStatus={this.state.tableStatus}
            tableStatusChange={this.tableStatusChange}
            closePenztartetelAnalitika={this.closePenztartetelAnalitika}
            closePenztartetel={this.closePenztartetel}
          />
        </Collapse>
        <Button
          className="szerzodes-collapse-buttons"
          color="success"
          onClick={this.collapsePenztartetel}
        >
          Pénztár tranzakció {this.state.collapsePenztartetel ? '↑' : '↓' }
        </Button>
        <br />
        <Collapse isOpen={this.state.collapsePenztartetel}>
          <PenztartetelTable
            changeData={this.changePenztarTetel}
            globals={this.props.globals}
            tableData={this.state.tablePenztarfejData}
            tableStatus={this.state.tableStatus}
            tableStatusChange={this.tableStatusChange}
          />
        </Collapse>
        <Button
          className="szerzodes-collapse-buttons"
          color="success"
          onClick={this.collapsePenztartetelAnalitika}
        >
          Pénztár tétel {this.state.collapsePenztartetelAnalitika ? '↑' : '↓' }
        </Button>
        <br />
        <Collapse isOpen={this.state.collapsePenztartetelAnalitika}>
          <PenztartetelAnalitikaTable
            globals={this.props.globals}
            tableData={this.state.tablePenztarData}
            tableStatus={this.state.tableStatus}
            tableStatusChange={this.tableStatusChange}
          />
        </Collapse>
      </div>
    );
  }
}

PenztarMenu.propTypes = {
  globals: PropTypes.object,
};

export default PenztarMenu;
