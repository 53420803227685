import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Label } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DataEmptyState from '../../commons/dataemptystate';
import { DatePicker, PaginationTotal } from '../../commons/controls';
import Loader from '../../commons/loader';
import NetworkEmptyState from '../../commons/networkemptystate';
import QueryEmptyState from '../../commons/queryempty';
import Services from './services';

class PenztarfejTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      /*
       *  FLOW STATE CONTROLLER
       */
      // ['QUERY', 'LOAD', 'DATA', 'NETW', 'TABL']
      currentStatusBaseTable: 'QUERY',

      /*
       *  FORM SELECT OPTIONS
       */
      aktivPenztarak: [],

      eloszuresValues: {
        id: '',
        from: null,
        to: null
      },

      /*
       *  TABLE DATA
       */
      baseJson: [],
    };
  }

  componentDidMount() {
    this.getAktivPenztarak();
  }

  onRowSelect = (row) => {
    this.props.tableStatusChange({
      fej: 'LOADED',
      tranzakcio: 'LOADED',
      tetel: 'RELOAD'
    });
    this.props.changeData({
      id: row.i,
      penztarid: row.penztarid
    });
    this.props.closePenztartetelAnalitika();
  }

  onVegeChange = (e, v) => {
    let currentState = this.state.eloszuresValues;
    let currentValue = v === '' ? null : v;
    currentState.to = currentValue;
    this.setState({ eloszuresValues: currentState });
  }

  onKezdeteChange = (e, v) => {
    let currentState = this.state.eloszuresValues;
    let currentValue = v === '' ? null : v;
    currentState.from = currentValue;
    this.setState({ eloszuresValues: currentState });
  }

  onSzamlaChange = (e) => {
    let currentState = this.state.eloszuresValues;
    let currentValue = e.target.value === '' ? '' : e.target.value;
    currentState.id = currentValue;
    this.setState({ eloszuresValues: currentState });
  }

  getAktivPenztarak = () => {
    Services.listPenztarak((err, res) => {
      if (!err) {
        let aktivPenztarak = res[0].map(e => <option key={e.id} value={e.id}>{e.nev + ' [' + e.penznem + '] (' + e.penzjel + ')' }</option>);
        this.setState({ aktivPenztarak: aktivPenztarak });
      }
    });
  }

  updateBaseTable = () => {
    this.setState({
      currentStatusBaseTable: 'LOAD'
    });
    let sendObj = this.state.eloszuresValues;
    if (!sendObj.id) delete sendObj.id;
    if (!sendObj.from) delete sendObj.from;
    if (!sendObj.to) delete sendObj.to;
    Services.listPenztarFejTable(sendObj, (err, res) => {
      if (!err) {
        res[0].forEach((element, idx) => {
          if (element.bankszamlabean && element.bankszamlabean.szamlaszam) {
            res[0][idx].szamlaszam = element.bankszamlabean.szamlaszam;
          }
        });
        this.setState({
          baseJson: res[0],
          currentStatusBaseTable: 'TABL',
        });
        this.getAktivPenztarak();
      } else {
        this.setState({
          currentStatusBaseTable: 'QUERY'
        });
      }
    });
  }

  renderEloSzures = () => {
    return (
      <AvForm>
        <div className="row">
          <div className="col-md-3">
            <AvField
              type="select"
              name="id"
              id="id"
              label="Pénztár: "
              errorMessage="Kötelező mező!"
              onChange={this.onSzamlaChange}
              value={this.state.eloszuresValues.id}
              disabled={!this.state.aktivPenztarak.length}
            >
              <option value="">
                Válassz pénztárt...
              </option>
              {this.state.aktivPenztarak}
            </AvField>
          </div>
          <div className="col-md-2">
            <Label for="from">
              Időszak kezdete:
            </Label>
            <DatePicker
              required
              placeholder="éééé-hh-nn"
              onChange={this.onKezdeteChange}
              type="select"
              name="from"
              value={this.state.eloszuresValues && this.state.eloszuresValues.from && new Date(this.state.eloszuresValues.from)}
            />
          </div>
          <div className="col-md-2">
            <Label for="to">
              Időszak vége:
            </Label>
            <DatePicker
              required
              placeholder="éééé-hh-nn"
              onChange={this.onVegeChange}
              type="select"
              name="to"
              value={this.state.eloszuresValues && this.state.eloszuresValues.to && new Date(this.state.eloszuresValues.to)}
            />
          </div>
          <div className="col-md-2">
            <Button
              className="margin-top-30"
              color="primary"
              onClick={() => {
                this.updateBaseTable();
                this.props.tableStatusChange({
                  fej: 'LOADED',
                  tranzakcio: 'RELOAD',
                  tetel: 'RELOAD'
                });
                this.props.closePenztartetelAnalitika();
                this.props.closePenztartetel();
              }}
            >
              Keresés
            </Button>
          </div>
        </div>
      </AvForm>
    );
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  renderBaseTable = () => {
    const pagination = true;

    const selectRowProp = {
      mode: 'radio',
      onSelect: this.onRowSelect
    };

    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };
    return (
      <div>
        { this.state.baseJson.length ? (
          <BootstrapTable
            data={this.state.baseJson}
            pagination={pagination}
            options={options}
            selectRow={selectRowProp}
            className="margin-top-30"
          >
            <TableHeaderColumn
              dataField="n"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            >
              Pénztár
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="d"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            >
              Kifizetés dátuma
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="m"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            >
              Mozgás
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="b"
              tdStyle={{ whiteSpace: 'normal' }}
              filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
            >
              Bizonylatszám
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="i"
              tdStyle={{ whiteSpace: 'normal' }}
              isKey
              hidden
            >
              Műveletek
            </TableHeaderColumn>
          </BootstrapTable>) : <DataEmptyState />}
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderEloSzures()}
        {{
          QUERY: (<QueryEmptyState
            hasImage
          />),
          LOAD: (<Loader />),
          DATA: (<DataEmptyState
            hasImage
            subtitle="Próbálj más előszűrést vagy vigyél fel új adatokat!"
          />),
          NETW: (<NetworkEmptyState
            hasImage
            submitClick={() => this.updateTable()}
          />),
          TABL: (this.renderBaseTable())
        }[this.state.currentStatusBaseTable]}
      </div>
    );
  }
}

PenztarfejTable.propTypes = {
  changeData: PropTypes.func,
  tableStatusChange: PropTypes.func,
  closePenztartetel: PropTypes.func
};

export default PenztarfejTable;
