import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import classnames from 'classnames';
import Lib from '../../../commons/lib';
import SzemelyitorzsLista from './SzemelyitorzsLista';

class SzemelyiTorzs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: (this.hasRole('SZERVEZET_SZEMELYI')) ? '1' : '2',
      type: 'SZERVEZET_SZEMELYI'
    };
  }

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({ type: tab === '1' ? 'SZERVEZET_SZEMELYI' : 'SZERVEZET_SZEMELYI' });
      this.setState({ activeTab: tab });
    }
  }

  hasRole = (role) => {
    return Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, [role]);
  }

  render() {
    let cardStyle = {
      padding: 10
    };

    return (
      <div>
        <div className="animated fadeIn">
          <div className="card">
            <div className="row" style={cardStyle}>
              <div className="col-md-12">
                <Nav tabs>
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['SZERVEZET_SZEMELYI'], true) && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => { this.toggle('1'); }}
                      >
                        Személyi törzs
                      </NavLink>
                    </NavItem>
                  )}
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['SZERVEZET_SZEMELYI'], true) && (
                    <TabPane tabId="1">
                      <SzemelyitorzsLista globals={this.props.route.globals} type={this.state.type} />
                    </TabPane>
                  )}
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SzemelyiTorzs.propTypes = {
  route: PropTypes.object,
  globals: PropTypes.object
};

export default SzemelyiTorzs;
