import Lib from '../../commons/lib';

export default class Service {
  static dashboardUrl = window.location.origin + '/api/dashboard/';

  static webshopUrl = 'http://opencart.zalaszamteszt.hu/';

  // DASHBOARD START

  static getPostalada(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.dashboardUrl + 'dashboard/postalada/list',
      args: { requestType: 'dashboard-postalada-list' },
    }], fnDone);
  }

  static getLevelszam(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.dashboardUrl + 'dashboard/postalada/levelszam',
      args: { requestType: 'dashboard-postalada-levelszam' },
    }], fnDone);
  }

  static getLevelDetails(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.dashboardUrl + 'dashboard/postalada/get',
      args: { requestType: 'dashboard-postalada-get' },
      params: {
        id: id,
      }
    }], fnDone);
  }

  static deleteMails(mails, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.dashboardUrl + 'dashboard/postalada/delete',
      args: { requestType: 'dashboard-postalada-delete' },
      params: { postaladaid: mails }
    }], fnDone);
  }

  // DASHBOARD END

  // OPENCART START

  // static loginWebshop(fnDone) {
  //   Lib.MicroServices.requestService([{
  //     method: 'POST',
  //     url: Service.webshopUrl + 'index.php',
  //     args: { requestType: 'webshop-login' },
  //     acceptType: 'application/json',
  //     params: {
  //       route: 'api/login',
  //       api_token: '3232dsds'
  //     },
  //     data: {
  //       username: 'gergi',
  //       key: 'jH1VLWObrbstOnhHW6ViwMlK0O4gZmnj7Em5slWUutjhgIP4w8V8DKcLmfr1mKUvpe4eNyNHbDO6fjedPTlhEyMuUE334FOwRTZv0JVeFqBZHPmndqAiNHcoDvJ4ch4o4VICPdGU2Ff0XwoCkQeGBC3OFg4b5lIMNOSdVDARSDQa8CTW5dKEkBxhJrknoufNTmzU8NiGnwb0dOhduLrEiLf5eGlZSqK370NEm74uoyCJCeEfxZTpIHCLLhrUvjYM'
  //     },
  //   }], fnDone);
  // }

  // static getAllProducts(fnDone) {
  //   Lib.MicroServices.requestService([{
  //     method: 'POST',
  //     url: Service.webshopUrl + 'index.php',
  //     args: { requestType: 'webshop-login' },
  //     params: {
  //       route: 'api/allproducts',
  //       api_token: 'mMG1VnBwe2ueInzvor2NGGvyUEfNY95WxpBPvQoKTViSub42sd6lEGsIBhqFVDUpuWTBjliCIeViDXGsWOMsmtTG4TzBXyLOOiQT6GW6KLWWdewtuhK0s1WW7OlAronP789Md8tcmA1boyV2iIXXHMjvugtfJkOpjhny1iBvH14V5YSEzZ2JkBugvfn8loSYgFiv1CjiMvcA6jjNkbyXNQJo5XQ5tBj7doqUNvp4upTpfd5ToBRHFA2k8PAK07Ec'
  //     },
  //     data: {
  //       username: 'Default',
  //       key: 'mMG1VnBwe2ueInzvor2NGGvyUEfNY95WxpBPvQoKTViSub42sd6lEGsIBhqFVDUpuWTBjliCIeViDXGsWOMsmtTG4TzBXyLOOiQT6GW6KLWWdewtuhK0s1WW7OlAronP789Md8tcmA1boyV2iIXXHMjvugtfJkOpjhny1iBvH14V5YSEzZ2JkBugvfn8loSYgFiv1CjiMvcA6jjNkbyXNQJo5XQ5tBj7doqUNvp4upTpfd5ToBRHFA2k8PAK07Ec'
  //     },
  //   }], fnDone);
  // }

  // OPENCART END
}
