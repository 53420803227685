import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { components } from "react-select";
import { css } from "emotion";
import {Button} from 'reactstrap';


export const CustomOption = props => {
  const {
    innerRef, 
    innerProps,
    cx, 
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    className,
    selectProps,
    clearValue,
  } = props;
  return(
    <div ref={innerRef} {...innerProps} onClick={e => {e.preventDefault()}} style={{ display: "block", alignItems: "center"}} className={cx(
      css(getStyles("option", props)),
      {
        option: true,
        "option--is-disabled": isDisabled,
        "option--is-focused": isFocused,
        "option--is-selected": isSelected
      },
      className
    )}>
      <div onClick={e => {innerProps.onClick()}}>
        {props.data.label}
        <span>
          <i className={"fa fa-trash remove-option pull-right "} aria-hidden="false" onClick={e => selectProps.onDeleteOption(props.data.value)}></i>
        </span>
      </div>
    </div>
  );
}