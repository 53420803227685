import React, { Component } from 'react';
import { browserHistory } from 'react-router';
import Services from './services';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      olvasatlan: null,
      osszes: null
    };
  }

  componentDidMount() {
    Services.getLevelszam((err, res) => {
      if (!err) {
        this.setState({
          olvasatlan: res[0].olvasatlan,
          osszes: res[0].osszes
        })
      }
    });
  }

  handleUzenetClick = () => {
    browserHistory.push('/dashboard/uzenetek/');
  }

  render() {
    return (
      <div className="animated fadeIn">
        <div className="row">
          <div className="col-sm-6 col-lg-3">
            <div
              className="social-box facebook"
              style={{ cursor: 'pointer' }}
              onClick={this.handleUzenetClick}
            >
              <i className="fa fa-envelope-o" />
              <div className="chart-wrapper">
                <canvas id="social-box-chart-1" height="90" />
              </div>
              <ul>
                <li>
                  <strong>{this.state.olvasatlan ? this.state.olvasatlan : '-'}</strong>
                  <span>OLVASATLAN</span>
                </li>
                <li>
                  <strong>{this.state.osszes ? this.state.osszes : '-'}</strong>
                  <span>ÖSSZES</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
