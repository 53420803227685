import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Loader from '../../../../commons/loader';

class UzenetDetailedItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: [],
    };
  }

  componentDidMount() {
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!nextProps.data) {
      return;
    }

    if (this.props.data !== nextProps.data) {
      this.setState({
        data: nextProps.data,
      });
    }

    if (this.props.loading !== nextProps.loading) {
      this.setState({
        loading: nextProps.loading,
      });
    }
  }

  renderData = () => {
    return (
      <div className="row" style={{ marginTop: 14 }}>
        <div className="col-12" style={{ whiteSpace: 'pre-wrap' }}>
          <b>
            {'Feladó: '}
          </b>
          {this.props.data.fromSzemelyNev ? this.props.data.fromSzemelyNev : this.props.data.fromUserName}
          <br />
          <b>
            {'Dátum: '}
          </b>
          {this.state.data.kuldesIdopont}
          <br />
          <br />
          {this.state.data.body}
        </div>
      </div>
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.props.loading ? <Loader style={{ paddingTop: 30 }} /> : this.renderData()}
      </React.Fragment>
    );
  }
}

UzenetDetailedItem.propTypes = {
  fromSzemelyNev: PropTypes.string,
  loading: PropTypes.bool,
  kuldesIdopont: PropTypes.string,
  subject: PropTypes.string,
};

UzenetDetailedItem.defaultProps = {
  fromSzemelyNev: null,
  kuldesIdopont: '-',
  subject: '-',
  loading: false
};

export default UzenetDetailedItem;
