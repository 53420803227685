import React, { Component } from 'react';
import PropTypes from 'prop-types';

class UzenetItem extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {
    const disabledFeatures = false;
    return (
      <React.Fragment>
      <li
        className={'message' + (this.props.unread ? ' unread' : ' ')}
        onClick={this.props.onUzenetClick}
      >
        <div className="actions">
          <span className="action">
            <i className="fa fa-address-card" />
          </span>
          <span className="action">
            <i className="fa fa-comment-o" />
          </span>
        </div>
        <div className="header">
          <span className="from">
            <b>Feladó: </b>
            {this.props.user ? this.props.user : this.props.userSec}
          </span>
          <span className="date">
            <span className="fa fa-paper-clip" />
            {this.props.date}
          </span>
        </div>
        <div className="title">
          <b>Tárgy: </b>
          {this.props.title}
        </div>
        {disabledFeatures && (
        <div className="description">
          {this.props.description}
        </div>
        )}
      </li>
      </React.Fragment>
    );
  }
}

UzenetItem.propTypes = {
  user: PropTypes.string,
  userSec: PropTypes.string,
  unread: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  onUzenetClick: PropTypes.func
};

UzenetItem.defaultProps = {
  unread: false,
  user: null,
  title: '-',
  description: '-',
  date: '-',
  onUzenetClick: () => {}
};

export default UzenetItem;
