import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';
import RecepcioLista from './RecepcioLista';
import YouTube from '../../commons/youtube';
import Lib from '../../commons/lib';
import KompenzacioLista from './KompenzacioLista';

const videoData = [
  { text: 'Recepció', id: 'KhOCTp7MgPM' },
];

class RecepcioBase extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: undefined
    };
  }

  // componentDidMount() {
  // //  console.log(this.props);
  // }

  // toggle(tab) {
  //   if (this.state.activeTab !== tab) {
  //     this.setState({
  //       activeTab: tab
  //     });
  //   }
  // }

  componentDidMount() {
    this.initTab();
  }

  initTab = () => {
    let activeTab = '1';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOG_UDJOG'], true)) activeTab = '2';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOG_RECEPCIO'], true)) activeTab = '1';

    this.setState({ activeTab: activeTab });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  render() {
    let cardStyle = {
      padding: 20
    };

    return (
      <div className="animated fadeIn">
        <div className="card">
          <div className="card-header">
            {'Recepció'}
            <YouTube data={videoData} />
          </div>
          <div className="row" style={cardStyle}>
            <div className="col-md-12">
              <Nav tabs>
                {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOG_RECEPCIO'], true) && (
                  <NavItem>
                    <NavLink
                      href="#"
                      className={classnames({ active: this.state.activeTab === '1' })}
                      onClick={() => { this.toggle('1'); }}
                    >
                      {'Infópult'}
                    </NavLink>
                  </NavItem>
                )}

                {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOG_UDJOG'], true) && (
                  <NavItem>
                    <NavLink
                      href="#"
                      className={classnames({ active: this.state.activeTab === '2' })}
                      onClick={() => { this.toggle('2'); }}
                    >
                      {'Kompenzációs szállásfoglalás'}
                    </NavLink>
                  </NavItem>
                )}
              </Nav>

              <TabContent activeTab={this.state.activeTab}>
                {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOG_RECEPCIO'], true) && (
                  <TabPane tabId="1">
                    <RecepcioLista
                      globals={this.props.route.globals}
                      route={this.props.route}
                      addNotification={this.addNotification}
                      location={this.props.location}
                      active={this.state.activeTab === '1'}
                    />
                  </TabPane>
                )}
                {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['UDULESIJOG_UDJOG'], true) && (
                  <TabPane tabId="2">
                    <KompenzacioLista globals={this.props.route.globals} type={this.state.type} active={this.state.activeTab === '2'} />
                  </TabPane>
                )}
              </TabContent>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RecepcioBase.propTypes = {
  route: PropTypes.object
};

export default RecepcioBase;
