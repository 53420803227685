import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Badge,
  Button,
  InputGroup,
  InputGroupAddon,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip
} from 'reactstrap';
import {
  AvFeedback,
  AvField,
  AvForm,
  AvGroup,
  AvInput
} from 'availity-reactstrap-validation';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Select from 'react-select/lib/Async';
import fileSaver from 'file-saver';
import Services from './services';
import DataEmptyState from '../../commons/dataemptystate';
import QueryEmptyState from '../../commons/queryempty';
import { DatePicker, PaginationTotal } from '../../commons/controls';
import Lib from '../../commons/lib';
import NumberFormatter from '../../commons/NumberFormatter';
import SlaveAvInput from '../../commons/SlaveAvInput';

let setarray = new Set();

class SzamlaLista extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalKiegeszito: false,
      modalMegtekintes: false,
      modalUdulesijog: false,
      dataLoaded: null,
      dataViewLoaded: null,
      dataFailed: null,
      viewLoading: true,
      queryLoaded: null,
      queryStarted: null,
      aktivPenznem: null,
      aktivFizmod: null,
      szamlaJson: [],
      udulesijogJson: [],
      szamlaObj: null,
      visszakuldesDatuma: null,
      visszakuldesOka: null,
      visszaKuldesOkai: null,
      kedvezmenyTipus: '',
      kedvezmenyTipusai: [],
      visszaKuldesEnable: false,
      fizetesimeghagyasalatt: false,
      formKkamat: '',
      formGenKkamat: '',
      formRendezettkamat: '',
      formPenznem: '-',
      halasztasdatum: null,
      generalasDisable: true,
      feladas: null,
      showNote: false,
      checkboxadataok : [],
      szamlaLista: [],
      // Modositas
      formId: '',
      formModosithato: false,
      formArfolyam: '',
      formAfa: '',
      formUdulesijogok: [],
      formKelte: null,
      formFizhat: null,
      formTelj: null,
      formDijBrutto: '',
    };
  }

  // componentWillMount() {
  //   this.getAktivPenznem();
  //   this.getAktivFizmod();
  // }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.active !== prevProps.active) {
      if (this.props.active) {
        this.getAktivPenznem();
        this.getAktivFizmod();
      }
    }
  }

  getAktivPenznem = () => {
    Services.getPenznem('SZAMLA', (err, res) => {
      if (!err) {
        let resOptions = res[0].map(e => <option key={e.id} value={e.id}>{e.nev + ' (' + e.azonosito + ')'}</option>);
        this.setState({ aktivPenznem: resOptions });
      }
    });
  }

  getSzamlaEnum = () => {
    Services.getSzamlaEnum((err, res) => {
      if (!err) {
        let resOptions = res[0][1].map(e => <option key={e.name} value={e.name}>{e.caption}</option>);
        this.setState({ visszaKuldesOkai: resOptions });
      }
    });
  }

  getAktivFizmod = () => {
    Services.getFizmod('SZAMLA', (err, res) => {
      if (!err) {
        let resOptions = res[0].map(e => <option key={e.variansid ? e.variansid : e.id} value={e.variansid ? e.variansid : e.id}>{e.variansnev ? e.variansnev : e.nev}</option>);
        this.setState({ aktivFizmod: resOptions });
      }
    });
  }

  updatevisszakuldes = (id) => {
    this.setState({
      mentesButtonLoading: true
    });

    let datum = this.state.visszakuldesDatuma;
    let oka = this.refs.visszakuldes._inputs.feladas.state.value === '' ? null : this.refs.visszakuldes._inputs.feladas.state.value;
    let kedvezmenyTipus = this.refs.visszakuldes._inputs.kedvezmenyTipus.state.value === '' ? null : this.refs.visszakuldes._inputs.kedvezmenyTipus.state.value;
    let data = {};
    let fizmeghagy = this.state.fizetesimeghagyasalatt;

    data.id = id;
    data.halasztasdatum = this.state.halasztasdatum;
    data.visszadatum = datum;
    data.visszaok = oka;
    data.fizetesikedvezmenytipus = kedvezmenyTipus;
    data.kkamat = this.state.formKkamat;
    data.kkamatgeneralt = this.state.formGenKkamat;
    data.rendezettkamat = this.state.formRendezettkamat;
    data.fizetesimeghagyasalatt = fizmeghagy;

    Services.setSzamlaKiegeszitoAdat(data, (err2) => {
      if (!err2) {
        this.props.globals.addSuccess('A mentés sikeres volt!');
        this.toggleKiegeszito();
      }
      this.setState({
        mentesButtonLoading: false
      });
    });
  }

  updateTable = (event, searchObj) => {
    this.setState({
      dataLoaded: false,
      dataFailed: false,
      queryLoaded: false,
      queryStarted: true,
      feladas: searchObj.feladas === 'true' ? 'true' : (searchObj.feladas === 'false' ? 'false' : null)
    });
    setarray.clear();
    if (searchObj.ev === '') delete searchObj.ev;
    if (searchObj.penznem === '') delete searchObj.penznem;
    if (searchObj.fizmod === '') delete searchObj.fizmod;
    if (searchObj.feladas === '' || searchObj.feladas === 'null') delete searchObj.feladas;
    if (searchObj.kiegInf === '') delete searchObj.kiegInf;

    Services.listSzamla(this.state.szamlakibocsatoValue && this.state.szamlakibocsatoValue.id && this.state.szamlakibocsatoValue.id, this.state.fojogtulajdonosValue && this.state.fojogtulajdonosValue.id && this.state.fojogtulajdonosValue.id, searchObj.ev, searchObj.penznemid, searchObj.fizmodid, searchObj.feladas, searchObj.kiegInf, (err, res) => {
      if (err) {
        this.setState({
          dataLoaded: null, dataFailed: null, queryLoaded: null, queryStarted: null
        });
      } else {
        let hasNote = false;
        res[0].forEach((e, i) => {
          if (e.fizetesimeghagyasalatt) hasNote = true;
          e.kkamatosszegek = e.kkamatgeneralt + ' ' + e.penznem + ' / \n' + e.rendezettkkamat + ' ' + e.penznem;
          e.kibocsatobean_nev = e.kibocsatobean.nev;
          e.datumok = (e.fizhat ? e.fizhat : '-') + ' / ' + (e.halasztasdatum ? e.halasztasdatum : '-');
          e.kedvezmeny = e.fizetesikedvezmenytipus && e.fizetesikedvezmenytipus.caption;
          e.osszegek = e.dijnetto + ' ß/' + e.dijbrutto + ' ß';
        });
        this.setState({
          szamlaJson: res[0], dataLoaded: true, dataFailed: false, queryLoaded: true, queryStarted: false, showNote: hasNote
        });
      }
    });
  }

  toggleMegtekintes = () => {
    this.setState({ modalMegtekintes: !this.state.modalMegtekintes });
  }

  toggleKiegeszito = () => {
    this.setState({ modalKiegeszito: !this.state.modalKiegeszito });
  }

  toggleUdulesijog = () => {
    this.setState({ modalUdulesijog: !this.state.modalUdulesijog });
  }

  toggleModositas = () => {
    this.setState({ modalModositas: !this.state.modalModositas });
  }

  iconFormatter = (cell, row) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_SZAMLAKEZELES', 'LEK') && (
          <React.Fragment>
            <Button className="icon-eye table-action-icon" id={'action-view-' + cell} onClick={() => this.handleViewClick(cell)} />
            <UncontrolledTooltip placement="left" target={'action-view-' + cell} delay={0}>
              Megtekintés
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        {(Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_SZAMLAKEZELES', 'KARB') /*|| Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_SZAMLA', 'KARB')*/) && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-edit-' + cell} onClick={() => this.handleEditClick(cell, row)} />
            <UncontrolledTooltip style={{ float: 'right' }} placement="right" target={'action-edit-' + cell} delay={0}>
              Módosítás
            </UncontrolledTooltip>
            <Button className="icon-envelope table-action-icon" id={'action-view-' + cell + 'level'} onClick={() => this.handleKiegeszitoClick(cell)} />
            <UncontrolledTooltip style={{ float: 'right' }} placement="right" target={'action-view-' + cell + 'level'} delay={0}>
              Kiegészítő információk
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOGSZLA_SZAMLAKEZELES', 'LEK') && (
          <React.Fragment>
            <Button className="fa fa-file-text-o table-action-icon" id={'action-new-' + cell} onClick={() => this.handleUdulesijogClick(cell, row)} />
            <UncontrolledTooltip placement="left" target={'action-new-' + cell} delay={0}>
              Üdülési jog adatok
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }


  szamlaKibocsatoFormatter = (cell) => {
    if (cell && cell.nev) return (cell.nev);
    return '';
  }

  dijFormatter = (cell, row) => {
    if (cell) {
      return NumberFormatter.addSpacesToString(cell, NumberFormatter.OSSZEG) + ' ' + row.penznem;
    }
    return '';
  }

  reszletFormatter = (cell, row) => {
    switch (row.reszlet) {
      case true:
        return <Badge color="danger">Igen</Badge>;
      case false:
        return <Badge color="success">Nem</Badge>;
      default:
        return <Badge color="secondary">n/a</Badge>;
    }
  }

  allapotFormatter = (cell) => {
    if (cell && cell.caption) return (cell.caption);
    return '';
  }

  tipusFormatter = (cell) => {
    if (cell && cell.caption) return (cell.caption);
    return '';
  }

  osszegFormatter = (cell, row) => {
    if (cell) {
      let array = cell.split('/');
      let output = '';
      for (let i = 0; i < array.length; i++) {
        let osszegArray = array[i].trim().split(' ');
        for (let o = 0; o < osszegArray.length; o++) {
          osszegArray[o] = NumberFormatter.addSpacesToString(osszegArray[o], NumberFormatter.OSSZEG);
        }
        output += osszegArray.join(' ') + (i < 1 ? ' / ' : '');
      }
      return output.replace(/ß/g, row.penznem);
    }
  }

  handleViewClick = (id) => {
    this.setState({ dataViewLoaded: false });
    Services.getSzamla(id, (err, res) => {
      if (!err) {
        this.setState({
          szamlaObj: res[0],
          dataViewLoaded: true
        });
      }
    });
    this.toggleMegtekintes();
  }

  handleUdulesijogClick = (id, row) => {
    if (row.udulesijogok) {
      row.udulesijogok.forEach((udulesijog) => {
        udulesijog.penznem = row.penznem;
      });
    }

    this.setState({
      udulesijogJson: row.udulesijogok
    });
    this.toggleUdulesijog();
  }

  handleKiegeszitoClick = (id) => {
    this.setState({
      visszaKuldesEnable: false,
      visszakuldesOka: null,
      viewLoading: true,
    });
    Services.getSzamlaKiegeszitoAdat(id, (err, res) => {
      if (!err) {
        let d = null;
        let hd = null;
        this.getSzamlaEnum();
        if (res[0] && res[0].bean) {
          if (res[0].bean.visszadatum == null || res[0].bean.visszadatum === '') {
            d = null;
          } else {
            d = res[0].bean.visszadatum;
          }

          if (res[0].bean.halasztasdatum == null || res[0].bean.halasztasdatum === '') {
            hd = null;
          } else {
            hd = res[0].bean.halasztasdatum;
          }

          let resOptions = res[0].type.map(e => <option key={e.name} value={e.name}>{e.caption}</option>);

          this.setState({
            kedvezmenyTipusai: resOptions,
            visszakuldesDatuma: d,
            halasztasdatum: hd,
            formKkamat: res[0].bean.kkamat,
            formPenznem: res[0].bean.penznem,
            formGenKkamat: res[0].bean.kkamatgeneralt,
            formRendezettkamat: res[0].bean.rendezettkamat,
            visszakuldesOka: res[0].bean.visszaok && res[0].bean.visszaok.name,
            kedvezmenyTipus: res[0].bean.fizetesikedvezmenytipus && res[0].bean.fizetesikedvezmenytipus.name,
            fizetesimeghagyasalatt: typeof res[0].bean.fizetesimeghagyasalatt === 'boolean' ? res[0].bean.fizetesimeghagyasalatt : false,
            viewLoading: false,
          });
        }
      }
    });
    this._currentViewId = id;
    this.toggleKiegeszito();
  }

  handleEditClick = (id, row) => {
    this.setState({ dataModifyLoading: true });
    this.toggleModositas();

    Services.getSzamlaInitModify(id, (err, res) => {
      if (!err) {
        // {"id":269653,"arfolyam":1.00,"afa":18.00,"penznem":"HUF","udulesijogok":[{"id":269654,"dijbrutto":1180.0000}],"kelte":"2019-03-11","fizhat":"2019-02-01","telj":"2019-02-15","dijbrutto":1180.0000}
        let formUdulesijogok = [];
        res[0].udulesijogok.forEach((udulesijog) => {
          udulesijog.dijnetto = Math.round(udulesijog.dijbrutto / (1 + res[0].afa / 100));
        });
        this.setState({
          formId: res[0].id,
          formModosithato: res[0].modosithato,
          formArfolyam: res[0].arfolyam,
          formAfa: res[0].afa === 0 ? '0' : res[0].afa,
          formPenznem: res[0].penznem,
          formUdulesijogok: res[0].udulesijogok,
          formKelte: res[0].kelte,
          formFizhat: res[0].fizhat,
          formTelj: res[0].telj,
          formDijBrutto: res[0].dijbrutto,
          // modositasJson: res[0],
          dataModifyLoading: false
        });
      } else {
        this.setState({
          dataModifyLoading: false
        });
      }
    });
  }

  edit = () => {
    let editObj = {
      id: this.state.formId,
      arfolyam: this.state.formArfolyam,
      fizhat: this.state.formFizhat,
      kelte: this.state.formKelte,
      telj: this.state.formTelj,
      udulesijogok: this.state.formUdulesijogok
    };

    Services.editSzamla(editObj, (err, res) => {
      if (!err) {
        this.props.globals.addSuccess('Sikeres módositás!');
        this.toggleModositas();
        this.updateTable(null, this.refs.feladas.context.FormCtrl.getValues());
      }
    });
  }

  loadSzamlakibocsatoChange = (input, callback) => {
    if (input.length !== 0) {
      Services.getSzamlaKibocsato('SZAMLA_SZAMLAKIBOCSATO_ELO', input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            item.nev += ' (' + item.cim + ') ';
            if (item.adoszam) {
              item.nev += '[' + NumberFormatter.addHyphensToString(item.adoszam) + '] ';
            }
            newArray.push(item);
          });
          callback(newArray);
        }
      });
    }
  }

  updateSzamlaFeladas = () => {
    let submitObj = {};
    if (this.lista && this.lista.state && this.lista.state.selectedRowKeys && this.lista.state.selectedRowKeys.length > 0) {
      submitObj.lista = [];
      let rowKeys = this.lista.state.selectedRowKeys;
      let data = this.lista.store.data;
      data.forEach((dataItem) => {
        rowKeys.forEach((key) => {
          if (dataItem.id === key) {
            submitObj.lista.push(dataItem.id);
          }
        });
      });
    } else {
      submitObj.lista = [];
    }

    Services.updateSzamlaFeladas(submitObj.lista, (err, res) => {
      if (!err) {
        this.updateTable(null,  this.refs.feladas.context.FormCtrl.getValues());
        fileSaver.saveAs(new Blob([res[0]], { type: 'application/zip;charset=ANSI', responseType: 'arraybuffer' }), new Date().toISOString().slice(0, 10) + '.zip');
        setarray.clear();
      }
    });
  }

  updateSzamlaVisszavonas = () => {
    let submitObj = {};
    if (this.lista && this.lista.state && this.lista.state.selectedRowKeys && this.lista.state.selectedRowKeys.length > 0) {
      submitObj.lista = [];
      let rowKeys = this.lista.state.selectedRowKeys;
      let data = this.lista.store.data;
      data.forEach((dataItem) => {
        rowKeys.forEach((key) => {
          if (dataItem.id === key) {
            submitObj.lista.push(dataItem.id);
          }
        });
      });
    } else {
      submitObj.lista = [];
    }
    Services.updateSzamlaVisszavonas(submitObj.lista, (err) => {
      if (!err) {
        this.props.globals.addSuccess('A visszavonás sikeres volt!');
        setarray.clear();
        this.updateTable(null, this.refs.feladas.context.FormCtrl.getValues());
      }
    });
  }

  loadFojogtulajChange = (input, callback) => {
    if (input.length !== 0) {
      Services.getSzamlaKibocsato('SZAMLA_SZAMLAFIZETO_ELO', input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            item.nev += ' (' + item.cim + ') ';
            if (item.adoszam) {
              item.nev += '[' + NumberFormatter.addHyphensToString(item.adoszam) + '] ';
            }
            newArray.push(item);
          });
          callback(newArray);
        }
      });
    }
  }


  // visszakuldesDatuma
  handleDateChange = (date, value) => {
    let currentValue = value === '' ? null : value;
    this.setState({ visszakuldesDatuma: currentValue });
  }

  // halasztasdatum
  handleHalasztasDateChange = (date, value) => {
    let currentValue = value === '' ? null : value;
    this.setState({ halasztasdatum: currentValue });
  }

  handleChangeSzamlakibocsato = (value) => {
    if (value && value.length !== 0) {
      this.setState({ szamlakibocsatoValue: value });
    } else {
      this.setState({ szamlakibocsatoValue: '' });
    }
  }

  handleChangeFojogtulajdonos = (value) => {
    if (value && value.length !== 0) {
      this.setState({ fojogtulajdonosValue: value });
    } else {
      this.setState({ fojogtulajdonosValue: '' });
    }
  }

  handleChangeFeladas= (e) => {
    // this.setState({ feladas: e.target.value });
  }

  handleArfolyamChange = (e, v) => {
    let currentValue = v === '' ? null : v;
    this.setState({ formArfolyam: currentValue });
  }

  handleFizhataridoChange = (e, v) => {
    let currentValue = v === '' ? null : v;
    let newState = this.state.alapAdatok;
    newState.fizhatarido = currentValue;
    this.setState({ alapAdatok: newState });
  }

  handleTeljesitesChange = (e, v) => {
    let currentValue = v === '' ? null : v;
    this.setState({ formTelj: currentValue });
  }

  handleFizhatChange = (e, v) => {
    let currentValue = v === '' ? null : v;
    this.setState({ formFizhat: currentValue });
  }

  handleKelteChange = (e, v) => {
    let currentValue = v === '' ? null : v;
    this.setState({ formKelte: currentValue });
  }

  renderEloSzures = () => {
    return (
      <AvForm onValidSubmit={this.updateTable}>
        <div className="row">
          <div className="col-md-12">
            <h5 style={{ marginBottom: 20 }}>
              Számlák:
            </h5>
          </div>
          <div className="col-md-6 form-group">
            <Label for="szallitoId">
              Számlakibocsátó:
            </Label>
            {/* <Select.Async
              name="szallitoId"
              id="szallitoId"
              placeholder="Kezdjen el gépelni..."
              noResultsText="Nincs keresési eredmény"
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingPlaceholder="Keresés..."
              onChange={v => this.handleChangeSzamlakibocsato(v)}
              clearable
              ignoreAccents={false}
              loadOptions={_.debounce((input, callback) => this.loadSzamlakibocsatoChange(input, callback), 1000)}
              isLoading={false}
              valueKey="id"
              labelKey="nev"
              value={this.state.szamlakibocsatoValue}
              cache={false}
              autoload={false}
            /> */}
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="szallitoId"
              id="szallitoId"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeSzamlakibocsato(v)}
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadSzamlakibocsatoChange, 1000)}
              isLoading={false}
              getOptionLabel={option => option.nev}
              getOptionValue={option => option.id}
              value={this.state.szamlakibocsatoValue}
              // disabled={this.state.fizTeljElerheto}
              cache={false}
              autoload={false}
            />
          </div>
          <div className="col-md-6 form-group">
            <Label for="jogtulajId">
              Fő jogtulajdonos:
            </Label>
            {/* <Select.Async
              name="jogtulajId"
              id="jogtulajId"
              placeholder="Kezdjen el gépelni..."
              noResultsText="Nincs keresési eredmény"
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingPlaceholder="Keresés..."
              onChange={v => this.handleChangeFojogtulajdonos(v)}
              clearable
              ignoreAccents={false}
              loadOptions={_.debounce((input, callback) => this.loadFojogtulajChange(input, callback), 1000)}
              isLoading={false}
              valueKey="id"
              labelKey="nev"
              value={this.state.fojogtulajdonosValue}
              cache={false}
              autoload={false}
            /> */}
            <Select
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="jogtulajId"
              id="jogtulajId"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeFojogtulajdonos(v)}
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadFojogtulajChange, 1000)}
              isLoading={false}
              getOptionLabel={option => option.nev}
              getOptionValue={option => option.id}
              value={this.state.fojogtulajdonosValue}
              // disabled={this.state.fizTeljElerheto}
              cache={false}
              autoload={false}
            />
          </div>
          <div className="col-md-3">
            <AvGroup>
              <Label for="ev">
                Év:
              </Label>
              <AvInput name="ev" id="ev" maxLength="4" placeholder="éééé" />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-3">
            <AvField type="select" name="penznemid" id="penznemid" label="Pénznem: " errorMessage="Kötelező mező!">
              <option value="">Válassz pénznemet...</option>
              {this.state.aktivPenznem}
            </AvField>
          </div>
          <div className="col-md-3">
            <AvField type="select" name="fizmodid" id="fizmodid" label="Fizetési mód: " errorMessage="Kötelező mező!">
              <option value="">Válassz fizetési módot...</option>
              {this.state.aktivFizmod}
            </AvField>
          </div>
          <div className="col-md-3 form-group">
            <AvField
              ref="feladas"
              type="select"
              name="feladas"
              id="feladas"
              onChange={v => this.handleChangeFeladas(v)}
              label="Feladás: "
            >
              <option value="null">Válassz értéket</option>
              <option value="true">Feladott</option>
              <option value="false">Feladható</option>
            </AvField>
          </div>
          <div className="col-md-3 form-group">
            <AvField type="select" name="kiegInf" id="kiegInf" label="Kiegészítő információk: ">
              <option value={false}>Válassz egy információt</option>
              <option value>Visszaérkezett</option>
            </AvField>
          </div>
          <div className="col-md-3">
            <Button className="margin-top-30" color="primary">Keresés</Button>
          </div>
        </div>
      </AvForm>
    );
  }

  renderPuteljList = (lista) => {
    let penzadatok = lista.map((item) => {
      return (
        <li key={item.id}>
          <b>Hely: </b>
          {item.szamlahely && item.szamlahely.caption} | <b>Típus: </b>{item.tipus} | <b>Dátum: </b>{item.datum}
          <br />
          <b>Bankszámla: </b>
          {item.bankszamla}
          <br />
          <b>Bankkivonat sorszáma: </b>
          {item.sorszam}
          <br />
          {item.arfolyam !== '1' && (
            <React.Fragment>
              <b>Árfolyam: </b>
              {'1 ' + item.penznem + ' = ' + item.arfolyam + ' HUF'}
              <br />
            </React.Fragment>
          )}
          <b>Összeg: </b>
          {item.arfolyam === 1 ? (
            NumberFormatter.addSpacesToString(item.osszeg, NumberFormatter.OSSZEG) + ' ' + item.penznem
          ) : (
            (NumberFormatter.addSpacesToString(item.osszeg, NumberFormatter.OSSZEG) + ' ' + item.penznem + ' | ' + NumberFormatter.addSpacesToString(item.osszegHuf, NumberFormatter.OSSZEG) + ' HUF')
          )}
          <br />
          <br />
        </li>
      );
    });
    return penzadatok;
  }

  renderAdatok = () => {
    let azonositok = '';
    this.state.szamlaObj && this.state.szamlaObj.udulesijogok && this.state.szamlaObj.udulesijogok.forEach((udjog) => {
      azonositok += udjog.azonosito + ', ';
    });

    return (this.state.dataViewLoaded ? (
      <div>
        <h6>
          {'Számla információ:'}
        </h6>
        <b>Év: </b>{this.state.szamlaObj && this.state.szamlaObj.ev}
        <br />
        <b>Üdülési jog: </b>{azonositok.length>2 ? azonositok.substring(0,azonositok.length-2): azonositok}
        <br />
        <b>Számlázási cím: </b>{this.state.szamlaObj && this.state.szamlaObj.szamlacim}
        <br />
        <b>Fizetési mód: </b>{this.state.szamlaObj && this.state.szamlaObj.fizetesimod}<br />
        <b>Pénznem: </b>{this.state.szamlaObj && this.state.szamlaObj.penznem}<br />
        <b>Fizetési határidő: </b>{this.state.szamlaObj && this.state.szamlaObj.fizhat}<br />
        <b>Teljesítés: </b>{this.state.szamlaObj && this.state.szamlaObj.teljesites}<br />
        <b>Számlakibocsátó főkönyvi száma: </b>{this.state.szamlaObj && this.state.szamlaObj.szamlaprofilentity.szallitofkviszam}<br />
        <b>Bizonylatszám: </b>{this.state.szamlaObj && this.state.szamlaObj.bizonylatszam}<br />
        <b>Árfolyam: </b>{this.state.szamlaObj && this.state.szamlaObj.arfolyam}<br />
        <b>Befizető azonosító: </b>{this.state.szamlaObj && this.state.szamlaObj.befizetoazonosito}<br />
        <b>KS azonosító: </b>{this.state.szamlaObj && this.state.szamlaObj.szamlaAzon}<br />
        <b>Számla állapota: </b>{this.state.szamlaObj && this.state.szamlaObj.allapot && this.state.szamlaObj.allapot.caption}<br /><br />
        <h6>Számlakibocsátó:</h6>
        <b>Név: </b>{this.state.szamlaObj && this.state.szamlaObj.kibocsatobean && this.state.szamlaObj.kibocsatobean.nev}<br />
        <b>Cím: </b>{this.state.szamlaObj && this.state.szamlaObj.kibocsatobean && this.state.szamlaObj.kibocsatobean.cim }<br />
        <b>Adószám: </b>{this.state.szamlaObj && this.state.szamlaObj.kibocsatobean && NumberFormatter.addHyphensToString(this.state.szamlaObj.kibocsatobean.adoszam)}<br /><br />
        <h6>Számlafizető:</h6>
        <b>Név: </b>{this.state.szamlaObj && this.state.szamlaObj.jogtulajbean && this.state.szamlaObj.jogtulajbean.nev}<br />
        <b>Cím: </b>{this.state.szamlaObj && this.state.szamlaObj.jogtulajbean && this.state.szamlaObj.jogtulajbean.cim }<br />
        <b>Adószám: </b>{this.state.szamlaObj && this.state.szamlaObj.jogtulajbean && NumberFormatter.addHyphensToString(this.state.szamlaObj.jogtulajbean.adoszam)}<br /><br />
        <h6>Pénzügyi értékek:</h6>
        <b>Fenntartási díj (nettó): </b>{this.state.szamlaObj && NumberFormatter.addSpacesToString(this.state.szamlaObj.dijnetto, NumberFormatter.OSSZEG) + ' ' + this.state.szamlaObj.penznem}<br />
        <b>Áfa: </b>{this.state.szamlaObj && this.state.szamlaObj.afa + ' %' }<br />
        <b>Fenntartási díj (bruttó): </b>{this.state.szamlaObj && NumberFormatter.addSpacesToString(this.state.szamlaObj.dijbrutto, NumberFormatter.OSSZEG) + ' ' + this.state.szamlaObj.penznem}<br />
        <b>Rendezett: </b>{this.state.szamlaObj && ( NumberFormatter.addSpacesToString(this.state.szamlaObj.rendezett, NumberFormatter.OSSZEG) + ' ' + this.state.szamlaObj.penznem )}<br />
        <b>Rendezetlen: </b>{this.state.szamlaObj && ( NumberFormatter.addSpacesToString(this.state.szamlaObj.rendezetlen, NumberFormatter.OSSZEG) + ' ' + this.state.szamlaObj.penznem ) }<br />
        <b>Rendezett késedelmi kamat: </b>{this.state.szamlaObj && ( NumberFormatter.addSpacesToString(this.state.szamlaObj.rendezettkkamat, NumberFormatter.OSSZEG) + ' ' + this.state.szamlaObj.penznem ) }<br /><br />
        <h6>Pénzügyi teljesítés adatok:</h6>
        <ul>{this.renderPuteljList(this.state.szamlaObj && this.state.szamlaObj.puteljek)}</ul>
      </div>
    ) : this.renderLoader());
  }

  onSelectAll = (isSelected) => {
    if (isSelected) {
      return this.state.szamlaJson.map(row => row.id);
    }
    return [];
  }

  osszegSzamitas = (szamlaObj) => {
    let osszeg = 0;
    szamlaObj.puteljek.map((item) => osszeg += item.osszeg);
    return osszeg;
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value
    });
  }

  getBruttoOsszeg = () => {
    let bruttoOsszeg = 0;
    this.state.formUdulesijogok.forEach((udjog) => {
      if (typeof udjog.dijbrutto === 'string' || udjog.dijbrutto instanceof String) {
        bruttoOsszeg += Number(udjog.dijbrutto.replace(/ /g, ''));
      } else {
        bruttoOsszeg += udjog.dijbrutto;
      }
    });

    return bruttoOsszeg;
  }

  getNettoOsszeg = () => {
    let nettoOsszeg = 0;
    this.state.formUdulesijogok.forEach((udjog) => {
      if (typeof udjog.dijnetto === 'string' || udjog.dijnetto instanceof String) {
        nettoOsszeg += Number(udjog.dijnetto.replace(/ /g, ''));
      } else {
        nettoOsszeg += udjog.dijnetto;
      }
    });

    return nettoOsszeg;
  }

  handleBruttoDijChange = (e, idx) => {
    let bruttodij = e.target.value.replace(/ /g, '');
    let nettodij = Math.round(bruttodij / (1 + this.state.formAfa / 100));

    console.log(nettodij, bruttodij, idx)

    let newState = JSON.parse(JSON.stringify(this.state.formUdulesijogok));
    newState[idx].dijbrutto = bruttodij;
    newState[idx].dijnetto = nettodij;

    console.log(newState)

    this.setState({ formUdulesijogok: newState });
  }

  handleNettoDijChange = (e, idx) => {
    let nettodij = e.target.value.replace(/ /g, '');
    let bruttodij = Math.round(nettodij * (1 + this.state.formAfa / 100));


    let newState = JSON.parse(JSON.stringify(this.state.formUdulesijogok));
    newState[idx].dijbrutto = bruttodij;
    newState[idx].dijnetto = nettodij;

    console.log(newState)

    this.setState({ formUdulesijogok: newState });
  }

  columnClassNameFormat = (fieldValue, row, rowIdx, colIdx) => {
    return row.fizetesimeghagyasalatt ? 'td-column-szamla-fizmeghagy' : '';
  }

  renderTable = () => {
    const selectRowProp = {
      mode: 'checkbox',
      onSelectAll: this.onSelectAll,
    };

    const options = {
      sizePerPage: 5,
      paginationShowsTotal: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    return this.state.szamlaJson.length ? (
      <BootstrapTable ref={((c) => { this.lista = c; })} data={this.state.szamlaJson} pagination options={options} selectRow={this.state.feladas ? selectRowProp : !selectRowProp} >
        <TableHeaderColumn
          dataField="kibocsatobean_nev"
          filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          tdStyle={{ whiteSpace: 'normal' }}
          columnClassName={this.columnClassNameFormat}
        >
          Számlakibocsátó
        </TableHeaderColumn>
        {/*<TableHeaderColumn
          dataField="szamlaAzon"
          tdStyle={{ whiteSpace: 'normal' }}
          filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          width="110"
          columnClassName={this.columnClassNameFormat}
        >
          KS azonosító
        </TableHeaderColumn> */}
        <TableHeaderColumn
          dataField="jogtulajbean"
          dataFormat={this.szamlaKibocsatoFormatter}
          tdStyle={{ whiteSpace: 'normal' }}
          columnClassName={this.columnClassNameFormat}
        >
          Jogtulajdonos
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="azonositok"
          tdStyle={{ whiteSpace: 'normal' }}
          filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          columnClassName={this.columnClassNameFormat}
        >
          Üdülési jog az.
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="osszegek"
          dataFormat={this.osszegFormatter}
          tdStyle={{ whiteSpace: 'normal', textAlign: 'right' }}
          filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          columnClassName={this.columnClassNameFormat}
        >
          Nettó / Bruttó fenntartási díj össz.
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="datumok"
          tdStyle={{ whiteSpace: 'normal' }}
          columnClassName={this.columnClassNameFormat}
        >
          Esedékesség / Halasztás
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="kedvezmeny"
          tdStyle={{ whiteSpace: 'normal' }}
          columnClassName={this.columnClassNameFormat}
        >
          Kedvezmény
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="kkamatosszegek"
          tdStyle={{ whiteSpace: 'pre-line', textAlign: 'right' }}
          filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          columnClassName={this.columnClassNameFormat}
          dataFormat={this.osszegFormatter}
        >
          <p className="whitespace-normal">Generált / Rendezett késedelmi kamat</p>
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="allapot"
          dataFormat={this.allapotFormatter}
          tdStyle={{ whiteSpace: 'normal' }}
          columnClassName={this.columnClassNameFormat}
        >
          Számla állapot
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="tipus"
          dataFormat={this.tipusFormatter}
          tdStyle={{ whiteSpace: 'normal' }}
          width="110"
          columnClassName={this.columnClassNameFormat}
        >
          Típus
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="bizonylatszam"
          tdStyle={{ whiteSpace: 'normal' }}
          filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}
          columnClassName={this.columnClassNameFormat}
        >
          Bizonylatszám
        </TableHeaderColumn>
        <TableHeaderColumn
          dataField="id"
          isKey
          dataFormat={this.iconFormatter}
          width="120"
        >
          Műveletek
        </TableHeaderColumn>
      </BootstrapTable>) : <DataEmptyState />;
  }

  renderLoader = () => {
    return (
      <div className="showbox">
        <div className="loader">
          <svg className="circular" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" />
          </svg>
        </div>
      </div>
    );
  }

  renderModositoForm = () => {
    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };
    let modositoMezok = [];
    modositoMezok.push(
      <div key="cim" className="col-md-12">
        <div><h5 style={titleStyle}>Általános adatok:</h5></div>
      </div>
    );
    modositoMezok.push(
      <AvGroup key="penznem" className="col-md-4">
        <Label for="penznem">Pénznem: </Label>
        <AvInput
          name="penznemid"
          id="penznemid"
          disabled
          value={this.state.formPenznem}
        />
        <AvFeedback>Kötelező mező!</AvFeedback>
      </AvGroup>
    );
    modositoMezok.push(
      <AvGroup key="afa" className="col-md-4">
        <Label for="afa">Áfa</Label>
        <InputGroup>
          <AvInput
            name="afa"
            id="afa"
            disabled
            value={this.state.formAfa}
          />
          <InputGroupAddon addonType="prepend">
            {'%'}
          </InputGroupAddon>
        </InputGroup>
        <AvFeedback>Kötelező mező!</AvFeedback>
      </AvGroup>
    );
    modositoMezok.push(
      <AvGroup key="arfolyam" className="col-md-4">
        <Label for="arfolyam">Árfolyam: </Label>
        <AvInput
          name="arfolyam"
          id="arfolyam"
          value={this.state.formArfolyam}
          disabled={!this.state.formModosithato}
          onChange={this.handleArfolyamChange}
        />
      </AvGroup>
    );
    modositoMezok.push(
      <div key="fizhatarido" className="col-md-4">
        <Label for="fizhatarido">
        Fizetési határidő:
        </Label>
        <DatePicker
          name="fizhatarido"
          value={this.state.formFizhat && new Date(this.state.formFizhat)}
          onChange={this.handleFizhatChange}
          disabled={!this.state.formModosithato}
        />
      </div>
    );
    modositoMezok.push(
      <div key="szamlakelte" className="col-md-4">
        <Label for="szamlakelte">
          Számla kelte:
        </Label>
        <DatePicker
          name="szamlakelte"
          value={this.state.formKelte && new Date(this.state.formKelte)}
          onChange={this.handleKelteChange}
          disabled={!this.state.formModosithato}
        />
      </div>
    );
    modositoMezok.push(
      <div key="teljesitesdatuma" className="col-md-4">
        <Label for="teljesitesdatuma">
          Teljesítés dátuma:
        </Label>
        <DatePicker
          name="teljesitesdatuma"
          value={this.state.formTelj && new Date(this.state.formTelj)}
          onChange={this.handleTeljesitesChange}
          disabled={!this.state.formModosithato}
        />
      </div>
    );
    modositoMezok.push(
      <div key="udjog" className="col-md-12">
        <div><h5 style={titleStyle}>Üdülési jogok:</h5></div>
      </div>
    );
    if (this.state.formUdulesijogok) {
      this.state.formUdulesijogok.forEach((item, idx) => {
        modositoMezok.push(<h5 key={'nev-' + idx.toString()} className="col-md-12">
          {item.azonosito ? item.azonosito : ' - '}
          :
        </h5>);
        modositoMezok.push(
          <div key={'nettodij1-' + idx.toString()} className="col-md-6">
            <SlaveAvInput
              id={'nettodij-' + idx.toString()}
              key={'nettodij-' + idx.toString()}
              label="Fenntartási díj (nettó): "
              maxLength={11}
              errorMessage="Kötelező szám mező!"
              onChange={e => this.handleNettoDijChange(e, idx)}
              value={item.dijnetto}
              szuroTipus={NumberFormatter.OSSZEG}
              inputGroupAddon={this.state.formPenznem}
              disabled={!this.state.formModosithato}
            />
          </div>
        );
        modositoMezok.push(
          <div key={'bruttodij1-' + idx.toString()} className="col-md-6">
            <SlaveAvInput
              id={'bruttodij-' + idx.toString()}
              key={'bruttodij-' + idx.toString()}
              label="Fenntartási díj (bruttó): "
              maxLength={11}
              onChange={e => this.handleBruttoDijChange(e, idx)}
              value={item.dijbrutto}
              inputGroupAddon={this.state.formPenznem}
              szuroTipus={NumberFormatter.OSSZEG}
              disabled={!this.state.formModosithato}
            />
          </div>
        );
      });
    }
    modositoMezok.push(<h5 key="osszesen" className="col-md-12">Összesen:</h5>);
    modositoMezok.push(
      <div key="nettodij" className="col-md-6">
        <SlaveAvInput
          id="nettodij"
          key="osszesen-netto"
          label="Fenntartási díj (nettó): "
          disabled
          value={this.getNettoOsszeg()}
          inputGroupAddon={this.state.formPenznem}
          szuroTipus={NumberFormatter.OSSZEG}
        />
      </div>
    );
    modositoMezok.push(
      <div key="bruttodij" className="col-md-6">
        <SlaveAvInput
          id="bruttodij"
          key="osszesen-brutto"
          label="Fenntartási díj (bruttó): "
          disabled
          value={this.getBruttoOsszeg()}
          inputGroupAddon={this.state.formPenznem}
          szuroTipus={NumberFormatter.OSSZEG}
        />
      </div>
    );
    return modositoMezok;
  }

  render() {
    return (
      <div>
        {this.renderEloSzures()}
        <div className="row">
          <div className="col-md-12">
            {this.state.showNote && (
              <div style={{ textAlign: 'right' }}>
                <h6>
                  {'Fizetési meghagyás alatt: '}
                  <i className="fa fa-square" aria-hidden="true" style={{ color: '#ff5050' }} />
                </h6>
              </div>
            )}
          </div>
          <div className="col-md-12" style={{ marginTop: 8 }}>
            { this.state.queryStarted ? this.renderLoader() : this.state.dataLoaded ? this.renderTable() : <QueryEmptyState /> }
          </div>
          { this.state.szamlaJson.length && this.state.feladas && this.state.feladas == 'false' ?
            <div className=" offset-md-10 col-md-2 margin-top-30">
              <Button type="button" onClick={() => this.updateSzamlaFeladas()} color="primary" className="btn-cursor-pointer  pull-right" >Generálás</Button>
            </div>
        : null }
          { this.state.szamlaJson.length && this.state.feladas && this.state.feladas == 'true' ?
            <div className=" offset-md-10 col-md-2 margin-top-30">
              <Button type="button" onClick={() => this.updateSzamlaVisszavonas()} color="primary" className="btn-cursor-pointer  pull-right" >Visszavonás</Button>
            </div>
            : null }
        </div>
        <Modal isOpen={this.state.modalMegtekintes} toggle={this.toggleMegtekintes} className={this.props.className} backdrop="static">
          <ModalHeader>Megtekintés</ModalHeader>
          <ModalBody>{this.state.modalMegtekintes ? this.renderAdatok(this._currentViewId) : <div />}</ModalBody>
          <ModalFooter> <Button type="button" color="primary" onClick={this.toggleMegtekintes} className="btn-cursor-pointer" >Vissza</Button></ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalKiegeszito} toggle={this.toggleKiegeszito} style={{ width: '300px' }} className={this.props.className} backdrop="static">
          <ModalHeader>Kiegészítő információk</ModalHeader>
          <ModalBody>
            {this.state.viewLoading ? <p>Betöltés...</p> : (
              <AvForm ref="visszakuldes">
                <div className="row">
                  <div className="col-md-12">
                    <Label for="VisszaKuldDatum">Visszaküldés dátuma:</Label>
                    <DatePicker
                      name="kezdete"
                      placeholder="éééé-hh-nn"
                      required
                      type="select"
                      value={this.state.visszakuldesDatuma && new Date(this.state.visszakuldesDatuma)}
                      onChange={this.handleDateChange}
                      disabled={this.state.visszaKuldesEnable}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 form-group">
                    <AvField
                      type="select"
                      name="feladas"
                      errorMessage="Kötelező mező!"
                      id="feladas"
                      label="Visszaérkezés oka: "
                      disabled={this.state.visszaKuldesEnable}
                      value={this.state.visszakuldesOka}
                    >
                      <option value="">
                        {'Válassz értéket...'}
                      </option>
                      {this.state.visszaKuldesOkai}
                    </AvField>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <AvGroup>
                      <Label check for="fizetesimeghagyasalatt">
                        <AvInput type="checkbox" name="fizetesimeghagyasalatt" id="fizetesimeghagyasalatt" value={this.state.fizetesimeghagyasalatt} disabled={this.state.visszaKuldesEnable} onChange={this.handleInputChange} />
                        {' Fizetési meghagyásra átadott'}
                      </Label>
                    </AvGroup>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 form-group">
                    <AvField
                      type="select"
                      name="kedvezmenyTipus"
                      errorMessage="Kötelező mező!"
                      id="kedvezmenyTipus"
                      label="Kedvezmény típusa: "
                      value={this.state.kedvezmenyTipus}
                    >
                      <option value="">
                        {'Válassz értéket...'}
                      </option>
                      {this.state.kedvezmenyTipusai}
                    </AvField>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <Label for="VisszaKuldDatum">Halasztott esedékesség:</Label>
                    <DatePicker
                      name="halasztott"
                      placeholder="éééé-hh-nn"
                      required
                      type="select"
                      value={this.state.halasztasdatum && new Date(this.state.halasztasdatum)}
                      onChange={this.handleHalasztasDateChange}
                      disabled={this.state.visszaKuldesEnable}
                    />
                  </div>
                </div>

                <div className="row">
                  <AvGroup className="col-md-12">
                    <Label for="formKkamat">Késedelmi kamat mértéke: </Label>
                    <InputGroup>
                      <AvInput
                        name="formKkamat"
                        id="formKkamat"
                        type="number"
                        disabled
                        value={this.state.formKkamat}
                        onChange={this.handleInputChange}
                      />
                      <InputGroupAddon addonType="prepend">
                        {'%'}
                      </InputGroupAddon>
                    </InputGroup>
                    <AvFeedback>Kötelező szám mező!</AvFeedback>
                  </AvGroup>
                </div>

                <div className="row col-md-12">
                  <SlaveAvInput
                    id="formGenKkamat"
                    label="Generált késedelmi kamat: "
                    disabled
                    value={this.state.formGenKkamat}
                    onChange={this.handleInputChange}
                    szuroTipus={NumberFormatter.OSSZEG}
                    inputGroupAddon={this.state.formPenznem}
                  />
                </div>

                <div className="row col-md-12">
                  <SlaveAvInput
                    id="formRendezettkamat"
                    label="Rendezett késedelmi kamat: "
                    disabled
                    value={this.state.formRendezettkamat}
                    onChange={this.handleInputChange}
                    szuroTipus={NumberFormatter.OSSZEG}
                    inputGroupAddon={this.state.formPenznem}
                  />
                </div>

              </AvForm>
            )}
          </ModalBody>
          <ModalFooter>
            {<Button color="primary" disabled={this.state.mentesButtonLoading} onClick={() => this.updatevisszakuldes(this._currentViewId)}>Mentés</Button>}
            <Button type="button" color="secondary" onClick={this.toggleKiegeszito} className="btn-cursor-pointer">Mégsem</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalUdulesijog} toggle={this.toggleUdulesijog} className={this.props.className} backdrop="static" size="lg">
          <ModalHeader>
            {'Üdülési jogok'}
          </ModalHeader>
          <ModalBody>
            <BootstrapTable data={this.state.udulesijogJson} pagination>
              <TableHeaderColumn isKey dataField="azonosito" tdStyle={{ whiteSpace: 'normal' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Azonosító</TableHeaderColumn>
              <TableHeaderColumn dataField="dijnetto" dataFormat={this.dijFormatter} tdStyle={{ whiteSpace: 'normal', textAlign: 'right' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Nettó díj</TableHeaderColumn>
              <TableHeaderColumn dataField="dijbrutto" dataFormat={this.dijFormatter} tdStyle={{ whiteSpace: 'normal', textAlign: 'right' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Bruttó díj</TableHeaderColumn>
              <TableHeaderColumn dataField="reszlet" dataFormat={this.reszletFormatter} tdStyle={{ whiteSpace: 'normal', textAlign: 'center' }} filter={{ type: 'TextFilter', delay: 400, placeholder: 'Keresés...' }}>Részletfizetés</TableHeaderColumn>
            </BootstrapTable>
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" onClick={this.toggleUdulesijog} className="btn-cursor-pointer">
              {'Vissza'}
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalModositas} toggle={this.toggleModositas} className={this.props.className} backdrop="static" size="lg">
          <AvForm onValidSubmit={this.toggleModositas}>
            <ModalHeader>
              Módosítás
            </ModalHeader>
            <ModalBody>
              { !this.state.dataModifyLoading ? (
                <div className="row">
                  {this.renderModositoForm()}
                </div>
              )
                : this.renderLoader()}
            </ModalBody>
            <ModalFooter>
              {this.state.formModosithato && <Button type="button" color="primary" onClick={() => { this.edit(); }}>Mentés</Button>}
              {' '}
              <Button type="button" color="secondary" onClick={this.toggleModositas}>{this.state.formModosithato ? 'Mégsem' : 'Bezárás'}</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </div>
    );
  }
}

SzamlaLista.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool
};

export default SzamlaLista;
