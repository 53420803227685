import { ValueJson } from "@organw/wysiwyg-editor";

export function defaultValue() {
  return ValueJson.fromJSON({
    document: {
      nodes: [
        {
          object: "block",
          type: "align-left",
          data: {
            style: { fontSize: "17px" }
          },
          nodes: [
            {
              object: "text",
              text: ""
            }
          ]
        }
      ]
    }
  });
}
