import React, { Component } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  ListGroup,
  ListGroupItem,
  Badge
} from 'reactstrap';
import PropTypes from 'prop-types';
import NetworkEmptyState from './networkemptystate';
import Loader from './loader';
import NumberFormatter from './NumberFormatter';

const propTypes = {
  modalTitle: PropTypes.string,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
  buttonColor: PropTypes.string,
  raktarak: PropTypes.array,
  handleListItemClick: PropTypes.func.isRequired,
  getData: PropTypes.func.isRequired,
  formKeszletCikkId: PropTypes.number,
  formKeszletCikkszam: PropTypes.string,
  formKeszletCikknev: PropTypes.string,
  formKeszletRaktarId: PropTypes.object,
  loadingStatus: PropTypes.string,
};

const defaultProps = {
  buttonText: 'Készletinformáció',
  modalTitle: 'Készletinformáció',
  buttonColor: 'success',
  raktarak: [],
  disabled: false,
};

/**
 * Készletinformáció megtekintő / raktár választó modal
 *
 * @version 1.0.0
 * @author [Nagy Gergely](https://github.com/gergely-nagy)
 */
class KeszletInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      currentSzabad: null,
      currentRaktarID: null,
      currentRaktarNev: null,
    };
  }

  toggleModal = () => {
    this.setState(prevState => ({ modalOpen: !prevState.modalOpen }));
  }

  renderRaktarak = () => {
    return this.props.raktarak.map((item) => {
      return (
        <ListGroupItem
          style={{ cursor: 'pointer' }}
          color="#ede"
          active={item.raktar.selected}
          key={item.id}
          onClick={() => {
            this.props.handleListItemClick(item.raktar.id, item.raktar.nev);
            this.setState({
              currentSzabad: item.szabadMennyiseg,
              currentRaktarID: item.raktar.id,
              currentRaktarNev: item.raktar.nev,
            });
          }}
        >
          {item.raktar.nev + ' ( ' + item.raktar.azonosito + ' )'}
          <Badge color="teljes" pill style={{ marginLeft: 5 }}>
            {NumberFormatter.addSpacesToString(item.teljesMennyiseg, NumberFormatter.OSSZEG)}
          </Badge>
          <Badge color="foglalt" pill style={{ marginLeft: 5 }}>
            {NumberFormatter.addSpacesToString(item.foglaltMennyiseg, NumberFormatter.OSSZEG)}
          </Badge>
          {/* <Badge pill color="varhato" style={{ marginLeft: 5 }}>
                3
          </Badge> */}
          <Badge color="szabad" pill style={{ marginLeft: 5 }}>
            {NumberFormatter.addSpacesToString(item.szabadMennyiseg, NumberFormatter.OSSZEG)}
          </Badge>
        </ListGroupItem>
      );
    });
  }

  emptyOrNull = (data) => {
    if (!data || data === '') return null;
    return data;
  }

  renderKeszletInfoLogic = () => {
    return (
      <React.Fragment>
        <FormGroup>
          <Label for="formKeszletCikkszam">Cikkszám:</Label>
          <Input
            name="formKeszletCikkszam"
            id="formKeszletCikkszam"
            value={this.props.formKeszletCikkszam}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="formKeszletCikknev">Cikknév:</Label>
          <Input
            name="formKeszletCikknev"
            id="formKeszletCikknev"
            value={this.props.formKeszletCikknev}
            disabled
          />
        </FormGroup>
        <ListGroup>
          {this.renderRaktarak()}
        </ListGroup>
        <div>
          <Badge color="teljes" pill style={{ marginLeft: 5 }}>
            {'Teljes készlet'}
          </Badge>
          <Badge color="foglalt" pill style={{ marginLeft: 5 }}>
            {'Foglalt készlet'}
          </Badge>
          {/* <Badge pill color="varhato" style={{ marginLeft: 5 }}>
                3
          </Badge> */}
          <Badge color="szabad" pill style={{ marginLeft: 5 }}>
            {'Szabad készlet'}
          </Badge>
        </div>
      </React.Fragment>
    );
  }

  render() {
    return (
      <React.Fragment>
        <Button
          onClick={() => {
            this.setState({ modalOpen: true }, () => {
              this.props.getData();
            });
          }}
          disabled={this.props.disabledButton}
          color={this.props.buttonColor}
          disabled={this.props.disabled}
        >
          {this.props.buttonText}
        </Button>
        {/* <Badge color="szabad" pill style={{ marginLeft: 5 }}>
          {this.props.selectedSzabad ? this.props.selectedSzabad + ' szabad mennyiség' : 'Nincs kiválasztott raktár!'}
        </Badge> */}
        <Modal
          isOpen={this.state.modalOpen}
          toggle={this.toggleModal}
          // className={this.props.className}
          backdrop="static"
          // size="lg"
        >
          <ModalHeader>{this.props.modalTitle}</ModalHeader>
          <ModalBody>
            {{
              LOAD: (<Loader />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.props.getData()}
              />),
              DATA: (this.renderKeszletInfoLogic())
            }[this.props.loadingStatus]}
          </ModalBody>
          <ModalFooter>
            {this.emptyOrNull(this.state.currentRaktarID) && (
              <Button className="btn-cursor-pointer" type="button" color="primary" onClick={() => {
                this.toggleModal();
                if (this.props.onSave) this.props.onSave(this.state.currentRaktarID, this.state.currentSzabad, this.state.currentRaktarNev);
              }}
              >
                {'Mentés'}
              </Button>
            )}
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.toggleModal}>Bezárás</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

KeszletInfo.propTypes = propTypes;
KeszletInfo.defaultProps = defaultProps;

export default KeszletInfo;
