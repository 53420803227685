import React from 'react';

/**
 * Loader.
 *
 * @version 1.0.0
 * @author [Nagy Gergely](https://github.com/gergely-nagy)
 */
const Loader = (props) => {
  return (
    <div className="showbox" style={props.style}>
      <div className="loader">
        <svg className="circular" viewBox="25 25 50 50">
          <circle className="path" cx="50" cy="50" r="20" fill="none" />
        </svg>
      </div>
    </div>
  );
};

export default Loader;
