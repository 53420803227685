import Lib from '../../../commons/lib';

export default class Service {

  static apartmanUrl = window.location.origin + '/api/apartman/';

  static udulesijogUrl = window.location.origin + '/api/udulesijog/';

  static partnerUrl = window.location.origin + '/api/partner/';

  // APARTMAN START

  static listAktivEpuletekById(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'epulet/listudulesijogepuletek',
      args: { requestType: 'udulesijog-listudulesijogepuletek' },
      params: { id: id }
    }], fnDone);
  }

  static listAktivSzallodak(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.apartmanUrl + 'szalloda/listudulesijogszallodak',
      args: { requestType: 'udulesijog-listudulesijogszallodak' }
    }], fnDone);
  }

  // APARTMAN END

  // UDULESIJOG START

  static exportUdulesijogXLSX(csakAktiv, statusz, szallodaid, jogtulajid, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'udulesijog/xlsx',
      acceptType: 'application/json, application/octet-stream',
      params: {
        csakAktiv: csakAktiv,
        statusz: statusz,
        szallodaid: szallodaid,
        jogtulajid: jogtulajid
      }
    }], fnDone);
  }

  static getEnum(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.udulesijogUrl + 'udulesijog/enum',
      args: { requestType: 'udulesijog-enum' },
    }], fnDone);
  }

  // UDULESIJOG END

  // PARTNER START

  static listUdulesijogTulaj(type, text, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'partner-search' },
      params: {
        type: type,
        text: text
      }
    }], fnDone);
  }

  static listSzallitoVevo(type, text, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'partnerUrl-search' },
      params: {
        type: type,
        text: text
      }
    }], fnDone);
  }

  // PARTNER END

}
