import React from 'react';
import { Label, Button } from 'reactstrap';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Select from 'react-select';
import SelectAsync from 'react-select/lib/Async';
import makeAnimated from 'react-select/lib/animated';
import SwichButton from '../../commons/SwitchButton';

const EllenorzesiListaRow = (props) => {
  return (
    <div
      className="row"
      style={{
        padding: 20,
        margin: 6,
        borderStyle: 'dashed',
        borderColor: '#cfd8dc'
      }}
    >
      <div className="col-md-4">
        <SwichButton
          active={props.aktiv}
          trueLabel="Cikk"
          trueToggle={() => props.toggleEllenorzesiListaType(true, props.idx)}
          trueWidth={60}
          falseToggle={() => props.toggleEllenorzesiListaType(false, props.idx)}
          falseLabel="Karbantartott cikk"
          falseWidth={140}
          style={{ marginTop: 29 }}
        />
      </div>
      <div className="col-md-3">
        <Label for={'formCikkSearch-' + props.idx}>Cikk keresése:</Label>
        <SelectAsync
          styles={{
            control: base => ({
              ...base,
              '&:focus': { borderColor: 'red' }, // border style on hover
              border: '1px solid lightgray', // default border color
              boxShadow: 'none', // no box-shadow
              borderRadius: '0'
            }),
          }}
          name={'formCikkSearch-' + props.idx}
          id={'formCikkSearch-' + props.idx}
          placeholder="Kezdjen el gépelni..."
          noOptionsMessage={() => 'Nincs keresési eredmény'}
          loadingMessage={() => 'Keresés...'}
          onChange={v => props.handleCikkChange(v, props.idx)}
          isClearable
          ignoreAccents={false}
          loadOptions={props.aktiv ? _.debounce(props.loadCikkOptions, 1000) : _.debounce(props.loadKarbantartottCikkOptions, 1000)}
          isLoading={false}
          getOptionValue={props.aktiv ? option => option.cid : option => option.kid}
          getOptionLabel={option => option.n}
          value={props.cikkValue}
          cache={false}
          autoload={false}
        />
      </div>
      <div className="col-md-3">
        <Label for={'formEllListaSearch-' + props.idx}>Ellenőrzési lista: </Label>
        <Select
          styles={{
            control: base => ({
              ...base,
              '&:focus': { borderColor: 'red' }, // border style on hover
              border: '1px solid lightgray', // default border color
              boxShadow: 'none', // no box-shadow
              borderRadius: '0'
            }),
          }}
          components={makeAnimated()}
          placeholder="Kezdjen el gépelni..."
          noOptionsMessage={() => 'Nincs keresési eredmény'}
          loadingMessage={() => 'Keresés...'}
          isMulti
          ignoreAccents={false}
          onChange={v => props.handleListaChange(v, props.idx)}
          getOptionLabel={option => option.nev}
          getOptionValue={option => option.id}
          value={props.listaValue}
          name={'formEllListaSearch-' + props.idx}
          id={'formEllListaSearch-' + props.idx}
          isDisabled={props.listaOptions.length === 0}
          options={props.listaOptions}
        />
      </div>
      <div className="col-md-2">
        <Button
          type="button"
          className="fa fa-trash-o"
          style={{ marginTop: 29 }}
          color="danger"
          onClick={props.removeEllenorzesiLista}
        />
      </div>
    </div>
  );
};

EllenorzesiListaRow.propTypes = {
  aktiv: PropTypes.bool,
  idx: PropTypes.number,
  cikkValue: PropTypes.oneOf(PropTypes.object, PropTypes.string),
  mezonev: PropTypes.string,
  tartalom: PropTypes.string,
  updateMetaAdat: PropTypes.func,
  loadCikkOptions: PropTypes.func,
  loadKarbantartottCikkOptions: PropTypes.func,
  handleListaChange: PropTypes.func,
  handleCikkChange: PropTypes.func,
  listaValue: PropTypes.array,
  listaOptions: PropTypes.array,
  toggleEllenorzesiListaType: PropTypes.func,
  removeEllenorzesiLista: PropTypes.func
};

export default EllenorzesiListaRow;
