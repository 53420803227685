import React, { Component } from 'react';
import { Button, Label } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput,} from 'availity-reactstrap-validation';
import Services from './services';
import Lib from '../../../commons/lib';

class RciProfilContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rciType: null,
      rciFields: []
    };
  }

  onSubmit = () => {
    const {
      rciType,
      rciFields
    } = this.state;
    Services.editRciKotelezoAdatok(rciFields, { rciTipus: rciType }, (err) => {
      if (!err) {
        this.props.globals.addSuccess('RCI profil rögzítve!');
      }
    });
  }

  handleTipusChange = (v) => {
    this.setState({ rciType: v }, () => {
      Services.listRciKotelezoAdatok({ rciTipus: v }, (err, res) => {
        if (!err) {
          this.setState({
            rciFields: res[0]
          });
        }
      });
    });
  }

  handleErtekChange = (v, idx) => {
    let {
      rciFields
    } = this.state;
    if (v) {
      rciFields.splice(idx, 1, v);
    }
    this.setState({ rciFields });
  }

  render() {
    const {
      rciFields
    } = this.state;

    let hasKarbRole = Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'UDULESIJOG_RCIPROFIL', 'KARB');

    return (
      <div className="row">
        <div className="col-md-3">
          <AvForm onValidSubmit={this.onSubmit}>
            <AvField
              type="select"
              name="rciTipus"
              label="RCI lista típusa"
              errorMessage="Kötelező mező!"
              onChange={(e, v) => this.handleTipusChange(v)}
              value={this.state.rciType}
              required
            >
              <option value="">
                Válassz értéket...
              </option>
              <option value="IC1">
                IC1
              </option>
              <option value="AC1">
                AC1
              </option>
            </AvField>
            { rciFields.map((rci, idx) => (
              <div className="col-md-12">
                <AvGroup>
                  <Label for={rci.azonosito}>{rci.felirat + ':'}</Label>
                  <AvInput
                    name={rci.azonosito}
                    id={rci.azonosito}
                    type="text"
                    value={rci.ertek}
                    onChange={(e, v) => this.handleErtekChange({ ertek: v, azonosito: rci.azonosito, felirat: rci.felirat }, idx)}
                    disabled={!hasKarbRole}
                  />
                </AvGroup>
              </div>
            ))}
            {hasKarbRole && <Button color="primary">Mentés</Button>}
          </AvForm>
        </div>
      </div>
    );
  }
}

export default RciProfilContent;
