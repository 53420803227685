import React, { Component } from 'react';
import { AvForm } from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {
  selectFilter,
  textFilter,
} from 'react-bootstrap-table2-filter';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import Row from 'reactstrap/lib/Row';
import Col from 'reactstrap/lib/Col';
import { browserHistory } from 'react-router';
import paginationFactory from 'react-bootstrap-table2-paginator';
import PropTypes from 'prop-types';
import Services from './services';
import Loader from '../../../commons/loader';
import DataEmptyState from '../../../commons/dataemptystate';
import NetworkEmptyState from '../../../commons/networkemptystate';
import { PaginationTotal } from '../../../commons/controls';
import Lib from '../../../commons/lib';

class FelhasznalokContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // ['LOAD', 'DATA', 'NETW', 'TABL']
      tableStatus: 'TABL',
      users: [],
    };
  }

  componentDidMount() {
    this.getUserList();
  }

  toggleDeleteModal = () => {
    this.setState((prevState) => { return { modalDelete: !prevState.modalDelete }; });
  }

  getUserList = () => {
    this.setState({ tableStatus: 'LOAD' });
    Services.getUserList((err, res) => {
      if (!err) {
        let array = res[0].sort((a, b) => a.username.localeCompare(b.username));
        this.setState({
          users: array,
          tableStatus: res[0].length > 0 ? 'TABL' : 'DATA',
        });
      } else {
        this.setState({ tableStatus: 'NETW' });
      }
    });
  };

  deleteUser = () => {
    Services.deleteUser(this._id, (err) => {
      if (!err) {
        this.props.globals.addSuccess('Felhasználó törölve!');
        this.getUserList();
        this.toggleDeleteModal();
      }
    });
  }

  handleAddFelhasznalo = () => {
    browserHistory.push(''.concat('/profil/felhasznalok/felhasznalo/felvetel'));
  };

  handleEditClick = (id) => {
    browserHistory.push(''.concat('/profil/felhasznalok/felhasznalo/modositas?id=' + id));
  }

  handleDeleteClick = (id) => {
    this._id = id;
    this.toggleDeleteModal();
  }

  tableIconFormatter = (cell) => {
    return (
      <>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'CONFIG_USER', 'KARB') && (
          <React.Fragment>
            <Button
              className="icon-pencil table-action-icon"
              id={'action-view-' + cell + 'modositas'}
              onClick={() => this.handleEditClick(cell)}
              type="button"
            />
            <UncontrolledTooltip
              style={{ float: 'left' }}
              placement="top"
              target={'action-view-' + cell + 'modositas'}
              delay={50}
            >
              {'Módosítás'}
            </UncontrolledTooltip>
            <Button
              className="icon-trash table-action-icon"
              id={'action-view-' + cell + 'file'}
              onClick={() => this.handleDeleteClick(cell)}
              type="button"
            />
            <UncontrolledTooltip
              style={{ float: 'left' }}
              placement="top"
              target={'action-view-' + cell + 'file'}
              delay={50}
            >
              {'Törlés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </>
    );
  };

  tableStatusFormatter = (cell) => {
    return cell === true ? (
      <span className="badge badge-success">Aktív</span>
    ) : (
      <span className="badge badge-default">Inaktív</span>
    );
  };

  renderUserTable = () => {
    const selectOptions = {
      true: 'Aktív',
      false: 'Inaktív',
    };

    const options = {
      sizePerPage: 25,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '25', value: 25 },
        { text: '50', value: 50 },
        { text: '100', value: 100 },
      ],
    };

    const { users } = this.state;
    const columns = [
      {
        dataField: 'username',
        text: 'Felhasználónév',
        filter: textFilter({
          delay: 400,
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
        },
        sort: true
      },
      {
        dataField: 'szemelynev',
        text: 'Felhasználó neve',
        filter: textFilter({
          delay: 400,
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
        },
        sort: true
      },
      {
        dataField: 'email',
        text: 'Email cím',
        filter: textFilter({
          delay: 400,
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
        },
        sort: true
      },
      {
        dataField: 'enabled',
        text: 'Állapot',
        filter: selectFilter({
          options: selectOptions,
          placeholder: 'Összes',
        }),
        style: {
          whiteSpace: 'pre-line',
          textAlign: 'center',
        },
        formatter: this.tableStatusFormatter,
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        formatter: this.tableIconFormatter,
      },
    ];
    return (
      <BootstrapTable
        bootstrap4
        keyField="id"
        data={users}
        columns={columns}
        filter={filterFactory()}
        pagination={paginationFactory(options)}
      />
    );
  };

  renderButtons = () => {
    return (
      <Row className="mb-1">
        <Col>
          <Button
            className="mr-1"
            type="button"
            color="primary"
            onClick={this.handleAddFelhasznalo}
          >
            Felhasználó rögzítése
          </Button>
        </Col>
      </Row>
    );
  };

  render() {
    return (
      <div className="row">
        <div className="col-md-12">
          {this.renderButtons()}
          {
            {
              LOAD: <Loader />,
              DATA: (
                <DataEmptyState hasImage subtitle="Vigyél fel új tételt!" />
              ),
              NETW: (
                <NetworkEmptyState
                  hasImage
                  submitClick={() => {
                    this.getUserList();
                  }}
                />
              ),
              TABL: this.renderUserTable(),
            }[this.state.tableStatus]
          }
          <Modal
            isOpen={this.state.modalDelete}
            toggle={this.toggleDeleteModal}
            backdrop="static"
          >
            <ModalHeader>Felhasználó törlése</ModalHeader>
            <ModalBody>Biztosan törölni szeretné a felhasználót?</ModalBody>
            <ModalFooter>
              <Button type="button" color="primary" onClick={this.deleteUser}>Igen</Button>
              <Button type="button" color="secondary" onClick={this.toggleDeleteModal}>Mégsem</Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

FelhasznalokContent.propTypes = {
  globals: PropTypes.object
};

export default FelhasznalokContent;
