import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledTooltip,
} from 'reactstrap';
import {
  AvFeedback,
  AvField,
  AvForm,
  AvGroup,
  AvInput
} from 'availity-reactstrap-validation';
import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import _ from 'lodash';
import Select from 'react-select';
import Async from 'react-select/lib/Async';
import Moment from 'moment';
import MomentTimezone from 'moment-timezone';
import momentLocalizer from 'react-widgets-moment';
import { DateTimePicker } from 'react-widgets';
import Lib from '../../commons/lib';
import DataEmptyState from '../../commons/dataemptystate';
import NetworkEmptyState from '../../commons/networkemptystate';
import Loader from '../../commons/loader';
import Services from './services';
import { PaginationTotal } from '../../commons/controls';
import { ProgressButton } from '../../commons/ProgressButton';
import SwitchButton from '../../commons/SwitchButton';
import CustomTooltip from '../../components/Tooltip/CustomTooltip';
import getTooltip from './KarbantartasTooltips';

momentLocalizer(Moment);

class KarbantartasContent extends Component {
  constructor(props) {
    super(props);


    this.state = {
      // ['LOAD', 'DATA', 'NETW', 'TABL']
      tableStatus: 'LOAD',

      // ['LOAD', 'DATA', 'NETW']
      formStatusFej: 'LOAD',
      formStatusTetel: 'LOAD',
      formStatusUtemezes: 'LOAD',

      // ['FEL', 'MOD', 'TOR', 'MEG']
      formTypeFej: 'FEL',
      formTypeTetel: 'FEL',

      // FORM VALUES
      formFejId: null,
      formFejPartner: '',
      formFejAzonosito: '',
      formFejKezdete: null,
      formFejVege: null,
      formFejMegjegyzes: '',

      formTetelId: null,
      formTetelCikk: '',
      formTetelAzonosito: '',
      formTetelKapcsolodoAzonosito: '',
      formTetelKezdete: null,
      formTetelVege: null,
      formTetelExistsFeladatlista: '',
      formTetelGenMenny: '',
      formTetelLeiras: '',
      formTetelPublikusLeiras: '',
      disableMezok: false,

      formTetelOrszag: '',
      formTetelKereses: '',
      kozterulet: '',
      kozteruletjelleg: '',
      hazszam: '',
      hrsz: '',
      lepcsohaz: '',
      epulet: '',
      emelet: '',
      ajto: '',
      postafiok: '',
      cimSwitchActive: true,
      formTetelIranyitoszamNev: '',
      formTetelTelepulesNev: '',

      utemezesek: [],
      defaultmennyEgysOptionValue: '',
      mennyEgysOptions: [],
      defaultEllListaOptions: [],
      utemezesekOptions: [],

      // UTILS
      baseJson: [],
      expandedItems: [],
      viewObjFej: null,
      viewObjTetel: null,
      modalViewFej: false,
      modalViewTetel: false,
      modalStateFej: false,
      modalStateTetel: false,
      modalStateUtemezes: false,
      modalTorlesFej: false,
      modalTorlesTetel: false,
      torlesId: null,
      fejId: null,
      saveButtonLoading: false,
    };

    this.tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      if (this.props.active) {
        this.getKarbantartasListData();
      }
    }
  }

  toggleViewModalFej = () => {
    this.setState(prevState => ({ modalViewFej: !prevState.modalViewFej }));
  }

  toggleViewModalTetel = () => {
    this.setState(prevState => ({ modalViewTetel: !prevState.modalViewTetel }));
  }

  toggleModalFej = () => {
    this.setState(prevState => ({ modalStateFej: !prevState.modalStateFej }));
  }

  toggleModalTetel = () => {
    this.setState(prevState => ({ modalStateTetel: !prevState.modalStateTetel }));
  }

  toggleModalUtemezes = () => {
    this.setState(prevState => ({ modalStateUtemezes: !prevState.modalStateUtemezes }));
  }

  toggleTorlesFej = () => {
    this.setState(prevState => ({ modalTorlesFej: !prevState.modalTorlesFej }));
  }

  toggleTorlesTetel = () => {
    this.setState(prevState => ({ modalTorlesTetel: !prevState.modalTorlesTetel }));
  }

  onRowSelect = (row) => {
    this.handleExpandTetelek(row.id);
  }

  deleteKarbantartasFej = () => {
    Services.deleteKarbantartasFej(this.state.torlesId, (err) => {
      if (!err) {
        this.toggleTorlesFej();
        this.getKarbantartasListData();
        this.props.globals.addSuccess('Karbantartás törölve!');
      }
    });
  }

  deleteKarbantartasTetel = () => {
    Services.deleteKarbantartasTetel(this.state.torlesId, (err) => {
      if (!err) {
        this.toggleTorlesTetel();
        this.getKarbantartasListTetelData(this.state.fejId);
        this.props.globals.addSuccess('Karbantartás tétel törölve!');
      }
    });
  }

  loadCikkChange = (input, callback) => {
    if (input.length !== 0) {
      Services.searchCikk(input, false, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            let newItem = item;
            newItem.nev = newItem.cikknev + ' (' + newItem.cikkszam + ') ';
            newArray.push(newItem);
          });
          callback(newArray);
        }
      });
    }
  }

  handleChangeCikk = (value) => {
    if (value && value !== '') {
      this.setState({
        formTetelCikk: value,
      });
    } else {
      this.setState({
        formTetelCikk: '',
      });
    }
  }

  loadPartnerChange = (input, callback) => {
    if (input.length !== 0) {
      Services.searchPartner(input, (err, res) => {
        if (!err) {
          let newArray = [];
          res[0].forEach((item) => {
            let newItem = item;
            newItem.nev = newItem.nev + ' (' + newItem.azon + ') ';
            newArray.push(newItem);
          });
          callback(newArray);
        }
      });
    }
  }

  handleChangePartner = (value) => {
    if (value && value !== '') {
      this.setState({
        formFejPartner: value,
      });
    } else {
      this.setState({
        formFejPartner: '',
      });
    }
  }

  loadOrszagOptions = (input, callback) => {
    if (input.length !== 0) {
      Services.listOrszag(input, (err, res) => {
        if (!err) {
          callback(res[0]);
        }
      });
    }
  }

  handleChangeOrszagInput = (value) => {
    if (value && value !== '') {
      this.setState({
        formTetelOrszag: value,
        formTetelKereses: '',
        formTetelIranyitoszamNev: '',
        formTetelTelepulesNev: ''
      });
    } else {
      this.setState({
        formTetelOrszag: '',
        formTetelKereses: '',
        formTetelIranyitoszamNev: '',
        formTetelTelepulesNev: ''
      });
    }
  }

  loadKeresesOptions = (input, callback) => {
    if (input.length !== 0) {
      Services.listCim(input, (err, res) => {
        if (!err) {
          let options = [];
          res[0].forEach((item) => {
            item.telepuirszek.forEach((iranyitoszam) => {
              options.push({
                telepulesnev: item.telepulesnev + ' (' + iranyitoszam.iranyitoszam + ')',
                telepulesid: item.telepulesid,
                iranyitoszam: iranyitoszam.iranyitoszam,
                irszid: iranyitoszam.telepuirszid,
                nev: item.telepulesnev,
                // irsz:
              });
            });
          });
          callback(options);
        }
      });
    }
  }

  handleChangeKeresesInput = (value) => {
    if (value && value !== '') {
      this.setState({
        formTetelKereses: value,
        formTetelTelepulesNev: value.nev,
        formTetelIranyitoszamNev: value.iranyitoszam
      });
    } else {
      this.setState({
        formTetelKereses: '',
        formTetelTelepulesNev: '',
        formTetelIranyitoszamNev: ''
      });
    }
  }

  getKarbantartasListData = () => {
    this.setState({
      tableStatus: 'LOAD',
    });
    Promise.all([
      this.getKarbantartasList(),
    ])
      .then(() => {
        this.setState({
          tableStatus: 'TABL'
        });
      })
      .catch(() => {
        this.setState({
          tableStatus: 'NETW'
        });
      });
  }

  getKarbantartasList = () => {
    return new Promise((resolve, reject) => {
      Services.getKarbantartasFejList((err, res) => {
        if (!err) {
          this.setState({
            baseJson: res[0],
            expandedItems: []
          }, () => {
            resolve(res[0]);
          });
        } else {
          reject(new Error('Hálózati hiba!'));
        }
      });
    });
  }

  getKarbantartasListTetelData = (id) => {
    Promise.all([
      this.getKarbantartasListTetel(id)
    ])
      .then(() => {
        this.setState({
          tableStatus: 'TABL'
        });
      })
      .catch(() => {
        this.setState({
          tableStatus: 'NETW'
        });
      });
  }

  getKarbantartasListTetel = (id) => {
    return new Promise((resolve, reject) => {
      Services.getKarbantartasTetelList(id, (err, res) => {
        if (!err) {
          this.setState({
            expandedItems: [{ id: id, data: res[0] }],
            fejId: id
          }, () => {
            resolve(res[0]);
          });
        } else {
          reject(new Error('Hálózati hiba!'));
        }
      });
    });
  }

  getFejData = (id, isFelvitel, isView) => {
    this.setState({
      formStatusFej: 'LOAD',
      formFejId: id
    });
    Promise.all([
      this.getViewKarbantartasFej(id, isFelvitel, isView),
    ])
      .then(() => {
        this.setState({
          formStatusFej: 'DATA'
        });
      })
      .catch(() => {
        this.setState({
          formStatusFej: 'NETW'
        });
      });
  }

  getViewKarbantartasFej = (id, isFelvitel, isView) => {
    return new Promise((resolve, reject) => {
      if (isFelvitel) {
        resolve();
      } else if (isView) {
        Services.viewKarbantartasFej(id, (err, res) => {
          if (!err) {
            this.setState({
              formFejPartner: {
                id: this.nullToString(res[0].partner.id),
                nev: this.nullToString(res[0].partner.nev) + ' (' + this.nullToString(res[0].partner.azon) + ')',
              },
              formFejAzonosito: this.nullToString(res[0].azonosito),
              formFejKezdete: res[0].kezdete ? res[0].kezdete : null,
              formFejVege: res[0].vege ? res[0].vege : null,
              formFejMegjegyzes: this.nullToString(res[0].megjegyzes),
              viewObjFej: res[0],
            });
            resolve(res[0]);
          } else {
            reject(new Error('Hálózati hiba!'));
          }
        });
      } else if (!isView) {
        Services.getKarbantartasFej(id, (err, res) => {
          if (!err) {
            this.setState({
              formFejPartner: {
                id: this.nullToString(res[0].partner.id),
                nev: this.nullToString(res[0].partner.nev) + ' (' + this.nullToString(res[0].partner.azon) + ')',
              },
              formFejAzonosito: this.nullToString(res[0].azonosito),
              formFejKezdete: res[0].kezdete ? res[0].kezdete : null,
              formFejVege: res[0].vege ? res[0].vege : null,
              formFejMegjegyzes: this.nullToString(res[0].megjegyzes),
              viewObjFej: res[0],
            });
            resolve(res[0]);
          } else {
            reject(new Error('Hálózati hiba!'));
          }
        });
      }
    });
  }

  getTetelData = (id, isFelvitel, isView) => {
    this.setState({
      formStatusTetel: 'LOAD',
      formTetelId: id
    });
    Promise.all([
      this.getViewKarbantartasTetel(id, isFelvitel, isView),
    ])
      .then(() => {
        this.setState({
          formStatusTetel: 'DATA'
        });
      })
      .catch(() => {
        this.setState({
          formStatusTetel: 'NETW'
        });
      });
  }

  getViewKarbantartasTetel = (id, isFelvitel, isView) => {
    return new Promise((resolve, reject) => {
      if (isFelvitel) {
        Services.initKarbantartasTetelForm(this.state.fejId, (err, res) => {
          if (!err) {
            this.setState({
              formTetelExistsFeladatlista: res[0].existsfeladatlista,

              formTetelOrszag: {
                keresheto: this.nullToString(res[0].cim.keresheto),
                nev: this.nullToString(res[0].cim.orszag),
                orszagid: this.nullToString(res[0].cim.orszagid),
              },
              formTetelKereses: {
                iranyitoszam: this.nullToString(res[0].cim.irsz),
                irszid: this.nullToString(res[0].cim.irszid),
                nev: this.nullToString(res[0].cim.telepules),
                telepulesid: this.nullToString(res[0].cim.telepulesid),
                telepulesnev: res[0].cim.telepules && res[0].cim.irsz ? this.nullToString(res[0].cim.telepules + ' (' + res[0].cim.irsz + ')') : '',
              },
              kozterulet: this.nullToString(res[0].cim.kozterulet),
              kozteruletjelleg: this.nullToString(res[0].cim.kozteruletjelleg),
              hrsz: this.nullToString(res[0].cim.hrsz),
              hazszam: this.nullToString(res[0].cim.hazszam),
              lepcsohaz: this.nullToString(res[0].cim.lepcsohaz),
              epulet: this.nullToString(res[0].cim.epulet),
              emelet: this.nullToString(res[0].cim.emelet),
              ajto: this.nullToString(res[0].cim.ajto),
              postafiok: this.nullToString(res[0].cim.postafiok),
              cimSwitchActive: !res[0].cim.postafiok,
              formTetelTelepulesNev: this.nullToString(res[0].cim.telepules),
              formTetelIranyitoszamNev: this.nullToString(res[0].cim.irsz),
            });
            resolve(res[0]);
          } else {
            reject(new Error('Hálózati hiba!'));
          }
        });
      } else if (isView) {
        Services.viewKarbantartasTetel(id, (err, res) => {
          if (!err) {
            this.setState({
              viewObjTetel: res[0],
            });
            resolve(res[0]);
          } else {
            reject(new Error('Hálózati hiba!'));
          }
        });
      } else if (!isView) {
        Services.getKarbantartasTetel(id, (err, res) => {
          if (!err) {
            this.setState({
              formTetelCikk: {
                id: this.nullToString(res[0].cikk.id),
                nev: this.nullToString(res[0].cikk.cikknev) + ' (' + this.nullToString(res[0].cikk.cikkszam) + ')',
              },
              formTetelAzonosito: this.nullToString(res[0].azonosito),
              formTetelKapcsolodoAzonosito: this.nullToString(res[0].kapcsolodoazonosito),
              formTetelKezdete: res[0].kezdete ? res[0].kezdete : null,
              formTetelVege: res[0].vege ? res[0].vege : null,
              formTetelExistsFeladatlista: res[0].existsfeladatlista,
              formTetelGenMenny: this.nullToString(res[0].genmenny),
              formTetelLeiras: this.nullToString(res[0].leiras),
              formTetelPublikusLeiras: this.nullToString(res[0].publikusleiras),
              disableMezok: res[0].disablemezok,

              formTetelOrszag: {
                keresheto: this.nullToString(res[0].cim.keresheto),
                nev: this.nullToString(res[0].cim.orszag),
                orszagid: this.nullToString(res[0].cim.orszagid),
              },
              formTetelKereses: {
                iranyitoszam: this.nullToString(res[0].cim.irsz),
                irszid: this.nullToString(res[0].cim.irszid),
                nev: this.nullToString(res[0].cim.telepules),
                telepulesid: this.nullToString(res[0].cim.telepulesid),
                telepulesnev: res[0].cim.telepules && res[0].cim.irsz ? this.nullToString(res[0].cim.telepules + ' (' + res[0].cim.irsz + ')') : '',
              },
              kozterulet: this.nullToString(res[0].cim.kozterulet),
              kozteruletjelleg: this.nullToString(res[0].cim.kozteruletjelleg),
              hrsz: this.nullToString(res[0].cim.hrsz),
              hazszam: this.nullToString(res[0].cim.hazszam),
              lepcsohaz: this.nullToString(res[0].cim.lepcsohaz),
              epulet: this.nullToString(res[0].cim.epulet),
              emelet: this.nullToString(res[0].cim.emelet),
              ajto: this.nullToString(res[0].cim.ajto),
              postafiok: this.nullToString(res[0].cim.postafiok),
              cimSwitchActive: !res[0].cim.postafiok,
              formTetelTelepulesNev: this.nullToString(res[0].cim.telepules),
              formTetelIranyitoszamNev: this.nullToString(res[0].cim.irsz),
            });
            resolve(res[0]);
          } else {
            reject(new Error('Hálózati hiba!'));
          }
        });
      }
    });
  }

  getUtemezesData = (id) => {
    this.setState({
      formStatusUtemezes: 'LOAD',
      formTetelId: id
    });
    Promise.all([
      this.getKarbantartasUtemezes(id),
    ])
      .then(() => {
        this.setState({
          formStatusUtemezes: 'DATA'
        });
      })
      .catch(() => {
        this.setState({
          formStatusUtemezes: 'NETW'
        });
      });
  }

  getKarbantartasUtemezes = (id) => {
    return new Promise((resolve, reject) => {
      Services.getKarbantartasUtemezes(id, (err, res) => {
        if (!err) {
          let utemezesekOptions = [];
          let utemezesekArray = [];
          res[0].utemezesek.forEach((u) => {
            utemezesekOptions.push(u.listak && u.listak.length > 0 ? u.listak : res[0].defaultelllista);

            let utemezes = u;
            utemezes.gyakmennyegys = u.gyakmennyegys.name;
            utemezes.listak = u.listak && u.listak.length > 0 ? u.listak.filter(x => x.id) : [];
            utemezesekArray.push(utemezes);
          });

          this.setState({
            utemezesekOptions: utemezesekOptions,
            utemezesek: utemezesekArray,
            mennyEgysOptions: res[0].gyakorisag.map(e => (<option key={e.name} value={e.name}>{e.caption}</option>)),
            defaultmennyEgysOptionValue: res[0].gyakorisag && res[0].gyakorisag[0] && res[0].gyakorisag[0].name,
            defaultEllListaOptions: res[0].defaultelllista,
          });
          resolve(res[0]);
        } else {
          reject(new Error('Hálózati hiba!'));
        }
      });
    });
  }

  nullToString = (data) => {
    if (!data) return '';
    return data;
  }

  emptyOrNull = (data) => {
    if (!data || data === '') return null;
    return data;
  }

  toggleCimSwitch = (trueFalse) => {
    if (trueFalse !== this.state.cimSwitchActive) {
      this.setState(prevState => ({
        cimSwitchActive: !prevState.cimSwitchActive,
        // rem-elve, hogy cím/postafiók váltáskor ne tűnjön el a mező(k)ből az érték!, mentes()-ben kezelve! MG
        // postafiok: '',
        // kozterulet: '',
        // kozteruletjelleg: '',
        // hrsz: '',
        // hazszam: '',
        // lepcsohaz: '',
        // epulet: '',
        // emelet: '',
        // ajto: ''
      }));
    }
  }

  handleSubmitFej = () => {
    const {
      formFejId,
      formFejPartner,
      formFejAzonosito,
      formFejKezdete,
      formFejVege,
      formFejMegjegyzes,

      formTypeFej
    } = this.state;

    this.setState({
      saveButtonLoading: true
    });

    let message;
    switch (formTypeFej) {
      case 'FEL':
        message = 'felvéve';
        break;
      case 'MOD':
        message = 'módosítva';
        break;
      default:
        message = '';
    }

    let submitObj = {};

    submitObj.id = formFejId;
    submitObj.partnertortenetid = formFejPartner.id;
    submitObj.azonosito = formFejAzonosito;
    submitObj.kezdete = this.dateToTimezone(formFejKezdete);
    submitObj.vege = this.dateToTimezone(formFejVege);
    submitObj.megjegyzes = formFejMegjegyzes;

    Services.saveKarbantartasFej(submitObj, (err) => {
      if (!err) {
        this.toggleModalFej();
        this.getKarbantartasListData();
        this.props.globals.addSuccess('Karbantartás ' + message + '!');
        this.setState({
          saveButtonLoading: false
        });
      } else {
        this.setState({
          saveButtonLoading: false
        });
      }
    });
  }

  handleSubmitTetel = () => {
    const {
      fejId,
      formTetelId,
      formTetelCikk,
      formTetelAzonosito,
      formTetelKapcsolodoAzonosito,
      formTetelKezdete,
      formTetelVege,
      formTetelGenMenny,
      formTetelLeiras,
      formTetelPublikusLeiras,
      formTetelExistsFeladatlista,

      formTypeTetel
    } = this.state;

    this.setState({
      saveButtonLoading: true
    });

    let message;
    switch (formTypeTetel) {
      case 'FEL':
        message = 'felvéve';
        break;
      case 'MOD':
        message = 'módosítva';
        break;
      default:
        message = '';
    }

    let submitObj = {};

    submitObj.fejid = fejId;
    submitObj.id = formTetelId;
    submitObj.cikkid = formTetelCikk.id;
    submitObj.azonosito = formTetelAzonosito;
    submitObj.kapcsolodoazonosito = formTetelKapcsolodoAzonosito;
    submitObj.kezdete = this.dateToTimezone(formTetelKezdete);
    submitObj.vege = this.dateToTimezone(formTetelVege);
    submitObj.genmenny = formTetelExistsFeladatlista ? formTetelGenMenny : null;
    submitObj.leiras = formTetelLeiras;
    submitObj.publikusleiras = formTetelPublikusLeiras;

    let cim = {};

    cim.orszagid = this.state.formTetelOrszag ? this.state.formTetelOrszag.orszagid : null;

    if (this.state.formTetelOrszag && this.state.formTetelOrszag.keresheto) {
      cim.telepulesid = this.emptyOrNull(this.state.formTetelKereses.telepulesid);
      cim.irszid = this.emptyOrNull(this.state.formTetelKereses.irszid);
    } else {
      cim.telepules = this.emptyOrNull(this.state.formTetelTelepulesNev);
      cim.irsz = this.emptyOrNull(this.state.formTetelIranyitoszamNev);
    }

    if (this.state.cimSwitchActive) { // cím
      cim.kozterulet = this.state.kozterulet;
      cim.kozteruletjelleg = this.state.kozteruletjelleg;
      cim.hazszam = this.state.hazszam;
      cim.hrsz = this.state.hrsz;
      cim.lepcsohaz = this.state.lepcsohaz;
      cim.epulet = this.state.epulet;
      cim.emelet = this.state.emelet;
      cim.ajto = this.state.ajto;

      cim.postafiok = null;
    } else { // postafiók
      cim.postafiok = this.state.postafiok;

      cim.kozterulet = null;
      cim.kozteruletjelleg = null;
      cim.hazszam = null;
      cim.hrsz = null;
      cim.lepcsohaz = null;
      cim.epulet = null;
      cim.emelet = null;
      cim.ajto = null;
    }

    submitObj.cim = cim;

    Services.saveKarbantartasTetel(submitObj, (err) => {
      if (!err) {
        this.toggleModalTetel();
        this.getKarbantartasListTetelData(this.state.fejId);
        this.props.globals.addSuccess('Karbantartás tétel' + message + '!');
        this.setState({
          saveButtonLoading: false
        });
      } else {
        this.setState({
          saveButtonLoading: false
        });
      }
    });
  }

  handleSubmitUtemezes = () => {
    const {
      formTetelId,
      utemezesek
    } = this.state;

    this.setState({
      saveButtonLoading: true
    });

    let utemezesekArray = [];
    utemezesek.forEach((utemezes) => {
      let ids = [];
      if (utemezes.listak && utemezes.listak.length > 0) {
        utemezes.listak.forEach((lista) => {
          ids.push([lista.id, lista.ellenorzesilistaid]);
        });
      }

      let utemObj = Object.assign({}, utemezes);
      utemObj.ids = ids;
      delete utemObj.listak;
      delete utemObj.tetelId;
      utemezesekArray.push(utemObj);
    });

    let submitObj = {};
    submitObj.tetelid = formTetelId;
    submitObj.utemezesek = utemezesekArray;

    Services.saveKarbantartasUtemezes(submitObj, (err) => {
      if (!err) {
        this.toggleModalUtemezes();
        this.getKarbantartasListTetelData(this.state.fejId);
        this.props.globals.addSuccess('Karbantartás ütemezés mentve!');
        this.setState({
          saveButtonLoading: false
        });
      } else {
        this.setState({
          saveButtonLoading: false
        });
      }
    });
  }

  handleInputChange = (e) => {
    const { target } = e;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;
    this.setState({
      [name]: value
    });
  }

  handleIdopontChange = (name, value) => {
    this.setState({
      [name]: value ? this.dateToTimezone(value) : null
    });
  }

  handleNewFejClick = () => {
    this.setState({
      formTypeFej: 'FEL',
      formStatusFej: 'DATA',
      formFejPartner: '',
      formFejAzonosito: '',
      formFejKezdete: null,
      formFejVege: null,
      formFejMegjegyzes: '',
    }, () => {
      this.getFejData(null, true, false);
      this.toggleModalFej();
    });
  }

  handleNewTetelClick = () => {
    this.setState({
      formTypeTetel: 'FEL',
      formStatusTetel: 'DATA',
      formTetelCikk: '',
      formTetelAzonosito: '',
      formTetelKapcsolodoAzonosito: '',
      formTetelKezdete: null,
      formTetelVege: null,
      formTetelExistsFeladatlista: false,
      formTetelGenMenny: 7,
      formTetelLeiras: '',
      formTetelPublikusLeiras: '',
      disableMezok: false,

      formTetelOrszag: '',
      formTetelKereses: '',
      kozterulet: '',
      kozteruletjelleg: '',
      hazszam: '',
      hrsz: '',
      lepcsohaz: '',
      epulet: '',
      emelet: '',
      ajto: '',
      postafiok: '',
      cimSwitchActive: true,
      formTetelIranyitoszamNev: '',
      formTetelTelepulesNev: '',
    }, () => {
      this.getTetelData(null, true, false);
      this.toggleModalTetel();
    });
  }

  handleViewFejClick = (cell) => {
    this.setState({
      formTypeFej: 'MEG',
      formStatusFej: 'LOAD',
      currentId: cell,
    }, () => {
      this.getFejData(cell, false, true);
      this.toggleViewModalFej();
    });
  }

  handleViewTetelClick = (cell) => {
    this.setState({
      formTypeTetel: 'MEG',
      formStatusTetel: 'LOAD',
      currentId: cell,
    }, () => {
      this.getTetelData(cell, false, true);
      this.toggleViewModalTetel();
    });
  }

  handleEditFejClick = (cell) => {
    this.setState({
      formTypeFej: 'MOD',
      formStatusFej: 'LOAD',
      currentId: cell,
      formFejPartner: '',
      formFejAzonosito: '',
      formFejKezdete: null,
      formFejVege: null,
      formFejMegjegyzes: '',
    }, () => {
      this.getFejData(cell);
      this.toggleModalFej();
    });
  }

  handleEditTetelClick = (cell) => {
    this.setState({
      formTypeTetel: 'MOD',
      formStatusTetel: 'LOAD',
      currentId: cell,
      formTetelCikk: '',
      formTetelAzonosito: '',
      formTetelKapcsolodoAzonosito: '',
      formTetelKezdete: null,
      formTetelVege: null,
      formTetelExistsFeladatlista: false,
      formTetelGenMenny: '',
      formTetelLeiras: '',
      formTetelPublikusLeiras: '',
      disableMezok: false,

      formTetelOrszag: '',
      formTetelKereses: '',
      kozterulet: '',
      kozteruletjelleg: '',
      hazszam: '',
      hrsz: '',
      lepcsohaz: '',
      epulet: '',
      emelet: '',
      ajto: '',
      postafiok: '',
      cimSwitchActive: true,
      formTetelIranyitoszamNev: '',
      formTetelTelepulesNev: '',
    }, () => {
      this.getTetelData(cell);
      this.toggleModalTetel();
    });
  }

  handleEditUtemezesClick = (cell) => {
    this.setState({
      formStatusUtemezes: 'LOAD',
      currentId: cell,
    }, () => {
      this.getUtemezesData(cell);
      this.toggleModalUtemezes();
    });
  }

  handleDeleteFejClick = (cell) => {
    this.setState({
      formTypeFej: 'TOR',
      torlesId: cell
    }, () => {
      this.toggleTorlesFej();
    });
  }

  handleDeleteTetelClick = (cell) => {
    this.setState({
      formTypeTetel: 'TOR',
      torlesId: cell
    }, () => {
      this.toggleTorlesTetel();
    });
  }

  handleExpandTetelek = (id) => {
    if (this.state.expandedItems.find(x => x.id === id)) {
      this.setState({ expandedItems: [], fejId: id });
    } else {
      this.getKarbantartasListTetelData(id);
    }
  }

  tableIconFormatterFej = (cell) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KARBANTARTAS_KARBANTARTAS', 'LEK') && (
          <React.Fragment>
            <Button className="icon-eye table-action-icon" id={'action-view-' + cell + 'view'} onClick={() => this.handleViewFejClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'view'} delay={50}>
              {'Megtekintés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KARBANTARTAS_KARBANTARTAS', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => this.handleEditFejClick(cell)} />
            <UncontrolledTooltip style={{ float: 'top' }} placement="top" target={'action-view-' + cell + 'modositas'} delay={0}>
              {'Módosítás'}
            </UncontrolledTooltip>
            <Button className="icon-trash table-action-icon" id={'action-view-' + cell + 'torles'} onClick={() => this.handleDeleteFejClick(cell)} />
            <UncontrolledTooltip style={{ float: 'top' }} placement="top" target={'action-view-' + cell + 'torles'} delay={0}>
              {'Törlés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  tableIconFormatterTetel = (cell) => {
    return (
      <div>
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KARBANTARTAS_KARBANTARTAS', 'LEK') && (
          <React.Fragment>
            <Button className="icon-eye table-action-icon" id={'action-view-' + cell + 'view'} onClick={() => this.handleViewTetelClick(cell)} />
            <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'action-view-' + cell + 'view'} delay={50}>
              {'Megtekintés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KARBANTARTAS_KARBANTARTAS', 'KARB') && (
          <React.Fragment>
            <Button className="icon-pencil table-action-icon" id={'action-view-' + cell + 'modositas'} onClick={() => this.handleEditTetelClick(cell)} />
            <UncontrolledTooltip style={{ float: 'top' }} placement="top" target={'action-view-' + cell + 'modositas'} delay={0}>
              {'Módosítás'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KARBANTARTAS_KARBANTARTAS', 'LEK') && (
          <React.Fragment>
            <Button className="icon-clock table-action-icon" id={'action-view-' + cell + 'utemezes'} onClick={() => this.handleEditUtemezesClick(cell)} />
            <UncontrolledTooltip style={{ float: 'top' }} placement="top" target={'action-view-' + cell + 'utemezes'} delay={0}>
              {'Ütemezés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
        {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KARBANTARTAS_KARBANTARTAS', 'KARB') && (
          <React.Fragment>
            <Button className="icon-trash table-action-icon" id={'action-view-' + cell + 'torles'} onClick={() => this.handleDeleteTetelClick(cell)} />
            <UncontrolledTooltip style={{ float: 'top' }} placement="top" target={'action-view-' + cell + 'torles'} delay={0}>
              {'Törlés'}
            </UncontrolledTooltip>
          </React.Fragment>
        )}
      </div>
    );
  }

  idopontFormatter = (cell) => {
    return this.timezoneToDate(cell);
  }

  dateToTimezone = (date) => {
    if (!date || date === '') return null;
    return MomentTimezone.tz(date, this.tz).format('YYYY-MM-DDTHH:mm:00.000ZZ');
  }


  timezoneToDate = (date) => {
    if (!date || date === '') {
      return null;
    }

    return MomentTimezone.tz(date, this.tz).format('YYYY-MM-DD HH:mm');
  }

  renderFormTitle = (formType, isTetel) => {
    let suffix = isTetel ? ' tétel' : '';
    let tooltip = false;
    let string;
    switch (formType) {
      case 'FEL':
        string = 'Új karbantartás' + suffix + ' létrehozás';
        tooltip = true;
        break;
      case 'MOD':
        string = 'Karbantartás' + suffix + ' módosítás';
        tooltip = true;
        break;
      case 'TOR':
        string = 'Karbantartás' + suffix + ' törlés';
        break;
      case 'MEG':
        string = 'Karbantartás' + suffix + ' megtekintés';
        break;
      default:
        string = '';
    }
    if (isTetel && tooltip) {
      return (
        <div className="row" style={{ marginLeft: 0 }}>
          <div>
            {string}
            <CustomTooltip text={getTooltip('karbantartasitetel')} id="karbantartasitetel2TT" />
          </div>
        </div>
      );
    }
    return string;
  }

  renderFormFej = () => {
    const {
      formFejPartner,
      formFejAzonosito,
      formFejKezdete,
      formFejVege,
      formFejMegjegyzes,
      formTypeFej,
    } = this.state;

    return (
      <React.Fragment>
        <div className="row">
          <div style={{ marginBottom: 20 }} className="col-md-12">
            <Label for="formFejPartner">Partner: *</Label>
            <Async
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="formFejPartner"
              id="formFejPartner"
              // placeholder="Kezdjen el gépelni..."
              placeholder="Keresés partner neve alapján..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangePartner(v)}
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadPartnerChange, 1000)}
              isLoading={false}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.nev}
              value={formFejPartner}
              cache={false}
              autoload={false}
              isDisabled={formTypeFej === 'MOD'}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <AvGroup>
              <Label for="formFejAzonosito">Azonosító: *</Label>
              <AvInput
                name="formFejAzonosito"
                id="formFejAzonosito"
                value={formFejAzonosito}
                onChange={this.handleInputChange}
                type="text"
                maxLength="30"
                autoComplete="off"
              />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <AvGroup>
              <Label>Karbantartás kezdete:</Label>
              <DateTimePicker
                containerClassName="organw-date-time"
                format="YYYY-MM-DD HH:mm"
                messages={{ dateButton: 'dátum', timeButton: 'idő' }}
                onChange={v => this.handleIdopontChange('formFejKezdete', v)}
                value={formFejKezdete && new Date(formFejKezdete)}
                name="formFejKezdete"
                id="formFejKezdete"
              />
              <AvFeedback>A mező kitöltése kötelező!</AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-6">
            <AvGroup>
              <Label>Karbantartás vége:</Label>
              <DateTimePicker
                containerClassName="organw-date-time"
                format="YYYY-MM-DD HH:mm"
                messages={{ dateButton: 'dátum', timeButton: 'idő' }}
                onChange={v => this.handleIdopontChange('formFejVege', v)}
                value={formFejVege && new Date(formFejVege)}
                name="formFejVege"
                id="formFejVege"
              />
              <AvFeedback>A mező kitöltése kötelező!</AvFeedback>
            </AvGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <AvGroup>
              <Label for="formFejMegjegyzes">Megjegyzés:</Label>
              <AvInput
                className="munkalap-profil-textarea"
                type="textarea"
                name="formFejMegjegyzes"
                id="formFejMegjegyzes"
                value={formFejMegjegyzes}
                onChange={this.handleInputChange}
              />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
        </div>

      </React.Fragment>
    );
  }

  renderFormTetel = () => {
    const {
      formTetelCikk,
      formTetelAzonosito,
      formTetelKapcsolodoAzonosito,
      formTetelKezdete,
      formTetelVege,
      formTetelExistsFeladatlista,
      formTetelGenMenny,
      formTetelLeiras,
      formTetelPublikusLeiras,
      disableMezok,
    } = this.state;

    let titleStyle = {
      marginBottom: 20,
      marginTop: 10
    };

    return (
      <React.Fragment>
        <div className="row">
          <div style={{ marginBottom: 20 }} className="col-md-6">
            <Label for="formTetelCikk">Cikk: *</Label>
            <Async
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="formTetelCikk"
              id="formTetelCikk"
              // placeholder="Kezdjen el gépelni..."
              placeholder="Keresés cikkszám vagy megnevezés alapján..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeCikk(v)}
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadCikkChange, 1000)}
              isLoading={false}
              getOptionValue={option => option.id}
              getOptionLabel={option => option.nev}
              value={formTetelCikk}
              cache={false}
              autoload={false}
              isDisabled={disableMezok}
            />
          </div>
          <div className="col-md-3">
            <AvGroup>
              <Label for="formTetelAzonosito">Azonosító: *</Label>
              <AvInput
                name="formTetelAzonosito"
                id="formTetelAzonosito"
                value={formTetelAzonosito}
                onChange={this.handleInputChange}
                type="text"
                maxLength="50"
                autoComplete="off"
                disabled={disableMezok}
              />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-3">
            <AvGroup>
              <Label for="formTetelKapcsolodoAzonosito">Kapcsolódó azonosító:</Label>
              <AvInput
                name="formTetelKapcsolodoAzonosito"
                id="formTetelKapcsolodoAzonosito"
                value={formTetelKapcsolodoAzonosito}
                onChange={this.handleInputChange}
                type="text"
                maxLength="50"
                autoComplete="off"
                disabled={disableMezok}
              />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <div>
              <h5 style={titleStyle}>
                {'Cím:'}
              </h5>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3" style={{ marginBottom: '1rem' }}>
            <Label for="formTetelOrszag">Ország: *</Label>
            <Async
              styles={{
                control: base => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                }),
              }}
              name="formTetelOrszag"
              id="formTetelOrszag"
              placeholder="Kezdjen el gépelni..."
              noOptionsMessage={() => 'Nincs keresési eredmény'}
              searchPromptText="Kezdjen el gépelni a kereséshez..."
              loadingMessage={() => 'Keresés...'}
              onChange={v => this.handleChangeOrszagInput(v)}
              isClearable
              ignoreAccents={false}
              loadOptions={_.debounce(this.loadOrszagOptions, 1000)}
              isLoading={false}
              getOptionLabel={option => option.nev}
              getOptionValue={option => option.orszagid}
              value={this.state.formTetelOrszag}
              cache={false}
              autoload={false}
              isDisabled={disableMezok}
            />
          </div>
          {this.state.formTetelOrszag && this.state.formTetelOrszag.keresheto && (
            <div className="col-md-3" style={{ marginBottom: '1rem' }}>
              <Label for="formTetelKereses">Keresés: </Label>
              <Async
                styles={{
                  control: base => ({
                    ...base,
                    '&:focus': { borderColor: 'red' }, // border style on hover
                    border: '1px solid lightgray', // default border color
                    boxShadow: 'none', // no box-shadow
                    borderRadius: '0'
                  }),
                }}
                name="formTetelKereses"
                id="formTetelKereses"
                placeholder="Település vagy irányítószám"
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingMessage={() => 'Keresés...'}
                onChange={v => this.handleChangeKeresesInput(v)}
                // isClearable
                ignoreAccents={false}
                // loadOptions={(input, callback) => this.loadKeresesOptions(input, callback)}
                loadOptions={_.debounce(this.loadKeresesOptions, 1000)}
                getOptionLabel={option => option.telepulesnev}
                getOptionValue={option => option.telepulesid}
                isLoading={false}
                value={this.state.formTetelKereses}
                cache={false}
                autoload={false}
                isDisabled={disableMezok}
              />
            </div>
          )}
          <div className="col-md-3">
            <AvGroup>
              <Label for="formTetelIranyitoszamNev">Irányítószám: *</Label>
              <AvInput
                name="formTetelIranyitoszamNev"
                id="formTetelIranyitoszamNev"
                type="text"
                maxLength="20"
                value={this.state.formTetelIranyitoszamNev}
                onChange={this.handleInputChange}
                disabled={disableMezok || (this.state.formTetelOrszag && this.state.formTetelOrszag.keresheto) || (this.state.formTetelOrszag === '')}
                autoComplete="off"
              />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-3">
            <AvGroup>
              <Label for="formTetelTelepulesNev">Település: *</Label>
              <AvInput
                name="formTetelTelepulesNev"
                id="formTetelTelepulesNev"
                type="text"
                value={this.state.formTetelTelepulesNev}
                onChange={this.handleInputChange}
                disabled={disableMezok || (this.state.formTetelOrszag && this.state.formTetelOrszag.keresheto) || (this.state.formTetelOrszag === '')}
                autoComplete="off"
              />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <SwitchButton
              active={this.state.cimSwitchActive}
              trueToggle={() => this.toggleCimSwitch(true)}
              falseToggle={() => this.toggleCimSwitch(false)}
              style={{ marginBottom: 15, marginTop: 8 }}
              trueLabel="Cím"
              falseLabel="Postafiók"
              disabled={disableMezok}
            />
          </div>
        </div>
        {this.state.cimSwitchActive && (
          <React.Fragment>
            <div className="row">
              <div className="col-md-3">
                <AvGroup>
                  <Label for="kozterulet">Közterület neve: *</Label>
                  <AvInput
                    name="kozterulet"
                    id="kozterulet"
                    type="text"
                    value={this.state.kozterulet}
                    onChange={this.handleInputChange}
                    maxLength="50"
                    autoComplete="off"
                    disabled={disableMezok}
                  />
                  <AvFeedback>Kötelező mező!</AvFeedback>
                </AvGroup>
              </div>
              <div className="col-md-3">
                <AvGroup>
                  <Label for="kozteruletjelleg">Közterület jellege: *</Label>
                  <AvInput
                    name="kozteruletjelleg"
                    id="kozteruletjelleg"
                    value={this.state.kozteruletjelleg}
                    onChange={this.handleInputChange}
                    type="text"
                    maxLength="20"
                    autoComplete="off"
                    disabled={disableMezok}
                  />
                  <AvFeedback>Kötelező mező!</AvFeedback>
                </AvGroup>
              </div>
              <div className="col-md-3">
                <AvGroup>
                  <Label for="hazszam">Házszám:</Label>
                  <AvInput
                    name="hazszam"
                    id="hazszam"
                    value={this.state.hazszam}
                    onChange={this.handleInputChange}
                    type="text"
                    maxLength="20"
                    autoComplete="off"
                    disabled={disableMezok}
                  />
                  <AvFeedback>Kötelező mező!</AvFeedback>
                </AvGroup>
              </div>
              <div className="col-md-3">
                <AvGroup>
                  <Label for="hrsz">Hrsz:</Label>
                  <AvInput
                    name="hrsz"
                    id="hrsz"
                    value={this.state.hrsz}
                    onChange={this.handleInputChange}
                    type="text"
                    maxLength="20"
                    autoComplete="off"
                    disabled={disableMezok}
                  />
                  <AvFeedback>Kötelező mező!</AvFeedback>
                </AvGroup>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <AvGroup>
                  <Label for="epulet">Épület:</Label>
                  <AvInput
                    name="epulet"
                    id="epulet"
                    value={this.state.epulet}
                    onChange={this.handleInputChange}
                    type="text"
                    maxLength="4"
                    autoComplete="off"
                    disabled={disableMezok}
                  />
                </AvGroup>
              </div>
              <div className="col-md-3">
                <AvGroup>
                  <Label for="lepcsohaz">Lépcsőház: </Label>
                  <AvInput
                    name="lepcsohaz"
                    id="lepcsohaz"
                    value={this.state.lepcsohaz}
                    onChange={this.handleInputChange}
                    type="text"
                    maxLength="4"
                    autoComplete="off"
                    disabled={disableMezok}
                  />
                </AvGroup>
              </div>
              <div className="col-md-3">
                <AvGroup>
                  <Label for="emelet">Emelet:</Label>
                  <AvInput
                    name="emelet"
                    id="emelet"
                    value={this.state.emelet}
                    onChange={this.handleInputChange}
                    type="text"
                    maxLength="10"
                    autoComplete="off"
                    disabled={disableMezok}
                  />
                </AvGroup>
              </div>
              <div className="col-md-3">
                <AvGroup>
                  <Label for="ajto">Ajtó:</Label>
                  <AvInput
                    name="ajto"
                    id="ajto"
                    value={this.state.ajto}
                    onChange={this.handleInputChange}
                    type="text"
                    maxLength="15"
                    autoComplete="off"
                    disabled={disableMezok}
                  />
                  <AvFeedback>Szám mező!</AvFeedback>
                </AvGroup>
              </div>
            </div>
          </React.Fragment>
        )}
        {!this.state.cimSwitchActive && (
          <div className="row">
            <div className="col-md-3">
              <AvGroup>
                <Label for="postafiok">Postafiók: *</Label>
                <AvInput
                  name="postafiok"
                  id="postafiok"
                  value={this.state.postafiok}
                  onChange={this.handleInputChange}
                  type="text"
                  maxLength="40"
                  autoComplete="off"
                  disabled={disableMezok}
                />
                <AvFeedback>Kötelező mező!</AvFeedback>
              </AvGroup>
            </div>
          </div>
        )}
        <div className="row">
          <div className={'col-md-' + (formTetelExistsFeladatlista ? 4 : 6)}>
            <AvGroup>
              <Label>Szolgáltatás kezdete: *</Label>
              <DateTimePicker
                containerClassName="organw-date-time"
                format="YYYY-MM-DD HH:mm"
                messages={{ dateButton: 'dátum', timeButton: 'idő' }}
                onChange={v => this.handleIdopontChange('formTetelKezdete', v)}
                value={formTetelKezdete && new Date(formTetelKezdete)}
                name="formTetelKezdete"
                id="formTetelKezdete"
                disabled={disableMezok}
              />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
          <div className={'col-md-' + (formTetelExistsFeladatlista ? 4 : 6)}>
            <AvGroup>
              <Label>Szolgáltatás vége:</Label>
              <DateTimePicker
                containerClassName="organw-date-time"
                format="YYYY-MM-DD HH:mm"
                messages={{ dateButton: 'dátum', timeButton: 'idő' }}
                onChange={v => this.handleIdopontChange('formTetelVege', v)}
                value={formTetelVege && new Date(formTetelVege)}
                name="formTetelVege"
                id="formTetelVege"
              />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
          {formTetelExistsFeladatlista && (
            <div className="col-md-4">
              <AvGroup>
                <Label for="formTetelGenMenny">
                  {'Feladatok generálása (nap):'}
                  <CustomTooltip text={getTooltip('tetelFeladatGeneralas')} id="tetelFeladatGeneralasTT" />
                </Label>
                <AvInput
                  name="formTetelGenMenny"
                  id="formTetelGenMenny"
                  type="text"
                  pattern="^[0-9]*$"
                  onChange={this.handleInputChange}
                  value={formTetelGenMenny}
                  autoComplete="off"
                />
                <AvFeedback>Csak egész szám!</AvFeedback>
              </AvGroup>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-6">
            <AvGroup>
              <Label for="formTetelLeiras">Leírás:</Label>
              <AvInput
                className="munkalap-profil-textarea"
                type="textarea"
                name="formTetelLeiras"
                id="formTetelLeiras"
                value={formTetelLeiras}
                onChange={this.handleInputChange}
              />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
          <div className="col-md-6">
            <AvGroup>
              <Label for="formTetelPublikusLeiras">
                {'Publikus leírás:'}
                <CustomTooltip text={getTooltip('tetelPublikusLeiras')} id="tetelPublikusLeirasTT" />
              </Label>
              <AvInput
                className="munkalap-profil-textarea"
                type="textarea"
                name="formTetelPublikusLeiras"
                id="formTetelPublikusLeiras"
                value={formTetelPublikusLeiras}
                onChange={this.handleInputChange}
                disabled={disableMezok}
              />
              <AvFeedback>Kötelező mező!</AvFeedback>
            </AvGroup>
          </div>
        </div>

      </React.Fragment>
    );
  }

  renderFormUtemezes = () => {
    let utemezesSorok = [];
    let vanKarbRole = Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KARBANTARTAS_KARBANTARTAS', 'KARB');
    if (this.state.utemezesek && this.state.utemezesek.length > 0) {
      this.state.utemezesek.forEach((item, idx) => {
        utemezesSorok.push(
          <React.Fragment key={idx.toString()}>
            <div className="row">
              <div className="col-md-2">
                <AvGroup>
                  <Label for={'gyakmenny' + idx}>Gyakoriság: *</Label>
                  <AvInput
                    name={'gyakmenny' + idx}
                    id={'gyakmenny' + idx}
                    type="text"
                    pattern="^[0-9]*$"
                    onChange={e => this.handleGyakMennyChange(e, idx)}
                    value={this.state.utemezesek[idx].gyakmenny}
                    autoComplete="off"
                    disabled={!vanKarbRole}
                  />
                  <AvFeedback>Csak egész szám!</AvFeedback>
                </AvGroup>
              </div>
              <div className="col-md-2">
                <AvField
                  type="select"
                  name={'gyakmennyegys' + idx}
                  id={'gyakmennyegys' + idx}
                  label="Egység: *"
                  errorMessage="Kötelező mező!"
                  value={this.state.utemezesek[idx].gyakmennyegys}
                  onChange={e => this.handleGyakMennyEgysChange(e, idx)}
                  disabled={!vanKarbRole}
                >
                  {this.state.mennyEgysOptions}
                </AvField>
              </div>
              <div className={vanKarbRole ? 'col-md-6' : 'col-md-8'}>
                <Label for={'elllista' + idx}>Ellenőrzési listák: </Label>
                <Select
                  styles={{
                    control: base => ({
                      ...base,
                      '&:focus': { borderColor: 'red' }, // border style on hover
                      border: '1px solid lightgray', // default border color
                      boxShadow: 'none', // no box-shadow
                      borderRadius: '0'
                    }),
                  }}
                  name={'elllista' + idx}
                  id={'elllista' + idx}
                  placeholder="Kezdjen el gépelni..."
                  noOptionsMessage={() => 'Nincs keresési eredmény'}
                  onChange={e => this.handleEllListaIdsChange(e, idx)}
                  options={this.state.utemezesekOptions[idx]}
                  getOptionLabel={option => option.nev}
                  getOptionValue={option => option.ellenorzesilistaid}
                  value={this.state.utemezesek[idx].listak}
                  isLoading={false}
                  isClearable
                  isMulti
                  isDisabled={!vanKarbRole}
                />
              </div>
              {vanKarbRole && (
                <div className={'col-md-' + (this.state.utemezesek.length - 1 !== idx ? 2 : 1)}>
                  <Button color="danger" style={{ marginTop: '29px' }} type="button" onClick={() => this.deleteUtemezesRow(idx, item)}><span className="fa fa-times" /></Button>
                </div>
              )}
              {vanKarbRole && this.state.utemezesek.length - 1 === idx && (
                <div className="col-md-1">
                  <Button color="success" style={{ marginTop: '29px' }} type="button" onClick={() => this.addUtemezesRow()}><span className="fa fa-plus" /></Button>
                </div>
              )}
            </div>
          </React.Fragment>
        );
      });
    }
    return (
      <React.Fragment>
        {utemezesSorok}
        {(vanKarbRole && (!this.state.utemezesek || this.state.utemezesek.length === 0)) && (
          <div className="row">
            <div className="col-md-12">
              <Button className="pull-left table-new-button" color="success" onClick={() => this.addUtemezesRow()}>Új ütemezés</Button>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }

  handleGyakMennyChange = (e, idx) => {
    let newState = this.state.utemezesek;
    newState[idx].gyakmenny = e.target.value;
    this.setState({ utemezesek: newState });
  }

  handleGyakMennyEgysChange = (e, idx) => {
    let newState = this.state.utemezesek;
    newState[idx].gyakmennyegys = e.target.value;
    this.setState({ utemezesek: newState });
  }

  handleEllListaIdsChange = (e, idx) => {
    let newState = this.state.utemezesek;
    newState[idx].listak = e;

    let newOptionsState = this.state.utemezesekOptions;
    newOptionsState[idx] = newOptionsState[idx].filter(x => x.valaszthato);


    this.setState({ utemezesek: newState, utemezesekOptions: newOptionsState });
  }

  addUtemezesRow = () => {
    let newState = this.state.utemezesek;
    let newOptionsState = this.state.utemezesekOptions;
    newState.push({
      gyakmenny: '',
      gyakmennyegys: this.state.defaultmennyEgysOptionValue,
    });
    newOptionsState.push(this.state.defaultEllListaOptions);

    this.setState({ utemezesek: newState, utemezesekOptions: newOptionsState });
  }

  deleteUtemezesRow = (idx, item) => {
    if (item.id) {
      this.checkUtemezesData(idx, item.id);
    } else {
      this.removeRow(idx);
    }
  }

  checkUtemezesData = (idx, id) => {
    Promise.all([
      this.checkUtemezes(idx, id),
    ])
      .then(() => {
        this.setState({
          formStatusUtemezes: 'DATA'
        });
      })
      .catch(() => {
        this.setState({
          formStatusUtemezes: 'DATA'
        });
      });
  }

  checkUtemezes = (idx, id) => {
    return new Promise((resolve, reject) => {
      Services.checkKarbantartasUtemezes(id, (err, res) => {
        if (!err) {
          this.removeRow(idx);

          resolve(res[0]);
        } else {
          reject(new Error('Hálózati hiba!'));
        }
      });
    });
  }

  removeRow = (idx) => {
    let newState = this.state.utemezesek;
    newState.splice(idx, 1);
    let newOptionsState = this.state.utemezesekOptions;
    newOptionsState.splice(idx, 1);

    this.setState({ utemezesek: newState, utemezesekOptions: newOptionsState });
  }

  renderTable = () => {
    const options = {
      sizePerPage: 5,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const selectRow = {
      mode: 'radio',
      onSelect: this.onRowSelect,
      style: { backgroundColor: '#c8e6c9' },
    };

    const columns = [
      {
        dataField: 'partner',
        text: 'Partner',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'azonosito',
        text: 'Azonosító',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'kezdete',
        text: 'Karbantartás kezdete',
        filter: textFilter({
          delay: 400,
          placeholder: 'Keresés...',
        }),
        sort: true,
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
          textAlign: 'center'
        },
        formatter: this.idopontFormatter,
        sortFunc: (a, b, order) => {
          if (order === 'asc') return a.localeCompare(b);
          return b.localeCompare(a);
        }
      },
      {
        dataField: 'vege',
        text: 'Karbantartás vége',
        filter: textFilter({
          delay: 400,
          placeholder: 'Keresés...',
        }),
        sort: true,
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
          textAlign: 'center'
        },
        formatter: this.idopontFormatter,
        sortFunc: (a, b, order) => {
          if (order === 'asc') {
            if (a == null || a === '') return 1;
            return a.localeCompare(b);
          }
          if (a == null || a === '') return -1;
          return b.localeCompare(a);
        }
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        formatter: this.tableIconFormatterFej
      }
    ];

    const columns2 = [
      {
        dataField: 'cikkszam',
        text: 'Cikkszám',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'cikknev',
        text: 'Cikknév',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'azonosito',
        text: 'Egyedi azonosító',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'kezdete',
        text: 'Szolgáltatás kezdete',
        filter: textFilter({
          delay: 400,
          placeholder: 'Keresés...',
        }),
        sort: true,
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
          textAlign: 'center'
        },
        formatter: this.idopontFormatter,
        sortFunc: (a, b, order) => {
          if (order === 'asc') return a.localeCompare(b);
          return b.localeCompare(a);
        }
      },
      {
        dataField: 'vege',
        text: 'Szolgáltatás vége',
        filter: textFilter({
          delay: 400,
          placeholder: 'Keresés...',
        }),
        sort: true,
        style: {
          wordBreak: 'break-all',
          whiteSpace: 'pre-line',
          textAlign: 'center'
        },
        formatter: this.idopontFormatter,
        sortFunc: (a, b, order) => {
          if (order === 'asc') {
            if (a == null || a === '') return 1;
            return a.localeCompare(b);
          }
          if (a == null || a === '') return -1;
          return b.localeCompare(a);
        }
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        formatter: this.tableIconFormatterTetel
      }
    ];

    const expandRow = {
      renderer: row => (this.state.expandedItems.find(x => x.id === row.id) && (
      <React.Fragment>
        <div className="row">
          <div className="col-md-2">
            <h5>
              Karbantartás tételek
              <CustomTooltip text={getTooltip('karbantartasitetel')} id="karbantartasitetel1TT" />
            </h5>
          </div>
          {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KARBANTARTAS_KARBANTARTAS', 'KARB') && (
            <div className="col-md-10">
              <Button className="pull-right table-new-button" color="primary" onClick={this.handleNewTetelClick}>
                {'Új karbantartás tétel'}
              </Button>
            </div>
          )}
        </div>
        { this.state.expandedItems.find(x => x.id === row.id).data && this.state.expandedItems.find(x => x.id === row.id).data.length > 0 && (
          <BootstrapTable
            bootstrap4
            keyField="id"
            data={this.state.expandedItems.find(x => x.id === row.id).data}
            columns={columns2}
            filter={filterFactory()}
            noDataIndication="Nincs megjelenítendő adat!"
          />
        )}
      </React.Fragment>
      )),
      expanded: this.state.expandedItems.map(x => x.id),
      expandByColumnOnly: true,
    };

    return (
      <div className="animated fadeIn">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={this.state.baseJson}
          columns={columns}
          selectRow={selectRow}
          expandRow={expandRow}
          filter={filterFactory()}
          pagination={paginationFactory(options)}
          noDataIndication="Nincs megjelenítendő adat!"
        />
      </div>
    );
  }

  renderViewFej = () => {
    const {
      viewObjFej
    } = this.state;

    return (
      <div>
        <b>Partner: </b>
        {viewObjFej && viewObjFej.partner && viewObjFej.partner.nev + ' (' + viewObjFej.partner.azon + ')'}
        <br />
        <b>Azonosító: </b>
        {viewObjFej && viewObjFej.azonosito}
        <br />
        <b>Karbantartás kezdete: </b>
        {viewObjFej && viewObjFej.kezdete && this.timezoneToDate(viewObjFej.kezdete)}
        <br />
        <b>Karbantartás vége: </b>
        {viewObjFej && viewObjFej.vege && this.timezoneToDate(viewObjFej.vege)}
        <br />
        <br />
        <b>Rögzítés időpontja: </b>
        {viewObjFej && viewObjFej.rogzido && this.timezoneToDate(viewObjFej.rogzido)}
        <br />
        <b>Rögzítő felhasználó: </b>
        {viewObjFej && viewObjFej.rogzuser}
        <br />
        <b>Módosítás időpontja: </b>
        {viewObjFej && viewObjFej.modido && this.timezoneToDate(viewObjFej.modido)}
        <br />
        <b>Módosító felhasználó: </b>
        {viewObjFej && viewObjFej.moduser}
        <br />
        <br />
        <b>Megjegyzés: </b>
        <br />
        {viewObjFej && viewObjFej.megjegyzes}
      </div>
    );
  }

  renderViewTetel = () => {
    const {
      viewObjTetel
    } = this.state;

    return (
      <div>
        <b>Cikk: </b>
        {viewObjTetel && viewObjTetel.cikk && viewObjTetel.cikk.cikknev + ' (' + viewObjTetel.cikk.cikkszam + ')'}
        <br />
        <b>Egyedi azonosító: </b>
        {viewObjTetel && viewObjTetel.azonosito}
        <br />
        <b>Kapcsolódó azonosító: </b>
        {viewObjTetel && viewObjTetel.kapcsolodoazonosito}
        <br />
        <b>Cím: </b>
        {viewObjTetel && viewObjTetel.teljescim}
        <br />
        <b>Szolgáltatás kezdete: </b>
        {viewObjTetel && viewObjTetel.kezdete && this.timezoneToDate(viewObjTetel.kezdete)}
        <br />
        <b>Szolgáltatás vége: </b>
        {viewObjTetel && viewObjTetel.vege && this.timezoneToDate(viewObjTetel.vege)}
        {viewObjTetel && (viewObjTetel.existsfeladatlista || viewObjTetel.genmenny) && (
        <React.Fragment>
          <br />
          <b>Feladatok generálása: </b>
        </React.Fragment>
        )}
        {viewObjTetel && (viewObjTetel.existsfeladatlista || viewObjTetel.genmenny) && viewObjTetel.genmenny}
        {viewObjTetel && (viewObjTetel.existsfeladatlista || viewObjTetel.genmenny) && viewObjTetel.genmenny ? ' nap' : ''}
        <br />
        <br />
        <b>Leírás: </b>
        <br />
        <span style={{ whiteSpace: 'pre-line' }}>{viewObjTetel && viewObjTetel.leiras}</span>
        <br />
        <br />
        <b>Publikus leírás: </b>
        <br />
        <span style={{ whiteSpace: 'pre-line' }}>{viewObjTetel && viewObjTetel.publikusleiras}</span>
      </div>
    );
  }

  render() {
    let vanKarbRole = Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KARBANTARTAS_KARBANTARTAS', 'KARB');
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md-12">
            { vanKarbRole && (
              <Button className="pull-left table-new-button" color="primary" onClick={this.handleNewFejClick}>
                {'Új karbantartás'}
              </Button>
            )}
          </div>
          <div className="col-md-12">
            {{
              LOAD: (<Loader />),
              DATA: (<DataEmptyState
                hasImage
                subtitle="Vigyél fel új tételt!"
              />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.getKarbantartasListData()}
              />),
              TABL: (this.renderTable())
            }[this.state.tableStatus]}
          </div>
        </div>
        <Modal isOpen={this.state.modalViewFej} toggle={this.toggleViewModalFej} backdrop="static">
          <ModalHeader>{this.renderFormTitle(this.state.formTypeFej)}</ModalHeader>
          <ModalBody>
            {{
              LOAD: (<Loader />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.getFejData(this.state.currentId, false, true)}
              />),
              DATA: (this.renderViewFej())
            }[this.state.formStatusFej]}
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" className="btn-cursor-pointer" onClick={this.toggleViewModalFej}>Vissza</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalViewTetel} toggle={this.toggleViewModalTetel} backdrop="static">
          <ModalHeader>{this.renderFormTitle(this.state.formTypeTetel, true)}</ModalHeader>
          <ModalBody>
            {{
              LOAD: (<Loader />),
              NETW: (<NetworkEmptyState
                hasImage
                submitClick={() => this.getTetelData(this.state.currentId, false, true)}
              />),
              DATA: (this.renderViewTetel())
            }[this.state.formStatusTetel]}
          </ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" className="btn-cursor-pointer" onClick={this.toggleViewModalTetel}>Vissza</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalStateFej} toggle={this.toggleModalFej} backdrop="static" size="md">
          <AvForm onValidSubmit={this.handleSubmitFej}>
            <ModalHeader>{this.renderFormTitle(this.state.formTypeFej)}</ModalHeader>
            <ModalBody>
              {{
                LOAD: (<Loader />),
                NETW: (<NetworkEmptyState
                  hasImage
                  submitClick={() => this.getFejData(this.state.currentId)}
                />),
                DATA: (this.renderFormFej())
              }[this.state.formStatusFej]}
            </ModalBody>
            <ModalFooter>
              <ProgressButton
                className="btn-cursor-pointer"
                color="primary"
                loading={this.state.saveButtonLoading}
              >
                {'Mentés'}
              </ProgressButton>
              <Button type="button" color="secondary" className="btn-cursor-pointer" onClick={this.toggleModalFej}>Mégsem</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
        <Modal isOpen={this.state.modalStateTetel} toggle={this.toggleModalTetel} backdrop="static" size="lg">
          <AvForm onValidSubmit={this.handleSubmitTetel}>
            <ModalHeader>{this.renderFormTitle(this.state.formTypeTetel, true)}</ModalHeader>
            <ModalBody>
              {{
                LOAD: (<Loader />),
                NETW: (<NetworkEmptyState
                  hasImage
                  submitClick={() => this.getTetelData(this.state.currentId)}
                />),
                DATA: (this.renderFormTetel())
              }[this.state.formStatusTetel]}
            </ModalBody>
            <ModalFooter>
              <ProgressButton
                className="btn-cursor-pointer"
                color="primary"
                loading={this.state.saveButtonLoading}
              >
                {'Mentés'}
              </ProgressButton>
              <Button type="button" color="secondary" className="btn-cursor-pointer" onClick={this.toggleModalTetel}>Mégsem</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
        <Modal isOpen={this.state.modalStateUtemezes} toggle={this.toggleModalUtemezes} backdrop="static" size="lg">
          <AvForm onValidSubmit={this.handleSubmitUtemezes}>
            <ModalHeader>
              {'Karbantartás tétel ütemezés '}
              <CustomTooltip text={getTooltip('utemezesForm')} id="utemezesFormTT" />
            </ModalHeader>
            <ModalBody>
              {{
                LOAD: (<Loader />),
                NETW: (<NetworkEmptyState
                  hasImage
                  submitClick={() => this.getUtemezesData(this.state.currentId)}
                />),
                DATA: (this.renderFormUtemezes())
              }[this.state.formStatusUtemezes]}
            </ModalBody>
            <ModalFooter>
              {vanKarbRole
                && (
                <ProgressButton
                  className="btn-cursor-pointer"
                  color="primary"
                  loading={this.state.saveButtonLoading}
                >
                  {'Mentés'}
                </ProgressButton>
                )}
              <Button type="button" color="secondary" className="btn-cursor-pointer" onClick={this.toggleModalUtemezes}>{vanKarbRole ? 'Mégsem' : 'Bezárás'}</Button>
            </ModalFooter>
          </AvForm>
        </Modal>
        <Modal isOpen={this.state.modalTorlesFej} toggle={this.toggleTorlesFej} backdrop="static">
          <ModalHeader>{this.renderFormTitle(this.state.formTypeFej)}</ModalHeader>
          <ModalBody>Valóban törölni kívánja a kiválasztott karbantartást?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.deleteKarbantartasFej()}>Igen</Button>
            <Button type="button" color="secondary" onClick={this.toggleTorlesFej}>Mégsem</Button>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.modalTorlesTetel} toggle={this.toggleTorlesTetel} backdrop="static">
          <ModalHeader>{this.renderFormTitle(this.state.formTypeTetel, true)}</ModalHeader>
          <ModalBody>Valóban törölni kívánja a kiválasztott karbantartás tételt?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => this.deleteKarbantartasTetel()}>Igen</Button>
            <Button type="button" color="secondary" onClick={this.toggleTorlesTetel}>Mégsem</Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

KarbantartasContent.propTypes = {
  globals: PropTypes.object,
  active: PropTypes.bool
};

export default KarbantartasContent;
