import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';
import classnames from 'classnames';
import Lib from '../../commons/lib';
import FeladatlistaContent from './FeladatlistaContent';
import AllapotContent from './AllapotContent';
import FeladatTipusContent from './FeladatTipusContent';
import CustomTooltip from '../../components/Tooltip/CustomTooltip';
import getTooltip from './FeladatlistaTooltips';

class FeladatListaMenuBase extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: undefined,
    };
  }

  componentDidMount() {
    this.initTab();
  }

  initTab = () => {
    let activeTab = '1';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['FELADATLISTA_FELADATTIPUS'], true)) activeTab = '3';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['FELADATLISTA_FELADATTIPUS'], true)) activeTab = '2';
    if (Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['FELADATLISTA_FELADAT'], true)) activeTab = '1';

    this.setState({ activeTab: activeTab });
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  }

  render() {
    let cardStyle = {
      padding: 10
    };

    return (
      <div>
        <div className="animated fadeIn">
          <div className="card">
            <div className="card-header">
              Feladatlista
              <CustomTooltip text={getTooltip('feladatlista')} id="feladatlistaTT" />
            </div>
            <div className="row" style={cardStyle}>
              <div className="col-md-12">
                <Nav tabs>
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['FELADATLISTA_FELADAT'], true)
                  && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => { this.toggle('1'); }}
                      >
                        Feladatok
                        <CustomTooltip text={getTooltip('feladatok')} id="feladatokTT" />
                      </NavLink>
                    </NavItem>
                  )
                  }
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['FELADATLISTA_FELADATTIPUS'], true)
                  && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => { this.toggle('2'); }}
                      >
                        Feladattípus állapotok
                        <CustomTooltip text={getTooltip('feladattipusallapotok')} id="feladattipusallapotokTT" />
                      </NavLink>
                    </NavItem>
                  )
                  }
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['FELADATLISTA_FELADATTIPUS'], true)
                  && (
                    <NavItem>
                      <NavLink
                        href="#"
                        className={classnames({ active: this.state.activeTab === '3' })}
                        onClick={() => { this.toggle('3'); }}
                      >
                        Feladattípusok
                        <CustomTooltip text={getTooltip('feladattipusok')} id="feladattipusokTT" />
                      </NavLink>
                    </NavItem>
                  )
                  }
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['FELADATLISTA_FELADAT'], true)
                  && (
                    <TabPane tabId="1">
                      <FeladatlistaContent
                        globals={this.props.route.globals}
                        ref={(instance) => { this.child1 = instance; }}
                        active={this.state.activeTab === '1'}
                      />
                    </TabPane>
                  )
                  }
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['FELADATLISTA_FELADATTIPUS'], true)
                  && (
                    <TabPane tabId="2">
                      <AllapotContent
                        globals={this.props.route.globals}
                        ref={(instance) => { this.child1 = instance; }}
                        active={this.state.activeTab === '2'}
                      />
                    </TabPane>
                  )
                  }
                  {Lib.KeyCloak.hasRole(this.props.route.globals.keycloak, ['FELADATLISTA_FELADATTIPUS'], true)
                  && (
                    <TabPane tabId="3">
                      <FeladatTipusContent
                        globals={this.props.route.globals}
                        ref={(instance) => { this.child4 = instance; }}
                        active={this.state.activeTab === '3'}
                      />
                    </TabPane>
                  )
                }
                </TabContent>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FeladatListaMenuBase.propTypes = {
  route: PropTypes.object,
  globals: PropTypes.object
};

export default FeladatListaMenuBase;
