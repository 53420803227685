import React, { Component } from 'react';
import { Button, Label, Modal, ModalHeader, ModalBody, ModalFooter, Badge, UncontrolledTooltip } from 'reactstrap';
import _ from 'lodash';
import { AvField, AvForm } from 'availity-reactstrap-validation'
import Select from 'react-select/lib/Async';
import { DatePicker} from '../../../../../commons/controls.js';
import { ProgressButton } from '../../../../../commons/ProgressButton';
import BootstrapTable from 'react-bootstrap-table-next';
import { PaginationTotal } from '../../../../../commons/controls';
import filterFactory, { textFilter, selectFilter } from 'react-bootstrap-table2-filter';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { browserHistory } from 'react-router';
import Service from '../services.js';
import Loader from '../../../../../commons/loader';
import NetworkEmptyState from '../../../../../commons/networkemptystate';
import QueryEmptyState from '../../../../../commons/queryempty';
import DataEmptyState from '../../../../../commons/dataemptystate';
import Lib from '../../../../../commons/lib';
import FileSaver from 'file-saver';
import moment from 'moment';
import NumberFormatter from '../../../../../commons/NumberFormatter';

/**
 * Osztály listázza a készlet növekedéseket.
 * @extends Component
 */

class BevetelezesLista extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalNezet: false,
      novekedesJson: [],
      expandedItems: [],
      novekedesView: {},
      modalTorles: false,
      listLoadingButton: false,
      tableStatus: 'QUERY', // [LOAD', NETW', 'LIST', 'QUERY', DATA]

      // FILTER VALUES
      filterMozgasdatum: new Date(),
      filterMozgastipus: null,
      filterPartner: null,
      filterRaktar: null,

    };
  }

  updateTable = () => {
    const{
      filterMozgasdatum,
      filterPartner,
      filterRaktar,
      filterMozgastipus
    } = this.state;

    let filters = {};
    filters.mozgasDatum = filterMozgasdatum ? moment(filterMozgasdatum).format("YYYY-MM-DD") : null;
    filters.partnerId = filterPartner ? filterPartner.id : null;
    filters.raktarId = filterRaktar ? filterRaktar.id : null;
    filters.mozgasTipus = filterMozgastipus ? filterMozgastipus.id : null;

    this.setState({ listLoadingButton: true , tableStatus: 'LOAD', expandedItems: [] }, ()=> {
      Service.listBevetelezes(filters, (err, res) => {
        if (!err) {
          let newNovekedes = res[0];
          newNovekedes.forEach( nov => {
            if (nov.mozgasTipus)
              nov.mozgasTipus = nov.mozgasTipus.name;
            nov.szamla = nov.szamla ? 1 : 0; 
            switch(nov.allapot.id){
              case 'FOLYAMATBAN': 
              nov.allapot = 0; break;
              case 'NORMAL': 
              nov.allapot = 1; break;
              case 'SZTORNO': 
              nov.allapot = 2; break;
              case 'SZTORNOZOTT': 
              nov.allapot = 3; break;
              default: 
              nov.allapot = -1; break;
            }
          });
          this.setState({ 
            novekedesJson: newNovekedes,
            listLoadingButton: false,
            tableStatus: newNovekedes && newNovekedes.length > 0 ? 'LIST': 'DATA',
            cikkekJson: [],
          });
        }else {
          this.setState({
            listLoadingButton: false,
            tableStatus: err && err.responseJSON && err.responseJSON.hibaazon == "EMPTY_FILTERS" ? 'QUERY' : 'NETW', 
            cikkekJson: [],
          });
        }
      });
    });
  }

  deleteBevetel = (id) => {
    Service.deleteBevetelezes(id, (err, res) => {
      if (!err) {
        this.setState({ cikkekJson: [] });
        this.updateTable();
        this.handleDeleteClick();
        this.props.globals.addSuccess('Készlet törölve!');
      }
    });
  }

  handleAddKeszlet = () => {
    browserHistory.push("".concat('/keszlet/keszletkezeles/bevetelezes/felvetel'));
  }

  handleEditClick = (id) => {
    browserHistory.push("".concat('/keszlet/keszletkezeles/bevetelezes/modositas?id=' + id));
  }


  addNovekedes = (event, novekedesObj) => {
    this.setState({ novekedesJson: [...this.state.novekedesJson, novekedesObj] });
    this.toggle();
  }

  handlePdfClick = (id) => {
    let novekedes = this.state.novekedesJson.find(novekedes => novekedes.id === id);
    Service.generateBizonylatPdf(id, 'BEVETELEZES', (err, res) => {
      if (!err) {
        FileSaver.saveAs(new Blob([res[0]], { type: 'application/pdf' }), novekedes.bizonylatszam);
      }
    });
  }

  handleDeleteClick = (id) => {
    if(id) this._currentDeleteId = id;
    this.setState({
      modalTorles: !this.state.modalTorles
    });
  }

  handleViewClick = (id) => {
    if(!this.state.modalNezet){
      Service.getBevetelezes(id, (err, res) => {
        if (!err) {
          if(id) this._currentViewId = id;
          this.setState({ 
            novekedesView: res[0],
            modalNezet: !this.state.modalNezet,
          });
        }
      });
    }else{
      this.setState({ 
        novekedesView: {},
        modalNezet: !this.state.modalNezet,
      });
    }
  }

  handleDateInputChange = (e, v, name) => {
    const value = v === '' ? null : v;
    this.setState({
      [name]: value
    });
  }

  handleUpdatePartnerInput = (input, callback) => {
    if (!input) callback([]);
    Service.queryPartner('KESZLET_SZALLITO_FORM', input, (err, res) => {
      if (!err) {
        let options = [];
        res[0].filter(item => item.nev.toLowerCase().includes(input.toLowerCase())).forEach((item) => {
          options.push(item);
        });
        callback(options);
      }
    });
  }

  handleUpdateRaktarInput = (input, callback) => {
    if (!input) callback([]);
    Service.listBizonylatRaktar('KESZLET', null, (err, res) => {
      if (!err) {
        let options = [];
        res[0].filter(item => item.nev.toLowerCase().includes(input.toLowerCase())).forEach((item) => {
          options.push(item);
        });
        callback(options);
      }
    });
  }

  loadMozgasTipusOptions = (input, callback) => {
    let leltarRole = Lib.KeyCloak.hasRoleSpec(this.props.globals.keycloak, ['KESZLETNYILV_LELTAR_KORREKCIO']);

    Service.listMozgastipusBevetelezes((err, res) => {
      if (!err) {
        let options = [];
        res[0]
          .filter(m => m.name.toLowerCase().includes(input.toLowerCase()))
          .filter(m => !m.name.includes('LELTAR') || (m.name.includes('LELTAR') && leltarRole))
          .forEach((item) => {
            options.push(item);
          });
        callback(options);
      }
    });
  }

  iconFormatter = (cell, row) => {
    switch (row.allapot) {
      case 0: // FOLYAMATBAN
        return (
          <React.Fragment>
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KESZLETNYILV_BEVETELEZES', 'LEK') && (
              <React.Fragment>
                <Button className="icon-eye table-action-icon" id={'bevetel-view-' + row.allapot + '-view-' + row.id} onClick={() => this.handleViewClick(cell)} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'bevetel-view-' + row.allapot + '-view-' + row.id} delay={40}> {'Megtekintés'} </UncontrolledTooltip>
              </React.Fragment>
            )}
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KESZLETNYILV_BEVETELEZES', 'KARB') && row.editable && (
              <React.Fragment>
                <Button className="icon-pencil table-action-icon" id={'bevetel-view-' + row.allapot + '-edit-' + row.id} onClick={() => this.handleEditClick(cell)} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'bevetel-view-' + row.allapot + '-edit-' + row.id} delay={40}> {'Módosítás'} </UncontrolledTooltip>
                <Button className="icon-trash table-action-icon" id={'bevetel-view-' + row.allapot + '-delete-' + row.id} onClick={() => this.handleDeleteClick(cell)} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'bevetel-view-' + row.allapot + '-delete-' + row.id} delay={40}> {'Törlés'} </UncontrolledTooltip>
              </React.Fragment>
            )}
          </React.Fragment>
        );
      case 1: // NORMAL
        return (
          <React.Fragment>
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KESZLETNYILV_BEVETELEZES', 'LEK') && (
              <React.Fragment>
                <Button className="fa fa-file-pdf-o table-action-icon" id={'bevetel-view-' + row.allapot + '-pdf-' + row.id} onClick={() => this.handlePdfClick(cell)} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'bevetel-view-' + row.allapot + '-pdf-' + row.id} delay={40}> {'Bizonylat PDF generálása'} </UncontrolledTooltip>
              </React.Fragment>
            )}
            {row.editable && (
              <React.Fragment>
                {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KESZLETNYILV_BEVETELEZES', 'LEK') && (
                  <React.Fragment>
                    <Button className="icon-eye table-action-icon" id={'bevetel-view-' + row.allapot + '-view-' + row.id} onClick={() => this.handleViewClick(cell)} />
                    <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'bevetel-view-' + row.allapot + '-view-' + row.id} delay={40}> {'Megtekintés'} </UncontrolledTooltip>
                  </React.Fragment>
                )}
                {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KESZLETNYILV_BEVETELEZES', 'KARB') && (
                  <React.Fragment>
                    <Button className="icon-trash table-action-icon" id={'bevetel-view-' + row.allapot + '-delete-' + row.id} onClick={() => this.handleDeleteClick(cell)} />
                    <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'bevetel-view-' + row.allapot + '-delete-' + row.id} delay={40}> {'Sztornó'} </UncontrolledTooltip>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        );
      case 2: // SZTORNO
        return (
          <React.Fragment>
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KESZLETNYILV_BEVETELEZES', 'LEK') && (
              <React.Fragment>
                <Button className="icon-eye table-action-icon" id={'bevetel-view-' + row.allapot + '-view-' + row.id} onClick={() => this.handleViewClick(cell)} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'bevetel-view-' + row.allapot + '-view-' + row.id} delay={40}> {'Megtekintés'} </UncontrolledTooltip>
              </React.Fragment>
            )}
          </React.Fragment>
        );
      case 3: // SZTORNOZOTT
        return (
          <React.Fragment>
            {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KESZLETNYILV_BEVETELEZES', 'LEK') && (
              <React.Fragment>
                <Button className="fa fa-file-pdf-o table-action-icon" id={'bevetel-view-' + row.allapot + '-pdf-' + row.id} onClick={() => this.handlePdfClick(cell)} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'bevetel-view-' + row.allapot + '-pdf-' + row.id} delay={40}> {'Bizonylat PDF generálása'} </UncontrolledTooltip>
                <Button className="icon-eye table-action-icon" id={'bevetel-view-' + row.allapot + '-view-' + row.id} onClick={() => this.handleViewClick(cell)} />
                <UncontrolledTooltip style={{ float: 'left' }} placement="top" target={'bevetel-view-' + row.allapot + '-view-' + row.id} delay={40}> {'Megtekintés'} </UncontrolledTooltip>
              </React.Fragment>
            )}
          </React.Fragment>
        );
      default:
        return ' ';
    }
  }

  tableStatusFormatter = (cell, row, enumobject) => {
    switch (cell) {
      case 0:
        return <Badge color="dark">folyamatban</Badge>;
      case 1:
        return <Badge color="success">normál</Badge>;
      case 2:
        return <Badge color="info">sztornó</Badge>;
      case 3:
        return <Badge color="primary">sztornózott</Badge>;
      default:
        return ' ';
    }
  }

  viewStatusFormatter = (cell, row, enumobject) => {
    switch (cell) {
      case 'FOLYAMATBAN':
        return <Badge color="dark">folyamatban</Badge>;
      case 'NORMAL':
        return <Badge color="success">normál</Badge>;
      case 'SZTORNO':
        return <Badge color="info">sztornó</Badge>;
      case 'SZTORNOZOTT':
        return <Badge color="primary">sztornózott</Badge>;
      default:
        return ' ';
    }
  }

  columnClassNameFormat = (fieldValue, row, rowIdx, colIdx) => {
    return row.modosithato ? 'td-column-nem-mentett' : 'td-column-mentett';
  }

  szamlaFormatter = (cell) => {
    return cell == 1 ? '✓' : 'X';
  }

  onRowSelect = (row, isSelected, e) => {
    Service.listKeszletCikk(row.id, (err, res) => {
      if(!err){
        this.setState({ 
          expandedItems: [{id: row.id, data: res[0]}],
        });
        if (row.cikkek && !row.cikkek.length) {
            this.props.globals.addWarning('A kiválasztott tételhez nem tartoznak cikkek!');
        }
      }
    });
  }

  renderAdatok = (id) => {
    const allapot = this.viewStatusFormatter(this.state.novekedesView.allapot.id);
    return (
      <div>
        <h6>Készletnövekedés információk:</h6>
        <b>Mozgás típusa: </b>{this.state.novekedesView.mozgasTipus && this.state.novekedesView.mozgasTipus.name}<br />
        <b>Mozgás dátum: </b>{moment(this.state.novekedesView.mozgasDatum).format("YYYY-MM-DD HH:mm:ss.SSS")}<br />
        <b>Raktár: </b>{this.state.novekedesView.raktar.nev}<br />
        <b>Szállító: </b>
          <span>
            {this.state.novekedesView.partner && this.state.novekedesView.partner.nev} 
            {this.state.novekedesView.partner && this.state.novekedesView.partner.azonosito ? ' - ' : ''} 
            {this.state.novekedesView.partner && this.state.novekedesView.partner.azonosito}
          </span>
          <br />
        {this.state.novekedesView.bizonylatszamrendeles ?
          (
            <span>
              <b>Szállítói rendelés: </b> {this.state.novekedesView.bizonylatszamrendeles}
              <br />
            </span>
          )
          : <div />
        }
        <b>Fizetési mód: </b>{this.state.novekedesView.fizmod ? this.state.novekedesView.fizmod.variansnev ? this.state.novekedesView.fizmod.variansnev : this.state.novekedesView.fizmod.nev : ''}<br />
        <b>Pénznem: </b>{this.state.novekedesView.penznem.azonosito}<br />
        <b>Számla: </b>{this.state.novekedesView.szamla ? this.state.novekedesView.szamlaszam : 'Nincs' }<br />
        <b>Szállítólevél szám: </b>{this.state.novekedesView.szallitolevelszam ? this.state.novekedesView.szallitolevelszam : 'Nincs' }<br />
        <b>Bizonylat azonosító: </b>{this.state.novekedesView.bizonylatszam}<br />
        <b>Kapcsolódó azonosító: </b>{this.state.novekedesView.kapcsolodoszam}<br />
        <b>Állapot: </b>{allapot}<br />
        <b>Fizetési határidő: </b>{this.state.novekedesView.fizetesihatarido}<br />
        <br />
        <b>UVD: </b>
          <div>
            <b>Rögzítés időpontja: </b>{moment(this.state.novekedesView.uvd.rogzitesidopont).format("YYYY-MM-DD HH:mm:ss")}<br />
            <b>Rögzítő személy: </b>{this.state.novekedesView.uvd.rogzszemelynev}<br />
            <b>Módosítás időpontja: </b>{moment(this.state.novekedesView.uvd.modositasidopont).format("YYYY-MM-DD HH:mm:ss")}<br />
            <b>Módosító személy: </b>{this.state.novekedesView.uvd.modszemelynev}<br />
            <b>Véglegezés időpontja: </b>{this.state.novekedesView.veglegezesdatum ? moment(this.state.novekedesView.veglegezesdatum).format("YYYY-MM-DD HH:mm:ss") : ''}<br />
            <b>Véglegező személy: </b>{this.state.novekedesView.veglegezesszemelynev}<br />
          </div>
      </div>
    );
  }

  renderFilters = () => {
    const{
      filterMozgasdatum,
      filterPartner,
      filterRaktar,
      filterMozgastipus,
    } = this.state;
    let leltarRole = Lib.KeyCloak.hasRoleSpec(this.props.globals.keycloak, ['KESZLETNYILV_LELTAR_KORREKCIO']);
    return(
      <React.Fragment>
        <AvForm>
          <div className="row">
            <div className="col-md-2">
                <Label for="filterMozgasdatum">Mozgás dátuma (-tól):</Label>
                <DatePicker 
                  name="filterMozgasdatum"
                  value={filterMozgasdatum && new Date(filterMozgasdatum)}
                  onChange={(e,v) => this.handleDateInputChange(e, v, "filterMozgasdatum")}
                  max={new Date()}
                />
            </div>
            <div className="col-md-3" >
              <Label for="filterRaktar">Raktár:</Label>
              <Select
                styles={{
                control: (base) => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                  }),
                }}
                name="filterRaktar"
                id="filterRaktar"
                placeholder="Kezdjen el gépelni a kereséshez..."
                noOptionsMessage={() => "Nincs keresési eredmény"}
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingMessage={() => "Keresés..."}
                onChange={v => this.setState({filterRaktar: v})}
                loadOptions={_.debounce(this.handleUpdateRaktarInput, 1000)}
                value={filterRaktar}
                defaultOptions
                isClearable
                getOptionValue={ option => option.id}
                getOptionLabel={ option => option.nev}
              />
            </div>
            <div className="col-md-3" >
              <Label for="filterPartner">Szállító partner:</Label>
              <Select
                styles={{
                control: (base) => ({
                  ...base,
                  '&:focus': { borderColor: 'red' }, // border style on hover
                  border: '1px solid lightgray', // default border color
                  boxShadow: 'none', // no box-shadow
                  borderRadius: '0'
                  }),
                }}
                name="filterPartner"
                id="filterPartner"
                placeholder="Kezdjen el gépelni a kereséshez..."
                noOptionsMessage={() => "Nincs keresési eredmény"}
                searchPromptText="Kezdjen el gépelni a kereséshez..."
                loadingMessage={() => "Keresés..."}
                onChange={v => this.setState({filterPartner: v})}
                loadOptions={_.debounce(this.handleUpdatePartnerInput, 1000)}
                value={filterPartner}
                isClearable 
                getOptionValue={ option => option.id}
                getOptionLabel={ option => option.nev}
              />
            </div>
            <div className="col-md-2">
              <Label for="filterMozgastipus">Mozgás típusa:</Label>
              <Select
                styles={{
                  control: base => ({
                    ...base,
                    '&:focus': { borderColor: 'red' }, // border style on hover
                    border: '1px solid lightgray', // default border color
                    boxShadow: 'none', // no box-shadow
                    borderRadius: '0'
                  }),
                }}
                name="filterMozgastipus"
                id="filterMozgastipus"
                placeholder="Kezdjen el gépelni..."
                noOptionsMessage={() => 'Nincs keresési eredmény'}
                onChange={v => this.setState({filterMozgastipus: v })}
                loadOptions={_.debounce(this.loadMozgasTipusOptions, 1000)}
                getOptionLabel={option => option.name}
                getOptionValue={option => option.id}
                value={filterMozgastipus}
                defaultOptions
                isClearable
              />
            </div>
            <div className="col-md-2" style={{paddingTop: "28px"}}>
              <ProgressButton color="primary" id="save" loading={this.state.listLoadingButton} onClick={e => this.updateTable(e)} >Keresés</ProgressButton>{' '}
            </div>
          </div>
        </AvForm>
      </React.Fragment>
    )
  }

  renderTable = () => {
    const options = {
      sizePerPage: 5,
      showTotal: true,
      paginationTotalRenderer: PaginationTotal,
      sizePerPageList: [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: '25', value: 25 }
      ],
    };

    const selectRow = {
      mode: 'radio',
      clickToSelect: true,
      onSelect: this.onRowSelect,
    };

    const allapotType = {
      0: 'Folyamatban',
      1: 'Normál',
      2: 'Sztornó',
      3: 'Sztornózott'
    };

    const columns = [
      {
        dataField: 'mozgasTipus',
        text: 'Mozgástípus',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'raktar.nev',
        text: 'Raktár',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },{
        dataField: 'partner.nev',
        text: 'Szállító',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'mozgasDatum',
        text: 'Mozgásdátum',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true,
        formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : ""
      },
      {
        dataField: 'fizmodnev',
        text: 'Fizetési mód',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true,
      },
      {
        dataField: 'szamla',
        text: 'Számla',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true,
        formatter: this.szamlaFormatter
      },
      {
        dataField: 'bizonylatszam',
        text: 'Bizonylat azonosító',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'kapcsolodoszam',
        text: 'Kapcsolódó azonosító',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line'
        },
        sort: true
      },
      {
        dataField: 'allapot',
        align: 'center',
        headerStyle: { textAlign: 'center' },
        text: 'Állapot',
        filter: selectFilter({
          options: allapotType,
          placeholder: 'Összes',
        }),
        style: {
          whiteSpace: 'pre-line',
        },
        formatter: this.tableStatusFormatter
      },
      {
        dataField: 'id',
        text: 'Műveletek',
        hidden: !Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KESZLETNYILV_BEVETELEZES', 'KARB'),
        formatter: this.iconFormatter
      }
    ];

    const cikkColumns = [
      {
        dataField: 'cikk.cikkszam',
        text: 'Cikkszám',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
          textAlign: 'right'
        },
        sort: true
      },
      {
        dataField: 'cikk.megnevezes',
        text: 'Megnevezés',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: {
          whiteSpace: 'pre-line',
          textAlign: 'right' 
        },
        sort: true
      },
      {
        dataField: 'mennyiseg',
        text: 'Mennyiség',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: NumberFormatter.styleTextAlignWhiteSpace,
        formatter: (cell, row) => NumberFormatter.formatterSpaceOsszeg(cell) + (row && row.cikk ? ' ' + row.cikk.mennyisegiEgyseg : ''),
        sort: true
      },
      {
        dataField: 'cikk.aktualisAfaErtek',
        text: 'ÁFA%',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: NumberFormatter.styleTextAlignWhiteSpace,
        formatter: NumberFormatter.formatterSpaceOsszeg,
        sort: true
      },
      {
        dataField: 'egysegarnetto',
        text: 'Nettó egységár',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: NumberFormatter.styleTextAlignWhiteSpace,
        formatter: (cell, row) => NumberFormatter.formatterSpaceOsszegPenznem(cell, row),
        sort: true
      },
      {
        dataField: 'egysegarbrutto',
        text: 'Bruttó egységár',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: NumberFormatter.styleTextAlignWhiteSpace,
        formatter: (cell, row) => NumberFormatter.formatterSpaceOsszegPenznem(cell, row),
        sort: true
      },
      {
        dataField: 'beszerzesnetto',
        text: 'Nettó beszerzési ár',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: NumberFormatter.styleTextAlignWhiteSpace,
        formatter: (cell, row) => NumberFormatter.formatterSpaceOsszegPenznem(cell, row),
        sort: true
      },
      {
        dataField: 'beszerzesbrutto',
        text: 'Bruttó beszerzési ár',
        filter: textFilter({
          delay: 400, // default is 500ms
          placeholder: 'Keresés...',
        }),
        style: NumberFormatter.styleTextAlignWhiteSpace,
        formatter: (cell, row) => NumberFormatter.formatterSpaceOsszegPenznem(cell, row),
        sort: true
      }
    ];

    const expandRow = {
      renderer: row => ( this.state.expandedItems.find(x => x.id == row.id) &&
          <React.Fragment>
            <div className="row">
              <div className="col-md-2">
                <h5>Cikkek</h5>
              </div>  
            </div>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  data={this.state.expandedItems.find(x => x.id == row.id).data}
                  columns={cikkColumns}
                  //selectRow={selectRow}
                  filter={filterFactory()}
                  pagination={paginationFactory(options)}
                  noDataIndication="Nincs megjelenítendő adat!"
                />
              </div>
            </div>
          </React.Fragment>
      ),
      expanded: this.state.expandedItems.map( x => x.id),
      expandByColumnOnly: true,
    };

    return (
      <div className="animated fadeIn">
        <BootstrapTable
          bootstrap4
          keyField="id"
          data={this.state.novekedesJson}
          expandRow={expandRow}
          columns={columns}
          filter={filterFactory()}
          pagination={paginationFactory(options)}
          selectRow={selectRow}
          noDataIndication="Nincs megjelenítendő adat!"
        />
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderFilters()}
        <div className="row">
          {Lib.KeyCloak.hasHigherRole(this.props.globals.keycloak, 'KESZLETNYILV_BEVETELEZES', 'KARB') && (
            <div className="col-md-12">
              <Button className="pull-left table-new-button" color="primary" onClick={this.handleAddKeszlet}>Új készletnövekedés</Button>
            </div>
          )}
        </div>
        {this.state.tableStatus === 'LOAD' ? <Loader /> : <span />}
        {this.state.tableStatus === 'QUERY' ? <QueryEmptyState hasImage/> : <span />}
        {this.state.tableStatus === 'DATA' ? <DataEmptyState hasImage subtitle="Próbálj más előszűrést vagy vigyél fel új adatokat!" /> : <span />}
        <div>
          {this.state.tableStatus === 'LIST' ? this.renderTable() : <span />}
          {this.state.tableStatus === 'NETW' ? (
          <NetworkEmptyState
            hasImage
            submitClick={() => this.updateTable()}
          />) : <span />}
        </div>

        <Modal isOpen={this.state.modalTorles} toggle={this.handleDeleteClick} className={this.props.className} backdrop="static">
          <ModalHeader >Törlés</ModalHeader>
          <ModalBody>Valóban törölni kívánja a kiválasztott tételt?</ModalBody>
          <ModalFooter>
            <Button className="btn-cursor-pointer" color="primary" onClick={() => this.deleteBevetel(this._currentDeleteId)}>OK</Button>
            <Button className="btn-cursor-pointer" type="button" color="secondary" onClick={this.handleDeleteClick}>Mégsem</Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalNezet} toggle={this.handleViewClick} className={this.props.className} backdrop="static">
          <ModalHeader >Megtekintés</ModalHeader>
          <ModalBody>{this.state.modalNezet ? this.renderAdatok(this._currentViewId) : <div />}</ModalBody>
          <ModalFooter>
            <Button type="button" color="primary" onClick={this.handleViewClick}>
              Vissza
            </Button>
          </ModalFooter>
        </Modal>

      </div>
    );
  }
}

export default BevetelezesLista;
