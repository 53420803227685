import Lib from '../../commons/lib';

export default class Service {
  static kartotekUrl = window.location.origin + '/api/kartotek/';

  static partnerUrl = window.location.origin + '/api/partner/';

  static telepulesUrl = window.location.origin + '/api/telepules/';

  static cegadatUrl = window.location.origin + '/api/cegadat/';

  // KARTOTÉKRENDSZER START

  static listKarton(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.kartotekUrl + 'karton/list',
      args: { requestType: 'karton-list' }
    }], fnDone);
  }

  static getActiveVizsgalo(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.kartotekUrl + 'vizsgaloszemely/aktiv',
      args: { requestType: 'vizsgaloszemely-aktiv' }
    }], fnDone);
  }

  static listVizsgalat(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.kartotekUrl + 'karton',
      args: { requestType: 'karton-vizsgalat-list' },
      params: { id: id }
    }], fnDone);
  }

  static listVizsgalat2(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.partnerUrl + 'search/getlist',
      args: { requestType: 'search-getlist' },
      params: { service: 'KARTON_GET' },
      data: data
    }], fnDone);
  }

  static updateKarton(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.kartotekUrl + 'karton',
      args: { requestType: 'karton-update' },
      data: data,
    }], fnDone);
  }

  static deleteKarton(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.kartotekUrl + 'karton',
      args: { requestType: 'karton-delete' },
      params: { id: id },
    }], fnDone);
  }

  static getVizsgalat(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.kartotekUrl + 'vizsgalat',
      args: { requestType: 'karton-vizsgalat-get' },
      params: { id: id }
    }], fnDone);
  }

  static createVizsgalat(data, print, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.kartotekUrl + 'karton/save',
      args: { requestType: 'karton-save-create' },
      data: data,
      params: { print: print }
    }], fnDone);
  }

  static updateVizsgalat(data, print, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.kartotekUrl + 'karton/modify',
      args: { requestType: 'karton-update' },
      data: data,
      params: { print: print }
    }], fnDone);
  }

  static createKarton(data, print, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.kartotekUrl + 'karton',
      args: { requestType: 'karton-create' },
      data: data,
      params: { print: print }
    }], fnDone);
  }

  static getKarton(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.kartotekUrl + 'karton',
      args: { requestType: 'karton-get' },
      params: { id: id }
    }], fnDone);
  }

  static getKartonData(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.partnerUrl + 'search/getlist',
      args: { requestType: 'search-getlist' },
      params: { service: 'KARTOTEK_UJ_KARTON_GET' },
      data: data
    }], fnDone);
  }

  static deleteVizsgalat(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'DELETE',
      url: Service.kartotekUrl + 'vizsgalat',
      args: { requestType: 'vizsgalat-delete' },
      params: { id: id },
    }], fnDone);
  }

  static getPdf(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.kartotekUrl + 'karton/pdf',
      acceptType: 'application/octet-stream',
      args: { requestType: 'karton-pdf' },
      params: { id: id },
    }], fnDone);
  }

  static createPartner(data, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'POST',
      url: Service.partnerUrl + 'partner',
      args: { requestType: 'partner-create' },
      data: data,
    }], fnDone);
  }

  static listKartotekUgyfel(nev, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'search',
      args: { requestType: 'partner-search' },
      params: {
        type: 'KARTOTEK_UJ_KARTON_LIST_FORM',
        text: nev
      }
    }], fnDone);
  }

  static getPartner(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + '/partner',
      args: { requestType: 'partner-get' },
      params: { id: id }
    }], fnDone);
  }

  static listTelefonEnum(fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.partnerUrl + 'partner/telefonenum',
      args: { requestType: 'partner-telefonenum-list' }
    }], fnDone);
  }

  static lockKarton(kartonid, date, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.kartotekUrl + 'karton/lezaras',
      args: { requestType: 'kartotek-karton-lezaras' },
      params: {
        id: kartonid,
        date: date
      }
    }], fnDone);
  }

  static listAktivCegadat(id, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.cegadatUrl + 'search',
      params: {
        aktiv: true,
        resultclass: '30'
      },
      data: [id]
    }], fnDone);
  }

  static checkPhone(telefon, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'PUT',
      url: Service.partnerUrl + 'partner/checktelefon',
      args: { requestType: 'partner-checktelefon-put' },
      data: telefon
    }], fnDone);
  }

  // KARTOTÉKRENDSZER END

  // TELEPÜLÉS START

  static listTelepules(iranyitoszam, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'telepules',
      args: { requestType: 'telepules-list' },
      params: { iranyitoszam: iranyitoszam }
    }], fnDone);
  }

  static listOrszag(name, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'orszag/list',
      args: { requestType: 'telepules-orszag-list' },
      params: { name: name }
    }], fnDone);
  }

  static getOrszag(kod, fnDone) {
    Lib.MicroServices.requestService([{
      method: 'GET',
      url: Service.telepulesUrl + 'orszag/kod',
      args: { requestType: 'telepules-orszag-get' },
      params: { kod: kod },
    }], fnDone);
  }

  // TELEPÜLÉS END
}
