import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';

/**
 * Empty state for network failure.
 *
 * @version 1.0.0
 * @author [Nagy Gergely](https://github.com/gergely-nagy)
 */
const NetworkEmptyState = (props) => {
  return (
    <div className="empty-state animated fadeIn">
      {props.hasImage && (
        <img
          src={process.env.PUBLIC_URL + '/img/noi-internet.png'}
          className="img-fluid"
          alt="img-avatar"
        />
      )}
      <h3>{props.title}</h3>
      <p>{props.subtitle}</p>
      <Button
        color="primary"
        onClick={props.submitClick}
      >
        {props.btnText}
      </Button>
    </div>
  );
};

NetworkEmptyState.propTypes = {
  /** boolean to control the image visibility of the NetworkEmptyState */
  hasImage: PropTypes.bool,
  /** function for the button's onClick */
  submitClick: PropTypes.func,
  /** custom title of the NetworkEmptyState */
  title: PropTypes.string,
  /** custom subtitle of the NetworkEmptyState */
  subtitle: PropTypes.string,
  /** custom button title of the NetworkEmptyState */
  btnText: PropTypes.string,
};

// Specifies the default values for props:
NetworkEmptyState.defaultProps = {
  hasImage: false,
  title: 'Hálózati hiba',
  subtitle: 'Hálózati hiba miatt nem sikerült kapcsolódni a szerverhez. Kérem, próbálkozzon újra!',
  btnText: 'Próbáld újra!'
};

export default NetworkEmptyState;
